import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ConsentService } from '../../../services/consent.service';
import { SharedService } from '../../../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../../shared/models/GlobalVars';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppointmentsService } from '../../../services/appointments.service';
import { Response } from '../../../../../_Models/Response';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfigurationManager } from '../../../../../../assets/configuration/configuration-manager';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-static-patient-consent',
  templateUrl: './static-patient-consent.component.html',
  styleUrls: ['./static-patient-consent.component.scss']
})
export class StaticPatientConsentComponent implements OnInit, AfterViewInit {

  @Input() rowData;
  consultationId: number;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  PatientConsents: any;
  public documentLoading: boolean = false;
  public documentForm: FormGroup;
  public signatureAdded: boolean = false;
  private baseUrl: string = '';
  private canvasContext: CanvasRenderingContext2D;
  private signStarted: boolean = false;
  pdfUrl:string=''

  @ViewChild('canvas') canvasEl: ElementRef;
  signaturePad: SignaturePad;
  constructor(private ConsentService: ConsentService,
    private sharedservice: SharedService,
    private formBuilder: FormBuilder,
    private appointmentService: AppointmentsService,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private activeModal: BsModalRef,
    private configurationManager: ConfigurationManager,
    ) {
      this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
        if (this.sharedservice.checkglobals(glob)) {
          this.globals = glob;
          this.BranchesId = this.globals.HospitalId;
        }
      });
     }

  ngOnInit(): void {
    this.consultationId = this.rowData.consultationId;
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.initDocumentForm();
    this.GetConsentWithPatDetails();
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.signaturePad.maxWidth = 0.9;

    // let canvasRef: any = <HTMLCanvasElement>(document.getElementById('canvas-signature'));
    // let canvas: CanvasRenderingContext2D = canvasRef.getContext('2d');
    // canvas.lineWidth = 1;
    // canvas.lineJoin = 'round';
    // canvas.strokeStyle = '#3e3f40';
    // canvas.fillStyle = '#3e3f40';
    // this.canvasContext = canvas;
  }

  initDocumentForm(){
    this.documentForm = this.formBuilder.group({
      documentId: new FormControl('', Validators.required),
      patientSignature: new FormControl('', Validators.required),
      documentPath: new FormControl(''),
      documentName: new FormControl('')
    })
  }

  GetConsentWithPatDetails(){
    const payload={
      ConsultationId: this.consultationId,
      PatientId: this.rowData.patientId,
      BranchId: this.BranchesId,
      StaticCType:1,
      ConsentType:"PC"    
    }
    this.documentLoading = true;
    this.ConsentService.GetConsentWithPatDetails(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          const data = response.response[0];
          if(data.id && data.reportPath){
            this.pdfUrl=this.baseUrl+data.reportPath
            this.documentForm.patchValue({
              documentPath: this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl+data.reportPath),
              documentId: data.id,
              documentName: data.reportName
            })
          }else{
            this.toastrService.error(data.errormessage);
            this.close();
          }
        }else{
          this.toastrService.error(response.errorMessage.message);
          this.close();
        }
        this.documentLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
        this.documentLoading = false;
        this.close();
      }
    })
  }

  close(){
    this.activeModal.hide();
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  onUpdatePatientSignature(){
    const signName = "sign.jpeg";
    let canvas = document.getElementById('canvas') as HTMLCanvasElement;
    let base64Data = canvas.toDataURL();
    let signsplit = base64Data.split(';');
    const signBlob = this.dataURItoBlob(signsplit[1].split(',')[1]);
    const signFile = new File([signBlob], signName, { type: 'image/jpeg' });

    const formData = new FormData();
    let consultationId: any;
    consultationId = this.consultationId;

    // formData.append('ConsultationId', consultationId);
    formData.append('Id', this.documentForm.get('documentId').value);
    formData.append('PatSign', signFile);
    // formData.append('ConsentType', '"PC"');
    this.documentLoading = true;
    this.ConsentService.GetSignedConsentPdf(formData).subscribe({
      next: (response: Response<any>)=>{
        this.documentLoading = false;
        if(response.status == 200){
          const data = response.response[0];
          if(data.reportPath){
            this.pdfUrl=this.baseUrl+data.reportPath
            this.documentForm.patchValue({
              documentPath: this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl+data.reportPath),
            })
            this.signatureAdded = true;
          }else{
            this.toastrService.error(data.errormessage);
            this.close();
          }
        }else{
          this.toastrService.error(response.errorMessage.message)
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

  // startDrawing(event: MouseEvent) {
  //   this.canvasContext.beginPath();
  //   this.canvasContext.moveTo(event.offsetX, event.offsetY);
  //   this.signStarted = true;
  // }
  startDrawing(event: Event) {

    // works in device not in browser

  }

  moved(event: Event) {
    // works in device not in browser
  }
  draw(event: MouseEvent) {
    if(this.signStarted){
      this.canvasContext.lineTo(event.offsetX, event.offsetY);
      this.canvasContext.stroke();
    }
  }

  stopDrawing(){
    this.canvasContext.closePath();
    this.signStarted = false;
  }

  clearSignature(){
    // this.canvasContext.clearRect(0,0,150,70);
    this.signaturePad.clear();
  }

}
