import { Component, OnInit ,TemplateRef,ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ConfigurationService } from "../../client/services/configuration.service";
import { ToastrService } from "ngx-toastr";
import _ from 'lodash';

@Component({
  selector: 'app-sample-tye',
  templateUrl: './sample-tye.component.html',
  styleUrls: ['./sample-tye.component.scss']
})
export class SampleTyeComponent implements OnInit {
  @ViewChild("template") modaltemp: any;

  sampleType: FormGroup;
  public gridApi;
  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];
  sampleData: any;
  sampleGroup: any;
  rowGroupPanelShow = "always";
  public context;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
        // 'c-reds': function (params) { return params.data.active == '0'; },
        // 'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    },
}
public loadingTemplate;
public noRowsTemplate;
public frameworkComponents;
TaxPopupHeading:String="Add Sample Type";
modalRef?: BsModalRef;
isLoading=false;
public submitted: boolean = false;
public user_id: string;
result: any;
StypeId:any;
functionPermissionData: any;

  constructor(
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,

  ) {
    this.user_id = localStorage.getItem('user_id');

   }

  ngOnInit(): void {
    this.functionPermission();
    this.context = { componentParent: this };
    this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
    };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
  };
  this.noRowsTemplate = `"<span">no rows to show</span>"`;
  this.sampleType = new FormGroup({
    StypeName: new FormControl('', [Validators.required]),
    StypeId: new FormControl(0),
    IsDeleting: new FormControl(0),
    UserId: new FormControl(Number(this.user_id )),
    ParentId: new FormControl(0)

});
this.columnDefs = [
  {
      headerName: "Sample Type Name",
      field: "stypeName",
      minWidth:180,
      enableRowGroup: true,
      resizable: false,
      sortable: true,
      flex: 2,
},
  {
      headerName: "Action",
      filter: false,
      maxWidth:100,
      pinned: "right",
      sortable: false,
      unSortIcon: true,
      field: "cptCodeId",
      flex: 2,
      resizable: false,

      cellRenderer: "buttonRender",
    cellRendererParams: { PageType: "sampleTypeLIstList" },
  },

]
this.getResultForm();
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
        (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
}
onGridReady(params) {
  this.gridApi = params.api;
}
openModal(template: TemplateRef<any>) {
  const foundElement = this.functionPermissionData.find(x => x.id === 165)
  if (foundElement.id == 165 && foundElement.isAllowed == true) {
  this.TaxPopupHeading="Add Sample Type";
  this.modalRef = this.modalService.show(template);
  this.clearForm();

  // this.TaxId = 0;
  } else {
    this.toastr.warning("You are not allowed to add data");
  }
}
close(){
  this.modalRef.hide();
}

save(){
  this.submitted = true;
  if (this.sampleType && this.sampleType.valid) {
    var formdata = this.sampleType.getRawValue();
    let dataid = 0;
    dataid = formdata.StypeId;
    formdata.ParentId = Number(formdata.ParentId);

    this.isLoading = true;
    this.configurationService.saveSampleType(formdata).subscribe((res) => {
      this.submitted = false;
      this.result = res;
      if (this.result.status == 200 && this.result.message == 'Success') {
        if (dataid == 0) {
          this.isLoading = false;
          this.toastr.success("Sample Type added Successfully");
        }
        else {
          this.isLoading = false;
          this.toastr.success("Sample Type Edit Successfully");
          this.StypeId = 0;

        }
        this.getResultForm()
        this.close();
        this.clearForm();


      }
      else {
        this.isLoading = false;
        this.toastr.error("Failed : " + this.result.message);
      }
    })

  }

  }

  getResultForm(){
    let input={
      "StypeId":0,
  
    }
    this.configurationService.GetSampleType(input).subscribe((res) => {
      this.sampleData = res.response;
      this.sampleGroup = _.filter(this.sampleData, {parentId: 0});
      // console.log('this.sampleGroup', this.sampleGroup);
    });
  
  }
  showModalDialog(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 166)
    if (foundElement.id == 166 && foundElement.isAllowed == true) {
    this.TaxPopupHeading="Edit Sample Type";
    this.modalRef = this.modalService.show(
      this.modaltemp,
      // Object.assign({}, { class: "gray modal-lg" })
      
  );

  this.sampleType.patchValue({
    StypeName: data.stypeName,
    StypeId:data.stypeId,
    ParentId: data.parentId
});
  } else {
    this.toastr.warning("You are not allowed to edit data");
  }

  }
  clearForm(){
    this.sampleType.reset({
      StypeName: '',
      StypeId: 0,
      IsDeleting:0,
      UserId:0,
      ParentId: 0
  });

  }
  get f() {
    return this.sampleType.controls;
  }
  DeleteSampleType(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 167)
    if (foundElement.id == 167 && foundElement.isAllowed == true) {
    var formdata = this.sampleType.getRawValue();
    formdata.IsDeleting=1;
    formdata.StypeId=data.stypeId;
    formdata.StypeName=data.stypeName;
    formdata.ParentId = Number(data.parentId);
    this.configurationService.saveSampleType(formdata).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200 && this.result.message == 'Success') {
        
          this.toastr.success("Deleted Successfully");
          this.getResultForm()

      }
  })
  } else {
    this.toastr.warning("You are not allowed to delete data");
  }
}

functionPermission() {
  let input = {
    MenuId: Number(localStorage.getItem('selectedMenu')),
    UserId: Number(localStorage.getItem("user_id"))
  }
  this.configurationService.functionPermission(input).subscribe((res) => {
    this.functionPermissionData = res.response;
    console.log('functionPermissionData', this.functionPermissionData);
  });

}
}
