import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SharedService } from '../../../../shared/shared.service';
import { ConfigurationService } from '../../../client/services/configuration.service';


@Component({
  selector: 'app-add-edit-external-lab',
  templateUrl: './add-edit-external-lab.component.html',
  styleUrls: ['./add-edit-external-lab.component.scss']
})
export class AddEditExternalLabComponent implements OnInit {
  @Input() labData
  @Output() createEmitter=new EventEmitter()

  globals: globalvars = new globalvars();
  ExternalLabForm: FormGroup;
  isLoading:boolean=false
  isSaveClicked: boolean=false;
  subscription: any;
  constructor(
    private fb:FormBuilder,
    private modalRef: BsModalRef,
    private configService: ConfigurationService,
    private sharedService:SharedService,
    private toastr:ToastrService
  ) { }

  ngOnInit(): void {
    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
    this.formInit()
    
  }

  formInit(){
   this.ExternalLabForm=this.fb.group({
    LabId:[this.labData ? this.labData.labId :0 ],
    LabCode:[this.labData ? this.labData.labCode :'',Validators.required],
    LabName:[this.labData ? this.labData.labName :'',Validators.required],
    Address:[this.labData ? this.labData.address :'',Validators.required],
    IsDisplayed:[this.labData ? this.labData.isDisplayed == 1 ? true : false :true]
   })
  }

  save(){
    console.log(this.labData)
    if(this.ExternalLabForm.invalid){
      this.isSaveClicked=true
      return
    }
    const paylaod={
      ...this.ExternalLabForm.value,
      IsDisplayed:this.ExternalLabForm.value['IsDisplayed'] ? 1 :0,
      BranchId:this.globals.HospitalId,
      IsDeleted:0
    }
    let dataid = 0;
    
      dataid =paylaod.LabId; 
    this.configService.addEditexternalLab(paylaod).subscribe({
      next:(res)=>{
        if(res.message === 'Success'){
          if (dataid == 0) {
            this.toastr.success("External Lab Added Successfully")
          }
          else{
            this.toastr.success("External Lab Edited Successfully");
            this.labData.labId = 0;
  
          }
          this.createEmitter.emit({status:true})
          this.close()
        }
        else{
          this.toastr.warning(res.message)
        }
      }
    })
  }

  close(){
    this.modalRef.hide()
  }

}
