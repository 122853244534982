import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GridApi, RowDragEvent } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Response } from '../../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { GeneralActionComponent } from '../../../client/general-action/general-action.component';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown'
import * as _ from 'lodash';

@Component({
  selector: 'app-c-form-order-modal',
  templateUrl: './c-form-order-modal.component.html',
  styleUrls: ['./c-form-order-modal.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class CFormOrderModalComponent implements OnInit {

  @Input() consultantId: number;
  @Input() branchId: number;
  @Input() userId: number;
  @Input() consultantList: any[];

  private dragStart: number = 0;
  private dragEnd: number = 0;

  public tabGridOptions = {
    pagination: false,
    rowDragEntireRow: true,
    rowDragManaged: true,
    onRowDragEnter: (event: RowDragEvent) => {
      this.dragStart = event.overIndex;
    },
    onRowDragEnd: (event: RowDragEvent) => {
     this.dragEnd = event.overIndex;
     this.onRearrangeTabOrder(event)
    }
  };
  public frameworkComponents = {
    buttonRender: GeneralActionComponent
  };
  public context = {componentParent: this};
  public viewSwitch: boolean = false;
  private tabOrderGrid: GridApi;
  public tabOrderData: any[] = [];
  public tabOrderLoading: boolean = false;
  public tabOrderColDef: ColDef[] = [
    {
      headerName: "Tab Name",
      field: "displayName",
      minWidth: 200,
      filter: true,
      flex: 1,
      editable: true,
      rowDrag: true,
    },
    {
      headerName: "Open",
      filter: false,
      resizable: false,
      minWidth:100,
      pinned:"right",
      sortable: false,
      unSortIcon: true,
      field: "id",
      flex: 2,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "consultantCform" },
    }
  ]

  public dropdownItems: any[] = [];
  
  constructor(private activeModal: BsModalRef,
              private toastr: ToastrService,
              private configurationService: ConfigurationService) { }

  ngOnInit(): void {
    _.forEach(this.consultantList, (consultant)=>{
      this.dropdownItems.push({
          label: consultant.consultantName,
          command: () => {
              this.getCformOrderData(consultant.consultantId);
          },
      })
    })
  }

  onGridReadyTabOrder(params){
    this.tabOrderGrid = params.api;
    this.getCformOrderData();
  }

  getCformOrderData(copyConsultantId?: any){
    let payload = {
      ConsultantId: copyConsultantId ? copyConsultantId : this.consultantId,
      BranchId: this.branchId
    }
    this.tabOrderGrid.showLoadingOverlay();
    this.configurationService.getCFormOrder(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.tabOrderData = response.response;
          this.viewSwitch = response.response[0].emrPageView ? true : false;
        }else{
          this.toastr.error(response.errorMessage.message);
        }
        this.tabOrderGrid.hideOverlay();
      },error: (error: any)=>{
        this.toastr.error('Failed to load CForm tab order..! Try again');
        this.tabOrderGrid.hideOverlay();
      }
    })
  }

  onRearrangeTabOrder(event){
    if(this.dragStart < this.dragEnd){
      for(let i=this.dragStart; i< this.dragEnd; i++){
        this.tabOrderData[i] = this.tabOrderData[i+1];
        this.tabOrderData[i].objectOrder--
      }
    }else{
      for(let i=this.dragStart; i> this.dragEnd; i--){
        this.tabOrderData[i] = this.tabOrderData[i-1];
        this.tabOrderData[i].objectOrder++;
      }
    }
    this.tabOrderData[this.dragEnd] = event.node.data;
    this.tabOrderData[this.dragEnd].objectOrder = this.dragEnd+1;
  }

  updateTabCformOrder(){
    let payload = {
      ConsultantId: this.consultantId,
      BranchId: this.branchId,
      UserId: this.userId,
      tsmlist: this.tabOrderData,
      EMRPageView: this.viewSwitch ? 1 : 0
    }

    this.tabOrderLoading = true;
    this.configurationService.saveCFormOrder(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == 'Success'){
          this.toastr.success('cForm orders data saved successfully.');
          this.close();
        }else{
          this.toastr.error(response.errorMessage.message);
        }
        this.tabOrderLoading = false;
      },error: (error: any)=>{
        this.toastr.error('Failed to save tab order..! Try again');
        this.tabOrderLoading = false;
      }
    })
  }

  tabOpenChange(row: any, isChecked: boolean){
    for(let i=0; i<this.tabOrderData.length; i++){
      if(this.tabOrderData[i].objectName === row.objectName){
        this.tabOrderData[i].openStatus = isChecked ? 1 : 0;
      }
    }
  }

  close(){
    this.activeModal.hide();
  }

}
