import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PAPserviceService {

  constructor(private httpClient: HttpClient) { }

  fetchPublicData(): Observable<any>{
    const url = 'http://localhost:80/PapService/publicData';
    return this.httpClient.post<any>(url, {});
  }
}
