import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { noop, Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';
import { Response } from '../../../../_Models/Response';

@Component({
  selector: 'app-choose-icd-modal',
  templateUrl: './choose-icd-modal.component.html',
  styleUrls: ['./choose-icd-modal.component.scss']
})
export class ChooseIcdModalComponent implements OnInit {
  @Input()  BranchesId
  @Input()  SelectedIcdList
  @Output() confirmEmitter=new EventEmitter()
  suggestions$?: Observable<icds[]>;
  isLoading: any;
  search?: string;
  baseUrl: any;
  


  constructor(
    private modalRef: BsModalRef,
    private http: HttpClient,
    private configurationManager: ConfigurationManager,
  ) { 
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  ngOnInit(): void {
    this.autoComplete()
  }

  autoComplete(){
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.search);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          return this.http.post<Response<icds[]>>(
            `${this.baseUrl}api/MasterData/GetICDLabel`, {
            "LabelDesc": query,
            "LabelId": 0,
            "ShowAll": 0,
            "BranchId": this.BranchesId

          }).pipe(
            map((data) => data && data.response || []),
            tap(() => noop, err => {
              // this.errorMessage = err && err.message || 'Something goes wrong';
            })
          );
        }

        return of([]);
      })
    );
     /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  confirm(){
    this.close()
    this.confirmEmitter.emit({ data:this.SelectedIcdList  });
  }

  close() {
    this.modalRef.hide();
  }

  LoadSelectedICD(item)
  {
    this.SelectedIcdList=[{
      labelId:item.labelId,
      labelDesc:item.labelDesc,
      labelCode:item.labelCode
    }]
    this.search="";
 }

 RemoveFromIcdList(){
  this.SelectedIcdList=[]
 }

}

interface icds{
  labelId:number;
  labelDesc:string;
  labelCode:string;
  catgDesc:string;
}
