import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


import { Subscription } from 'rxjs';



import { ActivatedRoute, Router } from "@angular/router";
import { ConfigurationService } from '../../../client/services/configuration.service';
import { SharedService } from '../../../../shared/shared.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { HospitalsService } from '../../../client/services/hospitals.service';
import { Consultants } from '../../../client/interfaces/Consultants';
import { WorkingDaysChildModel, WorkingDaysModel } from '../../../client/interfaces/WorkingDaysModel';
@Component({
  selector: 'app-consultantworkingdaysComponent',
  templateUrl: './consultantworkingdays.component.html',
  styleUrls: ['./consultantworkingdays.component.scss']
})
export class consultantworkingdaysComponent implements OnInit {

  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  BranchId: any;
  formmap: FormGroup;
  submitted: boolean;
  Selectedtab: string;
  BranchByDepartments: Consultants[];
  departments: Consultants[];
  consultants: Consultants[];
  workingDays: WorkingDaysModel;
  ConsultantId: any;
  GetConsultantById: Location[];
  DepartmentId: any;
  scheMidVar: any;
  isShowConsultantDetails = null;
  constructor(
    private sharedservice: SharedService,
    private toastr: ToastrService,
    private hospitalsService: HospitalsService,
    private activatedRoute: ActivatedRoute,
    private ConfigurationService: ConfigurationService,

  ) {
    this.workingDays = new WorkingDaysModel();

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
        localStorage.setItem('BranchId', this.BranchesId);
      }
      this.BranchId = localStorage.getItem('BranchId');

    });
    this.formmap = new FormGroup({
      ConsultantId: new FormControl(''),
      DepartmentId: new FormControl(''),
      ScheMid: new FormControl(''),
      SundayTimes: new FormArray([]),
      MondayTimes: new FormArray([]),
      TuesdayTimes: new FormArray([]),
      WednesdayTimes: new FormArray([]),
      ThursdayTimes: new FormArray([]),
      FridayTimes: new FormArray([]),
      SaturdayTimes: new FormArray([]),
    });
    this.Scrolltotab('Sunday');



  }


  Scrolltotab(elem) {

    this.Selectedtab = elem;
  }
  GetDepartments() {
    let showall = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showall).subscribe((res) => {
        this.departments = res.response;
      });
    // if (this.DepartmentId) {
    //   this.GetDoctors();
    // }

  }
  GetDoctors() {
    // var departmentid = Number(this.formmap.get('DepartmentId').value);
    var departmentid = this.DepartmentId;
    this.hospitalsService.GetConsultant(departmentid).subscribe(res => {
      this.consultants = res.response;
    })
  }
  Save() {
    this.workingDays.timeSchedules = [];
    var formdata = this.formmap.getRawValue();
    if (formdata.SundayTimes.length > 0) {
      formdata.SundayTimes.forEach(a => {
        if (a.fromTime != "" && a.fromTime != null && a.toTime != "" && a.toTime != null) {
          var fromAmpmArr = a.fromTime.split(' ');
          var toAmpmArr = a.toTime.split(' ');
          var fromTimeArr = fromAmpmArr[0].split(':');
          var toTimeArr = toAmpmArr[0].split(':');
          var obj = new WorkingDaysChildModel();
          obj.dayId = 1;
          obj.fromAmPm = fromAmpmArr[1];
          obj.fromHour = fromTimeArr[0];
          obj.fromMinute = fromTimeArr[1];
          obj.toAmPm = toAmpmArr[1];
          obj.toHour = toTimeArr[0];
          obj.toMinute = toTimeArr[1];
          this.workingDays.timeSchedules.push(obj);
        }
      })
    }
    if (formdata.MondayTimes.length > 0) {
      formdata.MondayTimes.forEach(a => {
        if (a.fromTime != "" && a.fromTime != null && a.toTime != "" && a.toTime != null) {
          var fromAmpmArr = a.fromTime.split(' ');
          var toAmpmArr = a.toTime.split(' ');
          var fromTimeArr = fromAmpmArr[0].split(':');
          var toTimeArr = toAmpmArr[0].split(':');
          var obj = new WorkingDaysChildModel();
          obj.dayId = 2;
          obj.fromAmPm = fromAmpmArr[1];
          obj.fromHour = fromTimeArr[0];
          obj.fromMinute = fromTimeArr[1];
          obj.toAmPm = toAmpmArr[1];
          obj.toHour = toTimeArr[0];
          obj.toMinute = toTimeArr[1];
          this.workingDays.timeSchedules.push(obj);
        }
      })
    }
    if (formdata.TuesdayTimes.length > 0) {
      formdata.TuesdayTimes.forEach(a => {
        if (a.fromTime != "" && a.fromTime != null && a.toTime != "" && a.toTime != null) {
          var fromAmpmArr = a.fromTime.split(' ');
          var toAmpmArr = a.toTime.split(' ');
          var fromTimeArr = fromAmpmArr[0].split(':');
          var toTimeArr = toAmpmArr[0].split(':');
          var obj = new WorkingDaysChildModel();
          obj.dayId = 3;
          obj.fromAmPm = fromAmpmArr[1];
          obj.fromHour = fromTimeArr[0];
          obj.fromMinute = fromTimeArr[1];
          obj.toAmPm = toAmpmArr[1];
          obj.toHour = toTimeArr[0];
          obj.toMinute = toTimeArr[1];
          this.workingDays.timeSchedules.push(obj);
        }
      })
    }
    if (formdata.WednesdayTimes.length > 0) {
      formdata.WednesdayTimes.forEach(a => {
        if (a.fromTime != "" && a.fromTime != null && a.toTime != "" && a.toTime != null) {
          var fromAmpmArr = a.fromTime.split(' ');
          var toAmpmArr = a.toTime.split(' ');
          var fromTimeArr = fromAmpmArr[0].split(':');
          var toTimeArr = toAmpmArr[0].split(':');
          var obj = new WorkingDaysChildModel();
          obj.dayId = 4;
          obj.fromAmPm = fromAmpmArr[1];
          obj.fromHour = fromTimeArr[0];
          obj.fromMinute = fromTimeArr[1];
          obj.toAmPm = toAmpmArr[1];
          obj.toHour = toTimeArr[0];
          obj.toMinute = toTimeArr[1];
          this.workingDays.timeSchedules.push(obj);
        }
      })
    }
    if (formdata.ThursdayTimes.length > 0) {
      formdata.ThursdayTimes.forEach(a => {
        if (a.fromTime != "" && a.fromTime != null && a.toTime != "" && a.toTime != null) {
          var fromAmpmArr = a.fromTime.split(' ');
          var toAmpmArr = a.toTime.split(' ');
          var fromTimeArr = fromAmpmArr[0].split(':');
          var toTimeArr = toAmpmArr[0].split(':');
          var obj = new WorkingDaysChildModel();
          obj.dayId = 5;
          obj.fromAmPm = fromAmpmArr[1];
          obj.fromHour = fromTimeArr[0];
          obj.fromMinute = fromTimeArr[1];
          obj.toAmPm = toAmpmArr[1];
          obj.toHour = toTimeArr[0];
          obj.toMinute = toTimeArr[1];
          this.workingDays.timeSchedules.push(obj);
        }
      })
    }
    if (formdata.FridayTimes.length > 0) {
      formdata.FridayTimes.forEach(a => {
        if (a.fromTime != "" && a.fromTime != null && a.toTime != "" && a.toTime != null) {
          var fromAmpmArr = a.fromTime.split(' ');
          var toAmpmArr = a.toTime.split(' ');
          var fromTimeArr = fromAmpmArr[0].split(':');
          var toTimeArr = toAmpmArr[0].split(':');
          var obj = new WorkingDaysChildModel();
          obj.dayId = 6;
          obj.fromAmPm = fromAmpmArr[1];
          obj.fromHour = fromTimeArr[0];
          obj.fromMinute = fromTimeArr[1];
          obj.toAmPm = toAmpmArr[1];
          obj.toHour = toTimeArr[0];
          obj.toMinute = toTimeArr[1];
          this.workingDays.timeSchedules.push(obj);
        }
      })
    }
    if (formdata.SaturdayTimes.length > 0) {
      formdata.SaturdayTimes.forEach(a => {
        if (a.fromTime != "" && a.fromTime != null && a.toTime != "" && a.toTime != null) {
          var fromAmpmArr = a.fromTime.split(' ');
          var toAmpmArr = a.toTime.split(' ');
          var fromTimeArr = fromAmpmArr[0].split(':');
          var toTimeArr = toAmpmArr[0].split(':');
          var obj = new WorkingDaysChildModel();
          obj.dayId = 7;
          obj.fromAmPm = fromAmpmArr[1];
          obj.fromHour = fromTimeArr[0];
          obj.fromMinute = fromTimeArr[1];
          obj.toAmPm = toAmpmArr[1];
          obj.toHour = toTimeArr[0];
          obj.toMinute = toTimeArr[1];
          this.workingDays.timeSchedules.push(obj);
        }
      })
    }
    this.workingDays.branchId = this.globals.HospitalId;
    this.workingDays.userId = Number(localStorage.getItem('user_id'));
    this.workingDays.consultantId = Number(formdata.ConsultantId);
    this.workingDays.scheMid = Number(this.scheMidVar);
    // if(this.workingDays.consultantId==0){
    //   this.toastr.warning("Please Select Consultant Name");
    // }
    this.hospitalsService.InsertConsultantSchedules(this.workingDays).subscribe(res => {
      if (res.message == 'Success') {
        this.toastr.success("Consultant days saved");
      }
      else {
        this.toastr.error("Failed : " + res.message);
      }

    });
  }
  ngOnInit(): void {

    this.activatedRoute.params.subscribe((params) => {
      this.ConsultantId = Number(params.id);
    });
    if (this.ConsultantId) {
      this.GetFetchingDetails();
    }


  }
  public addMapFormGroup(Day: any, data: any[] = [], saved = 0) {
    if (data.length > 0) {
      data.forEach((element, i) => {
        const dayTime = this.formmap.get(Day) as FormArray;
        dayTime.push(this.createListGroup(element, saved));
      });
    } else {
      const dayTime = this.formmap.get(Day) as FormArray;
      dayTime.push(this.createListGroup());
    }
  }
  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    return new FormGroup({
      fromTime: new FormControl(data['fromTime']),
      toTime: new FormControl(data['toTime']),

    });
  }

  ClearForm() {

    this.formmap.reset({

    });
    this.formmap.clearValidators();

  }




  RemoveRow(index, type) {
    var ValveTypes = new FormArray([]);

    ValveTypes = this.formmap.get(type) as FormArray;

    ValveTypes.removeAt(index);

  }

  clearchild() {
    let sunday = this.formmap.get('SundayTimes') as FormArray;
    let monday = this.formmap.get('MondayTimes') as FormArray;
    let tuesday = this.formmap.get('TuesdayTimes') as FormArray;
    let wednesday = this.formmap.get('WednesdayTimes') as FormArray;
    let friday = this.formmap.get('FridayTimes') as FormArray;
    let thursday = this.formmap.get('ThursdayTimes') as FormArray;
    let saturday = this.formmap.get('SaturdayTimes') as FormArray;
    sunday.clear();
    monday.clear();
    tuesday.clear();
    wednesday.clear();
    thursday.clear();
    friday.clear();
    saturday.clear();
  }
  patchchild() {
    var sundayarray = this.workingDays.timeSchedules.filter(a => a.dayId === 1);
    var mondayarray = this.workingDays.timeSchedules.filter(a => a.dayId === 2);
    var tuesdayarray = this.workingDays.timeSchedules.filter(a => a.dayId === 3);
    var wednesdayarray = this.workingDays.timeSchedules.filter(a => a.dayId === 4);
    var thursdayarray = this.workingDays.timeSchedules.filter(a => a.dayId === 5);
    var fridayarray = this.workingDays.timeSchedules.filter(a => a.dayId === 6);
    var saturdayarray = this.workingDays.timeSchedules.filter(a => a.dayId === 7);
    if (sundayarray.length > 0) {
      this.addMapFormGroup('SundayTimes', sundayarray);
    }
    if (mondayarray.length > 0) {
      this.addMapFormGroup('MondayTimes', mondayarray);
    }
    if (tuesdayarray.length > 0) {

      this.addMapFormGroup('TuesdayTimes', tuesdayarray);
    }
    if (wednesdayarray.length > 0) {
      this.addMapFormGroup('WednesdayTimes', wednesdayarray);
    }
    if (thursdayarray.length > 0) {
      this.addMapFormGroup('ThursdayTimes', thursdayarray);
    }
    if (fridayarray.length > 0) {
      this.addMapFormGroup('FridayTimes', fridayarray);
    }
    if (saturdayarray.length > 0) {
      this.addMapFormGroup('SaturdayTimes', saturdayarray);
    }







  }
  GetFetchingDetails() {
    this.GetDepartments();
    this.ConfigurationService.GetConsultantById(this.ConsultantId).subscribe((res) => {
      this.GetConsultantById = res.response;
      this.isShowConsultantDetails = true;
      this.DepartmentId = this.GetConsultantById[0]['deptId'],
      this.GetDoctors()

        this.formmap.patchValue({
          DepartmentId: this.GetConsultantById[0]['deptId'],
        });
      this.formmap.patchValue({
        ConsultantId: this.GetConsultantById[0]['consultantId'],
      });

    });
    this.clearchild();
    var DoctorId = Number(this.formmap.get('ConsultantId').value);

    if (this.ConsultantId > 0) {
      this.workingDays.branchId = this.globals.HospitalId;
      this.workingDays.consultantId = this.ConsultantId;
      this.hospitalsService.GetConsultantDays(this.workingDays).subscribe(res => {
        this.workingDays = res.response;
        this.scheMidVar = this.workingDays.scheMid;
        this.workingDays.timeSchedules.forEach(a => {
          a.fromTime = "";
          a.toTime = "";
          a.fromTime = a.fromTime.concat(a.fromHour.toString(), ":", a.fromMinute.toString(), " ", a.fromAmPm);
          a.toTime = a.toTime.concat(a.toHour.toString(), ":", a.toMinute.toString(), " ", a.toAmPm);
        });
        this.patchchild();

      });

      // if (this.workingDays.timeSchedules != null) {
        
      // }

    }
    else {
      this.addMapFormGroup('SundayTimes');
    }



  }

  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);
  }



}
