import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../client/general-action/general-action.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ResultAddParameterComponent } from '../result-addParameter/result-addParameter.component';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../shared/shared.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-result-form',
  templateUrl: './assign-resultform.component.html',
  styleUrls: ['./assign-resultform.component.scss']
})
export class AssignREsultFormComponent implements OnInit {

  @Input() itemName: ""
  @Input() userId: 0
  @Input() locationId: 0
  @Input() itemid: 0
  @Output() resultEmitter = new EventEmitter()
  @Input() rformId: 0
  @Output() getitemEmitter = new EventEmitter()

  @ViewChild("template") modaltemp: any;
  ParameterList:any;
  resultForm: FormGroup;
  result: any;
  public isSaved: boolean = false;

  public gridApi;
  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];
  parameterColumnDefs: ColDef[] = [];
  AddparameterColumnDefs: ColDef[] = [];
  subscription: Subscription;
  public submitted: boolean = false;
  rId: any
  resultFormFata: any;
  parameterData: any;
  rowGroupPanelShow = "always";
  public context;
  public statusBar;
  public parametergridOptions = {
    pagination: true,
    paginationPageSize: 5,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,

  }
  RformId: any;
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  addParameterPopupHeading: String = "Add Parameter"
  isLoading = false;
  public user_id: string;
  globals: globalvars = new globalvars();
  BranchesId: any;

  constructor(private modalRef: BsModalRef,
    private modalService: BsModalService,
    private sharedservice: SharedService,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,

  ) {
    this.user_id = localStorage.getItem('user_id');

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {
    this.resultForm = new FormGroup({
      sItemName: new FormControl(this.itemName),
      RformId: new FormControl(0),
      ParameterList: new FormControl([]),

    });


    this.context = { componentParent: this };

    this.parameterColumnDefs = [
      {
        headerName: "Select",
        field: "",
        resizable: true,
        filter: true,
        sortable: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        flex: 2,

      },
      {
        headerName: "Parameter",
        field: "paramName",
        resizable: false,
        sortable: true,
        filter: true,
        minWidth: 200,
        enableRowGroup: true,
        flex: 2,
      },
    ];




    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.getResultForm();
    this.getResultFome();
  }
  onFilterTextBoxChanged(event) {
    this.gridApi.setQuickFilter(
      event.target.value
    );
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

  close() {
    this.modalRef.hide();
  }
  getResultForm() {
    let input = {
      "RformId": 0,
      "BranchId": this.BranchesId,

    }
    this.configurationService.getResultForm(input).subscribe((res) => {
      this.resultFormFata = res.response;
      // this.RformId=this.resultFormFata.RformId;
      // console.log(this.RformId);

    });

  }
  getParameterList() {
    let input = {
      RformId: Number(this.resultForm.get('RformId').value)
    }
    this.configurationService.GetParameterList(input).subscribe((res) => {
      this.parameterData = res.response;
    });

  }
  save() {
    this.submitted = true;
    this.isSaved == false

    if ( this.submitted = true && this.resultForm && this.resultForm.valid &&  this.isSaved == false) {
      var formdata = this.resultForm.getRawValue();
      this.ParameterList=this.gridApi.getSelectedRows().map(items => {
        return items.paramId
      })
      let input = {
        ItemId: this.itemid,
        UserId: Number(this.userId),
        LocationId: this.locationId,
        RformId: Number(this.resultForm.get('RformId').value),
        SessionId: 0,
        ParameterList: this.ParameterList
      }
      if(this.ParameterList.length==0){
        this.toastr.warning("Please select parameter");

      }
      else{
        this.isLoading = true;

         this.configurationService.saveAssignResult(input).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == 'Success') {
          this.isLoading = false;
          this.isSaved = true;

          this.toastr.success("Assign result form added Successfully");
          this.getitemEmitter.emit({ status: true });

          this.close();
        }
        else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }

    }
  }
  getResultFome() {
    let input = {
      "ItemId": this.itemid,
      "RformId": this.rformId
    }

    this.configurationService.GetAssignResult(input).subscribe((res) => {
      this.result = res;
      this.parameterData=res.response
       
    })
    this.resultForm.controls['RformId'].setValue(this.rformId);
   
    
  }
  onFirstDataRendered(params: any) {
    params.api.forEachNode((node) =>
      node.setSelected(!!node.data && node.data.paramSelect)
    );
  }

}