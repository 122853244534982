import { Component,TemplateRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ColDef, GridApi, RowDragEvent } from 'ag-grid-community';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { Response } from '../../../_Models/Response';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { ConfigurationService } from '../../client/services/configuration.service';

@Component({
  selector: 'app-savingscheme',
  templateUrl: './savingscheme.component.html',
  styleUrls: ['./savingscheme.component.scss']
})
export class SavingschemeComponent implements OnInit {

  globals: globalvars = new globalvars();
  subscription: Subscription;

  savingForm: FormGroup;
  tabschemeForm: FormGroup;
  savingschemeForm: FormGroup;
  public submitted: boolean = false;
  savingstab: boolean = true;
  tabscheme: boolean;
  GetGroupItems: any;

  public pageSections: any[] = [];
  public savingSchemeData: any[] = [];
  public fetchSavingScheme: boolean = false;
  public savingSchemeLoading: boolean = false;
  public tabSchemeData: any[] = [];
  public fetchTabScheme: boolean = false;
  public tabSchemeLoading: boolean = false;

  public rowDragEntireRow = true;

  public savingSchemecolumnDefs : ColDef[] = [];

  public savingSchemePage: string = '';
  public tabSchemePage: string = '';
  
  public rowSelection = true;
  public context;
  public noRowsTemplate = '<span class="ag-overlay-loading-center">No data to show / Select a page from dropdown..</span>';
  public loadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';
  public frameworkComponents;

  public gridApiSaving: GridApi;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  private dragStart: number = 0;
  private dragEnd: number = 0;

  public tabGridOptions = {
    pagination: false,
    rowDragEntireRow: true,
    rowDragManaged: true,
    onRowDragEnter: (event: RowDragEvent) => {
      this.dragStart = event.overIndex;
    },
    onRowDragEnd: (event: RowDragEvent) => {
     this.dragEnd = event.overIndex;
     this.onRearrangeTabScheme(event)
    }
  };

  public gridApiTab: GridApi;
  public tabSchemecolumnDefs : ColDef[] = [];

  TabTitileName:String="Add Tab";
  modalRefTab: BsModalRef;
  @ViewChild("templateTab") modaltempTab: any;
  functionPermissionData: any;

  constructor(
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private sharedservice: SharedService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
        this.getPageSections();
      }
    });

    this.savingSchemecolumnDefs = [
      {
        headerName: "Property Caption",
        field: "propertyCaption",
        minWidth:200,
        flex:2,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        filter: true,
        editable: false,
      },
      
      {
        headerName: "Action",
        filter: false,
        resizable: false,
        minWidth:100,
        pinned:"right",
        sortable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "savingScheme" },
      },
    ]

    this.tabSchemecolumnDefs = [
      {
        headerName: "Property Caption",
        field: "objectName",
        minWidth:200,
        filter: true,
        flex:1,
        rowDrag: true,
      },
    ]

    this.context = { componentParent: this };


    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    this.functionPermission();
  }

  get f() {
    return this.savingForm.controls;
  }

  onRearrangeTabScheme(event){
    if(this.dragStart < this.dragEnd){
      for(let i=this.dragStart; i< this.dragEnd; i++){
        this.tabSchemeData[i] = this.tabSchemeData[i+1];
        this.tabSchemeData[i].objectOrder--
      }
    }else{
      for(let i=this.dragStart; i> this.dragEnd; i--){
        this.tabSchemeData[i] = this.tabSchemeData[i-1];
        this.tabSchemeData[i].objectOrder++;
      }
    }
    this.tabSchemeData[this.dragEnd] = event.node.data;
    this.tabSchemeData[this.dragEnd].objectOrder = this.dragEnd+1
  }

  getPageSections(){
    this.configurationService.getPageSectionsForSavingScheme({BranchId: this.globals.HospitalId}).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 ){
          this.pageSections = response.response;
        }else{
          this.toastrService.error('Failed:'+ response.message)
        }
      },error: (error: any)=>{

      }
    })
  }


  onTab(number) {
    this.savingstab = false;
    this.tabscheme = false;
   

    if (number == "1") {
      this.savingstab = true;
    } else if (number == "2") {
      this.tabscheme = true;
    } 
   
    
  }

  openModalTab(template: TemplateRef<any>) {
    this.TabTitileName="Add Symptom";
    this.modalRefTab = this.modalService.show(template);
  }
  
  LoadGroupItems(data){

  }

  onGridReadySavingScheme(params) {
    this.gridApiSaving = params.api;
  }

  getSavingSchemeData(event: any){
    if(event.target.value != ''){
      const payload = {
        FormName:event.target.value,
        BranchId:this.globals.HospitalId
      }
      this.fetchSavingScheme = true;
      this.gridApiSaving.showLoadingOverlay();
      this.configurationService.getSavingSchemeData(payload).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200 ){
            this.fetchSavingScheme = false;
            this.gridApiSaving.hideOverlay();
            this.savingSchemeData = response.response;
            if(this.savingSchemeData.length <= 0){
              this.gridApiSaving.showNoRowsOverlay();
            }
          }else{
            this.fetchSavingScheme = false;
            this.gridApiSaving.hideOverlay();
            this.gridApiSaving.showNoRowsOverlay();
            this.toastrService.error('Failed:'+ response.message);
          }
        },error: (error: any)=>{
          this.fetchSavingScheme = false;
          this.gridApiSaving.hideOverlay();
          this.gridApiSaving.showNoRowsOverlay();
          this.toastrService.error('Failed:'+ 'Something wents wrong, Try agian.')
        }
      })
    }else{
      this.gridApiSaving.hideOverlay();
      this.gridApiSaving.showNoRowsOverlay();
      this.savingSchemeData = [];
    }  
  }

  savingSchemeChange(row: any, isChecked: boolean){
    for(let i=0; i<this.savingSchemeData.length; i++){
      if(this.savingSchemeData[i].propertyName === row.propertyName){
        this.savingSchemeData[i].schemeValue = isChecked;
      }
    }
  }

  updateSavingSheme(){
    const foundElement = this.functionPermissionData.find(x => x.id === 154)
    if (foundElement.id == 154 && foundElement.isAllowed == true) {
    const payload = {
      FormName: this.savingSchemePage,
      BranchId: this.globals.HospitalId,
      UserId:Number(localStorage.getItem("user_id")),
      Ssmlist: this.savingSchemeData
    }

    this.savingSchemeLoading = true;
    this.configurationService.UpdateSavingScheme(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.savingSchemeLoading = false;
          this.toastrService.success('Success:'+ 'Saving Scheme details updated successfully.')
        }else{
          this.toastrService.error('Failed:'+ response.message)
        }
        this.savingSchemeLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Error', 'Something wents wrong, Try again.');
        this.savingSchemeLoading = false;
      }
    })
    } else {
      this.toastrService.warning("You are not allowed to save data");
    }
  }

  //Tab scheme operations//

  getTabSchemeData(event: any){
    if(event.target.value != ''){
      const payload = {
        ScreenName:event.target.value,
        BranchId:this.globals.HospitalId
      }
      this.fetchTabScheme = true;
      this.gridApiTab.showLoadingOverlay();
      this.configurationService.getTabSchemeData(payload).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200 ){
            this.fetchTabScheme = false;
            this.gridApiTab.hideOverlay();
            this.tabSchemeData = response.response;
            if(this.tabSchemeData.length <= 0){
              this.gridApiTab.showNoRowsOverlay();
            }
          }else{
            this.fetchTabScheme = false;
            this.gridApiTab.hideOverlay();
            this.gridApiTab.showNoRowsOverlay();
            this.toastrService.error('Failed:'+ response.message);
          }
        },error: (error: any)=>{
          this.fetchTabScheme = false;
          this.gridApiTab.hideOverlay();
          this.gridApiTab.showNoRowsOverlay();
          this.toastrService.error('Failed:'+ 'Something wents wrong, Try agian.')
        }
      })
    }else{
      this.gridApiTab.hideOverlay();
      this.gridApiTab.showNoRowsOverlay();
      this.tabSchemeData = [];
    }  
  }

  tabSchemeChange(row: any, isChecked: boolean){
    for(let i=0; i<this.tabSchemeData.length; i++){
      if(this.tabSchemeData[i].propertyName === row.propertyName){
        this.tabSchemeData[i].schemeValue = isChecked;
      }
    }
  }

  updateTabSheme(){
    const foundElement = this.functionPermissionData.find(x => x.id === 155)
    if (foundElement.id == 155 && foundElement.isAllowed == true) {
    const payload = {
      ScreenName: this.tabSchemePage,
      BranchId: this.globals.HospitalId,
      UserId:Number(localStorage.getItem("user_id")),
      tsmlist: this.tabSchemeData
    }

    this.tabSchemeLoading = true;
    this.configurationService.UpdateTabScheme(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.tabSchemeLoading = false;
          this.toastrService.success('Success:'+ 'Tab Scheme details updated successfully.')
        }else{
          this.toastrService.error('Failed:'+ response.message)
        }
        this.tabSchemeLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Error', 'Something wents wrong, Try again.');
        this.tabSchemeLoading = false;
      }
    })
  } else {
    this.toastrService.warning("You are not allowed to save data");
  }
  }

  onGridReadyTabScheme(params) {
    this.gridApiTab = params.api;
  }

  onFilterTextBoxChangedSaving(event){
    this.gridApiSaving.setQuickFilter(
      event.target.value
    );
  }

  onFilterTextBoxChangedTabbing(event){
    this.gridApiTab.setQuickFilter(
      event.target.value
    );
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }
}
