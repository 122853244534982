import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../client/services/configuration.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-CPTModifier',
  templateUrl: './cptmodifier.component.html',
  styleUrls: ['./cptmodifier.component.scss']
})
export class CPTModifierComponent implements OnInit {

  public submitted: boolean = false;

  result: any;
  professionId: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  deleteprofession: boolean;
  oppid: any;
  public statusBar;
  rowGroupPanelShow = "always";
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.active == '0'; },
      'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    },

  }
  rowData: any;
  cpctcode: any;
  unlistedCode: any;
  CPTModifier: FormGroup;
  CptCodeId: any;
  rateGroup: any;
  CPTModifiers: any;
  ModifierId: any;
  modalRef: BsModalRef;
  isLoading=false;
  CPTModifierPopupHeading:String="Add CPT Modifier";
  @ViewChild("template") modaltemp: any;
  functionPermissionData: any;

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private modalService: BsModalService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {
    this.CPTModifier = this.formBuilder.group({
      CPTValue: new FormControl('', [Validators.required]),
      cptdesc: new FormControl('', [Validators.required]),
      IsDisplayedGroup: new FormControl(true)
    });

    this.GetAllCPTModifier();
    this.functionPermission();

    this.columnDefs = [

      {
        headerName: "CPT Modifier",
        field: "cptModifier",
        minWidth:150,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,

      },

      {
        headerName: "Description",
        field: "cptDescription",
        minWidth:150,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,

      },
      {
        headerName: "Action",
        filter: false,
        resizable: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "CptModifierList" },
      },

    ]


    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };





    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };



    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

  }


  showModalDialog(orderId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 102)
    if (foundElement.id == 102 && foundElement.isAllowed == true) {
    this.CPTModifierPopupHeading="Edit CPT Modifier";
    this.modalRef = this.modalService.show(
      this.modaltemp,
      // Object.assign({}, { class: "gray modal-lg" })
    );

    this.ModifierId = orderId.id
    this.CPTModifier.patchValue({
      CPTValue: orderId.cptModifier,
      cptdesc: orderId.cptDescription,
    });
    if(orderId.isDisplayed==1){
      let displayed=true;
       this.CPTModifier.patchValue({
         IsDisplayedGroup:displayed
       });
     }
     else{
       let displayed=false;
       this.CPTModifier.patchValue({
         IsDisplayedGroup:displayed
       });
     }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }


  // for geting all form items

  get f() {
    return this.CPTModifier.controls;
  }


  //pagetitle to be displayed at the header
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);
  // }

  // to clear form
  clearForm() {
    this.CPTModifier.reset({
      CPTValue: '',
      cptdesc: '',
      IsDisplayedGroup: true
    });
    this.submitted = false;
  }


  SaveCptModifier() {
    this.submitted = true;
    if (this.CPTModifier && this.CPTModifier.valid) {
      var AppData = {
        CPTValue: this.CPTModifier.get("CPTValue").value,
        cptdesc: this.CPTModifier.get("cptdesc").value,
        IsDisplayedGroup: this.CPTModifier.get("IsDisplayedGroup").value,
      }
      // if (AppData.IsDisplayedGroup == true) {
      //   AppData.IsDisplayedGroup = 1;
      // }
      // else {
      //   AppData.IsDisplayedGroup = 0;
      // }

      let Modifierdata = 0;
      if (this.ModifierId > 0) {
        Modifierdata = this.ModifierId;
      }
      this.isLoading=true;

      this.ConfigurationService.InsertUpdateCPTModifier(AppData, this.globals.HospitalId, Modifierdata,).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == 'Success') {
          if (Modifierdata == 0) {
            this.isLoading=false
            this.toastr.success("CPT Modifier added successfully");
          }
          else {
            this.isLoading=false
            this.toastr.success("CPT Modifier Updated successfully");
          }
          this.modalRef.hide();
          this.GetAllCPTModifier();
          this.clearForm();
        }
        else {
          this.isLoading=false
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }


  }


  GetAllCPTModifier() {

    this.ConfigurationService.GetCPTModifier(this.globals.HospitalId).subscribe((res) => {
      this.CPTModifiers = res.response;

    });
  }

  DeleteCPTModifier(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 103)
    if (foundElement.id == 103 && foundElement.isAllowed == true) {
    this.ModifierId = id.id;
    this.ConfigurationService.DeleteCPTModifier(this.ModifierId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {

        this.toastr.success("Modifier deleted successfully");
        this.GetAllCPTModifier();
        this.clearForm();
        this.ModifierId=0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
      }

    },
    );
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }

  }


  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }


  openModalCPTmodifier(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 101)
    if (foundElement.id == 101 && foundElement.isAllowed == true) {
    this.CPTModifierPopupHeading="Add CPT Modifier";
    this.modalRef = this.modalService.show(template);
    this.ModifierId=0;
    this.clearForm();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }

  ResetFunction(){
    this.ModifierId=0;
    this.clearForm();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }
}
