import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { StaffList } from '../../client/interfaces/StaffList';
import { globalvars } from '../../../shared/models/GlobalVars';
import { DialogModule } from 'primeng/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../../shared/shared.service';
import { ConfigurationService } from "../../client/services/configuration.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import SignaturePad from "signature_pad";
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { log } from 'console';
import { ShowQrcodeModalComponent } from './show-qrcode-modal/show-qrcode-modal.component';
import { UserpermissionService } from '../../user-permission/userpermission.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  @ViewChild("template") modaltemp: any;
  @ViewChild('canvas') canvasEl: ElementRef;
  // @ViewChild("canvas", { static: true }) canvasEl: ElementRef;

  signaturePad: SignaturePad;


  signature:any;
  rowData = [];
  defaultColDef: ColDef[] = [];
  rowGroupPanelShow = "always";
  public context;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  staffCategory: any;
  subscription: Subscription;
  public noRowsTemplate;
  public frameworkComponents;
  public gridApi;
  public loadingTemplate = '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';
  display: boolean = false;
  maxDate: Date;
  staffForm: FormGroup;
  public submitted: boolean = false;
  result: any;
  Staffs: StaffList[] = [];
  globals: globalvars = new globalvars();
  staffId: any;
  isLoading: boolean = false;
  staffPopupHeading = "Add Staff";
  modalRef: BsModalRef;
  documentFile2: File;

  public usersList: any[] = [];
  functionPermissionData: any;

  constructor(
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private datepipe: DatePipe,
    private userService: UserpermissionService,
    private modalService: BsModalService,

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });
  }

  ngOnInit(): void {
    this.context = { componentParent: this };
    this.staffForm = new FormGroup({
      staffId: new FormControl(0),
      staffCode: new FormControl('', [Validators.required]),
      licenceNo: new FormControl(""),
      categoryId: new FormControl(0),
      firstName: new FormControl('', [Validators.required]),
      middleName: new FormControl(""),
      lastName: new FormControl(""),
      gender: new FormControl(""),
      dob: new FormControl(""),
      designation: new FormControl(""),
      mobile: new FormControl(""),
      dhaNo: new FormControl(""),
      isDisplayed: new FormControl(true),
      isDeleting: new FormControl(0),
      branchid: new FormControl(this.globals.HospitalId),
      userId: new FormControl(0),
      StaffUserId: new FormControl('')
    });
    this.GetUsers();
    this.GetAllStaff();
    this.GetCategoryStaff()
    this.functionPermission();
    this.defaultColDef = [
      {
        headerName: "Staff Code",
        field: "staffCode",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Licence No ",
        field: "licenceNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 110,
        filter: true,
      },
      {
        headerName: "Category",
        field: "category",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "First Name",
        field: "firstName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 150,
        filter: true,
      },
      {
        headerName: "Middle Name",
        field: "middleName",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        minWidth: 120,
        filter: true,
      },
      {
        headerName: "Last Name",
        field: "lastName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Gender",
        field: "gender",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 80,
        filter: true,
      },
      {
        headerName: "D.O.B",
        field: "dob",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Designation",
        field: "designation",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 110,
        filter: true,
      },
      {
        headerName: "Mob No",
        field: "mobile",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 120,
        filter: true,
      },
      {
        headerName: "Registration No.",
        field: "dhaNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 120,
        filter: true,
      },
      {
        headerName: "Action",
        field: "action",
        enableRowGroup: true,
        resizable: false,
        sortable: false,
        flex: 2,
        maxWidth: 80,
        pinned: "right",
        filter: false,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "staffList" },

      },
    ];
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
      onGridReady: (event) => event.api.sizeColumnsToFit()

    };
    this.maxDate = new Date();
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }

  get f() {
    return this.staffForm.controls;
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  GetUsers() {
    console.log('ok')
    this.userService.GetUsers(this.globals.HospitalId).subscribe((res) => {
      this.usersList = res.response;
    });
  }

  showDialog() {
    const foundElement = this.functionPermissionData.find(x => x.id === 140)
    if (foundElement.id == 140 && foundElement.isAllowed == true) {
    this.signature='';
    this.staffPopupHeading = "Add Staff";
    // this.modalRef = this.modalService.show(template, { class: "gray modal-lg" });
    this.display = true;

    this.clearForm();
    this.display = true;
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  saveStaff() {
    this.submitted = true;
    if (this.staffForm && this.staffForm.valid) {
      var formdata = this.staffForm.getRawValue();
      formdata.userId = Number(localStorage.getItem('user_id'));
      formdata.branchid = Number(this.globals.HospitalId);
      formdata.categoryId = Number(formdata.categoryId)
      if(formdata.StaffUserId == "" || formdata.StaffUserId == null){
        formdata.StaffUserId = 0;
      }
      let dob = formdata.dob;
      if (dob) {
        if (typeof (dob) == "object") {
          let dobConverted = this.datepipe.transform(dob, 'dd-MM-yyyy');
          formdata.dob = dobConverted;
        }
        else {
          formdata.dob = dob.replaceAll('/', '-');
        }
      }
      const signName = "sign.jpeg";

      let signaturedata = this.signaturePad.toDataURL();
      let signsplit = signaturedata.split(';');

      const signBlob = this.dataURItoBlob(signsplit[1].split(',')[1]);

      const signFile = new File([signBlob], signName, { type: 'image/jpeg' });

      this.documentFile2 = signFile;
      const formData2 = new FormData();

      formData2.append('staffSignature', this.documentFile2, this.documentFile2.name);
      formData2.append('staffJson', JSON.stringify(formdata));

      let dataid = 0;
      dataid = formdata.staffId
      this.isLoading = true;
      this.configurationService.SaveStaff(formData2).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == 'Success') {
          if (dataid == 0) {
            this.isLoading = false;
            this.toastr.success("Staff added Successfully");
          }
          else {
            this.isLoading = false;
            this.toastr.success("Staff Edited Successfully");
            this.staffId = 0;
          }
          this.display = false;
          this.GetAllStaff();
          // this.modalRef.hide();
          this.clearPad();
          this.clearForm();
        }
        else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }
  }

  GetAllStaff() {
    this.configurationService.GetStaff(this.globals.HospitalId).subscribe((res) => {
      this.Staffs = res.response;
      if (this.Staffs.length == 0) {
        this.toastr.warning("No Staff found");
      }
    });
  }
  GetCategoryStaff() {
    this.configurationService.GetcategoryStaff().subscribe((res) => {
      this.staffCategory = res.response;
    });
  }

  Edit(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 141)
    if (foundElement.id == 141 && foundElement.isAllowed == true) {
    this.display = true;
    this.staffPopupHeading = "Edit Staff";
    // this.modalRef = this.modalService.show(
    //   this.modaltemp,{ class: "gray modal-lg" }
    // );

    this.staffForm.patchValue(data);
    this.staffForm.controls['staffId'].setValue(data.staffId);
    this.staffForm.controls['StaffUserId'].setValue(data.staffUserId);

      this.signature=data.signatureLoc;
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }

  }
  Delete(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 142)
    if (foundElement.id == 142 && foundElement.isAllowed == true) {
      var form = this.staffForm.getRawValue();
      form.staffId = data.staffId;
      form.isDeleting = 1;
      form.staffCode='',
      form.licenceNo='',
      form.categoryId=0,
      form.firstName='',
      form.middleName= '',
      form.lastName= '',
      form.gender= '',
      form.dob='',
      form.designation= '',
      form. mobile= '',
      form.dhaNo= '',
      form.isDisplayed=true,
      form.branchid=0,
      form.userId= 0
      form.staffSignature=""
  
      // formdata.staffSignature=null
      // const signName = "sign.jpeg";

      // let signaturedata = this.signaturePad.toDataURL();
      // let signsplit = signaturedata.split(';');

      // const signBlob = this.dataURItoBlob(signsplit[1].split(',')[1]);

      // const signFile = new File([signBlob], signName, { type: 'image/jpeg' });

      // this.documentFile2 = signFile;
      const formData2 = new FormData();

      // formData2.append('staffSignature', null, '');
      formData2.append('staffJson', JSON.stringify(form));

      this.configurationService.SaveStaff(formData2).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Staff deleted successfully");
          this.GetAllStaff();
          this.clearForm();
          this.staffId = 0;
        }
        else {
          this.toastr.error("Failure: " + this.result.message);
          this.display = false;
        }
  
      },
      );
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
      
  }

  Deletse(data) {
    let form = this.staffForm.getRawValue();
    form.staffId = data.staffId;
    form.isDeleting = 1;
    form.staffCode='',
    form.licenceNo='',
    form.categoryId=0,
    form.firstName='',
    form.middleName= '',
    form.lastName= '',
    form.gender= '',
    form.dob='',
    form.designation= '',
    form. mobile= '',
    form.dhaNo= '',
    form.isDisplayed=true,
    form.branchid=0,
    form.userId= 0
    form.staffSignature=""
    this.configurationService.SaveStaff(form).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Staff deleted successfully");
        this.GetAllStaff();
        this.clearForm();
        this.staffId = 0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
        this.display = false;
      }

    },
    );
  }


  clearForm() {
    this.staffForm.reset({
      staffId: 0,
      staffCode: '',
      licenceNo: '',
      categoryId: 0,
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      dob: '',
      designation: '',
      mobile: '',
      dhaNo: '',
      isDisplayed: true,
      isDeleting: 0,
      branchid: this.globals.HospitalId,
      userId: 0
    })
  }
  close() {
    this.submitted = false
  }

  ResetFunction() {
    this.staffId = 0;
    this.submitted = false;
    this.clearForm();
  }
  startDrawing(event: Event) {
    // works in device not in browser
  }
  moved(event: Event) {
    // works in device not in browser
  }
  clearPad() {
    this.signaturePad.clear();
  }
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    // this.sig = new SignaturePad(this.canvas.nativeElement);

  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  onShowQrCode(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 143)
    if (foundElement.id == 143 && foundElement.isAllowed == true) {
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      staffData: data
    }
    const modalRef = this.modalService.show(ShowQrcodeModalComponent, Object.assign({}, config,  { class: 'modal-sm', initialState }));
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

}
