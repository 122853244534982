import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PAPserviceService } from '../../../services/papservice.service';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';

declare function Initialize(): any;
declare function getInitStatus(): any;
declare function getProgressMessage(): any;
declare function getErrorMessage(): any;

declare function DisplayPublicData(): any;
declare function getPublicDataObject() : any;
declare function getPublicDataStatus(): any;

declare function closeConnection();

@Component({
  selector: 'app-fetch-emirates-card-modal',
  templateUrl: './fetch-emirates-card-modal.component.html',
  styleUrls: ['./fetch-emirates-card-modal.component.scss']
})
export class FetchEmiratesCardModalComponent implements OnInit, OnDestroy, AfterViewInit {

  public readLoading: boolean = false;
  public publicData: any;

  @Output() emirateCardDataEmitter = new EventEmitter();

  constructor(private activeModal: BsModalRef,
              private papService: PAPserviceService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
  }

  readCardDataFromReader(){
    this.readLoading = true;
    DisplayPublicData();
    setTimeout(() => {
      let response = getPublicDataObject();
      console.log('card response', response);
      if(response && response?.status == 'success'){
        this.publicData = response;
        // console.clear();
      }
      this.readLoading = false;
    }, 5000);
    // this.papService.fetchPublicData().subscribe({
    //   next: (response: any)=>{
    //     if(response?.PublicData){
    //       this.publicData = response.publicData;
    //     }else{
    //       this.toastrService.error(response?.Description)
    //     }
    //     this.readLoading = false;
    //   },error: (error: any)=>{
    //     this.toastrService.error('Something wents wrong..! Try again.');
    //     this.readLoading = false;
    //   }
    // })
  }

  ngAfterViewInit(): void {
    this.initSDKService();
  }

  onClearCLicked(){
    this.publicData = null;
  }

  onAcceptClicked(){
    this.emirateCardDataEmitter.emit({
      status: true,
      cardData: this.publicData
    });
    this.close();
  }

  close(){
    this.activeModal.hide();
  }

  initSDKService(){
    Initialize();
  }

  get initStatus(){
    return getInitStatus();
  }

  get progressMessage(){
    return getProgressMessage();
  }

  get errorMessage(){
    return getErrorMessage();
  }

  get dataLoadingStatus(){
    return getPublicDataStatus();
  }

  getPublicDataObject(){
    let response = getPublicDataObject();
  }

  ngOnDestroy(): void {
    closeConnection();
  }
}
