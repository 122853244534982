import { Component, Input, OnInit } from '@angular/core';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { nailsData, builtData, hairData, eruptionsData, perspirationPartialData, toungueData, stoolData, urineData, deliveryData, abortionData, thermalData, sleepData, mensesData } from '../checkboax.data';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Response } from '../../../../_Models/Response';
import { HomeoService } from '../../services/homeo.service';
import { EMRService } from '../../services/emr.service';
import { ToastrService } from 'ngx-toastr';
import _ from 'lodash';

@Component({
  selector: 'app-physical-generals',
  templateUrl: './physical-generals.component.html',
  styleUrls: ['./physical-generals.component.scss']
})
export class PhysicalGeneralsComponent implements OnInit {

  public nails: any[] = nailsData;
  public builts: any[] = builtData;
  public hairs: any[] = hairData;
  public eruptions: any[] = eruptionsData;
  public perspiration: any[] = perspirationPartialData;
  public toungs: any[] = toungueData;
  public stools: any[] = stoolData;
  public urines: any[] = urineData;
  public deliverys: any[] = deliveryData;
  public abortions: any[] = abortionData;
  public thermals: any[] = thermalData;
  public sleeps: any[] = sleepData;
  public menses: any[] = mensesData;

  @Input() globals: globalvars;

  public EMRpatientInfo: EMRPatientInfo;

  public physicalGeneralForm: FormGroup;

  private FormValueChangeChecker: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private homeoService: HomeoService,
              private EmrService: EMRService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.EMRpatientInfo =this.EmrService.GetEMRPatientInfo();
    this.initForm();
    this.getPhysicalGenerals();
  }

  initForm(){
    this.physicalGeneralForm = this.formBuilder.group({
      pgId: new FormControl(0),
      generalAppearance: new FormControl(''),
      skinDiscoloration: new FormControl(''),
      perspirationGeneral: new FormControl(''),
      perspirationStaining: new FormControl(''),
      perspirationOdour: new FormControl(''),
      mensesLmp: new FormControl(''),
      mensesFlow: new FormControl(''),
      mensesQuantity: new FormControl(''),
      mensesColor: new FormControl(''),
      mensesOdour: new FormControl(''),
      mensesDysmenorhea: new FormControl(false),
      mensesAmenorhea: new FormControl(false),
      symptomsBeforeMenses: new FormControl(''),
      symptomsAfterMenses: new FormControl(''),
      symptomsDuringMenses: new FormControl(''),
      craving: new FormControl(''),
      appetite: new FormControl(''),
      thrist: new FormControl(''),
      stool: new FormControl(''),
      aversion: new FormControl(''),
      leucorrhea: new FormControl(''),
      milstonePhysical: new FormControl(''),
      milstoneMental: new FormControl(''),
      milstoneDental: new FormControl(''),
      milstoneTalking: new FormControl(''),
      nails: new FormArray([]),
      built: new FormArray([]),
      hair: new FormArray([]),
      eruption: new FormArray([]),
      perspirationPartial: new FormArray([]),
      // stool: new FormArray([]),
      urine: new FormArray([]),
      abortion: new FormArray([]),
      toungue: new FormArray([]),
      delivery: new FormArray([]),
      thermals: new FormArray([]),
      sleep: new FormArray([])
    })
  }

  getPhysicalGenerals(){
    let payload = {
      PatientId: this.EMRpatientInfo.PatientId,
      ConsultationId: this.EMRpatientInfo.ConsultationId
    }

    this.homeoService.getPhysicalGenerals(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          if(response.response.length){
            let data = response.response[0];
            this.physicalGeneralForm.patchValue({
              pgId: data.pgId,
              generalAppearance: data.generalAppearance,
              skinDiscoloration: data.skinDiscoloration,
              perspirationGeneral: data.perspirationGeneral,
              perspirationStaining: data.perspirationStianing,
              perspirationOdour: data.perspirationOdour,
              mensesLmp: data.mensesLmp ? data.mensesLmp : '',
              mensesFlow: data.mensesFlow,
              mensesQuantity: data.mensesQuantity,
              mensesColor: data.mensesColour,
              mensesOdour: data.mensesOdour,
              mensesDysmenorhea: data.mensesDysmenorrhoea,
              mensesAmenorhea: data.mensesAmenorrhea,
              symptomsBeforeMenses: data.symptomsBM,
              symptomsAfterMenses: data.symptomsAM,
              symptomsDuringMenses: data.symptomsDM,
              craving: data.craving,
              appetite: data.apetite,
              thrist: data.thirst,
              stool: data.stool,
              aversion: data.aversion,
              leucorrhea: data.leucorrhea,
              milstonePhysical: data.milestonePhysical,
              milstoneMental: data.milestoneMental,
              milstoneDental: data.milestoneDental,
              milstoneTalking: data.milestoneTalking,
            })
            this.fetchCheckBoxes(data.nailList ? data.nailList : [], 'nails');
            this.fetchCheckBoxes(data.builtList ? data.builtList : [], 'built');
            this.fetchCheckBoxes(data.hairList ? data.hairList : [], 'hair');
            this.fetchCheckBoxes(data.eruptionList ? data.eruptionList : [], 'eruption');
            this.fetchCheckBoxes(data.perspirationPartialList ? data.perspirationPartialList : [], 'perspirationPartial');
            // this.fetchCheckBoxes(data.stoolList ? data.stoolList : [], 'stool');
            this.fetchCheckBoxes(data.urineList ? data.urineList : [], 'urine');
            this.fetchCheckBoxes(data.abortionList ? data.abortionList : [], 'abortion');
            this.fetchCheckBoxes(data.tongueList ? data.tongueList : [], 'toungue');
            this.fetchCheckBoxes(data.deliveryList ? data.deliveryList : [], 'delivery');
            this.fetchCheckBoxes(data.thermalList ? data.thermalList : [], 'thermals');
            this.fetchCheckBoxes(data.sleepList ? data.sleepList : [], 'sleep');
          }
          this.physicalGeneralForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker = true;
          })
        }else{
          this.toastrService.error(response.errorMessage.message)
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again')
      }
    })
  }

  fetchCheckBoxes(values : any[], formControName: string){
    const formArray: FormArray = this.physicalGeneralForm.get(formControName) as FormArray;
    _.forEach(values, (item)=>{
      formArray.push(new FormControl(item))
    })
  }

  onCheckBoxChange(event, formControName: string){
    const formArray: FormArray = this.physicalGeneralForm.get(formControName) as FormArray;
    if(event.target.checked){
      formArray.push(new FormControl(event.target.value))
    }else{
      let index = formArray.controls.findIndex((item)=> item.value == event.target.value);
      formArray.removeAt(index);
    };
  }

  onSavePhysicalGenerals(){
    if(this.EMRpatientInfo.CrudType == 3 ||
      this.EMRpatientInfo.VisitId == 0 || !this.FormValueChangeChecker){
      return
    }

    let formValue = this.physicalGeneralForm.value;
    let payload = {
      PgId: parseInt(formValue.pgId),
      ConsultationId: this.EMRpatientInfo.ConsultationId,
      PatientId: this.EMRpatientInfo.PatientId,
      NailList: formValue.nails,
      HairList: formValue.hair,
      PerspirationGeneral: formValue.perspirationGeneral,
      PerspirationPartialList: formValue.perspirationPartial,
      PerspirationStianing: formValue.perspirationStaining,
      PerspirationOdour: formValue.perspirationOdour,
      Craving: formValue.craving,
      // StoolList: formValue.stool,
      Stool: formValue.stool,
      Aversion: formValue.aversion,
      TongueList: formValue.toungue,
      GeneralAppearance: formValue.generalAppearance,
      SkinDiscoloration: formValue.skinDiscoloration,
      MensesLmp: formValue.mensesLmp,
      MensesDysmenorrhoea: formValue.mensesDysmenorhea,
      MensesAmenorrhea: formValue.mensesAmenorhea,  
      MensesFlow: formValue.mensesFlow,  
      MensesQuantity: formValue.mensesQuantity,
      MensesColour: formValue.mensesColor,
      MensesOdour: formValue.mensesOdour,
      Apetite: formValue.appetite, 
      UrineList: formValue.urine,
      Leucorrhea: formValue.leucorrhea,
      MilestonePhysical: formValue.milstonePhysical,
      MilestoneMental: formValue.milstoneMental,
      MilestoneDental: formValue.milstoneDental,
      MilestoneTalking: formValue.milstoneTalking,
      BuiltList: formValue.built,
      EruptionList: formValue.eruption,
      SymptomsBM: formValue.symptomsBeforeMenses,
      SymptomsAM: formValue.symptomsAfterMenses,
      SymptomsDM: formValue.symptomsDuringMenses,
      Thirst: formValue.thrist,
      AbortionList: formValue.abortion,
      DeliveryList: formValue.delivery,
      ThermalList: formValue.thermals,
      SleepList: formValue.sleep,
      UserId: parseInt(localStorage.getItem('user_id'))
    }

    this.homeoService.savePhysicalGenerals(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == "Success"){
          this.physicalGeneralForm.patchValue({
            pgId: response.response[0].pgId 
          });
          this.toastrService.success('Physical Generals Saved Successfully');
          this.FormValueChangeChecker = false;
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
    
  }

}
