import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import SignaturePad from "signature_pad";
import { AppointmentsService } from "../../../services/appointments.service";
import { ToastrService } from "ngx-toastr";
import { Response } from "../../../../../_Models/Response";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigurationManager } from "../../../../../../assets/configuration/configuration-manager";

@Component({
  selector: "app-print-asoap",
  templateUrl: "./print-asoap.component.html",
  styleUrls: ["./print-asoap.component.scss"],
})
export class PrintAsoapComponent implements OnInit, AfterViewInit {
  @Input() consultationId: number;
  @Input() branchId: number;
  @Input() sponsorId: number;
  @Input() telerikName?:string
  

  private canvasContext: CanvasRenderingContext2D;
  private baseUrl: string = "";
  private signStarted: boolean = false;

  public documentLoading: boolean = false;
  public documentForm: FormGroup;
  public signatureAdded: boolean = false;

  public reportName: string = "";
  public serviceUrl: string = "";
  public viewerContainerStyle = {
    position: "relative",
    width: "100%",
    height: "550px",
    ["font-family"]: "ms sans serif",
  };
  public reportParams;
  update:boolean=true

  constructor(
    private activeModal: BsModalRef,
    private appointmentService: AppointmentsService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private configurationManager: ConfigurationManager,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.reportParams = {
      ConsultationId: this.consultationId,
      BranchId: this.branchId,
      Sponsorid: this.sponsorId,
      SympMultLine: 0,
      SignMultLine: 0,
      DiagMultLine: 0,
      DrugMultLine: 0,
      SOMultLine: 0,
      ICDCodeMultiline:0,
      CPTCodeMultiline:0,
      LabCPTCodeMultiLine:0
    };
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.initDocumentForm();
    // this.getPrintAsoapFile();
    this.serviceUrl = (this.configurationManager as any).reportServiceUrl;
    console.log(this.telerikName)
    
  }

  ngAfterViewInit() {
    let canvasRef: any = <HTMLCanvasElement>(
      document.getElementById("canvas-signature")
    );
    let canvas: CanvasRenderingContext2D = canvasRef.getContext("2d");
    canvas.lineWidth = 1;
    canvas.lineJoin = "round";
    canvas.strokeStyle = "#3e3f40";
    canvas.fillStyle = "#3e3f40";
    this.canvasContext = canvas;
  }

  initDocumentForm() {
    this.documentForm = this.formBuilder.group({
      documentId: new FormControl("", Validators.required),
      patientSignature: new FormControl("", Validators.required),
      documentPath: new FormControl(""),
      documentName: new FormControl(""),
    });
  }

  close() {
    this.activeModal.hide();
  }

  getPrintAsoapFile() {
    const payload = {
      Consultationid: this.consultationId,
      branchid: this.branchId,
      sponsorId: this.sponsorId,
    };
    this.documentLoading = true;
    this.appointmentService.printAsoap(payload).subscribe({
      next: (response: Response<any>) => {
        debugger
        if (response.status == 200) {
          const data = response.response[0];
          this.reportName = data.reportPath;
          debugger;
        } else {
          this.toastrService.error(response.errorMessage.message);
          this.close();
        }
        this.documentLoading = false;
      },
      error: (error: any) => {
        this.toastrService.error("Something wents wrong..! Try again");
        this.documentLoading = false;
        this.close();
      },
    });
  }

  startDrawing(event: MouseEvent) {
    this.canvasContext.beginPath();
    this.canvasContext.moveTo(event.offsetX, event.offsetY);
    this.signStarted = true;
  }

  draw(event: MouseEvent) {
    if (this.signStarted) {
      this.canvasContext.lineTo(event.offsetX, event.offsetY);
      this.canvasContext.stroke();
    }
  }

  stopDrawing() {
    this.canvasContext.closePath();
    this.signStarted = false;
  }

  clearSignature() {
    this.canvasContext.clearRect(0, 0, 150, 70);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }

  onUpdatePatientSignature() {
    const signName = "sign.jpeg";
    let canvas = document.getElementById(
      "canvas-signature"
    ) as HTMLCanvasElement;
    let base64Data = canvas.toDataURL();
    let signsplit = base64Data.split(";");
    const signBlob = this.dataURItoBlob(signsplit[1].split(",")[1]);
    const signFile = new File([signBlob], signName, { type: "image/jpeg" });

    const formData = new FormData();
    let consultationId: any;
    consultationId = this.consultationId;
    formData.append("ConsultationId", consultationId);
    formData.append("ImgSign", signFile);
    this.update=false
    this.appointmentService.getAsoapSignedErdp(formData).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200 && response.message == "success") {
          this.cdr.detectChanges();
          this.update=true
          this.toastrService.success("signature updated successfully");
        } else {
          this.toastrService.error(response.errorMessage.message);
        }
      },
      error: (error: any) => {
        this.toastrService.error("Something wents wrong..! Try again");
      },
    });
  }

  
}
