import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PatientList } from '../../interfaces/PatientList';
import { PatientService } from '../../services/patient.service';
import { Router } from '@angular/router';
import { ColDef, GridOptions } from "ag-grid-community";
import { AppointmentsService } from '../../services/appointments.service';
import { AppointmentTypesList } from '../../interfaces/AppointmentTypesList';
import { ToastrService } from 'ngx-toastr';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../shared/shared.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ApplicationSettingsService } from '../../../../shared/services/application-settings.service';
import { Response } from '../../../../_Models/Response';
import { ApplicationSettings } from '../../../../shared/models/applicationSettings';
import _ from 'lodash';
import { data } from 'jquery';

export interface SearchSettings{
  letterSearchEnabled: boolean,
  numberOfLetters: any
}

@Component({
  selector: 'SearchPatientModalComponent',
  templateUrl: './searchpatient-modal.component.html',
  styleUrls: ['./searchpatient-modal.component.scss']

})

export class SearchPatientModalComponent implements OnInit {
  @Input() patientModal;
  @Output() returnevent = new EventEmitter();
  @Output() returnFooter = new EventEmitter();
  @ViewChild('patientModal') modaltemp: ElementRef;
  patientResult: any;
  patients: PatientList[] = [];
  searchForm: FormGroup;
  form: FormGroup;
  user_role: any;
  user_id: any;
  router_url = this.router.url;
  public defaultColDef: ColDef;
  result: any;
  posts: any[];
  subscription: Subscription;
  globals: globalvars = new globalvars();
  appointmentTypesList: AppointmentTypesList[] = [];
  BranchesId: any;
  modalRef: BsModalRef;
  isLoading:boolean=false;
  message: number;
  
  private backupRowData=null;
  constructor(private patientService: PatientService,
    private formBuilder: FormBuilder,
    private router: Router,
    private appointmentService: AppointmentsService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private settingsService: ApplicationSettingsService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId

      }
    });
  }
  public gridApi;
  public rowSelection = true;
  public context;
  //Search Patient modal AG-Grid
  columns: ColDef[] = [
    {
      headerName: "Reg. No.",
      field: "regNo",
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      width: 100,
    },
    {
      headerName: "Patient Name",
      field: "patient",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "Age",
      field: "age",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      width: 70,
    },
    {
      headerName: "Phone No.",
      field: "mobile",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      width: 100,
    },
    {
      headerName: "Address",
      field: "address",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      width: 100,
    },
    {
      headerName: "Pin",
      field: "pin",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      width: 100,
    },
    {
      headerName: "Policy No.",
      field: "policyNo",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      width: 100,
    },
    {
      headerName: "Nationality ID.",
      field: "nationalityId",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      width: 130,
    },
  ];

  private searchSettings: SearchSettings;


  ngOnInit(): void {

    this.getApplicationSettings();

    this.user_role = localStorage.getItem('user_role');
    this.user_id = localStorage.getItem('user_id');


    this.searchForm = this.formBuilder.group({
      sMobile: new FormControl(''),
      sPhoneNo: new FormControl(''),
      sPin: new FormControl(''),
      sPolicyNo: new FormControl(''),
      sRegNo: new FormControl(''),
      sName: new FormControl(''),
      sAddress: new FormControl(''),
      sResNo: new FormControl(''),
      sEmiratesNo: new FormControl(''),
      BranchId: new FormControl(this.globals.HospitalId)
    })


    function Ctrl($scope) {
      $scope.date = new Date();
    }


  }

  getApplicationSettings(){
    const payload = {
      BranchId: this.globals.HospitalId,
      LocationId: this.globals.Location.locationId,
      UserId: Number(localStorage.getItem('user_id'))
    }
    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          let applicationSettings: ApplicationSettings[] = response.response;
          let search = _.filter(applicationSettings, {displayName: 'PatientSerach'})[0];
          let letter = _.filter(applicationSettings, {displayName: 'PatSerachLetterNum'})[0];
          this.searchSettings = {
            letterSearchEnabled: search?.settValue == 1 ? true : false,
            numberOfLetters: letter?.settValue
          }
        }else{
          this.toastr.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastr.error(error);
      }
    })
  }

  onInputFields(event){
    let value = event.target.value
    if(this.searchSettings?.letterSearchEnabled && value.length >= this.searchSettings?.numberOfLetters){
      this.SearchPatient();
    }
  }



  /* ============ page title to be displayed in header============ */
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);
  // }

  //Searching patient details
  SearchPatient() {

    this.isLoading=true;
    this.patientService.SearchPatient(this.searchForm).subscribe((res) => {
      this.patients = [];
      this.patients = res.response;
      console.log(this.patients)
      this.result = res;
      {
        this.isLoading=false;

        if (this.patients.length > 0) {
          if (this.patients.length == 1 && this.patients[0].active == "0") {
            // this.isLoading=false;
            this.toastr.warning("This Patient is blocked");
            this.patients = [];
          }
          else {
            this.patients = this.patients.filter((oppdata) => oppdata.active == "1");

            if (this.patients.length == 0) {
              // this.isLoading=false;
              this.toastr.warning("No Patient Found");
            }
          }


        }
        else {
          // this.isLoading=false;
          this.toastr.warning("No Patient Found");
        }
      }
    });


  }

  FillForm(patient: any) {
    //this.patients
    console.log(patient);
    this.returnevent.emit(patient)
    this.searchForm.patchValue({
      sAddress: '',
      sEmiratesNo: '',
      sMobile: '',
      sName: '',
      sPhoneNo: '',
      sPin: '',
      sPolicyNo: '',
      sRegNo: '',
      sResNo: ''
    })
    this.resetRowData();
     
  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {

    this.gridApi = params.api;
   
  }
  ClickPatient() {
    this.patientModal = false;
    this.returnFooter.emit(false);
    this.searchForm.reset();
   
   this.resetRowData();
 
  }
  open() {
    this.modalRef = this.modalService.show(this.modaltemp, { class: "modal-lg", backdrop: "static"});
  }
  resetRowData = () => {
    this.patients = [];
  };
}


