import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ConfigurationManager } from './assets/configuration/configuration-manager';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import moment from 'moment-timezone';

//change this timezone based on cliet
moment.tz.setDefault('Asia/Dubai');

let configPath = "assets/configuration/config.txt";


// const currentTime = moment();
// console.log('Current time in Kolkata:', currentTime.format('YYYY-MM-DD HH:mm:ss'));


if (environment.production) {
  enableProdMode();
}



  ConfigurationManager.loadInstance(configPath).then(() => {
    platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
  });

