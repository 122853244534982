import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { SketchConfigurationService } from './sketch-configuration.service';
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfigurationService } from '../../client/services/configuration.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-sketch-configuration',
  templateUrl: './sketch-configuration.component.html',
  styleUrls: ['./sketch-configuration.component.scss']
})
export class SketchConfigurationComponent implements OnInit {

  IndicatorTab: boolean; // for tab switching
  BodyPartTab: boolean; // for tab switching
  public user_id: string;
  public BodyPartFile: File = null;// for body part
  public IndicatorFile: File = null;// for indiator image
  form: FormGroup; // form variable
  Indicatorform: FormGroup; // form variable
  public submittedSketch: boolean = false; // form validation controller
  public submittedBodyPart: boolean = false; // form validation controller

  globals: globalvars = new globalvars();
  BranchesId: any;
  subscription: Subscription;

  public defaultColDef: ColDef;
  ColDef: ColDef[] = [];
  IndicatorColDef: ColDef[] = [];
  BodyPartList: any[] = [];
  IndicatortList: any[] = [];
  rowGroupPanelShow = "always";
  public context;
  public gridApiMarking;
  public gridApiSketch;
  public statusBar;
  documentList: any;
  documentUrl:any
  documentListName: any
  public gridOptionsList = {
    pagination: true,
    paginationPageSize: 10,
  };
  isLoading=false;
  public loadingTemplate;
  public noRowsTemplateList;
  public frameworkComponents;
  modalRef: BsModalRef;
  @ViewChild("template") modaltemp: any;
  @ViewChild("template1") modaltempSketch: any;
  IndicatorId: number;
  bodyId:number;
  functionPermissionData: any;

  constructor(
    private toastr: ToastrService,
    private SketchConfigurationService: SketchConfigurationService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,
    private domSanitizer: DomSanitizer

  ) {
    this.onTab(1); // default tab set
    this.user_id = localStorage.getItem('user_id');
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      bodyId: new FormControl(0),
      bodyDesc: new FormControl('', [Validators.required]),
      userId: new FormControl(this.user_id),
      branchId: new FormControl(this.globals.HospitalId),
      isDisplayed: new FormControl(true),
      bodypart: new FormControl('')
    });

    this.Indicatorform = new FormGroup({
      IndicatorId: new FormControl(0),
      IndicatorDesc: new FormControl('', [Validators.required]),
      BranchId: new FormControl(this.globals.HospitalId),
      IsDisplayed: new FormControl(true),
      IndImage: new FormControl('')
    });

    this.context = { componentParent: this };
    this.noRowsTemplateList = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    this.ColDef = [
      {
        headerName: "Sketch Name",
        field: "bodyDesc",
        minWidth:100,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
      },

      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "bodyId",
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "bodypart" },
      },
    ]

    this.IndicatorColDef = [
      {
        headerName: "Marking Name",
        field: "indicatorDesc",
        minWidth:100,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Action",
        maxWidth:100,
        filter: false,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "indicatorId",
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "sketchIndiator" },
      },
    ]

    this.GetBodyPartList();
    this.GetIndicatortList();
    this.functionPermission();
  }


  onGridReadyMarking(params) {
    this.gridApiMarking = params.api;
  }
  onGridReadySketch(params) {
    this.gridApiSketch = params.api;
  }
  onFilterTextBoxChangedMarking() {
    this.gridApiMarking.setQuickFilter(
      (document.getElementById('filter-text-box-marking') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedSketch() {
    this.gridApiSketch.setQuickFilter(
      (document.getElementById('filter-text-box-sketch') as HTMLInputElement).value
    );
  }


  get f() {
    return this.form.controls;
  }
  get fm() {
    return this.Indicatorform.controls;
  }

  // tab swwitcher

  onTab(number) {
    this.IndicatorTab = false;
    this.BodyPartTab = false;

    if (number == "1") {
      this.IndicatorTab = true;
    } else if (number == "2") {
      this.BodyPartTab = true;
    }
  }
  ChangeImage(event: any) {
    this.BodyPartFile = event.target.files[0];
  }
  ChangeIndicatorImage(event: any) {
    this.IndicatorFile = event.target.files[0];
  }
  SaveIndicator() {
    const foundElement = this.functionPermissionData.find(x => x.id === 59)
    if (foundElement.id == 59 && foundElement.isAllowed == true) {
    this.submittedSketch = true;
    if (this.Indicatorform && this.Indicatorform.valid) {
      let form = this.Indicatorform.getRawValue();


      if (this.IndicatorFile == null && form.IndicatorId == 0) {
        this.toastr.error("Please select a image");
      }
      else {
        if (form.IsDisplayed) {
          form.IsDisplayed = 1;
        }
        else {
          form.IsDisplayed = 0;
        }
        const formData = new FormData();
        this.isLoading=true;
        let fname = null;
        if (this.IndicatorFile != null) {
          fname = this.IndicatorFile.name;
          formData.append('Indicator', this.IndicatorFile, fname);
        }
        formData.append('SketchIndicatorJson', JSON.stringify(form));
        this.SketchConfigurationService.saveIndicator(formData).subscribe(
          (res) => {
            this.submittedSketch = false;

            if (res.status == 200 && res.message == "Success") {
              this.clearIndicatorForm();
              // this.ResetFunction();
              this.isLoading=false;
              this.toastr.success("Data saved successfully");
              this.GetIndicatortList();
            } else if (res.status == 200) {
              this.isLoading=false;
              this.toastr.error("Failed : " + res.message);
            } else {
              this.isLoading=false;
              this.toastr.error("Failure: " + res.response.message);
            }
          },
          (err) => {
            this.isLoading=false;
            this.toastr.error("Some unexpected error occures try again later");
          }
        );

      }
    }
    } else {
      this.toastr.warning("You are not allowed to save data");
    }
  }
  SaveBodyPart() {
    const foundElement = this.functionPermissionData.find(x => x.id === 63)
    if (foundElement.id == 63 && foundElement.isAllowed == true) {
    this.submittedBodyPart = true;
    if (this.form && this.form.valid) {
      let form = this.form.getRawValue();

      if (this.BodyPartFile == null && form.bodyId == 0) {
        this.toastr.error("Please select a image");
      }
      else {
        if (form.isDisplayed) {
          form.isDisplayed = 1;
        }
        else {
          form.isDisplayed = 0;
        }
        const formData = new FormData();
        let fname = null;
        this.isLoading=true;
        if (this.BodyPartFile != null) {
          fname = this.BodyPartFile.name;
          formData.append('BodyPartImg', this.BodyPartFile, this.BodyPartFile.name);
        }

        formData.append('BodyPartJson', JSON.stringify(form));
        this.SketchConfigurationService.saveBodyPart(formData).subscribe(
          (res) => {
            this.submittedBodyPart = false;

            if (res.status == 200 && res.message == "Success") {
              // this.ResetFunction();
              this.clearForm();
              this.isLoading=false;
              this.toastr.success("Data saved successfully");
              this.GetBodyPartList();
            } else if (res.status == 200) {
              this.isLoading=false;
              this.toastr.error("Failed : " + res.message);
            } else {
              this.isLoading=false;
              this.toastr.error("Failure: " + res.response.message);
            }
          },
          (err) => {
            this.isLoading=false;
            this.toastr.error("Some unexpected error occures try again later");
          }
        );
      }
    }
    } else {
      this.toastr.warning("You are not allowed to save data");
    }
  }
  clearIndicatorForm() {
    this.Indicatorform.reset({
      IndicatorId: 0,
      IndicatorDesc: '',
      BranchId: this.globals.HospitalId,
      IsDisplayed: true,
      IndImage: '',
    });
    this.IndicatorFile = null
  }
  clearForm() {
    this.form.reset({
      bodyId: 0,
      bodyDesc: '',
      userId: this.user_id,
      branchId: this.globals.HospitalId,
      IsDisplayed: true,
      bodypart: '',
    });
    this.BodyPartFile = null
  }


  GetBodyPartList() {
    let input = {
      "BodyId": 0,
      "ShowAll": 1,
      "BranchId": this.globals.HospitalId
    }
    this.SketchConfigurationService.GetBodyPartList(input).subscribe((res) => {
      this.BodyPartList = res.response;
    });
  }
  GetIndicatortList() {
    let input = {
      "IndicatorId": 0,
      "ShowAll": 1,
      "BranchId": this.globals.HospitalId
    }
    this.SketchConfigurationService.GetIndicatortList(input).subscribe((res) => {
      this.IndicatortList = res.response;
    });
  }
  EditIndicator(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 60)
    if (foundElement.id == 60 && foundElement.isAllowed == true) {
    data = this.upper(data);
    if (data['isDisplayed'] == 1) {
      this.Indicatorform.controls['IsDisplayed'].setValue(true);
    }
    else {
      this.Indicatorform.controls['IsDisplayed'].setValue(false);
    }
    // this.Indicatorform.patchValue({
    //   IndImage: data.imageFileName,
    // });
    this.Indicatorform.patchValue(data);
    // this.documentList = this.domSanitizer.bypassSecurityTrustResourceUrl(data.imageUrl)
    // this.clearIndicatorForm();
    } else {
      this.toastr.warning("You are not allowed to edit");
    }
  }
  DeleteIndicator(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 61)
    if (foundElement.id == 61 && foundElement.isAllowed == true) {
    let id = data.indicatorId;

    if (id > 0) {
      let input = {
        "IndicatorId": data.indicatorId,
      }
      this.SketchConfigurationService.DeleteSketchIndicator(input).subscribe((res) => {
        if (res.status == 200) {
          this.toastr.success("Data deleted successfully");
          this.GetIndicatortList();
          this.clearIndicatorForm();
        } else {
          this.toastr.error("Failure: " + res.message);
        }
      });
    }
    } else {
      this.toastr.warning("You are not allowed to delete");
    }
  }
  EditBodyPart(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 64)
    if (foundElement.id == 64 && foundElement.isAllowed == true) {
    if (data['isDisplayed'] == 1) {
      this.form.controls['isDisplayed'].setValue(true);
    }
    else {
      this.form.controls['isDisplayed'].setValue(false);
    }
    this.form.patchValue(data);
    // this.documentList = this.domSanitizer.bypassSecurityTrustResourceUrl(data.bodyPartImageLocation)
    // this.ResetFunction();
    // this.clearForm();
    } else {
      this.toastr.warning("You are not allowed to edit");
    }
  }
  ViewImage(data, type) {
    const foundElement = this.functionPermissionData.find(x => x.id === 66)
    if (foundElement.id == 66 && foundElement.isAllowed == true) {
    this.modalRef = this.modalService.show(
      this.modaltemp
    );
    this.documentList = this.domSanitizer.bypassSecurityTrustResourceUrl(data.bodyPartImageLocation)
    this.documentUrl=data.bodyPartImageLocation
    this.documentListName = data.bodyPartFileName;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  OpenImage(filePath: string) {//document view
    window.open(filePath, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
  }
  DeleteBodyPart(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 65)
    if (foundElement.id == 65 && foundElement.isAllowed == true) {
    let id = data.bodyId;

    if (id > 0) {
      let input = {
        "BodyId": data.bodyId,

      }
      this.SketchConfigurationService.DeleteBodyPart(input).subscribe((res) => {
        if (res.status == 200) {
          this.toastr.success("Data deleted successfully");
          this.GetBodyPartList();
          this.clearForm();
        } else {
          this.toastr.error("Failure: " + res.message);
        }
      });
    }
    } else {
      this.toastr.warning("You are not allowed to delete");
    }
  }

  upper(obj) {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      let first = key.substr(0, 1).toUpperCase();
      first = first + key.substr(1);

      newobj[first] = obj[key];
    }
    return newobj;
  }

  ViewImageSketch(data, type) {
    const foundElement = this.functionPermissionData.find(x => x.id === 62)
    if (foundElement.id == 62 && foundElement.isAllowed == true) {
    this.modalRef = this.modalService.show(
      this.modaltempSketch
    );
    this.documentList = this.domSanitizer.bypassSecurityTrustResourceUrl(data.imageUrl)
    this.documentUrl=data.imageUrl
    this.documentListName = data.imageFileName;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  OpenImagesKtech(filePath: string) {//document view
    window.open(filePath, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
  }
  // ResetFunction() {
  //   this.bodyId = 0;
  //   this.IndicatorId = 0;
  //   this.submittedSketch = false;
  //   this.submittedBodyPart = false;
  //   this.clearIndicatorForm();
  //   this.clearForm();
  // }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

}
