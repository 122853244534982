import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
//import { EventEmitter } from 'stream';
import { AppointmentsList } from '../../interfaces/AppointmentsList';
import { AppointmentsService } from '../../services/appointments.service';

@Component({
  selector: 'app-cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: ['./cancel-appointment.component.scss']
})
export class CancelAppointmentComponent implements OnInit {
  @Input() AppId;
  //@Input() CancelAppointments;
  @Output() reload = new EventEmitter();
  @Output() returnFooter = new EventEmitter();
  CancelAppointments: boolean;
  CancelButton: boolean;
  active: string;
  confirmblock: boolean;
  blockId: number;
  blockorunblock: boolean;

  AppointmentDataDetails: AppointmentsList[];
  form: FormGroup;
  constructor(private appointmentService: AppointmentsService,
    private toastr: ToastrService,) {
    this.form = new FormGroup({
      blockReason: new FormControl("")
    })
  }
  ngOnChanges() {
    if (typeof this.AppId != "undefined") {
      this.showCancelAppointment(this.AppId);
    }
  }
  confirmblocking(blockid) {
    this.confirmblock = true;

  }
  ngOnInit(): void {
  }
  showCancelAppointment(appId) {
    this.appointmentService.GetAppoinmentDetailsbyId(Number(appId)).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      this.form.patchValue({ blockReason: '' });
      this.active = (this.AppointmentDataDetails['appStatus'] == 'Cancelled') ? "Already Cancelled this Appointment" : "Are you sure that you want to this Cancel Appointment?"
      this.blockId = (appId);
      this.CancelAppointments = true;
      this.CancelButton = (this.AppointmentDataDetails['appStatus'] == 'Cancelled') ? false : true;
    });

  }
  ConfirmBlock(blockId) {

    let blockReason = this.form.get("blockReason").value;
    this.appointmentService.CancelAppointment(this.blockId, blockReason).subscribe((res) => {
      this.toastr.success("Appointment Cancelled", "Success");
      this.reload.emit();
      this.confirmblock = false;
    })

  }




}
