import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../client/services/configuration.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-Company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;

  public submitted: boolean = false;
  result: any;
  professionId: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  deleteprofession: boolean;
  oppid: any;
  public statusBar;
  rowGroupPanelShow = "always";
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  isLoading = false;
  functionPermissionData: any;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.active == '0'; },
      'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    },

  }
  rowData: any;
  cpctcode: any;
  unlistedCode: any;
  CptCodeId: any;
  rateGroup: any;
  CPTModifiers: any;
  ModifierId: any;
  CompanyForm: FormGroup;
  Company: any;
  cmpId: any;
  IsDisplayed: any;
  DisplayedId: any;
  modalRef: BsModalRef;
  CompanyPopupHeading: String = "Add Company Name";
  @ViewChild("template") modaltemp: any;
  selectedMenuId: string;

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private modalService: BsModalService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });
    this.selectedMenuId = localStorage.getItem('selectedMenu');
    // console.log(this.selectedMenuId);

  }

  ngOnInit(): void {



    this.CompanyForm = new FormGroup({
      companyname: new FormControl('', [Validators.required]),
      companydesc: new FormControl('', [Validators.required]),
      IsDisplayedGroup: new FormControl(true)
    });
    this.GetAllCompany();
    this.functionPermission()
    this.columnDefs = [

      {
        headerName: "Company Name",
        minWidth: 180,
        field: "nameData",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Company Description",
        field: "descriptionData",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "company" },
      },

    ]


    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };





    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };



    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

  }

  showModalDialog(orderId) {
    const foundElement = this.functionPermissionData.find(element => element.id === 2);
    if (foundElement.id == 2 && foundElement.isAllowed == true) {

      this.CompanyPopupHeading = "Edit Company Name";
      this.modalRef = this.modalService.show(
        this.modaltemp,
        // Object.assign({}, { class: "gray modal-lg" })
      );

      this.displayModal = true;
      this.cmpId = orderId.id
      this.DisplayedId = orderId.isDisplayed
      this.CompanyForm.patchValue({
        companyname: orderId.nameData,
        companydesc: orderId.descriptionData,
      });
      if (orderId.isDisplayed == 1) {
        let displayed = true;
        this.CompanyForm.patchValue({
          IsDisplayedGroup: displayed
        });
      }
      else {
        let displayed = false;
        this.CompanyForm.patchValue({
          IsDisplayedGroup: displayed
        });
      }
    } else {
      this.toastr.warning("You are not Allowed");

    }

  }

  // to clear form
  clearForm() {
    this.CompanyForm.reset({
      companyname: '',
      companydesc: '',
      IsDisplayedGroup: true
    });
    this.submitted = false;
  }


  //pagetitle to be displayed at the header
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);

  // }


  SaveCompany() {
    const foundElement = this.functionPermissionData.find(element => element.id === 1);
    const foundElementEdit = this.functionPermissionData.find(element => element.id === 2);

    if ((foundElement.id == 1 && foundElement.isAllowed == true && this.cmpId == 0) || (foundElementEdit.id == 2 && foundElementEdit.isAllowed == true && this.cmpId != 0)) {
      this.submitted = true;

      if (this.CompanyForm && this.CompanyForm.valid) {
        var AppData = {
          companyname: this.CompanyForm.get("companyname").value,
          companydesc: this.CompanyForm.get("companydesc").value,
          IsDisplayedGroup: this.CompanyForm.get("IsDisplayedGroup").value,

        }
        if (AppData.IsDisplayedGroup == true) {
          AppData.IsDisplayedGroup = 1;
        }
        else {
          AppData.IsDisplayedGroup = 0;
        }

        let Profdata = 0;
        if (this.cmpId > 0) {
          Profdata = this.cmpId;
        }
        this.isLoading = true;
        this.ConfigurationService.InsertUpdateCompany(AppData, Profdata, this.globals.HospitalId).subscribe((res) => {
          this.submitted = false;
          this.result = res;
          if (this.result.status == 200 && this.result.message == 'Success') {
            if (Profdata == 0) {
              this.isLoading = false;
              this.toastr.success("Company added successfully");
            }
            else {
              this.isLoading = false;
              this.toastr.success("Company Updated successfully");
              this.cmpId = 0;
            }
            this.modalRef.hide();
            this.GetAllCompany();
            this.clearForm();
          }
          else {
            this.isLoading = false;
            this.toastr.error("Failed : " + this.result.message);
          }
        })
      }

    } else {
      this.toastr.warning("You are not Allowed");

    }
  }


  GetAllCompany() {

    this.ConfigurationService.GetCompany(this.globals.HospitalId).subscribe((res) => {
      this.Company = res.response;

    });
  }

  DeleteCompany(id) {
    const foundElement = this.functionPermissionData.find(element => element.id === 3);
    if (foundElement.id == 3 && foundElement.isAllowed == true) {
      this.cmpId = id.id;
      this.ConfigurationService.DeleteCompany(this.cmpId).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200 && this.result.message == 'Success') {

          this.toastr.success("Company deleted successfully");
          this.displayModal = false;
          this.GetAllCompany();
          this.clearForm();
          this.cmpId = 0;
        }
        else {
          this.toastr.error("Failure: " + this.result.message);
          this.displayModal = false;
        }

      },
      );
    }
    else {
      this.toastr.warning("You are not Allowed");

    }
  }

  //check and uncheck of registration date

  selectchecking(evt) {

    if (evt.target.checked == true) {
      this.IsDisplayed = 1;
    }
    else {
      this.IsDisplayed = 0;
    }

  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }


  openModalCompany(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(element => element.id === 1);
    if (foundElement.id == 1 && foundElement.isAllowed == true) {
    this.CompanyPopupHeading = "Add Company Name";
    this.modalRef = this.modalService.show(template);
    this.cmpId = 0;
    this.clearForm();
    }else {
      this.toastr.warning("You are not Allowed");
    }
  }


  // for geting all form items

  get f() {
    return this.CompanyForm.controls;
  }

  ResetFunction() {
    this.cmpId = 0;
    this.clearForm();
  }
  functionPermission() {
    let input = {
      MenuId: Number(this.selectedMenuId),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;

    });

  }
}
