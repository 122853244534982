import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ColDef } from "ag-grid-community";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { ConfigurationService } from "../../client/services/configuration.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { getHtmlTagDefinition } from "@angular/compiler";
@Component({
  selector: "app-department",
  templateUrl: "./department.component.html",
  styleUrls: ["./department.component.scss"],
})
export class DepartmentComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;

  public submitted: boolean = false;
  result: any;
  professionId: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  deleteprofession: boolean;
  oppid: any;
  public statusBar;
  rowGroupPanelShow = "always";
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.active == "0";
      },
      "c-reds2": function (params) {
        return params.data.appStatus == "Cancelled";
      },
    },
  };
  rowData: any;
  cpctcode: any;
  unlistedCode: any;
  CptCodeId: any;
  rateGroup: any;
  CPTModifiers: any;
  ModifierId: any;
  DepartmentForm: FormGroup;
  Company: any;
  cmpId: any;
  RowData: any;
  DepartmentId: any;
  IsDisplayed: any;
  DisplayedId: any;
  modalRef: BsModalRef;
  isLoading = false;
  DepartmentPopupHeading: String = "Add Department";
  @ViewChild("template") modaltemp: any;
  functionPermissionData: any;

  constructor(
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private modalService: BsModalService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.DepartmentForm = new FormGroup({
      Departmentname: new FormControl("", [Validators.required]),
      Departmentcode: new FormControl("", [Validators.required]),
      DepartmentDescription: new FormControl("", [Validators.required]),
      IsDisplayedGroup: new FormControl(true),
    });
    this.GetAllDepartments();
    this.functionPermission();

    this.columnDefs = [
      {
        headerName: "Department Name",
        field: "deptName",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Department Code",
        field: "deptCode",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Action",
        maxWidth: 100,
        filter: false,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Department" },
      },
    ];

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }

  // for geting all form items

  get f() {
    return this.DepartmentForm.controls;
  }

  // to clear form
  clearForm() {
    this.DepartmentForm.reset({
      Departmentname: '',
      Departmentcode: '',
      DepartmentDescription: '',
      IsDisplayedGroup: true
    });
    this.submitted = false;
  }


  showModalDialog(DeptId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 114)
    if (foundElement.id == 114 && foundElement.isAllowed == true) {
    this.DepartmentPopupHeading = "Edit Department";
    this.modalRef = this.modalService.show(
      this.modaltemp,
      //Object.assign({}, { class: "gray modal-lg" })
    );
    this.displayModal = true;
    this.DepartmentId = DeptId.deptId;
    this.DisplayedId = DeptId.isDisplayed;
    this.DepartmentForm.patchValue({
      Departmentname: DeptId.deptName,
      Departmentcode: DeptId.deptCode,
      DepartmentDescription: DeptId.description,
    });
    if (DeptId.isDisplayed == 1) {
      let displayed = true;
      this.DepartmentForm.patchValue({
        IsDisplayedGroup: displayed
      });
    }
    else {
      let displayed = false;
      this.DepartmentForm.patchValue({
        IsDisplayedGroup: displayed
      });
    }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  //pagetitle to be displayed at the header
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);
  // }

  SaveDepartment() {
    this.submitted = true;

    if (this.DepartmentForm && this.DepartmentForm.valid) {
      var AppData = {
        Departmentname: this.DepartmentForm.get("Departmentname").value,
        Departmentcode: this.DepartmentForm.get("Departmentcode").value,
        DepartmentDescription: this.DepartmentForm.get("DepartmentDescription").value,
        IsDisplayedGroup: this.DepartmentForm.get("IsDisplayedGroup").value,
      };
      if (AppData.IsDisplayedGroup == true) {
        AppData.IsDisplayedGroup = 1;
      } else {
        AppData.IsDisplayedGroup = 0;
      }

      let Departmentdata = 0;
      if (this.DepartmentId > 0) {
        Departmentdata = this.DepartmentId;
      }
      this.isLoading = true;
      this.ConfigurationService.InsertUpdateDepartment(
        AppData,
        Departmentdata,
        this.globals.HospitalId
      ).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Success") {
          if (Departmentdata == 0) {
            this.isLoading = false;
            this.toastr.success("Department added successfully");
          } else {
            this.isLoading = false;
            this.toastr.success("Department Updated successfully");
            this.DepartmentId = 0;
          }
          this.modalRef.hide();
          this.GetAllDepartments();
          this.clearForm();

        } else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    }
  }

  GetAllDepartments() {
    this.ConfigurationService.GetAvailableDepartments(
      this.globals.HospitalId
    ).subscribe((res) => {
      this.RowData = res.response;
    });
  }

  DeleteDepartment(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 115)
    if (foundElement.id == 115 && foundElement.isAllowed == true) {
    this.DepartmentId = id.deptId;
    this.ConfigurationService.DeleteDepartment(this.DepartmentId).subscribe(
      (res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Department deleted successfully");
          this.displayModal = false;
          this.GetAllDepartments();
          this.clearForm();
          this.DepartmentId = 0;
        } else {
          this.toastr.error("Failure: " + this.result.message);
          this.displayModal = false;
        }
      }
    );
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }


  openModalDepartment(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 113)
    if (foundElement.id == 113 && foundElement.isAllowed == true) {
    this.DepartmentPopupHeading = "Add Department";
    this.modalRef = this.modalService.show(template);
    this.DepartmentId = 0;
    this.clearForm();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }

  ResetFunction() {
    this.DepartmentId = 0;
    this.clearForm();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }
}
