import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe,Location } from '@angular/common';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from "@angular/router";
import { PatientService } from '../../client/services/patient.service';

@Component({
  selector: 'app-patient-sticker',
  templateUrl: './patient-sticker.component.html',
  styleUrls: ['./patient-sticker.component.scss']
})
export class PatientStickerComponent implements OnInit {
  currentDate: string;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  patientId: any;
  patientDetails: any;
  regDate: any;
  patientName: any;
  regNo: any;
  ageInYear: any;
  ageInMonth: any;
  genderName: any;
  departmentName: any;
  dob: any;
  nationalityId: any;
  nationalityName: any;
  visaType: any;
  mobile: any;
  clinic: any;

  constructor(
    public datepipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private patientService: PatientService,
    private _location: Location
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      this.patientId = Number(params.id);

    });
    this.currentDate = this.datepipe.transform(new Date(), "dd-MM-YYYY HH:mm");

    this.patientService.GetPatientDetailsbyId(this.patientId).subscribe((res) => {
      this.patientDetails = res.response;
      this.regDate = this.patientDetails[0].regDate;
      this.patientName = this.patientDetails[0].patientName;
      this.regNo = this.patientDetails[0].regNo;
      this.ageInYear = this.patientDetails[0].ageInYear;
      this.genderName = this.patientDetails[0].genderName;
      this.ageInMonth = this.patientDetails[0].ageInMonth;
      this.departmentName = this.patientDetails[0].departmentName;
      this.dob = this.patientDetails[0].dob;
      this.nationalityId = this.patientDetails[0].regIdentities[6].identityNo;
      this.nationalityName = this.patientDetails[0].nationalityName;
      this.visaType = this.patientDetails[0].visaType;
      this.mobile = this.patientDetails[0].mobile;
      this.clinic = this.patientDetails[0].branchName
    });
  }
  printPage() {
    window.print();
  }
  goback() {
    this._location.back();
  }
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    ws.A1.h = "<b>A</b>";
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'PatientCard.xlsx');
  }


}
