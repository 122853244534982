import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-qrcode-scan-modal',
  templateUrl: './qrcode-scan-modal.component.html',
  styleUrls: ['./qrcode-scan-modal.component.scss']
})
export class QrcodeScanModalComponent implements OnInit {

  @Input() availableStaffs: any[] = [];

  @Output() staffEmitter = new EventEmitter();

  public scannerEnabled: boolean = false;
  public qrcodeErrorMessage: string = '';

  private myAudioContext = new AudioContext();

  constructor(private activeModal: BsModalRef,
              private toastrService: ToastrService) { }
  
  ngOnInit(): void {
    this.scannerEnabled = true;
    console.log(this.availableStaffs)
  }

  close(){
    this.activeModal.hide();
  }

  scanSuccessHandler(event: any) {
    console.log(event)
    this.qrcodeErrorMessage = '';
    const staffIndex = _.findIndex(this.availableStaffs, (staff: any)=>{
      return staff.staffId == event
    });
    if(staffIndex >= 0){
      this.scannerEnabled = false;
      this.staffEmitter.emit({
        status: true,
        staffId: event
      });
      this.beep(200,440,100)
      this.close();
    }else{
      this.beep(200,400,100)
      this.qrcodeErrorMessage = 'Not a valid QR Code to perform this action.. Try again.'
    }
  }

  beep(duration, frequency, volume){
    return new Promise<void>((resolve, reject) => {
        // Set default duration if not provided
        duration = duration || 200;
        frequency = frequency || 440;
        volume = volume || 100;

        try{
            let oscillatorNode = this.myAudioContext.createOscillator();
            let gainNode = this.myAudioContext.createGain();
            oscillatorNode.connect(gainNode);

            // Set the oscillator frequency in hertz
            oscillatorNode.frequency.value = frequency;

            // Set the type of oscillator
            oscillatorNode.type= "square";
            gainNode.connect(this.myAudioContext.destination);

            // Set the gain to the volume
            gainNode.gain.value = volume * 0.01;

            // Start audio with the desired duration
            oscillatorNode.start(this.myAudioContext.currentTime);
            oscillatorNode.stop(this.myAudioContext.currentTime + duration * 0.001);

            // Resolve the promise when the sound is finished
            oscillatorNode.onended = () => {
                resolve();
            };
        }catch(error){
            reject(error);
        }
    });
  }

}
