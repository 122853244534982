import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { SponsorsService } from "../../client/services/sponsors.service";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { noop, Observable, Observer, of, Subscription } from "rxjs";
import { BillingService } from '../services/billing.service';
import { ConfigurationService } from '../../client/services/configuration.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreditForm, CreditJson } from '../interfaces/OpenCredit';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import {Location} from '@angular/common';
import { CreditItemsModalComponent } from '../modals/credit-items-modal/credit-items-modal.component';
import { WebCameraModalComponent } from '../modals/web-camera-modal/web-camera-modal.component';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { FilePreviewModalComponent } from '../modals/file-preview-modal/file-preview-modal.component';
import { PatientService } from '../../client/services/patient.service';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { ScanInsuranceCardModalComponent } from '../modals/scan-insurance-card-modal/scan-insurance-card-modal.component';
import { parse } from 'date-fns';

@Component({
  selector: 'app-open-credit-modal',
  templateUrl: './open-credit-modal.component.html',
  styleUrls: ['./open-credit-modal.component.scss']
})
export class OpenCreditModalComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild(SearchPatientModalComponent)modaltemp: SearchPatientModalComponent;

  @ViewChild('OpenCreditTemplate')modaltempCredit: ElementRef;
  addData: any;
  consultationId: any;
  PatientData:boolean=true;
  patientId:number=0;
  creditId:number=0;
  previousCredits:any[]=[];
  PatientInformation={
    "regNo":"",
    "patient":"",
    "emirateID":""
  };
  currentDate ="";


  public submitted: boolean = false;
  public imageComponents: any;
  public imageUrl: any;
  private baseUrl: string = '';

   form: FormGroup; // form variable

   getAllSponsorsData:any;
   globals: globalvars = new globalvars();
   subscription: Subscription;
   getAllSubAgentsDatas:any[] = [];

  public creditItems: any[] = [];
  isLoading = false;
  private allItems: any[] = [];

   public isEditMode: boolean = false;
   previousUrl: string = null;
   currentUrl: string = null;


   sponserRules:any[]=[];
   credirform:CreditJson;
   patientCredits:any[]=[];
  minDate: Date;
    copyCreditId: number;
    actionName: string;
    copyFlag: boolean = false;
    credConsultationId = 0;
    expValidDate: any;
    waitingDate: any;
    waitingDays: number = 0;

  constructor(
    private modalService: BsModalService,
    private SponsorsService: SponsorsService,
    private sharedservice: SharedService,
    private billingService:BillingService,
    private toastr: ToastrService,
    public  datepipe: DatePipe,
    private patientService: PatientService,
    private configurationManager: ConfigurationManager,
    private router: Router,
    private _location: Location
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        let locationId=this.globals.Location.locationId;
        this.globals.HospitalId;
      }
    });


    if(this.router.getCurrentNavigation().extras.state){
      //  debugger
      let editData=this.router.getCurrentNavigation().extras.state.editData;
      this.consultationId = editData.consultationId
      if(editData){
        this.patientId=editData.patientId;
        // console.log('editdata', editData);
        if(editData.creditId == 'copyCredit') {
          this.creditId = 0;
          this.actionName = editData.creditId;
          this.copyFlag = true;
          if(sessionStorage.getItem('copyCreditDetails')){
            let copyCreditDetails = JSON.parse(sessionStorage.getItem('copyCreditDetails'));
            this.copyCreditId = Number(copyCreditDetails.creditId);
          }
        } else{
          this.actionName = '';
          this.copyFlag = false;
          this.creditId=editData.creditId;
          this.credConsultationId = editData?.credConsultationId;
        }
        this.PatientInformation.patient=editData.patientName;
        this.PatientInformation.regNo=editData.regNo;
        this.GetPatientByRegNo(this.PatientInformation.regNo)
        this.currentUrl = editData.url;
        if(this.creditId && this.creditId > 0){
          this.isEditMode = true;
        }
      }


     }

  }


  ngOnInit(): void {

    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.currentDate=this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    this.minDate=new Date();
    let today = new Date();
    
    let expiry = new Date().setFullYear(today.getFullYear()+1);

    this.form = new FormGroup({
      creditId:new FormControl(0),
      creditRefNo: new FormControl({value: '', disabled: true}),
      creditType: new FormControl({value: 0 , disabled: this.isEditMode}),
      openDate: new FormControl({value: this.currentDate , disabled: this.isEditMode}, [Validators.required]),
      // waitingPeriod: new FormControl('',Validators.required),
      waitingPeriod: new FormControl(''),
      // waitingPeriodDays: new FormControl(0, [Validators.required]),
      waitingPeriodDays: new FormControl(0),
      sponsorId: new FormControl({value: '' , disabled: this.isEditMode}, Validators.required),
      patientId: new FormControl({value: '' , disabled: this.isEditMode}),
      agentId: new FormControl({value: '' , disabled: this.isEditMode}, Validators.required),
      // creditLimit: new FormControl(0, [Validators.required]),
      creditLimit: new FormControl(0),
      creditAvailed: new FormControl(0),
      validUpto: new FormControl(this.datepipe.transform(expiry, 'dd-MM-yyyy'), [Validators.required]),
      maxLimit: new FormControl(0),
      priority: new FormControl(0),
      insCardNo: new FormControl(""),
      policyNo: new FormControl("", Validators.required),
      payerId: new FormControl(""),
      certificateNo: new FormControl(""),
      dependantNo: new FormControl(0),
      policyDate: new FormControl(this.datepipe.transform(today, 'dd-MM-yyyy'), Validators.required),
      expiryDate: new FormControl("", Validators.required),
      ruleId: new FormControl({value: '' , disabled: this.isEditMode}, Validators.required),
      userId: new FormControl(0),
      active: new FormControl(1),
      branchId: new FormControl(0),
      creditItemGroupList: new FormArray([]),
      creditMaternityItemGroupList: new FormArray([]),
      dedAmount: new FormControl(0),
      dedPcnt: new FormControl(0),
      coPayAmnt: new FormControl(0),
      coPayPcnt: new FormControl(0),
      dedAmountMaternity: new FormControl(0),
      dedPcntMaternity: new FormControl(0),
      coPayAmntMaternity: new FormControl(0),
      coPayPcntMaternity: new FormControl(0),
      // gpReferal:new FormControl(true)
      gpReferal:new FormControl(false)
    });
    let waitingDate = today.setDate(today.getDate() - Number(this.form.controls.waitingPeriodDays.value))
    this.form.controls.waitingPeriod.setValue(this.datepipe.transform(waitingDate, 'dd-MM-yyyy'))
    // this.expValidDate = this.form.controls.validUpto.value;
    this.expValidDate = "";
    this.waitingDate = this.form.controls.policyDate.value;
    this.waitingDays = 0;

    this.GetAllSponsors();
    this.getServiceGroups();

    if(this.creditId>0){
       this.getOpenCreditsByPatient();
       this.loadCreditDetails(this.creditId);
    }

    if(this.actionName == 'copyCredit') {
      this.loadCreditDetails(this.copyCreditId);
      sessionStorage.removeItem('copyCreditDetails');
    }
  }

  addDataScreen(flag){
    if(flag==1){
      this.addData=true;
    }

  }
  OpenCreditsModalopen(){
    this.modalRef = this.modalService.show(this.modaltempCredit,{ class: "modal-lg modal-custom-style" });
  }

    //calling patients details Api
  GetPatientByRegNo(event) {
    this.patientService.SearchPatientByRegNo(event).subscribe((res) => {
      if (res.response.length > 0) {
        if (res.response[0].active == "0") {
          this.toastr.warning("This Patient is blocked");
        }
        let patient = {
          data: res.response[0]
        }
        this.searchevent(patient);
      } else {
        this.toastr.warning("No patients found");
      }

    });
  }

  loadAllItems(creditItemGroupList: any[]){
    let itemInput = {
      Ruleid: Number(this.form.get('ruleId').value),
      Branchid: this.globals.HospitalId,
    }

    this.billingService.GetSponsorItemForRules(itemInput).subscribe((res) => {
      let allItems: any[] = res.response;
      this.allItems = allItems;

      var normal = new FormArray([]);
      var maternity = new FormArray([]);
      normal = this.form.get("creditItemGroupList") as FormArray;
      normal.controls = [];
      maternity = this.form.get("creditMaternityItemGroupList") as FormArray;
      maternity.controls = [];
      let normalItems: any[] = creditItemGroupList.filter(item=> item.category == 'N');
      let maternityItems: any[] = creditItemGroupList.filter(item=> item.category == 'M');
      this.addMapFormGroup(normalItems,1, 'N');
      this.addMapFormGroup(maternityItems,1, 'M');
    });
  }


  //pagetitle to be displayed at the header
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);

  }

      //  checking Agi grid is loaded completely

      handleChange(flag){
        if(flag==0){
          this.PatientData=true;
          this.form.get('sponsorId').setValidators(Validators.required);
          this.form.get('agentId').setValidators(Validators.required);
          this.form.get('policyNo').setValidators(Validators.required);
          this.form.get('policyDate').setValidators(Validators.required);
          this.form.get('expiryDate').setValidators(Validators.required);
          this.form.get('ruleId').setValidators(Validators.required);
        }
        if(flag==1){
          this.PatientData=false;
          this.form.get('sponsorId').clearValidators();
          this.form.get('agentId').clearValidators();
          this.form.get('policyNo').clearValidators();
          this.form.get('policyDate').clearValidators();
          this.form.get('expiryDate').clearValidators();
          this.form.get('ruleId').clearValidators();
        }

        this.form.get('sponsorId').updateValueAndValidity();
        this.form.get('agentId').updateValueAndValidity();
        this.form.get('policyNo').updateValueAndValidity();
        this.form.get('policyDate').updateValueAndValidity();
        this.form.get('expiryDate').updateValueAndValidity();
        this.form.get('ruleId').updateValueAndValidity();

      }

       //modal open for searching patient details
  showBasicDialog() {
    this.modaltemp.open();

  }

  GetAllSponsors(){

    let Input={
      "SponsorId":0,
      "Showall":1,
      "BranchId":this.globals.HospitalId
    }
    this.SponsorsService.GetAllSponsorList(Input).subscribe((res) => {
      this.getAllSponsorsData= res.response;
    });
  }



  searchevent(patient: any) {
    // debugger
    this.patientId=patient.data.patientId;
    this.PatientInformation=patient.data;
    this.loadPreviousCredits();
    this.getOpenCreditsByPatient();
  }

  loadPreviousCredits(){
    let input={
      PatientId:this.patientId
    }

    this.billingService.GetSponsorDetailsByPatient(input).subscribe((res) => {
      this.previousCredits=res.response;
    });

  }

  getServiceGroups(){
    let Input={
      "BranchId":  Number(this.globals.HospitalId),
      "GroupId": 0,
      "ShowAll":0
    }
    this.billingService.GetGroupData(Input).subscribe((res) => {
      let response=res.response;
     // this.addMapFormGroup(response,1);
    });
  }

////////////////////FIR GEBERATING DYNAMIC FORM MULI ARRAY///////////////////////////////////////

  public addMapFormGroup(data: any[] = [], saved = 0, category: string) {
    let ServiceItems: any;
    if(category == 'M'){
      ServiceItems = this.form.get("creditMaternityItemGroupList") as FormArray;
    }else{
      ServiceItems = this.form.get("creditItemGroupList") as FormArray;
    }
     
    if (data.length > 0) {
      data.forEach(element => {
        ServiceItems.push(this.createListGroup(element, saved));
      });
    }

  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    return new FormGroup({
      entryId: new FormControl(data["entryId"]),
      groupId: new FormControl(data['groupId']),
      groupName:  new FormControl(data["groupName"]),
      dedAmt: new FormControl(data["dedAmt"]),
      dedPer: new FormControl(data["dedPer"]),
      copayAmt: new FormControl(data["coPayAmt"]),
      copayPer: new FormControl(data["copayPer"]),
      coPayMaxLimitAmt: new FormControl(data["coPayMaxLimitAmt"])
    });
  }

  ///////////////LOAD SPONSER RULES//////////////////////////////////////////////////////////////////
  loadSponsorRules(sponserId){
    let input=
      { "SponsorId":Number(sponserId), "BranchId": this.globals.HospitalId };
      this.form.patchValue({
        ruleId: ''
      })
    
    this.billingService.GetSponsorRule(input).subscribe((res) => {
      this.sponserRules=res.response;
      console.log(this.sponserRules)
      if(this.sponserRules.length && this.sponserRules.length == 1){
        this.form.patchValue({
          ruleId: this.sponserRules[0].ruleId
        })
        this.loadRuleGroups(this.form.value.ruleId)
        console.log("381")
      }     
     });
  }

  //////////////////////////////LOAD GROUP RULES?////////////////////////////////////////////////////////////
  loadRuleGroups(ruleId,creditId=0){

    let selected = _.filter(this.sponserRules, (rule: any)=>{
      return rule.ruleId == ruleId
    })
    if(selected[0].insAmountLimit > 0){
      this.form.patchValue({
        creditLimit: selected[0].insAmountLimit
      })
    }
    creditId= this.form.controls['creditId'].value;
    if(!creditId) creditId=0;

    let input={ "RuleId":Number(ruleId), "CreditId": creditId };
      this.billingService.GetCreditItemGroup(input).subscribe((groupResponse) => {

        let itemInput = {
          Ruleid: Number(ruleId),
          Branchid: this.globals.HospitalId,
        }
    
        this.billingService.GetSponsorItemForRules(itemInput).subscribe((res) => {
          let allItems: any[] = res.response;
          this.allItems = allItems;

          var normal = new FormArray([]);
          var maternity = new FormArray([]);
          normal = this.form.get("creditItemGroupList") as FormArray;
          normal.controls = [];
          maternity = this.form.get("creditMaternityItemGroupList") as FormArray;
          maternity.controls = [];
          this.addMapFormGroup(groupResponse.response,1, 'N');
          this.addMapFormGroup(groupResponse.response,1, 'M');
        });
         
       });

  }
////////////////////////////////////////////////////////LOAD SUB AGENTS////////////////////////////////////////
  loadSubAgens(sponserId){
    this.billingService.GetAgentForSponsor(sponserId).subscribe((res) => {
      let response=res.response;
      this.getAllSubAgentsDatas= response;
   });

  }
  //////////////////////SAVE CREDIT/////////////////////////////////////////////////////////////////////
  saveCredit(){

    if(this.isLoading){
      return
    }
    if(this.actionName == 'copyCredit') {
      this.form.patchValue({creditId: 0});
    }
    this.credirform=this.form.getRawValue();

    this.credirform.patientId=this.patientId;
    this.submitted=true;
    if (this.credirform.waitingPeriod && (typeof (this.credirform.waitingPeriod) == "object")) {
      this.credirform.waitingPeriod = this.datepipe.transform(new Date(this.credirform.waitingPeriod), 'dd-MM-yyyy');
    }
    else if (this.credirform.waitingPeriod){
      this.credirform.waitingPeriod =   this.credirform.waitingPeriod.replace(/\//g, "-");
    }
    if(this.credirform.patientId>0){

    if(this.form.valid){

      ////////////////////////////////////////////////////////////////////////////////////////DATE CONVERTIONS
      if (this.credirform.openDate && (typeof (this.credirform.openDate) == "object")) {
        this.credirform.openDate = this.datepipe.transform(new Date(this.credirform.openDate), 'dd-MM-yyyy');
      }
      else if (this.credirform.openDate){
        this.credirform.openDate =   this.credirform.openDate.replace(/\//g, "-");
      }

      if (this.credirform.validUpto && (typeof (this.credirform.validUpto) == "object")) {
        this.credirform.validUpto = this.datepipe.transform(new Date(this.credirform.validUpto), 'dd-MM-yyyy');
      }
      else if (this.credirform.validUpto){
        this.credirform.validUpto =   this.credirform.validUpto.replace(/\//g, "-");
      }

      if (this.credirform.policyDate && (typeof (this.credirform.policyDate) == "object")) {
        this.credirform.policyDate = this.datepipe.transform(new Date(this.credirform.policyDate), 'dd-MM-yyyy');
      }
      else if (this.credirform.policyDate){
        this.credirform.policyDate =   this.credirform.policyDate.replace(/\//g, "-");
      }

      if (this.credirform.expiryDate && (typeof (this.credirform.expiryDate) == "object")) {
        this.credirform.expiryDate = this.datepipe.transform(new Date(this.credirform.expiryDate), 'dd-MM-yyyy');
      }
      else if (this.credirform.expiryDate){
        this.credirform.expiryDate =   this.credirform.expiryDate.replace(/\//g, "-");
      }

      /////////////////////////////////////////////////////////////////////////////////////END OF DATE Convertions

      ////////Number Convertions////////////////////////////////////////////////////////////////////////////////

      this.credirform.sponsorId=Number(this.credirform.sponsorId);
      this.credirform.agentId=Number(this.credirform.agentId);
      this.credirform.agentId=Number(this.credirform.agentId);
      this.credirform.creditAvailed=Number(this.credirform.creditAvailed);
      this.credirform.priority=Number(this.credirform.priority);
      this.credirform.ruleId=Number(this.credirform.ruleId);

      // END of Number Convertion///////////////////////////////////////////////////////////////////////
      this.credirform.userId= Number(localStorage.getItem('user_id'));
      this.credirform.branchId=this.globals.HospitalId;

      // console.log('this.credirform.creditLimit', this.credirform.creditLimit);
      let wDays;
      if(Number.isNaN(this.credirform.waitingPeriodDays) || this.credirform.waitingPeriodDays == null || this.credirform.waitingPeriodDays == undefined){
        wDays = 0
      }else{
        wDays = this.credirform.waitingPeriodDays;
      }
      let payload = {
        creditId: this.credirform.creditId,
        creditRefNo: this.credirform.creditRefNo,
        creditType: this.credirform.creditType,
        openDate: this.credirform.openDate,
        // waitingPeriod: this.credirform.waitingPeriod,
        waitingPeriod: ((this.credirform.waitingPeriod == "") && (Number(wDays) == 0)) ? this.credirform.policyDate : this.credirform.waitingPeriod,
        // waitingPeriodDays : Number(this.credirform.waitingPeriodDays),
        waitingPeriodDays : Number(wDays),
        sponsorId: this.credirform.sponsorId,
        patientId: this.credirform.patientId,
        agentId: this.credirform.agentId,
        creditLimit: this.credirform.creditLimit == null ? 0 : this.credirform.creditLimit,
        creditAvailed: this.credirform.creditAvailed,
        validUpto: this.credirform.validUpto,
        ExpiryDate: this.credirform.expiryDate,
        dedAmount: this.credirform.dedAmount,
        coPayPcnt: this.credirform.coPayPcnt,
        maxLimit: this.credirform.maxLimit,
        priority: this.credirform.priority,
        policyNo: this.credirform.policyNo,
        InsCardNo:this.credirform.insCardNo,
        payerId: this.credirform.payerId,
        certificateNo: "",
        dependantNo: 0,
        policyDate: this.credirform.policyDate,    
        ruleId: this.credirform.ruleId,
        userId: this.credirform.userId,
        active: this.credirform.active,
        branchId: this.credirform.branchId,
        currentCreditCategory: "N",
        creditItemGroupList: [],
        consultationId: this.consultationId ? this.consultationId : this.credConsultationId,
        gpReferal:this.credirform.gpReferal
      }
      // debugger
      let normalCreditItems: any[] = this.form.getRawValue()?.creditItemGroupList;
      let maternityCreditItems: any[] = this.form.getRawValue()?.creditMaternityItemGroupList;

      normalCreditItems.forEach(item=>{
        let data = {
          entryId: (item.entryId),
          groupId: (item.groupId),
          dedAmt: (item.dedAmt),
          dedPer: (item.dedPer),
          coPayAmt: (item.copayAmt),
          copayPer: (item.copayPer),
          coPayMaxLimitAmt: (item.coPayMaxLimitAmt),
          category: 'N'
        }
        payload.creditItemGroupList.push(data);
      });
      maternityCreditItems.forEach(item=>{
        let data = {
          entryId: (item.entryId),
          groupId: (item.groupId),
          dedAmt: (item.dedAmt),
          dedPer: (item.dedPer),
          coPayAmt: (item.copayAmt),
          copayPer: (item.copayPer),
          coPayMaxLimitAmt: (item.coPayMaxLimitAmt),
          category: 'M'
        }
        payload.creditItemGroupList.push(data);
      })

      /////////////////FORM DATA DECLARATION//////////////////////////////

      const formData = new FormData();
      formData.append('CreditJson', JSON.stringify(payload));
      formData.append('InsCardImg', this.imageComponents);


      ////////////////API CALL//////////////////////////////
  this.isLoading = true;
      this.billingService.InsertCredit(formData).subscribe((res) => {
  this.isLoading = false;

        this.submitted=false;
            if(res.status==200 && res.message=="success"){
               this.toastr.success("Credit Added Successfully");
               if(this.isEditMode){
                this.goBack()
               }
               this.clearForm();
            }
            else if(res.status==200){
              this.toastr.error(res.message);
            }
            else{
              this.toastr.error(res.message);
            }
      });
    }else{
      this.toastr.error('Fill all fields in order to proceed.')
    }
    // this.isLoading = false;
  }
    else{
      console.log(this.credirform)
      this.toastr.error("Please select a patient to continue");
    }


  }
  ///////////////CLEAR FORM AFTER SAVE  ALTER THUS IF ANY CHANGE IN FORM DEF////////////

  clearForm(){
    var normal = new FormArray([]);
    var maternity = new FormArray([]);
    normal = this.form.get("creditItemGroupList") as FormArray;
    normal.controls = [];
    maternity = this.form.get("creditMaternityItemGroupList") as FormArray;
    maternity.controls = [];
    this.handleChange(0);
    this.form.reset({
      creditId:0,
      creditRefNo: "",
      creditType: 0,
      openDate: "",
      sponsorId: 0,
      patientId: 0,
      agentId: 0,
      creditLimit: 0,
      creditAvailed: 0,
      validUpto: "",
      dedAmount: 0,
      dedPcnt: 0,
      coPayAmnt: 0,
      coPayPcnt: 0,
      dedAmountMaternity: 0,
      dedPcntMaternity: 0,
      coPayAmntMaternity: 0,
      coPayPcntMaternity: 0,
      maxLimit: 0,
      priority: 0,
      policyNo: "",
      insCardNo:"",
      payerId: "",
      certificateNo: "",
      dependantNo: 0,
      policyDate: "",
      expiryDate: "",
      ruleId: 0,
      userId: 0,
      active: 1,
      branchId: 0,
      creditItemGroupList: new FormArray([]),
      creditMaternityItemGroupList: new FormArray([])
    });
    this.imageComponents = '';
  }

  // GetOpen Credits///////////////////////////////////////////////////////////////
  getOpenCreditsByPatient(){
    let input={"PatientId":this.patientId,"Branchid":this.globals.HospitalId}
    this.billingService.GetOpenCreditsByPatient(input).subscribe((res) => {
      let response=res.response;
      this.patientCredits=response;
   });
  }
  //////////////////////////////////LOAD CREDIT DETAILS//////////////////////////////////
  loadCreditDetails(creditId){
    if(creditId>0){
      let input={"Creditid":Number(creditId),"Branchid":this.globals.HospitalId}
      this.billingService.GetCredit(input).subscribe((res) => {
          if(res.response){
            let creditItemGroupList: any[] = res.response[0]['creditItemGroupList'];
            this.loadSponsorRules(res.response[0]['sponsorId']);
            this.loadSubAgens(res.response[0]['sponsorId']);
            this.form.patchValue(res.response[0]);
            this.form.patchValue({
              policyDate: res.response[0]['policyDate'].replace(/\//g, "-"),
              waitingPeriod: res.response[0]['waitingPeriod'].replace(/\//g, "-"),
            });
            if(this.actionName == 'copyCredit') {
              this.form.controls.creditId.reset();
              this.form.patchValue({creditId: 0, policyNo: ''});
            }

            if(res.response[0].insCardImgName != ''){
              this.imageUrl = this.baseUrl + res.response[0].insCardImgName;
            }

            this.handleChange(res.response[0]['creditType']);

              if(creditItemGroupList){
                this.loadAllItems(creditItemGroupList);
              }
          }
      });
    }
  }
  /////////////////////applyToAll
  applyToAll(){
    let dedAmount=this.form.get("dedAmount").value;
    let dedPcnt=this.form.get("dedPcnt").value;
    let coPayAmnt=this.form.get("coPayAmnt").value;
    let coPayPcnt=this.form.get("coPayPcnt").value;

    this.form.get('creditItemGroupList')['controls'].forEach((element,i)=>{
      element.patchValue({dedAmt: dedAmount});
      element.patchValue({dedPer: dedPcnt});
      element.patchValue({copayAmt: coPayAmnt});
      element.patchValue({copayPer: coPayPcnt})
      }
    )

  }

  applyToAllMaternity(){
    let dedAmount=this.form.get("dedAmountMaternity").value;
    let dedPcnt=this.form.get("dedPcntMaternity").value;
    let coPayAmnt=this.form.get("coPayAmntMaternity").value;
    let coPayPcnt=this.form.get("coPayPcntMaternity").value;

    this.form.get('creditMaternityItemGroupList')['controls'].forEach((element,i)=>{
      element.patchValue({dedAmt: dedAmount});
      element.patchValue({dedPer: dedPcnt});
      element.patchValue({copayAmt: coPayAmnt});
      element.patchValue({copayPer: coPayPcnt})
      }
    )

  }

  get f() {
    return this.form.controls;
  }

  loadPartyId(agentId){
    let agent = this.getAllSubAgentsDatas.filter(agent=> agent.agentId == agentId);
    this.form.patchValue({
      payerId: agent[0].payerId
    })
  }

  goBack(){
    if(this.currentUrl == '/AddConsultation') {
      let input={
        status: 'close credit',
        patientId: this.patientId
      }
      this.router.navigate(['/AddConsultation'], { state: { editData: input }, replaceUrl: true  });
    } else {
      this._location.back();
    }
  }

  showItemWiseData(data: any){
    let groupId = data.value.groupId;
    let selectedGroupItems: any[] = []
    selectedGroupItems = this.allItems.filter(item=> item.insuranceGroupid == groupId);
    // selectedGroupItems.forEach((selected, index)=>{
    //   let i = this.creditItems.findIndex(item=> item.itemId == selected.itemId);
    //   if(i >= 0){
    //     selectedGroupItems[index] = this.creditItems[i];
    //   }
    // });

    let initialState = {
      creditItems: selectedGroupItems,
      groupName: data.value.groupName
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(CreditItemsModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    // this.modalRef.content.settlementEventEmit.subscribe(res=>{
    //   if(res.status){
    //     this.GetBillList();
    //   }
    // });
  }

  openWebCamera(){
    let initialState = {
      
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(WebCameraModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
    // this.modalRef.content.settlementEventEmit.subscribe(res=>{
    //   if(res.status){
    //     this.GetBillList();
    //   }
    // });
  }

  scanDocument(){
    let initialState = {
      
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(ScanInsuranceCardModalComponent, Object.assign({}, config, {class: 'modal-md', initialState}));
    modalRef.content.fileCreatedEmitter.subscribe((res: any)=>{
      if(res.status){
        this.imageComponents = res.file;
        this.imageUrl = res.filePreview
      }
    })
}


  onSelectFile(event: any){
    let file = event.target.files[0];
    if(file.type == 'application/pdf' || file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg'){
      this.imageComponents = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) =>{
        this.imageUrl = reader.result;
      }
    }else{
      this.imageComponents = '';
      this.toastr.warning("Unsupported file..! Please select a pdf file or a image (.jpg, .jpeg, .png)")
    }
  }

  viewAttachment(){
    let initialState = {
      fileUrl: this.imageUrl
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(FilePreviewModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
  }

  onNormalDataInput(field: string){
    if(field == 'dedPcnt'){
      this.form.get('dedAmount').setValue(0);
    }else if(field == 'dedAmount'){
      this.form.get('dedPcnt').setValue(0);
    }

    if(field == 'coPayAmnt'){
      this.form.get('coPayPcnt').setValue(0);
    }else if(field == 'coPayPcnt'){
      this.form.get('coPayAmnt').setValue(0);
    }
  }

  onMaternityDataInput(field: string){
    if(field == 'dedPcntMaternity'){
      this.form.get('dedAmountMaternity').setValue(0);
    }else if(field == 'dedAmountMaternity'){
      this.form.get('dedPcntMaternity').setValue(0);
    }

    if(field == 'coPayPcntMaternity'){
      this.form.get('coPayAmntMaternity').setValue(0);
    }else if(field == 'coPayAmntMaternity'){
      this.form.get('coPayPcntMaternity').setValue(0);
    }
  }

  onTableValuesChange(data: FormGroup, field: string){
    if(field == 'dedAmt'){
      data.get('dedPer').setValue(0);
    }else if(field == 'dedPer'){
      data.get('dedAmt').setValue(0);
    }

    if(field == 'copayAmt'){
      data.get('copayPer').setValue(0);
    }else if(field == 'copayPer'){
      data.get('copayAmt').setValue(0);
    }
  }

  changeExpDate(label){
    if(label == 1){
      this.form.controls.expiryDate.setValue(this.form.controls.validUpto.value);
      this.expValidDate = this.form.controls.validUpto.value;
    }else if(label == 2){
      this.form.controls.validUpto.setValue(this.form.controls.expiryDate.value);
      this.expValidDate = this.form.controls.validUpto.value;
    }

  }

  changeWait(label) {
    let tempPolDate = this.form.controls.policyDate.value
    if (Number(this.form.controls.waitingPeriodDays.value) == null) {
      // this.waitingDays = 0
      this.form.controls.waitingPeriod.setValue(this.form.controls.policyDate.value)
    }
    // if(Number(this.form.controls.waitingPeriodDays.value) !== null){
    let dateObject: Date;
    let PolDate: Date;
    let wait;
    let sat = this.form.controls.policyDate.value;
    if (typeof (this.form.get('policyDate').value) !== "object") {
      dateObject = this.convertStringToDate(this.form.controls.policyDate.value);
      wait = dateObject?.setDate(dateObject?.getDate() + Number(this.form.controls.waitingPeriodDays.value));
      this.form.controls.waitingPeriod.setValue(this.datepipe.transform(wait, 'dd-MM-yyyy'))
    } else {
      // let tempPolDate = this.form.controls.policyDate.value
      PolDate = this.form.controls.policyDate.value;
      const parsedDate = new Date(PolDate); // Parsing the date string
      let formatDate = this.datepipe.transform(parsedDate, 'dd-MM-yyyy');
      let dateObj: Date = this.convertStringToDate(formatDate);
      // wait = PolDate?.setDate(PolDate?.getDate() + Number(this.form.controls.waitingPeriodDays.value));
      wait = dateObj?.setDate(dateObj?.getDate() + Number(this.form.controls.waitingPeriodDays.value))
      this.form.controls.policyDate.setValue(tempPolDate)
      this.form.controls.waitingPeriod.setValue(this.datepipe.transform(wait, 'dd-MM-yyyy'))
    }
    // }
  }

  onChangePolicyDate(){
    let dateObject: Date;
    let wait;

    if (typeof (this.form.get('policyDate').value) !== "object") {
      dateObject = this.convertStringToDate(this.form.controls.policyDate.value);
      wait = dateObject?.setDate(dateObject?.getDate() + Number(this.form.controls.waitingPeriodDays.value));
    } else {
      let PolDate = this.form.value.policyDate
      let test = this.datepipe.transform(PolDate, 'dd-MM-yyyy')
      dateObject = this.convertStringToDate(test);
      wait = dateObject?.setDate(dateObject?.getDate() + Number(this.form.controls.waitingPeriodDays.value))
    }
    this.form.patchValue({waitingPeriod: this.datepipe.transform(wait, 'dd-MM-yyyy')})
  }

  convertStringToDate(dateString: string): Date {
    return parse(dateString, 'dd-MM-yyyy', new Date());
  }

  parseDateString(dateStr: string): Date {
    // Check if the dateStr is in the format dd-MM-yyyy
    const parts = dateStr?.split('-');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Month is 0-based in JavaScript Date
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    }
    // If not, try to create a date object directly
    const date = new Date(dateStr);
    if (!isNaN(date?.getTime())) {
      return date;
    }
    // throw new Error('Invalid date format');
  }
  
  calculateDaysBetween() {
    let date1: string | Date;
    let date2: string | Date;
    date1 = this.form.controls.policyDate.value;
    date2 = this.form.controls.waitingPeriod.value;

    // Convert inputs to Date objects
    let d1: Date;
    let d2: Date;
  
    if (typeof date1 === 'string') {
      d1 = this.parseDateString(date1);
    } else {
      d1 = date1;
    }
  
    if (typeof date2 === 'string') {
      d2 = this.parseDateString(date2);
    } else {
      d2 = date2;
    }
  
    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(d2?.getTime() - d1?.getTime());
  
    // Convert time difference from milliseconds to days
    const differenceInDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  
    // return differenceInDays;
    // this.form.controls.waitingPeriodDays.setValue(differenceInDays);
    this.waitingDays = Number(differenceInDays);
  }
}
