import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../../shared/models/GlobalVars';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { ConsentService } from '../../../services/consent.service';
import { SharedService } from '../../../../../shared/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfigurationManager } from '../../../../../../assets/configuration/configuration-manager';
import { Response } from '../../../../../_Models/Response';
import { PatientService } from '../../../services/patient.service';

@Component({
  selector: 'app-riayati-consent',
  templateUrl: './riayati-consent.component.html',
  styleUrls: ['./riayati-consent.component.scss']
})
export class RiayatiConsentComponent implements OnInit,OnChanges {

  @Input() ConsultationId;
  @Input() patientId;

  public fileEmitter: EventEmitter<any> = new EventEmitter();
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  public documentLoading: boolean = false;
  public documentForm: FormGroup;
  public signatureAdded: boolean = false;
  private baseUrl: string = '';
  private canvasContext: CanvasRenderingContext2D;
  private signStarted: boolean = false;
  reportLoc: string = '';
  pdfUrl:string=''

  @ViewChild('canvas') canvasEl: ElementRef;
  @ViewChild("canvas1") canvasEl1: ElementRef;

  signaturePad: SignaturePad;
  signaturePadWitness:SignaturePad
  update:boolean = true;
  public viewerContainerStyle = {
    position: "relative",
    width: "100%",
    height: "550px",
    ["font-family"]: "ms sans serif",
  };
  public serviceUrl: string = "";
  telerikName: string;
  public reportParams;
  guardianSign: boolean=true;

  constructor(
    private ConsentService: ConsentService,
    private sharedservice: SharedService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private activeModal: BsModalRef,
    private configurationManager: ConfigurationManager,
    private cdr: ChangeDetectorRef,
    private patientService: PatientService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
   }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.ConsultationId){
      
    }
  }

  ngOnInit(): void {
    this.reportParams = {
      PatientId: this.patientId,
      BranchId: this.BranchesId,
      ConsultationId: this.ConsultationId ? this.ConsultationId : 0,
    };
    this.telerikName = 'Rpt_RiayatiConsentForm.trdp';
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.serviceUrl = (this.configurationManager as any).reportServiceUrl;
    this.initDocumentForm();
    this.getpatientByid()
    // this.GetNabithOptInOptOutPDF();
    console.log("ConsultationId",this.ConsultationId)
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.signaturePad.maxWidth = 0.9;

    this.signaturePadWitness = new SignaturePad(this.canvasEl1.nativeElement);
    this.signaturePadWitness.maxWidth = 0.9;

  }

  initDocumentForm(){
    this.documentForm = this.formBuilder.group({
      documentPath: new FormControl(''),
    })
  }

  close(){
    this.fileEmitter.emit({
      status: true,
    });
    this.activeModal.hide();
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  onUpdatePatientSignature(){
    if(this.guardianSign && this.signaturePadWitness.isEmpty()){
      this.toastrService.warning("add Legal representative signature")
    }else if(!this.guardianSign && this.signaturePad.isEmpty()){
      this.toastrService.warning("add patient signature")
    }
    const signName = "sign.jpeg";
    let base64Data = this.signaturePad.toDataURL();
    let signsplit = base64Data.split(';');
    const signBlob = this.dataURItoBlob(signsplit[1].split(',')[1]);
    const signFile = new File([signBlob], signName, { type: 'image/jpeg' });

    const witnessSignName = "witnesssign.jpeg";
    let base64DataWitness = this.signaturePadWitness.toDataURL();
    let witnessSignsplit = base64DataWitness.split(';');
    const witnessSignBlob = this.dataURItoBlob(witnessSignsplit[1].split(',')[1]);
    const witnessSignFile = new File([witnessSignBlob], witnessSignName, { type: 'image/jpeg' });

    const formData = new FormData();

    console.log(this.signaturePad.isEmpty())

    formData.append('Sign', this.signaturePad.isEmpty() == true ? null : signFile);
    formData.append('WitnessSign', this.signaturePadWitness.isEmpty() == true ? null :witnessSignFile);
    
    let data={
      'BranchId': this.BranchesId,
      'Patientid': this.patientId,
      'ConsultationId': this.ConsultationId
    }
    // formData.append('BranchId', this.BranchesId);
    // formData.append('Patientid', this.patientId);
    // formData.append('ConsultationId', this.ConsultationId);
    formData.append("ConsentJson",JSON.stringify(data))
    this.documentLoading = true;
    this.update = false;
    this.ConsentService.UpdateHIConsentDetails(formData).subscribe({
      next: (response: Response<any>)=>{
        this.documentLoading = false;
        if(response.status == 200){
          this.update = true;
        }else{
          this.toastrService.error(response.errorMessage.message)
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

  // startDrawing(event: MouseEvent) {
  //   this.canvasContext.beginPath();
  //   this.canvasContext.moveTo(event.offsetX, event.offsetY);
  //   this.signStarted = true;
  // }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  draw(event: MouseEvent) {
    if(this.signStarted){
      this.canvasContext.lineTo(event.offsetX, event.offsetY);
      this.canvasContext.stroke();
    }
  }

  stopDrawing(){
    this.canvasContext.closePath();
    this.signStarted = false;
  }

  clearSignature(){
    // this.canvasContext.clearRect(0,0,200,100);
    this.signaturePad.clear();
    this.signaturePadWitness.clear()
  }

  getpatientByid(){
    this.patientService.GetPatientDetailsbyId(this.patientId).subscribe((res) => {
      let resp=res.response[0]
      this.guardianSign=resp.ageInYear < 18 ? true : false
    })
  }


}
