import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
// field which stores a schedule data
 schedule:any;
  constructor() { }
  // method to set values of schedule
   setSchedule(schedule) {
    this.schedule=schedule;
 }

 // method to read values of schedule 
  readSchedule() {
     return this.schedule;
    
 }
}
