import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SearchPatientModalComponent } from '../../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { BillingService } from '../../services/billing.service';

@Component({
  selector: 'app-new-reciept-modal',
  templateUrl: './new-reciept-modal.component.html',
  styleUrls: ['./new-reciept-modal.component.scss']
})
export class NewRecieptModalComponent implements OnInit {

  @Input() globals: globalvars;
  @Input() regNo: string = '';
  @Input() patientId: number; 
  @Input() transId: number;

  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;

  public ReceiptPopupHeading = "Add Receipt";

  public form: FormGroup;

  public PatientData: boolean = true;
  public sponsorList: boolean = true;
  public ledgerlist: boolean = true;
  public submitted: boolean = false;
  public isLoading: boolean = false;

  public isCardVisble: boolean = false;
  public refvisible: boolean = false;
  public isChequeateVisible: boolean = false;

  public creditList: any[];
  public billBalance: any[];
  public PaymentRefList:any[]
  public GetLedgers: any[];
  public GetSponsorFormsList: any[];
  public CardTypes: any[];

  public PatientInformation = {
    "regNo": "",
    "patient": ""
  };

  constructor(private modalRef: BsModalRef,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              private billingService: BillingService) { }

  ngOnInit(): void {

    this.initRecieptForm();
    if(this.regNo){
      this.GetPatientByRegNo();
    }

    if(this.transId){
      this.hidesponsor(0);
      this.hidePatientDetails(0)
    }

    this.getPaymentRef();
    this.GetAllLedgers();
    this.GetAllSponsorForms();
  }

  initRecieptForm(){
    this.form = new FormGroup({
      receiptId: new FormControl(0),
      receiptNo: new FormControl(""),
      recDate: new FormControl(this.datePipe.transform(new Date(), 'dd-MM-yyyy'), [Validators.required]),
      recType: new FormControl(this.transId ? '0' : '', [Validators.required]),
      headId: new FormControl(0),
      PaymentRef:new FormControl(""),
      patientId: new FormControl(0),
      creditId: new FormControl(0),
      amount: new FormControl('', [Validators.required]),
      mode: new FormControl('', [Validators.required]),
      remarks: new FormControl(""),
      cardId: new FormControl(''),
      outstandingCreditBalace: new FormControl(0),
      dueNo: new FormControl(0),
      cardNo: new FormControl(""),
      chqNo: new FormControl(''),
      chqDate: new FormControl(''),
      chqBranch: new FormControl(""),
      userId: new FormControl(0),
      status: new FormControl("N"),
      locationId: new FormControl(this.globals.Location.locationId),
      shiftId: new FormControl(0),
      transId: new FormControl(this.transId ? this.transId: ''),
      sponsorId: new FormControl(0),
      branchId: new FormControl(Number(this.globals.HospitalId)),
      isDisplayed: new FormControl(1),
      isDeleted: new FormControl(0),
      sessionId: new FormControl(0),
      regNo: new FormControl(this.regNo),
      FromDateRef: new FormControl(this.datePipe.transform(new Date().setDate(new Date().getDate()-7), 'dd-MM-yyyy')),
      ToDateRef: new FormControl(this.datePipe.transform(new Date(), 'dd-MM-yyyy')),
      patientName: new FormControl(""),
    });
  }

  closeModal(){
    this.modalRef.hide();
  }

  get f() {
    return this.form.controls;
  }

  hidePatientDetails(value) {
    if (value == 3 || value == 2) {
      this.PatientData = false;
    }
    else {
      this.PatientData = true;
    }
  }

  hidesponsor(value) {
    if (value == 0 || value == 1) {
      this.sponsorList = false;
      this.ledgerlist = false;
    }
    else if (value == 2) {
      this.sponsorList = false;
    }
    else {
      this.sponsorList = true;
      this.ledgerlist = true;
    }
  }
  
  osBalance(value) {
    this.form.patchValue({ outstandingCreditBalace: value.outstandingCreditBalace });
  }

  //////////get Credit balance  /////////
  GetCreditBalance() {
    let input = {
      "branchId": this.globals.HospitalId,
      "patientId": this.patientId
    }
    this.billingService.GetCreditBalance(input).subscribe((res) => {
      this.creditList = res.response;
    });
  }

  loadAmount(transId) {
    let item = this.billBalance.filter(x => x.transId == transId)[0];
    this.form.patchValue({ dueNo: item.dueAmt });
    this.form.patchValue({ amount: item.dueAmt });
  }

  getPaymentRef(isEdit?:any){
    let input = this.form.getRawValue();
    if (input.FromDateRef && (typeof (input.FromDateRef) == "object")) {
      input.FromDateRef = this.datePipe.transform(new Date(input.FromDateRef), 'dd-MM-yyyy');
      
    }
    if (input.ToDateRef && (typeof (input.ToDateRef) == "object")) {
      input.ToDateRef = this.datePipe.transform(new Date(input.ToDateRef), 'dd-MM-yyyy');
      
    }
    let payload={
      FromDate:isEdit ? "" :input.FromDateRef,
      Todate:isEdit? "" : input.ToDateRef,
      BranchId: this.globals.HospitalId,
      // paymentRef:""
    }

    
    this.billingService.GetPaymentRef(payload).subscribe({next:(res)=>{
      this.PaymentRefList=res.response
    }})
  }

    //////////////////get Credit caerd type/////////////////////
    GetCardType(value) {

      if (value == 2) {
        this.billingService.GetCardTypes(this.globals.HospitalId).subscribe((res) => {
          this.CardTypes = res.response;
        });
      }
    }


  ChangeModeOfpay(modeofpay) {

    if (modeofpay == 2) {
      this.isCardVisble = true;
      this.isChequeateVisible = false;
      this.refvisible=true;

      this.GetCardType(modeofpay);
    }
    else if (modeofpay == 3) {
      this.isChequeateVisible = true;
      this.isCardVisble = false;
      this.refvisible=true;

    }
    else if (modeofpay == 1) {
      this.refvisible = false;
      this.isChequeateVisible = false;
      this.isCardVisble = false;

    }

    else {
      this.isChequeateVisible = false;
      this.isCardVisble = false;
      this.refvisible = true;
    }
  }


   ///////////save Receipt list//////////////////////
   saveReceipt() {
    this.submitted = true;
    if (this.form && this.form.valid) {
      let formdata = this.form.getRawValue();
      formdata.BranchId = Number(this.globals.HospitalId);
      formdata.locationId = Number(this.globals.Location.locationId);
      formdata.headId = Number(formdata.headId);
      formdata.PaymentRef = (formdata.PaymentRef);
      
      formdata.creditId = Number(formdata.creditId);
      formdata.userId = Number(localStorage.getItem("user_id"));
      formdata.mode = Number(formdata.mode);
      formdata.amount = Number(formdata.amount);
      formdata.dueNo = Number(formdata.dueNo);
      formdata.cardId = Number(formdata.cardId);
      formdata.recType = Number(formdata.recType);
      formdata.transId=Number(formdata.transId);
      formdata.sponsorId = Number(formdata.sponsorId);
      
      if (formdata.chqDate && (typeof (formdata.chqDate) == "object")) {
        formdata.chqDate = this.datePipe.transform(new Date(formdata.chqDate), 'dd-MM-yyyy');
      }
      if (formdata.recDate && (typeof (formdata.recDate) == "object")) {
        formdata.recDate = this.datePipe.transform(new Date(formdata.recDate), 'dd-MM-yyyy');
      }
      formdata.isDisplayed = 1;
      formdata.status = "N";
      let dataid = 0;
      dataid = formdata.receiptId
      let pId = this.patientId;
      if (typeof pId === "number" && !isNaN(pId)) {
        formdata.patientId = Number(pId);
      }
      else {
        formdata.patientId = 0;
      }
      if(formdata.recType==3 && formdata.recType==2){
        formdata.patientId = 0;

      }
      if (formdata.recType == 3 && formdata.sponsorId == 0) {
        this.toastr.warning("please select sponsor ");
      }
      else if ( (formdata.recType == 0 || formdata.recType == 1 ) && (formdata.patientId == 0 || !formdata.patientId )) {
        this.toastr.warning("please select patient details");
      }
      else if((formdata.patientId != 0 )&&(formdata.creditId==0)&&(formdata.recType==0)){
        this.toastr.warning("please select credit");
      }
      else if((formdata.patientId != 0 )&&(formdata.transId==0)&&(formdata.recType==0)){
        this.toastr.warning("please select bill no");
      }
      else if((formdata.mode != 1 )&&(formdata.cardNo==0)){
        this.toastr.warning("please select Refferance no");
      }

      else if((formdata.patientId != 0 )&&(formdata.amount > formdata.dueNo)&&(formdata.recType==0)){
        this.toastr.warning("Amount is greater than due amount..!");
      }

      else {
        if(formdata.recType != 3){
          delete formdata.PaymentRef
        }
  
        this.isLoading = true;
        if(formdata.PaymentRef === ""){
          delete formdata.PaymentRef
        }
        delete formdata.FromDateRef
        delete formdata.ToDateRef
        this.billingService.saveReceiptLists(formdata).subscribe((res) => {
          this.submitted = false;
          if (res.status == 200 && res.message == 'Success') {
            if (dataid == 0) {
              this.isLoading = false;
              this.toastr.success("Receipt added Successfully");
            }
            else {
              this.isLoading = false;
              this.toastr.success("Receipt Edited Successfully");
            }
            this.modalRef.hide();
          }
          else {
            this.isLoading = false;
            this.toastr.error("Failed : " + res.message);
          }
        })

      }
    }
    // }

  }


  GetPatientByRegNo() {
    this.billingService.SearchPatientByRegNo(this.form.get("regNo").value).subscribe((res) => {
      let patients = res.response;
      if (patients.length > 0) {
        if (patients[0].active == "c") {
          this.toastr.warning("This Patient is blocked");
        }
        this.PatientInformation = patients[0];
        this.patientId = patients[0]?.patientId;
        this.form.patchValue({ 
          patientName: this.PatientInformation.patient,
          regNo: this.PatientInformation.regNo
        });
        this.GetCreditBalance();
        this.GetBillBalance();
      } else {
        this.toastr.warning("No patients found");
      }
    });
  }

  GetBillBalance() {
    let input = {
      "branchId": this.globals.HospitalId,
      "patientId": this.patientId
    }
    this.billingService.GetBillBalance(input).subscribe((res) => {
      this.billBalance = res.response;
      if(this.transId){
        this.loadAmount(this.transId)
      }
    });

  }

  /////////////Ledger///////////////////
  GetAllLedgers() {
    let LedgerId = 0;
    let showALL = 0;
    let BranchId = this.globals.HospitalId;

    this.billingService.GetLedger(LedgerId, showALL, BranchId).subscribe((res) => {
      this.GetLedgers = res.response;
    });
  }
  ///////////////////////Get Sponsor/////////////////
  GetAllSponsorForms() {
    let Input = {
      "ShowAll": 0,
      "SponsorId": 0,
      "BranchId": this.globals.HospitalId
    }
    this.billingService.GetAllSponsorForm(Input).subscribe((res) => {
      this.GetSponsorFormsList = res.response;
    });
  }


   ////search for patient/////
   searchevent(patient: any) {
    this.patientId = patient.data.patientId;
    this.PatientInformation = patient.data;
    this.form.patchValue({ patientName: this.PatientInformation.patient});
    this.form.patchValue({ regNo: this.PatientInformation.regNo});

    this.GetCreditBalance();
    this.GetBillBalance();
  }
  showBasicDialog() {
    this.modaltemp.open();
  }

}
