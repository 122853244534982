import { Component, OnInit, Output, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { BillingGeneralActionComponent } from '../billing-general-action/billing-general-action.component';
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { BillingService } from '../services/billing.service';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { ConfigurationService } from '../../client/services/configuration.service';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';

@Component({
  selector: 'app-receipts-list',
  templateUrl: './receipts-list.component.html',
  styleUrls: ['./receipts-list.component.scss']
})
export class ReceiptsListComponent implements OnInit {
  @Output() returnevent = new EventEmitter();

  modalRef: BsModalRef;
  modalRef1: BsModalRef
  @ViewChild("template2") modaltemp2: any;

  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;
  @ViewChild("template") modaledit: any;

  form: FormGroup;
  searchFoem: FormGroup;
  cancelForm: FormGroup;
  // AG GRID ITEMS
  display: boolean = false;
  ReceiptPopupHeading = "Add Receipt";
  public statusBar;
  rowGroupPanelShow = "always";
  public gridApi;
  columnDefs: ColDef[] = [];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "row-cancelled": function (params) { return params.data.status == 'C'; },
    },
    domLayout: "autoHeight",
  }
  public context;
  public frameworkComponents;
  public loadingTemplate;
  public noRowsTemplate;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  GetLedgers: any;
  GetLedgersData: any[];
  GetSponsorFormsData: any[];
  billBasket: any[] = [];
  cancelReason: any[] = [];
  GetSponsorFormsList: any;
  id: Number;
  receiptList: any[];
  creditList: any[];
  isLoading = false;
  iscalcelLoading: boolean = false;
  billBalance: any[];
  fromDate: string = "";
  todate: string = "";
  CardTypes: any;
  BranchesId: number;
  PatientData: boolean = true;
  sponsorList: boolean = true;
  ledgerlist: boolean = true;
  receiptId: any;
  isCardVisble: boolean = false;
  refvisible: boolean = false;

  isChequeateVisible: boolean = false;
  patients: any[] = [];
  patientId: number;
  result: any;
  PatientInformation = {
    "regNo": "",
    "patient": ""
  };
  outstandingCreditBalace: any;
  userId: number;
  patsId: number;
  rId: any;
  rectdemo: number;
  public submitted: boolean = false;
  transId:any;
  PaymentRefList:any[]
  functionPermissionData: any;

  constructor(
    private modalService: BsModalService,
    private sharedservice: SharedService,
    private billingService: BillingService,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private ConfigurationService: ConfigurationService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        let locationId = this.globals.Location.locationId;

        this.BranchesId = this.globals.HospitalId
      }
    });
  }

  ngOnInit(): void {
    
    this.context = { componentParent: this };
    let dte = new Date();
    dte.setDate(dte.getDate() - 7);
    this.fromDate = this.datepipe.transform(dte, 'dd-MM-yyyy');
    let currentappDate = new Date();
    this.todate = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');

    this.form = new FormGroup({
      receiptId: new FormControl(0),
      receiptNo: new FormControl(""),
      recDate: new FormControl(this.todate, [Validators.required]),
      recType: new FormControl(this.todate, [Validators.required]),
      headId: new FormControl(0),
      PaymentRef:new FormControl(""),
      patientId: new FormControl(0),
      creditId: new FormControl(0),
      amount: new FormControl('', [Validators.required]),
      mode: new FormControl('', [Validators.required]),
      remarks: new FormControl(""),
      cardId: new FormControl(''),
      outstandingCreditBalace: new FormControl(0),
      dueNo: new FormControl(0),
      cardNo: new FormControl(""),
      chqNo: new FormControl(0),
      chqDate: new FormControl(''),
      chqBranch: new FormControl(""),
      userId: new FormControl(0),
      status: new FormControl("N"),
      locationId: new FormControl(this.globals.Location.locationId),
      shiftId: new FormControl(0),
      transId: new FormControl(0),
      sponsorId: new FormControl(0),
      branchId: new FormControl(Number(this.globals.HospitalId)),
      isDisplayed: new FormControl(1),
      isDeleted: new FormControl(0),
      sessionId: new FormControl(0),
      regNo: new FormControl(),

      FromDateRef: new FormControl(this.fromDate),
      ToDateRef: new FormControl(this.todate),
      
      patientName: new FormControl(""),
    });
    this.searchFoem = new FormGroup({
      regsNo: new FormControl(),
      FromDate: new FormControl(this.fromDate),
      ToDate: new FormControl(this.todate),
      patientsName: new FormControl(),
      RecsType: new FormControl(5),

    });
    this.cancelForm = new FormGroup({
      cancelReason: new FormControl(""),

    });

    this.columnDefs = [
      {
        headerName: "Date",
        field: "recDate",
        resizable: false,
        sortable: true,
        filter: true,
        flex: 2,
        minWidth: 100,
        enableRowGroup: true,

      },
      {
        headerName: "Receipt No.",
        field: "receiptNo",
        resizable: false,
        sortable: true,
        filter: true,
        minWidth: 100,
        enableRowGroup: true,
        flex: 2,
      },
      {
        headerName: "Reg.No.",
        field: "regNo",
        resizable: false,
        filter: true,
        sortable: true,
        minWidth: 100,
        enableRowGroup: true,
        flex: 2,
      },
      {
        headerName: "Patient Name",
        field: "patientName",
        resizable: false,
        filter: true,
        sortable: true,
        minWidth: 170,
        enableRowGroup: true,
        flex: 2,
      },
      {
        headerName: "Insurance Name",
        field: "sponsorName",
        resizable: false,
        filter: true,
        sortable: true,
        minWidth: 100,
        enableRowGroup: true,
        flex: 2,
      },
      {
        headerName: "Receipt Type",
        field: "receiptType",
        resizable: false,
        filter: true,
        sortable: true,
        minWidth: 100,
        enableRowGroup: true,
        flex: 2,
      },
      {
        headerName: "Amount",
        field: "amount",
        resizable: false,
        sortable: true,
        filter: true,
        minWidth: 100,
        enableRowGroup: true,
        flex: 2,
      },
      {
        headerName: "Remarks",
        field: "remarks",
        resizable: false,
        sortable: true,
        filter: true,
        minWidth: 500,
        enableRowGroup: true,
        flex: 2,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "manageReceipt" },
      },
    ];
    this.functionPermission();
    this.GetAllLedgers();
    this.GetAllSponsorForms();
    this.GetReceiptList();
    this.frameworkComponents = { buttonRender: BillingGeneralActionComponent };
    
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  openModal(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 337)
    if (foundElement.id == 337 && foundElement.isAllowed == true) {
    this.getPaymentRef()

    this.ReceiptPopupHeading = "Add Receipt";
    this.modalRef = this.modalService.show(template, { class: "gray modal-lg" });
    this.ResetFunction();
    // this.receiptId=0;
    this.form.patchValue({ recDate:this.todate ,FromDateRef:this.todate,ToDateRef:this.todate});
    } else {
      this.toastr.warning("You are not allowed to add new receipt");
    }
  }
  ///////cancel receipt list/////////////////////
  cancelItem(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 339)
    if (foundElement.id == 339 && foundElement.isAllowed == true) {
    this.modalRef1 = this.modalService.show(
      this.modaltemp2, { class: "gray" })
    this.receiptId = data.receiptId;
    this.clearsearchform();
    } else {
      this.toastr.warning("You are not allowed to cancel receipt");
    }
  }
  cancel() {
    let input = this.cancelForm.getRawValue();
    input = {
      "ReceiptId": this.receiptId,
      "cancelReason": input.cancelReason,
      "userId": Number(localStorage.getItem("user_id")),
      "sessionId": 0,
    }
    this.iscalcelLoading = true;

    this.billingService.cancelItem(input).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200 && this.result.message == 'Success') {
        this.iscalcelLoading = false;

        this.toastr.success("Receipt cancelled Successfully");
        this.GetReceiptList();
        this.modalRef1.hide();

      } else {
        this.iscalcelLoading = false;

        this.toastr.error("Failed : " + this.result.message);
        this.clearsearchform();
      }
    });
  }
  ////search for patient/////
  searchevent(patient: any) {
    this.patientId = patient.data.patientId;
    this.PatientInformation = patient.data;
    this.form.patchValue({ patientName: this.PatientInformation.patient});
    this.form.patchValue({ regNo: this.PatientInformation.regNo});

    this.GetCreditBalance();
    this.GetBillBalance();
  }
  showBasicDialog() {
    this.modaltemp.open();
  }
  get f() {
    return this.form.controls;
  }
  ////////////Edit Receipt/////////////
  editReceipt(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 338)
    if (foundElement.id == 338 && foundElement.isAllowed == true) {
    console.log(data)
    this.getPaymentRef(true)
    this.ReceiptPopupHeading = "Edit Receipt";
    // this.rectdemo=data.recType;
    if (data.recType == 3 || data.recType == 2) {
      this.PatientData = false;
    }
    else {
      this.PatientData = true;
    }
    if (data.mode == 2) {
      this.isCardVisble = true;
      this.isChequeateVisible = false;
      this.refvisible=true;

      this.GetCardType(data.mode);
    }
    else if (data.mode == 3) {
      this.isChequeateVisible = true;
      this.isCardVisble = false;
      this.refvisible=true;

    }
    else if(data.mode==1){
      this.refvisible=false;
      this.isCardVisble = false;
      this.isChequeateVisible = false;

    }
    else {
      this.isChequeateVisible = false;
      this.isCardVisble = false;
      this.refvisible=true;
    }
    if ( (data.recType == 0 || data.recType == 1 ) && (data.patientId == 0 || !data.patientId )) {
      this.toastr.warning("please select patient details");
    }
    if (data.recType == 0 || data.recType == 1) {
      this.sponsorList = false;
      this.ledgerlist = false;
    }
    else if (data.recType == 2) {
      this.sponsorList = false;
    }
    else {
      this.sponsorList = true;
      this.ledgerlist = true;
    }
    
    this.modalRef = this.modalService.show(
      this.modaledit, { class: "gray modal-lg" });
      this.billingService.GetPaymentRef({
      FromDate: "" ,
      Todate:"" ,
      BranchId:this.BranchesId,
    }).subscribe({next:(res)=>{
        this.PaymentRefList=res.response
        this.form.patchValue(data);
        this.form.patchValue({
          PaymentRef:data.paymentRef
        })
      }})
      this.form.patchValue({ transId:data.transNo})
      this.osBalance(data.creditId);
      this.transId=data.transId,
      this.receiptId=data.receiptId,

    this.patientId = data.patientId;
    this.GetCreditBalance();
    this.GetBillBalance();
    this.form.controls['receiptId'].setValue(data.receiptId);
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  /////////////Ledger///////////////////
  GetAllLedgers() {
    let LedgerId = 0;
    let showALL = 0;
    let BranchId = this.globals.HospitalId;

    this.billingService.GetLedger(LedgerId, showALL, BranchId).subscribe((res) => {
      this.GetLedgers = res.response;
      // this.GetLedgersData = [];
      // this.GetLedgers.forEach((a) => {
      //   this.GetLedgersData.push({
      //     id: a.headId,
      //     text: a.headDesc,
      //   });
      // });
    });
  }
  ///////////////////////Get Sponsor/////////////////
  GetAllSponsorForms() {
    let Input = {
      "ShowAll": 0,
      "SponsorId": 0,
      "BranchId": this.globals.HospitalId
    }
    this.billingService.GetAllSponsorForm(Input).subscribe((res) => {
      this.GetSponsorFormsList = res.response;
      // this.GetSponsorFormsData = [];
      // if (this.GetSponsorFormsList) {
      //   this.id = this.GetSponsorFormsList[0].sFormId;
      //   this.GetSponsorFormsList.forEach((a) => {
      //     this.GetSponsorFormsData.push({
      //       id: a.sponsorId,
      //       text: a.sponsorName,
      //     });
      //   });
      // }
    });
  }
  //////////////Get Receiptlist////////////
  GetReceiptList() {
    let input = this.searchFoem.getRawValue();
    if (input.FromDate && (typeof (input.FromDate) == "object")) {
      input.FromDate = this.datepipe.transform(new Date(input.FromDate), 'dd-MM-yyyy');
    }
    if (input.ToDate && (typeof (input.ToDate) == "object")) {
      input.ToDate = this.datepipe.transform(new Date(input.ToDate), 'dd-MM-yyyy');
    }
    let Input = {
      "fromDate": input.FromDate,
      "toDate": input.ToDate,
      "RecType": input.RecsType == null ? "" : Number(input.RecsType),
      "ShowAll": 0,
      "branchId": this.globals.HospitalId,
      "RegNo": input.regsNo == null ? "" : input.regsNo,
      "PatientName": input.patientsName == null ? "" : input.patientsName,
    }
    this.billingService.GetReceiptList(Input).subscribe((res) => {
      this.receiptList = res.response;
      if (this.receiptList.length == 0) {
        this.toastr.warning("No Receipt list found");
      }
    });
  }
  //////////get Credit balance  /////////
  GetCreditBalance() {
    let input = {
      "branchId": this.globals.HospitalId,
      "patientId": this.patientId
    }
    this.billingService.GetCreditBalance(input).subscribe((res) => {
      this.creditList = res.response;
    });
  }

  osBalance(value) {
    // let input = {
    //   "branchId": this.globals.HospitalId,
    //   "patientId": this.patientId,
    //   "creditId": Number(value),
    // }
    // this.billingService.osBalance(input).subscribe((res) => {
    //   let response = res.response[0];
    //   if (response) {
    //   }
    // });
    // let item = this.creditList.filter(x => x.creditId == value)[0];
    this.form.patchValue({ outstandingCreditBalace: value.outstandingCreditBalace });

  }
  GetBillBalance() {
    let input = {
      "branchId": this.globals.HospitalId,
      "patientId": this.patientId,
      "transId":this.transId,
      "receiptId":this.receiptId
    }
    this.billingService.GetBillBalance(input).subscribe((res) => {
      this.billBalance = res.response;
      console.log(  this.billBalance);
      
    });

  }
  loadAmount(transId) {
    let item = this.billBalance.filter(x => x.transId == transId)[0];
    this.form.patchValue({ dueNo: item.dueAmt });
    this.form.patchValue({ amount: item.dueAmt });

    //  if (transId > 0) {
    //   let item = this.billBalance.filter(x => x.transId == transId)[0];
    //   let item2 = this.billBasket.filter(x => x.transId == transId)[0];
    //   if (item2 == undefined) {
    //     let itemarray = {
    //       transId: item.transId,
    //     }
    //     this.billBasket.push(itemarray);

    //   }
    //   else {

    //   }
    // }

  }

  ///////////save Receipt list//////////////////////
  saveReceipt() {
    this.submitted = true;
    if (this.form && this.form.valid) {
      let formdata = this.form.getRawValue();
      formdata.BranchId = Number(this.globals.HospitalId);
      formdata.locationId = Number(this.globals.Location.locationId);
      formdata.headId = Number(formdata.headId);
      formdata.PaymentRef = (formdata.PaymentRef);
      
      formdata.creditId = Number(formdata.creditId);
      formdata.userId = Number(localStorage.getItem("user_id"));
      formdata.mode = Number(formdata.mode);
      formdata.amount = Number(formdata.amount);
      formdata.dueNo = Number(formdata.dueNo);
      formdata.cardId = Number(formdata.cardId);
      formdata.recType = Number(formdata.recType);
      formdata.transId=Number(formdata.transId);
      formdata.sponsorId = Number(formdata.sponsorId);
      console.log("form data",formdata)
      
      if (formdata.chqDate && (typeof (formdata.chqDate) == "object")) {
        formdata.chqDate = this.datepipe.transform(new Date(formdata.chqDate), 'dd-MM-yyyy');
      }
      if (formdata.recDate && (typeof (formdata.recDate) == "object")) {
        formdata.recDate = this.datepipe.transform(new Date(formdata.recDate), 'dd-MM-yyyy');
      }
      formdata.isDisplayed = 1;
      formdata.status = "N";
      let dataid = 0;
      dataid = formdata.receiptId
      let pId = this.patientId;
      if (typeof pId === "number" && !isNaN(pId)) {
        formdata.patientId = Number(pId);
      }
      else {
        formdata.patientId = 0;
      }
      if(formdata.recType==3 && formdata.recType==2){
        formdata.patientId = 0;

      }
      if (formdata.recType == 3 && formdata.sponsorId == 0) {
        this.toastr.warning("please select Insurance ");
      }
      else if ( (formdata.recType == 0 || formdata.recType == 1 ) && (formdata.patientId == 0 || !formdata.patientId )) {
        this.toastr.warning("please select patient details");
      }
      else if((formdata.patientId != 0 )&&(formdata.creditId==0)&&(formdata.recType==0)){
        this.toastr.warning("please select credit");
      }
      else if((formdata.patientId != 0 )&&(formdata.transId==0)&&(formdata.recType==0)){
        this.toastr.warning("please select bill no");
      }
      else if((formdata.mode != 1 )&&(formdata.cardNo==0)){
        this.toastr.warning("please select Refferance no");
      }

      else if((formdata.patientId != 0 )&&(formdata.amount > formdata.dueNo)&&(formdata.recType==0)){
        this.toastr.warning("Amount is greater than due amount..!");
      }

      else {
        if(formdata.recType != 3){
          delete formdata.PaymentRef
        }
  
        this.isLoading = true;
        if(formdata.PaymentRef === ""){
          delete formdata.PaymentRef
        }
        delete formdata.FromDateRef
        delete formdata.ToDateRef
        this.billingService.saveReceiptLists(formdata).subscribe((res) => {
          this.submitted = false;
          this.result = res;
          if (this.result.status == 200 && this.result.message == 'Success') {
            if (dataid == 0) {
              this.isLoading = false;
              this.toastr.success("Receipt added Successfully");
            }
            else {
              this.isLoading = false;
              this.toastr.success("Receipt Edited Successfully");
              this.receiptId = 0;
            }
            this.display = false;
            this.modalRef.hide();
            this.GetReceiptList();
            this.ResetFunction();
            // this.refresh();
          }
          else {
            this.isLoading = false;
            this.toastr.error("Failed : " + this.result.message);
          }
        })

      }
    }
    // }

  }
  //////////////////get Credit caerd type/////////////////////
  GetCardType(value) {

    if (value == 2) {
      this.billingService.GetCardTypes(this.globals.HospitalId).subscribe((res) => {
        this.CardTypes = res.response;
      });
    }
  }
  ///////////////patient dtails hide///////////////
  hidePatientDetails(value) {
    if (value == 3 || value == 2) {
      this.PatientData = false;
    }
    else {
      this.PatientData = true;
    }
  }
  hidesponsor(value) {
    if (value == 0 || value == 1) {
      this.sponsorList = false;
      this.ledgerlist = false;
    }
    else if (value == 2) {
      this.sponsorList = false;
    }
    else {
      this.sponsorList = true;
      this.ledgerlist = true;
    }
  }
  ChangeModeOfpay(modeofpay) {

    if (modeofpay == 2) {
      this.isCardVisble = true;
      this.isChequeateVisible = false;
      this.refvisible=true;

      this.GetCardType(modeofpay);
    }
    else if (modeofpay == 3) {
      this.isChequeateVisible = true;
      this.isCardVisble = false;
      this.refvisible=true;

    }
    else if (modeofpay == 1) {
      this.refvisible = false;
      this.isChequeateVisible = false;
      this.isCardVisble = false;

    }

    else {
      this.isChequeateVisible = false;
      this.isCardVisble = false;
      this.refvisible = true;
    }
  }
  clearsearchform() {
    this.cancelForm.reset({
      cancelReason: ''
    })
  }
  ////////Clear Form/////////
  clearForm() {
    this.form.reset({
      receiptId: 0,
      receiptNo: '',
      recDate: '',
      recType: '',
      headId: 0,
      PaymentRef:"",
      patientId: 0,
      creditId: 0,
      amount: '',
      cardId:'',
      mode: '',
      remarks: '',
      cardType: '',
      cardNo: '',
      chqNo: '',
      chqDate: '',
      chqBranch: '',
      userId: 0,
      status: '',
      locationId: 0,
      shiftId: 0,
      transId: 0,
      sponsorId: 0,
      branchId: 0,
      isDisplayed: 0,
      isDeleted: 0,
      sessionId: 0,
      regNo: '',
      outstandingCreditBalace:0,
      dueNo:0,
      FromDateRef: '',
      ToDateRef: '',
      patientName: '',
    })
    this.patientId = 0;
  }
  ResetFunction() {
    this.receiptId = 0;
    this.submitted = false;
    this.clearForm();
  }

  SearchPatientDetail(patient: any) {
   
   // let patient=event.target.value.trim()
    // let _this = this;
    this.GetPatientByRegNo();
    // setTimeout(function () {
    //   this.GetCreditBalance()
    //   let patientDetail = _this.patients.filter(
    //     (patientData) => patientData.regNo == patient
    //   );
    //   if (patientDetail.length > 0) {
    //     _this.FillForm1(patientDetail[0]);
    //   }
    // }, 3000);

   
  

  }

  GetPatientByRegNo() {
    this.billingService.SearchPatientByRegNo(this.form.get("regNo").value).subscribe((res) => {
      this.patients = res.response;
      this.patientId = this.patients[0].patientId;
      this.PatientInformation=this.patients[0]
      this.form.patchValue({ patientName: this.PatientInformation.patient});
      this.GetCreditBalance();
      this.GetBillBalance();

      if (this.patients.length > 0) {
        if (this.patients[0].active == "c") {
          this.toastr.warning("This Patient is blocked");
          this.clearForm();
          this.form.patchValue({ regNo: this.patients[0].regNo });
          this.patients = [];
        }
      } else {
        this.toastr.warning("No patients found");
      }
    });
  }

  getPaymentRef(isEdit?:any){
    let input = this.form.getRawValue();
    if (input.FromDateRef && (typeof (input.FromDateRef) == "object")) {
      input.FromDateRef = this.datepipe.transform(new Date(input.FromDateRef), 'dd-MM-yyyy');
      
    }
    if (input.ToDateRef && (typeof (input.ToDateRef) == "object")) {
      input.ToDateRef = this.datepipe.transform(new Date(input.ToDateRef), 'dd-MM-yyyy');
      
    }
    let payload={
      FromDate:isEdit ? "" :input.FromDateRef,
      Todate:isEdit? "" : input.ToDateRef,
      BranchId:this.BranchesId,
      // paymentRef:""
    }

    
    this.billingService.GetPaymentRef(payload).subscribe({next:(res)=>{
      this.PaymentRefList=res.response
    }})
  }

  changeEvent(event:any){
    console.log(event)
  }

  onPaste(event){
    let clipboardData = event.clipboardData 
    let pastedText = clipboardData.getData('text');
    console.log(pastedText)
    this.SearchPatientDetail(pastedText)
  }

  FillForm1(patient: any) {
    
    console.log(patient)
    this.patientId = patient["patientId"];
    this.PatientInformation.patient= patient["patient"];
    this.PatientInformation.regNo= patient["regNo"];


    // this.patientId = patient["patientId"];
    // this.form.patchValue({ regNo: patient["regNo"] });
    // this.form.patchValue({ patientName: patient["patient"] });
    // this.form.patchValue({ patientId: patient["patientId"] });
  }

  closeModal(){
    this.clearForm()
    this.modalRef?.hide()
  }
  refresh() {
    location.reload();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  printReceipt(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 636)
    if (foundElement?.id == 636 && foundElement.isAllowed == true) {
    let initialState = {
      modalHeading: 'Print Receipt',
      reportName: 'Receipt.trdp',
      reportParams: {
        BranchId: this.BranchesId,
        ReceiptId: data.receiptId,
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
}