import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { noop, Observable, Observer, of, Subscription } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { Response } from "../../../_Models/Response";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { EMRPatientInfo } from "../interfaces/EMRPatientInfo";
import { EMRService } from "../../emr/services/emr.service";
import { ToastrService } from "ngx-toastr";
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import Swal from "sweetalert2";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ReportRequest } from "../interfaces/ReportRequest";
import { TreatmentPlanForm } from "../interfaces/TreatmentPlanForm";
import { ConfigurationService } from "../../client/services/configuration.service";
import { DentalService } from "../services/dental.service";
import { Router } from "@angular/router";

interface servicItems {
  itemId: number;
  itemCode: string;
  itemName: string;
}

@Component({
  selector: "app-follow-up-status",
  templateUrl: "./follow-up-status.component.html",
  styleUrls: ["./follow-up-status.component.scss"],
})
export class FollowUpStatusComponent implements OnInit {
  showTeethSystem: boolean = false;
  selectedRow: any;
  Loading: boolean = false;

  EMRPatientInfo: EMRPatientInfo;
  search?: string;
  suggestions$?: Observable<servicItems[]>;
  errorMessage?: string;
  private baseUrl: string = "";

  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;

  TreatmentPlanForm: TreatmentPlanForm;
  PrevTreatmentPlanForm: TreatmentPlanForm[] = [];
  form: FormGroup; // form variable
  ModalDrugs: any[] = [];

  Request: ReportRequest = new ReportRequest();
  modalRef?: BsModalRef;
  public allTreatmentDetails: any[] = [];
  CPTModifiers: any[] = [];
  showDentalChart: Boolean = false;
  selectedTeethNo: any;
  @ViewChild("template") modaltemp: any;
  showInstructor: any;
  instructorList: any;
  surfaceItem: any[] = [
    { name: "Labial", code: "Labial" },
    { name: "Lingual", code: "Lingual" },
    { name: "Mesial", code: "Mesial" },
    { name: "Distal", code: "Distal" },
    { name: "Occlusal", code: "Occlusal" },
    { name: "Lervical", code: "Lervical" },
    { name: "Inscisal", code: "Inscisal" },
  ];
  constructor(
    private http: HttpClient,
    private sharedservice: SharedService,
    private EMRService: EMRService,
    private toastr: ToastrService,
    private configurationManager: ConfigurationManager,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,
    private dentalService: DentalService,
    private router: Router
  ) {
    this.showInstructor = localStorage.getItem("isAprovedAuthority");
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  ngOnInit(): void {
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    //   this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
    //    observer.next(this.search);
    //  }).pipe(
    //    switchMap((query: string) => {
    //      if (query) {

    //        return this.http.post<Response<servicItems[]>>(
    //          `${this.baseUrl}api/EMR/GetEMRServiceItem`, {
    //          "ServiceName": query,
    //          "branchId": this.BranchesId,
    //          "GroupId":0,
    //          "consultantId":this.EMRPatientInfo.ConsultantId
    //        }).pipe(
    //          map((data) => data && data.response || []),
    //          tap(() => noop, err => {
    //            this.errorMessage = err && err.message || 'Something goes wrong';
    //          })
    //        );
    //      }

    //      return of([]);
    //    })
    //  );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */

    this.form = new FormGroup({
      procedureDetails: new FormArray([]),
      planDescription: new FormControl(""),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
    });

    this.GetDentalProcedureEMR();
    this.GetEMRServiceItems();
    this.GetAuthorisedConsultant();
    // this.GetPrevDrugsEMR();
  }

  SaveTreatMent() {
    if (this.EMRPatientInfo.VisitId > 0) {
      this.TreatmentPlanForm = this.form.getRawValue();
      if (this.TreatmentPlanForm.procedureDetails) {
        this.TreatmentPlanForm.procedureDetails.forEach(
          (res: any, index: number) => {
            let val = [];
            res.surface.forEach((i: any) => {
              val.push(i.name);
            });
            if (val.length) {
              res.surface = val.toString();
            } else {
              res.surface = ''
            }
            if(res?.notes == null) {
              res.notes = ''
            }
          }
        );
      }
      this.TreatmentPlanForm.userId = Number(localStorage.getItem("user_id"));

      this.EMRService.SaveTreatmentPlan(this.TreatmentPlanForm).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastr.success(
              "Success: " + "Treatment Plan Details Saved Successfully..."
            );
            //this.GetDentalProcedureEMR();
          } else {
            Swal.fire(
              "Error!",
              "Some unexpected error occures! try again later.",
              "error"
            );
          }
        }
      );
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  LoadItems(item) {
    this.addMapFormGroup(item);
  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    if (!data["approvalStatus"]) data["approvalStatus"] = 0;
    if (!data["billingMode"]) data["billingMode"] = 0;
    if (!data["isCompleted"]) data["isCompleted"] = 0;
    if (!data["approvalNumber"]) data["approvalNumber"] = "";
    if (!data["id"]) data["id"] = "";
    if (!data["orderId"]) data["orderId"] = 0;
    let val = []
    if(data["surface"] != ""){
      let i = data["surface"]?.split(",");
      i?.forEach((res:any)=>{
        val.push({name :res, code:res})
      })
    }
    return new FormGroup({
      id: new FormControl(data["id"]),
      itemId: new FormControl(data["itemId"]),
      teeths: new FormControl(data["teeths"]),
      qty: new FormControl(data["qty"]),
      surface: new FormControl(val.length ? val : []),
      notes: new FormControl(data["notes"]),
      orderId: new FormControl(data["orderId"]),
      approvalStatus: new FormControl(data["approvalStatus"]),
      approvalNumber: new FormControl(data["approvalNumber"]),
      billingMode: new FormControl(data["billingMode"]),
      itemName: new FormControl(data["itemName"]),
      isCompleted: new FormControl(data["isCompleted"]),
      Rate: new FormControl(data["rate"]),
      Instructor: new FormControl(data["instructor"] ? data["instructor"] : 0),
      InstructorName: new FormControl(data["instructorName"]),
    });
  }

  public addMapFormGroup(data: any[] = [], saved = 0) {
    const procedureDetails = this.form.get("procedureDetails") as FormArray;
    if (data.length > 0) {
      data.forEach((element, i) => {
        procedureDetails.push(this.createListGroup(element, saved));
      });
    } else if (data) {
      procedureDetails.push(this.createListGroup(data, saved));
    }

    this.search = "";
  }

  GetDentalProcedureEMR() {
    let input = {
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 1,
      branchId: this.BranchesId,
    };

    this.EMRService.GetFollowUpStatus(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];
        if (response) {
          if (response.procedureDetails) {
            this.addMapFormGroup(response.procedureDetails);
          }
        }
      }
    });
  }
  GetPrevDrugsEMR() {
    // let input={
    //   "VisitId":this.EMRPatientInfo.VisitId,
    //   "PatientId":this.EMRPatientInfo.PatientId,
    //   "ShowAll":0
    // };
    // this.EMRService.GetDrugsEMR(input).subscribe(
    //   (res) => {
    //     if(res.status==200)
    //     {
    //       this.PrevDrugsHistory=res.response;
    //     }
    //   });
  }

  showTeeth() {}

  changeColor(teethId, sub_count) {
    let elid = "teeth" + teethId;

    for (let i = 1; i <= sub_count; i++) {
      let subeid = elid + "_" + i;
      let element = document.getElementById(subeid);

      if (element.classList.contains("markedTeeth")) {
        element.classList.remove("markedTeeth");
      } else {
        element.classList.add("markedTeeth");
      }
    }
  }
  SetColor(teethId, universal = 0, fds = 0) {
    alert(teethId);
    let elid = "teeth_" + teethId;
    let element = document.getElementById(elid);

    if (element.classList.contains("markedTeeth")) {
      element.classList.remove("markedTeeth");
      element.style.fill = "rgba(255, 255, 255, 0)";
    } else {
      element.classList.add("markedTeeth");
      element.style.fill = "blue";
    }
  }

  compleProcedure(i) {
    const procedureDetails = this.form.controls["procedureDetails"].value;
    let selectedProcedure = procedureDetails[i];
    if (selectedProcedure.surface?.length) {
      let val = [];
      selectedProcedure.surface.forEach((i: any) => {
        val.push(i.name);
      });
      if (val.length) {
        selectedProcedure.surface = val.toString();
      } else {
        selectedProcedure.surface = ''
      }
      if(selectedProcedure.notes == null) {
        selectedProcedure.notes = ''
      }
    }
    if (selectedProcedure.isCompleted == 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "To mark this procedure as finished",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          selectedProcedure.isCompleted = 1;
          this.EMRService.CompleteDentalProcedureEMR(
            selectedProcedure
          ).subscribe((res) => {
            if (res.status == 200 && res.message == "Success") {
              Swal.fire("Done!", "Status updated successfully.", "success");
              // this.GetDentalProcedureEMR();
            } else {
              Swal.fire(
                "Error!",
                "Some unexpected error occures! try again later.",
                "error"
              );
            }
          });
        }
      });
    } else {
      this.toastr.error(
        "Error: " + "This procedure already marked as completed"
      );
    }
  }

  markteeth(teethNo) {
    let teethId = teethNo;
    let eid = "teeth_" + teethId;
    const item = document.getElementById(eid);

    let existvlues = this.selectedRow.value.teeths;

    if (item.classList.contains("teethBorder")) {
      item.classList.remove("teethBorder");

      var revalue = teethNo + ",";
      var newval = existvlues.replace(revalue, "");
      this.selectedRow.get("teeths").setValue(newval);
    } else {
      item.classList.add("teethBorder");
      if (existvlues != "") {
        var totvalue = existvlues + teethNo + ",";
      } else {
        var totvalue = teethNo + ",";
      }

      this.selectedRow.get("teeths").setValue(totvalue);
    }
  }

  dispTeeth(selectedRow) {
    this.showTeethSystem = true;
    //unselect all tetthsbefore selecting

    if (this.selectedRow) {
      for (let x = 1; x <= 32; x++) {
        let eid = "teeth_" + x;
        const item = document.getElementById(eid);
        item.classList.remove("teethBorder");
      }
    }

    // console.log(selectedRow)
    this.selectedRow = selectedRow;

    //   const procedureDetails = this.form.controls['itemDetails'].value;
    //   let selectedProcedure=procedureDetails[i]; selectedRow.value.teeths
    let teeths = selectedRow.value.teeths;

    if (teeths) {
      let teethList = teeths.split(",");
      for (let i = 0; i < teethList.length; i++) {
        let eid = "teeth_" + teethList[i];
        const item = document.getElementById(eid);
        if (item) {
          item.classList.add("teethBorder");
        }
      }
    }
  }

  displayTeeth(selectedRow) {
    this.selectedTeethNo = Number(selectedRow.value.teeths);
    this.showDentalChart = true;
  }

  onTreatmentDetailsChanged(event) {
    if (event.status) {
      const ServiceItems = this.form.get("procedureDetails") as FormArray;
      ServiceItems.clear();
      this.GetDentalProcedureEMR();
      this.GetEMRServiceItems();
    }
  }

  GetEMRServiceItems() {
    let input = {
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 1,
      BranchId: this.BranchesId,
      EstimateId: 0,
    };

    this.dentalService.GetDentalEstimateItemsEMR(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];

        if (response) {
          if (response.itemDetails) {
            this.allTreatmentDetails = response.itemDetails;
          }
        }
      }
    });
  }

  GetAuthorisedConsultant() {
    let payload = {
      ConsultantId: 0,
      BranchId: this.BranchesId,
    };
    this.ConfigurationService.GetAuthorisedConsultant(payload).subscribe(
      (res) => {
        this.instructorList = res.response;
      }
    );
  }

  attachment(data) {
    let tabName = "Followup";
    sessionStorage.setItem("tabName", tabName);
    this.router.navigate([
      `ServiceList/${data?.orderId}/report/${data?.itemId}`,
    ]);
  }
  multiselectSurface(data: any, index: number) {
    let val = [];
    if (data.value?.length) {
      data.value.forEach((res: any) => {
        val.push(res.name);
      });
    }
    let itemDtl = this.form.get("itemDetails")["controls"][index];
    itemDtl.get("surface").setValue(val);
    console.log(data, index, val, itemDtl.get("surface"));
  }
}
