import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { globalvars } from "../../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import {
  EMRPatientInfo,
  FamilyHistory,
} from "../../../emr/interfaces/EMRPatientInfo";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { PatientHistory } from "../../../emr/interfaces/PatientHistory";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SharedService } from "../../../../shared/shared.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { Questionare } from "../../../emr/interfaces/Questionare";
import { Options } from "select2";
import { EMRService } from "../../../emr/services/emr.service";
import { ChooseIcdModalComponent } from "../choose-icd-modal/choose-icd-modal.component";
import { DatePipe } from "@angular/common";
import _ from "lodash";
import { PatientService } from "../../../client/services/patient.service";

@Component({
  selector: "app-patient-history",
  templateUrl: "./patient-history.component.html",
  styleUrls: ["./patient-history.component.scss"],
})
export class PatientHistoryComponent implements OnInit {
  @Output() saveEmitter = new EventEmitter();
  @Input() visitId;
  @Input() patientId;
  @Input() functionPermissionData
  @Input() HealthInformationPlatform
  @Input() ConsultationId

  Selectedtab: string;
  phyVisible: Number;
  // EMRPatientInfo: EMRPatientInfo;
  form: FormGroup; // form variable
  QuestionareFrm: FormGroup;
  patientHistory: PatientHistory;

  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;
  socialHabitList = [
    { id: 1, habitDesc: "Unknown" },
    { id: 2, habitDesc: "Alcohol" },
    { id: 3, habitDesc: "Coffee" },
    { id: 4, habitDesc: "Drugs" },
    { id: 5, habitDesc: "No Habits" },
    { id: 6, habitDesc: "Shisha" },
    { id: 7, habitDesc: "Smoking" },
    { id: 8, habitDesc: "Tea" },
    { id: 9, habitDesc: "Other" },
    { id: 10, habitDesc: "Aerated Drink" },
  ];

  quantityList = [
    { id: 1, quantityDesc: "High" },
    { id: 2, quantityDesc: "Low" },
    { id: 3, quantityDesc: "Medium" },
    { id: 4, quantityDesc: "Never" },
    { id: 5, quantityDesc: "Occassional" },
    { id: 6, quantityDesc: "Other" },
    { id: 7, quantityDesc: "Rare" },
    { id: 8, quantityDesc: "Unknown" },
  ];

  categoryList = [
    { id: 1, categoryDesc: "Unknown" },
    { id: 2, categoryDesc: "No Habits" },
    { id: 3, categoryDesc: "Aerated Drink" },
    { id: 4, categoryDesc: "Alcohol" },
    { id: 5, categoryDesc: "Drugs" },
    { id: 6, categoryDesc: "Beverage" },
    { id: 7, categoryDesc: "Other" },
    { id: 8, categoryDesc: "Smoking" },
  ];

  // family History
  public dropdownOptions: Options;
  familyHistory: FamilyHistory[] = [];
  relationshipList = [];

  socialHistoryList: any[] = [];
  socialHistoryItems: any[] = [];
  familyHistoryCopy: FamilyHistory[];
  socialHistoryListCopy: any[];
  constructor(
    private EMRService: EMRService,
    private toastr: ToastrService,
    public modalRef: BsModalRef,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private datepipe: DatePipe,
    private patientService:PatientService
  ) {
    // this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
    this.phyVisible = Number(localStorage.getItem("physioVisible"));

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.Scrolltotab("PatientHistory");
    this.GetFamilyRelations();

    this.form = new FormGroup({
      id: new FormControl(0),
      pastMedicalHistory: new FormControl(""),

      socialHistory: new FormControl(""),
      currentMedication: new FormControl(""),
      immunization: new FormControl(""),
      cancerHistory: new FormControl(""),
      surgicalHistory: new FormControl(""),
      others: new FormControl(""),
      tobaccoStatus: new FormControl(0),
      socialStatusId: new FormControl(0),
      quantityId: new FormControl(0),
      categoryId: new FormControl(0),
      patientId: new FormControl(this.patientId),
      visitId: new FormControl(this.visitId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
    });

    this.QuestionareFrm = new FormGroup({
      PatientQuestionares: new FormArray([]),
    });

    this.GetPatientHistory();
    this.GetQuestionare();
    this.GetSocialHistoryTypeOptions();
  }

  Scrolltotab(elem) {
    this.Selectedtab = elem;
  }

  saveHistory() {
    if (
      this.familyHistory.some(
        (member) =>
          (member.relationId === 0 || member.condition === "") &&
          member.isDeleted != 1
      )
    ) {
      return;
    }

    this.patientHistory = this.form.getRawValue();
    this.patientHistory.patientId = this.patientId;
    this.patientHistory.userId = Number(localStorage.getItem("user_id"));
    if (!this.patientHistory.visitId) this.patientHistory.visitId = 0;

    this.patientHistory.tobaccoStatus = Number(
      this.patientHistory.tobaccoStatus
    );
    this.patientHistory.socialStatusId = Number(
      this.patientHistory.socialStatusId
    );
    this.patientHistory.quantityId = Number(this.patientHistory.quantityId);
    this.patientHistory.categoryId = Number(this.patientHistory.categoryId);
    this.patientHistory.FamilyHistoryList = this.familyHistory.map((item) => {
      let icdLabelList = item.icdLabelList?.length ? [{
        LabelId:item.icdLabelList[0].labelId,
        LabelDesc:item.icdLabelList[0].labelDesc,
        LabelCode:item.icdLabelList[0].labelCode,
      }] :[]

      return {
        fhId: item.fhId,
        RelationId: Number(item.relationId),
        RelationName: item.relationName,
        Condition: item.condition,
        PatientId: this.patientId,
        IsDeleted: item.isDeleted,
        IsDisplayed: item.isDisplayed,
        IcdLabelList:icdLabelList,
      };
    });
    this.patientHistory.socialHistoryList = this.socialHistoryList.map(
      (item: SocialHistory) => {
        let ShDate
      if(typeof (item.shDate) == "string"){
        ShDate=item.shDate.replace(/\//g, "-");
      }else{
        ShDate=this.datepipe.transform(item.shDate, 'dd-MM-yyyy');

      }
        return {
          ShId: item.shId,
          HistoryTypeId: item.historyTypeId,
          HistoryType: item.historyType,
          HistoryTypeOptionId: item.historyTypeOptionId,
          HistoryTypeOption: item.historyTypeOption,
          HistoryTypeOptionCode: item.historyTypeOptionCode,
          Duration: item.duration,
          Remarks: item.remarks,
          PatientId: item.patientId,
          PatEMRHistoryId: Number(this.patientHistory.id),
          IsDisplayed: true,
          IsDeleted: 0,
          SHDate :ShDate ? ShDate :''
        };
      }
    );
    this.patientHistory.socialHistoryList=this.patientHistory.socialHistoryList.length ? this.patientHistory.socialHistoryList : null
    this.patientHistory.FamilyHistoryList=this.patientHistory.FamilyHistoryList.length ? this.patientHistory.FamilyHistoryList : null 
    this.EMRService.SavePatientHistory(this.patientHistory).subscribe((res) => {
      if (res.status == 200 && res.message == "Success") {
        this.sendDataToNabid()
        this.GetPatientHistory();
        this.toastr.success(
          "Success: " + "Patient History Details Saved Successfully..."
        );
      } else {
        Swal.fire(
          "Error!",
          "Some unexpected error occures! try again later.",
          "error"
        );
      }
    });
  }

  GetPatientHistory() {
    let input = {
      PatientId: this.patientId,
    };
    this.EMRService.GetPatientHistory(input).subscribe((res) => {
      if (res.status == 200) {
        this.form.patchValue(res.response);
        this.familyHistory = res.response.familyHistoryList;
        this.socialHistoryList = res.response.socialHistoryList.map((item)=>{
          return{
            ...item,
            shDate:item.shDate ? item.shDate  == "01-01-1900" ? "" : item.shDate : ''
          }
        });
        this.familyHistoryCopy=_.cloneDeep(this.familyHistory);
        this.socialHistoryListCopy=_.cloneDeep(this.socialHistoryList);
      }
    });
  }

  GetQuestionare() {
    let input = {
      PatientId: this.patientId,
      branchId: this.BranchesId,
    };
    this.EMRService.GetQuestionare(input).subscribe((res) => {
      if (res.status == 200) {
        res.response.forEach((element) => {
          this.addMapFormGroup(
            element.patientQuestionaresData,
            0,
            element.groupName
          );
        });
      }
    });
  }

  public addMapFormGroup(data: Questionare[] = [], saved = 0, groupName) {
    const Drugs = this.QuestionareFrm.get("PatientQuestionares") as FormArray;
    if (data?.length > 0) {
      data.forEach((element, i) => {
        Drugs.push(this.createListGroup(element, saved, groupName));
      });
    }
  }

  private createListGroup(data: Questionare, saved = 0, groupName): FormGroup {
    return new FormGroup({
      patientId: new FormControl(this.patientId),
      qnId: new FormControl(data["qnId"]),
      question: new FormControl(data["question"]),
      ansId: new FormControl(data["ansId"]),
      branchId: new FormControl(data["branchId"]),
      notes: new FormControl(data["notes"]),
      groupName: new FormControl(groupName),
    });
  }

  SaveQuestionare() {
    let input = this.QuestionareFrm.getRawValue();

    this.EMRService.SaveQuestionare(input).subscribe((res) => {
      if (res.status == 200 && res.message == "Success") {
        this.toastr.success(
          "Success: " + "Questionare Details Saved Successfully..."
        );
      } else {
        Swal.fire(
          "Error!",
          "Some unexpected error occures! try again later.",
          "error"
        );
      }
    });
  }

  GetFamilyRelations() {
    this.EMRService.GetFamilyRelations().subscribe({
      next: (res) => {
        this.relationshipList = res.status == 200 ? res.response : [];
      },
    });
  }

  removeHistory(familyMember: any): void {
    if (familyMember.fhId !== 0) {
      // If fhId is not equal to 0, set isDeleted to 1
      familyMember.isDeleted = 1;
    } else {
      // If fhId is equal to 0, remove the item from the array
      const index = this.familyHistory.indexOf(familyMember);
      if (index !== -1) {
        this.familyHistory.splice(index, 1);
      }
    }
    console.log(this.familyHistory);
  }

  addRelation() {
    this.familyHistory.push({
      fhId: 0,
      relationId: 0,
      condition: "",
      isDeleted: 0,
      relationName: "",
      patientId: 0,
      isDisplayed: true,
      icdLabelList:[]
    });
  }

  GetSocialHistoryTypeOptions() {
    this.EMRService.GetSocialHistoryTypeOptions().subscribe({
      next: (res) => {
        this.socialHistoryItems = res.response;
      },
    });
  }

  setItemData(event) {
    if (event) {
      const existingEntry = this.socialHistoryList.find(
        (entry) => entry.historyTypeId === event.typeId
      );
      if (!existingEntry) {
        this.socialHistoryList.push({
          shId: 0,
          historyTypeId: event.typeId,
          historyType: event.type,
          historyTypeOptionId: "",
          historyTypeOption: "",
          historyTypeOptionCode: null,
          duration: "",
          remarks: "",
          patientId: this.patientId,
          patEMRHistoryId: 0,
          isDisplayed: false,
          isDeleted: 0,
          shDate:''
        });
      }
    }
  }

  filterOptions(id) {
    const Status = this.socialHistoryItems.find((item) => item.typeId === id);

    // Extract options from the maritalStatus object
    return Status ? Status.options : [];
  }

  setOptions(index, options) {
    const opt = options.find(
      (item) =>
        this.socialHistoryList[index].historyTypeOptionId === item.optionId
    );
    this.socialHistoryList[index] = {
      ...this.socialHistoryList[index],
      historyTypeOptionCode: opt.optionCode ? opt.optionCode : "",
      historyTypeOption: opt.optionType,
    };
    console.log(this.socialHistoryList);
  }

  openIcdModal(item){
    let initialState = {
      BranchesId:this.BranchesId,
      SelectedIcdList:item.icdLabelList
    }

    const modalRef = this.modalService.show(ChooseIcdModalComponent, Object.assign({ class: "gray modal-lg" }, { initialState }))
    modalRef.content.confirmEmitter.subscribe((res) => {

      if (res.data) {
        debugger
       item.icdLabelList=res.data
      }
    })
    console.log("377",this.familyHistory)
  }

  getValueIcd(familyMember,key){
    return familyMember?.icdLabelList && familyMember.icdLabelList.length > 0
      ? familyMember.icdLabelList[0][key]
      : '';
  }

  close() {
    this.saveEmitter.emit();
    this.modalRef.hide();
  }

  sendDataToNabid() {
    const currentDate = this.datepipe.transform(new Date(), "dd-MM-yyyy");
    const nbPayload = {
      EventId: 1,
      Consultationid: this.ConsultationId,
      EntryDate: currentDate,
      PatientId: this.patientId,
      BranchId: this.globals.HospitalId,
    };
    const Permission = this.functionPermissionData.find((x) => x.id === 560);

    this.socialHistoryListCopy=this.socialHistoryListCopy ? this.socialHistoryListCopy :[]
    this.familyHistoryCopy=this.familyHistoryCopy? this.familyHistoryCopy:[]
    const areEqualSocialHistory=_.isEqual(this.socialHistoryList,this.socialHistoryListCopy)
    const areEqualFamilyHistory=_.isEqual(this.familyHistoryCopy,this.familyHistory)
    if(areEqualSocialHistory && areEqualFamilyHistory) return
    
    // Check if the platform is 'Nabidh' and permissions are allowed
    if (this.HealthInformationPlatform.settValue === "Nabidh" ) {
      if (Permission?.isAllowed ) {
         this.sendToNabidh(nbPayload)
      } else {
        this.toastr.warning("No permission to send data to nabith");
      }
    }
  }

  private sendToNabidh(payload: any) {
    this.patientService.SendDetailstoNabith(payload).subscribe({
      next: (res: any) => {
        this.toastr.info("Response from Nabidh: " + res);
      },
    });
  }
}

interface SocialHistory {
  shId: number;
  historyTypeId: number;
  historyType: string;
  historyTypeOptionId: number;
  historyTypeOption: string;
  historyTypeOptionCode: string | null;
  duration: string;
  remarks: string;
  patientId: number;
  patEMRHistoryId: number;
  isDisplayed: boolean;
  isDeleted: number;
  shDate:string
}
