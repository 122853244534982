import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "../../../client/services/configuration.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-result-form-header",
  templateUrl: "./add-result-form-header.component.html",
  styleUrls: ["./add-result-form-header.component.scss"],
})
export class AddResultFormHeaderComponent implements OnInit {
  @Input() RFHeaderId;
  @Input() RformId;
  @Input() HeaderName;
  @Output() resultEmitter = new EventEmitter()
  resultForm: FormGroup;
  submitted: boolean = false;
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private configservice: ConfigurationService,
    private modalRef: BsModalRef,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.formInit();
  }

  formInit() {
    this.resultForm = this.fb.group({
      HeaderName: [this.HeaderName, Validators.required],
    });
  }

  get f() {
    return this.resultForm.controls;
  }

  save() {
    let payload = {
      ...this.resultForm.value,
      RFHeaderId: this.RFHeaderId,
      RformId: this.RformId,
      IsDisplayed: 1,
      IsDeleted: 0,
    };
    debugger;
    this.configservice.InsertUpdateHeader(payload).subscribe({
      next: (res) => {
        if (res.status == 200) {
          this.toastr.success(this.RFHeaderId == 0 ? "Add Successfull" :"Edit Successfull" );
          this.resultEmitter.emit({ status: true });
          this.close();
        }
      },
    });
  }

  close() {
    this.modalRef.hide();
  }
}
