import { Component, OnInit, SimpleChanges } from "@angular/core";
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { ConfigurationService } from "../../client/services/configuration.service";
import { ToastrService } from "ngx-toastr";
import { dependenciesFromGlobalMetadata } from "@angular/compiler/src/render3/r3_factory";

@Component({
  selector: "app-bodyparts",
  templateUrl: "./bodyparts.component.html",
  styleUrls: ["./bodyparts.component.scss"],
})
export class BodyPartsComponent implements OnInit {
  defaultColDef: ColDef[] = [];
  bodypartsList: any[] = [];

  serviceItemBasket: any = [
    {
      item: "mid1",
      value: "John Doe",
    },
  ];
  columnDefs: ColDef[] = [];

  rowGroupPanelShow = "always";
  public context;
  public gridApi;
  public gridApimodality;
  public frameworkComponents;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  public noRowsTemplate;
  public user_id: string;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  TendernesList: any[];
  MovementList: any[];
  SpasticityList: any;
  MovementRangeList: any;
  public loadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';
  bodyPartsForm: FormGroup;
  display: boolean = false;
  bodyPartsPopupHeading = "Add Body Parts";
  public submitted: boolean = false;
  isLoading: boolean = false;
  TendernessDropdownSettings: IDropdownSettings = {};
  movementsDropdownSettings: IDropdownSettings = {};
  spasticityDropdownSettings: IDropdownSettings = {};
  movementsRangeDropdownSettings: IDropdownSettings = {};
  BranchesId: any;
  result: any;
  bodyId: any;
  bodypartsPopupHeading = "Add Body Parts";
  functionPermissionData: any;
  movemtsData:any[]=[]; 
  movementsData:any[]=[];
   constructor(
    private sharedservice: SharedService,
    private configurationService: ConfigurationService,
    private toastr: ToastrService
  ) {
    this.user_id = localStorage.getItem("user_id");
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.context = { componentParent: this };
    this.context = { componentParent: this };
    this.bodyPartsForm = new FormGroup({
      bodyDesc: new FormControl("", [Validators.required]),
      tendernesListData: new FormControl([]),
      movementsListData: new FormControl([]),
      spasticityListData: new FormControl([]),
      movementsRangeListData: new FormControl([]),
      bodyId: new FormControl(0),
      branchId: new FormControl(this.globals.HospitalId),
      isDisplayed: new FormControl(1),
      isDeleted: new FormControl(0),
    });

    this.defaultColDef = [
      {
        headerName: "Description ",
        field: "bodyDesc",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Action",
        field: "action",
        enableRowGroup: true,
        resizable: false,
        sortable: false,
        flex: 2,
        maxWidth: 80,
        pinned: "right",
        filter: false,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "bodyparts" },
      },
    ];
    this.GetBodyparts();
    this.GetTendernesList();
    this.GetTMovementsList();
    this.GetSpasticityList();
    this.GetMovementsRange();
    this.functionPermission();

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
      onGridReady: (event) => event.api.sizeColumnsToFit(),
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    this.TendernessDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "descriptionData",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,
    };
    this.movementsDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "descriptionData",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,
    };

    this.spasticityDropdownSettings = {
      singleSelection: false,
      idField: "spasticityId",
      textField: "spasticityDesc",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,
    };

    this.movementsRangeDropdownSettings = {
      singleSelection: false,
      idField: "movementRangeId",
      textField: "movementRangeDesc",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,
    };

    this.columnDefs = [
      {
        headerName: "Selected Item",
        field: "descriptionData",

        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Normal Value",
        field: "value",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true,
      },
    ];
  }

  cancel() {
    this.submitted = false;
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
    onGridReadymodality(params) {
    this.gridApimodality = params.api;
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById("filter-text-box") as HTMLInputElement).value
    );
  }
  get f() {
    return this.bodyPartsForm.controls;
  }

  showDialog() {
    const foundElement = this.functionPermissionData.find((x) => x.id === 148);
    if (foundElement.id == 148 && foundElement.isAllowed == true) {
      this.bodypartsPopupHeading = "Add Body Parts";
      this.display = true;
      this.movemtsData=[];
      this.clearForm();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }
  //get tenderness
  GetTendernesList() {
    let input = {
      MasterFieldType: "Tenderness",
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId,
    };
    this.configurationService.GetTendernesList(input).subscribe((res) => {
      this.TendernesList = res.response;
    });
  }
  //get movements
  GetTMovementsList() {
    let input = {
      MasterFieldType: "Movement",
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId,
    };
    this.configurationService.GetTMovementsList(input).subscribe((res) => {
      this.MovementList = res.response;
    });
  }
  GetSpasticityList() {
    let input = {
      spasticityId: 0,
      branchId: this.globals.HospitalId,
    };
    this.configurationService.GetSpasticityList(input).subscribe((res) => {
      this.SpasticityList = res.response;
    });
  }
  GetMovementsRange() {
    let input = {
      movementRangeId: 0,
      branchId: this.globals.HospitalId,
    };
    this.configurationService.GetMovementsRange(input).subscribe((res) => {
      this.MovementRangeList = res.response;
    });
  }
  GetBodyparts() {
    let input = {
      bodyId: 0,
      branchId: this.globals.HospitalId,
      ShowAll: 1,
    };
    this.configurationService.GetBodyparts(input).subscribe((res) => {
      this.bodypartsList = res.response;
    });
  }

  savebodyparts() {
    this.submitted = true;
    if (this.bodyPartsForm && this.bodyPartsForm.valid) {
      var formdata = this.bodyPartsForm.getRawValue();
      let bodypartsid = formdata.bodyId;
      // let itemarayList = this.movemtsData.map(x => ({ ...x, Rate: Number(x.Rate) })); // string to number convertion
      // formdata.movementsListData=this.movemtsData;
      if (formdata.isDisplayed == true) {
        formdata.isDisplayed = 1;
      } else {
        formdata.isDisplayed = 0;
      }
      if (formdata.tendernesListData == null) {
        formdata.tendernesListData = [];
      }
      if (formdata.movementsListData == null) {
        formdata.movementsListData = [];
      }
      if (formdata.spasticityListData == null) {
        formdata.spasticityListData = [];
      }
      if (formdata.movementsRangeListData == null) {
        formdata.movementsRangeListData = [];
      }
      this.isLoading = true;
      this.configurationService.SaveBodyparts(formdata).subscribe((res) => {
        // this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Success") {
          if (bodypartsid == 0) {
            this.isLoading = false;
            this.toastr.success("Body part added Successfully");
          } else {
            this.isLoading = false;
            this.toastr.success("Body part Edited Successfully");
            this.bodyId = 0;
          }
          this.display = false;
          this.GetBodyparts();
          this.clearForm();
        } else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    }
  }

  Edit(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 149);
    if (foundElement.id == 149 && foundElement.isAllowed == true) {
      this.display = true;
      this.bodypartsPopupHeading = "Edit body part";
      this.bodyPartsForm.patchValue(data);
      this.bodyPartsForm.controls["bodyId"].setValue(data.bodyId);
      this.movementsData=data.movementsListData;
      this.bodyPartsForm.controls["movementsRangeListData"].setValue(data.movemetsRangeListData);

    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  Delete(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 150);
    if (foundElement.id == 150 && foundElement.isAllowed == true) {
      let form = this.bodyPartsForm.getRawValue();
      form.bodyId = data.bodyId;
      form.isDeleted = 1;
      this.configurationService.SaveBodyparts(form).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Bodypart deleted successfully");
          this.GetBodyparts();
          this.clearForm();
          this.bodyId = 0;
        } else {
          this.toastr.error("Failure: " + this.result.message);
          this.display = false;
        }
      });
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  clearForm() {
    this.bodyPartsForm.reset({
      bodyDesc: "",
      tendernesListData: [],
      movementsListData: [],
      spasticityListData: [],
      movementsRangeListData: [],
      bodyId: 0,
      branchId: this.globals.HospitalId,
      isDisplayed: 0,
      isDeleted: 0,
    });
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem("selectedMenu")),
      UserId: Number(localStorage.getItem("user_id")),
    };
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  // to delete a row from grid
  deleteItem(event) {
    let data = event.data;
  }
  listmoventsData(){
    this.movemtsData=this.bodyPartsForm.get("movementsListData").value;    
    this.gridApimodality.setRowData(this.movemtsData);

  }
}
