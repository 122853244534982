import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConsultaionsService } from '../../services/consultations.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from '../../services/patient.service';
import { DatePipe } from '@angular/common';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SharedService } from '../../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../../settings/services/settings.service';

@Component({
  selector: 'app-change-status-modal',
  templateUrl: './change-status-modal.component.html',
  styleUrls: ['./change-status-modal.component.scss']
})
export class ChangeStatusModalComponent implements OnInit {

  @Input() rowData;
  @Input() branchesId;
  @Input() functionPermissionData;
  ChangeStatusForm: FormGroup;
  result: any;
  @Output() statusChange = new EventEmitter();
  subscription: Subscription;
  globals: globalvars = new globalvars();
  healthPlatform: any;

  constructor(private consultationsService: ConsultaionsService,
              private modalRef: BsModalRef,
              private toastrService: ToastrService,
              private patientService: PatientService,
              private datePipe: DatePipe,
              private sharedservice: SharedService,
              private settingsService:SettingsService
    ) { 
      this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
        if (this.sharedservice.checkglobals(glob)) {
          this.globals = glob;
        }
      });
    }

  ngOnInit(): void {
    this.ChangeStatusForm = new FormGroup({
      Status: new FormControl('', [Validators.required]),
    });
    if(this.rowData.status !== "" && this.rowData.status !== null){
      this.ChangeStatusForm.patchValue({ Status: this.rowData.status });
    }
    this.getApplicationSettings()
    // console.log('functionPermissionData', this.functionPermissionData);
  }

  close() {
    this.modalRef.hide();
  }

  save() {
    let input = {
      UserId: Number(localStorage.getItem("user_id")),
      ConsultationId: this.rowData.consultationId,
      BranchId: this.branchesId,
      Status: this.ChangeStatusForm.controls.Status.value
    }
    this.consultationsService.updateConsultationStatus(input).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastrService.success("Status changed successfully");
        let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy')

        if (this.result.message == 'success' && this.ChangeStatusForm.controls.Status.value == 'C') {
          if (this.healthPlatform.settValue === "Nabidh") {
          const foundElement = this.functionPermissionData.find(x => x.id === 563)
          if (foundElement.id == 563 && foundElement.isAllowed == true) {
            let nabPayload = {
              EventId: 5,
              Consultationid: this.rowData.consultationId,
              EntryDate: currentDate,
              BranchId : this.globals.HospitalId
            }
            this.patientService.SendDetailstoNabith(nabPayload).subscribe((res) => {
              this.toastrService.info('Response from Nabith:' + res);
            })
          } else {
            this.toastrService.warning('No permission to send data to nabith');
          }
        }
        }

        this.statusChange.emit({isChanged: true});
        this.modalRef.hide();
      } else {
        this.toastrService.error("Status change failed");
      }
    });
  }

  getApplicationSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }

    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: any)=>{
        if(response.status == 200){
          this.healthPlatform=response.response.find(item => item.settId == 25)
        }
      }
    })
  }

}
