import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  HostListener,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { map, switchMap, tap } from "rxjs/operators";
import { ColDef } from "ag-grid-community";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";

import { ConfigurationService } from "../../client/services/configuration.service";
import { ServiceOrderingService } from "../../client/services/ServiceOrderingService";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ServicesGroups } from "../../client/interfaces/ServicesGroups";

import { HttpClient } from "@angular/common/http";
import { noop, Observable, Observer, of, Subscription } from "rxjs";
import { Response } from "../../../_Models/Response";
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";
import { AssignTemplateModalComponent } from "../modals/assign-template-modal/assign-template-modal.component";
import { AssignREsultFormComponent } from "../modals/assign-resultform/assign-resultform.component";
import { LabService } from "../../lab/services/lab.service";
import { th } from "date-fns/locale";
interface cptCodes {
  cptCodeId: number;
  cptCode: string;
  cptDesc: string;
  branchId: number;
  isDisplayed: number;
}
@Component({
  selector: "app-serviceItems",
  templateUrl: "./serviceItems.component.html",
  styleUrls: ["./serviceItems.component.scss"],
})
export class serviceItemsComponent implements OnInit {
  GroupCode: any;
  modalRef: BsModalRef;
  action: string = "";
  LabelName: string = "";
  SubTypeId: number;
  IsSubgroupVisible: boolean;
  BranchesId: number;
  ServicesGroups: ServicesGroups[];
  itemId: number;
  EventId: number;
  parentId: any;
  public submitted: boolean = false;
  public submitted1: boolean = false;
  search?: string;
  displayModal: boolean;
  displayModal1: boolean;
  suggestions$?: Observable<cptCodes[]>;
  private baseUrl: string = "";
  errorMessage?: string;
  //
  subscription: Subscription;
  globals: globalvars = new globalvars();
  selectedFiles: any[];
  serviceItemName:any ;
  //form declaration
  subGroupForm: FormGroup;
  ServiceItemForm: FormGroup;
  result: any;
  GetItemGroupTypeData: any;
  deptList: any;
  IsDeleteSubGroup: number;
  ServiceitemPopUpHeading: String = "Add Service Item";
  isLoading = false;
  @ViewChild("template") modaltemp: any;
  @ViewChild("template1") modaltemp1: any;
  // AG GRIDS

  // SERVICE ITEM LIST GRID
  public gridApi;
  public gridApi2;
  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];
  rowData: any = [];
  rowGroupPanelShow = "always";
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  public context;
  sampleData:any;
  itemData:any;
  Taxarray: any[] = [];

  public overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';


  // Rate Group
  rowdataRate: any[] = [];
  columnDefsItem: ColDef[] = [
    {
      headerName: "Rate Name",
      field: "rGroupName",
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      flex: 2,
    },

    {
      headerName: "Rate",
      field: "rateStr",
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      editable: true,
      flex: 2,
    },
  ];

  // Tax Tb
  columnDefsTax: ColDef[] = [
    {
      headerName: "Tax Desc",
      maxWidth: 180,
      field: "taxDesc",
      enableRowGroup: true,
      resizable: true,
      sortable: true,

      flex: 2,
    },

    {
      headerName: "Tax %",
      maxWidth: 180,
      field: "taxPcnt",
      enableRowGroup: true,
      resizable: true,
      sortable: true,

      flex: 2,
    },

    {
      headerName: "Action",
      filter: false,
      minWidth: 100,
      pinned: "right",
      resizable: false,
      sortable: false,
      unSortIcon: true,
      field: "Id",
      flex: 2,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "ServiceTax" },
    },
  ];

  GetAllTaxData: any;



  // FORM DROP DOWNS
  GroupsData: any;
  GetContainersData: any;
  GetDrugTypeDatas: any;
  GetLedgerHead: any;
  GetCpctCodeData: any;
  GetVaccineTypeDatas: any;
  public user_id: string;
  locationId:any;
  functionPermissionData: any;
  dropdownSettings: any = {
    singleSelection: false,
    minWidth: 180,
    idField: "spointId",
    textField: "spointName",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };

  public vaccinationRouteList: any[] = [];
  serviceItemData: any;
  insuranceGroupItems: any[];
  constructor(
    private modalService: BsModalService,
    private ServiceOrderingService: ServiceOrderingService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private toastr: ToastrService,
    private configurationService: ConfigurationService,
    private labService:LabService

  ) {
    this.user_id = localStorage.getItem('user_id');

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
        this. locationId=this.globals.Location.locationId;

      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  ngOnInit(): void {
    // form declaration

    this.subGroupForm = new FormGroup({
      SubGroupName: new FormControl("", [Validators.required]),
      subTypeId: new FormControl("", [Validators.required]),
    });

    //
    this.getServiceGroupItems();
    this.functionPermission();
    this.GetAllService()
    // this.GetAllServiceItems();
    this.servicepoint()


    //grid config
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.context = { componentParent: this };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `<span"></span>`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    this.columnDefs = [
      {
        headerName: "Code",
        minWidth: 180,
        field: "itemCode",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Item Name",
        minWidth: 180,
        field: "itemName",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "CPT Code",
        minWidth: 180,
        field: "cptCode",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        resizable: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        filter: false,

        unSortIcon: false,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Service Item" },
        getQuickFilterText: (params) => {
          return "";
        },
      },
    ];


    this.GetGroupData();
    this.GetLedgerHeadData();
    this.GetContainers();
    this.GetVaccineTypeData();
    this.GetDrugTypeData();
    this.getResultForm();
    // get department
    this.getDept()
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.search);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http.post<Response<cptCodes[]>>(
            `${this.baseUrl}api/MasterData/GetCPTCode`,
            {
              "CPTCodeId": 0,
              "CPTCode": query,
              "ShowAll": 1,
              "BranchId": this.globals.HospitalId
            }
          )
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  this.errorMessage =
                    (err && err.message) || "Something goes wrong";
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */


    // this.GetItemRateGroup();
    // this.GetItemTax();

    this.GetVaccineTypeData();
    this.GetItemGroupType();
    this.getVaccinationRoutes();
    this.GetInsurancegroup()
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  get f() {
    return this.ServiceItemForm.controls;
  }
  get f1() {
    return this.subGroupForm.controls;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.setRowData(this.rowData);
  }

  onGridReady2(params) {
    this.gridApi2 = params.api;
  }
  onGridReady3(params) {
  }

  ModalInitForm() {
    let options = {
      code: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      group: new FormControl(""),
      sortorder: new FormControl("", [Validators.required]),
      resultOn: new FormControl(""),
      sampleTypeId: new FormControl(""),
      STypeId: new FormControl(""),
      InsuranceGroupId:new FormControl(0),
      containers: new FormControl(""),
      //manufacturer: new FormControl("",[Validators.required]);
      ledgerhead: new FormControl("", [Validators.required]),
      cptcode: new FormControl(""),
      deptId: new FormControl(""),
      //unit: new FormControl(""),
      vaccinetype: new FormControl(""),
      basecost: new FormControl("", [Validators.required]),
      // liscode: new FormControl(""),
      MasterItemId: new FormControl(0),

      BranchId: new FormControl(this.globals.HospitalId),
      GetItemRateGroupData: new FormArray([]),
      ExternalItem: new FormControl(false),
      AllowRate: new FormControl(false),
      AllowDiscount: new FormControl(false),
      VitalSignItem: new FormControl(false),
      AllowPostPayments: new FormControl(false),
      AllowCommission: new FormControl(false),
      StaffMandatory: new FormControl(false),
      searchTextGroups: new FormControl(""),
      IsDisplayedGroup: new FormControl(true),
      isVaccine: new FormControl(false),
      routeId: new FormControl(''),
      Unit: new FormControl(''),
      VaccineValidation:new FormControl(false),
      SPointlist:new FormControl()
    };
    if (this.GroupCode == "111" || this.GroupCode == "112") {
      options["ValidityDays"] = new FormControl("", [Validators.required]);
      options["NoVisits"] = new FormControl("", [Validators.required]);
      options["defaulttat"] = new FormControl("", [Validators.required]);
      options["drugtype"] = new FormControl("");
      options["materialCost"] = new FormControl("");
    } else {
      options["ValidityDays"] = new FormControl("");
      options["NoVisits"] = new FormControl("");
      options["defaulttat"] = new FormControl("");
      options["drugtype"] = new FormControl("", [Validators.required]);
      options["materialCost"] = new FormControl("", [Validators.required]);
    }

    this.ServiceItemForm = new FormGroup(options);
    this.itemId = 0;
  }

  openModalServiceSubGroups(template1: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 104)
    if (foundElement.id == 104 && foundElement.isAllowed == true) {
    this.modalRef = this.modalService.show(template1);
    this.action = "Add";
    this.clearForm2();
    } else {
      this.toastr.warning("You are not allowed to add service sub group");
    }
  }

  clearForm2() {
    this.subGroupForm.reset({
      SubGroupName: "",
    });
  }

  EditSubGroup(template1) {
    this.modalRef = this.modalService.show(template1);
    this.action = "Edit";
    this.subGroupForm.patchValue({
      SubGroupName: this.LabelName,
      subTypeId: this.SubTypeId,
    });
  }


  DeleteSubGroup(event) {
    if (event == 1) {
      this.IsDeleteSubGroup = 1;
    }
    this.action = "Delete";
    this.subGroupForm.patchValue({
      SubGroupName: this.LabelName,
    });
    var AppData = {
      SubGroupName: this.subGroupForm.get("SubGroupName").value,
      subTypeId: this.subGroupForm.get("subTypeId").value,
    }
    let GroupId = 0;
    let ParentId = this.EventId;
    if (this.action == "Delete") {
      GroupId = this.EventId;
      ParentId = this.parentId
    }

    let IsDelete = 0;
    if (this.IsDeleteSubGroup > 0) {
      IsDelete = 1;
    }
    this.ConfigurationService.InsertUpdateServiceItemGroup(GroupId, ParentId, AppData, this.globals.HospitalId, IsDelete).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200 && this.result.message == 'Success') {
        this.toastr.success("Sub Group Deleted Successfully");
        this.GetGroupData();
        this.getServiceGroupItems();

      }
      else {
        this.toastr.error("Failed : " + this.result.message);
      }
    })
  }


  NodeSelect(event) {
    this.itemData=event
    let showall = 1;
    let consultantId = 0;
    this.EventId = event.node.groupId;
    this.GroupCode = event.node.groupCode;
    this.parentId = event.node.parent ? event.node.parent.groupId : 0;
    this.SubTypeId = event.node.groupTypeId;
    this.LabelName = event.node.label;

    this.gridApi.showLoadingOverlay();
    this.ConfigurationService.GetServiceItem(
      showall,
      this.globals.HospitalId,
      this.EventId, consultantId
    ).subscribe((res) => {
      this.rowData = res.response;
      this.gridApi.hideOverlay();
    });

    this.selectedFiles = this.selectedFiles.filter(
      (oppdata) => oppdata.groupId == this.EventId
    );
    this.IsSubgroupVisible = event.node.parent ? true : false;
  }

  getServiceGroupItems() {
    let input = {
      BranchId: this.globals.HospitalId,
      PageCode: "CONFIG",
    };
    this.ServiceOrderingService.getServiceGroupItems(input).subscribe((res) => {
      this.ServicesGroups = res.response;
    });
  }

  nodeUnselect(event) {
    let showAll = 1;
    let ConsultantId = 0;
    this.GroupCode = '';
    this.IsSubgroupVisible = false;
    let unselectgroup = [];
    this.rowData.forEach(a => {
      if (a != event.node.groupId) {
        if (this.rowData.indexOf(a) <= -1) {
          unselectgroup.push(a)
        }
      }
    });
    this.EventId = event.node.groupId;
    this.gridApi.showLoadingOverlay();
    this.ConfigurationService.GetServiceItem(
      showAll,
      this.globals.HospitalId,
      0, ConsultantId
    ).subscribe((res) => {
      this.rowData = res.response;
      this.rowData=[];
      this.gridApi.hideOverlay();
    });
  }

  openModalServiceItems(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 105)
    if (foundElement.id == 105 && foundElement.isAllowed == true) {
    this.ServiceitemPopUpHeading = "Add Service Item";
    this.ModalInitForm();
    this.modalRef = this.modalService.show(template, { class: "modal-lg modal-custom-style" });
    this.ServiceItemForm.patchValue({
      group: this.EventId,
    });

    //this.GetGroupData();
    // this.GetCpctCode();
    // this.GetVaccineTypeData();
    // this.GetContainers();
    // this.GetDrugTypeData();
    // this.GetLedgerHeadData();
    // this.GetItemRateGroup();

    this.GetItemRateGroup();
    this.GetItemTax();
    } else {
      this.toastr.warning("You are not allowed to add service item");
    }

  }

  SaveServiceItem() {
    
    this.gridApi2.stopEditing(); // for grid auto focus out and chnaging cell value
    this.submitted = true;
    if (this.ServiceItemForm && this.ServiceItemForm.valid) {
      let ServiceItemArray = [];
      this.rowdataRate.forEach(function (value) {
        ServiceItemArray.push({
          RGroupId: value.rGroupId,
          Rate: Number(value.rateStr),
        });
      });
      let itemIddata = 0;
      if (this.itemId > 0) {
        itemIddata = this.itemId;
      }
      this.isLoading = true;
      this.ServiceItemForm.patchValue({
        isVaccine: this.GroupCode == 115 ? true: false
      })
      console.log( this.ServiceItemForm);
      
      this.ConfigurationService.SaveServiceItems(
        this.ServiceItemForm,
        itemIddata,
        this.Taxarray,
        ServiceItemArray

      ).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Success") {
          if (itemIddata == 0) {
            this.isLoading = false;
            this.toastr.success("Service Items added successfully");
            // this.GetAllServiceItems();
          } else {
            this.isLoading = false;
            this.toastr.success("Service Items Updated successfully");
            // this.GetAllServiceItems();
          }
          this.modalRef.hide();
          this.clearForm();
          this.GetAllServiceItems(1);
        } else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    }    else{
      // alert("Not");

      Object.keys(this.ServiceItemForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.ServiceItemForm.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
           console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }

  }
  ResetFunction() {
    this.submitted = false;
    this.clearForm();
  }

  GetAllServiceItems(load = 0) {
    let ConsultantId = 0;
    let showall = 1;
    if (load == 1) this.gridApi.showLoadingOverlay();
    this.ConfigurationService.GetServiceItem(
      showall,
      this.globals.HospitalId,
      this.EventId, ConsultantId
    ).subscribe((res) => {
      this.rowData = res.response;
      if (load == 1) this.gridApi.hideOverlay();

    });
  }
  GetAllService() {
    let ConsultantId = 0;
    let showall = 1;
    this.ConfigurationService.GetServiceItem(
      showall,
      this.globals.HospitalId,
      2, ConsultantId
    ).subscribe((res) => {
      this.serviceItemName = res.response;
    });
  }

  GetGroupData() {
    this.ConfigurationService.GetGroupData(this.globals.HospitalId).subscribe(
      (res) => {
        this.GroupsData = res.response;
      }
    );
  }

  GetItemRateGroup() {

    this.ConfigurationService.GetItemRateAmountById(this.globals.HospitalId, 0).subscribe((res) => {
      this.rowdataRate = res.response;
    });


  }

  GetInsurancegroup(){
    let payload={
      GroupId:0,
      BranchId:this.BranchesId,
      ShowAll:1
    }
    this.configurationService.GetInsuranceGroup(payload).subscribe({
      next:(res)=>{
        this.insuranceGroupItems=res.response
      }
    })
  }
  


  GetLedgerHeadData() {

    let Input = {
      "HeadId": 0,
      "ShowAll": 0,
      "BranchId": this.globals.HospitalId
    }
    this.ConfigurationService.GetLedgerData(Input).subscribe((res) => {
      this.GetLedgerHead = res.response;
    });

  }

  GetCpctCode() {
    let ShowAll = 0
    this.ConfigurationService.GetCpctCode(this.globals.HospitalId, ShowAll).subscribe(
      (res) => {
        this.GetCpctCodeData = res.response;
      }
    );
  }
  getDept(){
    let show = 1
    this.configurationService.GetDepartments(this.BranchesId,show).subscribe((res:any)=>{
      this.deptList = res.response
    })
  }
  onSelectVisit(i:any){
    this.ServiceItemForm.controls['deptId'].setValue(i.target.value);
  }
  setCptCode(item) {
    let id = item.cptCodeId;
    if (id > 0)
      this.ServiceItemForm.controls['cptcode'].setValue(id);
  }

  GetItemTax() {

    let ItemId = 0;
    if (this.itemId) {
      ItemId = this.itemId
    }
    this.ConfigurationService.GetItemTax(ItemId, this.globals.HospitalId).subscribe((res) => {
      this.GetAllTaxData = res.response;

      if (ItemId > 0) {
        // const filteredThatArray = this.GetAllTaxData.filter((item) => item.isApplied=="true");
        // console.log(filteredThatArray);

        this.GetAllTaxData.forEach(element => {
          if (element.isApplied == "true") {
            this.Taxarray.push(element.taxId);
          }
        });

      }


    });



  }

  ServiceCheckingItems(checkvalue, Ischecked) {
    if (Ischecked == "checked") {
      this.Taxarray.push(checkvalue.taxId);
    }
    else {
      this.Taxarray = this.Taxarray.filter((oppdata) => oppdata != checkvalue.taxId);

    }

  }

  clearForm() {
    this.ServiceItemForm.reset({
      code: "",
      name: "",
      group: "",
      InsuranceGroupId:0,
      sortorder: "",
      resultOn: "",
      containers: "",
      defaulttat: "",
      materialCost: "",
      ledgerhead: "",
      cptcode: "",
      vaccinetype: "",
      basecost: "",
      drugtype: "",
      GetItemRateGroupData: "",
      ExternalItem: false,
      AllowRate: false,
      AllowDiscount: false,
      VitalSignItem: "",
      AllowPostPayments: false,
      AllowCommission: false,
      ValidityDays: "",
      StaffMandatory: false,
      NoVisits: "",
      BranchId: this.globals.HospitalId,
      IsDisplayedGroup: true,
      sampleTypeId:"",
      routeId: '',
      Unit: '',
      SPointlist:[]
    });

    this.search = "";

    // this.GetDrugTypeData();


  }

  DeleteItem(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 107)
    if (foundElement.id == 107 && foundElement.isAllowed == true) {
    this.itemId = id.itemId;

    this.ConfigurationService.DeleteServiceItem(this.itemId).subscribe(
      (res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("ServiceItem deleted successfully");
          this.displayModal = false;
          // this.GetAllServiceItems();

          let find = this.rowData.findIndex(x => x.itemId === this.itemId);
          this.rowData.splice(find, 1);
          this.gridApi.setRowData(this.rowData);


        } else {
          this.toastr.error("Failure: " + this.result.message);
          this.displayModal = false;
        }
      }
    );
    this.clearForm();
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  GetVaccineTypeData() {
    this.ConfigurationService.GetVaccineType(this.globals.HospitalId).subscribe(
      (res) => {
        this.GetVaccineTypeDatas = res.response;
      }
    );
  }

  GetContainers() {
    this.ConfigurationService.GetContainers(this.globals.HospitalId).subscribe(
      (res) => {
        this.GetContainersData = res.response;
      }
    );
  }

  GetDrugTypeData() {
    this.ConfigurationService.GetDrugTypeData(
      this.globals.HospitalId
    ).subscribe((res) => {
      this.GetDrugTypeDatas = res.response;
    });
  }

  EditItem(itemId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 106)
    if (foundElement.id == 106 && foundElement.isAllowed == true) {
    this.ServiceitemPopUpHeading = "Edit Service Item";

    this.modalRef = this.modalService.show(
      this.modaltemp,
      Object.assign({}, { class: "modal-lg modal-custom-style" })
    );
    this.ModalInitForm();
    // this.displayMsodal = true;
    this.itemId = itemId.itemId;
    if (itemId) {
      this.itemId = itemId.itemId;
      console.log(itemId)
      let cptcode = itemId.cptCode;
      this.search = cptcode;

      let spointlist=itemId.sPointList?.map(item=>{
        return{
          spointId:item.sPointId,
          spointName:item.sPointName
        }
      })

      this.ServiceItemForm.patchValue({
        code: itemId.itemCode,
        name: itemId.itemName,
        sortorder: itemId.sortOrder,
        materialCost: itemId.materialCost,
        basecost: itemId.baseCost,
        group: itemId.groupId,
        ledgerhead: itemId.headId,
        vaccinetype: itemId.vaccineTypeId,
        resultOn: itemId.resultOn,
        cptcode: itemId.cptCodeId,
        MasterItemId: itemId.masterItemId,
        // deptId: itemId.deptId,
        defaulttat: itemId.defaultTAT,
        drugtype: itemId.drugTypeId,
        ValidityDays: itemId.validityDays,
        NoVisits: itemId.validityVisits,
        containers: itemId.containerId,
        AllowCommission: itemId.allowCommission,
        AllowPostPayments: itemId.allowPP,
        AllowDiscount: itemId.allowDisc,
        VitalSignItem: itemId.isVSign,
        ExternalItem: itemId.externalItem,
        StaffMandatory: itemId.staffMandatory,
        AllowRate: itemId.allowRateEdit,
        IsDisplayedGroup: itemId.isDisplayed,
        sampleTypeId:itemId.sampleTypeId,
        routeId: itemId.routeId,
        Unit: itemId.unit,
        VaccineValidation:itemId.vaccineValidation,
        SPointlist:spointlist,
        InsuranceGroupId:itemId.insuranceGroupId
      });

    }
    this.ConfigurationService.GetItemRateAmountById(this.globals.HospitalId, this.itemId).subscribe((res) => {
      this.rowdataRate = res.response;
    });
    this.GetItemTax();
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  GetItemGroupType() {
    let input = {
      "GroupTypeId": 0
    }
    this.ConfigurationService.GetItemGroupType(input).subscribe((res) => {
      this.GetItemGroupTypeData = res.response;
    });
  }

  // SUB GROUP

  SaveSubGroup() {
    this.submitted1 = true;

    if (this.subGroupForm && this.subGroupForm.valid) {
      var AppData = {
        SubGroupName: this.subGroupForm.get("SubGroupName").value,
        subTypeId: this.subGroupForm.get("subTypeId").value,
      }
      let GroupId = 0;
      let ParentId = this.EventId;
      if (this.action == "Edit") {
        GroupId = this.EventId;
        ParentId = this.parentId
      }

      let IsDelete = 0;
      this.ConfigurationService.InsertUpdateServiceItemGroup(GroupId, ParentId, AppData, this.globals.HospitalId, IsDelete).subscribe((res) => {
        this.submitted1 = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == 'Success') {
          if (GroupId == 0) {
            this.toastr.success("subGroup added successfully");
          }
          else {
            this.toastr.success("subGroup Updated successfully");
            this.EventId = 0;
            this.parentId = 0;
            this.clearForm2();
          }

          this.getServiceGroupItems();
          this.modalRef.hide();
          this.GetGroupData();

        }
        else {
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }
  }
  ResetFunctionsubgroups() {
    this.submitted = false;
    this.clearForm2();
  }


  openAssignTemplateModal(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 109)
    if (foundElement.id == 109 && foundElement.isAllowed == true) {
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    }
    let initialState = {
      serviceItem : data,
      branchId: this.BranchesId
    }
    const modalRef = this.modalService.show(AssignTemplateModalComponent, Object.assign(
      {},
      config,
      { class: 'modal-lg', initialState }
    ));
    } else {
      this.toastr.warning("You are not allowed");
    }

  }
  assignTemplate(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 108)
    if (foundElement.id == 108 && foundElement.isAllowed == true) {
    console.log(data);
    
    let initialState={
      itemName:data.itemName,
      userId:this.user_id,
      locationId  :this.locationId,
      itemid:data.itemId,
      rformId:data.rformId
    }
    const modalRef = this.modalService.show(AssignREsultFormComponent, Object.assign({class: 'modal-lg' },{initialState}))
    modalRef.content.getitemEmitter.subscribe((res) => {

      if (res) {
        this.NodeSelect(this.itemData)

      }
    })
    } else {
      this.toastr.warning("You are not allowed");
    }

  }
  getResultForm(){
    let input={
      "StypeId":0,
  
    }
    this.configurationService.GetSampleType(input).subscribe((res) => {
      this.sampleData = res.response;
    });
  
  }

  getVaccinationRoutes(){
    let payload = {
      RouteId: 0
    }

    this.configurationService.getVaccinationRoutes(payload).subscribe(res=>{
      this.vaccinationRouteList = res.response
    })
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  servicepoint() {
    let input = {
      LocationId: 0
    }
    this.labService.GetservicePoin(input).subscribe((res) => {
      this.serviceItemData = res.response;
    });
  }

}
