import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ConfigurationService } from "../../client/services/configuration.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sample-tye',
  templateUrl: './resultPrint.component.html',
  styleUrls: ['./resultPrint.component.scss']
})
export class ResultPrintComponent implements OnInit {
  @ViewChild("template") modaltemp: any;
  isLoading = false;
  resultPrintForm: FormGroup;
  public submitted: boolean = false;
  public accerditationFile: File = null;
  accLogo: '';
  stampLogo: '';
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: Number;
  public stampImgFile: File = null;
  resultprintData: any;
  startDateFrom: any;
  endDateFrom: any;
  functionPermissionData: any;

  constructor(
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    public datepipe: DatePipe,

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {
    this.resultPrintForm = new FormGroup({
      accLogoImg: new FormControl(),
      clinicStampImg: new FormControl(0),
      reportRemark: new FormControl(''),
      PageFooterRemark: new FormControl(''),
      AccExpiryFromDate: new FormControl(''),
      AccExpiryToDate: new FormControl(''),

      BranchId: new FormControl(this.BranchesId)
    });
    this.getResultPrint();
    this.functionPermission();
  }
  ChangeIndicatorImageaccerditation(event: any) {
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png") {
      this.accerditationFile = event.target.files[0];

    }
    else {
      this.toastr.warning("Invalid File Type");
      event.target.value=null
    }
  }
  ChangeIndicatorImageStampImg(event: any) {
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png") {
      this.stampImgFile = event.target.files[0];

    }
    else {
      this.toastr.warning("Invalid File Type");
      event.target.value=null
    }

  }

  save() {
    const foundElement = this.functionPermissionData.find(x => x.id === 168)
    if (foundElement.id == 168 && foundElement.isAllowed == true) {
    this.submitted = true;
    if (this.resultPrintForm && this.resultPrintForm.valid) {
      let form = this.resultPrintForm.getRawValue();
      let fromDateObj = this.resultPrintForm.get("AccExpiryFromDate").value

      if (typeof (fromDateObj) == "object") {

        this.startDateFrom = this.datepipe.transform(fromDateObj, 'dd-MM-yyyy ');
        form.AccExpiryFromDate = this.startDateFrom;

      }
      let toDateObj = this.resultPrintForm.get("AccExpiryToDate").value

      if (typeof (toDateObj) == "object") {

        this.endDateFrom = this.datepipe.transform(toDateObj, 'dd-MM-yyyy ');
        form.AccExpiryToDate = this.endDateFrom;

      }


      const formData = new FormData();
      this.isLoading = true;
      if (this.accerditationFile != null) {
        formData.append('accLogoImg', this.accerditationFile);
      }
      if (this.stampImgFile != null) {
        formData.append('clinicStampImg', this.stampImgFile);
      }

      formData.append('ResultConfigJson', JSON.stringify(form));
      this.configurationService.SaveResultPrint(formData).subscribe(
        (res) => {
          this.submitted = false;

          if (res.status == 200 && res.message == "Success") {
            // this.ResetFunction();
            this.isLoading = false;
            this.toastr.success("Data saved successfully");
            this.getResultPrint()
          } else if (res.status == 200) {
            this.getResultPrint()
            this.isLoading = false;
            this.toastr.error("Failed : " + res.message);
          } else {
            this.isLoading = false;
            this.toastr.error("Failure: " + res.response);
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error("Some unexpected error occures try again later");
        }
      );

    }
    } else {
      this.toastr.warning("You are not allowed to save data");
    }
  }
  getResultPrint() {
    let input = {
      "BranchId": this.BranchesId,

    }
    this.configurationService.getResultprint(input).subscribe((res) => {
      this.resultprintData = res.response;
      this.accLogo = this.resultprintData[0].accLogoImgLoc
      this.stampLogo = this.resultprintData[0].clinicStampImgLoc


      this.resultPrintForm.controls['PageFooterRemark'].setValue(this.resultprintData[0].pageFooterRemark);
      this.resultPrintForm.controls['reportRemark'].setValue(this.resultprintData[0].reportRemark);
      this.resultPrintForm.controls['AccExpiryFromDate'].setValue(this.resultprintData[0].accExpiryFromDate);
      this.resultPrintForm.controls['AccExpiryToDate'].setValue(this.resultprintData[0].accExpiryToDate);


    });


  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }
}


