import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthsGuard } from '../../auths.guard';
import { NabitIntegrationComponent } from './nabit-integration/nabit-integration.component';
import { NabithComponent } from './nabith.component';

const routes: Routes = [
  {
    path: 'nabith/integration',
    component: NabitIntegrationComponent,
    data: { title:'LeHealth | Nabidh', pageName: 'Nabidh' },
    canActivate : [AuthsGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NabithRoutingModule { }
