import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';

@Component({
  selector: 'app-telerik-print',
  templateUrl: './telerik-print.component.html',
  styleUrls: ['./telerik-print.component.scss']
})
export class TelerikPrintComponent implements OnInit {

  @Input() modalHeading: string = '';
  @Input() reportName: string = '';
  @Input() reportParams: any = {};

  public serviceUrl: string = '';
  public viewerContainerStyle = {
    position: 'relative',
    width: '100%',
    height: '550px',
    ['font-family']: 'ms sans serif'
};

  constructor(private activeModalRef: BsModalRef,
    private configurationManager: ConfigurationManager,) { }

  ngOnInit(): void {
    // this.serviceUrl = environment.TELERIK_SERVICE_URL;
    this.serviceUrl = (this.configurationManager as any).reportServiceUrl;
  }

  close(){
    this.activeModalRef.hide();
  }

}
