import { Component, OnInit } from '@angular/core';
import { ConsultationsList } from '../interfaces/ConsultationsList';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// loading Consultation Service
import { ConsultaionsService } from '../services/consultations.service';
import { TreatmentsService } from '../../treatment/services/treatments.service';

import { AllAppointmentsList } from '../interfaces/AllAppointmentsList';

// loading Appointment Service
import { AppointmentsService } from '../services/appointments.service';
import { ToastrService } from 'ngx-toastr';
import { PatientList } from '../interfaces/PatientList';
import { Router } from '@angular/router';
// loading Patients Service
import { PatientService } from '../services/patient.service';

import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../general-action/general-action.component';
import { ConsultantDeskService } from '../services/consultant.service';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { EMRPatientInfo } from '../../emr/interfaces/EMRPatientInfo';
import { EMRService } from '../../emr/services/emr.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SponsorConsentComponent } from '../frontoffice/modals/sponsor-consent/sponsor-consent.component';
import { HealthSummaryModalComponent } from '../../treatment/modals/health-summary-modal/health-summary-modal.component';
import { ConfigurationService } from '../services/configuration.service';
import { PrintAsoapComponent } from '../frontoffice/modals/print-asoap/print-asoap.component';
import { ChangeConsultationCreditModalComponent } from '../../billing-settlement/modals/change-consultation-credit-modal/change-consultation-credit-modal.component';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
import { SettingsService } from '../../settings/services/settings.service';
import { Settings } from '../../settings/model/settings.model';
import { Response } from '../../../_Models/Response';
import _ from 'lodash';
import { StaticPatientConsentComponent } from '../frontoffice/modals/static-patient-consent/static-patient-consent.component';
import { NabithConsentComponent } from '../frontoffice/addregistration/nabith-consent/nabith-consent.component';
import { InformedStaticConsentComponent } from '../frontoffice/modals/informed-static-consent/informed-static-consent.component';
import { RiayatiConsentComponent } from '../frontoffice/addregistration/riayati-consent/riayati-consent.component';
@Component({
  selector: 'app-consultantdeskComponent',
  templateUrl: './consultantdesk.component.html',
  styleUrls: ['./consultantdesk.component.scss'],

})
export class consultantdeskComponent implements OnInit {
  products: any = {};
  mySchedule: boolean;
  myPatients: boolean;
  myAppointment: boolean;
  myConsultation: boolean;
  Personalisations: boolean;
  Notifications: boolean;
  Changepassword: boolean;
  displayModal: boolean;
  displayModal1: boolean;
  displayModaluser: boolean;
  displaybranch: boolean;
  rowData: any = [];
  username = '';
  password = '';
  consultations: ConsultationsList[] = [];
  items: any = {};
  user_role: any;
  user_id: any;
  Id: any;
  appointments: AllAppointmentsList[] = [];
  users: any = [];
  selectedCities: any[] = [];
  availableProducts: any = [];
  selectedProducts: any = [];
  draggedProduct: any = [];
  patients: PatientList[] = [];
  columnDefs: ColDef[] = [];
  columnDefsapp: ColDef[] = [];
  columnDefconsult: ColDef[] = [];
  searchForm: FormGroup;
  ReasonForm: FormGroup;
  treatmentList:any;
  attachdocuments: boolean;
  CancelAppId: number;
  printmodal: boolean;
  myFile: any;
  CancelAppointments: boolean;
  confirmblockingConsultants: any
  treatmentDetail:boolean;
  patientInfo: EMRPatientInfo;

  //ag grid setup
  public gridApiPatient;
  public gridApiAppointment;
  public gridApiConsultation;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.active == '0'; },
      'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
      'c-reds3': function (params) { return params.data.status == 'Cancelled'; },
    },

  }

  public consultationGridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.status == "Cancelled";
      },
      "c-completed": function (params) {
        return params.data.status.toLocaleLowerCase() == "finished";
      },
    },

    domLayout: "autoHeight",

  };
  consultantDataDetails: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  appointmentDetails: any;
  consultationsDetails: any;
  router_url = this.router.url;
  active: string;
  blockorunblock: boolean;
  blockId: number;
  patId: number = 0;
  confirmblock: boolean;
  displayBasicUser: boolean;
  patientId: any;
  displayBasicdragdrop: boolean;
  regDocLocation: any;
  documentList: any;
  pagetype: any;
  patientmodal: any;
  gender: any;
  regDate: any;
  patientName: any;
  address: any;
  mobile: any;
  regNo: any;
  age: any;
  regDetails: PatientList[];
  regIdentities: any;
  historyconsent: boolean;
  ConsentHistory: any;
  consentHistoryList: any;
  consentHistoryListArabic: any;
  consentHistoryPatientName: any;
  consentHistorySignature: any;
  PostponeObj: any;
  consultationId: any;
  CancelledReason: any;
  CancelledReasonData:any;
  symptomDetails: ConsultationsList[];
  ReasonVisit: boolean;
  symptomList: any[];
  dropdownSettings: IDropdownSettings = {};
  result: any;
  blockorunblockconsult: boolean;
  documentFile: File[];
  documentFile1: any;
  displayBasic: boolean;
  showWebcam: boolean;
  public statusBar;
  consentDate: any;
  myObservable$: Subject<unknown>;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  generatedImage: string;
  minDate: any;

  rowGroupPanelShow = "always";
  OtherSymptom: boolean = true;
  consultantDataDetailsEvent: any[];
  BranchId: any;
  valueDate: Date;
  valueDate2: Date;
  FromDate: string;
  ToDate: string;
  minDate1 = new Date();
  appointmentDate: string;
  CurrentappointmentDate: string;
  AppointmentDataDetails: any;
  StartDateFrom: any;
  startDateTo: any;
  functionPermissionData: any;
  localMenus:any=[];
  private changeCreditModalRef?: BsModalRef;
  public consentSettings: Settings[] = [];
  modalRef: BsModalRef;
  healthPlatform: any;

  constructor(
    private consultationService: ConsultaionsService,
    private appointmentService: AppointmentsService,
    private patientService: PatientService,
    private ConsultantDeskService: ConsultantDeskService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private EMRService: EMRService,
    private modalService:BsModalService,
    private TreatmentsService: TreatmentsService,
    private ConfigurationService: ConfigurationService,
    private settingsService: SettingsService,
    private datePipe: DatePipe,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
        localStorage.setItem('BranchId', this.BranchesId);
      }
      this.BranchId = Number(localStorage.getItem('BranchId'));

    });
    this.getApplicationSettings();

  }




  ngOnInit(): void {

    this.valueDate = new Date();
    let dte = new Date();
    dte.setDate(dte.getDate() - 7);
    this.valueDate2 = dte;
    this.valueDate = new Date();

    this.minDate1.setDate(this.minDate1.getDate() - 1);
    let currentappDate = new Date();
    this.CurrentappointmentDate = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    let convertDate = currentappDate.setDate(currentappDate.getDate() + 7);
    this.appointmentDate = this.datepipe.transform(convertDate, 'dd-MM-yyyy');

    this.activatedRoute.params.subscribe(paramsId => {
      this.pagetype = this.activatedRoute.params['value'].pagetype;
      this.TabSelection();
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'symptomId',
      textField: 'newsymDesc',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true
    };






    // getting the values of user_role and user_id  from session

    this.user_role = localStorage.getItem('user_role');
    this.user_id = localStorage.getItem('user_id');
    this.Id = localStorage.getItem('Id');
    this.LoadMyPatient();
    this.LoadMyAppointments();
    this.LoadMyConsultations();

    this.searchForm = this.formBuilder.group({
      sName: new FormControl(''),
      sMobile: new FormControl(''),
      sRegNo: new FormControl(''),
      sAddress: new FormControl(''),
      sEmiratesNo: new FormControl(''),
      sPin: new FormControl(''),
      sDate: new FormControl(''),
      sResNo: new FormControl(''),
      sDepartment: new FormControl('0'),
      sconsultant: new FormControl(''),
      BranchId: this.BranchesId,
      sPhone: new FormControl(''),
      sDateFromapp: new FormControl(''),
      sDateToapp: new FormControl(''),
      sAppointmentType: new FormControl(''),
      blockReason: new FormControl(''),
      Symptoms: new FormControl(''),
      otherreasonforvisit: new FormControl('')
    })

    this.ReasonForm = this.formBuilder.group({
      CancelledReasonData: new FormControl(''),
    })




    //Patients ag grid List
    this.columnDefs = [


      {
        headerName: "Reg. Date",
        minWidth: 180,
        field: 'regDate',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },


      {
        headerName: "Reg. No.",
        minWidth: 180,
        field: 'regNo',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Patient Name",
        minWidth: 180,
        field: 'patientName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Age",
        minWidth: 180,
        field: 'age',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 1,
        editable: false
      },
      {
        headerName: "Mobile",
        minWidth: 180,
        field: 'mobile',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Address",
        minWidth: 180,
        field: 'address',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Sponsor Name",
        minWidth: 180,
        field: 'sponsorName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },

      {
        headerName: "Policy No.",
        minWidth: 180,
        field: 'policyNo',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Nationality Id",
        minWidth: 180,
        field: 'emiratesId',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Last Visit",
        minWidth: 180,
        field: 'lastVisitDate',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },

      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "RegistrationList" },
      },
    ]


    //appointments ag grid

    this.columnDefsapp = [


      {
        headerName: "AppDate",
        minWidth: 180,
        field: 'appDate',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },

      {
        headerName: "Reg.No.",
        field: 'regNo',
        minWidth: 180,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Patient Name",
        minWidth: 180,
        field: 'patientName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Age",
        minWidth: 180,
        field: 'age',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 1,
        editable: false
      },
      {
        headerName: "Mobile",
        minWidth: 180,
        field: 'mobile',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Address",
        field: 'address1',
        minWidth: 180,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Sponsor Name",
        minWidth: 180,
        field: 'sponsorName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },

      {
        headerName: "Policy No.",
        minWidth: 180,
        field: 'policyNo',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Nationality Id",
        minWidth: 180,
        field: 'emiratesId',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Phone No",
        minWidth: 180,
        field: 'resPhone',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Status",
        minWidth: 180,
        field: 'appStatus',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "AppoinmentList" },
      },
    ]

    //consultations Ag Grid List
    this.columnDefconsult = [

      {
        headerName: "Consult Date",
        field: "consultDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        minWidth: 125,
        width: 125,
        maxWidth: 200,
        editable: false
      },

      {
        headerName: "Consult Type",
        field: 'consultType2',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 125,
        width: 125,
        maxWidth: 200,
        editable: false
      },
      {
        headerName: "Visit",
        field: 'visit',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 100,
        width: 100,
        maxWidth: 200,
        editable: false
      },
      {
        headerName: "Reg.No.",
        field: 'regNo',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 100,
        width: 100,
        maxWidth: 200,
        editable: false
      },
      {
        headerName: "Patient Name",
        field: 'patientName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 150,
        width: 150,
        maxWidth: 200,
        editable: false
      },
      {
        headerName: "Status",
        field: 'status',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 100,
        width: 100,
        maxWidth: 200,
        editable: false
      },
      {
        headerName: "Sponsor",
        field: 'sponsor',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 100,
        width: 100,
        maxWidth: 200,
        editable: false
      },
      {
        headerName: "Mobile",
        field: 'mobile',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 100,
        width: 100,
        maxWidth: 200,
        editable: false
      },
      {
        headerName: "Pin",
        field: 'pin',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 100,
        width: 100,
        maxWidth: 200,
        editable: false
      },
      {
        headerName: "Address",
        field: 'address',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 100,
        width: 100,
        maxWidth: 200,
        editable: false
      },
      {
        headerName: "Symptoms",
        field: 'configSymptoms',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 100,
        width: 100,
        maxWidth: 200,
        editable: false
      }, {
        headerName: "Nationality Id",
        field: 'nationalityIdData',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        minWidth: 125,
        width: 125,
        maxWidth: 200,
        editable: false
      },
      {
        headerName: "Action",
        filter: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        width: 100,
        maxWidth: 100,
        sortable: false,
        pinned: "right",
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "MyConsultation" },
      },
    ]



    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };





    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };



    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.functionPermission();

  }


  onTab(number) {
    this.mySchedule = false;
    this.myPatients = false;
    this.myAppointment = false;
    this.myConsultation = false;

    if (number == '1') {
      this.mySchedule = true;
    }
    else if (number == '2') {
      this.myPatients = true;
      localStorage.setItem('selectedMenu', '13');
    }

    else if (number == '3') {
      this.myAppointment = true;
      localStorage.setItem('selectedMenu', '21');
    }
    else if (number == '4') {
      this.myConsultation = true;
      localStorage.setItem('selectedMenu', '14');
    }
    this.functionPermission();
  }

  TabSelection() {
    this.mySchedule = false;
    this.myPatients = false;
    this.myAppointment = false;
    this.myConsultation = false;

    if (this.pagetype == 'myPatients') {
      this.myPatients = true;
    }
    if (this.pagetype == 'mySchedule') {
      this.mySchedule = true;
    }
    if (this.pagetype == 'myAppointment') {
      this.myAppointment = true;
    }
    if (this.pagetype == 'myConsultation') {
      this.myConsultation = true;
    }
  }

  showModalDialog() {

    this.displayModal = true;
  }
  showModalDialogedit() {
    this.username = 'user1';
    this.password = '123';
    this.displayModal1 = true;
  }
  showModalDialogbranch() {
    this.displaybranch = true;
  }

  showModalDialoguser() {
    this.displayModaluser = true;
  }


  //  fetching my-consultation data
  LoadConsultationTable() {



    this.consultationService.GetAllConsultation().subscribe((res) => {
      this.consultations = res.response;

      // if the login user is consulatant, then  filter the consultations based on logined 'consultant id'
      if (this.user_role == 'consultant') {
        if (this.user_id) {
          this.consultations = this.consultations.filter(consultation => consultation.consultantId == this.user_id);

        }
      }

    })
  }

  // fetching my-appointments data
  LoadAppTable() {


    this.appointmentService.GetAllAppointments().subscribe((res) => {
      this.appointments = res.response;

      // if the login user is consulatant, then  filter the appointments based on logined 'consultant id'

      if (this.user_role == 'consultant') {
        if (this.user_id) {
          this.appointments = this.appointments.filter(appointment => appointment.consultantId == this.user_id);

        }
      }
    });



  }

  LoadMyPatient() {
    this.FromDate = this.datepipe.transform(this.valueDate2, 'dd-MM-yyyy');
    this.ToDate = this.datepipe.transform(this.valueDate, 'dd-MM-yyyy')
    this.ConsultantDeskService.GetPatientByConsultants(Number(this.Id), Number(this.BranchId), this.FromDate, this.ToDate).subscribe((res) => {
      this.consultantDataDetails = res.response;
    });
  }

  LoadMyAppointments() {
    this.ConsultantDeskService.GetAppointmentsByConsultants(Number(this.Id), Number(this.BranchId), this.CurrentappointmentDate, this.appointmentDate).subscribe((res) => {
      this.appointmentDetails = res.response;
    });
  }
  LoadMyConsultations() {
    this.ConsultantDeskService.GetConsultationByConsultants(Number(this.Id), Number(this.BranchId), this.FromDate, this.ToDate).subscribe((res) => {
      this.consultationsDetails = res.response;
    });
  }


  searchevent($event) {
    this.patientService.SearchRegPatient($event, this.Id).subscribe((res) => {
      this.consultantDataDetails = res.response;
    })
  }

  //common search event
  searcheventapp($event) {
    let AppStartFrom = $event.value.sDateFromapp;
    let AppStartTo = $event.value.sDateToapp;
    if (typeof (AppStartFrom) == "object") {
      this.StartDateFrom = this.datepipe.transform(AppStartFrom, 'dd-MM-yyyy');

    }
    else {
      this.StartDateFrom = AppStartFrom;
    }
    if (typeof (AppStartTo) == "object") {
      this.startDateTo = this.datepipe.transform(AppStartTo, 'dd-MM-yyyy');

    }
    else {
      this.startDateTo = AppStartTo;
    }
    this.appointmentService.SearchAppointment($event, this.StartDateFrom, this.startDateTo).subscribe((res) => {
      this.appointmentDetails = res.response;
      if (this.appointmentDetails.length == 0) {
        this.toastr.warning("No Appointments found");
      }
    });
  }

  //common search event consultations
  searcheventconsultations($event) {
    this.consultationService.SearchConsultation($event, this.Id).subscribe((res) => {
      this.consultations = res.response;
      this.consultationsDetails = this.consultations;
      if (this.consultations.length == 0) {
        this.toastr.warning("No consultations found");
      }
    })
  }

  //modal for block or unblock
  showBlockUnblock(rowDatas) {
    const foundElement = this.functionPermissionData.find(x => x.id === 445)
    if (foundElement.id == 445 && foundElement.isAllowed == true) {
    this.searchForm.patchValue({ blockReason: '' })
    this.active = rowDatas.active == 1 ? "Block" : "Unblock"
    this.blockId = Number(rowDatas.patientId);
    this.blockorunblock = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  //confirming modal for block or unblock
  confirmblocking() {
    if (this.active == "Unblock") {
      this.ConfirmUnBlock();
    }
    else {
      this.confirmblock = true;
    }
  }
  //confirming block modal function
  ConfirmBlock() {

    let blockReason = this.searchForm.get("blockReason").value;
    this.patientService.BlockPatient(this.blockId, blockReason).subscribe((res) => {

      this.displayBasicUser = false;
      this.LoadMyConsultations();
      this.LoadMyPatient();
    })
    this.confirmblock = false;
  }

  //confirming unblock modal function
  ConfirmUnBlock() {
    this.patientService.UnBlockPatient(this.blockId).subscribe((res) => {

      this.displayBasicUser = false;
      this.LoadMyPatient();
      this.LoadConsultationTable();
      this.LoadMyConsultations();

    })
    this.confirmblock = false;
  }


  //modal showing for attached documents
  attachDocuments() {
    this.attachdocuments = true;
  }
  //Getting Uploaded Attachments
  attachments(rowdatas) {
    const foundElement = this.functionPermissionData.find(x => x.id === 447)
    if (foundElement.id == 447 && foundElement.isAllowed == true) {
    this.patientId = rowdatas.patientId;

    this.displayBasicdragdrop = true;

    if (this.patientId) {
      this.patientService.GetPatientDocuments(Number(this.patientId)).subscribe((res) => {
        this.regDocLocation = res.response;
        this.documentList = this.regDocLocation[0].regDocLocation

      },
        (error) => {
          this.toastr.error("Failure: " + error);
        })
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  //print registration card data
  fillCardData(patient: any) {

    this.patientmodal = patient;
    this.gender = patient.genderName ? patient.genderName : "";
    this.regDate = patient.registeredDate;
    this.patientName = patient.patientName;
    this.patientId = patient.patientId;
    this.address = patient.address;
    this.mobile = patient.mobile;
    this.regNo = patient.regNo;
    this.age = patient.age;

    var printContents = '<div align="center"><div class="card" style="border:1px solid #000;width:60%"><img src="assets/images/xs/imagesdefault.png" alt="image" /><h3>Patient Registration Card</h3>';

    printContents = printContents + ' <table class="print-table1"><tr><td><strong>Registration No.</strong></td><td>: ' + this.regNo + '</td></tr>';
    printContents = printContents + ' <tr><td><strong>Name</strong></td><td>: ' + this.patientName + '</td></tr>';
    printContents = printContents + '<tr><td><strong>Gender</strong></td><td>: ' + this.gender + '</td></tr>';
    printContents = printContents + '<tr><td><strong>Age</strong></td><td>: ' + this.age + '</td></tr>';
    printContents = printContents + '<tr><td><strong>Contact</strong></td><td>: ' + this.mobile + '</td></tr></table></div></div>';
    var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
    mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(printContents);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
    // myWindow.print();
  }



  //  print patient registration details
  fillRegCardData(patient: any) {
    const foundElement = this.functionPermissionData.find(x => x.id === 449)
    if (foundElement.id == 449 && foundElement.isAllowed == true) {
    this.patientService.GetPatientDetailsbyId(patient.patientId).subscribe((res) => {
      this.regDetails = res.response;
      this.regIdentities = this.regDetails[0]['regIdentities'];
      let genderName = this.regDetails[0]['genderName'] ? this.regDetails[0]['genderName'] : "";
      let countryName = this.regDetails[0]['countryName'] ? this.regDetails[0]['countryName'] : "";
      let phone = this.regDetails[0]['resNo'] ? this.regDetails[0]['resNo'] : "";
      let profession = this.regDetails[0]['cmpName'] ? this.regDetails[0]['cmpName'] : '';
      let printContents = '';
      printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:50%"><img src="assets/images/xs/imagesdefault.png" alt="image" />';
      printContents = printContents + ' <table class="print-table1"><tr><td><h3>Patient Registration Details</h3></td></tr><tr><td><strong>Registration No.</strong></td><td>' + patient.regNo + '</td></tr>';
      printContents = printContents + ' <tr><td><strong>Name</strong></td><td>' + this.regDetails[0]['firstName'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Age</strong></td><td>' + this.regDetails[0]['ageInYear'] + ' Years, ' + this.regDetails[0]['ageInMonth'] + ' Months </td></tr>';
      printContents = printContents + '<tr><td><strong>Contact</strong></td><td>' + this.regDetails[0]['mobile'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Registration Scheme</strong></td><td>' + this.regDetails[0]['itemName'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Rate Group</strong></td><td>' + this.regDetails[0]['rGroupName'] + '</td></tr>';
      // printContents = printContents + '</table></div></div>';
      //patient personnel detail
      printContents = printContents + '<tr><td><h3>Personal Details</h3></td></tr>'
      // printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:50%"><img src="assets/images/xs/imagesdefault.png" alt="image" /><h3>Personnel  Details</h3>';
      printContents = printContents + ' <tr><td><strong>Name</strong></td><td>' + this.regDetails[0]['firstName'] + ' ' + this.regDetails[0]['middleName'] + ' ' + this.regDetails[0]['lastName'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Nationality</strong></td><td>' + this.regDetails[0]['nationalityName'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Gender</strong></td><td>' + this.regDetails[0]['genderName'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Marital status</strong></td><td>' + this.regDetails[0]['maritalStatusDescription'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>D.O.B</strong></td><td>' + this.regDetails[0]['dob'] + '</td></tr>';
      //contact detatils
      // printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:50%"><img src="assets/images/xs/imagesdefault.png" alt="image" /><h3>Contact  Details</h3><table>';
      printContents = printContents + '<tr><td><h3>Contact Details</h3></td></tr>'
      printContents = printContents + ' <tr><td><strong>Mobile</strong></td><td>' + this.regDetails[0]['mobile'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Phone</strong></td><td>' + phone + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Office</strong></td><td>' + this.regDetails[0]['offNo'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Fax</strong></td><td>' + this.regDetails[0]['faxNo'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Email</strong></td><td>' + this.regDetails[0]['email'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Passport No</strong></td><td>' + this.regIdentities[0]['identityNo'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Visa Type</strong></td><td>' + this.regDetails[0]['visaType'] + '</td></tr>';
      //office details
      printContents = printContents + '<tr><td><h3>Office Details</h3></td></tr>'
      // printContents = printContents + ' <tr><td><strong>Employer Name</strong></td><td>' +this.regDetails[0].profName + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Profession</strong></td><td>' + this.regDetails[0]['profName'] + '</td></tr>';
      printContents = printContents + ' <tr><td><strong>Work Environment</strong></td><td>' + this.regDetails[0]['workEnvironMent'] + '</td></tr>';
      printContents = printContents + ' <tr><td><strong>Professional Noxious</strong></td><td>' + this.regDetails[0]['professionalNoxious'] + '</td></tr>';
      printContents = printContents + ' <tr><td><strong>Work Experience</strong></td><td>' + this.regDetails[0]['professionalExperience'] + '</td></tr>';
      printContents = printContents + '</table></div></div>';
      var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
      mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
      mywindow.document.write('</head><body >');
      mywindow.document.write(printContents);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    });
    return true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  /*========================Consenthistory modal=========================*/
  consentHistory(patient: any) {
    this.historyconsent = true;
    let Appdata = {
      patientId: Number(patient.patientId),
      branchId: Number(this.globals.HospitalId),
      sponsorId: 0,
      consentType: "PC"

    }
    this.patientService.ConsentHistoryModal(Appdata).subscribe((res) => {
      this.ConsentHistory = res.response;
      this.consentHistoryList = this.ConsentHistory[0]['consentContentValue'][0].ctEnglish;
      this.consentHistoryListArabic = this.ConsentHistory[0]['consentContentValue'][0].ctArabic;
      this.consentHistoryPatientName = this.ConsentHistory[0]['patientName'];
      this.consentHistorySignature = this.ConsentHistory[0]['fileLoc'];

    })
  }


  SponsrorConsent(data,type){
    let actionId;
    if(type == "PC") {
      actionId = 452;
    } else if(type == "IC") {
      actionId = 453;
    } else if(type == "SC") {
      actionId = 454;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    if(type == 'SC' && data.sponsorId == 0){
      this.toastr.warning("Patient doesn't have sponsor..!!");
      return
    }
    
    if(type == 'SC' && data.consentStatus == 0){
      this.toastr.warning("Sponsor doesn't have consent..!!");
      return
    }

    if (this.consentSettings[1].settValue == '0' && type == 'PC') {
      let initialState = {
        rowData: data
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(StaticPatientConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    } else if (this.consentSettings[0].settValue == '0' && type == 'IC') {
      let initialState = {
        ConsentPatientId: data.patientId,
        cType: type,
        data:data,
        branchId: this.globals.HospitalId,
        consultationId: data.consultationId
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(InformedStaticConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    } else {

      let initialState = {
        ConsentPatientId: data.patientId,
        cType: type,
        SelectedSponsor: data.sponsorId,
        data:data
      }
      console.log(initialState)
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(SponsorConsentComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }


  AppointmentPostPoned(appId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 460)
    if (foundElement.id == 460 && foundElement.isAllowed == true) {
    let obj = {
      AppId: appId,
      BranchId: this.BranchesId
    }
    this.PostponeObj = obj;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }


  // adding reload for child userdetails click
  reload() {
    this.LoadMyAppointments()
  }


  showCancelAppointment(rowDatas) {
    const foundElement = this.functionPermissionData.find(x => x.id === 464)
    if (foundElement.id == 464 && foundElement.isAllowed == true) {
    this.CancelAppId = rowDatas;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }



  showCancelAppntmnt($event) {
  }
  // ReasonForVisitModal(id) {

  //   this.consultationId = id.consultationId;
  //   this.GetTemplateReasonForVisit();
  //   this.consultationService.Getsymptoms(this.consultationId).subscribe((res) => {
  //     this.symptomDetails = res.response;
  //     this.searchForm.patchValue({ Symptoms: this.symptomDetails['symptoms'] });
  //   })


  //   this.ReasonVisit = true;


  // }

  ReasonForVisitModal(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 471)
    if (foundElement.id == 471 && foundElement.isAllowed == true) {
      this.searchForm.patchValue({ Symptoms: [] });
      this.searchForm.patchValue({ otherreasonforvisit: "" });
    this.consultationId = id.consultationId;
    this.GetTemplateReasonForVisit();
    this.consultationService.Getsymptoms(this.consultationId).subscribe((res) => {
      this.symptomDetails = res.response;
      this.searchForm.patchValue({ Symptoms: this.symptomDetails['symptoms'] });
      if (this.symptomDetails['reasonForVisit']) {
        this.OtherSymptom = true;
        this.searchForm.patchValue({ otherreasonforvisit: this.symptomDetails['reasonForVisit'] });
      }
      document.getElementById("myDiv").click();
    })


    this.ReasonVisit = true;

    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  ReasonForCancellation(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 472)
    if (foundElement.id == 472 && foundElement.isAllowed == true) {
    this.rowData;
    this.CancelledReason = true;
    // this.CancelledReasonData= id.cancelReason 
    this.ReasonForm.patchValue({ CancelledReasonData:  id.cancelReason })
    } else {
      this.toastr.warning("You are not allowed");
    }
  }




  // //Getting teplates for symtoms and reason for visit
  // GetTemplateReasonForVisit() {
  //   // this.searchForm.

  //   this.searchForm.controls['Symptoms'].setValue([]);
  //   //  this.searchForm.patchValue()
  //   let showall = 0;
  //   this.consultationService.GetTemplateReasonForvisit(this.globals.HospitalId, showall).subscribe((res) => {
  //     this.symptomList = res.response;

  //     let syptomReasonList = [];
  //     syptomReasonList.push({
  //       SymptomId: 0,
  //       SymptomDesc: "None Of the Above",
  //       Active: 1,
  //       BlockReason: "",
  //     });
  //     this.symptomList = syptomReasonList.concat(this.symptomList);
  //     this.symptomList.forEach((element) => {
  //       element["newsymDesc"] = element["SymptomDesc"] ? element["SymptomDesc"] : element["symptomDesc"];
  //     });
  //   });
  // }
  GetTemplateReasonForVisit() {
    let ShowAll = 0;
    this.consultationService.GetTemplateReasonForvisit(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.symptomList = res.response;

      // let syptomReasonList = [];
      // syptomReasonList.push({
      //   SymptomId: 0,
      //   SymptomDesc: "None Of the Above",
      //   Active: 1,
      //   BlockReason: "",
      // });
      // this.symptomList = syptomReasonList.concat(this.symptomList);
      this.symptomList.forEach((element) => {
        element["newsymDesc"] = element["SymptomDesc"] ? element["SymptomDesc"] : element["symptomDesc"];
      });
    });
  }



  //updating reason for visit
  SaveReasonForVisit(id) {

    var AppData = {
      ConsultationId: this.consultationId,
      Symptoms: this.searchForm.get("Symptoms").value,
      otherreasonforvisit: this.searchForm.get("otherreasonforvisit").value,

    };

    this.consultationService.SaveReasonVisit(AppData).subscribe((res) => {
      this.result = res;

      if (this.result.status == 200) {
        this.toastr.success("Reason For Visit added successfully");
        this.LoadMyConsultations();

      } else {

        this.toastr.error("Reason For Visit failed");
      }
    });


  }


  //modal for block or unblock
  showBlockUnblockConsultation(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 465)
    if (foundElement.id == 465 && foundElement.isAllowed == true) {
    this.rowData;
    let appointmentsData = this.rowData.filter((consultantdata) => consultantdata['consultationId'] == id);
    this.searchForm.patchValue({ blockReason: '' })
    this.active = (id.status == 'Waiting') ? "Cancel" : "Cancelled"
    this.blockId = Number(id.consultationId);
    this.patId = Number(id.patientId);
    this.confirmblockingConsultants = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  //confirming modal for block or unblock
  confirmblockingConsult(blockId: any) {

    let blockReason = this.searchForm.get("blockReason").value;
    this.consultationService.CancelConsultation(this.blockId, blockReason).subscribe((res) => {
      this.LoadConsultationTable();
      this.LoadMyPatient();
      this.LoadMyConsultations();
    })
    this.confirmblockingConsultants = false;
  }

  ConfirmBlockConsultant() {

    let blockReason = this.searchForm.get("blockReason").value;
    this.consultationService.CancelConsultation(this.blockId, blockReason).subscribe((res) => {
      this.LoadConsultationTable();
      this.LoadMyConsultations();
      let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
      if (res.status == 200 && res.message == 'success') {
        this.toastr.success("Consultation cancelled")
        if (this.healthPlatform.settValue === "Nabidh") {
        const foundElement = this.functionPermissionData.find(x => x.id === 563)
        // console.log('foundElement', foundElement);
        if (foundElement.id == 563 && foundElement.isAllowed == true) {
          let nabPayload = {
            EventId: 5,
            Consultationid: this.blockId,
            EntryDate: currentDate,
            PatientId: this.patId,
            BranchId : this.globals.HospitalId
          }
          this.patientService.SendDetailstoNabith(nabPayload).subscribe((res) => {
            this.toastr.info('Response from Nabith:' + res);
          })
        } else {
          this.toastr.warning('No permission to send data to nabith');
        }
      }
    }
    })
    this.confirmblockingConsultants = false;

  }

  //Uploading Attachments

  onUpload(event) {
    this.documentFile = <File[]>event.files;

    const formData = new FormData();
    if (this.documentFile) {

      for (var i = 0; i < this.documentFile.length; i++) {

        formData.append('PatientDocs', this.documentFile[i]);

      }
    }
    if (this.documentFile1) {

      formData.append('PatientPhoto', this.documentFile1, this.documentFile1.name);

    }

    formData.append('PatientJson', JSON.stringify({ 'PatientId': this.patientId }));

    this.patientService.GetPatientDocumentsFiles(formData).subscribe((res) => {
      this.result = res;

      if (this.result.status == 200) {


        this.toastr.success("Files Uploaded successfully");

      } else {

        this.toastr.error("Files Uploading failed");
      }
    });
    this.displayBasicdragdrop = false;

  }


  public get triggerObservable(): Observable<void> {

    return this.trigger.asObservable();
  }

  //Web Cam saving Service Calling
  webCamSave(event) {

    const formData = new FormData();
    if (this.documentFile) {

      for (var i = 0; i < this.documentFile.length; i++) {

        formData.append('PatientDocs', this.documentFile[i]);

      }
    }
    if (this.documentFile1) {

      formData.append('PatientPhoto', this.documentFile1, this.documentFile1.name);

    }

    formData.append('PatientJson', JSON.stringify({ 'PatientId': this.patientId }));

    this.patientService.GetPatientDocumentsFiles(formData).subscribe((res) => {
      this.result = res;

      if (this.result.status == 200) {


        this.toastr.success("Images Uploaded successfully");

      } else {

        this.toastr.error("Images Uploading failed");
      }
    });
    this.displayBasic = false;
  }



  camerclick(rowdatas) {
    const foundElement = this.functionPermissionData.find(x => x.id === 448)
    if (foundElement.id == 448 && foundElement.isAllowed == true) {
    this.patientId = rowdatas.patientId;
    this.displayBasic = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  public toggleWebcam(): void {

    this.showWebcam = !this.showWebcam;
  }
  triggerSnapshot(): void {
    this.myObservable$ = new Subject();
    this.myObservable$.next();
    this.trigger.next();
  }
  handleImage(webcamImage: WebcamImage): void {

    this.webcamImage = webcamImage;

    const imageName = "patient.jpeg";// new Date().toString();
    const base64 = webcamImage.imageAsDataUrl.split('base64,');

    const imageBlob = this.dataURItoBlob(base64[1]);
    const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    this.documentFile1 = imageFile;

    this.generatedImage = window.URL.createObjectURL(imageFile);

  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  fromdatechange(event) {
    let StartDateObj = this.searchForm.get("sDateFrom").value;
    this.minDate = StartDateObj;
  }

  // delete Uploaded documents
  DeleteUploadedDoc(docId) {
    if (confirm("Are you sure that you want to delete this file ?")) {
      this.patientService.DeleteUploadedDoc(Number(docId)).subscribe((res) => {
        this.result = res;
        if (this.result.message == 'success') {
          // this.attachments();
          this.toastr.success("The file deleted successfully");
        }
        else {
          this.toastr.error("Failure: " + this.result.message);
        }

      },
        (error) => {
          this.toastr.error("Failure: " + error);
        });
    }
  }

  //print appointment card details

  //print appointment card
  PrintAppointmentCard(appId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 461)
    if (foundElement.id == 461 && foundElement.isAllowed == true) {

      let initialState = {
        modalHeading: 'Print Appointment',
        reportName: 'PrintAppointment.trdp',
        reportParams: {
          AppId: appId,
          BranchId : this.globals.HospitalId
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    // this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
    //   this.AppointmentDataDetails = res.response;
    //   let sliceDatatime = [];
    //   this.AppointmentDataDetails['sliceData'].forEach(a => {
    //     sliceDatatime.push(a.sliceTime);
    //   });
    //   let printContents = "";
    //   //contact detatils

    //   printContents =
    //     printContents +
    //     '<div align="center"><div class="card" style="border:1px solid #000;width:50%">';
    //   printContents =
    //     printContents +
    //     ' <table class="print-table1"><tr><td><h3>Appointment Details</h3></td></tr><tr><td><strong>Appointment Date.</strong></td><td>' +
    //     this.AppointmentDataDetails["appDate"] +
    //     "</td></tr>";

    //   printContents =
    //     printContents +
    //     " <tr><td><strong>App Time:</strong></td><td>" +
    //     sliceDatatime.toString() +
    //     "</td></tr>";

    //   printContents =
    //     printContents +
    //     " <tr><td><strong>Consultant Name:</strong></td><td>" +
    //     this.AppointmentDataDetails["consultantName"] +
    //     "</td></tr>";
    //   printContents =
    //     printContents +
    //     " <tr><td><strong>Patient Name:</strong></td><td>" +
    //     this.AppointmentDataDetails["firstName"] +
    //     "</td></tr>";

    //   var mywindow = window.open("", "PRINT", "height=1000,width=1200");
    //   mywindow.document.write(
    //     '<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' +
    //     document.title +
    //     "</title>"
    //   );
    //   mywindow.document.write("</head><body >");
    //   mywindow.document.write(printContents);
    //   mywindow.document.write("</body></html>");

    //   mywindow.document.close(); 
    //   mywindow.focus(); 

    //   mywindow.print();
    //   mywindow.close();
    // });
    return true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  //pagetitle to be displayed at the header
  Activetab(pagetitle) {
    const foundElement = this.functionPermissionData.find(x => x.id === 443)
    if (foundElement.id == 443 && foundElement.isAllowed == true) {
      sessionStorage.setItem("pageTitle", pagetitle);
      this.router.navigate(["/AddRegistration"]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  ActivetabApp(pagetitle) {
      sessionStorage.setItem("pageTitle", pagetitle);
  }

  ActivetabCon(pagetitle) {
    const foundElement = this.functionPermissionData.find(x => x.id === 499)
    if (foundElement.id == 499 && foundElement.isAllowed == true) {
      sessionStorage.setItem("pageTitle", pagetitle);
      this.router.navigate(["/AddConsultation"]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  //  checking Agi grid is loaded completely
  onGridReadyPatient(params) {
    this.gridApiPatient = params.api;
  }
  onGridReadyAppointment(params) {
    this.gridApiAppointment = params.api;
  }
  onGridReadyConsultation(params) {
    this.gridApiConsultation = params.api;
  }

  onFilterTextBoxChangedPatient() {
    this.gridApiPatient.setQuickFilter(
      (document.getElementById('filter-text-box-patient') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedAppointment() {
    this.gridApiAppointment.setQuickFilter(
      (document.getElementById('filter-text-box-appointment') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedConsultation() {
    this.gridApiConsultation.setQuickFilter(
      (document.getElementById('filter-text-box-consultation') as HTMLInputElement).value
    );
  }
  //open image modal
  OpenImage(filePath: string) {//document view
    window.open(filePath, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
  }

  //Consent History printing function

  consentHistoryPrint(patient: any) {
    this.patientmodal = patient;
    let printContents = '';

    printContents = printContents + ' <table class="print-table1"><tr><td><strong>Consent Form</strong></td></tr>';
    printContents = printContents + ' <tr><td><img src="assets/images/xs/logo_sample.png" alt="image" /></td></tr>';
    printContents = printContents + '<tr><td></td><td>: ' + this.consentHistoryList + '</td></tr>';
    printContents = printContents + ' <tr><td><strong>Patient Name</strong></td><td>: ' + this.consentHistoryPatientName + '</td></tr>';
    printContents = printContents + ' <tr><td><strong>Date</strong></td><td>: ' + this.consentDate + '</td></tr>';
    printContents = printContents + ' <tr><td> <strong>Sign</strong>:' + '<img src="' + this.consentHistorySignature + '" />' + '</td></tr>';


    var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
    mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(printContents);


    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
    // myWindow.print();
  }

  //reason for visit symptoms list
  // SymptomReason() {
  //   let val = this.searchForm.get("Symptoms").value;

  //   if (val.length > 0) {
  //     if (val[0]["newsymDesc"] == "None Of the Above") {
  //       this.OtherSymptom = true;
  //     } else {
  //       this.OtherSymptom = false;
  //     }
  //   } else {
  //     this.OtherSymptom = false;
  //   }
  // }

  GoToEmr(patientData) {
    const foundElement = this.functionPermissionData.find(x => x.id === 467)
    if (foundElement.id == 467 && foundElement.isAllowed == true) {
      // console.log(patientData)
    this.patientInfo = {
      'VisitId': 0,
      'ConsultantId': Number(localStorage.getItem('Id')),
      'ConsultationId': patientData.consultationId,
      'lastConsultationId': patientData.consultationId,
      'PatientId': patientData.patientId,
      'VisitType': 0,
      // 'VisitStartTime': '',
      // 'VisitEndTime': '',
      'VisitStartTime': patientData.cstarttime,
      'VisitEndTime': patientData.cendtime !== null ?  patientData.cendtime : '',
      'UserId': Number(localStorage.getItem('user_id')),
      'CrudType': 0,
      InsAmountLimit: 0,
      insCovered: 0,
      sFormId:patientData.sFormId,
      telerikName:patientData.telerikName
    }
    // console.log(patientData,this.patientInfo)
    let patInfo = {
      ConsultationId: patientData.consultationId,
      SponsorId: patientData.sponsorId,
      telerikName:patientData.telerikName
    }
    localStorage.setItem('asoapPatInfo', JSON.stringify(patInfo));

    let OutPut = this.EMRService.SetEMRPatientInfo(this.patientInfo);
    if (OutPut) {
      this.router.navigate(['/EMR_Home']);
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onRegisterfromAppoinment(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 463)
    if (foundElement.id == 463 && foundElement.isAllowed == true) {
    this.router.navigate(['/AddRegistration'], {state: {appoinmentId: data.appId}});
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  treatmentDetails(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 466)
    if (foundElement.id == 466 && foundElement.isAllowed == true) {

      let initialState = {
        modalHeading: 'Treatment Summary',
        reportName: 'TreatmentSummary.trdp',
        reportParams: {
          PatientId : data.patientId,
          FromDate : (data?.consultDate.slice(3, 5) +'-'+data?.consultDate.slice(0,2) + '-' + data?.consultDate.slice(6, 10)),
          ToDate: (data?.consultDate.slice(3, 5) +'-'+data?.consultDate.slice(0,2) + '-' + data?.consultDate.slice(6, 10)),
          ConsultationId : data?.consultationId
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
//     this.treatmentDetail = true;

//   let pID=data.patientId
//    let input={
//   "branchId":Number(this.globals.HospitalId),
//   "PatientId":Number(pID),
//   "dateFrom":"",
//   "dateTo":"",
//   "TreatmentNumber":"",
//   "id":0,
//   "PatientName":"",
//   "mobile":"",
//   "regNo":""
// }
// this.TreatmentsService.GetTreatments(input).subscribe(
//   (res) => {
//     if (res.status == 200) {

//       this.treatmentList = res.response;
//       if (this.treatmentList.length == 0) {
//         this.toastr.warning("No Treatment Details");
//       }

//     }
//   });
//   this.treatmentDetail = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  appointmentEdit(appId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 459)
    if (foundElement.id == 459 && foundElement.isAllowed == true) {
      this.router.navigate(["/AddAppointment", appId]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  consultationAction(appId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 462)
    if (foundElement.id == 462 && foundElement.isAllowed == true) {
      if (appId) {
        this.router.navigate(["/AddConsultation", appId]);
      } else {
        this.router.navigate(["/AddRegistration"]);
      }    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  editRegistration(patientId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 444)
    if (foundElement.id == 444 && foundElement.isAllowed == true) {
      this.router.navigate(["/AddRegistration", patientId]);
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  onPrintRegistrationCard(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 446)
    if (foundElement.id == 446 && foundElement.isAllowed == true) {
      // need to add the functionalities inside this if condition(this is for functional permission)
        } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onPrintSticker(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 450)
    if (foundElement.id == 450 && foundElement.isAllowed == true) {
      // need to add the functionalities inside this if condition(This is for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  printBarcode(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 451)
    if (foundElement.id == 451 && foundElement.isAllowed == true) {
      // need to add the functionalities inside this if condition(This is for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  
  openCredit(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 455)
    if (foundElement.id == 455 && foundElement.isAllowed == true) {
      // need to add the functionalities inside this if condition(This is for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  
  editCredit(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 606)
    if (foundElement?.id == 606 && foundElement.isAllowed == true) {
      // need to write the function definition inside this if condition
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  gotoConsultation(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 456)
    if (foundElement.id == 456 && foundElement.isAllowed == true) {
      // need to add the functionalities inside this if condition(This is for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  
  showHealthSummary(){
    const foundElement = this.functionPermissionData.find(x => x.id === 607)
    if (foundElement?.id == 607 && foundElement.isAllowed == true) {

    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  gotoPatientQuestionnaire(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 457)
    if (foundElement.id == 457 && foundElement.isAllowed == true) {
      // need to add the functionalities inside this if condition(This is for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  printAsoap(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 604)
    if (foundElement.id == 604 && foundElement.isAllowed == true) {
    let initialState = {
      consultationId: data.consultationId,
      branchId: this.BranchesId,
      sponsorId: data.sponsorId,
      telerikName:data.telerikName
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(PrintAsoapComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  PrintStickerFormat(data, type) {
    let actionId;
    // if(type == "Print") {
    //   actionId = 468;
    // } else 
    if(type == "ClaimForm") {
      actionId = 469;
    } 
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
      // need to add the functionalities inside this if condition(This is for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  changeConsultationCredit(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 470)
    if (foundElement.id == 470 && foundElement.isAllowed == true) {
      let initialState = {
        branchesId: this.BranchesId,
        patientId: data.patientId,
        rowData: data
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.changeCreditModalRef = this.modalService.show(ChangeConsultationCreditModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
      this.changeCreditModalRef.content.creditChange.subscribe(res => {
        if (res.isChanged) {
          this.LoadMyConsultations();
        }
      })
    } else {
      this.toastr.warning("You are not allowed to change credit");
    }
  }

  onMedicalRecord(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 602)
    if (foundElement?.id == 602 && foundElement.isAllowed == true) {
    let initialState = {
      modalHeading: 'Medical Record',
      reportName: 'RptMedicalRecord.trdp',
      reportParams: {
        ConsultationId : data?.consultationId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  getApplicationSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }

    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.consentSettings = _.filter(response.response, {tabId: 5});
          this.healthPlatform=response.response.find(item => item.settId == 25)
        }
      }
    })
  }

  nabithConsent(patientId, consultationId, menu) {
    let actionId;
    if(menu == 'myPatient'){
      actionId = 605;
    } else if(menu == 'myConsultation'){
      actionId = 603;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement?.id == actionId && foundElement.isAllowed == true) {
    if (this.healthPlatform.settValue === "Nabidh") {
    let initialState = {
      patientId: patientId,
      ConsultationId: consultationId,
      action: 1,
      formName:this.healthPlatform.settValue
    }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(NabithConsentComponent,Object.assign( {}, config, { class: 'modal-xl', initialState }) );
    this.modalRef.content.fileEmitter.subscribe((response: any)=>{
      if(response.status == true){
        // this.toastr.success("The signature updated successfully");
      }
    })
    } else if (this.healthPlatform.settValue === "Riayati") {
      let initialState = {
        patientId: patientId,
        ConsultationId: consultationId,
      }

      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.modalRef = this.modalService.show(RiayatiConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
      this.modalRef.content.fileEmitter.subscribe((response: any) => {
        if (response.status == true) {
          // this.toastr.success("The signature updated successfully");
        }
      })
    }
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  onPatientEmr(patientData) {
    const foundElement = this.functionPermissionData.find(x => x.id === 542)
    if (foundElement.id == 542 && foundElement.isAllowed == true) {
      this.patientInfo = {
        'VisitId': 0,
        'ConsultantId': Number(localStorage.getItem('Id')),
        'ConsultationId': patientData.consultationId,
        'lastConsultationId': patientData.consultationId,
        'PatientId': patientData.patientId,
        'VisitType': 0,
        // 'VisitStartTime': '',
        // 'VisitEndTime': '',
        'VisitStartTime': patientData.cstarttime,
        'VisitEndTime': patientData.cendtime !== null ? patientData.cendtime : '',
        'UserId': Number(localStorage.getItem('user_id')),
        'CrudType': 0,
        InsAmountLimit: 0,
        insCovered: 0,
        sFormId: patientData.sFormId,
        telerikName: patientData.telerikName
      }
      let patInfo = {
        ConsultationId: patientData.consultationId,
        SponsorId: patientData.sponsorId,
        telerikName: patientData.telerikName
      }
      localStorage.setItem('asoapPatInfo', JSON.stringify(patInfo));

      let OutPut = this.EMRService.SetEMRPatientInfo(this.patientInfo);
      if (OutPut) {
        this.router.navigate(['/EMR_Home']);
      }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

}

