import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CommonValidationComponent } from '../../../../shared/common-validation/common-validation.component';
import { ConfigurationService } from '../../../client/services/configuration.service';

@Component({
  selector: 'app-sign-popup',
  templateUrl: './sign-popup.component.html',
  styleUrls: ['./sign-popup.component.scss']
})
export class SignPopupComponent implements OnInit {


  @Output() copyEmitter = new EventEmitter();
  public validator = new CommonValidationComponent();
  @Input() branchId: any;

  public signSympotns: FormGroup;
  public visitHistoryList: any[] = [];
  name = "Sign";
  public copyLoading: boolean = false;
  submitValue:any;
  SymptomId:any;
  constructor(private activeModal: BsModalRef, private toastr: ToastrService,
              private formBuilder: FormBuilder,
              private modalService: BsModalService,private ConfigurationService: ConfigurationService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
 this.signSympotns = this.formBuilder.group({
   symptomDesc: new FormControl('', [Validators.required,this.validator.noWhitespaceValidator]),
    // IsSign: new FormControl(true),
    IsDisplayedSymptom: new FormControl(true)
 }) 
  }

onTab(number) {
  if (number == "1") {
    this.name = 'Sign';
  }else if (number == "2") {
    this.name = 'Symptoms';
  }
}


  onNextClicked(){
    let appData;
    let saveupdateId = 0;;
    if(this.name === "Sign"){
      if (this.signSympotns && this.signSympotns.valid) {
        appData = {
          signDesc: this.signSympotns.value.symptomDesc,
          isDisplayed: Number(this.signSympotns.value.IsDisplayedSymptom)
        }
        // this.isLoading=true;
  
        this.ConfigurationService.InsertUpdateSign(appData, this.branchId, saveupdateId).subscribe((res) => {
          this.submitValue = res;
          if (res.status == 200 && res.message == 'Success') {
              this.searchSign(true,appData)
              this.toastr.success("Sign Added successfully");
            }
          else {
            this.toastr.error("Failed : " + this.submitValue.message);
          }
        })
      }
    } else {
    
        if (this.signSympotns && this.signSympotns.valid) {
          appData = {
            symptomDesc: this.signSympotns.value.symptomDesc,
            isDisplayed: Number(this.signSympotns.value.IsDisplayedSymptom)
          }
    
          this.ConfigurationService.InsertUpdateSymptom(appData, this.branchId, saveupdateId).subscribe((res) => {
            this.submitValue = res;
            if (res.status == 200 && res.message == 'Success') {
                this.searchSign(false,appData)
                this.toastr.success("Symptom Added successfully");
            }
            else {
              this.toastr.error("Failed : " + this.submitValue.message);
            }
          })
        }
    }
  }
  searchSign(i,data){
    let param;
    if(i){
      param = {
        Id: 0 ,
        ShowAll: 1,
        BranchId: this.branchId,
        SignDesc: data.signDesc

    }
    } else {
      param ={
        SymptomId: 0,
        ShowAll: 0,
        BranchId: this.branchId,
        SymptomDesc: data.symptomDesc
      }
    }
    this.ConfigurationService.searchBySign(i,param).subscribe((res:any)=>{
      let data = res.response[0]
      this.copyEmitter.emit({data:data,status: true,sign:i});
      this.close();
    })
  }
  close(){
    this.activeModal.hide();
  }

}
