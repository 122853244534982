import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { TreatmentDetailsComponent } from "./treatment-details/treatment-details.component";
import { TreatmentComponent } from "./treatment.component";
import { TreatmentListComponent } from "./treatment-list/treatment-list.component";
import { AuthsGuard } from "../../auths.guard";
import { VaccinationDetailsComponent } from "./vaccination-details/vaccination-details.component";
import { PhysiothearapyTreatmentComponent } from "./physiothearapy-treatment/physiothearapy-treatment.component";
import { PhysiothearapyTreatmentDetailsComponent } from "./physiothearapy-treatment-details/physiothearapy-treatment-details.component";
import { PhysiothearapySummaryComponent } from "./physiothearapy-summary/physiothearapy-summary.component";

const routes: Routes = [
  {
    path: "Treatment_Dashboard",
    component: DashboardComponent,
    data: { title: "LeHealth | Treatment", pageName: "Treatment" },
    canActivate: [AuthsGuard],
  },
  {
    path: "Treatment_details",
    component: TreatmentDetailsComponent,
    data: { title: "LeHealth | Treatment", pageName: "Treatment Details" },
    canActivate: [AuthsGuard],
  },
  {
    path: "physiothearapy-treatment",
    component: PhysiothearapyTreatmentComponent,
    data: {
      title: "LeHealth | Treatment",
      pageName: "Physiotherapy Treatment",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "physiothearapy-treatmentDetails",
    component: PhysiothearapyTreatmentDetailsComponent,
    data: {
      title: "LeHealth | TreatmentDetails",
      pageName: "Physiotherapy Treatment Details",
    },
    canActivate: [AuthsGuard],
  },

  {
    path: "physiothearapy-summary",
    component: PhysiothearapySummaryComponent,
    data: { title: "LeHealth | Treatment", pageName: "Physiotherapy Summary" },
    canActivate: [AuthsGuard],
  },

  {
    path: "Treatment_List",
    component: TreatmentListComponent,
    data: { title: "LeHealth | TreatmentList", pageName: "Treatment" },
    canActivate: [AuthsGuard],
  },
  {
    path: "Treatment_details/:patientId/:ConsultationId",
    component: TreatmentDetailsComponent,
    data: { title: "LeHealth | Treatment", pageName: "Treatment Details" },
    canActivate: [AuthsGuard],
  },
  {
    path: "vaccination",
    component: VaccinationDetailsComponent,
    data: { title: "LeHealth | Vaccination", pageName: "Vaccination" },
    canActivate: [AuthsGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TreatmentRoutingModule {}
