import { Component, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../shared/shared.service';
import { BillingService } from '../services/billing.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { ColDef, GridApi } from 'ag-grid-community';
import { OpenshiftComponent } from './openshift/openshift.component';
import { CloseshiftComponent } from './closeshift/closeshift.component';
import { Response } from '../../../_Models/Response';
import { BillingGeneralActionComponent } from '../billing-general-action/billing-general-action.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfigurationService } from '../../client/services/configuration.service';

@Component({
  selector: 'app-manage-shift',
  templateUrl: './manage-shift.component.html',
  styleUrls: ['./manage-shift.component.scss']
})
export class ManageShiftComponent implements OnInit {

  public gridApi: GridApi;
  columnDefs: ColDef[] = [];
  public defaultColDef: ColDef;
  public context;
  public statusBar;
  public frameworkComponents;
  public loadingTemplate = '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    
  }
  rowGroupPanelShow = "always";
  subscription: Subscription;
  globals: globalvars = new globalvars();
  public manageshift: any[] = [];

  public shiftForm: FormGroup;
  functionPermissionData: any;

  constructor(
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private BillingService: BillingService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private ConfigurationService: ConfigurationService,
  ) { 
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });

  }

  ngOnInit(): void {

    this.columnDefs = [

      {
        headerName: "Shift Date",
        minWidth: 100,
        field: "shiftDate",  
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Shift No.",
        field: "shiftNo",
        minWidth: 100,
        resizable: false,
        sortable: true,
        flex: 1,
      },
      {
        headerName: "User Name",
        field: "userName",
        minWidth: 150, 
        resizable: false,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Location",
        field: "location",
        minWidth: 100,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "From",
        field: "shiftFrom",
        minWidth: 100,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "To",
        field: "closedDate",
        minWidth: 100,    
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Opening Balance",
        field: "openingBalance",
        minWidth: 150,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Status",
        field: "status",
        minWidth: 150, 
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Closed By",
        field: "closedUser",
        minWidth: 150, 
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Closing Cash",
        field: "closingBalance",
        minWidth: 150, 
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        filter: false,
        resizable: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        unSortIcon: true,
        field: "manageshiftid",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "manageShift" },
      },
    ]

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: BillingGeneralActionComponent
    };
   
    this.initShiftForm();
    this.functionPermission();
  }

  initShiftForm(){
    this.shiftForm = this.formBuilder.group({
      status: new FormControl('O')
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.GetShift(); 
  }

  onFilterTextBoxChanged() {  
    this.gridApi.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
  }

  GetShift() {
    let payload = {
      BranchId: this.globals.HospitalId,
      LocationId: this.globals.Location?.locationId ? this.globals.Location.locationId : "",
      Status: this.shiftForm.controls.status.value
    }
    this.gridApi.showLoadingOverlay();
    this.BillingService.GetShift(payload).subscribe({
      next: (response: Response<any>) => {
        if(response.status == 200){
          this.manageshift = response.response
        }else{
          this.toastr.error(response.errorMessage.message)
        }
        this.gridApi.hideOverlay();
      },error: (error: any)=>{
        this.toastr.error('Something wents wrong..! try again.');
        this.gridApi.hideOverlay();
      }
    })
  }

  openmodalforopenshift(data?: any) {
    const foundElement = this.functionPermissionData.find(x => x.id === 366)
    if (foundElement.id == 366 && foundElement.isAllowed == true) {
    let initialState = {
      shiftdata:data
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }

    const modalRef = this.modalService.show(OpenshiftComponent, Object.assign({}, config, { class: 'modal-l', initialState }))
    modalRef.content.createEmitter.subscribe(res => {
      if (res.Status) {
        this.GetShift()
      }
    })
    } else {
      this.toastr.warning("You are not allowed to open shift");
    }
  }

  openmodalforcloseshift(data?) {
    const foundElement = this.functionPermissionData.find(x => x.id === 367)
    if (foundElement.id == 367 && foundElement.isAllowed == true) {
    let initialState = {
      shiftdata:data
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }

    const modalRef = this.modalService.show(CloseshiftComponent, Object.assign({}, config, { class: 'modal-l', initialState }))
    modalRef.content.createEmitter.subscribe(res => {
      if (res.Status) {
        this.GetShift()
      }
    })
    } else {
      this.toastr.warning("You are not allowed to close shift");
    }
  }
 

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }
 }
