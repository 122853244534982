import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { QrcodeScanModalComponent } from '../../../../shared/modals/qrcode-scan-modal/qrcode-scan-modal.component';
import { TreatmentsService } from '../../services/treatments.service';
import { Response } from '../../../../_Models/Response';
import { DatePipe } from '@angular/common';
import _ from 'lodash';
import { PatientService } from '../../../client/services/patient.service';

@Component({
  selector: 'app-apply-vaccine-modal',
  templateUrl: './apply-vaccine-modal.component.html',
  styleUrls: ['./apply-vaccine-modal.component.scss']
})
export class ApplyVaccineModalComponent implements OnInit {

  @Input() data: any;
  @Input() branchId: any;
  @Input() action: any;
  @Input() consultationId: number;
  @Input() permissionData: any;
  @Input() healthInsurancePlatform: any;
  @Output() vaccineAppliedEmitter = new EventEmitter();

  public applyVaccineForm: FormGroup;

  public performingstaffs: any[] = [];
  public saveLoading: boolean = false;
  public purposeList: any[] = [];
  public vaccineDurationQuantity:any[]=[]
  public vaccineDurationUnit:any[]=[]
  public vaccinepattern:any[]=[]

  constructor(private modalRef: BsModalRef,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private modalService: BsModalService,
              private treatmentsService: TreatmentsService,
              private datepipe: DatePipe,
              private patientService: PatientService,) { }

  ngOnInit(): void {

    this.getVaccineDurationquantity()
    this.getVaccineDurationUnit()
    this.getVaccinePattern()

    this.initForm();
    this.getPerformingStaff();
    this.applyVaccineForm.patchValue({
      staffId: Number(localStorage.getItem('staffId')) !== 0 ? Number(localStorage.getItem('staffId')) : ''
    })
    this.GetVaccinePurpose();
    console.log('action', this.action);
    if(this.action == 'edit'){
      this.editData();
    }
  }

  getVaccineDurationquantity(){
    this.treatmentsService.getVaccineDurationquantity().subscribe({
      next:(res)=>{
        this.vaccineDurationQuantity=res.response
      }
    })
  }

  getVaccineDurationUnit(){
    this.treatmentsService.getVaccineDurationUnit().subscribe({
      next:(res)=>{
        this.vaccineDurationUnit=res.response
      }
    })
  }

  getVaccinePattern(){
    this.treatmentsService.getVaccinePattern().subscribe({
      next:(res)=>{
        this.vaccinepattern=res.response
      }
    })
  }

  initForm(){
    this.applyVaccineForm = this.formBuilder.group({
      patientName: new FormControl({value: this.data?.patientName, disabled: true}),
      consultantName: new FormControl({value: this.data?.consultantName, disabled: true}),
      itemName: new FormControl({value: this.data?.itemName, disabled: true}),
      OrderDetId: new FormControl(this.data?.orderDetId),
      AdmQty: new FormControl('', Validators.required),
      LotNumber: new FormControl('', Validators.required),
      ExpiryDate: new FormControl('', Validators.required),
      VaccineDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      VPId: new FormControl(this.data?.vpId),
      VDQId: new FormControl(this.data?.vdqId),
      VDUId: new FormControl(this.data?.vduId),
      staffId: new FormControl('', Validators.required),
      BranchId: this.branchId,
      Status: new FormControl(1),
      VPurposeId: new FormControl(0),
      VaccinationDate: new FormControl(''),
      PatientId: new FormControl(this.data?.patientId)
    })
    console.log(this.data)
  }

  close(){
    this.modalRef.hide();
  }

  async getPerformingStaff() {
    let Input = {
      StaffId: 0,
      ShowAll: 0,
      BranchId: this.branchId,
    }
    let staffData = await this.treatmentsService.GetPerformingStaff(Input).toPromise();
    // this.treatmentsService.GetPerformingStaff(Input).subscribe((res) => {
      let Staffs = staffData.response;
      this.performingstaffs = Staffs.filter(x => x.categoryId === 4);
    // });
  }

  openQrCodeScanner(){
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      availableStaffs: this.performingstaffs
    }
    const modalRef = this.modalService.show(QrcodeScanModalComponent, Object.assign({}, config,  { class: 'modal-sm', initialState }));
    modalRef.content.staffEmitter.subscribe((res: any)=>{
      if(res.status){
        this.applyVaccineForm.patchValue({
          staffId: res.staffId
        })
      }
    })
  }

  saveVaccineDetails(){
    let currentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy')
    const controls = this.applyVaccineForm.controls;
    if(this.applyVaccineForm.invalid){
      Object.keys(controls).forEach(control=>{
        controls[control].markAllAsTouched();
      });
      return
    }

    let payload = this.applyVaccineForm.getRawValue();
    payload.staffId = Number(payload.staffId);
    payload.VPurposeId = Number(payload.VPurposeId);
    payload.VDQId = Number(payload.VDQId);
    payload.VDUId = Number(payload.VDUId);
    payload.VPId = Number(payload.VPId);

    if(payload.ExpiryDate && (typeof (payload.ExpiryDate) == "object")) {
      payload.ExpiryDate = this.datepipe.transform(new Date(payload.ExpiryDate), 'dd-MM-yyyy');
    }

    const sMenuId = Number(localStorage.getItem('selectedMenu'));
    let foundId;
    if (sMenuId == 68) {
      foundId = 570;
    } else if (sMenuId == 69) {
      foundId = 570;
    } else if (sMenuId == 111) {
      foundId = 570;
    }

    if (this.healthInsurancePlatform.settValue == 'Nabidh') {
      const foundElement = this.permissionData.find(x => x.id === foundId)
      if (foundElement.id == foundId && foundElement.isAllowed == true) {
        if(payload.VPurposeId == 0 || payload.VDQId == 0 || payload.VDUId == 0 || payload.VPId == 0){
          this.toastrService.warning('Please fill all the fields!');
          return
        }
      }
    }

    this.treatmentsService.saveVaccineDetails(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.toastrService.success('Vaccine details updated successfully.');
          // ###########################################
        // const sMenuId = Number(localStorage.getItem('selectedMenu'));
        // let foundId;
        // if (sMenuId == 103) {
        //   foundId = 551;
        // } else if (sMenuId == 104) {
        //   foundId = 552;
        // } else if (sMenuId == 108) {
        //   foundId = 553;
        // }
        if (this.healthInsurancePlatform.settValue == 'Nabidh') {
          const foundElement = this.permissionData.find(x => x.id === foundId)
          if (foundElement.id == foundId && foundElement.isAllowed == true) {
            // if (this.healthInsurancePlatform.settValue == 'Nabidh') {
            let nabPayload = {
              EventId: 17,
              Consultationid: this.consultationId ? Number(this.consultationId) : 0,
              EntryDate: currentDate,
              PatientId: Number(this.data?.patientId),
              BranchId: this.branchId,
            }
            this.patientService.SendDetailstoNabith(nabPayload).subscribe((res) => {
              this.toastrService.info('Response from Nabith:' + res);
            })
            // }
          } else {
            this.toastrService.warning('No permission to send data to Nabidh');
          }
        }
          // ###################################################3
          this.vaccineAppliedEmitter.emit({
            status: true,
            itemData:response.response
          })
          this.close();
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
        this.saveLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong... Try again');
        this.saveLoading = false;
      }
    })
  }

  GetVaccinePurpose() {
    let Input = {
      PatientId: this.data?.patientId
    }

    this.treatmentsService.GetVaccinePurpose(Input).subscribe((res) => {
      this.purposeList = res.response;
    });
  }

  getPurpose(event){
    let dateValue;
    console.log('event', event.target.value);
    this.purposeList.find(x => {
      if(x.vPurposeId == event.target.value){
        dateValue = x.vaccinationDate;
      }
    })
    console.log('dateValue', dateValue);
    this.applyVaccineForm.patchValue({VaccinationDate: dateValue});
  }

  editData(){
    this.applyVaccineForm.patchValue({
      patientName: this.data?.patientName,
      consultantName: this.data?.consultantName,
      itemName: this.data?.itemName,
      OrderDetId: this.data?.orderDetId,
      AdmQty: this.data?.admQty,
      LotNumber: this.data?.lotNumber,
      ExpiryDate: this.data?.expiryDate,
      VaccineDate: this.datepipe.transform(this.data?.vaccineDate, 'dd-MM-yyyy'),
      VPId: this.data?.vpId,
      VDQId: this.data?.vdqId,
      VDUId: this.data?.vduId,
      staffId: this.data?.staffId,
      BranchId: this.branchId,
      Status: this.data?.status,
      VPurposeId: this.data?.vPurposeId,
      VaccinationDate: this.data?.vaccinationDate,
      PatientId: this.data?.patientId,
    })
  }

}
