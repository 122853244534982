import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  Input,
  OnDestroy,
} from "@angular/core";
import { EMRPatientInfo } from "../interfaces/EMRPatientInfo";
import { EMRService } from "../../emr/services/emr.service";
import { ToastrService } from "ngx-toastr";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ChiefComplaints } from "../interfaces/ChiefComplaints";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import Swal from "sweetalert2";
import { Observable, Subscription } from "rxjs";
import { Response } from "../../../_Models/Response";
import * as _ from "lodash";
import { globalvars } from "../../../shared/models/GlobalVars";
import { SharedService } from "../../../shared/shared.service";
import { DatePipe } from "@angular/common";
import { IDropdownSettings } from "ng-multiselect-dropdown";
@Component({
  selector: "app-general-chief-complaints",
  templateUrl: "./general-chief-complaints.component.html",
  styleUrls: ["./general-chief-complaints.component.scss"],
})
export class GeneralChiefComplaintsComponent implements OnInit, OnDestroy {
  @Input() IsViewtab;
  EMRPatientInfo: EMRPatientInfo;
  Loading: boolean = false;
  form: FormGroup; // form variable
  ChiefComplaints: ChiefComplaints;
  CurrentForm: ChiefComplaints; // for value chnage detection
  ChiefComplaintsModalVal: ChiefComplaints;
  PrevChiefComplaints: any[] = [];
  PainScale: number = 0;
  painVal: number = 0;
  modalRef?: BsModalRef;
  IsDisabled: boolean = true;
  SubmitPage:boolean=false;
  @ViewChild("template") modaltemp: any;

  FormValueChangeChecker: boolean = false;

  public dropdownSettings: IDropdownSettings = {};
  public allDiseases: any[] = [];
  
  public allSymptoms: any[] = [];
  public symptomData: any;
  public selectedSymptoms: any;

  private globals: globalvars = new globalvars();
  private subscription: Subscription;

  constructor(
    private EMRService: EMRService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private sharedservice: SharedService,
    private datePipe: DatePipe
  ) {
    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
  }

  ngOnInit(): void {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });

    if (this.IsViewtab == true) {
      this.IsDisabled = false;
    } else if (this.IsViewtab == "undefined") {
      this.IsDisabled = true;
    }
    this.form = new FormGroup({
      complaintId: new FormControl(0),
      chiefComplaint: new FormControl(""),
      complaintOf: new FormControl(""),
      site: new FormControl(""),
      symptomSince: new FormControl(""),
      severity: new FormControl(0),
      course: new FormControl(0),
      symptom: new FormControl(0),
      tobaccoStatus: new FormControl(0),
      associatedSigns: new FormControl(""),
      chiefComplaintsBy: new FormControl(""),
      painScale: new FormControl(this.PainScale),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
    });

    this.GetPreviousCheifComplaints();
    this.GetChiefComplaints();


    // if(this.Loading){

    // }

    this.dropdownSettings = {
      singleSelection: false,
      idField: "diseaseId",
      textField: "diseaseDesc",
      enableCheckAll: false,
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
  }

  SaveCheifComplaints() {

    /*******************FORM VALUE CHNAGE DETECTION */
    if (
      JSON.stringify(this.CurrentForm) ==
      JSON.stringify(this.form.getRawValue())
    ) {

    } else {
      this.SubmitPage=true;
    }


    /*******************FORM VALUE CHNAGE DETECTION */
    if (
      this. EMRPatientInfo.CrudType!=3 &&
      this.EMRPatientInfo.VisitId > 0 && this.Loading && this.SubmitPage) {
      this.ChiefComplaints = this.form.getRawValue();
      this.ChiefComplaints.severity = Number(this.ChiefComplaints.severity);
      this.ChiefComplaints.course = Number(this.ChiefComplaints.course);
      this.ChiefComplaints.symptom = Number(this.ChiefComplaints.symptom);
      this.ChiefComplaints.tobaccoStatus = Number(
        this.ChiefComplaints.tobaccoStatus
      );
      this.ChiefComplaints.painScale = this.PainScale;
      this.ChiefComplaints.userId = Number(localStorage.getItem("user_id"));

      this.EMRService.SaveCheifComplaints(this.ChiefComplaints).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastr.success(
              "Success: " + "Chief Complaints Saved Successfully..."
            );
            this.SubmitPage=false;

          } else {
            Swal.fire(
              "Error!",
              "Some unexpected error occures! try again later.",
              "error"
            );
          }
        }
      );
    } else {
      if (!this.FormValueChangeChecker) {
        // this.toastr.warning("Info: " + "No changes detected in the entry");
      }
    }
  }

  changeStyle(id,submitpage=false) {
    this.painVal = id
    this.PainScale = id;
    for (let i = 1; i <= id; i++) {
      var element = document.getElementById(i.toString());
      element.classList.add("pain-scale__level--active");
    }
    for (let i = id + 1; i <= 10; i++) {
      var element = document.getElementById(i.toString());
      element.classList.remove("pain-scale__level--active");
    }
    this.FormValueChangeChecker = true;
    if(submitpage){
      this.SubmitPage=true;
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  //to get saved data
  GetChiefComplaints() {
    let input = {
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 1,
    };
    this.EMRService.GetChiefComplaints(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];
        this.form.patchValue(response);
        this.CurrentForm = this.form.getRawValue();
        if (response) {
          this.changeStyle(response.painScale);
        }

        this.Loading = true;
        this.form.valueChanges.subscribe((x) => {
          this.FormValueChangeChecker = true;
        });
      }
    });
  }

  GetPreviousCheifComplaints() {
    let input = {
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 0,
    };
    this.EMRService.GetChiefComplaints(input).subscribe((res) => {
      if (res.status == 200) {
        this.PrevChiefComplaints = res.response;
      }
    });
  }

  openModal(VisitId) {
    let input = {
      VisitId: VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 1,
    };
    this.EMRService.GetChiefComplaints(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];
        this.ChiefComplaintsModalVal = response;
        this.modalRef = this.modalService.show(this.modaltemp, {
          class: "modal-lg modal-custom-style",
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
