import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { ReportsService } from '../services/reports.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';

@Component({
  selector: 'app-registration-report',
  templateUrl: './registration-report.component.html',
  styleUrls: ['./registration-report.component.scss'],
})
export class RegistrationReportComponent implements OnInit {

  public viewerContainerStyle = {
      position: 'relative',
      width: '1000px',
      height: '800px',
      ['font-family']: 'ms sans serif'
  };

  form: FormGroup; // form variable
  subscription: Subscription;
  globals: globalvars = new globalvars();
  consultants:any[]=[];
  GetConsultantsData: any;
  isPdfLoaded:boolean=false;
  // private pdf: PDFDocumentProxy;
  isLoading=false;
  private baseUrl = "";
  private homeUrl="";

  src :any= '';
  public document;

  constructor(
    public  datepipe: DatePipe,
    private sharedservice: SharedService,
    private NewBillingService:NewBillingService,
    private ReportsService:ReportsService,
    private sanitizer: DomSanitizer,
    private configurationManager: ConfigurationManager,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });

    this.baseUrl = (this.configurationManager as any).baseUrl;

  }

  ngOnInit(): void {
    this.form=new FormGroup({
      FromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ToDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ConsultantId: new FormControl(0),
      BranchId: new FormControl(this.globals.HospitalId),
    });
    this.loadConsultants();
  }

  generateReport(){
    this.isLoading=true;
    let input=this.form.getRawValue();

    if (typeof (input.FromDate) == "object") {
      let dobConverted = this.datepipe.transform(input.FromDate, 'dd-MM-yyyy');
      input.FromDate = dobConverted;
    }

    if (typeof (input.ToDate) == "object") {
      let dobConverted = this.datepipe.transform(input.ToDate, 'dd-MM-yyyy');
      input.ToDate = dobConverted;
    }
    
    if(input.ConsultantId){
      input.ConsultantId=Number(input.ConsultantId);
    }
    // this.src="https:\\testapi.lehealthcloud.com\\Report\\Registration_Report\\0787d06c-3803-4a97-8b2a-5ac2fff1edca.pdf";
    // this.src=this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    // this.isPdfLoaded=true;


    this.ReportsService.GetRegistrationReport(input).subscribe((res) => {
      this.isLoading=false;
      if(res.status==200){
        let response=res.response;
        this.src=response[0].reportPath;
        this.src=this.baseUrl+this.src;
        this.src=this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
        this.isPdfLoaded=true;
      }
     });

  }

  loadConsultants() {
    let Input={
      "BranchId":  Number(this.globals.HospitalId),
      "IsExternal": 3
    }

    this.NewBillingService.getConsultantByHospital(Input).subscribe((res) => {
      this.consultants = res.response;

      this.GetConsultantsData = [];
      this.consultants.forEach((a) => {
        this.GetConsultantsData.push({
          id: a.consultantId.toString(),
          text: a.consultantName,
        });
      });

    });

  }

  print() {
    // this.pdf.getData().then((u8) => {
    //     let blob = new Blob([u8.buffer], {
    //         type: 'application/pdf'
    //     });

    //     const blobUrl = window.URL.createObjectURL((blob));
    //     const iframe = document.createElement('iframe');
    //     iframe.style.display = 'none';
    //     iframe.src = blobUrl;
    //     document.body.appendChild(iframe);
    //     iframe.contentWindow.print();
    // });
  }


  // onLoaded(pdf: PDFDocumentProxy) {
  //   this.pdf = pdf;
  //   this.isPdfLoaded = true;
  // }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
