import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ServicePoint } from '../../client/interfaces/ServicePoint';
import { ErrorHandleService } from '../../client/services/error-handle.service';
import { Response } from '../../../_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class SubLocationSettingsService {

  private baseUrl = "";


  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle:ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
   }

   saveServicePoint(ProjectDetails: ServicePoint)
   {
     var url="api/MasterData/InsertUpdateServicePoint";
     return this.http.post<Response<any>>(`${this.baseUrl}${url}`,ProjectDetails)
   }

   GetServicePoints(inputData){
    var url="api/MasterData/GetServicePoint";
    return this.http.post<Response<ServicePoint[]>>(`${this.baseUrl}${url}`,inputData)
   }
   DeleteSercicePoints(inputData){
    var url="api/MasterData/DeleteServicePoint";
    return this.http.post<Response<ServicePoint[]>>(`${this.baseUrl}${url}`,inputData)
   }
}
