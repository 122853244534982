import { Component, OnInit,ViewChild,TemplateRef } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BillingGeneralActionComponent } from '../billing-general-action/billing-general-action.component';

@Component({
  selector: 'app-manage-remittance',
  templateUrl: './manage-remittance.component.html',
  styleUrls: ['./manage-remittance.component.scss']
})
export class ManageRemittanceComponent implements OnInit {
  modalRef?: BsModalRef;
  @ViewChild("cancelTemplate") modalCancel: any;
  @ViewChild("resubmission") modalResubmmision: any;
  modalRefonline: BsModalRef;

  columnDefs: ColDef[] = [];
  columnDefsList: ColDef[] = [];
  columnDefsDetails: ColDef[] = [];
  uploadservicestab: boolean = true;
  downloadservicestab: boolean = true;

  // manageRemittanceList: any[] = [];
     resubmissionList: any[] = [];
   resubmissionData: any[] = [];

  manageRemittanceList=[{
    date:'12/11/22',
    claim:'Test Name',

    remittance:'',
    sposorName:'arya',
    claimAmount:'1000',
    receivedAmount:'1000',
    deniedAmount:'1000',

  }];

  rowGroupPanelShow = "always";
  public context;
  public gridApi;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  ActiveTab:number=1;

  constructor(
    private modalService: BsModalService

  ) { }

  ngOnInit(): void {
    this.context = { componentParent: this };

    this.columnDefs = [
      {
        headerName: "Receipt Date",
        field: "date",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Claim",
        field: "claim",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Remittance",
        field: "remittance",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Sponsor Name",
        field: "sposorName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Claim Amount",
        field: "claimAmount",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Received Amount",
        field: "receivedAmount",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Denied Amount",
        field: "deniedAmount",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },

      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "manageRemittance" },
      },
    ];
    this.columnDefsList = [
      {
        headerName: "REsub Ref No.",
        field: "refNo.",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Remitance",
        field: "remitance",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Resub Date",
        field: "resubdate",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
    ];
    this.columnDefsDetails = [
      {
        headerName: "Bill No.",
        field: "billNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Type",
        field: "type",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Comment",
        field: "comment",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
    ];

    this.frameworkComponents = { buttonRender: BillingGeneralActionComponent};

  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{ class: "gray modal-lg" });
  }
  openModalupload(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{ class: "gray modal-lg " });
  }

  cancelItem(data){
    this.modalRef= this.modalService.show(
       this.modalCancel, { class: "gray" })
   }
   Resubmission(data){
    this.modalRef= this.modalService.show(
       this.modalResubmmision, { class: "gray modal-xl" })
   }
   onTab(number) {
    this.uploadservicestab = false;
    this.downloadservicestab = false;
   

    if (number == "1") {
      this.uploadservicestab = true;
    } else if (number == "2") {
      this.downloadservicestab = true;
    } 
   
    
  }
  chageTab(tabid){
    this.ActiveTab=tabid;
  }

}
