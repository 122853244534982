import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SharedService } from '../../../../shared/shared.service';
import { Response } from '../../../../_Models/Response';
import { BillingService } from '../../services/billing.service';

@Component({
  selector: 'app-cancel-settlment-modal',
  templateUrl: './cancel-settlment-modal.component.html',
  styleUrls: ['./cancel-settlment-modal.component.scss']
})
export class CancelSettlmentModalComponent implements OnInit {

  @Input() TransId: number = 0;
  @Input() isExternal: boolean = false;
  @Output() cancelSettlementEvent = new EventEmitter();

  public cancelSettlmentForm: FormGroup;
  private subscription: Subscription;
  private CurrentDate: any;

  
  public cancelSettlmentLoading: boolean = false;

  private globals: globalvars = new globalvars();

  constructor(private billingService: BillingService,
              private toastrService: ToastrService,
              private sharedService: SharedService,
              private datepipe: DatePipe,
              public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.CurrentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');

    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });

    this.initCanceSettlmentlForm();
  }

  initCanceSettlmentlForm(){
    this.cancelSettlmentForm = new FormGroup({
      TransId: new FormControl(this.TransId, Validators.required),
      CancelReason: new FormControl('', Validators.required),
      UserId: new FormControl(Number(localStorage.getItem('user_id')), Validators.required),
      BranchId: new FormControl(this.globals.HospitalId, Validators.required),
      SessionId: new FormControl(0, Validators.required),
      IsExternal: new FormControl(this.isExternal)
    })
  }

  cancelSettlment(){
    const controls = this.cancelSettlmentForm.controls;
    if(this.cancelSettlmentForm.invalid){
      Object.keys(controls).forEach(control=>{
        controls[control].markAllAsTouched();
      });
      return
    }

    this.cancelSettlmentLoading = true;
    this.billingService.cancelSettlment(this.cancelSettlmentForm.value).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.response == 'Success'){
          this.modalRef.hide();
          this.toastrService.success('Settlement canceled successfully..');
          this.cancelSettlementEvent.emit({status: true});
        }else{
          this.toastrService.error(response.response)
        }
        this.cancelSettlmentLoading= false;
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong, Try again...');
        this.cancelSettlmentLoading = false;
      }
    })
   }

}
