import { Component, OnInit } from '@angular/core';
// import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-itembaseComponent',
  templateUrl: './itembase.component.html',
  styleUrls: ['./itembase.component.scss']
})
export class itembaseComponent implements OnInit {
    products3: any[];
    items1: any = {};
  constructor() { }

  ngOnInit(): void {
    this.products3 = [{'code': 'R5678900', 'name': 'Admin','cptcode': '2823243434', 'basecost': '28.00' },{'code': 'z5678900', 'name': 'xdmin','cptcode': '6565767676', 'basecost': '30.00'}];
    //this.products = [{ 'code': 'R5678900', 'name': 'Admin', 'category': '28', 'quantity': '8076543210', 'address': 'xyztestcbcvbv bvb', 'sponsorname': 'xyztest', 'consultant': 'user1', 'policyno': 'IC5677', 'emiratesId': '2345677', 'sponsorId': '99876550' }, { 'code': 'xtttt343', 'name': 'test', 'category': '19', 'quantity': '8076543210', 'address': 'xyztest', 'sponsorname': 'xyztest', 'consultant': 'user1', 'policyno': 'IC5677', 'emiratesId': '2345677', 'sponsorId': '99876550' }, { 'code': 'CTR35566', 'name': 'user', 'category': '32', 'quantity': '8076543210', 'address': 'xyztest', 'sponsorname': 'xyztest', 'consultant': 'user1', 'policyno': 'IC5677', 'emiratesId': '2345677', 'sponsorId': '99876550' }, { 'code': 'Qw256666', 'name': 'tester', 'category': '35', 'quantity': '8076543210', 'address': 'xyztest', 'sponsorname': 'xyztest', 'consultant': 'user1', 'policyno': 'IC5677', 'emiratesId': '2345677', 'sponsorId': '99876550' }, { 'code': 'RHYT6666', 'name': 'QWERTY', 'category': '20', 'quantity': '8076543210', 'address': 'xyztest', 'sponsorname': 'xyztest', 'consultant': 'user1', 'policyno': 'IC5677', 'emiratesId': '2345677', 'sponsorId': '99876550' }, { 'code': 'R5678900', 'name': 'Admin', 'category': '25', 'quantity': '8076543210', 'address': 'xyztest', 'sponsorname': 'xyztest', 'consultant': 'user1', 'policyno': 'IC5677', 'emiratesId': '2345677', 'sponsorId': '99876550' }, { 'code': 'R5678900', 'name': 'Admin', 'category': '12', 'quantity': '8076543210', 'address': 'xyztest', 'sponsorname': 'xyztest', 'consultant': 'user1', 'policyno': 'IC5677', 'emiratesId': '2345677', 'sponsorId': '99876550' },];
    this.items1 = [
        { label: 'Item Base Cost', url: '' },
        { separator: true },
        { label: 'Assign Consultation',url:''},
        { separator: true },
        {label: 'Working Days',  url: '' },
        { separator: true },
      
        { label: 'Edit Consultations', url: '' },
        { separator: true },
        { label: 'Add Consultations', url: '' },
        { separator: true },

        { label: 'Block/Unblock', url: '' },
        { separator: true },
    ];
  }
  customSort(event) {
    event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null)
            result = -1;
        else if (value1 != null && value2 == null)
            result = 1;
        else if (value1 == null && value2 == null)
            result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
        else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (event.order * result);
    });
}





}
