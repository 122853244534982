import { Component, OnInit } from '@angular/core';
import { ConsultationsList } from '../../../interfaces/ConsultationsList';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// loading Consultation Service
import { ConsultaionsService } from '../../../services/consultations.service';

import { AllAppointmentsList } from '../../../interfaces/AllAppointmentsList';

// loading Appointment Service
import { AppointmentsService } from '../../../services/appointments.service';
import { ToastrService } from 'ngx-toastr';
import { PatientList } from '../../../interfaces/PatientList';
import { NavigationExtras, Router } from '@angular/router';
// loading Patients Service
import { PatientService } from '../../../services/patient.service';

import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../general-action/general-action.component';
import { ConsultantDeskService } from '../../../services/consultant.service';
import { SharedService } from '../../../../../shared/shared.service';
import { globalvars } from '../../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ServiceOrderingService } from '../../../services/ServiceOrderingService';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ConfigurationService } from "../../../services/configuration.service";
@Component({
  selector: 'app-addServiceItemsComponent ',
  templateUrl: './addServiceItems.component.html',
  styleUrls: ['./addServiceItems.component.scss'],

})
export class addServiceItemsComponent  implements OnInit {
    products: any={ };
    Disease: boolean ;
    myPatients: boolean;
    myAppointment: boolean;
    myConsultation: boolean;
    Personalisations: boolean;
    Notifications: boolean;
    Changepassword: boolean;
    displayModal: boolean;
    displayModal1: boolean;
    displayModaluser: boolean;
    displaybranch:boolean;
    rowData : any=[];
    username='';
    password='';
    consultations: ConsultationsList[]=[];
    items: any = {};
    user_role:any;
    user_id :any;
    Id:any;
    appointments: AllAppointmentsList[] = [];
    users:any=[];
    selectedCities: any[] = [];
    availableProducts:any=[];
    selectedProducts: any=[];
    draggedProduct: any=[];
    patients:PatientList[]=[];
    columnDefs: ColDef[] =[];
    columnDefsapp: ColDef[] =[];
    columnDefconsult: ColDef[] =[];
    searchForm: FormGroup;
    attachdocuments:boolean;
    CancelAppId:number;
    printmodal: boolean;
    myFile: any;
    CancelAppointments: boolean;
    confirmblockingConsultants:any
    //ag grid setup
    public gridApi;
    public rowSelection = true;
    public defaultColDef: ColDef;
    public context;
    public frameworkComponents;
    public imageComponents;
    public noRowsTemplate;
    public loadingTemplate;
    public gridOptions = {
      pagination: true,
      paginationPageSize:10,
      rowClassRules: {
        'c-reds': function (params) { return params.data.active == '0'; },
        'c-reds2': function (params) {  return params.data.appStatus == 'Cancelled'; },
      },

    }
  consultantDataDetails: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  appointmentDetails: any;
  consultationsDetails: any;
  router_url = this.router.url;
  active: string;
  blockorunblock: boolean;
  blockId: number;
  confirmblock: boolean;
  displayBasicUser: boolean;
  patientId: any;
  displayBasicdragdrop: boolean;
  regDocLocation: any;
  documentList: any;
  pagetype: any;
  patientmodal: any;
  gender: any;
  regDate: any;
  patientName: any;
  address: any;
  mobile: any;
  regNo: any;
  age: any;
  searchTextGroups:'';
  searchText:'';
  regDetails: PatientList[];
  regIdentities: any;
  historyconsent: boolean;
  ConsentHistory: any;
  consentHistoryList: any;
  consentHistoryListArabic: any;
  consentHistoryPatientName: any;
  consentHistorySignature: any;
  PostponeObj:any;
  consultationId: any;
  CancelledReason: any;
  symptomDetails: ConsultationsList[];
  ReasonVisit: boolean;
  symptomList: any[];
  dropdownSettings: IDropdownSettings = {};
  result: any;
  blockorunblockconsult: boolean;
  documentFile: File[];
  documentFile1: any;
  displayBasic: boolean;
  showWebcam: boolean;
  ServicesGroups: any;
  public statusBar;
  consentDate: any;
  myObservable$: Subject<unknown>;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  generatedImage: string;
  minDate: any;

  rowGroupPanelShow = "always";
  OtherSymptom: boolean;
  consultantDataDetailsEvent: any[];
  BranchId: any;
  valueDate: Date;
  valueDate2: Date;
  FromDate: string;
  ToDate: string;
  minDate1 = new Date();
  appointmentDate: string;
  CurrentappointmentDate: string;
  AppointmentDataDetails: any;
  ServiceItems: boolean;
  DrugList: boolean;
  Marks: boolean;
  Sketch: boolean;
  GetSymptoms: any;
  GetSymptomsVitalSign: any;
  SymptomsVitalSign: any[];
  GetDiseasesIcd: any;
  OriginalServiceGroups: any;
  SelectGroupId: any[]=[];
  sourceOriginal: any;
  sourceProducts: any;
  targetProducts: any;
  ServiceItemsList: any;
  public submitted: boolean = false;
  AddServiceForm: FormGroup;
  ItemListId: any[]=[];
  selectedFiles: any[];
  EventId: any;
  GroupCode: any;
  parentId: any;
  LabelName: any;
  constructor(
    private consultationService:ConsultaionsService,
    private appointmentService: AppointmentsService,
    private patientService:PatientService,
    private ConsultantDeskService:ConsultantDeskService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private ServiceOrderingService:ServiceOrderingService,
    private ngxLoader:NgxUiLoaderService,
    private ConfigurationService: ConfigurationService,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
         this.BranchesId=this.globals.HospitalId
         localStorage.setItem('BranchId', this.BranchesId);
      }
      this.BranchId =  localStorage.getItem('BranchId');

    });


  }




  ngOnInit(): void {


    this.AddServiceForm = this.formBuilder.group({

      searchTextGroups:new FormControl(''),
      searchText:new FormControl(''),

    });

    // getting the values of user_role and user_id  from session

    this.user_role = localStorage.getItem('user_role');
    this.user_id =  localStorage.getItem('user_id');
    this.Id =  localStorage.getItem('Id');


//Patients ag grid List
this.columnDefs=[


  {
    headerName: "Disease Description",
    field: 'regDate',
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    flex: 2,
    editable: true
  },



]

  this.getServiceGroupItems();
 // this.GetDiseaseIcd();
  }


  onTab(number) {
    this.Disease = false;
    this.ServiceItems = false;
    this.DrugList = false;
    this.Marks = false;
    this.Sketch = false;

    if (number == '1') {
      this.Disease = true;
    }
    else if (number == '2') {
      this.ServiceItems = true;
    }

    else if (number == '3') {
      this.DrugList = true;
    }
    else if (number == '4') {
      this.Marks = true;
    }
    else if (number == '5') {
      this.Sketch = true;
    }


  }

  TabSelection(){
    this.Disease = false;
    this.ServiceItems = false;
    this.DrugList = false;
    this.Marks = false;
    this.Sketch = false;

  }

     //pagetitle to be displayed at the header
     Activetab(pagetitle) {
      sessionStorage.setItem("pageTitle", pagetitle);

    }

    onGridReady(params) {
      this.gridApi = params.api;
    }



isAllCheckBoxChecked() {
  return this.products.every(p => p.checked);

}


//checkAllCheckBox(ev) { // Angular 9
checkAllCheckBox(ev: any) { // Angular 13
  this.products.forEach(x => x.checked = ev.target.checked)
}

//getting service group items
getServiceGroupItems(){

let input={

    "BranchId":this.globals.HospitalId,
    "PageCode":"SO"

}
  this.ServiceOrderingService.getServiceGroupItems(input).subscribe((res) => {
    this.ServicesGroups=res.response;
    this.OriginalServiceGroups=res.response;

  });
}

onFilterServiceGroups(){
  let serviceGroups=this.AddServiceForm.get("searchTextGroups").value;
  let selectAvailbaleService1 = this.OriginalServiceGroups.filter(function(availservice){

    if( availservice.label.toLocaleLowerCase().indexOf(serviceGroups.toLocaleLowerCase()) !== -1){

    return availservice;
    }

 }

 );
 this.ServicesGroups=selectAvailbaleService1;


}

nodeSelect(event){
  let ShowAll=0;
  let ConsultantId=this.Id;
    this.EventId = event.node.groupId;
    this.GroupCode=event.node.groupCode;
    this.parentId=event.node.parent?event.node.parent.groupId:0;
    this.LabelName=event.node.label
    this.ConfigurationService.GetServiceItem(
      ShowAll,
      this.globals.HospitalId,
      this.EventId,ConsultantId
    ).subscribe((res) => {
      this.sourceProducts = res.response;
      this.sourceOriginal =res.response;
    });


   this.selectedFiles= this.selectedFiles.filter((oppdata) => oppdata.groupId ==this.EventId );


}

onFilter() {

  let searchFilter=this.AddServiceForm.get("searchText").value;
  let selectAvailbaleService1 = this.sourceOriginal.filter(function(availservice){

     if( availservice.itemName.toLocaleLowerCase().indexOf(searchFilter.toLocaleLowerCase()) !== -1){

     return availservice;
     }

  }

  );
  this.sourceProducts=selectAvailbaleService1;
 }

FilteringDataDuplication(response){
  let filteredService=[];

  return filteredService;
}

nodeUnselect(event){
let unselectgroup= [];
let unselectchild= [];
if(event.node.children !=null){
  event.node.children.forEach(b => {
    unselectchild.push(b.groupId)
  });
}


this.SelectGroupId.forEach(a => {
if(a!=event.node.groupId){
  if(unselectchild.indexOf(a) <= -1){
    unselectgroup.push(a)
  }
}
});

this.SelectGroupId=unselectgroup;
this.ServiceOrderingService.GetAvailableService(this.globals.HospitalId,0,this.SelectGroupId).subscribe((res) => {
  this.sourceOriginal = res.response;
  this.sourceProducts=this.sourceOriginal;
});

}
selectedServices(sourceProduct){
  let ItemListIds=Number(sourceProduct.target.defaultValue)
//this.ItemListId=ItemListIds;
this.ItemListId.push(ItemListIds)
  }

SaveAddServiceItems(){

  this.ConsultantDeskService.InsertSaveServiceItems(this.ItemListId,this.Id,this.globals.HospitalId, this.globals.Location.locationId).subscribe((res) => {
      this.ServiceItemsList = res.response;
      this.result = res;
      {
        if (this.result.message == "Success") {
          this.ngxLoader.stop();

          this.toastr.success(" Service Items Saved successfully");

        }

        else {
          this.ngxLoader.stop();
          this.toastr.error("Failure: " + this.result.message);
        }

        this.submitted = false;
      }
    });
}


setfocus(e)
  {
     document.getElementById("li_0").focus();
  }
  setSelectedFocus(e)
  {

     document.getElementById("tp_0").focus();
  }

  goTo(){
    const data = { tab: '2' };
    // Navigate to the target route with state
    this.router.navigate(['/personalisation'], {state : {data}});
  }


}

