import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../../_Models/Response';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { DentalService } from '../services/dental.service';
import { EMRService } from '../services/emr.service';

@Component({
  selector: 'app-dental-treatment-history',
  templateUrl: './dental-treatment-history.component.html',
  styleUrls: ['./dental-treatment-history.component.scss']
})
export class DentalTreatmentHistoryComponent implements OnInit {

  public EMRPatientInfo: EMRPatientInfo;
  public historyLoading: boolean = false;
  public dentalHistory: any[] = [];

  constructor(private dentalService: DentalService,
              private toastrService: ToastrService,
              private EMRService: EMRService) { }

  ngOnInit(): void {

    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
    this.getDentalHistory();
  }

  getDentalHistory(){
    let payload = {
      PatientId: this.EMRPatientInfo.PatientId
    }
    this.historyLoading = true;
    this.dentalService.getDentalHistory(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.dentalHistory = response.response;
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
        this.historyLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong, Try again ..!');
        this.historyLoading = false;
      }
    })
  }

}
