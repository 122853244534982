import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ColDef } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../client/services/configuration.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-Category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  public submitted: boolean = false;


  form: any;
  result: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  TitlescolumnDefs = [];

  public gridApi;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  public rowSelection = true;
  public signdefaultColDef: ColDef;
  public vitalsigndefaultColDef: ColDef;
  public symptomdefaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public SalutationForm: FormGroup;


  Titles: boolean = true;
  Religion: boolean = true;
  DisplayedId: any;
  TitlesModal: boolean;
  IsDisplay: any;
  IsDisplayed: any;
  SalutationList: any;
  SalutationId: any;
  modalRef: BsModalRef;
  isLoading=false;
  CategoryPopupHeading:String="add Salution";
  @ViewChild("template") modaltemp: any;
  functionPermissionData: any;

  constructor(
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.SalutationForm = new FormGroup({
      SalutationName: new FormControl("", [Validators.required]),
      IsDisplayedGroup: new FormControl(true)

    });

    this.GetSalutation();
    this.functionPermission();

    this.TitlescolumnDefs = [
      {
        headerName: "Titles Name",
        field: "nameData",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        resizable: false,
        minWidth:100,
        pinned:"right",
        sortable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "TitleList" },
      },
    ];



    this.context = { componentParent: this };
    this.signdefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }

  onTab(number) {
    this.Titles = false;

    if (number == "1") {
      this.Titles = true;
    }

    if (number == "2") {
      this.Religion = true;
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  /*--------------------------------------------------Title----------------------------------------*/
  GetSalutation() {
    this.configurationService
      .GetSalutation(this.globals.HospitalId, 1)
      .subscribe((res) => {
        this.SalutationList = res.response;
      });
  }
  showTitlemodal() {
    this.TitlesModal = true;
  }

  SaveUpdateSalutation() {

    this.submitted = true;

    if (this.SalutationForm && this.SalutationForm.valid) {
      var AppData = {
        SalutationName: this.SalutationForm.get("SalutationName").value,
        IsDisplayedGroup: this.SalutationForm.get("IsDisplayedGroup").value,

      }
      if (AppData.IsDisplayedGroup == true) {
        AppData.IsDisplayedGroup = 1;
      }
      else {
        AppData.IsDisplayedGroup = 0;
      }

      let Salutationdata = 0;
      this.  isLoading=true;
      if (this.SalutationId > 0) {
        Salutationdata = this.SalutationId;
      }
      this.configurationService.InsertUpdateSalutation(AppData, Salutationdata, this.globals.HospitalId).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == 'Success') {
          if (Salutationdata == 0) {
            this.  isLoading=false;
            this.toastr.success("Salutation added successfully");
          }
          else {
            this.  isLoading=false;
            this.toastr.success("Salutation Updated successfully");
            this.SalutationId = 0;
          }
          this.modalRef.hide();
          this.GetSalutation();
        }
        else {
          this.  isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }

  }

  EditTitle(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 117)
    if (foundElement.id == 117 && foundElement.isAllowed == true) {
    this.CategoryPopupHeading="Edit salutation"

    this.modalRef = this.modalService.show(
      this.modaltemp,
      // Object.assign({}, { class: "gray modal-lg" })
    );
    this.TitlesModal = true;
    if (data.id) {
      this.SalutationId = data.id;
      this.DisplayedId = data.isDisplayed;
      this.SalutationForm.patchValue({
        SalutationName: data.nameData,

      });
    }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  DeleteTitle(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 118)
    if (foundElement.id == 118 && foundElement.isAllowed == true) {
    this.SalutationId = data.id;
    this.configurationService.DeleteSalutation(this.SalutationId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Salutation deleted successfully");
        this.displayModal = false;
        this.GetSalutation();
        this.SalutationId = 0;
      } else {
        this.toastr.error("Failure: " + this.result.message);
        this.displayModal = false;
      }
    });
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  openModalSalutation(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 116)
    if (foundElement.id == 116 && foundElement.isAllowed == true) {
    this.CategoryPopupHeading="Add salutation"
    this.modalRef = this.modalService.show(template);
    this.SalutationId = 0;
    this.ResetFunction();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }

  // for geting all form items

  get f() {
    return this.SalutationForm.controls;
  }

  // to clear form
  clearForm() {
    this.SalutationForm.reset({
      SalutationName: '',
      IsDisplayedGroup: true
    });
  }

  ResetFunction() {
    this.SalutationId = 0;
    this.submitted = false;
    this.clearForm();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

}
