import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-show-qrcode-modal',
  templateUrl: './show-qrcode-modal.component.html',
  styleUrls: ['./show-qrcode-modal.component.scss']
})
export class ShowQrcodeModalComponent implements OnInit {

  @Input() staffData: any;

  public printLoading: boolean = false;

  @ViewChild('printContent') content: ElementRef;

  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
    console.log(this.staffData)
  }

  close(){
    this.modalRef.hide();
  }

  onPrintQrcode(){
    this.printLoading = true;
    html2canvas(this.content.nativeElement,{
      scale: 3
    }).then(canvas=>{
      let image = canvas.toDataURL();
      let a = document.createElement('a');
      a.href = image;
      a.download = 'Image';
      a.click();
      a.remove();

      // let doc = new jsPDF({
      //   compress: false,
      //   format: 'a4'
      // });
      // var width = doc.internal.pageSize.getWidth();
      // doc.internal.scaleFactor = 2;
      // doc.addImage(image,'image/png', 0, 0, 70, 30);
      // doc.save('QRcode.pdf');
      this.printLoading = false;
    })
    .catch(error=>{
      console.log(error);
      this.printLoading = false;
    })
  }
}
