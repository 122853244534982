import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { SigninComponent } from './signin/signin.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { LogoutComponent } from './logout/logout.component';


import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ViewReportComponent } from './view-report/view-report.component';
import { NgxBarcodeModule } from 'ngx-barcode';

export function HttpLoaderFactorys(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  imports: [
   // NgxUiLoaderModule,
    CommonModule,
    AuthRoutingModule,
    DialogModule,
    DropdownModule,
    ButtonModule,
    NgxBarcodeModule,

    //BrowserModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactorys,
        deps: [HttpClient],
      },
    }),

  ],
  declarations: [AuthRoutingModule.components, LoginComponent, ForgotPasswordComponent,SigninComponent, LogoutComponent, ViewReportComponent],
  providers: []
})
export class AuthModule { }
