import { Component, OnInit, ViewChild,Input, OnDestroy } from '@angular/core';
import { GeneralChiefComplaintsComponent } from '../general-chief-complaints/general-chief-complaints.component';
import { GeneralDrugsMedicationComponent } from '../general-drugs-medication/general-drugs-medication.component';
import { GeneralMedicalDecisionComponent } from '../general-medical-decision/general-medical-decision.component';
import { GeneralMenstrualHistoryComponent } from '../general-menstrual-history/general-menstrual-history.component';
import { GeneralNarrativeDiagonosisComponent } from '../general-narrative-diagonosis/general-narrative-diagonosis.component';
import { GeneralPhysicalCommunicationComponent } from '../general-physical-communication/general-physical-communication.component';
import { GeneralPlanProcedureComponent } from '../general-plan-procedure/general-plan-procedure.component';
import { GeneralReviewOfSymptomsComponent } from '../general-review-of-symptoms/general-review-of-symptoms.component';
import { GeneralVitalSignComponent } from '../general-vital-sign/general-vital-sign.component';
import { GeneralServiceOrderComponent } from '../general-service-order/general-service-order.component';
import { Subscription, fromEvent } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { timer } from 'rxjs';
import { merge } from 'rxjs';
import { Observable } from 'rxjs';
import { EMRService } from '../services/emr.service';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Response } from '../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../settings/services/settings.service';
import _ from 'lodash';
import { Settings } from '../../settings/model/settings.model';

export interface EmrEntryStatus{
  chiefComplaints: number
  drugsEMR: number
  medicalDecision: number
  menstrualHistory: number
  narrativeDiagnosis: number
  physicalExamination: number
  planandProcedure: number
  reviewOfSymptom: number
  serviceItemsEMR: number
  vitalSign: number
}

@Component({
  
  selector: 'app-consultation-general',
  templateUrl: './consultation-general.component.html',
  styleUrls: ['./consultation-general.component.scss'],

})
export class ConsultationGeneralComponent implements OnInit, OnDestroy {

  @Input() IsView;
  @Input() functionPermissionData
  public SelectedMenu:string="";

  public EMRPatientInfo: EMRPatientInfo;
  private subscription: Subscription;
  private globals: globalvars = new globalvars();
  public EmrEntryStatus: EmrEntryStatus;
 // public CC = new GeneralChiefComplaintsComponent(); // cheif complaints

 @ViewChild(GeneralChiefComplaintsComponent ) CC: GeneralChiefComplaintsComponent ;
 @ViewChild(GeneralPhysicalCommunicationComponent ) PC: GeneralPhysicalCommunicationComponent ;
 @ViewChild(GeneralReviewOfSymptomsComponent ) RS: GeneralReviewOfSymptomsComponent ;
 @ViewChild(GeneralMedicalDecisionComponent )  MD: GeneralMedicalDecisionComponent ;
 @ViewChild(GeneralPlanProcedureComponent )  PP: GeneralPlanProcedureComponent ;
 @ViewChild(GeneralMenstrualHistoryComponent )  MH: GeneralMenstrualHistoryComponent ;
 @ViewChild(GeneralNarrativeDiagonosisComponent )  ND: GeneralNarrativeDiagonosisComponent ;
 @ViewChild(GeneralVitalSignComponent )  VS: GeneralVitalSignComponent ;
 @ViewChild(GeneralDrugsMedicationComponent )  DM: GeneralDrugsMedicationComponent ;
 @ViewChild(GeneralServiceOrderComponent )  SO: GeneralServiceOrderComponent ;
 phyVisible:Number;
 tabList = [];


  constructor(private EMRService: EMRService,
              private sharedservice: SharedService,
              private toastrService: ToastrService,
             ) {

  }

  ngOnInit(): void {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });
    this.phyVisible=Number(localStorage.getItem('physioVisible'));

    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
    this.getEntryStatusEMRgeneral();
   
    this.ChangeFrame('CC');
    this.bindKeypressEvent().subscribe(($event: KeyboardEvent) => this.onKeyPress($event));
  }
  onKeyPress($event: KeyboardEvent) {
   
    if(($event.altKey || $event.metaKey) && $event.keyCode == 67)
    this.ChangeFrame('CC')

    

    if(($event.altKey || $event.metaKey) && $event.keyCode == 80)
    this.ChangeFrame('PC')
   
   

    if(($event.altKey || $event.metaKey) && $event.keyCode == 82)
    this.ChangeFrame('RS')


    
    if(($event.altKey || $event.metaKey) && $event.keyCode == 	77)
    this.ChangeFrame('MD')

    
    if(($event.altKey || $event.metaKey) && $event.keyCode == 	72)
    this.ChangeFrame('MH')


    if(($event.altKey || $event.metaKey) && $event.keyCode == 	83)
    this.ChangeFrame('PP')

    
    if(($event.altKey || $event.metaKey) && $event.keyCode == 	86)
    this.ChangeFrame('VS')


    if(($event.altKey || $event.metaKey) && $event.keyCode == 	84)
    this.ChangeFrame('DM')

    
    if(($event.altKey || $event.metaKey) && $event.keyCode == 	79)
    this.ChangeFrame('SO')
   

    if(($event.altKey || $event.metaKey) && $event.keyCode == 	73)
    this.ChangeFrame('ND')
   
}

private bindKeypressEvent(): Observable<KeyboardEvent> {
 
    const eventsType$ = [
        fromEvent(window, 'keypress'),
        fromEvent(window, 'keydown')
    ];
    // we merge all kind of event as one observable.
    return merge(...eventsType$)
        .pipe(
            // We prevent multiple next by wait 10ms before to next value.
            debounce(() => timer(10)),
            // We map answer to KeyboardEvent, typescript strong typing...
            map(state => (state as KeyboardEvent))
        );
}

  ChangeFrame(tab:string)
  {
    if (!this.tabList.includes(tab)) {
      // If not present, push it to the list
      this.tabList.push(tab);
    }
    let activaeTab= this.SelectedMenu;
    switch(activaeTab)
    {
      case  "CC":
      {
       this.CC.SaveCheifComplaints();
        break;
      }
      case  "PC":
      {
        this.PC.SavePhysicalCommunication();
        break;
      }
      case  "RS":
      {
        this.RS.SaveReviewOfSymptoms();
        break;
      }

      case  "MD":
      {
        this.MD.SaveMedicalDecision();
         break;
      }
      case  "MH":
      {
      this.MH.SaveMenstrualHistory();
      break;
      }

      case  "ND":
      {
      this.ND.SaveNarrativeDiagonosis();
      break;
      }

      case  "PP":
      {
       this.PP.SavePlanAndProcedure();
       break;
      }

      case  "VS":
      {
      this.VS.gridApi.stopEditing()
      this.VS.SaveVitalSign();
      break;
      }

      case  "DM":
      {
      this.DM.saveDrugs();
      break;
      }
      case  "SO":
        {
        this.SO.SaveServiceOrder();
        break;
        }

    }
    this.SelectedMenu=tab;
    this.getEntryStatusEMRgeneral();
  }

  getEntryStatusEMRgeneral(){
    let consultationId = Number(localStorage.getItem("consultationId"));

    const payload = {
      Consultationid: this.EMRPatientInfo.ConsultationId?this.EMRPatientInfo.ConsultationId:consultationId,
      PatientId: this.EMRPatientInfo.PatientId,
      Visitid: this.EMRPatientInfo.VisitId,
      BrabchId: this.globals.HospitalId
    }

    this.EMRService.getGeneralEntryStatus(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.EmrEntryStatus = response.response[0];
        }else{
          this.toastrService.error(response.errorMessage.message)
        }
      },error: (error: any)=>{
        this.toastrService.error('Failed to load EMR entry status..! Try again.');
      }
    })
  }


  ngOnDestroy(): void {

    this.subscription.unsubscribe();
    if(this.SelectedMenu == 'CC'){
      this.CC.SaveCheifComplaints();
    }
    if(this.SelectedMenu == 'PC'){
      this.PC.SavePhysicalCommunication();
    }
    if(this.SelectedMenu == 'RS'){
      this.RS.SaveReviewOfSymptoms();
    }
    if(this.SelectedMenu == 'MD'){
      this.MD.SaveMedicalDecision();
    }
    if(this.SelectedMenu == 'MH'){
      this.MH.SaveMenstrualHistory();
    }
    if(this.SelectedMenu == 'ND'){
      this.ND.SaveNarrativeDiagonosis();
    }
    if(this.SelectedMenu == 'PP'){
      this.PP.SavePlanAndProcedure();
    }
    if(this.SelectedMenu == 'VS'){
      this.VS.SaveVitalSign();
    }
    if(this.SelectedMenu == 'DM'){
      this.DM.saveDrugs();
    }
    if(this.SelectedMenu == 'SO'){
      this.SO.SaveServiceOrder();
    }
    // this.VS.gridApi.stopEditing()
    this.getEntryStatusEMRgeneral();
  }



}
