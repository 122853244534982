import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { SharedService } from '../../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';

@Component({
  selector: 'app-estimate-grid-cell-dropdown',
  templateUrl: './estimate-grid-cell-dropdown.component.html',
  styleUrls: ['./estimate-grid-cell-dropdown.component.scss']
})
export class EstimateGridCellDropdownComponent implements ICellRendererAngularComp {

  public imageSource!: string;
  public value: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  branchId: any;
  public CPTModifiersList: any[] = [];


  constructor(private ConfigurationService: ConfigurationService,
    private sharedservice: SharedService,
    ) {
      this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
        if (this.sharedservice.checkglobals(glob)) {
          this.globals = glob;
          this.branchId = this.globals.HospitalId;
        }
      });
      this.getAllCPTModifier();
     }

  // ngOnInit(): void {
  // }

  agInit(params: ICellRendererParams): void {
    console.log('params', params);
    // const image = params.value === 'Male' ? 'male.png' : 'female.png';
    // this.imageSource = `https://www.ag-grid.com/example-assets/genders/${image}`;
    this.value = params.value;
  }

  refresh(params: ICellRendererParams) {
    return false;
  }

  getAllCPTModifier() {
    this.ConfigurationService.GetCPTModifier(
      this.branchId,
      0
    ).subscribe((res) => {
      this.CPTModifiersList = res.response;
    });
  }

}
