import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonValidationComponent } from '../../../../shared/common-validation/common-validation.component';

@Component({
  selector: 'app-patient-registration',
  templateUrl: './patient-registration.component.html',
  styleUrls: ['./patient-registration.component.scss']
})
export class PatientRegistrationComponent implements OnInit {


  // Variablde Declarations
  public userType: string;
  public userForm: FormGroup;
  isShowRegDetails: boolean = true;
  propertyRegNo:boolean = true;

  public validator = new CommonValidationComponent();

  constructor() { }

  ngOnInit(): void {
  }


  SaveRegistrations(){

  }

  NewAdd(){

  }

  ClearForm(){

  }

  attachments(){

  }

  camerclick(){

  }
  Activetab(pagetitle){

  }


    //check and uncheck of registration date
    selectcheckdate(evt) {
      if (evt.target.checked) {
        // this.IsManualRegDate = true;
        // this.userForm.controls['RegDate'].enable();
      }
      else {
        // this.IsManualRegDate = false;
        // this.userForm.controls['RegDate'].disable();
        // let datecon = this.datepipe.transform(new Date(), 'dd-MM-yyyy')
        // this.userForm.controls['RegDate'].setValue(datecon);
      }

    }


     // ManualRegNo checkbox onchange
  ManualRegNo(val) {

    if (val) {
      // this.propertyRegNo = false;
      // this.manualRegNo1 = 1;
      // this.userForm.patchValue({ RegNo: '' });



    } else {
      // this.propertyRegNo = true;
      // this.manualRegNo1 = 0;
      // this.userForm.patchValue({ RegNo: '<<NEW>>' });


      // this.GetRegNo();
    }

  }
}
