import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { WebCameraModalComponent } from '../../../../shared/modals/web-camera-modal/web-camera-modal.component';
import jsPDF from 'jspdf';


@Component({
  selector: 'app-scan-insurance-card-modal',
  templateUrl: './scan-insurance-card-modal.component.html',
  styleUrls: ['./scan-insurance-card-modal.component.scss']
})
export class ScanInsuranceCardModalComponent implements OnInit {

  public fileCreatedEmitter = new EventEmitter();

  public frontView: any;
  public frontViewUrl: any;
  public backView: any;
  public backViewUrl: any;

  constructor(private activeModal: BsModalRef,
              private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  openCameraModal(view: string){
    let initialState = {
      
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(WebCameraModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
    modalRef.content.fileEmitter.subscribe((response: any)=>{
      if(response.status == true){
        if(view == 'front'){
          this.frontView = response.file;
          const reader = new FileReader();
          reader.onload = () => {
            this.frontViewUrl = reader.result as string;
          }
          reader.readAsDataURL(this.frontView);
        }else if(view == 'back'){
          this.backView = response.file;
          const reader = new FileReader();
          reader.onload = () => {
            this.backViewUrl = reader.result as string;
          }
          reader.readAsDataURL(this.backView);
        }
      }
    })
  }

  removeImage(view: string){
    if(view == 'front'){
      this.frontView = null;
      this.frontViewUrl = ''
    }else if(view == 'back'){
      this.backView = null;
      this.backViewUrl = '';
    }
  }

  onSaveDocument(){
    let doc = new jsPDF({
      compress: false,
      format: 'a4'
    });
    var width = doc.internal.pageSize.getWidth();
    doc.internal.scaleFactor = 2;
    doc.addImage(this.frontViewUrl,'image/png', 0, 0, width, width/2);
    // doc.addPage();
    doc.addImage(this.backViewUrl,'image/png', 0, (width/2)+6, width, width/2);
    let blob: any = doc.output('blob');
    let createdFile = new File([blob], 'scanned_file_insurance_card.pdf',{
      type: 'application/pdf'
    })
    let fileUrl = doc.output('datauristring');
    this.fileCreatedEmitter.emit({
      status: true,
      file: createdFile,
      filePreview: fileUrl
    });
    this.close();
  }

  close(){
    this.activeModal.hide();
  }

}
