import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ColDef } from 'ag-grid-community';
import { BillingGeneralActionComponent } from '../billing-general-action/billing-general-action.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { BillingService } from '../services/billing.service';
import { Response } from '../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';
import { CancelBillModalComponent } from '../modals/cancel-bill-modal/cancel-bill-modal.component';
import { CancelSettlmentModalComponent } from '../modals/cancel-settlment-modal/cancel-settlment-modal.component';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { SettlementModalComponent } from '../modals/settlement-modal/settlement-modal.component';
import { Router } from '@angular/router';
import { NewRecieptModalComponent } from '../modals/new-reciept-modal/new-reciept-modal.component';
import { ConfigurationService } from '../../client/services/configuration.service';
import { ChangeApprovalModalComponent } from '../../claim/modals/change-approval-modal/change-approval-modal.component';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.scss']
})
export class BillDetailsComponent implements OnInit {



  modalRef: BsModalRef;
  @ViewChild(SearchPatientModalComponent)modaltemp: SearchPatientModalComponent;


    // GRID DETAILS

    public statusBar;
    rowGroupPanelShow = "always";
    public gridApi;
    columnDefs: ColDef[] = [];
    public billDetails : any[] = [];
    public gridOptions = {
      pagination: true,
      paginationPageSize: 10,
      rowClassRules :{
        'row-cancelled':params=> params.data.status.toLowerCase() == 'cancelled' || params.data.status.toLowerCase() == 'c'
      }
    }
    public context;
    public frameworkComponents;
    public loadingTemplate;
    public noRowsTemplate;

    public billListingForm: FormGroup;
    private CurrentDate: any;
    private subscription: Subscription;
    private globals: globalvars = new globalvars();

    public cancelBillLoading: boolean = false;

    private baseUrl: string = '';

    public allLocations: any[] = [];
    functionPermissionData: any;
    credDetails: any;

  constructor(
    private modalService: BsModalService,
    private datepipe: DatePipe,
    private sharedService: SharedService,
    private billingService: BillingService,
    private toastrService: ToastrService,
    private router: Router,
    private configurationManager: ConfigurationManager,
    private ConfigurationService: ConfigurationService,
  ) { }

  ngOnInit(): void {
    sessionStorage.removeItem('bill-patient-regNo');
    sessionStorage.removeItem('isExternalLabBill');
    this.CurrentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');

    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });

    this.context = { componentParent: this };
    this.columnDefs = [
      {
        headerName: "Date",
        field: "billDate",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Bill No.",
        field: "billNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Reg No.",
        field: "regNo",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Patient Name",
        field: "patientName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Consultant",
        field: "consultantName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Insurance",
        field: "sponsorName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Bill Amt.",
        field: "billAmount",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Due",
        field: "dueAmt",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Location",
        field: "location",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Status",
        field: "status",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Settled By",
        field: "settledUser",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      // {
      //   headerName: "Comm. Status",
      //   field: "settledBy",
      //   resizable: true,
      //   filter: true,
      //   sortable: true,
      //   flex: 2,
      // },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "billDetails" },
      },
    ];
    this.frameworkComponents = { buttonRender: BillingGeneralActionComponent};

    this.billListingForm = new FormGroup({
      RegNos: new FormControl(""),
      PatientNames: new FormControl(""),
      BillNos: new FormControl(""),
      DateFromList: new FormControl(this.CurrentDate),
      DateToList: new FormControl(this.CurrentDate),
      PayStatus: new FormControl('All'),
      Location: new FormControl(0),
    });
    this.getLocations();
    this.GetBillList();
    this.functionPermission();
  }

  
  getLocations(){
    let payload = {
      "LocationId": 0,
      "ShowAll": 0,
      "HospitalId": this.globals.HospitalId
    }

    this.billingService.getAllLocations(payload).subscribe({
      next: (response: Response<any>)=>{
        this.allLocations = response.response;
      },error: (error: any)=>{
        this.toastrService.error('Failed to load locations, Try again.')
      }
    })
  }


  showBasicDialog() {
    this.modaltemp.open();

  }
  searchevent(patient: any) {
    this.billListingForm.controls['RegNo'].setValue(patient.data.regNo);
    // this.patientId=patient.data.patientId;
    // this.PatientInformation=patient.data;
  }

  GetBillList() {
    let StartDateObj = this.billListingForm.value.DateFromList;
    let EndDateObj = this.billListingForm.value.DateToList;
    // date convertion
    if (typeof (StartDateObj) == "object") {
      let DateFromList = this.datepipe.transform(StartDateObj, 'dd-MM-yyyy');
      this.billListingForm.value.DateFromList = DateFromList;
    }
    if (typeof (EndDateObj) == "object") {
      let endDate = this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      this.billListingForm.value.DateToList = endDate;
    }

    let input = {
      "Locationid": this.billListingForm.value.Location ? Number(this.billListingForm.value.Location) : 0,
      "TransFromDate": this.billListingForm.value.DateFromList ? this.billListingForm.value.DateFromList : "",
      "TransToDate": this.billListingForm.value.DateToList ? this.billListingForm.value.DateToList : "",
      "RegNo": this.billListingForm.value.RegNos ? this.billListingForm.value.RegNos : "",
      "BranchId": this.globals.HospitalId,
      "Externalstatus": 0,
      "PatientName": this.billListingForm.value.PatientNames ? this.billListingForm.value.PatientNames : "",
      "BillNo": this.billListingForm.value.BillNos ? this.billListingForm.value.BillNos : "",
      "Status" : this.billListingForm.value.PayStatus ? this.billListingForm.value.PayStatus : "",
    }
    this.billingService.getBillingList(input).subscribe((res) => {
      this.billDetails = res.response;
      //this.getConsultationResponse=this.BillingListData
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  cancelItem(data){
    let initialState = {
      TransId: Number(data.transId),
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(CancelBillModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
    this.modalRef.content.cancelEvent.subscribe(res=>{
      if(res.status){
        this.GetBillList();
      }
    });
    
  }

   openCancelSettlmentModal(data: any){
    let initialState = {
      TransId: Number(data.transId),
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(CancelSettlmentModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
    this.modalRef.content.cancelSettlementEvent.subscribe(res=>{
      if(res.status){
        this.GetBillList();
      }
    });

   }

   openSettlmentScreen(data){
    let initialState = {
      transId: Number(data.transId),
      branchId: Number(this.globals.HospitalId),
      locationId: Number(this.globals.Location.locationId),
      action: 'settlement'
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(SettlementModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    this.modalRef.content.settlementEventEmit.subscribe(res=>{
      if(res.status){
        this.GetBillList();
      }
    });
   }

   onEditBill(data: any) {
    let patientData = {
      patientData: data,
      OtherData: null,
      credDetails: this.credDetails,
      action: 'edit'
    }
    sessionStorage.setItem('billDetails', JSON.stringify(patientData));
    this.router.navigate(['../billitems/'+data.transId],{queryParams: {returnUrl: 'billdetails'}});
  }

  async onViewPrintBill(data: any){
    await this.loadSponsorTable(data);
    const foundElement = this.functionPermissionData.find(x => x.id === 345)
    if (foundElement.id == 345 && foundElement.isAllowed == true) {
    let patientData = {
      patientData: data,
      OtherData: null,
      credDetails: this.credDetails,
      action: 'view'
    }
    sessionStorage.setItem('billDetails', JSON.stringify(patientData));
    sessionStorage.setItem('viewOnly', JSON.stringify({status: true}));
    this.router.navigate(['../billitems/'+data.transId],{queryParams: {returnUrl: 'billdetails'}});
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  newBill(){
    const foundElement = this.functionPermissionData.find(x => x.id === 341)
    if (foundElement.id == 341 && foundElement.isAllowed == true) {
    this.router.navigate(['/newbill'], {queryParams: {returnUrl: 'billdetails'}});
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }


   onPrintUpFront(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 344)
    if (foundElement.id == 344 && foundElement.isAllowed == true) {

      let initialState = {
        modalHeading: 'Print Upfront',
        reportName: 'PrintUpfront.trdp',
        reportParams: {
          IsExternal: false,
          ShowPackName: true,
          TransId : Number(data.transId),
          BranchId :this.globals.HospitalId
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    // let payload = {
    //   IsExternal: false,
    //   ShowPackName: true,
    //   TransId : Number(data.transId),
    //   BranchId :this.globals.HospitalId
    // }

    // this.billingService.printUpfrontInvoice(payload).subscribe({
    //   next: (response: any)=>{
    //     if(response.status == 200){
    //       window.open(this.baseUrl+response.response[0]?.reportPath, '_blank')
    //     }else{
    //       this.toastrService.error(response.errorMessage?.message)
    //     }
    //   },error: (error: any)=>{
    //     this.toastrService.error('Something wents wrong, Try again...');
    //   }
    // })
    } else {
      this.toastrService.warning("You are not allowed");
    }
   }

   onPrintInvoice(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 346)
    if (foundElement.id == 346 && foundElement.isAllowed == true) {

      let initialState = {
        modalHeading: 'Print Invoice',
        reportName: 'PrintInvoice.trdp',
        reportParams: {
          TransId : Number(data.transId),
          BranchId :this.globals.HospitalId,
          CreditId: Number(data?.creditId)
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    // let payload = {
    //   TransId : Number(data.transId),
    //   BranchId :this.globals.HospitalId
    // }

    // this.billingService.printBillInvoice(payload).subscribe({
    //   next: (response: Response<any>)=>{
    //     if(response.status == 200){
    //       window.open(this.baseUrl+response.response[0]?.reportPath, '_blank')
    //     }else{
    //       this.toastrService.error(response.response)
    //     }
    //   },error: (error: any)=>{
    //     this.toastrService.error('Something wents wrong, Try again...');
    //   }
    // })
    } else {
      this.toastrService.warning("You are not allowed");
    }
   }

   onFilterTextBoxChangedItem(event){
    this.gridApi.setQuickFilter(
      event.target.value
    );
  }

  onNewRecieptBill(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 343)
    if (foundElement.id == 343 && foundElement.isAllowed == true) {
    let initialState = {
      patientId: Number(data.patientId),
      globals: this.globals,
      regNo: data.regNo,
      transId: Number(data.transId)
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(NewRecieptModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  onSettlement(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 342)
    if (foundElement.id == 342 && foundElement.isAllowed == true) {
      if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
        this.openSettlmentScreen(data);
      }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
        this.toastrService.error('This bill is already settled..');
      }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
        this.toastrService.error('This bill is cancelled..');
      }    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  cancelSettlement(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 347)
    if (foundElement.id == 347 && foundElement.isAllowed == true) {
      if (data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n') {
        this.toastrService.error('This bill is not settled yet..');
      } else if (data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's' || data.status.toLowerCase() == 'resettled') {
        this.openCancelSettlmentModal(data);
      } else if (data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c') {
        this.toastrService.error('This bill is already cancelled..');
      }
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  cancelBill(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 348)
    if (foundElement.id == 348 && foundElement.isAllowed == true) {
      if (data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n') {
        this.cancelItem(data);
      } else if (data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's') {
        this.toastrService.error('This bill is already settled, Cancel settlement first to cancel bill..');
      } else if (data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c') {
        this.toastrService.error('This bill is already cancelled..');
      }
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  async onActionEditBill(data) {
    await this.loadSponsorTable(data);
    const foundElement = this.functionPermissionData.find(x => x.id === 349)
    if (foundElement.id == 349 && foundElement.isAllowed == true) {
      if (data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's') {
        this.toastrService.error('Settled bills are not allowed to edit..');
      } else if (data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c') {
        this.toastrService.error('Cancelled bills are not allowed to edit..');
      } else {
        this.onEditBill(data);
      }
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  async loadSponsorTable(data:any) {
    let selectedSponsor;
    let payload = {
      PatientId: data.patientId,
      Branchid: this.globals.HospitalId,
      ShowExpired: 1
    }
    let sponsorList = await this.billingService.GetSponsorDetailsByPatient(payload).toPromise();
    // this.billingService.GetSponsorDetailsByPatient(payload).subscribe((res) => {
    //   this.sponsorList = res.response;
    // });
    // console.log('sponsorList', sponsorList);
    sponsorList.response.find(x => {
      // console.log('x', x);
      if(x.isPrimary == true){
        this.credDetails = x;
      }
    });
  }

  onChangeApproval(data: any){
    let initialState = {
      transId: Number(data.transId),
      branchId: this.globals.HospitalId,
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(ChangeApprovalModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
  }

  async reSettlement(data){
    await this.loadSponsorTable(data);
    this.onResettleBill(data);
  }

  onResettleBill(data: any) {
    if (data.billDate == this.datepipe.transform(new Date(), 'dd/MM/yyyy')) {
      let patientData = {
        patientData: data,
        OtherData: null,
        credDetails: this.credDetails,
        action: 'resettlement'
      }
      sessionStorage.setItem('billDetails', JSON.stringify(patientData));
      this.router.navigate(['../billitems/' + data.transId], { queryParams: { returnUrl: 'billdetails' } });
    }else{
      this.toastrService.warning("Only today's bill can be resettled");
    }
  }
}
