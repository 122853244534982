import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PrevVitalSignsModalComponent } from '../modals/prev-vital-signs-modal/prev-vital-signs-modal.component';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EMRService } from '../../services/emr.service';
import { PrevPhysicalExaminationModalComponent } from '../modals/prev-physical-examination-modal/prev-physical-examination-modal.component';
import { Settings } from '../../../settings/model/settings.model';

@Component({
  selector: 'app-physical-examination',
  templateUrl: './physical-examination.component.html',
  styleUrls: ['./physical-examination.component.scss']
})
export class PhysicalExaminationComponent implements OnInit,OnChanges {
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  disabled:boolean=false

  public EmrPatientInfo:EMRPatientInfo;
  private FormValueChangeChecker: boolean = false;

  public physicalExaminationForm: FormGroup;

  constructor(private modalService: BsModalService,
              private EmrService: EMRService,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.EmrPatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initform();
    this.GetPhysicalExaminationDetails();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 43 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (isSettingWithValueZeroPresent) {
        // Your logic when a setting with value 0 is present and billEdit is true
      
        this.disabled=true
      }
      
    }
  }

  initform(){
    this.physicalExaminationForm = this.formBuilder.group({
      peId: new FormControl(0),
      constitution: new FormControl(""),
      gastrointestinial: new FormControl(""),
      genitourinary: new FormControl(""),
      eyes: new FormControl(""),
      ent: new FormControl(""),
      neck: new FormControl(""),
      skin: new FormControl(""),
      breast: new FormControl(""),
      respiratory: new FormControl(""),
      muscleoskle: new FormControl(""),
      psychiarty: new FormControl(""),
      cardiovascular: new FormControl(""),
      neurological: new FormControl(""),
      hemotology: new FormControl(""),
      thyroid: new FormControl(""),
      abdomen: new FormControl(""),
      pelvis: new FormControl(""),
      others: new FormControl(""),
      visitId: new FormControl(this.EmrPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
    })
  }

  GetPhysicalExaminationDetails(){
    let input={
      VisitId:this.EmrPatientInfo.VisitId,
      PatientId:this.EmrPatientInfo.PatientId,
      ShowAll:1
    };

    this.EmrService.GetPhysicalExaminationDetails(input).subscribe(
      (res) => {
        if(res.status==200){
          let response=res.response[0];
          this.physicalExaminationForm.patchValue(response);
          this.physicalExaminationForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker=true;
           })
        }
      });
  }

  SavePhysicalCommunication(){
    if(this.EmrPatientInfo.CrudType!=3 &&
      this.EmrPatientInfo.VisitId>0 && this.FormValueChangeChecker){
      let PhysicalExamination = this.physicalExaminationForm.getRawValue();
      PhysicalExamination.userId=Number(localStorage.getItem('user_id'));
      this.EmrService.SavePhysicalExaminationDetails(
        PhysicalExamination
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastrService.success("Success: " + "Physical Examination Details Saved Successfully...");
          }
          else{
            this.toastrService.error(res.errorMessage.message)
          }
        });
    }
    else{
      if(!this.FormValueChangeChecker){
        //this.toastr.warning("Info: " + "No changes detected in the entry");
      }
    }
  }

  openHistory(){
    let initialState = {
      EmrPatientInfo: this.EmrPatientInfo
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const historyModalRef= this.modalService.show(PrevPhysicalExaminationModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }
  
}
