import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TreeNode } from 'primeng/api';
import { NodeService } from './nodeservice';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../general-action/general-action.component';
import { HospitalsService } from '../../services/hospitals.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../shared/shared.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { PatientList } from '../../interfaces/PatientList';
import { PatientService } from "../../services/patient.service";
import { ToastrService } from "ngx-toastr";
import { ServiceOrderingService } from '../../services/ServiceOrderingService';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormValidationService } from "../../services/form-validation.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SearchPatientModalComponent } from '../../frontoffice/searchpatient-modal/searchpatient-modal.component';
import { ConfigurationService } from "../../services/configuration.service";
import { CommonValidationComponent } from '../../../../shared/common-validation/common-validation.component';
import { Location } from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-ServiceOrdersComponent',
  templateUrl: './serviceordering.component.html',
  styleUrls: ['./serviceordering.component.scss'],

})
export class ServiceOrdersComponent implements OnInit {
  @ViewChild('grouplist') el: ElementRef;
  @ViewChildren('td') cells: QueryList<ElementRef>;
  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;
  hospitalIds: any;
  TargetOriginal: any[];
  OriginalServiceGroups: any[];
  user_id: string;
  orderNoModal: boolean;
  SerViceOrderNo: any;
  selectedOrderId: any;
  OrderById: any;
  OrderDetailsById: any;
  valueDate2: "";
  userType: any;
  ConsultantId: any;
  patientConsultant: any

  enterpressed(event) {
    alert("in");
  }
  files1: TreeNode[];
  serviceorder: boolean = true;
  orderdetails: boolean;
  pendingorder: boolean;
  sourceProducts: any[];
  roducts: any[];
  targetProducts: any[] = [];
  activeState: boolean[] = [true, false, false];
  displayBasic: boolean;
  selectedpackage: boolean;
  selectprofile: boolean;
  pendingorders: boolean;
  columnDefs: ColDef[] = [];
  pendingOrders: ColDef[] = [];
  PendingOrderDetails: ColDef[] = [];
  columnDefs1: ColDef[] = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings2: IDropdownSettings = {};
  rowDatapending: {}[] = [];
  rowData: {}[] = [];
  rowData1: {}[] = [];

  //  current date
  isCollapsed = true;
  public frameworkComponents;
  valueDate: "";
  hospitalList: any;
  globals: globalvars = new globalvars();;
  form: FormGroup;
  patId: any;
  orderDate: string;
  subscription: Subscription;
  BranchId: number;
  BranchesId: any;
  patients: any;
  result: any;
  consultants: any;
  consultantTypeId: any;
  profileId: any;
  packages: any;
  Availableservices: any;
  public submitted: boolean = false;
  ConsultantDate: any;
  PackageItem: any;
  search = '';
  //ag grid setup
  isLoading = false;
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;

  public noRowsTemplate;
  public loadingTemplate;
  getProfile: any;
  ProfileId: any = [];
  consultationId:any;
  ServicesGroups: any;
  PatientId: any;
  getAvailableServiceById: any;
  SelectGroupId: any[] = [];
  selectProfileId: any[] = [];
  ProfileIdEvents: any[];
  packageName: any;
  packageId: any;
  pendingOrderLength: any;
  ItemName: any;
  ProfileName: any;
  AvailableItemId: any;
  AvailableGroupId: any;
  isButtonVisible = true;
  arraySeperate: any;
  ShowPackage: any;
  //searchText: '';
  searchText: '';
  searchText1: '';
  searchTextGroups: '';
  filteredEmployees: any;
  sourceOriginal: any;
  selectedFiles: any[];
  patientDetails: PatientList[] = [];
  EventId: any;
  public validator = new CommonValidationComponent();
  consultationsId: any
  IsConsultantId = null;
  patientId:any;
  public totalCost: any = 0.00;
  getSpointData: any;
  prevUrl: any;
  newOrderId = 0;

  constructor(public datepipe: DatePipe, private nodeService: NodeService,
    private hospitalsService: HospitalsService,
    private formBuilder: FormBuilder,
    private sharedservice: SharedService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private patientService: PatientService,
    private ServiceOrderingService: ServiceOrderingService,
    private ngxLoader: NgxUiLoaderService,
    private FormValidationService: FormValidationService,
    private router: Router,
    private ConfigurationService: ConfigurationService,
    private _el: ElementRef,
    private _location: Location
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        // this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId;

      }
      if (this.router.getCurrentNavigation().extras.state) {
        this.patientId = this.router.getCurrentNavigation().extras.state.patientId;
        this.consultationId=this.router.getCurrentNavigation().extras.state.consultationId;
        this.getSpointData = this.router.getCurrentNavigation().extras.state?.getSpointData;
        this.prevUrl = this.router.getCurrentNavigation().extras.state?.prevUrl;
      }
  
    });



  }



  @HostListener('keyup', ['$event']) onKeyDown(e: any) {


    let element = e.srcElement;
    let cid = element.id;
    let eid = cid.split('_');
    let next;

    if (e.key == "ArrowDown") {
      next = parseInt(eid[1]) + 1;

      if (eid[0] == 'tp') {
        document.getElementById("tp_" + next).focus();
      } else {
        document.getElementById("li_" + next).focus();
      }

    }
    else if (e.key == "ArrowUp") {

      next = parseInt(eid[1]) - 1;
      if (next == 0 || next > 0) {
        if (eid[0] == 'tp') {
          document.getElementById("tp_" + next).focus();
        } else {
          document.getElementById("li_" + next).focus();
        }
      }
      else {
        if (eid[0] == 'tp') {
          document.getElementById("tp_").focus();
        } else {
          document.getElementById("li_").focus();
        }
      }

    }


  }

  setfocus(e) {
    document.getElementById("li_0").focus();
  }
  setSelectedFocus(e) {

    document.getElementById("tp_0").focus();
  }

  // setfocusServiceGroups(e)
  // {
  //   document.getElementById("setservices").focus();
  // }

  onTab(number) {
    this.serviceorder = false;
    this.orderdetails = false;
    this.pendingorder = false;


    if (number == '1') {
      this.serviceorder = true;
    }

    else if (number == '2') {
      this.pendingorder = true;
    }

  }
  ngOnInit(): void {


    this.userType = localStorage.getItem("usertype");
    this.activatedRoute.params.subscribe((params) => {
      this.PatientId = Number(this.patientId);
      this.consultationsId = Number( this.consultationId);
      // alert(this.PatientId);
    });
    let currentappDate = new Date();
    // this.valueDate2 = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    let convertDate = currentappDate.setDate(currentappDate.getDate() - 7);
    // this.valueDate = this.datepipe.transform(convertDate, 'dd-MM-yyyy');
    this.user_id = localStorage.getItem('user_id');
    this.fetchDetails();
    this.orderDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    this.form = this.formBuilder.group({
      regNo: new FormControl("", [Validators.required]),
      patientName: new FormControl(''),
      orderDate: new FormControl(this.orderDate, [Validators.required]),
      orderNo: new FormControl('<<NEW>>'),
      hospitalId: new FormControl(''),
      consultant_type: new FormControl('3'),
      consultant: new FormControl("", [Validators.required]),
      lastConsultantDate: new FormControl(''),
      packages: new FormControl(''),
      packageAmount: new FormControl(''),
      profiledesc: new FormControl(''),
      profileSubItem: new FormControl(''),
      searchText: new FormControl(''),
      searchText1: new FormControl(''),
      searchTextGroups: new FormControl(''),
      remarks: new FormControl(''),
      locationId: new FormControl(this.globals.Location.locationId)
    });


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'profileId',
      textField: 'profileDesc',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
      defaultOpen: false
    };

    this.dropdownSettings2 = {
      singleSelection: false,
      idField: 'itemId',
      textField: 'itemName',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
      defaultOpen: false
    };



    this.GetUserHospitals();
    setTimeout(() => {
      this.GetConsultantsByHospitals();
    }, 3000);
    //this.GetAllAvailableServices();
    this.getServiceGroupItems();
    this.GetAllPackages();
    // this.showBasicProfile();
    this.FormValidationService;
    //this.nodeService.getFiles().then(files => this.files1 = files);


    //= [{ 'name': 'hospital','category':'gfhf','price':'20' },{ 'name': 'radiology','category':'vcbv','price':'78' }];

    //  this.targetProducts = [];
    // this.valueDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');

    this.columnDefs = [
      {
        headerName: "Item Name",
        field: "itemName",
        enableRowGroup: false,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Rate",
        field: 'rate',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,
      },




    ];

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };



    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    this.pendingOrders = [
      {
        headerName: "Order No",
        field: "orderNo",
        enableRowGroup: false,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Order Date",
        field: 'orderDate',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,
      },

      {
        headerName: "Ordered By",
        field: 'consultantName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,
      },

    ];
    this.PendingOrderDetails = [
      {
        headerName: "Service Item",
        field: 'itemName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,
      },
      {
        headerName: "Status",
        field: 'status',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,
      },

      {
        headerName: "Payment",
        field: 'payStatus',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,
      },



    ];

    this.columnDefs1 = [
      {
        headerName: "Item Name",
        field: "itemName",
        enableRowGroup: false,
        resizable: true,
        sortable: true,
        flex: 2,
      },

    ];
    this.ConsultantId = localStorage.getItem('Id');
    if (this.userType !== "U") {
      this.form.controls['consultant'].disable();

      this.IsConsultantId = true;
      this.form.patchValue({ consultant: this.ConsultantId });
    }
  }

  // fetchId() {
  //   if (this.PatientId.toString() != 'NaN') {
  //     this.fetchDetails(this.PatientId);
  //   }
  // }

  fetchDetails() {
    // let input = {
    //   Id: this.PatientId,
    //   consultationId: this.consultationsId
    // }
    this.patientService.GetPatientDetailsbyId(this.PatientId, this.consultationsId).subscribe((res) => {
      this.patId = this.PatientId;
      this.patientDetails = res.response;

      this.form.patchValue({ regNo: this.patientDetails[0].regNo });

      this.form.patchValue({ patientName: this.patientDetails[0].patientName });


    })
  }
  //Reset form
  ClearForm() {
    this.form.reset();
    this.form.reset({

      regNo: "",
      patientName: "",
      consultantId: "",
      orderNo: '<<NEW>>',
      hospitalId: this.BranchesId,
      orderDate: this.orderDate

    });
    this.targetProducts = []
    this.BranchesId;
    this.rowData1;
  }
  //Session for PageTitle
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);

  }
  ShowOrderNo() {
    this.orderNoModal = true;
  }

  // Search patients Modal
  showBasicDialog() {

    this.modaltemp.open();
    this.displayBasic = true;
  }

  // Search patients Modal
  showBasicDialogSearch($event) {
    this.isLoading = true;

    this.displayBasic = $event;
  }
  //Package selecting Modal
  showBasicpackage(event) {
    if (event == true) {
      this.ShowPackage = true;
    }
    else {
      this.ShowPackage = null;

    }
    this.selectedpackage = true;


  }
  //close package
  closePackage() {
    this.ClearForm();
    this.GetAllPackages();
  }

  //pending Orders List Modal
  showBasicProfile() {

    this.selectprofile = true;
    this.ServiceOrderingService.GetProfile(this.globals.HospitalId).subscribe((res) => {
      this.getProfile = res.response;

    });
  }

  showPendingOrders() {


    if (this.patId) {
      this.GetPatientPendingOrders();
    }
    else {
      this.noRowsTemplate = "No Rows To Show"

    }
    this.pendingorders = true;
  }
  //Global settings for Branch ID
  GetUserHospitals() {
    this.hospitalsService.GetUserSpecificHospitals(this.user_id).subscribe((res) => {
      this.hospitalList = res.response;
      let BranchId = this.globals.HospitalId
      this.form.patchValue({ hospitalId: this.globals.HospitalId });

    });
  }

  //Global settings for Branch ID
  GetConsultantsByHospitals() {
    this.ServiceOrderingService.GetConsultantByHospital(this.globals.HospitalId, 0).subscribe((res) => {
      this.consultants = res.response;

      // this.form.controls['consultant'].patchValue(this.consultants[2]);
      this.form.patchValue({ consultant: this.patientDetails[0].consultantId });

      this.loadConsultantDate();
      // setTimeout(function () {
      // this.form.patchValue({
      //   consultant: 2531,
      // });
      // }, 5000);



    });

  }
  goback() {
    // this._location.back();
    if(this.prevUrl){
      this.router.navigate(['/lab/TodayspatientLab'], { state:{getPointDetails:this.getSpointData, newOrderId: this.newOrderId}})
    }else{
      this._location.back();
    }
  }

  //common function added for filling patient details on clicking search patient modal
  searchevent(patient: any) {

    this.patId = patient.data.patientId;
    this.form.patchValue({ regNo: patient.data.regNo });
    this.form.patchValue({ patientName: patient.data.patient });
    this.form.patchValue({ patientId: patient.data.patientId });
    this.form.patchValue({ firstName: patient.data.firstName });
    this.form.patchValue({ consultant: patient.data.consultantId != 0 ? patient.data.consultantId : '' });

    this.displayBasic = false;
    this.loadConsultantDate();

  }
  // getting search patients details
  // SearchPatientDetail(patient: any) {

  //   let _this = this;
  //   this.GetPatientByRegNo();
  //   if (this.patients && patient) {

  //     let patientDetail = _this.patients.filter(
  //       (patientData) => patientData.regNo == patient
  //     );

  //     if (patientDetail.length > 0) {
  //       _this.FillForm1(patientDetail[0]);

  //     }



  //   }
  // }
  SearchPatientDetail(patient: any) {

    let _this = this;
    this.GetPatientByRegNo();
    setTimeout(function () {
      let patientDetail = _this.patients.filter(
        (patientData) => patientData.regNo.trim() == patient.trim()
      );

      if (patientDetail.length > 0) {
        _this.FillForm1(patientDetail[0]);

      }

      // else {
      //   _this.toastr.error("No such patients found");
      // }
    }, 1000);

  }

  //calling patients details Api
  GetPatientByRegNo() {
    this.patientService.SearchPatientByRegNo(this.form.get("regNo").value).subscribe((res) => {
      this.patients = res.response;

      if (this.patients.length > 0) {
        if (this.patients[0].active == "0") {

          this.toastr.warning("This Patient is blocked");


          this.ClearForm();

          this.form.patchValue({ regNo: this.patients[0].regNo });
          this.patients = [];
        }

      } else {

        this.toastr.warning("No patients found");
      }

    });
  }

  // on entering reg no filling patient details on form
  FillForm1(patient: any) {

    this.patId = patient["patientId"];
    this.form.patchValue({ regNo: patient["regNo"] });
    this.form.patchValue({ patientName: patient["patient"] });
    this.form.patchValue({ consultant: patient["consultantId"] });

    this.loadConsultantDate();
  }
  //Loading consultants by consultants type

  loadConsultantType(consultantTypeId: any) {

    this.consultantTypeId = this.form.get("consultant_type").value
    this.hospitalIds = this.form.get("hospitalId").value

    this.ServiceOrderingService.GetConsultantByHospital(Number(this.hospitalIds), consultantTypeId).subscribe((res) => {
      this.consultants = res.response;
    });

  }

  //calling API of Getting All Packages
  GetAllPackages() {
    this.ServiceOrderingService.GetAllPckages(this.BranchesId).subscribe((res) => {
      this.packages = res.response;
    });

  }
  //getting All Available services Api
  GetAllAvailableServices() {

    this.ServiceOrderingService.GetAllAvailableServices(this.globals.HospitalId).subscribe((res) => {
      this.sourceProducts = res.response
      this.sourceOriginal = res.response
    });

  }

  get f() {
    return this.form.controls;
  }
  //Saving Service Orders

  SaveServiceOrdering() {

    this.submitted = true;
    let result = this.f;

    let TargetProductsDetails = [];
    this.targetProducts.forEach(a => {
      TargetProductsDetails.push({ "itemId": a.itemId, "itemType": a.itemType, 'itemTypeId': a.itemTypeId, 'rate': a.Rate });

    });

    let orderDate = this.form.value.orderDate

    if (typeof (orderDate) == "object") {
      this.orderDate = this.datepipe.transform(orderDate, 'dd-MM-yyyy');
    }
    else {
      this.orderDate = orderDate;
    }



    if (this.form && this.form.valid && TargetProductsDetails.length > 0) {

      this.ngxLoader.start();
      this.ConsultantId = Number(localStorage.getItem('Id'));
      if (this.userType !== "U") {
        this.IsConsultantId = true;
        this.form.value.consultant = this.ConsultantId;
      }
      if (this.form.value.consultant == "") {
        this.toastr.error("Consultant Required ");

      }
      else {
        this.isLoading = true;

        this.ServiceOrderingService.SaveServiceOrdering(this.form, TargetProductsDetails, this.patId, this.consultationId, this.orderDate).subscribe((res) => {
          this.consultants = res.response;

          this.result = res;
          {
            if (this.result.response.responseMessage == "Success") {
              this.isLoading = false;

              this.ngxLoader.stop();
              this.ShowOrderNo();
              this.SerViceOrderNo = this.result.response.orderNo;
              this.newOrderId = this.result.response.orderId;
              this.form.controls['regNo'].reset();
              this.form.controls['patientName'].reset();
              this.targetProducts = [];
              this.loadConsultant(this.consultantTypeId);
              this.toastr.success("Service Order added successfully");

            }

            else {
              this.isLoading = false;

              this.ngxLoader.stop();
              this.toastr.error("Failure: " + this.result.message);
            }

            this.submitted = false;
          }
        });
      }
    }
    else {

      let result = this.f;
      if (result.regNo.value == "") {
        this.toastr.error("RegNo Required", "", { timeOut: 1000000 });
      }
      if (result.consultant.value == "") {
        this.toastr.error("Consultant Required", "", { timeOut: 1000000 });
      }
      if (result.patientName.value == "") {
        this.toastr.error("Patient Name Required", "", { timeOut: 1000000 });
      }

      if (result.orderDate.value == "" || result.orderDate.value == null) {
        this.toastr.error("OrderDate Required", "", { timeOut: 1000000 });
      }

      if (TargetProductsDetails.length == 0) {
        this.toastr.error("Service Order Required", "", { timeOut: 1000000 });
      }

    }
  }

  //calling API of Getting All Packages

  loadConsultantDate() {
    let consultantId = this.form.get("consultant").value;
    this.ServiceOrderingService.GetLastConsultantDate(Number(consultantId), this.patId).subscribe((res) => {
      this.ConsultantDate = res.response;
      this.form.patchValue({ lastConsultantDate: this.ConsultantDate[0].consultDate });
      // this.consultationId = this.ConsultantDate[0].consultationId

    });
  }

  //on changing package getting package amount
  loadamount(event) {
    let packageDetail = this.packages.filter(
      (patientData) => patientData.packId == event
    );

    let packageAmount = packageDetail[0]['packAmount'];
    this.form.patchValue({ packageAmount: packageAmount });
    let remarks = packageDetail[0]['remarks'];
    this.form.patchValue({ remarks: remarks });
    this.loadPackageItem(event);
  }

  loadPackageItem(event) {
    this.ServiceOrderingService.GetPackageItem(event).subscribe((res) => {
      this.rowData = res.response;

    });
    // this.selectpackage()
  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {

    this.gridApi = params.api;
  }

  ProfileSubItems(event) {

    if (event.length > 1) {
      event.forEach((a) => {
        this.ProfileId.push(Number(a.profileId));
      });
    }
    else {
      this.ProfileId.push(Number(event.profileId));
    }
    this.ServiceOrderingService.GetProfileSubItem(this.ProfileId).subscribe((res) => {
      this.rowData1 = res.response;
    });

  }

  selectpackage() {
    let PackageId = this.form.get("packages").value;
    this.packageId = PackageId;

    let selectpackageDetail = this.packages.filter(
      (patientData) => patientData.packId == PackageId
    );

    this.packageName = selectpackageDetail[0]['packDesc'];
    let packageDetail = { 'itemName': this.packageName, 'itemId': this.rowData, 'itemTypeId': Number(this.packageId), 'itemType': 2, 'Rate': 0 };
    let IsExist = 0;
    this.targetProducts.forEach((a) => {
      if (a.itemType == "2") {

        IsExist = 1;
        this.toastr.warning("This Service Already Selected");

      }
    });
    if (IsExist == 0) {
      this.targetProducts.push(packageDetail);
    }
    let deletedKeys = [];
    this.rowData.forEach((rwdaata) => {
      this.targetProducts.forEach((tar, key) => {
        if (typeof (tar.itemId) != 'undefined' && typeof (tar.itemId) != 'object' && tar.itemId == rwdaata['itemId']) {
          deletedKeys.push(key);
          this.sourceProducts.push(tar);
          this.sourceOriginal = this.sourceProducts;
        }
      });
    });
    let newtarget = this.targetProducts.filter(function (targ, key1) {
      return deletedKeys.indexOf(key1) <= -1;
    }
    );
    this.targetProducts = newtarget;

  }
  //profile deselect

  packageDeSelect(event) {

    let unselectProfile = [];
    this.ProfileId.forEach(a => {
      if (a != event.profileId) {

        unselectProfile.push(a)

      }

      this.ProfileId = unselectProfile;
      this.ServiceOrderingService.GetProfileSubItem(unselectProfile).subscribe((res) => {
        this.rowData1 = res.response;

      });

    });

  }

  //select profile modal button
  selectedprofile() {
    let profiledesc = this.form.get("profiledesc").value;

    let profileId = [];
    profiledesc.forEach((element) => {
      profileId.push(element.profileId);
    });

    let profileNames = [];
    profiledesc.forEach((element) => {
      profileNames.push(element.profileDesc);
    });

    let gridItemName = [];
    this.rowData1.forEach((element) => {
      let IsExist = 0;
      this.targetProducts.forEach((a) => {
        if (a.itemId == element['itemId']) {
          IsExist = 1;
          this.toastr.warning("This Service Already Selected");
        }

      });
      if (IsExist == 0) {
        this.targetProducts.push({ 'itemName': element['itemName'], 'itemId': element['itemId'], 'itemType': 3, 'itemTypeId': 0, 'Rate': 0 });

      }

    });


    this.ProfileName = profileNames;
    //this.form.reset();
    this.form.controls['profiledesc'].reset()
    this.ProfileId = [];
    this.rowData1 = [];

  }
  //getting service group items
  getServiceGroupItems() {
    let input = {

      "BranchId": this.globals.HospitalId,
      "PageCode": "SO"

    }

    let firstNode=[ {
      "groupId": 0,
      "groupCode": "0",
      "groupTypeId": 0,
      "label": "All",
      "children": [
       ]}]

    this.ServiceOrderingService.getServiceGroupItems(input).subscribe((res) => {
      this.ServicesGroups = [...firstNode,...res.response];
      this.OriginalServiceGroups = res.response;

    });
  }

  nodeSelect(event) {

    this.EventId = event.node.groupId;
    this.ServiceOrderingService.GetAvailableServiceForLocation(this.globals.HospitalId, this.patId, [this.EventId],this.globals?.Location?.locationId).subscribe((res) => {
      this.sourceProducts = res.response;
      this.sourceOriginal = this.FilteringDataDuplication(res.response)
      this.sourceProducts = this.sourceOriginal;
    });

    this.selectedFiles = this.selectedFiles.filter((oppdata) => oppdata.groupId == this.EventId);



  }
  nodeUnselect(event) {
    this.sourceProducts = [];
  }


  FilteringDataDuplication(response) {
    let filteredService = [];
    if (this.targetProducts.length > 0) {
      response.forEach(a => {
        let IsSelectedProduct = false;
        this.targetProducts.forEach(b => {
          if (b.itemId == a.itemId) {
            IsSelectedProduct = true;
          }
        });
        // push only services which are not in selected list (not in target product)
        if (IsSelectedProduct == false) {
          filteredService.push(a)
        }
      });
    }
    else {
      filteredService = response;
    }
    return filteredService;
  }


  selectedServices(sourceProduct) {
    if (!this.checkExistingPackService(sourceProduct)) {
      let SelectEvent = sourceProduct.itemName
      let selectAvailbaleService = this.sourceProducts.filter(
        (availservice) => availservice.itemName == SelectEvent
      );

      this.ItemName = selectAvailbaleService[0]['itemName'];
      this.AvailableItemId = selectAvailbaleService[0]['itemId'];
      this.AvailableGroupId = selectAvailbaleService[0]['groupId'];
      let ServiceRate = selectAvailbaleService[0]['rate'];

      let selectAvailServ = { 'itemName': this.ItemName, 'groupId': this.AvailableGroupId, 'itemId': this.AvailableItemId, 'itemType': 1, 'itemTypeId': 0, 'Rate': ServiceRate };
      this.targetProducts.push(selectAvailServ);

      let unselectSourceProducts = [];
      this.sourceProducts.forEach(a => {
        if (sourceProduct.itemId != a.itemId) {
          unselectSourceProducts.push(a)
        };
      });
      this.sourceProducts = unselectSourceProducts;
      this.sourceOriginal = this.sourceOriginal.filter(function (availservice) {
        if (sourceProduct.itemId != availservice.itemId) {
          return availservice;
        }
      });
      //this.form.controls['searchText'].reset()
      this.TargetOriginal = this.targetProducts;
      // this.sourceOriginal=this.sourceProducts;

      // clear search


      this.form.controls['searchText'].setValue("");
      this.onFilter();
      document.getElementById("li_").focus();
      this.calculateTotalAmount();
    }
    else {
      this.toastr.warning("This Service Already Selected");
    }

  }

  checkExistingPackService(sourceProduct) {

    let IsExist = false;
    this.targetProducts.forEach(a => {
      if (a.itemType == 2) {
        a.itemId.forEach(b => {
          if (b.itemId == sourceProduct.itemId) {
            IsExist = true;
          }

        });
      }
      if (a.itemType == 3) {

        if (a.itemId == sourceProduct.itemId) {
          IsExist = true;

        }
      }

      if (a.itemType == 1) {

        if (a.itemId == sourceProduct.itemId) {
          IsExist = true;

        }
      }
    });
    return IsExist;
  }



  deleteItem($event) {

    let unselectgroup = [];
    this.targetProducts.forEach(a => {
      if ($event.itemId != a.itemId) {
        unselectgroup.push(a)
      };
      this.targetProducts = unselectgroup;
    });
    this.sourceProducts.push($event)
    this.sourceOriginal = this.sourceProducts;

    this.TargetOriginal = this.TargetOriginal.filter(function (availservice) {
      if ($event.itemId != availservice.itemId) {
        return availservice;
      }
    });
    this.calculateTotalAmount();

  }

  onFilter() {

    let searchFilter = this.form.get("searchText").value;
    let selectAvailbaleService1 = this.sourceOriginal.filter(function (availservice) {

      if (availservice.itemName.toLocaleLowerCase().indexOf(searchFilter.toLocaleLowerCase()) !== -1) {

        return availservice;
      }

    }

    );
    this.sourceProducts = selectAvailbaleService1;
  }

  //Loading consultants by consultants clinic

  loadConsultant(consultantTypeId: any) {
    this.consultantTypeId = this.form.get("consultant_type").value;
    let BranchId = this.form.get("hospitalId").value;
    this.consultantTypeId
    this.ServiceOrderingService.GetConsultantByHospital(Number(BranchId), this.consultantTypeId).subscribe((res) => {
      this.consultants = res.response;
    });

  }

  onFilterTarget() {

    let searchFilter = this.form.get("searchText1").value;
    let selectAvailbaleService1 = this.TargetOriginal.filter(function (availservice) {
      if (availservice.itemName.toLocaleLowerCase().indexOf(searchFilter.toLocaleLowerCase()) !== -1) {
        return availservice;
      }
    }
    );

    this.targetProducts = selectAvailbaleService1;
  }

  onFilterServiceGroups() {
    let serviceGroups = this.form.get("searchTextGroups").value;
    let selectAvailbaleService1 = this.OriginalServiceGroups.filter(function (availservice) {

      if (availservice.label.toLocaleLowerCase().indexOf(serviceGroups.toLocaleLowerCase()) !== -1) {

        return availservice;
      }

    }

    );
    this.ServicesGroups = selectAvailbaleService1;
  }
  GetPatientPendingOrders() {
    this.patId;
    this.valueDate = "";
    this.valueDate2 = "";
    this.ServiceOrderingService.GetPendingOrdersDetails(0, this.patId, this.globals.HospitalId, this.valueDate, this.valueDate2).subscribe((res) => {
      this.rowDatapending = res.response.filter((item) => item.isCancelled == 0);
      // this.pendingOrderLength = this.rowDatapending.length;
    });
  }


  onSelectionChanged(eventData) {
    this.selectedOrderId = eventData.data.orderId;
    let selectedOrderDetails = this.rowDatapending.filter(
      (availservice) => availservice['orderId'] == this.selectedOrderId
    );
    if (selectedOrderDetails[0]['itemData']) {
      this.OrderDetailsById = selectedOrderDetails[0]['itemData'];
      // this.rowDatapending=this.OrderDetailsById;
    }
    else {
      this.noRowsTemplate = "No Rows To Show"
      this.gridApi.hideOverlay();

    }


  }

  calculateTotalAmount() {
    this.totalCost = 0.00;
    _.forEach(this.targetProducts, (item: any) => {
      const unitPrice = Number(item.Rate);
      this.totalCost = (Number(this.totalCost) + Number(unitPrice)).toFixed(2);
    })
  }


}



