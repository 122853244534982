import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { GeneralGridActionComponent } from '../../../shared/general-grid-action/general-grid-action.component';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { Menu } from '../../client/interfaces/Menu';
import { UserpermissionService } from '../userpermission.service';
import { Submenu } from '../../client/interfaces/SubMenu';
//import("d:/GitOut/LeHealth-ANGULAR-UI/src/app/modules/client/interfaces/SubMenu").Submenu[]

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  modalRef?: BsModalRef;
  Submenus:Submenu[];
  menu: Menu;
  columnDefs = [];
  private gridOptions: GridOptions;
  public gridApi;
  private gridColumnApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public form: FormGroup;
  displayModal: boolean;
  displayModal1: boolean;
  supervisor = "";
  locationname = "";
  Mobilenumber = "";
  globals: globalvars = new globalvars();
  products: any = {};
  rowData: any = [];
  subscription: Subscription;
  selectedCities: any[] = [];
  statusBar: any;
  public selectoptions: any;
  @ViewChild('template') modaltemp:any
  constructor(
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private UserService:UserpermissionService
  ) {
    this.GetSubmenus();
    this.selectoptions = {
      multiple: true,
      closeOnSelect: false,
    };
    
    this.form = new FormGroup({
      menu: new FormControl(""),
      mid: new FormControl(0),
      menuIcon: new FormControl(""),
      link: new FormControl(""),
      menuAlias: new FormControl(''),
      subMenuFlag: new FormControl(0),
      submenuIds: new FormControl([])
    });
    this.columnDefs = [
      {
        headerName: "Menu",
        field: "menu",
        resizable: true,
        unSortIcon: true,
        flex: 3,
        sort: "asc",
      },
      {
        headerName: "Alias",
        field: "menuAlias",
        enableRowGroup: true,
        resizable: true,

        flex: 2,
      },
      {
        headerName: "Action",
        field: "mid",
        enableRowGroup: true,
        resizable: true,

        cellRenderer: "actionsComponentRenderer",
        cellRendererParams: {
            Page:'Menu'
         },
        flex: 2,
      },

      // ik
    ];
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    // this.valueDate =  this.datepipe.transform(new Date(),'yyyy-MM-dd');
    // let dte = new Date();
    // dte.setDate(dte.getDate() - 28);

    // this.valueDate2=this.datepipe.transform(dte.toString(),'yyyy-MM-dd');

    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };

    this.frameworkComponents = {
      actionsComponentRenderer: GeneralGridActionComponent,
    };
    this.context = { componentParent: this };
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  GetSubmenus() {
    this.UserService
      .GetSubmenus()
      .subscribe((res) => {
        this.Submenus = res.response;
      });
  }
  Save() {
    this.menu = this.form.getRawValue();
   
    this.UserService.SaveMenu(this.menu).subscribe((res) => {
      this.displayModal = false;
      this.form.reset();
      alert(res.response);
    });
  }
  Getmenu(id) {
    this.UserService.Getmenu(id).subscribe((res) => {

      this.menu = res.response;
      this.form.patchValue(this.menu);
      this.modalRef = this.modalService.show(this.modaltemp, Object.assign({}, { class: 'gray modal-lg' })  );
 
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  Getmenus() {
    //alert(JSON.stringify(this.globals));
    this.UserService
      .Getmenus()
      .subscribe((res) => {
        this.rowData = res.response;
      });
  }
 
  ngOnInit(): void {
      this.Getmenus();
    
  }
  showModalDialog() {
    this.displayModal = true;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'gray modal-lg' })  );
  }


}
