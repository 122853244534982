import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { EMRService } from "../../emr/services/emr.service";
import { ToastrService } from "ngx-toastr";
import { EMRPatientInfo } from "../interfaces/EMRPatientInfo";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { ConfigurationService } from "../../client/services/configuration.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { SharedService } from "../../../shared/shared.service";
import { Subscription } from "rxjs";
import { EmrPreference } from "../models/Preference.EMR";
import { Response } from "../../../_Models/Response";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { CopyEmrModalComponent } from "../modals/copy-emr-modal/copy-emr-modal.component";

@Component({
  selector: "app-emr-physiotherapy-view",
  templateUrl: "./emr-physiotherapy-view.component.html",
  styleUrls: ["./emr-physiotherapy-view.component.scss"],
})
export class EmrPhysiotherapyViewComponent implements OnInit {
  physiotForm: FormGroup;
  GetGaitAnalysisdata: [];
  Physiotherapist: any[] = [];
  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;
  public emrPreferenceLoading: boolean = false;
  IsViewed: any;
  public EmrPreference: EmrPreference;
  treatmentTab: boolean = true;
  summaryTab: boolean;

  GetPosturalAnalysisdata: [];
  GetPalpationdata: [];
  GetDeepTendonReflexdata: [];
  GetDeepSensationdata: [];
  GetSuperficialSensationdata: [];
  GetToneAssesmentdata: [];
  GetSkinAssesmentdata: [];
  GetAmputationdata: [];
  GetBodyBuiltdata: [];
  GetConsciousnessdata: [];
  GetMentalStatusdata: [];
  GetHandednessdata: [];
  GetTypeOfPaindata: [];
  GetPainDescriptiondata: [];
  GetSpasticitydata: [];
  pID: any;
  Selectedtab: string;

  public isLoading: boolean = false;
  public submitted: boolean = false;
  result: any;
  saveDataId: any;
  EMRPatientInfo: EMRPatientInfo;
  cId: any;
  tabId:number=0;
  conId:any;
  scrollTo: any;
  constructor(
    private EMRService: EMRService,
    private toastr: ToastrService,
    private router: Router,
    private configurationService: ConfigurationService,
    private sharedservice: SharedService,
    private emrService: EMRService,
    private modalService: BsModalService,

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    if (this.router.getCurrentNavigation().extras.state) {
      this.cId = this.router.getCurrentNavigation().extras.state.consultationId;
       this.scrollTo=this.router.getCurrentNavigation().extras.state.tab
       console.log(this.router.getCurrentNavigation().extras.state)
    }
    this.scrollTo=this.scrollTo ? this.scrollTo : 'General'
  }

  ngOnInit(): void {
    debugger
    this.Scrolltotab(this.scrollTo);
    this.EMRPatientInfo = this.emrService.GetEMRPatientInfo();
    console.log( this.EMRPatientInfo);
    this.getEMRPreference();

    //  this.Scrolltotab("Ortho");
    this.physiotForm = new FormGroup({
      consultationId: new FormControl(this.EMRPatientInfo.ConsultationId),
      physioTherapistId: new FormControl(0),
      informantReliability: new FormControl(""),
      PainDescription: new FormControl(0),
      siteOfPain: new FormControl(""),
      typeOfPain: new FormControl(0),
      onsetDuration: new FormControl(""),
      handedness: new FormControl(0),
      mentalStatus: new FormControl(0),
      painScale: new FormControl(""),
      levelOfConsciousness: new FormControl(0),
      bodyBuilt: new FormControl(0),
      amputation: new FormControl(0),
      skinAssesment: new FormControl(0),
      toneAssesment: new FormControl(0),
      gradeOfSpasticity: new FormControl(0),
      superficialSensation: new FormControl(0),
      sensoryAssesment: new FormControl(""),
      deepSensation: new FormControl(0),
      deepTendonReflexGrading: new FormControl(0),
      palpation: new FormControl(0),
      reflexTesting: new FormControl(""),
      posturalAnalysis: new FormControl(0),
      gaitAnalysis: new FormControl(0),
      ActivityOfDailyLiving: new FormControl(""),
      GeneralDataOne: new FormControl(""),
      GeneralDataTwo: new FormControl(""),
      GeneralDataThree: new FormControl(""),
      GeneralDataFour: new FormControl(""),
      GeneralDataFive: new FormControl(""),
    });
  }
  onTab(number) {
    this.treatmentTab = false;
    // this.treatmentdetailTab = false;
    // this.rangeofmotionTab = false;
    this.summaryTab = false;
    if (number == "1") {
      this.treatmentTab = true;
    } else if (number == "4") {
      this.summaryTab = true;
      let id = this.cId
      this.router.navigate(['/physiothearapy-summary'], { state: { id: id } });

    }
  }


  Scrolltotab(elem) {
    this.Selectedtab = elem;
  }
  get f() {
    return this.physiotForm.controls;
  }
  GetAllStaff() {
    this.configurationService
      .GetStaff(this.globals.HospitalId)
      .subscribe((res) => {
        let StaffData = res.response;
        this.Physiotherapist = StaffData.filter((x) => x.categoryId === 7);
      });
  }
  Activetab(pagetitle) {
    let consultationId = Number(localStorage.getItem("consultationId"));

    sessionStorage.setItem("pageTitle", pagetitle);
    this.router.navigate(["/physiothearapy-treatment"], {
      state: { consultationId: consultationId },
    });
  }
  getEMRPreference() {
    
    let payload = {
      ConsultantId:this.EMRPatientInfo.ConsultantId,
      BranchId: this.globals.HospitalId,
    };
    this.emrPreferenceLoading = true;
    this.emrService.getCFormOrder(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200) {
          this.EmrPreference = {
            singlePageView: response.response[0].emrPageView,
            tabOrders: response.response,
          };
        } else {
          this.toastr.error(response.errorMessage.message);
        }
        this.emrPreferenceLoading = false;
      },
      error: (error: any) => {
        this.emrPreferenceLoading = false;
      },
    });
  }
  goback(){
    let consultationId = Number(localStorage.getItem("consultationId"));

    this.router.navigate(["/physiothearapy-treatment"], {
      state: { consultationId: consultationId },
    });

  }
  copyEmrModal() {

    let initialState = {
      EmrPatientInfo: this.EMRPatientInfo,
      branchId: this.globals.HospitalId,
    };
    
    let modalConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-scrollable",
    };
    const copyModalRef = this.modalService.show(
      CopyEmrModalComponent,
      Object.assign({ class: "modal-dialog-centered " }, modalConfig, {
        class: "modal-md",
        initialState,
      })
    );
    copyModalRef.content.copyEmitter.subscribe((res) => {
      if (res.status) {
        this.getEMRPreference();
        // this.conId= Number(localStorage.getItem("emrPhyConsultationID"));

      }
    });

  }
getvisirt(){
  if(this.EMRPatientInfo.VisitId>0){
  }else{
    this.toastr.warning("No data Availbale for today's consultation")

  }
}

}
