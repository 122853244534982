import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import * as customEditor from '../../../../../ckeditor/build/ckeditor';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { Response } from '../../../_Models/Response';
import { ConfigurationService } from '../../client/services/configuration.service';

@Component({
  selector: 'app-add-edit-template',
  templateUrl: './add-edit-template.component.html',
  styleUrls: ['./add-edit-template.component.scss']
})
export class AddEditTemplateComponent implements OnInit,OnDestroy {

  public Editor = customEditor;
  public data: string = '';
  public templateForm: FormGroup;

  public saveTemplateLoading: boolean = false;
  public fetchTemplateLoading: boolean = false;
  private globals: globalvars = new globalvars();

  public config = {
    placeholder : 'type here..'
  }

  private subscription: Subscription;

  constructor(private formBuilder: FormBuilder,
              private configService: ConfigurationService,
              private toastrService: ToastrService,
              private sharedService: SharedService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {

    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });

    this.initTemplateForm();

    this.activatedRoute.params.subscribe(param=>{
      let id = Number(param['templateId']);
      if(id && id != 0){
        this.getTemplatebyId(id)
      }
    })
  }

  onReady(editor) {
    editor.ui.height=30;
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  initTemplateForm(){
    this.templateForm = this.formBuilder.group({
      templateId: new FormControl(0, Validators.required),
      templateName: new FormControl('', Validators.required),
      template: new FormControl('', Validators.required),
      isDisplayed: new FormControl(true, Validators.required),
      branchId: new FormControl(this.globals.HospitalId)
    })
  }

  getTemplatebyId(templateId : number){
    let payload = {
      Id: templateId,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.fetchTemplateLoading = true;
    this.configService.getReportWorklistTemplate(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.templateForm.patchValue({
            templateId: response.response[0]?.id,
            templateName: response.response[0]?.templateName,
            template: response.response[0]?.templateData,
            isDisplayed: response.response[0]?.isDisplayed,
          })
        }else{
          this.toastrService.error(response.message);
        }
        this.fetchTemplateLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong, Try again.');
        this.fetchTemplateLoading = false;
      }
    })
  }

  onShowPreview(){
    if(!this.saveTemplateLoading && !this.fetchTemplateLoading){
      let printContents, popupWin;
      printContents = this.templateForm.get('template').value;
      console.log(printContents)
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print tab</title>
          </head>
          <style>
              table, td, th {
                border: 1px solid;
              }
              
              table {
                width: 100%;
                border-collapse: collapse;
              }
          </style>
          <body>${printContents}</body>

          <script>
            window.onload = function() {
              setTimeout(() => {
                window.print();
                window.close();
              }, 100);      
            };
          </script>

        </html>`
      );
      popupWin.document.close();
    }
  }

  saveTemplate(){
    if(!this.saveTemplateLoading && !this.fetchTemplateLoading){
      const controls = this.templateForm.controls;
      if(this.templateForm.invalid){
        Object.keys(controls).forEach(control=>{
          controls[control].markAllAsTouched();
        });
        return
      }

      let payload = {
        Id: Number(this.templateForm.get('templateId').value),
        TemplateName : this.templateForm.get('templateName').value,
        TemplateData : this.templateForm.get('template').value,
        IsDisplayed: this.templateForm.get('isDisplayed').value,
        IsDeleting : 0,
        BranchId : Number(this.templateForm.get('branchId').value)
      }
      this.saveTemplateLoading = true;
      this.configService.saveReportWorkListTemplate(payload).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200 && response.message == 'Success'){
            this.toastrService.success('Template saved successfully.');
            this.router.navigate(['/config/ReportWorkList'], {replaceUrl: true});
          }else{
            this.toastrService.error(response.message);
          }
          this.saveTemplateLoading = false;
        },error: (error: any)=>{
          this.toastrService.error('Something wents wrong.. Try again.');
          this.saveTemplateLoading = false;
        }
      })
    }
  }

  goBack(){
    if(!this.saveTemplateLoading && !this.fetchTemplateLoading){
      this.router.navigate(['/config/ReportWorkList'], {replaceUrl: true});
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
