import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingService } from '../../services/billing.service';
import { SponsorList } from '../../../client/interfaces/SponsorList';
import { ColDef, GridApi } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Response } from '../../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-consultation-credit-modal',
  templateUrl: './change-consultation-credit-modal.component.html',
  styleUrls: ['./change-consultation-credit-modal.component.scss']
})
export class ChangeConsultationCreditModalComponent implements OnInit {

  @Input() branchesId;
  @Input() patientId;
  @Input() rowData;
  sponsorList: SponsorList[] = [];
  columnDefs: ColDef[] = [];
  public gridApi: GridApi;
  @Output() creditChange = new EventEmitter();
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.expired === true; },
    },
  };

  constructor(private billingService: BillingService,
              private modalRef: BsModalRef,
              private toastrService: ToastrService) { }


  ngOnInit(): void {
    this.getCreditData();
    this.columnDefs = [
      {
        headerName: "Credits",
        field: "empty",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        maxWidth: 80,
        checkboxSelection:  params => {
          // Check if selection should be prevented for this row
          if (params.data.expired == true) {
            return false; // Disable checkbox selection
          }
          return true; // Enable checkbox selection
        },
        filter: true,
        cellRenderer: params => {
          if(params.data.creditId === this.rowData.creditId) {
            params.node.setSelected(true)
          }
        //   else if (params.data.expired !== true) {
        //     const api = params.api;
        //     const rowCount = api.getDisplayedRowCount();
        //     for (let i = 0; i < rowCount; i++) {
        //         const rowNode = api.getDisplayedRowAtIndex(i);
        //         const rowData = rowNode.data;
        //         if (rowData.expired !== true) {
        //             rowNode.setSelected(true);
        //             break; // Select the first matching row and exit the loop
        //         }
        //     }
        // }
          return null
        },
      },
      {
        headerName: "Open Date",
        minWidth: 100,
        field: "openDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Credit Ref No.",
        minWidth: 100,
        field: "refNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Insurance Name",
        minWidth: 100,
        field: "sponsorName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "TPA",
        minWidth: 100,
        field: "agentName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Policy Number",
        minWidth: 100,
        field: "policyNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Valid Upto",
        minWidth: 100,
        field: "validUpto",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
    ];
  }

  getCreditData() {
    let payload = {
      PatientId: this.patientId,
      Branchid: this.branchesId,
      ShowExpired: 1
    }
    this.billingService.GetSponsorDetailsByPatient(payload).subscribe((res) => {
      this.sponsorList = res.response;
    });
  }

  close() {
    this.modalRef.hide();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  save() {
    const newCredit = this.gridApi.getSelectedRows();
    let payload = {
      EntryId: 0,
      ConsultationId: this.rowData.consultationId,
      CreditId: newCredit[0].creditId
    }
    this.billingService.saveConsultationCredits(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.toastrService.success('Credit details updated successfully');
          this.creditChange.emit({isChanged: true});
          this.modalRef.hide();
        }else{
          this.toastrService.error(response.message);
        }
      },error: (error:any)=>{
        this.toastrService.error('Something wents wrong, Try again');
      }
    });
  }

}
