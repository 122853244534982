import { Component, OnInit,TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Consultants } from '../../interfaces/Consultants';
import { ConsultationsList } from '../../interfaces/ConsultationsList';
import { Departments } from '../../interfaces/Departments';
import { UserHospitals } from '../../interfaces/UserHospitals';
import { ConsultaionsService } from '../../services/consultations.service';
import { HospitalsService } from '../../services/hospitals.service';
import { EMRService } from '../../../emr/services/emr.service';
import { TreatmentsService } from '../../../treatment/services/treatments.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../general-action/general-action.component';
import { GuiCellView, GuiColumn, GuiPaging, GuiPagingDisplay, GuiSearching, GuiSorting, GuiColumnMenu, GuiInfoPanel } from '@generic-ui/ngx-grid';
import { Select2OptionData } from 'ng-select2';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../shared/shared.service';
import { EMRPatientInfo } from '../../../emr/interfaces/EMRPatientInfo';
import { SponsorConsentComponent } from '../modals/sponsor-consent/sponsor-consent.component';
import { AttachmentModalComponent } from '../modals/attachment-modal/attachment-modal.component';
import { log } from 'console';
import { ChangeConsultationCreditModalComponent } from '../../../billing-settlement/modals/change-consultation-credit-modal/change-consultation-credit-modal.component';
import { ConfigurationService } from '../../services/configuration.service';
import { HealthSummaryModalComponent } from '../../../treatment/modals/health-summary-modal/health-summary-modal.component';
import { TelerikPrintComponent } from '../../../../shared/modals/telerik-print/telerik-print.component';
import { SettingsService } from '../../../settings/services/settings.service';
import { Response } from '../../../../_Models/Response';
import { Settings } from '../../../settings/model/settings.model';
import _ from 'lodash';
import { StaticPatientConsentComponent } from '../modals/static-patient-consent/static-patient-consent.component';
import { PatientService } from '../../services/patient.service';
import { NabithConsentComponent } from '../addregistration/nabith-consent/nabith-consent.component';
import { InformedStaticConsentComponent } from '../modals/informed-static-consent/informed-static-consent.component';
import { RiayatiConsentComponent } from '../addregistration/riayati-consent/riayati-consent.component';
@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss']
})
export class ConsultationComponent implements OnInit {
  products: any = {};
  items: any = {};
  update: any = {};
  delete: any = {};
  consultations: ConsultationsList[] = [];
  editdata: any = {};
  hospitalList: UserHospitals[] = [];
  departments: any;
  departmentsData: Array<Select2OptionData> = [];
  dropdownSettings: IDropdownSettings = {};
  patientInfo: EMRPatientInfo;
  consultants: Consultants[] = [];
  consultantsData: Array<Select2OptionData> = [];
  searchForm: FormGroup
  currentDate = new Date();
  posts = [];
  dtOptions: any = {};
  info: string;
  valueDate: any;
  valueDate2: any;
  blockorunblock: boolean;
  confirmblock: boolean;
  active: string;
  ReasonVisit: boolean;
  treatmentDetail: boolean;
  treatmentList:any[]=[];
  patId: number = 0;

  public show: boolean;
  isCollapsed = true;
  minDate: Date;
  globals: globalvars = new globalvars();
  subscription: Subscription;

  columns: Array<GuiColumn> = [
    {
      header: 'Consult Date',
      field: 'consultDate',
      view: GuiCellView.CHIP
    },
    {
      header: 'Consultant',
      field: 'consultant'
    },
    {
      header: 'Consult Type',
      field: 'consultType2'
    },
    {
      header: 'Visit',
      field: 'otherReasonForVisit'
    },
    {
      header: 'RegNo',
      field: 'regNo'
    },
    {
      header: 'Patient Name',
      field: 'patientName'
    },
    {
      header: 'Status',
      field: 'status'
    },
    {
      header: 'Sponsor',
      field: 'sponsor'
    },
    {
      header: 'Mobile',
      field: 'mobile'
    },
    {
      header: 'Pin',
      field: 'pin'
    },
    {
      header: 'Address',
      field: 'address'
    },

    {
      header: 'Reason',
      field: 'reason'
    },
    {
      header: 'Actions',
      field: '',
      view: (cellValue: any) => {


        let template = `

        <div class="gui-user-info" >
          <span class="fa fa-times gui-user-info-name icon" style="color:red" title="Cancel Consultations" ></span>
          <span class="gui-user-info-position" ></span>

        </div>
      `;

        return template;
      }
    }
  ];


  paging: GuiPaging = {
    enabled: true,
    page: 1,
    pageSize: 10,
    pageSizes: [10, 25, 50],
    pagerTop: false,
    pagerBottom: true,
    display: GuiPagingDisplay.ADVANCED
  };

  sorting: GuiSorting = {
    enabled: true
  };

  searching: GuiSearching = {
    enabled: true,
    placeholder: 'Search Here'
  };
  GuiColumnMenu = {
    enabled: true,
    columnsManager: true
  };

  infoPanel: GuiInfoPanel = {
    enabled: true,
    infoDialog: false,
    columnsManager: true,

  };
  rowData: ConsultationsList[] = [];

  //ag grid setup
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.status == 'Cancelled'; },
      'c-completed': function (params) { return params.data.status == 'Finished'; },
    },
  }
  public statusBar;

  columnDefs: ColDef[] = [];
  rowGroupPanelShow = "always";
  blockId: number;
  CancelledReason: any;
  reasonDataReason: any;
  OtherSymptom: any;
  symptomList: any[];
  ConsultationId: string;
  result: any;
  consultationId: any;
  symptomDetails: ConsultationsList[];
  BranchesId: any;
  modalRef: BsModalRef;

  private changeCreditModalRef?: BsModalRef;
  functionPermissionData: any;
  public consentSettings: Settings[] = [];
  healthPlatform: any;

  constructor(private consultationService: ConsultaionsService,
    public datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private hospitalsService: HospitalsService,
    private toastr: ToastrService,
    private router: Router,
    private sharedservice: SharedService,
    private EMRService: EMRService,
    private TreatmentsService: TreatmentsService,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,
    private settingsService: SettingsService,
    private patientService: PatientService,
    private datePipe: DatePipe,
  ) {
    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    this.columnDefs = [
      {
        headerName: "Consult Date",
        minWidth:115,
        field: "consultDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,

      },
      {
        headerName: "Consultant",
        minWidth:130,
        field: 'consultant',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },
      {
        headerName: "Consult Type",
        minWidth:115,
        field: 'consultType2',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Time",
        minWidth:80,
        field: 'consultationTime',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Visit",
        minWidth:100,
        field: 'visit',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 1.5,

      },
      {
        headerName: "Reg.No.",
        minWidth:130,
        field: 'regNo',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Patient Name",
        minWidth:150,
        field: 'patientName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },
      {
        headerName: "Status",
        minWidth:75,
        field: 'status',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Insurance",
        minWidth:95,
        field: 'sponsorTwo',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },
      {
        headerName: "Mobile",
        minWidth:180,
        field: 'mobile',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Pin",
        minWidth:100,
        field: 'pin',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Address",
        minWidth:180,
        field: 'address',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },


      // {
      //   headerName: "Reason",
      //   field: 'reason',
      //   sortable: true,
      //   filter: true,
      //   enableRowGroup: true,
      //   resizable: true,
      //   flex: 2,
      //   comparator: customComparator
      // },
      {
        headerName: "Action",
        maxWidth:80,
        pinned:"right",
        filter: false,
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "ConsultationList" },
      },
    ];
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    // this.valueDate =  this.datepipe.transform(new Date(),'yyyy-MM-dd');
    // let dte = new Date();
    // dte.setDate(dte.getDate() - 28);

    // this.valueDate2=this.datepipe.transform(dte.toString(),'yyyy-MM-dd');

    this.statusBar = {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'center',
        },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' },
      ],
    };

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });
    this.getApplicationSettings();

  }

  ngOnInit(): void {
    this.minDate = new Date();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'symptomId',
      textField: 'newsymDesc',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true
    };



    this.valueDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    let dte = new Date();
    dte.setDate(dte.getDate() - 7);
    this.valueDate2 = this.datepipe.transform(dte.toString(), 'dd-MM-yyyy');
    //this.valueDate2=new Date(this.valueDate.getMonth(), 1);
    this.rowData = [];
    this.GetUserHospitals();
    this.GetDepartments();
    this.searchForm = this.formBuilder.group({
      sName: new FormControl(''),
      sMobile: new FormControl(''),
      sRegNo: new FormControl(''),
      sAddress: new FormControl(''),
      sconsultant: new FormControl(''),
      sPin: new FormControl(''),
      sDate: new FormControl(''),
      sBranch: this.globals.HospitalId,
      sDepartment: new FormControl(''),
      sDateFrom: new FormControl(this.valueDate2),
      sDateTo: new FormControl(this.valueDate),
      remarks: new FormControl(''),
      blockReason: new FormControl(''),
      reasonDataReason: new FormControl(''),
      CancelledReason: new FormControl(''),
      Symptoms: new FormControl(),
      otherreasonforvisit: new FormControl(''),
      sEmiratesNo:new FormControl(),
    })


    this.SearchConsultation();
   // this.LoadConsultationTable();
    //this.GetTemplateReasonForVisit();
    this.functionPermission();
  }
  //get branches initialy load hospital list
  GetUserHospitals() {
    this.hospitalsService.GetUserHospitals().subscribe((res) => {
      this.hospitalList = res.response;

    });
  }
  //getting departments and consultants
  GetDepartments() {
    let ShowAll = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.departments = res.response;

      this.departmentsData = [];
      this.departmentsData.push({ id: "0", text: "Select All" });
      this.departments.forEach(a => {
        this.departmentsData.push({ id: a.deptId.toString(), text: a.deptName });
      });

    });
  }
  loadConsultant(DepartmentId: any) {
    //this.consultants=null;
    this.searchForm.patchValue({ sConsultant: '' })
    let deptId = Number(DepartmentId);
    this.hospitalsService.GetConsultant(deptId).subscribe((res) => {
      this.consultants = res.response;
      this.consultantsData = [];
      this.consultants.forEach(a => {
        this.consultantsData.push({ id: a.consultantId.toString(), text: a.consultantName });
      });
    })
  }

  // page title to be displayed in header
  Activetab(pagetitle) {
    const foundElement = this.functionPermissionData.find(x => x.id === 216)
    if (foundElement.id == 216 && foundElement.isAllowed == true) {
      this.router.navigate(["/AddConsultation"]);
    sessionStorage.setItem("pageTitle", pagetitle);
    } else {
      this.toastr.warning("You are not allowed to add consultation");
    }
  }
  LoadConsultationTable() {

    //   this.searchForm.patchValue({sDateFrom:this.valueDate})
    //   this.searchForm.patchValue({sDateTo:this.valueDate2});
    //   this.SearchConsultation();
    // this.consultationService.GetAllConsultation().subscribe((res) => {
    //   this.consultations=res.response;
    //   this.rowData=this.consultations;
    // })

    this.SearchConsultation();
  }
  SearchConsultation() {
    let StartDateObj = this.searchForm.get("sDateFrom").value
    let EndDateObj = this.searchForm.get("sDateTo").value

    if (typeof (StartDateObj) == "object") {

      let startDateFrom = this.datepipe.transform(StartDateObj, 'dd-MM-yyyy');
      // this.searchForm.value.sDateFrom=startDateFrom;
      this.searchForm.patchValue({ sDateFrom: startDateFrom })

    }
    if (typeof (EndDateObj) == "object") {

      let DateTo = this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      //this.searchForm.value.sDateTo=DateTo;
      this.searchForm.patchValue({ sDateTo: DateTo })
    }


    this.consultationService.SearchConsultation(this.searchForm, 0).subscribe((res) => {
      this.consultations = res.response;
      this.rowData = this.consultations;
      if (this.consultations.length == 0) {
        this.toastr.warning("No consultations found");
      }
    })
    // changing start date and end date back to date obj after search api
    this.searchForm.patchValue({ sDateFrom: StartDateObj });
    this.searchForm.patchValue({ sDateTo: EndDateObj });
  }



  getData(consultation) {
    this.editdata = consultation;

  }
  //datepicker shows till todays date
  getToday(): string {

    return new Date().toISOString().split('T')[0]
  }


  // Consulation cancel confirmation modal
  showBlockUnblock(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 217)
    if (foundElement.id == 217 && foundElement.isAllowed == true) {
    this.rowData;
    let appointmentsData = this.rowData.filter((consultantdata) => consultantdata['consultationId'] == id);
    this.searchForm.patchValue({ blockReason: '' })
    this.active = (id.status == 'Waiting') ? "Cancel" : "Cancelled"
    this.blockId = Number(id.consultationId);
    this.patId = Number(id.patientId);
    this.blockorunblock = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }


  // Consulation cancel confirmation is true then ask reason
  confirmblocking() {

    if (this.active == "Cancelled") {
      // this.ConfirmUnBlock();
    }
    else {
      this.confirmblock = true;

    }
  }

  // Function to cancel consultation with reason
  ConfirmBlock() {

    let blockReason = this.searchForm.get("blockReason").value;
    this.consultationService.CancelConsultation(this.blockId, blockReason).subscribe((res) => {
      this.LoadConsultationTable();
      // console.log('res', res);
      let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
      if (res.status == 200 && res.message == 'success') {
        this.toastr.success("Cancel successfull");
        if (this.healthPlatform.settValue === "Nabidh") {
        const foundElement = this.functionPermissionData.find(x => x.id === 563)
        if (foundElement.id == 563 && foundElement.isAllowed == true) {
        let nabPayload = {
          EventId: 5,
          Consultationid: this.blockId,
          EntryDate: currentDate,
          PatientId: this.patId,
          BranchId : this.globals.HospitalId
        }
        this.patientService.SendDetailstoNabith(nabPayload).subscribe((res) => {
          this.toastr.info('Response from Nabith:' + res);
        })
      } else {
        this.toastr.warning("No permission to send data to nabith");
      }
      }
      }
    })
    this.confirmblock = false;
  }


  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  ReasonForVisitModal(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 223)
    if (foundElement.id == 223 && foundElement.isAllowed == true) {
      this.searchForm.patchValue({ Symptoms: [] });
      this.searchForm.patchValue({ otherreasonforvisit: "" });
    this.consultationId = id.consultationId;
    this.GetTemplateReasonForVisit();
    this.consultationService.Getsymptoms(this.consultationId).subscribe((res) => {
      this.symptomDetails = res.response;
      this.searchForm.patchValue({ Symptoms: this.symptomDetails['symptoms'] });
      if (this.symptomDetails['reasonForVisit']) {
        this.OtherSymptom = true;
        this.searchForm.patchValue({ otherreasonforvisit: this.symptomDetails['reasonForVisit'] });
      }
      document.getElementById("myDiv").click();
    })

    this.ReasonVisit = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }


  ReasonForCancellation(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 224)
    if (foundElement.id == 224 && foundElement.isAllowed == true) {
    this.rowData;
    let reasonData = this.rowData.filter((consultantdata) => consultantdata['consultationId'] == id.consultationId);
    this.CancelledReason = true;
    this.CancelledReason = reasonData[0]['cancelReason'];
    this.searchForm.patchValue({ CancelledReason: this.CancelledReason });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  // //Getting teplates for symtoms and reason for visit
  GetTemplateReasonForVisit() {
    let ShowAll = 0;
    this.consultationService.GetTemplateReasonForvisit(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.symptomList = res.response;

      // let syptomReasonList = [];
      // syptomReasonList.push({
      //   SymptomId: 0,
      //   SymptomDesc: "None Of the Above",
      //   Active: 1,
      //   BlockReason: "",
      // });
      // this.symptomList = syptomReasonList.concat(this.symptomList);
      this.symptomList.forEach((element) => {
        element["newsymDesc"] = element["SymptomDesc"] ? element["SymptomDesc"] : element["symptomDesc"];
      });
    });
  }
  //symptom none of the above div hide and show
  // SymptomReason() {
  //   let val = this.searchForm.get('Symptoms').value;

  //   if (val.length > 0) {
  //     if (val[0]['newsymDesc'] == 'None Of the Above') {

  //       this.OtherSymptom = true;
  //     }
  //     else {

  //       this.OtherSymptom = false;
  //     }
  //   }
  //   else {
  //     this.OtherSymptom = false;
  //   }
  // }
  //updating reason for visit
  SaveReasonForVisit(id) {

    var AppData = {
      ConsultationId: this.consultationId,
      Symptoms: this.searchForm.get("Symptoms").value,

      otherreasonforvisit: this.searchForm.get("otherreasonforvisit").value,

    };

    this.consultationService.SaveReasonVisit(AppData).subscribe((res) => {
      this.result = res;

      if (this.result.status == 200) {


        this.toastr.success("Reason For Visit added successfully");

      } else {

        this.toastr.error("Reason For Visit failed");
      }
    });


  }


  fromdatechange(event) {
    let StartDateObj = this.searchForm.get("sDateFrom").value;
    this.minDate = StartDateObj;
  }

  //common search event for search modal popup
  searchevent($event) {
    //let ConsultantId=Number(this.searchForm.get("sconsultant").value);
    this.consultationService.SearchConsultation($event, 0).subscribe((res) => {
      this.consultations = res.response;
      this.rowData = this.consultations;
      if (this.consultations.length == 0) {
        this.toastr.warning("No consultations found");
      }
    })
  }
  GoToEmr(patientData)
  {
    const foundElement = this.functionPermissionData.find(x => x.id === 219)
    if (foundElement.id == 219 && foundElement.isAllowed == true) {
    this.patientInfo={
      'VisitId':0,
      'ConsultantId':Number(localStorage.getItem('Id')),
      'ConsultationId':0,
      'lastConsultationId': patientData.consultationId,
      'PatientId':patientData.patientId,
      'VisitType':0,
      'VisitStartTime':'',
      'VisitEndTime':'',
      'UserId':Number(localStorage.getItem('user_id')),
      'CrudType':0,
      InsAmountLimit: 0,
      insCovered: 0,
      sFormId:patientData.sFormId,
      telerikName:patientData.telerikName
    }
    let patInfo = {
      ConsultationId: patientData.consultationId,
      SponsorId: patientData.sponsorId,
      telerikName:patientData.telerikName
    }
    localStorage.setItem('asoapPatInfo', JSON.stringify(patInfo));

    let OutPut = this.EMRService.SetEMRPatientInfo(this.patientInfo);
    if (OutPut) {
      this.router.navigate(['/EMR_Home'],{state:{patientDatas:true}});
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  openCredit(data) {

    let input={
      patientId:data.patientId,
      creditId:0,
      patientName:data.patientName,
      regNo:data.regNo
   }
   this.router.navigate(['/OpenCredit'], { state: { editData: input } });
  }
  
  
  treatmentDetails(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 218)
    if (foundElement.id == 218 && foundElement.isAllowed == true) {

      let initialState = {
        modalHeading: 'Treatment Summary',
        reportName: 'TreatmentSummary.trdp',
        reportParams: {
          PatientId : data.patientId,
          FromDate : (data?.consultDate.slice(3, 5) +'-'+data?.consultDate.slice(0,2) + '-' + data?.consultDate.slice(6, 10)),
          ToDate: (data?.consultDate.slice(3, 5) +'-'+data?.consultDate.slice(0,2) + '-' + data?.consultDate.slice(6, 10)),
          ConsultationId : data?.consultationId
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))

//     this.treatmentDetail = true;

//   let pID=data.patientId
//    let input={
//   "branchId":Number(this.globals.HospitalId),
//   "PatientId":Number(pID),
//   "dateFrom":"",
//   "dateTo":"",
//   "TreatmentNumber":"",
//   "id":0,
//   "PatientName":"",
//   "mobile":"",
//   "regNo":""
// }
// this.TreatmentsService.GetTreatments(input).subscribe(
//   (res) => {
//     if (res.status == 200) {

//       this.treatmentList = res.response;
//       if (this.treatmentList.length == 0) {
//         this.toastr.warning("No Treatment Details");
//       }

//     }
//   });
//   this.treatmentDetail = true;
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  SignConsent(data,type, actionId){
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    if(type == 'SC' && data.sponsorId == 0){
      this.toastr.warning("Patient doesn't have sponsor..!!");
      return
    }

    if(type == 'SC' && data.consentStatus == 0){
      this.toastr.warning("Sponsor doesn't have consent..!!");
      return
    }

    // console.log('consentSettings', this.consentSettings);
    if (this.consentSettings[1].settValue == '0' && type == 'PC') {
      let initialState = {
        rowData: data
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(StaticPatientConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    } else if (this.consentSettings[0].settValue == '0' && type == 'IC') {
      let initialState = {
        ConsentPatientId: data.patientId,
        cType: type,
        data:data,
        branchId: this.globals.HospitalId,
        consultationId: data.consultationId
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(InformedStaticConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    } else {

      let initialState = {
        ConsentPatientId: data.patientId,
        cType: type,
        SelectedSponsor: data.sponsorId,
        data:data
      }
      console.log(initialState)
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(SponsorConsentComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  attachments(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 228)
    if (foundElement.id == 228 && foundElement.isAllowed == true) {
    let initialState = {
      rowDatas: data
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(AttachmentModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  changeConsultationCredit(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 222)
    if (foundElement.id == 222 && foundElement.isAllowed == true) {
      let initialState = {
        branchesId: this.BranchesId,
        patientId: data.patientId,
        rowData: data
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.changeCreditModalRef = this.modalService.show(ChangeConsultationCreditModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
      this.changeCreditModalRef.content.creditChange.subscribe(res => {
        if (res.isChanged) {
          this.SearchConsultation();
        }
      })
    } else {
      this.toastr.warning("You are not allowed to change credit");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }

  showHealthSummary(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 522)
    if (foundElement.id == 522 && foundElement.isAllowed == true) {
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      patientId: data.patientId,
      branchId: this.globals.HospitalId
    }
    const modalRef = this.modalService.show(HealthSummaryModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }));
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  PrintStickerFormat(data, type) {
    let actionId;
    // if(type == "Print") {
    //   actionId = 220;
    // } else 
    if(type == "ClaimForm") {
      actionId = 221;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
      // need to write the functionalities inside this if condition(This is for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  getApplicationSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }

    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.consentSettings = _.filter(response.response, {tabId: 5});
          this.healthPlatform=response.response.find(item => item.settId == 25)
          console.log('consentSettings', this.consentSettings);
        }
      }
    })
  }
  GoToApplyPhysiotherapy(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 579)
    if (foundElement?.id == 579 && foundElement.isAllowed == true) {
    this.patientInfo = {
      'VisitId':data.visitId,
      'ConsultantId':data.consultantId,
      'ConsultationId': data.consultationId,
      'lastConsultationId': data.consultationId,
      'PatientId': data.patientId,
      'VisitType': 0,
      'VisitStartTime': '',
      'VisitEndTime': '',
      'UserId': Number(localStorage.getItem('user_id')),
      'CrudType': 0,
      InsAmountLimit: 0,
      insCovered: 0
    }
    localStorage.setItem("emrPhyConsultationID", JSON.stringify(data.consultationId));

    let OutPut = this.EMRService.SetEMRPatientInfo(this.patientInfo);


    this.router.navigate(['/physiothearapy-treatment'], { state: { consultationId: data.consultationId,id: data.patientId,consultantId:data.consultantId,visitId:data.visitId } } );

  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  nabithConsent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 580)
    if (foundElement?.id == 580 && foundElement.isAllowed == true) {
    if (this.healthPlatform.settValue === "Nabidh") {
    let initialState = {
      patientId: data.patientId,
      ConsultationId: data.consultationId,
      action: 1,
      formName:this.healthPlatform.settValue
    }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(NabithConsentComponent,Object.assign( {}, config, { class: 'modal-xl', initialState }) );
    this.modalRef.content.fileEmitter.subscribe((response: any)=>{
      if(response.status == true){
        // this.toastr.success("The signature updated successfully");
      }
    })
    } else if (this.healthPlatform.settValue === "Riayati") {
      let initialState = {
        patientId: data.patientId,
        ConsultationId: data.consultationId,
      }

      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.modalRef = this.modalService.show(RiayatiConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
      this.modalRef.content.fileEmitter.subscribe((response: any) => {
        if (response.status == true) {
          // this.toastr.success("The signature updated successfully");
        }
      })
    }
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  editCredit(data){
    
    const foundElement = this.functionPermissionData.find(x => x.id === 537)
    if (foundElement?.id == 537 && foundElement.isAllowed == true) {
   let input={
       patientId:data.patientId,
       creditId:data.creditId,
       patientName:data.patientName,
       regNo:data.regNo
    }
    // debugger
    this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
}
