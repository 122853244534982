import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FormValidationService } from '../../client/services/form-validation.service';
// import { ActiveFilterMenuTriggerDirective } from '@generic-ui/ngx-grid/structure/source/feature/counter/filter/active-filter-menu-trigger.directive';
// import { ProfessionList } from '../../interfaces/ProfessionList';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Select2OptionData } from 'ng-select2';
import { ProfessionService } from '../../client/services/profession.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../client/services/configuration.service';
@Component({
    selector: 'app-tax',
    templateUrl: './tax.component.html',
    styleUrls: ['./tax.component.scss']
})
export class TaxComponent implements OnInit {

    modalRef?: BsModalRef;
    @ViewChild("template") modaltemp: any;
    products: any = {};
    displayModal: boolean;
    displayModal1: boolean;
    public submitted: boolean = false;
    result: any;
    subscription: Subscription;
    globals: globalvars = new globalvars();
    BranchesId: any;
    oppid: any;
    public statusBar;
    rowGroupPanelShow = "always";
    //ag grid setup
    public gridApi;
    columnDefs: ColDef[] = [];
    public rowSelection = true;
    public defaultColDef: ColDef;
    public context;
    public frameworkComponents;
    public imageComponents;
    public noRowsTemplate;
    public loadingTemplate;
    public gridOptions = {
        pagination: true,
        paginationPageSize: 10,
        rowClassRules: {
            'c-reds': function (params) { return params.data.active == '0'; },
            'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
        },
    }
    rowData: any;
    unlistedCode: any;
    TaxForm: FormGroup;
    TaxId: any;
    taxDatas: any;
    ledgers: any;
    ledgerData: Array<Select2OptionData> = [];
    departments: any;
    isLoading=false;
    TaxPopupHeading:String="Add Tax";
    functionPermissionData: any;

    constructor(private formBuilder: FormBuilder,
        private ngxLoader: NgxUiLoaderService,
        private toastr: ToastrService,
        private formValidationService: FormValidationService,
        private sharedservice: SharedService,
        private ConfigurationService: ConfigurationService,
        private modalService: BsModalService
    ) {
        this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
            if (this.sharedservice.checkglobals(glob)) {
                this.globals = glob;
                this.BranchesId = this.globals.HospitalId
            }
        });
    }
    ngOnInit(): void {
        this.TaxForm = new FormGroup({
            taxName: new FormControl('', [Validators.required]),
            taxPercent: new FormControl('', [Validators.required, Validators.max(100)]),
            taxLedger: new FormControl('', [Validators.required]),
            isDisplayed: new FormControl(true),
        });
        this.GetAllTax();
        this.GetLedger();
        this.functionPermission();
        this.columnDefs = [
            {
                headerName: "Tax Name",
                field: "taxDesc",
                minWidth:180,
                enableRowGroup: true,
                resizable: true,
                sortable: true,
                flex: 2,
                editable: false
            },
            {
                headerName: "Percentage",
                field: "taxPcnt",
                minWidth:180,
                enableRowGroup: true,
                resizable: true,
                sortable: true,
                flex: 2,
                editable: false
            },
            {
                headerName: "Head",
                field: "headDesc",
                minWidth:180,
                enableRowGroup: true,
                resizable: false,
                sortable: true,
                flex: 2,
                editable: false
            },
            {
                headerName: "Action",
                filter: false,
                maxWidth:100,
                pinned:"right",
                sortable: false,
                resizable: false,
                unSortIcon: true,
                field: "taxId",
                flex: 2,
                cellRenderer: "buttonRender",
                cellRendererParams: { PageType: "TaxList" },
            },

        ]
        this.context = { componentParent: this };
        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
        };
        this.frameworkComponents = {
            buttonRender: GeneralActionComponent
        };
        this.noRowsTemplate = `"<span">no rows to show</span>"`;
    }

    openModal(template: TemplateRef<any>) {
        const foundElement = this.functionPermissionData.find(x => x.id === 110)
        if (foundElement.id == 110 && foundElement.isAllowed == true) {
        this.TaxPopupHeading="Add Tax";
        this.modalRef = this.modalService.show(template);
        // this.TaxId = 0;
        this.ResetFunction();
    } else {
        this.toastr.warning("You are not allowed to add data");
      }
    }
    showModalDialog(taxData) {
        const foundElement = this.functionPermissionData.find(x => x.id === 111)
        if (foundElement.id == 111 && foundElement.isAllowed == true) {
        this.TaxPopupHeading="Edit Tax";
        this.modalRef = this.modalService.show(
            this.modaltemp,
            // Object.assign({}, { class: "gray modal-lg" })
        );
        this.TaxId = taxData.taxId;
        this.TaxForm.patchValue({
            taxName: taxData.taxDesc,
            taxPercent: taxData.taxPcnt,
            taxHead: taxData.headId,
            taxLedger: taxData.headId,
            isDisplayed: Boolean(taxData.isDisplayed)
        });
        } else {
            this.toastr.warning("You are not allowed to edit data");
        }
    }


    SaveTax() {
        this.submitted = true;
        // if(this.TaxForm.get("taxLedger").value=='0'){
        //     this.TaxForm.=false;
        // }
        if (this.TaxForm && this.TaxForm.valid) {
            var AppData = {
                taxname: this.TaxForm.get("taxName").value,
                taxpcnt: this.TaxForm.get("taxPercent").value,
                taxhead: this.TaxForm.get("taxLedger").value,
                isDisplayed: this.TaxForm.get("isDisplayed").value,
            }
            let taxiddata = 0;
            if (this.TaxId > 0) {
                taxiddata = this.TaxId;
            }
            this.isLoading=true;

            this.ConfigurationService.InsertUpdateTax(AppData, this.globals.HospitalId, taxiddata).subscribe((res) => {
                this.submitted = false;
                this.result = res;
                if (this.result.status == 200 && this.result.message == 'Success') {
                    if (taxiddata == 0) {
                        this.isLoading=false;
                        this.toastr.success("Tax saved successfully");
                    }
                    else {
                        this.isLoading=false;
                        this.toastr.success("Tax Updated successfully");
                    }
                    this.modalRef.hide();
                    this.GetAllTax();
                    this.TaxId = 0;
                }
                else {
                    this.isLoading=false;
                    this.toastr.error("Failed : " + this.result.message);
                }
            })
        }
    }
    GetAllTax() {
        this.ConfigurationService.GetTax(0, 1, this.globals.HospitalId).subscribe((res) => {
            this.taxDatas = res.response;
        });
    }

    GetLedger() {
        this.ConfigurationService.GetLedger(0, 0, this.globals.HospitalId).subscribe((res) => {
            this.ledgers = res.response;
            this.ledgerData = [];
            //this.ledgerData.push({ id: "0", text: "Select" });
            this.ledgers.forEach(a => {
                this.ledgerData.push({ id: a.headId.toString(), text: a.headDesc });
            });
        });
    }

    DeleteTax(taxDetail) {
        const foundElement = this.functionPermissionData.find(x => x.id === 112)
        if (foundElement.id == 112 && foundElement.isAllowed == true) {
        this.ConfigurationService.DeleteTax(taxDetail).subscribe((res) => {
            this.result = res;
            if (this.result.status == 200) {
                this.toastr.success("Tax deleted successfully");
                this.GetAllTax();
                this.TaxId = 0;
            }
            else {
                this.toastr.error("Failure: " + this.result.message);
            }
        },
        );
        } else {
            this.toastr.warning("You are not allowed to delete data");
        }
    }
    selectchecking(evt) {
        if (evt.target.checked == true) {
            this.unlistedCode = 1;
        }
        else {
            this.unlistedCode = 0;
        }
    }
    onGridReady(params) {
        this.gridApi = params.api;
    }
    onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter(
            (document.getElementById('filter-text-box') as HTMLInputElement).value
        );
    }

    get f() {
        return this.TaxForm.controls;
    }




    // to clear form
    clearForm() {
        this.TaxForm.reset({
            taxName: '',
            taxPercent: '',
            isDisplayed: true,
            taxLedger: ''
        });
    }

    ResetFunction() {
        this.TaxId = 0;
        this.submitted = false;

        this.clearForm();
    }

    functionPermission() {
        let input = {
          MenuId: Number(localStorage.getItem('selectedMenu')),
          UserId: Number(localStorage.getItem("user_id"))
        }
        this.ConfigurationService.functionPermission(input).subscribe((res) => {
          this.functionPermissionData = res.response;
          console.log('functionPermissionData', this.functionPermissionData);
        });
    
      }
}
