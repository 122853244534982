import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../../../_Models/Response';
import { BillingService } from '../../services/billing.service';

@Component({
  selector: 'app-transaction-list-modal',
  templateUrl: './transaction-list-modal.component.html',
  styleUrls: ['./transaction-list-modal.component.scss']
})
export class TransactionListModalComponent implements OnInit {

  @Input() patientId: number;
  @Input() patientName: string = '';
  @Input() sponsorName: string = '';
  @Input() regNo: string = '';
  @Input() openDate: string = '';
  @Input() branchId: number;
  @Input() creditId: number;

  public dataColumns: ColDef[] = [];
  private gridApi: GridApi;
  public loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
  public noRowsTemplate = `"<span">no rows to show</span>"`;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight'
  };
  public transactionList: any[] = [];

  constructor(private modalRef: BsModalRef,
              private billingService: BillingService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {

    this.dataColumns = [
      {
        headerName: "Date",
        field: "date",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Ref. No",
        field: "refNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Particulars",
        field: "particulars",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 5,
      },
      {
        headerName: "Debit",
        field: "debit",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Credit",
        field: "credit",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Balance",
        field: "balance",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Type(Cr/Dr)",
        field: "balType",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      }
    ]
  }

  close(){
    this.modalRef.hide();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.getTransactions();
  }

  getTransactions(){
    let payload = { 
      branchid: this.branchId,
      creditId: Number(this.creditId)
    }

    this.gridApi.showLoadingOverlay();
    this.billingService.getCreditTransactions(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.transactionList = response.response;
        }else{
          this.toastrService.error('Something wents wrong, Try again!!');
        }
        this.gridApi.hideOverlay();
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong, Try again!!');
        this.gridApi.hideOverlay();
      }
    })
  }

}
