import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { LocationService } from "../../modules/user-permission/location/location.service";
import { SharedService } from "../shared.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-general-grid-action",
  templateUrl: "./general-grid-action.component.html",
  styleUrls: ["./general-grid-action.component.scss"],
})
export class GeneralGridActionComponent implements ICellRendererAngularComp {
  params: any;
  Page: string;
  ParentComp: any;
  startDate:any
  endDate:any
  public pagetype: string;
  public pageField: string = '';

  constructor(private locationservice: LocationService,
    private datePipe: DatePipe,
    ) {}

  agInit(params: any): void {
    this.params = params;
    this.Page = this.params.Page;
    this.ParentComp = this.params.context.componentParent;
    this.startDate=params.data.startDate
    this.endDate=params.data.endDate
    this.pagetype = this.params.PageType;
    this.pageField = this.params.PageField;

    if(this.pagetype=="treatmentDetailsphysio"){
    }


  }
  refresh(): boolean {
    return false;
  }
  Map()
  {
    switch (this.Page) {
      case "User":
          this.ParentComp.MapLocations(this.params.value);
    
    }
  }
  MapGroups()
  {
    switch (this.Page) {
      case "User":
          this.ParentComp.MapGroups(this.params.value);
    
    }
  }
  MapMenu()
  {
    switch (this.Page) {
      case "UserGroups":
          this.ParentComp.MapMenu(this.params.value,this.params.data.userGroup);
    
    }
  }
  Edit() {
    switch (this.Page) {
      case "Location":
        this.ParentComp.GetLocation(this.params.value);
        break;
      case "UserGroups":
        this.ParentComp.getUserGroup(this.params.value);
        break;
        case "User":
          this.ParentComp.GetUser(this.params.value);
          break;
          case "SubMenu":
          this.ParentComp.GetSubmenu(this.params.value);
          case "Menu":
          this.ParentComp.Getmenu(this.params.value);
    }
  }

  AssociateLocation(){
    switch (this.Page) {
      case "Location":
          this.ParentComp.AssociateLocation(this.params.value);
    
    }
  }
  ServicePoints(){
    switch (this.Page) {
      case "Location":
          this.ParentComp.Servicepoints(this.params.data);
         
    }
  }
  onChangeStartDate(event){
    this.params.data.startDate =  this.datePipe.transform(this.startDate, 'dd-MM-yyyy')
  }

  onChangeEndDate(event){
    this.params.data.endDate =  this.datePipe.transform(this.endDate, 'dd-MM-yyyy')
  }

}
