import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FormValidationService } from '../../client/services/form-validation.service';
// import { ActiveFilterMenuTriggerDirective } from '@generic-ui/ngx-grid/structure/source/feature/counter/filter/active-filter-menu-trigger.directive';
// import { ProfessionList } from '../../client/interfaces/ProfessionList';
import { ProfessionService } from '../../client/services/profession.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../client/services/configuration.service';

@Component({
  selector: 'app-SketchIndicator',
  templateUrl: './sketchindicator.component.html',
  styleUrls: ['./sketchindicator.component.scss']
})
export class SketchIndicatorComponent implements OnInit {
  @ViewChild('myfiles') myfiles: ElementRef;
  products: any = {};
  displayModal: boolean;
  public submitted: boolean = false;
  result: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  public statusBar;
  rowGroupPanelShow = "always";
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.active == '0'; },
      'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    },
  }
  rowData: any;
  unlistedCode: any;
  SketchIndicatorForm: FormGroup;
  RateGroupId: any;
  sketchIndicatorDatas: any;
  IsDisplayed: any;
  constructor(private formBuilder: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private ProfessionService: ProfessionService,
    private toastr: ToastrService,
    private formValidationService: FormValidationService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });
  }
  public documentFile: File[] = null;
  ngOnInit(): void {
    this.BuildForm(1, 1);
    this.GetAllSketchIndicators();
    this.columnDefs = [
      {
        headerName: "Sketch Indicator Name",
        field: "indicatorDesc",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Link",
        field: "imageUrl",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Action",
        filter: false,
        resizable: false,
        sortable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "SketchIndicatorList" },
      },
    ]
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }
  BuildForm(FormId, DepartmentId) {
    this.GenerateForm();
  }
  GenerateForm() {
    this.SketchIndicatorForm = this.formBuilder.group({
      siDesc: new FormControl(''),
    });
  }
  onUpload(event) {
    this.documentFile = <File[]>event.files;
    //this.displayBasicdragdrop = false;
    this.toastr.success("File will be uploaded when sketch indiicator data saved");
  }
  showModalDialog(sketchIndicatorData) {
    this.displayModal = true;
    this.RateGroupId = sketchIndicatorData.rGroupId
    this.SketchIndicatorForm.patchValue({
      // rategroupName: sketchIndicatorData.rGroupName,
      // rategroupDesc: sketchIndicatorData.description,
      // rategroupEF: sketchIndicatorData.effectFrom,
      // rategroupET: sketchIndicatorData.effectTo,
    });
  }
  //pagetitle to be displayed at the header
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);
  // }
  SaveSketchIndicator() {
    const formData = new FormData();
    if (this.documentFile) {
      for (var i = 0; i < this.documentFile.length; i++) {
        formData.append('Indicator', this.documentFile[i], this.documentFile[i].name);
      }
    }
    var AppData = {
      IndicatorId: 0,
      IndicatorDesc: this.SketchIndicatorForm.value.siDesc,
      BranchId: 3,
      IsDisplayed: 1
    }
    formData.append('SketchIndicatorJson', JSON.stringify(AppData));
    this.ConfigurationService.InsertUpdateSketchIndicator(formData, this.globals.HospitalId, this.RateGroupId, this.IsDisplayed).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200 && this.result.message == 'Success') {
        this.toastr.success("Sketch Indicator saved successfully");
        this.displayModal = false;
        this.GetAllSketchIndicators();
      }
      else {
        this.toastr.error("Failed : " + this.result.message);
      }
    })
  }
  GetAllSketchIndicators() {
    this.ConfigurationService.GetSketchIndicator(this.globals.HospitalId).subscribe((res) => {
      this.sketchIndicatorDatas = res.response;
    });
  }
  DeleteRateGroup(id) {
    this.RateGroupId = id.cptCodeId;
    this.ConfigurationService.DeleteRateGroup(this.RateGroupId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Rate Group deleted successfully");
        this.displayModal = false;
        this.GetAllSketchIndicators();
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
        this.displayModal = false;
      }
    },
    );
  }
  //check and uncheck of registration date
  selectchecking(evt) {
    if (evt.target.checked == true) {
      this.unlistedCode = 1;
    }
    else {
      this.unlistedCode = 0;
    }
  }
  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }
}
