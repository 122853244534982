import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emr-menubar',
  templateUrl: './emr-menubar.component.html',
  styleUrls: ['./emr-menubar.component.scss']
})
export class EMRMenubarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
