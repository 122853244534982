import { Component, Input, OnInit } from '@angular/core';
import { EMRService } from '../../emr/services/emr.service';
import { BasicPatientInfo } from '../interfaces/BasicPatientInfo';
import {  EMRPatientInfo,EMRAllergy, EMRVital, EMRSponsorInfo } from '../interfaces/EMRPatientInfo';
import moment from 'moment';

@Component({
  selector: 'app-patient-bar',
  templateUrl: './patient-bar.component.html',
  styleUrls: ['./patient-bar.component.scss']
})
export class PatientBarComponent implements OnInit {

  //show hide detailed pan
  AllergyList: any;
  AlertList: any;
  vitalSignList:any;
  DetailedBox:boolean=false;
  TodaysvitalSignList:any;
  EMRPatientInfo:EMRPatientInfo;
  EMRSponsorInfo:EMRSponsorInfo;
  EMRAllergy:EMRAllergy;
  EMRVital:EMRVital;
  BasicPatientInfo:BasicPatientInfo=new BasicPatientInfo();
  PatientImage:string="assets/images/No-photo.png";
  @Input() BranchesId: any;
  lmpAlert:any=false;
  constructor(
    private EMRService:EMRService,

  ) {
    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();
    this.EMRSponsorInfo = this.EMRService.GetEMRSponsorInfo();
    this.getPatientInfo(this.EMRPatientInfo.PatientId)
    // this.EMRPatientInfo=this.EMRService.GetAllergy();
    // this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();

   }

  ngOnInit(): void {
    this.GetAlert() ;
    this.GetAllergies() ;
    this.GetTodaysvitalSign();
  }


  ActiavateDeatiledBox()
  {
    if(!this.DetailedBox)
    {
      this.DetailedBox=true;
    }
    else{
      this.DetailedBox=false;
    }
  }

  getPatientInfo(PatientId:Number)
  {
    if(PatientId>0){
      this.EMRService.GetPatientInfo(PatientId).subscribe((res) => {
        this.BasicPatientInfo=res.response[0];
        if(this.BasicPatientInfo.profilePicLocation)
        {
          this.PatientImage=this.BasicPatientInfo.profilePicLocation;
        }

      });
     }
  }

  GetAllergies() {
    let input = { "PatientId": this.EMRPatientInfo.PatientId };
    this.AllergyList = [];
    this.EMRService.GetAllergy(input).subscribe(
      (res) => {
        if (res.status == 200) {
          let Response = res.response
          if (Response.length > 0) {
            this.AllergyList = res.response;
          }
        }
      });
  }
  GetAlert() {
    let input = {
       "PatientId": this.EMRPatientInfo.PatientId,
       "BranchId": this.BranchesId,
       "AlertId": 0,
       "AlertType": '',
       };
    this.AlertList = [];
    this.EMRService.getAlertList(input).subscribe(
      (res:any) => {
        if (res.status == 200) {
          let date = new Date()
          this.AlertList = res.response;
          this.AlertList.map((res:any)=>{
            if(res.isDisplayed && res.alertType === 'LMP') {
              this.lmpAlert = true
            }
            if(res.isDisplayed){
              let date1 = moment(res.validTo).isSame(date);
              let date2 = moment(res.validTo).isBefore(date);
              if(date2){
                res.validDate = true;
              } else {
                res.validDate = false
              }
            }
          })
        }
      });
  }
  GetTodaysvitalSign() {
    let input = { "VisitId": this.EMRPatientInfo.VisitId, "PatientId": this.EMRPatientInfo.PatientId };
    this.TodaysvitalSignList = [];
    this.EMRService.GetAllEMRVitalSignByVisitId(input).subscribe(
      (res) => {
        if (res.status == 200) {
          let Response = res.response
          if (Response.length > 0) {
            this.TodaysvitalSignList = res.response[0].vitalSignData;
          }
        }
      });
  }

 
 
  // GetEMRVitalSign(vistId=this.EMRPatientInfo.VisitId)
  // {

  //   let input={
  //     "VisitId":vistId,
  //     "BranchId":this.BranchesId
  // };
  //   this.EMRService.GetEMRVitalSign(input).subscribe(
  //     (res) => {
  //       if(res.status==200)
  //       {
  //         this.vitalSignList=res.response;

  //       }
  //     });


  // }

}
