import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Toast, ToastRef, ToastrService } from 'ngx-toastr';
import { CommonValidationComponent } from '../../../shared/common-validation/common-validation.component';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { SponsorList } from '../../client/interfaces/SponsorList';
import { ConsultaionsService } from '../../client/services/consultations.service';
import { PatientService } from '../../client/services/patient.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { GenderList } from '../../client/interfaces/GenderList';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingService } from '../services/billing.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { DatePipe } from '@angular/common';
import { Response } from '../../../_Models/Response';

export interface consultation{
  regNo: any,
  consulatationId: any,
  consulatantID: any,
  creditId: any,
  rGroupId: any,
  extLabId: any
}

@Component({
  selector: 'app-new-bills',
  templateUrl: './new-bills.component.html',
  styleUrls: ['./new-bills.component.scss']
})
export class 
NewBillsComponent implements OnInit, OnDestroy {

  modalRef: BsModalRef;
  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;

  public BillingList: FormGroup;
  public validator = new CommonValidationComponent();

  public patientDetailsLoading: boolean = false;

  patients: any;
  public patientDetails: any;ConsultantId
  PatientId: number;

  private globals: globalvars = new globalvars();

  public genderList: GenderList[] = [];
  private subscription: Subscription;

  private consulatation : consultation;

  private toastrRef: any;

  sponsorList: SponsorList[] = [];
  public consultationList: any[] = [];

  constructor(private patientService: PatientService,
              private toastr: ToastrService,
              private NewBillingService: NewBillingService,
              private router: Router,
              private datePipe: DatePipe,
              private sharedservice: SharedService,
              private billingService: BillingService,) { }

  ngOnInit(): void {

    let isExternalLab: boolean = false;
    if(this.router.getCurrentNavigation()?.extras?.state){
      isExternalLab = this.router.getCurrentNavigation().extras.state.isExternalLab;
     }

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });

    this.BillingList = new FormGroup({
      id: new FormControl(0),
      RegNo: new FormControl(""),
      PatientName: new FormControl(""),
      patientId: new FormControl(0),
      Address: new FormControl(""),
      MobileNo: new FormControl(""),
      Dob: new FormControl(""),
      Gender: new FormControl("0"),
    });

    this.GetGender();

    if(sessionStorage.getItem('bill-patient-regNo')){
      let regNo = JSON.parse(sessionStorage.getItem('bill-patient-regNo'));
      if(regNo.regNo && regNo.regNo != ''){
        this.BillingList.patchValue({
          RegNo: regNo.regNo,
        });
        this.consulatation = regNo;
        this.GetPatientByRegNo();
      }
    }

  }

  showBasicDialog() {
    this.modaltemp.open();
  }

  GetPatientByRegNo() {
    if(this.toastrRef){
      this.toastrRef.toastRef.close()
    }
    this.patientDetailsLoading = true;
    this.patientService.SearchPatientByRegNo(this.BillingList.get("RegNo").value).subscribe((res) => {
      this.patients = res.response;
      if (this.patients.length > 0) {
        if (this.patients[0].active == "0") {
          this.toastr.warning("This Patient is blocked");
          this.BillingList.patchValue({ RegNo: this.patients[0].regNo });
          this.patients = [];
        }
        this.FillForm1(this.patients[0])
      } else {
        this.toastr.warning("No patients found");
      }
      this.patientDetailsLoading = false;
    });
  }

  SearchPatientDetail(patient: any) {

    let _this = this;
    this.GetPatientByRegNo();
  }

  FillForm1(patient: any) {
    this.patientDetails = patient;
    this.patientDetails.consultantId = this.consulatation?.consulatantID;
    this.patientDetails.consultationId = this.consulatation?.consulatationId;
    this.patientDetails.creditId = this.consulatation?.creditId;
    this.patientDetails.rGroupId = this.consulatation?.rGroupId ? this.consulatation.rGroupId : this.patientDetails?.rGroupId;
    this.patientDetails.extLabId = this.consulatation?.extLabId ? this.consulatation.extLabId : this.patientDetails?.extLabId;
    this.PatientId = patient["patientId"];
    this.BillingList.patchValue({ Address: patient["address"] });
    this.BillingList.patchValue({ RegNo: patient["regNo"] });
    this.BillingList.patchValue({ PatientName: patient["patient"] });
    this.BillingList.patchValue({ MobileNo: patient["mobile"] });
    this.BillingList.patchValue({ Dob: patient["age"] + ', '+ this.datePipe.transform(new Date(patient['dob']), 'dd-MM-yyyy') });
    this.BillingList.patchValue({ Gender: patient["gender"] });
    this.loadSponsorTable();
    this.loadConsulationList();
    this.checkForOutstandingBalance();
  }

  checkForOutstandingBalance(){
    let payload = {
      Patientid: this.PatientId,
      Creditid: 0,
      Branchid: this.globals.HospitalId
    }
    let allPatientCredits: any[] = [];
    let totalBalance: number = 0;
    this.billingService.getOutstandingBalanceOfPatient(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.response.length > 0){
          allPatientCredits = response.response;
          allPatientCredits.forEach((credit: any)=>{
            totalBalance = totalBalance + Number(credit.outstandingCreditBalace);
          })
          if(totalBalance > 0){
            let content = `<p>O/S Balance: ${totalBalance}<span class="badge badge-secondary">New</span></p>`
            this.toastrRef = this.toastr.warning(content,'Warning', {
              disableTimeOut: true,
              tapToDismiss: false,
              enableHtml: true,
              positionClass: 'toast-bottom-left'
            })
            
          }
        }
      },error: (error: any)=>{

      }
    })
  }

  //getting sponsors list
  loadSponsorTable() {
    let payload = {
      PatientId: this.PatientId,
      Branchid: this.globals.HospitalId,
      ShowExpired: 1
    }
    this.billingService.GetSponsorDetailsByPatient(payload).subscribe((res) => {
      this.sponsorList = res.response;
    });

    // this.consultationService.LoadSponsorTable(this.PatientId).subscribe((res) => {
    //   this.sponsorList = res.response;
    // })

  }

  loadConsulationList(){
    let payload = {
      ConsultantId: Number(this.patientDetails.consultantId) ? Number(this.patientDetails.consultantId) : 0,
      PatientId: this.PatientId
    }
    this.billingService.getConsulatationsList(payload).subscribe((res) => {
      this.consultationList = res.response;
    });
  }

  GetGender() {
    this.patientService.GetGender().subscribe((res) => {
      this.genderList = res.response;

    })
  }

  searchevent(patient: any) {
    this.patientDetails = patient.data;
    this.PatientId = patient.data.patientId;
    this.BillingList.patchValue({ Address: patient.data.address });
    this.BillingList.patchValue({ RegNo: patient.data.regNo });
    this.BillingList.patchValue({ PatientName: patient.data.patient });
    this.BillingList.patchValue({ MobileNo: patient.data.mobile });
    this.BillingList.patchValue({ Dob: patient.data.age });
    this.BillingList.patchValue({ Gender: patient.data.gender });
    this.loadSponsorTable();
    this.NewBillingService.PatientDataDetails = this.patientDetails;

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if(this.toastrRef){
      this.toastrRef.toastRef.close()
    }
  }

}
