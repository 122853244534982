import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { globalvars } from "../../../../../shared/models/GlobalVars";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConsentService } from "../../../services/consent.service";
import { SharedService } from "../../../../../shared/shared.service";
import { AppointmentsService } from "../../../services/appointments.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ConfigurationManager } from "../../../../../../assets/configuration/configuration-manager";
import { Response } from "../../../../../_Models/Response";
import SignaturePad from "signature_pad";
import { ChangeDetectorRef } from "@angular/core";
import { PatientService } from "../../../services/patient.service";

@Component({
  selector: "app-nabith-consent",
  templateUrl: "./nabith-consent.component.html",
  styleUrls: ["./nabith-consent.component.scss"],
})
export class NabithConsentComponent implements OnInit {
  @Input() patientId;
  @Input() ConsultationId;
  @Input() action;
  @Input() formName;
  public fileEmitter: EventEmitter<any> = new EventEmitter();
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  public documentLoading: boolean = false;
  public documentForm: FormGroup;
  public signatureAdded: boolean = false;
  private baseUrl: string = "";
  private canvasContext: CanvasRenderingContext2D;
  private signStarted: boolean = false;
  reportLoc: string = "";
  pdfUrl: string = "";

  public viewerContainerStyle = {
    position: "relative",
    width: "100%",
    height: "550px",
    ["font-family"]: "ms sans serif",
  };

  @ViewChild("canvas") canvasEl: ElementRef;
  signaturePad: SignaturePad;
  serviceUrl: any;
  telerikName: any;
  reportParams: any;
  loading: boolean=true;
  constructor(
    private ConsentService: ConsentService,
    private sharedservice: SharedService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private activeModal: BsModalRef,
    private configurationManager: ConfigurationManager,
    private cdr: ChangeDetectorRef,
    private patientService:PatientService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.baseUrl = (this.configurationManager as any).baseUrl;
    
    this.initDocumentForm();
    // this.GetNabithOptInOptOutPDF();
    this.getpatientByid()
    this.reportParams = {
      PatientId: this.patientId,
      BranchId: this.BranchesId,
      ConsultationId: this.ConsultationId ? this.ConsultationId : 0,
    };
    this.serviceUrl = (this.configurationManager as any).reportServiceUrl;
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.signaturePad.maxWidth = 0.9;
  }

  initDocumentForm() {
    this.documentForm = this.formBuilder.group({
      documentPath: new FormControl(""),
    });
  }

  GetNabithOptInOptOutPDF() {
    const payload = {
      Patientid: this.patientId,
      BranchId: this.BranchesId,
      ConsultationId: this.ConsultationId,
    };
    this.documentLoading = true;
    this.ConsentService.GetNabithOptInOptOutPDF(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200) {
          const data = response.response[0];
          this.reportLoc = data.reportPath;
          if (data.reportPath) {
            this.pdfUrl = this.baseUrl + data.reportPath;
            this.documentForm.patchValue({
              documentPath: this.sanitizer.bypassSecurityTrustResourceUrl(
                this.baseUrl + data.reportPath
              ),
            });
            this.cdr.detectChanges();
          } else {
            this.toastrService.error(data.errormessage);
            this.close();
          }
        } else {
          this.toastrService.error(response.errorMessage.message);
          this.close();
        }
        this.documentLoading = false;
      },
      error: (error: any) => {
        this.toastrService.error("Something wents wrong..! Try again");
        this.documentLoading = false;
        this.close();
      },
    });
  }

  close() {
    this.fileEmitter.emit({
      status: true,
    });
    this.activeModal.hide();
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }

  onUpdatePatientSignature() {
    const signName = "sign.jpeg";
    // let canvas = document.getElementById('canvas-signature') as HTMLCanvasElement;
    let base64Data = this.signaturePad.toDataURL();
    let signsplit = base64Data.split(";");
    const signBlob = this.dataURItoBlob(signsplit[1].split(",")[1]);
    const signFile = new File([signBlob], signName, { type: "image/jpeg" });

    const formData = new FormData();

    let data={
      'BranchId': this.BranchesId,
      'Patientid': this.patientId,
      'ConsultationId': this.ConsultationId
    }
    formData.append("ConsentJson",JSON.stringify(data))

    formData.append("Sign", signFile);
    formData.append("WitnessSign", null);
    // formData.append("PdfLoc", this.reportLoc);
    // formData.append("Patientid", this.patientId);
    // formData.append("ConsultationId", this.ConsultationId);
    this.documentLoading = true;
    this.loading=true
    this.ConsentService.UpdateHIConsentDetails(formData).subscribe({
      next: (response: Response<any>) => {
        this.documentLoading = false;
        if (response.status == 200) {
          this.loading=false
          const data = response.response[0];
          
        } else {
          this.toastrService.error(response.errorMessage.message);
        }
      },
      error: (error: any) => {
        this.toastrService.error("Something wents wrong..! Try again");
      },
    });
  }

  // startDrawing(event: MouseEvent) {
  //   this.canvasContext.beginPath();
  //   this.canvasContext.moveTo(event.offsetX, event.offsetY);
  //   this.signStarted = true;
  // }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  draw(event: MouseEvent) {
    if (this.signStarted) {
      this.canvasContext.lineTo(event.offsetX, event.offsetY);
      this.canvasContext.stroke();
    }
  }

  stopDrawing() {
    this.canvasContext.closePath();
    this.signStarted = false;
  }

  clearSignature() {
    // this.canvasContext.clearRect(0,0,200,100);
    this.signaturePad.clear();
  }

  getpatientByid(){
    this.patientService.GetPatientDetailsbyId(this.patientId,this.ConsultationId).subscribe((res) => {
      let resp=res.response[0]
      debugger
      if(resp.consentOptionId == 0){
        this.telerikName="Rpt_NabidhOptINConsentForm.trdp"
      }else if( resp.consentOptionId == 1){
         this.telerikName="Rpt_NabidhOptOutConsentForm.trdp"
      }
       this.loading=false

    })
  }
}
