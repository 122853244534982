import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OperatorService } from '../../client/services/operator.service';
import { ToastrService } from 'ngx-toastr';
import { FormValidationService } from '../../client/services/form-validation.service';
import { AllOperatorsList } from '../../client/interfaces/OperatorList';
import { CountryList } from '../../client/interfaces/CountryList';
import { AppointmentsService } from '../../client/services/appointments.service';
import { ConfigurationService } from '../../client/services/configuration.service';
import { ZoneList } from '../../client/interfaces/ZoneList';
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { ActiveFilterMenuTriggerDirective } from '@generic-ui/ngx-grid/structure/source/feature/counter/filter/active-filter-menu-trigger.directive';
@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss']
})
export class ZonesComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;
  supervisor = '';
  locationname = '';
  Mobilenumber = '';
  public submitted: boolean = false;
  Zonesform: FormGroup;
  result: any;
  Operators: AllOperatorsList[] = [];
  Zones: ZoneList[] = [];
  operatorId: any;
  countryList: CountryList[] = [];
  deleteZone: boolean;
  oppid: any;
  zoneId: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  Zones1: any;

  constructor(private formBuilder: FormBuilder,
    private appointmentService: AppointmentsService,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private OperatorService: OperatorService,
    private toastr: ToastrService,
    private formValidationService: FormValidationService,
    private sharedservice: SharedService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId;
      }
    });

  }

  ngOnInit(): void {
    this.BuildForm(1, 1);
    this.GetAllZones();
    this.LoadCountry();
    this.GetAllOperators();
  }


  BuildForm(FormId, DepartmentId) {
    this.GenerateForm();
  }
  GenerateForm() {
    this.Zonesform = this.formBuilder.group({
      ZoneCountry: new FormControl(''),
      ZoneName: new FormControl(''),
      ZoneCode: new FormControl(''),
      ZoneDescription: new FormControl(''),
      ZoneOperator: new FormControl(''),
    });
  }

  showModalDialog() {
    this.displayModal = true;
  }
  showModalDialogedit(id: any) {

    this.zoneId = id;
    let ZonesData = this.Zones.filter((oppdata) => oppdata['id'] == id);

    this.Zonesform.patchValue({
      ZoneCountry: ZonesData[0]['ZoneCountry'],
      ZoneName: ZonesData[0]['zoneName'],
      ZoneCode: ZonesData[0]['ZoneCode'],
      ZoneOperator: ZonesData[0]['ZoneOperator'],
      ZoneDescription: ZonesData[0]['ZoneDescription']
    });
    this.displayModal = true;
  }

  showModalDialogdelete(id: any) {
    this.zoneId = id;
    this.deleteZone = true;
    this.DeleteZone;
  }

  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);

  // }


  SaveZones() {
    let ZoneCountrys = this.Zonesform.get("ZoneCountry").value;

    var AppData = {

      ZoneCountry: Number(ZoneCountrys),
      ZoneName: this.Zonesform.get("ZoneName").value,
      ZoneCode: this.Zonesform.get("ZoneCode").value,
      ZoneDescription: this.Zonesform.get("ZoneDescription").value,
      ZoneOperator: this.Zonesform.get("ZoneOperator").value,
    }


    if (this.zoneId > 0) {
      this.configurationService.UpdateZone(AppData, this.zoneId).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Zones Updated successfully");
        }
        else {
          this.toastr.error("Failed.Please try later");
        }
      })
    }
    else {
      this.configurationService.SaveZones(AppData).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Zones added successfully");
        }
        else {
          this.toastr.error("Failed.Please try later");
        }
      })
    }
  }


  GetAllZones() {

    this.configurationService.GetZones(this.globals.HospitalId).subscribe((res) => {
      this.Zones1 = res.response;
      if (this.Zones.length == 0) {
        this.toastr.warning("No Zones found");
      }
    });
  }

  DeleteZone() {
    this.configurationService.DeleteZones(this.zoneId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {

        this.toastr.success("Zones deleted successfully");
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
      }

    },
    );

  }


  LoadCountry() {
    this.appointmentService.GetCountry(this.globals.HospitalId).subscribe((res) => {
      this.countryList = res.response;
    })
  }



  GetAllOperators() {

    this.OperatorService.GetAllOperators().subscribe((res) => {
      this.Operators = res.response;
      if (this.Operators.length == 0) {
        this.toastr.warning("No Operators found");
      }
    });
  }



}
