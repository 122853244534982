import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';

import { Response } from '../../../_Models/Response';
import { UserProfile } from '../interfaces/UserProfile';
import { ErrorHandleService } from './error-handle.service';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {
  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle:ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }




AddOperator(appData){
  var url = "api/MasterData/InsertUpdateOperator";
  return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
  {
    "OperatorCode": appData.operatorCode,
    "OperatorDescription": appData.operatorDescription,
    "OperatorName": appData.operatorName,
    "BlockReason":"",

  });
}


UpdateOperator(appData,id){
  var url = "api/MasterData/UpdateOperator";
  return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
  {
      "id":id,
      "OperatorCode": appData.operatorCode,
      "OperatorDescription": appData.operatorDescription,
      "OperatorName": appData.operatorName,

  });
}


GetAllOperators() {
  var url = "api/MasterData/GetOperator/0";
  return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
    {

    }
  ).pipe(
    retry(1),
    catchError(this.errorHandle.handleError)
  );
}


DeleteOperator(id: Number) {
  var url = "api/MasterData/DeleteOperator/"  + id;
  return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
   { "id": id }).pipe(
    retry(1),
    catchError(this.errorHandle.handleError)
  );
}




}
