import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { ReportsService } from '../services/reports.service';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-receipt-and-payment-report',
  templateUrl: './receipt-and-payment-report.component.html',
  styleUrls: ['./receipt-and-payment-report.component.scss']
})
export class ReceiptAndPaymentReportComponent implements OnInit {
  globals: globalvars = new globalvars();
  subscription: Subscription;
  form: FormGroup; // form variable
  location: any[];
  isLoading = false;
  src: any = '';
  private baseUrl = "";
  isPdfLoaded: boolean = false;

  constructor(
    public datepipe: DatePipe,
    private sharedservice: SharedService,
    private ReportsService: ReportsService,
    private configurationManager: ConfigurationManager,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      FromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ToDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      BranchId: new FormControl(this.globals.HospitalId),
      locationId: new FormControl(0),
      type: new FormControl(0)
    });
    this.Getlocation();

  }

  Getlocation() {

    let input = {
      "showAll": 0,
      "HospitalId": this.globals.HospitalId,
      "locationId": 0
    }
    this.ReportsService.Getlocation(input).subscribe((res) => {
      this.location = res.response;
    });

  }
  showReport() {

      let formdata = this.form.getRawValue();
      if (typeof (formdata.FromDate) == "object") {
        let dobConverted = this.datepipe.transform(formdata.FromDate, 'dd-MM-yyyy');
        formdata.FromDate = dobConverted;
      }

      if (typeof (formdata.ToDate) == "object") {
        let dobConverted = this.datepipe.transform(formdata.ToDate, 'dd-MM-yyyy');
        formdata.ToDate = dobConverted;
      }

      let input = {
        "FromDate": formdata.FromDate,
        "ToDate": formdata.ToDate,
        "BranchId": this.globals.HospitalId,
        "locationId": Number(formdata.locationId)
      }
      if(formdata.type==0){
        this.toastr.warning("Please Select Type")
      }

      if (formdata.type == 1) {
        this.isLoading = true;

        this.ReportsService.GetReceiptReport(input).subscribe((res) => {

          this.isLoading = false;
          if (res.status == 200) {
            let response = res.response;
            this.src = response[0].reportPath;
            this.src = this.baseUrl + this.src;
            this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
            this.isPdfLoaded = true;
            this.clearForm();
          }
        });
      }
      else if(formdata.type == 2) {
        this.isLoading = true;

        this.ReportsService.GetPaymentreport(input).subscribe((res) => {

          this.isLoading = false;
          if (res.status == 200) {
            let response = res.response;
            this.src = response[0].reportPath;
            this.src = this.baseUrl + this.src;
            this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
            this.isPdfLoaded = true;
            this.clearForm()
          }
        });
      }
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  get f() {
    return this.form.controls;
  }
  clearForm() {
    this.form.patchValue({
      type:0,
    })
  }
}


