import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-resettlement-confirm-modal',
  templateUrl: './resettlement-confirm-modal.component.html',
  styleUrls: ['./resettlement-confirm-modal.component.scss']
})
export class ResettlementConfirmModalComponent implements OnInit {

  @Output() resettlementEmitter = new EventEmitter();

  constructor(
    private activeModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.resettlementEmitter.emit({status: false});
    this.activeModalRef.hide();
  }
  
  save(){
    this.resettlementEmitter.emit({status: true});
    this.activeModalRef.hide();
  }
}
