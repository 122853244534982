import { Component, OnInit } from '@angular/core';
import { ConsultationsList } from '../../../interfaces/ConsultationsList';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// loading Consultation Service
import { ConsultaionsService } from '../../../services/consultations.service';

import { AllAppointmentsList } from '../../../interfaces/AllAppointmentsList';

// loading Appointment Service
import { AppointmentsService } from '../../../services/appointments.service';
import { ToastrService } from 'ngx-toastr';
import { PatientList } from '../../../interfaces/PatientList';
import { Router } from '@angular/router';
// loading Patients Service
import { PatientService } from '../../../services/patient.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../general-action/general-action.component';
import { ConsultantDeskService } from '../../../services/consultant.service';
import { SharedService } from '../../../../../shared/shared.service';
import { globalvars } from '../../../../../shared/models/GlobalVars';
import { noop, Observer, of, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Response } from '../../../../../_Models/Response';
import * as _ from 'lodash';
import { map, switchMap, tap } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../../../assets/configuration/configuration-manager';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-addDisease ',
  templateUrl: './addDisease.component.html',
  styleUrls: ['./addDisease.component.scss'],

})
export class addDiseaseComponent implements OnInit {
  products: any = {};
  Disease: boolean;
  myPatients: boolean;
  myAppointment: boolean;
  myConsultation: boolean;
  Personalisations: boolean;
  Notifications: boolean;
  Changepassword: boolean;
  displayModal: boolean;
  displayModal1: boolean;
  displayModaluser: boolean;
  displaybranch: boolean;
  rowData: any = [];
  username = '';
  password = '';
  consultations: ConsultationsList[] = [];
  items: any = {};
  user_role: any;
  user_id: any;
  Id: any;
  appointments: AllAppointmentsList[] = [];
  users: any = [];
  selectedCities: any[] = [];
  availableProducts: any = [];
  selectedProducts: any = [];
  draggedProduct: any = [];
  patients: PatientList[] = [];
  columnDefs: ColDef[] = [];
  columnDefsapp: ColDef[] = [];
  columnDefconsult: ColDef[] = [];
  searchForm: FormGroup;
  attachdocuments: boolean;
  CancelAppId: number;
  printmodal: boolean;
  myFile: any;
  CancelAppointments: boolean;
  confirmblockingConsultants: any;
  signDropdownSettings: IDropdownSettings = {};
  SymptomsList: any[];
  SignList: any[];

  public submitted: boolean = false;
  //ag grid setup
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.active == '0'; },
      'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    },

  }
  consultantDataDetails: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  appointmentDetails: any;
  consultationsDetails: any;
  router_url = this.router.url;
  active: string;
  blockorunblock: boolean;
  blockId: number;
  confirmblock: boolean;
  displayBasicUser: boolean;
  patientId: any;
  displayBasicdragdrop: boolean;
  regDocLocation: any;
  documentList: any;
  pagetype: any;
  patientmodal: any;
  gender: any;
  regDate: any;
  patientName: any;
  address: any;
  mobile: any;
  regNo: any;
  age: any;
  regDetails: PatientList[];
  regIdentities: any;
  historyconsent: boolean;
  ConsentHistory: any;
  consentHistoryList: any;
  consentHistoryListArabic: any;
  consentHistoryPatientName: any;
  consentHistorySignature: any;
  PostponeObj: any;
  consultationId: any;
  CancelledReason: any;
  symptomDetails: ConsultationsList[];
  ReasonVisit: boolean;
  symptomList: any[];
  dropdownSettings: IDropdownSettings = {};
  result: any;
  blockorunblockconsult: boolean;
  documentFile: File[];
  documentFile1: any;
  displayBasic: boolean;
  showWebcam: boolean;
  public statusBar;
  consentDate: any;
  myObservable$: Subject<unknown>;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  generatedImage: string;
  minDate: any;

  rowGroupPanelShow = "always";
  OtherSymptom: boolean;
  consultantDataDetailsEvent: any[];
  BranchId: any;
  valueDate: Date;
  valueDate2: Date;
  FromDate: string;
  ToDate: string;
  minDate1 = new Date();
  appointmentDate: string;
  CurrentappointmentDate: string;
  AppointmentDataDetails: any;
  ServiceItems: boolean;
  DrugList: boolean;
  Marks: boolean;
  Sketch: boolean;
  GetSymptoms: any;
  GetSymptomsVitalSign: any;
  SymptomsVitalSign: any[];
  GetDiseasesIcd: any=[];
  GetIcdSymptoms: any;
  SymptomsId: any[];
  SignId: any[];
  GetICDSymptoms: any;
  SaveDiseasesResponse: any;
  AddDiseaseForm: FormGroup;
  ICD: any;
  SymptomsFreq: any;
  SymptomsDisease: any;
  Signs: any;
  SymptomsIdArray: any[] = [];
  SignIdArray: any[] = [];
  SymptomId: any[] = [];
  SignsIdArrays: any[] = [];
  DiseaseId: any;
  DiseaseResponse: any[] = [];
  GetDiseaseDetails: any;
  GetDiseaseDetailSymptoms: any;
  GetDiseaseDetailSigns: any;
  LabelIcd: any;
  icddropdownSettings: IDropdownSettings = {};

  public allICDlabels: any;

  public suggestions$?: Observable<any[]>;
  private baseUrl: string = "";

  test = [];

  constructor(
    private consultationService: ConsultaionsService,
    private http: HttpClient,
    private configurationManager: ConfigurationManager,
    private ConsultantDeskService: ConsultantDeskService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private ngxLoader: NgxUiLoaderService
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
        localStorage.setItem('BranchId', this.BranchesId);
      }
      this.BranchId = localStorage.getItem('BranchId');

    });
    this.icddropdownSettings = {
      singleSelection: false,
      idField: 'symptomId',
      textField: 'symptomDesc',
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,

    };

    this.signDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'descriptionData',
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,
    }

  }




  ngOnInit(): void {

    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.AddDiseaseForm = this.formBuilder.group({
      DiseaseName: new FormControl('', [Validators.required]),
      symptomdesc: new FormControl(''),
      DiseaseDuration: new FormControl(''),
      symptomSelect: new FormControl([]),
      signSelect: new FormControl([]),
      labelId: new FormControl(''),
      labelDesc: new FormControl('')
    });

    this.user_role = localStorage.getItem('user_role');
    this.user_id = localStorage.getItem('user_id');
    this.Id = localStorage.getItem('Id');
    this.activatedRoute.params.subscribe((params) => {
      this.DiseaseId = Number(params.id);
    });
    if (this.DiseaseId) {
      setTimeout(() => {
        this.GetDetailsFetching();
      }, 1000);
    }else{
      this.getSymptomsList();
      this.getSigns();
    }

    //Patients ag grid List
    this.columnDefs = [


      {
        headerName: "Disease Description",
        field: 'regDate',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: true
      },



    ]



     /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
     this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.AddDiseaseForm.get('labelDesc').value);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<any[]>>(
              `${this.baseUrl}api/EMR/SearchICD`,
              {
                LabelCode: "",
                LabelDesc: this.AddDiseaseForm.get('labelDesc').value,
                ConsultationId: 0,
                BranchId: this.globals.HospitalId
              }
            )
            .pipe(
              map((data) => _.map(data.response, (item)=>{
                return{
                  ...item,
                  itemDropdownData: item.labelCode+' - '+item.labelDesc
                }

              }) || []),
              tap(
                () => noop,
                (err) => {
                  
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */

  }

  ClearForm() {
    this.AddDiseaseForm.reset({
      symptomdesc: "",
      DiseaseName: "",
      labelId: '',
      symptomSelect: [],
      signSelect: []
    });
    this.AddDiseaseForm.clearValidators();

  }



  onTab(number) {
    this.Disease = false;
    this.ServiceItems = false;
    this.DrugList = false;
    this.Marks = false;
    this.Sketch = false;

    if (number == '1') {
      this.Disease = true;
    }
    else if (number == '2') {
      this.ServiceItems = true;
    }

    else if (number == '3') {
      this.DrugList = true;
    }
    else if (number == '4') {
      this.Marks = true;
    }
    else if (number == '5') {
      this.Sketch = true;
    }


  }

  TabSelection() {
    this.Disease = false;
    this.ServiceItems = false;
    this.DrugList = false;
    this.Marks = false;
    this.Sketch = false;

  }

  //pagetitle to be displayed at the header
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);

  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  selectcheckdays($event, symptoms) {
    let days = $event.target.defaultValue
    this.SymptomsFreq = days;
    this.SymptomsIdArray.forEach(a => {
      if (a.symptomId == symptoms) {
        a.duration = this.SymptomsFreq
      }

    });

  }

  selectcheckSigns($event, signs) {

    let signIds = Number($event.target.defaultValue)
    this.Signs = signIds;
    this.SignIdArray.push(signIds);
    this.SignsIdArrays.push(signs);
    this.GetIcdata();
  }

  isAllCheckBoxChecked() {
    return this.products.every(p => p.checked);

  }


  checkAllCheckBox(ev: any) {
    this.products.forEach(x => x.checked = ev.target.checked)
  }

  GetDetailsFetching() {
    this.ConsultantDeskService.getDiseaseDetailsAdd(this.DiseaseId, this.globals.HospitalId).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.GetDiseaseDetails = response.response;
          this.GetDiseaseDetailSymptoms = this.GetDiseaseDetails[0].symptoms;
          this.GetDiseaseDetailSymptoms?.forEach((a) => {
            if (a.selected == "true") {
              this.SymptomId.push({
                symptomId: a.symptomId,
                symptomDesc: a.symptomDesc
              });
            }
          });

          this.GetDiseaseDetailSigns = this.GetDiseaseDetails[0].signs;
          this.GetDiseaseDetailSigns?.forEach((a) => {
            if (a.selected == "true") {
              this.SignIdArray.push({
                id: a.signId,
                descriptionData: a.signDesc
              });
            }
          });

          this.AddDiseaseForm.patchValue({
            DiseaseName: this.GetDiseaseDetails[0].diseaseDesc,
            labelId: this.GetDiseaseDetails[0].labelId,
            labelDesc: this.GetDiseaseDetails[0].labelDesc,
            symptomSelect: this.SymptomId,
            signSelect: this.SignIdArray
          });
          this.getSymptomsList();
          this.getSigns();
        }else{
          this.toastr.error(response.errorMessage.message)
        }
      }
    })
  }

  onSelectICDLabel(icdInfo){
    this.AddDiseaseForm.patchValue({
      labelDesc: icdInfo.labelDesc,
      labelId: icdInfo.labelId
    })
  }

  selectcheckdate($event, symptoms) {
    let symptomsIds = Number($event.target.defaultValue);
    this.SymptomsDisease = symptomsIds;
    this.SymptomId.push(this.SymptomsDisease)
    this.SymptomsIdArray.push(symptoms);
  }

  GetIcdata() {
    this.ConsultantDeskService.GetIcdsSymptomSign(this.SymptomId, this.SignIdArray, this.globals.HospitalId).subscribe((res) => {
      this.GetDiseasesIcd = res.response;
    });
  }

  InsertConsultantDiseases() {
    let diseaseName = this.AddDiseaseForm.get("DiseaseName").value;
    let icdLabel = this.AddDiseaseForm.get("labelId").value;
    let symptoms: any[] = [];
    let signs: any[] = [];

    _.forEach(this.AddDiseaseForm.get("symptomSelect").value, (selectedSymptom: any)=>{
      let index = _.findIndex(this.SymptomsList, {symptomId: selectedSymptom.symptomId})
      if(index >= 0){
        symptoms.push({
          SymptomId: this.SymptomsList[index].symptomId,
          DiseaseId: this.DiseaseId > 0 ? this.DiseaseId : 0,
          Since: 0,
          Duration: "",
          SymptomDesc: this.SymptomsList[index].symptomDesc
        })
      }
    });

    _.forEach(this.AddDiseaseForm.get("signSelect").value, (selectedSign: any)=>{
      let index = _.findIndex(this.SignList, {id: selectedSign.id})
      if(index >= 0){
        signs.push({
          SignId: this.SignList[index].id,
          DiseaseId: this.DiseaseId > 0 ? this.DiseaseId : 0,
          Since: 0,
          Duration: "",
          SignDesc: this.SignList[index].descriptionData
        })
      }
    });
    let DiseaseId = 0;
    if (this.DiseaseId > 0) {
      DiseaseId = this.DiseaseId;
    }
    this.submitted = true;

    if(this.AddDiseaseForm && this.AddDiseaseForm.valid ) {
      this.ConsultantDeskService.InsertSaveConsultantDiseases(diseaseName, this.Id, Number(icdLabel), symptoms, signs, DiseaseId).subscribe((res) => {
        this.SaveDiseasesResponse = res.response;
        this.result = res;
        {
          if (this.result.message == "Success") {
            this.ngxLoader.stop();
            if (DiseaseId == 0) {
              this.toastr.success("Diseases added successfully");
              this.submitted = false;
            }
            else {
              this.toastr.success("Diseases Updated successfully");
            }
          }
          else {
            this.ngxLoader.stop();
            this.toastr.error("Failure: " + this.result.message);
          }
          this.submitted = false;
        }
      });
    }
  }
  selectcheckIcd($event) {
    let Icds = Number($event.target.defaultValue)
    this.ICD = Icds;
  }
  get f() {
    return this.AddDiseaseForm.controls;
  }
  getSymptomsList() {
    this.ConfigurationService.GetSymptom(this.globals.HospitalId, 0).subscribe((res) => {
      this.SymptomsList = res.response;
    });
  }

  getSigns() {
    this.ConfigurationService.GetSign(this.globals.HospitalId, 0).subscribe((res) => {
      this.SignList = res.response;
    });
  }

}


