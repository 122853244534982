import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppComponent } from '../../../app.component';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { UserpermissionService } from '../../user-permission/userpermission.service';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss']
})
export class LeftmenuComponent implements OnInit {
  isCollapsed = true;
  Pagecollapse = true;
  PaymentCollapsed = true;
  RoomCollapsed = true;
  configuration = true;
  consultantDesk=true;
  IsUserPermission=true;
  subscription:Subscription;
  globals:globalvars= new globalvars();
  userType:any;
  BranchesId: number;
  Leftmenuitems: any;
  collpaseArray:any[]=[];

  constructor(private router: Router, @Inject(AppComponent) private app: AppComponent,
  private activatedRoute: ActivatedRoute,
  private sharedservice:SharedService,
  private userservice:UserpermissionService) {

    if ((this.router.url).includes('registration') || (this.router.url).includes('consultation') || (this.router.url).includes('checkappointment')) {
      this.isCollapsed = false;
    }

    if ((this.router.url).includes('pages')) {
      this.Pagecollapse = false;
    }
    if ((this.router.url).includes('payments')) {
      this.PaymentCollapsed = false;
    }

    if ((this.router.url).includes('room-allotment')) {
      this.RoomCollapsed = false;
    }
    if ((this.router.url).includes('Branches') || (this.router.url).includes('Location')|| (this.router.url).includes('Usermanagement') || (this.router.url).includes('UserRights') ) {
      this.configuration = false;
    }
    if ((this.router.url).includes('Branches') || (this.router.url).includes('Location')|| (this.router.url).includes('Usermanagement') || (this.router.url).includes('UserRights') ) {
      this.consultantDesk = false;
    }
    this.globals =JSON.parse(sessionStorage.getItem("globalvars"));
    this.BranchesId=this.globals?.HospitalId;
    // this.SetLeftmenu();
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
         this.BranchesId=this.globals.HospitalId;
         this.SetLeftmenu();

      }
    });
  }

  SetLeftmenu()
  {
      if(this.globals?.HospitalId && this.globals?.Location?.locationId){
        var user= localStorage.getItem('user_id');

      this.userservice.GetLeftmenu(Number(user),this.BranchesId).subscribe((res) => {
      this.Leftmenuitems = res.response;
       let submenus=Array();

      //  this.Leftmenuitems.forEach(element => {
      //    submenus.push(element.subMenus);
      //    });
      localStorage.setItem('localMenus', JSON.stringify(this.Leftmenuitems));

      });
      }

}
getChild(activatedRoute: any) {
  if (activatedRoute.firstChild) {
    console.log("s" + activatedRoute);
    return this.getChild(activatedRoute.firstChild);
  } else {
    console.log("n" + activatedRoute);
    return activatedRoute;
  }

}

  ngOnInit(): void {
    this.userType = localStorage.getItem('user_role');

    //  this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    // )
    //   .subscribe((params ) => {
    //   console.log(event);
    //     var rt = this.getChild(this.activatedRoute);

    //     rt.data.subscribe(data => {
    //       if(data.pageName){
    //         this.Activetab(data.pageName);
    //       }
    //       else{
    //         this.Activetab(data.title);
    //       }


    //     })
    //   });


    // this.router.events.pipe(
    //     filter(event => event instanceof NavigationEnd),
    //   ).subscribe((event ) => {
    //     console.log(event);
    //   })



  }
ngOnDestroy()
{
  this.subscription.unsubscribe();
}
  ngAfterViewInit() {

    setTimeout(() => {

      if (this.router.url.includes('cryptocurrency')) {
        this.app.themeColor('o');
      }
      else if (this.router.url.includes('campaign')) {
        this.app.themeColor('b');
      }
      else if (this.router.url.includes('ecommerce')) {
        this.app.themeColor('a');
      }
      else {
        this.app.themeColor('g');
      }
      const className = document.getElementById('left-sidebar');
      let colorClassName = document.getElementsByClassName('theme-bg');
      if (sessionStorage.getItem("Sidebar") != "" && sessionStorage.getItem("Sidebar") != null) {
        className?.classList.add(sessionStorage.getItem("Sidebar"));
      }
      else {
        className.classList.remove('light_active');
      }
      for (let index = 0; index < colorClassName.length; index++) {
        const element = colorClassName[index];
        if (sessionStorage.getItem("GradientColor") != "" && sessionStorage.getItem("GradientColor") != null) {
          element.classList.add('gradient');
        }
        else {
          element.classList.remove('gradient');
        }
      }
    });
  }

  showDropDown() {
    document.getElementById('drp').classList.toggle("ShowDiv")
  }

  toggleMenu() {
    const body = document.getElementsByTagName('body')[0];

    if (body.classList.contains('toggle_menu_active')) {
      body.classList.remove('toggle_menu_active');
    }
    else {
      body.classList.add('toggle_menu_active');
    }
  }
  cToggoleMenu() {
    const body = document.getElementsByTagName('body')[0].classList.remove("offcanvas-active");
    document.getElementsByClassName('overlay')[0].classList.toggle("open");
  }

  Activetab(pagetitle){
    sessionStorage.setItem("pageTitle", pagetitle);

  }

  navigateToMenu(path,menuId)
  {
   // Replace with your actual menu ID
   // alert(menuId);

    localStorage.setItem('selectedMenu',menuId);
    

    this.router.navigate(['/'+path]);
  }


}
