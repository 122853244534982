import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationManager } from '../../../assets/configuration/configuration-manager';
import { ErrorHandleService } from '../client/services/error-handle.service';
import { Response } from '../../_Models/Response';
import { MessageTypeList } from './interface/MessageTypeList';
import { ConsultantList } from './interface/ConsultantList';
import { NabithList } from './interface/NabithList';


@Injectable({
  providedIn: 'root'
})
export class NabithService {
  private baseUrl: string = "";


  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService,
    private router: Router,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }
  GetMessageType(inputDetails = "") {
    var url = "api/Nabith/GetMessageType";
    return this.http.post<Response<MessageTypeList[]>>(`${this.baseUrl}${url}`, inputDetails
    )
  }

  GetConsultant(ConsultantId, FromDate, ToDate, BranchId) {
    var url = "api/Nabith/GetConsultationForDateRange";
    return this.http.post<Response<ConsultantList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": ConsultantId,
        "FromDate": FromDate,
        "ToDate": ToDate,
        "BranchId": BranchId
      }
    )
  }

  GetNabithDetails(EventId,FromDate,ToDate,ResultStatus,Environment,ConsultantId) {
    var url = "api/Nabith/GetNabidhDetails";
    return this.http.post<Response<NabithList[]>>(`${this.baseUrl}${url}`,
      {
        "EventId":EventId,
        "FromDate":FromDate,
        "ToDate":ToDate,
        "ResultStatus":ResultStatus,
        "Environment":Environment,
        "ConsultantId":ConsultantId,
          }
    )
  }

}
