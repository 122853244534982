import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { SponsorsService } from "../../client/services/sponsors.service";
import { BillingService } from '../services/billing.service';
@Component({
  selector: 'app-claim-dashboard',
  templateUrl: './claim-dashboard.component.html',
  styleUrls: ['./claim-dashboard.component.scss']
})
export class ClaimDashboardComponent implements OnInit {

  ActiveTab:number=1;

  globals: globalvars = new globalvars();
  subscription: Subscription;

  getAllSponsorsData:any;
  getAllSubAgentsDatas:any[] = [];

  constructor(
    private sharedservice: SharedService,
    private SponsorsService: SponsorsService,
    private billingService:BillingService,
    private router: Router,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        let locationId=this.globals.Location.locationId;
        this.globals.HospitalId;
      }
    });

   }

  ngOnInit(): void {

    this.GetAllSponsors();

  }

  chageTab(tabid){
    this.ActiveTab=tabid;
  }



  // GET SPONSERES


  GetAllSponsors(){

    let Input={
      "SponsorId":0,
      "Showall":1,
      "BranchId":this.globals.HospitalId
    }
    this.SponsorsService.GetAllSponsorList(Input).subscribe((res) => {
      this.getAllSponsorsData= res.response;
      this.loadSubAgens(0);
    });


  }

  ////////////////////////////////////////////////////////LOAD SUB AGENTS////////////////////////////////////////
  loadSubAgens(sponserId){

    if(sponserId==0){
      sponserId=this.getAllSponsorsData[0].sponsorId;
    }

    this.billingService.GetAgentForSponsor(sponserId).subscribe((res) => {
      let response=res.response;
      this.getAllSubAgentsDatas= response;
   });

  }

  //////////////////OPEN CLAIM/////////////

  openClaim(data){
    let input={
      sponsorId:data.sponsorId,
      fromDate:'',
      toDate:''
   }
   this.router.navigate(['/Claim'], { state: { searchData: input } });
  }

}
