import { Component, EventEmitter, Input, OnInit,Output,TemplateRef ,ViewChild, ViewEncapsulation  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { ColDef, GridApi } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../client/general-action/general-action.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from "ngx-toastr";
import { ConfigurationService } from "../../../client/services/configuration.service";
import { ResultForm } from '../../../client/interfaces/ResultForm';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-result-form',
  templateUrl: './assignService-modal.component.html',
  styleUrls: ['./assignService-modal.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AssignServiceModalComponent implements OnInit {

  @Input() BranchesId:any
  @Input() ServicePointId:any


  @Output() resultEmitter=new EventEmitter()


  @ViewChild("template") modaltemp: any;

  resultForm: FormGroup;
  DropdownSettings: IDropdownSettings = {};
  itemList: any;
  public modalColDef: ColDef;
  modalcolumnDefs: ColDef[] = [];
  selectedItem: ColDef[] = [];
  ServicesGroups:any;
  serviceItemBasket: any[] = [];
  rowGroupPanelShow = "always";
  public context;
  public gridApi;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize:8,
    rowClassRules: {
      // 'c-reds': function (params) { return params.data.active == '0'; },
      // 'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    }

  }
  OriginalServiceGroups: any[];

  sourceOriginal:any;
  selectedFiles:any;
  EventId:any
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  public itemGridApi: GridApi;
  sourceProducts:any;
  isLoading=false;
  constructor(private modalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private configurationService: ConfigurationService,


  ) { }

  ngOnInit(): void {
    this.resultForm =  new FormGroup({
      spasticityListData: new FormControl([]),

    });
    this.modalcolumnDefs = [

      {
        headerName: "Item Name",
        field: "itemName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
    ]
    this.selectedItem = [
      {
        headerName: "Item Name",
        field: "BaseCost",
        minWidth:150,
        // maxWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
      {
        headerName: "Remove",
        maxWidth: 80,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
    },

    ]

    this.modalColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.context = { componentParent: this };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
this.getServiceGroupItems()
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onGridReadyItem(params) {
    this.itemGridApi = params.api;
  }

close(){
  this.modalRef.hide();
}
getServiceGroupItems() {
  let input = {

    "BranchId": this.BranchesId,
    "PageCode": "SO"

  }
  this.configurationService.getServiceGroupItems(input).subscribe((res) => {
    this.ServicesGroups = res.response;
    this.OriginalServiceGroups = res.response;

  });
}
getAssignServicePoint(data){
  console.log(data.node.groupId);
  let input={
    BranchId: this.BranchesId,
    ServicePointId :this.ServicePointId,
    GroupIdList:[data.node.groupId]
  }
  this.configurationService.getAssignSerrvice(input).subscribe((res) => {
    this.ServicesGroups = res.response;
    // this.OriginalServiceGroups = res.response;

  });
  // this.selectedFiles = this.selectedFiles.filter((oppdata) => oppdata.groupId == this.EventId);

}
nodeUnselect(event) {
  this.ServicesGroups = [];
}

save(){

}
}