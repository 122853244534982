import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UsermanagementComponent implements OnInit {
	displayModal: boolean;
	displayModal1 : boolean;
	username='';
	password='';
 products: any=[];
 users:any=[];
 selectedCities: any[] = [];
    availableProducts:any=[];
    selectedProducts: any=[];
    draggedProduct: any=[];
  constructor() { }

  ngOnInit(): void {
       this.products = [{'code':'Administartion'},{'code':'Clinic'},{'code':'ICU'}];
	   this.users = [{'code':'test'},{'code':'user1'}];
	   this.availableProducts = [{'id':'1','category':'Administration','name':'bl','price':'100'},{'id':'2','category':'Management','name':'Bhagya','price':'500'}];       
  }
    
  dragStart(products) {
        this.draggedProduct = products;
    }
    
    drop(event) {
        if (this.draggedProduct) {
            let draggedProductIndex = this.findIndex(this.draggedProduct);
            this.selectedProducts = [...this.selectedProducts, this.draggedProduct];
            this.availableProducts = this.availableProducts.filter((val,i) => i!=draggedProductIndex);
            this.draggedProduct = null;
        }
    }
    
    dragEnd(event) {
        this.draggedProduct = null;
    }
    
    findIndex(product) {
        let index = -1;
        for(let i = 0; i < this.availableProducts.length; i++) {
            if (product.id === this.availableProducts[i].id) {
                index = i;
                break;
            }
        }
        return index;
    }
	
	 handleClick(event) {
		
    const className = 'user1';
    
  }

 showModalDialog() {
        this.displayModal = true;
    }
	showModalDialogedit() {
		this.username  = 'user1';
		this.password  = '123';
        this.displayModal1 = true;
    }

}
