import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { BillingGeneralActionComponent } from "../billing-general-action/billing-general-action.component";
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SponsorList } from "../../client/interfaces/SponsorList";
import { ConsultaionsService } from "../../client/services/consultations.service";
import { PatientService } from "../../client/services/patient.service";
import { ToastrService } from "ngx-toastr";
import { GenderList } from '../../client/interfaces/GenderList';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { BillingDashboardService } from '../../billing-settlement/dashboard/billingdashboard.service';
import { SharedService } from '../../../shared/shared.service';
import { DatePipe } from '@angular/common';
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { Router } from '@angular/router';
import { HospitalsService } from "../../client/services/hospitals.service";
import { CommonValidationComponent } from '../../../shared/common-validation/common-validation.component';
import {Location} from '@angular/common';
import { BillingService } from '../services/billing.service';
import { CancelBillModalComponent } from '../modals/cancel-bill-modal/cancel-bill-modal.component';
import { CancelSettlmentModalComponent } from '../modals/cancel-settlment-modal/cancel-settlment-modal.component';
import { Response } from '../../../_Models/Response';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { SettlementModalComponent } from '../modals/settlement-modal/settlement-modal.component';
import { NewRecieptModalComponent } from '../modals/new-reciept-modal/new-reciept-modal.component';
import { NewPaymentModalComponent } from '../modals/new-payment-modal/new-payment-modal.component';
import { ChangeConsultationCreditModalComponent } from '../modals/change-consultation-credit-modal/change-consultation-credit-modal.component';
import { ConfigurationService } from '../../client/services/configuration.service';
import { ChangeApprovalModalComponent } from '../../claim/modals/change-approval-modal/change-approval-modal.component';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';

@Component({
  selector: 'app-todays-billing',
  templateUrl: './todays-billing.component.html',
  styleUrls: ['./todays-billing.component.scss']
})
export class TodaysBillingComponent implements OnInit {

  //ag grid setup
  public statusBar;
  rowGroupPanelShow = "always";
  globals: globalvars = new globalvars();
  subscription: Subscription;

  public gridApi: GridApi;
  public billGridApi: GridApi;
  columnDefs: ColDef[] = [];
  todaysPatients: ColDef[] = [];
  patientBills: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "row-cancelled": function (params) {
        return (params.data.status.toLowerCase() == "c" || params.data.status.toLowerCase() == "cancelled");
      },
      "c-completed": function (params) {return params.data.status.toLocaleLowerCase() == "f"},
      "c-urgent": function (params) {
        return (params.data.status.toLocaleLowerCase() == "w" && params.data.emergency == 1);
      },
    },
    domLayout: 'autoHeight'
  }

  public billgridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "row-cancelled": function (params) {
        return (params.data.status.toLowerCase() == "c" || params.data.status.toLowerCase() == "cancelled");
      },
    },
    domLayout: 'autoHeight'
  }

  CurrentDate: any;
  billListingForm: FormGroup;
  getConsultationResponse: any;
  consultDate: any;

  public billList: any[] = [];

  private selectedConsultation: number;
  private baseUrl: string = '';

  branchesId: any;
  private changeCreditModalRef?: BsModalRef;
  functionPermissionData: any;
  credDetails: any;
  sponsorList: any[] = [];

  constructor(
    private toastr: ToastrService,
    private modalService: BsModalService,
    private sharedservice: SharedService,
    public datepipe: DatePipe,
    private NewBillingService: NewBillingService,
    private router: Router,
    private billingService: BillingService,
    private configurationManager: ConfigurationManager,
    private ConfigurationService: ConfigurationService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
        this.branchesId = this.globals.HospitalId;
      }
    });
    
  }

  ngOnInit(): void {

    sessionStorage.removeItem('bill-patient-regNo');
    sessionStorage.removeItem('isExternalLabBill');

    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.consultDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.CurrentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    
    this.billListingForm = new FormGroup({
      RegNos: new FormControl(""),
      PatientNames: new FormControl(""),
      BillNos: new FormControl(""),
      DateFromList: new FormControl(this.CurrentDate),
      DateToList: new FormControl(this.CurrentDate),
      PayStatus: new FormControl(''),

    });

    this.todaysPatients = [

      {
        headerName: "Patient Name",
        minWidth: 90,
        field: "patientName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        filter: true,
        flex: 6,
        cellRenderer: (params) => {

          if (params.data.gender == 1) {
            if(params.data.emergency == '1'){
              return (
                " <img   src='assets/images/xs/maleurgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
  
            }
            else{
            return (
              " <img  src='assets/images/xs/malegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );}
          } else if (params.data.gender == 2) {
            if(params.data.emergency == '1'){
              return (
                " <img   src='assets/images/xs/femaleurgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
  
            }
            else{
            return (
              " <img  src='assets/images/xs/femalegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );}
          } else {
            if(params.data.emergency == '1'){
              return (
                " <img   src='assets/images/xs/defaulturgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
  
            }
            else{
            return (
              " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );}
          }
        },
  
      },


      {
        headerName: "Reg.No.",
        minWidth: 75,
        field: "regNo",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 6,
      },
      {
        headerName: "Insurance",
        minWidth: 75,
        field: "sponsorName",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 6,
      },
      {
        headerName: "Consultant",
        minWidth: 75,
        field: "consultant",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 6,
      },
      {
        headerName: "Mdn",
        minWidth: 45,
        field: "mdnstatus",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 6,
      },
      {
        headerName: "Pay Status",
        minWidth: 75,
        field: "payStatus",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 6,
      },

      {
        headerName: "Action",
        maxWidth: 75,
        resizable: false,
        pinned: "right",
        sortable: false,
        field: "Id",
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "TodaysBilling" },
      },

    ];

    this.patientBills = [
      {
        headerName: "Bill No",
        minWidth: 75,
        field: "billNo",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Consultant",
        minWidth: 75,
        field: "consultantName",
        filter: true,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Bill Amount",
        minWidth: 75,
        field: "billAmount",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Bill Status",
        minWidth: 75,
        field: "status",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "billDetails" },
      },
    ]

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `<span">no rows to show</span>`;
    this.frameworkComponents = {
      buttonRender: BillingGeneralActionComponent,
    };
  
    // this.GetBillList();
    this.getTodaysConsultations();
    this.functionPermission();
  }
  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }

  onBillGridReady(params) {
    this.billGridApi = params.api;
  }


  getTodaysConsultations() {
    this.consultDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');

    let StartDateObj = this.billListingForm.value.DateFromList;
    let EndDateObj = this.billListingForm.value.DateToList;
    // date convertion
    if (typeof (StartDateObj) == "object") {
      let DateFromList = this.datepipe.transform(StartDateObj, 'dd-MM-yyyy');
      this.billListingForm.value.DateFromList = DateFromList;
    }
    if (typeof (EndDateObj) == "object") {
      let endDate = this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      this.billListingForm.value.DateToList = endDate;
    }

    let Input = {
      "consultantId": 0,
      "externalstatus": 0,
      "consultDate": this.billListingForm.value.DateFromList ? this.billListingForm.value.DateFromList : "",
      "payStatusinput": this.billListingForm.value.PayStatus ? Number(this.billListingForm.value.PayStatus) : -1,
      "RegNo": this.billListingForm.value.RegNos ? this.billListingForm.value.RegNos : "",
      "PatientName": this.billListingForm.value.PatientNames ? this.billListingForm.value.PatientNames : "",
      "branchId": this.globals.HospitalId,
    }

    this.NewBillingService.getBillingListConsultation(Input).subscribe((res) => {
      this.getConsultationResponse = res.response;
    },error=>{
      
    });
  }

  onEditBill(data: any) {
    console.log('this.credDetails', this.credDetails);
    let patientData = {
      patientData: data,
      OtherData: null,
      credDetails: this.credDetails,
      action: 'edit'
    }
    sessionStorage.setItem('billDetails', JSON.stringify(patientData));
    this.router.navigate(['../billitems/'+data.transId],{queryParams: {returnUrl: 'todaysbilling'}});
  }

  async onViewPrintBill(data: any){
    // console.log('data', data);
    await this.loadSponsorTable(data);
    const foundElement = this.functionPermissionData.find(x => x.id === 320)
    if (foundElement.id == 320 && foundElement.isAllowed == true) {
    let patientData = {
      patientData: data,
      OtherData: null,
      credDetails: this.credDetails,
      action: 'view'
    }
    sessionStorage.setItem('billDetails', JSON.stringify(patientData));
    sessionStorage.setItem('viewOnly', JSON.stringify({status: true}));
    this.router.navigate(['../billitems/'+data.transId],{queryParams: {returnUrl: 'todaysbilling'}});
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  refreshConsultation(){
    const foundElement = this.functionPermissionData.find(x => x.id === 326)
    if (foundElement.id == 326 && foundElement.isAllowed == true) {
    this.billListingForm.patchValue({
      RegNos: '',
      PatientNames: '',
      BillNos: '',
      DateFromList: this.CurrentDate,
      DateToList: this.CurrentDate,
      PayStatus: '',
    })
    
    this.getTodaysConsultations();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  getPatientBillList(event?){
    this.billList = [];
    this.selectedConsultation = event?.data?.consultationId ? event.data.consultationId : this.selectedConsultation;
    let payload = {
      Consultationid: this.selectedConsultation,
      BranchId: this.globals.HospitalId,
      PatientId: event ? event.data.patientId : 0
    }
    this.billingService.getConsultationBills(payload).subscribe((res) => {
      if(res.status == 200){
        this.billList = res.response
      }else{
        this.toastr.error(res.message)
      }
    });
  }

  cancelItem(data){
    let initialState = {
      TransId: Number(data.transId),
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(CancelBillModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
    modalRef.content.cancelEvent.subscribe(res=>{
      if(res.status){
        this.getPatientBillList();
      }
    });
    
  }

  openCancelSettlmentModal(data: any){
  let initialState = {
    TransId: Number(data.transId),
  }
  let config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: true,
  }
  const modalRef= this.modalService.show(CancelSettlmentModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
  modalRef.content.cancelSettlementEvent.subscribe(res=>{
    if(res.status){
      this.getPatientBillList();
    }
  });

  }

  openSettlmentScreen(data){
  let initialState = {
    transId: Number(data.transId),
    branchId: Number(this.globals.HospitalId),
    locationId: Number(this.globals.Location.locationId),
    action: 'settlement'
  }
  let config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: true,
  }
  const modalRef= this.modalService.show(SettlementModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
  modalRef.content.settlementEventEmit.subscribe(res=>{
    if(res.status){
      this.getPatientBillList();
    }
  });
  }

  onPrintInvoice(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 321)
    if (foundElement.id == 321 && foundElement.isAllowed == true) {
      let initialState = {
        modalHeading: 'Print Invoice',
        reportName: 'PrintInvoice.trdp',
        reportParams: {
          TransId : Number(data.transId),
          BranchId :this.globals.HospitalId,
          CreditId: Number(data?.creditId)
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  // let payload = {
  //   TransId : Number(data.transId),
  //   BranchId :this.globals.HospitalId
  // }

  // this.billingService.printBillInvoice(payload).subscribe({
  //   next: (response: Response<any>)=>{
  //     if(response.status == 200){
  //       window.open(this.baseUrl+response.response[0]?.reportPath, '_blank');
  //     }else{
  //       this.toastr.error(response.response)
  //     }
  //   },error: (error: any)=>{
  //     this.toastr.error('Something wents wrong, Try again...');
  //   }
  // })
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onFilterTextBoxChangedConsultation(event){
    this.gridApi.setQuickFilter(
      event.target.value
    );
  }

  onFilterTextBoxChangedBill(event){
    this.billGridApi.setQuickFilter(
      event.target.value
    );
  }

  onNewReciept(data: any){
    let initialState = {
      patientId: Number(data.patientId),
      globals: this.globals,
      regNo: data.regNo
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(NewRecieptModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    // this.router.navigate(['/receipt'], { state: { patientData: input } });
  }

  onNewRecieptBill(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 318)
    if (foundElement.id == 318 && foundElement.isAllowed == true) {
    let initialState = {
      patientId: Number(data.patientId),
      globals: this.globals,
      regNo: data.regNo,
      transId: Number(data.transId)
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(NewRecieptModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onNewPayment(data: any){
    let initialState = {
      patientId: Number(data.patientId),
      globals: this.globals,
      regNo: data.regNo
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(NewPaymentModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    // this.router.navigate(['/receipt'], { state: { patientData: input } });
  }

  onPrintUpFront(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 319)
    if (foundElement.id == 319 && foundElement.isAllowed == true) {
      let initialState = {
        modalHeading: 'Print Upfront',
        reportName: 'PrintUpfront.trdp',
        reportParams: {
          IsExternal: false,
          ShowPackName: true,
          TransId : Number(data.transId),
          BranchId :this.globals.HospitalId
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    // let payload = {
    //   IsExternal: false,
    //   ShowPackName: true,
    //   TransId : Number(data.transId),
    //   BranchId :this.globals.HospitalId
    // }

    // this.billingService.printUpfrontInvoice(payload).subscribe({
    //   next: (response: any)=>{
    //     if(response.status == 200){
    //       window.open(this.baseUrl+response.response[0]?.reportPath, '_blank')
    //     }else{
    //       this.toastr.error(response.errorMessage?.message)
    //     }
    //   },error: (error: any)=>{
    //     this.toastr.error('Something wents wrong, Try again...');
    //   }
    // })
    } else {
      this.toastr.warning("You are not allowed");
    }
   }

   onOpenCredit(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 315)
    if (foundElement.id == 315 && foundElement.isAllowed == true) {
    let input={
      patientId: data.patientId,
      creditId: '',
      patientName: data.patientName,
      regNo: data.regNo,
    }
    this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    } else {
      this.toastr.warning("You are not allowed");
    }
   }

   changeConsultationCredit(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 314)
    if (foundElement.id == 314 && foundElement.isAllowed == true) {
    let initialState = {
      branchesId: this.branchesId,
      patientId: data.patientId,
      rowData: data
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.changeCreditModalRef = this.modalService.show(ChangeConsultationCreditModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
    this.changeCreditModalRef.content.creditChange.subscribe(res=>{
      if(res.isChanged){
        this.getTodaysConsultations();
      }
    })
    } else {
      this.toastr.warning("You are not allowed to change credit");
    }
  }


  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  addNewBill() {
    const foundElement = this.functionPermissionData.find(x => x.id === 312)
    if (foundElement.id == 312 && foundElement.isAllowed == true) {
      this.router.navigate(["../newbill"]);
    } else {
      this.toastr.warning("You are not allowed to add new bill");
    }
  }

  externalItems(details) {
    const foundElement = this.functionPermissionData.find(x => x.id === 313)
    if (foundElement.id == 313 && foundElement.isAllowed == true) {
      sessionStorage.setItem('bill-patient-regNo', JSON.stringify({
        regNo: details?.regNo,
        consulatantID: details?.consultantId,
        creditId: details?.creditId,
        consulatationId: details?.consultationId
      }));
      let external = {
        isExternalStatus: true
      }
      sessionStorage.setItem('isExternalLabBill', JSON.stringify(external));
      this.router.navigate(['../newbill']);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onActionNewBill(details) {
    const foundElement = this.functionPermissionData.find(x => x.id === 316)
    if (foundElement.id == 316 && foundElement.isAllowed == true) {
      sessionStorage.setItem('bill-patient-regNo', JSON.stringify({
        regNo: details?.regNo,
        consulatantID: details?.consultantId,
        creditId: details?.creditId,
        consulatationId: details?.consultationId
      }));
      this.router.navigate(['../newbill']);
    } else {
      this.toastr.warning("You are not allowed to add new bill");
    }
  }

  onSettlement(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 317)
    if (foundElement.id == 317 && foundElement.isAllowed == true) {
      if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
        this.openSettlmentScreen(data);
      }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
        this.toastr.error('This bill is already settled..');
      }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
        this.toastr.error('This bill is cancelled..');
      }    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  cancelSettlement(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 322)
    if (foundElement.id == 322 && foundElement.isAllowed == true) {
      if (data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n') {
        this.toastr.error('This bill is not settled yet..');
      } else if (data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's' || data.status.toLowerCase() == 'resettled') {
        this.openCancelSettlmentModal(data);
      } else if (data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c') {
        this.toastr.error('This bill is already cancelled..');
      }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  cancelBill(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 323)
    if (foundElement.id == 323 && foundElement.isAllowed == true) {
      if (data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n') {
        this.cancelItem(data);
      } else if (data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's') {
        this.toastr.error('This bill is already settled, Cancel settlement first to cancel bill..');
      } else if (data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c') {
        this.toastr.error('This bill is already cancelled..');
      }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  async onActionEditBill(data) {
    // console.log('data', data);
    await this.loadSponsorTable(data);
    // this.credDetails = await this.loadSponsorTable(data).toPromise();
    const foundElement = this.functionPermissionData.find(x => x.id === 324)
    if (foundElement.id == 324 && foundElement.isAllowed == true) {
      if (data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's') {
        this.toastr.error('Settled bills are not allowed to edit..');
      } else if (data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c') {
        this.toastr.error('Cancelled bills are not allowed to edit..');
      } else {
        this.onEditBill(data);
      }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  async loadSponsorTable(data:any) {
    let selectedSponsor;
    let payload = {
      PatientId: data.patientId,
      Branchid: this.globals.HospitalId,
      ShowExpired: 1
    }
    let sponsorList = await this.billingService.GetSponsorDetailsByPatient(payload).toPromise();
    // this.billingService.GetSponsorDetailsByPatient(payload).subscribe((res) => {
    //   this.sponsorList = res.response;
    // });
    // console.log('sponsorList', sponsorList);
    sponsorList.response.find(x => {
      // console.log('x', x);
      if(x.isPrimary == true){
        this.credDetails = x;
      }
    });
    // this.getCreditDetails(this.sponsorList);
  }

  getCreditDetails(data) {
    let payload = {
      CreditId:data.creditId,
      SponsorId:data.sponsorId,
      Branchid: this.globals.HospitalId
    }
    this.billingService.getCreditDetailsForBilling(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.credDetails = response.response[0];
        }else{
          this.toastr.error(response.message);
        }
      }
    });
  }

  onChangeApproval(data: any){
    let initialState = {
      transId: Number(data.transId),
      branchId: this.globals.HospitalId,
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(ChangeApprovalModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
  }

  editCredit(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 540)
    if (foundElement?.id == 540 && foundElement.isAllowed == true) {
   let input={
       patientId:data.patientId,
       creditId:data.creditId,
       patientName:data.patientName,
       regNo:data.regNo
    }
    this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  async reSettlement(data){
    await this.loadSponsorTable(data);
    this.onResettleBill(data);
  }

  onResettleBill(data: any) {
    // if (data.billDate == this.datepipe.transform(new Date(), 'dd/MM/yyyy')) {
    //   console.log('date', this.datepipe.transform(new Date(), 'dd/MM/yyyy'))
      let patientData = {
        patientData: data,
        OtherData: null,
        credDetails: this.credDetails,
        action: 'resettlement'
      }
      sessionStorage.setItem('billDetails', JSON.stringify(patientData));
      this.router.navigate(['../billitems/' + data.transId], { queryParams: { returnUrl: 'billdetails' } });
    // }else{
    //   this.toastrService.warning("Only today's bill can be resettled");
    // }
  }

}
