import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReportRequest } from '../interfaces/ReportRequest';
import { EMRService } from '../../emr/services/emr.service';
import { BasicPatientInfo } from '../interfaces/BasicPatientInfo';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-emr-prints',
  templateUrl: './emr-prints.component.html',
  styleUrls: ['./emr-prints.component.scss']
})
export class EmrPrintsComponent implements OnInit {

  Request:ReportRequest;
  Type:number=0;
  DrugDetails:any[]=[];
  BasicPatientInfo:BasicPatientInfo=new BasicPatientInfo();
  currentDate:string;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  constructor(
    private EMRService:EMRService,
    public datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.currentDate=this.datepipe.transform(new Date(), "dd-MM-YYYY");

    this.Request = JSON.parse(localStorage.getItem("ReportRequest"));
    if(typeof this.Request !='undefined')
    {
      this.Type=this.Request.ReportType;
      switch (Number(this.Type)) {
        case 1:
          this.CurrentMedicationPrint();
          break;
      }
    }
  }


  printPage()
  {
    window.print();
  }
  close()
  {
    window.close();
  }

  CurrentMedicationPrint()
  {

    let EMRInfo=this.Request.ReportInput

    let input={
      "VisitId":EMRInfo.VisitId,
      "PatientId":EMRInfo.PatientId,
      "ShowAll":1
    };

    this.EMRService.GetDrugsEMR(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          if(response)
          {
            if(response.drugDetails){
             this.DrugDetails=response.drugDetails
            }
          }
        }
      });

      this.GetpatientInfo(EMRInfo.PatientId);
  }

  GetpatientInfo(PatientId:Number)
  {
    if(PatientId>0){
      this.EMRService.GetPatientInfo(PatientId).subscribe((res) => {
        this.BasicPatientInfo=res.response[0];
        // if(this.BasicPatientInfo.profilePicLocation)
        // {
        //   this.PatientImage=this.BasicPatientInfo.profilePicLocation;
        // }

      });
     }
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
     ws.A1.h =  "<b>A</b>";
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'DrugList.xlsx');
  }
}
