import { Component, OnInit,TemplateRef, ViewChild  } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from "../../emr/services/emr.service";
import { ToastrService } from "ngx-toastr";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Treatmentfollowup } from '../interfaces/Treatmentfollowup';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-follow-up-treatment-details',
  templateUrl: './follow-up-treatment-details.component.html',
  styleUrls: ['./follow-up-treatment-details.component.scss']
})
export class FollowUpTreatmentDetailsComponent implements OnInit {
  modalRef?: BsModalRef;
  form: FormGroup; // form variable
  public submitted: boolean = false;
  EMRPatientInfo: EMRPatientInfo;
  formData:Treatmentfollowup;
  btnName="Save";
  CurrentFollowUpData:Treatmentfollowup[]=[];
  @ViewChild("template") modaltemp1: any;
  disableSave: boolean=false;

  constructor(
    private EMRService: EMRService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private datepipe: DatePipe,
  ) {
    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {

    this.form = new FormGroup({
      tid: new FormControl(0),
      treatmentDate: new FormControl('',[Validators.required]),
      treatmentDetails: new FormControl('',[Validators.required]),
      isDeleting: new FormControl(0),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
    });
    this.GetCureentFollowUpdata();
  }
  openModal(template: TemplateRef<any>) {
    this.clearForm();
    this.btnName="Save";
    this.modalRef = this.modalService.show(template,{ backdrop: "static"});
  }
  saveTreatment(){
    this.submitted = true;
     if (this.form && this.form.valid) {
      this.formData=this.form.getRawValue();

      let date=this.formData.treatmentDate;
      this.formData.treatmentDate = this.datepipe.transform(date, 'dd-MM-yyyy');

      this.formData.userId=Number(localStorage.getItem('user_id'));
      this.disableSave=true
      this.EMRService.SaveFollowp(this.formData).subscribe((res) => {
        this.submitted = false;
        this.disableSave=false
        if (res.status == 200 && res.message == 'Success') {
          this.toastr.success("Data saved successfully");
          this.modalRef?.hide()
         this.GetCureentFollowUpdata();
         this.clearForm();
        }
        else{
          this.toastr.error(res.message);
        }

      })


     }
  }
  clearForm(){
    this.form.reset({
      tid:0,
      treatmentDate:'',
      treatmentDetails:'',
      isDeleting:0,
      visitId:this.EMRPatientInfo.VisitId,
      userId:Number(localStorage.getItem("user_id"))
    })
  }

  GetCureentFollowUpdata(){
    let input={
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 1,
    };
    //CurrentFollowUpData
    this.EMRService.GetFollowUpDetails(input).subscribe((res) => {
      if (res.status == 200) {
       this.CurrentFollowUpData=res.response;
      }
    });

  }
  editData(data){
    this.form.patchValue(data);
    this.btnName="Update";
    this.modalRef = this.modalService.show(this.modaltemp1,{ backdrop: "static"});
  }
  deleteData(data){
    Swal.fire({
      title: 'Are you sure?',
      text: 'To delete this details',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        data.isDeleting=1;
        this.form.patchValue(data);
        let formdata=this.form.getRawValue();
        formdata.isDeleting=1;
        formdata.userId=Number(localStorage.getItem('user_id'));
        this.EMRService.SaveFollowp(formdata).subscribe((res) => {

          if (res.status == 200 && res.message == "Success") {
            Swal.fire(
              'Done!',
              'Data deleted successfully.',
              'success'
            )
            ;
            this.clearForm();
            this.GetCureentFollowUpdata();
          }
          else{
            Swal.fire(
              'Error!',
              'Some unexpected error occures! try again later.',
              'error'
            )
          }

         });
         }
    })
  }
}
