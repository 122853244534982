import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationReportComponent } from './registration-report/registration-report.component';
import { ReportsComponent } from './reports.component';

import { SignupComponent } from '../client/pages/signup/signup.component';
import { DashboardComponent } from '../client/dashboard/dashboard.component';
import { ConsultationReportComponent } from './consultation-report/consultation-report.component';
import { AppointmentReportComponent } from './appointment-report/appointment-report.component';
import { BillReportComponent } from './bill-report/bill-report.component';
import { BillSettlementDetailsComponent } from './bill-settlement-details/bill-settlement-details.component';
import { DayEndReportComponent } from './day-end-report/day-end-report.component';
import { ReceiptAndPaymentReportComponent } from './receipt-and-payment-report/receipt-and-payment-report.component';
import { TreatmentDetailsComponent } from './treatment-details/treatment-details.component';
import { RevenueReportsComponent } from './revenue-reports/revenue-reports.component';
import { AuthsGuard } from '../../auths.guard';
import { UserLogReportComponent } from './user-log-report/user-log-report.component';
import { CollectionSummarayComponent } from './collection-summaray/collection-summaray.component';
import { CollectionSummarayDaywiseComponent } from './collection-summaray-daywise/collection-summaray-daywise.component';
import { CollectionSummarayGroupwiseComponent } from './collection-summaray-groupwise/collection-summaray-groupwise.component';
import { ConsultantDrugSalesComponent } from './consultant-drug-sales/consultant-drug-sales.component';
import { ConsultantWiseBillDetailsComponent } from './consultant-wise-bill-details/consultant-wise-bill-details.component';
import { DailyCollectionSummaryComponent } from './daily-collection-summary/daily-collection-summary.component';
import { DailySalesReportComponent } from './daily-sales-report/daily-sales-report.component';
import { DiscountedBillDetailsComponent } from './discounted-bill-details/discounted-bill-details.component';
import { EPrescriptionReportComponent } from './e-prescription-report/e-prescription-report.component';
import { ItemWiseSaleComponent } from './item-wise-sale/item-wise-sale.component';
import { ItemWiseSaleSummaryComponent } from './item-wise-sale-summary/item-wise-sale-summary.component';
import { PackageSaleComponent } from './package-sale/package-sale.component';
import { RevenueItemwiseDiscountComponent } from './revenue-itemwise-discount/revenue-itemwise-discount.component';
import { RevenueSummaryReportComponent } from './revenue-summary-report/revenue-summary-report.component';
import { SpecialDiscountsDetailsComponent } from './special-discounts-details/special-discounts-details.component';
import { SponsorRevenueComponent } from './sponsor-revenue/sponsor-revenue.component';
import { SponsorRevenueMonthwiseComponent } from './sponsor-revenue-monthwise/sponsor-revenue-monthwise.component';
const routes: Routes = [
  {
    path: 'Registration_Report',
    component: RegistrationReportComponent,
    data: { title:'LeHealth | Reports | Registration Report', pageName: 'Registration Report' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'consultation-report',
    component:ConsultationReportComponent,
    data: { title:'LeHealth | Reports | consultation Report', pageName: 'Consultation Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'appointment-report',
    component: AppointmentReportComponent,
    data: { title:'LeHealth | Reports | Appointment Report', pageName: 'Appointment Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'revenue-report',
    component:RevenueReportsComponent,
    data: { title:'LeHealth | Reports | Revenue Report', pageName: 'Revenue Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'bill-report',
    component:BillReportComponent,
    data: { title:'LeHealth | Reports | Bill Report', pageName: 'Bill Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'bill-settlement-details',
    component:BillSettlementDetailsComponent,
    data: { title:'LeHealth | Reports | Bill Settlement Details', pageName: 'Bill Settlement Details' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'day-end-report',
    component:DayEndReportComponent,
    data: { title:'LeHealth | Reports | Day End Report', pageName: 'Day End Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'receipt-and-payment-report',
    component:ReceiptAndPaymentReportComponent,
    data: { title:'LeHealth | Reports | Receipt And Payment Report', pageName: 'Receipt And Payment Report' },
    canActivate : [AuthsGuard]

  },

  {
    path: 'treatment-details',
    component:TreatmentDetailsComponent,
    data: { title:'LeHealth | Reports |Treatment Details ', pageName: 'Treatment Details' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'user-log-report',
    component:UserLogReportComponent,
    data: { title:'LeHealth | Reports | User Log Report ', pageName: 'User Log Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'collection-summary-report',
    component:CollectionSummarayComponent,
    data: { title:'LeHealth | Reports | Collection Summary Report ', pageName: 'Collection Summary Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'collection-summary-daywise-report',
    component:CollectionSummarayDaywiseComponent,
    data: { title:'LeHealth | Reports | Collection Summary Daywise Report ', pageName: 'Collection Summary Daywise Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'collection-summary-groupwise-report',
    component:CollectionSummarayGroupwiseComponent,
    data: { title:'LeHealth | Reports | Collection Summary Groupwise Report ', pageName: 'Collection Summary Groupwise Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'collection-summary-groupwise-report',
    component:CollectionSummarayGroupwiseComponent,
    data: { title:'LeHealth | Reports | Collection Summary Groupwise Report ', pageName: 'Collection Summary Groupwise Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'consultant-drug-sale',
    component:ConsultantDrugSalesComponent,
    data: { title:'LeHealth | Reports | Consultant Drug Sale ', pageName: 'Consultant Drug Sale' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'consultant-wise-bill-details',
    component:ConsultantWiseBillDetailsComponent,
    data: { title:'LeHealth | Reports | Consultant Wise Bill Details ', pageName: 'Consultant Wise Bill Details' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'daily-collection-summary',
    component:DailyCollectionSummaryComponent,
    data: { title:'LeHealth | Reports |Daily Collection Summary ', pageName: 'Daily Collection Summary' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'daily-sales-report',
    component:DailySalesReportComponent,
    data: { title:'LeHealth | Reports |Daily Sales Report ', pageName: 'Daily Sales Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'discounted-bill-details',
    component:DiscountedBillDetailsComponent,
    data: { title:'LeHealth | Reports |Discounted Bill Details ', pageName: 'Discounted Bill Details' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'eprescription-report',
    component:EPrescriptionReportComponent,
    data: { title:'LeHealth | Reports |EPrescription Report ', pageName: 'EPrescription Report' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'itemwise-sale',
    component:ItemWiseSaleComponent,
    data: { title:'LeHealth | Reports |ItemWise Sale ', pageName: 'ItemWise Sale' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'itemwise-sale-summary',
    component:ItemWiseSaleSummaryComponent,
    data: { title:'LeHealth | Reports |ItemWise Sale Summary', pageName: 'ItemWise Sale Summary' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'package-sale',
    component:PackageSaleComponent,
    data: { title:'LeHealth | Reports |Package Sale', pageName: 'Package Sale' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'revenue-itemwise-discounts',
    component:RevenueItemwiseDiscountComponent,
    data: { title:'LeHealth | Reports |Revenue ItemWise Discount', pageName: 'Revenue ItemWise Discount' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'revenue-summary',
    component:RevenueSummaryReportComponent,
    data: { title:'LeHealth | Reports |Revenue Summary', pageName: 'Revenue Summary' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'special-discount-details',
    component:SpecialDiscountsDetailsComponent,
    data: { title:'LeHealth | Reports |Special Discount Details', pageName: 'Special Discount Details' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'sponsor-revenue',
    component:SponsorRevenueComponent,
    data: { title:'LeHealth | Reports |Sponsor Revenue', pageName: 'Sponsor Revenue' },
    canActivate : [AuthsGuard]

  },
  {
    path: 'sponsor-revenue-monthwisw',
    component:SponsorRevenueMonthwiseComponent,
    data: { title:'LeHealth | Reports |Sponsor Revenue Monthwise', pageName: 'Sponsor Revenue Monthwise' },
    canActivate : [AuthsGuard]

  },






 







 ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule {
  static components = [
    SignupComponent,
    DashboardComponent

  ];
}
