import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ColDef, GridOptions } from "ag-grid-community";
import { Subscription } from "rxjs";
import { GeneralGridActionComponent } from "../../../shared/general-grid-action/general-grid-action.component";
import { globalvars } from "../../../shared/models/GlobalVars";
import { SharedService } from "../../../shared/shared.service";
import { Location, LocationTypes } from "../../client/interfaces/Location";
import { LocationService } from "./location.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationService } from "../../client/services/configuration.service";
@Component({
  selector: "app-location",
  templateUrl: "./location.component.html",
  styleUrls: ["./location.component.scss"],
})
export class LocationComponent implements OnInit {
  modalRef?: BsModalRef;

  location: any;
  LocationTypes: LocationTypes[];
  columnDefs = [];
  public gridApi;
  private gridColumnApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public form: FormGroup;
  AssociationForm: FormGroup;
  displayModal: boolean;
  displayModal1: boolean;
  supervisor = "";
  locationname = "";
  Mobilenumber = "";
  isLoading = false;

  public submitted: boolean = false;
  globals: globalvars = new globalvars();
  products: any = {};
  rowData: any = [];
  subscription: Subscription;
  selectedCities: any[] = [];
  selectedAssociativeLocation: any[] = [];
  statusBar: any;
  DisplayedId: any;
  displayModalAssociate: boolean;
  dropdownSettings: IDropdownSettings = {};
  AssociativedropdownSettings: IDropdownSettings = {};
  rowData1: any[];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    // rowClassRules: {
    //   'c-reds': function (params) { return params.data.active == '0'; },
    // },
  };

  @ViewChild("template") modaltemp: any;
  @ViewChild("template1") modaltempAssociativeLocation: any;
  user_id: any;
  LocationId: any;
  result: any;
  ActiveChecking: any;
  IsDisplayed: any;
  //rowData1: any;
  AssociativeLocation: any;
  functionPermissionData: any;

  constructor(
    private locationservice: LocationService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private router: Router,
    private ConfigurationService: ConfigurationService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.GetLocations();
      }
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'locationId',
      textField: 'locationName',
      // selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,

    };
    this.AssociativedropdownSettings = {
      singleSelection: false,
      idField: 'locationId',
      textField: 'locationName',
      // selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,
    }

    this.user_id = localStorage.getItem("user_id");

    this.form = new FormGroup({
      locationName: new FormControl("", [Validators.required]),
      locationId: new FormControl(0),
      supervisor: new FormControl("", [Validators.required]),
      contactNumber: new FormControl("", [Validators.required]),
      lTypeId: new FormControl(0),
      manageSPoints: new FormControl(false),
      manageBilling: new FormControl(false),
      manageCash: new FormControl(false),
      manageCredit: new FormControl(false),
      // manageIPCredit: new FormControl(false),
      //active: new FormControl(),
      blockReason: new FormControl(""),
      repHeadImg: new FormControl(""),
      IsDisplayed: new FormControl(false),
      UserId: new FormControl(Number(this.user_id)),
    });

    this.AssociationForm = new FormGroup({
      Associatelocation: new FormControl(""),
    })
    this.columnDefs = [
      {
        headerName: "Location Name",
        minWidth: 180,
        field: "locationName",
        resizable: true,
        unSortIcon: true,
        flex: 3,
        filter: true,
      },
      {
        headerName: "Supervisor",
        minWidth: 180,
        field: "supervisor",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Contact Number",
        minWidth: 180,
        field: "contactNumber",
        enableRowGroup: true,
        resizable: false,
        filter: true,
        flex: 2,

      },

      // {
      //   headerName: "locationType",
      //   field: "lType",
      //   enableRowGroup: true,
      //   resizable: true,
      //   filter: true,
      //   flex: 1,
      // },
      // {
      //   headerName:"Structural",
      //  field:"StructuralStatus",
      //  enableRowGroup: true,
      //  resizable: true,
      //  unSortIcon: true,
      //  flex:2,

      // },
      // {
      //   headerName:"Instrumentation",
      //  field:"InstrumentationStatus",
      //  enableRowGroup: true,
      //  resizable: true,
      //  unSortIcon: true,
      //  flex:2,

      // },
      {
        headerName: "Action",
        maxWidth: 180,
        pinned: "right",
        field: "locationId",
        enableRowGroup: true,
        resizable: false,
        filter: false,
        sortable: false,
        cellRenderer: "actionsComponentRenderer",
        cellRendererParams: {
          Page: "Location",
        },
        flex: 2,
      },

      // ik
    ];
    this.defaultColDef = {
      sortable: true,

      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    // this.valueDate =  this.datepipe.transform(new Date(),'yyyy-MM-dd');
    // let dte = new Date();
    // dte.setDate(dte.getDate() - 28);

    // this.valueDate2=this.datepipe.transform(dte.toString(),'yyyy-MM-dd');

    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };

    this.frameworkComponents = {
      actionsComponentRenderer: GeneralGridActionComponent,
    };
    this.context = { componentParent: this };
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  Save() {
    this.submitted = true;
    if (this.form && this.form.valid) {
      this.location = this.form.getRawValue();
      if (this.location.IsDisplayed == true) {
        this.location.IsDisplayed = 1;

      }
      else {
        this.location.IsDisplayed = 0;

      }
      this.location.hospitalId = this.globals.HospitalId;
      this.location.lTypeId = Number(this.form.get("lTypeId").value);
      if (this.LocationId) {
        this.location.locationId = this.LocationId;
        this.location.UserId = Number(this.user_id);
      }
      let Locdata = 0;
      if (this.location.locationId > 0) {
        Locdata = this.location.locationId;
      }

      this.locationservice.Save(this.location).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        // alert(res.message);
        if (this.result.status == 200 && this.result.message == 'Success') {
          if (Locdata == 0) {
            this.toastr.success("Location Added successfully");
          }
          else {
            this.toastr.success("Location Updated  successfully");
            this.location.locationId = 0;
          }
          this.modalRef.hide();
          this.GetLocations();
          this.clearForm();
          this.LocationId = 0;

        }
        else {
          this.toastr.error("Failed : " + this.result.message);
        }


        this.GetLocations();
        // if(this.result.message=="Success"){
        this.submitted = false;
        this.displayModal = false;
        this.form.reset({
          locationId: 0,
          lTypeId: 0,
          manageSPoints: false,
          manageBilling: false,
          manageCash: false,
          manageCredit: false,
          // manageIPCredit:false,
          active: false,
          blockReason: "",
          repHeadImg: "",
        });


        // }

      });
      this.modalRef.hide();
    }
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  GetLocation(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 486)
    if (foundElement.id == 486 && foundElement.isAllowed == true) {
    this.locationservice
      .GetLocations(this.globals.HospitalId, id)
      .subscribe((res) => {
        this.location = res.response;
        if (this.location[0].isDisplayed == 1) {
          this.IsDisplayed = true;

        }
        else {
          this.IsDisplayed = false;
        }
        this.LocationId = this.location[0].locationId;
        this.form.patchValue(this.location);
        this.form.patchValue({ locationName: this.location[0].locationName });
        this.form.patchValue({ supervisor: this.location[0].supervisor });
        this.form.patchValue({ contactNumber: this.location[0].contactNumber });
        this.form.patchValue({ lTypeId: this.location[0].lTypeId });
        this.form.patchValue({ manageCash: this.location[0].manageCash });
        this.form.patchValue({ manageCredit: this.location[0].manageCredit });
        this.form.patchValue({ manageSPoints: this.location[0].manageSPoints });
        this.form.patchValue({ manageBilling: this.location[0].manageBilling });
        this.form.patchValue({ IsDisplayed: this.IsDisplayed });
        this.form.patchValue({ blockReason: this.location[0].blockReason });
        this.form
        this.modalRef = this.modalService.show(
          this.modaltemp,
          Object.assign({}, { class: "gray modal-lg" })
        );
      });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  GetLocations() {
    //alert(JSON.stringify(this.globals));
    this.locationservice
      .GetLocations(this.globals.HospitalId, 0)
      .subscribe((res) => {
        this.rowData = res.response;
      });


    // this.rowData.forEach((a) => {
    //   (a.id = a.locationId), (a.text = a.locationName);
    // });
  }
  GetLocationsType() {
    //alert(JSON.stringify(this.globals));
    this.locationservice.GetLocationsType().subscribe((res) => {
      this.LocationTypes = res.response;
    });
  }
  ngOnInit(): void {
    this.user_id = localStorage.getItem("user_id");
    this.GetLocationsType();
    this.functionPermission();
  }

  get f() {
    return this.form.controls;
  }

  showModalDialog() {
    this.displayModal = true;
  }
  showModalDialogedit() {
    this.locationname = "Medical college trivandrum";
    this.supervisor = "user1";
    this.Mobilenumber = "7890654321";
    this.displayModal1 = true;
  }

  openModal(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 485)
    if (foundElement.id == 485 && foundElement.isAllowed == true) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-lg" })
    );
    this.submitted = false;
    this.clearForm();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  CloseLocationModal() {
    this.LocationId = 0,
      this.form.reset({
        locationId: 0,
        lTypeId: 0,
        manageSPoints: false,
        manageBilling: false,
        manageCash: false,
        manageCredit: false,
        // manageIPCredit:false,
        active: false,
        blockReason: "",
        repHeadImg: "",
      });
  }

  AssociateLocation(Id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 487)
    if (foundElement.id == 487 && foundElement.isAllowed == true) {
    this.modalRef = this.modalService.show(
      this.modaltempAssociativeLocation
    );
     this.LocationId = Id
    this.displayModalAssociate = true;
    this.locationservice
      .GetLocations(this.globals.HospitalId, 0)
      .subscribe((res) => {
        this.rowData = res.response;
      });

    this.rowData1 = this.rowData.filter(locationId => locationId.locationId != this.LocationId);

    this.rowData1.forEach((a) => {
      (a.id = a.locationId), (a.text = a.locationName);
    });
    let Input = {
      "LocationId": Id,
      "ShowAll": 1
    }
    this.locationservice.GetAssociativeLocationById(Input).subscribe((res) => {
      this.AssociativeLocation = res.response;
      //this.selectedAssociativeLocation = [];

      if (this.AssociativeLocation.length != 0) {
        this.AssociationForm.controls['Associatelocation'].setValue(this.AssociativeLocation);
        document.getElementById("myDiv").click();

      }

    });

    this.AssociationForm.reset({
      Associatelocation: "",

    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  SaveAssociateLocation() {

    let AssociatelocationId = this.AssociationForm.get("Associatelocation").value;
    if (AssociatelocationId.length == 0) {
      this.toastr.error("Failure: " + "Please select Locations");
    }
    else {
      this.isLoading = true;

      this.locationservice.SaveAssociateLocations(this.LocationId, AssociatelocationId).subscribe((res) => {
        this.isLoading = false;
        this.toastr.success(res.message);
        this.isLoading = false;

        this.modalRef.hide();
        this.GetLocations();
        this.submitted = false;
        this.displayModalAssociate = false;
        this.form.reset({
          Associatelocation: "",
        });
      });
    }

  }
  Servicepoints(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 488)
    if (foundElement.id == 488 && foundElement.isAllowed == true) {
    if(data.manageSPoints==true){
    this.router.navigate(['/config/subLocationSettings'],{ queryParams: { id: data.locationId }});
    }
    else{
      this.toastr.warning("Please Enable Manage Service Point");

    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  // to clear form
  clearForm() {

    this.form.reset({
      locationName: '',
      locationId: 0,
      supervisor: '',
      contactNumber: '',
      lTypeId: 0,
      manageSPoints: false,
      manageBilling: false,
      manageCash: false,
      manageCredit: false,
      blockReason: '',
      repHeadImg: '',
      IsDisplayed: false,
      UserId: Number(this.user_id)

    });
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }
}