import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppointmentsList } from '../../interfaces/AppointmentsList';
import { AppointmentsService } from '../../services/appointments.service';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { ScheduleService } from "../../services/schedule.service";
import { ConsoleLogger } from '@generic-ui/ngx-grid/common/cdk/logger/console.logger';
@Component({
  selector: 'app-postpone-appointment',
  templateUrl: './postpone-appointment.component.html',
  styleUrls: ['./postpone-appointment.component.scss']
})
export class PostponeAppointmentComponent implements OnInit {
  @Input() PostponeObj;
  @Output() reload = new EventEmitter();
  AppId: number;
  Departments: any[];
  BranchId: number;
  PostponedAppointments: boolean;
  postponeddate: any;
  scheduleTime: any[] = [];
  appDate: any;
  curentAppdate:any;
  consultDataId: any[];
  AppointmentDataDetails: AppointmentsList[];
  dropdownSettings: IDropdownSettings = {};
  result: any;
  minDate: Date;
  form: FormGroup;
  patientId: any;
  service: ScheduleService;
  schedule: any;
  consultId: any;
  appointments:any[]=[];
  CurrentappointmentDate: string;

  constructor(
    private appointmentService: AppointmentsService,
    private datepipe: DatePipe,
    private router: Router,
    private toastr: ToastrService,
    private scheduleService: ScheduleService,
  ) {

    this.service = scheduleService;

    this.form = new FormGroup({
      slicetime: new FormControl(''),
      date: new FormControl(''),
      consultantList: new FormControl(''),
      PostPonedDepartment: new FormControl(''),
      PostPonedConsultant: new FormControl(''),
    });
    let currentappDate = new Date();
    this.CurrentappointmentDate=this.datepipe.transform(currentappDate, 'dd-MM-yyyy');

  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'sliceNo',
      textField: 'sliceTime',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.minDate = new Date();
    // this.SearchAppointment();
  }

  ngOnChanges() {
    if (typeof this.PostponeObj != "undefined") {
      this.AppId = this.PostponeObj.AppId;
      this.BranchId = this.PostponeObj.BranchId;
      this.AppointmentPostPoned(this.AppId);
    }

  }

  AppointmentPostPoned(appId) {
    this.AppId = appId;
    this.Departments = [];
    this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      // let appdate= this.AppointmentDataDetails?.[0]["appDate"];
      this.appDate = this.AppointmentDataDetails["appDate"];
      this.curentAppdate=this.appDate;
      this.consultDataId = [this.AppointmentDataDetails["consultantId"]];
      this.consultId = this.AppointmentDataDetails["consultantId"]
      this.Departments.push(this.AppointmentDataDetails["deptId"]);
      this.form.patchValue({ PostPonedDepartment: this.AppointmentDataDetails["departmentName"] });
      setTimeout(() => {
        //this.form.patchValue({PostPonedDepartment: [{deptId: this.AppointmentDataDetails[0]["deptId"], deptName:this.AppointmentDataDetails[0]["departmentName"] }]});
        this.form.patchValue({ slicetime: this.AppointmentDataDetails["sliceData"] });
        this.patientId = this.AppointmentDataDetails["patientId"];

        this.GetScheduledData();
      }, 1000);
      // setTimeout(() => {
      this.form.get("PostPonedDepartment").patchValue(this.AppointmentDataDetails["departmentName"]);
      this.form.get("PostPonedConsultant").patchValue(this.AppointmentDataDetails["consultantName"]);
      this.form.get("date").patchValue(this.appDate);

      this.form.get("date").value;
      //  }, 100);

      this.PostponedAppointments = true;
    });
  }

  onDateChange(value){

    this.appDate = this.AppointmentDataDetails["appDate"];
    if(this.appDate !=value){

      if (typeof (value) == "object") {
        this.appDate = this.datepipe.transform(value, 'dd-MM-yyyy');
       }
      this.GetScheduledData() ;
    }
}

  GetScheduledData() {
    this.appointmentService.GetAppointmentSchedule(this.consultDataId, this.appDate, this.Departments, this.BranchId).subscribe((res) => {
      if(res.response[0]){
        this.scheduleTime = res.response[0]['labels'];
        this.scheduleTime = this.scheduleTime.map(v => {
           return v.appId > 0 && v.appId!=this.AppId ? {...v, isDisabled: true} : {...v,isDisabled: false}
         });
      }
      else{
        this.scheduleTime=[];
      }


      if(this.curentAppdate==this.appDate){
        this.form.patchValue({ slicetime: this.AppointmentDataDetails["sliceData"] });
      }
      else{
        this.form.patchValue({ slicetime: []});
      }

    })

  }
  InsertPostponnedAppointments() {

    this.postponeddate = this.form.get("date").value;

    if (typeof (this.postponeddate) == "object") {

      this.postponeddate = this.datepipe.transform(this.postponeddate, 'dd-MM-yyyy');

    }
    else {
      this.postponeddate = this.form.get("date").value;
    }
    let sliceData = this.form.get("slicetime").value;
    let sliceDataDetails = [];
    sliceData.forEach(a => {
      sliceDataDetails.push({ "sliceNo": Number(a.sliceNo), "sliceTime": a.sliceTime });
    });

    var AppData = {
      appId: this.AppId,
      BranchId: this.BranchId,
      PatientId: this.patientId,
      PostPonedConsultant: this.consultId,
      PostPonedDepartment: this.form.get("PostPonedDepartment").value,
      AppDate: this.postponeddate,
      SliceTime: sliceDataDetails,
      //  SliceNo: 0,
      UserId: Number(localStorage.getItem('user_id')),

    };

    this.appointmentService.SavePostponnedAppointments(AppData).subscribe((res) => {
      this.result = res;

      if (this.result.status == 200) {


        this.toastr.success("Postponned Appointment added successfully");
        this.PostponedAppointments = false;
        this.reload.emit(true);
        // this. SearchAppointment();

      } else {
        this.toastr.error("Postponned Appointment failed");
      }
    });

  }
  // SearchAppointment() {
  //   this.appointmentService.SearchAppointment(this.form,this.CurrentappointmentDate,this.postponeddate).subscribe((res) => {
  //     this.appointments = res.response;
  //     if (this.appointments.length == 0) {
  //       this.toastr.warning("No Appointments found");
  //     }
  //   });
  // }
  // LoadAppTable() {

  //   this.SearchAppointment();
  //   //this.searchevent
  //   if(this.searchevent){
  //   this.searchevent;
  //   }
  // }
}
