import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { ReportsService } from '../services/reports.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';

@Component({
  selector: 'app-revenue-reports',
  templateUrl: './revenue-reports.component.html',
  styleUrls: ['./revenue-reports.component.scss']
})
export class RevenueReportsComponent implements OnInit {

  form: FormGroup; // form variable
  subscription: Subscription;
  globals: globalvars = new globalvars();
  consultants: any[] = [];
  GetConsultantsData: any;
  GetGroupItems: any;
  isPdfLoaded: boolean = false;
  // private pdf: PDFDocumentProxy;
  isLoading = false;
  private baseUrl = "";

  src: any = '';
  public document;


  constructor(
    public datepipe: DatePipe,
    private sharedservice: SharedService,
    private NewBillingService: NewBillingService,
    private ReportsService: ReportsService,
    private sanitizer: DomSanitizer,
    private configurationManager: ConfigurationManager,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });

    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      Type: new FormControl("1"),
      FromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ToDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      GroupId: new FormControl(0),
      ConsultantId: new FormControl(0),
      HideReg: new FormControl(0),
      BranchId: new FormControl(this.globals.HospitalId),
    });
    this.loadConsultants();
    this.GetItemGroups();
  }
  generateReport() {
    this.isLoading = true;
    let input = this.form.getRawValue();
    input.GroupId=Number(input.GroupId);
    if (typeof (input.FromDate) == "object") {
      let dobConverted = this.datepipe.transform(input.FromDate, 'dd-MM-yyyy');
      input.FromDate = dobConverted;
    }

    if (typeof (input.ToDate) == "object") {
      let dobConverted = this.datepipe.transform(input.ToDate, 'dd-MM-yyyy');
      input.ToDate = dobConverted;
    }

    if (input.ConsultantId) {
      input.ConsultantId = Number(input.ConsultantId);
    }

    if (input.HideReg == true) {
      input.HideReg = 1;
    }
    else {
      input.HideReg = 0;
    }

    this.ReportsService.GetRevenueReport(input).subscribe((res) => {
      this.isLoading = false;
      if (res.status == 200) {
        let response = res.response;
        this.src = response[0].reportPath;
        this.src = this.baseUrl + this.src;
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
        this.isPdfLoaded = true;
      }
    });
  }

  loadConsultants() {
    let Input = {
      "BranchId": Number(this.globals.HospitalId),
      "IsExternal": 3
    }

    this.NewBillingService.getConsultantByHospital(Input).subscribe((res) => {
      this.consultants = res.response;

      this.GetConsultantsData = [];
      this.consultants.forEach((a) => {
        this.GetConsultantsData.push({
          id: a.consultantId.toString(),
          text: a.consultantName,
        });
      }); 

    });

  }

  GetItemGroups() {
    let Input = {
      "BranchId": Number(this.globals.HospitalId),
      "GroupId": 0,
      "ShowAll": 0
    }

    this.ReportsService.GetItemGroups(Input).subscribe((res) => {
      let response = res.response;
      this.GetGroupItems = [];
      response.forEach((a) => {
        this.GetGroupItems.push({
          id: a.groupId.toString(),
          text: a.groupName,
        });
      });

    });

  }

}
