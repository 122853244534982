import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Subscription } from "rxjs";
import { globalvars } from "../../../shared/models/GlobalVars";
import { ColDef, GridApi, GridOptions, Logger } from "ag-grid-community";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { GeneralGridActionComponent } from "../../../shared/general-grid-action/general-grid-action.component";
import { TreatmentGeneralActionComponent } from "../treatment-general-action/treatment-general-action.component";
import { TreatmentsService } from "../services/treatments.service";
import { ConfigurationService } from "../../client/services/configuration.service";
import { SharedService } from '../../../shared/shared.service';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { EMRService } from '../../emr/services/emr.service';
import { EMRPatientInfo } from "../../emr/interfaces/EMRPatientInfo";

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { log } from "console";
import { finished } from "stream";

@Component({
  selector: "app-physiothearapy-treatment",
  templateUrl: "./physiothearapy-treatment.component.html",
  styleUrls: ["./physiothearapy-treatment.component.scss"],
})
export class PhysiothearapyTreatmentComponent implements OnInit {
  form: FormGroup; // form variable

  treatmentdetailTab: boolean;
  rangeofmotionTab: boolean;
  treatmentTab: boolean = true;
  summaryTab: boolean;
  public treatmentOrderForm: FormGroup;
  public gridApi: GridApi;
  public gridApi1: GridApi;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  // modalRefVitalsign: BsModalRef;
  // modalRef2?: BsModalRef;
  private serviceItenSelect: any[] = [];
  phyId: any;
  phtreatmentData: any = [];
  RightData: any = [];
  consultationId: any;
  checkedValues: any[] = [];
  dynamicArray: any[] = [];
  arrayLength:number;
  is_locked:any
  // phtreatmentcolumnDefs = [];
  // RightcolumnDefs = [];
  selsctedServiceItemData: any[] = [];
  BranchesId: any;
  Physiotherapist: any[] = [];
  modalityData: any = [];
  dailyMovementData: any = [];
  TendernessData: any = [];
  SpasticityData: any = [];
  MovementData: any = [];
  bodypartsDropdownSettings: IDropdownSettings = {};
  public submitted: boolean = false;
  result: any;
  indexId: any;
  physioIdFromPopup: any;
  daysValue: any;
  PhysioId: any;
  EMRPatientInfo: EMRPatientInfo;
  nameArr:any = false;
  numberArr:any = false;
  dateArr:any = false;
  // modalitycolumnDefs = [];
  // dailyMovementcolumnDefs = [];
  // TendernesscolumnDefs = [];
  // SpasticitycolumnDefs = [];
  // MovementcolumnDefs = [];

  // phtreatmentDefs: ColDef[] = [];
  // RightDefs: ColDef[] = [];

  // modalityDefs: ColDef[] = [];
  // dailyMovementDefs: ColDef[] = [];
  // TendernessDefs: ColDef[] = [];
  // SpasticityDefs: ColDef[] = [];
  // MovementDefs: ColDef[] = [];

  // phDetailsData: any = [
  //   {
  //     day: 1,
  //     bodyparts: "Head",
  //     painscale: 30,
  //     billrequest: "USA",
  //     treatdate: 30,
  //     notes: "USA",
  //     performingstaff: 30,
  //     startdate: "USA",
  //     endDate: "USA",
  //   },
  // ];
  // phDetailscolumnDefs = [];
  // phDetailsDefs: ColDef[] = [];

  statusBar: any;
  isLoading = false;

  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  // grid setup

  private gridColumnApi;
  public rowSelection = true;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public phtreatmentdefaultColDef: ColDef;

  public phDetailsdefaultColDef: ColDef;

  // public RightdefaultColDef: ColDef;
  // public modalitydefaultColDef: ColDef;
  // public dailyMovementdefaultColDef: ColDef;
  // public TendernessdefaultColDef: ColDef;
  // public SpasticitydefaultColDef: ColDef;
  // public MovementdefaultColDef: ColDef;

  modalRef: BsModalRef;
  CreateOrderPopupHeading: string = "Add Created Order";
  @ViewChild("template") modaltemp: any;
  getserviceorderData: any;
  bodypartsList: any[] = [];
  patientId: any;
  consultantId: any;
  phyDateData: any[] = [];
  lastUsedId: number = 0;
  phydetID: any;
  orderDetIdAtIndex0: any[] = [];
  todate: string = "";
  visitId:any;
  buttondisable:number=0;
  billFlag = false;
  secondIndex: number;
  billRequest: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private router: Router,
    private TreatmentsService: TreatmentsService,
    private configurationService: ConfigurationService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    private _location: Location,
    private datepipe: DatePipe,
    private EMRService: EMRService,


  ) {
    this.frameworkComponents = {
      actionsComponentRenderer: GeneralGridActionComponent,
    };
    this.context = { componentParent: this };
    if (this.router.getCurrentNavigation().extras.state) {
      this.patientId = this.router.getCurrentNavigation().extras.state.id;
      this.consultationId = this.router.getCurrentNavigation().extras.state.consultationId;
      this.consultantId = this.router.getCurrentNavigation().extras.state.consultantId;
      this.visitId = this.router.getCurrentNavigation().extras.state.visitId;

    }
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });

  }

  ngOnInit(): void {
    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();

    let currentappDate = new Date();

    // this.todate = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    // this.todate = this.datepipe.transform(new Date(), 'dd-MM-yyyy hh:mm:ss');
    let timeZoneData=this.sharedservice.timeZoneData
    this.todate = this.datepipe.transform(new Date(), 'dd-MM-yyyy',);
    console.log(this.EMRPatientInfo);
    
    this.form = new FormGroup({
      consultationId: new FormControl(this.EMRPatientInfo.ConsultationId),
      branchId: new FormControl(this.BranchesId),
      patientId: new FormControl(this.EMRPatientInfo.PatientId),
      consultantId: new FormControl(this.EMRPatientInfo.ConsultantId),
      SpointId: new FormControl(0),

      ItemDetailsList: new FormArray([]),
      PhyDetails: new FormArray([]),

    });
    this.bodypartsDropdownSettings = {
      singleSelection: false,
      idField: "bodyId",
      textField: "bodyDesc",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,
    };
    this.GetBodyparts();
    this.GetAllStaff();
    this.getsummary();

  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
  onGridReady1(params) {
    this.gridApi1 = params.api;
  }

  AddSessionDetails(data) {
    this.router.navigate(["/physiothearapy-treatmentDetails"]);
  }
  EditSessionDetails(data) {
    this.router.navigate(["/physiothearapy-treatmentDetails"]);
  }
  onFilterTextBoxChanged() {
    this.gridApi1.setQuickFilter(
      (document.getElementById("filter-text-box") as HTMLInputElement).value
    );
  }
  //tab
  onTab(number) {
    this.treatmentTab = false;
    this.treatmentdetailTab = false;
    this.rangeofmotionTab = false;
    this.summaryTab = false;
    if (number == "1") {
      this.treatmentTab = true;
    } else if (number == "2") {
      this.treatmentdetailTab = true;
    } else if (number == "3") {
      this.rangeofmotionTab = true;
    } else if (number == "4") {
      this.summaryTab = true;
      let id = this.consultationId
      this.router.navigate(['/physiothearapy-summary'], { state: { id: id } });

    }
  }


  openModalCreatedOrder(template: TemplateRef<any>) {
    this.selsctedServiceItemData=[];

    if(this.is_locked==0){
    this.modalRef = this.modalService.show(template, {
      class: "modal-md",
      backdrop: "static",
    });

    //get service item by patient
    this.checkedValues=[];
    this.selsctedServiceItemData = [];
    // this.serviceItenSelect=[];
    let input = {
      ConsultationId: this.EMRPatientInfo.ConsultationId,
    };
    this.TreatmentsService.GetServiceItemByConsultation(input).subscribe(
      (res) => {
        this.getserviceorderData = res.response;
        this.serviceItenSelect = this.getserviceorderData;
        this.getserviceorderData.map((res:any)=>{
          res.isChecked = false
        })
      }
    );
    }else{
      this.toastr.warning("This Treatment iS Locked");
    }
  }

  Activetab(pagetitle) {
    if(this.is_locked==0){
    sessionStorage.setItem("pageTitle", pagetitle);
    this.router.navigate(['/serviceOrder'], { state: { patientId:this.EMRPatientInfo.PatientId, consultationId:this.EMRPatientInfo.ConsultationId, phyId: this.phyId } });
    }else{
      this.toastr.warning("This Treatment iS Locked");

    }
  }
  Activetab1(index) {
    let phyID=(this.form.get('PhyDetails') as FormArray).controls[index].get('PhysioDetId').value
    let finishedStatus=(this.form.get('PhyDetails') as FormArray).controls[index].get('finished_status').value

    let PainScale=(this.form.get('PhyDetails') as FormArray).controls[index].get('PainScale').value
    localStorage.setItem("phyID", JSON.stringify(phyID));
    localStorage.setItem("PainScale", JSON.stringify(PainScale));
    localStorage.setItem("PhysioId", JSON.stringify(this.PhysioId));
    // localStorage.setItem("is_locked", JSON.stringify(this.is_locked));

    if (phyID > 0 ) {
      if( finishedStatus!="Finished"){
      this.router.navigate(["/physiothearapy-treatmentDetails"], { state: { consultationId:this.EMRPatientInfo.ConsultationId, phydetID: phyID, PhysioId: this.PhysioId ,PainScale:PainScale} });
    }else{
      this.toastr.warning("Items Finished ");
 
    }
    } else {
      this.toastr.warning("Please Save Your Days Datas");

    }
  }
  allCheckboxChange(){
    this.getserviceorderData.map((res:any)=>{
      res.isChecked = !res.isChecked
      if(res.isChecked){
        this.selsctedServiceItemData.push(res);
      } else {
        this.selsctedServiceItemData.splice(0,this.getserviceorderData.length);
      }
    })
  }
  checkboxChanged(checked: boolean, value: number, text: number) {
    if (checked == true) {
      let input = {
        orderDetId: value,
        itemName: text,
        // orderdetId:orderDetId
      };
      this.checkedValues.push(input);
      this.selsctedServiceItemData.push(input);


    }
    else {
      // const index = this.checkedValues.indexOf(value);
      const index = this.checkedValues.findIndex(item => item.orderDetId === value);
      if (index !== -1) {
        this.checkedValues.splice(index, 1);
        this.selsctedServiceItemData.splice(index, 1);

      }
    }

  }
  newdata() {

    this.saveServiceItemData(this.selsctedServiceItemData)

    this.modalRef.hide();
  }
  public addMapFormGroup(data?: any[]) {
    (this.form.get('ItemDetailsList') as FormArray).clear();
    const rangedatas = this.form.get("ItemDetailsList") as FormArray;

    data.forEach(item => {
      rangedatas.push(this.createListGroup(item));
    });
  }
  private createListGroup(data?): FormGroup {
    return new FormGroup({
      PhysioId: new FormControl(data.physioId?data.physioId:this.physioIdFromPopup),

      Physiono: new FormControl(data.physioNo?data.physioNo:this.phyId),
      itemName: new FormControl(data ? data.itemName : ''),
      OrderDetId: new FormControl(data ? data.orderDetId : 0),
      NoOfDays: new FormControl(data ? data.noOfDays : 0),
      NoOfDaysFromDb: new FormControl(data ? data.approvedDays : 0),
      ApprovalDate: new FormControl(''),
      ApprovedDays: new FormControl(data ? data.approvedDays : 0),
      TreatFrom: new FormControl(data ? data.treatFrom : ''),
      TreatTo: new FormControl(data ? data.treatTo : ''),
      Remarks: new FormControl(data ? data.remarks : ''),
      ApprovalNo: new FormControl(data ? data.approvalNo : ''),
      FinishedCount:new FormControl(data.finishedCount),
    });
  }
  handleKeyPress(indexs) {
    // let compareId=(this.form.get('ItemDetailsList') as FormArray).controls[indexs].get('PhysioId').value
    // if( this.PhysioId==compareId || this.PhysioId==undefined){
    const formArray = this.form.get('PhyDetails') as FormArray;
    var length = formArray.length;
    this.indexId = indexs;
    let arrayLength =length;
    this.dynamicArray = [];
    const noOfDaysValue = (this.form.get('ItemDetailsList') as FormArray).controls[indexs].get('ApprovedDays').value;
    if (Number(noOfDaysValue) > arrayLength) {
      let diffVAlue = Number(noOfDaysValue) - arrayLength;
      const newElements = Array.from({ length: Number(diffVAlue) }, (_, index) => {
        return { day: arrayLength + index + 1 };
      });
      this.dynamicArray = [...this.dynamicArray, ...newElements];
    } 
    this.numberOfDays();
    // this.GetBodyparts();
    // this.GetAllStaff();
  // }else{
  //   this.toastr.warning("Please choose details using the action button")
  // }
  }
  numberOfDays() {

    this.addDaysMapFormGroup(this.dynamicArray)
  }
  public addDaysMapFormGroup(data?: any[]) {
    const rangedatas = this.form.get("PhyDetails") as FormArray;

    data.forEach(item => {
      rangedatas.push(this.createDaysListGroup(item));
    });
  }
  private createDaysListGroup(data?): FormGroup {
    return new FormGroup({

      DayId: new FormControl(data.day ? data.day : data.dayId),
      PhysioDetId: new FormControl(data ? data.phyDetId : 0),
      BodyPartIds: new FormControl(data ? data.bodyParts : []),
      PainScale: new FormControl(data ? data.painImprovement : 0),
      bill_request: new FormControl(data ? data.bill_request : 0),
      TreatDate: new FormControl(data.treatDate != undefined ? data.treatDate : this.todate),
      Notes: new FormControl(data ? data.notes : ''),
      PerformingStaffId: new FormControl(data ? data.performingStaff : 0),
      StartTime: new FormControl(data.startTime != undefined ? data.startTime :0),
      EndTime: new FormControl(data.endTime != undefined ? data.endTime : 0),
      finished_status:new FormControl(data.finishStatus)
    });
  }
  GetBodyparts() {
    let input = {
      bodyId: 0,
      branchId: this.globals.HospitalId,
      ShowAll: 1,
    };
    this.configurationService.GetBodyparts(input).subscribe((res) => {
      this.bodypartsList = res.response;
    });
  }
  searchSponsor(sponsorName) {

    this.getserviceorderData = this.serviceItenSelect.filter(x =>{
      return(
        x.itemName.toLowerCase().includes(sponsorName.toLowerCase()) || x.orderNumber.toLowerCase().includes(sponsorName.toLowerCase())
      )
    } );


  }
  saveServiceItemData(data) {
    const idsArray = data.map(item => item.orderDetId);
    if(idsArray.length!=0){
    let input = {
      BranchId: this.globals.HospitalId,
      ConsultationId:  this.EMRPatientInfo.ConsultationId,
      OrderDetIds: idsArray,
      PatientId:this.EMRPatientInfo.PatientId,
      SpointId: 0
    }
    
    this.TreatmentsService.PhySaveItemFromOrder(input).subscribe(
      (res) => {
        this.toastr.success(" Data Saved Successfully");
        let physionumber = res.response;
        this.phyId = physionumber[0].physioNo;
        this.physioIdFromPopup = physionumber[0].physioId;
        this.addMapFormGroup(this.checkedValues);

      }
    );
    }
    else{
      this.toastr.warning("Please Select Items");
    }
  }
  gotoEMR() {
    if(this.is_locked==0){
    let vId= this.EMRPatientInfo.VisitId;
    if( vId==0){
      let timeZoneData=this.sharedservice.timeZoneData
      let cDate = new Date();
      let startTime = this.datepipe.transform(cDate, 'dd-MM-yyyy hh:mm:ss a',timeZoneData);
  
      let patientInfo = {
        'VisitId':0,
        'ConsultantId': this.EMRPatientInfo.ConsultantId,
        'ConsultationId': this.EMRPatientInfo.ConsultationId,
        'PatientId':  this.EMRPatientInfo.PatientId,
        'VisitType': 1,
        'VisitStartTime': startTime,
        'VisitEndTime': '',
        'UserId': Number(localStorage.getItem('user_id')),
        'CrudType': 0,
        InsAmountLimit: 0,
        insCovered: 0,
      }
      this.EMRService.InsertVisit(patientInfo).subscribe((res) => {

        if (res.status == 200 && res.message == "Success") {
          let result = res.response;
          result = this.EMRService.upper(res.response);
          let patientInfo = {
            'VisitId':  result.VisitId,
            'ConsultantId': this.EMRPatientInfo.ConsultantId,
            'ConsultationId': this.EMRPatientInfo.ConsultationId,
            'PatientId':  this.EMRPatientInfo.PatientId,
            'VisitType': 0,
            'VisitStartTime': '',
            'VisitEndTime': '',
            'UserId': Number(localStorage.getItem('user_id')),
            'CrudType': 3,
            InsAmountLimit: 0,
            insCovered: 0,
          }
          localStorage.setItem("physioVisible", JSON.stringify(1));
          localStorage.setItem("physiohistoryVisit", JSON.stringify(1));

          let OutPut = this.EMRService.SetEMRPatientInfo(patientInfo);
    
          // this.EMRPatientInfo.VisitId = result.VisitId;
          this.router.navigate(['/emr-physiothearapy'], { state: { patientId: this.patientId, consultationId: this.consultationId,tab:'Ortho'} });
        }

      });

    }else{
      localStorage.setItem('consultationId', this.consultationId);

      let patientInfo = {
        'VisitId': this.EMRPatientInfo.VisitId,
        'ConsultantId': this.EMRPatientInfo.ConsultantId,
        'ConsultationId': this.EMRPatientInfo.ConsultationId,
        'PatientId':  this.EMRPatientInfo.PatientId,
        'VisitType': 0,
        'VisitStartTime': '',
        'VisitEndTime': '',
        'UserId': Number(localStorage.getItem('user_id')),
        'CrudType': 3,
        InsAmountLimit: 0,
        insCovered: 0,
      }
      localStorage.setItem("physioVisible", JSON.stringify(1));
      localStorage.setItem("physiohistoryVisit", JSON.stringify(1));

      let OutPut = this.EMRService.SetEMRPatientInfo(patientInfo);
  
      this.router.navigate(['/emr-physiothearapy'], { state: { patientId: this.patientId, consultationId: this.consultationId,tab:'Ortho'} });
  
  
    }
  }else{
    this.toastr.warning("This Treatment is Locked");
  }
  }
  RemoveFormArray(index, data?) {
    var Value = new FormArray([]);
    Value = this.form.get(data) as FormArray;
    Value.removeAt(index);
  }
  GetAllStaff() {
    this.configurationService.GetStaff(this.BranchesId).subscribe((res) => {
      let StaffData = res.response;
      this.Physiotherapist = StaffData.filter(x => x.categoryId === 7);

    });

  }

  save(fnId) {
    if(this.is_locked==0){
    this.submitted = true;
    if (this.form && this.form.valid) {
      var formdata = this.form.getRawValue();
      if (this.indexId >= 0) {
        this.orderDetIdAtIndex0 = this.form.get('ItemDetailsList')['controls'][this.indexId].value;
        formdata.ItemDetailsList = [this.orderDetIdAtIndex0];

      }

      if (formdata.PhyDetails.length == 0) {
        this.toastr.warning("Please Select days datas");
      }
      else if( this.orderDetIdAtIndex0['ApprovedDays']!=formdata.PhyDetails.length){
        this.toastr.warning("The details table content and the number of days do not match");

       }

      else {
        // const phyDetailsArray =formdata.PhyDetails;
        // let isValidated=1;
        // phyDetailsArray.forEach((item) => {
        
        //   if (item.BodyPartIds== null || item.BodyPartIds.length==0 ) {
        //     isValidated=0;
        //     this.toastr.warning("Please Select bodyparts" );
        //   }

        // });
        // if(isValidated==1){
          formdata.ItemDetailsList = formdata.ItemDetailsList.map((item) => {
          
            if (typeof (item.TreatFrom) == "object") {
              item.TreatFrom = this.datepipe.transform(item.TreatFrom, 'dd-MM-yyyy');
    
            }
            else {
              item.TreatFrom = item.TreatFrom.replaceAll('/', '-');
            }
            if (typeof (item.TreatTo) == "object") {
              item.TreatTo = this.datepipe.transform(item.TreatTo, 'dd-MM-yyyy');
    
            }
            else {
              item.TreatTo = item.TreatTo.replaceAll('/', '-');
            }
  
  
            return {
              NoOfDays:Number(item.NoOfDays),
              ApprovedDays: item.ApprovedDays == null ? 0 : item.ApprovedDays,
              TreatFrom: item.TreatFrom == null ? '' : item.TreatFrom,
              TreatTo: item.TreatTo == null ? '' : item.TreatTo,
              Remarks: item.Remarks == null ? '' : item.Remarks,
              ApprovalNo: item.ApprovalNo == null ? '' : item.ApprovalNo,
              PhysioId: item.PhysioId,
              OrderDetId: item.OrderDetId == null ? '' : item.OrderDetId,
  
  
  
  
            }
          })
          formdata.PhyDetails = formdata.PhyDetails.map((item) => {
            if (typeof (item.TreatDate) == "object") {
              item.TreatDate = this.datepipe.transform(item.TreatDate, 'dd-MM-yyyy');
    
            }
            else {
              item.TreatDate = item.TreatDate.replaceAll('/', '-');
            }
  
            return {
  
  
              ...item,
              DayId:Number(item.DayId),
              BodyPartIds: item.BodyPartIds == null ? [] : item.BodyPartIds,
              PhysioDetId: item.PhysioDetId == null ? 0 : item.PhysioDetId,
              PainScale: item.PainScale == null ? 0 : Number(item.PainScale),
              bill_request: item.bill_request == null ? false: item.bill_request,
              TreatDate: item.TreatDate == null ? '' : item.TreatDate,
              Notes: item.Notes == null ? '' : item.Notes,
              PerformingStaffId: item.PerformingStaffId == null ? 0 : Number(item.PerformingStaffId),
              StartTime: item.StartTime == 0 ? '' : item.StartTime,
              EndTime: item.EndTime == 0 ? '' : item.EndTime,
  
            }
          })
  
          this.isLoading = true;
  
          this.TreatmentsService.PhySaveUpdateHome(formdata).subscribe(
            (res) => {
              this.result = res;
              if (this.result.status == 200 && this.result.message == "Success") {
                this.isLoading = false;
                if(fnId == 1){
                this.toastr.success(" Data Saved Successfully");
                }
                this.detailedData(this.indexId);
  
                 this.createDaysListGroup();
                // (this.form.get('PhyDetails') as FormArray).clear();
                // this.display = false;
                // this.GetBodyparts();
              } else {
                this.isLoading = false;
                this.toastr.error("Failed : " + this.result.message);
              }
            });
  
        // }


      }
    }
  }
  else {
   this.toastr.warning("This Treatment Is Locked");

 }

  }
  existingList() {
    (this.form.get('ItemDetailsList') as FormArray).clear();

    (this.form.get('PhyDetails') as FormArray).clear();

    let input = {
      PatientId: this.EMRPatientInfo.PatientId,
      ConsultationId:this.EMRPatientInfo.ConsultationId,
    }
    this.TreatmentsService.PhyGet(input).subscribe(
      (res) => {
        this.checkedValues = res.response;
        if (this.checkedValues.length > 0) {

          this.addMapFormGroup(this.checkedValues);

        } else {
          this.toastr.warning("No items");

        }

      }
    );

  }
   detailedData(index?) {
  //  this.indexId=index>=0?index:this.indexId;
  this.indexId=index;
    const orderDetIdAtIndex0 = this.form.get('ItemDetailsList')['controls'][index].value;
    this.PhysioId = orderDetIdAtIndex0['PhysioId'];
    let numberOfDays = orderDetIdAtIndex0['ApprovedDays'];
    let numberOfDaysFromDb=orderDetIdAtIndex0['NoOfDaysFromDb']; 
    if(numberOfDaysFromDb==null){
      numberOfDaysFromDb=0;
    } 
    if(numberOfDays>=numberOfDaysFromDb){

    let input = {
      PhysioId: orderDetIdAtIndex0['PhysioId']
    }
    this.TreatmentsService.PhyGetDayDetail(input).subscribe(
       (res) => {
        this.phyDateData = res.response;
        if (this.phyDateData.length > 0) {
          (this.form.get('PhyDetails') as FormArray).clear();
          this.phydetID = this.phyDateData[0].phyDetId;
          
          this.addDaysMapFormGroup(this.phyDateData);
          this.handleKeyPress(index)
        } else {
          (this.form.get('PhyDetails') as FormArray).clear();
          this.handleKeyPress(index)

          this.toastr.warning("No Saved Details");

        }

      }
    );
  }
else{
  this.toastr.warning("Please enter a value that is higher than" + numberOfDaysFromDb);

}

  }
  goback() {
    this._location.back();
  }
  FinishPhysiotherapy(index) {

    if (this.phydetID > 0) {
      if(this.is_locked==0){
        let continueSave = true;
        const orderDetIdAtIndex0 = this.form.get('PhyDetails')['controls'][index].value;
        if (orderDetIdAtIndex0.PerformingStaffId == 0) {
          this.toastr.error('Please enter staff');
          continueSave = false;
          return;
        }
        if (orderDetIdAtIndex0.StartTime == '') {
          this.toastr.error('Please enter start time');
          continueSave = false;
          return;
        }
        if (orderDetIdAtIndex0.EndTime == '') {
          this.toastr.error('Please enter end time');
          continueSave = false;
          return;
        }
        if (orderDetIdAtIndex0.BodyPartIds == null || orderDetIdAtIndex0.BodyPartIds.length == 0) {
          this.toastr.error('Please enter body part');
          continueSave = false;
          return;
        }

    if(continueSave == true){
      this.save(2);
    let input = {
      PhysioDetId: orderDetIdAtIndex0['PhysioDetId'],
      DayId:orderDetIdAtIndex0['DayId'],
    }
    this.TreatmentsService.FinishPhysiotherapy(input).subscribe(
      (res) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          this.existingList();
          this.detailedData();
        }

      });
  }
    }else{
      this.toastr.warning("This Treatment is Locked");

    }


    } else {
      this.toastr.warning("Please Save Your Days Datas");

    }

  }
  getsummary() {
    let input = {
      ConsultationId: this.EMRPatientInfo.ConsultationId,
    };
    this.TreatmentsService.GetSummary(input).subscribe((res) => {
      let getSummaryData = res.response[0];
     this.is_locked = getSummaryData["isLocked"];
    });
  }

  sortBy(data:any){
    switch (data) {
      case "name":
        {
          this.nameArr = !this.nameArr
          this.nameSort()
          break;
        }
      case "number":
        {
          this.numberArr = !this.numberArr
          this.numberSort()
          break;
        }
      case "date":
        {
          this.dateArr = !this.dateArr
          this.dateSort()
          break;
        }
    }
  }
  nameSort(){
    this.getserviceorderData =this.getserviceorderData.sort((x:any,y:any)=>
      this.nameArr ?
       ( y.itemName.localeCompare(x.itemName)) : (  x.itemName.localeCompare(y.itemName))
    )
  }
  numberSort(){
    this.getserviceorderData =this.getserviceorderData.sort((x:any,y:any)=>{
      return this.numberArr ?
      ( y.orderNumber.localeCompare(x.orderNumber)) : (  x.orderNumber.localeCompare(y.orderNumber))
    })
  }
  dateSort(){
    this.getserviceorderData =this.getserviceorderData.sort((x:any,y:any)=>{
      let xdate = new Date(x.orderDate)
      let ydate = new Date(y.orderDate)
      return this.dateArr ?
       ( ydate.getTime() - xdate.getTime()) :( xdate.getTime() - ydate.getTime())
    })
  }
  doAction(event:any, i){
    this.secondIndex = i;
    if(event.target.checked == true){
      this.billFlag = true;
    }
  }

  confirm(data){
    if(data == 'yes'){
      this.form.get("PhyDetails").value[this.secondIndex].bill_request = true;
      this.billRequest = true;
    } else if(data == 'no'){
      this.form.get("PhyDetails").value[this.secondIndex].bill_request = false;
      this.billRequest = false;
    }
    this.billFlag = false;
  }

}
