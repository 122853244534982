import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PatientList } from '../../interfaces/PatientList';
import { PatientService } from '../../services/patient.service';
import { Router } from '@angular/router';
import { Departments } from '../../interfaces/Departments';
import { Consultants } from '../../interfaces/Consultants';
import { HospitalsService } from '../../services/hospitals.service';
import { DatePipe } from '@angular/common';
import { Select2OptionData } from 'ng-select2';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../shared/shared.service';
import { AppointmentsService } from '../../services/appointments.service';
import { AppointmentTypesList } from '../../interfaces/AppointmentTypesList';
import { ServiceOrderingService } from '../../services/ServiceOrderingService';
import { CommonValidationComponent } from '../../../../shared/common-validation/common-validation.component';

@Component({
  selector: 'SearchPatientComponent',
  templateUrl: './searchpatient.component.html',
  styleUrls: ['./searchpatient.component.scss']

})
export class SearchPatientComponent implements OnInit {
  @Input() pagetype;
  @Output() returnevent = new EventEmitter();
  @Output() returnAllevent = new EventEmitter();
  @Output() event = new EventEmitter();
  date1: Date;
  opened = false;
  displayBasic: boolean;
  displayBasicUser: boolean;
  displaydialpad: boolean;
  patientResult: any;
  patients: PatientList[] = [];
  searchForm: FormGroup;

  isCollapsed = true;
  dtOptions: any = {};
  info: string;

  user_role: any;
  user_id: any;
  router_url = this.router.url;
  radioSelected: number;
  departments: any;
  departmentsData: Array<Select2OptionData> = [];
  ClinicsData: Array<Select2OptionData> = [];
  consultants: Consultants[] = [];
  consultantsData: Array<Select2OptionData> = [];
  patientmodal: any;
  gender: any;
  patientName: any;
  address: any;
  mobile: any;
  regDate: any;
  regNo: any;
  patientId: any;
  active: string;
  subscription: Subscription;
  globals: globalvars = new globalvars();

  rowData: PatientList[] = [];
  bsValue = new Date();
  bsRangeValue: Date[];
  minDate: Date;
  valueDate: Date;
  valueDate2: Date;
  userType: any;
  myDisabledCondition: boolean = false;
  public validator = new CommonValidationComponent();
  toastr: any;
  minDate1 = new Date();
  CurrentappointmentDate: string;
  appointmentDate: string;
  BranchesId: any;
  // minDate: Date;

  //ag grid setup
  appointmentTypesList: AppointmentTypesList[] = [];
  radiodate1: boolean;
  hospitalList: any;
  consultanttype: any;
  hospitalIds: any;
  valueDateService: string;
  valueDateFrom: string;
  departmentId: any;
  ConsultantId: any;


  constructor(private patientService: PatientService,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private router: Router,
    private hospitalsService: HospitalsService,
    private sharedservice: SharedService,
    private appointmentService: AppointmentsService,
    private ServiceOrderingService: ServiceOrderingService,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {
    this.departmentId = localStorage.getItem("departmentId");
    this.ConsultantId = localStorage.getItem('Id');
    this.userType = localStorage.getItem("usertype");

    this.minDate = new Date();
    this.valueDate = new Date();
    let dte = new Date();
    dte.setDate(dte.getDate() - 7);
    this.valueDate2 = dte;
    this.rowData = [];
    this.minDate1.setDate(this.minDate1.getDate() - 1);
    let currentappDate = new Date();
    this.CurrentappointmentDate = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    let convertDate = currentappDate.setDate(currentappDate.getDate() + 7);
    this.appointmentDate = this.datepipe.transform(convertDate, 'dd-MM-yyyy');
    this.user_role = localStorage.getItem('user_role');
    this.user_id = localStorage.getItem('user_id');
    let currentSerDate = new Date();
    this.valueDateService = this.datepipe.transform(currentSerDate, 'dd-MM-yyyy');
    let convertSerDateFrom = currentSerDate.setDate(currentSerDate.getDate() - 7);
    this.valueDateFrom = this.datepipe.transform(convertSerDateFrom, 'dd-MM-yyyy');
    this.GetDepartments();
    this.GetAppointmentType();
    this.GetUserHospitals();
    setTimeout(() => {
      this.loadConsultantService(3);
    }, 3000);
    this.searchForm = this.formBuilder.group({
      sName: new FormControl(''),
      sMobile: new FormControl(''),
      sRegNo: new FormControl(''),
      sAddress: new FormControl(''),
      sEmiratesNo: new FormControl(''),
      sPin: new FormControl(''),
      sDate: new FormControl(''),
      sResNo: new FormControl(''),
      sDepartment: new FormControl(''),
      sconsultant: new FormControl(''),
      sDateFrom: new FormControl(this.valueDate2),
      sDateTo: new FormControl(this.valueDate),
      sPhone: new FormControl(''),
      blockReason: new FormControl(''),
      sDateFromapp: new FormControl(this.CurrentappointmentDate),
      sDateToapp: new FormControl(this.appointmentDate),
      sAppointmentType: new FormControl(''),
      sBranch: this.globals.HospitalId,
      sConsultantId: new FormControl(''),
      RegtrnNo: new FormControl(''),
      patientname: new FormControl(''),
      orderNo: new FormControl(''),
      servicedatefrom: new FormControl(''),
      servicedateto: new FormControl(''),
      serviceconsultants: new FormControl(''),
      consultanttype: new FormControl('3'),
      clinicname: new FormControl(''),
      ServiceDateFrom: new FormControl(this.valueDateFrom),
    })



    function Ctrl($scope) {
      $scope.date = new Date();
    }


    if (this.userType !== "U") {
      this.myDisabledCondition=true;
      this.searchForm.patchValue({ sDepartment: this.departmentId });
      this.loadConsultant(this.departmentId);
      this.searchForm.patchValue({ sconsultant: this.ConsultantId })
      this.searchForm.patchValue({ serviceconsultants: this.ConsultantId })
    }


  }



  /* ============ page title to be displayed in header============ */
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);
  // }


  //getting departments and consultants
  GetDepartments() {
    let ShowAll=0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId,ShowAll).subscribe((res) => {
      this.departments = res.response;
      this.departmentsData = [];
      this.departmentsData.push({ id: "0", text: "Select All" });
      this.departments.forEach(a => {
        this.departmentsData.push({ id: a.deptId.toString(), text: a.deptName });
      });
    });
  }
  //Getting Clinics Data
  GetClinics() {
    this.hospitalsService.GetUserSpecificHospitals(this.user_id).subscribe((res) => {
      this.hospitalList = res.response;
      let BranchId = this.globals.HospitalId
      this.searchForm.patchValue({ hospitalId: this.globals.HospitalId });
      //this.loadDepartment(BranchId)
    });
  }

  //get consultant by dept id
  loadConsultant($event) {
    this.consultants = null;
    let deptId = this.searchForm.get("sDepartment").value?this.searchForm.get("sDepartment").value:$event;
    this.hospitalsService.GetConsultant($event).subscribe((res) => {
      this.consultants = res.response;

      this.consultantsData = [];
      this.consultants.forEach(a => {
        this.consultantsData.push({ id: a.consultantId.toString(), text: a.consultantName });
      });
    })
  }
  //datepicker shows till todays date
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
  fromdatechange(event) {
    let StartDateObj = this.searchForm.get("sDateFrom").value;
    this.minDate = StartDateObj;
  }
  //Searching patient details
  SearchRegPatient() {
    
    let StartDateObj = this.searchForm.get("sDateFrom").value
    let EndDateObj = this.searchForm.get("sDateTo").value
    if (typeof (StartDateObj) == "object") {
      let startDateFrom = this.datepipe.transform(StartDateObj, 'dd-MM-yyyy');
      this.searchForm.patchValue({ sDateFrom: startDateFrom })
    }
    if (typeof (EndDateObj) == "object") {
      let DateTo = this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      this.searchForm.patchValue({ sDateTo: DateTo })
    }
    this.returnevent.emit(this.searchForm);
    this.searchForm.patchValue({ sDateFrom: StartDateObj });
    this.searchForm.patchValue({ sDateTo: EndDateObj });
  }

  //Get Appointment type list for patient
  GetAppointmentType() {
    this.appointmentService.GetAppointmentTypes().subscribe((res) => {
      this.appointmentTypesList = res.response;
    })
  }
  onItemChange(value) {
    if (value == 1 || value == 2) {
      this.radiodate1 = true;
      if (value == 2) {
        this.event.emit();
      }
    }
    else {
      this.radiodate1 = false;
      this.returnAllevent.emit();
    }
  }
  //Global settings for Branch ID
  GetUserHospitals() {
    this.hospitalsService.GetUserSpecificHospitals(this.user_id).subscribe((res) => {
      this.hospitalList = res.response;
      this.searchForm.patchValue({ clinicname: this.globals.HospitalId });
    });
  }
  //Loading consultants by consultants type

  loadConsultantType(consultanttype: any) {
    this.consultanttype
    this.hospitalIds = this.searchForm.get("clinicname").value
    this.ServiceOrderingService.GetConsultantByHospital(Number(this.hospitalIds), consultanttype).subscribe((res) => {
      this.consultants = res.response;
    });
  }

  //Loading consultants by consultants clinic

  loadConsultantService(consultanttype: any) {
    this.searchForm.get('consultanttype').reset();
    this.searchForm.get('serviceconsultants').reset();
    this.consultanttype
    this.hospitalIds = this.searchForm.get("clinicname").value
    if (!this.ConsultantId) {
      this.ServiceOrderingService.GetConsultantByHospital(Number(this.hospitalIds), consultanttype).subscribe((res) => {
        this.consultants = res.response;
      });
    }
  }

  onDisableDateFilter(event: any){
    const today = new Date();
    if(event.target.checked){
      this.searchForm.get('sDateFrom').disable();
      this.searchForm.get('sDateTo').disable();
      this.searchForm.patchValue({
        sDateFrom: '',
        sDateTo: ''
      });
    }else{
      this.searchForm.get('sDateFrom').enable();
      this.searchForm.get('sDateTo').enable();
      let dte = new Date();
      dte.setDate(dte.getDate() - 7);
      this.searchForm.patchValue({
        sDateTo: today,
        sDateFrom: dte
      });
    }
  }


}
