export class BasicPatientInfo {
  patientId?:number;
  regNo?:string;
  patientName?:string;
  dob?:string;
  mobile?:string;
  eMail?:string;
  nationalityName?:string;
  profilePicLocation?:string;
  age?:string;
  gender?:string;
  hook?:string;
  sponsorName?:string;
}

export interface  BasicPatientInfo
{
  patientId?:number;
  regNo?:string;
  patientName?:string;
  dob?:string;
  mobile?:string;
  eMail?:string;
  nationalityName?:string;
  profilePicLocation?:string;
  age?:string;
  gender?:string;
  hook?:string;
  sponsorName?:string;

}

