import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { CommonMaster } from "../../client/interfaces/CommonMaster";
import { ConfigurationService } from "../../client/services/configuration.service";
import { ToastrService } from "ngx-toastr";
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
@Component({
  selector: "app-physical-examination-settings",
  templateUrl: "./physical-examination-settings.component.html",
  styleUrls: ["./physical-examination-settings.component.scss"],
})
export class PhysicalExaminationSettingsComponent implements OnInit {
  subscription: Subscription;
  TendernessTab: boolean; // for tab switching
  MovementsTab: boolean; // for tab switching
  SpasticityTab: boolean;
  RangeTab: boolean;
  ModalityTab: boolean;
  public user_id: string;
  form: FormGroup; // form variable
  movementsForm: FormGroup; // form variable
  spasticityForm: FormGroup;
  movementRangeForm: FormGroup;
  modalityForm: FormGroup;

  public TendernessForm: CommonMaster; // form interface
  public MovmentForm: CommonMaster;
  public SpesticityData: any; // form interface
  public MovmentRangeForm: any; // form interface
  // form interface
  TendernesList: CommonMaster[] = [];
  MovementList: CommonMaster[] = [];
  SpasticityList: any[] = [];
  MovementRangeList: any[] = [];
  modalityData:any[]=[];
  public submittedTendern: boolean = false; // form validation controller
  public submittedMovement: boolean = false;
  public submittedSpasticity: boolean = false;
  public submittedMovementRange: boolean = false; // form validation controller
  public submittedModality: boolean = false; // form validation controller

  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];
  MovmentcolumnDefs: ColDef[] = [];
  SpasticitycolumnDefs: ColDef[] = [];
  MovementRangecolumnDefs: ColDef[] = [];
  modalitycolumnDefs: ColDef[] = [];

  rowGroupPanelShow = "always";
  public context;
  public gridApiTenderness;
  public gridApiMovement;
  public gridApiSpasticity;
  public gridApimovementsRange;
  public gridApimodality;
  public statusBar;
  public loadingTemplate;
  public noRowsTemplateList;
  public frameworkComponents;
  isLoading = false;
  globals: globalvars = new globalvars();
  BranchesId: any;
  public gridOptionsList = {
    pagination: true,
    paginationPageSize: 10,
  };

  functionPermissionData: any;

  constructor(
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private sharedservice: SharedService
  ) {
    this.onTab(1); // default tab set
    this.user_id = localStorage.getItem("user_id");
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      Id: new FormControl(0),
      MasterFieldType: new FormControl("Tenderness"),
      NameData: new FormControl(""),
      DescriptionData: new FormControl("", [Validators.required]),
      CodeData: new FormControl(""),
      IsDisplayed: new FormControl(true),
      BranchId: new FormControl(this.globals.HospitalId),
      UserId: new FormControl(this.user_id),
    });

    this.movementsForm = new FormGroup({
      Id: new FormControl(0),
      MasterFieldType: new FormControl("Movement"),
      NameData: new FormControl(""),
      DescriptionData: new FormControl("", [Validators.required]),
      CodeData: new FormControl(""),
      IsDisplayed: new FormControl(true),
      BranchId: new FormControl(this.globals.HospitalId),
      UserId: new FormControl(this.user_id),
    });

    this.spasticityForm = new FormGroup({
      isDisplayed: new FormControl(true),
      spasticityId: new FormControl(0),
      spasticityDesc: new FormControl("", [Validators.required]),
      branchId: new FormControl(this.globals.HospitalId),
      isDeleted: new FormControl(0),
    });
    this.movementRangeForm = new FormGroup({
      isDisplayed: new FormControl(true),
      movementRangeId: new FormControl(0),
      movementRangeDesc: new FormControl("", [Validators.required]),
      branchId: new FormControl(this.globals.HospitalId),
      isDeleted: new FormControl(0),
    });
    this.modalityForm = new FormGroup({
      isDisplayed: new FormControl(true),
      id: new FormControl(0),
      modality: new FormControl("", [Validators.required]),
      branchId: new FormControl(this.globals.HospitalId),
      isDeleting: new FormControl(0),
    });


    this.context = { componentParent: this };

    this.noRowsTemplateList = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    this.columnDefs = [
      {
        headerName: "Description",
        field: "descriptionData",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "tenderness" },
      },
    ];

    this.MovmentcolumnDefs = [
      {
        headerName: "Description",
        field: "descriptionData",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "movements" },
      },
    ];
    this.SpasticitycolumnDefs = [
      {
        headerName: "Description",
        field: "spasticityDesc",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "spesticity" },
      },
    ];

    this.MovementRangecolumnDefs = [
      {
        headerName: "Description",
        field: "movementRangeDesc",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "movementsrange" },
      },
    ];
    this.modalitycolumnDefs = [
      {
        headerName: "Description",
        field: "modality",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "modality" },
      },
    ];

    this.GetTendernesList();
    this.GetTMovementsList();
    this.GetSpasticityList();
    this.GetMovementsRange();
    this.functionPermission();
    this.GetModality();
  }
  get f() {
    return this.form.controls;
  }
  get fm() {
    return this.movementsForm.controls;
  }
  get fs() {
    return this.spasticityForm.controls;
  }
  get fr() {
    return this.movementRangeForm.controls;
  }

  onGridReadyTenderness(params) {
    this.gridApiTenderness = params.api;
  }
  onGridReadyMovement(params) {
    this.gridApiMovement = params.api;
  }
  onGridReadySpasticity(params) {
    this.gridApiSpasticity = params.api;
  }
  onGridReadyMovements(params) {
    this.gridApimovementsRange = params.api;
  }
  onGridReadyModality(params) {
    this.gridApimodality = params.api;
  }

  onFilterTextBoxChangedTenderness() {
    this.gridApiTenderness.setQuickFilter(
      (
        document.getElementById(
          "filter-text-box-tenderness"
        ) as HTMLInputElement
      ).value
    );
  }
  onFilterTextBoxChangedMovement() {
    this.gridApiMovement.setQuickFilter(
      (document.getElementById("filter-text-box-movement") as HTMLInputElement)
        .value
    );
  }
  onFilterTextBoxChangedSpesticity() {
    this.gridApiSpasticity.setQuickFilter(
      (
        document.getElementById(
          "filter-text-box-spasticity"
        ) as HTMLInputElement
      ).value
    );
  }
  onFilterTextBoxChangedMovementsrange() {
    this.gridApimovementsRange.setQuickFilter(
      (
        document.getElementById(
          "filter-text-box-movementRange"
        ) as HTMLInputElement
      ).value
    );
  }
  onFilterTextBoxChangedmodality() {
    this.gridApimodality.setQuickFilter(
      (
        document.getElementById(
          "filter-text-box-movementRange"
        ) as HTMLInputElement
      ).value
    );
  }

  onTab(number) {
    this.TendernessTab = false;
    this.MovementsTab = false;
    this.SpasticityTab = false;
    this.RangeTab = false;
    this.ModalityTab = false;

    if (number == "1") {
      this.TendernessTab = true;
    } else if (number == "2") {
      this.MovementsTab = true;
    } else if (number == "3") {
      this.SpasticityTab = true;
    } else if (number == "4") {
      this.RangeTab = true;
    } else if (number == "5") {
      this.ModalityTab = true;
    }
  }

  SaveTenderness() {
    const foundElement = this.functionPermissionData.find((x) => x.id === 47);
    if (foundElement.id == 47 && foundElement.isAllowed == true) {
      this.submittedTendern = true;
      if (this.form && this.form.valid) {
        this.TendernessForm = this.form.getRawValue();
        if (this.TendernessForm.IsDisplayed) {
          this.TendernessForm.IsDisplayed = 1;
        } else {
          this.TendernessForm.IsDisplayed = 0;
        }
        this.isLoading = true;

        this.TendernessForm.UserId = Number(this.TendernessForm.UserId); // string to number
        this.configurationService

          .SaveCommonMasters(this.TendernessForm)
          .subscribe(
            (res) => {
              this.submittedTendern = false;

              if (res.status == 200 && res.message == "Success") {
                this.clearForm();
                this.isLoading = false;
                this.toastr.success("Data saved successfully");
                this.GetTendernesList();
              } else if (res.status == 200) {
                this.clearForm();
                this.isLoading = false;
                this.toastr.error("Failed : " + res.message);
              } else {
                this.clearForm();
                this.isLoading = false;
                this.toastr.error("Failure: " + res.response.message);
              }
            },
            (err) => {
              this.isLoading = false;
              this.toastr.error(
                "Some unexpected error occures try again later"
              );
            }
          );
      }
    } else {
      this.toastr.warning("You are not allowed to save data");
    }
  }
  ////////////////////////////////////
  SaveMovements() {
    const foundElement = this.functionPermissionData.find((x) => x.id === 50);
    if (foundElement.id == 50 && foundElement.isAllowed == true) {
      this.submittedMovement = true;
      if (this.movementsForm && this.movementsForm.valid) {
        this.MovmentForm = this.movementsForm.getRawValue();
        if (this.MovmentForm.IsDisplayed) {
          this.MovmentForm.IsDisplayed = 1;
        } else {
          this.MovmentForm.IsDisplayed = 0;
        }
        this.isLoading = true;
        this.MovmentForm.UserId = Number(this.MovmentForm.UserId); // string to number
        this.configurationService.SaveCommonMasters(this.MovmentForm).subscribe(
          (res) => {
            this.submittedMovement = false;

            if (res.status == 200 && res.message == "Success") {
              this.clearmovementsForm();
              this.isLoading = false;

              this.toastr.success("Data saved successfully");
              this.GetTMovementsList();
            } else if (res.status == 200) {
              this.isLoading = false;
              this.toastr.error("Failed : " + res.message);
              this.clearmovementsForm();
            } else {
              this.isLoading = false;
              this.toastr.error("Failure: " + res.response.message);
              this.clearmovementsForm();
            }
          },
          (err) => {
            this.isLoading = false;
            this.toastr.error("Some unexpected error occures try again later");
          }
        );
      }
    } else {
      this.toastr.warning("You are not allowed to save data");
    }
  }

  //save spasticity
  SaveSpesticity() {
    const foundElement = this.functionPermissionData.find((x) => x.id === 53);
    if (foundElement.id == 53 && foundElement.isAllowed == true) {
      this.submittedSpasticity = true;
      if (this.spasticityForm && this.spasticityForm.valid) {
        var SpesticityData = this.spasticityForm.getRawValue();
        // if (this.SpesticityData.isDisplayed) {
        //   this.SpesticityData.isDisplayed = 1;
        // }
        // else {
        //   this.SpesticityData.isDisplayed = 0;
        // }
        SpesticityData.isDeleted = 0;
        SpesticityData.branchId = Number(this.globals.HospitalId);
        this.isLoading = true; // string to number
        this.configurationService.SaveSpesticity(SpesticityData).subscribe(
          (res) => {
            this.submittedSpasticity = false;

            if (res.status == 200 && res.message == "Success") {
              this.clearSpasticityForm();
              this.isLoading = false;
              this.toastr.success("Data saved successfully");
              this.GetSpasticityList();
            } else if (res.status == 200) {
              this.isLoading = false;
              this.toastr.error("Failed : " + res.message);
              this.clearSpasticityForm();
            } else {
              this.isLoading = false;
              this.toastr.error("Failure: " + res.response.message);
              this.clearSpasticityForm();
            }
          },
          (err) => {
            this.isLoading = false;
            this.toastr.error("Some unexpected error occures try again later");
          }
        );
      }
    } else {
      this.toastr.warning("You are not allowed to save data");
    }
  }
  //save movements range
  SaveMovementsRangeeSpesticity() {
    const foundElement = this.functionPermissionData.find((x) => x.id === 56);
    if (foundElement.id == 56 && foundElement.isAllowed == true) {
      this.submittedMovementRange = true;
      if (this.spasticityForm && this.movementRangeForm.valid) {
        var MovementsRangeData = this.movementRangeForm.getRawValue();
        // if (this.SpesticityData.isDisplayed) {
        //   this.SpesticityData.isDisplayed = 1;
        // }
        // else {
        //   this.SpesticityData.isDisplayed = 0;
        // }
        MovementsRangeData.isDeleted = 0;
        MovementsRangeData.branchId = Number(this.globals.HospitalId);
        this.isLoading = true; // string to number
        this.configurationService
          .SaveMovementsRange(MovementsRangeData)
          .subscribe(
            (res) => {
              this.submittedMovementRange = false;

              if (res.status == 200 && res.message == "Success") {
                this.clearMovementrangeForm();
                this.isLoading = false;
                this.toastr.success("Data saved successfully");
                this.GetMovementsRange();
              } else if (res.status == 200) {
                this.isLoading = false;
                this.toastr.error("Failed : " + res.message);
                this.clearMovementrangeForm();
              } else {
                this.isLoading = false;
                this.toastr.error("Failure: " + res.response.message);
                this.clearMovementrangeForm();
              }
            },
            (err) => {
              this.isLoading = false;
              this.toastr.error(
                "Some unexpected error occures try again later"
              );
            }
          );
      }
    } else {
      this.toastr.warning("You are not allowed to save data");
    }
  }
  saveModality() {
    const foundElement = this.functionPermissionData.find((x) => x.id === 56);
    if (foundElement.id == 56 && foundElement.isAllowed == true) {
      this.submittedModality = true;
      if (this.modalityForm && this.modalityForm.valid) {
        var ModalityData = this.modalityForm.getRawValue();
        ModalityData.isDeleting = 0;
        ModalityData.branchId = Number(this.globals.HospitalId);
        this.isLoading = true; // string to number
        this.configurationService.saveModality(ModalityData).subscribe(
            (res) => {
              this.submittedModality = false;

              if (res.status == 200 && res.message == "Success") {
                this.clearModalityForm();
                this.isLoading = false;
                this.toastr.success("Data saved successfully");
                this.GetModality();
              } else if (res.status == 200) {
                this.isLoading = false;
                this.toastr.error("Failed : " + res.message);
                this.clearModalityForm();
              } else {
                this.isLoading = false;
                this.toastr.error("Failure: " + res.response.message);
                this.clearModalityForm();
              }
            },
            (err) => {
              this.isLoading = false;
              this.toastr.error(
                "Some unexpected error occures try again later"
              );
            }
          );
      }
    } else {
      this.toastr.warning("You are not allowed to save data");
    }
  }

  clearForm() {
    this.form.reset({
      Id: 0,
      MasterFieldType: "Tenderness",
      NameData: "",
      DescriptionData: "",
      CodeData: "",
      IsDisplayed: true,
      BranchId: this.globals.HospitalId,
      UserId: this.user_id,
    });
  }
  clearmovementsForm() {
    this.movementsForm.reset({
      Id: 0,
      MasterFieldType: "Movement",
      NameData: "",
      DescriptionData: "",
      CodeData: "",
      IsDisplayed: true,
      BranchId: this.globals.HospitalId,
      UserId: this.user_id,
    });
  }
  clearSpasticityForm() {
    this.spasticityForm.reset({
      spasticityId: 0,
      spasticityDesc: "",
      isDisplayed: true,
      branchId: this.globals.HospitalId,
    });
  }
  clearMovementrangeForm() {
    this.movementRangeForm.reset({
      movementRangeId: 0,
      movementRangeDesc: "",
      isDisplayed: true,
      branchId: this.globals.HospitalId,
    });
  }
  clearModalityForm() {
    this.modalityForm.reset({
      id: 0,
      modality: "",
      isDisplayed: true,
      branchId: this.globals.HospitalId,
    });
  }

  GetTendernesList() {
    let input = {
      MasterFieldType: "Tenderness",
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId,
    };
    this.configurationService.GetTendernesList(input).subscribe((res) => {
      this.TendernesList = res.response;
    });
  }
  GetTMovementsList() {
    let input = {
      MasterFieldType: "Movement",
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId,
    };
    this.configurationService.GetTMovementsList(input).subscribe((res) => {
      this.MovementList = res.response;
    });
  }
  GetSpasticityList() {
    let input = {
      ShowAll: 1,
      spasticityId: 0,
      branchId: this.globals.HospitalId,
    };
    this.configurationService.GetSpasticityList(input).subscribe((res) => {
      this.SpasticityList = res.response;
    });
  }
  GetMovementsRange() {
    let input = {
      ShowAll: 1,
      movementRangeId: 0,
      branchId: this.globals.HospitalId,
    };
    this.configurationService.GetMovementsRange(input).subscribe((res) => {
      this.MovementRangeList = res.response;
    });
  }
  GetModality(){
    let input = {
      ShowAll: 1,
      Id: 0,
      BranchId: this.globals.HospitalId,
      
    };
    this.configurationService.GetModality(input).subscribe((res) => {
      this.modalityData = res.response;
    });
  }
  EditTenderness(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 48);
    if (foundElement.id == 48 && foundElement.isAllowed == true) {
      let TendernessSingle = this.upper(data);
      this.form.patchValue(TendernessSingle);
      if (TendernessSingle["IsDisplayed"] == 1) {
        this.form.controls["IsDisplayed"].setValue(true);
      } else {
        this.form.controls["IsDisplayed"].setValue(false);
      }
    } else {
      this.toastr.warning("You are not allowed to edit");
    }
  }
  EditSpesticity(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 54);
    if (foundElement.id == 54 && foundElement.isAllowed == true) {
      console.log(data);
      this.spasticityForm.patchValue(data);
      if (data["isDisplayed"] == 1) {
        this.spasticityForm.controls["isDisplayed"].setValue(true);
      } else {
        this.spasticityForm.controls["isDisplayed"].setValue(false);
      }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  EditMovementsrange(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 57);
    if (foundElement.id == 57 && foundElement.isAllowed == true) {
      this.movementRangeForm.patchValue(data);
      if (data["isDisplayed"] == 1) {
        this.spasticityForm.controls["isDisplayed"].setValue(true);
      } else {
        this.spasticityForm.controls["isDisplayed"].setValue(false);
      }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  EditMovements(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 51);
    if (foundElement.id == 51 && foundElement.isAllowed == true) {
      let movementsSIngle = this.upper(data);
      this.movementsForm.patchValue(movementsSIngle);
      if (movementsSIngle["IsDisplayed"] == 1) {
        this.movementsForm.controls["IsDisplayed"].setValue(true);
      } else {
        this.movementsForm.controls["IsDisplayed"].setValue(false);
      }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  DeleteTenderness(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 49);
    if (foundElement.id == 49 && foundElement.isAllowed == true) {
      let input = {
        MasterFieldType: "Tendern",
        Id: data.id,
      };
      this.configurationService.DeleteCommonMaster(input).subscribe((res) => {
        if (res.status == 200) {
          this.toastr.success("Data deleted successfully");
          this.GetTendernesList();
        } else {
          this.toastr.error("Failure: " + res.message);
        }
      });
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  DeleteMovements(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 52);
    if (foundElement.id == 52 && foundElement.isAllowed == true) {
      let input = {
        MasterFieldType: "Movement",
        Id: data.id,
      };
      this.configurationService.DeleteCommonMaster(input).subscribe((res) => {
        if (res.status == 200) {
          this.toastr.success("Data deleted successfully");
          this.GetTMovementsList();
        } else {
          this.toastr.error("Failure: " + res.message);
        }
      });
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }
  DeleteSpesticity(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 55);
    if (foundElement.id == 55 && foundElement.isAllowed == true) {
      let form = this.spasticityForm.getRawValue();
      form.branchId = Number(this.globals.HospitalId);
      form.isDeleted = 1;
      form.spasticityDesc = data.spasticityDesc;
      form.spasticityId = data.spasticityId;
      form.isDisplayed = true;
      this.configurationService.SaveSpesticity(form).subscribe((res) => {
        if (res.status == 200) {
          this.toastr.success("Data deleted successfully");
          this.GetSpasticityList();
        } else {
          this.toastr.error("Failure: " + res.message);
        }
      });
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }
  DeleteMovementsrange(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 58);
    if (foundElement.id == 58 && foundElement.isAllowed == true) {
      let form = this.movementRangeForm.getRawValue();
      form.branchId = Number(this.globals.HospitalId);
      form.isDeleted = 1;
      form.movementRangeDesc = data.movementRangeDesc;
      form.movementRangeId = data.movementRangeId;
      form.isDisplayed = true;
      this.configurationService.SaveMovementsRange(form).subscribe((res) => {
        if (res.status == 200) {
          this.toastr.success("Data deleted successfully");
          this.GetMovementsRange();
        } else {
          this.toastr.error("Failure: " + res.message);
        }
      });
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }
  Editmodality(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 51);
    if (foundElement.id == 51 && foundElement.isAllowed == true) {
      this.modalityForm.patchValue(data);
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  Deletemodality(data) {
    const foundElement = this.functionPermissionData.find((x) => x.id === 58);
    if (foundElement.id == 58 && foundElement.isAllowed == true) {
      let form = this.modalityForm.getRawValue();
      form.branchId = Number(this.globals.HospitalId);
      form.isDeleting = 1;
      form.modality = data.modality;
      form.id = data.id;
      form.isDisplayed = true;
      this.configurationService.saveModality(form).subscribe((res) => {
        if (res.status == 200) {
          this.toastr.success("Data deleted successfully");
          this.GetModality();
        } else {
          this.toastr.error("Failure: " + res.message);
        }
      });
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  /// For object key name convertion
  upper(obj) {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      let first = key.substr(0, 1).toUpperCase();
      first = first + key.substr(1);

      newobj[first] = obj[key];
    }
    return newobj;
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem("selectedMenu")),
      UserId: Number(localStorage.getItem("user_id")),
    };
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }
}
