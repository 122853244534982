import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "../../../client/services/configuration.service";
import { SharedService } from "../../../../shared/shared.service";
import { Subscription } from "rxjs";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-edit-frequency",
  templateUrl: "./add-edit-frequency.component.html",
  styleUrls: ["./add-edit-frequency.component.scss"],
})
export class AddEditFrequencyComponent implements OnInit {
  @Input() DrugFrequencyPopupHeading: string;
  @Output() frequencyEvent=new EventEmitter()
  public FrequencyForm: FormGroup;
  Zones: any;
  ZonesData: any;
  globals: any;
  subscription: Subscription;
  submitted: boolean = false;
  isLoading: boolean = false;

  constructor(
    private configurationService: ConfigurationService,
    private sharedservice: SharedService,
    public modalRef: BsModalRef,
    private tostr:ToastrService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        // this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.formInit();
    this.getZones();
  }

  formInit() {
    this.FrequencyForm = new FormGroup({
      FrequencyDescription: new FormControl("", [Validators.required]),
      Frequencyvalue: new FormControl("", [Validators.required]),
      ZoneIdFreq: new FormControl("", [Validators.required]),
      IsDisplayedFrequency: new FormControl(true),
    });
  }

  get frequencyf() {
    return this.FrequencyForm.controls;
  }

  getZones() {
    this.configurationService
      .GetZones(this.globals.HospitalId)
      .subscribe((res) => {
        this.Zones = res.response;
        this.ZonesData = [];
        this.Zones.forEach((a) => {
          this.ZonesData.push({
            id: a.id.toString(),
            text: a.zoneName,
          });
        });
      });
  }

  SaveFrequency() {
    this.submitted=true
    if (this.FrequencyForm && this.FrequencyForm.valid) {
      var AppData = {
        FreqId: 0,
        FreqValue: this.FrequencyForm.get("Frequencyvalue").value,
        FreqDesc: this.FrequencyForm.get("FrequencyDescription").value,
        ZoneId: Number(this.FrequencyForm.get("ZoneIdFreq").value),
        IsDisplayed: this.FrequencyForm.get("IsDisplayedFrequency").value,
        UserId: Number(localStorage.getItem("user_id")),
        BranchId: this.globals.HospitalId,
        IsDeleted: 0,
      };
      if (AppData.IsDisplayed == true) {
        AppData.IsDisplayed = 1;
      } else {
        AppData.IsDisplayed = 0;
      }

      this.isLoading = true;

      this.configurationService
        .InsertManualFrequency(AppData)
        .subscribe((res) => {
          this.isLoading=false
          if (res.status == 200 && res.message == "Success") {
            this.frequencyEvent.emit({data: Number(res.response[0].freqId)})
            this.modalRef.hide()
          }else if(res.status ==200){
            this.tostr.warning(res.message)
          }
        });
    }
  }

  close(){
    this.modalRef.hide()
    this.frequencyEvent.emit();
  }
}
