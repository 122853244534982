import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { ReportsService } from '../services/reports.service';
// import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { HospitalsService } from '../../client/services/hospitals.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
@Component({
  selector: 'app-consultation-report',
  templateUrl: './consultation-report.component.html',
  styleUrls: ['./consultation-report.component.scss']
})
export class ConsultationReportComponent implements OnInit {

  form: FormGroup; // form variable
  subscription: Subscription;
  globals: globalvars = new globalvars();
  consultants:any[]=[];
  GetConsultantsData: any;
  isPdfLoaded:boolean=false;
  // private pdf: PDFDocumentProxy;
  isLoading=false;
  src:any = '';

  departments: any;
  private baseUrl = "";
  constructor(
    public  datepipe: DatePipe,
    private sharedservice: SharedService,
    private ReportsService:ReportsService,
    private hospitalsService: HospitalsService,
    private sanitizer: DomSanitizer,
    private configurationManager: ConfigurationManager,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  ngOnInit(): void {
    this.form=new FormGroup({
      ConsultFromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ConsultToDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ConsultantId: new FormControl(0),
      DeptId: new FormControl(0),
      BranchId: new FormControl(this.globals.HospitalId),
      Status: new FormControl('All'),
    });

    this.GetDepartments(); //on load api function getting departments
  }

  GetDepartments() {
    let showall = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showall).subscribe((res) => {
      this.departments = res.response;
    });
  }
  loadConsultant(DepartmentId: number) {
    let departmentId = Number(this.form.get("DeptId").value);
    if(departmentId>0){
      this.hospitalsService.GetConsultant(departmentId).subscribe((res) => {
      this.consultants = res.response;
    });
    }
    else{
      this.consultants = [];
    }
  }

  generateReport(){
    this.isLoading=true;
    let input=this.form.getRawValue();

    if (typeof (input.ConsultFromDate) == "object") {
      let dobConverted = this.datepipe.transform(input.ConsultFromDate, 'dd-MM-yyyy');
      input.ConsultFromDate = dobConverted;
    }
    if (typeof (input.ConsultToDate) == "object") {
      let dobConverted = this.datepipe.transform(input.ConsultToDate, 'dd-MM-yyyy');
      input.ConsultToDate = dobConverted;
    }
    input.DeptId=Number(input.DeptId);

    if(input.DeptId==0){
      input.ConsultantId=0;
    }
    else{
      input.ConsultantId=Number(input.ConsultantId);
    }
    this.ReportsService.GetConsultantReport(input).subscribe((res) => {
      this.isLoading=false;
      if(res.status==200){
        let response=res.response;
        this.src=response[0].reportPath;
        this.src=this.baseUrl+this.src;
        this.src=this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
        this.isPdfLoaded=true;
      }
     });
  }
  print(){
  //   this.pdf.getData().then((u8) => {
  //     let blob = new Blob([u8.buffer], {
  //         type: 'application/pdf'
  //     });

  //     const blobUrl = window.URL.createObjectURL((blob));
  //     const iframe = document.createElement('iframe');
  //     iframe.style.display = 'none';
  //     iframe.src = blobUrl;
  //     document.body.appendChild(iframe);
  //     iframe.contentWindow.print();
  // });
  }

  // onLoaded(pdf: PDFDocumentProxy) {
  //   this.pdf = pdf;
  //   this.isPdfLoaded = true;
  // }

}
