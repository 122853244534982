import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserpermissionService } from "../../../../user-permission/userpermission.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  @Input() userId: any;
  isLoading = false;
  transferForm: FormGroup;
  public submitted: boolean = false;
  result: any;
  public strongPassword: boolean = false;

  constructor(
    private modalRef: BsModalRef,
    private userservice: UserpermissionService,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.transferForm = new FormGroup({
      UserId: new FormControl(this.userId),
      CurrentPassword: new FormControl(''),
      NewPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          "(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}"
        ),
      ]),
      confirmPassword: new FormControl(''),


    });

  }
  close() {
    this.modalRef.hide();
  }
  save() {
    if (this.submitted = true) {
      var formdata = this.transferForm.getRawValue();
      if(formdata.CurrentPassword==""){
        this.toastr.warning("Please enter Current password");

      }
      else if(formdata.confirmPassword==""){
        this.toastr.warning("Please enter confirm Password");

      }

      else if (formdata.confirmPassword == formdata.NewPassword) {

      // }
      // else {
        this.isLoading = true;

        this.userservice.setResetPassword(formdata).subscribe((res) => {
          this.submitted = false;
          this.result = res;
          if (this.result.status == 200 && this.result.message == 'Success') {
            this.isLoading = false;
            this.toastr.success("Password Upadate Successfully");

            this.close();
          }
          else {
            this.isLoading = false;

            this.toastr.error(this.result.message );

          }



        }) 
      }
      else{
        this.toastr.error("Password and confirm password do not match")

      }

     
    }


  }
  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }
  get f() {
    return this.transferForm.controls;
  }

}
