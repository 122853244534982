import { Component, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EMRService } from '../../emr/services/emr.service';
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2';
import { RegDocLocation } from '../../client/interfaces/RegDocLocation';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
@Component({
  selector: 'app-emr-documents',
  templateUrl: './emr-documents.component.html',
  styleUrls: ['./emr-documents.component.scss']
})
export class EmrDocumentsComponent implements OnInit {

  EMRPatientInfo:EMRPatientInfo; 
  form: FormGroup; // form variable

  modalRef?: BsModalRef;
  @ViewChild("template") modaltemp: any;
  folderList:any[]=[];
  documentList: RegDocLocation[] = [];
  attachdocuments: boolean;
  displayBasicdragdrop: boolean;
  result: any;
  public documentFile: File[] = null;
  public documentFile1: File = null;
  FolderId: any;
  fileLength: any;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;
  filesListing: any;
  isShowFilesButton: boolean = false;
  FolderName: any;
  documentListFile: any;
  documentFileName: any;
  SelectedFolderId: any;
  btnactive: boolean ;
  folderName: any;
  selected=0;
  filesListingFiles:any;
  documentFileType: any;
  fileUpload:any;
  constructor(
    private modalService: BsModalService,
    private EMRService:EMRService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
  ) {
    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.form=new FormGroup({
      folderId: new FormControl(0),
      folderName: new FormControl(""),
      isDeleting: new FormControl(0),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
      patientId: new FormControl(Number(this.EMRPatientInfo.PatientId)),
    });

    this.GetFolders();
    this.GetListingOfFiles();
  }
  openModal()
  {
    this.modalRef = this.modalService.show(
      this.modaltemp);
  }
  SaveFolder()
  {
      // if(this.EMRPatientInfo.VisitId>0){
        let input=this.form.getRawValue();
        input.userId=Number(localStorage.getItem('user_id'));
        this.EMRService.CreateFolder(
          input
        ).subscribe(
          (res) => {
            if (res.status == 200 && res.message == "Success") {
              this.toastr.success("Success: " + "Folder created Successfully...");
              this.modalRef?.hide()
              this.GetFolders();
              this.clearForm();
            }
            else{
              Swal.fire(
                'Error!',
                'Some unexpected error occures! try again later.',
                'error'
              )
            }
          });
      // }
      // else{
      //   this.toastr.warning("Info: " + "Start/edit/view a consultation to create folder");
      // }
  }
  GetFolders()
  {
    let input={
      "patientId":this.EMRPatientInfo.PatientId,
    };

    this.EMRService.GetFolders(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.folderList=res.response;
        }
      });
  }


  //modal showing for attached documents
  attachDocuments() {

    this.displayBasicdragdrop = true;
  }

//fileupload calling function for getting folderId
  FileUpload(folderListId,folderName,event){
     let target = event.target || event.srcElement || event.currentTarget;
     let idAttr = target.attributes.dataindex;
     let value = idAttr.nodeValue;
     this.selected=value;
    // this.folderName=folderList.folderName
    this.folderName=folderName;
     this.isShowFilesButton=true;
   // this.FolderId=folderList.id
   this.FolderId=folderListId;
    this.SelectedFolderId= this.folderList.filter((oppdata) => oppdata.id ==this.FolderId );
    this.filesListingFiles= this.SelectedFolderId[0].patientFiles

  }

 //Uploading Attachments

 onUpload(event,fileUpload) {

  this.documentFile = <File[]>event.files;

  const formData = new FormData();
  if (this.documentFile) {

    for (var i = 0; i < this.documentFile.length; i++) {

      formData.append('PatientDocs', this.documentFile[i]);

    }
  }
          if(this.FolderId||this.folderList.length>0){
             this.FolderId=this.FolderId?this.FolderId:this.folderList[0].id;
            formData.append('FileJson', JSON.stringify({"PatientId": this.EMRPatientInfo.PatientId ,"FolderId": this.FolderId,"UserId":Number(localStorage.getItem('user_id'))}));
            this.EMRService.UploadDocuments(formData).subscribe((res) => {
              this.result = res;
              if (this.result.message == "Success") {
                this.toastr.success("Files Uploaded successfully");
                fileUpload.clear();

                this.GetFolders();
                if(this.FolderId){
                  this.GetFolders();
                 this.GetListingOfFiles()
                }

              } else {
                this.toastr.error("Files Uploading failed");
              }
            });
            this.clearForm();
          }

else{
  this.toastr.error("Create folder for uploading Documents");

}
  this.displayBasicdragdrop = false;

}

OpenImage(filePath: string) {//document view
  window.open(filePath, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
}

GetListingOfFiles(){
  let input={
    "patientId":this.EMRPatientInfo.PatientId,
    "branchId":this.BranchesId
  };

  this.EMRService.GetListingOfFiles(input).subscribe(
    (res) => {
      if(res.status==200)
      {
        this.filesListing=res.response;
        if(this.filesListing.length>0){
          if(this.FolderId){
            this.SelectedFolderId= this.filesListing.filter((oppdata) => oppdata.id ==this.FolderId );
            this.filesListingFiles= this.SelectedFolderId[0].patientFiles;
            this.FolderName= this.filesListing[0].folderName;
          }
          else{
            this.filesListingFiles=this.filesListing[0].patientFiles;
           this.FolderName= this.filesListing[0].folderName;
          }
        }


      }

    });

}


openModalFilesView(fileLocation,FileName,fileType,template1: TemplateRef<any>) {
  this.modalRef = this.modalService.show(template1,{ class: "gray modal-lg" });
  this.documentListFile =fileLocation;
  this.documentFileName=FileName;
  this.documentFileType=fileType;
  this.clearForm();
}


OpenUploadImage(filePath: string) {//document view
  window.open(filePath, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
}


//In order to clear the folder creation
clearForm(){
  this.form.reset({
    folderId: 0,
    folderName: '',
    isDeleting:0,
    visitId:this.EMRPatientInfo.VisitId,
    userId:Number(localStorage.getItem('user_id')),
    patientId:Number(this.EMRPatientInfo.PatientId)
  });
}

deleteFile(fileId){
  let input={
    "fileId":fileId,
    "userId":Number(localStorage.getItem('user_id')),
  };
  this.EMRService.DeleteFIle(input).subscribe(
    (res) => {
      if(res.status==200)
      {
        this.toastr.success("File Deleted Successfully");
        this.GetListingOfFiles();
        this.GetFolders();
      }
      else{
        this.toastr.success("Some unexpected error occures! Please try again later");
      }
    });


}

}
