import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgGridModule } from "ag-grid-angular";
//import "ag-grid-enterprise";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { NgSelect2Module } from "ng-select2";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import { CollapseModule } from "ngx-bootstrap/collapse";
// import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PopoverModule } from "ngx-bootstrap/popover";
import { AlertConfig, AlertModule } from "ngx-bootstrap/alert";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ToastrModule } from "ngx-toastr";
import { ModalModule } from "ngx-bootstrap/modal";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
/* import { DragDropModule } from '@angular/cdk/drag-drop'; */
import { MatSliderModule } from "@angular/material/slider";
import { NgApexchartsModule } from "ng-apexcharts";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { FullCalendarModule } from "@fullcalendar/angular"; // the main connector. must go first
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin
import interactionPlugin from "@fullcalendar/interaction"; // a plugin
import timeGridPlugin from "@fullcalendar/timegrid";
import { AgmCoreModule } from "@agm/core";
//import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { SidebarModule } from "primeng/sidebar";
import { ButtonModule } from "primeng/button";
//import { TableModule } from "primeng/table";
import { PickListModule } from "primeng/picklist";
import { TreeTableModule } from "primeng/treetable";
import { TreeModule } from "primeng/tree";
import { DragDropModule } from "primeng/dragdrop";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { TieredMenuModule } from "primeng/tieredmenu";
import { DialogModule } from "primeng/dialog";
import { InputTextModule } from "primeng/inputtext";
import { CheckboxModule } from "primeng/checkbox";
import { SplitButtonModule } from "primeng/splitbutton";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MegaMenuModule } from "primeng/megamenu";
import { MenubarModule } from "primeng/menubar";
import { CardModule } from "primeng/card";
import { SlideMenuModule } from "primeng/slidemenu";
import { DropdownModule } from "primeng/dropdown";
import { BrowserModule } from "@angular/platform-browser";
import { InputMaskModule } from "primeng/inputmask";
import { CarouselModule } from "primeng/carousel";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { TextMaskModule } from "angular2-text-mask";
import { InputNumberModule } from "primeng/inputnumber";
import { MultiSelectModule } from "primeng/multiselect";
import { WebcamModule } from "ngx-webcam";
import { DatePipe } from "@angular/common";
import { FileUploadModule } from "primeng/fileupload";
import { DataTablesModule } from "angular-datatables";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { GMapModule } from "primeng/gmap";
import { AccordionModule } from "primeng/accordion";
import { NgxUiLoaderModule } from "ngx-ui-loader";
//import 'ag-grid-enterprise';
import { AutocompleteLibModule } from "angular-ng-autocomplete";
/* import {ImageModule} from 'primeng/image'; */
//import { FieldsetModule } from 'primeng/fieldset';
//import {DialogModule} from 'primeng/dialog';
import { MatExpansionModule } from "@angular/material/expansion";

import { GuiGridModule } from "@generic-ui/ngx-grid";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BsDropdownConfig } from "ngx-bootstrap/dropdown";

import { ButtonsModule } from "ngx-bootstrap/buttons";
import { LeftmenuComponent } from "../../../app/modules/client/leftmenu/leftmenu.component";
import { HeaderComponent } from "../../../app/modules/client/header/header.component";
import { FooterComponent } from "../../../app/modules/client/footer/footer.component";

import { NgSelectModule } from '@ng-select/ng-select';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import{CalendarModule as calenderprime} from"primeng/calendar"
import { SearchPatientModalComponent } from '../../../app/modules/client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { TranslateModule } from "@ngx-translate/core";
import { SearchPatientComponent } from "../../modules/client/frontoffice/searchpatient/searchpatient.component";
import { CFormLockComponent } from "../modals/c-form-lock/c-form-lock.component";
import { BreadCrumbsComponent } from "../components/bread-crumbs/bread-crumbs.component";
import { NumbersOnlyDirective } from "../directives/number-only.directive";
import { PasswordStrengthMeterComponent } from "../components/password-strength-meter/password-strength-meter.component";
import { WebCameraModalComponent } from "../modals/web-camera-modal/web-camera-modal.component";
import { TelerikReportingModule } from "@progress/telerik-angular-report-viewer";
FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    HeaderComponent,
    LeftmenuComponent,
    FooterComponent,
    SearchPatientModalComponent,
    SearchPatientComponent,
    CFormLockComponent,
    BreadCrumbsComponent,
    NumbersOnlyDirective,
    PasswordStrengthMeterComponent,
    WebCameraModalComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgGridModule.withComponents(),
    FormsModule,
    NgSelect2Module,
    NgxUiLoaderModule,
    DataTablesModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    AgGridModule.withComponents(),
    DragDropModule,
    MatSliderModule,
    NgApexchartsModule,
    SidebarModule,
    ButtonModule,
    PickListModule,
    TreeTableModule,
    TreeModule,
    VirtualScrollerModule,
    TieredMenuModule,
    CheckboxModule,
    ConfirmDialogModule,
    //FieldsetModule,
    DialogModule,
    InputTextModule,
    SplitButtonModule,
    MegaMenuModule,
    MenubarModule,
    CardModule,
    SlideMenuModule,
    DropdownModule,
    InputMaskModule,
    CarouselModule,
    DynamicDialogModule,
    TextMaskModule,
    WebcamModule,
    FileUploadModule,
    HttpClientModule,
    InputNumberModule,
    MultiSelectModule,
    NgMultiSelectDropDownModule,
    GMapModule,
    AccordionModule,
    AutocompleteLibModule,
    MatExpansionModule,
    ButtonsModule,
    NgSelectModule,
    TelerikReportingModule,
    
    // AgGridModule.withComponents(),

    ToastrModule.forRoot({}),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AgmCoreModule.forRoot({
      apiKey: "GOOGLE_API_KEY",
    }),
    FullCalendarModule,
   // CKEditorModule,
    GuiGridModule,
    AccordionModule,
    AlertModule,
    ButtonModule,
    CarouselModule,
    CollapseModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule,
    TimepickerModule.forRoot(),calenderprime
  ],
  exports: [
    BreadCrumbsComponent,
    PasswordStrengthMeterComponent,
    WebCameraModalComponent,
    HeaderComponent,
    SearchPatientModalComponent,
    SearchPatientComponent,
    LeftmenuComponent,
    FooterComponent,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelect2Module,
    ReactiveFormsModule,
    HttpClientModule,
    AgGridModule,
    FormsModule,
    NgxUiLoaderModule,
    DataTablesModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule,
    CollapseModule,
    // AccordionModule.forRoot(),
    TooltipModule,
    PopoverModule,
    AlertModule,
    BsDropdownModule,
    ModalModule,
    ProgressbarModule,
    DragDropModule,
    MatSliderModule,
    NgApexchartsModule,
    SidebarModule,
    ButtonModule,

    PickListModule,
    TreeTableModule,
    TreeModule,
    VirtualScrollerModule,
    TieredMenuModule,
    CheckboxModule,
    ConfirmDialogModule,
    //FieldsetModule,
    DialogModule,
    InputTextModule,
    SplitButtonModule,
    MegaMenuModule,
    MenubarModule,
    CardModule,
    SlideMenuModule,
    DropdownModule,
    InputMaskModule,
    CarouselModule,
    DynamicDialogModule,
    TextMaskModule,
    WebcamModule,
    FileUploadModule,
    HttpClientModule,
    InputNumberModule,
    MultiSelectModule,
    NgMultiSelectDropDownModule,
    GMapModule,
    AccordionModule,
    AutocompleteLibModule,
    MatExpansionModule,
    ButtonsModule,
    NgSelectModule,
    // AgGridModule.withComponents(),
    ToastrModule,
    CalendarModule,
    AgmCoreModule,
    FullCalendarModule,
    //CKEditorModule,
    GuiGridModule,
    AccordionModule,
    AlertModule,
    ButtonModule,
    FormsModule,
    CarouselModule,
    CollapseModule,
    BsDatepickerModule,
    BsDropdownModule,
    TimepickerModule,
    calenderprime,
    NumbersOnlyDirective
  ],
  providers: [
    BsDatepickerModule,
    DatePipe,
    AlertConfig,
    BsDatepickerConfig,
    BsDropdownConfig,
  ],
})
export class SharedModule {




}
