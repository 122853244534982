import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TreatmentsService } from "../../services/treatments.service";
import { ToastrService } from "ngx-toastr";
import { EMRPatientInfo } from "../../../emr/interfaces/EMRPatientInfo";
import { EMRService } from '../../../emr/services/emr.service';

@Component({
  selector: 'app-lock-confirm',
  templateUrl: './lock-confirm.component.html',
  styleUrls: ['./lock-confirm.component.scss']
})
export class LockConfirmComponent implements OnInit {
  @Output() getitemEmitter = new EventEmitter()
  @Input() is_locked: any;

  resultForm: FormGroup;
  isLoading = false;
  result:any;
  public submitted: boolean = false;
  EMRPatientInfo: EMRPatientInfo;

  constructor
  (private modalRef: BsModalRef,
    private TreatmentsService: TreatmentsService,
    private toastr: ToastrService,
    private EMRService: EMRService,

    ) { }


  ngOnInit(): void {
    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();

    this.resultForm = new FormGroup({
      UserId: new FormControl(Number(localStorage.getItem("user_id"))),
      Username: new FormControl(""),
      Password: new FormControl(""),
      consultationId:new FormControl(Number(this.EMRPatientInfo.ConsultationId)),
      isLocked: new FormControl(this.is_locked),

    });
  
  }
  Submit(){
    this.submitted = true;
    var formdata = this.resultForm.getRawValue();
    if(formdata.Password==""){
      this.toastr.warning("Please enter the Password");

    }else{

    this.isLoading = true;
    this.TreatmentsService.VerifyUser(formdata).subscribe((res) => {
      this.submitted = false;
      this.result = res;
      if (this.result.status == 200 && this.result.message == "success") {
        this.toastr.success(" Success");
         this.isLoading = false;
         this.getitemEmitter.emit({ status: true });

      } else {
        this.getitemEmitter.emit({ status: false });

        this.isLoading = false;
        this.toastr.error("Invalid  password");
      }
    });

    this.modalRef.hide();
  }

  }
  close() {
    this.modalRef.hide();
    this.getitemEmitter.emit({ status: false });

  }
  get f() {
    return this.resultForm.controls;
  }

}
