import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { GeneralActionComponent } from '../../../general-action/general-action.component';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import _ from 'lodash';
import { Router } from '@angular/router';
import { TelerikPrintComponent } from '../../../../../shared/modals/telerik-print/telerik-print.component';

@Component({
  selector: 'app-show-overbooked-appoinments-modal',
  templateUrl: './show-overbooked-appoinments-modal.component.html',
  styleUrls: ['./show-overbooked-appoinments-modal.component.scss']
})
export class ShowOverbookedAppoinmentsModalComponent implements OnInit {

  @Output() modalEventEmitters = new EventEmitter();

  @Input() timeSlot: any;
  @Input() branchId: number;

  public gridApi: GridApi;
  public columnDefs: ColDef[] = [];
  public gridOptions: GridOptions = {};
  public context: any = {};
  public frameworkComponents: any = {};

  public CancelAppId: any;
  public PostponeObj: any;

  constructor(private modalRef: BsModalRef,
              private router: Router,
              private modalService: BsModalService) {
    
   }

  ngOnInit(): void {
    this.initGridOptions();
  }

  initGridOptions(){
    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    this.columnDefs=[
      {
        headerName: "Reg.No",
        minWidth:120,
        field: 'regNo',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 1.5,

      },
      {
        headerName: "Patient Name",
        minWidth:150,
        field: 'patientName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },
      {
        headerName: "Mobile",
        minWidth:150,
        field: 'mobile',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Status",
        minWidth:85,
        field: 'appStatus',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Action",
        maxWidth:100,
        filter: false,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "appId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "AppoinmentList", returnFunctionCall: true },
      },
    ];
    this.context = { componentParent: this };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };

    this.gridOptions = {
      pagination: true,
      paginationPageSize:10,
    }
  }

  close() {
    this.modalRef.hide();
  }

  onGridReady(event){
    this.gridApi = event.api;
  }

  PrintAppointmentCard(appId) {

    let initialState = {
      modalHeading: 'Print Appointment',
      reportName: 'PrintAppointment.trdp',
      reportParams: {
        AppId: appId,
        BranchId : this.branchId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
    // let rowdatas = _.filter(this.timeSlot.patientDataList, {appId: appId })[0];
    // let PatientName = rowdatas.patientName;
    // let PatientRegNo = rowdatas.regNo;
    // let PatientMob = rowdatas.mobileNumber;
    // let Slicetime = rowdatas.sliceTime
    // let printContents = '';


    // printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:50%">';
    // printContents = printContents + ' <table class="print-table1"><tr><td><h3>Appointment Details</h3></td></tr>';

    // printContents = printContents + ' <tr><td><strong>App Reg.No:</strong></td><td>' + PatientRegNo + '</td></tr>';

    // printContents = printContents + ' <tr><td><strong>App Time:</strong></td><td>' + this.timeSlot?.sliceTime + '</td></tr>';

    // printContents = printContents + ' <tr><td><strong>Mobile Number:</strong></td><td>' + PatientMob + '</td></tr>';
    // printContents = printContents + ' <tr><td><strong>Patient Name:</strong></td><td>' + PatientName + '</td></tr>';

    // var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
    // mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
    // mywindow.document.write('</head><body >');
    // mywindow.document.write(printContents);
    // mywindow.document.write('</body></html>');

    // mywindow.document.close(); 
    // mywindow.focus(); 

    // mywindow.print();
    // mywindow.close();
    // // });
    // return true;
  }

  showCancelAppointment(rowDatas) {
    this.modalEventEmitters.emit({
      status: true,
      eventType: 'cancel',
      data: rowDatas
    })
   }

   reload(){

   }

   AppointmentPostPoned(appId)
   {
    this.modalEventEmitters.emit({
      status: true,
      eventType: 'postpone',
      data: appId
    })

   }

   onRegisterfromAppoinment(data: any){
    this.router.navigate(['/AddRegistration'], {state: {appoinmentId: data.appId}});
  }

  appointmentEdit(appId) {
      this.router.navigate(["/AddAppointment", appId]);
  }

  consultationAction(appId) {
      if (appId) {
        this.router.navigate(["/AddConsultation", appId]);
      } else {
        this.router.navigate(["/AddRegistration"]);
      }
  }
}
