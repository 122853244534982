import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SignupComponent } from "../client/pages/signup/signup.component";
import { CommunicationConfigComponent } from "./communication-config/communication-config.component";
import { ConfigurationsComponent } from "./configurations.component";
import { DashboardComponent } from "../client/dashboard/dashboard.component";

import { branchesComponent } from "./branches/branches.component";
import { UsermanagementComponent } from "./usermanagement/usermanagement.component";
import { UserRightsComponent } from "./userrights/userrights.component";

import { consultantComponent } from "./consultant/consultant.component";
import { itembaseComponent } from "./consultant/itembasecount/itembase.component";
import { addconsultantComponent } from "./consultant/addconsultant/addconsultant.component";
import { ExternallabComponent } from "./externallab/externallab.component";
import { OperatorComponent } from "./operator/operator.component";
import { ZonesComponent } from "./zones/zone.component";
import { countryComponent } from "./country/country.component";
import { CompanyComponent } from "./company/company.component";
import { ProfessionComponent } from "./profession/profession.component";
import { ConsentFormComponent } from "./consentform/consentform.component";
import { LeadAgentComponent } from "./LeadAgent/LeadAgent.component";

import { CpctCodeComponent } from "./cpctcode/cpctcode.component";
import { RateGroupComponent } from "./rategroup/rategroup.component";
import { CPTModifierComponent } from "./cptmodifier/cptmodifier.component";
import { DepartmentComponent } from "./department/department.component";
import { serviceItemsComponent } from "./serviceItems/serviceItems.component";
import { TaxComponent } from "./tax/tax.component";
import { ItemBaseCostComponent } from "./itembasecost/itembasecost.component";
import { SketchIndicatorComponent } from "./sketchindicator/sketchindicator.component";
import { consultantworkingdaysComponent } from "./consultant/consultantworkingdays/consultantworkingdays.component";
import { CategoryComponent } from "./category/category.component";
import { IcdlabelComponent } from "./icdlabel/icdlabel.component";
import { SymptomComponent } from "./symptom/symptom.component";
import { SubLocationSettingsComponent } from "./sub-location-settings/sub-location-settings.component";
import { PackageAndProfileComponent } from "./package-and-profile/package-and-profile.component";
import { PhysicalExaminationSettingsComponent } from "./physical-examination-settings/physical-examination-settings.component";
import { CurrencyManagementComponent } from "./CurrencyManagement/CurrencyManagement.component";
import { SketchConfigurationComponent } from "./sketch-configuration/sketch-configuration.component";
import { drugsManagementComponent } from "./drugsManagement/drugsManagement.component";
import { LedgerSettingsComponent } from "./ledger-settings/ledger-settings.component";
import { VitalSymptomsComponent } from "./vital-symptoms/vital-symptoms.component";
import { SponsorAndSubagentComponent } from "./sponsor-and-subagent/sponsor-and-subagent.component";
import { SponsorRulesComponent } from "./sponsor-and-subagent/sponsor-rules/sponsor-rules.component";
import { SponsorFormComponent } from "./sponsor-form/sponsor-form.component";
import { QuestionmasterComponent } from "./questionmaster/questionmaster.component";
import { StaffComponent } from "./staff/staff.component";
import { CommissionRulesComponent } from "./commission-rules/commission-rules.component";
import { SavingschemeComponent } from "./savingscheme/savingscheme.component";
import { BodyPartsComponent } from "./bodyparts/bodyparts.component";
import { NumberSettingsComponent } from "./number-settings/number-settings.component";
import { PrintSponserFormComponent } from "./print-sponser-form/print-sponser-form.component";
import { AuthsGuard } from "../../auths.guard";
import { SettingsComponent } from "./settings/settings.component";
import { ReportWorklistComponent } from "./report-worklist/report-worklist.component";
import { AddEditTemplateComponent } from "./add-edit-template/add-edit-template.component";
import { ResultFormComponent } from "./result-form/result-form.component";
import { SampleTyeComponent } from "./sample-tye/sample-tye.component";
import { ResultPrintComponent } from "./resultPrint/resultPrint.component";
import { DesignationComponent } from "./designation/designation.component";
import { SponsorConsultantFeeComponent } from "./sponsor-and-subagent/sponsor-consultant-fee/sponsor-consultant-fee.component";

const routes: Routes = [
  {
    path: "config/communicationconfiguration",
    component: CommunicationConfigComponent,
    data: {
      title: "LeHealth | communicationconfiguration",
      pageName: "Communication",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/Branches",
    component: branchesComponent,
    data: { title: "LeHealth | Branches", pageName: "Branches" },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/Company",
    component: CompanyComponent,
    data: { title: "LeHealth | Company", pageName: "Company" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/Profession",
    component: ProfessionComponent,
    data: { title: "LeHealth | Profession", pageName: "Profession" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/ConsentForm",
    component: ConsentFormComponent,
    data: { title: "LeHealth | Consent Form", pageName: "Consent Forms" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/Usermanagement",
    component: UsermanagementComponent,
    data: { title: "LeHealth | Usermanagement", pageName: "User Management" },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/UserRights",
    component: UserRightsComponent,
    data: { title: "LeHealth | UserRights", pageName: "User Rights" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/Consultant",
    component: consultantComponent,
    data: { title: "LeHealth | Consultant", pageName: "Consultant" },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/Itembasecomponent",
    component: itembaseComponent,
    data: { title: "LeHealth | itembasecomponent", pageName: "Item Base Cost" },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/addconsultant",
    component: addconsultantComponent,
    data: { title: "LeHealth | addconsultant", pageName: "Consultant" },
    canActivate: [AuthsGuard],
  },

  // {
  //   path: 'configurations',
  //   component: ConfigComponent,
  //   data: { title: 'LeHealth | Configurations' }
  // },

  {
    path: "config/Operators",
    component: OperatorComponent,
    data: { title: "LeHealth | Operators", pageName: "Operators" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/zones",
    component: ZonesComponent,
    data: { title: "LeHealth | Zones", pageName: "Zones" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/country",
    component: countryComponent,
    data: { title: "LeHealth | Country", pageName: "Country " },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/LeadAgent",
    component: LeadAgentComponent,
    data: { title: "LeHealth | LeadAgent", pageName: "Lead Agent" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/departments",
    component: DepartmentComponent,
    data: { title: "LeHealth | departments", pageName: "Departments" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/CpctCode",
    component: CpctCodeComponent,
    data: { title: "LeHealth | CpctCode", pageName: "CPT Code" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/RateGroup",
    component: RateGroupComponent,
    data: { title: "LeHealth | RateGroup", pageName: "Rate Groups" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/QuestionMaster",
    component: QuestionmasterComponent,
    data: { title: "LeHealth | Questions", pageName: "Patient Questionnaire " },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/CPTModifier",
    component: CPTModifierComponent,
    data: { title: "LeHealth | CPTModifier", pageName: "CPT Modifier" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/serviceItems",
    component: serviceItemsComponent,
    data: { title: "LeHealth | serviceItems", pageName: "Service Items" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/tax",
    component: TaxComponent,
    data: { title: "LeHealth | tax", pageName: "Tax" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/consultantworkingdays",
    component: consultantworkingdaysComponent,
    data: {
      title: "LeHealth | consultantworkingdays",
      pageName: "Consultant Working Days",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/consultantworkingdays/:id",
    component: consultantworkingdaysComponent,
    data: {
      title: "LeHealth | consultantworkingdays",
      pageName: "Consultant Working Days",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/Category",
    component: CategoryComponent,
    data: { title: "LeHealth | Salutation", pageName: "Salutation" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/addconsultant/:id",
    component: addconsultantComponent,
    data: { title: "LeHealth | addconsultant", pageName: "Consultant" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/sketchindicator",
    component: SketchIndicatorComponent,
    data: { title: "LeHealth | ICDGroup", pageName: "Sketch Indicators" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/icdlabel",
    component: IcdlabelComponent,
    data: { title: "LeHealth | ICDLabel", pageName: "ICD Configs" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/symptom",
    component: SymptomComponent,
    data: { title: "LeHealth | Symptom", pageName: "Signs & Symptoms" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/subLocationSettings",
    component: SubLocationSettingsComponent,
    data: {
      title: "LeHealth | subLocationSettings",
      pageName: "Sub Locations",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/itembasecost/:id",
    component: ItemBaseCostComponent,
    data: { title: "LeHealth | ItemBaseCost", pageName: "Item Base Cost" },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/packageandprofile",
    component: PackageAndProfileComponent,
    data: {
      title: "LeHealth | packageandprofile",
      pageName: "Package And Profile",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/physicalexaminationsettings",
    component: PhysicalExaminationSettingsComponent,
    data: {
      title: "LeHealth | physicalexaminationsettings",
      pageName: "Physical Examination ",
    },
    canActivate: [AuthsGuard],
  }, //emr-pages
  {
    path: "config/CurrencyManagement",
    component: CurrencyManagementComponent,
    data: {
      title: "LeHealth | CurrencyManagement",
      pageName: "Currency & Card Type Management",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/sketchconfiguration",
    component: SketchConfigurationComponent,
    data: {
      title: "LeHealth | sketchconfiguration",
      pageName: "Sketch Configuration",
    },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/drugsManagement",
    component: drugsManagementComponent,
    data: { title: "LeHealth | drugsManagement", pageName: "Drugs Management" },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/ledgersettings",
    component: LedgerSettingsComponent,
    data: { title: "LeHealth | ledgersettings", pageName: "Ledger" },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/VitalSymptoms",
    component: VitalSymptomsComponent,
    data: { title: "LeHealth | VitalSymptoms", pageName: "Vital Signs" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/SponsorAndSubagent",
    component: SponsorAndSubagentComponent,
    data: {
      title: "LeHealth | SponsorAndSubagent",
      pageName: "Sponsor and Sub Agent",
    },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/SponsorRules",
    component: SponsorRulesComponent,
    data: { title: "LeHealth | SponsorRules", pageName: "Sponser Rules" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/SponsorRules/:sponsorId",
    component: SponsorRulesComponent,
    data: { title: "LeHealth | SponsorRules", pageName: "Sponser Rules" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/SponsorRules/:sponsorId/:id",
    component: SponsorRulesComponent,
    data: { title: "LeHealth | SponsorRules", pageName: "Sponser Rules" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/SponsorConsultanFee",
    component: SponsorConsultantFeeComponent,
    data: { title: "LeHealth | SponsorRules", pageName: "Sponser Rules" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/SponsorConsultanFee/:sponsorId",
    component: SponsorConsultantFeeComponent,
    data: { title: "LeHealth | SponsorRules", pageName: "Sponser Rules" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/SponsorConsultanFee/:sponsorId/:id",
    component: SponsorConsultantFeeComponent,
    data: { title: "LeHealth | SponsorRules", pageName: "Sponser Rules" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/SponsorForm",
    component: SponsorFormComponent,
    data: { title: "LeHealth | SponsorForm", pageName: "Sponser Form" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/staff",
    component: StaffComponent,
    data: { title: "LeHealth | Staff", pageName: "Staff" },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/commission-rules",
    component: CommissionRulesComponent,
    data: { title: "LeHealth | CommissionRules", pageName: "Commission Rules" },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/SavingScheme",
    component: SavingschemeComponent,
    data: {
      title: "LeHealth | SavingScheme",
      pageName: "Saving and Tab Scheme",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/BodyParts",
    component: BodyPartsComponent,
    data: { title: "LeHealth | BodyParts", pageName: "Body Parts" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/NumberSettings",
    component: NumberSettingsComponent,
    data: { title: "LeHealth | NumberSettings", pageName: "Number Settings" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/printForm",
    component: PrintSponserFormComponent,
    data: { title: "LeHealth | NumberSettings", pageName: "number settings" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/Settings",
    component: SettingsComponent,
    data: { title: "LeHealth | settings", pageName: " Settings" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/ReportWorkList",
    component: ReportWorklistComponent,
    data: { title: "LeHealth | Report WorkList", pageName: "Report Work List" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/ReportWorkList/add",
    component: AddEditTemplateComponent,
    data: {
      title: "LeHealth | Add Report WorkList",
      pageName: "Add Report Work List",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/ReportWorkList/edit/:templateId",
    component: AddEditTemplateComponent,
    data: {
      title: "LeHealth | Edit Report WorkList",
      pageName: "Edit Report Work List",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/ResultForm",
    component: ResultFormComponent,
    data: { title: "LeHealth | ResultForm", pageName: " Result Form" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/externallab",
    component: ExternallabComponent,
    data: { title: "LeHealth | External Lab", pageName: "External Lab" },
    canActivate: [AuthsGuard],
  },

  {
    path: "config/sampleType",
    component: SampleTyeComponent,
    data: { title: "LeHealth | Sample Type", pageName: "Sample Type" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/resultsettings",
    component: ResultPrintComponent,
    data: { title: "LeHealth | Result Settings", pageName: "Result Settings" },
    canActivate: [AuthsGuard],
  },
  {
    path: "config/designation",
    component: DesignationComponent,
    data: { title: "LeHealth | Designation", pageName: "Designation" },
    canActivate: [AuthsGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfigurationsRoutingModule {
  static components = [SignupComponent, DashboardComponent];
}
