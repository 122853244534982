import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { noop, Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';
import { Response } from '../../../../_Models/Response';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { marking, markingColor, markingTool, servicItems, teeth, cordinates } from '../../models/dental.model';
import { DentalService } from '../../services/dental.service';
import { EMRService } from '../../services/emr.service';
import { EMRPatientInfo, EMRSponsorInfo } from '../../interfaces/EMRPatientInfo';
import { HospitalsService } from '../../../client/services/hospitals.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mark-on-teeth-modal',
  templateUrl: './mark-on-teeth-modal.component.html',
  styleUrls: ['./mark-on-teeth-modal.component.scss']
})
export class MarkOnTeethModalComponent implements OnInit, AfterViewInit {

  @Input() selectedTeeth: teeth;
  @Input() branchId: number = 0;
  @Input() visitId: number = 0;
  @Input() treatments: any[] = [];
  @Input() modalPage: any;

  @Output() saveTreatmentDetailsEmitter = new EventEmitter();

  public suggestions$?: Observable<servicItems[]>;
  public searchTreatment: string = '';
  private baseUrl: string = '';

  public selectedTool: markingTool ;
  public toolItems: markingTool[] = [];
  public selectedColor: markingColor = {
    color:'#0d0b0ba1',
    colorName: 'default'
  }
  public selectedSize: number = 17.54;
  public colorItems: markingColor[] = [];
  public editMarking = {};

  public CPTModifiersList: any[] = [];

  surfaceItem: any[] = [
    { name: 'Labial', code: 'Labial' },
    { name: 'Lingual', code: 'Lingual' },
    { name: 'Mesial', code: 'Mesial' },
    { name: 'Distal', code: 'Distal' },
    { name: 'Occlusal', code: 'Occlusal' },
    { name: 'Lervical', code: 'Lervical' },
    { name: 'Inscisal', code: 'Inscisal' },
  ];
  selectedSurface = []
  public treatmentForm: FormGroup;

  private firstViewContext: CanvasRenderingContext2D;;
  private secondViewContext: CanvasRenderingContext2D;
  private thirdViewContext: CanvasRenderingContext2D;
  private fourthViewContext: CanvasRenderingContext2D;
  private fifthViewContext: CanvasRenderingContext2D;
  private drawingStatus: boolean = false;
  public objectCordinates: any;

  public canvasLoading: boolean = false;
  public markingSaveLoading: boolean = false;
  mixed = false;
  markedAsAdultFlag: number;
  EMRPatientInfo: EMRPatientInfo;

  showDropdown = false;
  instructorList: any;
  attachFlag = false;
  ngModelValue: any;
  public authenticationForm: FormGroup;
  itemIndex: number;
  instructFlag = false;
  checkFlag = false;
  showInstructor: any;
  EMRSponsorInfo:EMRSponsorInfo;

  constructor(private modalRef: BsModalRef,
              private http: HttpClient,
              private configurationManager: ConfigurationManager,
              private ConfigurationService: ConfigurationService,
              private dentalService: DentalService,
              private toastrService: ToastrService,
              private EMRService: EMRService,
              private hospitalsService: HospitalsService,
              private modalService: BsModalService,
              private formBuilder: FormBuilder,
              private emrService: EMRService,
              private datepipe: DatePipe,
              ) {
                this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
                this.EMRSponsorInfo = this.EMRService.GetEMRSponsorInfo();
                this.showInstructor = localStorage.getItem('isAprovedAuthority');
               }

  ngOnInit(): void {
    this.markedAsAdultFlag = this.selectedTeeth.pediatricData.markedAsAdult;
    this.mixed = this.selectedTeeth.mixedDentition;
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.searchTreatment);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<servicItems[]>>(
              `${this.baseUrl}api/EMR/GetEMRServiceItem`,
              {
                ServiceName: query,
                branchId: this.branchId,
                GroupId: 0,
                consultantId: 0,
                GroupCode: "114",
                ConsultationId: this.EMRPatientInfo.ConsultationId,
                CurrentDate: this.datepipe.transform(new Date(), "dd-MM-yyyy"),
                CreditId: this.EMRSponsorInfo.CreditId,
                Category: 'N'
              }
            )
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  this.toastrService.error(err ? err.message : 'Something goes wrong');
                  // this.errorMessage =
                  //   (err && err.message) || "Something goes wrong";
                }
              )
            );
        }

        return of([]);
      })
    );

    this.authenticationForm = this.formBuilder.group({
      BranchId: new FormControl(this.branchId),
      ConsultantId: new FormControl(),
      UserId: new FormControl(),
      Username: new FormControl(),
      Password: new FormControl(),
    })

    this.toolItems = [
      {
        icon: 'fa-times',
        tool: 'x-mark',
        toolName: 'Missing'
      },
      {
        icon: 'fa-square-o',
        tool: 'square-o',
        toolName: 'square-o'
      },
      {
        icon: 'fa-square',
        tool: 'square',
        toolName: 'square'
      },
      {
        icon: 'fa-circle',
        tool: 'circle',
        toolName: 'circle'
      },
      {
        icon: 'fa-circle-o',
        tool: 'circle-o',
        toolName: 'circle-o'
      },
      {
        icon: 'fa-pencil',
        tool: 'pencil',
        toolName: 'pencil'
      },
      {
        icon: 'fa-spinner',
        tool: 'spinner',
        toolName: 'spinner'
      },
      {
        icon: 'fa-bolt',
        tool: 'bolt',
        toolName: 'Mobility'
      }
    ]

    this.colorItems = [
      {
        color: '#18cd3c',
        colorName: 'Green'
      },
      {
        color: '#d81326',
        colorName: 'Red'
      },
      {
        color: '#1219d3',
        colorName: 'Blue'
      }
    ]
    
    this.initTreatmentForm();
    this.getAllCPTModifier();
    this.GetAuthorisedConsultant();
  }

  GetAuthorisedConsultant() {
    let payload = {
      ConsultantId: 0,
      BranchId: this.branchId
    }
    
    this.ConfigurationService.GetAuthorisedConsultant(payload).subscribe((res) => {
      this.instructorList = res.response;
    })
  }

  onDeptSelect(event: any, i) { // select multiple departments
    this.instructFlag = false;
    this.checkFlag = true;
    this.attachFlag = true;
    let instructValue = this.treatmentForm.get("itemDetails").value[i].Instructor;
    let itemValue = this.treatmentForm.get("itemDetails").value[i].itemId;
    this.authenticationForm.controls.ConsultantId.setValue(Number(instructValue));
    this.instructorList.find(x => {
      if(x.consultantId == instructValue) {
        this.authenticationForm.controls.UserId.setValue(x.userId);
      }
    })

    this.itemIndex = this.treatmentForm.get("itemDetails").value.findIndex(x => (_.isEqual(x.Instructor, instructValue) && _.isEqual(x.itemId, itemValue)));
  }

  close(){
    this.modalRef.hide();
  }

  ngAfterViewInit() {
    let firstcanvasRef: any = <HTMLCanvasElement>(document.getElementById('teeth-canvas-view-1'));
    let firstcanvas: CanvasRenderingContext2D = firstcanvasRef.getContext('2d');
    firstcanvas.lineWidth = 1;
    firstcanvas.lineJoin = 'round';
    this.firstViewContext = firstcanvas;

    let secondcanvasRef: any = <HTMLCanvasElement>(document.getElementById('teeth-canvas-view-2'));
    let secondcanvas: CanvasRenderingContext2D = secondcanvasRef.getContext('2d');
    secondcanvas.lineWidth = 1;
    secondcanvas.lineJoin = 'round';
    this.secondViewContext = secondcanvas;

    let thirdcanvasRef: any = <HTMLCanvasElement>(document.getElementById('teeth-canvas-view-3'));
    let thirdcanvas: CanvasRenderingContext2D = thirdcanvasRef.getContext('2d');
    thirdcanvas.lineWidth = 1;
    thirdcanvas.lineJoin = 'round';
    this.thirdViewContext = thirdcanvas;

    let fourthcanvasRef: any = <HTMLCanvasElement>(document.getElementById('teeth-canvas-view-4'));
    let fourthcanvas: CanvasRenderingContext2D = fourthcanvasRef.getContext('2d');
    fourthcanvas.lineWidth = 1;
    fourthcanvas.lineJoin = 'round';
    this.fourthViewContext = fourthcanvas;

    let fifthcanvasRef: any = <HTMLCanvasElement>(document.getElementById('teeth-canvas-view-5'));
    let fifthCanvas: CanvasRenderingContext2D = fifthcanvasRef.getContext('2d');
    fifthCanvas.lineWidth = 1;
    fifthCanvas.lineJoin = 'round';
    this.fifthViewContext = fifthCanvas;

    this.canvasLoading = true;
    setTimeout(() => {
      this.drawOnCanvasInit();
      this.canvasLoading = false;
    }, 1000);
  }

  drawOnCanvasInit(){

    let img1 = new Image(58,101);
    img1.src = this.selectedTeeth?.views?.firstView?.imageUrl;
    img1.crossOrigin = 'anonymous';
    img1.onload = ()=>{
      this.firstViewContext.drawImage(img1, 0, 0);

      if(this.selectedTeeth.views?.firstView?.markings?.length > 0){
        const markings: marking[] = this.selectedTeeth.views?.firstView?.markings;
  
        _.forEach(markings, (marking: marking, index: number)=>{
          this.firstViewContext.fillStyle = marking.markingColor;
          this.firstViewContext.strokeStyle = marking.markingColor;
          let size = Number(((marking.markingSize * 57)/100).toFixed(0));
          if(marking.markingType == 'pencil'){
            this.firstViewContext.beginPath()
            this.firstViewContext.moveTo(marking.cordinates[0].x, marking.cordinates[0].y);
            for(let i=1; i<marking.cordinates.length; i++){
              this.firstViewContext.lineTo(marking.cordinates[i].x, marking.cordinates[i].y);
              this.firstViewContext.stroke();
            }
          }else if(marking.markingType == 'square-o'){
            this.firstViewContext.beginPath();
            this.firstViewContext.rect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
            this.firstViewContext.closePath();
            this.firstViewContext.stroke() 
          }else if(marking.markingType == 'square'){
            this.firstViewContext.beginPath();
            this.firstViewContext.fillRect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
            this.firstViewContext.closePath();
            this.firstViewContext.stroke()
          }else if(marking.markingType == 'circle-o'){
            this.firstViewContext.beginPath();
            this.firstViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
            this.firstViewContext.closePath();
            this.firstViewContext.stroke()
          }else if(marking.markingType == 'circle'){
            this.firstViewContext.beginPath();
            this.firstViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
            this.firstViewContext.fill();
            this.firstViewContext.closePath();
            this.firstViewContext.stroke();
          }else if(marking.markingType == 'spinner'){
            this.firstViewContext.beginPath();
            this.firstViewContext.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
            this.firstViewContext.closePath();
            this.firstViewContext.stroke();
          }else if(marking.markingType == 'x-mark'){
            this.firstViewContext.moveTo(29 - 20, 50.5 - 20);
            this.firstViewContext.lineTo(29 + 20, 50.5 + 20);
            this.firstViewContext.moveTo(29 + 20, 50.5 - 20);
            this.firstViewContext.lineTo(29 - 20, 50.5 + 20);
            this.firstViewContext.closePath();
            this.firstViewContext.stroke();
          }else if(marking.markingType == 'bolt'){
            this.firstViewContext.font = `${30}px FontAwesome`;
            this.firstViewContext.fillText('\uf0e7', 42, 26);
            this.firstViewContext.closePath();
            this.firstViewContext.stroke();
  
            this.firstViewContext.beginPath();
            this.firstViewContext.font = `${30}px FontAwesome`;
            this.firstViewContext.fillText('\uf0e7', 0, 26);
            this.firstViewContext.closePath();
            this.firstViewContext.stroke();
          }
        });
  
      }
    }

    let img2 = new Image(58,101);
    img2.src = this.selectedTeeth?.views?.secondView?.imageUrl;
    img2.crossOrigin = 'anonymous';
    img2.onload = ()=>{
      this.secondViewContext.drawImage(img2, 0, 0);

      if(this.selectedTeeth.views?.secondView?.markings?.length > 0){
        const markings: marking[] = this.selectedTeeth.views?.secondView?.markings;
  
        _.forEach(markings, (marking: marking, index: number)=>{
          this.secondViewContext.fillStyle = marking.markingColor;
          this.secondViewContext.strokeStyle = marking.markingColor;
          let size = Number(((marking.markingSize * 57)/100).toFixed(0));
          if(marking.markingType == 'pencil'){
            this.secondViewContext.beginPath();
            this.secondViewContext.moveTo(marking.cordinates[0].x, marking.cordinates[0].y);
            for(let i=1; i<marking.cordinates.length; i++){
              this.secondViewContext.lineTo(marking.cordinates[i].x, marking.cordinates[i].y);
              this.secondViewContext.stroke();
            }
          }else if(marking.markingType == 'square-o'){
            this.secondViewContext.beginPath();
            this.secondViewContext.rect(marking.cordinates[0].x -(size/2), marking.cordinates[0].y - (size/2), size, size);
            this.secondViewContext.closePath();
            this.secondViewContext.stroke() 
          }else if(marking.markingType == 'square'){
            this.secondViewContext.beginPath();
            this.secondViewContext.fillRect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
            this.secondViewContext.closePath();
            this.secondViewContext.stroke()
          }else if(marking.markingType == 'circle-o'){
            this.secondViewContext.beginPath();
            this.secondViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
            this.secondViewContext.closePath();
            this.secondViewContext.stroke()
          }else if(marking.markingType == 'circle'){
            this.secondViewContext.beginPath();
            this.secondViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
            this.secondViewContext.fill();
            this.secondViewContext.closePath();
            this.secondViewContext.stroke();
          }else if(marking.markingType == 'spinner'){
            this.secondViewContext.beginPath();
            this.secondViewContext.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
            this.secondViewContext.closePath();
            this.secondViewContext.stroke();
          }else if(marking.markingType == 'x-mark'){
            this.secondViewContext.moveTo(29 - 20, 50.5 - 20);
            this.secondViewContext.lineTo(29 + 20, 50.5 + 20);
            this.secondViewContext.moveTo(29 + 20, 50.5 - 20);
            this.secondViewContext.lineTo(29 - 20, 50.5 + 20);
            this.secondViewContext.closePath();
            this.secondViewContext.stroke();
          }else if(marking.markingType == 'bolt'){
            this.secondViewContext.font = `${30}px FontAwesome`;
            this.secondViewContext.fillText('\uf0e7', 42, 26);
            this.secondViewContext.closePath();
            this.secondViewContext.stroke();
  
            this.secondViewContext.beginPath();
            this.secondViewContext.font = `${30}px FontAwesome`;
            this.secondViewContext.fillText('\uf0e7', 0, 26);
            this.secondViewContext.closePath();
            this.secondViewContext.stroke();
          }
        });
  
      }
    }

    let img3 = new Image(58,101);
    img3.src = this.selectedTeeth?.views?.thirdView?.imageUrl;
    img3.crossOrigin = 'anonymous';
    img3.onload = ()=>{
      this.thirdViewContext.drawImage(img3, 0, 0);

      if(this.selectedTeeth.views?.thirdView?.markings?.length > 0){
        const markings: marking[] = this.selectedTeeth.views?.thirdView?.markings;
  
        _.forEach(markings, (marking: marking, index: number)=>{
          this.thirdViewContext.fillStyle = marking.markingColor;
          this.thirdViewContext.strokeStyle = marking.markingColor;
          let size = Number(((marking.markingSize * 57)/100).toFixed(0));
          if(marking.markingType == 'pencil'){
            this.thirdViewContext.beginPath();
            this.thirdViewContext.moveTo(marking.cordinates[0].x, marking.cordinates[0].y);
            for(let i=1; i<marking.cordinates.length; i++){
              this.thirdViewContext.lineTo(marking.cordinates[i].x, marking.cordinates[i].y);
              this.thirdViewContext.stroke();
            }
          }else if(marking.markingType == 'square-o'){
            this.thirdViewContext.beginPath();
            this.thirdViewContext.rect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
            this.thirdViewContext.closePath();
            this.thirdViewContext.stroke() 
          }else if(marking.markingType == 'square'){
            this.thirdViewContext.beginPath();
            this.thirdViewContext.fillRect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
            this.thirdViewContext.closePath();
            this.thirdViewContext.stroke()
          }else if(marking.markingType == 'circle'){
            this.thirdViewContext.beginPath();
            this.thirdViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
            this.thirdViewContext.fill();
            this.thirdViewContext.closePath();
            this.thirdViewContext.stroke()
          }else if(marking.markingType == 'circle-o'){
            this.thirdViewContext.beginPath();
            this.thirdViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
            this.thirdViewContext.closePath();
            this.thirdViewContext.stroke()
          }else if(marking.markingType == 'spinner'){
            this.thirdViewContext.beginPath();
            this.thirdViewContext.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
            this.thirdViewContext.closePath();
            this.thirdViewContext.stroke();
          }else if(marking.markingType == 'x-mark'){
            this.thirdViewContext.moveTo(29 - 20, 50.5 - 20);
            this.thirdViewContext.lineTo(29 + 20, 50.5 + 20);
            this.thirdViewContext.moveTo(29 + 20, 50.5 - 20);
            this.thirdViewContext.lineTo(29 - 20, 50.5 + 20);
            this.thirdViewContext.closePath();
            this.thirdViewContext.stroke();
          }else if(marking.markingType == 'bolt'){
            this.thirdViewContext.font = `${30}px FontAwesome`;
            this.thirdViewContext.fillText('\uf0e7', 42, 26);
            this.thirdViewContext.closePath();
            this.thirdViewContext.stroke();
  
            this.thirdViewContext.beginPath();
            this.thirdViewContext.font = `${30}px FontAwesome`;
            this.thirdViewContext.fillText('\uf0e7', 0, 26);
            this.thirdViewContext.closePath();
            this.thirdViewContext.stroke();
          }
        });
  
      }
    }

    let img4 = new Image(58,101);
    img4.src = this.selectedTeeth?.views?.fourthView?.imageUrl;
    img4.crossOrigin = 'anonymous';
    img4.onload = ()=>{
      this.fourthViewContext.drawImage(img4, 0, 0);

      if(this.selectedTeeth.views?.fourthView?.markings?.length > 0){
        const markings: marking[] = this.selectedTeeth.views?.fourthView?.markings;
        _.forEach(markings, (marking: marking, index: number)=>{
          this.fourthViewContext.fillStyle = marking.markingColor;
          this.fourthViewContext.strokeStyle = marking.markingColor;
          let size = Number(((marking.markingSize * 57)/100).toFixed(0));
          if(marking.markingType == 'pencil'){
            this.fourthViewContext.beginPath();
            this.fourthViewContext.moveTo(marking.cordinates[0].x, marking.cordinates[0].y);
            for(let i=1; i<marking.cordinates.length; i++){
              this.fourthViewContext.lineTo(marking.cordinates[i].x, marking.cordinates[i].y);
              this.fourthViewContext.stroke();
            }
          }else if(marking.markingType == 'square-o'){
            this.fourthViewContext.beginPath();
            this.fourthViewContext.rect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
            this.fourthViewContext.closePath();
            this.fourthViewContext.stroke() 
          }else if(marking.markingType == 'square'){
            this.fourthViewContext.beginPath();
            this.fourthViewContext.fillRect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
            this.fourthViewContext.closePath();
            this.fourthViewContext.stroke()
          }else if(marking.markingType == 'circle'){
            this.fourthViewContext.beginPath();
            this.fourthViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
            this.fourthViewContext.fill();
            this.fourthViewContext.closePath();
            this.fourthViewContext.stroke()
          }else if(marking.markingType == 'circle-o'){
            this.fourthViewContext.beginPath();
            this.fourthViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
            this.fourthViewContext.closePath();
            this.fourthViewContext.stroke()
          }else if(marking.markingType == 'spinner'){
            this.fourthViewContext.beginPath();
            this.fourthViewContext.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
            this.fourthViewContext.closePath();
            this.fourthViewContext.stroke();
          }else if(marking.markingType == 'x-mark'){
            this.fourthViewContext.moveTo(29 - 20, 50.5 - 20);
            this.fourthViewContext.lineTo(29 + 20, 50.5 + 20);
            this.fourthViewContext.moveTo(29 + 20, 50.5 - 20);
            this.fourthViewContext.lineTo(29 - 20, 50.5 + 20);
            this.fourthViewContext.closePath();
            this.fourthViewContext.stroke();
          }else if(marking.markingType == 'bolt'){
            this.fourthViewContext.font = `${30}px FontAwesome`;
            this.fourthViewContext.fillText('\uf0e7', 42, 26);
            this.fourthViewContext.closePath();
            this.fourthViewContext.stroke();
  
            this.fourthViewContext.beginPath();
            this.fourthViewContext.font = `${30}px FontAwesome`;
            this.fourthViewContext.fillText('\uf0e7', 0, 26);
            this.fourthViewContext.closePath();
            this.fourthViewContext.stroke();
          }
        });
      }
    }

    let img5 = new Image(58,101);
    img5.src = this.selectedTeeth?.views?.fifthView?.imageUrl;
    img5.crossOrigin = 'anonymous';
    img5.onload = ()=>{
      this.fifthViewContext.drawImage(img5, 0, 0);

      if(this.selectedTeeth.views?.fifthView?.markings?.length > 0){
        const markings: marking[] = this.selectedTeeth.views?.fifthView?.markings;
        _.forEach(markings, (marking: marking, index: number)=>{
          this.fifthViewContext.fillStyle = marking.markingColor;
          this.fifthViewContext.strokeStyle = marking.markingColor;
          let size = Number(((marking.markingSize * 57)/100).toFixed(0));
          if(marking.markingType == 'pencil'){
            this.fifthViewContext.beginPath();
            this.fifthViewContext.moveTo(marking.cordinates[0].x, marking.cordinates[0].y);
            for(let i=1; i<marking.cordinates.length; i++){
              this.fifthViewContext.lineTo(marking.cordinates[i].x, marking.cordinates[i].y);
              this.fifthViewContext.stroke();
            }
          }else if(marking.markingType == 'square-o'){
            this.fifthViewContext.beginPath();
            this.fifthViewContext.rect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
            this.fifthViewContext.closePath();
            this.fifthViewContext.stroke() 
          }else if(marking.markingType == 'square'){
            this.fifthViewContext.beginPath();
            this.fifthViewContext.fillRect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
            this.fifthViewContext.closePath();
            this.fifthViewContext.stroke()
          }else if(marking.markingType == 'circle'){
            this.fifthViewContext.beginPath();
            this.fifthViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
            this.fifthViewContext.fill();
            this.fifthViewContext.closePath();
            this.fifthViewContext.stroke()
          }else if(marking.markingType == 'circle-o'){
            this.fifthViewContext.beginPath();
            this.fifthViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
            this.fifthViewContext.closePath();
            this.fifthViewContext.stroke()
          }else if(marking.markingType == 'spinner'){
            this.fifthViewContext.beginPath();
            this.fifthViewContext.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
            this.fifthViewContext.closePath();
            this.fifthViewContext.stroke();
          }else if(marking.markingType == 'x-mark'){
            this.fifthViewContext.moveTo(29 - 20, 50.5 - 20);
            this.fifthViewContext.lineTo(29 + 20, 50.5 + 20);
            this.fifthViewContext.moveTo(29 + 20, 50.5 - 20);
            this.fifthViewContext.lineTo(29 - 20, 50.5 + 20);
            this.fifthViewContext.closePath();
            this.fifthViewContext.stroke();
          }else if(marking.markingType == 'bolt'){
            this.fifthViewContext.font = `${30}px FontAwesome`;
            this.fifthViewContext.fillText('\uf0e7', 42, 26);
            this.fifthViewContext.closePath();
            this.fifthViewContext.stroke();
  
            this.fifthViewContext.beginPath();
            this.fifthViewContext.font = `${30}px FontAwesome`;
            this.fifthViewContext.fillText('\uf0e7', 0, 26);
            this.fifthViewContext.closePath();
            this.fifthViewContext.stroke();
          }
        });
      }
    }

    this.addMapFormGroup( this.treatments, 0);

  }

  ////////////////test ////////////////////

  startDrawing(event: MouseEvent, index: number) {
    // code to start drawing
    if(this.selectedTool && this.selectedTool.tool != ''){
      switch(index){
        case 1: this.firstViewContext.strokeStyle = this.selectedColor.color;
                this.firstViewContext.fillStyle = this.selectedColor.color;
                this.firstViewContext.beginPath();
                this.startDrawingOnCanvas(this.firstViewContext, event);
                break;

        case 2: this.secondViewContext.strokeStyle = this.selectedColor.color;
                this.secondViewContext.fillStyle = this.selectedColor.color;
                this.secondViewContext.beginPath();
                this.startDrawingOnCanvas(this.secondViewContext, event);
                break;

        case 3: this.thirdViewContext.strokeStyle = this.selectedColor.color;
                this.thirdViewContext.fillStyle = this.selectedColor.color;
                this.thirdViewContext.beginPath();
                this.startDrawingOnCanvas(this.thirdViewContext, event);
                break;
        case 4: this.fourthViewContext.strokeStyle = this.selectedColor.color;
                this.fourthViewContext.fillStyle = this.selectedColor.color;
                this.fourthViewContext.beginPath();
                this.startDrawingOnCanvas(this.fourthViewContext, event);
                break;
        case 5: this.fifthViewContext.strokeStyle = this.selectedColor.color;
                this.fifthViewContext.fillStyle = this.selectedColor.color;
                this.fifthViewContext.beginPath();
                this.startDrawingOnCanvas(this.fifthViewContext, event);
                break;
      }
      let marking: marking = {
        markingText : this.selectedTool.toolName,
        cordinates: [],
        markingType: this.selectedTool.tool,
        markingColor: this.selectedColor.color,
        markingSize: this.selectedSize
      }
      marking.cordinates.push({x: event.offsetX, y: event.offsetY});
      this.objectCordinates = marking;
      this.drawingStatus = true;
    }
  }

  draw(event: MouseEvent, index: number) {
    // code to draw on canvas
    if(this.drawingStatus && this.selectedTool.tool == 'pencil'){
      switch(index){
        case 1 : this.drawOnCanvas(this.firstViewContext, event);
                 break;
        case 2 : this.drawOnCanvas(this.secondViewContext, event);
                 break;
        case 3 : this.drawOnCanvas(this.thirdViewContext, event);
                 break;
        case 4 : this.drawOnCanvas(this.fourthViewContext, event);
                 break;
      }
      let item = {
        x: event.offsetX,
        y: event.offsetY
      }
      this.objectCordinates.cordinates.push(item);
    }
    
  }

  stopDrawing(index: number) {
    // code to stop drawing and get coordinates
    if(this.drawingStatus){
      this.drawingStatus = false;
      this.selectedTool = null;
      switch(index){
        case 1 : this.selectedTeeth.views?.firstView.markings.push(this.objectCordinates);
                 break;
        case 2 : this.selectedTeeth.views?.secondView.markings.push(this.objectCordinates);
                 break;
        case 3 : this.selectedTeeth.views?.thirdView.markings.push(this.objectCordinates);
                 break;
        case 4 : this.selectedTeeth.views?.fourthView.markings.push(this.objectCordinates);
                 break;
        case 5 : this.selectedTeeth.views?.fifthView.markings.push(this.objectCordinates);
                 break;
        default : break;
      }
      this.objectCordinates = null;
    }
    // const imageData = this.context.getImageData(0, 0, this.canvasRef.nativeElement.width, this.canvasRef.nativeElement.height);
    // code to process the image data and determine coordinates
  }

  removeMarking(viewIndex: number, markingIndex: any){
    switch(viewIndex){
      case 1 : this.selectedTeeth.views.firstView.markings = _.filter(this.selectedTeeth.views.firstView.markings, (item: any, index: number)=>{
                  return index != markingIndex
               });
               this.firstViewContext.clearRect(0,0,58,101);
               let img1 = new Image(58,101);
               img1.src = this.selectedTeeth?.views?.firstView?.imageUrl;
               img1.crossOrigin = 'anonymous';
               img1.onload = ()=>{
                this.firstViewContext.drawImage(img1, 0, 0);

                _.forEach(this.selectedTeeth?.views?.firstView?.markings, (marking: marking, index: number)=>{
                  this.firstViewContext.fillStyle = marking.markingColor;
                  this.firstViewContext.strokeStyle = marking.markingColor;
                  let size = Number(((marking.markingSize * 57)/100).toFixed(0));
                  if(marking.markingType == 'pencil'){
                    this.firstViewContext.beginPath()
                    this.firstViewContext.moveTo(marking.cordinates[0].x, marking.cordinates[0].y);
                    for(let i=1; i<marking.cordinates.length; i++){
                      this.firstViewContext.lineTo(marking.cordinates[i].x, marking.cordinates[i].y);
                      this.firstViewContext.stroke();
                    }
                  }else if(marking.markingType == 'square-o'){
                    this.firstViewContext.beginPath();
                    this.firstViewContext.rect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
                    this.firstViewContext.closePath();
                    this.firstViewContext.stroke() 
                  }else if(marking.markingType == 'square'){
                    this.firstViewContext.beginPath();
                    this.firstViewContext.fillRect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
                    this.firstViewContext.closePath();
                    this.firstViewContext.stroke()
                  }else if(marking.markingType == 'circle-o'){
                    this.firstViewContext.beginPath();
                    this.firstViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
                    this.firstViewContext.closePath();
                    this.firstViewContext.stroke()
                  }else if(marking.markingType == 'circle'){
                    this.firstViewContext.beginPath();
                    this.firstViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
                    this.firstViewContext.fill();
                    this.firstViewContext.closePath();
                    this.firstViewContext.stroke();
                  }else if(marking.markingType == 'spinner'){
                    this.firstViewContext.beginPath();
                    this.firstViewContext.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
                    this.firstViewContext.closePath();
                    this.firstViewContext.stroke();
                  }else if(marking.markingType == 'x-mark'){
                    this.firstViewContext.moveTo(29 - 20, 50.5 - 20);
                    this.firstViewContext.lineTo(29 + 20, 50.5 + 20);
                    this.firstViewContext.moveTo(29 + 20, 50.5 - 20);
                    this.firstViewContext.lineTo(29 - 20, 50.5 + 20);
                    this.firstViewContext.closePath();
                    this.firstViewContext.stroke();
                  }else if(marking.markingType == 'bolt'){
                    this.firstViewContext.font = `${30}px FontAwesome`;
                    this.firstViewContext.fillText('\uf0e7', 42, 26);
                    this.firstViewContext.closePath();
                    this.firstViewContext.stroke();

                    this.firstViewContext.beginPath();
                    this.firstViewContext.font = `${30}px FontAwesome`;
                    this.firstViewContext.fillText('\uf0e7', 0, 26);
                    this.firstViewContext.closePath();
                    this.firstViewContext.stroke();
                  }
                });
               }

               break;
      case 2 : this.selectedTeeth.views.secondView.markings = _.filter(this.selectedTeeth.views.secondView.markings, (item: any, index: number)=>{
                    return index != markingIndex
                });
                this.secondViewContext.clearRect(0,0,58,101);
                let img2 = new Image(58,101);
                img2.src = this.selectedTeeth?.views?.secondView?.imageUrl;
                img2.crossOrigin = 'anonymous';
                img2.onload = ()=>{
                  this.secondViewContext.drawImage(img2, 0, 0);

                  _.forEach(this.selectedTeeth?.views?.secondView?.markings, (marking: marking, index: number)=>{
                    this.secondViewContext.fillStyle = marking.markingColor;
                    this.secondViewContext.strokeStyle = marking.markingColor;
                    let size = Number(((marking.markingSize * 57)/100).toFixed(0));
                    if(marking.markingType == 'pencil'){
                      this.secondViewContext.beginPath();
                      this.secondViewContext.moveTo(marking.cordinates[0].x, marking.cordinates[0].y);
                      for(let i=1; i<marking.cordinates.length; i++){
                        this.secondViewContext.lineTo(marking.cordinates[i].x, marking.cordinates[i].y);
                        this.secondViewContext.stroke();
                      }
                    }else if(marking.markingType == 'square-o'){
                      this.secondViewContext.beginPath();
                      this.secondViewContext.rect(marking.cordinates[0].x -(size/2), marking.cordinates[0].y - (size/2), size, size);
                      this.secondViewContext.closePath();
                      this.secondViewContext.stroke() 
                    }else if(marking.markingType == 'square'){
                      this.secondViewContext.beginPath();
                      this.secondViewContext.fillRect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
                      this.secondViewContext.closePath();
                      this.secondViewContext.stroke()
                    }else if(marking.markingType == 'circle-o'){
                      this.secondViewContext.beginPath();
                      this.secondViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
                      this.secondViewContext.closePath();
                      this.secondViewContext.stroke()
                    }else if(marking.markingType == 'circle'){
                      this.secondViewContext.beginPath();
                      this.secondViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
                      this.secondViewContext.fill();
                      this.secondViewContext.closePath();
                      this.secondViewContext.stroke();
                    }else if(marking.markingType == 'spinner'){
                      this.secondViewContext.beginPath();
                      this.secondViewContext.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
                      this.secondViewContext.closePath();
                      this.secondViewContext.stroke();
                    }else if(marking.markingType == 'x-mark'){
                      this.secondViewContext.moveTo(29 - 20, 50.5 - 20);
                      this.secondViewContext.lineTo(29 + 20, 50.5 + 20);
                      this.secondViewContext.moveTo(29 + 20, 50.5 - 20);
                      this.secondViewContext.lineTo(29 - 20, 50.5 + 20);
                      this.secondViewContext.closePath();
                      this.secondViewContext.stroke();
                    }else if(marking.markingType == 'bolt'){
                      this.secondViewContext.font = `${30}px FontAwesome`;
                      this.secondViewContext.fillText('\uf0e7', 42, 26);
                      this.secondViewContext.closePath();
                      this.secondViewContext.stroke();
  
                      this.secondViewContext.beginPath();
                      this.secondViewContext.font = `${30}px FontAwesome`;
                      this.secondViewContext.fillText('\uf0e7', 0, 26);
                      this.secondViewContext.closePath();
                      this.secondViewContext.stroke();
                    }
                  });
                }
                
                break;
      case 3 : this.selectedTeeth.views.thirdView.markings = _.filter(this.selectedTeeth.views.thirdView.markings, (item: any, index: number)=>{
                    return index != markingIndex
                });
                this.thirdViewContext.clearRect(0,0,58,101);
                let img3 = new Image(58,101);
                img3.src = this.selectedTeeth?.views?.thirdView?.imageUrl;
                img3.crossOrigin = 'anonymous';
                img3.onload = ()=>{
                  this.thirdViewContext.drawImage(img3, 0, 0);

                  _.forEach(this.selectedTeeth?.views?.thirdView?.markings, (marking: marking, index: number)=>{
                    this.thirdViewContext.fillStyle = marking.markingColor;
                    this.thirdViewContext.strokeStyle = marking.markingColor;
                    let size = Number(((marking.markingSize * 57)/100).toFixed(0));
                    if(marking.markingType == 'pencil'){
                      this.thirdViewContext.beginPath();
                      this.thirdViewContext.moveTo(marking.cordinates[0].x, marking.cordinates[0].y);
                      for(let i=1; i<marking.cordinates.length; i++){
                        this.thirdViewContext.lineTo(marking.cordinates[i].x, marking.cordinates[i].y);
                        this.thirdViewContext.stroke();
                      }
                    }else if(marking.markingType == 'square-o'){
                      this.thirdViewContext.beginPath();
                      this.thirdViewContext.rect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
                      this.thirdViewContext.closePath();
                      this.thirdViewContext.stroke() 
                    }else if(marking.markingType == 'square'){
                      this.thirdViewContext.beginPath();
                      this.thirdViewContext.fillRect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
                      this.thirdViewContext.closePath();
                      this.thirdViewContext.stroke()
                    }else if(marking.markingType == 'circle'){
                      this.thirdViewContext.beginPath();
                      this.thirdViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
                      this.thirdViewContext.fill();
                      this.thirdViewContext.closePath();
                      this.thirdViewContext.stroke()
                    }else if(marking.markingType == 'circle-o'){
                      this.thirdViewContext.beginPath();
                      this.thirdViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
                      this.thirdViewContext.closePath();
                      this.thirdViewContext.stroke()
                    }else if(marking.markingType == 'spinner'){
                      this.thirdViewContext.beginPath();
                      this.thirdViewContext.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
                      this.thirdViewContext.closePath();
                      this.thirdViewContext.stroke();
                    }else if(marking.markingType == 'x-mark'){
                      this.thirdViewContext.moveTo(29 - 20, 50.5 - 20);
                      this.thirdViewContext.lineTo(29 + 20, 50.5 + 20);
                      this.thirdViewContext.moveTo(29 + 20, 50.5 - 20);
                      this.thirdViewContext.lineTo(29 - 20, 50.5 + 20);
                      this.thirdViewContext.closePath();
                      this.thirdViewContext.stroke();
                    }else if(marking.markingType == 'bolt'){
                      this.thirdViewContext.font = `${30}px FontAwesome`;
                      this.thirdViewContext.fillText('\uf0e7', 42, 26);
                      this.thirdViewContext.closePath();
                      this.thirdViewContext.stroke();
  
                      this.thirdViewContext.beginPath();
                      this.thirdViewContext.font = `${30}px FontAwesome`;
                      this.thirdViewContext.fillText('\uf0e7', 0, 26);
                      this.thirdViewContext.closePath();
                      this.thirdViewContext.stroke();
                    }
                  });
                }
                
                break;
      case 4 : this.selectedTeeth.views.fourthView.markings = _.filter(this.selectedTeeth.views.fourthView.markings, (item: any, index: number)=>{
                  return index != markingIndex
                });
                this.fourthViewContext.clearRect(0,0,58,101);
                let img4 = new Image(58,101);
                img4.src = this.selectedTeeth?.views?.fourthView?.imageUrl;
                img4.crossOrigin = 'anonymous';
                img4.onload = ()=>{
                  this.fourthViewContext.drawImage(img4, 0, 0);

                  _.forEach(this.selectedTeeth?.views?.fourthView?.markings, (marking: marking, index: number)=>{
                    this.fourthViewContext.fillStyle = marking.markingColor;
                    this.fourthViewContext.strokeStyle = marking.markingColor;
                    let size = Number(((marking.markingSize * 57)/100).toFixed(0));
                    if(marking.markingType == 'pencil'){
                      this.fourthViewContext.beginPath();
                      this.fourthViewContext.moveTo(marking.cordinates[0].x, marking.cordinates[0].y);
                      for(let i=1; i<marking.cordinates.length; i++){
                        this.fourthViewContext.lineTo(marking.cordinates[i].x, marking.cordinates[i].y);
                        this.fourthViewContext.stroke();
                      }
                    }else if(marking.markingType == 'square-o'){
                      this.fourthViewContext.beginPath();
                      this.fourthViewContext.rect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
                      this.fourthViewContext.closePath();
                      this.fourthViewContext.stroke() 
                    }else if(marking.markingType == 'square'){
                      this.fourthViewContext.beginPath();
                      this.fourthViewContext.fillRect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
                      this.fourthViewContext.closePath();
                      this.fourthViewContext.stroke()
                    }else if(marking.markingType == 'circle'){
                      this.fourthViewContext.beginPath();
                      this.fourthViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
                      this.fourthViewContext.fill();
                      this.fourthViewContext.closePath();
                      this.fourthViewContext.stroke()
                    }else if(marking.markingType == 'circle-o'){
                      this.fourthViewContext.beginPath();
                      this.fourthViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
                      this.fourthViewContext.closePath();
                      this.fourthViewContext.stroke()
                    }else if(marking.markingType == 'spinner'){
                      this.fourthViewContext.beginPath();
                      this.fourthViewContext.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
                      this.fourthViewContext.closePath();
                      this.fourthViewContext.stroke();
                    }else if(marking.markingType == 'x-mark'){
                      this.fourthViewContext.moveTo(29 - 20, 50.5 - 20);
                      this.fourthViewContext.lineTo(29 + 20, 50.5 + 20);
                      this.fourthViewContext.moveTo(29 + 20, 50.5 - 20);
                      this.fourthViewContext.lineTo(29 - 20, 50.5 + 20);
                      this.fourthViewContext.closePath();
                      this.fourthViewContext.stroke();
                    }else if(marking.markingType == 'bolt'){
                      this.fourthViewContext.font = `${30}px FontAwesome`;
                      this.fourthViewContext.fillText('\uf0e7', 42, 26);
                      this.fourthViewContext.closePath();
                      this.fourthViewContext.stroke();
  
                      this.fourthViewContext.beginPath();
                      this.fourthViewContext.font = `${30}px FontAwesome`;
                      this.fourthViewContext.fillText('\uf0e7', 0, 26);
                      this.fourthViewContext.closePath();
                      this.fourthViewContext.stroke();
                    }
                  });
                }

                break;

      case 5 : this.selectedTeeth.views.fifthView.markings = _.filter(this.selectedTeeth.views.fifthView.markings, (item: any, index: number)=>{
                  return index != markingIndex
                });
                this.fifthViewContext.clearRect(0,0,58,101);
                let img5 = new Image(58,101);
                img5.src = this.selectedTeeth?.views?.fifthView?.imageUrl;
                img5.crossOrigin = 'anonymous';
                img5.onload = ()=>{
                  this.fifthViewContext.drawImage(img5, 0, 0);

                  _.forEach(this.selectedTeeth?.views?.fifthView?.markings, (marking: marking, index: number)=>{
                    this.fifthViewContext.fillStyle = marking.markingColor;
                    this.fifthViewContext.strokeStyle = marking.markingColor;
                    let size = Number(((marking.markingSize * 57)/100).toFixed(0));
                    if(marking.markingType == 'pencil'){
                      this.fifthViewContext.beginPath();
                      this.fifthViewContext.moveTo(marking.cordinates[0].x, marking.cordinates[0].y);
                      for(let i=1; i<marking.cordinates.length; i++){
                        this.fifthViewContext.lineTo(marking.cordinates[i].x, marking.cordinates[i].y);
                        this.fifthViewContext.stroke();
                      }
                    }else if(marking.markingType == 'square-o'){
                      this.fifthViewContext.beginPath();
                      this.fifthViewContext.rect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
                      this.fifthViewContext.closePath();
                      this.fifthViewContext.stroke() 
                    }else if(marking.markingType == 'square'){
                      this.fifthViewContext.beginPath();
                      this.fifthViewContext.fillRect(marking.cordinates[0].x - (size/2), marking.cordinates[0].y - (size/2), size, size);
                      this.fifthViewContext.closePath();
                      this.fifthViewContext.stroke()
                    }else if(marking.markingType == 'circle'){
                      this.fifthViewContext.beginPath();
                      this.fifthViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
                      this.fifthViewContext.fill();
                      this.fifthViewContext.closePath();
                      this.fifthViewContext.stroke()
                    }else if(marking.markingType == 'circle-o'){
                      this.fifthViewContext.beginPath();
                      this.fifthViewContext.arc(marking.cordinates[0].x, marking.cordinates[0].y, size/2, 0, 2 * Math.PI, false);
                      this.fifthViewContext.closePath();
                      this.fifthViewContext.stroke()
                    }else if(marking.markingType == 'spinner'){
                      this.fifthViewContext.beginPath();
                      this.fifthViewContext.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
                      this.fifthViewContext.closePath();
                      this.fifthViewContext.stroke();
                    }else if(marking.markingType == 'x-mark'){
                      this.fifthViewContext.moveTo(29 - 20, 50.5 - 20);
                      this.fifthViewContext.lineTo(29 + 20, 50.5 + 20);
                      this.fifthViewContext.moveTo(29 + 20, 50.5 - 20);
                      this.fifthViewContext.lineTo(29 - 20, 50.5 + 20);
                      this.fifthViewContext.closePath();
                      this.fifthViewContext.stroke();
                    }else if(marking.markingType == 'bolt'){
                      this.fifthViewContext.font = `${30}px FontAwesome`;
                      this.fifthViewContext.fillText('\uf0e7', 42, 26);
                      this.fifthViewContext.closePath();
                      this.fifthViewContext.stroke();
  
                      this.fifthViewContext.beginPath();
                      this.fifthViewContext.font = `${30}px FontAwesome`;
                      this.fifthViewContext.fillText('\uf0e7', 0, 26);
                      this.fifthViewContext.closePath();
                      this.fifthViewContext.stroke();
                    }
                  });
                }

                break;

      default:  break;
    }
  }

  onSelectTool(tool: markingTool){
    this.selectedTool = tool
  }

  onSelectColor(color: markingColor){
    this.selectedColor = color;
  }

  onEditMarkingName(teethIndex: number, markingIndex: number){
    this.editMarking[teethIndex +'-' + markingIndex] = true;
    setTimeout(() => {
      document.getElementById(teethIndex+'-'+markingIndex).focus();
    }, 500);
  }

  editMarkingNameUpdate(teethIndex: number, markingIndex: number){
    this.editMarking[teethIndex +'-' + markingIndex] = false;
  }

  onFocusName(text: string){
    return this.toolItems.some((item)=> item.toolName == text);
  }

  onBlurName(type: string){
    let index = this.toolItems.findIndex((item)=> item.tool == type);
    return this.toolItems[index].toolName
  }

  startDrawingOnCanvas(context: CanvasRenderingContext2D, event: MouseEvent){
    let size = Number(((this.selectedSize * 57)/100).toFixed(0));
    if(this.selectedTool.tool == 'pencil'){
      context.moveTo(event.offsetX, event.offsetY);
    }else if(this.selectedTool.tool == 'square-o'){
      context.rect(event.offsetX - (size/2), event.offsetY - (size/2), size, size);
      context.closePath();
      context.stroke()
      
    }else if(this.selectedTool.tool == 'square'){
      context.fillRect(event.offsetX - (size/2), event.offsetY - (size/2), size, size);
      context.closePath();
      context.stroke()
    }else if(this.selectedTool.tool == 'circle-o'){
      context.arc(event.offsetX, event.offsetY, size/2, 0, 2 * Math.PI, false);
      context.closePath();
      context.stroke()
    }else if(this.selectedTool.tool == 'circle'){
      context.arc(event.offsetX, event.offsetY, size/2, 0, 2 * Math.PI, false);
      context.closePath();
      context.fill();
      context.stroke();
    }else if(this.selectedTool.tool == 'x-mark'){
      context.moveTo(29 - 20, 50.5 - 20);
      context.lineTo(29 + 20, 50.5 + 20);
      context.moveTo(29 + 20, 50.5 - 20);
      context.lineTo(29 - 20, 50.5 + 20);
      context.closePath();
      context.stroke();
    }else if(this.selectedTool.tool == 'spinner'){
      context.ellipse(29, 50.5, 29, 50.6, 0, 0, 2 * Math.PI, false);
      context.closePath();
      context.stroke();
    }else if(this.selectedTool.tool == 'bolt'){
      context.font = `${30}px FontAwesome`;
      context.fillText('\uf0e7', 42, 26);
      context.closePath();
      context.stroke();

      context.beginPath();
      context.font = `${30}px FontAwesome`;
      context.fillText('\uf0e7', 0, 26);
      context.closePath();
      context.stroke();
    }
    
  }

  drawOnCanvas(context: CanvasRenderingContext2D, event: MouseEvent){
    context.lineTo(event.offsetX, event.offsetY);
    context.stroke();
  }

  //////////////////////////// Select Traetment /////////////////////////////////////////

  initTreatmentForm(){
    this.treatmentForm = new FormGroup({
      itemDetails: new FormArray([]),
      views: new FormControl(''),
      typeOfOrder: new FormControl('D'),
      visitId: new FormControl(this.visitId),
      branchId: new FormControl(this.branchId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
      isMissing: new FormControl(false),
      isMarkedAsAdult: new FormControl(this.selectedTeeth.pediatricData.markedAsAdult),
      // Rate: new FormControl()
      // Instructor: new FormControl(0)
      // isMarkedAsAdult: new FormControl(this.selectedTeeth.pediatricData.isPediatricTeeth)
    });
  }

  onSelectTreatment(data: any){
    this.addMapFormGroup(data, 0);
  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    if (saved == 0) {
      if (!data["qty"]) data["qty"] = 1;
    }
    let val = []
    if(data["surface"] != ""){
      let i = data["surface"]?.split(",");
      i?.forEach((res:any)=>{
        val.push({name :res, code:res})
      })
    }
    return new FormGroup({
      itemId: new FormControl(data["itemId"]),
      itemName: new FormControl( data["itemName"] + " - " + data["itemCode"]),
      teeths: new FormControl((this.selectedTeeth?.toothNumber).toString()),
      notes: new FormControl(data["notes"]),
      cptModifierId: new FormControl( data["cptModifierId"] ? data['cptModifierId'] : ''),
      qty: new FormControl(data["qty"]),
      surface: new FormControl(val.length ? val : []),
      Rate: new FormControl(data["rate"]),
      // RequestStatus: new FormControl()
      Instructor: new FormControl(data["instructor"] ? data['instructor'] : 0),
      aprovalStatus: new FormControl(data["aprovalStatus"] ? data['aprovalStatus'] : false),
      EstimateId: new FormControl(data["estimateId"] ? data['estimateId'] : 0),
      ConfirmedServiceOrder: new FormControl(data["confirmedServiceOrder"]),
      insCovered: new FormControl(data["insCovered"])
    });
  }

  public addMapFormGroup(data: any[] = [], saved = 0) {
    const ServiceItems = this.treatmentForm.get("itemDetails") as FormArray;
    const values = Object.keys(data).map((key) => data[key]);

    if (data.length > 0) {
      data.forEach((element, i) => {
        let filteredArray = ServiceItems.value.filter(
          (value) => value.itemId == element.itemId
        );
        if (filteredArray.length == 0) {
          ServiceItems.push(this.createListGroup(element, saved));
        } else {
          this.toastrService.error("Failure: " + "Service Items Already added");
        }
      });
    } else if (values.length > 0) {
      let filteredArray = ServiceItems.value.filter(
        (value) => value.itemId == values[0]
      );
      if (filteredArray.length == 0) {
        ServiceItems.push(this.createListGroup(data, saved));
        this.checkFlag = true;
      } else {
        this.toastrService.error("Failure: " + "Service Items Already added");
      }
    }
    // else if(data){

    //  ServiceItems.push(this.createListGroup(data, saved));
    // }
    this.searchTreatment = "";
  }

  RemoveFromServiceList(index) {
    var Value = new FormArray([]);
    Value = this.treatmentForm.get("itemDetails") as FormArray;
    Value.removeAt(index);
  }

  getAllCPTModifier() {
    this.ConfigurationService.GetCPTModifier(
      this.branchId,
      0
    ).subscribe((res) => {
      this.CPTModifiersList = res.response;
    });
  }

  onSaveTreatmentDetails() {
    this.markingSaveLoading = true;
    let markingView = this.selectedTeeth.views;
    if(markingView.firstView.markings.length > 0){
      let canvas = document.getElementById('teeth-canvas-view-1') as HTMLCanvasElement;
      let image = canvas.toDataURL();
      markingView.firstView.MarkedImageBase64 = image.replace('data:image/png;base64,','');

      let exist =_.findIndex(markingView.firstView.markings, {markingType : 'x-mark'});
      this.treatmentForm.patchValue({
        isMissing: exist >= 0 ? true : false
      });
    }
    if(markingView.secondView.markings.length > 0){
      let canvas = document.getElementById('teeth-canvas-view-2') as HTMLCanvasElement;
      let image = canvas.toDataURL();
      markingView.secondView.MarkedImageBase64 = image.replace('data:image/png;base64,','');

      if(!this.treatmentForm.get('isMissing').value){
        let exist =_.findIndex(markingView.secondView.markings, {markingType : 'x-mark'});
        this.treatmentForm.patchValue({
          isMissing: exist >= 0 ? true : false
        });
      }
    }
    if(markingView.thirdView.markings.length > 0){
      let canvas = document.getElementById('teeth-canvas-view-3') as HTMLCanvasElement;
      let image = canvas.toDataURL();
      markingView.thirdView.MarkedImageBase64 = image.replace('data:image/png;base64,','');

      if(!this.treatmentForm.get('isMissing').value){
        let exist =_.findIndex(markingView.thirdView.markings, {markingType : 'x-mark'});
        this.treatmentForm.patchValue({
          isMissing: exist >= 0 ? true : false
        });
      }
    }
    if(markingView.fourthView.markings.length > 0){
      let canvas = document.getElementById('teeth-canvas-view-4') as HTMLCanvasElement;
      let image = canvas.toDataURL();
      markingView.fourthView.MarkedImageBase64 = image.replace('data:image/png;base64,','');

      if(!this.treatmentForm.get('isMissing').value){
        let exist =_.findIndex(markingView.fourthView.markings, {markingType : 'x-mark'});
        this.treatmentForm.patchValue({
          isMissing: exist >= 0 ? true : false
        });
      }
    }
    if(markingView.fifthView.markings.length > 0){
      let canvas = document.getElementById('teeth-canvas-view-5') as HTMLCanvasElement;
      let image = canvas.toDataURL();
      markingView.fifthView.MarkedImageBase64 = image.replace('data:image/png;base64,','');

      if(!this.treatmentForm.get('isMissing').value){
        let exist =_.findIndex(markingView.fifthView.markings, {markingType : 'x-mark'});
        this.treatmentForm.patchValue({
          isMissing: exist >= 0 ? true : false
        });
      }
    }
    let payload = this.treatmentForm.getRawValue();
    if(payload.itemDetails){
      payload.itemDetails.forEach((res:any,index:number)=>{
        let val = []
        res.surface.forEach((i:any)=>{
          val.push(i.name)
        })
        if(val.length){
          res.surface = val.toString()
        } else {
          res.surface = ''
        }
        if(res.notes == null) {
          res.notes = ''
        }
      })
    }
    _.forEach(payload.itemDetails, (item: any)=>{
      item.cptModifierId = item.cptModifierId ? Number(item.cptModifierId) : 0;
    })

    _.forEach(payload.itemDetails, (item: any)=>{
      item.Instructor = item.Instructor ? Number(item.Instructor) : 0;
    })

    payload.userId = Number(localStorage.getItem("user_id"));
    payload.branchId = Number(this.branchId);
    payload.typeOfOrder='D';
    // payload.isMarkedAsAdult = Boolean(this.selectedTeeth.pediatricData.markedAsAdult);
    
    if(this.markedAsAdultFlag == 0 && this.mixed == true) {
      this.markedAsAdultFlag = 1;
    } 
    payload.isMarkedAsAdult = Number(this.markedAsAdultFlag);
    payload.mixedDentition = Boolean(this.mixed);

    payload.views = markingView;
    this.dentalService.updateTeethMarkingandEstimate(payload).subscribe({
      next: (response: Response<any>)=>{
        if (response.status == 200 && response.message == "success") {
          this.toastrService.success(
            "Success: " + "Treatment Plan and Marking Details are Saved Successfully..."
          );
          this.saveTreatmentDetailsEmitter.emit({status: true});
          this.close();
        } else {
          if(response?.errorMessage?.message) {
            this.toastrService.error(response.errorMessage.message)
          } else {
            this.toastrService.error(response.message)
          }
        }
        this.markingSaveLoading = false;
      },error: (error: any)=>{
        this.toastrService.error(
          "Somthing wents wrong, Try again..!"
        );
        this.markingSaveLoading = false;
      }
    })

    
    // this.EMRService.SaveServiceOrder(form).subscribe((res) => {
    //   if (res.status == 200 && res.message == "Success") {
    //     this.toastrService.success(
    //       "Success: " + "Treatment Plan Details Saved Successfully..."
    //     );
    //   } else {
        
    //   }
    // });
  }

  multiselectSurface(data:any,index:number){
    let itemDtl = this.treatmentForm.get('itemDetails')['controls'][index]
    itemDtl.get('surface').reset()
    let val = [];
    if(data.value?.length){
      data.value.forEach((res:any)=>{
        val.push(res.name)
      })
    }
    itemDtl.get('surface').setValue(val.toString())
     }

  submit() {
    let payload = this.authenticationForm.getRawValue();
    this.emrService.VerifyInstructor(payload).subscribe((res) => {
      if(res.response[0].msg == 'Success') {
        this.attachFlag = false;
        this.instructFlag = false;
        this.checkFlag = false;
        this.toastrService.success("Authentication Success")
      } else if(res.response[0].msg == 'Failure') {
        this.attachFlag = false;
        this.instructFlag = true;
        this.checkFlag = true;
        this.toastrService.error("Authentication Failed");
      }
    })
  }

  closeDialog() {
    this.attachFlag = false;
    this.instructFlag = true;
    this.checkFlag = true;
  }

}
