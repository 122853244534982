
export class Treatmentform {
  id: number;
  consultationId: number;
  appointmentId: number;
  patientId: number;
  servicePoint: number;
  performingStaff: number;
  treatmentDate: string;
  treatmentDetails: string;
  treatmentRemarks: string;
  branchId: number;
  treatmentNumber:string;
  itemDetails: ItemDetail[];
}

export class ItemDetail {
  id: number;
  itemId: number;
  qty: number;
  orderDate: string;
  route: number;
  dosage: number;
  startDate: string;
  endDate: string;
  notes: string;
  startTime: string;
  endTime: string;
  isFinished: any;
  orderId: number;
}
