import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../../../_Models/Response';
import { ConfigurationService } from '../../../client/services/configuration.service';

@Component({
  selector: 'app-assign-template-modal',
  templateUrl: './assign-template-modal.component.html',
  styleUrls: ['./assign-template-modal.component.scss']
})
export class AssignTemplateModalComponent implements OnInit {

  @Input() serviceItem: any;
  @Input() branchId: number;

  public dropdownData: string = '';

  public assignTemplateLoading: boolean = false;

  public selectedTemplateData: any[] = [];
  public allTemplates: any[] = [];
  public dropDownTemplates: any[] = [];

  constructor(public modalRef: BsModalRef,
              private toastr: ToastrService,
              private configService: ConfigurationService) { }

  ngOnInit(): void {
    this.getAllTemplates();
  }

  onSelectTemplate(event){
    let templateId = event.target.value;
    if(templateId != ''){
      let selectedCopy = this.selectedTemplateData;
      let selectedTemp = this.allTemplates.filter(temp=> temp.id == templateId);
      selectedCopy.push(selectedTemp[0]);
      this.selectedTemplateData = [];
      this.selectedTemplateData = selectedCopy;
      this.dropdownData = '';
      this.filterSelectedTemplates();
      
    }else{
      this.toastr.warning('Select a valid template..');
    }
  }

  removeFromSelected(template: any){
    let index = this.selectedTemplateData.findIndex(temp=> temp.id == template.id);
    if(index >= 0){
      this.selectedTemplateData.splice(index, 1);
    }
    this.dropdownData = ''
    this.filterSelectedTemplates();
  }

  filterSelectedTemplates(){
    this.dropDownTemplates = [];
    if(this.selectedTemplateData.length > 0){
      this.allTemplates.forEach(temp=>{
        let index = this.selectedTemplateData.findIndex(selected=> temp.id == selected.id);
        if(index < 0){
          this.dropDownTemplates.push(temp)
        }
      });
    }else{
      this.dropDownTemplates = this.allTemplates;
    }
  }

  onAssignTemplate(){
    let payload = {
      ServiceItemId: this.serviceItem?.itemId,
      BranchId: this.branchId,
      TemplateIds: []
    }
    this.selectedTemplateData.forEach(template=> {
      payload.TemplateIds.push(Number(template.id));
    });

    this.assignTemplateLoading = true;
    this.configService.assignTemplatesServiceItem(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.toastr.success('Templates assigned succesfully.');
          this.modalRef.hide();
        }else{
          this.toastr.error(response.message)
        }
        this.assignTemplateLoading = false;
      },error: (error: any)=>{
        this.toastr.error('Something wents wrong, Try again');
        this.assignTemplateLoading = false;
      }
    })
  }

  getAllTemplates(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.branchId
    }
    this.configService.getReportWorklistTemplate(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.allTemplates = response.response;
          this.getItemTemplates();
        }else{
          this.toastr.error(response.message);
        }
      },error: (error: any)=>{
        this.toastr.error('Something wents wrong, Try again.');
      }
    })
  }

  getItemTemplates(){
    let payload = {
      ServiceItemId: this.serviceItem?.itemId,
      BranchId: this.branchId
    }

    this.configService.getTemplatesOServiceItem(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.selectedTemplateData = response.response;
          this.filterSelectedTemplates();
        }else{
          this.toastr.error(response.message)
        }
      },error: (error: any)=>{
        this.toastr.error('Something wents wrong, Try again');
      }
    })
  }

  onSaveTemplate(){

  }

}
