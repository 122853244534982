import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-c-form-lock',
  templateUrl: './c-form-lock.component.html',
  styleUrls: ['./c-form-lock.component.scss']
})
export class CFormLockComponent implements OnInit {
  @Input() heading:string
  @Input() Id:number
  @Input() IsCformLock:boolean
  @Input() TypeData:string

  @Output() cFormEmitter= new EventEmitter();

  constructor(
    private sharedservice:SharedService,
    private toastrService:ToastrService,
    public modalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
  }

  onConfirm(){
    const payload={
      Id: this.Id,
      IsCformLock: ! this.IsCformLock,
      TypeData: this.TypeData
    }
    this.sharedservice.UpdateLockCform(payload).subscribe({
      next:((res)=>{
        this.toastrService.success("success")
        this.cFormEmitter.emit({status:true})
        this.close()
      })
    })
  }

  close(){
    this.modalRef.hide()
  }

}
