import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMRService } from '../../../services/emr.service';

@Component({
  selector: 'app-view-drugs-medication-modal',
  templateUrl: './view-drugs-medication-modal.component.html',
  styleUrls: ['./view-drugs-medication-modal.component.scss']
})
export class ViewDrugsMedicationModalComponent implements OnInit {

  @Input() drugDetails: any[] = [];

  public dataLoading: boolean = false;
  public itemData: any;

  constructor(private activeModalRef: BsModalRef,
              private emrService: EMRService) { }

  ngOnInit(): void {
    
  }

  close(){
    this.activeModalRef.hide();
  }

}
