import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiInfoPanel, GuiPaging, GuiPagingDisplay, GuiSearching, GuiSorting } from '@generic-ui/ngx-grid';
import { ColDef, Column, GridApi } from 'ag-grid-community';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { Response } from '../../../_Models/Response';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { BillingService } from '../services/billing.service';
import { ConfigurationService } from '../../client/services/configuration.service';

@Component({
  selector: 'app-unbilled-item',
  templateUrl: './unbilled-item.component.html',
  styleUrls: ['./unbilled-item.component.scss']
})
export class UnbilledItemComponent implements OnInit {

  user_id: string;
  columnDefs: ColDef[] = [];
  columnDefsMoreDetails: ColDef[] = [];
  OrderById: {}[];
  rowData: {}[];
  subscription: Subscription;
  public params: any;
  globals: any;
  
  public gridApi: GridApi;
  public rowSelection = true;
  public enableCellTextSelection = true
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public statusBar;
  

  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.itemStatus == "Cancelled";

      },
      "c-reds2": function (params) {
        return params.data.isCancelled == 1;

      },
    },

    domLayout: "autoHeight",
  };
  rowGroupPanelShow = "always";

  BranchesId: any;
  selectedOrderId: any;
  minDate: Date;
  valueDate: string;
  valueDate2: string;
  ConsultantId: any;
  serviceDateFrom: any;
  serviceDateTo: any;

  itemSearchForm: FormGroup;

  public allLocations: any[] = [];
  functionPermissionData: any;

  constructor(
    private formBuilder: FormBuilder,
    private billingService: BillingService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private router: Router,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        // this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId

      }
    });

  }

  ngOnInit(): void {

    this.ConsultantId = localStorage.getItem('Id');
    this.minDate = new Date();
    let dte = new Date();
    dte.setDate(dte.getDate() - 31);
    this.rowData = [];
    this.OrderById = [];

    let currentappDate = new Date();
    this.valueDate2 = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    let convertDate = currentappDate.setDate(currentappDate.getDate() - 7);

    this.valueDate = this.datepipe.transform(convertDate, 'dd-MM-yyyy');

    this.itemSearchForm  = this.formBuilder.group({
      regNo: new FormControl(''),
      patientName: new FormControl(''),
      orderNo: new FormControl(''),
      dateFrom: new FormControl(this.valueDate),
      dateTo: new FormControl(this.valueDate2),
      Location: new FormControl(0)
    })

    this.columnDefs = [
      {
        headerName: "Order No",
        minWidth: 90,
        field: "orderNo",
        enableRowGroup: false,
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,

      },


      {
        headerName: "Service Item",
        minWidth: 80,
        field: 'itemName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Action",
        maxWidth: 50,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "unBilledItem" },
      },
    ];


    this.columnDefsMoreDetails = [
      {
        headerName: "Order Date",
        minWidth: 110,
        field: 'orderDate',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Order No",
        minWidth: 100,
        field: "orderNo",
        enableRowGroup: false,
        resizable: true,
        // rowGroup: true,
        // hide: true,
        sortable: true,
        filter: true,
        flex: 2,

      },
      {
        headerName: "Reg.No.",
        minWidth: 90,
        field: 'regNo',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },

      {
        headerName: "Patient Name",
        minWidth: 180,
        field: 'firstName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: false,
        flex: 2,

      },
      {
        headerName: "Location",
        minWidth: 120,
        field: 'location',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Action",
        maxWidth: 50,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "unBilledItemOrder" },
      },
    ];

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };

    this.GetAllServicesOrders();
    this.getLocations();
    this.functionPermission();
  }

  getLocations(){
    let payload = {
      "LocationId": 0,
      "ShowAll": 1,
      "HospitalId": this.globals.HospitalId
    }

    this.billingService.getAllLocations(payload).subscribe({
      next: (response: Response<any>)=>{
        this.allLocations = response.response;
      },error: (error: any)=>{
        this.toastr.error('Failed to load locations, Try again.')
      }
    })
  }

  //Getting Api call for listing service orders
  GetAllServicesOrders() {

    let StartDateObj = this.itemSearchForm.value.dateFrom;
    let EndDateObj = this.itemSearchForm.value.dateTo;
    // date convertion
    if (typeof (StartDateObj) == "object") {
      let DateFromList = this.datepipe.transform(StartDateObj, 'dd-MM-yyyy');
      this.itemSearchForm.value.dateFrom = DateFromList;
    }
    if (typeof (EndDateObj) == "object") {
      let endDate = this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      this.itemSearchForm.value.dateTo = endDate;
    }

    let payload = {
      OrderFromDate: this.itemSearchForm.value.dateFrom,
      OrderToDate: this.itemSearchForm.value.dateTo,
      PatientId: 0,
      OrderNo: this.itemSearchForm.value.orderNo,
      BranchId: this.BranchesId,
      RegNo: this.itemSearchForm.value.regNo,
      PatientName: this.itemSearchForm.value.patientName,
      ConsultantId: 0,
      isExternalConsultant:3,
      OrderId:0,
      LocationId: Number(this.itemSearchForm.value.Location)
    }
      this.billingService.GetServicesOrderLoad(payload).subscribe((res) => {
        this.rowData = res.response.map((item)=>{
          return{
          ...item,
          firstName:item.firstName +" "+ item.lastName
          }
        });
        this.OrderById = [];
    })
    
  }

  //On selection changed
  onSelectionChanged(eventData) {
    this.selectedOrderId = eventData.data.orderId;
    this.GetAllServicesOrdersById(eventData.data)
  }

  //get Order details by OrderId
  GetAllServicesOrdersById(selectedOrder) {
    let selectedOrderId = selectedOrder.orderId;
    if (selectedOrderId) {
      this.billingService.GetAllServicesOrdersById(this.selectedOrderId).subscribe((res) => {
        this.OrderById = res.response.map((item)=>{
          return {
            ...item,
            regNo: selectedOrder.regNo,
            consultantId: selectedOrder.consultantId,
            consultationId: selectedOrder.consultationId
          }
        });
      });
    }
    else {
      this.noRowsTemplate = "No Rows To Show"
      this.gridApi.hideOverlay();
    }
  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
    // setTimeout(() => {
    //   this.GetAllServicesOrdersById(this.selectedOrderId);
    // }, 1000);
  }

  onOrderGridReady(params) {
    this.gridApi = params.api;
  }

  onNewBill(data, actionId){
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    sessionStorage.setItem('bill-patient-regNo', JSON.stringify({regNo: data?.regNo,
    consulatantID: data?.consultantId,
    consulatationId: data?.consultationId}));
    this.router.navigate(['../newbill'], {queryParams :{returnUrl : 'unbilled-items'}});
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onCancelOrder(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 362)
    if (foundElement.id == 362 && foundElement.isAllowed == true) {
    let initialState = {
      heading: 'Confirm Cancel Order',
      content: `Are you sure that you want to cancel this Orders (${data?.orderNo}).?`,
      isReasonNeeded: true
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(ConfirmModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
    modalRef.content.confirmEmitter.subscribe(res=>{
      if(res.status){
        let payload = {
          CancelReason: res.reason,
          // "SubType":subprofile,
          OrderId: data.orderId,
          ItemId: 0,
          UserId: Number(localStorage.getItem('user_id')),
          SessionId: 0,
        }
        this.billingService.CancelUnbilledOrder(payload).subscribe({
          next: (response: Response<any>)=>{
            if(response.status == 200){
              this.toastr.success('Order canceled succesfully.');
              this.GetAllServicesOrders();
            }else{
              this.toastr.error(response.message);
            }
          },error: (error: any)=>{
            this.toastr.error('Something wents wrong, Try again');
          }
        })
      }
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onCancelOrderItem(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 364)
    if (foundElement.id == 364 && foundElement.isAllowed == true) {
    let initialState = {
      heading: 'Confirm Cancel Item Order',
      content: `Are you sure that you want to cancel this Orders (${data?.itemName}).?`,
      isReasonNeeded: true
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(ConfirmModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
    modalRef.content.confirmEmitter.subscribe(res=>{
      if(res.status){
        let payload = {
          CancelReason: res.reason,
          // "SubType":subprofile,
          OrderId: data.orderId,
          ItemId: data.itemId,
          UserId: Number(localStorage.getItem('user_id')),
          SessionId: 0,
        }
        this.billingService.CancelUnbilledOrder(payload).subscribe({
          next: (response: Response<any>)=>{
            if(response.status == 200){
              this.toastr.success('Order canceled succesfully.');
              this.GetAllServicesOrders();
              this.GetAllServicesOrdersById(data)
            }else{
              this.toastr.error(response.message);
            }
          },error: (error: any)=>{
            this.toastr.error('Something wents wrong, Try again');
          }
        })
      }
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }
}
