import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ColDef } from "ag-grid-community";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { ConfigurationService } from "../../client/services/configuration.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-questionmaster',
  templateUrl: './questionmaster.component.html',
  styleUrls: ['./questionmaster.component.scss']
})
export class QuestionmasterComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;

  public submitted: boolean = false;
  result: any;
  professionId: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  deleteprofession: boolean;
  oppid: any;
  public statusBar;
  rowGroupPanelShow = "always";
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.active == "0";
      },
      "c-reds2": function (params) {
        return params.data.appStatus == "Cancelled";
      },
    },
  };
  rowData: any;
  cpctcode: any;
  unlistedCode: any;
  CptCodeId: any;
  rateGroup: any;
  CPTModifiers: any;
  ModifierId: any;
  QuestionForm: FormGroup;
  Company: any;
  cmpId: any;
  RowData: any;
  QuestionId: any;
  questionGroupDatas: any;
  QuestionGroupData: Array<Select2OptionData> = [];
  IsDisplayed: any;
  DisplayedId: any;
  modalRef: BsModalRef;
  isLoading=false;
  QuestionPopupHeading:String="Add Question";
  @ViewChild("template") modaltemp: any;
  functionPermissionData: any;

  constructor(
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private modalService: BsModalService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.QuestionForm = new FormGroup({
      Questiongroup: new FormControl("", [Validators.required]),
      QuestionText: new FormControl("", [Validators.required]),
      SortOrderText: new FormControl(0, [Validators.required]),
      IsDisplayedGroup: new FormControl(true),
    });
    this.GetAllQuestions();
    this.GetAllQuestionGroup();
    this.functionPermission();
    this.columnDefs = [
      {
        headerName: "Question",
        minWidth:180,
        field: "question",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Group",
        minWidth:180,
        field: "groupName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Sort Order",
        minWidth:180,
        field: "sortOrder",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: true,
        unSortIcon: true,
        field: "qnId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Question" },
      },
    ];

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }

  // for geting all form items

  get f() {
    return this.QuestionForm.controls;
  }

  // to clear form
  clearForm() {
    this.QuestionForm.reset({
      QuestionText: '',
      SortOrderText: 0,
      IsDisplayedGroup: true
    });
    this.submitted = false;
  }


  showModalDialog(QuestionData) {
    const foundElement = this.functionPermissionData.find(x => x.id === 138)
    if (foundElement.id == 138 && foundElement.isAllowed == true) {
    this.QuestionPopupHeading="Edit Question";
    this.modalRef = this.modalService.show(
      this.modaltemp,
      //Object.assign({}, { class: "gray modal-lg" })
    );
    this.displayModal = true;
    this.QuestionId = QuestionData.qnId;
    this.QuestionForm.patchValue({
      Questiongroup: QuestionData.groupId,
      QuestionText: QuestionData.question,
      SortOrderText: QuestionData.sortOrder,
      IsDisplayedGroup: QuestionData.isDisplayed,
    });
    } else {
      this.toastr.warning("You are not allowed to edit question");
    }
  }

  //pagetitle to be displayed at the header
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);
  // }

  SaveQuestion() {
    this.submitted = true;

    if (this.QuestionForm && this.QuestionForm.valid) {
      var AppData = {
        QuestionText: this.QuestionForm.get("QuestionText").value,
        GroupId: this.QuestionForm.get("Questiongroup").value,
        SortOrderText: this.QuestionForm.get("SortOrderText").value,
        IsDisplayedGroup: this.QuestionForm.get("IsDisplayedGroup").value,
        IsDeleting: 0,
      };

      let Qndata = 0;
      if (this.QuestionId > 0) {
        Qndata = this.QuestionId;
      }
      this.isLoading=true;
      this.ConfigurationService.InsertUpdateDeleteQuestion(
        AppData,
        Qndata,
        this.globals.HospitalId
      ).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Success") {
          if (Qndata == 0) {
            this.isLoading=false;
            this.toastr.success("Question added successfully");
          } else {
            this.isLoading=false;
            this.toastr.success("Question Updated successfully");
            this.QuestionId = 0;
          }
          this.modalRef.hide();
          this.GetAllQuestions();
          this.clearForm();

        } else {
          this.isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    }
  }
  DeleteQuestion(QnContent) {
    const foundElement = this.functionPermissionData.find(x => x.id === 139)
    if (foundElement.id == 139 && foundElement.isAllowed == true) {
    var AppData = {
      QuestionText: this.QuestionForm.get("QuestionText").value,
      SortOrderText: this.QuestionForm.get("SortOrderText").value,
      IsDisplayedGroup: this.QuestionForm.get("IsDisplayedGroup").value,
      IsDeleting: 1,
      GroupId:0
    };

    let Qndata = 0;
    Qndata = QnContent.qnId;

    this.ConfigurationService.InsertUpdateDeleteQuestion(
      AppData,
      Qndata,
      this.globals.HospitalId
    ).subscribe((res) => {
      this.submitted = false;
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success(this.result.message);
        this.GetAllQuestions();
      }
    });
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  GetAllQuestions() {
    this.ConfigurationService.GetAllQuestions(
      this.globals.HospitalId
    ).subscribe((res) => {
      this.RowData = res.response;
    });
  }
  GetAllQuestionGroup() {
    this.ConfigurationService.GetQuestionGroup().subscribe((res) => {
      this.questionGroupDatas = res.response;
      this.QuestionGroupData = [];
      this.QuestionGroupData.push({
        id: '',
        text: 'Select a group',
      });
      this.questionGroupDatas.forEach((a) => {
        this.QuestionGroupData.push({
          id: a.id.toString(),
          text: a.questionType,
        });
      });
    });
  }

  DeleteDepartment(id) {
    this.QuestionId = id.deptId;
    this.ConfigurationService.DeleteDepartment(this.QuestionId).subscribe(
      (res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Department deleted successfully");
          this.displayModal = false;
          this.GetAllQuestions();
          this.clearForm();
          this.QuestionId = 0;
        } else {
          this.toastr.error("Failure: " + this.result.message);
          this.displayModal = false;
        }
      }
    );
  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }

  openModalDepartment(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 137)
    if (foundElement.id == 137 && foundElement.isAllowed == true) {
    this.QuestionPopupHeading="Add Question";
    this.modalRef = this.modalService.show(template);
    this.QuestionId = 0;
    this.clearForm();
    } else {
      this.toastr.warning("You are not allowed to add question");
    }
  }

  ResetFunction() {
    this.QuestionId = 0;
    this.clearForm();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }
}
