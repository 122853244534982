import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// import { ToastrService } from 'ngx-toastr/public_api';
import BaseImage from '../../../../../models/base-image.enum';
import {Location} from '@angular/common';
import { AddSketchService } from './add-sketch.service';
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';


@Component({
  selector: 'app-add-sketch',
  templateUrl: './add-sketch.component.html',
  styleUrls: ['./add-sketch.component.scss']
})
export class AddSketchComponent implements AfterViewInit {

  baseImage = BaseImage;
  baseImageList: any[] = [];
  indicatorsList: any[] = [];
  customImages: any[] = [];
  form: FormGroup;
  maploc: any;
  ConsultantId: any;
  isLoading = false;

  @ViewChild('container') container!: ElementRef<HTMLDivElement>;
  result: any;
  constructor(
    private _drawService: AddSketchService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private _location: Location,
    private router: Router,
    ) {
    this.customImages = this._drawService.customImages;
  }
  ngOnInit(): void {
    this.getbaseImages();
    this.getIndicators();

    this.form = this.formBuilder.group({
      SketchName: [''],
      BodyPartId: [0],
    });
    this.ConsultantId = localStorage.getItem('Id')
  }

  ngAfterViewInit(): void {
    this._drawService.createStageInstance(this.container.nativeElement);
    //this._drawService.changeBaseImage(BaseImage.FullBody);
  }

  onBaseImageChange(event: any, type: BaseImage) {

    this._drawService.clear();
    this._drawService.changeBaseImage(type);
  }

  setDraggingElement(ev: any) {
    this._drawService.dragging = ev.currentTarget.dataset.type;
    this._drawService.indicatorsList = this.indicatorsList;
  }

  resetDraggingElement(ev: any) {
    this._drawService.dragging = '';
  }

  @HostListener('window:keydown.delete', ['$event'])
  deleteLayer(ev: KeyboardEvent) {
    this._drawService.deleteLayer();
  }

  @HostListener('window:keydown.esc', ['$event'])
  removeResizer(ev: KeyboardEvent) {
    this._drawService.removeResizer();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  save(ev: KeyboardEvent) {
    ev.preventDefault()


    this._drawService.getImage((imageData) => {
      const a = document.createElement('a');
      a.download = `download-${new Date().toLocaleString()}`;
      a.href = imageData.src;
      a.click();
    });
  }
  saveSketch() {
    if (this.form.value.SketchName == '') {
      this.toastr.error("Please enter sketch name");
    }
     else {
      let saveData = this.form.getRawValue();
      this._drawService.getImage((imageData) => {
        const a = document.createElement('a');
        let image = imageData.src;
        saveData.PatientId=0;
         saveData.Base64Img = image;
        saveData.branchId = Number(localStorage.getItem('BranchId'));
        saveData.ConsultantId = Number(this.ConsultantId);
        this.isLoading = true;

        this._drawService.saveSketch(saveData).subscribe((res) => {
          this.result = res;

           if (this.result.status == 200) {
            this.isLoading = false;

             this.toastr.success('Added Successfully');
            } else {
            this.isLoading = false;
             this.toastr.error("failed");
          }
        });
      });

    }
  }
  getbaseImages() {
    this._drawService.GetBodyPart().subscribe((res) => {
      this.baseImageList = res.response;
    });
  }
  loadBaseImage(id) {
    let item = this.baseImageList.filter(x => x.bodyId == id)[0];
    if (item) {
      let image = item.bodyPartImageLocation;
      if (image) {
        this._drawService.clear();
        this._drawService.changeBaseImage(image);
      }
      else {
        alert("No image found")
      }
    }
    else {
      alert("No image found")
      this.toastr.error("failed");
    }
  }

  getIndicators() {
    this._drawService.getIndicators().subscribe((res) => {
      this.indicatorsList = res.response;
    });
  }

  // goBack(){
  //   this._location.back();
  // }

  goTo(){
    const data = { tab: '5' };
    // Navigate to the target route with state
    this.router.navigate(['/personalisation'], {state : {data}});
  }
}
