import { Component, OnInit, TemplateRef, ViewChild,Input, OnDestroy } from '@angular/core';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from '../../emr/services/emr.service';
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { PlanAndProcedure } from '../interfaces/PlanAndProcedure';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';

@Component({
  selector: 'app-general-plan-procedure',
  templateUrl: './general-plan-procedure.component.html',
  styleUrls: ['./general-plan-procedure.component.scss']
})
export class GeneralPlanProcedureComponent implements OnInit, OnDestroy {

  EMRPatientInfo:EMRPatientInfo;
  @Input() IsViewtab;
  form: FormGroup; // form variable
  PlanAndProcedure:PlanAndProcedure;
  PrevPlanAndProcedure:PlanAndProcedure[]=[];
  PrevPlanAndProcedureModalVal:PlanAndProcedure;
  Loading:boolean=false;
  modalRef?: BsModalRef;
  FormValueChangeChecker:boolean=false;
  @ViewChild("template") modaltemp: any;
  IsDisabled: boolean = true;
  globals: globalvars = new globalvars();
  subscription: Subscription;

  constructor(
    private EMRService:EMRService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private sharedservice: SharedService,
  ) {
    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });
  }

  ngOnInit(): void {

    if (this.IsViewtab == true) {
      this.IsDisabled = false;
    } else if (this.IsViewtab == "undefined") {
      this.IsDisabled = true;
    }

    this.form= new FormGroup({
      papId: new FormControl(0),
      planAndProcedure: new FormControl(""),
      patientInstruction: new FormControl(""),
      followUp: new FormControl(""),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
    });

    this.GetPlanAndProcedure();
    this.GetPrevPlanAndProcedure();
  }

  SavePlanAndProcedure()
  {
    if(
      this. EMRPatientInfo.CrudType!=3 &&
      this.EMRPatientInfo.VisitId>0 && this.Loading && this.FormValueChangeChecker)
    {

      this.PlanAndProcedure=this.form.getRawValue();
      this.PlanAndProcedure.userId=Number(localStorage.getItem('user_id'));
      this.EMRService.SavePlanAndProcedure(
        this.PlanAndProcedure
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastr.success("Success: " + "Plan And Procedure Saved Successfully...");
          }
          else{
            Swal.fire(
              'Error!',
              'Some unexpected error occures! try again later.',
              'error'
            )
          }
        });
    }
    else{
      if(!this.FormValueChangeChecker){
       // this.toastr.warning("Info: " + "No changes detected in the entry");
      }
  }
  }

  GetPlanAndProcedure()
  {
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };

    this.EMRService.GetPlanAndProcedure(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.form.patchValue(response);
          this.form.valueChanges.subscribe(x => {
            this.FormValueChangeChecker=true;
           })
        }
      });
      this.Loading=true;

  }

  GetPrevPlanAndProcedure()
  {
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":0
    };

    this.EMRService.GetPlanAndProcedure(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.PrevPlanAndProcedure=res.response;
        }
      });
  }


  openModal(VisitId) {

    let input={
      "VisitId":VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };
    this.EMRService.GetPlanAndProcedure(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.PrevPlanAndProcedureModalVal=response;
          this.modalRef = this.modalService.show(
            this.modaltemp,
            {class: 'modal-lg modal-custom-style' }
          );
        }
      });


  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
