import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ColDef, GridOptions } from "ag-grid-community";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { globalvars } from "../../../shared/models/GlobalVars";
import { SharedService } from "../../../shared/shared.service";
// import { Menu } from "../../../client/interfaces/Menu";
// import { UserGroups } from "../../../client/interfaces/usergroup";
import { TreeviewItem, TreeviewConfig, TreeviewHelper } from "ngx-treeview";
import { GeneralGridActionComponent } from '../../../shared/general-grid-action/general-grid-action.component';
import { UserpermissionService } from '../../user-permission/userpermission.service';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { ConfigurationService } from '../../client/services/configuration.service';
import { ToastrService } from 'ngx-toastr';
import { CommonValidationComponent } from '../../../shared/common-validation/common-validation.component';


@Component({
  selector: 'app-symptom',
  templateUrl: './symptom.component.html',
  styleUrls: ['./symptom.component.scss']
})
export class SymptomComponent implements OnInit {
  usergroup: any;
  signcolumnDefs = [];
  vitalsigncolumnDefs = [];
  symptomcolumnDefs = [];
  allemnus: any[];
  public gridApiSign;
  public gridApiSymptom;
  result: any;
  signDefs: ColDef[] = [];
  vitalsignDefs: ColDef[] = [];
  symptomDefs: ColDef[] = [];
  private gridColumnApi;
  public rowSelection = true;
  public signdefaultColDef: ColDef;
  public vitalsigndefaultColDef: ColDef;
  public symptomdefaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public SignForm: FormGroup;
  public VitalSignForm: FormGroup;
  public SymptomForm: FormGroup;
  SignId: any;
  VitalSignId: any;
  MandatoryStat: any;
  SymptomId: any;
  signModal: boolean;
  vitalsignModal: boolean;
  symptomModal: boolean;
  showReason: boolean = false;
  supervisor = "";
  modalRef2?: BsModalRef;
  locationname = "";
  Mobilenumber = "";
  globals: globalvars = new globalvars();
  products: any = {};
  signData: any = [];
  vitalsignData: any = [];
  symptomData: any = [];
  subscription: Subscription;
  @ViewChild("templateMenu") modalmap: any;
  selectedCities: any[] = [];
  statusBar: any;
  public submitted: boolean = false;
  public submitted1: boolean = false;
  signstab: boolean = true;
  vitalsignstab: boolean;
  symptomstab: boolean;
  isLoading=false;
  menus: any[];
  SignTitileName:String="Add Sign";      //chnage the title
  SymptomTitileName:String="Add Symptom";
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  items: any;
  simpleItems: any;
  config = {
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 380
  }
  IsDisplayed: any;
  IsMandatory: any;
  modalRefSign: BsModalRef;
  modalRefVitalsign: BsModalRef;
  modalRefSymptom: BsModalRef;
  @ViewChild("templateSign") modaltempSign: any;
  @ViewChild("templateVitalsign") modaltempVitalsign: any;
  @ViewChild("templateSymptom") modaltempSymptom: any;
  
  public validator = new CommonValidationComponent();
  functionPermissionData: any;

  constructor(private formBuilder: FormBuilder,
    private userpermissionservice: UserpermissionService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
    this.frameworkComponents = {
      actionsComponentRenderer: GeneralGridActionComponent,
    };
    this.context = { componentParent: this };
  }

  SaveUpdateSign() {
    this.submitted = true;

    if (this.SignForm && this.SignForm.valid) {
      var AppData = {
        signDesc: this.SignForm.value.signDesc,
        isDisplayed: Number(this.SignForm.value.IsDisplayedSign)
      }
      var saveupdateId = 0;
      if (this.SignId > 0) {
        saveupdateId = this.SignId;
      }
      this.isLoading=true;

      this.ConfigurationService.InsertUpdateSign(AppData, this.globals.HospitalId, saveupdateId).subscribe((res) => {
        this.result = res;
        this.submitted = false;
        if (this.result.status == 200 && this.result.message == 'Success') {
          if (saveupdateId == 0) {
            this.isLoading=false;
            this.toastr.success("Sign Added successfully");
          }
          else {
            this.isLoading=false;
            this.toastr.success("Sign Updated successfully");
            this.SignId=0;
          }
          this.modalRefSign.hide();
          this.GetAllSign();

          this.clearForm2();
        }
        else {
          this.isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }
  }

  SaveUpdateSymptom() {
    this.submitted1 = true;

    if (this.SymptomForm && this.SymptomForm.valid) {
      var AppData = {
        symptomDesc: this.SymptomForm.value.symptomDesc,
        isDisplayed: Number(this.SymptomForm.value.IsDisplayedSymptom)
      }
      var saveupdateId = 0;
      if (this.SymptomId > 0) {
        saveupdateId = this.SymptomId;
      }
      this.isLoading=true;

      this.ConfigurationService.InsertUpdateSymptom(AppData, this.globals.HospitalId, saveupdateId).subscribe((res) => {
        this.result = res;
        this.submitted1=false;
        if (this.result.status == 200 && this.result.message == 'Success') {
          if (saveupdateId == 0) {
            this.isLoading=false;
            this.toastr.success("Symptom Added successfully");
          }
          else {
            this.isLoading=false;
            this.toastr.success("Symptom Updated successfully");
            this.SymptomId=0
          }
          this.modalRefSymptom.hide();
          this.GetAllSymptom();

          this.clearForm();
        }
        else {
          this.isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }
  }
  DeleteSign(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 21)
    if (foundElement.id == 21 && foundElement.isAllowed == true) {
    this.SignId = id.id;
    this.ConfigurationService.DeleteSign(this.SignId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Sign deleted successfully");
        this.signModal = false;
        this.GetAllSign();
        this.SignId=0
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
        this.signModal = false;
      }
    },
    );
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  DeleteSymptom(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 24)
    if (foundElement.id == 24 && foundElement.isAllowed == true) {
    this.SymptomId = id.symptomId;
    this.ConfigurationService.DeleteSymptom(this.SymptomId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Symptom deleted successfully");
        this.symptomModal = false;
        this.GetAllSymptom();
        this.SymptomId=0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
        this.symptomModal = false;
      }
    },
    );
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onGridReadySign(params) {
    this.gridApiSign = params.api;
  }
  onGridReadySymptom(params) {
    this.gridApiSymptom = params.api;
  }
  onFilterTextBoxChangedSign() {
    this.gridApiSign.setQuickFilter(
      (document.getElementById('filter-text-box-sign') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedSymptom() {
    this.gridApiSymptom.setQuickFilter(
      (document.getElementById('filter-text-box-symptom') as HTMLInputElement).value
    );
  }


  selectchecking(evt) {
    if (evt.target.checked == true) {
      this.IsMandatory = 1;
    }
    else {
      this.IsMandatory = 0;
    }
  }
  ngOnInit(): void {
    this.SignForm = new FormGroup({
      signDesc: new FormControl('', [Validators.required,this.validator.noWhitespaceValidator]),
      IsDisplayedSign: new FormControl(true)
    });

    this.SymptomForm = new FormGroup({
      symptomDesc: new FormControl('', [Validators.required,this.validator.noWhitespaceValidator]),
      IsDisplayedSymptom: new FormControl(true)
    });

    this.GetAllSign();
    this.GetAllSymptom();
    this.functionPermission();
    this.signcolumnDefs = [
      {
        headerName: "Sign Description",
        field: "descriptionData",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false
      },
      {
        headerName: "Action",
        filter: false,
        resizable: false,
        minWidth:100,
        pinned:"right",
        sortable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "SignList" },
      },
    ]

    this.symptomcolumnDefs = [
      {
        headerName: "Symptom Description",
        field: "symptomDesc",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false
      },
      {
        headerName: "Action",
        filter: false,
        minWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "symptomId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "SymptomList" },
      },
    ]
    this.context = { componentParent: this };
    this.signdefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.vitalsigndefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.symptomdefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }



  GetAllSign() {
    this.ConfigurationService.GetSign(this.globals.HospitalId, 1).subscribe((res) => {
      this.signData = res.response;
    });
  }
  GetAllSymptom() {
    let showAll=1;
    this.ConfigurationService.GetSymptom(this.globals.HospitalId,showAll).subscribe((res) => {
      this.symptomData = res.response;
    });
  }

  openModalSign(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 19)
    if (foundElement.id == 19 && foundElement.isAllowed == true) {
    this.SignTitileName="Add Sign";
    this.modalRefSign = this.modalService.show(template);
    this.SignId=0;
    this.ResetFunction1();
    } else {
      this.toastr.warning("You are not allowed");
    }
   
  }

  openModalSymptom(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 22)
    if (foundElement.id == 22 && foundElement.isAllowed == true) {
    this.SymptomTitileName="Add Symptom";
    this.modalRefSymptom = this.modalService.show(template);
    this.SymptomId=0
    this.ResetFunction();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  showSignModalDialog(showsignmodalvalue) {
    const foundElement = this.functionPermissionData.find(x => x.id === 20)
    if (foundElement.id == 20 && foundElement.isAllowed == true) {
    this.SignTitileName="Update Sign";
    this.modalRefSign = this.modalService.show(
      this.modaltempSign,
      // Object.assign({}, { class: "gray modal-lg" })
    );
    this.SignId = showsignmodalvalue.id;
    if (showsignmodalvalue.isDisplayed == 1) {
      showsignmodalvalue.isDisplayed = true;
    }
    else {
      showsignmodalvalue.isDisplayed = false;
    }
    this.SignForm.patchValue({
      signDesc: showsignmodalvalue.descriptionData,
      IsDisplayedSign: showsignmodalvalue.isDisplayed
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  showVitalSignModalDialog(showvitalmodalvalue) {
    this.modalRefVitalsign = this.modalService.show(
      this.modaltempVitalsign,
      // Object.assign({}, { class: "gray modal-lg" })
    );
    this.VitalSignId = showvitalmodalvalue.signId;
    this.MandatoryStat = showvitalmodalvalue.mandatory;
    if (showvitalmodalvalue.isDisplayed == 1) {
      showvitalmodalvalue.isDisplayed = true;
    }
    else {
      showvitalmodalvalue.isDisplayed = false;
    }
    this.VitalSignForm.patchValue({
      vitalsignName: showvitalmodalvalue.signName,
      vitalsignMandatory: showvitalmodalvalue.mandatory,
      vitalsignCode: showvitalmodalvalue.signCode,
      vitalsignUnit: showvitalmodalvalue.signUnit,
      vitalsignMin: showvitalmodalvalue.minValue,
      vitalsignMax: showvitalmodalvalue.maxValue,
      vitalsignSortOrder: showvitalmodalvalue.sortOrder,
      IsDisplayedVitalSign: showvitalmodalvalue.isDisplayed
    });
  }
  showSymptomModalDialog(showsymptommodalvalue) {
    const foundElement = this.functionPermissionData.find(x => x.id === 23)
    if (foundElement.id == 23 && foundElement.isAllowed == true) {
    this.SymptomTitileName="Update Symptom";
    this.modalRefSymptom = this.modalService.show(
      this.modaltempSymptom,
      // Object.assign({}, { class: "gray modal-lg" })
    );
    if (showsymptommodalvalue.isDisplayed == 1) {
      showsymptommodalvalue.isDisplayed = true;
    }
    else {
      showsymptommodalvalue.isDisplayed = false;
    }
    this.SymptomId = showsymptommodalvalue.symptomId
    this.SymptomForm.patchValue({
      symptomDesc: showsymptommodalvalue.symptomDesc,
      IsDisplayedSymptom: showsymptommodalvalue.isDisplayed
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach((set) => {
      itemsArray.push(new TreeviewItem(set));
    });
    return itemsArray;
  }
  onTab(number) {
    this.signstab = false;
    this.vitalsignstab = false;
    this.symptomstab = false;
    if (number == "1") {
      this.signstab = true;
    } else if (number == "2") {
      this.vitalsignstab = true;
    } else if (number == "3") {
      this.symptomstab = true;
    }
  }
  get signf() {
    return this.SignForm.controls;
  }
  get vitalsignf() {
    return this.VitalSignForm.controls;
  }
  get symptomf() {
    return this.SymptomForm.controls;
  }
  showHideReason(event) {
    if (event.currentTarget.checked) {
      this.showReason = false;
    }
    else {
      this.showReason = true;
    }
  }
  RefreshUser() {
  }

    // to clear form symptomform
    clearForm() {
      this.SymptomForm.reset({
        symptomDesc:'',
        IsDisplayedSymptom:true
      })

      }

       // to clear form signForm
    clearForm2() {
      this.SignForm.reset({
        signDesc: '',
        IsDisplayedSign: true
      })

      }
  ResetFunction(){
    this.SymptomId=0
    this.submitted1=false;
    this.clearForm();
  }

  ResetFunction1(){
    this.SignId=0;
    this.submitted=false;
    this.clearForm2();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }
}
