import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.scss']
})
export class DesignationComponent implements OnInit {

  designationForm: FormGroup;
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  columnDefs: ColDef[] = [];
  rowData: any;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      // "c-reds": function (params) {
      //   return params.data.status == "C";

      // },
      // "c-reds2": function (params) {
      //   return params.data.isCancelled == 1;

      // },
    },

    // domLayout: "autoHeight",
  };

  constructor() { }

  ngOnInit(): void {
    this.designationForm = new FormGroup({
      BranchId: new FormControl(),
      Designation: new FormControl(""),
    });
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.columnDefs = [

      {
        headerName: "DesignationId",
        minWidth: 100,
        field: 'designationId',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Designation",
        minWidth: 250,
        field: 'designation',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },

      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Designation" },

      },


    ];
  }

  SaveDesignation() {

  }

  onSelectionChanged(eventData) {

  }

}
