import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { ConsultantBaseCost } from '../../client/interfaces/ConsultantBaseCost';
import { FormValidationService } from '../../client/services/form-validation.service';
import { PackageandprofileService } from '../package-and-profile/packageandprofile.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../client/services/configuration.service';
import { Select2OptionData } from 'ng-select2';
import { ActivatedRoute, Router } from "@angular/router";
import { HospitalsService } from '../../client/services/hospitals.service';
@Component({
  selector: 'app-ItemBaseCost',
  templateUrl: './itembasecost.component.html',
  styleUrls: ['./itembasecost.component.scss']
})
export class ItemBaseCostComponent implements OnInit {
  form: FormGroup;
  public ConsultantBaseCostForm: ConsultantBaseCost; // form interface
  serviceitemlist: any[];
  consultantlistarray: any;
  consultantList: Array<Select2OptionData> = [];
  serviceItemBasket: any[] = [];
  products: any = {};
  displayModal: boolean;
  public submitted: boolean = false;
  result: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  public statusBar;
  rowGroupPanelShow = "always";
  isShowConsultantDetails = null;
  //ag grid setup
  public gridApiList;
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  rowData: any;
  unlistedCode: any;
  ItemBaseCostForm: FormGroup;
  ConsultantId: any;
  rateGroupDatas: any;
  IsDisplayed: any;
  isShowConsultant: boolean = true;

  ListcolumnDefs: ColDef[] = [];
  GetConsultantById: Location[];
  consultants: any;
  departments: any;
  DepartmentId: any
  constructor(
    private toastr: ToastrService,
    private packageandprofileService: PackageandprofileService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private activatedRoute: ActivatedRoute,
    private hospitalsService: HospitalsService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      //  serviceitemlist: new FormControl(),
      ConsultantId: new FormControl(),
      DepartmentId: new FormControl()
    });
    // this.GetDepartments();
    // this.getConsultantList();
    this.GetAllAvailableServices();
    this.fetchId();
    if (this.ConsultantId) {
      this.fetchDetails();
    }
    this.getConsultantRates();
    this.columnDefs = [
      {
        headerName: "Item Name",
        field: "itemName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Base Cost",
        field: "baseCost",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
    ];
    this.ListcolumnDefs = [
      {
        headerName: "Consultant Name",
        field: "consultantName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        filter: false,
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "consultantId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "IBCConsultant" },
      },
    ];
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }
  onGridReadyList(params) {
    this.gridApiList = params.api;
  }
  EditConsultantBaseCost(data) {
    //this.getConsultantRates(Number(data));
  }
  //pagetitle to be displayed at the header
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);
  }
  GetAllAvailableServices() {
    this.packageandprofileService.GetServiceItems(this.globals.HospitalId).subscribe((res) => {
      this.serviceitemlist = res.response;
      this.serviceitemlist.forEach((a) => {
        (a.id = a.itemId), (a.text = a.itemName);
      });
    });
  }

  getConsultantRates() {
    let input = {
      "ConsultantId": this.ConsultantId,
      "BranchId": this.globals.HospitalId
    }
    this.ConfigurationService.getconsultantbasecost(input).subscribe((res) => {
      this.consultantlistarray = res.response;

    });
  }
  loadserviceitem(itemId) {
    if (itemId > 0) {
      let item = this.serviceitemlist.filter(x => x.itemId == itemId)[0];
      let itemarray = {
        itemId: item.id,
        baseCost: 0,
        itemName: item.text
      }
      this.serviceItemBasket.push(itemarray);
      this.gridApi.setRowData(this.serviceItemBasket);
    }

  }

  saveItemBaseCost() {
    this.gridApi.stopEditing(); 
    if (this.form && this.form.valid) {
      this.ConsultantBaseCostForm = this.form.getRawValue();
      //this.ConsultantBaseCostForm.PackAmount = Number(this.ConsultantBaseCostForm.PackAmount); // string to number//Not Needed Now
      let itemarayList = this.consultantlistarray.map(x => ({ ...x, baseCost: Number(x.baseCost) })); // string to number convertion
      //this.ConsultantBaseCostForm.consultantId = this.ConsultantId;
      this.ConsultantBaseCostForm.branchId = this.globals.HospitalId;
      this.ConsultantBaseCostForm.itemRateIP = itemarayList;
      this.ConfigurationService
        .saveconsultantbasecost(this.ConsultantBaseCostForm).subscribe(
          (res) => {
            this.submitted = false;
            if (res.status == 200 && res.message == "Success") {
              this.clearForm();
              this.toastr.success("Data saved successfully");
              this.getConsultantRates()
              // this.GetServicePoints();
            } else if (res.status == 200) {
              this.toastr.error("Failed : " + res.message);
            } else {
              this.toastr.error("Failure: " + res.response.message);
            }
          },
          (err) => {
            this.toastr.error("Some unexpected error occures try again later");
          }
        );
    }
  }
  // to clear form
  clearForm() {
    this.serviceItemBasket = [];
    this.gridApi.setRowData(this.serviceItemBasket);
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
  fetchId() {
    this.activatedRoute.params.subscribe((params) => {
      this.ConsultantId = Number(params.id);
    });
  }

  fetchDetails() {
    this.isShowConsultantDetails = true;
    this.ConfigurationService.GetConsultantById(this.ConsultantId).subscribe((res) => {
      this.GetConsultantById = res.response;

      this.DepartmentId = this.GetConsultantById[0]['deptId']
      this.GetDepartments();
      this.form.patchValue({
        DepartmentId: this.GetConsultantById[0]['deptId'],
      });
      this.form.patchValue({
        ConsultantId: this.GetConsultantById[0]['consultantId'],
      });

    })
  }

  GetDepartments() {
    this.hospitalsService
      .GetDepartments(this.globals.HospitalId, 0)
      .subscribe((res) => {
        this.departments = res.response;
      });
    this.GetDoctors();
  }

  GetDoctors() {
    this.hospitalsService.GetConsultant(this.DepartmentId).subscribe(res => {
      this.consultants = res.response;

    })
  }

}
