import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMRService } from '../../../emr/services/emr.service';
import { ConfigurationService } from '../../../client/services/configuration.service';

@Component({
  selector: 'app-add-route',
  templateUrl: './add-route.component.html',
  styleUrls: ['./add-route.component.scss']
})
export class AddRouteComponent implements OnInit {
  @Input() BranchId:number
  @Output() dosageEvent = new EventEmitter();
  isLoading:boolean=false
  public RouteForm: FormGroup;
  submitted:boolean=false

  constructor(
    private configurationService: ConfigurationService,
    public modalRef: BsModalRef,
  ) {

   }

  ngOnInit(): void {
    this.RouteForm = new FormGroup({
      RouteDesc: new FormControl("", [Validators.required]),
      RouteCode: new FormControl("", [Validators.required]),
    });
  }


  get dosagef() {
    return this.RouteForm.controls;
  }

  SaveDosage(){
    this.submitted=true
    if(this.RouteForm.valid){
      let payload={
        ...this.RouteForm.value,
        // RouteCode:Number(this.RouteForm.value.RouteCode),
        BranchId:this.BranchId
      }
      this.configurationService.InsertManualRoute(payload).subscribe({
        next:(res)=>{
          if(res.status == 200){
            this.dosageEvent.emit({data: Number(res.response[0].routeId)})
            this.modalRef.hide()
          }
        }
      })
    }
  }

  close(status){
    this.modalRef.hide()
    this.dosageEvent.emit();
  }
}
