import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sponsor-revenue-monthwise',
  templateUrl: './sponsor-revenue-monthwise.component.html',
  styleUrls: ['./sponsor-revenue-monthwise.component.scss']
})
export class SponsorRevenueMonthwiseComponent implements OnInit {
  form: FormGroup; // form variable
  isLoading=false;
  isPdfLoaded:boolean=false;
  src :any= '';
  subscription: Subscription;
  globals: globalvars = new globalvars();
  private baseUrl = "";

  constructor(
    private sharedservice: SharedService,
    private configurationManager: ConfigurationManager,
    public  datepipe: DatePipe,


  ) { 
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });
  
    this.baseUrl = (this.configurationManager as any).baseUrl;
  

  }

  ngOnInit(): void {
    this.form=new FormGroup({
      FromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ToDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      BranchId: new FormControl(this.globals.HospitalId),
    });

  }

}
