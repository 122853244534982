import { Component, OnInit } from '@angular/core';
// import { MessageType } from '@generic-ui/hermes/core/domain/message/message-type';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { NabithService } from "../nabith.service";
import { MessageTypeList } from '../interface/MessageTypeList';
import { ConsultantList } from '../interface/ConsultantList';
import { NabithList } from '../interface/NabithList';
import { ToastrService } from "ngx-toastr";
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-nabit-integration',
  templateUrl: './nabit-integration.component.html',
  styleUrls: ['./nabit-integration.component.scss']
})
export class NabitIntegrationComponent implements OnInit {

  consultantForm: FormGroup; // form variable
  MessageTypes: MessageTypeList[] = [];
  MessageTypesData: any;
  ConsultantType: ConsultantList[] = [];
  ConsultantListData: any;
  NabithType: NabithList[] = [];
  NabithDetailsData: any;
  rowData: [];
  rowdataView: [];
  defaultColDef: ColDef[] = [];
  columnDefsView: ColDef[] = [];
  rowGroupPanelShow = "always";
  public context;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 20,
  }
  public noRowsTemplate;
  public frameworkComponents;
  public gridApi;
  public loadingTemplate = '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';
  globals: globalvars = new globalvars();
  subscription: Subscription;
  public submitted: boolean = false;
  public gridApiConsultation;
  public gridApiNabith;


  constructor(
    private NabithService: NabithService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private datepipe: DatePipe,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });
  }

  ngOnInit(): void {
    this.consultantForm = new FormGroup({
      messageTypeDdl: new FormControl('', [Validators.required]),
      environment: new FormControl('', [Validators.required]),
      responseType: new FormControl('', [Validators.required]),
      fromDate: new FormControl('', [Validators.required]),
      toDate: new FormControl('', [Validators.required]),
    });
    this.defaultColDef = [
      {
        headerName: "Consult Date",
        field: "consultDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Consultant ",
        field: "consultantName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 180,
        filter: true,
      },
      {
        headerName: "Token",
        field: "tokenNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Reg.No",
        field: "regNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 150,
        filter: true,
      },
      {
        headerName: "Patient Name",
        field: "patientName",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        minWidth: 180,
        filter: true,
      },
      {
        headerName: "Sponsor",
        field: "sponsorName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 150,
        filter: true,
      },
      {
        headerName: "Action",
        field: "action",
        enableRowGroup: true,
        resizable: false,
        sortable: false,
        flex: 2,
        maxWidth: 90,
        pinned: "right",
        filter: false,
        // cellRenderer: "buttonRender",
        // cellRendererParams: { PageType: "MessageTypeList" },
      }
    ]
    this.columnDefsView = [
      {
        headerName: "",
        field: "empty",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        maxWidth: 80,
        checkboxSelection: true,
        filter: true,
      },
      {
        headerName: "Date",
        field: "entryDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Message Details ",
        field: "messageDetails",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 250,
        filter: true,
      },
      {
        headerName: "Res Status",
        field: "resultStatus",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 120,
        filter: true,
      },
      {
        headerName: "Nabith Ref No",
        field: "nabidhRefNumber",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 180,
        filter: true,
      },
      {
        headerName: "Event ",
        field: "eventName",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        minWidth: 180,
        filter: true,

      },
      {
        headerName: "Environ",
        field: "environment",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 180,
        filter: true,

      },
    ];
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
      onGridReady: (event) => event.api.sizeColumnsToFit()
    };
    this.Getmessage();
  }

  onGridReadyConsultation(params) {
    this.gridApiConsultation = params.api;
  }
  onGridReadyNabith(params) {
    this.gridApiNabith = params.api;
  }

  onFilterTextBoxConsultation() {
    this.gridApiConsultation.setQuickFilter(
      (document.getElementById('filter-text-box-consultation') as HTMLInputElement).value
    );
  }
  onFilterTextBoxNabith() {
    this.gridApiNabith.setQuickFilter(
      (document.getElementById('filter-text-box-nabith') as HTMLInputElement).value
    );
  }



  get f() {
    return this.consultantForm.controls;
  }

  Getmessage() {
    this.NabithService.GetMessageType().subscribe((res) => {
      this.MessageTypes = res.response;
      this.MessageTypesData = [];
      this.MessageTypes.forEach((a) => {
        this.MessageTypesData.push({
          id: a.eventId.toString(),
          text: a.eventName,
        });
      });
      if (this.MessageTypes.length == 0) {
        this.toastr.warning("Data Not found");
      }
    });
  }

  GetConsultants() {
    let ConsultantId = Number(localStorage.getItem('Id'));
    let dobConvertedFrom = '';
    let FromDate = this.consultantForm.get('fromDate').value;
    if (typeof (FromDate) == "object") {
      let dobConvertedf = this.datepipe.transform(FromDate, 'dd-MM-yyyy');
      dobConvertedFrom = dobConvertedf;
    }
    let dobConvertedTo = '';

    let ToDate = this.consultantForm.get('toDate').value;
    if (typeof (ToDate) == "object") {
      let dobConvertedt = this.datepipe.transform(ToDate, 'dd-MM-yyyy');
      dobConvertedTo = dobConvertedt;
    }

    let BranchId = Number(this.globals.HospitalId);
    this.NabithService.GetConsultant(ConsultantId, dobConvertedFrom, dobConvertedTo, BranchId).subscribe((res) => {
      this.ConsultantType = res.response;
      if (this.ConsultantType.length == 0) {
        this.toastr.warning("No Details found");
      }
    });
  }

  GetNabith() {
    let EventId = Number(this.consultantForm.get('messageTypeDdl').value);
    let dobConvertedFrom = '';
    let FromDate = this.consultantForm.get('fromDate').value;
    if (typeof (FromDate) == "object") {
      let dobConvertedf = this.datepipe.transform(FromDate, 'dd-MM-yyyy');
      dobConvertedFrom = dobConvertedf;
    }
    let dobConvertedTo = '';

    let ToDate = this.consultantForm.get('toDate').value;
    if (typeof (ToDate) == "object") {
      let dobConvertedt = this.datepipe.transform(ToDate, 'dd-MM-yyyy');
      dobConvertedTo = dobConvertedt;
    }

    let ResultStatus = this.consultantForm.get('responseType').value;
    let Environment = this.consultantForm.get('environment').value;;
    let ConsultantId = Number(localStorage.getItem('Id'));
    this.NabithService.GetNabithDetails(EventId, dobConvertedFrom, dobConvertedTo, ResultStatus, Environment, ConsultantId).subscribe((res) => {
      this.NabithDetailsData = res.response;
      if (this.NabithDetailsData.length == 0) {
        this.toastr.warning("No Details found");
      }
    });
  }

  searchNabith() {
    this.submitted = true;
    if (this.consultantForm && this.consultantForm.valid) {
      this.GetNabith();
      this.GetConsultants();
    }
  }

}
