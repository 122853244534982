import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Response } from '../../../_Models/Response';
import { UserHospitals } from '../interfaces/UserHospitals';
import { Departments } from '../interfaces/Departments';
import { Consultants } from '../interfaces/Consultants';
import { catchError, retry } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandleService } from './error-handle.service';
import { progressBarList } from '../interfaces/ProgressBarList';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class ConsultantDeskService {

  private baseUrl = "";
  errorMessage: any;

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private toastr: ToastrService,
    private errorHandle: ErrorHandleService,
    private datepipe: DatePipe,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;

  }


  // GetPatientByConsultants(patient, HospitalId, FromDate, ToDate) {
  //   var url = "api/Consultant/SearchPatientByConsultantId/";
  //   return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
  //     {
  //       "ConsultantId": patient,
  //       "BranchId": HospitalId,
  //       "RegDateFrom": FromDate,
  //       "RegDateTo": ToDate

  //     }).pipe(
  //       retry(1),
  //       catchError(this.errorHandle.handleError)
  //     );
  // }
  GetPatientByConsultants(patient, HospitalId, FromDate, ToDate) {
  var url = "api/Registration/SearchPatientInList";
  return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
    {
      "BranchId": HospitalId,
      "ConsultantId": Number(patient),
      "Name": "",
      "RegNo": "",
      "Address": "",
      "IdentityNo": "",
      "PolicyNo": "",
      "Mobile": "",
      "ResNo": "",
      "Mode": "",
      "RegDateFrom": FromDate,
      "RegDateTo": ToDate,
      "Phone": "",
      "PIN": "",

    })
  }
  GetAppointmentsByConsultants(patient, HospitalId, FromDate, ToDate) {
    var url = "api/Consultant/SearchAppointmentByConsultantId/";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": patient,
        "BranchId": HospitalId,
        "FromDate": FromDate,
        "ToDate": ToDate

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetConsultationByConsultants(patient, HospitalId, FromDate, ToDate) {
    var url = "api/Consultant/SearchConsultationById/";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": patient,
        "BranchId": HospitalId,
        "FromDate": FromDate,
        "ToDate": ToDate

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetConsultantConsultations(consultantId, date, branchId) {
    var url = "api/Consultant/GetMyConsultations";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": consultantId,
        "AppDate": date,
        "BranchId": branchId,
        "Status": "W",
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  InsertUpdateSchedule(eventypeId,title, starttime, endtime, consultId) {
    var url = "api/Consultant/InsertUpdateSchedule";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "ScheduleId": 0,
        "ConsultantId": Number(consultId),
        "StartTime": starttime,
        "EndTime": endtime,
        "Title": title,
        "EventTypeId":eventypeId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }


  GetScheduleTitle(consultantId, BranchId) {
    var url = "api/Consultant/GetSchedules";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "consultantId": Number(consultantId),
        "BranchId": BranchId

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }


  DeleteSchedule(ScheduleId) {
    var url = "api/Consultant/DeleteSchedule/" + ScheduleId;
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

  SaveTimer(starttime, endtime, consultId, day, hour, min, sec, count, patientId) {
    var url = "api/Consultant/InsertUpdateTimer";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {

        "TimerId": 0,
        "ConsultantId": Number(consultId),
        "PatientId": patientId,
        "UserId": Number(localStorage.getItem('user_id')),
        "StartTime": starttime,
        "EndTime": endtime,
        "Days": day,
        "Hours": hour,
        "Minutes": min,
        "Seconds": sec,
        "TickCount": count

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  //To display progressbar monitor
  GetProgressBar(consultDate: any, ConsultantId, branchId) {
    var url = "api/Consultant/GetFrontOfficeProgressBarByConsultantId";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": Number(ConsultantId),
        "AppDate": consultDate,
        "BranchId": branchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetDiseaseSymptoms(ConsultantId: Number) {
    var url = "api/Consultant/GetDiseaseSymptoms/" + ConsultantId;
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetDiseaseVitalSigns(ConsultantId: Number) {
    var url = "api/Consultant/GetDiseaseSigns/" + ConsultantId;
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }



  GetAllDiseseById(ConsultantId) {

    var url = "api/Consultant/GetDiseaseByConsultantId/" + ConsultantId;
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }
  GetAllMarksList(ConsultantId, branchId) {
    var url = "api/Consultant/GetConsultantMarkings/";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {
        "MarkId": 0,
        "ConsultantId": ConsultantId,
        "BranchId": branchId


      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  InsertSaveMarks(form, ConsultantId) {

    var url = "api/Consultant/InsertUpdateConsultantMarking";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "MarkId": 0,
        "MarkDesc": form.value.MarkDescription,
        "IndicatorId": Number(form.value.indicator),
        "Colour": form.value.Color,
        "ShowCaption": false,
        "ConsultantId": Number(ConsultantId)
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  InsertUpdateConsultantMarking(markId, form, ConsultantId) {
    {
      var url = "api/Consultant/InsertUpdateConsultantMarking";
      return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
        {
          "MarkId": Number(markId),
          "MarkDesc": form.value.MarkDescription,
          "IndicatorId": Number(form.value.indicator),
          "Colour": form.value.Color,
          "ShowCaption": false,
          "ConsultantId": Number(ConsultantId)
        }).pipe(
          retry(1),
          catchError(this.errorHandle.handleError)
        );

    }
  }

  GetAllIndicators(branchId) {
    var url = "api/MasterData/GetSketchIndicators/";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

        "IndicatorId": 0,
        "ShowAll": 1,
        "BranchId": branchId

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetAllDrugsList(ConsultantId,branchId) {
    var url = "api/Consultant/GetConsultantDrugs";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": ConsultantId,
        "DrugId": 0,
        "BranchId": branchId,
        ConsultationId: 0,
        IsInsured: 0,
        IsFreq: 1
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  DeleteConsultantMarks(marksId) {
    var url = "api/Consultant/DeleteConsultantMarkings/" + marksId;
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  getDiseaseDetailsAdd(diseaseId,branchId) {
    var url = "api/Consultant/GetDiseaseDetailsById/" ;
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
    {
      "DiseaseId": diseaseId,
      "BranchId": branchId
  }
    ).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetIcdsSymptomSign(Symptoms, Signs, BranchId) {

    var url = "api/Consultant/GetICDBySymptomSign";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {
        "Signs": Signs,
        "Symptoms": Symptoms,
        "BranchId": BranchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }

  FetchingMarksDetails(MarksId: Number) {
    var url = "api/Consultant/GetConsultantMarkingsById/" + MarksId;
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  InsertSaveConsultantDiseases(DiseaseDesc, ConsultantId, ICD, symptoms, Signs, DiseaseId) {

    var url = "api/Consultant/InsertConsultantDiseases/";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

        "DiseaseId": DiseaseId,
        "DiseaseDesc": DiseaseDesc,
        "ConsultantId": Number(ConsultantId),
        "LabelId": ICD,
        "Symptoms": symptoms,
        "Signs": Signs,


      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }


  GetServiceItems(ConsultantId, branchId, consultationId?,dataConfig?, CurrentDate?, CreditId?, Category?) {

    var url = "api/Consultant/GetConsultantServicesItems/";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": ConsultantId,
        "BranchId": branchId,
        ConsultationId: consultationId? consultationId : 0,
        IsFreq: dataConfig ? dataConfig.showFrequent ? 1 : 0: 1,
        IsInsured: dataConfig ? dataConfig.showCovered ? 1 : 0: 0,
        CurrentDate: CurrentDate ? CurrentDate : this.datepipe.transform(new Date(), "dd-MM-yyyy"),
        CreditId: CreditId ? CreditId : 0,
        Category: Category ? Category : ''
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  DeleteConsultantService(ItemId, ConsultantId, branchId) {
    var url = "api/Consultant/DeleteConsultantService/";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

        "ItemId": ItemId,
        "BranchId": branchId,
        "ConsultantId": ConsultantId,
        "UserId": Number(localStorage.getItem('user_id')),

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  DeleteDiseaseSign(DiseaseId) {
    var url = "api/Consultant/DeleteConsultantDisease/" + DiseaseId;
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  DeleteDrugs(DrugId) {
    var url = "api/Consultant/DeleteConsultantDrug/" + DrugId;
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetDrugsListing(BranchId) {
    var url = "api/MasterData/GetDrug/";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {
        "DrugId": 0,
        "DrugTypeId": 0,
        "BranchId": BranchId

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }



  GetDiseaseDetails(DiseaseId) {

    var url = "api/Consultant/GetDiseaseDetailsById/" + DiseaseId;
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  InsertSaveServiceItems(ItemListId, ConsultantId, BranchId, locationId) {

    var url = "api/Consultant/InsertConsultantService/";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {

        "ConsultantId": Number(ConsultantId),
        "ItemIdList": ItemListId,
        "BranchId": BranchId,
        "UserId": Number(localStorage.getItem('user_id')),
        ModifiedLoc: Number(locationId),
        ModifiedBy: Number(localStorage.getItem('user_id'))

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  InsertSaveDrugs(formData) {
    var url = "api/Consultant/InsertConsultantDrugs";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, formData)
  }



  FetchingDrugsDetails(ConsultantId, DrugsId: Number) {
    var url = "api/Consultant/GetConsultantDrugsById/" ;
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": Number(ConsultantId),
        "DrugId": DrugsId,
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  BlockPatient(DiseaseId: Number, blockReason: string) {//block  patient by patient id
    var url = "api/Consultant/BlockDisease";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "DiseaseId": DiseaseId,
        "BlockReason": blockReason,
        // "UserId":Number(localStorage.getItem('user_id'))
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  UnBlockPatient(DiseaseId: Number) {//unblock  patient by patient id
    var url = "api/Consultant/UnblockDisease";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "DiseaseId": DiseaseId,
        // "UserId":Number(localStorage.getItem('user_id'))
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetRoute(BranchId) {
    var url = "api/MasterData/GetRoute";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "RouteId": 0,
        "BranchId": BranchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

 
  GetSketches(Id, BranchId) {
    var url = "api/Consultant/GetConsultantSketch";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": Id,
        "BranchId": BranchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetEventType(Input){
    var url = "api/MasterData/GetCommonMasterItem";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,Input);
  }

  getICDSList(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/MaterData/GetICDLabel`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  getCFormOrder(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/Consultant/GetConsultantEMRTabView`;
    return this.http.post<Response<any>>(endPoint, payload);
  }
 
  saveCFormOrder(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/Consultant/InsertUpdateEMRTabView`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  getFeeDetails(payload): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/TodaysPatient/GetConsultationRate`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  changeConsultationFee(payload): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/TodaysPatient/UpdateConsultantFeeOrDiscount`;
    return this.http.post<Response<any>>(endPoint, payload);
  }
}
