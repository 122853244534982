
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class LoginGuard implements CanActivate {

    constructor(private router: Router){
  
    }
  
    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): boolean {
     
      if(localStorage.getItem('sec_token')){
        this.router.navigate(['/dashboard']);
        return false;
      }
      return true
    }
    
}