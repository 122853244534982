import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EMRPatientInfo } from '../../../interfaces/EMRPatientInfo';
import { EMRService } from '../../../services/emr.service';
import { ViewDrugsMedicationModalComponent } from '../view-drugs-medication-modal/view-drugs-medication-modal.component';

@Component({
  selector: 'app-prev-drugs-and-medication-modal',
  templateUrl: './prev-drugs-and-medication-modal.component.html',
  styleUrls: ['./prev-drugs-and-medication-modal.component.scss']
})
export class PrevDrugsAndMedicationModalComponent implements OnInit {

  @Input() EmrPatientInfo: EMRPatientInfo;

  public drugsMedicationList: any[] = [];

  constructor(private activeModalRef: BsModalRef,
              private EmrService: EMRService,
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.GetPrevDrugsEMR();
  }

  close(){
    this.activeModalRef.hide();
  }

  GetPrevDrugsEMR() {
    let input = {
      VisitId: this.EmrPatientInfo.VisitId,
      PatientId: this.EmrPatientInfo.PatientId,
      ShowAll: 0,
    };

    this.EmrService.GetDrugsEMR(input).subscribe((res) => {
      if (res.status == 200) {
        this.drugsMedicationList = res.response;
      }
    });
  }

  viewDetails(data: any){
    let initialState = {
      drugDetails: data,
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const modalRef= this.modalService.show(ViewDrugsMedicationModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

}
