import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ColDef, GridOptions } from 'ag-grid-community';
import { Select2OptionData } from "ng-select2";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { GeneralGridActionComponent } from "../../../shared/general-grid-action/general-grid-action.component";
import { globalvars } from "../../../shared/models/GlobalVars";
import { SharedService } from "../../../shared/shared.service";
import { UserGroups } from "../../client/interfaces/usergroup";
import { UserHospitals } from "../../client/interfaces/UserHospitals";
import { UserModel } from "../../client/interfaces/UserModel";
import { HospitalsService } from "../../client/services/hospitals.service";
import { LocationService } from "../location/location.service";
import { UserpermissionService } from "../userpermission.service";
import { Location } from "../../client/interfaces/Location";
import { TreeviewHelper, TreeviewItem } from "ngx-treeview";
import { ToastrService } from "ngx-toastr";
import { UserFunctionalityComponent } from "./user-functionality/user-functionality.component";
import _ from "lodash";
import { ConfigurationService } from "../../client/services/configuration.service";
@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  User: any;
  columnDefs = [];
  public gridApi;
  private gridColumnApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public savedGroups: any;
  public form: FormGroup;
  public submitted: boolean = false;
  displayModal: boolean;
  showReason: boolean = false;
  displayModal1: boolean;
  supervisor = "";
  locationname = "";
  Mobilenumber = "";
  IsList: boolean = true;
  hospitalList: UserHospitals[] = [];
  public defaultBranchList: UserHospitals[] = [];
  Grouplist: UserGroups[] = [];
  public selectoptions: any;
  HospitalData: Array<Select2OptionData> = [];
  globals: globalvars = new globalvars();
  products: any = {};
  rowData: any = [];
  subscription: Subscription;
  selectedCities: any[] = [];
  statusBar: any;
  modalRef?: BsModalRef;
  modalRef2?: BsModalRef;
  modalRef3?: BsModalRef;
  @ViewChild("template") modaltemp: any;
  @ViewChild("LocationMap") modalmaptemp: any;
  @ViewChild("GroupMap") modalmapgroup: any;
  branchlist: any;
  locationList: Location[];
  defaultBranch: any[] = [];
  formmap: FormGroup;
  groupmap: FormGroup;
  maploc: any;
  LocationMasters: [any[]] = [[]];
  GroupMasters: [any[]] = [[]];
  addedgroups: boolean;
  addgroup: boolean;
  addedmenu: boolean;
  addmenu: boolean;
  menus: any;
  items: any;
  editUserId: number = 0;
  config = {
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 220,
  };
  BranchId: any;

  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    // rowClassRules: {
    //   'c-reds': function (params) { return params.data.active == '0'; },
    // },
  }

  public locationLoading: boolean = false;

  public filterBranch: any;

  public strongPassword: boolean = false;
  functionPermissionData: any;

  constructor(
    private Userservice: UserpermissionService,
    private sharedservice: SharedService,
    private hospitalsService: HospitalsService,
    private modalService: BsModalService,
    private locationservice: LocationService,
    private toastr: ToastrService,
    private ConfigurationService: ConfigurationService,
  ) {
    this.onTab(1);
    this.onTabinner(2);
    //this.getBranch();
    //  this.Getmenus();

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.filterBranch = this.globals.HospitalId
      }
    });
    this.GetUsers();
    this.formmap = new FormGroup({
      LocationMaps: new FormArray([]),
    });
    this.groupmap = new FormGroup({
      branchId: new FormControl(),
      groupIds: new FormControl(""),
      submenuIds: new FormControl(""),
    });
    this.form = new FormGroup({
      userName: new FormControl("", [Validators.required]),
      userId: new FormControl(0),
      userPassword: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          "(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}"
        ),
      ]),
      confirmUserPassword: new FormControl("", [Validators.required]),
      branchIds: new FormControl(),
      defaultBranch: new FormControl('0'),
      groupIds: new FormControl(),
      active: new FormControl(true),
      blockReason: new FormControl(""),
      UserType: new FormControl("")
    });
    this.columnDefs = [
      {
        headerName: "User",
        minWidth:200,
        field: "userName",
        resizable: true,
        unSortIcon: true,
        flex: 2,
      },
      {
        headerName: "Branch",
        minWidth:200,
        field: "branches",
        enableRowGroup: true,
        resizable: false,
        flex: 3,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:130,
        pinned:"right",
        sortable: false,
        field: "userId",
        enableRowGroup: true,
        resizable: false,

        cellRenderer: "actionsComponentRenderer",
        cellRendererParams: {
          Page: "User",
        },
        flex: 2,
      },

      // ik
    ];
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    // this.valueDate =  this.datepipe.transform(new Date(),'yyyy-MM-dd');
    // let dte = new Date();
    // dte.setDate(dte.getDate() - 28);

    // this.valueDate2=this.datepipe.transform(dte.toString(),'yyyy-MM-dd');

    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };

    this.frameworkComponents = {
      actionsComponentRenderer: GeneralGridActionComponent,
    };
    this.context = { componentParent: this };
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  Save() {
    this.submitted = true;

    if (this.form.get("userId").value == null) {
      this.form.get("userId").patchValue(0);
    }
    if (this.form.get("blockReason").value == null) {
      this.form.get("blockReason").patchValue("");
    }
    this.User = this.form.getRawValue();
    let result = this.f;
    let Notvalid = 0;

    if (result.userName.value == "") {
      this.toastr.error("UserName Required", "", { timeOut: 10000 });
      Notvalid = 1;
    }

    if (result.userPassword.value == "") {
      this.toastr.error("Password Required", "", { timeOut: 10000 });
      Notvalid = 1;
    }
    if (result.branchIds.value == "") {
      this.toastr.error("Minimum One Branch Required", "", { timeOut: 10000 });
      Notvalid = 1;
    }

    if (Notvalid == 0 && this.form.valid) {
      if (result.confirmUserPassword.value == result.userPassword.value) {
        this.User = this.form.getRawValue();
        this.User.defaultBranch = Number(this.User.defaultBranch);
        this.User.branchIds.map((a) => (a = Number(a)));
        this.User.ModifiedLoc =this.globals.Location.locationId,
        this.User.ModifiedBy = Number(localStorage.getItem('user_id'))

        // this.location.hospitalId = this.globals.HospitalId;
        // this.location.lTypeId=Number(this.form.get('lTypeId').value);
        this.Userservice.SaveUser(this.User).subscribe((res) => {
          //   this.displayModal = false;

          this.form.reset({
            userName: "",
            userId: 0,
            userPassword: "",
            confirmUserPassword: "",
            active: true,
            UserType: ""
          });
          this.showReason = false;
          this.GetUsers();
          this.submitted = false;
          if (res.response == "Username already exists") {
            this.toastr.error("Username already exists")
          }

          else {
            this.toastr.success(res.response);
          }


        });
      } else {
        this.toastr.error("Password and confirm password do not match", "", {
          timeOut: 10000,
        });
      }
    }
  }
  getitemsChecked(event) {
    this.groupmap?.get("submenuIds").patchValue(event);
  }
  onTab(number) {
    this.addedgroups = false;
    this.addgroup = false;

    if (number == "1") {
      this.addedgroups = true;
    } else if (number == "2") {
      this.addgroup = true;
    }
  }
  onTabinner(number) {
    this.addedmenu = false;
    this.addmenu = false;

    if (number == "1") {
      this.addedmenu = true;
    } else if (number == "2") {
      this.addmenu = true;
    }
  }
  GetMenuongroups(event) {
    this.items = [];
    var user = this.form.get("userId").value;
    var groupid = event?.map((x) => x).join(",");
    if (groupid == "") {
      return;
    }
    var branch = this.groupmap.get("branchId").value;
    this.BranchId = branch;
    this.Userservice.GetMenuongroups(user, branch, event).subscribe((res) => {
      this.menus = res.response.leftmenu;
      var usersubmenu = res.response.subMenuIds;
      var submenuids = [];
      this.menus?.map((a) => {
        (a.text = a.menuAlias),
          (a.value = a.mid),
          a.subMenus?.map((b) => {
            (b.text = b.subMenuAlias), (b.value = b.sId);
            submenuids.push(b.sId);
          }),
          (a.children = a.subMenus);
      });
      if (usersubmenu != null) {
        submenuids = [];
        this.menus?.map((a) => {
          (a.text = a.menuAlias),
            (a.value = a.mid),
            a.subMenus?.map((b) => {
              (b.text = b.subMenuAlias), (b.value = b.sId);
              var foundarr = usersubmenu.filter((c) => c.groupId == a.groupId);
              if (foundarr?.length == 0) {
                submenuids.push(b.sId);
              } else {
                var foundarr2 = usersubmenu.filter(
                  (c) => c.groupId == a.groupId && c.submenuId == b.sId
                );
                if (foundarr2?.length > 0) {
                  submenuids.push(b.sId);
                }
              }
            }),
            (a.children = a.subMenus);
        });
        //submenuids=usersubmenu;
      }
      this.items = this.getItems(this.menus);
      this.items.forEach((item) => {
        item.setCheckedRecursive(false);
      });
      this.groupmap?.get("submenuIds").patchValue(submenuids);
      submenuids.forEach((e) => {
        const found = TreeviewHelper.findItemInList(this.items, e);
        found.checked = true;
        this.items.forEach((element) => {
          element.correctChecked();
        });
      });
    });
  }
  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach((set) => {
      itemsArray.push(new TreeviewItem(set));
    });
    return itemsArray;
  }
  MapLocations(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 495)
    if (foundElement.id == 495 && foundElement.isAllowed == true) {
    const LocationMap = this.formmap.get("LocationMaps") as FormArray;
    LocationMap.clear();
    // this.modalRef2 = this.modalService.show(
    //   this.modalmaptemp,
    //   Object.assign({}, { class: "gray modal-lg" })
    // );
    this.locationLoading = true;
    this.Userservice.GetUserBranches(id).subscribe((res) => {
      //this.branchlist ="";
      this.branchlist = res.response;
      this.branchlist.unshift({ 'hospitalId': 0, hospitalName: 'Select Branch' });
      this.branchlist.forEach((a) => {
        (a.id = a.hospitalId), (a.text = a.hospitalName);
      });
      this.form.get("userId").patchValue(id);
      this.Userservice.GetUserLocations(id).subscribe((res) => {
        this.addMapFormGroup(res.response);
        this.modalRef2 = this.modalService.show(
          this.modalmaptemp,
          Object.assign({}, { class: "gray modal-lg" })
        );
        this.locationLoading = false;
      });
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
    // this.form.get("userId").patchValue(id);
    // this.Userservice.GetUserLocations(id).subscribe((res) => {
    //   this.addMapFormGroup(res.response);
    // });
  }

  getBranch() {
    var id = this.form.get("userId").value;
    if (Number(id) == 0) {
      return;
    }
    this.Userservice.GetUserBranches(id).subscribe((res) => {
      this.branchlist = res.response;
      this.defaultBranch['hospitalName'] = "Select Branch";
      this.defaultBranch['hosptalId'] = 0;
      this.branchlist.unshift(this.defaultBranch);
      //this.branchlist.push(this.defaultBranch);
      this.branchlist.forEach((a) => {
        (a.id = a.hospitalId), (a.text = a.hospitalName);
      });
    });
    this.groupmap.patchValue({branchId: this.globals.HospitalId});
  }
  GetLocations(branch, index, locationIds, defaultIds) {
    this.locationservice.GetLocations(Number(branch), 0).subscribe((res) => {
      this.locationList = res.response;

      //  this.locationList.push(this.defaultLocation);
      this.locationList.forEach((a) => {
        (a.id = a.locationId), (a.text = a.locationName);
      });
      this.LocationMasters[index] = this.locationList;
      console.log(defaultIds)
      if (locationIds.length != 0) {
        ((this.formmap.get("LocationMaps") as FormArray).at(index) as FormGroup).get("locationIds").patchValue(locationIds);
        ((this.formmap.get("LocationMaps") as FormArray).at(index) as FormGroup).get("defaultLocationIds").patchValue(defaultIds ? defaultIds : '');
      }
    });
  }
  GetUser(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 494)
    if (foundElement.id == 494 && foundElement.isAllowed == true) {
    this.editUserId = id;
    this.groupmap.enable();
    this.Userservice.GetUser(id).subscribe((res) => {
      this.User = res.response;
      this.User.defaultBranch = this.User.defaultBranch == 0 ? '' : this.User.defaultBranch;
      this.form.patchValue({ UserType: this.User['userType'] });
      this.form.patchValue(this.User);
      this.getBranch();
      this.getsavedgroups(id);
      this.onSelectBranch(this.User.branchIds);
      this.onTab(2);
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  GetUsers() {
    var bidv = 0;
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        bidv = this.globals.HospitalId;
      }
    });
    var branchid = bidv;
    this.Userservice.GetUsers(branchid).subscribe((res) => {
      this.rowData = res.response;
    });
  }
  GetUserHospitals() {
    var user = localStorage.getItem("user_id");
    this.hospitalsService.GetUserSpecificHospitals(user).subscribe((res) => {
      this.hospitalList = res.response;
      this.hospitalList.forEach((a) => {
        (a.id = a.hospitalId), (a.text = a.hospitalName);
      });
    });
  }
  GetGroups(branch) {
    if (branch > 0) {


      var userId = this.form.get("userId").value;
      this.Userservice.getUserGroupsonBranch(
        Number(branch),
        Number(userId)
      ).subscribe((res) => {

        this.Grouplist = res.response.userGroups;
        this.Grouplist.map((a) => {
          (a.id = a.userGroupId), (a.text = a.userGroup);
        });
        this.Grouplist.forEach((a) => {
          (a.id = a.userGroupId), (a.text = a.userGroup);
        });
        this.groupmap.get("groupIds").patchValue(res.response.groupIds);
        if (res.response.groupIds.length > 0) {
          this.GetMenuongroups(res.response.groupIds);
        }
      });
    }
  }

  ngOnInit(): void {
    this.groupmap.disable();

    this.selectoptions = {
      multiple: true,
      closeOnSelect: true,
    };
    this.GetUserHospitals();
    this.functionPermission();
  }

  get f() {
    return this.form.controls;
  }

  showModalDialog() {
    this.displayModal = true;
  }
  MapSave() {
    var saveobj = { userId: this.form.get("userId").value, LocationIds: [], defaultLocationIds: [] };
    this.maploc = this.formmap.getRawValue();
    this.maploc.LocationMaps.forEach((a) => {
      a.locationIds.forEach((b) => {
        saveobj.LocationIds.push(b);
      });
      saveobj.defaultLocationIds.push(a.defaultLocationIds);
    });
    this.Userservice.SaveLocationMap(saveobj).subscribe((res) => {
      this.toastr.success(res.response);
      this.modalRef2.hide();
      this.form.get("userId").patchValue(0);
      this.formmap.reset();
    });
  }
  SaveMenumap() {
    var groupids = this.groupmap.get("groupIds").value;
    groupids = groupids.map((a) => (a = Number(a)));
    var saveobj = {
      UserId: this.form.get("userId").value,
      SubmenuIds: this.groupmap.get("submenuIds").value,
      branchId: Number(this.groupmap.get("branchId").value),
      groupIds: groupids,
    };
    this.Userservice.SaveUserMenu(saveobj).subscribe((res) => {
      this.toastr.success(res.response);
    });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-lg" })
    );
  }
  public addMapFormGroup(data: any[] = [], saved = 0) {
    if (data.length > 0) {
      this.LocationMasters = [[]];
      data.forEach((element, i) => {
        this.LocationMasters.push(new Array());
        const LocationMap = this.formmap.get("LocationMaps") as FormArray;
        this.GetLocations(element["branch"], i, element["locationIds"], element['defaultLocationstring']);
        LocationMap.push(this.createListGroup(element, saved));
      });
    } else {
      const LocationMap = this.formmap.get("LocationMaps") as FormArray;
      this.LocationMasters.push(new Array());
      LocationMap.push(this.createListGroup());
      const arr = this.formmap.get("LocationMaps") as FormArray;
      ((this.formmap.get("LocationMaps") as FormArray).at(arr.length - 1) as FormGroup).get("locationIds").patchValue(0);

      ((this.formmap.get("LocationMaps") as FormArray).at(arr.length - 1) as FormGroup).get("defaultLocationIds").patchValue('');
    }
  }
  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    return new FormGroup({
      branch: new FormControl(data["branch"]),
      locationIds: new FormControl(data[""]),
      defaultLocationIds: new FormControl(data[''])
    });
  }
  RemoveRow(index, type) {
    var ValveTypes = new FormArray([]);
    if (type == 1) {
      ValveTypes = this.formmap.get("LocationMaps") as FormArray;
      this.LocationMasters.splice(index, 1);
    } else {
      ValveTypes = this.groupmap.get("GroupMaps") as FormArray;
      this.GroupMasters.splice(index, 1);
    }

    ValveTypes.removeAt(index);
  }

  getsavedgroups(uid = 0) {
    var id = this.form.get("userId").value;
    if (uid > 0) {
      this.Userservice.getSavedUsergRoups(uid).subscribe((res) => {
        this.savedGroups = res.response;
      });
    }
  }

  showHideReason(event) {
    if (event.currentTarget.checked) {
      this.showReason = false;
    } else {
      this.showReason = true;
    }
  }
  loadUsersList(ClinicId: any) {
    this.Userservice.GetUsers(ClinicId).subscribe((res) => {
      this.rowData = res.response;
    });
  }


  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }
  userFunctionality( ){
    let initialState={
      UserId: this.form.get("userId").value,

  }
    const modalRef = this.modalService.show(UserFunctionalityComponent, Object.assign({ class: "gray modal-lg" }, { initialState }))
    // modalRef.content.getitemEmitter.subscribe((res) => {

    //   if (res) {
    //     this.onSelectionChanged(this.GetEventObject)

    //   }
    // })

 
  }

  onSelectBranch(data: any[]){
    if(!data?.includes(this.form.get('defaultBranch').value?.toString())){
      this.form.get('defaultBranch').setValue('');
    }
    this.defaultBranchList = [];
    _.forEach(this.hospitalList, (clinic)=>{
      if(data?.includes(clinic.id.toString())){
        this.defaultBranchList.push(clinic);
      }
    })
  }

  getdefaultLocations(locationIds: any[], index: any): any[]{
    let selectedLocations: any[] = [];
    _.forEach(this.LocationMasters[index], (locaion)=>{
      if(locationIds?.includes(locaion.id.toString())){
        selectedLocations.push(locaion);
      }
    })
    return selectedLocations;
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }
}
