import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { defaultThrottleConfig } from 'rxjs/internal/operators/throttle';
import { catchError, retry } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { EMRPatientInfo } from '../../emr/interfaces/EMRPatientInfo';

import { Response } from '../../../_Models/Response';
import { CommunicationTypeList } from '../interfaces/CommunicationTypeList';
import { CompanyList } from '../interfaces/CompanyList';
import { ConsultRateList } from '../interfaces/ConsultRateList';
import { GenderList } from '../interfaces/GenderList';
import { KinRelationList } from '../interfaces/KinRelationList';
import { MaritalStatusList } from '../interfaces/MaritalStatusList';
import { PatientList } from '../interfaces/PatientList';
import { PatientRegistration } from '../interfaces/PatientRegistration';
import { ProfessionList } from '../interfaces/ProfessionList';
import { progressBarList } from '../interfaces/ProgressBarList';
import { RateGroupList } from '../interfaces/RateGroupList';
import { ReferenceList } from '../interfaces/ReferenceList';
import { RegisterNumberList } from '../interfaces/RegisterNumberList';
import { RegSchemeList } from '../interfaces/RegSchemeList';
import { ReligionList } from '../interfaces/ReligionList';
import { ReregisterResponse } from '../interfaces/ReregisterResponse';
import { SchemeRateList } from '../interfaces/SchemeRateList';
import { TitleList } from '../interfaces/TitlteList';
import { VisaTypeList } from '../interfaces/VisaTypeList';
import { ErrorHandleService } from './error-handle.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PatientService {

  baseUrl: any;
  constructor(
    private http: HttpClient,
    private configurationManager: ConfigurationManager,
    private errorHandle: ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }
  //Getting  patient RegNo
  GetRegNo() {
    var url = "api/MasterData/GetNumber/REG-NO";
    return this.http.post<Response<RegisterNumberList[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  //Getting  patient salutation
  GetTitle(branchId) {
    var url = "api/MasterData/GetCommonMasterItem";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "MasterFieldType": "Salutation",
        "Id": 0,
        "ShowAll": 0,
        "BranchId": branchId
      })
  }
  //Getting  patient kin relation
  GetKinRelation() {
    var url = "api/MasterData/GetKinRelation";
    return this.http.post<Response<KinRelationList[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  //Getting  patient gender
  GetGender() {
    var url = "api/MasterData/GetGender";
    return this.http.post<Response<GenderList[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  //Getting  patient marital status
  GetMaritalStatus(branchId) {
    var url = "api/MasterData/GetMaritalStatus";
    return this.http.post<Response<MaritalStatusList[]>>(`${this.baseUrl}${url}`,
      {
        "Id": 0,
        "ShowAll": 1,
        "BranchId": branchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetConfidentiality(ConfCodeId) {
    var url = "api/MasterData/GetConfidentialityCode";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "ConfCodeId": ConfCodeId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetConsentOption(ConsentOptionId) {
    var url = "api/MasterData/GetConsentOption";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "ConsentOptionId": ConsentOptionId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  //Getting  patient Comunication Type
  GetCommunicationType(branchId) {
    var url = "api/MasterData/GetCommunicationType";
    return this.http.post<Response<CommunicationTypeList[]>>(`${this.baseUrl}${url}`,
      {
        "Id": 0,
        "ShowAll": 1,
        "BranchId": branchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }
  //Getting  patient Visa Type
  GetVisaType(branchId) {

    var url = "api/MasterData/GetVisaType";
    return this.http.post<Response<VisaTypeList[]>>(`${this.baseUrl}${url}`,
      {
        "VisaTypeID": 0,
        "ShowAll": 1,
        "BranchId": branchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }
  //Getting reference doctor or recommandation
  GetReference(branchId) {
    var url = "api/MasterData/GetLeadAgent";
    return this.http.post<Response<ReferenceList[]>>(`${this.baseUrl}${url}`,
      {
        "LeadAgentId": 0,
        "ShowAll": 1,
        "BranchId": branchId
      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );

  }
  // Getting company details of patient/
  GetCompany(branchId) {

    var url = "api/MasterData/GetCommonMasterItem/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,

      {
        "MasterFieldType": "Company",
        "Id": 0,
        "ShowAll": 0,
        "BranchId": branchId
      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  GetProfession(branchId) {
    var url = "api/MasterData/GetProfession";

    return this.http.post<Response<ProfessionList[]>>(`${this.baseUrl}${url}`,
      {
        "ProfId": 0,
        "ShowAll": 0,
        "BranchId": branchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetReligion(branchId) {
    var url = "api/MasterData/GetReligion";
    return this.http.post<Response<ReligionList[]>>(`${this.baseUrl}${url}`,
      {
        "Id": 0,
        "ShowAll": 1,
        "BranchId": branchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetRegScheme(BranchId, ShowAll = 0) {
    var url = "api/MasterData/GetItemsByType";
    return this.http.post<Response<RegSchemeList[]>>(`${this.baseUrl}${url}`,
      {
        "GroupCode": "111",
        "BranchId": BranchId,
        "ShowAll": ShowAll
      })
  }
  GetRegSchemeByRateGroup(BranchId, RateGroupId) {
    var url = "api/MasterData/GetRegSchemeByRateGroup";
    return this.http.post<Response<RegSchemeList[]>>(`${this.baseUrl}${url}`,
      {
        "GroupCode": "111",
        "BranchId": BranchId,
        "RateGroupId": Number(RateGroupId)
      })
  }

  GetRateGroupId(Input) {
    var url = "api/MasterData/GetItemRateAmountById";
    return this.http.post<Response<RateGroupList[]>>(`${this.baseUrl}${url}`, Input)
  }
  GetRateGroup(branchId) {
    var url = "api/MasterData/GetRateGroup";
    return this.http.post<Response<RateGroupList[]>>(`${this.baseUrl}${url}`,
      {
        "RGroupId": 0,
        "ShowAll": 0,
        "BranchId": branchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetSchemeRate(itemId: Number, consultantId: Number, RGroupId: Number, emergency: Number) {
    var url = "api/TodaysPatient/GetConsultantItemSchemeRate";
    return this.http.post<Response<SchemeRateList[]>>(`${this.baseUrl}${url}`,
      {
        "ItemId": itemId,
        "ConsultantId": consultantId,
        "RGroupId": RGroupId,
        "Emergency": emergency
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetAmountOfScheme(itemId: Number, patientId) {
    var url = "api/TodaysPatient/GetRegSchmAmtOfPatient";
    return this.http.post<Response<ConsultRateList[]>>(`${this.baseUrl}${url}`,
      {
        "ItemId": itemId,
        "PatientId": patientId,
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetConsultRate(params:any) {
    var url = "api/TodaysPatient/GetConsultRate";
    return this.http.post<Response<ConsultRateList[]>>(`${this.baseUrl}${url}`,
      params).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  //Getting all patients detail
  GetAllPatient(HospitalId) {
    var url = "api/Registration/GetAllPatient/" + HospitalId;
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  GetPatient(patientId: Number) {
    var url = "api/TodaysPatient/GetPatient";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, { "PatientId": patientId }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  //search patient for filling data calling from api
  SearchPatient(form: any) {
    var url = "api/TodaysPatient/SearchPatient";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": 0,
        "Name": form.get("sName").value,
        "RegNo": form.get("sRegNo").value,
        "PIN": form.get("sPin").value,
        "Address": form.get("sAddress").value,
        "IdentityNo": form.get("sEmiratesNo").value,
        "PolicyNo": form.get("sPolicyNo").value,
        "Mobile": form.get("sMobile").value,
        "ResNo": form.get("sPhoneNo").value,
        "BranchId": form.get("BranchId").value,
        //"email":form.get("email").value,
        "Mode": ""

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  ReRegister(itemId: Number, rgroupId: Number, patientId: Number, loctionId: Number, ReregisterDate) {
    var url = "api/Registration/SaveReRegistration";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "RegDate": ReregisterDate,
        "PatientId": patientId,
        "ItemId": itemId,
        "RGroupId": rgroupId,
        "UserId": Number(localStorage.getItem('user_id')),
        "LocationId": loctionId,
        "SessionId": 0
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  BlockPatient(patientId: Number, blockReason: string) {//block  patient by patient id
    var url = "api/Registration/BlockPatient";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "PatientId": patientId,
        "BlockReason": blockReason,
        "UserId": Number(localStorage.getItem('user_id'))
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  UnBlockPatient(patientId: Number) {//unblock  patient by patient id
    var url = "api/Registration/UnblockPatient";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "PatientId": patientId,
        "UserId": Number(localStorage.getItem('user_id'))
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  //getting patient Documents
  GetPatientDocuments(patientId: Number) {
    var url = "api/Registration/ViewPatientFiles";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "PatientId": patientId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  SearchRegPatient(form: any, ConsultantId) {

    var url = "api/Registration/SearchPatientInList";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "BranchId": Number(form.get("sBranch").value),
        "ConsultantId": Number(form.get("sconsultant").value),
        "Name": form.get("sName").value.trim(),
        "RegNo": form.get("sRegNo").value.trim(),
        "Address": form.get("sAddress").value.trim(),
        "IdentityNo": form.get("sEmiratesNo").value.trim(),
        "PolicyNo": "",
        "Mobile": form.get("sMobile").value,
        "ResNo": form.get("sResNo").value,
        "Mode": "",
        "RegDateFrom": form.get("sDateFrom").value,
        "RegDateTo": form.get("sDateTo").value,
        "Phone": form.get("sPhone").value,
        "PIN": form.get("sPin").value,

      })
  }


  SaveRegistration(formData) {
    var url = "api/Registration/InsertPatientRegistration";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, formData)
  }


  //save patient details calling from api
  SaveRegistrationOld(userForm: any, enableNewCons: any, emerFees: any, PatId: any, PatientDocs: File[]) {
    var url = "api/TodaysPatient/InsertPatientRegistration";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "PatientId": PatId,
        "RegNo": userForm.get("regNo").value,
        "RegDate": new Date(),
        "Salutation": userForm.get("title").value,
        "FirstName": userForm.get("firstName").value,
        "MiddleName": userForm.get("middleName").value,
        "LastName": userForm.get("lastName").value,
        "DOB": userForm.get("dob").value, //"1993-10-09 00: 00: 00",
        "Gender": userForm.get("gender").value,
        "MaritalStatus": userForm.get("maritalStatus").value,
        "KinName": userForm.get("kinName").value ? userForm.get("kinName").value : "",
        "KinRelation": userForm.get("kinRelation").value ? userForm.get("kinRelation").value : "",
        "KinContactNo": userForm.get("kinContact").value ? userForm.get("kinContact").value : "",
        "Mobile": String(userForm.get("mobile").value ? userForm.get("mobile").value : ""),
        "ResNo": String(userForm.get("resNo").value ? userForm.get("resNo").value : ""),
        "OffNo": String(userForm.get("offNo").value ? userForm.get("offNo").value : ""),
        "Email": userForm.get("email").value ? userForm.get("email").value : "",
        "FaxNo": userForm.get("fax").value ? userForm.get("fax").value : "",
        "Religion": userForm.get("religion").value ? userForm.get("religion").value : "",
        "Status": 0,
        "PatState": 0,
        "RGroupId": Number(userForm.get("rateGroup").value),
        "Mode": "R",
        "Remarks": userForm.get("remarks").value ? userForm.get("remarks").value : "",
        "NationalityId": userForm.get("remarks").value ? Number(userForm.get("remarks").value) : 0,
        "ConsultantId": Number(userForm.get("consultantId").value),
        "Active": 1,
        "RefBy": userForm.get("referedBy").value ? userForm.get("referedBy").value : "",
        "PrivilegeCard": false,
        "UserId": Number(localStorage.getItem('user_id')),
        "LocationId": 1,
        "WorkEnvironment": userForm.get("workEnvironment").value ? userForm.get("workEnvironment").value : "",
        "ProfessionalNoxious": userForm.get("professionalNoxious").value ? userForm.get("professionalNoxious").value : "",
        "ProfessionalExperience": userForm.get("workExperience").value ? userForm.get("workExperience").value : "",
        "RegAddress": [
          {
            "AddType": 0,
            "Address1": userForm.get("address1").value ? userForm.get("address1").value : "",
            "Address2": userForm.get("address2").value ? userForm.get("address2").value : "",
            "Street": "",// userForm.get("street1").value,
            "PlacePO": userForm.get("place1").value ? userForm.get("place1").value : "",
            "PIN": userForm.get("pin1").value ? userForm.get("pin1").value : "",
            "City": userForm.get("city1").value ? userForm.get("city1").value : "",
            "State": userForm.get("state1").value ? userForm.get("state1").value : "",
            "CountryId": Number(userForm.get("country1").value) ? Number(userForm.get("country1").value) : 0
          },
          {
            "AddType": 1,
            "Address1": userForm.get("address3").value ? userForm.get("address3").value : "",
            "Address2": userForm.get("address4").value ? userForm.get("address4").value : "",
            "Street": userForm.get("street2").value ? userForm.get("street2").value : "",
            "PlacePO": userForm.get("place2").value ? userForm.get("place2").value : "",
            "PIN": userForm.get("pin2").value ? userForm.get("pin2").value : "",
            "City": userForm.get("city2").value ? userForm.get("city2").value : "",
            "State": userForm.get("state2").value ? userForm.get("state2").value : "",
            "CountryId": Number(userForm.get("country2").value) ? Number(userForm.get("country1").value) : 0
          },
          {
            "AddType": 2,
            "Address1": userForm.get("address5").value ? userForm.get("address5").value : "",
            "Address2": userForm.get("address6").value ? userForm.get("address6").value : "",
            "Street": userForm.get("street3").value ? userForm.get("street3").value : "",
            "PlacePO": userForm.get("place3").value ? userForm.get("place3").value : "",
            "PIN": userForm.get("pin3").value ? userForm.get("pin3").value : "",
            "City": userForm.get("city3").value ? userForm.get("city3").value : "",
            "State": userForm.get("state3").value ? userForm.get("state3").value : "",
            "CountryId": userForm.get("country3").value ? Number(userForm.get("country1").value) : 0
          }
        ],
        "RegIdentities": [
          {
            "IdentityType": 0,
            "IdentityNo": userForm.get("pinOrvid").value ? userForm.get("pinOrvid").value : "",
            "PatientId": 0
          },
          {
            "IdentityType": 1,
            "IdentityNo": userForm.get("passportNo").value ? userForm.get("passportNo").value : "",
            "PatientId": 0
          },
          {
            "IdentityType": 2,
            "IdentityNo": userForm.get("drivingLicNo").value ? userForm.get("drivingLicNo").value : "",
            "PatientId": 0
          },
          {
            "IdentityType": 3,
            "IdentityNo": userForm.get("healthCardId").value ? userForm.get("healthCardId").value : "",
            "PatientId": 0
          },
          {
            "IdentityType": 4,
            "IdentityNo": userForm.get("labourCardNo").value ? userForm.get("labourCardNo").value : "",
            "PatientId": 0
          },
          {
            "IdentityType": 5,
            "IdentityNo": userForm.get("visaOrpermitNo").value ? userForm.get("visaOrpermitNo").value : "",
            "PatientId": 0
          },
          {
            "IdentityType": 6,
            "IdentityNo": userForm.get("nationalId").value ? userForm.get("nationalId").value : "",
            "PatientId": 0
          }
        ],
        "ItemId": Number(userForm.get("scheme").value), //Number(userForm.get("scheme").value),
        "RegAmount": 30,
        "ExpiryDate": "2021-10-20 00:36:00",
        "RetVal": 0,
        "RetDesc": "",
        "RetRegNo": "",
        "Consultation": {
          "EnableConsultation": enableNewCons,
          "ConsultationId": null,
          "AppId": 0,
          "ConsultDate": new Date(),
          "ConsultantId": Number(userForm.get("consultantId").value),
          "PatientId": 0,
          "Symptoms": userForm.get("reasonForVisit").value,
          "ConsultFee": Number(userForm.get("consultFee").value),
          "ItemId": Number(userForm.get("scheme2").value),
          "ConsultType": 1,
          "Emergency": 1,
          "EmerFee": 100,
          "LocationId": 1,
          "InitiateCall": 1,
          "AgentId": null,
          "LeadAgentId": null,
          "UserId": Number(localStorage.getItem('user_id')),
          "SessionId": null,
          "BranchId": null,
          "RetVal": 0,
          "RetDesc": "",
          "RetSeqNo": 0,
          "PatientPhoto": ""
        }
      })
  }

  GetPatientDetailsbyId(patientId: Number, consultaionId: Number = 0) {
    var url = "api/Registration/GetRegisteredDataById";
    return this.http.post<Response<PatientList[]>>(`${this.baseUrl}${url}`,
      {
        "PatientId": patientId?patientId:0,
        "ConsultationId": consultaionId?consultaionId:0
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  //To display progressbar monitor
  GetProgressBar(consultDate: any, BranchId) {
    var url = "api/TodaysPatient/GetFrontOfficeProgressBars";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultDate": consultDate,
        "BranchId": Number(BranchId)
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  // delete patient documents
  DeleteUploadedDoc(docId: Number) {
    var url = "api/Registration/DeletePatRegFiles";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "Id": docId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }


  SearchPatientByRegNo(regNo: string) {
    // api/TodaysPatient/GetPatientByRegNo
    var url = "api/TodaysPatient/GetPatientByRegNo";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "RegNo": regNo
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  ConsentHistoryModal(inputDetails) {

    var url = "api/MasterData/GetConsentPreviewContent";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputDetails).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

  ConsentFormDataSave(formData) {

    var url = "api/MasterData/ConsentFormDataSave";
    return this.http.post<Response<progressBarList[]>>(`${this.baseUrl}${url}`, formData
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );

  }

  //getting patient Documents
  GetPatientDocumentsFiles(formdata) {
    var url = "api/Registration/UploadPatientDocuments";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, formdata
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

  GetPatientFiles(formdata) {
    var url = "api/Registration/UploadPatientDocumentsbyId";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, formdata
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

  GetSponsors(BranchId) {
    var url = "api/MasterData/GetSponsor";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {

      "SponsorId": 0,
      "ShowAll": 1,
      "BranchId": BranchId


    });
  }


  printSticker(payload: any): Observable<Response<any>>{
    var url = "api/Report/PrintSticker";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  printRegistrationCard(payload: any): Observable<Response<any>>{
    var url = "api/Report/PrintRegistrationCard";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  getApplicationSettings(payload: any): Observable<Response<any>>{
    var url = "api/MasterData/GetApplicationSettings";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  getDocumentType(payload: any): Observable<Response<any>>{
    var url = "api/MasterData/GetDocumentType";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  SendDetailstoNabith(payload: any) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    var url = "api/Nabith/SendDetailstoNabith";
    return this.http.post(`${this.baseUrl}${url}`, payload, { headers, responseType: 'text'})
  }

  CheckPatRegistration(payload) {
    var url = "api/Registration/CheckPatRegistration";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload)
  }

  GetServiceItemsByConsultationId(payload){
    var url = "api/MasterData/GetServiceItemsByConsultationId"
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload)
  }
  

}
