import { Component, OnInit, TemplateRef, ViewChild ,Input, ViewChildren, QueryList, ElementRef, OnDestroy} from '@angular/core';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from '../../emr/services/emr.service';
import { ToastrService } from "ngx-toastr";
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs/internal/Subscription';

import { ColDef, GridOptions } from "ag-grid-community";
import { EMRVitalSign, EMRVitalSignForm } from '../interfaces/EMRVitalSign';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import { TreatmentsService } from '../../treatment/services/treatments.service';
import { ConfigurationService } from '../../client/services/configuration.service';
import _ from 'lodash';
import { SettingsService } from '../../settings/services/settings.service';
import { PatientService } from '../../client/services/patient.service';

@Component({
  selector: 'app-general-vital-sign',
  templateUrl: './general-vital-sign.component.html',
  styleUrls: ['./general-vital-sign.component.scss']
})
export class GeneralVitalSignComponent implements OnInit, OnDestroy {
  @Input() IsViewtab;
  @Input() functionPermissionData
  EMRPatientInfo:EMRPatientInfo;
  form: FormGroup; // form variable
  EMRVitalSignForm:EMRVitalSignForm;
  Loading:boolean=false;

  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;
  public context;
  public gridApi;
  public statusBar;
  public submitted: boolean = false;
  // grid variables
  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];
  vitalSignList:EMRVitalSign[]=[];
  rowGroupPanelShow = "always";
  public gridOptions = {
    pagination: true,
    paginationPageSize: 50,
    onCellKeyDown: function(event) {
      console.log(event.newValue);
    },
    rowClassRules: {
      "hidden-row": function (params) {
        return params.data.vitalSignName === 'PainScale';
      },}
  };
  public loadingTemplate;
  public noRowsTemplate;
  isLoading=false;

  isFirstOpen = true;
  isPrevious:boolean=false;

  TodaysvitalSignList:any[]=[];
  PrevVitalSigns:any[]=[];
  nursingStationValue:any;
  isFormChanged:boolean=false;
  IsDisabled: boolean = true;
  modalRef?: BsModalRef;
  Staffs:any;
  NurseData:any;
  staffName:string = '';
  @ViewChild("template") modaltemp: any;

  bp1:boolean;
  bp2:boolean;
  vitalsignData: any;
  @ViewChildren('since') since:  QueryList<ElementRef>;
  VitalSignsMOdalVal:any[]=[];
  painVal: any=1;
  HealthInformationPlatform: any;
  vitalSignListCopy: EMRVitalSign[];
  constructor(
    private EMRService:EMRService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private TreatmentsService: TreatmentsService,
    private modalService: BsModalService,
    private datepipe: DatePipe,private ConfigurationService: ConfigurationService,
    private settingsService:SettingsService,
    private patientService:PatientService
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });


    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();

    this.form= new FormGroup({
      eid: new FormControl(0),
      vitalSignDataList: new FormArray([]),
      createdTime: new FormControl(''),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
      ConsultationId: new FormControl(this.EMRPatientInfo.ConsultationId),
      PerformedStaffId: new FormControl(this.EMRPatientInfo.UserId),
    });
    this.GetAllVitalSign();
    this.GetPerformingStaff();
    this.GetEMRVitalSign();
    this.GetTodaysvitalSign();
    this.GetNursingStationVitalSign()
    this.GetPrevVitalSigns();
    this.getAppSettings()
  }

  ngOnInit(): void {

    if (this.IsViewtab == true) {
      this.IsDisabled = false;
    } else if (this.IsViewtab == "undefined") {
      this.IsDisabled = true;
    }
    this.context = { componentParent: this };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    this.columnDefs = [
      {
        headerName: "Vital Sign",
        field: "vitalSignName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Uom",
        field: "uom",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Vital Sign Value",
        field: "vitalSignValue",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true

      },
    ];


  }

  onCellValueChanged(event){
    if(!this.isFormChanged){
        if(event.value)
        {
          this.isFormChanged=true;
        }
    }

    if (event.data.reservedId == 5) {
      let weightValue = Number(event.data.vitalSignValue);
      if (typeof weightValue === 'number' && !isNaN(weightValue) && weightValue !== 0 && weightValue !== null) {
        this.gridApi.forEachNode((node) => {
          let vitalValue = Number(node.data.vitalSignValue);
          if (node.data.reservedId == 6 && (typeof vitalValue === 'number' && !isNaN(vitalValue) && vitalValue !== 0 && vitalValue !== null)) {
            this.calculateBMI();
          }
        });
      }
    } else if (event.data.reservedId == 6) {
      let heightValue = Number(event.data.vitalSignValue);
      if (typeof heightValue === 'number' && !isNaN(heightValue) && heightValue !== 0 && heightValue !== null) {
        this.gridApi.forEachNode((node) => {
          let vitalValue = Number(node.data.vitalSignValue);
          if (node.data.reservedId == 5 && (typeof vitalValue === 'number' && !isNaN(vitalValue) && vitalValue !== 0 && vitalValue !== null)) {
            this.calculateBMI();
          }
        });
      }
    }
  }

  calculateBMI(){
    let height = 0;
    let weight = 0;

    this.gridApi.forEachNode((node) => {
      if(node.data.reservedId == 6){
        height = Number(node.data.vitalSignValue);
      }
      if(node.data.reservedId == 5){
        weight = Number(node.data.vitalSignValue);
      }
    })

    let meterHeight = height/100;
    let bmi = (weight / (meterHeight * meterHeight));

    this.gridApi.forEachNode((node) => {
      if(node.data.reservedId == 8){
        node.data.vitalSignValue = bmi.toFixed(2);
      }
    })
    this.gridApi.refreshCells();
  }

  SaveVitalSign()
  {
    this.gridApi.stopEditing(); // for grid auto focus out and chnaging cell value

    if( this. EMRPatientInfo.CrudType!=3 && this.EMRPatientInfo.VisitId>0 && this.Loading )
    {
      this.EMRVitalSignForm=this.form.getRawValue();
      this.vitalSignList=this.vitalSignList.map((sign)=>{
        if(sign.vitalSignName=='PainScale'){
          return{
            ...sign,
            vitalSignValue:String(this.painVal)
          }
        }
        else{
          return sign
        }
      });
      this.EMRVitalSignForm.vitalSignDataList=this.vitalSignList
      let ctime=new Date();
      this.EMRVitalSignForm.CreatedTime = this.datepipe.transform(ctime, 'dd-MM-yyyy HH:mm');
      this.EMRVitalSignForm.userId=Number(localStorage.getItem('user_id'));
      this.EMRVitalSignForm.IsFromEMR=1;

      let bp1;
      let bp2;
      // let vitalVal1 = [];
      // let vitalVal2 = [];
      let save=true
      if(this.vitalSignList?.length){
        this.vitalsignData.forEach((val:any)=>{
          if(val.mandatory === 1 || val.inValidValidation){
            // vitalVal1.push(val)
            this.vitalSignList.forEach((res:any)=>{
              if(res.vitalSignName === val.signName){
                if(res.vitalSignValue == "" && val.mandatory === 1){
                  this.toastr.error(`Please add ${res.vitalSignName}`)
                  this.isLoading=false;
                  save=false
                } 
                if(val.inValidValidation && res.vitalSignValue != ""){
                  const enteredValue = parseFloat(res.vitalSignValue)
                  if (isNaN(enteredValue) || enteredValue < val.minValidationValue || enteredValue > val.maxValidationValue) {
                    // Show a Toastr message
                    this.toastr.error(res.vitalSignName + ' value is outside the allowed range ' + val.minValidationValue +'-'+val.maxValidationValue);
                    this.isLoading=false;
                    save=false
                    // Clear the value or handle accordingly
                   
                  }
                }
              }
            })
          }
        })
        this.vitalSignList.forEach((res:any)=>{
          if(res.vitalSignName == 'BP Systolic'){
            if(res.vitalSignValue != "" ){
              bp1 = true;
            } else {
              bp1 = false;
            }
          }
          if(res.vitalSignName == 'BP Diastolic'){
            if(res.vitalSignValue != "" ){
              bp2 = true;
            } else {
              bp2 = false;
            }
          }
        })
      }
      if(bp1 && !bp2){
        this.toastr.error('Please add BP Diastolic')
        this.  isLoading=false;
        setTimeout(()=>{
          this.since.last.nativeElement.focus()
        },300)
      } else if(!bp1 && bp2){
        this.toastr.error('Please add BP Systolic')
        this.  isLoading=false;
        setTimeout(()=>{
          this.since.last.nativeElement.focus()
        },300)
      } else {
        if(save) {
          // let date = this.datepipe.transform(new Date(), 'MM-dd-yyyy HH:mm');
          // date = date.concat(':00')

          // if(this.TodaysvitalSignList?.length){
          //   let date1 = this.datepipe.transform(this.TodaysvitalSignList[0]?.createdDate, 'dd-MM-yyyy HH:mm:ss');
          //   let val= this.TodaysvitalSignList[0]?.createdDate;
          //   let dateObj = new Date(date1)
          //   let timestamp= dateObj.getTime()
          //   let dateObj1 = new Date(date)
          //   let timestamp1= dateObj1.getTime()
          //   if(date !== date1){
          //     this.EMRService.SaveEMRVitalSigns(this.EMRVitalSignForm).subscribe(
          //       (res) => {
          //         if (res.status == 200 && res.message == "Success") {
          //           this.  isLoading=false;
        
          //           this.toastr.success("Success: " + "Vital Sign details Saved Successfully...");
          //           this.GetTodaysvitalSign();
          //           this.GetEMRVitalSign();
                    
          //         }
          //         else{
          //           this.  isLoading=false;
        
          //           Swal.fire(
          //             'Error!',
          //             'Some unexpected error occures! try again later.',
          //             'error'
          //           )
          //         }
          //       });
          //   } else {
          //     this.  isLoading=false;
          //   }
          // } else {
            const areEqualvitalSignList=_.isEqual(this.vitalSignList,this.vitalSignListCopy)
            if(areEqualvitalSignList) return
            this.isLoading=true;
            this.EMRService.SaveEMRVitalSigns(this.EMRVitalSignForm).subscribe(
              (res) => {
                if (res.status == 200 && res.message == "Success") {
                  this.isLoading=false;
                  // this.sendDataToNabid()
                  this.toastr.success("Success: " + "Vital Sign details Saved Successfully...");
                  this.GetTodaysvitalSign();
                  this.GetEMRVitalSign();
                }
                else{
                  this.  isLoading=false;
      
                  Swal.fire(
                    'Error!',
                    'Some unexpected error occures! try again later.',
                    'error'
                  )
                }
              });
          // }
        }
      }
    }
    else{
      //this.toastr.warning("Info: " + "No changes detected in the page");
    }
  }

  GetEMRVitalSign(vistId=this.EMRPatientInfo.VisitId)
  {

    let input={
      "VisitId":vistId,
      "BranchId":this.BranchesId
  };
  debugger
    this.EMRService.GetEMRVitalSign(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.vitalSignList=res.response;
          this.getStaffName()
          if(this.vitalSignList?.length){
            this.vitalSignList?.forEach((res:any)=>{
              if(res.vitalSignName == 'BP Systolic'){
                if(res.vitalSignValue != "" ){
                  this.bp1 = true;
                } else {
                  this.bp1 = false;
                }
              }
              if(res.vitalSignName == 'BP Diastolic'){
                if(res.vitalSignValue != "" ){
                  this.bp2 = true;
                } else {
                  this.bp2 = false;
                }
              }

              if(res.vitalSignName == 'PainScale'){
                this.changeStyle(res.vitalSignValue)
              }
            })
            if(this.bp1 && !this.bp2){
              this.toastr.error('Please add BP Diastolic')
              setTimeout(()=>{
                this.since.last.nativeElement.focus()
              },300)
            } else if(!this.bp1 && this.bp2){
              this.toastr.error('Please add BP Systolic')
              setTimeout(()=>{
                this.since.last.nativeElement.focus()
              },300)
            }
          }

          if(this.vitalSignList){
            let eid=this.vitalSignList[0].eid;
            if(eid>0){
              this.form.controls['eid'].setValue(this.vitalSignList[0].eid);
              this.isPrevious=true;
            }

            }
            this.vitalSignListCopy=_.cloneDeep(this.vitalSignList)
        }
      });


      this.Loading=true;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }


  onGridReady(params) {
    this.gridApi = params.api;
  }

  GetTodaysvitalSign()
  {
    let input={
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      IsFromEMR: 1,
    }
    this.EMRService.GetAllEMRVitalSignByVisitId(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.TodaysvitalSignList=res.response;
        }
      });
  }
  GetNursingStationVitalSign()
  {
    let input={
      "VisitId":0,
      "patientId":this.EMRPatientInfo.PatientId,
      "consultationId":this.EMRPatientInfo.ConsultationId,
      "isFromEMR":0
    };
    this.EMRService.GetAllEMRVitalSignByVisitId(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.nursingStationValue=res.response;
        }
      });
  }

  AddNew()
  {
    this.isFormChanged=false;
    this.form.controls['eid'].setValue(0);
    this.GetEMRVitalSign(0);
  }

  GetPrevVitalSigns()
  {
    let input={
      // "VisitId":this.EMRPatientInfo.VisitId,
      "VisitId":0,
      "PatientId":this.EMRPatientInfo.PatientId,
      // "ShowAll":0
      "ShowAll":1
    };
    this.EMRService.GetPrevVitalSigns(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.PrevVitalSigns=res.response;
        }
      });
  }
  GetPerformingStaff() {
    let Input = {
      "StaffId": 0,
      "ShowAll": 0,
      "BranchId": this.globals.HospitalId,
    }

    this.TreatmentsService.GetPerformingStaff(Input).subscribe((res) => {
      this.Staffs = res.response;
      this.NurseData = this.Staffs.filter(x => x.categoryId === 4);

    });
  }
  getStaffName(){
    this.vitalSignList.map((res:any)=>{
      if(res.performedStaffId !== 0){
        if(this.EMRPatientInfo.UserId === res.performedStaffId){
          this.staffName = localStorage.getItem('username')
        } else {
          this.Staffs.map((val:any)=>{
            if(val.staffId === res.performedStaffId){
              this.staffName =  val.name;
            } 
          })
        }
      }
    })
    // console.log(this.staffName)
  }
  openModal(VisitId, consultationId)
  {
    let input={
      "VisitId":VisitId,
      "ConsultationId": consultationId
    };
    this.EMRService.GetAllEMRVitalSignByVisitId(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.VitalSignsMOdalVal=res.response;
          this.modalRef = this.modalService.show(
            this.modaltemp,
            {class: 'modal-lg modal-custom-style' }
          );
        }
      });

  }

  getBMI(){
    let height = 0;
    let weight = 0;

    this.gridApi.forEachNode((node) => {
      if(node.data.reservedId == 6){
        height = Number(node.data.vitalSignValue);
      }
      if(node.data.reservedId == 5){
        weight = Number(node.data.vitalSignValue);
      }
    })

    if(height == 0 || height == null || height == undefined){
      this.toastr.warning("Please enter height");
      return
    }

    if(weight == 0 || weight == null || weight == undefined){
      this.toastr.warning("Please enter weight");
      return
    }

    let meterHeight = height/100;
    let bmi = (weight / (meterHeight * meterHeight));

    this.gridApi.forEachNode((node) => {
      if(node.data.reservedId == 8){
        node.data.vitalSignValue = bmi.toFixed(2);
      }
    })
    this.gridApi.refreshCells();
  }

    // get vital sign mandatory
    GetAllVitalSign() {
      this.ConfigurationService.GetVitalSign(this.globals.HospitalId).subscribe((res) => {
        this.vitalsignData = res.response;
      });
    }

    changeStyle(id, isInit = false) {
      this.painVal = id
     
      for (let i = 1; i <= id; i++) {
        var element = document.getElementById(i.toString()+'pain');
        element.classList.add("pain-scale__level--active");
      }
      for (let i = id + 1; i <= 10; i++) {
        var element = document.getElementById(i.toString()+'pain');
        element.classList.remove("pain-scale__level--active");
      }
      if(!isInit){
        // this.FormValueChangeChecker = true;
      }
    }

    changeStylePrevious(id,entry) {
    
      for (let i = 1; i <= id; i++) {
        debugger
        var element = document.getElementById(`${i}pain${entry}`);
        element.classList.add("pain-scale__level--active");
      }
      for (let i = id + 1; i <= 10; i++) {
        var element = document.getElementById(`${i}pain${entry}`);
        element.classList.remove("pain-scale__level--active");
      }  
    }

    openAccordian(entry){
      debugger
      let painScale=entry.vitalSignData.find(sign => sign.vitalSignName === 'PainScale')
      if(painScale){
        this.changeStylePrevious(Number(painScale.vitalSignValue),entry.eid)
      }
    }

    getPain(entry){
      let painScale=entry.vitalSignData.find(sign => sign.vitalSignName === 'PainScale')
      if(painScale){
        return Number(painScale.vitalSignValue)
      }else{
        return 1
      }
    }

    getAppSettings(){
      let  payload = {
        UserId: Number(localStorage.getItem('user_id')),
        LocationId: this.globals?.Location?.locationId,
        BranchId:this.globals.HospitalId
      }
    
      this.settingsService.getApplicationSettings(payload).subscribe({
        next: (response: any)=>{
          if(response.status == 200){
            let val = response.response
             this.HealthInformationPlatform=_.find(response.response,{displayName:"HealthInformationPlatform"})
        }
      }
      })
    }
  
  
    sendDataToNabid() {
      const currentDate = this.datepipe.transform(new Date(), "dd-MM-yyyy");
      const nbPayload = {
        EventId: 1,
        Consultationid: this.EMRPatientInfo.ConsultationId,
        EntryDate: currentDate,
        PatientId: this.EMRPatientInfo.PatientId,
        BranchId: this.globals.HospitalId,
      };
      const Permission = this.functionPermissionData.find((x) => x.id === 560);
      // Check if the platform is 'Nabidh' and permissions are allowed
      if (this.HealthInformationPlatform.settValue === "Nabidh" ) {
        if (Permission?.isAllowed ) {
           this.sendToNabidh(nbPayload)
        } else {
          this.toastr.warning("No permission to send data to nabith");
        }
      }
    }
  
    private sendToNabidh(payload: any) {
      this.patientService.SendDetailstoNabith(payload).subscribe({
        next: (res: any) => {
          this.toastr.info("Response from Nabidh: " + res);
        },
      });
    }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }
  

}
