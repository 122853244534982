import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPermissionRoutingModule } from './user-permission-routing.module';
import { SharedModule } from '../../shared/models/shared.module';
import { LocationTypesComponent } from './location-types/location-types.component';
import { LocationComponent } from './location/location.component';
import { UserGroupMasterComponent } from './user-group-master/user-group-master.component';
import { AgGridModule } from 'ag-grid-angular';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { UserComponent } from './user/user.component';
import { MenuComponent } from './menu/menu.component';
import { SubmenuComponent } from './submenu/submenu.component';
import { UserGroupMenumapComponent } from './user-group-menumap/user-group-menumap.component';
import { TreeviewModule } from 'ngx-treeview';
import { UserFunctionalityComponent } from './user/user-functionality/user-functionality.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';



@NgModule({
  declarations: [
    LocationTypesComponent,
    LocationComponent,
    UserGroupMasterComponent,
    UserGroupsComponent,
    UserComponent,
    MenuComponent,
    SubmenuComponent,
    UserGroupMenumapComponent,
    UserFunctionalityComponent
  ],
  imports: [
    CommonModule,
    UserPermissionRoutingModule,
    SharedModule,
    TreeviewModule.forRoot(),
    AccordionModule.forRoot(),
    AgGridModule.withComponents(),

  ]
})
export class UserPermissionModule { }
