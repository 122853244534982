import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../../../_Models/Response';
import { ClaimActionComponent } from '../../claim-action/claim-action.component';
import { ClaimService } from '../../services/claim.service';

@Component({
  selector: 'app-change-claim-details-modal',
  templateUrl: './change-claim-details-modal.component.html',
  styleUrls: ['./change-claim-details-modal.component.scss']
})
export class ChangeClaimDetailsModalComponent implements OnInit {

  @Input() transId: any = 0;
  @Input() branchId: any = 0;
  @Input() orderId: any = 0;
  @Input() allStaffList: any[] = [];
  
  @Output() detailsChangeEmitter = new EventEmitter();
  

  private gridApi: GridApi;
  public columnDefs: ColDef[] = [];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 20,
  }
  public context;
  public frameworkComponents = { buttonRender: ClaimActionComponent};;
  public loadingTemplate;
  public noRowsTemplate;

  public transClaimItems: any[] = [];

  public saveClaimDetailsLoading: boolean = false;

  constructor(private modalRef: BsModalRef,
              private claimService: ClaimService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {

    this.columnDefs = [
      {
        headerName: "Item Name",
        field: "itemName",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 4,
      },
      {
        headerName: "Performing Staff",
        field: "",
        resizable: true,
        flex: 3,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "changeClaimDetails", PageField: "staff", StaffList: this.allStaffList },
      },
      {
        headerName: "From",
        field: "startDate",
        
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "changeClaimDetails", PageField: "startDate" },
      },
      {
        headerName: "To",
        field: "endDate",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "changeClaimDetails", PageField: "endDate" },
      },
      {
        headerName: "Tooth No",
        field: "toothNo",
        resizable: true,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "changeClaimDetails", PageField: "tooth" },
      },
    ]

    this.context = { componentParent: this };

  }

  onGridReady(params){
    this.gridApi = params.api;
    this.getTransClaimDetails();
  }

  getTransClaimDetails(){
    let payload = {
      Transid: this.transId,
      Branchid: this.branchId,
      Orderid: this.orderId 
    }

    this.gridApi.showLoadingOverlay();
    this.claimService.getTransactionClaimDetails(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.transClaimItems = response.response;
        }else{
          this.toastrService.error(response.message);
        }
        this.gridApi.hideOverlay();
      },error: (error: any)=>{
        this.toastrService.error('Failed to load transaction details, Try agian');
        this.gridApi.hideOverlay();
      }
    })
  }

  onChangeClaimDetails(){
    let payload = {
      ClaimItemList: []
    }

    this.transClaimItems.forEach(item=>{
      let payloadItem = {
        OrderDetId: Number(item.orderDetId),
        PerStaffId: Number(item.perStaffId),
        StartDate: item.startDate,
        EndDate: item.endDate,
        Remarks: item.remarks,
        ToothNo: item.toothNo,
        PerLocation: item.perLocation,
        UnlistedCodeValue: item.unlistedCodeValue,
        UserId: Number(localStorage.getItem("user_id")),
        SessionId: 0,
        IcdLabelId: Number(item.icdLabelId),
        EntryId: 0,
        cptmodifierList : item.cptmodifierList ? item.cptmodifierList : []
      }

      payload.ClaimItemList.push(payloadItem)
    })
    
    this.saveClaimDetailsLoading = true;
    this.claimService.updateTransactionClaimDetails(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == 'Success'){
          this.toastrService.success('Claim details changed successfully.');
          this.close();
        }else{
          this.toastrService.error(response.message);
        }
        this.saveClaimDetailsLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Failed to change claim details, Try again..!');
        this.saveClaimDetailsLoading = false;
      }
    })
  }

  close(){
    this.modalRef.hide();
  }

}
