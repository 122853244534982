import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Consultants } from "../../interfaces/Consultants";
import { Departments } from "../../interfaces/Departments";
import { PatientList } from "../../interfaces/PatientList";
import { RecentConsultationList } from "../../interfaces/RecentConsultationList";
import { SponsorList } from "../../interfaces/SponsorList";
import { ConsultaionsService } from "../../services/consultations.service";
import { HospitalsService } from "../../services/hospitals.service";
import { PatientService } from "../../services/patient.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { HttpClient } from "@angular/common/http";
import { SchemeList } from "../../interfaces/SchemeList";
import { SymptomList } from "../../interfaces/SymptomList";
import { DatePipe } from "@angular/common";
import { ReferenceList } from "../../interfaces/ReferenceList";
import { RegisterNumberList } from "../../interfaces/RegisterNumberList";
import { RegSchemeList } from "../../interfaces/RegSchemeList";
import { SchemeRateList } from "../../interfaces/SchemeRateList";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ConsultRateList } from "../../interfaces/ConsultRateList";
import { FormValidationService } from "../../services/form-validation.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonValidationComponent } from '../../../../shared/common-validation/common-validation.component';
import { ElementRef, QueryList, ViewChild, ViewChildren, } from "@angular/core";
import { ColDef, GridApi, GridOptions } from "ag-grid-community";
import { SharedService } from '../../../../shared/shared.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { AppointmentsService } from "../../services/appointments.service";
import { AppointmentsList } from "../../interfaces/AppointmentsList";
import { SearchPatientModalComponent } from '../../frontoffice/searchpatient-modal/searchpatient-modal.component';
import { Location } from '@angular/common';
import { Select2OptionData } from 'ng-select2';
import { RateGroupList } from '../../interfaces/RateGroupList';
import { Response } from "../../../../_Models/Response";
import { ConfigurationService } from "../../services/configuration.service";
import { EMRService } from "../../../emr/services/emr.service";
import { GeneralActionComponent } from "../../general-action/general-action.component";
import { filter } from "rxjs/operators";
import _ from "lodash";
import { Settings } from "../../../settings/model/settings.model";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { NabithConsentComponent } from "../addregistration/nabith-consent/nabith-consent.component";
import { TelerikPrintComponent } from "../../../../shared/modals/telerik-print/telerik-print.component";
import { RiayatiConsentComponent } from "../addregistration/riayati-consent/riayati-consent.component";

@Component({
  selector: "app-AddRegistrationComponent",
  templateUrl: "./addconsultation.component.html",
  styleUrls: ["./addconsultation.component.scss"],
})
export class AddConsultationComponent implements OnInit {
  @ViewChild("barcodeprint") barCodePrinting: ElementRef;
  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;
  opened = false;
  TokenpPint: boolean;
  TokenPrinter: boolean;
  displayBasic: boolean;
  preregisterclick: boolean;
  products: any = {};
  departments: any;
  consultants: Consultants[] = [];
  patients: PatientList[] = [];
  form: FormGroup;
  resubmittedform: FormGroup;
  result: any;
  registerResult: any;
  schemeList: any[] = [];
  patientId: number;
  radioSelected: number;
  enableRadionBtn = false;
  AppointmentDataDetails: AppointmentsList[] = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  public submitted: boolean = false;
  public resubmitted: boolean = false;
  regSchemeList: RegSchemeList[] = [];
  OtherSymptom: any;
  dropDownForm: FormGroup;
  posts = [];
  dtOptions: any = {};
  info: string;

  currentDate = new Date();
  patientRegNo: any;
  searchedPatient: any;
  sponsorList: SponsorList[] = [];
  tokenNo: any;
  creditId:any;
  recentConsultation: RecentConsultationList[] = [];
  consultFee = 0;
  symptomList: any;
  selectedProduct1: any;
  patientList: PatientList[];
  patId: number;
  expiryDate: any;
  visits: any;
  consultDate: any;
  regDate: any;
  schemeName: any;
  regAmount: any;
  expiry: any;
  registrationError: string;
  isRegistrationExpired: boolean = false;
  isConsultationExpired: boolean = false;
  referenceList: ReferenceList[] = [];
  regNumberList: RegisterNumberList[] = [];
  emerFees: any;
  isUrgentConsultation: boolean;
  schemeRateList: SchemeRateList[] = [];
  amount: number;
  consultationId: number = 0;
  consultationDetails = [];
  pageTitle: any;
  consultRateList: ConsultRateList[] = [];
  consultRateList2: ConsultRateList[] = [];
  // consultAmount: any;
  registerResponse: any;
  IsConsultantId = null;
  //VARIABLES FORM VALIDATION CLASS ADD IN STYLE
  public departmentValidator: boolean = false;
  public consultantValidator: boolean = false;
  public patientNameValidator: boolean = false;
  public regNoValidator: boolean = false;
  public scheme: boolean = false;
  public validator = new CommonValidationComponent();
  patIdNo: Number;
  patientDetails: PatientList[];
  regIdentities: any;
  symptomLists: any[];
  emergency: number;
  symptom: any[];
  deptId: void;
  searchpatientmodal: any;
  globals: globalvars = new globalvars();
  BranchId: any;
  BranchesId: any;
  subscription: Subscription;
  departmentId: any;
  userType: any;
  ConsultantId: any;
  ReregisterDate: any;
  PatientId: any;
  ConsultantIdScheme: any;
  ConsultantScheme: number;
  regSchemeListData: Array<Select2OptionData> = [];
  rateGroupListData: Array<Select2OptionData> = [];
  rateGroupList: RateGroupList[] = [];
  patConsultData:any;

  private tabClickCount: number = 0;
  private tabOrders: any [] = [];

  private patientCache: any = null;

  currentUrl: string = null;

  defaultScheme: any;
  urgentScheme: any;
  defaultValueList = [];
  defaultRegScheme: any;
  defaultRateGroup: any;
  ConsentOptionIdList:any=[];
  public configurationSettings: Settings[] = [];
  nabithOptInOptOut: any;
  modalRef: BsModalRef;
  functionPermissionData: any;
  printTokenSettings: any;
  nabithConsentFlag =  false;
  nabithConsentDetails = {};
  tokenPrintFlag = false;
  tokenConsultation = 0;
  healthPlatform: any;

  constructor(
    private formBuilder: FormBuilder,
    private consultationService: ConsultaionsService,
    private hospitalsService: HospitalsService,
    private patientService: PatientService,
    private http: HttpClient,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formValidationService: FormValidationService,
    private sharedservice: SharedService,
    private appointmentService: AppointmentsService,
    private _location: Location,
    private EMRService:EMRService,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,

  ) {
    this.radioSelected = 0;
    this.enableRadionBtn = false;

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
    });

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId

      }
    });

    // debugger
    if(this.router.getCurrentNavigation()?.extras?.state?.editData?.status == 'close credit') {
      let editData = this.router.getCurrentNavigation().extras.state.editData;
      this.patId = editData.patientId;
      this.loadSponsorTable();
    }

    if(this.router.getCurrentNavigation().extras.state){
      let patientCache = this.router.getCurrentNavigation().extras.state.patientData;
      if(patientCache){
        this.patientCache = patientCache;
      }
    }


  }



  //form creating for input fields

  PrintSerials: { SerialId: number; Name: string }[];

  //AG-Grid set up
  public gridApi: GridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context = { componentParent: this };;
  public frameworkComponents = {
    buttonRender: GeneralActionComponent,
  };
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.expired === true; },
    },
  };
  getSelectedRows: "getSelectedRows";
  public getRowStyle;
  public statusBar;


  //sponsor table grid loading
  columnsSpnsor: ColDef[] = [
    {
      headerName: "Open Date",
      field: "openDate",
      sortable: true,
      filter: true,
      checkboxSelection:  params => {
        // Check if selection should be prevented for this row
        if (params.data.expired == true) {
          return false; // Disable checkbox selection
        }
        return true; // Enable checkbox selection
      },
      enableRowGroup: true,
      resizable: true,
      flex: 2,
      cellRenderer: params => {
        if(params.data.isPrimary == true && params.data.expired != true) {
          params.node.setSelected(true)
        }else if (params.data.expired !== true) {
          const api = params.api;
          const rowCount = api.getDisplayedRowCount();
          for (let i = 0; i < rowCount; i++) {
              const rowNode = api.getDisplayedRowAtIndex(i);
              const rowData = rowNode.data;
              if (rowData.expired !== true) {
                  rowNode.setSelected(true);
                  break; // Select the first matching row and exit the loop
              }
          }
      }
        return null
      },
    },
    {
      headerName: "Credit Ref. No.",
      field: "refNo",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Sponsor Name",
      field: "sponsorName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Agent Name",
      field: "agentName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Policy No.",
      field: "policyNo",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Valid Upto",
      field: "expiryDate",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Action",
      maxWidth: 80,
      resizable: true,
      sortable: false,
      field: "Id",
      cellStyle: {color: '', 'text-decoration': 'none'},
      pinned: "right",
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "addConsultation" },
    },
  ];




  ngOnInit(): void {
    this.userType = localStorage.getItem("usertype");
    this.departmentId = localStorage.getItem("departmentId");
    this.ConsultantId = localStorage.getItem('Id');

    this.ReregisterDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    this.activatedRoute.params.subscribe((params) => {
      this.consultationId = params.id;
    });

    var that = this;
    this.functionPermission();
    this.GetReference();
    this.GetTemplateReasonForVisit();
    if (this.consultationId) {
      this.PatientDetailsById();
    }



    this.dropdownSettings = {
      singleSelection: false,
      idField: 'symptomId',
      textField: 'newsymDesc',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true
    };


    this.selectedItems = [
      { item_id: 3, item_text: "Item3" },
      { item_id: 4, item_text: "Item4" },
    ];

    this.PrintSerials = [
      {
        SerialId: 12345,
        Name: "A",
      },
    ];

    this.GetRecentConsultation();
    this.fetchId();
    this.GetRegScheme();
    this.getApplicationSettings();
    this.products = [
      {
        regno: "R5678900",
        name: "Admin",
        address: "28",
        age: "28",
        pin: "28",
        phoneno: "28",
        policyno: "POl788928",
        emiratedid: "EM6778828",
      },
      {
        regno: "z5678900",
        name: "user2",
        address: "28",
        age: "28",
        pin: "28",
        phoneno: "28",
        policyno: "POl788928",
        emiratedid: "EM6778828",
      },
    ];

    //adding a consultaions


    this.BuildForm(1, 1); //1=formId,1=departmentId

    this.GetDepartments();
    this.GetRegSchemeNew();
    this.form = this.formBuilder.group({
      consultDate: [""],
      tokenNo: [""],
      department: ["", [Validators.required]],
      consultant: ["", [Validators.required]],
      regNo: ["", [Validators.required]],
      patientName: ["", [Validators.required]],
      patientId: [0],
      nationalityId: [""],
      // refBy: [""],
      hook: [""],
      ReferedBy: [""],
      ConsentOptionId : [0],
      reasonForVisit: [[]],
      Symptoms: [[]],
      otherreasonforvisit: [""],
      ConsentReason: [""],
      newConsultation: ["0"],
      scheme: [""],
      urgentConsultation: [""],
      fee: [""],
      visit: [""],
      validFrom: [""],
      validTo: [""],
      scheme2: [""],
      amount: [""],
      regDate: [""],
      validUpto: [""],
      lastConsultDate: [""],
      appId: [0],
      CreditId: [0],
      //re register patient form
      // regScheme: ["", [Validators.required]],
      // RGroupId: ["", [Validators.required]],
      // consultAmount: [""],
      ddlRegScheme: [""],
      ddlRateGroup: [""]
    });

    this.resubmittedform = new FormGroup({
      regScheme: new FormControl('', [Validators.required]),
      RGroupId: new FormControl('', [Validators.required]),


    });

    this.getDynamicValidationSettings();
    this.getConsulationPageTabOrder();
    this.readPatientCache();
    // this.GetConsentOption();
  }

  readPatientCache(){
    if(sessionStorage.getItem('consulatation-patient-regNo')){
      let data = JSON.parse(sessionStorage.getItem('consulatation-patient-regNo'));
      this.form.patchValue(data);
      if(data.department){
        this.loadConsultant(Number(data.department));
      }
      if(data.consultant){
        this.GetTokenNo();
      }
      this.GetPatientByRegNo();
      sessionStorage.removeItem('consulatation-patient-regNo')
      return
    }

    if(!this.patientCache){
      return
    }

    this.form.patchValue({
      regNo: this.patientCache?.regNo
    });
    this.SearchPatientDetail(this.patientCache.regNo);
  }

  // @HostListener('window:keydown', ['$event'])
  // tabClickEvent(event: KeyboardEvent){
  //   if(event.key === 'Tab'){
  //     this.tabClickCount++;
  //     event.preventDefault();
  //     for(let field of this.tabOrders){
  //       if(field.objectOrder == this.tabClickCount){
  //         this.changeFocus(field.objectName);
  //         console.log(field.objectName)
  //         return;
  //       }
  //     }
  //   }
  // }

  BuildForm(FormId, DepartmentId) {
    this.formValidationService
      .GetFormValidation(FormId, DepartmentId)
      .subscribe((res) => {
        let response = res.response;

        this.GenerateForm(response);

        if (this.userType !== "U") {
          this.IsConsultantId = true;
          this.form.patchValue({ department: this.departmentId });
          this.loadConsultant(this.departmentId);
          setTimeout(() => {

            this.form.patchValue({ consultant: this.ConsultantId })
            this.LoadConsultantScheme();
          }, 1000);

        }

      });
  }
  GenerateForm(formValidations) {


    formValidations.forEach((element) => {
      if (element.isMandatory) {
        this.form.controls[element.fieldName].setValidators([
          Validators.required,
        ]);
        if (element.fieldName == "department") this.departmentValidator = true;
        else if (element.fieldName == "consultant")
          this.consultantValidator = true;
        else if (element.fieldName == "patientName")
          this.patientNameValidator = true;
        else if (element.fieldName == "regNo") this.regNoValidator = true;
        else if (element.fieldName == "scheme") this.scheme = true;
      }
    });
  }






  get f() {
    return this.form.controls;
  }
  get fr() {
    return this.resubmittedform.controls;
  }

  //getting departments and consultants
  GetDepartments() {
    let showAll = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showAll).subscribe((res) => {
      this.departments = res.response;
    });
  }
  //loading consultants by departmentbyId
  loadConsultant(DepartmentId: Number) {
    let deptId = Number(this.form.get("department").value);
    let currentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy')
    this.hospitalsService.GetConsultant(deptId,currentDate).subscribe((res) => {
      this.consultants = res.response;
      if(this.AppointmentDataDetails['consultantId']){
        this.form.patchValue({ consultant: this.AppointmentDataDetails['consultantId'] });
      }

      if(this.consultants.length === 1){
        this.form.patchValue({ consultant: this.consultants[0].consultantId });
        this.GetTokenNo()
      }
    });
  }
  GetRegSchemeNew() {
    let ShowAll = 0
    this.patientService.GetRegScheme(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.regSchemeList = res.response;
      this.regSchemeListData = [];
      this.regSchemeList.forEach(a => {
        this.regSchemeListData.push({ id: a.itemId.toString(), text: a.itemName });
      });

    })
  }


  // //Getting teplates for symtoms and reason for visit
  GetTemplateReasonForVisit() {
    let ShowAll = 0;
    this.consultationService.GetTemplateReasonForvisit(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.symptomList = res.response;

      // let syptomReasonList = [];
      // syptomReasonList.push({
      //   SymptomId: 0,
      //   SymptomDesc: "None Of the Above",
      //   Active: 1,
      //   BlockReason: "",
      // });
      // this.symptomList = syptomReasonList.concat(this.symptomList);
      this.symptomList.forEach((element) => {
        element["newsymDesc"] = element["SymptomDesc"] ? element["SymptomDesc"] : element["symptomDesc"];
      });
    });
  }



  // fetchId for edit
  fetchId() {
    this.activatedRoute.params.subscribe((params) => {
      this.consultationId = params.id;
    });
    if (this.consultationId) {
      this.fetchDetails(this.consultationId);
      //this.GetTokenNo();
      //this.LoadConsultantScheme();
      this.Activetab("Front Office");
    }
  }
  fetchDetails(Id) {
    // this.consultationService.GetConsultationDetailsbyId(Id).subscribe((res) => {
    //   this.consultationDetails = res.response;
    // });
    if (Id > 0) {
      this.IsConsultantId = true;
    }
  }

  //loading reg no dropdowns as recent consultation
  GetRecentConsultation() {
    this.consultationService.GetRecentConsultations().subscribe((res) => {
      this.recentConsultation = res.response;
    });
  }
  //getting token by consultant
  GetTokenNo() {
    let consultantId = Number(this.form.get("consultant").value);
    let consultDate = new Date(); // today  date
    this.consultationService
      .GetTokenNo(consultantId, consultDate)
      .subscribe((res) => {
        this.tokenNo = res.response.map(function (a) {
          return a["tokenNo"];
        })[0];
        this.LoadConsultantScheme();
      });
  }
  //filling consultant scheme
  LoadConsultantScheme() {
    this.ConsultantScheme = Number(this.form.get("consultant").value);
    console.log(this.patConsultData)
    let data;
    if(this.patConsultData) {
      data = {
        followup:this.form.get("newConsultation").value == 1 ? true : false,
        inscovered:this.patConsultData.insCovered ? true : false,
        creditid:this.creditId,
        deptid: Number(this.form.get("department").value),
        mastermindId: this.patConsultData.itemid ? this.patConsultData.itemid : 0,
      }
    } else {
      data = {
        followup:this.form.get("newConsultation").value == 1 ? true : false,
        inscovered: false,
        creditid:this.creditId,
        deptid: Number(this.form.get("department").value),
        mastermindId: 0,
      }
    }
    this.consultationService
      .GetConsultantScheme(this.ConsultantScheme, this.globals.HospitalId, this.emergency,data)
      .subscribe((res) => {


        this.schemeList = res.response;
        // this.urgentScheme = res.response;
        if (this.isUrgentConsultation ) {
          if (this.schemeList.length == 1) {
            this.form.patchValue({
              scheme: this.schemeList[0].itemId
            })
            // this.defaultScheme = this.schemeList[0].itemId;
          }
        }else{
          if(this.form.get("newConsultation").value == 1 && this.patConsultData.insCovered ){
            if(this.schemeList.length < 1){
              this.form.patchValue({
                scheme: ""
              })
            } else {
              this.form.patchValue({
                scheme: this.schemeList[0].itemId
              })
            }
          } else {
            this.schemeList.find(x => {
              if(x.isDefault == true) {
                this.form.patchValue({
                  scheme: x.itemId
                })
                // this.defaultScheme = x.itemId;
              }
            });
          }
        }
        this.GetSchemeRate();

        this.result = res;
        if (this.result.message == "Empty") {
          this.toastr.warning("No Scheme For this Consultant");
          return false;
        }
        // else {
        //   if (res.response[0]) {
        //     this.form.patchValue({ scheme: res.response[0].itemId });
        //   }
        // }

        // this.GetPatientConfig();

      });
  }

  //checking of urgent consultation is checked or not

  async CheckUrgentCons(isCheckCons) {

    this.emerFees = 0;
    this.urgentScheme = [];
    if (isCheckCons == true) {

      this.isUrgentConsultation = true;
      this.consultFee += this.emerFees;

      this.emergency = 1;

      // this.GetSchemeRate();

    } else {

      this.isUrgentConsultation = false;
      this.consultFee -= this.emerFees;
      this.emergency = 0;

    }
    if(this.form.get("consultant").value !== null && this.form.get("consultant").value !== "" && this.form.get("consultant").value !== undefined){
      await this.LoadConsultantScheme();
    }
  }

  //Get scheme rate and emergency fees
  GetSchemeRate() {

    let consultantId = Number(this.form.get("consultant").value);
    // let patientId = this.patientId ? this.patientId : 0;
    let patientId = 0;
    if(this.patId !== undefined && this.patId !== null) {
      patientId = this.patId;
    } else{
      patientId = 0;
    }
    let itemId = Number(this.form.get('scheme').value);
    let params = {
      "PatientId": patientId,
      "ConsultantId": consultantId,
      "ItemId":itemId,
      "CreditId":this.creditId,
      "IsFollowup": this.form.get("newConsultation").value == 1 ? this.form.get("newConsultation").value : 0,
      "DaySpan": this.patConsultData?.daySpan ? this.patConsultData.daySpan : 0,
      "Deptid":this.patConsultData?.deptId ? this.patConsultData.deptId : Number(this.form.get("department").value)
  }
    this.patientService
      .GetConsultRate(params)
      .subscribe((res) => {
        this.consultRateList = res.response;

        // // let rate = this.schemeRateList[0].rate;
        this.consultFee=0;
        this.emerFees = 0;
        this.consultFee += Number(this.consultRateList[0].rate);

        this.emerFees += Number(this.consultRateList[0].emergencyFees);
        // if (this.isUrgentConsultation) {
        //   this.consultFee += this.emerFees;
        // }
        this.form.patchValue({
          scheme: itemId
        })
      });
  }




  //filling amount in re gister popup
  GetSchemeAmount() {
    let itemId = Number(this.form.get("regScheme").value);
    let patientId = this.form.get("regNo").value;
    this.patId;
    this.patientService
      .GetAmountOfScheme(itemId, this.patId)
      .subscribe((res) => {
        this.consultRateList2 = res.response;
        this.rateGroupListData = [];
        // this.consultRateList2.forEach(a => {
        //   this.rateGroupListData.push({ id: a.patientId.toString(), text: a. + " " });


        // });


        // if (this.consultRateList2[0] == undefined) {
        //   this.toastr.error("Rate not specified for this scheme");

        // }
        // else {
        //   this.consultAmount = this.consultRateList2[0].rate;
        //   this.form.patchValue({ consultAmount: this.consultAmount });
        // }
      });
  }

getPatientFollowUPDtl(){
  let patientId = Number(this.form.get("patientId").value);
  let consultantId = Number(this.form.get("consultant").value);
  let deptId = Number(this.form.get("department").value);
  // let consultantId = Number(this.form.get("consultant").value);
  let params = {
    "PatientId": patientId,
    "ConsultantId": consultantId,
    "BranchId": 1,
    "CreditId": this.creditId,
    "DeptId": deptId,
  }
  this.consultationService.GetPatientConsultConfig(params).subscribe((res:any)=>{
    if(res.response.length){
      let data = res.response[0]
      this.patConsultData = data
      this.form.get("newConsultation").setValue(data.isfollowup)
      if(data.insCovered == 1 && data.isfollowup == 1) {
        this.LoadConsultantScheme();
      }  else {
        this.LoadConsultantScheme();
      }
      this.GetSchemeRate()
    }
  })
}



  //get patient consultation details by entering reg no
  GetPatientConfig() {

    let patientId = Number(this.form.get("patientId").value);
    let consultantId = Number(this.form.get("consultant").value);
    if (consultantId > 0 && patientId > 0) {
      this.consultationService
        .GetPatientConfig(consultantId, this.patId)
        .subscribe((res) => {
          if (res.response[0]) {
            this.emerFees = 0;
            this.consultFee = res.response[0].consultFee
              ? res.response[0].consultFee
              : 0;
            this.expiryDate = res.response[0].expiryDate
              ? res.response[0].expiryDate
              : 0;
            this.isConsultationExpired = res.response[0].isConsultationExpired;
            if (!this.isConsultationExpired) {
              this.radioSelected = 1;
              this.enableRadionBtn = true;
            } else {
              this.radioSelected = 0;
            }
            this.visits = res.response[0].expiryVisits;
            this.emerFees = res.response[0].emergency;
            this.consultDate = res.response[0].consultDate;
            if (this.isUrgentConsultation == true) {
              this.consultFee += this.emerFees;
            }
            if (this.consultDate) {
              let dateAray = this.consultDate.split(" ");
              this.consultDate = dateAray[0];
            }
          } else {
            this.consultFee = null;
            this.expiryDate = null;
            this.isConsultationExpired = null;
            this.visits = null;
            this.consultDate = null;
          }
          if (this.expiryDate) {
            let dateAray = this.expiryDate.split(" ");
            this.expiryDate = dateAray[0];
          }
        });

      //

      //
    }
  }
  // get patient Registration details
  GetPatientRegDetails() {
    this.consultationService.GetRegDetails(this.patId).subscribe((res) => {
      if(res.response.length){
        this.regDate = res.response[0]?.regDate;
        this.expiry = res.response[0]?.expiryDate.substring(0, 10);
        this.schemeName = res.response[0]?.itemName;
        this.regAmount = res.response[0]?.regAmount;
        this.isRegistrationExpired = res.response[0]?.isRegistrationExpired;
        this.form.patchValue({ ConsentOptionId:res.response[0]?.consentOptionId });
        this.form.patchValue({ ConsentReason: res.response[0]?.consentReason });
      } else {
        this.form.patchValue({ ConsentOptionId:'-1' });
      }
    });
  }
  //filling basic detail for patient form
  FillDefaultForm(recentPatient: any) {

    this.form.patchValue({ regNo: recentPatient[0]?.patientId });
    this.form.patchValue({ patientName: recentPatient[0]?.patientName });
    this.form.patchValue({ patientId: recentPatient[0]?.patientId });
    this.loadSponsorTable();
    this.GetPatientRegDetails();
  }
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);
  }

  //modal open for searching patient details
  showBasicDialog() {
    this.modaltemp.open();
    this.form.patchValue({ sName: "" });
    this.form.patchValue({ sMobile: "" });
    this.form.patchValue({ sAddress: "" });
    this.form.patchValue({ sPhoneNo: "" });
    this.form.patchValue({ sPin: "" });
    this.form.patchValue({ sEmiratesNo: "" });
    this.form.patchValue({ sPolicyNo: "" });
    this.form.patchValue({ sRegNo: "" });
    this.displayBasic = true;

  }
  preregister() {
    this.preregisterclick = true;
    this.GetRegScheme();
  }
  //Reset form
  ClearForm() {
    this.consultFee = 0;
    this.form.reset({
      newConsultation: "0",
      consultant: this.IsConsultantId ? this.form.get('consultant').value : "",
      department: this.IsConsultantId ? this.form.get('department').value : "",
      regNo: "",
      scheme: "",
      Symptoms: [],
      reasonForVisit: "",
      sName: "",
      sMobile: "",
      sAddress: "",
      sPhoneNo: "",
      sPin: "",
      otherreasonforvisit: "",
      ConsentOptionId: -1,
      ReferedBy: "",
      ConsentReason: "",
      sEmiratesNo: "",
      sPolicyNo: "",
      sRegNo: "",
    });

    this.GetTemplateReasonForVisit();
    this.sponsorList = null;
    this.tokenNo = null;
    this.consultFee = 0;
    this.expiryDate = null;
    this.visits = null;
    this.consultDate = null;
    this.regDate = null;
    this.schemeName = null;
    this.regAmount = null;
    this.expiry = null;
  }
  //getting registration scheme api service
  GetRegScheme() {
    let ShowAll = 0;
    this.patientService.GetRegScheme(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.regSchemeList = res.response;

    });
  }
  //Add Consultations
  SaveConsultation() {
    let ConsentOptionId =this.form.value.ConsentOptionId ? Number(this.form.value.ConsentOptionId ): 0
    this.nabithConsentFlag = false;
    this.tokenPrintFlag = false;
    this.submitted = true;
    let result = this.f;
    if (this.isRegistrationExpired == true) {
      this.toastr.warning("Registration expired, please Re-register");
      return false;
    }
    if (this.consultationId) {
      this.form.patchValue({ patientId: this.PatientId });
    }
    // if (this.form['value'].department == "") {
    //   this.toastr.error("Department Required");
    // }
    // if (this.form['value'].consultant == "") {
    //   this.toastr.error("Consultant Required");
    // }
    // if (this.form['value'].patientName == "") {
    //   this.toastr.error("patient Name Required");
    // }
    // if (this.form['value'].regNo == "") {
    //   this.toastr.error("reg No Required");
    // }
    // if (this.form['value'].scheme == "") {
    //   this.toastr.error("Scheme Required");
    // }
   
    if (this.healthPlatform.settValue === "Nabidh" &&
      this.nabithOptInOptOut[0].settValue === "1" &&
      ConsentOptionId !== 0 &&
      ConsentOptionId !== 1 &&
      ConsentOptionId !== 2) {
    
    this.toastr.warning("Please choose a consent option for Nabidh");
    return;
    }
    
    else if (this.healthPlatform.settValue.trim() === "Riayati" && 
           this.nabithOptInOptOut[0].settValue === "1" &&
           ConsentOptionId !== 3 &&
           ConsentOptionId !== 4) {
    
    this.toastr.warning("Please choose a consent option for Riayati");
    return;
    }
    

    if (this.form && this.form.valid) {

      if(!this.getMaxPatientsPerDayForConsultant()){
        this.toastr.warning("exceed daily limit for this consultant")
        return
      }

      if (this.consultationId) {
        this.form.patchValue({ appId: this.consultationId });
      }
      if(this.gridApi.getSelectedRows().length > 0){
        let credit: any = this.gridApi.getSelectedRows()[0];
        this.form.patchValue({ CreditId: credit.creditId });
      }
      if(!this.form.get("scheme").value){
          this.toastr.warning("Please  Select Scheme");


      }
      else{
      this.ngxLoader.start();
      let pageTitle = sessionStorage.getItem("pageTitle");
      this.consultationService.SaveConsultation(this.form, this.consultFee, this.emergency, Number(this.globals.HospitalId)).subscribe((res) => {
        // this.consultants = res.response;
        this.result = res;
        {
          if (this.result.message == "Success") {
            this.ngxLoader.stop();
            this.ClearForm();
            this.toastr.success("Consultation added successfully");
            if((this.result.response[0]['consentOptionId'] == 0 || this.result.response[0]['consentOptionId'] == 1) && this.nabithOptInOptOut[0].settValue == '1'){
              // this.nabithConsentFlag = true;
              // this.nabithConsentDetails = {
              //   patientId: this.result.response[0]['patientId'],
              //   consultationId: this.result.response[0]['consultationId']
              // }
              this.tokenConsultation = this.result.response[0]['consultationId'];
              this.nabithConsent(this.result.response[0]['patientId'], this.result.response[0]['consultationId']);
            }else if(this.printTokenSettings[0].settValue == '1'){
              this.tokenPrintFlag = true;
              this.tokenConsultation = this.result.response[0]['consultationId'];
            }
            let currentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy')
            // const foundElement = this.functionPermissionData.find(x => x.id === 524)
            const sMenuId = Number(localStorage.getItem('selectedMenu'));
            let foundId;
            if(sMenuId == 7){
              foundId = 562;
            }else if(sMenuId == 14){
              foundId = 562;
            }
            debugger
            if (this.healthPlatform.settValue === "Nabidh") {
            const foundElement = this.functionPermissionData.find(x => x.id === foundId)
            if (foundElement.id == foundId && foundElement.isAllowed == true) {
              if (this.result.response[0]['consultationId'] !== 0 && this.result.response[0]['consultationId'] !== null) {
                let nabPayload = {
                  EventId: 4,
                  Consultationid: this.result.response[0].consultationId,
                  EntryDate: currentDate,
                  PatientId: this.result.response[0].patientId,
                  BranchId : this.globals.HospitalId
                }
                this.patientService.SendDetailstoNabith(nabPayload).subscribe((res) => {
                  // console.log('res', res);
                  this.toastr.info('Response from Nabith:' + res);
                })
              }
            } else {
              this.toastr.warning('No permission to send data to nabith');
            }
            }
            if (pageTitle == "Front Office") {
              this.router.navigate(['/FrontOffice']);
            }
            else if (pageTitle == "New Consultations" && (this.result.response[0]["consultantId"] == this.ConsultantId)) {
              this.router.navigate(['/consultantdesk/myConsultation']);
            }
            else if (pageTitle == "New Consultations") {
              this.router.navigate(['/consultation']);
            }
            this.PrintTokenModal(this.result.response[0]["consultantId"]);
          } else {
            this.ngxLoader.stop();
            this.toastr.error("Failure: " + this.result.message);
          }

          this.submitted = false;
        }
      });
    }
    }else{
      this.toastr.error("Please fill all required fields.. ");
    }
  }

  //save Hook

  markHook(){
      let hookString="";
      hookString=this.form.controls['hook'].value;
    if (this.form.controls['patientId'].value > 0) {
    let formdata={
      hookid:0,
      hookDesc:hookString,
      patientId:Number(this.form.controls['patientId'].value),
      branchId: this.globals.HospitalId,
      IsDisplayed: true,
      IsDeleted: 0
    }
        this.EMRService.SaveHook(formdata).subscribe((res) => {
          if (res.status == 200 && res.message == "Success") {
            // this.toastr.success(" Successfully added to hook list");
          }
          else if (res.status == 200) {
            // this.toastr.error("Hook Failure: " + res.message);
          }
          else {
           // this.toastr.error("Some unexpected error occures! try again later.");
          }
        });
    }
  }

  SearchPatient() {
    let sName = this.form.get("sName").value;
    let sPhoneNo = this.form.get("sPhoneNo").value;
    let sAddress = this.form.get("sAddress").value;
    let sMobile = this.form.get("sMobile").value;
    let sRegNo = this.form.get("sRegNo").value;
    let sEmiratesNo = this.form.get("sEmiratesNo").value;
    let sPolicyNo = this.form.get("sPolicyNo").value;
    let sPin = this.form.get("sPin").value;


    if (
      sName == "" &&
      sMobile == "" &&
      sPhoneNo == "" &&
      sAddress == "" &&
      sRegNo == "" &&
      sEmiratesNo == "" &&
      sPin == "" &&
      sPolicyNo == ""
    ) {
      this.toastr.warning("Please enter patient details");
      return false;
    }
    this.patientService.SearchPatient(this.form).subscribe((res) => {
      this.patients = res.response;
      this.result = res;
      {
        if (this.patients.length > 0) {
          if (this.patients.length == 1 && this.patients[0].active == "0") {
            this.toastr.warning("This Patient is blocked");
            this.patients = [];
          }
          else {
            this.patients = this.patients.filter((oppdata) => oppdata.active == "1");
            //this.form.patchValue({ sRegNo:this.patients[0].regNo });
            //this.patients=[];
            // this.ClearForm();
            if (this.patients.length == 0) {
              this.toastr.warning("No Patient Found");
            }
          }


        }
        else {
          this.toastr.warning("No Patient Found");
        }
      }
    });

    // initializing Search Patient data into datatable
    this.patientService.SearchPatient(this.form).subscribe((res) => {
      this.posts = res.response;
      // this.loadSponsorTable();
      // this.GetPatientRegDetails();
    });
  }
  //Re register patient if registration expired date
  GetRegSchemeByRateGroup(event) {

    if (typeof (event) == "object") {
      event = 0;
    }
    let RateGroupId = event;//this.userForm.get("RGroupId").value;
    let Input = {
      "Id": Number(RateGroupId),
      "BranchId": this.globals.HospitalId
    }
    this.patientService.GetRateGroupId(Input).subscribe((res) => {
      this.rateGroupList = res.response;
      this.rateGroupListData = [];
      this.rateGroupList.forEach(a => {
        this.rateGroupListData.push({ id: a.rGroupId.toString(), text: a.rGroupName + " " + ("(fee-" + a.rateStr + ")") });
      });
    });
  }

  ReRegister() {
    this.resubmitted = true;
    let result = this.fr;
    if (this.resubmittedform && this.resubmittedform.valid){
    // let itemIdData = Number(this.resubmittedform.get("regScheme").value);
    let itemIdData = Number(this.defaultRegScheme);
    let rateGroupIdData = Number(this.defaultRateGroup);
    // let rateGroupIdData = Number(this.resubmittedform.get("RGroupId").value);

    let patientId = Number(this.form.get("regNo").value);
    let locationId = this.BranchesId;

    this.patientService
      .ReRegister(itemIdData,rateGroupIdData, this.patId, locationId, this.ReregisterDate)
      .subscribe((res) => {
        this.resubmitted = false;
        this.registerResult = res;
        let patientName = this.form.get("patientName").value;
        if (this.registerResult.message == "success") {
          this.preregisterclick = false;
          this.isRegistrationExpired = false;
          this.form.patchValue({ regScheme: "" });
          // this.form.patchValue({ consultAmount: "" });
          this.isRegistrationExpired = false;
          this.GetPatientRegDetails();
          this.toastr.success(
            "The patient " + patientName + " re-registered successfully"
          );

        } else {
          this.toastr.error("Failure: " + this.registerResult.message);
        }


      });
  }
}

  // on clicking on search patients modal filling patient details on form
  FillForm(patient: any) {
    this.patId = patient.data.patientId;

    this.form.patchValue({ regNo: patient.data.regNo });
    this.form.patchValue({ patientName: patient.data.patient });
    this.form.patchValue({ patientId: patient.data.patientId });
    this.form.patchValue({ firstName: patient.data.firstName });
    this.form.patchValue({ nationalityId: patient.data.emirateID });
    this.form.patchValue({ hook: patient.data.hook });
    this.displayBasic = false;
    this.loadSponsorTable();
    this.GetPatientRegDetails();
  }
  // on entering reg no filling patient details on form
  FillForm1(patient: any) {
    debugger
    this.patId = patient["patientId"];

    this.form.patchValue({ regNo: patient["regNo"] });
    this.form.patchValue({ patientName: patient["patient"] });
    this.form.patchValue({ patientId: patient["patientId"] });
    this.form.patchValue({ firstName: patient["firstName"] });
    this.form.patchValue({ nationalityId: patient["emirateID"] });
    this.form.patchValue({ hook: patient["hook"] });
    this.displayBasic = false;
    this.loadSponsorTable();
    this.GetPatientRegDetails();
    if(this.defaultScheme !== undefined) {
      this.GetSchemeRate();
    }
  }

  //getting sponsors list
  loadSponsorTable() {
    let payload = {
      PatientId: this.patId,
      Branchid: this.globals.HospitalId,
      ShowExpired: 1
    }
    this.consultationService.GetSponsorDetailsByPatient(payload).subscribe((res) => {
      this.sponsorList = res.response;
      res.response.map((res:any)=>{
        if(!res.expired){
          this.creditId =res.creditId
        }
      })
      this.getPatientFollowUPDtl();
    });

  }

  //Get reference list for patient
  GetReference() {
    this.patientService.GetReference(this.globals.HospitalId).subscribe((res) => {
      this.referenceList = res.response;

    });
  }



  PrintTokenModal(ConsultantId) {
    this.TokenpPint = true;
  }
  //Print Token For Consultations
  PrintModalToken(ConsultantId) {
    this.router.navigate(['/consultation']);
    this.PrintSerials = [
      {
        SerialId: 12345,
        Name: "A",
      },
    ];

    let barcodepp = this.barCodePrinting.nativeElement.innerHtml;

    let printContents = "";
    //contact detatils

    printContents =
      printContents +
      '<div align="center"><div class="card" style="border:1px solid #000;width:100%">';
    printContents =
      printContents +
      ' <table class="print-table1"><tr><td><h3>Kindly Present This Token To Doctor-Cashier-Lab-Treatment Room</h3></td></tr><tr><td><strong><h3>Occupational HealthCare International04-230640</h3></strong></td></tr>';
    printContents =
      printContents + "<tr><td><strong><h4>TokenNo:1</h4></strong></tr></td>";
    printContents =
      printContents + "<tr><td><strong><h4>Room No:1</h4></strong></tr></td>";
    printContents =
      printContents +
      " <tr><td><strong>Consultant Name:Dr.MOHAMAD MOU</strong></td><td>" +
      "12345" +
      "</td></tr>";
    printContents =
      printContents +
      " <tr><td><strong>Name:</strong></td><td>" +
      "TestCase" +
      "</td></tr>";

    printContents =
      printContents +
      " <tr><td><strong>Age:</strong></td><td>" +
      "29" +
      "</td></tr>";

    printContents =
      printContents +
      " <tr><td><strong>RegNo:</strong></td><td>" +
      "12345" +
      "</td></tr>";
    printContents =
      printContents +
      ' <tr><td><ngx-barcode  [bc-display-value]="true"> </ngx-barcode> </td><td>' +
      +"</td></tr>";

    printContents =
      printContents +
      " <tr><td><strong>Mobile:</strong></td><td>" +
      +"</td></tr>";

    var mywindow = window.open("", "PRINT", "height=1000,width=1200");
    mywindow.document.write(
      '<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' +
      document.title +
      "</title>"
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write(printContents);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  }

  SearchPatientDetail(patient: any) {

    let _this = this;
    this.GetPatientByRegNo();
    this.getPatientFollowUPDtl();
    // setTimeout(function () {
    //   let patientDetail = _this.patients.filter(
    //     (patientData) => patientData.regNo == patient
    //   );

    //   if (patientDetail.length > 0) {
    //     _this.FillForm1(patientDetail[0]);

    //   }
    // }, 1000);

  }
  /*================Bhagya changes==========================*/
  GetPatientByRegNo() {
    this.patientService.SearchPatientByRegNo(this.form.get("regNo").value).subscribe((res) => {
      this.patients = res.response;
      this.result = res;

      if (this.patients.length > 0) {
        if (this.patients[0].active == "0") {

          this.toastr.warning("This Patient is blocked");


          this.ClearForm();

          this.form.patchValue({ regNo: this.patients[0].regNo });
          this.patients = [];
          return
        }
        this.FillForm1(this.patients[0]);

      } else {

        this.toastr.warning("No patients found");
      }

    });
  }



  PatientDetailsById() {
    
    // this.activatedRoute.params.subscribe((params) => {
    //   this.consultationId = Number(params.id);
    // });
    // this.patientService.GetPatientDetailsbyId(this.consultationId).subscribe((res) => {
    //   this.patientDetails = res.response
    //   let deptId=this.patientDetails['departmentId'];
    //   this.form.patchValue({ department:this.patientDetails[0]['departmentId'] });
    //   this.loadConsultant(deptId);
    //   this.form.patchValue({ consultant:this.patientDetails[0]['consultantId'] });
    //   this.regIdentities=this.patientDetails[0]['regIdentities'];

    //     this.form.patchValue({ patientName:this.patientDetails[0]['patientName'] });
    //     this.form.patchValue({ regNo:this.patientDetails[0]['regNo']});
    //     this.form.patchValue({ nationalityId:this.regIdentities[6].identityNo });
    //    let registrationdate=   this.form.patchValue({  regDate:this.patientDetails['regDate'] });
    //    this.regDate = registrationdate



    // });


    this.activatedRoute.params.subscribe((params) => {
      this.consultationId = Number(params.id);
    });
    this.appointmentService.GetAppoinmentDetailsbyId(this.consultationId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      let deptId = this.AppointmentDataDetails['deptId'];
      this.PatientId = this.AppointmentDataDetails['patientId']
      this.form.patchValue({ department: this.AppointmentDataDetails['deptId'] });
      this.loadConsultant(deptId);
      this.form.patchValue({ consultant: this.AppointmentDataDetails['consultantId'] });
      this.form.patchValue({ patientName: this.AppointmentDataDetails['firstName'] +" "+ this.AppointmentDataDetails['lastName']});
      this.form.patchValue({ regNo: this.AppointmentDataDetails['patientRegNo'] });
      this.form.patchValue({ patientId: this.AppointmentDataDetails["patientId"] });

      this.form.patchValue({ nationalityId: this.AppointmentDataDetails["emirateID"] });
      // this.form.patchValue({ hook: this.AppointmentDataDetails["hook"] });
      
      //876
      this.ConsultantId = this.AppointmentDataDetails['consultantId'];

      if (this.ConsultantId) {
        this.LoadConsultantScheme();
        // issue in showing sponsor 
        this.patId=this.AppointmentDataDetails['patientId']
        this.loadSponsorTable()
      }

    });

  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }

  ConsultationList() {
    this.router.navigate(['/consultation']);
  }

  SymptomReason() {
    let val = this.form.get('Symptoms').value;

    if (val.length > 0) {

      if (val[0]['newsymDesc'] == 'None Of the Above') {

        this.OtherSymptom = true;
      }
      else {

        this.OtherSymptom = false;
      }
    }
    else {
      this.OtherSymptom = false;
    }
  }



  //common function added for filling patient details on clicking search patient modal
  searchevent(patient: any) {
    this.patId = patient.data.patientId;

    this.form.patchValue({ regNo: patient.data.regNo });
    this.form.patchValue({ patientName: patient.data.patient });
    this.form.patchValue({ patientId: patient.data.patientId });
    this.form.patchValue({ firstName: patient.data.firstName });
    this.form.patchValue({ nationalityId: patient.data.emirateID });
    this.form.patchValue({ hook: patient.data.hook });
    this.displayBasic = false;
    this.loadSponsorTable();
    this.GetPatientRegDetails();
    if(this.defaultScheme !== undefined) {
      this.GetSchemeRate();
    }
  }

  /*================Bhagya changes end==========================*/

  showBasicDialogSearch($event) {
    this.displayBasic = $event;
  }
  goback() {
    this._location.back();
  }

  getDynamicValidationSettings(){
    const payload= {
      FormName:"Consultation",
      BranchId:this.globals.HospitalId
    }

    this.configurationService.getSavingSchemeData(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status){
          let dynamicValidation = response.response;

          for(let field of dynamicValidation){
            if(field.schemeValue && (field.propertyName != 'department' || field.propertyName != 'consultant' || field.propertyName != 'regNo' || field.propertyName != 'patientName')){
              this.form.controls[field.propertyName].setValidators(Validators.required);
              this.form.controls[field.propertyName].updateValueAndValidity()
            }
          }
        }
      }
    })
  }

  getConsulationPageTabOrder(){
    let payload= {
      ScreenName:"Consultation",
      BranchId:this.globals.HospitalId
    }
    this.configurationService.getTabSchemeData(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.tabOrders = response.response;
          let first = this.tabOrders.find(item=>{
            return item.objectOrder == 1;
          })
          this.changeFocus(first.objectName);
          this.tabClickCount = 1;
        }else{

        }
      },error: (error: any)=>{

      }
    })
  }

  changeFocus(fieldName: string){
    document.getElementById(fieldName).focus();
  }


  onOpencredit(){
    if(this.form.get('patientId').value){
      let input={
        patientId: Number(this.form.get('patientId').value),
        creditId: '',
        patientName: this.form.get('patientName').value,
        regNo: this.form.get('regNo').value,
        url: this.currentUrl
      }

      sessionStorage.setItem('consulatation-patient-regNo', JSON.stringify(this.form.getRawValue()));

      this.router.navigate(['/OpenCredit'], { state: { editData: input }, replaceUrl: true  });
    }else{
      this.toastr.warning('Please select a patient to continue..')
    }
  }

  onEditcredit(data: any){
    if(this.form.get('patientId').value){
      let input={
        patientId: Number(this.form.get('patientId').value),
        creditId: data.creditId,
        patientName: this.form.get('patientName').value,
        regNo: this.form.get('regNo').value,
      }

      sessionStorage.setItem('consulatation-patient-regNo', JSON.stringify(this.form.getRawValue()));

      this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    }else{
      this.toastr.warning('Please select a patient to continue..')
    }
  }

  setCreditAsDefault(data: any) {
    if (data.expired == true || data.active !== 1 || data.IsDeleted == 1) {
      this.toastr.warning('Credit expired: Cannot set as default');
    } else if (data.expired == false) {
      let payload = {
        CreditId: data.creditId,
        PatientId: this.patId,
        BranchId: this.globals.HospitalId
      }
      this.consultationService
        .updateDefaultCredit(payload)
        .subscribe((res) => {
          if (res.status == 200) {
            this.loadSponsorTable();
            this.toastr.success("Credit saved as default");
          }
        });
    }
  }

  getApplicationSettings() {
    let payload= {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }
    this.patientService.getApplicationSettings(payload).subscribe((res) => {
      this.defaultValueList = res.response;
      this.healthPlatform=res.response.find(item => item.settId == 25)
      this.configurationSettings = _.filter(res.response, {tabId: 1});
      this.nabithOptInOptOut = _.filter(this.configurationSettings, {displayName: 'EnableNabOptinOptOutforConsultation'});
      this.printTokenSettings = _.filter(this.configurationSettings, {displayName: 'EnablePrintToken'});
      this.defaultValueList.find(x => {
        if(x.displayName == 'DefaultReRegistrationScheme') {
          this.defaultRegScheme = x.settValue;
        }
        if(x.displayName == 'DefaultReRegRateGroup') {
          this.defaultRateGroup = x.settValue;
        }
      });
      this.GetConsentOption();
    })
  }

  GetConsentOption() {
    this.patientService.GetConsentOption(-1).subscribe((res) => {
      // this.ConsentOptionIdList = res.response;
      this.ConsentOptionIdList = res.response.filter(item=> item.receivedBy == this.healthPlatform.settValue);   
    })
  }

  copyCredit(data: any){
    if(this.form.get('patientId').value){
      let input={
        patientId: Number(this.form.get('patientId').value),
        creditId: 'copyCredit',
        patientName: this.form.get('patientName').value,
        regNo: this.form.get('regNo').value,
      }

      sessionStorage.setItem('consulatation-patient-regNo', JSON.stringify(this.form.getRawValue()));
      sessionStorage.setItem('copyCreditDetails', JSON.stringify(data));

      this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    }else{
      this.toastr.warning('Please select a patient to continue..')
    }
  }

  nabithConsent(patientId, ConsultationId) {
    if(this.healthPlatform.settValue === "Nabidh"){
    let initialState = {
      patientId: patientId,
      ConsultationId: ConsultationId,
      action: 0,
      formName:this.healthPlatform.settValue
    }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(NabithConsentComponent,Object.assign( {}, config, { class: 'modal-xl', initialState }) );
    this.modalRef.content.fileEmitter.subscribe((response: any)=>{
      // console.log('response', response);
      if(response.status == true){
        if(this.printTokenSettings[0].settValue == '1'){
          this.tokenPrintFlag = true;
        }
      }
    })
  }else if (this.healthPlatform.settValue === "Riayati") {
    let initialState = {
      patientId: patientId,
      ConsultationId: ConsultationId ? ConsultationId : 0,
    }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef = this.modalService.show(RiayatiConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
    this.modalRef.content.fileEmitter.subscribe((response: any) => {
      if (response.status == true) {
        // this.toastr.success("The signature updated successfully");
      }
    })
  }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  printTokenMethod(){
    let initialState = {
      modalHeading: 'Print Token',
      reportName: 'RptPrintToken.trdp',
      reportParams: {
        BranchId: this.BranchesId,
        ConsultationId : this.tokenConsultation
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  }

  getMaxPatientsPerDayForConsultant(){
    let flag=true
    let currentappDate = new Date();
   this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    let payload={
      ConsultDate:this.datepipe.transform(currentappDate, 'yyyy-MM-dd'),
      ConsultantId:Number(this.form.value.consultant)
    }
    this.sharedservice.getGetMaxPatientsPerDayForConsultant(payload).subscribe({
      next:(res)=>{
        if(res.response){
          flag=res.response.isAvailable
        }
      }
    })
    return flag
  }

  getTabIndex(objectName:string){
    const object=this.tabOrders.find(item=>item.objectName == objectName)
    return object ?  object.objectOrder : 100
  }
 
}
