import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-consultant-list',
  templateUrl: './consultant-list.component.html',
  styleUrls: ['./consultant-list.component.scss']
})
export class ConsultantListComponent implements OnInit {
  @Input() datalist: any[];

  constructor(private modalRef: BsModalRef,) { }

  ngOnInit(): void {
  }
  close() {
    this.modalRef.hide();
  }

}
