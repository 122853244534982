import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMRService } from '../../../services/emr.service';

@Component({
  selector: 'app-view-sign-symptoms-icd-modal',
  templateUrl: './view-sign-symptoms-icd-modal.component.html',
  styleUrls: ['./view-sign-symptoms-icd-modal.component.scss']
})
export class ViewSignSymptomsIcdModalComponent implements OnInit {

  @Input() entryId: any;
  @Input() patientId: any;
  @Input() consultationId: any;

  public dataLoading: boolean = false;
  public itemData: any;

  constructor(private activeModalRef: BsModalRef,
              private emrService: EMRService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    let input = {
      VisitId: this.entryId,
      PatientId: this.patientId,
      ShowAll: 1,
      ConsultationId: this.consultationId
    };
    this.dataLoading = true;
    this.emrService.GetNarrativeDiagonosis(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];
        this.itemData = response;
      }
      this.dataLoading = false;
    });
  }

  close(){
    this.activeModalRef.hide();
  }

}
