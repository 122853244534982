import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ErrorHandleService } from '../../client/services/error-handle.service';
import { Response } from '../../../../app/_Models/Response';
import { BillingForm } from "../../billing-settlement/interfaces/BillingForm";
import { ConsultationsList } from '../../client/interfaces/ConsultationsList';
@Injectable({
  providedIn: 'root'
})
export class NewBillingService {

  private baseUrl = "";
 PatientDataDetails:any;
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

 

  getProfileItems(inputData) {
    var url = "api/Bill/GetProfileforBilling";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  getPendingItems(inputData) {
    var url = "api/Bill/GetUnBilledItem";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  saveBillingDetails(formData){
    var url = "api/Bill/InsertUpdateTransaction";
    return this.http.post<Response<BillingForm>>(`${this.baseUrl}${url}`, formData)
  }

  saveExternalBillingDetails(formData){
    var url = "api/Bill/InsertUpdateTransactionExternal";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, formData)
  }

      //Getting Departments 
      getDepartment(input) {
        var url = "api/MasterData/GetDepartment";
        return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input)
      }
      //getting consultant
      getConsultant(input){
        var url = "api/TodaysPatient/GetConsultant";
        return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input)
      }
      //getting consultant
      getBillingListConsultation(input){
        var url = "api/Bill/GetBillConsultation";
        return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input)
      }

      getConsultantByHospital(input) {
        var url = "api/MasterData/GetConsultantByHospital/";
        return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input)
      }

}
