import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-physiotherapy-list',
  templateUrl: './physiotherapy-list.component.html',
  styleUrls: ['./physiotherapy-list.component.scss']
})
export class PhysiotherapyListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
