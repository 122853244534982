import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../../_Models/Response';
import { ClaimService } from '../services/claim.service';

@Component({
  selector: 'app-claim-action',
  templateUrl: './claim-action.component.html',
  styleUrls: ['./claim-action.component.scss']
})
export class ClaimActionComponent implements ICellRendererAngularComp {

  public params: any;
  public pagetype: string;
  public pageField: string = '';
  public items: any[] = [];
  toothList:any[]=[{value:1},{value:2},{value:3},{value:4},{value:5},{value:6},{value:7},{value:8},{value:9},{value:10},{value:11},{value:12}]
  toothNo=[1,3,5]

  public staffId: any = '';
  startDate:any
  endDate:any
  public allStaffList: any[] = [];
  dropdownSettings:IDropdownSettings = {};

  constructor(private claimService: ClaimService,
              private toastrService: ToastrService,
              private datePipe: DatePipe,
              ) { }

  agInit(params: ICellRendererParams): void {

    this.params = params;
    this.startDate=params.data.startDate
    this.endDate=params.data.endDate
    this.pagetype = this.params.PageType;
    this.pageField = this.params.PageField;
    this.allStaffList = this.params.StaffList;
    // this.toothNo=this.params.data.toothNo !=  "" && this.params.data.toothNo !=  "[]" ? JSON.parse(this.params.data.toothNo).map((items)=>  {
    // return {value:items }}) :[]

    this.toothNo=this.params.data.toothNo && this.params.data.toothNo !=  "" && this.params.data.toothNo !=  "[]" ? JSON.parse(this.params.data.toothNo) : []


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'value',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
      defaultOpen: false
    };

    if(this.pagetype=="manageprior"){
      this.items = [
        // {
        //   label: "Lock Cform",
        //   command: (x: any) => {
        //     let data = this.params.data;
        //     this.params.context.componentParent.LockCForm(data);
        //   }
        // },
        { separator: true },
        {
          label: "Edit Prior Request",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditPriorReqOPenMoadl(data);
          },
          disabled: this.params.data.status == 'S' || this.params.data.status == 'C' ? true : false,
        },
        { separator: true },
        {
          label: "Copy Prior Request",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.CopyPriorReqOPenMoadl(data);
          }
        },
        { separator: true },
        {
          label: "Upload Request",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.UploadPriorReq(data);
          },
          disabled: this.params.data.status == 'S' || this.params.data.status == 'C' ? true : false,
        },
        { separator: true },
        {
          label: "Print Prior Request",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printPriorRequest(data);
          }

        },
        { separator: true },
        // {
        //   label: "Download Authorization",
        //   command: (x: any) => {
        //     let data = this.params.data;
        //    this.params.context.componentParent.Downloadeprior(data);
        //   },
        // },
        {
          label: "Delete Prior Request",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.DeletePrior(data);
          },
          disabled: this.params.data.status !== 'C' &&  this.params.data.status !== 'N'? true : false,
        },
        { separator: true },
        {
          label: "Cancel Prior-Request",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelPreRequest(data);
          },
          disabled: this.params.data.status == 'C' ? true : false,
        },
        { separator: true },
        {
          label: "View Prior-Request",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.viewPreRequest(data);
          },
        },
        { separator: true },
        {
          label: "Mark as Read",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.markAsRead(data);
          },
          disabled: this.params.data.isRead == true ? true : false,
        },
      ]
    }
    else if(this.pagetype=="manageRemittance"){
      this.items = [
        {
          label: "Cancel ",
           disabled: this.params.data.status == 'C' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelItem(data);
          },
        },
        { separator: true },
        {
          label: "Create Resubmition",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.Resubmission(data);
          },
        },
        { separator: true },
        {
          label: "View Details",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.viewDetails(data);
          },
        },
        { separator: true },

      ]
    }else if(this.pagetype=="manageClaim"){
      this.items = [
        {
          label: "Create Test XML",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onCreateTestXML(data);
          },
        },
        // { separator: true },
        // {
        //   label: "Create Production XML",
        //   disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
        //   command: (x: any) => {
        //     let data = this.params.data;
        //     this.params.context.componentParent.onCreateProductionXML(data);
        //   },
        // },
        { separator: true },
        {
          label: "Create Test Investigation XML",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onCreateInvestigationTestXML(data);
          },
        },
        // { separator: true },
        // {
        //   label: "Create Production Investigation XML",
        //   disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
        //   command: (x: any) => {
        //     let data = this.params.data;
        //     this.params.context.componentParent.onCreateInvestigationProductionXML(data);
        //   },
        // },
        { separator: true },
        {
          label: "Create Test XML (NS Code)",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onCreateTestXMLwithNScode(data);
          },
        },
        // { separator: true },
        // {
        //   label: "Create Production XML (NS Code)",
        //   disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
        //   command: (x: any) => {
        //     let data = this.params.data;
        //     this.params.context.componentParent.onCreateProductionXMLwithNScode(data);
        //   },
        // },
        // { separator: true },
        // {
        //   label: "Download XML",
        //   disabled: this.params.data.status.toLowerCase() == 'c' ? true : ((this.params.data.xmlPath) ? false : true),
        //   command: (x: any) => {
        //     let data = this.params.data;
        //     this.params.context.componentParent.onDownloadXML(data);
        //   },
        // },
        { separator: true },
        { separator: true },
        // {
        //   label: "Online Submission",
        //   disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
        //   command: (x: any) => {
        //     let data = this.params.data;
        //    this.params.context.componentParent.onOnlineSubmitClaime(data);
        //   },
        // },
        // { separator: true },
        {
          label: "Create Resubmition",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onCreateResubmissionClicked(data);
          },
        },
        { separator: true },
        
        // {
        //   label: "Claim Receipt",
        //   disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
        //   // command: (x: any) => {
        //   //   let data = this.params.data;
        //   //  this.params.context.componentParent.claimReceipt(data);
        //   // },
        // },
        { separator: true },
        {
          label: "Edit Claim",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any)=>{
           let data = this.params.data;
           this.params.context.componentParent.onEditClaim(data);
          }
        },
        { separator: true },
        {
          label: "Cancel Claim",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.onCancelClaim(data);
          },
        },
        { separator: true },
        {
          label: "Receipt Report",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.receiptReport(data);
          },
        },
        { separator: true },
        {
          label: "Detailed Claim Report",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.detailedClaimReport(data);
          },
        },
        { separator: true },
        {
          label: "Claim Receipts",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.claimReceipts(data);
          },
        },
        { separator: true },
        {
          label: "Claim Report(item wise)",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.claimReportItemWise(data);
          },
        },
        { separator: true },
        {
          label: "Claim Report",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.cliamReport(data);
          },
        },

        {
          label: "SCDS Report",
          disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.scdsReport(data);
          },
        },
        { separator: true },

      ];
    }else if(this.pagetype == 'changeClaimDetails' && this.pageField == 'staff'){
      this.staffId = this.params.data.perStaffId ? this.params.data.perStaffId : ''
    }else if(this.pagetype == 'manageXML'){
      this.items = [
        {
          label: "Online Submission",
          // disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.onOnlineSubmitClaime(data);
          },
        },
        {
          label: "Download XML",
          // disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.onDownloadXML(data);
          },
        },
      ]
    }else if(this.pagetype == 'resubmissionList'){
      this.items = [
        {
          label: "Edit Resubmission",
          // disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onEditResubmission(data);
          },
        },
        {
          label: "Cancel Resubmission",
          // disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onCancelReSubmission(data);
          },
        },
      ]
    }else if(this.pagetype == 'createResubmission' && this.pageField == 'action'){
      this.items = [
        {
          label: "Print Invoice",
          // disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onPrintInvoice(data);
          },
        },
        {
          label: "Change Item Details",
          // disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onChangeItemDetails(data);
          },
        },
        {
          label: "Change XML Claim ID",
          // disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onChangeXMLClaimId(data);
          },
        },
        {
          label: "Change Approval",
          // disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onChangeApprovalNumber(data);
          },
        },
        {
          label: "Edit Credit",
          // disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onEditCredit(data);
          },
        },
        {
          label: "Patient EMR",
          // disabled: this.params.data.status.toLowerCase() == 'c' ? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onOpenPatientEMR(data);
          },
        },
      ]
    }
    else if(this.pagetype=="ConsultationList-Add"){
      this.items = [
        {
          label: "Add",
          disabled: this.params.data.status == "Waiting" && this.params.data.sponsorId != 0? true : false,
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.addPriorRequest(data);
          },
        },
        { separator: true },
        {
          label: "Patient EMR",
          command: (x: any) => {
            this.params.context.componentParent.GoToEmr(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        // {
        //   label: "View Prior Request",
        //   command: (x: any) => {
        //     let data = this.params.data;
        //     this.params.context.componentParent.goToPriorRequest(data);
        //   },
        // },
        { separator: true },
        {
          label: "Treatment Summary",
          command: (x: any) => {
            this.params.context.componentParent.getTreatmentSummary(this.params.data);
          },
        },
      ]
    }
    else if(this.pagetype=="ClaimVerificationPage"){
      this.items = [
        {
          label: "Change Claim Details",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.changeClaimDetails(data);
          },
        },
        { separator: true },
        {
          label: "Change Approval Number",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.changeApprovalNumber(data);
          },
        },
      ]
    }
    else if(this.pagetype=="ClaimBatchPage"){
      this.items = [
        {
          label: "View XML",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.viewXml(data);
          },
        },
        { separator: true },
        {
          label: "Edit Claim Batch",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.editClaimBatch(data);
          },
        },
        { separator: true },
        {
          label: "Cancel Claim Batch",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelClaimBatch(data);
          },
        },
        { separator: true },
        {
          label: "Receipt Report",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onReceiptReport(data);
          },
        },
        { separator: true },
        {
          label: "Detailed Claim Report",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onDetailedClaimReport(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },
        {
          label: "Claim Report Item Wise",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onClaimReportItemWise(data);
          },
        },
        { separator: true },
        {
          label: "Claim Report",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onClaimReport(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
      ]
    }
    else if(this.pagetype=="remittanceManage"){
      this.items = [
        {
          label: "Update",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onUpdate(data);
          },
          disabled: this.params.data.status == "D" ? true : false,
        },
        { separator: true },
        {
          label: "View Xml",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.viewXmlData(data);
          },
        },
      ]
    }
    else if(this.pagetype == "claimManage"){
      this.items = [
        {
          label: "Resubmission",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onResubmission(data);
          },
        },
        { separator: true },
        {
          label: "View Bill details",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onView(data);
          },
        },
      ]
    }
    else if(this.pagetype=="reSubmissionManage"){
      this.items = [
        {
          label: "Add Re-submission",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.addReSubmission(data);
          },
          // disabled: this.params.data.status == "D" ? true : false,
        },
      ]
    }
    else if(this.pagetype=="reSubmissionListManage"){
      this.items = [
        {
          label: "Edit Re-submission",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.editReSubmission(data);
          },
        },
        { separator: true },
        {
          label: "Upload Resubmission",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.uploadResubmission(data);
          },
        },
        { separator: true },
        {
          label: "View Resubmission XML",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.viewResubmissionXml(data);
          },
        },
      ]
    }
    else if(this.pagetype=="addResubmissionPage"){
      this.items = [
        {
          label: "Print Invoice",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printInvoice(data);
          },
        },
        { separator: true },
        {
          label: "Change Item Details",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.changeItemDetails(data);
          },
        },
        // { separator: true },
        // {
        //   label: "Change XML Claim ID",
        //   command: (x: any) => {
        //     let data = this.params.data;
        //     this.params.context.componentParent.changeXMLClaimID(data);
        //   },
        // },
        { separator: true },
        {
          label: "Change Approval",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.changeApproval(data);
          },
        },
      ]
    }
    else if(this.pagetype=="reconciliation"){
      this.items = [
        {
          label: "View Resubmission",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.addReSubmission(data);
          },
          // disabled: this.params.data.status == "D" ? true : false,
        },
      ]
    }
    else if(this.pagetype=="bank-reconciliation"){
      this.items = [
        {
          label: "Show Details",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.showDetails(data);
          },
          // disabled: this.params.data.status == "D" ? true : false,
        },
      ]
    }
    
    
  }

  onChangeStaff(){
    this.params.data.perStaffId = this.staffId ? Number(this.staffId) : 0
  }

  onChangeFile(event){
    if (event.target.files.length){
      const file: File = event.target.files[0];
      // const formData = new FormData();
      // formData.append('clinicStampImg', file);
      this.params.data.fileContent = file
    }else{
      
    }
  }
  onChangeStartDate(event){
    this.params.data.startDate =  this.datePipe.transform(this.startDate, 'dd-MM-yyyy')
  }

  onChangeEndDate(event){
    this.params.data.endDate =  this.datePipe.transform(this.endDate, 'dd-MM-yyyy')
  }

  onChangeToothNo(){
   
    // let selectedtooth=this.toothNo.map((tooth)=>{
    //   return tooth.value
    // })
    this.params.data.toothNo=JSON.stringify(this.toothNo)
   
  }

  // deniedStatusChange(event) {
  //   if (this.params.pageType == 'createResubmission' || this.params.gridType == 'deniedStatus') {
  //     this.params.context.componentParent.groupFieldChange(this.params.data, event.target.checked, this.params.gridType);
  //   } else {
  //     this.params.context.componentParent.itemFieldChange(this.params.data, event.target.checked, this.params.gridType);
  //   }
  // }

  refresh(): boolean {
    return false;
  }

}
