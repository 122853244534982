import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { SharedService } from "../../../../shared/shared.service";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ConfigurationService } from "../../../client/services/configuration.service";

@Component({
  selector: "app-preview-multiple-result-form",
  templateUrl: "./preview-multiple-result-form.component.html",
  styleUrls: ["./preview-multiple-result-form.component.scss"],
})
export class PreviewMultipleResultFormComponent implements OnInit {
  @Input() RformId: 0
  @Input() BranchId: 0
  @Input() rformName: "";
  @Input() microbiologist: Boolean;
  @Input() pathologist: Boolean;
  @Input() radiologist: Boolean;
  @Input() technologist: Boolean;
  @Input() ItemIdList: any[];
  @Input() branchId: any[];
  @Input() patientId: any;
  @Output() getitemEmitter = new EventEmitter()
  @Input() OrderDetIdList: any[];

  resultFormFata: any;
  previewFormFata: any;
  data1: any;
  data: any;
  colourData: any;
  rangeData: any;
  positiveData: any = [];
  clarityData: any;
  StaffData: any;
  technologistdata: any;
  pathalogisttdata: any;
  microbiologistdata: any;
  radiologistdata: any;
  resultForm: FormGroup;
  fromDates: any;
  public submitted: boolean = false;
  isLoading = false;
  result: any;
  presence: any;
  consistency: any;
  reaction: any;
  subscription: Subscription;

  constructor(private modalRef: BsModalRef,
    private configurationService: ConfigurationService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private sharedservice: SharedService,

  ) { 
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {

      }
    });

  }

  ngOnInit(): void {
    let timeZoneData=this.sharedservice.timeZoneData

    this.fromDates = this.datepipe.transform(new Date(), 'dd-MM-yyyy HH:mm',timeZoneData);

    this.resultForm = new FormGroup({
      ResultId: new FormControl(0),
      PatientId: new FormControl(this.patientId),
      ResultDate: new FormControl(this.fromDates),
      RformId: new FormControl(this.RformId),
      Status: new FormControl('R'),
      PublishDate: new FormControl(''),
      PublishUserId: new FormControl(0),
      TechId: new FormControl(0),
      PathId: new FormControl(0),
      RadioId: new FormControl(0),
      MicroId: new FormControl(0),
      Remarks: new FormControl(''),
      ResNo: new FormControl(''),
      UserId: new FormControl(Number(localStorage.getItem("user_id"))),
      ResultDetailList: new FormArray([]),
      Interpretation: new FormControl(''),
      PerformingLocation: new FormControl(''),
      PerformingStaff: new FormControl(0),
      LabNo: new FormControl(''),

    });

    this.getResultForm();
    this.getPreviewResult();
    this.getcolour();
    this.getPositive();
    this.getClarity();
    this.getpresence();
    this.getreaction();
    this.getconsistency();
    this.GetAllStaff();
  }
  close() {
    this.modalRef.hide();
  }
  getResultForm() {

    let input = {
      "RformId": this.RformId,
      "BranchId": this.BranchId,

    }

    this.configurationService.getResultForm(input).subscribe((res) => {
      this.resultFormFata = res.response;
    });

  }
  //GET RESULT FORM
  getPreviewResult() {
    let input = {
      "RformId": this.RformId,
      "ItemIdList": this.ItemIdList ? this.ItemIdList : [],
      "resultId": 0,
      "patientId": this.patientId
    }

    this.configurationService.getPreviewResultForm(input).subscribe((res) => {
      this.data = res.response;
      this.data.sort((a, b) => a.paramOrder - b.paramOrder);
      res.response.forEach(element => {
        debugger
        this.addMapFormGroup(element);
      });

      //
    });

  }

  // CREATE RESULT FORM ARRAY


  public addMapFormGroup(data?: any[]) {
    const ServiceItems = this.resultForm.get("ResultDetailList") as FormArray;
    const values = Object.keys(data).map(key => data[key]);
    ServiceItems.push(this.createListGroup(data));
  }


  private createListGroup(data?): FormGroup {
      return new FormGroup({
      parameterId: new FormControl(data['paramId']),
      ColourResultId: new FormControl(data?.defaultParamValue ? data.defaultParamValue : ''),
      RangeResult: new FormControl(data?.defaultParamValue ? data.defaultParamValue : ''),
      PositiveNegativeResultId: new FormControl(data?.defaultParamValue ? data.defaultParamValue : ''),
      ClarityResultId: new FormControl(data?.defaultParamValue ? data.defaultParamValue : ''),
      consistencyResultId: new FormControl(data?.defaultParamValue ? data.defaultParamValue : ''),
      presenceResultId: new FormControl(data?.defaultParamValue ? data.defaultParamValue : ''),
      reactionResultId: new FormControl(data?.defaultParamValue ? data.defaultParamValue : ''),
      gender: new FormControl(data?.normalValue[0]?.gender ? data.normalValue[0]?.gender : ''),
      resultType: new FormControl(data['resultType']),
      IsMachineResult: new FormControl(data['IsMachineResult'])

    });
  }
  // END FORM ARRAY
  getcolour() {
    let payload = {
      id: 1
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.colourData = res.response;
    });

  }
  getPositive() {
    let payload = {
      id: 3
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.positiveData = res.response;


    });

  }
  getClarity() {
    let payload = {
      id: 4
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.clarityData = res.response;
    });

  }
  getconsistency() {
    let payload = {
      id: 5
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.consistency = res.response;
    });

  }
  getpresence() {
    let payload = {
      id: 6
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.presence = res.response;

    });

  }
  getreaction() {
    let payload = {
      id: 7
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.reaction = res.response;
    });

  }

  GetAllStaff() {
    this.configurationService.GetStaff(this.BranchId).subscribe((res) => {
      this.StaffData = res.response;
      this.technologistdata = this.StaffData.filter(x => x.categoryId === 1);
      this.pathalogisttdata = this.StaffData.filter(x => x.categoryId === 2);
      this.microbiologistdata = this.StaffData.filter(x => x.categoryId === 5);
      this.radiologistdata = this.StaffData.filter(x => x.categoryId === 3);

    });
  }

  save() {
    this.submitted = true;
    if (this.resultForm && this.resultForm.valid) {
      var formdata = this.resultForm.getRawValue();
      if (formdata.PatientId > 0) {
        formdata.TechId = Number(formdata.TechId);
        formdata.PathId = Number(formdata.PathId);
        formdata.RadioId = Number(formdata.RadioId);
        formdata.MicroId = Number(formdata.MicroId);
        formdata.ResultDetailList = formdata.ResultDetailList.map((item) => {
          return {
            ...item,
            ColourResultId: (item.resultType == 1) && (item.ColourResultId != '') ? Number(item.ColourResultId) : 0,
            ClarityResultId:(item.resultType == 4) && item.ClarityResultId != '' ? Number(item.ClarityResultId) : 0,
            PositiveNegativeResultId:(item.resultType == 3) && item.PositiveNegativeResultId != '' ? Number(item.PositiveNegativeResultId) : 0,
            consistencyResultId:(item.resultType == 5) && item.consistencyResultId != '' ? Number(item.consistencyResultId) : 0,
            presenceResultId:(item.resultType == 6) && item.presenceResultId != '' ? Number(item.presenceResultId) : 0,
            reactionResultId:(item.resultType == 7) && item.reactionResultId != '' ? Number(item.reactionResultId) : 0,
            IsMachineResult: (item.resultType == 4) &&item.IsMachineResult != null ? item.IsMachineResult : false
          }
        })

        formdata.PerformingStaff = Number(formdata.PerformingStaff);

        formdata.orderDetIdList = this.OrderDetIdList;
        if (this.microbiologist == true && formdata.MicroId == 0) {
          this.toastr.warning("please select microbiologist");
        }
        else if (this.pathologist == true && formdata.PathId == 0) {
          this.toastr.warning("please select pathologist");
        }
        else if (this.technologist == true && formdata.TechId == 0) {
          this.toastr.warning("please select technologist");
        }
        else if (this.radiologist == true && formdata.RadioId == 0) {
          this.toastr.warning("please select radiologist");
        }
        else {

          this.isLoading = true;
          this.configurationService.SaveResultEntry(formdata).subscribe((res) => {
            this.submitted = false;
            this.result = res;
            if (this.result.status == 200 && this.result.message == 'Success') {
              this.isLoading = false;
              this.toastr.success("Result  added Successfully");
              this.getitemEmitter.emit({ status: true });

              this.close();
              this.clearForm();
            }
            else {
              this.isLoading = false;
              this.toastr.error("Failed : " + this.result.message);
            }

          })
        }
      }
    }
  }
  clearForm() {
    this.resultForm.reset({
      ResultId: 0,
      PatientId: 0,
      ResultDate: '',
      RformId: 0,
      Status: '',
      PublishDate: '',
      PublishUserId: 0,
      TechId: 0,
      PathId: 0,
      RadioId: 0,
      MicroId: 0,
      Remarks: '',
      ResNo: '',
      UserId: 0,
      ResultDetailList: [],
      Interpretation: '',
      PerformingLocation: '',
      PerformingStaff: 0,
      LabNo: '',
    })

  }
}
