import { Component, OnInit } from '@angular/core';
import { ConsultationsList } from '../../interfaces/ConsultationsList';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// loading Consultation Service
import { ConsultaionsService } from '../../services/consultations.service';

import { AllAppointmentsList } from '../../interfaces/AllAppointmentsList';

// loading Appointment Service
import { AppointmentsService } from '../../services/appointments.service';
import { ToastrService } from 'ngx-toastr';
import { PatientList } from '../../interfaces/PatientList';
import { Router } from '@angular/router';
// loading Patients Service
import { PatientService } from '../../services/patient.service';

import { ColDef, GridApi, GridOptions, RowDragEvent } from 'ag-grid-community';
import { GeneralActionComponent } from '../../general-action/general-action.component';
import { ConsultantDeskService } from '../../services/consultant.service';
import { SharedService } from '../../../../shared/shared.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Response } from '../../../../_Models/Response';
import { ConfigurationService } from '../../services/configuration.service';
import * as _ from 'lodash';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddDiseaseModalComponent } from '../modals/add-disease-modal/add-disease-modal.component';
import { SchemeList } from '../../interfaces/SchemeList';

@Component({
  selector: 'app-personalisationComponent ',
  templateUrl: './personalisation.component.html',
  styleUrls: ['./personalisation.component.scss'],

})
export class personalisationComponent implements OnInit {
  products: any = {};
  Disease: boolean = true;
  myPatients: boolean;
  myAppointment: boolean;
  myConsultation: boolean;
  Personalisations: boolean;
  Notifications: boolean;
  Changepassword: boolean;
  displayModal: boolean;
  displayModal1: boolean;
  displayModaluser: boolean;
  displaybranch: boolean;
  rowData: any = [];
  username = '';
  password = '';
  consultations: ConsultationsList[] = [];
  items: any = {};
  user_role: any;
  user_id: any;
  Id: any;
  appointments: AllAppointmentsList[] = [];
  users: any = [];
  selectedCities: any[] = [];
  availableProducts: any = [];
  selectedProducts: any = [];
  draggedProduct: any = [];
  patients: PatientList[] = [];
  columnDefs: ColDef[] = [];
  ServiceList: ColDef[] = [];
  MarksList: ColDef[] = [];
  DrugListing: ColDef[] = [];
  SketchListing: ColDef[] = [];
  AddPersonalisationForm: FormGroup;
  searchForm: FormGroup;
  attachdocuments: boolean;
  CancelAppId: number;
  printmodal: boolean;
  myFile: any;
  CancelAppointments: boolean;
  confirmblockingConsultants: any
  MarksListEdit: boolean;
  MarksListModal: boolean;
  //ag grid setup
  public gridApiDisease;
  public gridApiServiceItem;
  public gridApiDrug;
  public gridApiSketch;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.active == '0'; },
      'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    },

  }
  consultantDataDetails: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  appointmentDetails: any;
  consultationsDetails: any;
  router_url = this.router.url;
  active: string;
  blockorunblock: boolean;
  blockId: number;
  confirmblock: boolean;
  displayBasicUser: boolean;
  patientId: any;
  displayBasicdragdrop: boolean;
  regDocLocation: any;
  documentList: any;
  pagetype: any;
  patientmodal: any;
  gender: any;
  regDate: any;
  patientName: any;
  address: any;
  mobile: any;
  regNo: any;
  age: any;
  regDetails: PatientList[];
  regIdentities: any;
  historyconsent: boolean;
  ConsentHistory: any;
  consentHistoryList: any;
  consentHistoryListArabic: any;
  consentHistoryPatientName: any;
  consentHistorySignature: any;
  PostponeObj: any;
  consultationId: any;
  CancelledReason: any;
  symptomDetails: ConsultationsList[];
  ReasonVisit: boolean;
  symptomList: any[];
  dropdownSettings: IDropdownSettings = {};
  result: any;
  blockorunblockconsult: boolean;
  documentFile: File[];
  documentFile1: any;
  displayBasic: boolean;
  showWebcam: boolean;
  public statusBar;
  consentDate: any;
  myObservable$: Subject<unknown>;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  public submitted: boolean = false;
  generatedImage: string;
  minDate: any;

  rowGroupPanelShow = "always";
  OtherSymptom: boolean;
  consultantDataDetailsEvent: any[];
  BranchId: any;
  valueDate: Date;
  valueDate2: Date;
  FromDate: string;
  ToDate: string;
  minDate1 = new Date();
  appointmentDate: string;
  CurrentappointmentDate: string;
  AppointmentDataDetails: any;
  ServiceItems: boolean;
  DrugList: boolean;
  Sketch: boolean;
  cFormOrder: boolean;
  DhaLogin:boolean
  GetDiseasesById: any;
  Marks: boolean;
  GetMarksList: any;
  GetDrugList: any;
  marksId: void;
  GetServiceList: any;
  ServiceItemId: any;
  ServiceItemsModal: boolean;
  DiseasesId: any;
  DiseasesModal: boolean;
  DrugsModal: boolean;
  DrugsId: any;
  RemoveDiseases: any;
  DrugsRemove: any;
  GetSketchList: any;
  DHAUserName:string;
  DHAPassword:string;

  /////cForm Order declarations ////////////
  private dragStart: number = 0;
  private dragEnd: number = 0;

  public tabGridOptions = {
    pagination: false,
    rowDragEntireRow: true,
    rowDragManaged: true,
    onRowDragEnter: (event: RowDragEvent) => {
      this.dragStart = event.overIndex;
    },
    onRowDragEnd: (event: RowDragEvent) => {
     this.dragEnd = event.overIndex;
     this.onRearrangeTabOrder(event)
    }
  };
  public CformframeworkComponents = {
    buttonRender: GeneralActionComponent
  };
  public viewSwitch: boolean = false;
  private tabOrderGrid: GridApi;
  public tabOrderData: any[] = [];
  public tabOrderLoading: boolean = false;
  public tabOrderColDef: ColDef[] = [
    {
      headerName: "Tab Name",
      field: "displayName",
      minWidth: 200,
      filter: true,
      flex: 1,
      rowDrag: true,
    },
    {
      headerName: "Open",
      filter: false,
      resizable: false,
      minWidth:100,
      pinned:"right",
      sortable: false,
      unSortIcon: true,
      field: "id",
      flex: 2,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "consultantCform" },
    }
  ]

  public dropdownItems: any[] = [];

  schemeList = [];
  defaultScheme: any;
  functionPermissionData: any;

  constructor(
    private consultationService: ConsultaionsService,
    private appointmentService: AppointmentsService,
    private patientService: PatientService,
    private ConsultantDeskService: ConsultantDeskService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private ngxLoader: NgxUiLoaderService,
    private configurationService: ConfigurationService,
    private modalService:BsModalService,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
        localStorage.setItem('BranchId', this.BranchesId);
      }
      this.BranchId = localStorage.getItem('BranchId');

    });

    this.getState()

  }

  ngOnInit(): void {

    this.AddPersonalisationForm = this.formBuilder.group({

      blockReason: new FormControl(''),


    });
    // getting the values of user_role and user_id  from session
    this.user_role = localStorage.getItem('user_role');
    this.user_id = localStorage.getItem('user_id');
    this.Id = localStorage.getItem('Id');
    //Patients ag grid List
    this.columnDefs = [

      {
        headerName: "Disease Description",
        minWidth:180,
        field: 'diseaseDesc',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: false,
        flex: 2,
        editable: true
      },
      {
        headerName: "Action",
        maxWidth:100,
        filter: false,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "personalisationSerDisease" },
      },


    ]

    this.ServiceList = [

      {
        headerName: "Item Name",
        minWidth:180,
        field: 'itemName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: false,
        flex: 2,
        editable: true
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        // unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "personalisationServiceLis" },
      },




    ]

    this.MarksList = [

      {
        headerName: "Mark Description",
        minWidth:180,
        field: 'markDesc',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: false,
        flex: 2,
        editable: true
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        // unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "personalisationMarks" },
      },




    ]

    this.DrugListing = [
      {
        headerName: "Drug Description",
        minWidth:180,
        field: 'drugName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: true
      },
      {
        headerName: "Dosage",
        minWidth:180,
        field: 'dosage',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: true
      },
      {
        headerName: "Route",
        minWidth:180,
        field: 'routeDesc',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: true
      },
      {
        headerName: "Frequency",
        minWidth:180,
        field: 'freqDesc',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: false,
        flex: 2,
        editable: true
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        // unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "personalisationDrugs" },
      },
    ]

    this.SketchListing = [
      {
        headerName: "Sketch Name",
        minWidth:180,
        field: 'sketchName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: false,
        flex: 2,
        editable: true
      },
      // {
      //   headerName: "View Image",
      //   field: '',
      //   sortable: true,
      //   filter: true,
      //   enableRowGroup: true,
      //   resizable: true,
      //   flex: 2,
      //   editable: true
      // },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        // unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "SketchList" },
      },
    ]


    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };





    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };



    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.GetAllSymptomsDiseases();
    this.GetAllMarksList();
    this.GetAllDrugsList();
    this.GetAllServiceItems();
    this.GetAllSketches();
    this.GetConsultantScheme();
    this.functionPermission();
    // this.getState()
  }

  getState(){
    const state = this.router.getCurrentNavigation().extras.state
    if(state){
      this.onTab(state.data.tab)
    }else{
      this.onTab('1')
    }
  }


  onTab(number) {
    this.Disease = false;
    this.ServiceItems = false;
    this.DrugList = false;
    this.Marks = false;
    this.Sketch = false;
    this.cFormOrder = false;
    this.DhaLogin=false

    if (number == '1') {
      this.Disease = true;
    }
    else if (number == '2') {
      this.ServiceItems = true;
    }

    else if (number == '3') {
      this.DrugList = true;
    }
    else if (number == '4') {
      this.Marks = true;
    }
    else if (number == '5') {
      this.Sketch = true;
    }
    else if (number == '6') {
      this.cFormOrder = true;
    }
    else if (number == '7') {
      this.DhaLogin = true;
    } 


  }

  TabSelection() {
    this.Disease = false;
    this.ServiceItems = false;
    this.DrugList = false;
    this.Marks = false;
    this.Sketch = false;

  }

  //pagetitle to be displayed at the header
  Activetab(pagetitle, actionId) {
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    sessionStorage.setItem("pageTitle", pagetitle);
    if(pagetitle == 'Add Disease') {
      this.addDisease(-1, actionId);
    } else if(pagetitle == 'Add Service Items') {
      this.addServiceItems(actionId);
    } else if(pagetitle == 'Add Drugs') {
      this.addDrugs(actionId);
    } else if(pagetitle == 'Add Sketch') {
      this.addSketch(actionId);
    }
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
 }

 addDisease(diseaseId, actionId) {
  const foundElement = this.functionPermissionData.find(x => x.id === actionId)
  if (foundElement.id == actionId && foundElement.isAllowed == true) {
  let initialState = {
    diseaseId: diseaseId
  }
  let config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: true,
  }
  const modalRef = this.modalService.show(AddDiseaseModalComponent, Object.assign({}, config, { class: 'modal-md', initialState }));
  modalRef.content.diseaseEmitter.subscribe((response: any)=>{
    if(response.status){
      this.GetAllSymptomsDiseases();
    }
  })
    } else {
      this.toastr.warning("You are not allowed");
    }
 }

  GetAllSymptomsDiseases() {
    this.ConsultantDeskService.GetAllDiseseById(Number(this.Id)).subscribe((res) => {
      this.GetDiseasesById = res.response;
    });

  }
  GetAllMarksList() {
    this.ConsultantDeskService.GetAllMarksList(Number(this.Id), this.globals.HospitalId).subscribe((res) => {
      this.GetMarksList = res.response;
    });
  }

  GetAllDrugsList() {
    this.ConsultantDeskService.GetAllDrugsList(Number(this.Id),this.globals.HospitalId).subscribe((res) => {
      this.GetDrugList = res.response;
    });
  }

  GetAllServiceItems() {
    this.ConsultantDeskService.GetServiceItems(Number(this.Id), this.globals.HospitalId).subscribe((res) => {
      this.GetServiceList = res.response;
    });
  }
  GetAllSketches() {
    this.ConsultantDeskService.GetSketches(Number(this.Id), this.globals.HospitalId).subscribe((res) => {
      this.GetSketchList = res.response;
    });
  }

  MarksListModalView(id) {
    this.marksId = id.markId
    this.MarksListModal = true;
  }
  confirmremove() {

    this.ConsultantDeskService.DeleteConsultantMarks(Number(this.marksId)).subscribe((res) => {
      this.GetDrugList = res.response;
      this.result = res;
      {
        if (this.result.message == "Deleted") {
          this.ngxLoader.stop();

          this.toastr.success(" Marks Deleted successfully");
          this.GetAllMarksList();
          this.MarksListModal = false;
        }

        else {
          this.ngxLoader.stop();
          this.toastr.error("Failure: " + this.result.message);
        }

        this.submitted = false;
      }
    });
  }


  ServiceListModalView(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 419)
    if (foundElement.id == 419 && foundElement.isAllowed == true) {
    this.ServiceItemId = id.itemId
    this.ServiceItemsModal = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  confirmRemoveItem() {

    this.ConsultantDeskService.DeleteConsultantService(Number(this.ServiceItemId), Number(this.Id), this.globals.HospitalId).subscribe((res) => {
      this.GetDrugList = res.response;
      this.result = res;
      {
        if (this.result.message == "Success") {
          this.ngxLoader.stop();

          this.toastr.success(" Service Item Deleted successfully");
          this.GetAllServiceItems();
          this.ServiceItemsModal = false;
        }

        else {
          this.ngxLoader.stop();
          this.toastr.error("Failure: " + this.result.message);
        }

        this.submitted = false;
      }
    });
  }

  showCancelDiseases(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 417)
    if (foundElement.id == 417 && foundElement.isAllowed == true) {
    this.DiseasesId = id.diseaseId
    this.DiseasesModal = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  confirmRemoveDiseases() {

    this.ConsultantDeskService.DeleteDiseaseSign(Number(this.DiseasesId)).subscribe((res) => {
      this.RemoveDiseases = res.response;
      this.result = res;
      {
        if (this.result.message == "Success") {
          this.ngxLoader.stop();

          this.toastr.success(" Diseases Deleted successfully");
          this.GetAllSymptomsDiseases();
          this.DiseasesModal = false;
        }

        else {
          this.ngxLoader.stop();
          this.toastr.error("Failure: " + this.result.message);
        }

        this.submitted = false;
      }
    });
  }

  RemoveDrugs(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 422)
    if (foundElement.id == 422 && foundElement.isAllowed == true) {
    this.DrugsId = id.drugId
    this.DrugsModal = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  confirmRemoveDrugs() {
    this.ConsultantDeskService.DeleteDrugs(Number(this.DrugsId)).subscribe((res) => {
      this.DrugsRemove = res.response;
      this.result = res;
      {
        if (this.result.message == "success") {
          this.ngxLoader.stop();

          this.toastr.success(" Drugs Deleted successfully");
          this.GetAllDrugsList();
          this.DiseasesModal = false;
        }

        else {
          this.ngxLoader.stop();
          this.toastr.error("Failure: " + this.result.message);
        }

        this.submitted = false;
      }
    })
  }
  BlockDiseases(rowDatas) {
    this.DiseasesId = rowDatas.diseaseId
    this.active = rowDatas.active == 1 ? "Block" : "Unblock"
    this.blockId = Number(rowDatas.diseaseId);
    this.blockorunblock = true;
  }


  //confirming modal for block or unblock
  confirmblocking(blockId: any) {

    if (this.active == "Unblock") {
      this.ConfirmUnBlock();
    }
    else {
      this.confirmblock = true;

    }
  }
  //confirming block modal function
  ConfirmBlock() {
    let blockReason = this.AddPersonalisationForm.get("blockReason").value;
    this.ConsultantDeskService.BlockPatient(this.blockId, blockReason).subscribe((res) => {
      this.result = res;
      {
        if (this.result.message == "Success") {
          this.ngxLoader.stop();

          this.toastr.success(" Block successfully");

        }

        else {
          this.ngxLoader.stop();
          this.toastr.error("Failure: " + this.result.message);
        }

        this.submitted = false;
      }
      this.displayBasicUser = false;
    })
    this.confirmblock = false;
  }

  //confirming unblock modal function
  ConfirmUnBlock() {
    this.ConsultantDeskService.UnBlockPatient(this.blockId).subscribe((res) => {

      this.displayBasicUser = false;
    })
    this.confirmblock = false;
  }

  showModalDialog(patient: any) {
    const foundElement = this.functionPermissionData.find(x => x.id === 424)
    if (foundElement.id == 424 && foundElement.isAllowed == true) {
    this.documentList = patient.fileLocation;
    this.attachdocuments = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  OpenImage(filePath: string) {//document view
    window.open(filePath, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
  }
  onGridReadyDisease(params) {
    this.gridApiDisease = params.api;
  }
  onGridReadyServiceItem(params) {
    this.gridApiServiceItem = params.api;
  }
  onGridReadyDrug(params) {
    this.gridApiDrug = params.api;
  }
  onGridReadySketch(params) {
    this.gridApiSketch = params.api;
  }

  onFilterTextBoxChangedDisease() {
    this.gridApiDisease.setQuickFilter(
      (document.getElementById('filter-text-box-disease') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedServiceItem() {
    this.gridApiServiceItem.setQuickFilter(
      (document.getElementById('filter-text-box-serviceitem') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedDrug() {
    this.gridApiDrug.setQuickFilter(
      (document.getElementById('filter-text-box-drug') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedSketch() {
    this.gridApiSketch.setQuickFilter(
      (document.getElementById('filter-text-box-sketch') as HTMLInputElement).value
    );
  }

  ////// cform orders functions ////////////

  onGridReadyTabOrder(params){
    this.tabOrderGrid = params.api;
    this.getCformOrderData();
    this.getAllConsultantsList();
  }

  getAllConsultantsList(){
    this.configurationService.GetAllConsultants(this.globals.HospitalId).subscribe((res) => {
      let data = res.response;
      _.forEach(data, (consultant)=>{
        this.dropdownItems.push({
            label: consultant.consultantName,
            command: () => {
                this.getCformOrderData(consultant.consultantId);
            },
        })
      })
    });
  }

  getCformOrderData(consultantCopy?: any){
    let payload = {
      ConsultantId: consultantCopy ? consultantCopy : Number(this.Id),
      BranchId: this.globals.HospitalId
    }
    this.tabOrderGrid.showLoadingOverlay();
    this.ConsultantDeskService.getCFormOrder(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.tabOrderData = response.response;
          this.viewSwitch = response.response[0].emrPageView ? true : false;
          this.defaultScheme = response.response[0].defaultScheme;
        }else{
          this.toastr.error(response.errorMessage.message);
        }
        this.tabOrderGrid.hideOverlay();
      },error: (error: any)=>{
        this.toastr.error('Failed to load CForm tab order..! Try again');
        this.tabOrderGrid.hideOverlay();
      }
    })
  }

  onRearrangeTabOrder(event){
    if(this.dragStart < this.dragEnd){
      for(let i=this.dragStart; i< this.dragEnd; i++){
        this.tabOrderData[i] = this.tabOrderData[i+1];
        this.tabOrderData[i].objectOrder--
      }
    }else{
      for(let i=this.dragStart; i> this.dragEnd; i--){
        this.tabOrderData[i] = this.tabOrderData[i-1];
        this.tabOrderData[i].objectOrder++;
      }
    }
    this.tabOrderData[this.dragEnd] = event.node.data;
    this.tabOrderData[this.dragEnd].objectOrder = this.dragEnd+1;
  }
  
  tabOpenChange(row: any, isChecked: boolean){
    for(let i=0; i<this.tabOrderData.length; i++){
      if(this.tabOrderData[i].objectName === row.objectName){
        this.tabOrderData[i].openStatus = isChecked ? 1 : 0;
      }
    }
  }

  updateTabCformOrder(){
    const foundElement = this.functionPermissionData.find(x => x.id === 425)
    if (foundElement.id == 425 && foundElement.isAllowed == true) {
    let payload = {
      ConsultantId: Number(this.Id),
      BranchId: this.globals.HospitalId,
      UserId: Number(this.user_id),
      tsmlist: this.tabOrderData,
      EMRPageView: this.viewSwitch ? 1 : 0,
      DefaultScheme: Number(this.defaultScheme)
    }

    this.tabOrderLoading = true;
    this.ConsultantDeskService.saveCFormOrder(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == 'Success'){
          this.toastr.success('cForm orders data saved successfully.');
        }else{
          this.toastr.error(response.errorMessage.message);
        }
        this.tabOrderLoading = false;
      },error: (error: any)=>{
        this.toastr.error('Failed to save tab order..! Try again');
        this.tabOrderLoading = false;
      }
    })
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  GetConsultantScheme(){
    const consultantId = Number(localStorage.getItem('Id'));
    let data = {
      followup: false,
      inscovered: false,
      creditid:0,
      deptid: 0,
      mastermindId: 0,
    }
    this.consultationService.GetConsultantScheme(consultantId, this.globals.HospitalId, 0,data).subscribe((res) => {
      this.schemeList = res.response;
      this.schemeList.find(x => {
        if(x.isDefault == true) {
          this.defaultScheme = x.itemId;
        }
        else if(x.itemName == 'GP- CONSULTATION') {
          this.defaultScheme = x.itemId;
        }
      });
    })
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  addServiceItems(actionId) {
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
      this.router.navigate(["/addServiceItems"]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  addDrugs(actionId) {
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
      this.router.navigate(["/addDrugs"]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  editDrugs(drugId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 421)
    if (foundElement.id == 421 && foundElement.isAllowed == true) {
      this.router.navigate(["/addDrugs", drugId])
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  addSketch(actionId) {
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
      this.router.navigate(["/addSketch"])
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
}

