import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

declare let scanner;

@Component({
  selector: 'app-web-camera-modal',
  templateUrl: './web-camera-modal.component.html',
  styleUrls: ['./web-camera-modal.component.scss']
})
export class WebCameraModalComponent implements OnInit, OnDestroy {

  // private trigger: Subject<void> = new Subject<void>();

  // private myObservable$: Subject<unknown>;

  // public capturedImage: WebcamImage = null;

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  scanDocument(){
      scanner.scan(this.displayImagesOnPage,
      {
         "output_settings" :
         [
            {
               "type" : "return-base64",
               "format" : "jpg"
            }
         ]
      }
      );
  }

  private displayImagesOnPage(successful, mesg, response): any {
    if (!successful) { // On error
      console.error('Failed: ' + mesg);
      return;
    }

    // User canceled.
    if (successful && mesg !== null && mesg.toLowerCase().indexOf('user cancel') >= 0) {
      console.log('User canceled');
      return;
    }

    // returns an array of ScannedImage
    // function getScannedImages(response, includeOriginals, includeThumbnails)
    const that = this;
    const scannedImages = scanner.getScannedImages(response, true, false);
    for (let i = 0; (scannedImages instanceof Array) && i < scannedImages.length; i++) {
      const scannedImage = scannedImages[i];
      that.processScannedImage(scannedImage);
    }
  }

  /** Processes a ScannedImage */
  private processScannedImage(scannedImage): any {
    console.log(scannedImage)

    // scannedImage = this.typeFormatter.caseRequestImageFormat(scannedImage);

    // console.log(scannedImage)

    // this._scannedImages.push(scannedImage);
    // const elementImg = scanner.createDomElementFromModel({
    //   'name': 'img',
    //   'attributes': {
    //     'class': 'scanned',
    //     'src': scannedImage.src
    //   }
    // });
    // document.getElementById('images').appendChild(elementImg);

    // console.log(this._scannedImages);
  }

  // public get triggerObservable(): Observable<void> {
  //   return this.trigger.asObservable();
  // }

  // triggerSnapshot(): void {
  //   this.myObservable$ = new Subject();
  //   this.myObservable$.next();
  //   this.trigger.next();
  // }

  // cancelImage(){
  //   this.capturedImage = null;
  // }

  // handleImage(webcamImage: WebcamImage): void {
  //   console.log(webcamImage)

  //   this.capturedImage = webcamImage;

  //   const imageName = "patient.jpeg";// new Date().toString();
  //   const base64 = webcamImage.imageAsDataUrl.split('base64,');

  //   const imageBlob = this.dataURItoBlob(base64[1]);
  //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
  //   // this.documentFile1 = imageFile;

  //   // this.generatedImage = window.URL.createObjectURL(imageFile);

  // }

  // dataURItoBlob(dataURI) {
  //   const byteString = window.atob(dataURI);
  //   const arrayBuffer = new ArrayBuffer(byteString.length);
  //   const int8Array = new Uint8Array(arrayBuffer);
  //   for (let i = 0; i < byteString.length; i++) {
  //     int8Array[i] = byteString.charCodeAt(i);
  //   }
  //   const blob = new Blob([int8Array], { type: 'image/jpeg' });
  //   return blob;
  // }


  ngOnDestroy(): void {
    
  }

}
