import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ServicePoint } from "../../client/interfaces/ServicePoint";
import { SubLocationSettingsService } from "../../configurations/sub-location-settings/sub-location-settings.service";
import { SharedService } from "../../../shared/shared.service";
import { noop, Observable, Observer, of, Subscription } from 'rxjs';
import { globalvars } from "../../../shared/models/GlobalVars";
import { ReportsService } from '../services/reports.service';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { DomSanitizer } from "@angular/platform-browser";


@Component({
  selector: 'app-treatment-details',
  templateUrl: './treatment-details.component.html',
  styleUrls: ['./treatment-details.component.scss']
})
export class TreatmentDetailsComponent implements OnInit {
  form: FormGroup;
  ServicePointList: ServicePoint[] = [];
  globals: globalvars = new globalvars();
  BranchesId: any;
  subscription: Subscription;
  isLoading=false;
  src :any= '';
  private baseUrl = "";
  isPdfLoaded:boolean=false;


  constructor(
    private datepipe: DatePipe,
    private sharedservice: SharedService,
    private SubLocationSettingsService:SubLocationSettingsService,
    private ReportsService:ReportsService,
    private configurationManager: ConfigurationManager,
    private sanitizer: DomSanitizer,

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;


   }

  ngOnInit(): void {
    this.form=new FormGroup({
      fromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      toDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      sPointId:new FormControl(0),
    });
    this.GetServicePoints();


  }

  GetServicePoints() {
    //GetAssociativeLocation
    let input = {
      SPointId: 0,
      ShowAll: 0,
      BranchId: this.globals.HospitalId,
    };
    this.SubLocationSettingsService.GetServicePoints(input).subscribe((res) => {
      this.ServicePointList = res.response;
    });
  }
  generateReport(){
    this.isLoading=true;
    let input=this.form.getRawValue();

    if (typeof (input.fromDate) == "object") {
      let dobConverted = this.datepipe.transform(input.fromDate, 'dd-MM-yyyy');
      input.fromDate = dobConverted;
    }

    if (typeof (input.toDate) == "object") {
      let dobConverted = this.datepipe.transform(input.toDate, 'dd-MM-yyyy');
      input.toDate = dobConverted;
    }

    if(input.sPointId){
      input.sPointId=Number(input.sPointId);
    }
    if( this.BranchesId){
      input.BranchId=Number(this.BranchesId);
    }

    this.ReportsService.GetTreatmentReport(input).subscribe((res) => {
      this.isLoading=false;
      if(res.status==200){
        let response=res.response;
        this.src=response[0].reportPath;
        this.src=this.baseUrl+this.src;
        this.src=this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
        this.isPdfLoaded=true;
      }
     });

  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }


}
