import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ResultModalComponent } from '../modals/result-modal/result-modal.component';
import { ResultparameterComponent } from '../modals/result-parameter/result-parameter.component';
import { ConfigurationService } from '../../client/services/configuration.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { PreviewResultFormComponent } from '../modals/preview-result-form/preview-result-form.component';
import { ToastrService } from 'ngx-toastr';
import { ResultFormHeadersComponent } from '../modals/result-form-headers/result-form-headers.component';


@Component({
  selector: 'app-result-form',
  templateUrl: './result-form.component.html',
  styleUrls: ['./result-form.component.scss']
})
export class ResultFormComponent implements OnInit {
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;


  @ViewChild("template") modaltemp: any;
  @ViewChild("template2") modaltemp2: any;
  @ViewChild("template3") modaltemp3: any;

  resultForm: FormGroup;
  parameterForm: FormGroup;

  public gridApi;
  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];

  resultFormFata: any;
  rowGroupPanelShow = "always";
  public context;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    // rowClassRules: {
    // },

  }
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  resultPopupHeading: String = "Add Result Form";
  parameterPopupHeading: String = "Result Parameter List"
  addParameterPopupHeading: String = "Add Parameter"
  isLoading = false;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  public user_id: string;
  functionPermissionData: any;

  constructor(
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private sharedservice: SharedService,
    private toastr: ToastrService,

  ) {
    this.user_id = localStorage.getItem('user_id');

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {
    this.resultForm = new FormGroup({
    });
    this.context = { componentParent: this };

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.columnDefs = [

      {
        headerName: "Result Form",
        minWidth: 180,
        field: "rformName",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "resultFormAction" },
      },

    ]

    this.getResultForm();
    this.functionPermission();

  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  openModalCompany(template: TemplateRef<any>) {
    this.resultPopupHeading = "Add Result Form";
    this.modalRef = this.modalService.show(template);
  }
  editResultForm(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 161)
    if (foundElement.id == 161 && foundElement.isAllowed == true) {
    let initialState = {
      resultPopupHeading: "Edit Result Form",
      rformId: data.rformId,
      rformName: data.rformName,
      rfooter: data.rfooter,
      technologist: data.technologist,
      pathologist: data.pathologist,
      radiologist: data.radiologist,
      microbiologist: data.microbiologist,
      BranchId: this.BranchesId,
      UserId: this.user_id,

    }

    const modalRef = this.modalService.show(ResultModalComponent, Object.assign({}, { initialState }))
    // Object.assign({}, { class: "gray modal-lg" })
    modalRef.content.resultEmitter.subscribe((res) => {
      console.log(res)
      if (res) {
        this.getResultForm()
      }
    })

    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  resultFormParameter(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 163)
    if (foundElement.id == 163 && foundElement.isAllowed == true) {
    let initialState = {
      RformId: data.rformId,
      UserId: this.user_id
    }

    const modalRef = this.modalService.show(ResultparameterComponent, Object.assign({ class: "gray modal-xl" }, { initialState }))
    // Object.assign({}, { class: "gray modal-lg" })
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  editResultParameterForm(data) {
    this.addParameterPopupHeading = "Add Parameter";

    this.modalRef3 = this.modalService.show(
      this.modaltemp3, { class: "gray modal-xl" });

  }
  addserviecItemMoadalopen() {
    const foundElement = this.functionPermissionData.find(x => x.id === 160)
    if (foundElement.id == 160 && foundElement.isAllowed == true) {
    let initialState = {
      "BranchId": this.BranchesId,
      "UserId": this.user_id,
      resultPopupHeading: "Add Result Form",

    }
    const modalRef = this.modalService.show(ResultModalComponent, Object.assign({}, { initialState }))
    // selected row keeping after loading

    modalRef.content.resultEmitter.subscribe((res) => {
      console.log(res)
      if (res) {
        this.getResultForm()
      }
    })
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }
  getResultForm() {
    let input = {
      "RformId": 0,
      "BranchId": this.BranchesId,

    }
    this.configurationService.getResultForm(input).subscribe((res) => {
      this.resultFormFata = res.response;
    });

  }
  previewResultForm(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 162)
    if (foundElement.id == 162 && foundElement.isAllowed == true) {
    let initialState = {
      "BranchId": this.BranchesId,
      "RformId": data.rformId,
      "rformName": data.rformName,
      "microbiologist":data.microbiologist,
      "pathologist":data.pathologist,
      "radiologist":data.radiologist,
      "technologist":data.technologist
    }

    const modalRef = this.modalService.show(PreviewResultFormComponent, Object.assign({ class: "gray modal-xl" }, { initialState }))
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  addtemplate(consultantId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 164)
    if (foundElement.id == 164 && foundElement.isAllowed == true) {
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  resultFormHeader(data) {

    let initialState = {
      RformId: data.rformId,
      UserId: this.user_id
    }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }

    const modalRef = this.modalService.show(ResultFormHeadersComponent, Object.assign({}, config,{ class: "gray modal-xl" }, { initialState }))
    // Object.assign({}, { class: "gray modal-lg" })
    } 
  

}