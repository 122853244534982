import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from '../../emr/services/emr.service';
import { BasicPatientInfo } from '../interfaces/BasicPatientInfo';
import { fromEvent } from 'rxjs';
import { timer } from 'rxjs';
import { merge } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { FollowUpStatusComponent } from '../follow-up-status/follow-up-status.component';
import { Observable } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { FollowUpTreatmentDetailsComponent } from '../follow-up-treatment-details/follow-up-treatment-details.component';

@Component({
  selector: 'app-emr-follow-up',
  templateUrl: './emr-follow-up.component.html',
  styleUrls: ['./emr-follow-up.component.scss']
})
export class EmrFollowUpComponent implements OnInit {

  @Input() IsView;
  public SelectedMenu:string="";
  @ViewChild( FollowUpStatusComponent ) TS:  FollowUpStatusComponent ;
  @ViewChild( FollowUpTreatmentDetailsComponent ) PC:  FollowUpTreatmentDetailsComponent ;
  constructor(

  ) {


   }

  ngOnInit(): void {
    this.ChangeFrame('TS');
    this.bindKeypressEvent().subscribe(($event: KeyboardEvent) => this.onKeyPress($event));
  }
  onKeyPress($event: KeyboardEvent) {
    if(($event.altKey || $event.metaKey) && $event.keyCode == 84)
    this.ChangeFrame('TS')

    if(($event.altKey || $event.metaKey) && $event.keyCode == 	80)
    this.ChangeFrame('PC')

  }


  private bindKeypressEvent(): Observable<KeyboardEvent> {
    const eventsType$ = [
      fromEvent(window, 'keypress'),
      fromEvent(window, 'keydown')
  ];
     // we merge all kind of event as one observable.
     return merge(...eventsType$)
     .pipe(
         // We prevent multiple next by wait 10ms before to next value.
         debounce(() => timer(10)),
         // We map answer to KeyboardEvent, typescript strong typing...
         map(state => (state as KeyboardEvent))
     );

  }

  ChangeFrame(tab:string)
  {

    let activaeTab= this.SelectedMenu;
    switch(activaeTab)
    {
      case  "TS":
      {
       this.TS.SaveTreatMent();
        break;
      }
      case  "PC":
      {
        this.PC.saveTreatment();
        break;
      }


    }

    this.SelectedMenu=tab;
  }


}
