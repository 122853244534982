import { Component, OnInit } from '@angular/core';
import { ConsultationsList } from '../../../interfaces/ConsultationsList';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
// loading Consultation Service
import { ConsultaionsService } from '../../../services/consultations.service';
import { noop, Observer, of } from 'rxjs';
import { AllAppointmentsList } from '../../../interfaces/AllAppointmentsList';
import { Response } from '../../../../../_Models/Response';
// loading Appointment Service
import { AppointmentsService } from '../../../services/appointments.service';
import { ToastrService } from 'ngx-toastr';
import { PatientList } from '../../../interfaces/PatientList';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
// loading Patients Service
import { PatientService } from '../../../services/patient.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../general-action/general-action.component';
import { ConsultantDeskService } from '../../../services/consultant.service';
import { SharedService } from '../../../../../shared/shared.service';
import { globalvars } from '../../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { EMRService } from '../../../../emr/services/emr.service';
import { Drugs } from '../../../../emr/interfaces/Drugs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../../../assets/configuration/configuration-manager';
import { ConfigurationService } from "../../../services/configuration.service";
import * as _ from 'lodash';
interface drugs {
  drugId: number;
  drugName: string;
  branchId: number
}
@Component({
  selector: 'app-addDrugsComponent ',
  templateUrl: './add_drugs.component.html',
  styleUrls: ['./add_drugs.component.scss'],

})

export class addDrugsComponent implements OnInit {
  products: any = {};
  Disease: boolean;
  myPatients: boolean;
  myAppointment: boolean;
  myConsultation: boolean;
  Personalisations: boolean;
  Notifications: boolean;
  Changepassword: boolean;
  displayModal: boolean;
  displayModal1: boolean;
  displayModaluser: boolean;
  displaybranch: boolean;
  rowData: any = [];
  username = '';
  password = '';
  consultations: ConsultationsList[] = [];
  items: any = {};
  user_role: any;
  user_id: any;
  Id: any;
  appointments: AllAppointmentsList[] = [];
  users: any = [];
  selectedCities: any[] = [];
  availableProducts: any = [];
  selectedProducts: any = [];
  draggedProduct: any = [];
  patients: PatientList[] = [];
  columnDefs: ColDef[] = [];
  columnDefsapp: ColDef[] = [];
  columnDefconsult: ColDef[] = [];
  searchForm: FormGroup;
  attachdocuments: boolean;
  CancelAppId: number;
  printmodal: boolean;
  myFile: any;
  CancelAppointments: boolean;
  confirmblockingConsultants: any
  //ag grid setup
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.active == '0'; },
      'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    },

  }
  consultantDataDetails: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  appointmentDetails: any;
  consultationsDetails: any;
  router_url = this.router.url;
  active: string;
  blockorunblock: boolean;
  blockId: number;
  confirmblock: boolean;
  displayBasicUser: boolean;
  patientId: any;
  displayBasicdragdrop: boolean;
  regDocLocation: any;
  documentList: any;
  pagetype: any;
  patientmodal: any;
  gender: any;
  regDate: any;
  patientName: any;
  address: any;
  mobile: any;
  regNo: any;
  age: any;
  regDetails: PatientList[];
  regIdentities: any;
  historyconsent: boolean;
  ConsentHistory: any;
  consentHistoryList: any;
  consentHistoryListArabic: any;
  consentHistoryPatientName: any;
  consentHistorySignature: any;
  PostponeObj: any;
  consultationId: any;
  CancelledReason: any;
  symptomDetails: ConsultationsList[];
  ReasonVisit: boolean;
  symptomList: any[];
  dropdownSettings: IDropdownSettings = {};
  result: any;
  blockorunblockconsult: boolean;
  documentFile: File[];
  documentFile1: any;
  displayBasic: boolean;
  showWebcam: boolean;
  public statusBar;
  consentDate: any;
  myObservable$: Subject<unknown>;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  generatedImage: string;
  minDate: any;
  modalRef2?: BsModalRef;
  rowGroupPanelShow = "always";
  OtherSymptom: boolean;
  consultantDataDetailsEvent: any[];
  BranchId: any;
  valueDate: Date;
  valueDate2: Date;
  FromDate: string;
  ToDate: string;
  minDate1 = new Date();
  appointmentDate: string;
  CurrentappointmentDate: string;
  AppointmentDataDetails: any;
  ServiceItems: boolean;
  DrugList: boolean;
  Marks: boolean;
  Sketch: boolean;
  GetSymptoms: any;
  GetSymptomsVitalSign: any;
  SymptomsVitalSign: any[];
  GetDiseasesIcd: any;
  DrugsForm: FormGroup;
  GetDrugsListing: any;
  LocationMasters: any[][];
  formmap: FormGroup;
  GetFrequencyListing: any;
  FrequencyId: number;
  maploc: any;
  public selectoptions: any;
  SaveDrugsResponse: any;
  submitted: boolean;
  DrugsId: any;
  DrugsResponse: any;
  GetRouteListing: any;
  GetDosageListing: any;
  search?: string;
  DrugRow: Drugs[];
  suggestions$?: Observable<drugs[]>;
  private baseUrl: string = "";
  errorMessage?: string;
  isShowSelDrug: boolean = true;
  constructor(
    private http: HttpClient,
    private consultationService: ConsultaionsService,
    private appointmentService: AppointmentsService,
    private patientService: PatientService,
    private ConsultantDeskService: ConsultantDeskService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private ngxLoader: NgxUiLoaderService,
    private EMRService: EMRService,
    private configurationManager: ConfigurationManager,
    private ConfigurationService: ConfigurationService,

  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
        localStorage.setItem('BranchId', this.BranchesId);
      }
      this.BranchId = localStorage.getItem('BranchId');

    });

    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.activatedRoute.params.subscribe((params) => {
      this.DrugsId = Number(params.id);
    });
  }




  ngOnInit(): void {

    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.search);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          return this.http.post<Response<drugs[]>>(
            `${this.baseUrl}api/EMR/GetDrugsAutoComplete`, {
            "drugName": query,
            "branchId": this.BranchesId,
            //"consultantId":this.EMRPatientInfo.ConsultantId
            "consultantId": 0,
            TradeName: '',
            ScientificName: ''
          }).pipe(
            map((data) => data && data.response || []),
            tap(() => noop, err => {
              this.errorMessage = err && err.message || 'Something goes wrong';
            })
          );
        }

        return of([]);
      })
    );
    this.selectoptions = {
      //multiple: true,
      closeOnSelect: true,
    };

    // getting the values of user_role and user_id  from session

    this.user_role = localStorage.getItem('user_role');
    this.user_id = localStorage.getItem('user_id');
    this.Id = localStorage.getItem('Id');

    this.GetFrequency();
    this.GetDosage();
    this.GetRoute();
    //Patients ag grid List
    this.columnDefs = [
      {
        headerName: "Disease Description",
        field: 'regDate',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        editable: true
      },



    ]

    this.formmap = new FormGroup({
      drugDetails: new FormArray([]),
      UserId: new FormControl(Number(this.user_id)),
    });

    if (this.DrugsId) {
      this.GetFetchingDetails();
    }
  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    return new FormGroup({
      drugId: new FormControl(data["drugId"]),
      drugName: new FormControl(data["drugName"]),
      routeId: new FormControl(data["routeId"]),
      duration: new FormControl(data["duration"], Validators.required),
      freqId: new FormControl(data["freqId"] > 0 ? data['freqId'] : '', Validators.required),
      DurationMode: new FormControl(data["durationMode"]),
      DosageId: new FormControl(data["dosageId"] > 0 ? data['dosageId'] : '', Validators.required),
      qty: new FormControl(data["qty"]),
    });
  }

  public addMapFormGroup(data: any[] = [], saved = 0) {

    const Drugs = this.formmap.get("drugDetails") as FormArray;
    if (data.length > 0) {
      data.forEach((element, i) => {
        Drugs.push(this.createListGroup(element, saved));
      });

    } else if (data) {
      Drugs.push(this.createListGroup(data, saved));
    }

    this.search = "";



  }
  onTab(number) {
    this.Disease = false;
    this.ServiceItems = false;
    this.DrugList = false;
    this.Marks = false;
    this.Sketch = false;

    if (number == '1') {
      this.Disease = true;
    }
    else if (number == '2') {
      this.ServiceItems = true;
    }

    else if (number == '3') {
      this.DrugList = true;
    }
    else if (number == '4') {
      this.Marks = true;
    }
    else if (number == '5') {
      this.Sketch = true;
    }


  }
  //Reseting Form
  ClearForm() {

    this.formmap.reset({

    });
    this.formmap.clearValidators();

  }

  TabSelection() {
    this.Disease = false;
    this.ServiceItems = false;
    this.DrugList = false;
    this.Marks = false;
    this.Sketch = false;

  }

  //pagetitle to be displayed at the header
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);

  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  GetAllSymptomsDiseases() {
    this.ConsultantDeskService.GetDiseaseSymptoms(this.Id).subscribe((res) => {
      this.GetSymptoms = res.response;
    });

  }
  GetDiseaseVitalSigns() {
    this.ConsultantDeskService.GetDiseaseVitalSigns(this.Id).subscribe((res) => {
      this.GetSymptomsVitalSign = res.response;

    });
  }
  isAllCheckBoxChecked() {
    return this.products.every(p => p.checked);
  }
  checkAllCheckBox(ev: any) { // Angular 13
    this.products.forEach(x => x.checked = ev.target.checked)
  }



  GetFrequency() {
    let ShowAll = 0;
    this.ConfigurationService
      .GetFrequency(this.globals.HospitalId, ShowAll)
      .subscribe((res) => {
        this.GetFrequencyListing = res.response;
        this.GetFrequencyListing.forEach((a) => {
          (a.id = Number(a.freqId)), (a.text = a.freqDesc);
        });
      });
  }
  GetRoute() {

    this.ConsultantDeskService.GetRoute(this.globals.HospitalId).subscribe((res) => {
      this.GetRouteListing = res.response;
      this.GetRouteListing.forEach((a) => {
        (a.id = Number(a.routeId)), (a.text = a.routeDesc);
      });

    });
  }


  GetDosage() {
    let ShowAll = 0;
    this.ConfigurationService
      .GetDosage(this.globals.HospitalId, ShowAll)
      .subscribe((res) => {
        this.GetDosageListing = res.response;
        this.GetDosageListing.forEach((a) => {
          (a.id = Number(a.dosageId)), (a.text = a.dosageDesc);
        });
      });
  }


  selectcheckFredays(event) {
    let Frquency = Number(event.target.defaultValue)
    this.FrequencyId = Frquency
  }


  insertDrugs() {
    let DrugId = 0;
    this.maploc = this.formmap.getRawValue();
    let drugDetails = this.formmap.get('drugDetails') as FormArray;
    let invalid = false;
    _.forEach(drugDetails.controls, (drug: FormGroup)=>{
      const controls = drug.controls;
      if(drug.invalid){
        Object.keys(controls).forEach(control=>{
          controls[control].markAllAsTouched();
        })
        invalid = true;
      }
    })
    if(invalid){
      this.toastr.error('Fill all required fields..!');
      return
    }
    this.maploc.drugDetails.forEach((a) => {
      a.DosageId = Number(a.DosageId);
      a.routeId = Number(a.routeId);
      a.freqId = Number(a.freqId);
      a.ConsultantId = Number(this.Id);
      // ConsultantId: new FormControl(Number(this.Id))
      if (this.DrugsId > 0) {
        a.IsUpdate = 1;
      }
      else {
        a.IsUpdate = 0;
      }

    });


    this.ConsultantDeskService.InsertSaveDrugs(this.maploc).subscribe((res) => {
      this.SaveDrugsResponse = res.response;
      this.result = res;
      {
        if (this.result.message == "Success") {
          this.ngxLoader.stop();
          if (DrugId == 0) {
            this.toastr.success("Drugs added successfully");
          }
          else if (this.DrugsId > 0) {
            this.toastr.success("Drugs Updated successfully");
          }
        }
        else {
          this.ngxLoader.stop();
          this.toastr.error("Failure: " + this.result.message);
        }
        this.submitted = false;
      }
    });

  }

  GetFetchingDetails() {
    this.isShowSelDrug = false;
    if (this.DrugsId) {
      this.ConsultantDeskService.FetchingDrugsDetails(this.Id, this.DrugsId).subscribe((res) => {
        this.DrugsResponse = res.response;
        this.addMapFormGroup(res.response);
      });
    } else {
      this.addMapFormGroup();
    }
  }


  LoadDrug(drugInfo) {
    let input = {
      "consultantId": 0,
      "drugId": drugInfo.drugId
    };
    input
    this.EMRService.GetConsultantDrugs(input).subscribe(
      (res) => {
        if (res.status == 200) {
          this.DrugRow = res.response;
          this.addMapFormGroup(this.DrugRow);
        }
      });
  }

  // for geting all form items


  checkValue(i, formValue) {
    let form = this.formmap.controls["drugDetails"].value;
    if (formValue == "duration") {
      let duration = form[i].duration;
      if (!duration) {
        this.toastr.warning(
          "Warning: " + "Duration cannot be empty, It may cause error"
        );
      }
    }

    if (formValue == "qty") {
      let qty = form[i].qty;
      if (!qty) {
        this.toastr.warning(
          "Warning: " + "Quantity cannot be empty, It may cause error"
        );
      }
    }
  }

  RemoveFromDrugList(index) {
    var ValveTypes = new FormArray([]);
    ValveTypes = this.formmap.get("drugDetails") as FormArray;
    ValveTypes.removeAt(index);
  }

  goTo(){
    const data = { tab: '3' };
    // Navigate to the target route with state
    this.router.navigate(['/personalisation'], {state : {data}});
  }

}

