import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RegDocLocation } from '../../../interfaces/RegDocLocation';
import { PatientService } from '../../../services/patient.service';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng-select2';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.scss']
})
export class AttachmentModalComponent implements OnInit {

  documentList: RegDocLocation[] = [];
  displayBasicdragdrop: boolean;
  // patientId: any;
  regDocLocation: RegDocLocation[] = [];
  attachdocuments: boolean;
  departmentsData: Array<Select2OptionData> = [];
  public documentFile: File[] = null;
  public documentFile1: File = null;
  result: any;
  patientId: any;
  attachDoc: FormGroup;
  @Input() rowDatas;
  public docEmitter: EventEmitter<any> = new EventEmitter();

  // attachmentTypeList = [
  //   {DocId: 1, DocumentName: "Passport"},
  //   {DocId: 2, DocumentName: "EmiratesId"},
  //   {DocId: 3, DocumentName: "Visa"},
  //   {DocId: 4, DocumentName: "other"}
  // ];

  attachmentTypeList = [];


  constructor(public modalRef: BsModalRef,
              private patientService: PatientService,
              private toastr: ToastrService,
              ) { }

  ngOnInit(): void {
    this.attachDoc = new FormGroup({
      DocId: new FormControl('', Validators.required),
    });
    this.patientId = this.rowDatas.patientId
    this.attachments(this.rowDatas);
    this.getDocumentType();
  }

  close() {
    this.attachDoc.reset();
    this.docEmitter.emit({
      status: 'closed',
    });
    this.modalRef.hide();
  }

  attachments(rowDatas) {
    this.patientId = rowDatas.patientId;
    this.documentList=[];

    this.displayBasicdragdrop = true;
    if (this.patientId) {
      this.patientService.GetPatientDocuments(Number(this.patientId)).subscribe((res) => {
        this.regDocLocation = res.response;
        this.documentList = this.regDocLocation[0].regDocLocation

      },
        (error) => {
          this.toastr.error("Failure: " + error);
        })
    }

  }

  OpenImage(filePath: string) {//document view
    window.open(filePath, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
  }

  DeleteUploadedDoc(eventparam,docId) {
    if (confirm("Are you sure that you want to delete this file ?")) {
      this.patientService.DeleteUploadedDoc(Number(docId)).subscribe((res) => {
        this.result = res;
        if (this.result.message == 'success') {
          this.toastr.success("The file deleted successfully");
          this.attachments(this.rowDatas);
        }
        else {
          this.toastr.error("Failure: " + this.result.message);
        }

      },
        (error) => {
          this.toastr.error("Failure: " + error);
        });
    }
  }

  async onUpload(event,fileUpload) {
    this.attachDoc.markAllAsTouched();
    this.documentFile = <File[]>event.files;
    const formData = new FormData();

    if (this.documentFile) {
      formData.append('DocumentName', this.documentFile[0]);
    }

    formData.append('PatientJson', JSON.stringify({ 'PatientId': this.patientId, 'DocId': this.attachDoc.controls.DocId.value}));
   
    if(this.attachDoc.controls.DocId.value != "") {
      await this.patientService.GetPatientFiles(formData).subscribe(async (res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Files Uploaded successfully");
          this.attachments(this.rowDatas);
          fileUpload.clear();
        } else {
          this.toastr.error("Files Uploading failed");
        }
      });
    } else{
      this.toastr.error("Please select the attachment type");
    }
    this.displayBasicdragdrop = false;
    this.documentList=[];
    await this.attachDoc.reset();
  }

  setDocType(event) {
    this.attachDoc.controls.DocId.setValue(event); 
  }

  getDocumentType() {
    let payLoad = {
      "DocId":0,
      "ShowAll":0
   }
    this.patientService.getDocumentType(payLoad).subscribe((res) => {
      this.attachmentTypeList = res.response;
    })
  }

}
