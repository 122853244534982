import { Component, OnInit, TemplateRef } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Router } from "@angular/router";

@Component({
  selector: "app-general-action",
  templateUrl: "./general-action.component.html",
  styleUrls: ["./general-action.component.scss"],
})
export class GeneralActionComponent implements ICellRendererAngularComp {
  public params: any;
  public pagetype: string;
  public returnCall: boolean = false;
  items: any[] = [];
  selectedValues: boolean;
  appId: any;
  checkvalue: any[] = [];
  orderDetail: any[] = [];
  //data=this.params.data
  constructor(private router: Router) { }

  agInit(params: any): void {
    this.params = params;
    this.pagetype = this.params.PageType;
    this.returnCall = params.returnFunctionCall ? true : false;
    console.log("params",this.params)
    //////////////////CONSULTATION LIST////////////////////////
    if (this.pagetype == "ConsultationList") {
      this.items = [
        
        {
          label: "Open Credit",
          command: (x: any) => {
            this.params.context.componentParent.editCredit(
              this.params.data
            );
          },
          
        },
        { separator: true },
        {
          label:
            this.params.data.status == "Waiting"
              ? "Cancel Consultation"
              : this.params.data.status == "Cancelled"
                ? "Cancelled"
                : "Cancel Consultation",
          command: (x: any) => {
            this.params.context.componentParent.showBlockUnblock(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" || this.params.data.status == "Finished" ? true : false,
        },
        { separator: true },
        {
          label: "Treatment Summary",
          command: (x: any) => {
            this.params.context.componentParent.treatmentDetails(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        { separator: true },
        // {
        //   label: "Occupational EMR",
        //   command: (x: any) => {
        //     this.params.context.componentParent.PrintStickerFormat(
        //       this.params.data
        //     );
        //   },
        //   disabled: this.params.data.status == "Cancelled" ? true : false,
        // },
        // { separator: true },

        {
          label: "Patient EMR",
          command: (x: any) => {
            this.params.context.componentParent.GoToEmr(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        { separator: true },        {
          label: "Physiotherapy",
          command: (x: any) => {
            this.params.context.componentParent.GoToApplyPhysiotherapy(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        { separator: true },

        // {
        //   label: "Print",
        //   command: (x: any) => {
        //     this.params.context.componentParent.PrintStickerFormat(
        //       this.params.data, "Print"
        //     );
        //   },
        //   disabled: this.params.data.status == "Cancelled" ? true : false,
        // },
        // { separator: true },

        {
          label: "Opt-in/ Opt-out Consent Form",
          command: (x: any) => {
            this.params.context.componentParent.nabithConsent(this.params.data);
          },
          disabled: this.params.data.consentOptionId == 2 ? true : false,
        },
        { separator: true },

        {
          label: "Unified ClaimForm",
          command: (x: any) => {
            this.params.context.componentParent.PrintStickerFormat(
              this.params.data, "ClaimForm"
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        { separator: true },
        {
          label: "Change Credit",
          command: (x: any) => {
            this.params.context.componentParent.changeConsultationCredit(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,

        },
        { separator: true },
        {
          label: "Reason For Visit",
          command: (x: any) => {
            this.params.context.componentParent.ReasonForVisitModal(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        { separator: true },
        {
          label:
            this.params.data.status == "Waiting"
              ? "No Cancel Reason"
              : this.params.data.status == "Cancelled"
                ? "Reason For Cancelled"
                : "Reason For Cancelled",
          command: (x: any) => {
            this.params.context.componentParent.ReasonForCancellation(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Waiting" || this.params.data.status == "OnGoing" ? true : false,
        },
        { separator: true },
        {
          label: "Insurance consent",
          command: (x: any) => {
            this.params.context.componentParent.SignConsent(this.params.data, "SC", 225);
          },
        },
        { separator: true },
        {
          label: "Patient consent",
          command: (x: any) => {
            this.params.context.componentParent.SignConsent(this.params.data, "PC", 226);
          },
        },
        { separator: true },
        {
          label: "Informed consent",
          command: (x: any) => {
            this.params.context.componentParent.SignConsent(this.params.data, "IC", 227);
          },
        },
        { separator: true },
        {
          label: "Attachments",
          command: (x: any) => {
            this.params.context.componentParent.attachments(this.params.data);
          },
        },
        { separator: true },
        {
          label: "Health Summary Chart",
          command: (x: any) => {
            this.params.context.componentParent.showHealthSummary(this.params.data);
          }
        }

      ];
    }
    ///////////////////////////CONSULTATION LIST ENDS///////////////////
    //   REGISTRATION LIST STARTS
    else if (this.pagetype == "RegistrationList") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let patientId = this.params.data.patientId;
            // this.router.navigate(["/AddRegistration", patientId]);
            this.params.context.componentParent.editRegistration(patientId);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },

        { separator: true },
        {

          label: this.params.data.active == 0 ? "Unblock" : "Block",

          command: (x: any) => {
            this.params.context.componentParent.showBlockUnblock(
              this.params.data
            );
          },
          //disabled: this.params.data.active == 0 ? true : false,
        },

        { separator: true },
        {
          label: "Print Registration Card",
          command: (x: any) => {
            // let patientId = this.params.data.patientId;
            // this.router.navigate(["/PatientPrint", patientId]);
            this.params.context.componentParent.onPrintRegistrationCard(this.params.data);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },

        { separator: true },
        {
          label: "Attachments",
          command: (x: any) => {
            this.params.context.componentParent.attachments(this.params.data);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },

        { separator: true },
        {
          label: "Web Cam",
          command: (x: any) => {
            this.params.context.componentParent.camerclick(this.params.data);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },

        { separator: true },
        {
          label: "Opt-in/ Opt-out Consent Form",
          command: (x: any) => {
            this.params.context.componentParent.nabithConsent(this.params.data.patientId,this.params.data.consultationId, 'myPatient');
          },
          disabled: this.params.data.consentOptionId == 2 ? true : false || this.params.data.active == 0 ? true : false,
        },

        { separator: true },
        {
          label: "Print Registration Details",
          command: (x: any) => {
            this.params.context.componentParent.fillRegCardData(
              this.params.data
            );
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },
        {
          label: "Print Sticker",
          command: (x: any) => {

            // this.params.data
            let patientId = this.params.data.patientId;
            // this.router.navigate(["/PatientSticker", patientId]);
            this.params.context.componentParent.onPrintSticker(this.params.data);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },
        {
          label: "Print Barcode",
          command: (x: any) => {
            this.params.context.componentParent.printBarcode(this.params.data);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },
        {
          label: " Patient Consent",
          command: (x: any) => {
            this.params.context.componentParent.SponsrorConsent(
              this.params.data, "PC"
            );
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },
        {
          label: "Informed Consent",
          command: (x: any) => {
            this.params.context.componentParent.SponsrorConsent(
              this.params.data, "IC"
            );
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },
        {
          label: "Insurance Consent",
          command: (x: any) => {
            this.params.context.componentParent.SponsrorConsent(
              this.params.data, "SC"
            );
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },

        {
          label: "Open Credit",
          command: (x: any) => {
            this.params.context.componentParent.openCredit(this.params.data);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },
        {
          label: "Edit Credit",
          command: (x: any) => {
            this.params.context.componentParent.editCredit(this.params.data);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },
        {
          label: "Go to Consultation",
          command: (x: any) => {
            this.params.context.componentParent.gotoConsultation(this.params.data);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },
        {
          label: "Patient Questionnaire",
          command: (x: any) => {
            this.params.context.componentParent.gotoPatientQuestionnaire(this.params.data);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },
        {
          label: "Health Summary Chart",
          command: (x: any) => {
            this.params.context.componentParent.showHealthSummary(this.params.data);
          },
          disabled: this.params.data.active == 0 ? true : false,
        },
        { separator: true },
        {
          label: "Patient EMR",
          command: (x: any) => {
            this.params.context.componentParent.onPatientEmr(this.params.data);
          },
          // disabled: this.params.data.active == 0 ? true : false,
        }
      ];
    }
    //  REGISTRATION LIST ENDS

    // fornt office consultation start
    else if (this.pagetype == "FrontofficeConsultationList") {
      this.items = [
        {
          label: "Service Order",
          command: (x: any) => {
            let patientId = this.params.data.patientId;
            let consultationsId = this.params.data.consultationId;
            this.params.context.componentParent.gotoServiceOrder(patientId, consultationsId)
            // this.router.navigate(["/serviceOrder", {patientId,consultationsId}]);
            // this.router.navigate(['/serviceOrder'], { state: { patientId: this.params.data.patientId ,consultationId:this.params.data.consultationId} });

          },
        },
        { separator: true },
        {
          label: "Open Credit",
          command: (x: any) => {
            this.params.context.componentParent.openCredit(this.params.data)
          },
        },
        { separator: true },
        {
          label: "Edit Credit",
          command: (x: any) => {
            this.params.context.componentParent.editCredit(this.params.data)
          },
        },
        { separator: true },
        {
          label: "Change Visit Reason",
          command: (x: any) => {
            this.params.context.componentParent.ReasonForVisitModal(
              this.params.data
            );
          },
          disabled: this.params.data.status != "W" ? true : false,
        },
        { separator: true },
        {
          label: "Change as Urgent",
          command: (x: any) => {
            //this.params.context.componentParent.ChangeAsUrgent(this.params.data);
            this.params.context.componentParent.UrgentVisitmodal(
              this.params.data
            );
          },
          disabled: this.params.data.status != "W" ? true : false,
        },

        { separator: true },
        {
          label: "Opt-in/ Opt-out Consent Form",
          command: (x: any) => {
            this.params.context.componentParent.nabithConsent(this.params.data);
          },
          disabled: this.params.data.consentOptionId == 2 ? true : false,
        },

        { separator: true },
        {
          label: "Print Service order",
          command: (x: any) => {
            this.params.context.componentParent.openServiceOrder(this.params.data);
          },
        },
        { separator: true },
        {
          label: "Print Asoap",
          command: (x: any) => {
            this.params.context.componentParent.printAsoap(this.params.data);
            console.log(this.params.data)
          },
          disabled: !this.params.data.sponsorId && this.params.data.sponsorId <= 0
        },
        { separator: true },
        {
          label: "Print Sticker",
          command: (x: any) => {
            let patientId = this.params.data.patientId;
            this.params.context.componentParent.onPrintSticker(this.params.data);
            // this.router.navigate(["/PatientSticker", patientId]);
          },
        },
        { separator: true },
        {
          label: "Print Token",
          command: (x: any) => {
            this.params.context.componentParent.onPrintToken(this.params.data);
          },
        },
        { separator: true },
        {
          label: "New Bill",
          command: (x: any) => {
            this.params.context.componentParent.showNewBill(this.params.data);
          },
        },

        { separator: true },
        {
          label:
            this.params.data.status == "W"
              ? "Cancel Consultation"
              : this.params.data.status == "C"
                ? "Cancelled"
                : "Cancel",
          command: (x: any) => {
            this.params.context.componentParent.showBlockUnblock(
              this.params.data
            );
          },
          disabled: this.params.data.status != "W" ? true : false,
        },
        { separator: true },
        {
          label: "Insurance consent",
          command: (x: any) => {
            this.params.context.componentParent.SponsrorConsent(this.params.data, "SC", 187);
          },
        },
        { separator: true },
        {
          label: "Patient consent",
          command: (x: any) => {
            this.params.context.componentParent.SponsrorConsent(this.params.data, "PC", 188);
          },
        },
        { separator: true },
        {
          label: "Informed consent",
          command: (x: any) => {
            this.params.context.componentParent.SponsrorConsent(this.params.data, "IC", 189);
          },
        },
        { separator: true },
        {
          label: "Health Summary Chart",
          command: (x: any) => {
            this.params.context.componentParent.showHealthSummary(this.params.data);
          }
        }
      ];
    }

    // fornt office Appointment start
    else if (this.pagetype == "FrontAppointmentList") {
      this.items = [
        {
          label: "Edit Appointment",
          command: (x: any) => {
            let appId = this.params.data.appId;
            this.params.context.componentParent.editAppointment(appId);
            // this.router.navigate(["/AddAppointment", appId]);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },
        {
          label: "Consultations",
          command: (x: any) => {
            let appId = this.params.data.appId;
            this.params.context.componentParent.gotoConsultation(appId);
            // if (appId) {
            //   this.router.navigate(["/AddConsultation", appId]);
            // } else {
            //   this.router.navigate(["/AddRegistration"]);
            // }
          },
          disabled: this.params.data.status == "C" || this.params.data.regNo == '' ? true : false,
        },
        { separator: true },
        {
          label: "Registration",
          command: (x: any) => {
            this.params.context.componentParent.onRegisterfromAppoinment(this.params.data);
          },
          disabled: this.params.data.status == "C" || this.params.data.regNo != '' ? true : false,
        },
        { separator: true },
        {
          label: "Postponed Appointment",
          command: (x: any) => {
            let appId = this.params.data.appId;
            this.params.context.componentParent.AppointmentPostPoned(appId);
            //this.params.context.componentParent.openModal('template');
            //this.router.navigate(['/scheduledappoinment',appId]);
          },
          disabled: this.params.data.status == "C" || this.params.data.consultationStatus == "F" ? true : false,
        },

        { separator: true },

        {
          label: "Print Appointment",
          command: (x: any) => {
            let appId = this.params.data.appId;
            this.params.context.componentParent.printAppointment(appId);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },

        {
          label: "Cancel Appointment",
          command: (x: any) => {
            let appId = this.params.data.appId;
            let consultationId = this.params.data.consultationId;
            this.params.context.componentParent.showCancelAppointment(appId, consultationId);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },

        {
          label: "Edit Status",
          command: (x: any) => {
            let appId = this.params.data.appId;
            this.params.context.componentParent.EditStatusModal(appId);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },
      ];
    }

    //  Appointment List start
    else if (this.pagetype == "AppoinmentList") {
      this.items = [
        {
          label: "Edit Appointment",
          command: (x: any) => {
            let appId = this.params.data.appId;
            if(this.returnCall){
              this.params.context.componentParent.close();
            }
            // this.router.navigate(["/AddAppointment", appId]);
            this.params.context.componentParent.appointmentEdit(appId);

          },
          disabled: this.params.data.appStatus == "Cancelled" ? true : false,
        },
        { separator: true },
        {
          label: "Postponed Appointment",
          command: (x: any) => {
            if(this.returnCall){
              this.params.context.componentParent.close();
            }
            let appId = this.params.data.appId;
            this.params.context.componentParent.AppointmentPostPoned(appId);
            //this.params.context.componentParent.openModal('template');
            //this.router.navigate(['/scheduledappoinment',appId]);
          },
          disabled: this.params.data.appStatus == "Cancelled" || this.params.data.consultationStatus == "F" ? true : false,
        },
        { separator: true },

        {
          label: "Print Appointment",
          command: (x: any) => {
            let appId = this.params.data.appId;
            this.params.context.componentParent.PrintAppointmentCard(appId);
          },
          disabled: this.params.data.appStatus == "Cancelled" ? true : false,
        },
        { separator: true },
        {
          label: "Consultations",
          command: (x: any) => {
            if(this.returnCall){
              this.params.context.componentParent.close();
            }
            let appId = this.params.data.appId;
            // if (appId) {
            //   this.router.navigate(["/AddConsultation", appId]);
            // } else {
            //   this.router.navigate(["/AddRegistration"]);
            // }
            this.params.context.componentParent.consultationAction(appId);
          },
          // disabled: this.params.data.consultationStatus !== null ? true : false
          disabled: this.params.data.appStatus == "Cancelled" || (this.params.data.regNo == '' || !this.params.data.regNo) || (this.params.data.consultationStatus !== null && this.params.data.consultationStatus !== "") ? true : false,
        },
        { separator: true },

        {
          label: "Registration",
          command: (x: any) => {
            if(this.returnCall){
              this.params.context.componentParent.close();
            }
            let data = this.params.data;
            this.params.context.componentParent.onRegisterfromAppoinment(data);
          },
          disabled: this.params.data.appStatus == "Cancelled" || (this.params.data.regNo != '' && this.params.data.regNo) ? true : false,
        },
        { separator: true },

        {
          label: "Cancel Appointment",
          command: (x: any) => {
            if(this.returnCall){
              this.params.context.componentParent.close();
            }
            let appId = this.params.data.appId;
            this.params.context.componentParent.showCancelAppointment(appId);
          },
          disabled: this.params.data.appStatus == "Cancelled" ? true : false,
        },
        { separator: true },
      ];
    }

    //  ServiceList  start
    else if (this.pagetype == "ServiceList") {
      let disabled = false;
      if (this.params.data.status == "C" || this.params.data.itemStatus == "Cancelled") {
        disabled = true;
      }

      this.items = [


        {
          label: "Auto Initiate",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.autoInitiate(data);

          },
          // disabled: this.params.data.status == "C" ? true : false,
          disabled: disabled

        },
        {
          label: "New investigation",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.newInvestigation(data);

          },
           disabled: (this.params.data.itemStatus == "New Order" && this.params.data.locationType == 3) &&( this.params.data.resultOn == 1 &&  this.params.data.isTransfered != 1) ? false : true
         

        },

        { separator: true },
        {
          label: "Cancel Orders",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showCancelOrders(orderId, 'OD');

          },
          // disabled: this.params.data.status == "C" ? true : false,
          disabled: disabled


        },
        { separator: true },

        {
          label: "Work List",
          command: (x: any) => {
            this.params.context.componentParent.openWorkListModal(params.data);
          },
          disabled: disabled,
        },
        // { separator: true },


      ];




    }
    //  ServiceList  start
    else if (this.pagetype == "OrderDetails") {

      this.items = [

        {
          label: "Print Order",
          command: (x: any) => {

            let orderId = this.params.data.orderId;

            this.params.context.componentParent.PrintOrderReportCardAll(orderId, this.pagetype);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,
        },
        { separator: true },
        {
          label: "Cancel Orders",
          command: (x: any) => {

            let orderId = this.params.data;
            this.params.context.componentParent.showCancelOrders(orderId, 'OS');
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,


        },
        { separator: true },

      ];

    }

    //  OrderDetailsModal  start
    else if (this.pagetype == "OrderDetailsModal") {

      this.items = [

        {
          label: "Print Order",
          command: (x: any) => {

            let orderId = this.params.data.orderId;

            this.params.context.componentParent.PrintOrderReportCardAll(orderId, this.pagetype);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,
        },
        { separator: true },

      ];

    }


    // unbilled ServiceList  start
    else if (this.pagetype == "unBilledItemOrder") {

      this.items = [

        {
          // label: "New Bill",
          // command: (x: any) => {
          //   this.params.context.componentParent.onNewBill(this.params.data);
          // },
          // disabled: this.params.data.isCancelled == 1 ? true : false,
          label: "New Bill",
          command: (x: any) => {
            let details = this.params.data;
            let actionId = 361;
            this.params.context.componentParent.onNewBill(details, actionId);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,
        },
        { separator: true },
        {
          label: "Cancel Order",
          command: (x: any) => {
            this.params.context.componentParent.onCancelOrder(this.params.data);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,


        },
        { separator: true },

      ];

    }

    else if (this.pagetype == "unBilledItem") {

      this.items = [

        {
          label: "New Bill",
          command: (x: any) => {
            let actionId = 363;
            this.params.context.componentParent.onNewBill(this.params.data, actionId);
          },
          disabled: this.params.data.itemStatus == 'Cancelled' ? true : false,
        },
        { separator: true },
        {
          label: "Cancel Order",
          command: (x: any) => {
            this.params.context.componentParent.onCancelOrderItem(this.params.data);
          },
          disabled: this.params.data.itemStatus == 'Cancelled' ? true : false,


        },
        { separator: true },

      ];

    }

    // fornt office consultation start
    else if (this.pagetype == "FrontofficeConsultantConsultationList") {

      this.items = [
        {
          label: "Service Order",
          command: (x: any) => {

            let patientId = this.params.data.patientId;
            let consultationsId = this.params.data.consultationId;
            this.params.context.componentParent.serviceOrderConsultant(this.params.data);
            // this.router.navigate(["/serviceOrder", {patientId,consultationsId}]);
            // this.router.navigate(['/serviceOrder'], { state: { patientId: this.params.data.patientId ,consultationId:this.params.data.consultationId} });

          },
        },
        { separator: true },

        {
          label: "Billing Instruction",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.billingInstuction(data);
          },
        },
        { separator: true },
        {
          label: "Special Discount",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.specialDiscount(data);
          },

        },
        { separator: true },
        {
          label: "Change Fee",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.changeFees(data);
          },

        },
        { separator: true },
        // {
        //   label: "Occupational EMR",

        // },
        { separator: true },
        {
          label: "Patient EMR",
          command: (x: any) => {

            this.params.context.componentParent.goToEmr(
              this.params.data
            );
          },

        },

        { separator: true },
        {
          label: "Opt-in/ Opt-out Consent Form",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.nabithConsent(data);
          },
          disabled: this.params.data.consentOptionId == 2 ? true : false,
        },

        { separator: true },
        {
          label: "Treatment Summary",
          command: (x: any) => {
            this.params.context.componentParent.treatmentDetails(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,

        },
        // { separator: true },
        // {
        //   label: "Print",
        //   command: (x: any) => {
        //     this.params.context.componentParent.gotoPrintAction(
        //       this.params.data
        //     );
        //   },
        // },
        { separator: true },
        {
          label: "Print Medical Certificate",
          command: (x: any) => {
            this.params.context.componentParent.printMedicalCertificate(
              this.params.data
            );
          },
        },
        { separator: true },
        {
          label: "Print ASOAP",
          command: (x: any) => {
            //this.params.context.componentParent.ChangeAsUrgent(this.params.data);
            this.params.context.componentParent.printAsoap(
              this.params.data
            );
          },
          disabled: this.params.data.sponsorId == 0 ? true : false,
        },
        { separator: true },
        {
          label: "Set As Urgent",
          command: (x: any) => {
            //this.params.context.componentParent.ChangeAsUrgent(this.params.data);
            this.params.context.componentParent.UrgentVisitmodal(
              this.params.data
            );
          },
          disabled: this.params.data.status != "W" ? true : false,
        },
        { separator: true },
        {
          label: "Reason For visit",
          command: (x: any) => {
            this.params.context.componentParent.ReasonForVisitModal(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        { separator: true },


        {
          label: "Credit Timer settings",
          command: (x: any) => {
            this.params.context.componentParent.showConsultantTimerSettings(
              this.params.data
            );
          },
          // disabled: this.params.data.status != "W" ? true : false,
        },
        { separator: true },
        {
          label: "Change Status",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.changeStatusModal(data);
          },
          // disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },
        {
          label: "Print Medical Report",
          command: (x: any) => {
            this.params.context.componentParent.printMedicalReport(
              this.params.data
            );
          },
        },



      ];
    }


    //////////////////CONSULTATION LIST////////////////////////
    if (this.pagetype == "MyConsultation") {
      this.items = [


        {
          label:
            this.params.data.status == "Waiting"
              ? "Cancel Consultation"
              : this.params.data.status == "Cancelled"
                ? "Cancelled"
                : "Cancel Consultation",
          command: (x: any) => {
            this.params.context.componentParent.showBlockUnblockConsultation(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" || this.params.data.status == "Finished" ? true : false,
        },
        { separator: true },
        {
          label: "Treatment Summary",
          command: (x: any) => {
            this.params.context.componentParent.treatmentDetails(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        { separator: true },
        // {
        //   label: "Occupational EMR",
        //   command: (x: any) => {
        //     this.params.context.componentParent.PrintStickerFormat(
        //       this.params.data
        //     );
        //   },
        //   disabled: this.params.data.status != "Cancelled" ? true : false,
        // },
        // { separator: true },

        {
          label: "Patient EMR",
          command: (x: any) => {
            this.params.context.componentParent.GoToEmr(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        { separator: true },
        {
          label: "Print ASOP",
          command: (x: any) => {
            this.params.context.componentParent.printAsoap(
              this.params.data
            );
          },
          disabled: !this.params.data.sponsorId && this.params.data.sponsorId <= 0
        },
        { separator: true },

        {
          label: "Unified ClaimForm",
          command: (x: any) => {
            this.params.context.componentParent.PrintStickerFormat(
              this.params.data, "ClaimForm"
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        { separator: true },
        {
          label: "Change Credit",
          command: (x: any) => {
            this.params.context.componentParent.changeConsultationCredit(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },

        { separator: true },
        {
          label: "Opt-in/ Opt-out Consent Form",
          command: (x: any) => {
            this.params.context.componentParent.nabithConsent(this.params.data.patientId, this.params.data.consultationId, 'myConsultation');
          },
          disabled: this.params.data.consentOptionId == 2 ? true : false,
        },

        { separator: true },
        {
          label: "Reason For Visit",
          command: (x: any) => {
            this.params.context.componentParent.ReasonForVisitModal(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Cancelled" ? true : false,
        },
        { separator: true },
        {
          label:
            this.params.data.status == "Waiting"
              ? "No Cancel Reason"
              : this.params.data.status == "Cancelled"
                ? "Reason For Cancelled"
                : "Reason For Cancelled",
          command: (x: any) => {
            this.params.context.componentParent.ReasonForCancellation(
              this.params.data
            );
          },
          disabled: this.params.data.status == "Waiting" || this.params.data.status == "OnGoing" ? true : false,

        },
        { separator: true },
        {
          label: "Medical Record",
          command: (x: any) => {
            this.params.context.componentParent.onMedicalRecord(
              this.params.data
            );
          },

        },
      ];
    }

    //  ServiceList  start
    else if (this.pagetype == "personalisationSerDisease") {

      this.items = [

        {
          label: "Edit Diseases",
          command: (x: any) => {
            let DiseaseId = this.params.data.diseaseId;
            this.params.context.componentParent.addDisease(DiseaseId, 416);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,
        },
        { separator: true },
        {
          label: "Remove Diseases",
          command: (x: any) => {

            let orderId = this.params.data;
            this.params.context.componentParent.showCancelDiseases(orderId);

          },
          disabled: this.params.data.isCancelled == 1 ? true : false,


        },
        { separator: true },

        // {
        //   label: "Block Diseases",
        //   command: (x: any) => {

        //     let orderId = this.params.data;
        //     this.params.context.componentParent.BlockDiseases(orderId);

        //   },
        //   disabled: this.params.data.isCancelled == 1 ? true : false,


        // },
        // { separator: true },

      ];

    }


    else if (this.pagetype == "personalisationMarks") {

      this.items = [

        {
          label: "Edit Marks",
          command: (x: any) => {

            let markId = this.params.data.markId;

            this.router.navigate(["/addMarks", markId]);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,
        },
        { separator: true },
        {
          label: "Remove Marks",
          command: (x: any) => {

            let orderId = this.params.data;
            this.params.context.componentParent.MarksListModalView(orderId);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,


        },
        { separator: true },



      ];

    }


    else if (this.pagetype == "personalisationServiceLis") {

      this.items = [


        {
          label: "Remove Service Items",
          command: (x: any) => {

            let orderId = this.params.data;
            this.params.context.componentParent.ServiceListModalView(orderId);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,


        },
        { separator: true },



      ];
    }
    else if (this.pagetype == "personalisationDrugs") {
      this.items = [
        {
          label: "Edit Drugs",
          command: (x: any) => {
            let drugId = this.params.data.drugId;
            this.params.context.componentParent.editDrugs(drugId);
            // this.router.navigate(["/addDrugs", drugId]);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,
        },
        { separator: true },
        {
          label: "Remove Drugs",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.RemoveDrugs(orderId);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "professionList") {
      this.items = [
        {
          label: "Edit Profession",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showModalDialog(orderId);
          },
        },
        { separator: true },
        {
          label: "Delete Profession",
          command: (x: any) => {
            let profId = this.params.data;
            this.params.context.componentParent.DeleteProfession(profId);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,
        },
        { separator: true },
      ];
    }

    else if (this.pagetype == "templateList") {
      this.items = [
        {
          label: "Edit Template",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.editTemplate(data);
            // this.router.navigate(['/config/ReportWorkList/edit/' + data.id]);
          },
        },
        { separator: true },
        {
          label: "Delete Template",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.deleteTemplate(data);
          }
        }
      ];
    }




    else if (this.pagetype == "commissionList") {
      this.items = [
        {
          label: "Edit Commission Rules",
          command: (x: any) => {

            this.params.context.componentParent.showModalDialog(this.params.data);
          },
        },
        { separator: true },
        {
          label: "Delete Commission",
          command: (x: any) => {

            this.params.context.componentParent.DeleteCommission(this.params.data);
          },
          disabled: this.params.data.isCancelled == 1 ? true : false,
        },


        // {
        //   label: "Delete Commission Rules",
        //   command: (x: any) => {
        //   this.params.context.componentParent.showModalDialog(this.params.data);
        //   },
        //   disabled: this.params.data.isCancelled == 1 ? true : false,
        // },




        { separator: true },
      ];
    }



    else if (this.pagetype == "CPTCodeList") {
      this.items = [
        {
          label: "Edit CPTCode",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showModalDialog(orderId);
          },
        },
        { separator: true },
        {
          label: "Delete CPTCode",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.DeleteCPTCode(orderId);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "RateGroupList") {
      this.items = [
        {
          label: "Edit RateGroup",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showModalDialog(orderId);
          },
        },
        { separator: true },
        {
          label: "Delete RateGroup",
          command: (x: any) => {
            let Id = this.params.data;
            this.params.context.componentParent.DeleteRateGroup(Id);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "ICDCategoryList") {
      this.items = [
        {
          label: "Edit ICD Category",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showModalDialogCategory(orderId);
          },
        },
        { separator: true },
        {
          label: "Delete ICD Category",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.DeleteICDCategory(orderId);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "ICDContradictoryList") {
      this.items = [
        {
          label: "Edit ICD Contradictory",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showModalDialogContradictory(orderId);
          },
        },
        { separator: true },
        {
          label: "Delete ICD Contradictory",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.deleteICDContradictory(orderId);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "ICDGroupList") {
      this.items = [
        {
          label: "Edit ICD Group",
          command: (x: any) => {
            let Id = this.params.data;
            this.params.context.componentParent.showModalDialogGroup(Id);
          },
        },
        { separator: true },
        {
          label: "Delete ICD Group",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.DeleteICDGroup(orderId);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "IcdlabelList") {
      this.items = [
        {
          label: "Edit ICD Label",
          command: (x: any) => {
            let Id = this.params.data;
            this.params.context.componentParent.showModalDialogLabel(Id);
          },
        },
        { separator: true },
        {
          label: "Delete ICD Label",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.DeleteICDLabel(orderId);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "TaxList") {
      this.items = [
        {
          label: "Edit Tax",
          command: (x: any) => {
            let taxDetails = this.params.data;
            this.params.context.componentParent.showModalDialog(taxDetails);

          },
        },
        { separator: true },
        {
          label: "Delete Tax",
          command: (x: any) => {
            let taxDetails = this.params.data;
            this.params.context.componentParent.DeleteTax(taxDetails);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "SketchIndicatorList") {
      this.items = [
        {
          label: "Edit Indicator",
          command: (x: any) => {
            let taxDetails = this.params.data;
            this.params.context.componentParent.showModalDialog(taxDetails);
          },
        },
        { separator: true },
        {
          label: "Remove Indicator",
          command: (x: any) => {
            let taxDetails = this.params.data;
            this.params.context.componentParent.DeleteTax(taxDetails);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "SignList") {
      this.items = [
        {
          label: "Edit Sign",
          command: (x: any) => {
            let signDetails = this.params.data;
            this.params.context.componentParent.showSignModalDialog(signDetails);
          },
        },
        { separator: true },
        {
          label: "Delete Sign",
          command: (x: any) => {
            let signDetails = this.params.data;
            this.params.context.componentParent.DeleteSign(signDetails);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "VitalSignList") {
      this.items = [
        {
          label: "Edit Vital Sign",
          command: (x: any) => {
            let vitalsignDetails = this.params.data;
            this.params.context.componentParent.showVitalSignModalDialog(vitalsignDetails);
          },
        },
        { separator: true },
        {
          label: "Delete Vital Sign",
          command: (x: any) => {
            let vitalsignDetails = this.params.data;
            this.params.context.componentParent.DeleteVitalSign(vitalsignDetails);
          },
          disabled:this.params.data.reservedId !== 0
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "SymptomList") {
      this.items = [
        {
          label: "Edit Symptom",
          command: (x: any) => {
            let symptomDetails = this.params.data;
            this.params.context.componentParent.showSymptomModalDialog(symptomDetails);
          },
        },
        { separator: true },
        {
          label: "Delete Symptom",
          command: (x: any) => {
            let taxDetails = this.params.data;
            this.params.context.componentParent.DeleteSymptom(taxDetails);
          },
        },
        { separator: true },
      ];
    }

    else if (this.pagetype == "CptModifierList") {
      this.items = [
        {
          label: "Edit Modifier",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showModalDialog(orderId);
          },
        },
        { separator: true },
        {
          label: "Delete Modifier",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.DeleteCPTModifier(orderId);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "company") {
      this.items = [
        {
          label: "Edit Company",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showModalDialog(orderId);
          },
        },
        { separator: true },
        {
          label: "Delete Company",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.DeleteCompany(orderId);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "SketchList") {
      this.items = [
        {
          label: "View Image",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showModalDialog(orderId);
          },
        },
        { separator: true },
        //   {
        //    label: "Edit Sketch",
        //    command: (x: any) => {
        //      let orderId = this.params.data;
        //      this.params.context.componentParent.showModalDialog(orderId);
        //    },
        //  },
        //  { separator: true },
        //  {
        //   label: "Remove Sketch",
        //   command: (x: any) => {

        //     let orderId = this.params.data;
        //     this.params.context.componentParent.DeleteCompany(orderId);
        //   },
        // },
        // { separator: true },
      ];
    }
    else if (this.pagetype == "Service Item") {
      this.items = [
        {
          label: "Assign Result Form",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.assignTemplate(orderId);
          },
        },
        { separator: true },
        {
          label: "Assign Template",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.openAssignTemplateModal(orderId);
          },
        },
        { separator: true },



        {
          label: "Edit Item",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.EditItem(orderId);
          },
        },
        { separator: true },

        {
          label: "Delete Item",
          command: (x: any) => {
            let orderId = this.params.data;
            // console.log(this.params);
            this.params.context.componentParent.DeleteItem(orderId);
          },
        },
        { separator: true },
      ];
    }

    else if (this.pagetype == "Designation") {
      this.items = [
        {
          label: "Edit Designation",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.editDesignation(data);
          },
        },
        { separator: true },
        {
          label: "Delete Designation",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.deleteDesignation(data);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "Department") {
      this.items = [
        {
          label: "Edit Department",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showModalDialog(orderId);
          },
        },
        { separator: true },
        {
          label: "Delete Department",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.DeleteDepartment(orderId);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "Question") {
      this.items = [
        {
          label: "Edit Question",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.showModalDialog(orderId);
          },
        },
        { separator: true },
        {
          label: "Delete Question",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.DeleteQuestion(orderId);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "MaterConsultant") {
      this.items = [
        {
          label: "Edit Consultant",

          command: (x: any) => {
            let consultantId = this.params.data.consultantId;
            this.params.context.componentParent.editConsultant(consultantId);
            // this.router.navigate(["/config/addconsultant", consultantId]);
          },
          disabled: this.params.data.status == "C" ? true : false,

        },
        { separator: true },
        {
          label: "Delete Consultant",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteConsultant(data);

          },
        },
        { separator: true },
        {
          label: "Consultant Base Cost",
          command: (x: any) => {
            let consultantId = this.params.data.consultantId;
            this.params.context.componentParent.consultantBaseCost(consultantId);
            // this.router.navigate(["/config/itembasecost", consultantId]);
            // this.params.context.componentParent.Delete(orderId);
          },
        },
        { separator: true },
        {
          label: "Consultant Working Days",
          command: (x: any) => {
            let consultantId = this.params.data.consultantId;
            this.params.context.componentParent.consultantWorkingDays(consultantId);
            // this.router.navigate(["/config/consultantworkingdays", consultantId]);
            // this.params.context.componentParent.Delete(orderId);
          },
        },
        { separator: true },
        {
          label: "cForm Order",
          command: (x: any) => {
            let orderId = this.params.data;
            this.params.context.componentParent.onCFormModal(orderId);
          },
        },
        { separator: true },
      ];
    }

    else if (this.pagetype == "ServiceTax") {


    }
    else if (this.pagetype == "servicepoints") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditServicePoint(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteServicePoint(data);
          },
        },
        { separator: true },
        {
          label: "Assign Service Item",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.AssignServiceItem(data);
          },
        },
        { separator: true },

      ];
    }

    else if (this.pagetype == "package") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditPackage(data);
          },
        },
        { separator: true },

      ];
    }
    else if (this.pagetype == "IBCConsultant") {

      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditConsultantBaseCost(data);
          },
        },
        { separator: true },

      ];
    }

    else if (this.pagetype == "profile") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditProfile(data);
          },
        },
        { separator: true },

      ];
    }

    else if (this.pagetype == "Country") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditCountry(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteCountry(data);
          },
        },
        { separator: true },
      ];

    }


    else if (this.pagetype == "StateList") {
      this.items = [
        {
          label: "Edit State",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditState(data);
          },
        },
        { separator: true },
        {
          label: "Delete State",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteState(data);
          },
        },
        { separator: true },
      ];

    }

    else if (this.pagetype == "CityList") {
      this.items = [
        {
          label: "Edit City",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditCity(data);
          },
        },
        { separator: true },
        {
          label: "Delete City",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteCity(data);
          },
        },
        { separator: true },
      ];

    }

    else if (this.pagetype == "TitleList") {
      this.items = [
        {
          label: "Edit Title",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditTitle(data);
          },
        },
        { separator: true },
        {
          label: "Delete Title",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteTitle(data);
          },
        },
        { separator: true },
      ];

    }
    else if (this.pagetype == "tenderness") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditTenderness(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteTenderness(data);
          },
        },
        { separator: true },

      ];

    }
    else if (this.pagetype == "movements") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditMovements(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteMovements(data);
          },
        },
        { separator: true },

      ];

    }
    else if (this.pagetype == "modality") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.Editmodality(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.Deletemodality(data);
          },
        },
        { separator: true },

      ];

    }

    else if (this.pagetype == "movementsrange") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditMovementsrange(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteMovementsrange(data);
          },
        },
        { separator: true },

      ];

    }
    else if (this.pagetype == "spesticity") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditSpesticity(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteSpesticity(data);
          },
        },
        { separator: true },

      ];

    }

    else if (this.pagetype == "bodypart") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditBodyPart(data);
          },
        },
        { separator: true },
        {
          label: "View",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.ViewImage(data, 1);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteBodyPart(data);
          },
        },
        { separator: true },

      ];
    }
    else if (this.pagetype == "sketchIndiator") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditIndicator(data);
          },
        },
        { separator: true },
        {
          label: "View",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.ViewImageSketch(data, 2);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteIndicator(data);
          },
        },
        { separator: true },

      ];
    }
    else if (this.pagetype == "ledger") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditLedger(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteLedger(data);
          },
        },
        { separator: true },

      ];
    }

    else if (this.pagetype == "Nationality") {
      this.items = [
        {
          label: "Edit Nationality",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditNationality(data);
          },
        },
        { separator: true },
        {
          label: "Delete Nationality",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteNationality(data);
          },
        },
        { separator: true },
      ];

    }

    else if (this.pagetype == "ScientificName") {
      this.items = [
        {
          label: "Edit ScientificName",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditScientificName(data);
          },
        },
        { separator: true },
        {
          label: "Delete ScientificName",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteScientificName(data);
          },
        },
        { separator: true },
      ];

    }
    else if (this.pagetype == "CardType") {
      this.items = [
        {
          label: "Edit CardType",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditCardType(data);
          },
        },
        { separator: true },
        {
          label: "Delete CardType",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteCardType(data);
          },
        },
        { separator: true },
      ];

    }
    else if (this.pagetype == "TradeList") {
      this.items = [
        {
          label: "Edit TradeList",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditTradeList(data);
          },
        },
        { separator: true },
        {
          label: "Delete TradeList",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteTradeList(data);
          },
        },
        { separator: true },
      ];

    }

    else if (this.pagetype == "DrugList") {
      this.items = [
        {
          label: "Edit Drug",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditDrugList(data);
          },
        },
        { separator: true },
        {
          label: "Delete Drug",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteDrugList(data);
          },
        },
        { separator: true },
      ];

    }

    else if (this.pagetype == "Currency") {
      this.items = [
        {
          label: "Edit Currency",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditCurrency(data);
          },
        },
        { separator: true },
        {
          label: "Delete Currency",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteCurrency(data);
          },
        },
        { separator: true },
      ];

    }

    else if (this.pagetype == "Dosage") {
      this.items = [
        {
          label: "Edit Dosage",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditDosage(data);
          },
        },
        { separator: true },
        {
          label: "Delete Dosage",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteDosage(data);
          },
        },
        { separator: true },
      ];

    }

    else if (this.pagetype == "Frequency") {
      this.items = [
        {
          label: "Edit Frequency",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditFrequency(data);
          },
        },
        { separator: true },
        {
          label: "Delete Frequency",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteFrequency(data);
          },
        },
        { separator: true },
      ];

    }

    else if(this.pagetype == 'Consumption'){
      this.items = [
        {
          label: "Edit Consumption",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditConsumptionOpen(data);
          },
        },
        { separator: true },
        {
          label: "Delete Consumption",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteConsumption(data);
          },
        },
        { separator: true },
      ];
    }

    else if (this.pagetype == "Patient Consent") {
      this.items = [
        {
          label: "View Patient Consent",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.viewStaticPatientConsent(data);
          },
          disabled: this.params.data.settValue !== "0" ? true : false,
        },
        { separator: true },
        {
          label: "Edit Patient Consent",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditPatientConsent(data);
          },
          disabled: this.params.data.settValue == "0" ? true : false,
        },
        { separator: true },
        {
          label: "Delete Patient Consent",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeletePatientConsent(data);
          },
          disabled: this.params.data.settValue == "0" ? true : false,
        },
        { separator: true },
      ];

    }
    else if (this.pagetype == "Sponsor Consent") {
      this.items = [
        {
          label: "Edit Sponsor Consent",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditSponsorConsent(data);
          },
        },
        { separator: true },
        {
          label: "Delete Sponsor Consent",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteSponsorConsent(data);
          },
        },
        { separator: true },
      ];

    }

    else if (this.pagetype == "Informed Consent") {
      this.items = [
        {
          label: "Edit Informed Consent",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditInformedConsent(data);
          },
        },
        { separator: true },
        {
          label: "Delete Informed Consent",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteInformedConsent(data);
          },
        },
        { separator: true },
      ];

    }
    else if (this.pagetype == "SponsorList") {
      this.items = [

        {
          label: "Edit Insurance",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditSponsor(data);
          },
        },
        { separator: true },
        {
          label: "Delete Insurance",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteSponsor(data);
          },
        },
        { separator: true },
        {
          label: "Insurance Consent",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditSponsorConsent(data);
          },
        },
        { separator: true },

        {
          label: "Drug List",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.openModalDrugList(data);
          },
        },
        { separator: true },
        {
          label: "Consultant Reduction",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.openModalConsultantReduction(data);
          },
        },
        { separator: true },


      ];

    }
    else if (this.pagetype == "SponsorsRule") {
      this.items = [
        {
          label: "Edit Insurance Network",
          command: (x: any) => {
            let data = this.params.data.ruleId;
            let sponsorId = params.data.sponsorId
            this.params.context.componentParent.EditSponsorRule(data, sponsorId);
            // this.router.navigate(["/config/SponsorRules/" + sponsorId + '/' + data,])
          },
        },
        { separator: true },
        {
          label: "Delete Insurance Network",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteSponsorRule(data);
          },
        },
        { separator: true },
        {
          label: "Edit Consultation Slab",
          command: (x: any) => {
            let data = this.params.data.ruleId;
            let sponsorId = params.data.sponsorId
            this.params.context.componentParent.EditConsultationSlap(data,sponsorId);
          },
        },
        { separator: true },
      ];

    }
    else if (this.pagetype == "SponsorFormist") {
      this.items = [
        {
          label: "Edit Insurance Form",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditSponsorForms(data);
          },
        },
        { separator: true },
        {
          label: "Delete Insurance Form",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteSponsorForm(data);
          },
        },
        { separator: true },
        {
          label: "Form Preview",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.formPreview(data);
          },
        },
        { separator: true },

      ];

    }

    else if (this.pagetype == "SubAgentist") {
      this.items = [
        {
          label: "Edit TPA List ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.EditSubAgent(data);
          },
        },
        { separator: true },
        {
          label: "Delete TPA List",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.DeleteSubAgent(data);
          },
        },
        { separator: true },
      ];

    }
    else if (this.pagetype == "staffList") {
      this.items = [
        {
          label: "Edit ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.Edit(data);
          },
        },
        { separator: true },
        {
          label: "Print QR Code",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onShowQrCode(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.Delete(data);
          },
        },
        { separator: true },
      ];
    }

    else if (this.pagetype == "sicklist") {
      this.items = [
        {
          label: "Edit ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.Edit(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.Delete(data);
          },
        },
        { separator: true },
        {
          label: "Print Sick Leave",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printSickLeave(data);
          },
        },
        { separator: true },
        {
          label: "Print Attendance",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printAttendance(data);
          },
        },
      ];
    }
    else if (this.pagetype == "bodyparts") {
      this.items = [
        {
          label: "Edit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.Edit(data);
          },
        },
        { separator: true },
        {
          label: "Delete",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.Delete(data);
          },
        },
        { separator: true },
      ];
    }

    else if (this.pagetype == "numbersettings") {
      this.items = [
        {
          label: "Edit ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.NumberSettingsupdate(data);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "resultFormAction") {
      this.items = [
        {
          label: "Edit Result Form ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.editResultForm(data);
          },
        },
        { separator: true },
        {
          label: "Template",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.addtemplate(data);
          },
        },
        { separator: true },
        {
          label: "Preview Result Form",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.previewResultForm(data);
          },
        },
        { separator: true },
        {
          label: "Result Form Parameters",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.resultFormParameter(data);
          },
        },
        { separator: true },
        {
          label: "Result Form Header",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.resultFormHeader(data);
          },
        },
        { separator: true },

      ];

    }
    else if (this.pagetype == "numbersettings") {
      this.items = [
        {
          label: "Edit ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.NumberSettingsupdate(data);
          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "parameterList") {
      this.items = [
        {
          label: "Edit Result Parameter",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.editResultParameterForm(data);
          },
        },
        { separator: true },
        {
          label: "Delete Result Parameter",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.deleteResultParameterForm(data);
          },
        },
        { separator: true },

      ];

    }
    else if (this.pagetype == "headerList") {
      this.items = [
        {
          label: "Edit Header",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.editHeaderForm(data);
          },
        },
        { separator: true },
        {
          label: "Delete Header",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.deleteHeaderForm(data);
          },
        },
        { separator: true },

      ];

    }
    else if (this.pagetype == "patientLabDetails") {
      this.items = [
        {
          label: "New Order",
          command: (x: any) => {
            let patientId = this.params.data.patientId;
            let consultationsId = this.params.data.consultationId;
            this.params.context.componentParent.newOrder(patientId, consultationsId);
            // this.router.navigate(["/serviceOrder", {patientId,consultationsId}]);
            // this.router.navigate(['/serviceOrder'], { state: { patientId: this.params.data.patientId ,consultationId:this.params.data.consultationId} });

          },
        },
        { separator: true },
        {
          label: "Result On Date ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.resultOnDate(data);
          },
        },
        { separator: true },

      ];
    }
    else if (this.pagetype == "investigationDetailsAction") {
      this.items = [
        {
          label: "Repeat Sample",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.repeatSample(data);
          },
        },
        { separator: true },
        {
          label: "New Investigation ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.newSample(data);
          },
          disabled: (this.params.data.status == "Result Entered"  || this.params.data.status == "Published" || this.params.data.status == "Sample Collected") || (this.params.data.locationType != 3 || this.params.data.status=="New Order" || this.params.data.status == "Test Initiated" ) ? true : false         },
        { separator: true },
        {
          label: "Test Initiate",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.TestInitiated(data);
          },
          disabled: (this.params.data.status == "Sample Collected" && this.params.data.locationType == 3) ? false : true,

        },
        { separator: true },
        {
          label: "Cancel Result",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelResult(data);
          },
          disabled: (this.params.data.status == "Result Entered" || this.params.data.locationType == 3 && this.params.data.status != "Sample Collected") &&(this.params.data.status!="New Order"&&this.params.data.status != "Published"&&this.params.data.status != "Test Initiated" ) ? false : true,         },
        { separator: true },
        {
          label: "Cancel Investigation",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelInvestigation(data);
          },
          disabled: (this.params.data.status == "Result Entered" ? true : false || this.params.data.status == "Published") || (this.params.data.locationType != 3 || this.params.data.status=="New Order") ? true : false        },
        { separator: true },
        {
          label: "Publish Result ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.publishResult(data);
          },
          disabled: (this.params.data.status == "Published" || this.params.data.status == "Test Initiated" || this.params.data.status == "Sample Collected"  ) || (this.params.data.locationType != 3 || this.params.data.status=="New Order") ? true : false,        },
        { separator: true },
        {
          label: "Enter Result",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.enterResult(data);
          },
          disabled: (this.params.data.status == "Test Initiated" || this.params.data.locationType == 3 && this.params.data.status != "Sample Collected"  )&& (this.params.data.status != "Result Entered" && this.params.data.status!="New Order" && this.params.data.status != "Published" ) ? false : true ,        },
        { separator: true },
        {
          label: "Print Result With Stamp ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.PrintResultWithStamp(data);
          },

        },
        { separator: true },
        {
          label: "Print Result WithOut Stamp ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.PrintResult(data);
          },

        },
        { separator: true },

        {
          label: "Print Order ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.OderResult(data);
          },
        },
        { separator: true },
        {
          label: "Result Document",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.resultDocument(data);
          },
        },
        { separator: true },
        {
          label: "Mail Result ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.mailResult(data);
          },
          disabled: this.params.data.status != "Published" ? true : false,

        },
        { separator: true },
        {
          label: "Sticker",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.sticker(data);
          },
          disabled:this.params.data.investgnNo=="N/A"?true:false

        },
        { separator: true },
        {
          label: "report Work List ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.reportWorkLIst(data);
            // this.router.navigate(['/ServiceList/'+data.orderId+'/report/',data.itemId]);
          },
        },
        { separator: true },

      ];
    }

    else if (this.pagetype == "investigationAction") {
      this.items = [
        {
          label: "New investigation",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.newInvestigation(data);
          },
        },
        { separator: true },
        {
          label: "Result On Date ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.resultOnDate(data);
          },
        },
        { separator: true },
        {
          label: "Lab Card",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.labCard(data);
          },
        },
        { separator: true },

      ];
    }
    else if (this.pagetype == "serviceItemList") {
      this.items = [
        {
          label: "Auto Initiate",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.autoInitiateAction(data);
          },
          disabled: ((this.params.data.status == "New Order" || this.params.data.status == "Sample Collected" && this.params.data.locationType == 3) ? false : true),

        },
        { separator: true },
        {
          label: "New Investigation",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.newInvestigation(data);
          },
          disabled: (this.params.data.status == "New Order" && this.params.data.locationType == 3) &&( this.params.data.resultOn == 1 &&  this.params.data.isTransfered != 1) ? false : true

        },
        { separator: true },
        {
          label: "Test Initiate",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.TestInitiated(data);
          },
          disabled: (this.params.data.status == "Sample Collected" && this.params.data.locationType == 3) ? false : true,

        },
        { separator: true },
        {
          label: "Transfer",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.transfor(data);
          },
          disabled: (this.params.data.status == "New Order" || this.params.data.status == "Sample Collected" && this.params.data.isTransfered != 1) &&(this.params.data.locationType == 3 && this.params.data.resultOn == 1 ) ? false : true,


        },
        { separator: true },

        {
          label: "Cancel Investigation",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelInvestigation(data);
          },
          disabled: (this.params.data.status == "Sample Collected"  || this.params.data.status == "Test Initiated") ||(this.params.data.locationType != 3 ) ? false : true

        },
        { separator: true },
        {
          label: "Send LIS Request ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.sendLisRequest(data);
          },
          disabled: (this.params.data.status == "Result Entered" ? true : false || this.params.data.status == "Published") || (this.params.data.locationType != 3 || this.params.data.status=="New Order") ? true : false
        },
        { separator: true },
        {
          label: "Enter Result",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.enterResult(data, 'enterResult');
          },
          disabled: (this.params.data.status == "Test Initiated" && this.params.data.locationType == 3  ) ? false : true ,

        },
        { separator: true },
        {
          label: "Edit Result",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.enterResult(data, 'editResult');
          },
          disabled: (this.params.data.status == "Result Entered" && this.params.data.locationType == 3  ) ? false : true ,

        },
        { separator: true },
        {
          label: "Publish Result",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.publishResult(data);
          },
          disabled: (this.params.data.status == "Published" || this.params.data.status == "Test Initiated"  ) || (this.params.data.locationType != 3 || this.params.data.status=="New Order") ? true : false,

        },
        { separator: true },
        {
          label: "Cancel Result",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelResult(data);
          },
          disabled: (this.params.data.status == "Result Entered" || this.params.data.locationType == 3) &&(this.params.data.status!="New Order"&&this.params.data.status != "Published"&&this.params.data.status != "Test Initiated" ) ? false : true,

        },
        { separator: true },
        {
          label: "Report Work LIst",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.reportWorkLIst(data);
          },
        },
        { separator: true },
        {
          label: "Print Result With Stamp ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.PrintResultWithStamp(data);
          },
          disabled: this.params.data.status == "Result Entered" ? false : true || this.params.data.status == "Published" ? false : true,

        },
        { separator: true },
        {
          label: "Print Result WithOut Stamp ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.PrintResult(data);
          },
          disabled: this.params.data.status == "Result Entered" ? false : true || this.params.data.status == "Published" ? false : true,

        },
        { separator: true },
        {
          label: "Mail Result ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.mailResult(data);
          },
          disabled: this.params.data.status != "Published" ? true : false,

        },
        { separator: true },
        {
          label: "Print Order",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printOrder(data);
          },
          disabled: this.params.data.status == "Result Entered" ? false : true || this.params.data.status == "Published" ? false : true,
        },
        { separator: true },
        {
          label: "LIS Barcode",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.lisBarcode(data);
          },
        },
        { separator: true },
        {
          label: "Print Sticker",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printInvestigationSticker(data);
          },
          disabled: (this.params.data.status !== "New Order" && this.params.data.locationType == 3) ? false : true,
        },
      ];
    }

    else if (this.pagetype == "sampleTypeLIstList") {
      this.items = [
        {
          label: "Edit Sample Type",
          command: (x: any) => {
            let taxDetails = this.params.data;
            this.params.context.componentParent.showModalDialog(taxDetails);

          },
        },
        { separator: true },
        {
          label: "Delete Sample Type",
          command: (x: any) => {
            let taxDetails = this.params.data;
            this.params.context.componentParent.DeleteSampleType(taxDetails);
          },
        },
        { separator: true },
      ];
    }

    else if (this.pagetype == "ExternalLab") {
      this.items = [
        {
          label: "Edit External lab",
          command: (x: any) => {
            let labdetails = this.params.data;
            this.params.context.componentParent.openModalExternalLab(labdetails);

          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "itemSampleType") {
      this.items = [
        {
          label: "Remove",
          command: (x: any) => {
            let eventdata = this.params.data;
            this.params.context.componentParent.delete(eventdata);

          },
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "addConsultation") {
      this.items = [
        {
          label: "Edit Credit",
          command: (x: any) => {
            let eventdata = this.params.data;
            this.params.context.componentParent.onEditcredit(eventdata);
          },
        },
        { separator: true },
        {
          label: "Set As Default",
          command: (x: any) => {
            let eventdata = this.params.data;
            this.params.context.componentParent.setCreditAsDefault(eventdata);
          },
        },
        { separator: true },
        {
          label: "Copy Credit",
          command: (x: any) => {
            let eventdata = this.params.data;
            this.params.context.componentParent.copyCredit(eventdata);
          },
        },
      ];
    }
    else if (this.pagetype == "trancferpatientdata") {
      this.items = [
        {
          label: "Result On Date ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.resultOnDate(data);
          },
        },
        { separator: true },

      ];
    }
    else if (this.pagetype == "trancferpateintitemdata") {
      this.items = [
        {
          label: "New Investigation ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.newSample(data);
          },
          disabled: (this.params.data.status == "Result Entered"  || this.params.data.status == "Published" || this.params.data.status == "Sample Collected") || (this.params.data.locationType != 3 || this.params.data.status!="New Order" || this.params.data.status == "Test Initiated" ) ? true : false         },

        { separator: true },
        {
          label: "Repeat Sample",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.repeatSample(data);
          },
        },
        { separator: true },
        {
          label: "Test Initiate",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.TestInitiated(data);
          },
          disabled: (this.params.data.status == "Sample Collected" && this.params.data.locationType == 3) ? false : true,
         }, { separator: true },
        {
          label: "Cancel Result",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelResult(data);
          },
          disabled: (this.params.data.status == "Result Entered" || this.params.data.locationType == 3 && this.params.data.status != "Sample Collected") &&(this.params.data.status!="New Order"&&this.params.data.status != "Published"&&this.params.data.status != "Test Initiated" ) ? false : true,         },

        { separator: true },
        {
          label: "Cancel Investigation",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelInvestigation(data);
          },
          disabled: (this.params.data.status == "Result Entered" ? true : false || this.params.data.status == "Published") || (this.params.data.locationType != 3 || this.params.data.status=="New Order") ? true : false        },

        { separator: true },
        {
          label: "Lab Card",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.labCard(data);
          },
          disabled:this.params.data.investgnNo=="N/A"?true:false
        },
        { separator: true },
        {
          label: "Publish Result ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.publishResult(data);
          },
          disabled: (this.params.data.status == "Published" || this.params.data.status == "Test Initiated" || this.params.data.status == "Sample Collected"  ) || (this.params.data.locationType != 3 || this.params.data.status=="New Order") ? true : false,        },

          { separator: true },
        {
          label: "Enter Result",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.enterResult(data);
          },
          disabled: (this.params.data.status == "Test Initiated" || this.params.data.locationType == 3 && this.params.data.status != "Sample Collected"  )&& (this.params.data.status != "Result Entered" && this.params.data.status!="New Order" && this.params.data.status != "Published" ) ? false : true ,        },
          { separator: true },
        {
          label: "Print Result With Stamp ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.PrintResultWithStamp(data);
          },
           disabled: this.params.data.status == "Result Entered" ? false : true || this.params.data.status == "Published" ? false : true,
        },
        { separator: true },
        {
          label: "Print Result WithOut Stamp ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.PrintResult(data);
          },
          disabled: this.params.data.status == "Result Entered" ? false : true || this.params.data.status == "Published" ? false : true,

        },
        { separator: true },

        {
          label: "Print Order ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.OderResult(data);
          },
        },
        { separator: true },
        {
          label: "Result Document",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.resultDocument(data);
          },
        },
        { separator: true },
        {
          label: "Sticker",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.sticker(data);
          },
          disabled:this.params.data.investgnNo=="N/A"?true:false

        },
        { separator: true },
        {
          label: "report Work List ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.reportWorkLIst(data);
          },
        },
        { separator: true },

      ];
    }
    else if (this.pagetype == "AssignSerive") {
      this.items = [
        {
          label: "Remove",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.removeitem(data);
          },
        },
        { separator: true },

      ];
    }
    else if (this.pagetype == "serviceOrderEMRHome") {
      this.items = [
        {
          label: "Report Worklist",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onReportWorkList(data);
          },
          disabled: this.params.data.status == 'Cancelled' ? true : false
        },
        { separator: true },
        {
          label: "View Document",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onViewDocument(data);
          },
          disabled: (this.params.data.status == 'Cancelled' || this.params.data.documentName == '') ? true : false
        },
        { separator: true },
        {
          label: "Change Details",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onChangeDetails(data);
          },
          disabled: this.params.data.status == 'Cancelled' ? true : false
        },
        // { separator: true },
        // {
        //   label: "Print",
        //   command: (x: any) => {
        //     let data = this.params.data;
        //     this.params.context.componentParent.onPrinttem(data);
        //   },
        //   disabled: this.params.data.status == 'Cancelled' ? true : false
        // },
        { separator: true },
        {
          label: "Print result with stamp",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printItemWithstamp(data);
          },
          disabled: this.params.data.status == 'Cancelled' || this.params.data.status == 'New Order' || this.params.data.status == 'Transfered' || this.params.data.status == 'Test Initiated' || this.params.data.status == 'Result Entered'
        },
        { separator: true },
        {
          label: "Print result without stamp",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printItemWithOutstamp(data);
          },
          disabled: this.params.data.status == 'Cancelled' || this.params.data.status == 'New Order' || this.params.data.status == 'Transfered' || this.params.data.status == 'Test Initiated' || this.params.data.status == 'Result Entered'
        },
        { separator: true },
        {
          label: "Cancel",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onCancelItem(data);
          },
          disabled: this.params.data.status == 'Cancelled' ? true : false
        },
        { separator: true },
      ];
    }
    else if (this.pagetype == "EstimateSummary") {
      this.items = [
        {
          label: "Print Estimate",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printEstimate(data);
          },
        },
        { separator: true },

      ];
    }
    else if (this.pagetype == "EstimateDetails") {
      this.items = [
        {
          label: "Cancel",
          command: (x: any) => {
            let data = this.params.data;
            console.log('gen data', data);
            this.params.context.componentParent.cancelEstimateOrder(data);
          },
        },
        { separator: true },

      ];
    }
    else if (this.pagetype == "OrderSummary") {
      this.items = [
        {
          label: "Print Order",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printOrder(data);
          },
        },
        { separator: true },

      ];
    }

  }


  CheckedValues(isCheckCons) {
    if (this.pagetype == "ServiceTax") {
      if (isCheckCons == true) {

        this.params.context.componentParent.ServiceCheckingItems(this.params.data, "checked");
      }
      else {

        this.params.context.componentParent.ServiceCheckingItems(this.params.data, "unchecked");
      }
    }

  }

  CheckedValuesSponsorRule(isCheckCons) {

    if (this.pagetype == "SponsorDrugList") {
      if (isCheckCons == true) {

        this.params.context.componentParent.SponsorRuleCheckingList(this.params.data, "checked");
      }
      else {

        this.params.context.componentParent.SponsorRuleCheckingList(this.params.data, "unchecked");
      }
    }
  }
  changeCheckBoxSavingScheme(isChecked) {
    if (this.pagetype == "savingScheme") {
      this.params.context.componentParent.savingSchemeChange(this.params.data, isChecked);
    } else if (this.pagetype == 'tabScheme') {
      this.params.context.componentParent.tabSchemeChange(this.params.data, isChecked);
    }
  }

  sponsorRuleChange(event) {
    if (this.params.gridType == 'dedGroup' || this.params.gridType == 'coPayGroup') {
      this.params.context.componentParent.groupFieldChange(this.params.data, event.target.checked, this.params.gridType);
    } else {
      this.params.context.componentParent.itemFieldChange(this.params.data, event.target.checked, this.params.gridType);
    }
  }

  changeCheckBoxTabOpen(event) {
    this.params.context.componentParent.tabOpenChange(this.params.data, event.target.checked);
  }

  refresh(): boolean {
    return false;
  }



}
