import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { EMRService } from '../services/emr.service';
import { Response } from '../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-investigation-flow-sheet',
  templateUrl: './investigation-flow-sheet.component.html',
  styleUrls: ['./investigation-flow-sheet.component.scss']
})
export class InvestigationFlowSheetComponent implements OnInit, OnChanges {

  public flowSheetData: any[] = [];

  @Input() visitList : any[] = [];
  @Input() branchId: any;
  @Input() patientId: any;
  @Input() resultServiceItems: any;

  public consultationList: any[] = [];
  public orderList: any[] = [];
  public resultLists : any[] = [
    // {
    //   itemGroup: 'Group 1',
    //   groupId: 1,
    //   serviceItems: [
    //     {
    //       itemName: 'Test 1',
    //       itemId: 1,
    //       testList: [
    //         {
    //           testName: 'Name 1',
    //           testId: 1,
    //           testResults: [
    //             {
    //               consultationId: 124578,
    //               result: 'Negative'
    //             }
    //           ]
    //         },
    //         {
    //           testName: 'Name 2',
    //           testId: 2,
    //           testResults: [
    //             {
    //               consultationId: 124578,
    //               result: 'Positive'
    //             }
    //           ]
    //         },
    //         {
    //           testName: 'Name 3',
    //           testId: 3,
    //           testResults: [
    //             {
    //               consultationId: 124578,
    //               result: '25'
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       itemName: 'Test 2',
    //       itemId: 2,
    //       testList: [
    //         {
    //           testName: 'Name 4',
    //           testId: 4,
    //           testResults: [
    //             {
    //               consultationId: 124578,
    //               result: 'Negative'
    //             },
    //             {
    //               consultationId: 122118,
    //               result: '10'
    //             }
    //           ]
    //         },
    //         {
    //           testName: 'Name 5',
    //           testId: 5,
    //           testResults: [
    //             {
    //               consultationId: 124578,
    //               result: '+ve'
    //             },
    //             {
    //               consultationId: 122118,
    //               result: '14'
    //             }
    //           ]
    //         },
    //         {
    //           testName: 'Name 6',
    //           testId: 6,
    //           testResults: [
    //             {
    //               consultationId: 124578,
    //               result: '35'
    //             },
    //             {
    //               consultationId: 122118,
    //               result: '25'
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   itemGroup: 'Group 2',
    //   groupId: 2,
    //   serviceItems: [
    //     {
    //       itemName: 'Test 10',
    //       itemId: 10,
    //       testResults: [
    //         {
    //           consultationId: 12,
    //           result: '120/10'
    //         },
    //         {
    //           consultationId: 13,
    //           result: '78'
    //         },
    //         {
    //           consultationId: 14,
    //           result: 'Normal'
    //         }
    //       ]
    //     },
    //     {
    //       itemName: 'Test 12',
    //       itemId: 12,
    //       testResults: [
    //         {
    //           consultationId: 14,
    //           result: '10.5'
    //         },
    //       ]
    //     },
    //     {
    //       itemName: 'Test 13',
    //       itemId: 13,
    //       testResults: [
    //         {
    //           consultationId: 14,
    //           result: 'Negative'
    //         },
    //       ]
    //     }
    //   ]
    // }
  ];

  constructor(private emrService: EMRService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    // this.generateFlowSheet();
    this.getFlowSheetData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.consultationList = _.map(this.visitList, (visit)=>{
    //   return {
    //     consultDate: visit.visitDate,
    //     consultationId: visit.consultationId
    //   }
    // });
    this.orderList = _.map(this.resultServiceItems, (visit)=>{
      return {
        orderDate: visit.orderDate,
        orderId: visit.orderId
      }
    });
    this.generateFlowSheet();
  }

  getFlowSheetData(){
    let payload = {
      PatientId: this.patientId,
      BranchId: this.branchId
    }

    this.emrService.getInvestigationFlowSheetData(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.resultLists = response.response[0]?.itemGroupList;
          this.generateFlowSheet();
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

  generateFlowSheet(){
    this.orderList = _.orderBy(this.orderList, 'orderId', 'desc');
    if(this.orderList.length && this.resultLists.length){
      _.forEach(this.orderList, (consultation: any)=>{
        _.forEach(this.resultLists, (result: any)=>{
          _.forEach(result?.serviceItemItemList, (item: any)=>{
            _.forEach(item?.resultParamList, (test: any)=>{
              // _.forEach(test.resultList, (re)=>{
              //   let a = _.filter(re?.consultationsList, {consultationId: consultation.consultationId});
              //   if(!a.length){
              //     re.consultationsList?.push({
              //       consultationId: consultation.consultationId,
              //       resultValue: ''
              //     })
              //   }
              //   re.consultationsList = _.orderBy(re.consultationsList, 'consultationId' , 'asc' )
              // })

                let a = _.filter(test?.resultList, {orderId: consultation.orderId});
                if(!a.length){
                  test.resultList?.push({
                    orderId: consultation.orderId,
                    resultValue: '',
                    resultId: 0
                  })
                }
                test.resultList = _.orderBy(test.resultList, 'orderId' , 'desc' )
            })
          })
        })
      });
      this.flowSheetData = this.resultLists;
      console.log(this.flowSheetData)
    }else{
      this.flowSheetData = [];
    }
  }

  // generateFlowSheet(){
  //   this.consultationList = _.orderBy(this.consultationList, 'consultationId', 'asc');
  //   if(this.consultationList.length && this.resultLists.length){
  //     _.forEach(this.consultationList, (consultation: any)=>{
  //       _.forEach(this.resultLists, (result: any)=>{
  //         _.forEach(result?.serviceItemItemList, (item: any)=>{
  //           _.forEach(item?.resultParamList, (test: any)=>{
  //             // _.forEach(test.resultList, (re)=>{
  //             //   let a = _.filter(re?.consultationsList, {consultationId: consultation.consultationId});
  //             //   if(!a.length){
  //             //     re.consultationsList?.push({
  //             //       consultationId: consultation.consultationId,
  //             //       resultValue: ''
  //             //     })
  //             //   }
  //             //   re.consultationsList = _.orderBy(re.consultationsList, 'consultationId' , 'asc' )
  //             // })

  //               let a = _.filter(test?.resultList, {consultationId: consultation.consultationId});
  //               if(!a.length){
  //                 test.resultList?.push({
  //                   consultationId: consultation.consultationId,
  //                   resultValue: '',
  //                   resultId: 0
  //                 })
  //               }
  //               test.resultList = _.orderBy(test.resultList, 'consultationId' , 'asc' )
  //           })
  //         })
  //       })
  //     });
  //     this.flowSheetData = this.resultLists;
  //     console.log(this.flowSheetData)
  //   }else{
  //     this.flowSheetData = [];
  //   }
  // }

  getTotalTestCount(serviceItems: any[]): number{
    let count = 0;
    _.forEach(serviceItems, (item: any)=>{
      count = count + item?.resultParamList?.length
    })
    return count;
  }

}
