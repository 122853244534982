import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PrevVitalSignsModalComponent } from '../modals/prev-vital-signs-modal/prev-vital-signs-modal.component';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { EMRService } from '../../services/emr.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PrevMedicalDecisionModalComponent } from '../modals/prev-medical-decision-modal/prev-medical-decision-modal.component';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { SharedService } from '../../../../shared/shared.service';
import { Settings } from '../../../settings/model/settings.model';

@Component({
  selector: 'app-medical-decision',
  templateUrl: './medical-decision.component.html',
  styleUrls: ['./medical-decision.component.scss']
})
export class MedicalDecisionComponent implements OnInit ,OnChanges{
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  disabled:boolean=false
  
 
  public EmrPatientInfo:EMRPatientInfo;
  private globals: globalvars = new globalvars();
  private subscription: Subscription;
  private FormValueChangeChecker: boolean = false;

  public medicalDecisionForm: FormGroup;
  referredToList: any[];
  internalConsultantlistData: any=[];
  externalConsultantlistData: any=[];

  constructor(private modalService: BsModalService,
              private EmrService: EMRService,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder,
              private sharedservice:SharedService,
              private DashboardService:DashboardService) { 
                this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
                  if (this.sharedservice.checkglobals(glob)) {
                    this.globals = glob;
                    //this.BranchId=this.form.get('branchId')?.value;
                  }
                });
              }

  ngOnInit(): void {
    this.EmrPatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initForm();
    this.GetMedicalDecision();
    this.GetReferredTo();
    this.GetExternalConsultants()
    this.GetInternalConsultants()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 39 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (isSettingWithValueZeroPresent) {
        // Your logic when a setting with value 0 is present and billEdit is true
      
        this.disabled=true
      }
      
    }
  }


  initForm(){
    this.medicalDecisionForm = this.formBuilder.group({
      mDId: new FormControl(0),
      labOrder: new FormControl(""),
      radiologyOrder: new FormControl(""),
      treatmentOrder: new FormControl(""),
      oldMedicalRecord: new FormControl(""),
      referToPhysician: new FormControl(0),
      externalConsultant:new FormControl(false),
      referralNote: new FormControl(""),
      remarks: new FormControl(""),
      referedTo: new FormControl(0),
      clinicalImpression: new FormControl(""),
      differencialDiagnosis: new FormControl(""),
      eligibility: new FormControl(""),
      visitId: new FormControl(this.EmrPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
    })
  }

  GetMedicalDecision(){
    let input={
      VisitId:this.EmrPatientInfo.VisitId,
      PatientId:this.EmrPatientInfo.PatientId,
      ShowAll:1
    };

    this.EmrService.GetMedicalDecision(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.medicalDecisionForm.patchValue(response);
          this.medicalDecisionForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker=true;
           })
        }
      });
  }

  SaveMedicalDecision(){
    if(this.EmrPatientInfo.CrudType!=3 &&
      this.EmrPatientInfo.VisitId>0 && this.FormValueChangeChecker)
    {
      this.medicalDecisionForm.controls.referedTo.patchValue(Number(this.medicalDecisionForm.controls.referedTo.value));
      let MedicalDecisions = this.medicalDecisionForm.getRawValue();
      MedicalDecisions.userId=Number(localStorage.getItem('user_id'));
      this.EmrService.SaveMedicalDecision(
        MedicalDecisions
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastrService.success("Success: " + "Medical Decision Details Saved Successfully...");
          }
          else{
            this.toastrService.error(res.errorMessage.message);
          }
        });
    }
    else{
      if(!this.FormValueChangeChecker){
       // this.toastr.warning("Info: " + "No changes detected in the entry");
      }
  }
  }

  openHistory(){
    let initialState = {
      EmrPatientInfo: this.EmrPatientInfo
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const historyModalRef= this.modalService.show(PrevMedicalDecisionModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

  GetReferredTo(){
    this.EmrService.GetReferredTo().subscribe((res) => {
      this.referredToList = res.response;
    })
  }

  GetInternalConsultants() {
    let input={
      BranchId:Number(this.globals.HospitalId),
      ConsultantType: 0
    }
    this.DashboardService.GetAllConsultants(input).subscribe((res) => {
      this.internalConsultantlistData = res.response;

    });
  }

  GetExternalConsultants() {
    let input={
      BranchId:Number(this.globals.HospitalId),
      ConsultantType: 1
    }
    this.DashboardService.GetAllConsultants(input).subscribe((res) => {
      this.externalConsultantlistData = res.response;
    });
  }

  resetReferToPhysician(){
    this.medicalDecisionForm.controls.referToPhysician.patchValue(0)
  }
}
