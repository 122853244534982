import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from "@angular/router";
import { PatientService } from '../../client/services/patient.service';
@Component({
  selector: 'app-patient-print',
  templateUrl: './patient-print.component.html',
  styleUrls: ['./patient-print.component.scss']
})
export class PatientPrintComponent implements OnInit {
  currentDate: string;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  Type: number = 0;
  patientId: any;
  patientDetails: any;
  regDate: any;
  patientName: any;
  regNo: any;
  ageInYear: any;
  ageInMonth: any;

  PrintSerials: { SerialId: number; Name: string; }[];
  constructor(
    public datepipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private patientService: PatientService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.patientId = Number(params.id);

    });
    this.currentDate = this.datepipe.transform(new Date(), "dd-MM-YYYY");
    this.patientService.GetPatientDetailsbyId(this.patientId).subscribe((res) => {
      this.patientDetails = res.response;
      this.regDate = this.patientDetails[0].regDate;
      this.patientName = this.patientDetails[0].patientName;
      this.regNo = this.patientDetails[0].regNo;
      this.ageInYear = this.patientDetails[0].ageInYear;
      this.ageInMonth = this.patientDetails[0].ageInMonth;
      this.PrintSerials = [{
        SerialId: 12345,
        Name: this.regNo
      },
      ];

    });

  }


  printPage() {
    let print_div=document.getElementById("printPatient");
    var print_area = window.open();
print_area.document.write(print_div.innerHTML);
print_area.document.close();
print_area.focus();
print_area.print();
print_area.close();
    
  }
  close() {
    //window.close();
    this.router.navigate(['/registration'])
  }





  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    ws.A1.h = "<b>A</b>";
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'PatientCard.xlsx');
  }



}
