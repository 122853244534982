import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EMRService } from '../../../../emr/services/emr.service';
import { globalvars } from '../../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs/internal/Subscription';
import { SharedService } from '../../../../../shared/shared.service';
import { Questionare } from '../../../../emr/interfaces/Questionare';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patient-questionnaire-modal',
  templateUrl: './patient-questionnaire-modal.component.html',
  styleUrls: ['./patient-questionnaire-modal.component.scss']
})
export class PatientQuestionnaireModalComponent implements OnInit {

  QuestionnaireForm:FormGroup;
  subscription: Subscription;
  BranchesId: any;
  @Input() branchesId;
  @Input() patientId;

  constructor(private EMRService: EMRService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    ) {   }

  ngOnInit(): void {
    this.QuestionnaireForm=new FormGroup({
      PatientQuestionares: new FormArray([]),
    });
    this.GetQuestionare();
  }

  GetQuestionare()
  {
    let input={
      "PatientId":this.patientId,
      "branchId":this.branchesId
    };
    this.EMRService.GetQuestionare(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          res.response.forEach(element => {
            this.addMapFormGroup(element.patientQuestionaresData,0,element.groupName);
          });
        }
      });
  }

  public addMapFormGroup(data: Questionare[] = [], saved = 0,groupName) {
    const Questions = this.QuestionnaireForm.get("PatientQuestionares") as FormArray;
    if (data?.length > 0) {
      data.forEach((element, i) => {
        Questions.push(this.createListGroup(element, saved,groupName));
      });
    }
  }

  private createListGroup(data: Questionare, saved = 0,groupName): FormGroup {

    return new FormGroup({
      patientId: new FormControl(this.patientId),
      qnId: new FormControl(data["qnId"]),
      question: new FormControl(data["question"]),
      ansId: new FormControl(data["ansId"]),
      branchId: new FormControl(data["branchId"]),
      notes: new FormControl(data["notes"]),
      groupName:new FormControl(groupName),
    });
  }

  close() {
    this.modalRef.hide()
  }

  SaveQuestionare()
  {
    let input=this.QuestionnaireForm.getRawValue()
    this.EMRService.SaveQuestionare(
      input
    ).subscribe(
      (res) => {
        if (res.status == 200 && res.message == "Success") {
          this.toastr.success("Success: " + "Questionare Details Saved Successfully...");
        }
        else{
          this.toastr.success("Error: " + "Some unexpected error occures! try again later.");
        }
    });
  }

}
