import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocationService } from "../../user-permission/location/location.service";
import { ServicePoint } from "../../client/interfaces/ServicePoint";
import { Location } from "../../client/interfaces/Location";
import { SubLocationSettingsService } from "./sub-location-settings.service";
import { ToastrService } from "ngx-toastr";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ColDef, GridOptions } from "ag-grid-community";
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { AssignserviceComponent } from "../modals/assignservice/assignservice.component";
import { ConfigurationService } from "../../client/services/configuration.service";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-sub-location-settings",
  templateUrl: "./sub-location-settings.component.html",
  styleUrls: ["./sub-location-settings.component.scss"],
})
export class SubLocationSettingsComponent implements OnInit {
  //Variable Declarations
  subscription: Subscription;
  ServicePointtabSet: boolean; // for tab switching
  LocationPointtabSet: boolean; // for tab switching
  public ServicePoint: ServicePoint; // form interface
  ServicePointList: ServicePoint[] = [];
  form: FormGroup; // form variable
  locationList: Location[]; //; location list
  public submitted: boolean = false; // form validation controller
  globals: globalvars = new globalvars();
  BranchesId: any;
  result: any;
  // grid variables
  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];
  rowGroupPanelShow = "always";
  public context;
  public gridApi;
  public statusBar;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  public noRowsTemplate;
  public frameworkComponents;
  user_id: any;

  // end of grid variables

  //END OF VARIALBLE DECLARATION
  functionPermissionData: any;
  public locationId=''

  constructor(
    private locationservice: LocationService,
    private subLocationSettingsService: SubLocationSettingsService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,
    private route:ActivatedRoute,
    private router: Router,
  ) {
    this.onTab(1); // default tab set
    this.user_id = localStorage.getItem('user_id');
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });
  }

  ngOnInit(): void {
    this.getParams()
    // form initialize
    this.form = new FormGroup({
      SPointId: new FormControl(0),
      LocationId: new FormControl(this.locationId,[Validators.required]),
      SPointName: new FormControl("", [Validators.required]),
      Schedulable: new FormControl(false),
      RoutineNos: new FormControl(0, [Validators.required]),
      UrgentNos: new FormControl(0, [Validators.required]),
      Active: new FormControl(true),
      UserId: new FormControl(0),
      OrderId:new FormControl(0,[Validators.required]),
    });
    // ag grid table head and col definitions
    this.columnDefs = [
      {
        headerName: "Location Name",
        field: "locationName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Point Name",
        field: "sPointName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2
      },
      {
        headerName: "Routine Nos.",
        field: "routineNos",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2
      },
      {
        headerName: "Sort No",
        field: "orderId",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2
      },
      {
        headerName: "Action",
        filter: false,
        resizable: false,
        unSortIcon: true,
        field: "taxId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "servicepoints" },
      },
    ];
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.context = { componentParent: this };

    this.GetServicePoints();

    this.GetLocations();
    this.functionPermission();
  }

  getParams(){
    this.locationId= this.route.snapshot.queryParamMap.get('id');
    debugger
  }

  /******* Function Definitions******************************/
  // for geting all form items

  get f() {
    return this.form.controls;
  }

  // tab switch
  onTab(number) {
    this.ServicePointtabSet = false;
    this.LocationPointtabSet = false;

    if (number == "1") {
      this.ServicePointtabSet = true;
    } else if (number == "2") {
      this.LocationPointtabSet = true;
    }
  }
  // end of tab switch

  //get locations

  GetLocations() {
    this.locationservice.GetLocations(Number(this.BranchesId), 0).subscribe((res) => {
      this.locationList = res.response;
      this.locationList.forEach((a) => {
        (a.id = a.locationId), (a.text = a.locationName);
      });
    });
  }
  // save access point
  SaveAccessPoint() {
    const foundElement = this.functionPermissionData.find(x => x.id === 489)
    if (foundElement.id == 489 && foundElement.isAllowed == true) {
    this.submitted = true;
    if (this.form && this.form.valid) {
      this.ServicePoint = this.form.getRawValue();
      this.ServicePoint.LocationId = Number(this.ServicePoint.LocationId);
      this.subLocationSettingsService
        .saveServicePoint(this.ServicePoint)
        .subscribe(
          (res) => {
            this.submitted = false;

            if (res.status == 200 && res.message == "Success") {
              // this.clearForm();
              debugger
              this.form.patchValue({
                SPointId: 0,
                SPointName:'',
                Schedulable: false,
                RoutineNos: 0,
                UrgentNos: 0,
                Active: true,
                UserId: 0,
                OrderId:0
              });
              this.toastr.success("Data saved successfully");
              this.GetServicePoints();
            } else if (res.status == 200) {
              this.toastr.error("Failed : " + res.message);
            } else {
              this.toastr.error("Failure: " + res.response.message);
            }
          },
          (err) => {
            this.toastr.error("Some unexpected error occures try again later");
          }
        );
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  // end of save access point

  // form reset with default values
  clearForm() {
    this.form.reset({
      SPointId: 0,
      LocationId: 0,
      Schedulable: false,
      RoutineNos: 0,
      UrgentNos: 0,
      Active: true,
      UserId: 0,
      OrderId:0
    });
  }

  // data for grid

  GetServicePoints() {
    //GetAssociativeLocation
    let input = {
      SPointId: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId,
      LocationId:this.form.value.LocationId !='' ? Number(this.form.value.LocationId) : 0
    };
    this.subLocationSettingsService.GetServicePoints(input).subscribe((res) => {
      this.ServicePointList = res.response;
    });
  }

  // grid initialize
  onGridReady(params) {
    this.gridApi = params.api;
  }
  // data filing for edit
  EditServicePoint(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 490)
    if (foundElement.id == 490 && foundElement.isAllowed == true) {
    data = this.upper(data);
    this.form.patchValue(data);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  DeleteServicePoint(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 491)
    if (foundElement.id == 491 && foundElement.isAllowed == true) {
    this.form.patchValue(data);
    let input =
      {
       SPointId:data.sPointId,
      UserId:Number(this.user_id)
    }

    this.subLocationSettingsService.DeleteSercicePoints(input).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {

        this.toastr.success("Service deleted successfully");
       this.GetServicePoints();
      }
      else {
        this.toastr.error("Failure: " + this.result.message);

      }

    },
    );
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  /// For object key name convertion
  upper(obj) {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      let first = key.substr(0, 1).toUpperCase();
      first = first + key.substr(1);

      newobj[first] = obj[key];
    }
    return newobj;
  }

  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);
  }
  /*************END OF FUNCTION DEFINITION*******************/
  AssignServiceItem(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 492)
    if (foundElement.id == 492 && foundElement.isAllowed == true) {
    let initialState = {
      BranchesId:this.BranchesId,
      ServicePointId:data.sPointId
    }
    const modalRef = this.modalService.show(AssignserviceComponent, Object.assign({class: "gray modal-xl"  }, { initialState }))
    modalRef.content.getitemEmitter.subscribe((res) => {

      if (res) {
        this.GetServicePoints()

      }
    })
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  onLocationselect(event){
    this.form.patchValue({
      LocationId:event
    })
    this.router.navigate(['/config/subLocationSettings'],{ queryParams: { id: this.form.value.LocationId }});
    this.GetServicePoints()
  }
}

