import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-UserRights',
  templateUrl: './userrights.component.html',
  styleUrls: ['./userrights.component.scss']
})
export class UserRightsComponent implements OnInit {
	 displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  /* dataSource = ELEMENT_DATA; */
  products: any={ };
  rowData : any=[];
  
   name: string;
  position: number;
  weight: number;
  symbol: string;
  
  constructor() { }

  ngOnInit(): void {
    //this.products = [{'code':'Aj1002','name':'Vasumathi Eye Clinic','Address1':'1st floor,Lullu Mall','address2':'Edapally','street':'Kochi','city':'Ernakulam'},{'code':'TMC_Kerala','name':'Government Medical College TVM','Address1':'Medical College PO','address2':'TVM','street':'ULLOOR','city':'Thiruvananthapuram'},{'code':'KIM','name':'KIMS','Address1':'','address2':'','street':'','city':''},{'code':'AIIMS','name':'All India Institute Of Medical Sciences','Address1':'Delhi','address2':'Delhi','street':'Delhi','city':'Delhi'}];
	 this.rowData = [
           
            {
                data: { brand: 'Task Group Name Configuration '},
                children: [
				
                    {
                        data: { brand :"Body Part"},
                    },
                    {
                        data: { brand: 'Consultant' },
                    },
					{
                        data: { brand: 'Card Type' },
                    },
					{
                        data: { brand: 'City' },
                    },
					{
                        data: { brand: 'Commission Rules' },
                    },
					{
                        data: { brand: 'Communication' },
                    },
                ]
            },
            {
                data: { brand: 'Task Group Name Functionalities'  },
                children: [
				 {
                        data: { brand: 'Appointment' },
                    },
                    {
                        data: { brand: 'Billing'  },
                    },
					 {
                        data: { brand: 'Consultation' },
                    },
					{
                        data: { brand: 'ConsultDesk' },
                    },
					{
                        data: { brand: 'Insurance Claim' },
                    },
					{
                        data: { brand: 'Manage Shift' },
                    },
                    
                ]
            },
            {
                data: { brand: 'Task Group Name LeHealth' },
                children: [
                   {
                        data: { brand: 'Allow Access To Billing'},
                    },
					  {
                        data: { brand: 'Change Password'},
                    },
					  {
                        data: { brand: 'Change Date'},
                    }
                    
                ]
            },
            {
                data: { brand: 'Task Group Name Reports'  },
                children: [
                    {
                        data: { brand: 'Report appointment'  },
                    },
					  {
                        data: { brand: 'Bill Summary'  },
                    },
					  {
                        data: { brand: 'Credit Details'  },
                    }
                    
                ]
            }
            
        ];
		
	/* const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
]; */
		
  }
  


}
