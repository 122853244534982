import { Component, Input, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GeneralActionComponent } from "../../../client/general-action/general-action.component";
import { ColDef } from "ag-grid-community";
import { ConfigurationService } from "../../../client/services/configuration.service";
import { AddResultFormHeaderComponent } from "../add-result-form-header/add-result-form-header.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-result-form-headers",
  templateUrl: "./result-form-headers.component.html",
  styleUrls: ["./result-form-headers.component.scss"],
})
export class ResultFormHeadersComponent implements OnInit {
  @Input() RformId:number
  defaultColDef: { sortable: boolean; filter: boolean; resizable: boolean };
  noRowsTemplate: string;
  frameworkComponents: { buttonRender: any };
  gridApi: any;
  parameterColumnDefs: ColDef[] = [];
  parameterData: any;
  rowGroupPanelShow = "always";
  public context;
  statusBar: any;
  public parametergridOptions = {
    pagination: true,
    paginationPageSize: 10,
    suppressRowTransform: true,
  };
  loadingTemplate: any;

  constructor(private modalRef: BsModalRef,
    private configservice:ConfigurationService,
    private modalService: BsModalService,
    private toastr:ToastrService
  ) {}

  ngOnInit(): void {
    this.context = { componentParent: this };
    this.parameterColumnDefs = [
      {
        headerName: "Header name",
        field: "headerName",
        resizable: false,
        sortable: true,
        filter: true,
        flex: 2,
        minWidth: 100,
        enableRowGroup: true,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "headerList" },
      },
    ];
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    this.getHeaderlist()
  }

  onFilterTextBoxChanged(event) {
    this.gridApi.setQuickFilter(event.target.value);
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

  getHeaderlist(){
    let payload={
      RformId:this.RformId,
      ShowAll:0
    }
    this.configservice.GetHeaderList(payload).subscribe({
      next:(res)=>{
        this.parameterData=res.response
      }
    })
  }

  addHeaderOpenModal() {
    let initialState = {
      RFHeaderId:0,
      RformId:this.RformId,
      HeaderName:''
    }

    const modalRef = this.modalService.show(AddResultFormHeaderComponent, Object.assign({}, { initialState }))
    // Object.assign({}, { class: "gray modal-lg" })
    modalRef.content?.resultEmitter.subscribe((res) => {
      if (res.status) {
        this.getHeaderlist()
      }
    })
  }

  editHeaderForm(data){
    let initialState = {
      RFHeaderId:data.rfHeaderId,
      RformId:this.RformId,
      HeaderName:data.headerName
    }

    const modalRef = this.modalService.show(AddResultFormHeaderComponent, Object.assign({}, { initialState }))
    // Object.assign({}, { class: "gray modal-lg" })
    modalRef.content?.resultEmitter.subscribe((res) => {
      debugger
      if (res.status) {
        this.getHeaderlist()
      }
    })
  }

  deleteHeaderForm(data){
    let payload={
      RFHeaderId:data.rfHeaderId,
      RformId:this.RformId,
      HeaderName:data.headerName,
      IsDisplayed:1,
      IsDeleted:1
    }
    this.configservice.InsertUpdateHeader(payload).subscribe({
      next:(res)=>{
        if(res.status == 200){this.toastr.success("Delete Successfull")
          this.getHeaderlist()

        }
      }
    })
  }

  close() {
    this.modalRef.hide();
  }
}
