import { Component, OnInit, TemplateRef, ViewChild ,Input, OnDestroy} from '@angular/core';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from '../../emr/services/emr.service';
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MenstrualHistory } from '../interfaces/MenstrualHistory';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';

@Component({
  selector: 'app-general-menstrual-history',
  templateUrl: './general-menstrual-history.component.html',
  styleUrls: ['./general-menstrual-history.component.scss']
})
export class GeneralMenstrualHistoryComponent implements OnInit, OnDestroy {
  @Input() IsViewtab;
  EMRPatientInfo:EMRPatientInfo;
  form: FormGroup; // form variable
  MenstrualHistory:MenstrualHistory;
  MenstrualHistoryModalVal:MenstrualHistory;

  PrevMenstrualHistory:MenstrualHistory[]=[];
  Loading:boolean=false;
  FormValueChangeChecker:boolean=false;
  modalRef?: BsModalRef;
  modalRefAlert?: BsModalRef;
  ConsultationTime: FormGroup;
  @ViewChild("template") modaltemp: any;
  IsDisabled: boolean = true;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  firstDate: any;
  secondDate: any;
  AlertList: any;
  alertId: any;
  isEdit: any = false;
  constructor(
    private EMRService:EMRService,
    private toastr: ToastrService,private sharedservice: SharedService,
    private modalService: BsModalService,private datepipe: DatePipe,
  ) {
    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();
    this.GetMenstrualHistory();
    this.GetPrevMenstrualHistory();
    this.ConsultationTime = new FormGroup({
      StartTime: new FormControl(''),
      EndTime: new FormControl(''),
      Details: new FormControl(''),
      IsActive: new FormControl(true),
    });
   }

  ngOnInit(): void {

    if (this.IsViewtab == true) {
      this.IsDisabled = false;
    } else if (this.IsViewtab == "undefined") {
      this.IsDisabled = true;
    }

    this.form=new FormGroup({
      mid: new FormControl(0),
      menarche: new FormControl(""),
      cycle: new FormControl(""),
      lmp: new FormControl(""),
      flow: new FormControl(""),
      StartTime: new FormControl(""),
      contraception: new FormControl(""),
      papSmear: new FormControl(""),
      memogram: new FormControl(""),
      obstertrichHistory: new FormControl(""),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
      LMP2: new FormControl(0),
      date: new FormControl(''),
      HTN: new FormControl(false),
      DM: new FormControl(false),
      PSY: new FormControl(false),
      Other: new FormControl(false),
      Gravida: new FormControl(""),
      Parity: new FormControl(""),
      EDC: new FormControl(""),
      Infertility: new FormControl(""),
      Infertility1: new FormControl(false),
      Infertility2: new FormControl(false),
      Anaemia: new FormControl(""),
      Oedema: new FormControl(""),
      CV: new FormControl(0),
      RS: new FormControl(0),
      BloodGroupNote: new FormControl(""),
      BloodGroup: new FormControl(0),
      BreastFeeding: new FormControl(""),
      BreastFeeding1: new FormControl(false),
      BreastFeeding2: new FormControl(false),
      NormalDelivery: new FormControl(false),
      NormalDeliveryNote: new FormControl(""),
      Caesaerian: new FormControl(false),
      CaesaerianNote: new FormControl(""),
      LCB: new FormControl(false),
      LCBNote: new FormControl(""),
      GynecHistory: new FormControl(""),
      UVScan: new FormControl(""),
    });
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.globals.HospitalId
        this.BranchesId = this.globals.HospitalId;
      }

    });
    this.GetAlert()
  }
  GetAlert() {
    let input = {
       "PatientId": this.EMRPatientInfo.PatientId,
       "BranchId": this.BranchesId,
       "AlertId": 0,
       "AlertType": '',
       };
    this.AlertList = [];
    this.EMRService.getAlertList(input).subscribe(
      (res:any) => {
        if (res.status == 200) {
          let Response = res.response
          if (Response.length > 0) {
            let date = new Date()
            this.AlertList = res.response;
            this.AlertList.map((res:any)=>{
              if(res.isDisplayed && res.alertType === 'LMP') {
                this.isEdit = true;
                this.form.get('StartTime').setValue(res.validFrom)
                this.alertId = res.alertId
              } else {
                this.isEdit = false;
              }
              if(res.isDisplayed){
                let date1 = moment(res.validTo).isSame(date);
                let date2 = moment(res.validTo).isBefore(date);
                if(date2){
                  res.validDate = true;
                } else {
                  res.validDate = false
                }
              }
            })
          }
        }
      });
  }
  SaveMenstrualHistory()
  {
    if(  this. EMRPatientInfo.CrudType!=3 &&
      this.EMRPatientInfo.VisitId>0 && this.Loading && this.FormValueChangeChecker)
    {
      let MenstrualHistory=this.form.getRawValue();
      MenstrualHistory.userId=Number(localStorage.getItem('user_id'));
      if(MenstrualHistory.Infertility1 == true) {
        MenstrualHistory.Infertility = "Threated"
      } else if(MenstrualHistory.Infertility2 == true){
        MenstrualHistory.Infertility = "UnThreated"
      }

      if(MenstrualHistory.BreastFeeding1 == true) {
        MenstrualHistory.BreastFeeding = 'yes'
      } else if(MenstrualHistory.BreastFeeding2 == true){
        MenstrualHistory.BreastFeeding = 'no'
      }

      MenstrualHistory.LMP2 = Number(MenstrualHistory.LMP2);
      MenstrualHistory.CV = Number(MenstrualHistory.CV);
      MenstrualHistory.RS = Number(MenstrualHistory.RS);
      MenstrualHistory.BloodGroup = Number(MenstrualHistory.BloodGroup);
      this.EMRService.SaveMenstrualHistory(
        MenstrualHistory
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastr.success("Success: " + "Menstrual History Details Saved Successfully...");
          }
          else{
            Swal.fire(
              'Error!',
              'Some unexpected error occures! try again later.',
              'error'
            )
          }
        });
    }
    else{
      if(!this.FormValueChangeChecker){
       // this.toastr.warning("Info: " + "No changes detected in the entry");
      }
  }
  }

  GetMenstrualHistory()
  {
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };

    this.EMRService.GetMenstrualHistory(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          // this.form.patchValue(response);
          if(res.response.length !== 0){
            this.form.patchValue({
              mid: response?.mid,
              menarche: response?.menarche,
              cycle: response?.cycle,
              lmp: response?.lmp,
              flow: response?.flow,
              contraception: response?.contraception,
              papSmear: response?.papSmear,
              memogram: response?.memogram,
              obstertrichHistory: response?.obstertrichHistory,
              visitId: response?.visitId,
              userId: response?.userId,
              LMP2: response?.lmP2,
              HTN: response?.htn,
              DM: response?.dm,
              PSY: response?.psy,
              Other: response?.other,
              Gravida: response?.gravida,
              Parity: response?.parity,
              EDC: response?.edc,
              Anaemia: response?.anaemia,
              Oedema: response?.oedema,
              CV: response?.cv,
              RS: response?.rs,
              BloodGroupNote: response?.bloodGroupNote,
              BloodGroup: response?.bloodGroup,
              NormalDelivery: response?.normalDelivery,
              NormalDeliveryNote: response?.normalDeliveryNote,
              Caesaerian: response?.caesaerian,
              CaesaerianNote: response?.caesaerianNote,
              LCB: response?.lcb,
              LCBNote: response?.lcbNote,
              GynecHistory: response?.gynecHistory,
              UVScan: response?.uvScan,
            });
            if(response?.infertility === 'Threated'){
              this.form.patchValue({
                Infertility1: true,
                Infertility2: false
              })
            } else if(response?.infertility === 'UnThreated'){
              this.form.patchValue({
                Infertility2: true,
                Infertility1: false,
              })
            }
            if(response?.breastFeeding === 'no'){
              this.form.patchValue({
                BreastFeeding2: true,
                BreastFeeding1: false,
              })
            } 
            else if(response?.breastFeeding === 'yes'){
              this.form.patchValue({
                BreastFeeding1: true,
                BreastFeeding2: false,
              })
            }
          }
          this.Loading=true;
          this.form.valueChanges.subscribe(x => {
            this.FormValueChangeChecker=true;
           })
        }
      });
  }

  GetPrevMenstrualHistory()
  {
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":0
    };

    this.EMRService.GetMenstrualHistory(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.PrevMenstrualHistory=res.response;

        }
      });
  }


  openModal(VisitId) {

    let input={
      "VisitId":VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };
    this.EMRService.GetMenstrualHistory(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.MenstrualHistoryModalVal=response;
          this.modalRef = this.modalService.show(
            this.modaltemp,
            {class: 'modal-lg modal-custom-style' }
          );
        }
      });


  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  openModalAlert(template: TemplateRef<any>) {
    this.modalRefAlert = this.modalService.show(template, { class: 'modal-lg modal-custom-style' });
    if(this.form.get('StartTime').value && !this.isEdit) {
      this.firstDate = this.datepipe.transform(this.form.get("StartTime").value, 'dd-MM-yyyy');
      this.ConsultationTime.controls.StartTime.setValue(this.firstDate)
      let date1= moment(this.form.get("StartTime").value).add(10, 'month').toDate(); // March 28
      this.secondDate = this.datepipe.transform(date1, 'dd-MM-yyyy');
      this.ConsultationTime.get("EndTime").setValue(this.secondDate)
      this.ConsultationTime.get("Details").setValue(this.secondDate)
    } else {
      this.AlertList.map((res:any)=>{
        if(res.alertType === 'LMP') {
          this.firstDate = res.validFrom
          this.secondDate = res.validTo
          this.ConsultationTime.controls.StartTime.setValue(res.validFrom)
          this.ConsultationTime.get("EndTime").setValue(res.validTo) 
          this.ConsultationTime.get("Details").setValue(res.alertNote) 
          this.ConsultationTime.get("IsActive").setValue(res.isDisplayed) 
        }
      })
    }
  }
  saveAlert(){
    if(this.ConsultationTime.get("StartTime").value === ""){
      this.toastr.error("Please Choose Start Date");
    } else if(this.ConsultationTime.get("EndTime").value === ""){
      this.toastr.error("Please Choose End Date");
    } else {
      let FirstDate;
      let SecondDate
      if(this.firstDate === this.ConsultationTime.get("StartTime").value) {
        FirstDate = this.ConsultationTime.get("StartTime").value
       FirstDate = FirstDate.replaceAll("/", "-");
      } else {
        FirstDate = this.datepipe.transform(this.ConsultationTime.get("StartTime").value, 'dd-MM-yyyy');
      }
      if(this.secondDate === this.ConsultationTime.get("EndTime").value) {
        SecondDate = this.ConsultationTime.get("EndTime").value
        SecondDate = SecondDate.replaceAll("/", "-");
      } else {
        SecondDate = this.datepipe.transform(this.ConsultationTime.get("EndTime").value, 'dd-MM-yyyy');
      }
      let data= {
        ValidFrom: FirstDate,
        ValidTo:SecondDate,
        AlertNote:this.ConsultationTime.get("Details").value !== null ? this.ConsultationTime.get("Details").value : '',
        AlertId:this.isEdit ? this.alertId :  0,
        PatientId:this.EMRPatientInfo.PatientId,
        AlertType:'LMP',
        UserId:Number(localStorage.getItem('user_id')),
        BranchId:Number(this.globals.HospitalId),
        IsDeleted:0,
        IsDisplayed:this.ConsultationTime.get("IsActive").value ? this.ConsultationTime.get("IsActive").value : false
      }
      this.EMRService.addAlert(data).subscribe((res:any)=>{
        if(res.status === 200 && res.message == "Success"){
          this.toastr.success("Success : Alert is added Successfully.");
          this.ConsultationTime.reset()
          this.modalRefAlert.hide()
          this.GetAlert()
        } else {
          this.toastr.error("Failed : " + res.message);
        }
      },(err:any)=>{
        this.toastr.error("Failed : " + err?.error?.message);
      })
    }
  }
  datePicker(i:any){
    let date1= moment(i).add(10, 'month').toDate();; // March 28
    this.secondDate = this.datepipe.transform(date1, 'dd-MM-yyyy');
    this.ConsultationTime.get("EndTime").setValue(this.secondDate)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
