import { Component, OnInit, Input,ViewChild,Output,EventEmitter} from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ManageLocComponent } from '../modals/manage-loc/manage-loc.component';
import { PendingItemsComponent } from '../modals/pending-items/pending-items.component';
import { NewBillingQuickModalComponent } from '../new-billing-quick-modal/new-billing-quick-modal.component';
import { OpenCreditModalComponent } from '../open-credit-modal/open-credit-modal.component';
@Component({
  selector: 'app-billing-quick-menu',
  templateUrl: './billing-quick-menu.component.html',
  styleUrls: ['./billing-quick-menu.component.scss']
})
export class BillingQuickMenuComponent implements OnInit {
  @ViewChild(NewBillingQuickModalComponent)modaltemp: NewBillingQuickModalComponent;
  @ViewChild(NewBillingQuickModalComponent)modaltempPackage: NewBillingQuickModalComponent;
  @ViewChild(NewBillingQuickModalComponent)modaltempProfile: NewBillingQuickModalComponent;
  @ViewChild(NewBillingQuickModalComponent)modaltempManageLOC: NewBillingQuickModalComponent;
  @ViewChild(NewBillingQuickModalComponent)modaltempPendingItems: NewBillingQuickModalComponent;
  @ViewChild(OpenCreditModalComponent)modaltempCredit: OpenCreditModalComponent;
  @Output() PackageDataDetails = new EventEmitter();
  @Output() profileDataDetails = new EventEmitter();
  @Output() specialDiscountDataDetails = new EventEmitter();
  @Output() manageLOCEmitter = new EventEmitter();
  @Output() pendingItemsEmitter = new EventEmitter();


  @Input() specialDiscount: any;
  @Input() creditId: any;
  @Input() billingItems: any[];
  @Input() isPackageApplied: boolean = false;

  @Input() patientData: any;

  constructor(private modalService: BsModalService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
  }
  openMenu()
  {
    var element = document.getElementById('themesetting');

    if(element.classList.contains('open'))
    {
      element.classList.remove('open');
    }
    else{
      element.classList.add('open');
    }


  }

    //modal open for searching patient details
    SpecialDiscountModal() {
      this.modaltemp.open();
      this.openMenu();

    }
    PackageModal(){

      this.modaltempPackage.PackageModalopen();
      this.openMenu();
    }
    ProfileModal(){
      if(this.isPackageApplied){
        this.toastrService.warning('Unable to add profiles...');
      }else{
        this.modaltempProfile.ProfileModalopen();
        this.openMenu();
      }
      
    }
    ManageLOCModal(){
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-lg'
      }
      let initialState = {
        billItems: this.billingItems
      }
      const modalRef = this.modalService.show(ManageLocComponent, Object.assign(
        {}, 
        config, 
        { class: 'modal-lg', initialState }
      ));
      modalRef.content.manageLocEmitter.subscribe((res)=>{
        this.manageLOCEmitter.emit(res);
      })
      this.openMenu();
    }

    PendingItemsModal(){
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-lg'
      }
      let initialState = {
        patientData: this.patientData
      }
      const modalRef = this.modalService.show(PendingItemsComponent, Object.assign(
        {}, 
        config, 
        { class: 'modal-lg', initialState }
      ));

      modalRef.content.pendingItemsEmitter.subscribe((res)=>{
        this.pendingItemsEmitter.emit(res);
      });

      this.openMenu();
    }

    OpenCreditModalopen(){
      this.modaltempCredit.OpenCreditsModalopen();
    }
    PackageData(event){
    this.PackageDataDetails.emit(event)
      }
    profileData(event){
    this.profileDataDetails.emit(event)
    }
    specialDiscountData(event){
    this.specialDiscountDataDetails.emit(event)
    }

}
