import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SharedService } from '../../../../shared/shared.service';

import { BillingService } from '../../services/billing.service';



@Component({
  selector: 'app-closeshift',
  templateUrl: './closeshift.component.html',
  styleUrls: ['./closeshift.component.scss']
})
export class CloseshiftComponent implements OnInit, OnDestroy {
  @Input() shiftdata: any

  @Output() createEmitter = new EventEmitter();

  public submitted: boolean = false;
  public CloseShiftForm: FormGroup;

  private globals: globalvars = new globalvars();
  public isLoading: boolean = false;
  private subscription: Subscription;

  private localTodayDate: Date = new Date();

  constructor(
    private billingService: BillingService,
    private toastrService: ToastrService,
    private sharedService: SharedService,
    private fb: FormBuilder,
    public datepipe:DatePipe,
    public modalRef: BsModalRef) {
     
    }

  ngOnInit(): void {
    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
    this.formInit();
  }

  formInit(){
    let localDate = this.datepipe.transform(this.localTodayDate, 'yyyy-MM-dd')
    let localTime = this.datepipe.transform(this.localTodayDate, 'hh:mm');
    const currentDateTime = localDate+'T'+localTime;
    this.CloseShiftForm=this.fb.group({
      UserId:new FormControl(Number(localStorage.getItem("user_id"))),
      ShiftTo:new FormControl(currentDateTime),
      locationname:new FormControl(this.shiftdata.location),
      ClosedDate:new FormControl({value: this.datepipe.transform(new Date(), "dd-MM-yyyy"), disabled: true}),
      ShiftId	:new FormControl(this.shiftdata.shiftId),
      ShiftNo:new FormControl(this.shiftdata.shiftNo),
      ClosingBalance:new FormControl(0),
      BranchId:new FormControl(this.globals.HospitalId),
    })
  }

  save(){
    var payload=this.CloseShiftForm.getRawValue();
    payload.ShiftNo=Number(payload.ShiftNo);
    payload.ShiftTo = this.datepipe.transform(payload.ShiftTo, 'dd-MM-yyyy HH:mm');
    payload.ClosingBalance=Number(payload.ClosingBalance);
    this.billingService.CloseShift(payload).subscribe((res) => {
      this.submitted = false;
      let result = res;
      if (result.status == 200 && result.message == 'Saved Successfully') {
          this.isLoading = false;
          this.toastrService.success("Saved SuccessFully'");
          this.createEmitter.emit({Status:true})
          this.close();
      }
      else {
        this.isLoading = false;
        this.toastrService.error("Failed : " + result.message);
      }
    })
  }

  close(){
    this.modalRef.hide()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
