import { Component, OnInit,TemplateRef ,ViewChild } from "@angular/core";
import { CountryList } from "../../client/interfaces/CountryList";
import {FormBuilder,FormControl,FormGroup,Validators,} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ConfigurationService } from "../../client/services/configuration.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { ColDef } from "ag-grid-community";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonValidationComponent } from '../../../shared/common-validation/common-validation.component';

@Component({
  selector: "app-Country",
  templateUrl: "./country.component.html",
  styleUrls: ["./country.component.scss"],
})
export class countryComponent implements OnInit {

  supervisor = "";
  locationname = "";
  Mobilenumber = "";
  public submitted: boolean = false;
  public submitted1: boolean = false;
  public submitted2: boolean = false;
   public submitted3: boolean = false;
  countryList: CountryList[] = [];

  form: any;
  result: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  Country: boolean = true;
  CountryId: any;
  State: boolean;
  City: boolean;
  Nationality: any;
  CountrycolumnDefs = [];
  StatecolumnDefs = [];
  CitycolumnDefs = [];
  NationalitycolumnDefs= [];
  public gridApiCountry;
  public gridApiState;
  public gridApiCity;
  public gridApiNGroup;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  public validator = new CommonValidationComponent();

  public rowSelection = true;
  public signdefaultColDef: ColDef;
  public vitalsigndefaultColDef: ColDef;
  public symptomdefaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public CountryForm: FormGroup;
  public StateForm: FormGroup;
  public CityForm: FormGroup;
  public NationalityForm: FormGroup;
  DisplayedId: any;
  IsDisplayed: any;
  stateList: any;
  StateId: any;
  CityList: any;
  CityId: any;
  NationalityGroups: any;
  modalRef: BsModalRef;
  CountryPopupHeading:string="Add country";
  CountryStatePopupHeading:string="Add country";
  CountryCityPopupHeading:string="Add country";
  CountryNationalotyPopupHeading:string="Add country";
  @ViewChild("template") modaltemp: any;
  @ViewChild("template2") modaltemp2: any;
  @ViewChild("template3") modaltemp3: any;
  @ViewChild("template4") modaltemp4: any;
  isLoading=false;
  NationalityId: any;
  functionPermissionData: any;

  constructor(
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.CountryForm = this.formBuilder.group({
      CountryName: new FormControl("",[Validators.required,this.validator.noWhitespaceValidator]),
      CountryCode: new FormControl("",[Validators.required,this.validator.noWhitespaceValidator]),
      Natioanlity: new FormControl("",[Validators.required,this.validator.noWhitespaceValidator]),
      NationalityGroups:new FormControl("",[Validators.required]),
      ReportCode:new FormControl("",[Validators.required,this.validator.noWhitespaceValidator]),
      IsDisplayedGroup:new FormControl(true)

    });

    this.StateForm = this.formBuilder.group({
      countryId: new FormControl("",[Validators.required]),
      StateName: new FormControl("",[Validators.required,this.validator.noWhitespaceValidator]),
      StateCode:new FormControl("",[Validators.required,this.validator.noWhitespaceValidator]),
      IsDisplayedGroup:new FormControl(true)
    });

    this.CityForm = this.formBuilder.group({
      CityName: new FormControl("",[Validators.required,this.validator.noWhitespaceValidator]),
      StateId: new FormControl("",[Validators.required]),
      countryId: new FormControl("",[Validators.required]),
      IsDisplayedGroup:new FormControl(true)
    });

    this.NationalityForm = this.formBuilder.group({
      NationalityGroup: new FormControl("",[Validators.required,this.validator.noWhitespaceValidator]),
      RegionCode: new FormControl("",[Validators.required,this.validator.noWhitespaceValidator]),
      IsDisplayedGroup:new FormControl(true)
    });

    this.GetCountry();
    this.GetState(0);
    this.GetCity();
    this.GetAllNationalityGroups();
    this.functionPermission();

    this.CountrycolumnDefs = [
      {
        headerName: "Country Name",
        field: "countryName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Country Code",
        field: "countryCode",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Report Code",
        field: "reportCode",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Nationality",
        field: "nationalityName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Nationality Group",
        field: "nGroupDesc",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxwidth:100,
        pinned:"right",
        sortable:false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Country" },
      },
    ];

    this.StatecolumnDefs = [
      {
        headerName: "State Name",
        field: "stateName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Country",
        field: "country",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "State Code",
        field: "stateCode",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxwidth:100,
        pinned:"right",
        sortable:false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "StateList" },
      },
    ];

    this.CitycolumnDefs = [
      {
        headerName: "Country Name",
        field: "countryName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "City Name",
        field: "cityName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      // {
      //   headerName: "Nationality Group",
      //   field: "nGroupDesc",
      //   minWidth:180,
      //   enableRowGroup: true,
      //   resizable: true,
      //   sortable: true,
      //   flex: 2,
      //   filter: true,
      //   editable: false,
      // },
      // {
      //   headerName: "Region Code",
      //   field: "regionCode",
      //   minWidth:180,
      //   enableRowGroup: true,
      //   resizable: false,
      //   sortable: true,
      //   flex: 2,
      //   filter: true,
      //   editable: false,
      // },
         {
        headerName: "State Name",
        field: "stateName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxwidth:100,
        pinned:"right",
        sortable:false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "CityList" },
      },
    ];
    this.NationalitycolumnDefs = [
      {
        headerName:  "Nationality Name",
        field: "nGroupDesc",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
          headerName: "Region Code",
          field: "regionCode",
          minWidth:180,
          enableRowGroup: true,
          resizable: false,
          sortable: true,
          flex: 2,
          filter: true,
          editable: false,
        },
      {
        headerName: "Action",
        filter: false,
        maxwidth:100,
        pinned:"right",
        sortable:false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Nationality" },
      },
    ];
    this.context = { componentParent: this };
    this.signdefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }

  onTab(number) {
    this.Country = false;
    this.State = false;
    this.City = false;
    this.Nationality = false;
    if (number == "1") {
      this.Country = true;
    } else if (number == "2") {
      this.State = true;
    } else if (number == "3") {
      this.City = true;
    }
      else if (number == "4") {
        this.Nationality = true;
    }

  }

  onGridReadyCountry(params) {
    this.gridApiCountry = params.api;
  }
  onGridReadyState(params) {
    this.gridApiState = params.api;
  }
  onGridReadyCity(params) {
    this.gridApiCity = params.api;
  }
  onGridReadyNGroup(params) {
    this.gridApiNGroup = params.api;
  }
  onFilterTextBoxChangedCountry() {
    this.gridApiCountry.setQuickFilter(
      (document.getElementById('filter-text-box-country') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedState() {
    this.gridApiState.setQuickFilter(
      (document.getElementById('filter-text-box-state') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedCity() {
    this.gridApiCity.setQuickFilter(
      (document.getElementById('filter-text-box-city') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedNGroup() {
    this.gridApiNGroup.setQuickFilter(
      (document.getElementById('filter-text-box-ngroup') as HTMLInputElement).value
    );
  }

  EditCountry(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 30)
    if (foundElement.id == 30 && foundElement.isAllowed == true) {
    this.CountryPopupHeading="Edit Country";
    this.modalRef = this.modalService.show(
      this.modaltemp,
      // Object.assign({}, { class: "gray modal-lg" })
    );

    if (data.countryId) {
      this.CountryId = data.countryId;
      this.DisplayedId = data.isDisplayed;
      this.CountryForm.patchValue({
        CountryName: data.countryName,
        CountryCode: data.countryCode,
        Natioanlity: data.nationalityName,
        ReportCode:data.reportCode,
        NationalityGroups:data.nGroupId
      });
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  DeleteCountry(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 31)
    if (foundElement.id == 31 && foundElement.isAllowed == true) {
    this.CountryId = data.countryId;
    this.configurationService.DeleteCountry(this.CountryId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Country  deleted successfully");
        this.CountryId=0
        this.GetCountry();
      } else {
        this.toastr.error("Failure: " + this.result.message);

      }
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }


  openModalCountry(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 29)
    if (foundElement.id == 29 && foundElement.isAllowed == true) {
    this.CountryPopupHeading="Add Country";
    this.modalRef = this.modalService.show(template);
    this.CountryId=0
    this.clearForm();
    } else {
      this.toastr.warning("You are not allowed");
    }
 }

  // for geting all form items

  get f() {
    return this.CountryForm.controls;
  }

  GetCountry() {
    this.configurationService
      .GetCountry(this.globals.HospitalId)
      .subscribe((res) => {
        this.countryList = res.response;
      });
  }

 // to clear form
 clearForm() {
  this.CountryForm.reset({
    CountryName: '',
    CountryCode: '',
    Natioanlity:'',
    NationalityGroups:'',
    ReportCode:'',
    IsDisplayedGroup:true
  });
  this.submitted = false;
}
  //check and uncheck of registration date
  selectchecking(evt) {
    if (evt.target.checked == true) {
      this.IsDisplayed = 1;
    } else {
      this.IsDisplayed = 0;
    }
  }
  SaveUpdateCountry() {

    this.submitted = true;

    if (this.CountryForm && this.CountryForm.valid) {
      var AppData = {
        CountryName: this.CountryForm.get("CountryName").value,
        CountryCode: this.CountryForm.get("CountryCode").value,
        Natioanlity: this.CountryForm.get("Natioanlity").value,
        NationalityGroups:this.CountryForm.get("NationalityGroups").value,
        ReportCode:this.CountryForm.get("ReportCode").value,
        IsDisplayedGroup: this.CountryForm.get("IsDisplayedGroup").value,

      }
      if(AppData.IsDisplayedGroup==true){
        AppData.IsDisplayedGroup=1;
      }
      else{
        AppData.IsDisplayedGroup=0;
      }

      let Countrydata = 0;
      if (this.CountryId > 0) {
        Countrydata = this.CountryId;
      }
      this.isLoading=true;

      this.configurationService.InsertUpdateCountry(AppData, Countrydata, this.globals.HospitalId).subscribe((res) => {
          this.submitted = false;
          this.result = res;
          if (this.result.status == 200 && this.result.message == 'Success') {
              if (Countrydata == 0) {
                this.isLoading=false;
                this.toastr.success("Country added successfully");
              }
              else {
                this.isLoading=false;
                this.toastr.success("Country Updated successfully");
                  this.CountryId=0;
              }
              this.modalRef.hide();
              this.GetCountry();
              //this.clearForm();
          }
          else {
            this.isLoading=false;
            this.toastr.error("Failed : " + this.result.message);
          }
      })
  }

  }

  /*--------------------------------------------------State----------------------------------------*/
  openModalState(template2: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 32)
    if (foundElement.id == 32 && foundElement.isAllowed == true) {
    this.CountryStatePopupHeading="Add State";
    this.modalRef = this.modalService.show(template2);
    this.StateId=0
    this.clearForm2();
    } else {
      this.toastr.warning("You are not allowed");
    }
 }
    // for geting all form items

  get f2() {
    return this.StateForm.controls;
  }

  //ng-autocomplete countrylist select event
  selectEvent() {
    let Country=this.CityForm.get("countryId").value;
    this.GetState(Country);
  }

  GetState(Country) {
    let ShowAll=1;
    this.configurationService
      .GetState(this.globals.HospitalId,Country,ShowAll)
      .subscribe((res) => {
        this.stateList = res.response;
      });
  }


  SaveUpdateState() {

    this.submitted1 = true;

    if (this.StateForm && this.StateForm.valid) {
      var AppData = {
        CountryId: this.StateForm.get("countryId").value,
        StateName: this.StateForm.get("StateName").value,
        StateCode: this.StateForm.get("StateCode").value,
        IsDisplayedGroup: this.StateForm.get("IsDisplayedGroup").value,

      }
      if(AppData.IsDisplayedGroup==true){
        AppData.IsDisplayedGroup=1;
      }
      else{
        AppData.IsDisplayedGroup=0;
      }

      let Statedata = 0;
      if (this.StateId > 0) {
        Statedata = this.StateId;
      }
      this.isLoading=true;
      this.configurationService.InsertUpdateState(AppData, Statedata, this.globals.HospitalId).subscribe((res) => {
          this.submitted1 = false;
          this.result = res;
          if (this.result.status == 200 && this.result.message == 'Success') {
              if (Statedata == 0) {
                this.isLoading=false;
                this.toastr.success("State added successfully");
              }
              else {
                this.isLoading=false;
                this.toastr.success("State Updated successfully");
                  this.StateId=0;
              }
              this.modalRef.hide();
              this.GetState(0);
              this.clearForm2();
          }
          else {
            this.isLoading=false;
            this.toastr.error("Failed : " + this.result.message);
          }
      })
  }


  }

  EditState(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 33)
    if (foundElement.id == 33 && foundElement.isAllowed == true) {
    this.CountryStatePopupHeading="Edit State";
    this.modalRef = this.modalService.show(
      this.modaltemp2,
      // Object.assign({}, { class: "gray modal-lg" })
    );

    if (data.stateId) {
      this.StateId = data.stateId;
      this.DisplayedId = data.isDisplayed;
      this.StateForm.patchValue({
        countryId: data.countryId,
        StateName: data.stateName,
        StateCode: data.stateCode,
        IsDisplayedGroup:data.IsDisplayed
      });
      if(data.isDisplayed==1){
        let displayed=true;
         this.StateForm.patchValue({
          IsDisplayedGroup:displayed
         });
       }
    }
    } else {
      this.toastr.warning("You are not allowed");
    }

  }

  DeleteState(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 34)
    if (foundElement.id == 34 && foundElement.isAllowed == true) {
    this.StateId = data.stateId;
    this.configurationService.DeleteState(this.StateId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("State  deleted successfully");
        this.StateId=0;
        this.GetState(0);
        this.clearForm2();
      } else {
        this.toastr.error("Failure: " + this.result.message);

      }
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

// to clear form
clearForm2() {
  this.StateForm.reset({
    CountryId: '',
    StateName: '',
    StateCode:'',
    IsDisplayedGroup:true
  });
  this.submitted1 = false;
}


  /*-------------------------------------------City---------------------------------------*/

  openModalCity(template3: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 35)
    if (foundElement.id == 35 && foundElement.isAllowed == true) {
    this.CountryCityPopupHeading="Add City";
    this.modalRef = this.modalService.show(template3);
    this.CityId=0
    this.clearForm3();
    } else {
      this.toastr.warning("You are not allowed");
    }
 }
  GetCity() {
    this.configurationService
      .GetCity(this.globals.HospitalId)
      .subscribe((res) => {
        this.CityList = res.response;
      });
  }



  SaveUpdateCity() {

    this.submitted2 = true;
    if (this.CityForm && this.CityForm.valid) {
      var AppData = {
        CityName: this.CityForm.get("CityName").value,
        CountryId: this.CityForm.get("countryId").value,
        StateId: this.CityForm.get("StateId").value,
        IsDisplayedGroup: this.CityForm.get("IsDisplayedGroup").value,

      }
      if(AppData.IsDisplayedGroup==true){
        AppData.IsDisplayedGroup=1;
      }
      else{
        AppData.IsDisplayedGroup=0;
      }

      let Citydata = 0;
      if (this.CityId > 0) {
        Citydata = this.CityId;
      }

      this.isLoading=true;
      this.configurationService.InsertUpdateCity(AppData, Citydata, this.globals.HospitalId).subscribe((res) => {
        this.submitted2 = false;
          this.result = res;
          if (this.result.status == 200 && this.result.message == 'Success') {
              if (Citydata == 0) {
                this.isLoading=false;
                this.toastr.success("City added successfully");
              }
              else {
                this.isLoading=false;
                this.toastr.success("City Updated successfully");
                  this.CityId=0;
              }
              this.modalRef.hide();
              this.GetCity();
              this.clearForm3();
          }
          else {
            this.isLoading=false;
            this.toastr.error("Failed : " + this.result.message);
          }
      })
  }


  }

  EditCity(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 36)
    if (foundElement.id == 36 && foundElement.isAllowed == true) {
    this.CountryCityPopupHeading="Edit City";
    this.modalRef = this.modalService.show(
      this.modaltemp3,
      // Object.assign({}, { class: "gray modal-lg" })
    );


    if (data.cityId) {
      this.CityId = data.cityId;
      this.DisplayedId = data.isDisplayed;
      this.CityForm.patchValue({
        countryId: data.countryId,
        CityName: data.cityName,
        StateId: data.stateId,
      });

      if(data.isDisplayed==1){
        let displayed=true;
         this.CityForm.patchValue({
          IsDisplayedGroup:displayed
         });
       }
       else{
        let displayed=false;
        this.CityForm.patchValue({
         IsDisplayedGroup:displayed
        });
       }

    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  DeleteCity(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 37)
    if (foundElement.id == 37 && foundElement.isAllowed == true) {
    this.CityId = data.cityId;
    this.configurationService.DeleteCity(this.CityId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("City  deleted successfully");

        this.GetCity();
        this.clearForm3();
        this.CityId =0;

      } else {
        this.toastr.error("Failure: " + this.result.message);

      }
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  get f3() {
    return this.CityForm.controls;
  }


  // to clear form
clearForm3() {
  this.CityForm.reset({
    CityName: '',
    CountryId: '',
    StateId:'',
    IsDisplayedGroup:true
  });
  this.submitted2 = false;
}
/*-----------------------------------showNationalitymodal------------------------*/

openModalNationality(template4: TemplateRef<any>) {
  const foundElement = this.functionPermissionData.find(x => x.id === 38)
  if (foundElement.id == 38 && foundElement.isAllowed == true) {
  this.CountryNationalotyPopupHeading="Add Nationality"
  this.modalRef = this.modalService.show(template4);
  this.NationalityId=0
  this.clearForm4();
  } else {
    this.toastr.warning("You are not allowed");
  }
}
GetAllNationalityGroups(){
  this.configurationService
  .GetNationalityGroups(this.globals.HospitalId)
  .subscribe((res) => {
    this.NationalityGroups = res.response;
  });
}

SaveUpdateNationality(){

  this.submitted3 = true;

  if (this.NationalityForm && this.NationalityForm.valid) {
    var AppData = {
      NationalityGroup: this.NationalityForm.get("NationalityGroup").value,
      RegionCode: this.NationalityForm.get("RegionCode").value,
      IsDisplayedGroup: this.NationalityForm.get("IsDisplayedGroup").value,

    }
    if(AppData.IsDisplayedGroup==true){
      AppData.IsDisplayedGroup=1;
    }
    else{
      AppData.IsDisplayedGroup=0;
    }

    let Nationalitydata = 0;
    if (this.NationalityId > 0) {
      Nationalitydata = this.NationalityId;
    }
    this.isLoading=true;
    this.configurationService.InsertUpdateNationalityGroup(AppData, Nationalitydata, this.globals.HospitalId).subscribe((res) => {
        this.submitted3 = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == 'Success') {
            if (Nationalitydata == 0) {
              this.isLoading=false;
              this.toastr.success("Nationality added successfully");
            }
            else {
              this.isLoading=false;
              this.toastr.success("Nationalitydata Updated successfully");
                this.NationalityId=0;
            }
            this.modalRef.hide();
            this.GetAllNationalityGroups();
            this.clearForm4();
        }
        else {
          this.isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
    })
}

}

EditNationality(data) {
  const foundElement = this.functionPermissionData.find(x => x.id === 39)
  if (foundElement.id == 39 && foundElement.isAllowed == true) {
  this.CountryNationalotyPopupHeading="Edit Nationality";
  this.modalRef = this.modalService.show(
    this.modaltemp4,
    // Object.assign({}, { class: "gray modal-lg" })
  );

  if (data.nGroupId) {
    this.NationalityId = data.nGroupId;
    this.DisplayedId = data.isDisplayed;
    this.NationalityForm.patchValue({
      NationalityGroup: data.nGroupDesc,
      RegionCode: data.regionCode,
    });
    if(data.isDisplayed==1){
      let displayed=true;
       this.NationalityForm.patchValue({
        IsDisplayedGroup:displayed
       });
     }
     else{
      let displayed=false;
       this.NationalityForm.patchValue({
        IsDisplayedGroup:displayed
       });
     }
  }
  } else {
    this.toastr.warning("You are not allowed");
  }
}

DeleteNationality(data) {
  const foundElement = this.functionPermissionData.find(x => x.id === 40)
  if (foundElement.id == 40 && foundElement.isAllowed == true) {
  this.NationalityId = data.nGroupId;
  this.configurationService.DeleteNationalityGroup(this.NationalityId).subscribe((res) => {
    this.result = res;
    if (this.result.status == 200) {
      this.toastr.success("Nationality Groups deleted successfully");

      this.GetAllNationalityGroups();
      this.clearForm4();
      this.NationalityId=0;
    } else {
      this.toastr.error("Failure: " + this.result.message);

    }
  });
  } else {
    this.toastr.warning("You are not allowed");
  }
}

get f4() {
  return this.NationalityForm.controls;
}

  // to clear form
  clearForm4() {
    this.NationalityForm.reset({
      NationalityGroup: '',
      RegionCode: '',
      IsDisplayedGroup:true
    });
    this.submitted3 = false;
  }

  ResetFunction(){
    this.CountryId=0
    this.clearForm();
  }

  ResetFunction2(){
    this.StateId=0
    this.clearForm2();
  }
  ResetFunction3(){
    this.CityId=0
    this.clearForm3();
  }
  ResetFunction4(){
    this.NationalityId=0
    this.clearForm4();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }

}
