import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SharedService } from "../../../../shared/shared.service";
import { globalvars } from "../../../../shared/models/GlobalVars";

@Component({
  selector: 'app-result-form',
  templateUrl: './preview-result-form.component.html',
  styleUrls: ['./preview-result-form.component.scss']
})
export class PreviewResultFormComponent implements OnInit {
  @Input() RformId: 0
  @Input() BranchId: 0
  @Input() rformName: "";
  @Input() microbiologist: Boolean;
  @Input() pathologist: Boolean;
  @Input() radiologist: Boolean;
  @Input() technologist: Boolean;
  @Input() ItemIdList: any[];
  @Input() branchId: any[];
  @Input() patientId: any;
  @Output() getitemEmitter = new EventEmitter()
  @Input() OrderDetIdList: any[];
  @Input() ResultId

  resultFormFata: any;
  previewFormFata: any;
  data1: any;
  data: any;
  colourData: any;
  rangeData: any;
  positiveData: any = [];
  clarityData: any;
  StaffData: any;
  technologistdata: any;
  pathalogisttdata: any;
  microbiologistdata: any;
  radiologistdata: any;
  resultForm: FormGroup;
  fromDates: any;
  public submitted: boolean = false;
  isLoading = false;
  result: any;
  presence: any;
  consistency: any;
  reaction: any;
  subscription: Subscription;
  resultDetailsData: any[];
  dropdownSettings: any = {
    singleSelection: false,
    minWidth: 180,
    idField: "id",
    textField: "displayName",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  checkboxItemData: any[];

  constructor(private modalRef: BsModalRef,
    private configurationService: ConfigurationService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private sharedservice: SharedService,

  ) { 
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {

      }
    });

  }

  ngOnInit(): void {
    this.getCheckListResultTypeData()
    let timeZoneData=this.sharedservice.timeZoneData

    this.fromDates = this.datepipe.transform(new Date(), 'dd-MM-yyyy HH:mm',timeZoneData);

    this.resultForm = new FormGroup({
      ResultId: new FormControl(this.ResultId),
      PatientId: new FormControl(this.patientId),
      ResultDate: new FormControl(this.fromDates),
      RformId: new FormControl(this.RformId),
      Status: new FormControl('R'),
      PublishDate: new FormControl(''),
      PublishUserId: new FormControl(0),
      TechId: new FormControl(0),
      PathId: new FormControl(0),
      RadioId: new FormControl(0),
      MicroId: new FormControl(0),
      Remarks: new FormControl(''),
      ResNo: new FormControl(''),
      UserId: new FormControl(Number(localStorage.getItem("user_id"))),
      ResultDetailList: new FormArray([]),
      Interpretation: new FormControl(''),
      PerformingLocation: new FormControl(''),
      PerformingStaff: new FormControl(0),
      LabNo: new FormControl(''),

    });

    if(this.ResultId != 0) this.getResultFormV1()

    this.getResultForm();
    this.getPreviewResult();
    this.getcolour();
    this.getPositive();
    this.getClarity();
    this.getpresence();
    this.getreaction();
    this.getconsistency();
    this.GetAllStaff();
    this.resultForm.patchValue({
      PerformingStaff: Number(localStorage.getItem('staffId')) !== 0 ? Number(localStorage.getItem('staffId')) : ''
    })
  }
  close() {
    this.modalRef.hide();
  }
  getResultForm() {

    let input = {
      "RformId": this.RformId,
      "BranchId": this.BranchId,

    }

    this.configurationService.getResultForm(input).subscribe((res) => {
      this.resultFormFata = res.response;
    });

  }
  //GET RESULT FORM
  getPreviewResult() {
    
    let input = {
      "RformId": this.RformId,
      "ItemIdList": this.ItemIdList ? this.ItemIdList : [],
      "resultId": this.ResultId ? this.ResultId : 0,
      "patientId": this.patientId
    }

    this.configurationService.getPreviewResultForm(input).subscribe((res) => {
      //  this.resultDetailsData = res.response;

      this.resultDetailsData=res.response.reduce((acc, curr) => {
        const existingHeader = acc.find((h) => h.headerName === curr.headerName);
        if (existingHeader) {
          existingHeader.paramList = [...existingHeader.paramList, ...curr.paramList];
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);
      this.resultDetailsData=this.resultDetailsData.map((item) => {
        return{
          ...item,
          paramList:item.paramList.map((data)=>{
            let checkList=data.checkList ? data.checkList : ""
            let checkboxItemData = this.checkboxItemData.filter(item => data.checkList.includes(item.id)); 
            return{
              ...data,
              checkList:checkboxItemData,
              parameterId: (data['paramId']),
              ColourResultId: (data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
              RangeResult: (data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
              PositiveNegativeResultId: (data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
              ClarityResultId: (data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
              consistencyResultId: (data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
              presenceResultId: (data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
              reactionResultId:(data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
              gender: (data?.normalValue[0]?.gender ? data.normalValue[0]?.gender : ''),
              resultType: (data['resultType']),
              IsMachineResult: (data['IsMachineResult']),

              LessthanResult:(data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
              ChecklistResultId:data.resultValue != 'ResultValue' ? this.getSelectedChecklist(checkboxItemData,data.resultValue) : ''
              
            }
          })
        }
      })
      console.log(this.resultDetailsData)



      // this.data.sort((a, b) => a.paramOrder - b.paramOrder);

      // res.response.forEach(element => {
      //   this.addMapFormGroup(element);
      // });

      //
    });

  }

  getSelectedChecklist(checkboxItemData,checklist){
      let selectedItems=checkboxItemData.filter(item => checklist.includes(item.id));
      return selectedItems
  }

  getResultFormV1(){
    let payload={
      "RformId": this.RformId,
      "ResultId": this.ResultId
    }
    // console.log('payload', payload);
    this.configurationService.GetResultFormV1(payload).subscribe({
      next:(res=>{
        if(res.response){
          let data=res.response[0]
          this.resultForm.patchValue({
            TechId: data?.technologist,
            LabNo: data?.labNo,
            PerformingStaff: data?.performingStaff,
            PerformingLocation: data?.performingLocation,
            Interpretation: data?.interpretation,
            Remarks: data?.remarks ? data?.remarks : '',
            MicroId:data?.microbiologist,
            PathId:data?.pathologist,
            RadioId:data?.radiologist
          })

        }
      })
    })
  }

  // CREATE RESULT FORM ARRAY


  // public addMapFormGroup(data?: any[]) {
  //   const ServiceItems = this.resultForm.get("ResultDetailList") as FormArray;
  //   const values = Object.keys(data).map(key => data[key]);
  //   ServiceItems.push(this.createListGroup(data));
  // }


  // private createListGroup(data?): FormGroup {
  //     return new FormGroup({
  //     parameterId: new FormControl(data['paramId']),
  //     ColourResultId: new FormControl(data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
  //     RangeResult: new FormControl(data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
  //     PositiveNegativeResultId: new FormControl(data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
  //     ClarityResultId: new FormControl(data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
  //     consistencyResultId: new FormControl(data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
  //     presenceResultId: new FormControl(data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
  //     reactionResultId: new FormControl(data.resultValue != 'ResultValue' ? data.resultValue : data?.defaultParamValue ? data.defaultParamValue : ''),
  //     gender: new FormControl(data?.normalValue[0]?.gender ? data.normalValue[0]?.gender : ''),
  //     resultType: new FormControl(data['resultType']),
  //     IsMachineResult: new FormControl(data['IsMachineResult'])

  //   });
  // }
  // END FORM ARRAY

  getcolour() {
    let payload = {
      id: 1
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.colourData = res.response;
    });

  }
  getPositive() {
    let payload = {
      id: 3
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.positiveData = res.response;


    });

  }
  getClarity() {
    let payload = {
      id: 4
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.clarityData = res.response;
    });

  }
  getconsistency() {
    let payload = {
      id: 5
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.consistency = res.response;
    });

  }
  getpresence() {
    let payload = {
      id: 6
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.presence = res.response;

    });

  }
  getreaction() {
    let payload = {
      id: 7
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.reaction = res.response;
    });

  }

  async GetAllStaff() {
    let staffList = await this.configurationService.GetStaff(this.BranchId).toPromise();
    // this.configurationService.GetStaff(this.BranchId).subscribe((res) => {
      this.StaffData = staffList.response;
      this.technologistdata = this.StaffData.filter(x => x.categoryId === 1);
      this.pathalogisttdata = this.StaffData.filter(x => x.categoryId === 2);
      this.microbiologistdata = this.StaffData.filter(x => x.categoryId === 5);
      this.radiologistdata = this.StaffData.filter(x => x.categoryId === 3);

    // });
  }

  save() {
    this.submitted = true;
    if (this.resultForm && this.resultForm.valid) {
      var formdata = this.resultForm.getRawValue();
      // console.log('formdata', formdata);
      if (formdata.PatientId > 0) {
        formdata.ResultId=Number(formdata.ResultId ? formdata.ResultId : 0);
        formdata.TechId = Number(formdata.TechId ? formdata.TechId  : 0);
        formdata.PathId = Number(formdata.PathId ? formdata.PathId : 0);
        formdata.RadioId = Number(formdata.RadioId ? formdata.RadioId :0);
        formdata.MicroId = Number(formdata.MicroId ? formdata.MicroId : 0);

        // formdata.ResultDetailList = formdata.ResultDetailList.map((item) => {
        //   return {
        //     ...item,
        //     ColourResultId: (item.resultType == 1) && (item.ColourResultId != '') ? Number(item.ColourResultId) : 0,
        //     ClarityResultId:(item.resultType == 4) && item.ClarityResultId != '' ? Number(item.ClarityResultId) : 0,
        //     PositiveNegativeResultId:(item.resultType == 3) && item.PositiveNegativeResultId != '' ? Number(item.PositiveNegativeResultId) : 0,
        //     consistencyResultId:(item.resultType == 5) && item.consistencyResultId != '' ? Number(item.consistencyResultId) : 0,
        //     presenceResultId:(item.resultType == 6) && item.presenceResultId != '' ? Number(item.presenceResultId) : 0,
        //     reactionResultId:(item.resultType == 7) && item.reactionResultId != '' ? Number(item.reactionResultId) : 0,
        //     IsMachineResult: (item.resultType == 4) &&item.IsMachineResult != null ? item.IsMachineResult : false
        //   }
        // })
        
        formdata.ResultDetailList=this.resultDetailsData.reduce((acc, item) => {
          acc.push(...item.paramList.map(param => ({
            parameterId:param.parameterId,
            gender:param.gender,
            resultType:param.resultType,
            RangeResult:param.RangeResult,

            LessthanResult:(param.resultType == 9) && (param.LessthanResult != '') ? (param.LessthanResult) : '',
            ChecklistResultId:(param.resultType == 8) && (param.ChecklistResultId != '') ? (param.ChecklistResultId.map(item=> item.id).join(',')) : '',

            ColourResultId: (param.resultType == 1) && (param.ColourResultId != '') ? Number(param.ColourResultId) : 0,
            ClarityResultId:(param.resultType == 4) && param.ClarityResultId != '' ? Number(param.ClarityResultId) : 0,
            PositiveNegativeResultId:(param.resultType == 3) && param.PositiveNegativeResultId != '' ? Number(param.PositiveNegativeResultId) : 0,
            consistencyResultId:(param.resultType == 5) && param.consistencyResultId != '' ? Number(param.consistencyResultId) : 0,
            presenceResultId:(param.resultType == 6) && param.presenceResultId != '' ? Number(param.presenceResultId) : 0,
            reactionResultId:(param.resultType == 7) && param.reactionResultId != '' ? Number(param.reactionResultId) : 0,
            IsMachineResult: (param.resultType == 4) &&param.IsMachineResult != null ? param.IsMachineResult : false
          })));
          return acc;
        }, []);

        let resultFlag = 0;
        formdata.ResultDetailList.forEach(item => {
          if(item.resultType == 1 && item.ColourResultId == 0){resultFlag = 1;}
          else if(item.resultType == 2 && (item.RangeResult == '-' || item.RangeResult == '')){resultFlag = 1;}
          else if(item.resultType == 3 && item.PositiveNegativeResultId == 0){resultFlag = 1;}
          else if(item.resultType == 4 && item.ClarityResultId == 0){resultFlag = 1;}
          else if(item.resultType == 5 && item.consistencyResultId == 0){resultFlag = 1;}
          else if(item.resultType == 6 && item.presenceResultId == 0){resultFlag = 1;}
          else if(item.resultType == 7 && item.reactionResultId == 0){resultFlag = 1;}
          else if(item.resultType == 8 && item.ChecklistResultId == ''){resultFlag = 1;}
          else if(item.resultType == 9 && (item.LessthanResult == '' || item.LessthanResult == '<')){resultFlag = 1;}
        })

        if(resultFlag == 1){
          this.toastr.warning("please enter result");
          return
        }
        formdata.PerformingStaff = Number(formdata.PerformingStaff);

        formdata.orderDetIdList = this.OrderDetIdList;
        if (this.microbiologist == true && formdata.MicroId == 0) {
          this.toastr.warning("please select microbiologist");
        }
        else if (this.pathologist == true && formdata.PathId == 0) {
          this.toastr.warning("please select pathologist");
        }
        else if (this.technologist == true && formdata.TechId == 0) {
          this.toastr.warning("please select technologist");
        }
        else if (this.radiologist == true && formdata.RadioId == 0) {
          this.toastr.warning("please select radiologist");
        }
        else {
          console.log(formdata)
          // return
          this.isLoading = true;
          this.configurationService.SaveResultEntry(formdata).subscribe((res) => {
            this.submitted = false;
            this.result = res;
            if (this.result.status == 200 && this.result.message == 'Success') {
              this.isLoading = false;
              this.toastr.success("Result  added Successfully");
              this.getitemEmitter.emit({ status: true });

              this.close();
              this.clearForm();
            }
            else {
              this.isLoading = false;
              this.toastr.error("Failed : " + this.result.message);
            }

          })
        }
      }
    }
  }
  clearForm() {
    this.resultForm.reset({
      ResultId: 0,
      PatientId: 0,
      ResultDate: '',
      RformId: 0,
      Status: '',
      PublishDate: '',
      PublishUserId: 0,
      TechId: 0,
      PathId: 0,
      RadioId: 0,
      MicroId: 0,
      Remarks: '',
      ResNo: '',
      UserId: 0,
      ResultDetailList: [],
      Interpretation: '',
      PerformingLocation: '',
      PerformingStaff: 0,
      LabNo: '',
    })

  }
  getCheckListResultTypeData(){
    let payload={
      id:0
    }
    this.configurationService.GetCheckListResultTypeData(payload).subscribe({
      next:(res)=>{
        if(res.response){
          this.checkboxItemData=res.response
        }
      }
    })
  }
}


