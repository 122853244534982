import { Component, OnInit, ViewChild } from '@angular/core';
import { HospitalsService } from "../../services/hospitals.service";
import { AppointmentsService } from "../../services/appointments.service";
import { DatePipe } from "@angular/common";
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Departments } from "../../interfaces/Departments";
import { Consultants } from "../../interfaces/Consultants";
import { AppointmentsList } from "../../interfaces/AppointmentsList";
import { FormBuilder, FormGroup, FormControl, FormArray } from "@angular/forms";
import { ConsultaionsService } from "../../services/consultations.service";
import { ConsultationsList } from "../../interfaces/ConsultationsList";
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralActionComponent } from "../../general-action/general-action.component";
import { PatientService } from "../../services/patient.service";
import { progressBarList } from "../../interfaces/ProgressBarList";
import { ToastrService } from "ngx-toastr";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Router } from "@angular/router";
import { globalvars } from "../../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { SharedService } from "../../../../shared/shared.service";
import { countUpTimerConfigModel, timerTexts } from 'ngx-timer';
import { ConsultantDeskService } from '../../services/consultant.service';
import { EMRService } from '../../../emr/services/emr.service';
import { TreatmentsService } from '../../../treatment/services/treatments.service';

import {
  GuiCellView,
  GuiColumn,
  GuiPaging,
  GuiPagingDisplay,
  GuiSearching,
  GuiSorting,
  GuiColumnMenu,
  GuiInfoPanel,
  GuiDataType,
} from "@generic-ui/ngx-grid";
import { PrintAsoapComponent } from '../../frontoffice/modals/print-asoap/print-asoap.component';
import { Response } from '../../../../_Models/Response';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';
import { HealthSummaryModalComponent } from '../../../treatment/modals/health-summary-modal/health-summary-modal.component';
import { ConfigurationService } from '../../services/configuration.service';
import { ChangeStatusModalComponent } from '../change-status-modal/change-status-modal.component';
import { ChangeFeeComponent } from '../modals/change-fee/change-fee.component';
import { TelerikPrintComponent } from '../../../../shared/modals/telerik-print/telerik-print.component';
import { NabithConsentComponent } from '../../frontoffice/addregistration/nabith-consent/nabith-consent.component';
import { StatusInfoModalComponent } from '../../frontoffice/modals/status-info-modal/status-info-modal.component';
import { SettingsService } from '../../../settings/services/settings.service';
import { RiayatiConsentComponent } from '../../frontoffice/addregistration/riayati-consent/riayati-consent.component';

@Component({
  selector: 'app-todayspatientComponent',
  templateUrl: './todayspatient.component.html',
  styleUrls: ['./todayspatient.component.scss'],

})
export class todayspatientComponent implements OnInit {
  @ViewChild('basicTimer') timer;
  @ViewChild("instructionTemplate") modalInstruction: any;
  @ViewChild("template") modaledit: any;
  @ViewChild('templatespecialDiscount') modaltemp:any;

  userImg:any
  modalRef1: BsModalRef;
  modalRef2: BsModalRef;

  products: any = {};
  items: any = {};
  update: any = {};
  delete: any = {};
  Instructionform: FormGroup;
  BillinginstructionPopupHeading: string = "Add Billing Instruction";
  form: FormGroup;
  displayBasic: boolean;
  UrgentVisit: boolean;
  position: string;
  users: any = [];
  displayBasicAppoint: boolean;
  displayTimer: boolean;
  displayPosition: boolean;
  departments: any;
  consultants: Consultants[] = [];
  appointments: AppointmentsList[] = [];
  userType: any;
  username: any;
  departmentName: any;
  Id: any;
  totalConsultations: any;
  totalAppointments: any;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingsPostPoned: IDropdownSettings = {};
  gender: any;
  patientName: any;
  CancelButton: boolean;
  PostponedAppointments: boolean;
  blockId: any;
  departmentId: string;
  links: any[] = ["link1.com", "link2.com", "link3.com"];
  minDate = new Date();
  mailText: string = "";
  searchForm: FormGroup;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  timeLeft: number = 60;
  interval;
  consultsId:any;
  specialDiscountForm:FormGroup;
  treatmentList:any;
  treatmentDetail: boolean;

  paging: GuiPaging = {
    enabled: true,
    page: 1,
    pageSize: 10,
    pageSizes: [10, 25, 50],
    pagerTop: false,
    pagerBottom: true,
    display: GuiPagingDisplay.ADVANCED,
  };

  sorting: GuiSorting = {
    enabled: true,
  };

  searching: GuiSearching = {
    enabled: true,
    placeholder: "Search Here",
  };
  GuiColumnMenu = {
    enabled: true,
    columnsManager: true,
  };

  infoPanel: GuiInfoPanel = {
    enabled: true,
    infoDialog: false,
    columnsManager: true,
  };

  rowGroupPanelShow = "always";

  rowData: ConsultationsList[] = [];
  //ag grid setup
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public noRowsTemplate;
  public loadingTemplate;

  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.status == "C";
      },
      "c-urgent": function (params) {
        return (params.data.status.toLocaleLowerCase() == "w" && params.data.emergency == 1);
      },
      "c-greens": function (params) {
        return params.data.status == "F";
      },
    },

    domLayout: "autoHeight",

  };

  public consultationGridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.status == "C";
      },
      "c-completed": function (params) {
        return params.data.status.toLocaleLowerCase() == "f";
      },
    },

    domLayout: "autoHeight",

  };

  public statusBar;
  // appoinements ag Grid
  columnDefs: ColDef[] = [
    {
      headerName: "Token No.",
      field: "tokenNO",
      valueGetter: params=> {
        return params.data.tokenNO + "/" + params.data.consTakenTime;
      },
      minWidth: 93,
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      flex: 1,
    },
    {
      headerName: "INV",
      minWidth:50,
      field: "",
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
      onCellClicked: this.openInfo.bind(this),
      cellRenderer:(params)=>{
        switch (params.data.overallLabStatus) {
          case 'New Investigation':
            return ('<span class="newi">N</span>')
            break;

          case 'Sample Collected':
            return ('<span class="inv">SC</span>')          
            break;

          case "Test Initiated":
            return ('<span class="invi">TI</span>')           
            break;

          case "Result Entered":
            return ('<span class="RE">RE</span>')
            break;

          case "Published":
            return ('<span class="RP">P</span>')
            break;

          case "Cancelled":
            return ('<span >C</span>')
            break;

          
        }
          
      }
    },
    {
      headerName: "V Sign",
      minWidth:50,
      field: "vitalSignStatus",
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
      cellRenderer:(params)=>{
        if(params.data.vitalSignStatus == 1){
          return ('<i class="fa fa-heartbeat text-info" aria-hidden="true "></i>')
        }
      }
    },
    {
      headerName: "C stat",
      minWidth:50,
      field: "patientConsentStatus",
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
      cellRenderer:(params)=>{
        if(params.data.patientConsentStatus == 1){
          return ('<i class="fa fa-file-text text-info" aria-hidden="true "></i>')
        }
      }
    },
    {
      headerName: "Patient Name",
      field: "patientName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      minWidth: 115,
      flex: 2,
      onCellClicked: this.showuserDialog.bind(this),
      cellRenderer: (params) => {
        if(params.data.profilePicLocation){
          return (
            " <img  src='"+ params.data.profilePicLocation +"' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle '+ params.data.emergency == '1' ? 'emergency' : '' +'' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        }else{
          if (params.data.gender == 1) {
            if (params.data.emergency == 1) {
              return (
                " <img  src='assets/images/xs/maleurgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
            }
            return (
              " <img  src='assets/images/xs/malegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          } else if (params.data.gender == 2) {
            if (params.data.emergency == 1) {
              return (
                " <img  src='assets/images/xs/femaleurgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
            }
  
            return (
              " <img  src='assets/images/xs/femalegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          } else if (params.data.gender == 0) {
            if (params.data.emergency == 1) {
              return (
                " <img  src='assets/images/xs/defaulturgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
            }
            return (
              " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          } else if (params.data.gender == 3) {
            if (params.data.emergency == 1) {
              return (
                " <img  src='assets/images/xs/defaulturgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
            }
            return (
              " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          }
        }
      },
    },
    {
      headerName: "Reg. No.",
      field: "regNo",
      sortable: true,
      filter: true,
      minWidth: 95,
      enableRowGroup: true,
      resizable: true,
      flex: 1,
      editable: true
    },
    {
      headerName: "Sponsor",
      field: "sponsor",
      sortable: true,
      filter: true,
      minWidth: 85,
      enableRowGroup: true,
      resizable: true,
      flex: 1,
      editable: true
    },
    {
      headerName: "Policy No.",
      field: "policyNo",
      sortable: true,
      filter: true,
      minWidth: 95,
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
      editable: true
    },
    {
      headerName: "Policy Period",
      field: "expiryDate",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      minWidth: 115,
      resizable: true,
      flex: 1.5,
      editable: true
    },
    {
      headerName: "DHA",
      field: "dhaRule",
      sortable: true,
      filter: true,
      minWidth: 95,
      enableRowGroup: true,
      resizable: true,
      flex: 1,
      editable: true
    },
    {
      headerName: "Cons Fee",
      field: "consultationFee",
      sortable: true,
      filter: true,
      minWidth: 95,
      enableRowGroup: true,
      resizable: true,
      flex: 1,
      editable: true
    },
    {
      headerName: "Con.Dur",
      field: "consultDuration",
      sortable: true,
      filter: true,
      minWidth: 90,
      enableRowGroup: true,
      resizable: true,
      flex: 1,
      editable: true,
      onCellClicked: this.showConsultantTimerSettings.bind(this),
    },
    {
      headerName: "Con Start Time",
      field: "cstarttime",
      sortable: true,
      minWidth: 90,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 1,
      editable: true
    },
    // {
    //   headerName: "Vital Sign",
    //   field: "",
    //   sortable: true,
    //   filter: true,
    //   enableRowGroup: true,
    //   resizable: true,
    //   flex: 1.5,
    //   minWidth: 100,
    //   editable: true
    // },

    // {
    //   headerName: "Fees",
    //   field: "feesAmount",
    //   sortable: true,
    //   filter: true,
    //   enableRowGroup: true,
    //   resizable: true,
    //   minWidth: 70,
    //   flex: 1.5,
    //   editable: true
    // },
    {
      headerName: "Consultant",
      field: "consultantName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      minWidth: 110,
      flex: 1.5,
      editable: true
    },


    {
      headerName: "Action",
      filter: false,
      resizable: false,
      maxWidth: 60,
      pinned: "right",
      // unSortIcon: true,
      field: "Id",
      flex: 1.5,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "FrontofficeConsultantConsultationList" },
    },
  ];

  ConsultationBlockId: number;
  active: string;
  consultationId: number;
  blockorunblock: boolean;
  confirmBlock: boolean;

  // Appointmnet Ag=grid
  rowDataAppt: AppointmentsList[] = [];

  columnDefs_appt: ColDef[] = [
    {
      headerName: "Patient Name",
      field: "patientName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
      minWidth: 120,
      onCellClicked: this.showuserDialogAppointments.bind(this),
      cellRenderer: (params) => {
        if (params.data.gender == 1) {
          return (
            " <img  src='assets/images/xs/malegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        } else if (params.data.gender == 2) {
          return (
            " <img  src='assets/images/xs/femalegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        } else {
          return (
            " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        }
      },
    },
    {
      headerName: "Reg. No.",
      field: "regNo",
      sortable: true,
      filter: true,
      minWidth: 90,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
      editable: true
    },
    {
      headerName: "Time",
      field: "appTime",
      sortable: true,
      filter: true,
      minWidth: 70,
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
      editable: true
    },

    {
      headerName: "Status",
      field: "status",
      sortable: true,
      minWidth: 80,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
      editable: true
    },
    // {
    //   headerName: "Fees",
    //   field: "feesAmount",
    //   sortable: true,
    //   filter: true,
    //   enableRowGroup: true,
    //   minWidth:80,
    //   maxWidth:180,
    //   resizable: true,
    //   flex: 2,
    //   editable: true
    // },

    {
      headerName: "Action",
      filter: false,
      resizable: true,
      unSortIcon: false,
      field: "Id",
      flex: 2,
      maxWidth: 60,
      pinned: "right",
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "FrontAppointmentList" },
    },
  ];
  confirmMarkasUrgent: boolean = false;
  progressBarData: progressBarList[] = [];
  userId: number;
  locationId: number;
  ReasonVisit: boolean;
  reasonDataReason: any;
  result: any;
  consultId: boolean;
  OtherSymptom: any;
  symptomList: any;
  CancelAppointments: boolean;
  confirmblockApp: boolean;
  confirmedblockApp: boolean;
  Confirmed: any;
  Arrived: any;
  Cancelled: any;
  waiting: any;
  ConsultCancelled: any;
  Consultfinished: any;
  ConsultOngoing: any;
  Consultwaiting: any;
  AppointmentDataDetails: AppointmentsList[];
  appDate: string;
  AppId: any;
  emergency: any;
  BranchId: number;
  consultDataId: any[];
  departmentList: any;
  valueDate: string;
  curentdDate: any;
  scheduleTime: any;
  postponeddate: any;
  sliceNo: any;
  EditStatus: boolean;
  EditStatusAppId: any;
  symptomDetails: any;
  BranchesId: any;
  addressapp: any;
  mobapp: any;
  regNoapp: any;
  emailapp: boolean;
  appdate: any;
  regNoconsult: any;
  regNoaddress: any;
  regNomobile: any;
  consultdate: any;
  consultemail: any;
  postponnedconsultId: any;
  testConfig: countUpTimerConfigModel;
  userTimer: boolean = false;
  cdTimer: boolean;
  patientId: any;

  private baseUrl: any='';
  functionPermissionData: any;
  private changeStatusModalRef?: BsModalRef;
  modalRef: BsModalRef;
  healthPlatform: any;

  constructor(
    private TreatmentsService: TreatmentsService,

    private formBuilder: FormBuilder,
    private hospitalsService: HospitalsService,
    private appointmentsService: AppointmentsService,
    private patientService: PatientService,
    private consultationsService: ConsultaionsService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private appointmentService: AppointmentsService,
    private sharedservice: SharedService,
    private ConsultantDeskService: ConsultantDeskService,
    private router: Router,
    private EMRService: EMRService,
    private modalService: BsModalService,
    private configurationManager:ConfigurationManager,
    private ConfigurationService: ConfigurationService,
    private settingsService: SettingsService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };

    this.minDate.setDate(this.minDate.getDate() - 1);
  }


  //Initailasing functions on Page Load
  ngOnInit() {

    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.valueDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.curentdDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");

    this.testConfig = new countUpTimerConfigModel();

    //custom class
    this.testConfig.timerClass = 'test_Timer_class';

    //timer text values
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = "Hours"; //default - hh
    this.testConfig.timerTexts.minuteText = "Minutes"; //default - mm
    this.testConfig.timerTexts.secondsText = "Seconds"; //default - ss


    this.dropdownSettings = {
      singleSelection: false,
      idField: "symptomId",
      textField: "newsymDesc",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
    this.dropdownSettingsPostPoned = {
      singleSelection: false,
      idField: 'sliceNo',
      textField: 'sliceTime',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };

    this.userType = localStorage.getItem("usertype"); //U As admin otherwise consultant
    this.username = localStorage.getItem("username");
    this.departmentName = localStorage.getItem("departmentName");
    this.Id = localStorage.getItem("Id");
    this.departmentId = localStorage.getItem("departmentId");

    // Appointments Action grid
    this.form = this.formBuilder.group({
      departmenId: [0],
      consultantId: [0],
      patientStatus: [""],
      appointmentStatus: new FormControl(""),
      reasonDataReason: new FormControl(""),
      ConsultationId: new FormControl(""),
      Symptoms: new FormControl(""),
      otherreasonforvisit: new FormControl(""),
      blockReason: new FormControl(""),
      PostPonedDepartment: new FormControl(""),
      PostPonedConsultant: new FormControl(""),
      appDate: new FormControl(""),
      slicetime: new FormControl(""),
      DeptId: new FormControl(""),
      consultId: new FormControl(""),
      sliceNo: new FormControl(""),
      consultantDepartment: new FormControl(this.departmentName),
      consultantName: new FormControl(this.username),
      starttime: new FormControl(""),
      endtime: new FormControl(""),
    });

    // Modal form initialization
    this.searchForm = this.formBuilder.group({
      Symptoms: new FormControl(),
      otherreasonforvisit: new FormControl(""),
      blockReason: new FormControl(""),
    });
    this.Instructionform = new FormGroup({
      // CancelReason: new FormControl(""),
      BillingInstruction: new FormControl(""),
    });
    this.specialDiscountForm = new FormGroup({
      SpdiscAmount:new FormControl(0),

    });

    this.getApplicationSettings();
    this.GetDepartments(); //on load api function getting departments
    this.GetAppointments(); //on load api function getting appoinments
    this.GetConsultations();
    this.GetProgressBar();
    this.GetTemplateReasonForVisit();
    this.functionPermission();
  }

  /* ============  fetching data to be filled in progress bar ============ */
  GetProgressBar() {
    let CurrentDate = new Date();
    let date = this.datePipe.transform(CurrentDate, "dd-MM-yyyy");
    this.ConsultantDeskService.GetProgressBar(date, this.Id, this.globals.HospitalId).subscribe((res) => {
      this.progressBarData = res.response;
      this.waiting = this.progressBarData[0]['appStatCountW'];
      this.Arrived = this.progressBarData[0]['appStatCountA'];
      this.Cancelled = this.progressBarData[0]['appStatCountC'];
      this.Confirmed = this.progressBarData[0]['appStatCountCF'];

      this.Consultwaiting = this.progressBarData[0]['conStatCountW'];
      this.ConsultOngoing = this.progressBarData[0]['conStatCountO'];
      this.ConsultCancelled = this.progressBarData[0]['conStatCountC'];
      this.Consultfinished = this.progressBarData[0]['conStatCountF'];

      // this.searchForm.patchValue({blockReason:''})
    });
  }
  /* ============  fetching data to be filled in select department dropdowns ============ */
  GetDepartments() {
    let showAll = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showAll).subscribe((res) => {
      this.departments = res.response;
    });
  }
  /* ============ Onchanging data Appointments by consultations ============ */
  loadConsultant(DepartmentId: number) {
    let departmentId = Number(this.form.get("departmenId").value);

    this.hospitalsService.GetConsultant(departmentId).subscribe((res) => {
      this.consultants = res.response;
      this.form.patchValue({ consultantId: "" });
      this.GetAppointments();
      this.GetConsultations();
    });
  }

  /* ============  fetching data to the appointments grid ============ */
  GetAppointments() {
    let CurrentDate = new Date();
    let date = this.datePipe.transform(CurrentDate, "dd-MM-yyyy");
    let DepartmentId = Number(this.departmentId);
    let consultantId = Number(this.Id);
    let branchId = this.globals.HospitalId

    this.appointmentsService
      .GetConsultantAppointments(date, DepartmentId, consultantId, branchId)
      .subscribe((res) => {
        this.appointments = res.response;
        this.totalAppointments = this.appointments.length;

        this.rowDataAppt = res.response;
        this.totalAppointments = this.rowDataAppt.length;
        this.GetConsultations();
      });
  }
  /* ============  fetching data to the Consultations grid ============ */
  GetConsultations() {
    let status = this.form.get("patientStatus").value;
    let CurrentDate = new Date();
    let date = this.datePipe.transform(CurrentDate, "dd-MM-yyyy");
    let branchId = this.globals.HospitalId
    let consultantId = Number(this.Id);
    let DepartmentId = Number(this.departmentId);
    this.consultationsService
      .GetConsultantConsultations(consultantId, date, branchId, status, DepartmentId)
      .subscribe((res) => {
        this.rowData = res.response;
        this.totalConsultations = this.rowData.length;
      });
    //this.GetAppointments();
  }

  /* ============  userinformation opening modal data  ============ */

  showuserDialog(consultation: any) {

    consultation = consultation.data;
    this.consultsId=consultation.consultationId,
    this.patientId = consultation.patientId
    this.patientName = consultation.patientName;
    this.gender = consultation.gender;
    this.emergency = consultation.emergency;
    this.regNoconsult = consultation.regNo;
    this.regNoaddress = consultation.address;
    this.regNomobile = consultation.mobile;
    this.consultdate = consultation.consultDate;
    this.userImg=consultation.profilePicLocation ? consultation.profilePicLocation : undefined
    //this.consultemail=consultation.data.mobile;
    this.displayBasic = true;
  }

  showuserDialogAppointments(product: any) {
    this.patientId = product.data.patientId
    this.consultsId=product.data.consultationId,
    this.gender = product.data.gender;
    this.patientName = product.data.patientName;
    this.addressapp = product.data.address1;
    this.mobapp = product.data.mobile;
    this.regNoapp = product.data.regNo;
    this.emailapp = product.data.email;
    this.appdate = product.data.appDate
    // this.emergency = consultation.emergency;
    this.userImg=product.data.profilePicLocation ? product.data.profilePicLocation : undefined
    this.displayBasicAppoint = true;
  }

  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  }

  // showuserDialog3() {
  //   this.displayBasic = true;
  // }

  showConsultantTimerSettings(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 442)
    if (foundElement.id == 442 && foundElement.isAllowed == true) {
    this.patientId = data.patientId
    this.displayTimer = true;
    this.timer.reset();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  save(param) {
    return false;
  }
  drop() {
    return false;
  }
  mailMe() {
    this.mailText =
      "mailto:abc@abc.com?subject=files&body=" + this.links.join(" ,");
    window.location.href = this.mailText;
  }

  // Activetab(pagetitle = "Front Office") {
  //   sessionStorage.setItem("pageTitle", pagetitle);
  // }

  //Mark as urgent Confirmation Popup
  ConfirmMarkasUrgent(rowDatas) {
    this.confirmMarkasUrgent = true;
  }

  confirmrugentConslt(rowDatas) {
    let consultationId = Number(rowDatas.consultationId);
    // this.consultationsService.MarkasUrgentConsultation(consultationId).subscribe((res)=>{

    //     this.GetConsultations();
    // });

    this.confirmMarkasUrgent = false;
  }

  //changing urgent consultation
  ChangeAsUrgent(id) {
    this.consultationId = id;

    this.userId = Number(localStorage.getItem("user_id"));
    this.locationId = 1;

    this.consultationsService
      .ChangeAsUrgent(this.consultationId, this.userId, this.locationId, this.globals.HospitalId)
      .subscribe((res) => {
        if (res.message == "success") {
          this.toastr.success("Consultation changed as Urgent");
          this.GetConsultations();
        } else {
          this.toastr.error("Failure: " + res["errorMessage"]["message"]);
        }
      });
    this.GetConsultations();
  }
  // Consulation cancel confirmation modal
  showBlockUnblock(rowDatas) {
    this.searchForm.patchValue({ blockReason: "" });
    this.active = rowDatas.status == "W" ? "Cancel" : "Cancelled";
    this.consultationId = Number(rowDatas.consultationId);
    this.blockorunblock = true;
  }

  // Consulation cancel confirmation is true then ask reason
  confirmblocking(blockId: any) {
    if (this.active != "Cancelled") {
      this.confirmBlock = true;
    }
  }

  // Function to cancel consultation with reason
  ConfirmBlock() {
    let blockReason = this.searchForm.get("blockReason").value;
    this.consultationsService
      .CancelConsultation(this.consultationId, blockReason)
      .subscribe((res) => {
        this.GetConsultations();
      });
    this.confirmBlock = false;
  }

  // //Getting teplates for symtoms and reason for visit
  GetTemplateReasonForVisit() {
    let showAll = 0;
    this.consultationsService.GetTemplateReasonForvisit(this.globals.HospitalId, showAll).subscribe((res) => {
      this.symptomList = res.response;
      // let syptomReasonList = [];
      // syptomReasonList.push({
      //   SymptomId: 0,
      //   SymptomDesc: "None Of the Above",
      //   Active: 1,
      //   BlockReason: "",
      // });
      // this.symptomList = syptomReasonList.concat(this.symptomList);
      this.symptomList.forEach((element) => {
        element["newsymDesc"] = element["SymptomDesc"]
          ? element["SymptomDesc"]
          : element["symptomDesc"];
      });
    });
  }

  //  checking Agi grid is loaded completely

  onGridReady(params) {
    this.gridApi = params.api;
  }
  //Modal showing reason visits
  ReasonForVisitModal(id) {
    this.searchForm.patchValue({ Symptoms: [] });
    this.searchForm.patchValue({ otherreasonforvisit: "" });
    const foundElement = this.functionPermissionData.find(x => x.id === 441)
    if (foundElement.id == 441 && foundElement.isAllowed == true) {
    //this.rowData;

    // this.consultationId = id.consultationId;
    // this.GetTemplateReasonForVisit();
    // this.consultationsService.Getsymptoms(this.consultationId).subscribe((res) => {
    //     this.symptomDetails = res.response;

    //     this.searchForm.patchValue({
    //       Symptoms: this.symptomDetails["symptoms"],
    //     });
    //   });

    // this.ReasonVisit = true;
    this.searchForm.patchValue({ Symptoms: [] });
    this.searchForm.patchValue({ otherreasonforvisit: "" });
    this.consultationId = id.consultationId;
    this.GetTemplateReasonForVisit();
    this.consultationsService.Getsymptoms(this.consultationId).subscribe((res) => {
      this.symptomDetails = res.response;
      this.searchForm.patchValue({ Symptoms: this.symptomDetails['symptoms'] });
      this.searchForm.patchValue({ otherreasonforvisit: "" });
      if (this.symptomDetails['reasonForVisit']) {
        this.OtherSymptom = true;
        this.searchForm.patchValue({ otherreasonforvisit: this.symptomDetails['reasonForVisit'] });
      }
      document.getElementById("myDiv").click();
    })


    this.ReasonVisit = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  //updating reason for visit
  SaveReasonForVisit(id) {
    var AppData = {
      ConsultationId: this.consultationId,
      Symptoms: this.searchForm.get("Symptoms").value,
      otherreasonforvisit: this.searchForm.get("otherreasonforvisit").value,
    };

    this.consultationsService.SaveReasonVisit(AppData).subscribe((res) => {
      this.result = res;

      if (this.result.status == 200) {
        this.toastr.success("Reason For Visit added successfully");
        this.GetConsultations();
      } else {
        this.toastr.error("Reason For Visit failed");
      }
    });
  }
  //Modal showing for urgent Consultations
  UrgentVisitmodal(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 440)
    if (foundElement.id == 440 && foundElement.isAllowed == true) {
    this.consultId = id.consultationId;
    this.UrgentVisit = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  //confirm for saving Urgent consultation
  ConfirmUrgentConsultation(id) {
    this.ChangeAsUrgent(id);
  }

  //reason for visit symptoms list
  // SymptomReason() {
  //   let val = this.searchForm.get("Symptoms").value;

  //   if (val.length > 0) {
  //     if (val[0]["newsymDesc"] == "None Of the Above") {
  //       this.OtherSymptom = true;
  //     } else {
  //       this.OtherSymptom = false;
  //     }
  //   } else {
  //     this.OtherSymptom = false;
  //   }
  // }
  //Modal showing cancel Appointment
  showCancelAppointment(rowDatas, consultationId = 0) {
    const foundElement = this.functionPermissionData.find(x => x.id === 431)
    if (foundElement.id == 431 && foundElement.isAllowed == true) {
    if (consultationId > 0) {
      this.toastr.error("Unable to cancel! because consultation is taken");
    } else {
      let appointmentsData = this.appointments.filter(
        (oppdata) => oppdata["appId"] == rowDatas
      );

      this.form.patchValue({ blockReason: "" });
      this.active =
        appointmentsData[0]["appStatus"] == "Cancelled"
          ? "Already Cancelled this Appointment"
          : "Are you sure that you want to this Cancel Appointment?";
      this.blockId = rowDatas;
      this.CancelAppointments = true;
      this.CancelButton =
        appointmentsData[0]["appStatus"] == "Cancelled" ? false : true;
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  // Consulation cancel confirmation is true then ask reason
  confirmblockingAppointment(blockId) {
    this.confirmblockApp = true;
  }

  // Function to cancel consultation with reason
  ConfirmBlockingApp(blockId) {
    let blockReason = this.form.get("blockReason").value;

    this.appointmentService
      .CancelAppointment(this.blockId, blockReason)
      .subscribe((res) => {
        this.GetAppointments();
        //this.LoadAppTable();
      });
    this.confirmedblockApp = false;
  }


  //apoointment postponned modal
  AppointmentPostPoned(appId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 429)
    if (foundElement.id == 429 && foundElement.isAllowed == true) {
    this.AppId = appId;
    this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      this.departmentList = [this.AppointmentDataDetails["deptId"]];
      this.consultDataId = [this.AppointmentDataDetails["consultantId"]];
      this.postponnedconsultId = this.AppointmentDataDetails["consultantId"];
      this.form.patchValue({
        PostPonedDepartment: this.AppointmentDataDetails["departmentName"],
      });
      this.form.patchValue({
        PostPonedConsultant: this.AppointmentDataDetails["consultantName"],
      });
      this.form.patchValue({
        appDate: this.AppointmentDataDetails["appDate"],
      });
      this.form.patchValue({
        slicetime: this.AppointmentDataDetails["sliceData"],
      });



      // this.form.patchValue({
      //   slicetime: this.AppointmentDataDetails[0]["sliceTime"],
      // });
      this.form.patchValue({
        sliceNo: this.AppointmentDataDetails["sliceNo"],
      });
      //this.form.patchValue({date:  this.appDate});


      this.PostponedAppointments = true;
    });
    setTimeout(() => {
      this.GetScheduledData();
    }, 1000);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onDateChange(value) {

    if (this.curentdDate != value) {
      if (typeof (value) == "object") {
        this.valueDate = this.datePipe.transform(value, 'dd-MM-yyyy');
      }
      this.GetScheduledData();
    }
  }
  //Getschedule method called from AppointmentService
  GetScheduledData() {

    this.appointmentService
      .GetAppointmentSchedule(
        this.consultDataId,
        this.valueDate,
        this.departmentList,
        this.BranchesId
      )
      .subscribe((res) => {


        if (res.response[0]) {
          this.scheduleTime = res.response[0]['labels'];
          this.scheduleTime = this.scheduleTime.map(v => {
            return v.appId > 0 && v.appId != this.AppId ? { ...v, isDisabled: true } : { ...v, isDisabled: false }
          });
        }
        else {
          this.scheduleTime = [];
        }

        if (this.curentdDate == this.valueDate) {
          this.form.patchValue({ slicetime: this.AppointmentDataDetails["sliceData"] });
        }
        else {
          this.form.patchValue({ slicetime: [] });
        }

      });
  }

  //Save PostPonned Appointments method
  InsertPostponnedAppointments(appId) {
    this.postponeddate = this.form.get("appDate").value;

    if (typeof this.postponeddate == "object") {
      this.postponeddate = this.datePipe.transform(
        this.postponeddate,
        "dd-MM-yyyy"
      );
    } else {
      this.postponeddate = this.form.get("appDate").value;
    }
    let sliceData = this.form.get("slicetime").value;
    let sliceDataDetails = [];
    sliceData.forEach(a => {
      sliceDataDetails.push({ "sliceNo": Number(a.sliceNo), "sliceTime": a.sliceTime });
    });
    var AppData = {
      appId: this.AppId,
      PostPonedConsultant: this.postponnedconsultId,
      PostPonedDepartment: this.form.get("PostPonedDepartment").value,
      AppDate: this.postponeddate,
      SliceTime: sliceDataDetails,
      BranchId: this.globals.HospitalId,
      // SliceNo: 0,
      UserId: Number(localStorage.getItem("user_id")),
    };

    this.appointmentService
      .SavePostponnedAppointments(AppData)
      .subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Postponned Appointment added successfully");
          this.GetAppointments();
          this.PostponedAppointments = false;


        } else {
          this.toastr.error("Postponned Appointment failed");
        }
      });
  }

  //Details to show Appoinment Status modal
  EditStatusModal(appId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 432)
    if (foundElement.id == 432 && foundElement.isAllowed == true) {
    this.EditStatusAppId = appId;
    this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      this.form.patchValue({
        appointmentStatus: this.AppointmentDataDetails['appStatus'],
      });
    });


    this.EditStatus = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  //Inserting Appointment Status
  SaveAppointmentStatus(appId) {
    appId = this.EditStatusAppId;
    var AppData = {
      appId: this.EditStatusAppId,
      appointmentStatus: this.form.get("appointmentStatus").value,
    };

    // return false;
    this.appointmentService.SaveAppointmentstatus(AppData).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success(" Appointment status added successfully");

        this.GetAppointments();
        this.GetProgressBar();
      } else {
        this.toastr.error("Appointment status failed");
      }
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  StartTimerst(event) {

    this.userTimer = false;
    this.cdTimer = true;
    if (event == "start") {
      this.timer.start();
    }

    if (event == "resume") {
      this.timer.resume();
    }

    if (event == "reset") {
      this.timer.reset();
    }


    if (event == "stop") {
      this.timer.stop();
      this.timer.get();
      let days = this.timer.get().days
      let hours = this.timer.get().hours
      let seconds = this.timer.get().seconds
      let minutes = this.timer.get().minutes
      let tickcount = this.timer.get().tick_count
      let CurrentDate = new Date();
      let date = this.datePipe.transform(CurrentDate, "yyyy-MM-dd'T'HH:mm");

      this.ConsultantDeskService.SaveTimer(date, date, this.Id, days, hours, seconds, minutes, tickcount, this.patientId).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success(" Timer added successfully");


        } else {
          this.toastr.error("Timer set failed");
        }
      });

    }

  }

  Addtime() {
    this.userTimer = true;
    this.cdTimer = false;

  }

  saveTimerSettings() {
    let startimer = this.form.get("starttime").value;
    let endtimer = this.form.get("endtime").value;
    this.ConsultantDeskService.SaveTimer(startimer, endtimer, this.Id, 0, 0, 0, 0, 0, this.patientId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success(" Timer added successfully");


      } else {
        this.toastr.error("Timer set failed");
      }
    });
  }

  //print appointment card
  printAppointments(appId) {

    this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      let sliceDatatime = [];
      this.AppointmentDataDetails['sliceData'].forEach(a => {
        sliceDatatime.push(a.sliceTime);
      });
      let printContents = "";
      //contact detatils

      printContents =
        printContents +
        '<div align="center"><div class="card" style="border:1px solid #000;width:50%">';
      printContents =
        printContents +
        ' <table class="print-table1"><tr style="text-align:center"><td colspan="2"><h3>Appointment Detail</h3></td></tr>treatment_<tr><td><strong>Appointment Date.</strong></td><td>' +
        this.AppointmentDataDetails["appDate"] +
        "</td></tr>";

      printContents =
        printContents +
        " <tr><td><strong>App Time:</strong></td><td>" +
        sliceDatatime.toString() +
        "</td></tr>";

      printContents =
        printContents +
        " <tr><td><strong>Consultant Name:</strong></td><td>" +
        this.AppointmentDataDetails["consultantName"] +
        "</td></tr>";
      printContents =
        printContents +
        " <tr><td><strong>Patient Name:</strong></td><td>" +
        this.AppointmentDataDetails["firstName"] + " " + this.AppointmentDataDetails["middleName"] + " " + this.AppointmentDataDetails["lastName"]
      "</td></tr>";
      // printContents = printContents + ' <tr><td><strong>Mobile:</strong></td><td>' + this.appointments[0].mobile + '</td></tr>';

      var mywindow = window.open("", "PRINT", "height=1000,width=1200");
      mywindow.document.write(
        '<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' +
        document.title +
        "</title>"
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write(printContents);
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    });
    return true;
  }
  printAppointment(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 430)
    if (foundElement.id == 430 && foundElement.isAllowed == true) {
    const payload={
      AppId :data,
      BranchId:this.BranchesId
    }
    this.appointmentService.PrintAppointment(payload).subscribe({
      next:(res)=>{
        if(res.status==200){
          let response=res.response;
          let src=''
          src=response[0].reportPath;
          src=this.baseUrl+src;
          //  src=this.sanitizer.bypassSecurityTrustResourceUrl(src);
          window.open(src, "_blank")
        }
      }
    })
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  reload() {
    this.GetDepartments(); //on load api function getting departments
    this.GetAppointments(); //on load api function getting appoinments
    this.GetConsultations();//on load api function getting Consultations
    this.GetProgressBar();//on load api function getting Progressbar
    this.GetTemplateReasonForVisit();//on load api function getting reasonforvisit
    this.toastr.success("Data Refreshed");
  }
  goToEmr(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 436)
    if (foundElement.id == 436 && foundElement.isAllowed == true) {
      // console.log(data)
    let patientInfo = {
      'VisitId': 0,
      'ConsultantId': Number(localStorage.getItem('Id')),
      'ConsultationId': data.consultationId,
      'lastConsultationId': data.consultationId,
      'PatientId': data.patientId,
      'VisitType': 0,
      // 'VisitStartTime': '',
      // 'VisitEndTime': '',
      'VisitStartTime': data.cstarttime,
      'VisitEndTime': data.cendtime,
      'UserId': Number(localStorage.getItem('user_id')),
      'CrudType': 0,
      InsAmountLimit: 0,
      insCovered: 0,
      sFormId:data.sFormId,
      telerikName:data.telerikName
    }
    localStorage.setItem('consultFee',data.consultationFee)
    let patInfo = {
      ConsultationId: data.consultationId,
      SponsorId: data.sponsorId,
      telerikName:data.telerikName
    }
    console.log(patientInfo)
    localStorage.setItem('asoapPatInfo', JSON.stringify(patInfo));
    let OutPut = this.EMRService.SetEMRPatientInfo(patientInfo);
    if (OutPut) {
      this.router.navigate(['/EMR_Home']);
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  openEmr() {
    let patientInfo = {
      'VisitId': 0,
      'ConsultantId': Number(localStorage.getItem('Id')),
      'ConsultationId': this.consultsId,
      'lastConsultationId': this.consultsId,
      'PatientId': this.patientId,
      'VisitType': 0,
      'VisitStartTime': '',
      'VisitEndTime': '',
      'UserId': Number(localStorage.getItem('user_id')),
      'CrudType': 0,
      InsAmountLimit: 0,
      insCovered: 0
    }
    // console.log(patientInfo)
    let OutPut = this.EMRService.SetEMRPatientInfo(patientInfo);
    if (OutPut) {
      this.router.navigate(['/EMR_Home']);
    }


  }

  billingInstuction(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 434)
    if (foundElement.id == 434 && foundElement.isAllowed == true) {
    this.modalRef1 = this.modalService.show(
      this.modalInstruction, { class: "gray" })
    this.consultationId = data.consultationId
    // alert("10");
    this.Instructionform.patchValue({ BillingInstruction: data.billingInstruction });
    // this.SponsorConsentForm.patchValue({
    //   SponsorNameConsent: SponsorId,
    // });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  BillingInstuction() {
    var AppData = {
      ConsultationId: this.consultationId,
      BillingInstruction: this.Instructionform.get("BillingInstruction").value,
    };


    this.consultationsService.billingInstructions(AppData).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200 && this.result.message == 'Success') {


        this.toastr.success("Instruction Saved Successfully");
        this.GetConsultations();
        this.modalRef1.hide();

      } else {


        this.toastr.error("Failed : " + this.result.message);
        // this.clearform();
      }
    });
  }

  // editBillingInstuction(data) {
  //   this.BillinginstructionPopupHeading = "Edit Billing Instuction"
  //   // this.display = true;
  //   // this.isLoading=false;
  //   this.modalRef1 = this.modalService.show(this.modaledit,  );
  //   this.Instructionform.patchValue({BillingInstruction: data});

  // }
  // openModalPayment(template: TemplateRef<any>) {
  //   this.BillinginstructionPopupHeading = "New Payment";
  //   this.modalRef1 = this.modalService.show(template, { class: "modal-lg modal-custom-style", backdrop: "static" });
  //   // this.ResetFunction();
  //   this.paymentId = 0;
  //   //  this.clearForm();

  // }
  specialDiscount(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 435)
    if (foundElement.id == 435 && foundElement.isAllowed == true) {
    this.modalRef2 = this.modalService.show(
      this.modaltemp, { class: "gray" })
      this.consultationId = data.consultationId
      this.specialDiscountForm.patchValue({ SpdiscAmount :data.spdiscPcnt   });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  specialDiscountSAve(){
    // let input = this.specialDiscountForm.getRawValue();
    let input={
      consultationId:this.consultationId,
      spdiscPcnt: this.specialDiscountForm.get("SpdiscAmount").value,

    }

    this.consultationsService.specialDiscount(input).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200 && this.result.message == 'Success') {


        this.toastr.success("Discount Saved Successfully");
        this.GetConsultations();
        this.modalRef2.hide();

      } else {
        this.toastr.error("Failed : " + this.result.message);
      }
    });

  }

  printAsoap(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 439)
    if (foundElement.id == 439 && foundElement.isAllowed == true) {
    let initialState = {
      consultationId: data.consultationId,
      branchId: this.BranchesId,
      sponsorId: data.sponsorId,
      telerikName:data.telerikName
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(PrintAsoapComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onPrintSticker(data: any){
    const payload = {
      PatientId: data.patientId,
      BranchId: this.globals.HospitalId,
      ConsultationId: 0
    }
    this.patientService.printSticker(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          window.open(this.baseUrl + response.response[0].reportPath, '_blank')
        }else{
          this.toastr.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastr.error('Something wents wrong..! Try again.')
      }
    })
  }
  treatmentDetails(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 437)
    if (foundElement.id == 437 && foundElement.isAllowed == true) {

      let initialState = {
        modalHeading: 'Treatment Summary',
        reportName: 'TreatmentSummary.trdp',
        reportParams: {
          PatientId : data.patientId,
          FromDate : (data?.consultDate.slice(3, 5) +'-'+data?.consultDate.slice(0,2) + '-' + data?.consultDate.slice(6, 10)),
          ToDate: (data?.consultDate.slice(3, 5) +'-'+data?.consultDate.slice(0,2) + '-' + data?.consultDate.slice(6, 10)),
          ConsultationId : data?.consultationId
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
//     this.treatmentDetail = true;

//   let pID=data.patientId
//    let input={
//   "branchId":Number(this.globals.HospitalId),
//   "PatientId":Number(pID),
//   "dateFrom":"",
//   "dateTo":"",
//   "TreatmentNumber":"",
//   "id":0,
//   "PatientName":"",
//   "mobile":"",
//   "regNo":""
// }
// this.TreatmentsService.GetTreatments(input).subscribe(
//   (res) => {
//     if (res.status == 200) {

//       this.treatmentList = res.response;
//       if (this.treatmentList.length == 0) {
//         this.toastr.warning("No Treatment Details");
//       }

//     }
//   });
//   this.treatmentDetail = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  editAppointment(appId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 426)
    if (foundElement.id == 426 && foundElement.isAllowed == true) {
      this.router.navigate(["/AddAppointment", appId])
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  gotoConsultation(appId?) {
    const foundElement = this.functionPermissionData.find(x => x.id === 427)
    if (foundElement.id == 427 && foundElement.isAllowed == true) {
      if(appId){
        this.router.navigate(["/AddConsultation", appId]);
      } else{
        this.router.navigate(["/AddRegistration"]);
      }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onRegisterfromAppoinment(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 428)
    if (foundElement.id == 428 && foundElement.isAllowed == true) {
    this.router.navigate(['/AddRegistration'], {state: {appoinmentId: data.appId}});
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  serviceOrderConsultant(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 433)
    if (foundElement.id == 433 && foundElement.isAllowed == true) {
      this.router.navigate(['/serviceOrder'], { state: { patientId: data.patientId ,consultationId:data.consultationId} });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  gotoPrintAction(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 438)
    if (foundElement.id == 438 && foundElement.isAllowed == true) {
      // need to write the functionalities inside this if condition(this if condition is used for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }


  changeFees(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 608)
    if (foundElement?.id == 608 && foundElement.isAllowed == true) {
    let initialState = {
      consultationId: data.consultationId,
      branchId: this.BranchesId,
      patientId: data.patientId,
      consultantId: data.consultantId,
      consultationDate: data.consultDate
    }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(ChangeFeeComponent, Object.assign({}, config, { class: 'modal-md', initialState }));
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  changeStatusModal(data) {
    // console.log('functionPermissionData1', this.functionPermissionData);
    const foundElement = this.functionPermissionData.find(x => x.id === 519)
    if (foundElement.id == 519 && foundElement.isAllowed == true) {
      let initialState = {
        branchesId: this.BranchesId,
        rowData: data,
        functionPermissionData: this.functionPermissionData
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.changeStatusModalRef = this.modalService.show(ChangeStatusModalComponent, Object.assign({}, config, { class: 'modal-md', initialState }))
      this.changeStatusModalRef.content.statusChange.subscribe(res => {
        if (res.isChanged) {
          this.GetConsultations();
          this.GetProgressBar();
        }
      })
    } else {
      this.toastr.warning("You are not allowed to change status");
    }
  }

  printMedicalCertificate(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 610)
    if (foundElement?.id == 610 && foundElement.isAllowed == true) {
    let initialState = {
      modalHeading: 'Medical Certificate',
      reportName: 'RptMedicalCertificate.trdp',
      reportParams: {
        PatientId : data.patientId,
        ConsultationId : data?.consultationId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  printMedicalReport(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 611)
    if (foundElement?.id == 611 && foundElement.isAllowed == true) {
    let initialState = {
      modalHeading: 'Medical Report',
      reportName: 'RptMedicalReport.trdp',
      reportParams: {
        PatientId : data.patientId,
        ConsultationId : data?.consultationId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  nabithConsent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 609)
    if (foundElement?.id == 609 && foundElement.isAllowed == true) {
    if (this.healthPlatform.settValue === "Nabidh") {
    let initialState = {
      patientId: data.patientId,
      ConsultationId: data.consultationId,
      action: 1,
      formName:this.healthPlatform.settValue
    }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(NabithConsentComponent,Object.assign( {}, config, { class: 'modal-xl', initialState }) );
    this.modalRef.content.fileEmitter.subscribe((response: any)=>{
      if(response.status == true){
        // this.toastr.success("The signature updated successfully");
      }
    })
    } else if (this.healthPlatform.settValue === "Riayati") {
      let initialState = {
        patientId: data.patientId,
        ConsultationId: data.consultationId,
      }

      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.modalRef = this.modalService.show(RiayatiConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
      this.modalRef.content.fileEmitter.subscribe((response: any) => {
        if (response.status == true) {
          // this.toastr.success("The signature updated successfully");
        }
      })
    }
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  getApplicationSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }

    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.healthPlatform=response.response.find(item => item.settId == 25)
        }
      }
    })
  }

  openInfo(event){
    let data=event.data
    let orderIds=event.data.orderIds?.map((item => item.orderId))
    let initialState = {
        data:{
          BranchId : this.globals.HospitalId,
          patientId:data.patientId,
          ConsultationId:data.consultantId,
          OrderDate:"",
           Today:this.convertDateFormat(data.consultDate),
          locationId:this.globals?.Location?.locationId,
          SpointId:0,
          OrderIds:orderIds ? orderIds :[]
        }    
    }
    debugger
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(StatusInfoModalComponent, Object.assign({}, config, { class: 'modal-m', initialState }))
    
  }

  convertDateFormat(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    const date = new Date(+year, +month - 1, +day); // Months are 0-based in JavaScript Date
    return this.datePipe.transform(date, 'dd-MM-YYYY') ?? '';
  }
}



