import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { BillingGeneralActionComponent } from '../billing-general-action/billing-general-action.component';
import { BillingService } from '../services/billing.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-person-register',
  templateUrl: './person-register.component.html',
  styleUrls: ['./person-register.component.scss']
})
export class PersonRegisterComponent implements OnInit {
  columnDefs: ColDef[] = [];
  rowGroupPanelShow = "always";
  public context;
  public gridApi;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 5,
  }
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  // personRegisterList: any[] = [];
  personRegisterList = [{
    regno: 'A001',
    patientName: 'Test Name',

    nationality: 'india',
    address: 'address',
    state: 'kerala',
    palce: 'kozhikode',

  }];
  globals: globalvars = new globalvars();
  subscription: Subscription;
  departments: any;
  consultants: any[] = [];
  form: FormGroup;

  constructor(
    private billingService: BillingService,
    private sharedservice: SharedService,
    public datepipe: DatePipe,

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {
    this.context = { componentParent: this };
    this.form = new FormGroup({
      FromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ToDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ConsultantId: new FormControl(0),
      DeptId: new FormControl(0),
      BranchId: new FormControl(this.globals.HospitalId),
    });

    this.columnDefs = [
      {
        headerName: "Select",
        field: "empty",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        maxWidth: 80,
        checkboxSelection: true,
        filter: true,
      },

      {
        headerName: "Reg No.",
        field: "regno",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Patient Name",
        field: "patientName",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "XMLGenerated",
        field: "xml",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Nationality",
        field: "nationality",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Address",
        field: "address",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "State",
        field: "state",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Place",
        field: "palce",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },

    ];
    this.frameworkComponents = { buttonRender: BillingGeneralActionComponent };
    this.GetDepartments();
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  GetDepartments() {
    let showall = 0;
    this.billingService.GetDepartments(this.globals.HospitalId, showall).subscribe((res) => {
      this.departments = res.response;
    });
  }
  loadConsultant(DepartmentId: number) {
    let departmentId = Number(this.form.get("DeptId").value);
    if (departmentId > 0) {
      this.billingService.GetConsultant(departmentId).subscribe((res) => {
        this.consultants = res.response;
      });
    }
    else {
      this.consultants = [];
    }
  }
  searchdetails() {
    let input = this.form.getRawValue();
    if (input.FromDate && (typeof (input.FromDate) == "object")) {
      input.FromDate = this.datepipe.transform(new Date(input.FromDate), 'dd-MM-yyyy');
    }
    if (input.ToDate && (typeof (input.ToDate) == "object")) {
      input.ToDate = this.datepipe.transform(new Date(input.ToDate), 'dd-MM-yyyy');
    }
    input= {

    }
  }
}
