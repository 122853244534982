import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";

import * as customEditor from "../../../../../../ckeditor/build/ckeditor";
import { ConfigurationManager } from "../../../../../assets/configuration/configuration-manager";
import { globalvars } from "../../../../shared/models/GlobalVars";
import { SharedService } from "../../../../shared/shared.service";
import { Response } from "../../../../_Models/Response";
import { ServiceOrderingService } from "../../services/ServiceOrderingService";
import { PatientService } from "../../services/patient.service";
import { ConfigurationService } from "../../../client/services/configuration.service";
import _ from "lodash";

@Component({
  selector: "app-report-manage",
  templateUrl: "./report-manage.component.html",
  styleUrls: ["./report-manage.component.scss"],
})
export class ReportManageComponent implements OnInit {
  public Editor = customEditor;
  public workListForm: FormGroup;

  private globals = new globalvars();

  private orderItemId: number = 0;
  private orderId: number = 0;
  staffId: any;
  public workLists: any[] = [];
  public allStaffs: any[] = [];
  public allTemplates: any[] = [];

  private subscription: Subscription[] = [];

  public reportsLoading: boolean = false;
  public saveReportLoading: boolean = false;

  public selectedFile: any;
  private baseUrl: string = "";
  private defaultReportName: string = "";
  functionPermissionData: any[];
  HealthInformationPlatform: any;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private sharedservice: SharedService,
    private datePipe: DatePipe,
    private serviceOrderService: ServiceOrderingService,
    private configurationManager: ConfigurationManager,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private ConfigurationService: ConfigurationService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
   
    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.subscription.push(
      this.activatedRoute.params.subscribe((param) => {
        this.orderId = param["orderId"];
        this.orderItemId = param["orderItemId"];
        if (
          this.orderItemId &&
          this.orderItemId > 0 &&
          this.orderId &&
          this.orderId > 0
        ) {
          this.getItemReports();
        }
      })
    );

    this.subscription.push(
      this.sharedservice.getHospital().subscribe((glob) => {
        if (this.sharedservice.checkglobals(glob)) {
          this.globals = glob;
        }
      })
    );
    this.getAllStaffs();
    this.getItemTemplates();
    this.GetServiceItems();
    this.initWorkListForm();
    this.functionPermission();
    this.getApplicationSettings();
    this.staffId = "";
  }

  GetServiceItems(load = 0) {
    const payload = {
      Id: Number(this.orderItemId),
      ShowAll: 1,
      BranchId: this.globals.HospitalId,
      GroupId: 0,
      ConsultantId: 0,
    };

    this.serviceOrderService.GetServiceItem(payload).subscribe((res) => {
      let serviceItem = res.response[0];
      this.defaultReportName =
        serviceItem.itemName +
        "-" +
        this.datePipe.transform(new Date(), "dd-MM-yyyy");
      this.workListForm.patchValue({
        reportName: this.defaultReportName,
      });
    });
  }

  getAllStaffs() {
    let payload = {
      StaffId: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId,
    };

    this.serviceOrderService.getAllStaffs(payload).subscribe({
      next: (response: Response<any>) => {
        this.allStaffs = response.response;
        this.workListForm.patchValue({
          staffId:Number(localStorage.getItem('staffId')) !== 0 ? Number(localStorage.getItem('staffId')) : ''
        })
      },
      error: (error: any) => {
        this.toastrService.error("Failed to load staff details, Try again!");
      },
    });
  }

  // getItemTemplates(){
  //   let payload = {
  //     ServiceItemId: Number(this.orderItemId),
  //     BranchId: this.globals.HospitalId
  //   }

  //   this.serviceOrderService.getTemplatesOServiceItem(payload).subscribe({
  //     next: (response: Response<any>)=>{
  //       if(response.status == 200){
  //         this.allTemplates = response.response;
  //       }else{
  //         this.toastrService.error(response.message)
  //       }
  //     },error: (error: any)=>{
  //       this.toastrService.error('Something wents wrong, Try again');
  //     }
  //   })
  // }
  getItemTemplates() {
    let payload = {
      Id: 0,
      ShowAll: 0,
      BranchId: this.globals.HospitalId,
    };
    this.serviceOrderService.getTemplatesOServiceItem(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200) {
          this.allTemplates = response.response;
        } else {
          this.toastrService.error(response.message);
        }
      },
      error: (error: any) => {
        this.toastrService.error("Something wents wrong, Try again.");
      },
    });
  }

  initWorkListForm() {
    this.workListForm = this.formBuilder.group({
      workListId: new FormControl(0, Validators.required),
      reportName: new FormControl(this.defaultReportName, Validators.required),
      staffId: new FormControl("", Validators.required),
      workListFile: new FormControl(""),
      workListTemplateId: new FormControl(""),
      workListTemplateData: new FormControl(""),
      reportStatus: new FormControl(1),
    });
  }

  onReady(editor) {
    editor.ui.height = 30;
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  onSelectTemplate() {
    let template = this.allTemplates.filter(
      (temp) => temp.id == this.workListForm.get("workListTemplateId").value
    )[0];
    this.workListForm.patchValue({
      workListTemplateData: template.templateData,
      workListFile: "",
    });

    this.onWorkListTypeChange(1);
  }

  onWorkListTypeChange(type) {
    if (type == 1) {
      this.workListForm
        .get("workListTemplateId")
        .setValidators(Validators.required);
      this.workListForm
        .get("workListTemplateData")
        .setValidators(Validators.required);

      this.workListForm.get("workListFile").clearValidators();
    } else if (type == 2) {
      this.workListForm.get("workListTemplateId").clearValidators();
      this.workListForm.get("workListTemplateData").clearValidators();

      this.workListForm.get("workListFile").setValidators(Validators.required);
    } else {
      this.workListForm.get("workListTemplateId").clearValidators();
      this.workListForm.get("workListTemplateData").clearValidators();
      this.workListForm.get("workListFile").clearValidators();
    }

    this.workListForm.get("workListTemplateId").updateValueAndValidity();
    this.workListForm.get("workListTemplateData").updateValueAndValidity();
    this.workListForm.get("workListFile").updateValueAndValidity();
  }

  onFileSelected(event) {
    let file = event.target.files[0];
    // if(file.type == "application/pdf" || file.type == ".doc" || file.type == ".docx"){
    this.selectedFile = file;
    this.onWorkListTypeChange(2);
    this.workListForm.patchValue({
      workListTemplateData: "",
      workListTemplateId: "",
      workListFile: this.selectedFile.name,
    });
    // }else{
    //   this.toastrService.warning('Unsupported file, Select a document file');
    //   this.workListForm.patchValue({
    //     workListFile: '',
    //     workListTemplateData: '',
    //     workListTemplateId: ''
    //   });
    //   this.onWorkListTypeChange(3);
    // }
  }

  onRemoveFile() {
    this.workListForm.patchValue({
      workListFile: "",
      workListTemplateData: "",
      workListTemplateId: "",
    });
    this.selectedFile = "";
    this.onWorkListTypeChange(3);
  }

  saveWorkList() {
    if (!this.saveReportLoading) {
      const controls = this.workListForm.controls;
      if (this.workListForm.invalid) {
        Object.keys(controls).forEach((control) => {
          controls[control].markAllAsTouched();
        });

        return;
      }

      let templateData = {
        Id: Number(controls.workListId.value),
        ServiceOrderId: Number(this.orderId),
        ItemId: Number(this.orderItemId),
        TemplateId: Number(controls.workListTemplateId.value),
        StaffId: Number(controls.staffId.value),
        IsDeleting: 0,
        ReportName: controls.reportName.value,
        ReportContent: controls.workListTemplateData.value,
        ReportStatus: controls.reportStatus.value,
      };

      const formData = new FormData();
      formData.append("ServiceOrderReportJson", JSON.stringify(templateData));
      formData.append("ServiceOrderReportFile", this.selectedFile);

      this.saveReportLoading = true;
      this.serviceOrderService.saveReportDetails(formData).subscribe({
        next: (response: Response<any>) => {
          if (response.status == 200) {
            this.toastrService.success("Report saved succesfully");
            this.sendDataToNabid()
            this.resetForm();
            this.getItemReports();
          } else {
            this.toastrService.error(response.message);
          }
          this.saveReportLoading = false;
        },
        error: (error: any) => {
          this.toastrService.error("Something wents wrong, Try again");
          this.saveReportLoading = false;
        },
      });
    }
  }

  goBack() {
    // if(!this.saveReportLoading){
    //   this.router.navigate(['/ServiceList'], {replaceUrl: true});
    // }
    this._location.back();
  }

  getItemReports() {
    let payload = {
      ServiceOrderId: Number(this.orderId),
      ItemId: Number(this.orderItemId),
    };
    this.reportsLoading = true;
    this.serviceOrderService.GetReportsByServiceItem(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200) {
          this.workLists = response.response;
        } else {
          this.toastrService.error(response.message);
        }
        this.reportsLoading = false;
      },
      error: (error: any) => {
        this.toastrService.error("Something wents wrong, Try again!");
        this.reportsLoading = false;
      },
    });
  }

  resetForm() {
    this.workListForm.patchValue({
      workListId: 0,
      // staffId: '',
      reportName: this.defaultReportName,
      workListFile: "",
      workListTemplateId: "",
      workListTemplateData: "",
      reportStatus: 1,
    });
    this.selectedFile = "";
    const controls = this.workListForm.controls;
    Object.keys(controls).forEach((control) => {
      controls[control].markAsUntouched();
    });
  }

  onViewReportClicked(report: any) {
    if (report.templateId == 0 && report.fileLocation != "") {
      window.open(this.baseUrl + report.fileLocation, "_blank");
    } else {
      this.workListForm.patchValue({
        workListId: report.id,
        staffId: report.staffId,
        workListFile: "",
        reportName: report.reportName,
        workListTemplateId: report.templateId,
        workListTemplateData: report.reportContent,
        reportStatus: report.reportStatus,
      });
      this.selectedFile = "";
    }
  }

  onDeleteReportClicked(report: any) {
    if (!this.saveReportLoading) {
      const controls = this.workListForm.controls;

      let templateData = {
        Id: Number(report.id),
        IsDeleting: 1,
        ReportName: report.reportName,
        ReportContent: report.reportContent,
      };

      const formData = new FormData();
      formData.append("ServiceOrderReportJson", JSON.stringify(templateData));
      formData.append("ServiceOrderReportFile", "");

      this.saveReportLoading = true;
      this.serviceOrderService.saveReportDetails(formData).subscribe({
        next: (response: Response<any>) => {
          if (response.status == 200) {
            this.toastrService.success("Report deleted succesfully");
            this.resetForm();
            this.getItemReports();
          } else {
            this.toastrService.error(response.message);
          }
          this.saveReportLoading = false;
        },
        error: (error: any) => {
          this.toastrService.error("Something wents wrong, Try again");
          this.saveReportLoading = false;
        },
      });
    }
  }

  onPrintReportClicked(report) {
    let printContents, popupWin;
    if (report.templateId == 0 && report.fileLocation != "") {
      popupWin = window.open(
        "",
        "_blank",
        "top=0,left=0,height=100%,width=auto"
      );
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print Report</title>
          </head>
          <style>
              table, td, th {
                border: 1px solid;
              }
              
              table {
                width: 100%;
                border-collapse: collapse;
              }
          </style>
          <body><iframe src="${
            this.baseUrl + report.fileLocation
          }" style="width: 100%;height: 100%;border: none;"></iframe></body>

        </html>`);
    } else {
      printContents = report.reportContent;
      popupWin = window.open(
        "",
        "_blank",
        "top=0,left=0,height=100%,width=auto"
      );
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print Report</title>
          </head>
          <style>
              table, td, th {
                border: 1px solid;
              }
              
              table {
                width: 100%;
                border-collapse: collapse;
              }
          </style>
          <body>${printContents}</body>

          <script>
            window.onload = function() {
              setTimeout(() => {
                window.print();
                window.close();
              }, 100);      
            };
          </script>

        </html>`);
    }
    popupWin.document.close();
  }

  functionPermission() {
    let input = {
      // page is loaded from action button of todays patient we use menuId of todays patient
      MenuId: 103,
      UserId: Number(localStorage.getItem("user_id")),
    };
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  getApplicationSettings() {
    let payload = {
      UserId: Number(localStorage.getItem("user_id")),
      LocationId: this.globals?.Location?.locationId,
      BranchId: this.globals.HospitalId,
    };
    this.patientService.getApplicationSettings(payload).subscribe((res) => {
      let HealthInformationPlatform = _.find(res.response, {
        displayName: "HealthInformationPlatform",
      });
      this.HealthInformationPlatform = HealthInformationPlatform;
    });
  }

  sendDataToNabid() {
    let worklistdata = JSON.parse(localStorage.getItem("reportWorkListData"));
    const currentDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    const nbPayload = {
      EventId: 19,
      Consultationid: worklistdata.consultationId,
      EntryDate: currentDate,
      PatientId: worklistdata.patientId,
      BranchId: this.globals.HospitalId,
      OrderDetId: worklistdata.orderDetId,
    };
    const Permission = this.functionPermissionData.find((x) => x.id === 572);
    // Check if the platform is 'Nabidh' and permissions are allowed
    if (this.HealthInformationPlatform.settValue === "Nabidh" ) {
      if (Permission?.isAllowed ) {
         if(this.selectedFile || this.workListForm.get("workListTemplateId").value) {
          this.sendToNabidh(nbPayload)
        }
      } else {
        this.toastrService.warning("No permission to send data to nabith");
      }
    }
  }

  private sendToNabidh(payload: any) {
    this.patientService.SendDetailstoNabith(payload).subscribe({
      next: (res: any) => {
        this.toastrService.info("Response from Nabidh: " + res);
      },
    });
  }

  ngOnDestroy() {
   localStorage.removeItem("reportWorkListData")
  }
}
