import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { EMRService } from '../../emr/services/emr.service';
import { BasicPatientInfo } from '../interfaces/BasicPatientInfo';
import { ConsultationList } from '../interfaces/ConsultationList';
import { EMRPatientInfo, EMRSponsorInfo } from '../interfaces/EMRPatientInfo';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VisitHistory } from '../interfaces/VisitHistory';
import { Router } from '@angular/router';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { DatePipe } from "@angular/common";
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { AllergyForm } from '../interfaces/AllergyForm';
import { Notes } from '../interfaces/Notes';
import { Location } from '@angular/common';
import { Hooks } from '../interfaces/Hooks';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { DomSanitizer } from "@angular/platform-browser";
import { state } from '@angular/animations';
import { ConfirmModalComponent } from '../../../shared/modals/confirm-modal/confirm-modal.component';
import _ from 'lodash';
import moment from 'moment';
import { PopupAlertComponent } from '../modals/popup-alert/popup-alert.component';
import { PrintAsoapComponent } from '../../client/frontoffice/modals/print-asoap/print-asoap.component';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
import { PatientService } from '../../client/services/patient.service';
import { ConfigurationService } from '../../client/services/configuration.service';
import { CFormLockComponent } from '../../../shared/modals/c-form-lock/c-form-lock.component';
import { debug } from 'console';
import { SettingsService } from '../../settings/services/settings.service';
import { Response } from '../../../_Models/Response';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-emr-home',
  templateUrl: './emr-home.component.html',
  styleUrls: ['./emr-home.component.scss']
})
export class EMRHomeComponent implements OnInit {

  // for tab setup
  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;
  public submittedAllergy: boolean = false;
  allergySaveLoader:boolean=false

  Selectedtab: string;
  notesPopupHeading = "Add Notes";
  hooksPopupHeading = "Add Hooks"
  EMRPatientInfo: EMRPatientInfo;
  EMRSponsorInfo: EMRSponsorInfo;
  BasicPatientInfo: BasicPatientInfo = new BasicPatientInfo();
  PatientImage: string = "assets/images/No-photo.png";
  ConsultationList: ConsultationList[] = [];
  SelectedConsultation: number = 0;
  router_url = this.router.url;
  form: FormGroup; // form variable
  ConsultationTime: FormGroup;
  alertForm: FormGroup;
  AllergyForm: AllergyForm;
  display: boolean = false;
  display1: boolean = false;
  isLoading: boolean = false;
  VisitHistory: VisitHistory[] = [];
  notes: Notes;
  formdata: Hooks;
  NotesList: Notes[] = [];
  ConfNotesList: Notes[] = [];
  HooksDataList: any;
  hookDesc: any;
  displayBasic: boolean;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  patId: any;
  noteId: number = 0;
  hookid: any;
  ServiceItems: any;
  resultServiceItems: any;
  ModalServiceItems: any[] = [];
  modalRef?: BsModalRef;
  src: any = '';

  modalRefAllergy?: BsModalRef;
  modalRefAlert?: BsModalRef;
  modalRefNotes?: BsModalRef;
  modalRefHook?: BsModalRef;
  modalConfNotes?: BsModalRef;

  @ViewChild("template1") modaltemp1: any;
  @ViewChild("template2") modaltemp2: any;
  @ViewChild("template3") modaltemp3: any;
  @ViewChild("template4") modaltemp4: any;
  @ViewChild("template5") modaltemp5: any;
  @ViewChild("template6") modaltemp6: any;
  @ViewChild("template7") modaltemp7: any;

  DrugResponse: any;
  TodaysvitalSignList: any;
  AllergyList: any;
  AlertList: any;
  SelectedIcdList = new Array;
  visitwiseIcdList: any;
  StartDate: any;
  IsViewed: boolean;
  EndDate: any;
  BranchesId: any;
  EndtimeDate: string;
  IsButtonVisible = true;
  valueDate: any;
  allergyTypeList: any;
  firstDate: any;
  AllergySeverityList: any;
  AllergyDataform: FormGroup;
  NotesDataform: FormGroup;
  Hookform: FormGroup;
  UpdateVistId: number = 0;
  private baseUrl = "";
  userType:any;
  alertId:any;
  isEdit:any = false;
  secondDate: any;
  visitEditHis: any;
  popupData:any;
  popupshow:any=false;
  public activeTabId: number = 1;
  ConsultantCanViewAllConsultation: any;
  addAllergy: boolean=true;
  functionPermissionData: any;
  HealthInformationPlatform: any;
  allergenTypeList: any;
  allergenReactionList: any;
  drugAllergenList: any;
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "arId",
    textField: "arDesc",
    enableCheckAll: false,
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  public allergenDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "drugId",
    textField: "drugName",
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  public today = new Date();
 

  constructor(
    private EMRService: EMRService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: BsModalService,
    private datepipe: DatePipe,
    private sharedservice: SharedService,
    private _location: Location,
    private configurationManager: ConfigurationManager,
    private sanitizer: DomSanitizer,
    private patientService:PatientService,
    private ConfigurationService: ConfigurationService,
    private settingsService:SettingsService,
    private datePipe: DatePipe,
  ) {
  // if(!(router.getCurrentNavigation().extras.state)){
  //   sessionStorage.removeItem("EMRPatientInfo")
  // }
   this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
  //  console.log('this.EMRPatientInfo', this.EMRPatientInfo);
    this.SelectedConsultation = this.EMRPatientInfo.ConsultationId;
    this.getPatientInfo(this.EMRPatientInfo.PatientId)
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.globals.HospitalId
        this.BranchesId = this.globals.HospitalId;
      }

    });
    this.baseUrl = (this.configurationManager as any).baseUrl;
    localStorage.setItem("emrPhyConsultationID", JSON.stringify( this.EMRPatientInfo.ConsultationId ));

  

  }
  public submitted: boolean = false;
  public submitted1: boolean = false;

  ngOnInit(): void {
    this.getApplicationSettings();
    this.functionPermission();
    this.Scrolltotab('serviceOrder');
    this.GetConsultation();
    setTimeout(() => {
      this.GetServiceItems();
    }, 9000);
    // this.GetServiceItems();
    this.GetAllResultPublishedServiceItems();
    this.GetAllergies();
    this.GetAlert();
    this.userType = localStorage.getItem("usertype")
    this.form = new FormGroup({
      ConsultationId: new FormControl(Number(this.SelectedConsultation)),
    });
    this.ConsultationTime = new FormGroup({
      StartTime: new FormControl(''),
      EndTime: new FormControl(''),


    });
    this.alertForm = new FormGroup({
      StartTime: new FormControl('' , [Validators.required]),
      EndTime: new FormControl('' , [Validators.required]),
      Details: new FormControl(''),
      month: new FormControl(0),
      AlertType: new FormControl(''),
      IsActive: new FormControl(true),

    });
    this.AllergyDataform = new FormGroup({
      AllergyType: new FormControl(0),
      AllergySeverity: new FormControl(0),
      AllergyDetails: new FormControl(''),
      allergyTypeName: new FormControl(''),
      Status:new FormControl(''),
      UserId: new FormControl(Number(localStorage.getItem('user_id'))),
      PatientId: new FormControl(this.EMRPatientInfo.PatientId),
      IsDeleting: new FormControl(0),
      Id:new FormControl(0),
      ResolvedDate:new FormControl(''),
      AllergenId:new FormControl(0),
      // ARId:new FormControl(0)
      ARIdList:new FormControl([]),
      OnsetDate:new FormControl(''),
      DrugIdList: new FormControl([])
    });
    this.NotesDataform = new FormGroup({
      notes: new FormControl('', [Validators.required]),
      UserId: new FormControl(Number(localStorage.getItem('user_id'))),
      PatientId: new FormControl(this.EMRPatientInfo.PatientId),
      IsDeleting: new FormControl(0),
      ConsultantId: new FormControl(0),
      NoteType: new FormControl(false),
    });
    this.Hookform = new FormGroup({
      hookid: new FormControl(0),
      hookDesc: new FormControl('', [Validators.required]),
      patientId: new FormControl(this.EMRPatientInfo.PatientId),
      branchId: new FormControl(this.globals.HospitalId),
      IsDisplayed: new FormControl(true),
      IsDeleted: new FormControl(0)

    });

    // this.GetVisits();
    this.GetDrugsEMR();
    this.GetTodaysvitalSign();
    this.GetNarrativeDiagonosis();
    this.GetICDFOREMRHome();
    this.GetNotes();
    this.GetConfNotes();
    this.GetHook(true);
  }
  get f() {
    return this.NotesDataform.controls;
  }
  get fh() {
    return this.Hookform.controls;
  }

  //
  Scrolltotab(elem) {
    this.Selectedtab = elem;
  }
  // to get patient details
  getPatientInfo(PatientId: number) {
    if (PatientId > 0) {
      this.EMRService.GetPatientInfo(PatientId).subscribe((res) => {
        this.BasicPatientInfo = res.response[0];
        if (this.BasicPatientInfo.profilePicLocation) {
          this.PatientImage = this.BasicPatientInfo.profilePicLocation;
        }

      });
    }

  }
  // to get consultation details

  GetConsultation() {
    if (this.EMRPatientInfo.ConsultantId && this.EMRPatientInfo.PatientId) {
      let input = {
        "ConsultantId": this.EMRPatientInfo.ConsultantId,
        "PatientId": this.EMRPatientInfo.PatientId
      }
      this.EMRService.GetConsultation(input).subscribe((res) => {
        this.ConsultationList = res.response;
        if (this.ConsultationList[0]) {
          this.form.patchValue({
            ConsultationId: (this.ConsultationList[0]['consultationId'])
          });
        }

        if(this.ConsultationList.length==0){
          this.form.controls['ConsultationId'].setValue(0);


        }
        else{
         let exist=this.ConsultationList.filter(x => x.consultationId == this.SelectedConsultation)[0];
          if(!exist){
            this.form.controls['ConsultationId'].setValue(0);
          }
        }


      });

    }

  }
  //Save Notes

  SaveNotes() {

    if (this.EMRPatientInfo.PatientId > 0) {
      this.submitted = true;

      if (this.NotesDataform && this.NotesDataform.valid) {

        this.notes = this.NotesDataform.getRawValue();
        let date = new Date();
        if (this.noteId > 0) {
          this.notes.noteId = Number(this.noteId);
        }
        else {
          this.notes.noteId = Number(0);
        }
        this.notes.branchId = Number(this.BranchesId);
        this.notes.patientId = Number(this.EMRPatientInfo.PatientId);
        this.notes.entryDate = this.datepipe.transform(date, 'dd-MM-yyyy');
        this.notes.NoteType = this.notes.NoteType == true ? true : false;
        if(this.notes.NoteType == true){
          this.notes.ConsultantId = localStorage.getItem('user_role') == 'consultant' ? Number(localStorage.getItem('Id')) : 0
        } else{
          this.notes.ConsultantId = 0;
        }
        this.isLoading = true;
        this.EMRService.SaveNotes(this.notes).subscribe((res) => {
          this.submitted = false;
          if (res.status == 200 && res.message == "Success") {
            this.isLoading=false;
            this.toastr.success("Notes Details Saved Successfully...");
            this.GetNotes();
            this.GetConfNotes();
            this.display = false;
            this.noteId = 0;
            this.modalRefNotes?.hide();
            this.clearNotesForm();
          }
          else if (res.status == 200) {
            this.isLoading = false;
            this.toastr.error("Failure: " + res.message);
          }
          else {
            this.toastr.error("Some unexpected error occures! try again later.");
          }
        });
      }
    }
  };

  //save hook
  saveHook() {
    if (this.EMRPatientInfo.PatientId > 0) {
      this.submitted1 = true;
      if (this.Hookform && this.Hookform.valid) {
        let formdata = this.Hookform.getRawValue();
        let hId=this.hookid;
        if (hId > 0) {
          formdata.hookid = Number(hId);
        }
        else {
          formdata.hookid = Number(0);
        }

        formdata.patientId = Number(this.EMRPatientInfo.PatientId);
        this.isLoading = true;
        this.EMRService.SaveHook(formdata).subscribe((res) => {
          this.submitted1 = false;
          if (res.status == 200 && res.message == "Success") {
            this.isLoading=false;
            this.toastr.success("Hooks Details Saved Successfully...");
            this.GetHook(false);
            this.hookid = 0;
            this.display1 = false;
            this.clearHookForm();
            this.modalRefHook?.hide();

          }
          else if (res.status == 200) {
            this.isLoading = false;
            this.toastr.error("Failure: " + res.message);
          }
          else {
            this.toastr.error("Some unexpected error occures! try again later.");
          }
        });

      }
    }
  }

  // edit note
  editNote(dataText) {

    this.notesPopupHeading = "Edit Notes"
    this.display = true;
    this.isLoading=false;
    this.modalRef = this.modalService.show(
      this.modaltemp,
    );
    this.NotesDataform.patchValue(dataText);
  }
  editHook(data) {

    this.hooksPopupHeading = "Edit hooks"
    this.display1 = true;
    this.modalRef = this.modalService.show(
      this.modaltemp,
    );

    this.Hookform.patchValue(data);
  }


  closeModalNotes() {
    this.modalRefNotes?.hide();
    this.clearNotesForm();
  }

  closeModalHook() {
    this.modalRefHook?.hide();
    this.clearHookForm();
  }

  // detete note
  deleteNote(data, NotesListData) {
    if (this.EMRPatientInfo.PatientId > 0) {
      this.notes = this.NotesDataform.getRawValue();
      let date = new Date();
      this.notes.noteId = Number(data);
      this.notes.branchId = Number(this.BranchesId);
      this.notes.patientId = Number(this.EMRPatientInfo.PatientId);
      this.notes.entryDate = this.datepipe.transform(date, 'dd-MM-yyyy');
      this.notes.IsDeleted = Number(1);
      this.notes.ConsultantId = NotesListData?.ConsultantId !== null ? NotesListData?.ConsultantId : 0;
      this.notes.NoteType = NotesListData?.NoteType !== null ? NotesListData?.NoteType : 0;
      this.EMRService.SaveNotes(this.notes).subscribe((res) => {

        if (res.status == 200 && res.message == "Deleted Successfully") {
          this.toastr.success("Notes Deleted Successfully");
          this.clearNotesForm();
          this.GetNotes();
          this.GetConfNotes();
        }
        else {
          this.toastr.error("Some unexpected error occures! try again later.");
        }

      });
    }
  }
  deleteHook(data) {
    if (this.EMRPatientInfo.PatientId > 0) {
      let hooks = this.Hookform.getRawValue();
      hooks.hookid = Number(data);
      hooks.patientId = Number(this.EMRPatientInfo.PatientId);
      hooks.IsDeleted = Number(1);
      this.EMRService.SaveHook(hooks).subscribe(
        (res) => {

          if (res.status == 200 && res.message == "Success") {
            this.toastr.success("hook Deleted Successfully");
            this.clearHookForm();

            this.GetHook(false);
            this.hookid = 0;

          }
          else {
            this.toastr.error("Some unexpected error occures! try again later.");
          }

        });
    }
  }


  //get notes 
  GetNotes() {
    let noteId = Number(0);
    let branchId = Number(this.BranchesId);
    let patientId = Number(this.EMRPatientInfo.PatientId);
    let ConsultantId = 0;
    let NoteType = false;
    this.EMRService.getNotes(noteId, patientId, branchId, ConsultantId, NoteType).subscribe((res) => {
      this.NotesList = res.response;
    });
  }

  GetConfNotes() {
    let noteId = Number(0);
    let branchId = Number(this.BranchesId);
    let patientId = Number(this.EMRPatientInfo.PatientId);
    let ConsultantId = localStorage.getItem('user_role') == 'consultant' ? Number(localStorage.getItem('Id')) : 0
    let NoteType = true;
    this.EMRService.getNotes(noteId, patientId, branchId, ConsultantId, NoteType).subscribe((res) => {
      this.ConfNotesList = res.response;
    });
  }

  GetHook(isPageloading) {

    let input = {
      "patientId": this.EMRPatientInfo.PatientId,
      "branchId": this.globals.HospitalId,
      "hookid": this.hookid
    }
    this.EMRService.GetHook(input).subscribe((res) => {
      this.HooksDataList = res.response;

      if (!(this.HooksDataList.length == 0)&&( isPageloading==true)) {
        for(let i=0;i<this.HooksDataList.length;i++){

          this.toastr.info('Hook!',this.HooksDataList[i].hookDesc,{
            positionClass: 'toast-bottom-right',
            closeButton:true,
            progressBar:true,
          //  disableTimeOut:false,
            tapToDismiss:true
         });
        }
      }
    });
  }

  SaveAllergy() {
    this.submittedAllergy = true;

    if (this.EMRPatientInfo.PatientId > 0) {
      // debugger
      this.AllergyForm = this.AllergyDataform.getRawValue();
      let reacList = [];
      _.forEach(this.AllergyForm.ARIdList, (item: any)=>{
        reacList.push(item.arId);
      })

      let drugIds = []; 
      if(this.AllergyForm.DrugIdList && this.AllergyForm.DrugIdList?.length !== 0){
        _.forEach(this.AllergyForm.DrugIdList, (item: any)=>{
          drugIds.push(item.drugId);
        })
      }
      let Rdata
      if(typeof (this.AllergyForm?.ResolvedDate) == "string"){
        Rdata=this.AllergyForm?.ResolvedDate.replace(/\//g, "-");
      }else{
         Rdata=this.datepipe.transform(this.AllergyForm?.ResolvedDate, 'dd-MM-yyyy');
      }

      let onset;
      if(typeof (this.AllergyForm?.OnsetDate) == "string"){
        onset = this.AllergyForm?.OnsetDate.replace(/\//g, "-");
      }else{
        onset = this.datepipe.transform(this.AllergyForm?.OnsetDate, 'dd-MM-yyyy');
      }
      this.AllergyForm.AllergyType = Number(this.AllergyForm.AllergyType);
      this.AllergyForm.AllergySeverity = Number(this.AllergyForm.AllergySeverity);
      // this.AllergyForm.ARId = Number(this.AllergyForm.ARId);
      // this.AllergyForm.ARId =
      // console.log('this.AllergyForm.DrugIdList', this.AllergyForm.DrugIdList);
      this.AllergyForm.ARIdList = this.AllergyForm.ARIdList?.length > 0 ? reacList : [];
      this.AllergyForm.AllergenId = this.AllergyForm.AllergyType !== 2 ? Number(this.AllergyForm.AllergenId) : 0;
      this.AllergyForm.DrugId = this.AllergyForm.AllergyType == 2 ? Number(drugIds[0]) : 0;
      this.AllergyForm.ResolvedDate= this.AllergyForm.Status == 'R' ? Rdata ? Rdata : '' : ""
      this.AllergyForm.OnsetDate = this.AllergyForm.OnsetDate ? onset : ""
      if (this.AllergyForm.AllergyType == 0) {
        this.toastr.error("Please Choose Allergy Type");

      }
      else if (this.AllergyForm.AllergySeverity == 0) {
        this.toastr.error("Please Choose Allergy Severity");
      }
      else {
        this.allergySaveLoader=true
        this.EMRService.SaveAllergy(this.AllergyForm).subscribe((res) => {
          this.submittedAllergy = false;
          this.allergySaveLoader=false
          if (res.status == 200 && res.message == "Success") {
            this.toastr.success("Allergies Plan Details Saved Successfully...");
            this.modalRefAllergy.hide();

            this.GetAllergies();
            this.clearForm();
          }
          else if (res.status == 200) {
            this.toastr.error("Failure: " + res.message);
          }
          else {
            this.toastr.error("Some unexpected error occures! try again later.");
          }
        });
      }

    }
  }
  clearForm() {
    this.AllergyDataform.reset({
      AllergyType: 0,
      AllergySeverity: 0,
      AllergyDetails: '',
      IsDeleting: 0,
      Status: "",
      UserId: Number(localStorage.getItem('user_id')),
      PatientId: this.EMRPatientInfo.PatientId,
      Id:0,
      ResolvedDate:'',
      AllergenId:0,
      // ARId:0
      ARIdList:[],
      OnsetDate: ""
    });
    this.AllergyDataform.controls['AllergyType'].enable()
    this.submittedAllergy = false;
    this.addAllergy=true
  }
  clearNotesForm() {
    this.NotesDataform.reset({
      notes: '',
      IsDeleting: 0,
      UserId: Number(localStorage.getItem('user_id')),
      PatientId: Number(this.EMRPatientInfo.PatientId),
    })
  }

  clearHookForm() {
    this.Hookform.reset({
      hookid: 0,
      hookDesc: '',
      IsDeleted: 0,
      branchId: Number(this.globals.HospitalId),
      IsDisplayed: true,
      PatientId: Number(this.EMRPatientInfo.PatientId),
    })

  }
  // start consultation
  StartConsultation() {
    let conultationId = this.form.controls['ConsultationId'].value;

    let timeZoneData=this.sharedservice.timeZoneData

    let cDate = new Date();
    let startTime = this.datepipe.transform(cDate, 'dd-MM-yyyy hh:mm:ss a',timeZoneData);

    if (conultationId > 0) {

      //  Swal.fire({
      //     title: 'Are you sure?',
      //     text: 'To start the consultation',
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonText: 'Yes, Start it!',
      //     cancelButtonText: 'No'
      //   }).then((result) => {
      //     if (result.value) {
      this.EMRPatientInfo.ConsultationId = Number(conultationId);
      this.EMRPatientInfo.VisitType = 1;
      this.EMRPatientInfo.VisitStartTime = startTime;
      this.EMRPatientInfo.VisitEndTime="";
      let lastConsultationId = this.EMRPatientInfo.lastConsultationId
      let ConsultationId = this.EMRPatientInfo.ConsultationId
      this.EMRService.InsertVisit(this.EMRPatientInfo).subscribe((res) => {
        localStorage.setItem("emrPhyConsultationID", JSON.stringify( this.EMRPatientInfo.ConsultationId ));

        if (res.status == 200 && res.message == "Success") {
          let result = res.response;
          result = this.EMRService.upper(res.response);
          this.EMRPatientInfo = result;
          this.EMRPatientInfo.CrudType = 1;
          if(ConsultationId != lastConsultationId){
            this.EMRPatientInfo.VisitEndTime = '';
            this.EMRPatientInfo.lastConsultationId = lastConsultationId;
          }
          this.SelectedConsultation = Number(conultationId);
          this.EMRService.SetEMRPatientInfo(this.EMRPatientInfo);
          // console.log('EMRPatientInfo', this.EMRPatientInfo);
          this.EMRSponsorInfo = result;
          // console.log('sponsorInfo', this.EMRSponsorInfo);
          this.EMRService.SetEMRPatientSponsor(this.EMRSponsorInfo);
          // Swal.fire(
          //   'Done!',
          //   'Consultation started successfully.',
          //   'success'
          // )
          this.router.navigate(['/EMR_Consultation']);
        }
        else {
          this.toastr.warning("Error: " + res.message);
        }

      });
      //      }
      // })
    }

    else {
      this.toastr.warning("Error: " + "Please select a consulation to start");
    }
  }
  // end of start consultation

  //end consultation insertion
  EndConsultation(isGoback: boolean = false) {
    let conultationId = this.EMRPatientInfo.ConsultationId;
    if (conultationId > 0) {

      localStorage.setItem("emrPhyConsultationID", JSON.stringify( this.EMRPatientInfo.ConsultationId ));

      this.EMRPatientInfo.VisitStartTime = "";
      this.EMRPatientInfo.ConsultationId = Number(conultationId);
      this.EMRPatientInfo.VisitType = 1;
      let lastConsultationId = this.EMRPatientInfo.lastConsultationId
      let ConsultationId = this.EMRPatientInfo.ConsultationId
      this.EMRPatientInfo.CrudType = 1;
      let CDate = new Date();
      let Endtime = this.datepipe.transform(CDate, 'dd-MM-yyyy hh:mm:ss a');
      this.EMRPatientInfo.VisitEndTime = Endtime;

      this.EMRService.InsertVisit(this.EMRPatientInfo).subscribe((res) => {
        if (res.status == 200 && res.message == "Success") {
          this.sendDataToNabid()
          let result = res.response;
          result = this.EMRService.upper(res.response);
          this.EMRPatientInfo = result;
          if(ConsultationId != lastConsultationId){
            this.EMRPatientInfo.VisitEndTime = '';
          }
          this.EMRPatientInfo.ConsultationId = 0;
          this.EMRPatientInfo.VisitType = 0;
          this.EMRPatientInfo.VisitId = 0;
          this.EMRPatientInfo.CrudType = 0;
          this.SelectedConsultation = Number(conultationId);
          this.EMRService.SetEMRPatientInfo(this.EMRPatientInfo);
          this.IsButtonVisible = false;
          this.GetConsultation();
          this.GetVisits();
          if(isGoback){
            this._location.back();
          }
        }
        else {
          Swal.fire(
            'Error!',
            'Some unexpected error occures! try again later.',
            'error'
          )
        }

      });

    }

    else {
      this.toastr.warning("Error: " + "Please select a consulation to start");
    }
  }
  // end of  insert end consulation

  GetVisits() {

    //VisitHistory
    // this.EMRPatientInfo.ConsultantId
    this.EMRService.GetVisits(this.EMRPatientInfo.PatientId,this.ConsultantCanViewAllConsultation.settValue == 1 ? 0 : this.EMRPatientInfo.ConsultantId).subscribe((res) => {
      this.VisitHistory = res.response;
    });

  }
  GetAllergyType(HealthInsplatform) {
    let Input = {
      HealthInsplatform:HealthInsplatform
    }
    this.EMRService.GetAllergyType(Input).subscribe((res) => {
      this.allergyTypeList = res.response;
    });
  }

  GetAllergySeverity(HealthInsplatform) {
    let Input = {
      HealthInsplatform:HealthInsplatform,
      BranchId:this.globals.HospitalId
    }
    this.EMRService.GetAllergySeverity(Input).subscribe((res) => {
      this.AllergySeverityList = res.response;
    });

  }
  EditVisit(vistDetails) {
    if(this.userType!="U" && this.EMRPatientInfo.ConsultantId == vistDetails?.consultantId){
      this.EMRPatientInfo.ConsultationId = Number(vistDetails.consultationId);
      this.EMRPatientInfo.VisitType = 1;
      this.EMRPatientInfo.VisitId = 0;
      this.EMRPatientInfo.VisitStartTime = "";
      console.log(this.EMRPatientInfo)
      this.EMRPatientInfo.VisitEndTime = "";
      let lastConsultationId = this.EMRPatientInfo.lastConsultationId
      localStorage.setItem("emrPhyConsultationID", JSON.stringify( this.EMRPatientInfo.ConsultationId ));

      this.EMRService.InsertVisit(this.EMRPatientInfo).subscribe((res) => {

        if (res.status == 200 && res.message == "Success") {
          // let lastConsultationId =this.EMRPatientInfo.lastConsultationId;
          // if(vistDetails.consultationId === this.EMRPatientInfo.lastConsultationId) {
          //   this.EMRPatientInfo.VisitEndTime = this.EMRPatientInfo.VisitEndTime;
          //   } else {
          //   this.EMRPatientInfo.VisitEndTime = "";
          //   }
          let result = res.response;
          result = this.EMRService.upper(res.response);
          this.EMRPatientInfo = result;
          this.EMRPatientInfo.lastConsultationId = lastConsultationId
          this.EMRPatientInfo.CrudType =  2;
          // this.EMRPatientInfo.lastConsultationId = lastConsultationId
          this.SelectedConsultation = Number(vistDetails.consultationId);
          this.EMRService.SetEMRPatientInfo(this.EMRPatientInfo);
          // console.log('EMRPatientInfo', this.EMRPatientInfo);
          this.EMRSponsorInfo = result;
          // console.log('sponsorInfo', this.EMRSponsorInfo);
          this.EMRService.SetEMRPatientSponsor(this.EMRSponsorInfo);
          this.router.navigate(['/EMR_Consultation']);
        }

      });
    }else{
      this.toastr.warning('You are not allowed to edit this consultation..!');
    }
  }
  


  //modal open for searching patient details
  showBasicDialog() {
    this.modaltemp.open();

  }


  //common function added for filling patient details on clicking search patient modal
  searchevent(patient: any) {

    this.EMRPatientInfo = {
      'VisitId': 0,
      'ConsultantId': Number(localStorage.getItem('Id')),
      'ConsultationId': 0,
      'PatientId': patient.data.patientId,
      'VisitType': 0,
      'VisitStartTime': '',
      'VisitEndTime': '',
      'UserId': Number(localStorage.getItem('user_id')),
      'CrudType': 0,
      'InsAmountLimit': 0,
      insCovered: 0
    }
    let OutPut = this.EMRService.SetEMRPatientInfo(this.EMRPatientInfo);
    if (OutPut) {
      this.router.navigate(['/EMR_Home']);
      this.getPatientInfo(this.EMRPatientInfo.PatientId)
      this.GetVisits();
      this.GetConsultation();
      this.GetDrugsEMR();
      this.GetTodaysvitalSign();
      this.GetServiceItems();
      this.GetNarrativeDiagonosis();
      localStorage.setItem("physioVisible", JSON.stringify(0));
      localStorage.setItem("physiohistoryVisit", JSON.stringify(0));

    }
  }

  GetServiceItems() {
    let input = {
      // "VisitId": this.EMRPatientInfo.VisitId,
      "PatientId": this.EMRPatientInfo.PatientId,
      // "ShowAll": 1
    };
    this.ServiceItems = [];
    this.EMRService.GetAllServiceItemsForEMRHome(input).subscribe(
      (res) => {
        if (res.status == 200) {
          _.forEach(res.response, (order: any)=>{
            order.itemDetails = _.map(order.itemDetails, (item: any)=>{
                return {
                  ...item,
                  orderId: order?.orderId,
                  consultantName: order?.consultantName,
                  consultantId: order?.consultantId,
                  orderNo: order?.orderNo
                }
            })
          })
          this.ServiceItems = res.response;

          this.ServiceItems = res.response.map((icd: any) => {
            let flag = 0
            icd.itemDetails.find(x => {
              if(x.isFinished == 1){
                flag = 1
              }
            })
            return {
              ...icd,
              finishStatus: flag,
            };
          });
          // debugger
           console.log('this.ServiceItems', this.ServiceItems);
        }
      });
  }

  openModal(ItemDetails) {
    this.ModalServiceItems = ItemDetails;
    this.display = true;
    this.notesPopupHeading = "Add Notes";
    this.modalRef = this.modalService.show(
      this.modaltemp1,
      { class: 'modal-lg modal-custom-style' }
    );
  }

  GetDrugsEMR() {
    let input = {
      PatientId: this.EMRPatientInfo.PatientId,
      ConsultationId: Number(this.EMRPatientInfo.ConsultationId),
    };
    this.DrugResponse = [];
    this.EMRService.GetMedicationForEMRHome(input).subscribe((res) => {
      if (res.status == 200) {

        let response = res.response;
        if (response.length > 0) {
          this.DrugResponse = res.response;
        }
      }
    });
  }

  GetTodaysvitalSign() {
    let input = { "VisitId": this.EMRPatientInfo.VisitId, "PatientId": this.EMRPatientInfo.PatientId };
    this.TodaysvitalSignList = [];
    this.EMRService.GetAllEMRVitalSignByVisitId(input).subscribe(
      (res) => {
        if (res.status == 200) {
          let Response = res.response
          if (Response.length > 0) {
            this.TodaysvitalSignList = res.response[0].vitalSignData;
          }
        }
      });
  }
  GetAllergies() {
    let input = { "PatientId": this.EMRPatientInfo.PatientId };
    this.AllergyList = [];
    this.EMRService.GetAllergy(input).subscribe(
      (res) => {
        if (res.status == 200) {
          let Response = res.response
          if (Response.length > 0) {
            this.AllergyList = res.response;
            this.AllergyList = this.AllergyList.map(item => {
              // Extract arDesc from patARList if it exists
              const reactionList = item.patARList ? item.patARList.map(ar => ar.arDesc) : [];
            
              // Return a new object with all original data and the new reactionList key
              return {
                ...item,
                reactionList: reactionList
              };
            });
          }
        }
      });
  }
  GetAlert() {
    let input = {
       "PatientId": this.EMRPatientInfo.PatientId,
       "BranchId": this.BranchesId,
       "AlertId": 0,
       "AlertType": '',
       };
    this.AlertList = [];
    this.EMRService.getAlertList(input).subscribe(
      (res:any) => {
        if (res.status == 200) {
          let date = new Date()
          // let date1 = this.datepipe.transform(date, 'dd-MM-yyyy');
          let Response = res.response
          if (Response.length > 0) {
            this.AlertList = res.response;
            this.AlertList.map((res:any)=>{
              if(res.isDisplayed){
                let date1 = moment(res.validTo).isSame(date);
                let date2 = moment(res.validTo).isBefore(date);
                if(date2){
                  res.validDate = true;
                } else {
                  res.validDate = false
                }
              }
            })
          }
        }
      });
  }

  GetNarrativeDiagonosis() {
    let input = {
      "VisitId": this.EMRPatientInfo.VisitId,
      "PatientId": this.EMRPatientInfo.PatientId,
      "ShowAll": 1
    };
    this.SelectedIcdList = [];
    this.EMRService.GetNarrativeDiagonosis(input).subscribe(
      (res) => {
        if (res.status == 200) {
          let response = res.response[0];
          this.form.patchValue(response);
          if (response) {
            this.SelectedIcdList = response.icdLabelList;
          }
        }
      });
  }

  GetICDFOREMRHome() {
    let payload = {
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 1
    };
    this.EMRService.GetICDFOREMRHome(payload).subscribe(
      (res) => {
        if (res.status == 200) {
          this.visitwiseIcdList = res.response;
        }
      });
  }


  openModalConsultationTime(vistDetails) {

    this.UpdateVistId = vistDetails.visitId;

    let VisitStarttime = vistDetails.visitStartTime.split(",")[1];
    VisitStarttime = String(VisitStarttime.trim());

    this.ConsultationTime.controls['StartTime'].setValue(VisitStarttime);

    this.StartDate = vistDetails.visitStartTime.split(",")[0];
    if (vistDetails.visitEndTime !== null) {
      let VisitEndtime = vistDetails.visitEndTime.split(",")[1];
      this.EndDate = vistDetails.visitEndTime.split(",")[0];
      VisitEndtime = String(VisitEndtime.trim());
      this.ConsultationTime.controls['EndTime'].setValue(VisitEndtime);
    }

    this.modalRef = this.modalService.show(
      this.modaltemp2);

  }
  saveAlert(){
    if(this.alertForm.get("StartTime").value === ""){
      this.toastr.error("Please Choose Start Date");
    } else if(this.alertForm.get("EndTime").value === ""){
      this.toastr.error("Please Choose End Date");
    } else {
      let FirstDate;
      let SecondDate
      if(this.firstDate === this.alertForm.get("StartTime").value) {
        FirstDate = this.alertForm.get("StartTime").value
        FirstDate = FirstDate.replaceAll("/", "-");
      } else {
        FirstDate = this.datepipe.transform(this.alertForm.get("StartTime").value, 'dd-MM-yyyy');
      }
      if(this.secondDate === this.alertForm.get("EndTime").value) {
        SecondDate = this.alertForm.get("EndTime").value
        SecondDate = SecondDate.replaceAll("/", "-");
      } else {
        SecondDate = this.datepipe.transform(this.alertForm.get("EndTime").value, 'dd-MM-yyyy');
      }
      let data= {
        ValidFrom: FirstDate,
        ValidTo:SecondDate,
        AlertNote:this.alertForm.get("Details").value !== null ? this.alertForm.get("Details").value : '',
        AlertId:this.isEdit ? this.alertId : 0,
        PatientId:this.EMRPatientInfo.PatientId,
        AlertType: this.alertForm.get("AlertType").value !== null  ? this.alertForm.get("AlertType").value : '',
        UserId:Number(localStorage.getItem('user_id')),
        BranchId:Number(this.globals.HospitalId),
        IsDeleted:0,
        IsDisplayed:this.alertForm.get("IsActive").value ? this.alertForm.get("IsActive").value : false
      }
      this.EMRService.addAlert(data).subscribe((res:any)=>{
        if(res.status === 200 && res.message == "Success"){
          this.toastr.success("Success : Alert is added Successfully.");
          this.alertForm.reset();
          this.modalRefAlert.hide();
          this.GetAlert()
        } else {
          this.toastr.error("Failed : " + res.message);
        }
      },(err:any)=>{
        this.toastr.error("Failed : " + err?.error?.message);
      })
    }
  }
  onMnthChange(i:any){
    let date1;
    let firstDate =  this.alertForm.get("StartTime").value;
    if(i == '0') {
      date1= moment(firstDate).add(20, 'years').toDate(); 
      this.secondDate = this.datepipe.transform(date1, 'dd-MM-yyyy');
      this.alertForm.get("EndTime").setValue(this.secondDate)
    } else {
      if(this.isEdit){
        let [day, month, year] = firstDate.split('/')
        const dateObj = new Date(+year, +month - 1, +day)
        date1= moment(dateObj).add(i, 'months').toDate(); 
      } else {
        date1= moment(firstDate).add(i, 'months').toDate();
      }
      this.secondDate = this.datepipe.transform(date1, 'dd-MM-yyyy');
      this.alertForm.get("EndTime").setValue(this.secondDate)
    }
  }
  closeAlert(){
    this.modalRefAlert?.hide();
    this.alertForm.reset()
  }
  datePicker(i:any){
   
  }
  UpdateConsultationTime() {

    let starttime = this.ConsultationTime.get("StartTime").value;
    this.valueDate = this.StartDate.replaceAll("/", "-");
    let Endtime = this.ConsultationTime.get("EndTime").value;

    this.EMRPatientInfo.VisitType = 1;
    let starttimeDate = this.valueDate + ' ' + starttime;
    this.EndtimeDate = this.valueDate + ' ' + Endtime;

    localStorage.setItem("emrPhyConsultationID", JSON.stringify( this.EMRPatientInfo.ConsultationId ));

    var Input = {
      "VisitId": this.UpdateVistId,
      "ConsultantId": this.EMRPatientInfo.ConsultantId,
      "ConsultationId": this.SelectedConsultation,
      "PatientId": this.EMRPatientInfo.PatientId,
      "VisitType": this.EMRPatientInfo.VisitType,
      "UserId": Number(localStorage.getItem('user_id')),
      "VisitStartTime": starttimeDate,
      "VisitEndTime": this.EndtimeDate,
      "CrudType": 0,
      'InsAmountLimit': this.EMRPatientInfo.InsAmountLimit,
      insCovered: this.EMRPatientInfo.insCovered
    }
    this.EMRService.InsertVisit(Input).subscribe((res) => {

      if (res.status == 200 ) {
        let result = res.response;
        result = this.EMRService.upper(res.response);
        this.EMRPatientInfo = result;
        this.toastr.success("Success: " + "consultation time Updated Successfully...");
        this.GetVisits();
        this.modalRef.hide();
      }


    });
  }
  Isview(vistDetails) {
    localStorage.setItem("emrPhyConsultationID", JSON.stringify( this.EMRPatientInfo.ConsultationId ));

    this.EMRPatientInfo.ConsultationId = Number(vistDetails.consultationId);
    this.EMRPatientInfo.VisitType = 1;
    this.EMRPatientInfo.VisitId = 0;
    this.EMRPatientInfo.VisitStartTime = "";
    this.EMRPatientInfo.VisitEndTime = "";
    this.EMRPatientInfo.CrudType = 3;
    this.EMRService.InsertVisit(this.EMRPatientInfo).subscribe((res) => {

      if (res.status == 200 && res.message == "Success") {
        let result = res.response;
        result = this.EMRService.upper(res.response);
        this.EMRPatientInfo = result;
        this.EMRPatientInfo.CrudType = 3;
        this.SelectedConsultation = Number(vistDetails.consultationId);
        this.EMRService.SetEMRPatientInfo(this.EMRPatientInfo);
        this.router.navigate(['/EMR_Consultation']);
      }

    });

  }
  openModalAllergy(template: TemplateRef<any>) {
    this.addAllergy=true
    this.modalRefAllergy = this.modalService.show(template, { class: 'modal-lg modal-custom-style' });
  }
  openModalAlert(template: TemplateRef<any>,i:any) {
    // this.popupshow = true;
    // this.popupData = i
    // let initialState = {
    //   data: i,
    //   type:'GEN'
    // }

    // let config: ModalOptions = {
    //   backdrop: true,
    //   ignoreBackdropClick: true,
    // }
    // this.modalRef= this.modalService.show(PopupAlertComponent,Object.assign( {}, config, { class: 'modal-xl', initialState }) );
    // this.modalRef.content.fileEmitter.subscribe((response: any)=>{
    //   if(response.status == true){
    //     // this.toastr.success("The signature updated successfully");
    //   }
    // })
    if(i?.alertId){
      this.isEdit = true;
      this.alertId = i.alertId
      this.firstDate = i.validFrom
      this.secondDate = i.validTo
      this.alertForm.get('StartTime').setValue(i.validFrom)
      this.alertForm.get('EndTime').setValue(i.validTo)
      this.alertForm.get('month').setValue(0)
      this.alertForm.get('AlertType').setValue(i.alertType)
      this.alertForm.get('Details').setValue(i.alertNote)
      this.alertForm.get('IsActive').setValue(i.isDisplayed)
    } else {
      this.isEdit = false;
      let date = new Date()
      let date1= moment(date).add(20, 'years').toDate();
      this.alertForm.get('StartTime').setValue(date)
      this.alertForm.get('EndTime').setValue(date1)
      this.alertForm.get('month').setValue(0)
      this.alertForm.get('IsActive').setValue(true)
    }
    this.modalRefAlert = this.modalService.show(template, { class: 'modal-lg modal-custom-style' });
  }

  goback() {
    if(this.EMRPatientInfo.VisitId > 0 && this.EMRPatientInfo.CrudType != 0){
      let content = '';
      if(this.EMRPatientInfo.CrudType == 1){
        content = '<p>Do you want to save and finish this consultation..?</p>'
      }else if(this.EMRPatientInfo.CrudType == 2){
        content = '<p>Do you want to save and finish updating this consultation..?</p>'
      }else if(this.EMRPatientInfo.CrudType == 1){
        content = '<p>Do you want to save and end view this consultation..?</p>'
      }else{
        content = '';
      }

      let initialState = {
        heading: 'Confirm',
        content: content,
        yesButton: 'Save & Finish,Send data to '+this.HealthInformationPlatform.settValue,
        noButton: 'Save and Go back'
      }
      let modalConfig: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-dialog-scrollable '
      }
      const modalRef= this.modalService.show(ConfirmModalComponent,Object.assign({class: "modal-dialog-centered "}, modalConfig, { class: 'modal-md', initialState }) );
      modalRef.content.confirmEmitter.subscribe((res)=>{
        if(res.status){
          this.EndConsultation(true);
          // here SendDetailstoNabith
          // this.sendDataToNabid()

        }else{
          this._location.back();
        }
      })
    }else{
      this._location.back();
    }
  }
  followup(vistDetails) {
    this.EMRPatientInfo.ConsultationId = Number(vistDetails.consultationId);
    this.EMRPatientInfo.VisitType = 0;
    this.EMRPatientInfo.VisitId = 0;
    this.EMRPatientInfo.VisitStartTime = "";
    this.EMRPatientInfo.VisitEndTime = "";
    this.EMRService.InsertVisit(this.EMRPatientInfo).subscribe((res) => {

      if (res.status == 200 && res.message == "Success") {
        let result = res.response;
        result = this.EMRService.upper(res.response);
        this.EMRPatientInfo = result;
        this.EMRPatientInfo.CrudType = 0;
        this.SelectedConsultation = Number(vistDetails.consultationId);
        this.EMRService.SetEMRPatientInfo(this.EMRPatientInfo);
        this.router.navigate(['/EMR_followup']);
      }

    });

  }

  openModalNotes(template: TemplateRef<any>) {
    this.modalRefNotes = this.modalService.show(template);
    this.ResetFunction();
  }
  openModalNotesEdit(template: TemplateRef<any>, idData, notesData, type) {
    this.noteId = Number(idData);
    this.notesPopupHeading="Manage Notes";
    this.modalRefNotes = this.modalService.show(template);
    this.NotesDataform.patchValue({ notes: notesData });
    this.NotesDataform.patchValue({ NoteType: type == true ? true : false });
    // this.ResetFunction();

  }
  openModalHook(template: TemplateRef<any>) {
    this.modalRefHook = this.modalService.show(template);
    this.ResetFunction();
  }
  openModalHookEdit(template: TemplateRef<any>, hookIdData, hookDescData) {

    this.hookid = hookIdData;
    this.hooksPopupHeading="Edit Hook";
    this.modalRefHook = this.modalService.show(template);
    this.Hookform.patchValue({ hookDesc: hookDescData });

  }
  printRefferLetter() {
    let initialState = {
      modalHeading: 'Referral Letter',
      reportName: 'ReferralLetter.trdp',
      reportParams: {
        branchId: this.globals.HospitalId,
        ConsultationId : this.EMRPatientInfo.ConsultationId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))

  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  ResetFunction() {
    this.noteId = 0;
    this.hookid = 0;
    this.submitted = false;
    this.submitted1 = false;
    this.clearNotesForm();
    this.clearHookForm();
  }

  GetAllResultPublishedServiceItems() {
    let input = {
      "PatientId": this.EMRPatientInfo.PatientId,
    };
    this.ServiceItems = [];
    this.EMRService.GetAllResultPublishedServiceItems(input).subscribe(
      (res) => {
        if (res.status == 200) {
          this.resultServiceItems = res.response;
        }
      });
  }

  gotoVaccineChart(){
    let prevUrl = '/EMR_Home';
    localStorage.setItem("vaccEmrBack", prevUrl);
    this.router.navigate(['/vaccination'], { state: { pUrl: prevUrl } });
  }
  visitEditHistory(i :any){
  console.log(i)
    this.visitEditHis = i
  }
  EditConsultationHistory(){
    let params:any;
    if(this.visitEditHis?.consultationId){
      params = {
        ConsultationId : Number(this.visitEditHis.consultationId),
      };
    } else {
      params = {
        ConsultationId : Number(this.VisitHistory[0].consultantId),
      };
    }
    console.log(this.visitEditHis,params,this.EMRPatientInfo)
    // if(this.userType!="U" && this.EMRPatientInfo.ConsultantId == this.visitEditHis?.consultantId){
      this.EMRPatientInfo.ConsultationId = this.visitEditHis?.consultationId ? Number(this.visitEditHis.consultationId) : Number(this.VisitHistory[0].consultationId);
      this.EMRPatientInfo.VisitType = 1;
      this.EMRPatientInfo.VisitId = 0;
      this.EMRPatientInfo.VisitStartTime = "";
      this.EMRPatientInfo.VisitEndTime = "";
      localStorage.setItem("emrPhyConsultationID", JSON.stringify( params.ConsultationId ));

      this.EMRService.InsertVisit(this.EMRPatientInfo).subscribe((res) => {

        if (res.status == 200 && res.message == "Success") {
          let result = res.response;
          result = this.EMRService.upper(res.response);
          this.EMRPatientInfo = result;
          this.EMRPatientInfo.CrudType =  2;
          this.SelectedConsultation = Number(this.visitEditHis?.consultationId);
          this.EMRService.SetEMRPatientInfo(this.EMRPatientInfo);
          console.log('EMRPatientInfo', this.EMRPatientInfo);
          this.EMRSponsorInfo = result;
          console.log('sponsorInfo', this.EMRSponsorInfo);
          this.EMRService.SetEMRPatientSponsor(this.EMRSponsorInfo);
          this.router.navigate(['/EMR_Consultation']);
        }

      });
    // }else{
    //   this.toastr.warning('You are not allowed to edit this consultation..!');
    // }
  }

  onTabClick(tabId: number){
    this.activeTabId = tabId;
  }

  printAsoap() {
    let asoapPatInfo = JSON.parse(localStorage.getItem('asoapPatInfo'));
    if (asoapPatInfo.SponsorId !== 0) {
      let initialState = {
        consultationId: asoapPatInfo.ConsultationId,
        branchId: this.BranchesId,
        sponsorId: asoapPatInfo.SponsorId,
        telerikName:asoapPatInfo.telerikName
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(PrintAsoapComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
    } else{
      this.toastr.warning('No sponsor was found for this consultation');
    }
  }

  getApplicationSettings() {
    let payload= {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }
    this.patientService.getApplicationSettings(payload).subscribe((res) => {
      let configurationSettings = _.filter(res.response, {tabId: 1});
      this.ConsultantCanViewAllConsultation = _.filter(configurationSettings, {displayName: 'ConsultantCanViewAllConsultation'})[0];
      let HealthInformationPlatform=_.find(res.response,{displayName:"HealthInformationPlatform"})
      this.HealthInformationPlatform=HealthInformationPlatform
      this.GetAllergyType(HealthInformationPlatform.settValue);
      this.GetAllergySeverity(HealthInformationPlatform.settValue);
      // this.GetAllergen()
      this.GetAllergenReaction()
      this.GetVisits()
    })
  }

  async openEditAllergies(alergydata:any,template: TemplateRef<any>){
    this.addAllergy=false
    // console.log('alergydata', alergydata);
    if(alergydata.allergyType && Number(alergydata.allergyType) !== 2){
      await this.GetAllergen(alergydata.allergyType);
      this.AllergyDataform.patchValue({
        AllergenId:alergydata.allergenId
      })
    }else if(Number(alergydata.allergyType) == 2){
      this.SearchDrugForAllergy();
      this.AllergyDataform.patchValue({
        DrugIdList: alergydata.allergenDrugList
      })
    }
    this.AllergyDataform.patchValue({
      AllergyType: alergydata.allergyType,
      AllergySeverity: alergydata.allergySeverity,
      AllergyDetails: alergydata.allergyDetails,
      IsDeleting: 0,
      Status: alergydata.status,
      UserId: Number(localStorage.getItem('user_id')),
      PatientId: this.EMRPatientInfo.PatientId,
      Id:alergydata.id,
      ResolvedDate:alergydata.resolvedDate == "01/01/1900" ? "" : alergydata.resolvedDate,
      // AllergenId:alergydata.allergenId,
      // ARId:alergydata.arId,
      ARIdList:alergydata.patARList,
      OnsetDate: alergydata.onsetDate == "01/01/1900" ? "" : alergydata.onsetDate
    });
    this.AllergyDataform.controls['AllergyType'].disable()
    this.modalRefAllergy = this.modalService.show(template, { class: 'modal-lg modal-custom-style' });
  }

  deleteAllergies(alergydata){
    let reacList = [];

    if (alergydata.patARList?.length > 0) {
      _.forEach(alergydata.patARList, (item: any) => {
        reacList.push(item.arId);
      })
    }

    let onset;
    if(typeof (alergydata?.onsetDate) == "string"){
      onset = alergydata?.onsetDate.replace(/\//g, "-");
    }else{
      onset = this.datepipe.transform(alergydata?.onsetDate, 'dd-MM-yyyy');
    }
    let paylod={
      AllergyType: alergydata.allergyType,
      AllergySeverity: alergydata.allergySeverity,
      AllergyDetails: alergydata.allergyDetails,
      IsDeleting: 1,
      Status: alergydata.status ,
      UserId: Number(localStorage.getItem('user_id')),
      PatientId: this.EMRPatientInfo.PatientId,
      allergyTypeName:'',
      Id:alergydata.id,
      ResolvedDate:'',
      AllergenId:alergydata.allergenId,
      // ARId:alergydata.arId,
      ARIdList: alergydata.patARList?.length > 0 ? reacList : [],
      OnsetDate: alergydata?.onsetDate ? onset : "",
      DrugId: 0,
      DrugIdList: []
    }
    this.EMRService.SaveAllergy(paylod).subscribe((res) => {
      this.submittedAllergy = false;
      if (res.status == 200 ) {
        this.toastr.success("Success"+res.message);
        this.GetAllergies();
      }
      else {
        this.toastr.error("Some unexpected error occures! try again later.");
      }
    });
  }

  functionPermission() {
    let input = {
      // MenuId: Number(localStorage.getItem('selectedMenu')),
      MenuId: 140,
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      // console.log('functionPermissionData', this.functionPermissionData);
    });
  }

  lockCForm(data) {

    const foundElement = this.functionPermissionData.find(x => x.id === 536)
    if (foundElement.id == 536 && foundElement.isAllowed == true) {
      let initialState = {
        Id: data.visitId,
        IsCformLock: data.cformLock,
        TypeData: "emr"
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(CFormLockComponent, Object.assign({}, config, { class: 'modal-md', initialState }));
      modalRef.content.cFormEmitter.subscribe(res => {
        if (res.status) {
          // this.getPriorRequestList()
          this.GetVisits();
        }
      })
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  sendDataToNabid() {
    const consultationId = this.EMRPatientInfo.ConsultationId;
    const patientId = this.EMRPatientInfo.PatientId;
    const currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
  
    const allergyPermission = this.functionPermissionData.find(x => x.id === 564);
    const vitalPermission = this.functionPermissionData.find(x => x.id === 561);
    const chronicIcdPermission = this.functionPermissionData.find(x => x.id === 569);
  
    // Check if the platform is 'Nabidh' and permissions are allowed
    if (this.HealthInformationPlatform.settValue === "Nabidh") {
  
      // Send allergy data if permission is allowed and there are allergies
      if (allergyPermission?.isAllowed && this.AllergyList?.length > 0) {
        const nabPayload = {
          EventId: 6,
          Consultationid: consultationId,
          EntryDate: currentDate,
          PatientId: patientId,
          BranchId : this.globals.HospitalId
        };
        this.sendToNabidh(nabPayload);
      }else if(allergyPermission?.isAllowed == false){
        this.toastr.warning('No permission to send allergy data to nabith');
      }
  
      // Fetch and send vital sign data if permission is allowed
      if (vitalPermission?.isAllowed) {
        const input = { VisitId: this.EMRPatientInfo.VisitId, PatientId: patientId };
        this.EMRService.GetAllEMRVitalSignByVisitId(input).subscribe(res => {
          if (res.status === 200 && res.response?.length > 0) {
            const nabPayload = {
              EventId: 3,
              Consultationid: consultationId,
              EntryDate: currentDate,
              PatientId: patientId,
              BranchId : this.globals.HospitalId
            };
            this.sendToNabidh(nabPayload);
          }
        });
      }else{
        this.toastr.warning('No permission to send vital data to nabith');
      }

      // send icd data if there is any chronic icd and permission is allowed
      if (chronicIcdPermission?.isAllowed) {
        let inputPayload = {
          VisitId: this.EMRPatientInfo.VisitId,
          PatientId: patientId,
          ShowAll: 1,
          ConsultationId: consultationId,
        };

        this.EMRService.GetNarrativeDiagonosis(inputPayload).subscribe((res) => {
          if (res.status == 200) {
            let response = res.response[0];
            if (res.status === 200 && res.response?.length > 0) {
              if (response?.icdLabelList) {
                let chronicIcd = response.icdLabelList.filter(item => (item.isChronic == true))
                let chronicLen = chronicIcd?.length ? chronicIcd?.length : 0
                if(chronicLen !== 0){
                  const nabPayload = {
                    EventId: 14,
                    Consultationid: consultationId,
                    EntryDate: currentDate,
                    PatientId: patientId,
                    BranchId : this.globals.HospitalId
                  };
                  this.sendToNabidh(nabPayload);
                }
              }
            }
          }
        });
      }else{
        this.toastr.warning('No permission to send icd data to nabith');
      }
    }
  }
  
  private sendToNabidh(payload: any) {
    this.patientService.SendDetailstoNabith(payload).subscribe({
      next: (res: any) => {
        this.toastr.info('Response from Nabidh: ' + res);
      }
    });
  }

  getAllergenType(allergyTypeId){
    if(Number(allergyTypeId) == 2){
      this.SearchDrugForAllergy();
    }else{
      this.GetAllergen(allergyTypeId);
    }
  }
  
  async GetAllergen(allergyTypeId){
    let payload={
      BranchId: this.globals.HospitalId,
      HealthInsplatform:this.HealthInformationPlatform.settValue,
      AllergyTypeId: Number(allergyTypeId)
    }
    
    let response = await this.EMRService.GetAllergen(payload).toPromise();
    // this.EMRService.GetAllergen(payload).subscribe({
    //   next:(res)=>{
        this.allergenTypeList=response.response;
    //     console.log('res.response', res.response);
    //   }
    // })
  }

  GetAllergenReaction(){
    let payload={
      BranchId: this.globals.HospitalId,
      HealthInsplatform:this.HealthInformationPlatform.settValue  
    }
    this.EMRService.GetAllergenReaction(payload).subscribe({
      next:(res)=>{
        this.allergenReactionList=res.response
      }
    })
  }

  SearchDrugForAllergy(){
    let payload={
      BranchId: this.globals.HospitalId,
      DrugId: 0
    }
    this.EMRService.SearchDrugForAllergy(payload).subscribe({
      next:(res)=>{
        this.drugAllergenList = res.response;
      }
    })
  }
}
