import { ANALYZE_FOR_ENTRY_COMPONENTS, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PatientList } from '../../interfaces/PatientList';
import { PatientService } from '../../services/patient.service';
import { Router } from '@angular/router';
import { Departments } from '../../interfaces/Departments';
import { Consultants } from '../../interfaces/Consultants';
import { HospitalsService } from '../../services/hospitals.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../general-action/general-action.component';
import { DatePipe } from '@angular/common';
import { RegDocLocation } from '../../interfaces/RegDocLocation';
import SignaturePad from 'signature_pad';
import { regIdentities } from '../../interfaces/regIdentities';
import { ConsentHistory } from '../../interfaces/ConsentHistory';
import { GuiCellView, GuiColumn, GuiPaging, GuiPagingDisplay, GuiSearching, GuiSorting, GuiColumnMenu, GuiInfoPanel, GuiDataType } from '@generic-ui/ngx-grid';
import { CommonValidationComponent } from '../../../../shared/common-validation/common-validation.component';
import { Select2OptionData } from 'ng-select2';
import { SharedService } from '../../../../shared/shared.service';
import { WebcamImage } from 'ngx-webcam';
import { HttpClient } from '@angular/common/http';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConsultantDeskService } from '../../services/consultant.service';
import { EMRPatientInfo } from '../../../emr/interfaces/EMRPatientInfo';
import { EMRService } from '../../../emr/services/emr.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SponsorConsentComponent } from '../modals/sponsor-consent/sponsor-consent.component';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { PatientQuestionnaireModalComponent } from '../modals/patient-questionnaire-modal/patient-questionnaire-modal.component';
import { PrintBarcodeModalComponent } from '../modals/print-barcode-modal/print-barcode-modal.component';
import { Response } from '../../../../_Models/Response';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';
import { HealthSummaryModalComponent } from '../../../treatment/modals/health-summary-modal/health-summary-modal.component';
import { ConfigurationService } from '../../services/configuration.service';
import { TelerikPrintComponent } from '../../../../shared/modals/telerik-print/telerik-print.component';
import { SettingsService } from '../../../settings/services/settings.service';
import { Settings } from '../../../settings/model/settings.model';
import { StaticPatientConsentComponent } from '../modals/static-patient-consent/static-patient-consent.component';
import { NabithConsentComponent } from '../addregistration/nabith-consent/nabith-consent.component';
import { InformedStaticConsentComponent } from '../modals/informed-static-consent/informed-static-consent.component';
import { RiayatiConsentComponent } from '../addregistration/riayati-consent/riayati-consent.component';
import { MergePatientModalComponent } from '../../../integrations/modals/merge-patient-modal/merge-patient-modal.component';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']

})
export class RegistrationComponent implements OnInit {
  signaturePad: SignaturePad;
  signaturePad1: SignaturePad;
  signaturePad2: SignaturePad;

  @ViewChild('canvas') canvasEl: ElementRef;
  // @ViewChild('canvas1') canvasE2: ElementRef;
  // @ViewChild('canvas') canvasE3: ElementRef;

  signatureImg: string;
  @ViewChild('patientCard') patientCard: ElementRef;
  // signatureImg: string;
  patientHeading = "Patinet Consent";
  userImg:any
  date1: Date;
  opened = false;
  products: any = {};
  items: any = {};
  items1: any = {};
  update: any = {};
  delete: any = {};
  displayBasic: boolean;
  displayBasicUser: boolean;
  displaydialpad: boolean;
  patientResult: any;
  patients: PatientList[] = [];
  searchForm: FormGroup;
  userType: any;
  emraccess: boolean;
  editdata: any = {};
  blockorunblock: boolean;
  confirmblock: boolean;
  isCollapsed = true;
  dtOptions: any = {};
  info: string;
  public documentFile: File[] = null;
  public documentFile1: File = null;
  user_role: any;
  user_id: any;
  router_url = this.router.url;
  links: any[] = ["link1.com", "link2.com", "link3.com"];
  mailText: string = "";
  uploadedFiles: any[] = [];
  departments: any;
  departmentsData: Array<Select2OptionData> = [];
  consultants: Consultants[] = [];
  consultantsData: Array<Select2OptionData> = [];
  patientmodal: any;
  cTypemodal:string;
  gender: any;
  patientName: any;
  address: any;
  mobile: any;
  regDate: any;
  regNo: any;
  patientId: any;
  active: string;
  blockId: number;
  blockResult: any;
  attachdocuments: boolean;
  historyconsent: boolean;
  historyInformconsent: boolean;
  historySponsorconsent: boolean;

  public rowClassRules;
  rowData: any[] = [];
  bsValue = new Date();
  bsRangeValue: Date[];
  IsConsentShow: boolean;
  IsConsentShowing: boolean;
  patientInfo: EMRPatientInfo;
  Sponsors:any[]=[];
  sponsorId:any;
  cType:string;
  // minDate: Date;

  //ag grid setup

  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public validator = new CommonValidationComponent();
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.active == '0'; },
    },
  }

  public uploadClera;
  public getRowStyle;
  public statusBar;

  columnDefs: ColDef[] = [];
  paging: GuiPaging = {
    enabled: true,
    page: 1,
    pageSize: 10,
    pageSizes: [10, 25, 50],
    pagerTop: false,
    pagerBottom: true,
    display: GuiPagingDisplay.ADVANCED
  };

  sorting: GuiSorting = {
    enabled: true
  };

  searching: GuiSearching = {
    enabled: true,
    placeholder: 'Search Here'
  };
  GuiColumnMenu = {
    enabled: true,
    columnsManager: true
  };

  infoPanel: GuiInfoPanel = {
    enabled: true,
    infoDialog: false,
    columnsManager: true,

  };

  rowGroupPanelShow = "always";
  pId:number;
  age: any;
  email: any;
  valueDate: any;
  valueDate2: any;
  regDocLocation: RegDocLocation[] = [];
  documentList: RegDocLocation[] = [];
  regDetails: PatientList[];
  regIdentities: regIdentities[] = [];
  ConsentHistory: ConsentHistory[];
  printmodal: boolean;
  consentHistoryList: string;
  consentHistoryPatientName: any;
  consentHistoryDate:any;
  consentHistoryListArabic: any;
  minDate: Date;
  displayBasicdragdrop: boolean;
  public showWebcam = false;
  myObservable$: Subject<unknown>;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  generatedImage: string;
  result: any;
  consentHistorySignature: any;
  consentDate: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  consultantDataDetails: any;
  filepath: any;
  fileName: any;
  documentFile2: any;
  ConsentPatientId: any;
  consentForm:FormGroup;
  fileUpload:any;
  patientData: any;
  consentHistoryListTemp: string;
  SelectedSponsorId: number;
  // ag grid setup ends

  private baseUrl: string = '';
  attachDoc: FormGroup;
  attachmentTypeList = [];
  functionPermissionData: any;
  public consentSettings: Settings[] = [];
  modalRef: BsModalRef;
  healthPlatform: any;

  constructor(private patientService: PatientService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private datepipe: DatePipe,
    private hospitalsService: HospitalsService,
    private http: HttpClient,
    private sharedservice: SharedService,
    private ConsultantDeskService: ConsultantDeskService,
    private EMRService: EMRService,
    private modalService:BsModalService,
    private configurationManager: ConfigurationManager,
    private ConfigurationService: ConfigurationService,
    private settingsService: SettingsService,
  ) {


    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId

      }
    });



    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    this.columnDefs = [
      {
        headerName: "Reg. No.",
        minWidth: 150,
        field: "regNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        onCellClicked: this.showuserDialog.bind(this),
        cellRenderer: params => {
          if (params.data.gender == 1) {
            return " <img  src='assets/images/xs/malegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" + '&nbsp' + '&nbsp' + params.value;
          } else if (params.data.gender == 2) {
            return " <img  src='assets/images/xs/femalegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" + '&nbsp' + '&nbsp' + params.value;
          } else {
            return " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" + '&nbsp' + '&nbsp' + params.value;
          }

        },

      },

      {
        headerName: "Reg.Date",
        field: 'regDate',
        minWidth: 100,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Patient Name",
        field: 'patientName',
        minWidth: 150,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },
      {
        headerName: "Age",
        field: 'age',
        minWidth: 80,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Mobile",
        minWidth: 130,
        field: 'mobile',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Address",
        minWidth: 140,
        field: 'address',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },
      {
        headerName: "Insurance",
        minWidth: 100,
        field: 'sponsorName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },
      {
        headerName: "Consultant",
        minWidth: 130,
        field: 'consultant',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },
      {
        headerName: "Policy No.",
        minWidth: 100,
        field: 'policyNo',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Nationality",
        minWidth: 130,
        field: 'emiratesId',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Comm.Type",
        field: 'communicationType',
        minWidth: 130,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Action",
        maxWidth: 80,
        filter: false,
        resizable: false,
        sortable: false,
        field: "Id",
        pinned: 'right',
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "RegistrationList" },
      },
    ];
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    this.statusBar = {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'center',
        },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' },
      ],
    };
    this.getApplicationSettings();
  }


  ngOnInit(): void {
    this.getDocumentType();
    this.attachDoc = new FormGroup({
      DocId: new FormControl('', Validators.required),
    });

    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.minDate = new Date();

    this.valueDate = new Date();
    this.consentDate = this.datepipe.transform(this.valueDate, 'dd-MM-yyyy');
    let dte = new Date();
    dte.setDate(dte.getDate() - 7);
    this.valueDate2 = this.datepipe.transform(dte, 'dd-MM-yyyy');
    this.rowData = [];
    this.user_role = localStorage.getItem('user_role');
    this.user_id = localStorage.getItem('user_id');
    this.GetDepartments();
    this.userType = localStorage.getItem('user_role');

    this.searchForm = this.formBuilder.group({
      sName: new FormControl(''),
      sMobile: new FormControl(''),
      sRegNo: new FormControl(''),
      sAddress: new FormControl(''),
      sEmiratesNo: new FormControl(''),
      sPin: new FormControl(''),
      sDate: new FormControl(''),
      sResNo: new FormControl(''),
      sDepartment: new FormControl('0'),
      sconsultant: new FormControl(''),
      sDateFrom: new FormControl(this.valueDate2),
      sDateTo: new FormControl(this.consentDate),
      sPhone: new FormControl(''),
      blockReason: new FormControl(''),
      sBranch: this.globals.HospitalId,
    })

    this.LoadPatientTable();
    this.GetSponsors();

    function Ctrl($scope) {
      $scope.date = new Date();
    }
    this.functionPermission();
  }
  //Block or Unblock Patient Details
  BlockUnblockPatient(patientId: Number, patientName: string) {
  }
  //Editi registration patient details
  EditRegistration(patientId: any) {
    this.router.navigate(['/AddRegistration', patientId]);
  }
  LoadPatientTable() {
    // this.SearchRegPatient();
    this.patientService.SearchRegPatient(this.searchForm, 0).subscribe((res) => {
      this.rowData = res.response;
    })


  }
  /* ============ userinformation modal data============ */
  showuserDialog(patient: any) {
    patient = patient.data;
    this.patientmodal = patient;
    this.gender = patient.gender;
    this.regDate = patient.regDate;
    this.patientName = patient.patientName;
    this.patientId = patient.patientId;
    this.email = patient.email;
    this.address = patient.address;
    this.mobile = patient.mobile;
    this.regNo = patient.regNo;
    this.active = patient.active == 1 ? "Block" : "Unblock"
    this.userImg=patient.profilePicLocation ? patient.profilePicLocation : undefined
    debugger
    this.patientService.GetPatientDocuments(Number(this.patientId)).subscribe((res) => {
      this.regDocLocation = res.response;

      this.documentList = this.regDocLocation[0].regDocLocation;


    },

      (error) => {
        this.toastr.error("Failure: " + error);
      })
      let AppData={
        patientId:Number(this.patientId),
        branchId:Number(this.globals.HospitalId),
        sponsorId:0,
        consentType:"IC"

      }
    this.patientService.ConsentHistoryModal(AppData).subscribe((res) => {
      this.ConsentHistory = res.response;
      this.consentHistoryList = this.ConsentHistory[0]['consentContentValue'][0].ctEnglish;
      this.consentHistoryListTemp=this.consentHistoryList.replace("<br/>","")
      this.consentHistoryListArabic = this.ConsentHistory[0]['consentContentValue'][0].ctArabic;
      this.consentHistoryPatientName = this.ConsentHistory[0]['patientName'];
      this.consentHistoryDate = this.ConsentHistory[0]['dateOfConsent'];

      if (this.ConsentHistory[0]['fileLoc']== '') {
        this.consentHistorySignature = null;
      }
      else {
        this.consentHistorySignature = this.ConsentHistory[0]['fileLoc'];
      }

    },



      (error) => {
        this.toastr.error("Failure: " + error);
      })
    this.displayBasicUser = true;

  }
  OpenImage(filePath: string) {//document view
    window.open(filePath, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
  }

  //print registration card data
  fillCardData(patient: any) {

    this.patientmodal = patient;
    this.gender = patient.genderName ? patient.genderName : "";
    this.regDate = patient.registeredDate;
    this.patientName = patient.patientName;
    this.patientId = patient.patientId;
    this.address = patient.address;
    this.mobile = patient.mobile;
    this.regNo = patient.regNo;
    this.age = patient.age;


    var printContents = '<div align="center"><div class="card" style="border:1px solid #000;width:60%"><img src="assets/images/xs/imagesdefault.png" alt="image" /><h3>Patient Registration Card</h3>';

    printContents = printContents + ' <table class="print-table1"><tr><td><strong>Registration No.</strong></td><td>: ' + this.regNo + '</td></tr>';
    printContents = printContents + ' <tr><td><strong>Name</strong></td><td>: ' + this.patientName + '</td></tr>';
    printContents = printContents + '<tr><td><strong>Gender</strong></td><td>: ' + this.gender + '</td></tr>';
    printContents = printContents + '<tr><td><strong>Age</strong></td><td>: ' + this.age + '</td></tr>';
    printContents = printContents + '<tr><td><strong>Contact</strong></td><td>: ' + this.mobile + '</td></tr></table></div></div>';
    var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
    mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(printContents);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
    // myWindow.print();
  }

  //  print patient registration details
  fillRegCardData(patient: any) {
    const foundElement = this.functionPermissionData.find(x => x.id === 205)
    if (foundElement.id == 205 && foundElement.isAllowed == true) {
    this.patientService.GetPatientDetailsbyId(patient.patientId).subscribe((res) => {
      this.regDetails = res.response;

      this.regIdentities = this.regDetails[0]['regIdentities'];
      let genderName = this.regDetails[0]['genderName'] ? this.regDetails[0]['genderName'] : "";
      let countryName = this.regDetails[0]['countryName'] ? this.regDetails[0]['countryName'] : "";
      let phone = this.regDetails[0]['resNo'] ? this.regDetails[0]['resNo'] : "";
      let profession = this.regDetails[0]['cmpName'] ? this.regDetails[0]['cmpName'] : '';
      let printContents = '';
      printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:50%"><img src="assets/images/xs/imagesdefault.png" alt="image" />';
      printContents = printContents + ' <table class="print-table1"><tr><td colspan="2"><h3>Patient Registration Detail</h3></td></tr><tr><td><strong>Registration No.</strong></td><td>' + patient.regNo + '</td></tr>';
      printContents = printContents + ' <tr><td><strong>Name</strong></td><td>' + this.regDetails[0]['firstName'] + ' ' + this.regDetails[0]['middleName'] + ' ' + this.regDetails[0]['lastName'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Age</strong></td><td>' + this.regDetails[0]['ageInYear'] + ' Years, ' + this.regDetails[0]['ageInMonth'] + ' Months </td></tr>';
      printContents = printContents + '<tr><td><strong>Contact</strong></td><td>' + this.regDetails[0]['mobile'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Registration Scheme</strong></td><td>' + this.regDetails[0]['itemName'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Rate Group</strong></td><td>' + this.regDetails[0]['rGroupName'] + '</td></tr>';
      // printContents = printContents + '</table></div></div>';
      //patient personnel detail
      printContents = printContents + '<tr><td><h3>Personal Details</h3></td></tr>'
      // printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:50%"><img src="assets/images/xs/imagesdefault.png" alt="image" /><h3>Personnel  Details</h3>';
      printContents = printContents + ' <tr><td><strong>Name</strong></td><td>' + this.regDetails[0]['firstName'] + ' ' + this.regDetails[0]['middleName'] + ' ' + this.regDetails[0]['lastName'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Nationality</strong></td><td>' + this.regDetails[0]['nationalityName'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Gender</strong></td><td>' + this.regDetails[0]['genderName'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Marital status</strong></td><td>' + this.regDetails[0]['maritalStatusDescription'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>D.O.B</strong></td><td>' + this.regDetails[0]['dob'] + '</td></tr>';
      //contact detatils
      // printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:50%"><img src="assets/images/xs/imagesdefault.png" alt="image" /><h3>Contact  Details</h3><table>';
      printContents = printContents + '<tr><td><h3>Contact Details</h3></td></tr>'
      printContents = printContents + ' <tr><td><strong>Mobile</strong></td><td>' + this.regDetails[0]['mobile'] + '</td></tr>';
      // printContents = printContents + '<tr><td><strong>Phone</strong></td><td>' + phone + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Office</strong></td><td>' + this.regDetails[0]['offNo'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Fax</strong></td><td>' + this.regDetails[0]['faxNo'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Email</strong></td><td>' + this.regDetails[0]['email'] + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Passport No</strong></td><td>' + this.regIdentities[1].identityNo + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Visa Type</strong></td><td>' + this.regDetails[0]['visaType'] + '</td></tr>';
      //office details
      printContents = printContents + '<tr><td><h3>Office Details</h3></td></tr>'
      // printContents = printContents + ' <tr><td><strong>Employer Name</strong></td><td>' +this.regDetails[0].profName + '</td></tr>';
      printContents = printContents + '<tr><td><strong>Profession</strong></td><td>' + this.regDetails[0]['profName'] + '</td></tr>';
      printContents = printContents + ' <tr><td><strong>Work Environment</strong></td><td>' + this.regDetails[0]['workEnvironMent'] + '</td></tr>';
      printContents = printContents + ' <tr><td><strong>Professional Noxious</strong></td><td>' + this.regDetails[0]['professionalNoxious'] + '</td></tr>';
      printContents = printContents + ' <tr><td><strong>Work Experience</strong></td><td>' + this.regDetails[0]['professionalExperience'] + '</td></tr>';
      printContents = printContents + '</table></div></div>';
      var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
      mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
      mywindow.document.write('</head><body >');
      mywindow.document.write(printContents);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    });
    return true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  openWin() {
    let printer1 = window.open('', '', 'width=500,height=600');
    printer1.document.write("<html>");
    printer1.document.write("<head><title></title></head>");
    printer1.document.write("<body>");
    printer1.document.write(document.getElementById('patientCard').innerHTML);
    printer1.document.write("</body></html>");
    printer1.document.close(); //missing code
    printer1.focus();
    printer1.print();
  }
  // user dialpad modal
  showuserDialpad() {

    this.displaydialpad = true;
  }
  printbutton() {
    this.printmodal = true;
  }
  /*========================Consenthistory modal=========================*/
  consentHistory(patient: any,cType) {
    
    this.patientData=patient;
    this.SelectedSponsorId=patient.sponsorId;
    this.cType=cType;
    this.clearPad();
    let sponserId=0;
    if(cType=="PC"){ this.patientHeading="Patient Consent";}
    else if(cType=="IC"){ this.patientHeading="Informed Consent"; }
    else if(cType=="SC")
    {
    this.patientHeading="Sponsor Consent";
     sponserId=this.SelectedSponsorId ;
    // your sponserid here
     }
    var AppData={
      "consentType":cType,
      "patientId":Number(patient.patientId),
      "branchId":Number(this.globals.HospitalId),
      "sponsorId":sponserId,

    };
    this.historyconsent = true;
    this.ConsentPatientId = patient.patientId;
    this.patientService.ConsentHistoryModal(AppData).subscribe((res) => {
       
      this.ConsentHistory = res.response;
      this.consentHistoryList = this.ConsentHistory[0]['consentContent'];
      this.consentHistoryListTemp=this.consentHistoryList.replace("<br/>","")

      this.consentHistoryPatientName = this.ConsentHistory[0]['patientName'];
      this.consentHistoryDate = this.ConsentHistory[0]['dateOfConsent'];
      //  this.consentHistorySignature="https://testapi.lehealthcloud.com/uploads/documents/96293714-5617-4fc5-9d54-4eae77b19394.jpeg"

      if (this.ConsentHistory[0]['fileLoc'] !== '') {
        this.IsConsentShow = true;
        this.consentHistorySignature = this.ConsentHistory[0]['fileLoc'];
      }
      else {
        this.IsConsentShowing = true;
        this.consentHistorySignature = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=';
      }

    })
  }
  consentSponsorHistory(sponserId){
    var AppData={
      "consentType":'SC',
      "patientId":Number(this.ConsentPatientId),
      "branchId":Number(this.globals.HospitalId),
      "sponsorId":Number(sponserId),

    };


    this.patientService.ConsentHistoryModal(AppData).subscribe((res) => {
      this.ConsentHistory = res.response;
      this.consentHistoryList = this.ConsentHistory[0]['consentContent'];
      this.consentHistoryListTemp=this.consentHistoryList.replace("<br/>","")

      this.consentHistoryPatientName = this.ConsentHistory[0]['patientName'];
      this.consentHistoryDate = this.ConsentHistory[0]['dateOfConsent'];

      if (this.ConsentHistory[0]['fileLoc'] !== '') {
        this.IsConsentShow = true;
        this.consentHistorySignature = this.ConsentHistory[0]['fileLoc'];
      }
      else {
        this.IsConsentShowing = true;
      }

    })


  }
  // consentInformedHistory(patient: any) {
  //   this.clearInformedPad();
  //   this.historyInformconsent = true;
  //   this.ConsentPatientId = patient.patientId;
  //   this.patientService.consentInformedHistory(Number(patient.patientId),Number(this.globals.HospitalId)).subscribe((res) => {
  //     this.ConsentHistory = res.response;
  //     this.consentHistoryList = this.ConsentHistory[0]['consentContent'];
  //     this.consentHistoryPatientName = this.ConsentHistory[0]['patientName'];
  //     this.consentHistoryDate = this.ConsentHistory[0]['dateOfConsent'];

  //     if (this.ConsentHistory[0]['fileLoc'] !== '') {
  //       this.IsConsentShow = true;
  //       this.consentHistorySignature = this.ConsentHistory[0]['fileLoc'];
  //     }
  //     else {
  //       this.IsConsentShowing = true;
  //     }

  //   })
  // }
  // openmodal(patient){
  //   this.historySponsorconsent = true;
  //   this.pId=Number(patient.patientId);

  // }
  // consentSponsorHistoryfn(params) {
  //   this.clearSponserPad();
  //   this.sponsorId=Number(params);
  //   this.patientService.consentSponsorHistory(Number(this.pId),Number(this.globals.HospitalId),Number(this.sponsorId)).subscribe((res) => {
  //     this.ConsentHistory = res.response;
  //     this.consentHistoryList = this.ConsentHistory[0]['consentContent'];
  //     this.consentHistoryPatientName = this.ConsentHistory[0]['patientName'];
  //     this.consentHistoryDate = this.ConsentHistory[0]['dateOfConsent'];

  //     if (this.ConsentHistory[0]['fileLoc'] !== '') {
  //       this.IsConsentShow = true;
  //       this.consentHistorySignature = this.ConsentHistory[0]['fileLoc'];
  //     }
  //     else {
  //       this.IsConsentShowing = true;
  //     }

  //   })
  // }


  /* ============ page title to be displayed in header============ */
  Activetab(pagetitle) {
    const foundElement = this.functionPermissionData.find(x => x.id === 190)
    if (foundElement.id == 190 && foundElement.isAllowed == true) {
      sessionStorage.setItem("pageTitle", pagetitle);
      this.router.navigate(["/AddRegistration"]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  //Searching patient details
  SearchRegPatient() {
    let StartDateObj = this.searchForm.get("sDateFrom").value
    let EndDateObj = this.searchForm.get("sDateTo").value
    // changing start date and end date to dd-MM-yyyy for search api
    if (typeof (StartDateObj) == "object") {
      let startDateFrom = this.datepipe.transform(StartDateObj, 'dd-MM-yyyy');
      this.searchForm.patchValue({ sDateFrom: startDateFrom })
    }
    if (typeof (EndDateObj) == "object") {
      let DateTo = this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      this.searchForm.patchValue({ sDateTo: DateTo })
    }
    this.patientService.SearchRegPatient(this.searchForm, 0).subscribe((res) => {
      this.rowData = res.response;
      if (this.user_role == 'consultant' && this.router_url == '/consultantdesk') {

        if (this.user_id) {
          this.rowData = this.rowData.filter(patient => patient.consultantId == this.user_id);

        }
      }

      if (this.rowData == null) {
        this.toastr.warning("No patients found");
      }
    })
    // changing start date and end date back to date obj after search api
    this.searchForm.patchValue({ sDateFrom: StartDateObj });
    this.searchForm.patchValue({ sDateTo: EndDateObj });
  }

  mailMe() {
    this.mailText = "mailto:abc@abc.com?subject=files&body=" + this.links.join(" ,");
    window.location.href = this.mailText;
  }
  //getting departments and consultants
  GetDepartments() {
    let ShowAll = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.departments = res.response;
      this.departmentsData = [];
      this.departmentsData.push({ id: "0", text: "Select All" });
      this.departments.forEach(a => {
        this.departmentsData.push({ id: a.deptId.toString(), text: a.deptName });
      });
    });
  }

  //get consultant by dept id
  loadConsultant(DepartmentId: Number) {
    this.consultants = null;
    let deptId = Number(this.searchForm.get("sDepartment").value);
    this.hospitalsService.GetConsultant(deptId).subscribe((res) => {
      this.consultants = res.response;

      this.consultantsData = [];
      this.consultants.forEach(a => {
        this.consultantsData.push({ id: a.consultantId.toString(), text: a.consultantName });
      });

    })
  }


  //datepicker shows till todays date
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }


  showEmrAccess() {
    this.emraccess = true;
  }
  //modal for block or unblock
  showBlockUnblock(rowDatas) {
    const foundElement = this.functionPermissionData.find(x => x.id === 192)
    if (foundElement.id == 192 && foundElement.isAllowed == true) {
    this.searchForm.patchValue({ blockReason: '' })
    this.active = rowDatas.active == 1 ? "Block" : "Unblock"
    this.blockId = Number(rowDatas.patientId);
    this.blockorunblock = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  //confirming modal for block or unblock
  confirmblocking(blockId: any) {

    if (this.active == "Unblock") {
      this.ConfirmUnBlock();
    }
    else {
      this.confirmblock = true;

    }
  }
  //confirming block modal function
  ConfirmBlock() {

    let blockReason = this.searchForm.get("blockReason").value;
    this.patientService.BlockPatient(this.blockId, blockReason).subscribe((res) => {

      this.displayBasicUser = false;
      this.LoadPatientTable();
    })
    this.confirmblock = false;
  }

  //confirming unblock modal function
  ConfirmUnBlock() {
    this.patientService.UnBlockPatient(this.blockId).subscribe((res) => {

      this.displayBasicUser = false;
      this.LoadPatientTable();
    })
    this.confirmblock = false;
  }

  //modal showing for attached documents
  attachDocuments() {
    this.attachdocuments = true;
    if (this.patientId) {
      this.patientService.GetPatientDocuments(Number(this.patientId)).subscribe((res) => {
        this.regDocLocation = res.response;
        this.documentList = this.regDocLocation[0].regDocLocation

        this.fileName = this.regDocLocation[0].regDocLocation['newUniqueName']
      },
        (error) => {
          this.toastr.error("Failure: " + error);
        })
    }

  }

  //------------------Starting for signaturepad------------------------------//

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }
  // ngAfterViewInit1() {
  //   this.signaturePad1 = new SignaturePad(this.canvasE2.nativeElement);
  // }
  // ngAfterViewInit2() {
  //   this.signaturePad2 = new SignaturePad(this.canvasE3.nativeElement);
  // }

  startDrawing(event: Event) {

    // works in device not in browser

  }
  moved(event: Event) {
    // works in device not in browser
  }

  movedInform(event: Event) {
    // works in device not in browser
  }
  startDrawingInform(event: Event) {

    // works in device not in browser

  }

  movedSponsor(event: Event) {
    // works in device not in browser
  }
  startDrawingSponsor(event: Event) {

    // works in device not in browser

  }

  clearPad() {
    this.signaturePad.clear();
  }
  //Saving Signature Pad
  savePad() {

    this.ConsentHistory;
    const signName = "sign.jpeg";
    const base64Data = this.signaturePad.toDataURL();

    let signsplit = base64Data.split(';');

    const signBlob = this.dataURItoBlob(signsplit[1].split(',')[1]);

    const signFile = new File([signBlob], signName, { type: 'image/jpeg' });

    this.documentFile2 = signFile;

    const formData = new FormData();
    formData.append('Sign', this.documentFile2, this.documentFile2.name);
    let TypeOfConsent=this.cType;
    if(TypeOfConsent=="PC")
    {
      var AppData = {
        patientId: this.ConsentPatientId,
        ConsentId:0,
        sponsorId:0,
        BranchId: this.globals.HospitalId,
        TypeOfConsent:TypeOfConsent,
        consentContent:this.consentHistoryList

      }

    }
    else if(TypeOfConsent=="IC"){
      var AppData = {
        patientId: this.ConsentPatientId,
        ConsentId:0,
        sponsorId:0,
        BranchId: this.globals.HospitalId,
        TypeOfConsent:TypeOfConsent,
        consentContent:this.consentHistoryList

    }
  }
    else if(TypeOfConsent=="SC")
    {
      var AppData = {
        patientId: this.ConsentPatientId,
        ConsentId:0,
        sponsorId:this.SelectedSponsorId,
        BranchId: this.globals.HospitalId,
        TypeOfConsent:TypeOfConsent,
        consentContent:this.consentHistoryList

    }

    }


    formData.append('ConsentJson', JSON.stringify(AppData));
    this.patientService.ConsentFormDataSave(formData).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.consentHistory(this.patientData,TypeOfConsent)
        this.toastr.success("Saved Your Signature  successfully");
        this.clearPad();
        // if(TypeOfConsent=="PC")
        // {
        //   this.consentHistory(this.patientData,"PC")
        //   this.toastr.success("Saved Your Signature  successfully");
        //   this.clearPad();
        //   // this.consentSponsorHistory(Number(this.sponsorId));
        // }
        // else if(TypeOfConsent=="SC")
        // {
        //   this.consentHistory(this.patientData,"SC")
        //   this.toastr.success("Saved Your Signature  successfully");
        //   this.clearPad();
        //   // this.consentSponsorHistory(Number(this.sponsorId));
        // }
        // else if(TypeOfConsent=="IC")
        // {
        //   this.consentHistory(this.patientData,"IC")
        //   this.toastr.success("Saved Your Signature  successfully");
        //   this.clearPad();
        //   // this.consentSponsorHistory(Number(this.sponsorId));
        // }
       
      }
      else {
        this.toastr.error("Failed.Please try later");
        //this.clearPad()
      }
    })

  }
// saveSponserdPad() {
// this.ConsentHistory;
// const signName = "sign.jpeg";
// const base64Data = this.signaturePad.toDataURL();

// let signsplit = base64Data.split(';');

// const signBlob = this.dataURItoBlob(signsplit[1].split(',')[1]);

// const signFile = new File([signBlob], signName, { type: 'image/jpeg' });

// this.documentFile2 = signFile;

// const formData = new FormData();
// formData.append('Sign', this.documentFile2, this.documentFile2.name);
// var AppData = {
//   // patientId: this.patientId,
//   patientId: this.ConsentPatientId,
//   ConsentId:0,
//   BranchId: this.globals.HospitalId,
//   sponsorId:this.pId,
//   TypeOfConsent:"SC",
//   consentContent:this.consentHistoryList

// }
// formData.append('ConsentJson', JSON.stringify(AppData));
// this.patientService.ConsentFormDataSaveSponsor(formData).subscribe((res) => {
//   this.result = res;
//   if (this.result.status == 200) {
//     this.toastr.success("Saved Your Signature  successfully");
//     this.clearSponserPad();
//   }
//   else {
//     this.toastr.error("Failed.Please try later");
//     //this.clearPad()
//   }
// })

// }



  //------------------End for signaturepad------------------------------//

  //Consent History printing function

  consentHistoryPrint(patient: any) {
    window.print()
    this.patientmodal = patient;
    let printContents = '';
    let tempSignature=this.consentHistorySignature.replace("/", "//");

    printContents = printContents + '<div style="padding: 30px; font-size: 12px;text-align: justify;"> <table class="print-table1"><h2 style="text-align: center;"><strong>Consent Form </strong></h2>';
    printContents = printContents + ' <tr><td><img src="assets/images/xs/logo_sample.png" alt="image" /></td></tr>';
    printContents = printContents + '<tr><td> <p>' + this.consentHistoryList + '</p></td></tr>';
    printContents = printContents + ' <tr><td><strong>Patient Name :</strong>'+ this.consentHistoryPatientName + '</td><td> ';
    printContents = printContents + ' <tr><td><strong>Date :</strong> ' + this.consentDate + '</td><td> ' ;
    printContents = printContents + ' <tr><td> <strong>Sign</strong>:' + '<img src="' + this.consentHistorySignature + '" />' + '</td></tr></div>';


    var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
    mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(printContents);


    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;




    // myWindow.print();
  }



  SponsrorConsent(data,type){
    let actionId;
    if(type == "PC") {
      actionId = 196;
    } else if(type == "IC") {
      actionId = 197;
    } else if(type == "SC") {
      actionId = 198;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    if(type == 'SC' && data.sponsorId == 0){
      this.toastr.warning("Patient doesn't have sponsor..!!");
      return
    }

    if(type == 'SC' && data.consentStatus == 0){
      this.toastr.warning("Sponsor doesn't have consent..!!");
      return
    }

    if (this.consentSettings[1].settValue == '0' && type == 'PC') {
      let initialState = {
        rowData: data
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(StaticPatientConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    } else if (this.consentSettings[0].settValue == '0' && type == 'IC') {
      let initialState = {
        ConsentPatientId: data.patientId,
        cType: type,
        // SelectedSponsor: data.sponsorId,
        data:data,
        branchId: this.globals.HospitalId,
        consultationId: data.consultationId
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(InformedStaticConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    }  else {
      console.log(data)
      let initialState = {
        ConsentPatientId: data.patientId,
        cType: type,
        SelectedSponsor: data.sponsorId,
        data:data
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(SponsorConsentComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  //printing function for sticker

  PrintStickerFormat(patient: any) {

    const printContent = document.getElementById("tablereg");

    var printContents = '<div id="tablereg"><table width="100%"><tr><th > <tr> <td>1456789</td><td> 12/2/2016 </td></tr> <tr><td>Murali Krishna</td></tr><tr><td>General Medicine</td> <td>D.O.B 23/12/2021</td></tr><tr><td>43/M</td> <td>111-1111-1111111-1</td></tr><tr><td>VISA </td><td>INDIAN</td></tr><tr><td>MOBILE</td><td>32456576767</td></tr><tr><td> Daman Thiqa(Thiqa) </td><td>ABC-Clinic-MF2</td></tr></th>   <th > <tr><td>1456789</td><td>12/2/2016</td></tr><tr><td>Murali Krishna</td></tr><tr> <td>General Medicine</td><td>D.O.B 23/12/2021</td></tr><tr><td> 43/M</td><td>111-1111-1111111-1</td></tr><tr><td>VISA</td><td>INDIAN</td></tr><tr><td> MOBILE</td><td>32456576767</td></tr><tr><td>Daman Thiqa(Thiqa)</td><td>ABC-Clinic-MF2</td></tr></th> </table></div>';



    var mywindow = window.open('', 'PRINT', 'height=1000,width=1000');
    mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');

    mywindow.document.write('<style>table, th { border: 1px solid black;border-collapse: collapse;}</style></head><body >');
    mywindow.document.write(printContents);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
    // myWindow.print();
  }

  onPrintSticker(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 194)
    if (foundElement.id == 194 && foundElement.isAllowed == true) {
    const payload = {
      PatientId: data.patientId,
      BranchId: this.globals.HospitalId,
      ConsultationId: 0
    }
    this.patientService.printSticker(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          window.open(this.baseUrl + response.response[0].reportPath, '_blank')
        }else{
          this.toastr.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastr.error('Something wents wrong..! Try again.')
      }
    })
    } else {
      this.toastr.warning("You are not allowed");
    } 
  }

  onPrintRegistrationCard(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 193)
    if (foundElement.id == 193 && foundElement.isAllowed == true) {
      let initialState = {
        modalHeading: 'Registration Card',
        reportName: 'PrintRegistrationCard.trdp',
        reportParams: {
          PatientId: data?.patientId,
          BranchId: this.globals?.HospitalId,
          ConsultationId: 0
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    } else {
      this.toastr.warning("You are not allowed to print");
    }
    // const foundElement = this.functionPermissionData.find(x => x.id === 193)
    // if (foundElement.id == 193 && foundElement.isAllowed == true) {
    // const payload = {
      // PatientId: data.patientId,
      // BranchId: this.globals.HospitalId,
    // }
    // this.patientService.printRegistrationCard(payload).subscribe({
    //   next: (response: Response<any>)=>{
    //     if(response.status == 200){
    //       window.open(this.baseUrl + response.response[0].reportPath, '_blank')
    //     }else{
    //       this.toastr.error(response.errorMessage.message);
    //     }
    //   },error: (error: any)=>{
    //     this.toastr.error('Something wents wrong..! Try again.')
    //   }
    // })
    // } else {
    //   this.toastr.warning("You are not allowed to print");
    // }
  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  //Getting Uploaded Attachments
  attachments(rowdatas) {
    const foundElement = this.functionPermissionData.find(x => x.id === 203)
    if (foundElement.id == 203 && foundElement.isAllowed == true) {
    this.patientId = rowdatas.patientId;
    this.documentList=[];

    this.displayBasicdragdrop = true;
    if (this.patientId) {
      this.patientService.GetPatientDocuments(Number(this.patientId)).subscribe((res) => {
        this.regDocLocation = res.response;
        this.documentList = this.regDocLocation[0].regDocLocation

      },
        (error) => {
          this.toastr.error("Failure: " + error);
        })
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  //Uploading Attachments

  async onUpload(event,fileUpload) {
    
    this.attachDoc.markAllAsTouched();
    this.documentFile = <File[]>event.files;

    const formData = new FormData();
    if (this.documentFile) {
      formData.append('DocumentName', this.documentFile[0]);
    }
    // if (this.documentFile1) {
    //   formData.append('PatientPhoto', this.documentFile1, this.documentFile1.name);
    // }

    formData.append('PatientJson', JSON.stringify({ 'PatientId': this.patientId, 'DocId': this.attachDoc.controls.DocId.value}));
    if (this.attachDoc.controls.DocId.value != "") {
      this.patientService.GetPatientFiles(formData).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Files Uploaded successfully");
          // this.LoadPatientTable();
          fileUpload.clear();
        } else {
          this.toastr.error("Files Uploading failed");
        }
      });
      this.displayBasicdragdrop = false;
      this.documentList=[];
      await this.attachDoc.reset();
    } else{
      this.toastr.error("Please select the attachment type");
    }

  }



  public get triggerObservable(): Observable<void> {

    return this.trigger.asObservable();
  }

  //Web Cam saving Service Calling
  webCamSave(event) {

    const formData = new FormData();
    if (this.documentFile) {

      for (var i = 0; i < this.documentFile.length; i++) {

        formData.append('PatientDocs', this.documentFile[i]);

      }
    }
    if (this.documentFile1) {

      formData.append('PatientPhoto', this.documentFile1, this.documentFile1.name);

    }

    formData.append('PatientJson', JSON.stringify({ 'PatientId': this.patientId }));

    this.patientService.GetPatientDocumentsFiles(formData).subscribe((res) => {
      this.result = res;

      if (this.result.status == 200) {
         this.toastr.success("Images Uploaded successfully");
      } else {

        this.toastr.error("Images Uploading failed");
      }
    });
    this.displayBasic = false;
  }


  camerclick(rowdatas) {
    const foundElement = this.functionPermissionData.find(x => x.id === 204)
    if (foundElement.id == 204 && foundElement.isAllowed == true) {
    this.patientId = rowdatas.patientId;
    this.displayBasic = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  public toggleWebcam(): void {

    this.showWebcam = !this.showWebcam;
  }
  triggerSnapshot(): void {
    this.myObservable$ = new Subject();
    this.myObservable$.next();
    this.trigger.next();
  }
  handleImage(webcamImage: WebcamImage): void {

    this.webcamImage = webcamImage;

    const imageName = "patient.jpeg";// new Date().toString();
    const base64 = webcamImage.imageAsDataUrl.split('base64,');

    const imageBlob = this.dataURItoBlob(base64[1]);
    const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    this.documentFile1 = imageFile;

    this.generatedImage = window.URL.createObjectURL(imageFile);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  fromdatechange(event) {
    let StartDateObj = this.searchForm.get("sDateFrom").value;
    this.minDate = StartDateObj;
  }

  // delete Uploaded documents
  DeleteUploadedDoc(eventparam,docId) {
    if (confirm("Are you sure that you want to delete this file ?")) {
      this.patientService.DeleteUploadedDoc(Number(docId)).subscribe((res) => {
        this.result = res;
        if (this.result.message == 'success') {
          // this.attachments();
          this.toastr.success("The file deleted successfully");
          eventparam.target.closest('tr').remove();
        }
        else {
          this.toastr.error("Failure: " + this.result.message);
        }

      },
        (error) => {
          this.toastr.error("Failure: " + error);
        });
    }
  }

  searchevent($event) {
    this.patientService.SearchRegPatient($event, 0).subscribe((res) => {
      this.rowData = res.response;
      if (this.user_role == 'consultant' && this.router_url == '/consultantdesk') {

        if (this.user_id) {
          this.rowData = this.rowData.filter(patient => patient.consultantId == this.user_id);

        }
      }

      if (this.rowData == null) {
        this.toastr.warning("No patients found");
      }
    })
  }

  openEmr() {

    this.router.navigate(['/EMR_Home']);
  }
  GoToEmr(patientId) {
    this.patientInfo = {
      'VisitId': 0,
      'ConsultantId': Number(localStorage.getItem('Id')),
      'ConsultationId': 0,
      'PatientId': patientId,
      'VisitType': 0,
      'VisitStartTime': '',
      'VisitEndTime': '',
      'UserId': Number(localStorage.getItem('user_id')),
      'CrudType': 0,
      InsAmountLimit: 0,
      insCovered: 0
    }

    let OutPut = this.EMRService.SetEMRPatientInfo(this.patientInfo);
    if (OutPut) {
      this.router.navigate(['/EMR_Home'],{state:{patientDatas:true}});
    }
  }

  openCredit(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 199)
    if (foundElement.id == 199 && foundElement.isAllowed == true) {
    let input={
      patientId:data.patientId,
      creditId:0,
      patientName:data.patientName,
      regNo:data.regNo
   }
   this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  GetSponsors() {
    this.patientService.GetSponsors(this.globals.HospitalId).subscribe((res) => {
      this.Sponsors = res.response;
      this.sponsorId=this.Sponsors['sponsorId'];
    });
  }

  exportToXLSX(){
    const foundElement = this.functionPermissionData.find(x => x.id === 202)
    if (foundElement.id == 202 && foundElement.isAllowed == true) {
    let excelData: any[] = [];
    const fileName: string = 'Le-Health_patient_registration_list.xlsx'
    _.forEach(this.rowData, (data, index)=>{
      excelData.push({
        'Sl.No.': index+1,
        'Reg.No.': data.regNo,
        'Reg.Date': data.regDate,
        'Patient Name': data.patientName,
        'Age': data.age,
        'Gender': data.genderName,
        'Mobile': data.mobile,
        'Address': data.address,
        'Sponsor': data.sponsorName,
        'Consultant': data.consultant,
        'Policy No.': data.policyNo,
        'Nationality': data.nationalId,
        'Comm.Type': data.communicationType
      })
    })
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    
    var wscols = [
      { wch: 4 },
      { wch: 20 },
      { wch: 20 },
      { wch: 40 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 40 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    ws['!cols'] = wscols;
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
    } else {
      this.toastr.warning("You are not allowed to download");
    }
  }

  gotoConsultation(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 200)
    if (foundElement.id == 200 && foundElement.isAllowed == true) {
    let input={
      patientId:data.patientId,
      patientName:data.patientName,
      regNo:data.regNo
   }
   this.router.navigate(['/AddConsultation'], { state: { patientData: input } });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  gotoPatientQuestionnaire(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 206)
    if (foundElement.id == 206 && foundElement.isAllowed == true) {
    let initialState = {
      branchesId: this.BranchesId,
      patientId: data.patientId
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(PatientQuestionnaireModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  printBarcode(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 195)
    if (foundElement.id == 195 && foundElement.isAllowed == true) {
    let initialState = {
      regNo: data.regNo
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(PrintBarcodeModalComponent, Object.assign({}, config, { class: 'modal-sm', initialState }))
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  showHealthSummary(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 201)
    if (foundElement.id == 201 && foundElement.isAllowed == true) {
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      patientId: data.patientId,
      branchId: this.globals.HospitalId
    }
    const modalRef = this.modalService.show(HealthSummaryModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }));
    } else {
      this.toastr.warning("You are not allowed");
    }
  }


  setDocType(event) {
    this.attachDoc.controls.DocId.setValue(event); 
  }

  getDocumentType() {
    let payLoad = {
      "DocId":0,
      "ShowAll":0
   }
    this.patientService.getDocumentType(payLoad).subscribe((res) => {
      this.attachmentTypeList = res.response;
    })
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }

  editRegistration(patientId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 191)
    if (foundElement.id == 191 && foundElement.isAllowed == true) {
      this.router.navigate(["/AddRegistration", patientId]);
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  getApplicationSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }

    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.consentSettings = _.filter(response.response, {tabId: 5});
          this.healthPlatform=response.response.find(item => item.settId == 25)
        }
      }
    })
  }

  nabithConsent(patientId, consultationId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 577)
    if (foundElement?.id == 577 && foundElement.isAllowed == true) {
    if (this.healthPlatform.settValue === "Nabidh") {
    let initialState = {
      patientId: patientId,
      ConsultationId: 0,
      action: 1,
      formName:this.healthPlatform.settValue
    }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(NabithConsentComponent,Object.assign( {}, config, { class: 'modal-xl', initialState }) );
    this.modalRef.content.fileEmitter.subscribe((response: any)=>{
      if(response.status == true){
        // this.toastr.success("The signature updated successfully");
      }
    })
    } else if (this.healthPlatform.settValue === "Riayati") {
      let initialState = {
        patientId: patientId,
        ConsultationId: 0,
        // action: 1,
        // formName: this.healthPlatform.settValue
      }

      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.modalRef = this.modalService.show(RiayatiConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
      this.modalRef.content.fileEmitter.subscribe((response: any) => {
        if (response.status == true) {
          // this.toastr.success("The signature updated successfully");
        }
      })
    }
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  editCredit(data){
    
    const foundElement = this.functionPermissionData.find(x => x.id === 538)
    if (foundElement?.id == 538 && foundElement.isAllowed == true) {
   let input={
       patientId:data.patientId,
       creditId:data.creditId,
       patientName:data.patientName,
       regNo:data.regNo
    }
    debugger
    this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  onPatientEmr(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 541)
    if (foundElement.id == 541 && foundElement.isAllowed == true) {
      this.patientInfo = {
        VisitId: 0,
        ConsultantId: Number(localStorage.getItem('Id')),
        ConsultationId: 0,
        lastConsultationId: data.consultationId,
        PatientId: data.patientId,
        VisitType: 0,
        VisitStartTime: '',
        VisitEndTime: '',
        UserId: Number(localStorage.getItem('user_id')),
        CrudType: 0,
        InsAmountLimit: 0,
        insCovered: 0,
        sFormId: data.sFormId,
        telerikName: data.telerikName
      }
      let patInfo = {
        ConsultationId: data.consultationId,
        SponsorId: data.sponsorId,
        telerikName: data.telerikName
      }
      localStorage.setItem('asoapPatInfo', JSON.stringify(patInfo));

      let OutPut = this.EMRService.SetEMRPatientInfo(this.patientInfo);
      if (OutPut) {
        this.router.navigate(['/EMR_Home'], { state: { patientDatas: true } });
      }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  mergePatient() {
    const foundElement = this.functionPermissionData.find(x => x.id === 640)
    if (foundElement.id == 640 && foundElement.isAllowed == true) {
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-lg'
      }
      let initialState = {
        functionPermissionData: this.functionPermissionData
      }
      this.modalRef = this.modalService.show(MergePatientModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }));
      this.modalRef.content.statusEmitter.subscribe((response: any) => {
        if (response.status == true) {
          this.LoadPatientTable();
          // this.toastr.success("The signature updated successfully");
        }
      })
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

}

