import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter } from 'stream';
import { AppointmentsList } from '../../interfaces/AppointmentsList';
import { AppointmentsService } from '../../services/appointments.service';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';

@Component({
  
  selector: 'app-UserDetailsAppointmentComponent',
  templateUrl: './userdetails-appointment.component.html',
  styleUrls: ['./userdetails-appointment.component.scss']
})
export class UserDetailsAppointmentComponent implements OnInit {
  @Input() userdetails;
 // @Output() returnevent: EventEmitter<any>;
  CancelAppointments:boolean;
  CancelButton:boolean;
  active:string;
  confirmblock:boolean;
  blockId:number;
  blockorunblock:boolean;
  
  AppointmentDataDetails: AppointmentsList[];
  form:FormGroup;
  displayBasicAppoint: boolean;
  BranchId: any;
  appId: any;
  UserAppId: any;
  PatientRegNo: any;
  PatientMob: any;
  PatientName: any;
  PatientDate: any;
  Patientemail: any;
  Patientaddress: any;
  Patientremarks: any;
  baseUrl: any;
  userImage: any;
  constructor(private appointmentService:AppointmentsService,
    private toastr: ToastrService,
    private configurationManager: ConfigurationManager,
  ) { 
 
  }
ngOnChanges()
{
 
  this.UserAppId=this.userdetails?.AppId;
   this.UserDetailsAppointment(this.UserAppId);


}
  

  ngOnInit(): void {

    this.form= new FormGroup({
      blockReason:new FormControl("")
      })

      this.baseUrl = (this.configurationManager as any).baseUrl;


  }
  UserDetailsAppointment(UserAppId) {

    this.appointmentService.GetAppoinmentDetailsbyId(UserAppId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      this.PatientRegNo=this.AppointmentDataDetails['patientRegNo'];
      this.PatientName=this.AppointmentDataDetails['patientName'];
      this.PatientMob=this.AppointmentDataDetails['mobile'];
      this.PatientDate=this.AppointmentDataDetails['appDate'];
      this.Patientemail=this.AppointmentDataDetails['email'];
      this.Patientaddress=this.AppointmentDataDetails['address1'];
      this.Patientremarks=this.AppointmentDataDetails['remarks'];
      this.displayBasicAppoint= true;
      this.userImage=this.AppointmentDataDetails['profilePicLocation'] !=="" ? this.baseUrl+this.AppointmentDataDetails['profilePicLocation'] : undefined
    });
  
  }


}
