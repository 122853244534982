import { Component, OnInit } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { NumberSettingList } from '../../client/interfaces/NumberSettingList';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { ConfigurationService } from "../../client/services/configuration.service";
import { ToastrService } from "ngx-toastr";
import _ from 'lodash';

@Component({
  selector: 'app-number-settings',
  templateUrl: './number-settings.component.html',
  styleUrls: ['./number-settings.component.scss']
})
export class NumberSettingsComponent implements OnInit {

  display: boolean = false;
  public gridApi;
  defaultColDef: ColDef[] = [];
  rowGroupPanelShow = "always";
  public context;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  public loadingTemplate = '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';
  public noRowsTemplate;
  public frameworkComponents;
  NumberSettingsPopupHeading = "Add Number Settings";
  NumberSettingsfForm: FormGroup;
  public submitted: boolean = false;
  isLoading: boolean = false;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  numberList: any;
  result: any;
  oldValue: any;
  oldLength:any;
  oldPreview:any;
  oldMaxlength:any;
  functionPermissionData: any;

  constructor(
    private sharedservice: SharedService,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {
    this.context = { componentParent: this };
    this.NumberSettingsfForm = new FormGroup({
      numId: new FormControl(""),
      description: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
      prefix: new FormControl('', [Validators.required]),
      suffix: new FormControl(""),
      length: new FormControl('', [Validators.required]),
      maxLength: new FormControl('', [Validators.required]),
      preview: new FormControl('', [Validators.required]),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
      branchid: new FormControl(this.globals.HospitalId),
      status: new FormControl(1),

    });

    this.defaultColDef = [
      {
        headerName: "Description ",
        field: "description",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 110,
        filter: true,
      },
      {
        headerName: "Next",
        field: "value",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Prefix",
        field: "prefix",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 150,
        filter: true,
      },
      {
        headerName: "Suffix",
        field: "suffix",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        minWidth: 120,
        filter: true,
      },
      {
        headerName: "Length",
        field: "length",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Preview",
        field: "preview",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 80,
        filter: true,
      },
      {
        headerName: "Max Length",
        field: "maxLength",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },

      {
        headerName: "Action",
        field: "action",
        enableRowGroup: true,
        resizable: false,
        sortable: false,
        flex: 2,
        maxWidth: 80,
        pinned: "right",
        filter: false,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "numbersettings" },

      },

    ];
    this.GetNumber();
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
      onGridReady: (event) => event.api.sizeColumnsToFit()

    };

    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.functionPermission();

  }
  get f() {
    return this.NumberSettingsfForm.controls;
  }

  showDialog() {
    this.NumberSettingsPopupHeading = "Add Number Settings";
    this.display = true;
    // this.ResetFunction();
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

  GetNumber() {
    let Input = {
      "NumId": "All",
      "branchid": this.globals.HospitalId

    }
    this.configurationService.GetNumber(Input).subscribe((res) => {
      this.numberList = res.response;
    });
  }
  NumberSettingsupdate(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 156)
    if (foundElement.id == 156 && foundElement.isAllowed == true) {
    this.display = true;
    this.NumberSettingsPopupHeading = "Update Number Settings";

    console.log(data);

    this.NumberSettingsfForm.patchValue(data);
    this.oldValue = data.value;
 
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  Update() {
    this.submitted = true;
    if (this.NumberSettingsfForm && this.NumberSettingsfForm.valid) {
      var formdata = this.NumberSettingsfForm.getRawValue();
      formdata.userId = Number(localStorage.getItem('user_id'));
      let nprefix = formdata.prefix.toString();
      let nValue = formdata.value.toString();
      let nSuffix = formdata.suffix.toString();
      formdata.preview = (nprefix + nValue + nSuffix);
      formdata.value=Number(this.NumberSettingsfForm.get('value').value);
      formdata.suffix=nSuffix;
       if (!(formdata.length < formdata.preview.length)) {
        this.toastr.error('please enter length Less than preview');
      }
      // else if () {
      //   this.toastr.error('please enter preview greater than maxlength');

      // }
      else if (!(formdata.length <formdata.maxLength)) {
        this.toastr.error('please enter maxlength Less than preview');

      }
      else if (!(formdata.value>=this.oldValue)) {
        this.toastr.error('please enter next value grater than '+ this.oldValue);

      }
      else {
        this.isLoading = true;
        this.configurationService.NumberSettingsupdate(formdata).subscribe((res) => {
          // this.submitted = false;
          this.result = res;
          if (this.result.status == 200 && this.result.message == 'Saved Sucessfully') {

            this.isLoading = false;
            this.toastr.success("Number Settings Updated Successfully");
            this.display = false;
            this.GetNumber();
          }
          else {
            this.isLoading = false;
            this.toastr.error("Failed : " + this.result.message);
          }
        })

      }

    }
  }
  previewOnchange() {
    let formdata = this.NumberSettingsfForm.getRawValue();
    let preview = "";
    preview = preview.concat(formdata.prefix, (_.padStart(formdata.value, formdata.length, '0')), formdata.suffix);
    this.NumberSettingsfForm.controls['preview'].setValue(preview);

  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }
}
