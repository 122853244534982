import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ErrorHandleService } from '../../client/services/error-handle.service';
import { Response } from '../../../_Models/Response';



@Injectable({
  providedIn: 'root'
})
export class SketchConfigurationService {

  private baseUrl = "";

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle:ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
   }
   saveIndicator(data)
   {
    var url="api/MasterData/InsertUpdateSketchIndicator";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,data)
   }
   saveBodyPart(data)
   {
    var url="api/MasterData/InsertUpdateBodyPart";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,data)
   }
   GetIndicatortList(Input)
   {
    var url="api/MasterData/GetSketchIndicators";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input)
   }
   GetBodyPartList(Input)
   {
    var url="api/MasterData/GetBodyPart";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input)
   }
   DeleteBodyPart(Input)
   {
    var url="api/MasterData/DeleteBodyPart";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input)
   }
   DeleteSketchIndicator(Input)
   {
    var url="api/MasterData/DeleteSketchIndicator";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input)
   }
}
