import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { EMRService } from '../../services/emr.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { OphthalmologyService } from '../../services/ophthalmology.service';
import { Response } from '../../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import _ from 'lodash';

@Component({
  selector: 'app-medical-lens-specification',
  templateUrl: './medical-lens-specification.component.html',
  styleUrls: ['./medical-lens-specification.component.scss']
})
export class MedicalLensSpecificationComponent implements OnInit {

  @Input() globals: globalvars;

  public lensSpecificationForm: FormGroup;
  public EMRpatientInfo: EMRPatientInfo;
  public specLoading: boolean = false;

  private FormValueChangeChecker: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private EmrService: EMRService,
              private ophthalmologyService: OphthalmologyService,
              private toastrService: ToastrService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.EMRpatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initForm();
    this.getLensSpecification();
  }

  getLensSpecification(){
    let payload = {
      PatientId: this.EMRpatientInfo.PatientId,
      ConsultationId: this.EMRpatientInfo.ConsultationId,
      BranchId: this.globals.HospitalId
    }
    this.specLoading = true;
    this.ophthalmologyService.getLensSpecification(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          if(response.response[0]){
            this.lensSpecificationForm.patchValue({
              EntryId: Number(response.response[0]?.entryId),
              Remarks: response.response[0]?.remarks,
              Distance: response.response[0]?.distance,
              NVAdd: response.response[0]?.nvAdd,
            })
            this.createTestFormArray(response.response[0]?.eyeTestDetailList)
          }
          this.lensSpecificationForm.valueChanges.subscribe(x=>{
            this.FormValueChangeChecker = true;
          })
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
        this.specLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again.');
        this.specLoading = false;
      }
    })
  }

  initForm(){
    this.lensSpecificationForm = this.formBuilder.group({
        EntryId: new FormControl(0),
        PatientId: new FormControl(this.EMRpatientInfo.PatientId, Validators.required),
        ConsultationId: new FormControl(this.EMRpatientInfo.ConsultationId, Validators.required),
        EntryDate: new FormControl(this.datePipe.transform(new Date(), 'dd-MM-yyyy'), Validators.required),
        UserId: new FormControl(Number(localStorage.getItem('user_id')), Validators.required),
        Remarks: new FormControl(''),
        Distance: new FormControl(''),
        NVAdd: new FormControl(''),
        EyeTestDetailList: new FormArray([])
    });
    this.createTestFormArray([]);
  }

  createTestFormArray(data: any[]){
    const lens = this.lensSpecificationForm.get("EyeTestDetailList") as FormArray;
    lens.clear();
    if(data.length > 0){
      _.forEach(data, (eye: any)=>{
        lens.insert(0, this.createArrayObject(eye, ''));
      })
    }else{
      lens.insert(0, this.createArrayObject(null, 'R'));
      lens.insert(0, this.createArrayObject(null, 'L'));
    } 
  }

  createArrayObject(data: any, eye: string): FormGroup{
    return new FormGroup({
      Eye: new FormControl(data ? data.eye : eye),
      EyeName: new FormControl(data ? (data.eye == 'R' ? 'Right Eye' : 'Left Eye'):(eye == 'R' ? 'Right Eye' : 'Left Eye')),
      Spherical: new FormControl(data ? data.spherical : ''),
      Cylindrical: new FormControl(data ? data.cylindrical : ''),
      Axis: new FormControl(data ? data.axis : ''),
      VA: new FormControl(data ? data.va : '')
    }) 
  }

  saveLensSpecification(){
    if(this.EMRpatientInfo.CrudType == 3 &&
      this.EMRpatientInfo.VisitId == 0 && !this.FormValueChangeChecker){
      return
    }
    
    let payload = this.lensSpecificationForm.getRawValue();
    this.ophthalmologyService.saveLensSpecification(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.lensSpecificationForm.patchValue({
            EntryId: Number(response.response[0]?.entryId),
          });
          this.FormValueChangeChecker = false;
          this.toastrService.success('Lens Specification saved successfully.')
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

}
