import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BillingGeneralActionComponent } from '../../billing-general-action/billing-general-action.component';

@Component({
  selector: 'app-manage-loc',
  templateUrl: './manage-loc.component.html',
  styleUrls: ['./manage-loc.component.scss']
})
export class ManageLocComponent implements OnInit {

  @Input() billItems: any[];

  SponsorName: boolean=true;
  PatientOwn: boolean;
  ManageLoc:any[];
  PatientOwnLoc:any[];

  @Output() manageLocEmitter= new EventEmitter();
  
  ManageLocItems:ColDef[] = [
    {
      headerName: "Item Name",
      field: "itemName",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      checkboxSelection: true,
      flex: 2,
    },
    {
      headerName: "Rate",
      field: "usedRate",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Quantity",
      field: "qty",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Amount",
      field: "amount",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    }
  ];

  gridApi: GridApi;
  patientGridApi: GridApi;
  public frameworkComponents: any;
  public imageComponents: any;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {

    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: BillingGeneralActionComponent,
    };

    this.fetchTableDats();

  }

  fetchTableDats(){
    this.ManageLoc = [];
    this.PatientOwnLoc = [];
    if(this.billItems.length > 0){
      this.billItems.forEach((item)=>{
        item.rate = item.usedRate;
        if(item?.insCovered){
          this.ManageLoc.push(item);
        }else{
          this.PatientOwnLoc.push(item);
        }
      })
    }
  }

  onTab(number) {
    this.SponsorName = false;
    this.PatientOwn = false;

    if (number == '1') {
      this.SponsorName = true;
    }
    else if (number == '2') {
      this.PatientOwn = true;
    }

  }

  onGridSponsorReady(params) {
    this.gridApi = params.api;
  }

  onGridPatientReady(params) {
    this.patientGridApi = params.api;
  }

  onManageLoc(){
    let selectedRows = (this.patientGridApi.getSelectedRows());
    if(selectedRows.length > 0){
      selectedRows.forEach((item)=> {
        this.billItems.forEach((billItem)=>{
          // if(billItem.itemId == item.itemId){
          if((billItem.teeths !== '') ? ((billItem.itemId == item.itemId) && (billItem.teeths == item.teeths)) : (billItem.itemId == item.itemId)){
            billItem.insCovered = true;
          }
        })
      });
    }

    let selectedSponsorRows = (this.gridApi.getSelectedRows());
    if(selectedSponsorRows.length > 0){
      selectedSponsorRows.forEach((item)=> {
        this.billItems.forEach((billItem)=>{
          // if(billItem.itemId == item.itemId){
          if((billItem.teeths !== '') ? ((billItem.itemId == item.itemId) && (billItem.teeths == item.teeths)) : (billItem.itemId == item.itemId)){
            billItem.insCovered = false;
          }
        })
      });
    }
    this.fetchTableDats();
  }

  onApplyChanges(){
    this.manageLocEmitter.emit({data: this.billItems});
    this.modalRef.hide();
  }

}
