import { Component, Input, OnInit } from '@angular/core';
import { EMRPatientInfo } from '../../../interfaces/EMRPatientInfo';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EMRService } from '../../../services/emr.service';
import { ViewPlanProcedureModalComponent } from '../view-plan-procedure-modal/view-plan-procedure-modal.component';

@Component({
  selector: 'app-prev-plan-procedure-modal',
  templateUrl: './prev-plan-procedure-modal.component.html',
  styleUrls: ['./prev-plan-procedure-modal.component.scss']
})
export class PrevPlanProcedureModalComponent implements OnInit {

  @Input() EmrPatientInfo: EMRPatientInfo;

  public planProcedureList: any[] = [];

  constructor(private activeModalRef: BsModalRef,
              private EmrService: EMRService,
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.GetPrevPlanAndProcedure();
  }

  close(){
    this.activeModalRef.hide();
  }

  GetPrevPlanAndProcedure()
  {
    let input={
      "VisitId":this.EmrPatientInfo.VisitId,
      "PatientId":this.EmrPatientInfo.PatientId,
      "ShowAll":0
    };

    this.EmrService.GetPlanAndProcedure(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.planProcedureList=res.response;
        }
      });
  }

  viewDetails(entryId: any){
    let initialState = {
      entryId: entryId,
      patientId: this.EmrPatientInfo.PatientId
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const modalRef= this.modalService.show(ViewPlanProcedureModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

}
