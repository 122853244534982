import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../assets/configuration/configuration-manager';
import { UserGroups } from '../client/interfaces/usergroup';
import { Response } from '../../_Models/Response';
import { UserModel } from '../client/interfaces/UserModel';
import { Submenu } from '../client/interfaces/SubMenu';
import { Menu } from '../client/interfaces/Menu';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserpermissionService {
  SaveUserMenu(saveobj) {
    //alert(JSON.stringify(saveobj));
    var url="api/UserPermission/SaveUsermenu";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,saveobj);
  }
  GetMenuongroups(user: any, branch: any, groupids: any) {
    var url="api/menu/GetMenuongroups/"+user+"/"+branch+"/"+groupids;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,null);
  }

  getSavedUsergRoups(userid)
  {
    var url="api/UserPermission/GetUserGroupBranches/"+userid;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,null);
  }



  getUserGrouponUser(id: any) {
    var url="api/UserPermission/getUserGrouponUser";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,id);
  }
  getUserGroupsonBranch(brabchId: number,UserId:number) {
    var url="api/UserPermission/getUserGroupsonBranch/"+brabchId+"/"+UserId;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,null);
  }
  SaveUserGroupMap(mapgroup:any) {
    var url="api/UserPermission/MapUserGroup";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,mapgroup);
  }
  GetUserLocations(id: any) {
    var url="api/UserPermission/GetUserlocations";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,id);
  }
  SaveLocationMap(maploc: any) {
    var url="api/UserPermission/MapLocation";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,maploc);
  }
  GetUserBranches(id: any) {
    var url="api/UserPermission/GetUserBranches";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,id);
  }
  GetUsers(branchid) {
    var url="api/UserPermission/GetUsers/"+branchid;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,null);
  }
  GetUser(id: any) {
    var url="api/UserPermission/GetUser";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,id);
  }
  SaveUser(User: UserModel) {
    var url="api/UserPermission/SaveUser";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,User);

  }
  getUserGroups(branchId) {
    var url="api/UserPermission/getUserGroups/" + branchId;;
    return this.http.post<Response<UserGroups[]>>(`${this.baseUrl}${url}`,null);
  }
  getUserGroup(id: any) {
    var url="api/UserPermission/getUserGroup";
    return this.http.post<Response<UserGroups>>(`${this.baseUrl}${url}`,id);
  }
  SaveUserGroup(usergroup: UserGroups) {
    var url="api/UserPermission/SaveUserGroup";
    return this.http.post<Response<UserGroups>>(`${this.baseUrl}${url}`,usergroup);
  }
  GetSubmenu(id: any) {
    var url="api/menu/GetSubmenuMenuItem/";
    return this.http.post<Response<Submenu>>(`${this.baseUrl}${url}${id}`,null);
  }
  SaveSubMenu(submenu: Submenu) {
    var url="api/menu/SaveSubMenuItems";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,submenu);
  }
  GetSubmenus() {
    var url="api/menu/GetSubMenuItems";
    return this.http.post<Response<Submenu[]>>(`${this.baseUrl}${url}`,null);
  }
  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
   }
   Getmenus() {
    var url="api/menu/GetMenuItems";
    return this.http.post<Response<Menu[]>>(`${this.baseUrl}${url}`,null);
  }
  Getmenu(id: any) {
    var url="api/menu/GetMenuItem/";
    return this.http.post<Response<Menu>>(`${this.baseUrl}${url}${id}`,null);
  }
  SaveMenu(menu: Menu) {
    var url="api/menu/SaveMenuItems";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,menu);
  }
  SaveMenumap(data: any) {
    var url="api/menu/SaveMenumap";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,data);
  }
  getMenuMap(GroupId: number) {
    var url="api/menu/GetMenuMap/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}${GroupId}`,null);
  }
  GetLeftmenu(user: number, BranchesId: number) {
    var url="api/menu/GetLeftmenu/"+user+"/"+BranchesId;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,null);

  }

  getShiftStatus(payload: any): Observable<any>{
    var url="api/Bill/GetSettingsforBilling";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }
  getUserPermission(payload) {
    var url="api/MasterData/GetUserFunctionalityPermission";
    return this.http.post<Response<UserGroups[]>>(`${this.baseUrl}${url}`,payload);
  }
  saveUserPermission(payload) {
    var url="api/MasterData/UpdateUserFunctionalityPermission";
    return this.http.post<Response<UserGroups[]>>(`${this.baseUrl}${url}`,payload);
  }
  setResetPassword(payload) {
    var url="api/MasterData/UpdateUserPassword";
    return this.http.post<Response<UserGroups[]>>(`${this.baseUrl}${url}`,payload);
  }
  GetUserData(payload) {
    var url="api/MasterData/GetUserProfile";
    return this.http.post<Response<UserGroups[]>>(`${this.baseUrl}${url}`,payload);
  }

}
