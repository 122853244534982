import { Component, Input, OnInit } from '@angular/core';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { OphthalmologyService } from '../../services/ophthalmology.service';
import { Response } from '../../../../_Models/Response';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EMRService } from '../../services/emr.service';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import _ from 'lodash';

@Component({
  selector: 'app-vision-acuity',
  templateUrl: './vision-acuity.component.html',
  styleUrls: ['./vision-acuity.component.scss']
})
export class VisionAcuityComponent implements OnInit {

  @Input() globals: globalvars;

  public EMRpatientInfo: EMRPatientInfo;

  public visionAcuityForm: FormGroup;

  public unaidedDropdownData: any[] = [];
  public ocularMotilityDropdownData: any[] = [];
  public strabismusDropdownData: any[] = [];
  public visualFieldDropdownData: any[] = [];
  public withGlassesDropdownData: any[] = [];
  public withRefractionDropdownData: any[] = [];
  public nearVisionWithNearAidDropdownData: any[] = [];

  private FormValueChangeChecker: boolean = false;

  constructor(private ophthalmologyService: OphthalmologyService,
              private formbuilder: FormBuilder,
              private EmrService: EMRService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.EMRpatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initForm();

    this.getUnaidedDropdown();
    this.getWithGlassesDropdown();
    this.getWithRefractionDropdown();
    this.getNearVisionWithNearAidDropdown();
    this.getOccularMotilityDropdown();
    this.getStrabismusDropdown();
    this.getVisualFieldDropdown();

    this.getVisionAcuity();

  }
  
  initForm(){
    this.visionAcuityForm = this.formbuilder.group({
      VAId: new FormControl(0),
      rightUnaided: new FormControl(''),
      leftUnaided: new FormControl(''),
      rightPowerWithGlass: new FormControl(''),
      leftPowerWithGlass: new FormControl(''),
      rightPowerWithRefraction: new FormControl(''),
      leftPowerWithRefraction: new FormControl(''),
      rightNearVisionNearAid: new FormControl(''),
      leftNearVisionNearAid: new FormControl(''),
      rightOcularMotility: new FormControl(''),
      leftOcularMotility: new FormControl(''),
      rightStrabismus: new FormControl(''),
      leftStrabismus: new FormControl(''),
      rightVisualField: new FormControl(''),
      leftVisualField: new FormControl(''),
    })
  }

  ////////////////////////////// Dropdown data api calls starts ///////////////////////////////

  getUnaidedDropdown(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getUnaidedDropdown(payload).subscribe({
      next: (response: Response<any>)=>{
        this.unaidedDropdownData = response.response;
      }
    })
  }

  getOccularMotilityDropdown(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getOcularMotulityDropdown(payload).subscribe({
      next: (response: Response<any>)=>{
        this.ocularMotilityDropdownData = response.response;
      }
    })
  }

  getStrabismusDropdown(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getStrabismusDropdown(payload).subscribe({
      next: (response: Response<any>)=>{
        this.strabismusDropdownData = response.response;
      }
    })
  }

  getVisualFieldDropdown(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getVisualFieldDropdown(payload).subscribe({
      next: (response: Response<any>)=>{
        this.visualFieldDropdownData = response.response;
      }
    })
  }

  getWithGlassesDropdown(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getGetPowerWithGlassDropdown(payload).subscribe({
      next: (response: Response<any>)=>{
        this.withGlassesDropdownData = response.response;
      }
    })
  }

  getWithRefractionDropdown(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getGetPowerWithRefractionDropdown(payload).subscribe({
      next: (response: Response<any>)=>{
        this.withRefractionDropdownData = response.response;
      }
    })
  }

  getNearVisionWithNearAidDropdown(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getNearVisionWithNearAidDropdown(payload).subscribe({
      next: (response: Response<any>)=>{
        this.nearVisionWithNearAidDropdownData = response.response;
      }
    })
  }

    ////////////////////////////// Dropdown data api calls ends ///////////////////////////////

  getVisionAcuity(){
    let payload = {
      PatientId: this.EMRpatientInfo.PatientId,
      ConsultationId: this.EMRpatientInfo.ConsultationId,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getVisionAcuity(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          if(response.response[0]){
            let data = response.response[0];
            let rightEye = _.find(data?.visualActivityDetailList, {eye: 'R'});
            let leftEye = _.find(data?.visualActivityDetailList, {eye: 'L'});
            this.visionAcuityForm.patchValue({
              VAId: data?.vaId,
              rightUnaided: rightEye?.unAidedId != 0 ? rightEye?.unAidedId : '',
              leftUnaided: leftEye?.unAidedId != 0 ? leftEye?.unAidedId : '',
              rightPowerWithGlass: rightEye?.powerwithGlassId != 0 ? rightEye?.powerwithGlassId : '',
              leftPowerWithGlass: leftEye?.powerwithGlassId != 0 ? leftEye?.powerwithGlassId : '',
              rightPowerWithRefraction: rightEye?.pwReffractionId != 0 ? rightEye?.pwReffractionId : '',
              leftPowerWithRefraction: leftEye?.pwReffractionId != 0 ? leftEye?.pwReffractionId : '',
              rightNearVisionNearAid: rightEye?.nvwnAid != 0 ? rightEye?.nvwnAid : '',
              leftNearVisionNearAid: leftEye?.nvwnAid != 0 ? leftEye?.nvwnAid : '',
              rightOcularMotility: rightEye?.ocularMotilityId != 0 ? rightEye?.ocularMotilityId : '',
              leftOcularMotility: leftEye?.ocularMotilityId != 0 ? leftEye?.ocularMotilityId : '',
              rightStrabismus: rightEye?.strabismusId != 0 ? rightEye?.strabismusId : '',
              leftStrabismus: leftEye?.strabismusId != 0 ? leftEye?.strabismusId : '',
              rightVisualField: rightEye?.visualFieldId != 0 ? rightEye?.visualFieldId : '',
              leftVisualField: leftEye?.visualFieldId != 0 ? leftEye?.visualFieldId : ''
            });
          }
          this.visionAcuityForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker = true;
          })
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

  onSaveVisionAcuity(){
    if(this.EMRpatientInfo.CrudType == 3 &&
      this.EMRpatientInfo.VisitId == 0 && !this.FormValueChangeChecker){
      return
    }

    let payload = {
      VAId: Number(this.visionAcuityForm.get('VAId').value),
      PatientId: this.EMRpatientInfo.PatientId,
      ConsultationId: this.EMRpatientInfo.ConsultationId,
      VisitId: this.EMRpatientInfo.VisitId,
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals.Location.locationId,
      BranchId: this.globals.HospitalId,
      VisualActivityDetailList:[
        {
          Eye: 'R',
          UnAidedId: Number(this.visionAcuityForm.get('rightUnaided').value),
          PowerwithGlassId: Number(this.visionAcuityForm.get('rightPowerWithGlass').value),
          PWReffractionId: Number(this.visionAcuityForm.get('rightPowerWithRefraction').value),
          NVWNAid: Number(this.visionAcuityForm.get('rightNearVisionNearAid').value),
          OcularMotilityId: Number(this.visionAcuityForm.get('rightOcularMotility').value),
          StrabismusId: Number(this.visionAcuityForm.get('rightStrabismus').value),
          VisualFieldId: Number(this.visionAcuityForm.get('rightVisualField').value),
          VADId: 0
        },
        {
          Eye: 'L',
          UnAidedId: Number(this.visionAcuityForm.get('leftUnaided').value),
          PowerwithGlassId:Number( this.visionAcuityForm.get('leftPowerWithGlass').value),
          PWReffractionId: Number(this.visionAcuityForm.get('leftPowerWithRefraction').value),
          NVWNAid: Number(this.visionAcuityForm.get('leftNearVisionNearAid').value),
          OcularMotilityId: Number(this.visionAcuityForm.get('leftOcularMotility').value),
          StrabismusId: Number(this.visionAcuityForm.get('leftStrabismus').value),
          VisualFieldId: Number(this.visionAcuityForm.get('leftVisualField').value),
          VADId: 0
        },
      ]
    }

    this.ophthalmologyService.saveVisionAcuity(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == 'Success'){
          this.visionAcuityForm.patchValue({
            VAId: response.response[0].vaId
          });
          this.FormValueChangeChecker = false;
          this.toastrService.success('Vision Acuity Details Saved Successfully');
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

}
