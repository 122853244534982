import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-web-camera-modal',
  templateUrl: './web-camera-modal.component.html',
  styleUrls: ['./web-camera-modal.component.scss']
})
export class WebCameraModalComponent implements OnInit {

  public fileEmitter: EventEmitter<any> = new EventEmitter();

  public isImageCaptured: boolean = false;

  private myObservable$: Subject<unknown>;
  private trigger: Subject<void> = new Subject<void>();

  public webcamImage: WebcamImage = null;
  private imageFile: File;

  constructor(private activeModal: BsModalRef) { }

  ngOnInit(): void {
  }

  triggerSnapshot(): void {
    this.myObservable$ = new Subject();
    this.myObservable$.next();
    this.trigger.next();
    this.isImageCaptured = true;
  }

  reCapture(){
    this.isImageCaptured = false;
    this.webcamImage = null;
    this.imageFile = null;
  }

  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    const imageName = "patient.jpeg";// new Date().toString();
    const base64 = webcamImage.imageAsDataUrl.split('base64,');
    const imageBlob = this.dataURItoBlob(base64[1]);
    const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    this.imageFile = imageFile;
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  onSaveImage(){
    this.fileEmitter.emit({
      status: true,
      file: this.imageFile
    });
    this.close();
  }

  close(){
    this.activeModal.hide();
  }
}
