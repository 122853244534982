import { Component, OnInit, TemplateRef, ViewChild,Input } from '@angular/core';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from '../../emr/services/emr.service';
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { PhysicalExamination } from '../interfaces/PhysicalExamination';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';

@Component({
  selector: 'app-general-physical-communication',
  templateUrl: './general-physical-communication.component.html',
  styleUrls: ['./general-physical-communication.component.scss']
})
export class GeneralPhysicalCommunicationComponent implements OnInit {
  @Input() IsViewtab;
  EMRPatientInfo:EMRPatientInfo;

  form: FormGroup; // form variable
  PhysicalExamination:PhysicalExamination;
  PhysicalExaminationModalVal:PhysicalExamination;
  PrevPhysicalExaminationDetails:PhysicalExamination[]=[];

  Loading:boolean=false;
  FormValueChangeChecker:boolean=false;
  modalRef?: BsModalRef;
  @ViewChild("template") modaltemp: any;
  IsDisabled: boolean = true;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  
  constructor(
    private EMRService:EMRService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private sharedservice: SharedService,
  ) {
    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });
   }

  ngOnInit(): void {

    if (this.IsViewtab == true) {
      this.IsDisabled = false;
    } else if (this.IsViewtab == "undefined") {
      this.IsDisabled = true;
    }

    this.form= new FormGroup({
        peId: new FormControl(0),
        constitution: new FormControl(""),
        gastrointestinial: new FormControl(""),
        genitourinary: new FormControl(""),
        eyes: new FormControl(""),
        ent: new FormControl(""),
        neck: new FormControl(""),
        skin: new FormControl(""),
        breast: new FormControl(""),
        respiratory: new FormControl(""),
        muscleoskle: new FormControl(""),
        psychiarty: new FormControl(""),
        cardiovascular: new FormControl(""),
        neurological: new FormControl(""),
        hemotology: new FormControl(""),
        thyroid: new FormControl(""),
        abdomen: new FormControl(""),
        pelvis: new FormControl(""),
        others: new FormControl(""),
        visitId: new FormControl(this.EMRPatientInfo.VisitId),
        userId: new FormControl(Number(localStorage.getItem('user_id'))),
      });

      this.GetPhysicalExaminationDetails();
      this.GetPreviousPhysicalExaminationDetails();
  }

 SavePhysicalCommunication()
  {

    if(
      this. EMRPatientInfo.CrudType!=3 &&
      this.EMRPatientInfo.VisitId>0 && this.Loading && this.FormValueChangeChecker)
    {
      this.PhysicalExamination=this.form.getRawValue();
      this.PhysicalExamination.userId=Number(localStorage.getItem('user_id'));
      this.EMRService.SavePhysicalExaminationDetails(
        this.PhysicalExamination
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastr.success("Success: " + "Physical Examination Details Saved Successfully...");
          }
          else{
            Swal.fire(
              'Error!',
              'Some unexpected error occures! try again later.',
              'error'
            )
          }
        });
    }
    else{
      if(!this.FormValueChangeChecker){
        //this.toastr.warning("Info: " + "No changes detected in the entry");
      }
  }
  }

  GetPhysicalExaminationDetails()
  {
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };

    this.EMRService.GetPhysicalExaminationDetails(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.form.patchValue(response);
          this.Loading=true;
          this.form.valueChanges.subscribe(x => {
            this.FormValueChangeChecker=true;
           })
        }
      });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  GetPreviousPhysicalExaminationDetails()
  {
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":0
    };
    this.EMRService.GetPhysicalExaminationDetails(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.PrevPhysicalExaminationDetails=res.response;
        }
      });

  }

  openModal(VisitId) {

    let input={
      "VisitId":VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };
    this.EMRService.GetPhysicalExaminationDetails(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.PhysicalExaminationModalVal=response;
          this.modalRef = this.modalService.show(
            this.modaltemp,
            {class: 'modal-lg modal-custom-style' }
          );
        }
      });


  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
