import { Location } from "../../modules/client/interfaces/Location";

export class globalvars
{
    HospitalId:number;
    ClientId: number;
    HospitalName:string;
    Location:Location;
    globalvars()
    {
      this.HospitalId=0;
      this.ClientId = 0;
      this.HospitalName='';  
      this.Location=new Location();
    }
}