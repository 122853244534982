import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EMRPatientInfo } from '../../../interfaces/EMRPatientInfo';
import { EMRService } from '../../../services/emr.service';
import { ViewVitalSignModalComponent } from '../view-vital-sign-modal/view-vital-sign-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prev-vital-signs-modal',
  templateUrl: './prev-vital-signs-modal.component.html',
  styleUrls: ['./prev-vital-signs-modal.component.scss']
})
export class PrevVitalSignsModalComponent implements OnInit {

  @Input() EmrPatientInfo: EMRPatientInfo;

  public vitalSignsList: any[] = [];
  public chiefComplaintLoading: boolean = false;

  constructor(private activeModalRef: BsModalRef,
              private EmrService: EMRService,
              private modalService: BsModalService,
              private router: Router,) { }

  ngOnInit(): void {
    this.GetPrevVitalSigns();
  }

  GetPrevVitalSigns()
  {
    let input;

    if (this.router.url == '/Treatment_Dashboard') {
      input = {
        // "VisitId": this.EmrPatientInfo.VisitId,
        "VisitId": 0,
        "PatientId": this.EmrPatientInfo.PatientId,
        "ShowAll": 1
      };
    } else {
      input = {
        // "VisitId": this.EmrPatientInfo.VisitId,
        "VisitId": 0,
        "PatientId": this.EmrPatientInfo.PatientId,
        // "ShowAll": 0
        "ShowAll": 1
      };
    }
    this.EmrService.GetPrevVitalSigns(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.vitalSignsList=res.response;
        }
      });
  }


  close(){
    this.activeModalRef.hide();
  }

  viewDetails(entryId: any, consultationId,eid){
    let initialState = {
      entryId: entryId,
      patientId: this.EmrPatientInfo.PatientId,
      consultationId: consultationId,
      eid:eid
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const modalRef= this.modalService.show(ViewVitalSignModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-md', initialState }) );
  }

}
