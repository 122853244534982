import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EMRPatientInfo } from '../../../interfaces/EMRPatientInfo';
import { EMRService } from '../../../services/emr.service';
import { ViewMenstrualHistoryModalComponent } from '../view-menstrual-history-modal/view-menstrual-history-modal.component';

@Component({
  selector: 'app-prev-menstrual-history-modal',
  templateUrl: './prev-menstrual-history-modal.component.html',
  styleUrls: ['./prev-menstrual-history-modal.component.scss']
})
export class PrevMenstrualHistoryModalComponent implements OnInit {

  @Input() EmrPatientInfo: EMRPatientInfo;

  public menstrualList: any[] = [];

  constructor(private activeModalRef: BsModalRef,
              private EmrService: EMRService,
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.GetPrevMenstrualHistory();
  }

  close(){
    this.activeModalRef.hide();
  }

  GetPrevMenstrualHistory()
  {
    let input={
      "VisitId":this.EmrPatientInfo.VisitId,
      "PatientId":this.EmrPatientInfo.PatientId,
      "ShowAll":0
    };

    this.EmrService.GetMenstrualHistory(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.menstrualList=res.response;

        }
      });
  }

  viewDetails(entryId: any){
    let initialState = {
      entryId: entryId,
      patientId: this.EmrPatientInfo.PatientId
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const modalRef= this.modalService.show(ViewMenstrualHistoryModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

}
