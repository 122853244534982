import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../client/general-action/general-action.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfigurationService } from "../../../client/services/configuration.service";
import { ToastrService } from "ngx-toastr";
import { data } from 'jquery';

@Component({
  selector: 'app-result-form',
  templateUrl: './result-addParameter.component.html',
  styleUrls: ['./result-addParameter.component.scss']
})
export class ResultAddParameterComponent implements OnInit {

  @Input() addParameterPopupHeading: any = "";
  @Input() RformId: any = 0;
  @Input() UserId: any = 0;
  @Input() ParamId: any = 0;
  @Output() parameterEmitter = new EventEmitter()
  @ViewChild("template") modaltemp: any;
  resultForm: FormGroup;
  parameterForm: FormGroup;
  clarityData: any;
  dataId: number;
  public gridApi;
  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];
  parameterColumnDefs: ColDef[] = [];
  AddparameterColumnDefs: ColDef[] = [];
  public submitted: boolean = false;
  parameterList: any;
  resultFormFata: any;
  parameterData: any;
  rowGroupPanelShow = "always";
  public context;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    // rowClassRules: {
    // },
  }
  isValidated = true;
  result: any;
  resultTyprData: any;
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  isLoading = false;
  ResultType: any;
  public isSaved: boolean = false;
  haderList: any;
  dropdownSettings: any = {
    singleSelection: false,
    minWidth: 180,
    idField: "id",
    textField: "displayName",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  checkboxItemData:any=[]

  constructor(private modalRef: BsModalRef,
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.resultForm = new FormGroup({
      ResultType: new FormControl(0),
      RformId: new FormControl(this.RformId),
      ParamId: new FormControl(this.ParamId),
      GroupId: new FormControl(0),
      ParamName: new FormControl(''),
      Description: new FormControl(''),
      ParamUnit: new FormControl(''),
      MethodName: new FormControl(''),
      ParamOrder: new FormControl(''),
      IsDisplayed: new FormControl(true),
      IsAccredited: new FormControl(false),
      UserId: new FormControl(this.UserId),
      colourDetails: new FormArray([]),
      rangeDetails: new FormArray([]),
      positiveDetails: new FormArray([]),
      clarityDetails: new FormArray([]),
      consistencyDetails: new FormArray([]),
      presenceDetails: new FormArray([]),
      reactionDetails: new FormArray([]),
      lessthanDetails:new FormArray([]),
      checklistDetails:new FormArray([]),
      defaultValue: new FormControl(''),
      defaultValue1: new FormControl(''),
      defaultValue2: new FormControl(''),
      RFHeaderId:new FormControl(0)
    });
    this.AddparameterColumnDefs = [
      {
        headerName: "Combo/Checkbox List Value",
        minWidth: 180,
        field: "nameData",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
    ]
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    if (this.ParamId == 0) {
      this.addMapFormGroup();
      this.addRangeMapFormGroup();
      this.addPositiveMapFormGroup();
      this.addClarityMapFormGroup();
      this.addconsistencyMapFormGroup();
      this.addPresenceMapFormGroup();
      this.addReactionMapFormGroup();
      this.addLessthanDetailsMapFormGroup()
      this.addchecklistDetails()
    }

    this.getResultType();
    this.editparameter();
    this.getHeaderlist()
    this.getCheckListResultTypeData()
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  close() {
    this.modalRef.hide();
  }
  // openModalParametrCompany() {
  //   this.addParameterPopupHeading="Add Parameter";
  //   // const modalRef = this.modalService.show(ResultModalComponent, Object.assign({ class: "gray modal-xl" },))
  // }
  new() {
    this.addMapFormGroup()
  }
  public addMapFormGroup(data?: any[]) {
    const rangedatas = this.resultForm.get("colourDetails") as FormArray;
    rangedatas.push(this.createListGroup(data));
  }
  private createListGroup(data?): FormGroup {
    return new FormGroup({
      fromAge: new FormControl(data?.fromAge ? data.fromAge : ''),
      toAge: new FormControl(data?.toAge ? data.toAge : ''),
      gender: new FormControl(data?.gender ? data.gender : ""),
      colour: new FormControl(data?.colour ? data.colour : 0),
      colourRemarks: new FormControl(data?.colourRemarks ? data.colourRemarks : ''),
      ReferenceIntervalDescription: new FormControl(data?.referenceIntervalDescription ? data.referenceIntervalDescription : ''),
    });
  }
  newrangeDetails() {
    this.addRangeMapFormGroup()
  }
  public addRangeMapFormGroup(data?: any[]) {
    const rangedatas = this.resultForm.get("rangeDetails") as FormArray;
    rangedatas.push(this.createRangeListGroup(data));
  }
  private createRangeListGroup(data?): FormGroup {
    return new FormGroup({
      fromAge: new FormControl(data?.fromAge ? data.fromAge : ''),
      toAge: new FormControl(data?.toAge ? data.toAge : ''),
      gender: new FormControl(data?.gender ? data.gender : ""),
      fromRange: new FormControl(data?.fromRange ? data.fromRange : ''),
      toRange: new FormControl(data?.toRange ? data.toRange : ''),
      fromRangeRemarks: new FormControl(data?.fromRangeRemarks ? data.fromRangeRemarks : ''),
      toRangeRemarks: new FormControl(data?.toRangeRemarks ? data.toRangeRemarks : ''),
      ReferenceIntervalDescription: new FormControl(data?.referenceIntervalDescription ? data.referenceIntervalDescription : ''),
    });
  }
  newPositiveDetails() {
    this.addPositiveMapFormGroup()
  }
  public addPositiveMapFormGroup(data?: any[]) {
    const rangedatas = this.resultForm.get("positiveDetails") as FormArray;
    rangedatas.push(this.createpositiveListGroup(data));

  }
  private createpositiveListGroup(data?): FormGroup {
    return new FormGroup({

      fromAge: new FormControl(data?.fromAge ? data.fromAge : ''),
      toAge: new FormControl(data?.toAge ? data.toAge : ''),
      gender: new FormControl(data?.gender ? data.gender : ""),
      normalValue: new FormControl(data?.normalvalue ? data.normalvalue : 0),
      normalValueRemarks: new FormControl(data?.normalValueRemarks ? data.normalValueRemarks : ""),
      ReferenceIntervalDescription: new FormControl(data?.referenceIntervalDescription ? data.referenceIntervalDescription : ""),
    });
  }
  newConsistencyDetails() {
    this.addconsistencyMapFormGroup()
  }
  public addconsistencyMapFormGroup(data?: any[]) {
    const rangedatas = this.resultForm.get("consistencyDetails") as FormArray;
    rangedatas.push(this.createconsistencyListGroup(data));

  }
  private createconsistencyListGroup(data?): FormGroup {
    return new FormGroup({

      fromAge: new FormControl(data?.fromAge ? data.fromAge : ''),
      toAge: new FormControl(data?.toAge ? data.toAge : ''),
      gender: new FormControl(data?.gender ? data.gender : ""),
      consistency: new FormControl(data?.consistency ? data.consistency : 0),
      consistencyRemarks: new FormControl(data?.consistencyRemarks ? data.consistencyRemarks : ''),
      ReferenceIntervalDescription: new FormControl(data?.referenceIntervalDescription ? data.referenceIntervalDescription : ''),
    });
  }
  newReactionDetails() {
    this.addReactionMapFormGroup()
  }
  public addReactionMapFormGroup(data?: any[]) {
    const rangedatas = this.resultForm.get("reactionDetails") as FormArray;
    rangedatas.push(this.createReactionListGroup(data));

  }
  private createReactionListGroup(data?): FormGroup {
    return new FormGroup({

      fromAge: new FormControl(data?.fromAge ? data.fromAge : ''),
      toAge: new FormControl(data?.toAge ? data.toAge : ''),
      gender: new FormControl(data?.gender ? data.gender : ""),
      reaction: new FormControl(data?.reaction ? data.reaction : 0),
      reactionRemarks: new FormControl(data?.reactionRemarks ? data.reactionRemarks : ''),
      ReferenceIntervalDescription: new FormControl(data?.referenceIntervalDescription ? data.referenceIntervalDescription : ''),

    });
  }

  newPresenceDetails() {
    this.addPresenceMapFormGroup()
  }
  public addPresenceMapFormGroup(data?: any[]) {
    const rangedatas = this.resultForm.get("presenceDetails") as FormArray;
    rangedatas.push(this.createPresenceListGroup(data));

  }
  private createPresenceListGroup(data?): FormGroup {
    return new FormGroup({

      fromAge: new FormControl(data?.fromAge ? data.fromAge : ''),
      toAge: new FormControl(data?.toAge ? data.toAge : ''),
      gender: new FormControl(data?.gender ? data.gender : ""),
      presence: new FormControl(data?.presence ? data.presence : 0),
      presenceRemarks: new FormControl(data?.presenceRemarks ? data.presenceRemarks : ''),
      ReferenceIntervalDescription: new FormControl(data?.referenceIntervalDescription ? data.referenceIntervalDescription : ''),
    });
  }

  newClarityDetails() {
    this.addClarityMapFormGroup()
  }
  public addClarityMapFormGroup(data?: any[]) {
    const rangedatas = this.resultForm.get("clarityDetails") as FormArray;
    rangedatas.push(this.createClarityListGroup(data));

  }
  private createClarityListGroup(data?): FormGroup {
    return new FormGroup({

      fromAge: new FormControl(data?.fromAge ? data.fromAge : ''),
      toAge: new FormControl(data?.toAge ? data.toAge : ''),
      gender: new FormControl(data?.gender ? data.gender : ""),
      clarity: new FormControl(data?.clarity ? data.clarity : 0),
      clarityRemarks: new FormControl(data?.clarityRemarks ? data.clarityRemarks : ""),
      ReferenceIntervalDescription: new FormControl(data?.referenceIntervalDescription ? data.referenceIntervalDescription : ""),
    });
  }
  RemoveFormArray(index, data?) {
    var Value = new FormArray([]);
    Value = this.resultForm.get(data) as FormArray;
    Value.removeAt(index);
  }

  public addLessthanDetailsMapFormGroup(data?: any[]) {
    const rangedatas = this.resultForm.get("lessthanDetails") as FormArray;
    rangedatas.push(this.createLessthanDetails(data));
  }
  private createLessthanDetails(data?): FormGroup {
    return new FormGroup({
      fromAge: new FormControl(data?.fromAge ? data.fromAge : ''),
      toAge: new FormControl(data?.toAge ? data.toAge : ''),
      gender: new FormControl(data?.gender ? data.gender : ""),
      lessthan: new FormControl(data?.lessthan ? data.lessthan : ""),
      lessthanRemarks: new FormControl(data?.lessthanRemarks ? data.lessthanRemarks : ''),
      ReferenceIntervalDescription: new FormControl(data?.referenceIntervalDescription ? data.referenceIntervalDescription : ''),
    });
  }

  public addchecklistDetails(data?: any[]) {
    const rangedatas = this.resultForm.get("checklistDetails") as FormArray;
    rangedatas.push(this.createchecklistDetails(data));
  }
  private createchecklistDetails(data?): FormGroup {
    debugger
    let checklist=data?.checklist ? data.checklist : ""
    let selectedItems=this.checkboxItemData.filter(item => checklist.includes(item.id));
    return new FormGroup({
      fromAge: new FormControl(data?.fromAge ? data.fromAge : ''),
      toAge: new FormControl(data?.toAge ? data.toAge : ''),
      gender: new FormControl(data?.gender ? data.gender : ""),
      checklist: new FormControl(data?.checklist ? selectedItems : []),
      checklistRemarks: new FormControl(data?.checklistRemarks ? data.checklistRemarks : ''),
      ReferenceIntervalDescription: new FormControl(data?.referenceIntervalDescription ? data.referenceIntervalDescription : ''),
    });
  }

  getResultType() {
    this.configurationService.GetResultType().subscribe((res) => {
      this.resultTyprData = [...res.response];
    });
  }
  getDataType() {
    
    if(this.resultForm.get('ResultType').value == 9){
      this.resultForm.patchValue({
        defaultValue:"<"
      })
    }else{
      // this.resultForm.patchValue({
      //   defaultValue:""
      // })
    }
    let payload = {
      id: Number(this.resultForm.get('ResultType').value)
    }
    this.configurationService.GetResultTypeData(payload).subscribe((res) => {
      this.clarityData = res.response;
    });

  }
  //save parameter//
  saveParameter() {
    this.isValidated = true
    this.submitted = true;
    if (this.submitted = true && this.resultForm && this.resultForm.valid && this.isSaved == false) {
      var formdata = this.resultForm.getRawValue();
      formdata.ResultType = Number(formdata.ResultType)
      formdata.ParamOrder = Number(formdata.ParamOrder)
      formdata.UserId = Number(formdata.UserId)
      formdata.RFHeaderId=Number(formdata.RFHeaderId)
      formdata.colourDetails = formdata.colourDetails.map((item) => {
        return {
          ...item,
          fromAge: Number(item.fromAge),
          toAge: Number(item.toAge),
          gender: Number(item.gender),
          colour: Number(item.colour),
          colourRemarks: item.colourRemarks,
          ReferenceIntervalDescription: item.ReferenceIntervalDescription
        }
      })
      formdata.lessthanDetails = formdata.lessthanDetails.map((item) => {
        return {
          ...item,
          fromAge: Number(item.fromAge),
          toAge: Number(item.toAge),
          gender: Number(item.gender),
          lessthan: Number(item.lessthan),
          lessthanRemarks: item.lessthanRemarks,
          ReferenceIntervalDescription: item.ReferenceIntervalDescription
        }
      })
      formdata.checklistDetails = formdata.checklistDetails.map((item) => {
        return {
          ...item,
          fromAge: Number(item.fromAge),
          toAge: Number(item.toAge),
          gender: Number(item.gender),

          checklist: item.checklist.map(data=>Number(data.id)),
          checklistRemarks: item.checklistRemarks,
          ReferenceIntervalDescription: item.ReferenceIntervalDescription
        }
      })

      formdata.rangeDetails = formdata.rangeDetails.map((item) => {
        return {
          ...item,
          fromAge: Number(item.fromAge),
          toAge: Number(item.toAge),
          gender: Number(item.gender),
        }
      })
      formdata.positiveDetails = formdata.positiveDetails.map((item) => {
        return {
          ...item,
          fromAge: Number(item.fromAge),
          toAge: Number(item.toAge),
          gender: Number(item.gender),
          normalValue: Number(item.normalValue),
          normalValueRemarks: item.normalValueRemarks,
          ReferenceIntervalDescription: item.ReferenceIntervalDescription
        }
      })
      formdata.clarityDetails = formdata.clarityDetails.map((item) => {
        return {
          ...item,
          fromAge: Number(item.fromAge),
          toAge: Number(item.toAge),
          gender: Number(item.gender),
          clarity: Number(item.clarity),
          clarityRemarks: item.clarityRemarks,
          ReferenceIntervalDescription: item.ReferenceIntervalDescription
        }
      })
      formdata.consistencyDetails = formdata.consistencyDetails.map((item) => {
        return {
          ...item,
          fromAge: Number(item.fromAge),
          toAge: Number(item.toAge),
          gender: Number(item.gender),
          consistency: Number(item.consistency),
          consistencyRemarks: item.consistencyRemarks,
          ReferenceIntervalDescription: item.ReferenceIntervalDescription
        }
      })
      formdata.presenceDetails = formdata.presenceDetails.map((item) => {
        return {
          ...item,
          fromAge: Number(item.fromAge),
          toAge: Number(item.toAge),
          gender: Number(item.gender),
          presence: Number(item.presence),
          presenceRemarks: item.presenceRemarks,
          ReferenceIntervalDescription: item.ReferenceIntervalDescription
        }
      })
      formdata.reactionDetails = formdata.reactionDetails.map((item) => {
        return {
          ...item,
          fromAge: Number(item.fromAge),
          toAge: Number(item.toAge),
          gender: Number(item.gender),
          reaction: Number(item.reaction),
          reactionRemarks: item.reactionRemarks,
          ReferenceIntervalDescription: item.ReferenceIntervalDescription
        }
      })

      if (formdata.ResultType == 0) {
        this.isValidated = false;
        this.toastr.warning("Please Select Result Type");
      }
      else if (formdata.ResultType == 1) {

        let colorItem = formdata.colourDetails.filter((item) => item.colour == 0)
        let fromAge = formdata.colourDetails.filter((item) => item.fromAge == 0)
        let toAge = formdata.colourDetails.filter((item) => item.toAge == 0)
        let gender = formdata.colourDetails.filter((item) => item.gender == 0)

        if (colorItem.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select color");
        }
        if (fromAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select From Age ");
        }
        if (toAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select  To Age ");
        }
        if (gender.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Gender ");
        }

      }
      else if (formdata.ResultType == 2) {
        let toRange = formdata.rangeDetails.filter((item) => item.toRange == 0)
        let fromRange = formdata.rangeDetails.filter((item) => item.fromRange == 0)
        let fromAge = formdata.rangeDetails.filter((item) => item.fromAge == 0)
        let toAge = formdata.rangeDetails.filter((item) => item.toAge == 0)
        let gender = formdata.rangeDetails.filter((item) => item.gender == 0)

        // if (toRange.length > 0) {
        //   this.isValidated = false;

        //   this.toastr.warning("Please Select To Range ");
        // }
        // if (fromRange.length > 0) {
        //   this.isValidated = false;

        //   this.toastr.warning("Please Select From Range ");
        // }

        if (fromAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select From Age ");
        }
        if (toAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select To Age ");
        }
        if (gender.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Gender ");
        }

      }
      else if (formdata.ResultType == 3) {
        let normalValue = formdata.positiveDetails.filter((item) => item.normalValue == 0)
        let fromAge = formdata.positiveDetails.filter((item) => item.fromAge == 0)
        let toAge = formdata.positiveDetails.filter((item) => item.toAge == 0)
        let gender = formdata.positiveDetails.filter((item) => item.gender == 0)

        if (normalValue.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Normal Value ");
        }
        if (fromAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select From Age ");
        }
        if (toAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select To Age ");
        }
        if (gender.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Gender ");
        }

      }
      else if (formdata.ResultType == 4) {
        let clarity = formdata.clarityDetails.filter((item) => item.clarity == 0)
        let fromAge = formdata.clarityDetails.filter((item) => item.fromAge == 0)
        let toAge = formdata.clarityDetails.filter((item) => item.toAge == 0)
        let gender = formdata.clarityDetails.filter((item) => item.gender == 0)

        if (clarity.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Clarity");
        }
        if (fromAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please  SelectFrom Age ");
        }
        if (toAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select To Age ");
        }
        if (gender.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Gender ");
        }
      }

      else if (formdata.ResultType == 5) {
        let consistency = formdata.consistencyDetails.filter((item) => item.consistency == 0)
        let fromAge = formdata.consistencyDetails.filter((item) => item.fromAge == 0)
        let toAge = formdata.consistencyDetails.filter((item) => item.toAge == 0)
        let gender = formdata.consistencyDetails.filter((item) => item.gender == 0)

        if (consistency.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Consistency");
        }
        if (fromAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select From Age ");
        }
        if (toAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select To Age ");
        }
        if (gender.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Gender ");
        }
      }
      else if (formdata.ResultType == 6) {
        let presence = formdata.presenceDetails.filter((item) => item.presence == 0)
        let fromAge = formdata.presenceDetails.filter((item) => item.fromAge == 0)
        let toAge = formdata.presenceDetails.filter((item) => item.toAge == 0)
        let gender = formdata.presenceDetails.filter((item) => item.gender == 0)

        if (presence.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Presence");
        }
        if (fromAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select From Age ");
        }
        if (toAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select To Age ");
        }
        if (gender.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Gender ");
        }
      }
      else if (formdata.ResultType == 8) {
        let checklistDetails = formdata.checklistDetails.filter((item) => item.checklist == 0)
        let fromAge = formdata.checklistDetails.filter((item) => item.fromAge == 0)
        let toAge = formdata.checklistDetails.filter((item) => item.toAge == 0)
        let gender = formdata.checklistDetails.filter((item) => item.gender == 0)

        if (checklistDetails.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please select");
        }
        if (fromAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select From Age ");
        }
        if (toAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select  To Age ");
        }
        if (gender.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Gender ");
        }

      }
      else if (formdata.ResultType == 9) {

        let lessthanItem = formdata.lessthanDetails.filter((item) => item.lessthan == 0)
        let fromAge = formdata.lessthanDetails.filter((item) => item.fromAge == 0)
        let toAge = formdata.lessthanDetails.filter((item) => item.toAge == 0)
        let gender = formdata.lessthanDetails.filter((item) => item.gender == 0)

        if (lessthanItem.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Enter lessthan value");
        }
        if (fromAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select From Age ");
        }
        if (toAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select  To Age ");
        }
        if (gender.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Gender ");
        }

      }
      else {
        let reaction = formdata.reactionDetails.filter((item) => item.reaction == 0)
        let fromAge = formdata.reactionDetails.filter((item) => item.fromAge == 0)
        let toAge = formdata.reactionDetails.filter((item) => item.toAge == 0)
        let gender = formdata.reactionDetails.filter((item) => item.gender == 0)

        if (reaction.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Reaction");
        }
        if (fromAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select From Age ");
        }
        if (toAge.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select To Age ");
        }
        if (gender.length > 0) {
          this.isValidated = false;

          this.toastr.warning("Please Select Gender ");
        }
      }
      if (formdata.ResultType == 2) {
        if((formdata.defaultValue1 == '' && formdata.defaultValue1 !== 0) || formdata.defaultValue1 == null || formdata.defaultValue1 == undefined){
          formdata.defaultValue1 = ''
        } 
        if((formdata.defaultValue2 == '' && formdata.defaultValue2 !== 0) || formdata.defaultValue2 == null || formdata.defaultValue2 == undefined){
          formdata.defaultValue2 = ''
        } 
          formdata.defaultValue = formdata.defaultValue1 + "-" + formdata.defaultValue2;

        console.log(formdata.defaultValue);

      }
      if (this.isValidated == true) {
        let dataid = 0;
        dataid = formdata.ParamId;
        this.isLoading = true;

        this.configurationService.saveParameter(formdata).subscribe((res) => {
          this.submitted = false;

          this.result = res;
          if (this.result.status == 200 && this.result.message == 'Success') {
            if (dataid == 0) {
              this.isLoading = false;
              this.isSaved = true;

              this.toastr.success("Added successfully");
            }
            else {
              this.isLoading = false;
              this.isSaved = true;

              this.toastr.success("Edited successfully");
              this.ParamId = 0;
            }
            this.parameterEmitter.emit({ status: true });
            this.close();
          }
          else {
            this.isLoading = false;

            this.toastr.error("Failed : " + this.result.message);
          }
        })
      }
    }
  }
  //edit parameter//
  editparameter() {
    let formdata = {
      ParamId: this.ParamId,
    }
    this.configurationService.EditParameterList(formdata).subscribe((res) => {
      let parameterDetails = res.response;
      var str = parameterDetails[0]?.defaultValue
      console.log(str);
      var splitted = str.split("-", 2);
      console.log(splitted)
      this.resultForm.controls['ResultType'].setValue(parameterDetails[0].resultType);
      this.resultForm.controls['Description'].setValue(parameterDetails[0].description);
      this.resultForm.controls['IsDisplayed'].setValue(parameterDetails[0].isDisplayed);
      this.resultForm.controls['IsAccredited'].setValue(parameterDetails[0].isAccredited);
      this.resultForm.controls['MethodName'].setValue(parameterDetails[0].method);
      this.resultForm.controls['ParamId'].setValue(parameterDetails[0].paramId);
      this.resultForm.controls['ParamName'].setValue(parameterDetails[0].paramName);
      this.resultForm.controls['ParamOrder'].setValue(parameterDetails[0].paramOrder);
      this.resultForm.controls['ParamUnit'].setValue(parameterDetails[0].paramUnit);
      this.resultForm.controls['RformId'].setValue(parameterDetails[0].RformId);
      this.resultForm.controls['UserId'].setValue(this.UserId);
      this.resultForm.controls['defaultValue'].setValue(parameterDetails[0].defaultValue);
      this.resultForm.controls['defaultValue1'].setValue(splitted[0]);
      this.resultForm.controls['defaultValue2'].setValue(splitted[1]);
      this.resultForm.controls['RFHeaderId'].setValue(parameterDetails[0].rfHeaderId);
      debugger

      this.getDataType();
      parameterDetails[0].paramDetails.forEach(element => {
        this.addRangeMapFormGroup(element);
        this.addMapFormGroup(element);
        this.addPositiveMapFormGroup(element);
        this.addClarityMapFormGroup(element);
        this.addPresenceMapFormGroup(element);
        this.addconsistencyMapFormGroup(element);
        this.addReactionMapFormGroup(element);
        this.addLessthanDetailsMapFormGroup(element);
        setTimeout(() => {
          this.addchecklistDetails(element)
        }, 1000);
      });


    });
  }
  get f() {
    return this.resultForm.controls;
  }

  getHeaderlist(){
    let payload={
      RformId:this.RformId,
      ShowAll:0
    }
    this.configurationService.GetHeaderList(payload).subscribe({
      next:(res)=>{
        this.haderList=res.response
      }
    })
  }

  getCheckListResultTypeData(){
    let payload={
      id:0
    }
    this.configurationService.GetCheckListResultTypeData(payload).subscribe({
      next:(res)=>{
        if(res.response){
          this.checkboxItemData=res.response
        }
      }
    })
  }

}