import { Component, OnInit , TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { BillingGeneralActionComponent } from '../billing-general-action/billing-general-action.component';

@Component({
  selector: 'app-prior-request',
  templateUrl: './prior-request.component.html',
  styleUrls: ['./prior-request.component.scss']
})
export class PriorRequestComponent implements OnInit {

  modalRef?: BsModalRef;
  @ViewChild("downloadTemplate") modaldownload: any;
  @ViewChild(SearchPatientModalComponent)modaltemp: SearchPatientModalComponent;
  form: FormGroup;
  // AG GRID ITEMS

  public statusBar;
  rowGroupPanelShow = "always";
  public gridApi;
  columnDefs: ColDef[] = [];
  priorDetails=[{
    date:'15-11-2022',
    patientName:'Test Name',
    consultant:'Consultant Test',
    approvalno:' 111',
    reultremarks:'Good',
    cfo:'Abcd'

  }];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  public context;
  public frameworkComponents;
  public loadingTemplate;
  public noRowsTemplate;

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {

    this.context = { componentParent: this };

    this.columnDefs = [
      {
        headerName: "Date",
        field: "date",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      
      {
        headerName: "Patient Name",
        field: "patientName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Consultant",
        field: "consultant",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Approval No",
        field: "approvalno",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Result Remarks",
        field: "reultremarks",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "CFO",
        field: "cfo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "manageprior" },
      },
    ];
    this.frameworkComponents = { buttonRender: BillingGeneralActionComponent};
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{ class: "gray modal-lg" });
  }

  // cancelItem(data){
  //  this.modalRef= this.modalService.show(
  //     this.modalCancel, { class: "gray" })
  // }
  // searchevent(patient: any) {
  //   this.form.controls['RegNo'].setValue(patient.data.regNo);
  // }

  showBasicDialog() {
    this.modaltemp.open();

  }
  Downloadeprior(data){
    this.modalRef= this.modalService.show(
       this.modaldownload, { class: "gray" })
   }
}
