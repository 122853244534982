import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { Response } from '../../../_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrl: any;

  constructor(
    private http: HttpClient,
    private configurationManager: ConfigurationManager,

  ) { 
    this.baseUrl = (this.configurationManager as any).baseUrl;

  }
  SearchRegPatient(InputDetails) {
    var url = "api/Registration/SearchPatientInList";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetConsultatnt(InputDetails) {
    var url = "api/Consultant/GetAllConsultants";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetStaff(InputDetails) {
    var url = "api/MasterData/GetStaff";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetAppoinment(InputDetails) {
    var url = "api/TodaysPatient/SearchAppointment";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  UpdateUserPassword(payload) {
    var url="api/MasterData/UpdateUserPassword";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,payload);
  }

}
