import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMRService } from '../../../services/emr.service';

@Component({
  selector: 'app-view-physical-examination-modal',
  templateUrl: './view-physical-examination-modal.component.html',
  styleUrls: ['./view-physical-examination-modal.component.scss']
})
export class ViewPhysicalExaminationModalComponent implements OnInit {

  @Input() entryId: any;
  @Input() patientId: any;

  public dataLoading: boolean = false;
  public itemData: any;

  constructor(private activeModalRef: BsModalRef,
              private emrService: EMRService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    let input = {
      VisitId: this.entryId,
      PatientId: this.patientId,
      ShowAll: 1,
    };
    this.dataLoading = true;
    this.emrService.GetPhysicalExaminationDetails(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];
        this.itemData = response;
      }
      this.dataLoading = false;
    });
  }

  close(){
    this.activeModalRef.hide();
  }

}
