import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from "../../emr/services/emr.service";
import { ToastrService } from "ngx-toastr";
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { SickLeave } from '../interfaces/SickLeave';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import Swal from 'sweetalert2';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';

@Component({
  selector: 'app-emr-sick-leave',
  templateUrl: './emr-sick-leave.component.html',
  styleUrls: ['./emr-sick-leave.component.scss']
})
export class EmrSickLeaveComponent implements OnInit {

  subscription: Subscription;
  modalRef?: BsModalRef;
  sickLeaveForm: FormGroup;
  public submitted: boolean = false;
  result: any;
  globals: globalvars = new globalvars();
  BranchesId: any;
  EMRPatientInfo: EMRPatientInfo;
  formSubmitData: SickLeave;
  form: SickLeave;
  display: boolean = false;
  defaultColDef: ColDef[] = [];
  rowData = [];
  sickleave: SickLeave[] = [];
  sickPopupHeading = "Add Sick Leave";
  @ViewChild("template") modaltemp: any;
  userId: any;
  src: any = '';
  private baseUrl = "";
  currentD = new Date();
  consultdate: any;
  rowGroupPanelShow = "always";
  public context;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  public loadingTemplate = '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';
  public noRowsTemplate;
  public frameworkComponents;
  public gridApi;
  todate: string = "";
  public oldDAte;
  public leave="";

  constructor(
    private EMRService: EMRService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    public datepipe: DatePipe,
    private sharedservice: SharedService,
    private sanitizer: DomSanitizer,
    private configurationManager: ConfigurationManager,

  ) {
    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  ngOnInit(): void {
    this.context = { componentParent: this };
    let currentappDate = new Date();
    this.todate = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');

    this.sickLeaveForm = new FormGroup({
      consultdate: new FormControl(this.todate, [Validators.required]),
      patientId: new FormControl(this.EMRPatientInfo.PatientId),
      entryId: new FormControl(0),
      // consultdate: new FormControl('', [Validators.required]),
      leaveFrom: new FormControl(this.todate, [Validators.required]),
      leaveTo: new FormControl('', [Validators.required]),
      userId: new FormControl(),
      days: new FormControl(0, [Validators.required]),
      userName: new FormControl(""),
      refLetter: new FormControl(""),
      isDeleting: new FormControl(0),
      consultantId: new FormControl(this.EMRPatientInfo.ConsultantId),
      branchId: new FormControl(""),
      isDisplayed: new FormControl(true),

    });
    this.GetSickLeave();

    this.defaultColDef = [
      {
        headerName: "Date",
        field: "consultdate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Leave Days",
        field: "days",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 110,
        filter: true,
      },
      {
        headerName: "From Date",
        field: "leaveFrom",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "To Date",
        field: "leaveTo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 100,
        filter: true,
      },

      {
        headerName: "Authorized by User",
        field: "userName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 150,
        filter: true,
      },

      {
        headerName: "Action",
        field: "action",
        enableRowGroup: true,
        resizable: false,
        sortable: false,
        flex: 2,
        maxWidth: 80,
        pinned: "right",
        filter: false,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "sicklist" },

      },

    ]

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
      onGridReady: (event) => event.api.sizeColumnsToFit()

    };

  }
  get f() {
    return this.sickLeaveForm.controls;
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }


  openModal(template: TemplateRef<any>) {

    this.sickPopupHeading = "Add Sick Leave";
    this.display = true;
    this.modalRef = this.modalService.show(template, { backdrop: "static" });
    this.ResetFunction();
    this.sickLeaveForm.patchValue({ consultdate: this.todate });
    this.sickLeaveForm.patchValue({ leaveFrom: this.todate });
    this.oldDAte = this.todate;

  }

  GetSickLeave() {
    let branchId = Number(this.BranchesId);
    let patientId = Number(this.EMRPatientInfo.PatientId);
    this.EMRService.getSick(patientId, branchId).subscribe((res) => {
      this.sickleave = res.response.map((leave)=>{
        return{
          ...leave,
          leaveFrom:leave.leaveFrom.replaceAll('/','-'),
          consultdate:leave.consultdate.replaceAll('/','-'),
          leaveTo:leave.leaveTo.replaceAll('/','-')

        }
      })

    });
  }
  saveFunction(formSubmitData) {
    let sickid = 0;
    sickid = Number(this.formSubmitData.entryId);

    this.EMRService.SaveSickLeave(this.formSubmitData).subscribe((res) => {
      this.submitted = false;
      this.result = res;

      if (this.result.status == 200 && this.result.message == 'Success') {
        if (sickid == 0) {

          this.toastr.success("Sick Leave added Successfully");
        }
        else {
          this.toastr.success("Sick Leave Edited Successfully");
        }
        this.display = false;
        this.modalRef?.hide()
        this.GetSickLeave();
        this.clearForm();
      }
      else {
        this.toastr.error(this.result.message);
      }

    })

  }

  // save leave
  saveLeave() {
    this.submitted = true;
    if (this.sickLeaveForm && this.sickLeaveForm.valid) {
      this.formSubmitData = this.sickLeaveForm.getRawValue();
      this.formSubmitData.consultantId = this.EMRPatientInfo.ConsultantId;
      if (Number(this.formSubmitData.consultantId) > 0) {
        let consdate = this.sickLeaveForm.get('consultdate').value;
        if (typeof (consdate) == "object") {
          this.formSubmitData.consultdate = this.datepipe.transform(consdate, 'dd-MM-yyyy');

        }
        else {
          this.formSubmitData.consultdate = consdate.replaceAll('/', '-');
        }

        let ConvertedFrom = this.sickLeaveForm.get('leaveFrom').value;
        if (typeof (ConvertedFrom) == "object") {
          this.formSubmitData.leaveFrom = this.datepipe.transform(ConvertedFrom, 'dd-MM-yyyy');
        }
        else {
          this.formSubmitData.leaveFrom = ConvertedFrom.replaceAll('/', '-');
        }
        let ConvertedTo = this.sickLeaveForm.get('leaveTo').value;
        if (typeof (ConvertedTo) == "object") {
          this.formSubmitData.leaveTo = this.datepipe.transform(ConvertedTo, 'dd-MM-yyyy');
        }
        else {
          this.formSubmitData.leaveTo = ConvertedTo.replaceAll('/', '-');
        }
        this.formSubmitData.patientId = this.EMRPatientInfo.PatientId;
        this.formSubmitData.branchId = Number(this.BranchesId);
        this.formSubmitData.userId = Number(localStorage.getItem('user_id'));
        if (!this.formSubmitData.entryId) this.formSubmitData.entryId = 0;

        let sickid = 0;
        sickid = Number(this.formSubmitData.entryId);
        let Condate = this.sickLeaveForm.get('consultdate').value;

        if ((this.oldDAte != Condate)) {
          this.submitted = true;
          Swal.fire({
            title: 'Are you sure. you want to change the date?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.value) {
              this.saveFunction(this.formSubmitData);
            }
            else {
              this.saveFunction({...this.formSubmitData.consultdate=this.oldDAte});

              this.modalRef.hide()
            }

          })

        }
        else if (sickid!=0 ||sickid == 0) {
          this.saveFunction(this.formSubmitData);
        }

      }
    }
    // else {
    //   this.toastr.error("Only a consultant can add sick leave");
    // }


  }

  clearForm() {
    this.sickLeaveForm.reset({
      patientId: 0,
      consultdate: '',
      leaveFrom: '',
      leaveTo: '',
      userId: 0,
      days: '',
      userName: '',
      refLetter: '',
      isDeleting: 0,
      consultantId: '',
      isDisplayed: true,
      branchid: 0,
    })

  }
  ResetFunction() {
    // this.userId = 0;
    this.submitted = false;
    this.clearForm();
  }

  calcDate() {
    //test start
    // var date = new Date("2014-10-10");//YYYY-MM-DD
    // console.log(date.toDateString());
    //test end
    let days = this.sickLeaveForm.get('days').value;
    if (!days || days == "") days = 0;
    let fromdate = this.sickLeaveForm.get('leaveFrom').value;
    if(fromdate==""){
      fromdate=this.leave;
    }
    if (typeof (fromdate) == "string") {
      const [day, month, year] = fromdate.split('-');
      const datev = new Date(+year, Number(month) - 1, +day);
      fromdate = datev;
    }
    if (fromdate) {
      let dte = new Date(fromdate);
      dte.setDate(dte.getDate() + days);
      let newdate = this.datepipe.transform(dte, 'dd-MM-yyyy');
      this.sickLeaveForm.controls['leaveTo'].setValue(newdate);
    }

  }
  Edit(data) {

    this.sickPopupHeading = "Edit Sick Leave"
    this.display = true;
    this.modalRef = this.modalService.show(
      this.modaltemp,
    );
    this.sickLeaveForm.patchValue(data);
    this.leave=data.leaveFrom;
    this.sickLeaveForm.patchValue({
      consultdate: data.consultdate
    });
    this.oldDAte = data.consultdate;
  }

  printSickLeave(data) {
    // let entryId = Number(data.entryId);
    // let branchId = Number(this.BranchesId);

    // this.EMRService.printSickLeave(entryId, branchId, Number(this.globals.ClientId)).subscribe((res) => {
    //   if (res.status == 200) {
    //     let response = res.response;
    //     this.src = response[0].reportPath;
    //     this.src = this.baseUrl + this.src;
    //     //this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);

    //     window.open(this.src, "_blank");
    //     // this.isPdfLoaded=true;
    //   }

    // })

    let initialState = {
      modalHeading: 'Print Sick Leave',
      reportName: 'RptPrintSickLeave.trdp',
      reportParams: {
        EntryId : data.entryId,
        BranchId : Number(this.BranchesId)
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))

  }

  Delete(data) {
    let form = this.sickLeaveForm.getRawValue();
    if (form.consultantId > 0) {

    form.consultantId = this.EMRPatientInfo.ConsultantId;
    form.leaveFrom="";
    form.leaveTo="";
    form.patientId = data.patientId;
    form.userId = data.userId;
    form.entryId = Number(data.entryId);
    form.branchId = Number(this.BranchesId);
    form.isDeleting = 1;
    form.days = 0;

    this.EMRService.SaveSickLeave(form).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Sick Leave deleted successfully");
        this.GetSickLeave();
        this.clearForm();
        // this.patientId = 0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
        this.display = false;
      }

    },
    );
    }
  }

  printAttendance(data: any){
    let initialState = {
      modalHeading: 'Print Attendance',
      reportName: 'RptPrintAttendance.trdp',
      reportParams: {
        EntryId : data.entryId,
        BranchId : Number(this.BranchesId)
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
  }
}




