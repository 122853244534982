import { Component, OnInit } from '@angular/core';
import {  FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { TreatmentsService } from '../../treatment/services/treatments.service';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from '../../emr/services/emr.service';


@Component({
  selector: 'app-emr-treatmentdetails',
  templateUrl: './emr-treatmentdetails.component.html',
  styleUrls: ['./emr-treatmentdetails.component.scss']
})
export class EmrTreatmentdetailsComponent implements OnInit {
  EMRPatientInfo:EMRPatientInfo; 

  form: FormGroup;
  treatmentList:any[]=[];
  globals: globalvars = new globalvars();
  BranchesId: any;
  subscription: Subscription;
  constructor(
    private datepipe: DatePipe,
    private sharedservice: SharedService,
    private TreatmentsService: TreatmentsService,
    private EMRService:EMRService,

  ) {

    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });


  }

  ngOnInit(): void {
    this.form= new FormGroup({
      treatmentNumber: new FormControl(""),
      dateFrom: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      dateTo: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      PatientName: new FormControl(""),
      PatientId: new FormControl(Number(this.EMRPatientInfo.PatientId)),
      mobile: new FormControl(""),
      regNo: new FormControl(''),
      id: new FormControl(0),
      branchId: new FormControl(0),
    });
    this.GetTreatmentDetails()
  }


  GetTreatmentDetails() {
    let DateFrom = this.form.get("dateFrom").value;
    let DateTo = this.form.get("dateTo").value;

  if (typeof (DateFrom) == "object") {
     DateFrom = this.datepipe.transform(DateFrom, 'dd-MM-yyyy');
    }
    if (typeof (DateTo) == "object") {
      DateTo = this.datepipe.transform(DateTo, 'dd-MM-yyyy');
    }

    let input=this.form.getRawValue();
    input.dateFrom=DateFrom;
    input.dateTo=DateTo;
    input.branchId=this.globals.HospitalId;
    input.PatientId=this.EMRPatientInfo.PatientId;

    const payload={
      PatientId: this.EMRPatientInfo.PatientId,  
      id: 0,
      branchId: this.globals.HospitalId
    }

    // let inputs = {
    //   "DateFrom": DateFrom,
    //   "DateTo": DateTo,
    //   "PatientId": 0,
    //   "PatientName": this.form.get("PatientName").value,
    //   "Mobile": this.form.get("mobile").value,
    //   "RegNo": this.form.get("regNo").value,
    //   "Id": 0,
    //   "TreatmentNumber": this.form.get("treatmentNumber").value,
    //   "branchId":this.globals.HospitalId
    // };

    this.TreatmentsService.GetTreatmentDetailsForEMRHome(payload).subscribe(
      (res) => {
        if (res.status == 200) {

          this.treatmentList = res.response;
        }
      });
  }


}
