import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { DashboardService } from '../services/dashboard.service';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConsultantListComponent } from './modal/consultant-list/consultant-list.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isFull: boolean;
  isFull1: any;
  isFull3: boolean;
  isFull4: boolean;
  patientData: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  doctorData: Number;
  StaffData: Number;
  technologistdata: any;
  pathalogisttdata: any;
  microbiologistdata: any;
  radiologistdata: any;
  branchName: string;
  pending:any;
  cancelled:any;
  consulted:any;
  ToDates:any;
  fromDates:any;
  consultantdata:any;
  progressBar:any;
  limitedonsultantArray:any;
  constructor(private router: Router,
    private sharedservice: SharedService,
    private DashboardService: DashboardService,
    public datepipe: DatePipe,
    private modalService: BsModalService,

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
        this.branchName = glob.HospitalName;
      }
    });


  }

  ngOnInit(): void {
    this.ToDates = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    let dte = new Date();
    dte.setDate(dte.getDate() - 365);
    this.fromDates = this.datepipe.transform(dte.toString(), 'dd-MM-yyyy');

    this.searchevent();
    this.getconsultant();
    this.GetStaff();
    this.GetAppointment();
  }
  closeMenu() {
    const body = document.getElementsByTagName('body')[0].classList.remove("offcanvas-active");
  }

  dashboardMenu() {
    document.getElementById('navbarNavDropdown').classList.toggle("show");
  }

  fullScreenSection(number) {
    if (number == 1) {
      if (this.isFull) {
        this.isFull = false;
      }
      else {
        this.isFull = true;
      }
    }
    else if (number == 2) {
      if (this.isFull1) {
        this.isFull1 = false;
      }
      else {
        this.isFull1 = true;
      }
    }
    else if (number == 3) {
      if (this.isFull3) {
        this.isFull3 = false;
      }
      else {
        this.isFull3 = true;
      }
    }
    else if (number == 4) {
      if (this.isFull4) {
        this.isFull4 = false;
      }
      else {
        this.isFull4 = true;
      }
    }

  }
  searchevent() {
    let EndDateObj = this.ToDates

    if (typeof (EndDateObj) == "object") {

      this.ToDates= this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      
    }
    let startDateObj=this.fromDates;
    if (typeof (startDateObj) == "object") {

      this.fromDates= this.datepipe.transform(startDateObj, 'dd-MM-yyyy');
      
    }

    let form = {
      BranchId: Number(this.BranchesId),
      ConsultantId: 0,
      Name: "",
      RegNo: "",
      Address: "",
      IdentityNo: "",
      PolicyNo: "",
      Mobile: "",
      ResNo: "",
      Mode: "",
      RegDateFrom: this.fromDates,
      RegDateTo: this.ToDates,
      Phone: "",
      PIN: ""
    }
    this.DashboardService.SearchRegPatient(form).subscribe((res) => {
      let patientREgisterData = res.response;
      this.patientData = (patientREgisterData.length)

    })
  }
  getconsultant() {
    let form = {
      BranchId: Number(this.BranchesId),
      ConsultantType: 2

    }
    this.DashboardService.GetConsultatnt(form).subscribe((res) => {
      this.consultantdata = res.response;
      this.doctorData = (this.consultantdata.length)
      this.GetConsultantType()
    })
  }
  GetStaff() {
    let form = {
      BranchId: Number(this.BranchesId),
      ShowAll: 1,
      StaffId: 0,
    }
    this.DashboardService.GetStaff(form).subscribe((res) => {
      let data = res.response;
      let techdata = data.filter(x => x.categoryId === 1);
      this.technologistdata = techdata.length;
      let pathdata = data.filter(x => x.categoryId === 2);

      this.pathalogisttdata = pathdata.length;
      let microdata = data.filter(x => x.categoryId === 4);

      this.microbiologistdata = microdata.length;
      let radiodata = data.filter(x => x.categoryId === 3);

      this.radiologistdata = radiodata.length;

    })

  }
  report() {
    this.router.navigate(['/Registration_Report/']);
  }
  GetAppointment() {
    let EndDateObj = this.ToDates

    if (typeof (EndDateObj) == "object") {

      this.ToDates= this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      
    }

    let form = {
      "ConsultantId": 0,
      "BranchId": Number(this.BranchesId),
      "Name": "",
      "RegNo": "",
      "PIN": "",
      "Address": "",
      "Mobile": "",
      "AppFromDate":  this.ToDates,
      "AppToDate": this.ToDates,
      "Phone": "",
      "AppType": 0,
    }
    this.DashboardService.GetAppoinment(form).subscribe((res) => {
      let AppointmentDataLIst = res.response;
      let pendingdata= AppointmentDataLIst.filter(x => x.appStatus === "Waiting");
      this.pending =pendingdata.length
      let cancelledData= AppointmentDataLIst.filter(x => x.appStatus === "Cancelled");
      this.cancelled=cancelledData.length;
      let consulted= AppointmentDataLIst.filter(x => x.appStatus === "Consulted");
      this.consulted =consulted.length;
      // this.dynamicValue = [75,10,15];
      // this.progressBar = document.querySelector('.progress-bar');
      // this.progressBar.forEach(function(progressBar, index) {
      //   var dynamicValues = this.dynamicValue[index];
      //   progressBar.setAttribute('aria-valuenow', dynamicValues);
      //   progressBar.style.width = dynamicValues + '%';
      // });
    })
  }
GetConsultantType(){
  this.limitedonsultantArray = this.consultantdata.slice(0, 7);
  
}
consultantFullScreen(){
    let initialState = {
      datalist:this.consultantdata
    }

    const modalRef = this.modalService.show(ConsultantListComponent, Object.assign({ class: "gray modal-xl" }, { initialState }))

  }


}
