import { Component, EventEmitter,Input,OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMRService } from '../../../services/emr.service';

@Component({
  selector: 'app-drug-dosage',
  templateUrl: './drug-dosage.component.html',
  styleUrls: ['./drug-dosage.component.scss']
})
export class DrugDosageComponent implements OnInit {
  @Input() BranchId:number
  @Output() dosageEvent = new EventEmitter();
  isLoading:boolean=false
  public DosageForm: FormGroup;
  submitted:boolean=false

  constructor(
    public modalRef: BsModalRef,
    private emrService:EMRService
  ) {

   }

  ngOnInit(): void {
    this.DosageForm = new FormGroup({
      DosageDesc: new FormControl("", [Validators.required]),
      DosageValue: new FormControl("", [Validators.required]),
    });
  }


  get dosagef() {
    return this.DosageForm.controls;
  }

  SaveDosage(){
    this.submitted=true
    if(this.DosageForm.valid){
      let payload={
        ...this.DosageForm.value,
        DosageValue:Number(this.DosageForm.value.DosageValue),
        BranchId:this.BranchId
      }
      this.emrService.InsertManualDosage(payload).subscribe({
        next:(res)=>{
          if(res.status == 200){
            this.dosageEvent.emit({data: Number(res.response)})
            this.modalRef.hide()
          }
        }
      })
    }
  }

  close(status){
    this.modalRef.hide()
    this.dosageEvent.emit();
  }

}
