import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FormValidationService } from '../../client/services/form-validation.service';
import { ActiveFilterMenuTriggerDirective } from '@generic-ui/ngx-grid/structure/source/feature/counter/filter/active-filter-menu-trigger.directive';
import { ProfessionList } from '../../client/interfaces/ProfessionList';
import { ProfessionService } from '../../client/services/profession.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../client/services/configuration.service';
import { DatePipe } from '@angular/common';
import { PackageandprofileService } from '../package-and-profile/packageandprofile.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-RateGroup',
  templateUrl: './rategroup.component.html',
  styleUrls: ['./rategroup.component.scss']
})
export class RateGroupComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  public submitted: boolean = false;
  result: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  public statusBar;
  rowGroupPanelShow = "always";
  //ag grid setup
  modalRef: BsModalRef;
  @ViewChild("template") modaltemp: any;
  public gridApi;
  serviceItemBasket: any[] = [];
  columnDefs: ColDef[] = [];
  modalcolumnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public modalColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.active == '0'; },
      'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    },
  }
  rowData: any;
  unlistedCode: any;
  RateGroupForm: FormGroup;
  RateGroupId: any;
  rateGroupDatas: any;
  modalGroupDatas: any[] = [];
  DisplayedId: any;
  RateDateFrom: any;
  RateDateTo: any;
  isLoading = false;
  serviceitemlist: any[];
  standardFillRate: any[] = [];
  ItemIds: any[] = [];
  RategroupPopupName: String = "Add Rate Group";
  functionPermissionData: any;

  constructor(private formBuilder: FormBuilder,
    private packageandprofileService: PackageandprofileService,
    private ngxLoader: NgxUiLoaderService,
    private ProfessionService: ProfessionService,
    private toastr: ToastrService,
    private formValidationService: FormValidationService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    public datepipe: DatePipe,
    private modalService: BsModalService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });

  }
  ngOnInit(): void {
    this.RateGroupForm = new FormGroup({
      rategroupName: new FormControl('', [Validators.required]),
      rategroupDesc: new FormControl('', [Validators.required]),
      rategroupEF: new FormControl('', [Validators.required]),
      rategroupET: new FormControl('', [Validators.required]),
      IsDisplayed: new FormControl(true),
      IsStandardRate: new FormControl(false),
    });
    this.GetAllAvailableServices();
    this.GetAllRateGroup();
    this.loadserviceitem(0);
    this.functionPermission();
    this.columnDefs = [
      {
        headerName: "Rate Group Name",
        minWidth: 150,
        field: "rGroupName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Description",
        field: "description",
        minWidth: 150,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Effect From",
        field: "effectFrom",
        minWidth: 150,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Effect To",
        field: "effectTo",
        minWidth: 150,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "RateGroupList" },
      },
    ]
    this.modalcolumnDefs = [
      {
        headerName: "Item Name",
        field: "ItemName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Item Rate",
        field: "BaseCost",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
    ]
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.modalColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }


  openModal(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 119)
    if (foundElement.id == 119 && foundElement.isAllowed == true) {
    this.RategroupPopupName = "Add Rate Group";
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-lg" })
    );
    // this.clearRateGroupForm();
    this.ResetFunction();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }
  showModalDialog(rategroupData) {
    const foundElement = this.functionPermissionData.find(x => x.id === 120)
    if (foundElement.id == 120 && foundElement.isAllowed == true) {
    console.log(rategroupData);
    
    this.RategroupPopupName = "Edit Rate Group";
    //this.GetAllRateGroup();
    this.modalRef = this.modalService.show(
      this.modaltemp,
      Object.assign({}, { class: "gray modal-lg" })
    );
    if (rategroupData.isDisplayed == 1) {
      rategroupData.isDisplayed = true;
    }
    else {
      rategroupData.isDisplayed = false;
    }
    this.RateGroupId = rategroupData.rGroupId;
    this.RateGroupForm.patchValue({
      rategroupName: rategroupData.rGroupName,
      rategroupDesc: rategroupData.description,
      rategroupEF: rategroupData.effectFrom,
      rategroupET: rategroupData.effectTo,
      IsDisplayed: rategroupData.isDisplayed,
      IsStandardRate: rategroupData.isStandard
    });

    this.serviceItemBasket = [];

    rategroupData.rate.forEach(element => {
      let itemarray = {
        ItemId: element.itemId,
        BaseCost: element.baseCost,
        ItemName: element.itemName
      }
      this.serviceItemBasket.push(itemarray);
    });
    this.gridApi.setRowData(this.serviceItemBasket);
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  FillStandardRate() {
    let ipdata = [];
    this.serviceItemBasket.forEach(element => {
      ipdata.push(element.ItemId)
    });
    this.ConfigurationService.GetStandardRate(this.BranchesId, ipdata).subscribe((res) => {
      let data = res.response;
      this.serviceItemBasket = [];
      data.forEach(element => {
        let itemarray = {
          ItemId: element.itemId,
          BaseCost: String(element.rate),
          ItemName: element.itemName
        }
        this.serviceItemBasket.push(itemarray);
      });
      this.gridApi.setRowData(this.serviceItemBasket);
      //this.serviceItemBasket = this.serviceItemBasket.map(x => ({ ...x, BaseCost: "1500" }));
    });
  }

  SaveRateGroup() {
    let RateFrom = this.RateGroupForm.get("rategroupEF").value;
    if (typeof (RateFrom) == "object") {
      let date = this.datepipe.transform(RateFrom, 'dd-MM-yyyy');
      this.RateDateFrom = date;
    }
    else {
      this.RateDateFrom = this.RateGroupForm.get("rategroupEF").value;
    }
    let RateTo = this.RateGroupForm.get("rategroupET").value;
    if (typeof (RateTo) == "object") {
      let date = this.datepipe.transform(RateTo, 'dd-MM-yyyy');
      this.RateDateTo = date;
    }
    else {
      this.RateDateTo = this.RateGroupForm.get("rategroupET").value;
    }

    this.submitted = true;
    if (this.RateGroupForm && this.RateGroupForm.valid) {
      var AppData = {
        rategroupName: this.RateGroupForm.get("rategroupName").value,
        rategroupDesc: this.RateGroupForm.get("rategroupDesc").value,
        rategroupEF: this.RateDateFrom,
        rategroupET: this.RateDateTo,
        isDisplayed: Number(this.RateGroupForm.get("IsDisplayed").value),
        IsStandardRate: this.RateGroupForm.get("IsStandardRate").value
      }
      let rgid = 0;
      if (this.RateGroupId > 0) {
        rgid = this.RateGroupId;
      }
      if (this.RateDateFrom == this.RateDateTo) {
        this.toastr.error("Failed : " + "Dates can't be equal");
        return;
      }
      let itemarayList = this.serviceItemBasket.map(x => ({ ...x, BaseCost:x.BaseCost==null?0:Number(x.BaseCost) }));
      // string to number convertion
      this.isLoading = true;
      this.ConfigurationService.InsertUpdateRateGroup(AppData, this.globals.HospitalId, rgid, itemarayList).subscribe((res) => {
        this.result = res;

        if (this.result.status == 200 && this.result.message == 'Success') {
          this.submitted = false;
          this.clearRateGroupForm();
          if (rgid == 0) {
            this.isLoading = false;
            this.toastr.success("Rate Group added successfully");
          }
          else {
            this.isLoading = false;
            this.toastr.success("Rate Group Updated successfully");
            this.RateGroupId = 0;
          }
          this.modalRef.hide();
          this.GetAllRateGroup();
        }
        else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }
  }
  GetAllRateGroup() {
    let input = {
      "RGroupId": 0,
      "ShowAll": 1,
      "BranchId": this.BranchesId
    }
    this.ConfigurationService.GetRateGroup(input).subscribe((res) => {
      this.rateGroupDatas = res.response;
      
    });
  }

  DeleteRateGroup(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 121)
    if (foundElement.id == 121 && foundElement.isAllowed == true) {
    this.RateGroupId = id.rGroupId;
    this.ConfigurationService.DeleteRateGroup(this.RateGroupId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Rate Group deleted successfully");
        this.displayModal = false;
        this.GetAllRateGroup();
        this.RateGroupId = 0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
        this.displayModal = false;
      }
    },
    );
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }
  //check and uncheck of registration date
  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  // loadserviceitem(itemId) {
  //   if (itemId > 0) {
  //     let item = this.serviceitemlist.filter(x => x.itemId == itemId)[0];
  //     let GetStandardRateItem = {
  //       ItemId: item.itemId
  //     }
  //     let existscheck = this.serviceItemBasket.filter(y => y.ItemId == itemId)[0];
  //     if (typeof (existscheck) == "undefined") {
  //       this.toastr.error("Failed : " + "Service item already exists");
  //     }
  //     else {
  //       this.standardFillRate.push(GetStandardRateItem);
  //       this.standardFillRate.forEach((a) => {
  //         this.ItemIds.push(Number(a.ItemId));
  //       });

  //       if (item) {
  //         let itemarray = {
  //           ItemId: item.itemId,
  //           BaseCost: 0,
  //           ItemName: item.itemName
  //         }
  //         this.serviceItemBasket.push(itemarray);
  //         this.gridApi.setRowData(this.serviceItemBasket);
  //       }
  //     }
  //   }

  // }
  loadserviceitem(itemId) {
    if (itemId > 0) {
      let item = this.serviceitemlist.filter(x => x.itemId == itemId)[0];
      let item2 = this.serviceItemBasket.filter(x => x.ItemId == itemId)[0];
      if (item2 == undefined) {
        let itemarray = {
          ItemId: item.itemId,
          Rate: 0,
          ItemName: item.itemName
        }
        this.serviceItemBasket.push(itemarray);
        this.gridApi.setRowData(this.serviceItemBasket);

      }
      else {

      }
    }
  }

  clearRateGroupForm() {

    this.RateGroupForm.reset({
      rategroupName: '',
      rategroupDesc: '',
      rategroupEF: '',
      rategroupET: '',
      IsDisplayed: true,
      IsStandardRate: false
    });
    this.serviceItemBasket=[];
  }
  GetAllAvailableServices() {
    this.packageandprofileService.GetServiceItems(this.BranchesId).subscribe((res) => {
      this.serviceitemlist = res.response;      
      this.serviceitemlist.forEach((a) => {
        (a.id = a.itemId), (a.text = a.itemName);
      });
    });

  }
  get f() {
    return this.RateGroupForm.controls;
  }
  ResetFunction() {
    this.submitted = false;

    this.clearRateGroupForm();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }
}
