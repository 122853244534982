import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from '../../client/services/configuration.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from "rxjs";
import { globalvars } from "../../../shared/models/GlobalVars";
import { SharedService } from "../../../shared/shared.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { GeneralGridActionComponent } from '../../../shared/general-grid-action/general-grid-action.component';
@Component({
  selector: 'app-vital-symptoms',
  templateUrl: './vital-symptoms.component.html',
  styleUrls: ['./vital-symptoms.component.scss']
})
export class VitalSymptomsComponent implements OnInit {
  public VitalSignForm: FormGroup;
  public submitted: boolean = false;
  IsMandatory: any;
  VitalSignId: any;
  result: any;
  public gridApi;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  modalRefVitalsign: BsModalRef;
  modalRef2?: BsModalRef;
  vitalsignData: any = [];
  vitalsigncolumnDefs = [];
  vitalsignDefs: ColDef[] = [];
  statusBar: any;
  isLoading=false;
  VitalPopupHeading:String="Add Vital Sign";
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  // grid setup

  private gridColumnApi;
  public rowSelection = true;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public vitalsigndefaultColDef: ColDef;
  MandatoryStat: any;
  @ViewChild("templateVitalsign") modaltempVitalsign: any;
  functionPermissionData: any;
  reservedId: any;

  constructor(
    private ConfigurationService: ConfigurationService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });

    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
    this.frameworkComponents = {
      actionsComponentRenderer: GeneralGridActionComponent,
    };
    this.context = { componentParent: this };
  }

  ngOnInit(): void {
    this.GetAllVitalSign();
    this.functionPermission();

    this.VitalSignForm = new FormGroup({
      vitalsignName: new FormControl('', [Validators.required]),
      vitalsignMandatory: new FormControl(true),
      vitalsignCode: new FormControl('', [Validators.required]),
      vitalsignUnit: new FormControl('', [Validators.required]),
      vitalsignMin: new FormControl('', [Validators.required]),
      vitalsignMax: new FormControl('', [Validators.required]),
      vitalsignSortOrder: new FormControl('', [Validators.required]),
      IsDisplayedVitalSign: new FormControl(true),

      minValidationValue: new FormControl('', [Validators.required]),
      maxValidationValue: new FormControl('', [Validators.required]),
      inValidValidation: new FormControl(false),
    });

    this.vitalsigncolumnDefs = [
      {
        headerName: "Vital Sign Name",
        field: "signName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false
      },
      {
        headerName: "Mandatory",
        field: "mandatoryStatus",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false
      },
      {
        headerName: "Validation Mandatory",
        field: "inValidValidation",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
        valueFormatter: params => params.value ? "Yes" : "No"
      },
      {
        headerName: "Sign Code",
        field: "signCode",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false
      },
      {
        headerName: "UoM",
        field: "signUnit",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false
      },
      {
        headerName: "Min Value",
        field: "minValue",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false
      },
      {
        headerName: "Max Value",
        field: "maxValue",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false
      },
      {
        headerName: "Min Validation",
        field: "minValidationValue",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false
      },
      {
        headerName: "Max Validation",
        field: "maxValidationValue",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "signId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "VitalSignList" },
      },
    ]
    this.vitalsigndefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

  }

  GetAllVitalSign() {
    this.ConfigurationService.GetVitalSign(this.globals.HospitalId).subscribe((res) => {
      this.vitalsignData = res.response;

    });
  }
  get vitalsignf() {
    return this.VitalSignForm.controls;
  }

  openModalVitalsign(template: TemplateRef<any>) {
    this.reservedId=0
    const foundElement = this.functionPermissionData.find(x => x.id === 122)
    if (foundElement.id == 122 && foundElement.isAllowed == true) {
    this.VitalPopupHeading="Add Vital sign";
    this.modalRefVitalsign = this.modalService.show(template);
    this.clearForm();
    this.VitalSignId = 0;
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }

  SaveUpdateVitalSign() {
    console.log(this.VitalSignForm.value)
    
    this.submitted = true;

    if (this.VitalSignForm && this.VitalSignForm.valid) {
      if(this.VitalSignForm.value.minValidationValue >  this.VitalSignForm.value.maxValidationValue){
        this.toastr.warning("Minimum ValidationValue must be less than Maximum Validation Value")
        return
      }
      var AppData = {
        vitalsignName: this.VitalSignForm.value.vitalsignName,
        vitalsignMandatory: this.VitalSignForm.value.vitalsignMandatory,
        vitalsignCode: this.VitalSignForm.value.vitalsignCode,
        vitalsignUnit: this.VitalSignForm.value.vitalsignUnit,
        vitalsignMin: this.VitalSignForm.value.vitalsignMin,
        vitalsignMax: this.VitalSignForm.value.vitalsignMax,
        vitalsortOrder: this.VitalSignForm.value.vitalsignSortOrder,
        // isMandatory: this.IsMandatory,
        isDisplayed: Number(this.VitalSignForm.value.IsDisplayedVitalSign),

        minValidationValue: this.VitalSignForm.value.minValidationValue,
        maxValidationValue: this.VitalSignForm.value.maxValidationValue,
        inValidValidation: this.VitalSignForm.value.inValidValidation ? true : false
      }
      if (AppData.vitalsignMandatory == true) {
        AppData.vitalsignMandatory = 1;
      }
      else {
        AppData.vitalsignMandatory = 0;
      }
      var saveupdateId = 0;
      if (this.VitalSignId > 0) {
        saveupdateId = this.VitalSignId;
      }
      this.  isLoading=true;

      this.ConfigurationService.InsertUpdateVitalSign(AppData, this.globals.HospitalId, saveupdateId).subscribe((res) => {
        this.result = res;
        this.submitted = false;
        if (this.result.status == 200 && this.result.message == 'Success') {
          if (saveupdateId == 0) {
            this.  isLoading=false;
            this.toastr.success("Vital Sign Added successfully");
          }
          else {
            this.  isLoading=false;
            this.toastr.success("Vital Sign Updated successfully");
            this.VitalSignId = 0;
          }
          this.modalRefVitalsign.hide();
          this.GetAllVitalSign();

        }
        else {
          this.  isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
        this.clearForm();
      })
    }
  }


  DeleteVitalSign(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 124)
    if (foundElement.id == 124 && foundElement.isAllowed == true) {
    this.VitalSignId = id.signId;
    this.ConfigurationService.DeleteVitalSign(this.VitalSignId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Sign deleted successfully");
        this.VitalSignId = 0;
        this.GetAllVitalSign();
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
      }
    },
    );
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  showVitalSignModalDialog(showvitalmodalvalue) {
    this.reservedId=showvitalmodalvalue.reservedId
    const foundElement = this.functionPermissionData.find(x => x.id === 123)
    if (foundElement.id == 123 && foundElement.isAllowed == true) {
    this.VitalPopupHeading="Edit Vital sign";
    this.modalRefVitalsign = this.modalService.show(
      this.modaltempVitalsign,
    );
    this.VitalSignId = showvitalmodalvalue.signId;
    this.MandatoryStat = showvitalmodalvalue.mandatory;
    if (showvitalmodalvalue.isDisplayed == 1) {
      showvitalmodalvalue.isDisplayed = true;
    }
    else {
      showvitalmodalvalue.isDisplayed = false;
    }



    if (showvitalmodalvalue.mandatoryStatus == "Yes" || showvitalmodalvalue.mandatoryStatus == "yes") {
      showvitalmodalvalue.vitalsignMandatory = true;
    }
    else {
      showvitalmodalvalue.mandatoryStatus = false;
    }
    
    this.VitalSignForm.patchValue({
      vitalsignName: showvitalmodalvalue.signName,
      vitalsignMandatory: showvitalmodalvalue.mandatoryStatus,
      vitalsignCode: showvitalmodalvalue.signCode,
      vitalsignUnit: showvitalmodalvalue.signUnit,
      vitalsignMin: showvitalmodalvalue.minValue,
      vitalsignMax: showvitalmodalvalue.maxValue,
      vitalsignSortOrder: showvitalmodalvalue.sortOrder,
      IsDisplayedVitalSign: showvitalmodalvalue.isDisplayed,

      minValidationValue: showvitalmodalvalue.minValidationValue,
      maxValidationValue: showvitalmodalvalue.maxValidationValue,
      inValidValidation: showvitalmodalvalue.inValidValidation,
    });
    console.log(this.VitalSignForm.value)
    
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  // to clear form
  clearForm() {
    this.VitalSignForm.reset({
      vitalsignName: '',
      vitalsignMandatory: '',
      vitalsignCode: '',
      vitalsignUnit: '',
      vitalsignMin: '',
      vitalsignMax: '',
      vitalsignSortOrder: '',
      IsDisplayedVitalSign: true,
      minValidationValue:'',
      maxValidationValue:'',
      inValidValidation:true
    });
    this.submitted = false;
  }
  ResetFunction(){
    this.clearForm();
    this.VitalSignId = 0;
    this.submitted = false;

  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }
}
