import { Component, OnInit, Renderer2 } from "@angular/core";
import { MatListModule } from "@angular/material/list";
import { ToastrService } from "ngx-toastr";

import { globalvars } from "../../../shared/models/GlobalVars";
import { SharedService } from "../../../shared/shared.service";
import { UserHospitals } from "../interfaces/UserHospitals";
import { HospitalsService } from "../services/hospitals.service";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { parseJSON } from "date-fns/esm";
import { DatePipe, JsonPipe } from "@angular/common";
import { UserpermissionService } from "../../user-permission/userpermission.service";
import { Response } from "../../../_Models/Response";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ConfirmModalComponent } from "../../../shared/modals/confirm-modal/confirm-modal.component";
import { OpenshiftComponent } from "../../billing-settlement/manage-shift/openshift/openshift.component";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  BranchesId: number;
  nodes: any[];
  MenuNodes:any[];
  displayMaximizable: boolean;
  displayBasic: boolean;
  isFullScreen: boolean;
  contactTab: boolean;
  groupTab: boolean;
  chatTab: boolean = true;
  displayModal: boolean;
  visible: boolean = true;
  globals:globalvars= new globalvars();
  products: any = {};
  visibleSidebar2: boolean = true;
  //items: any = [];
  items_list: any = [];
  rowData: any = [];
  size: any;
  currentDate = new Date();
  username: any;
  selectedItem: any = {};
  globalvars: globalvars = new globalvars();
  branchName: string;
  localMenus:any=[];
  // branches = sessionStorage.getItem('branches');
  items: any[];
  branchesarray: any = [];
  hospitalList: UserHospitals[] = [];
  pageTitle: any;
  selectedLoc: any = {};
  LocationList: any[];
  display: any;
  mobileResBtnClick:boolean= false;
  private shiftChecking: boolean = true;
  constructor(
    private renderer: Renderer2,
    private hospitalsService: HospitalsService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    private userservice:UserpermissionService
  ) {
    this.GetUserHospitals();


    this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
         this.BranchesId=this.globals.HospitalId;
         this.checkForShift();
      }
    });

  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

  ngOnInit(): void {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {

        var rt = this.getChild(this.activatedRoute)

        rt.data.subscribe(data => {
          this.pageTitle=data.pageName;
        })
      });

    this.username = localStorage.getItem("username");

    this.items = [
      {
        label: this.username,
        icon: "fa fa-user-circle-o",
        items: [
          [
            {
              items: [
                { label: "My profile", icon: "fa fa-user",
              command:()=>{
                
                this.router.navigate(['/userprofile']);
                

              }
             },
                {
                  label: "Reset Password", icon: "fa fa-key",
                  command: () => {
                    let config: ModalOptions = {
                      backdrop: true,
                      ignoreBackdropClick: true,
                      class: 'modal-md'
                    }
                    let initialState = {
                    }
                    const modalRef = this.modalService.show(ResetPasswordComponent, Object.assign({}, config, { class: 'modal-md', initialState }));
                  }
                },
                // { label: "Messages", icon: "fa fa-envelope" },
                // { label: "Settings", icon: "fa fa-gear" },
                { label: "Logout", 
                  icon: "fa fa-power-off", 
                  // routerLink: "/logout",
                  command: ()=>{
                    const payload = {
                      LocationId: this.globals?.Location?.locationId,
                      BranchId: this.globals?.HospitalId,
                      UserId: Number(localStorage.getItem('user_id')),
                      ShiftDate: this.datePipe.transform(new Date(), 'dd-MM-yyyy')
                    }

                    this.userservice.getShiftStatus(payload).subscribe({
                      next: (response: Response<any>)=>{
                        if(response.status == 200){
                          let shiftStatus = response.response[0];
                          if(shiftStatus.enableBilling && shiftStatus.enableShift && shiftStatus.isShiftOPen){
              
                            let initialState = {
                              heading: 'Warning..!',
                              content: '<p>Shift is active now, Would you like to close shift before logout..?</p>',
                              isReasonNeeded: false,
                              yesButton: 'Goto Manage Shift',
                              noButton: 'Logout'
                            }
                            let historyConfig: ModalOptions = {
                              backdrop: true,
                              ignoreBackdropClick: true,
                              class: 'modal-dialog-scrollable'
                            }
                            const confirmModalRef= this.modalService.show(ConfirmModalComponent,Object.assign(
                              {class: "modal-dialog-centered "}, 
                              historyConfig, 
                              { class: 'modal-sm', initialState }) );
              
                            confirmModalRef.content.confirmEmitter.subscribe((res: any)=>{
                              if(res.status){
                                this.router.navigate(['/manageshift']);
                              }else{
                                this.router.navigate(['/logout']);
                              }
                            })
                          }else{
                            this.router.navigate(['/logout']);
                          }
                        }else{
                          this.toastr.error(response.errorMessage.message);
                        }
                      },error: (error: any)=>{
                        this.toastr.error('Something wents wrong..! Try again.')
                      }
                    })
                  } 
                },
              ],
            },
          ],
        ],
      },
    ];



    /*======== pagetitle and fontsize session storage data keeping   =========*/

    if (!sessionStorage.getItem("pageTitle")) {
      // sessionStorage.setItem("pageTitle", "Dashboard");
    }
    if (sessionStorage.getItem("globalvars")) {
      this.globalvars = JSON.parse(sessionStorage.getItem("globalvars"));
      this.sharedservice.setHospital(this.globalvars);
    }

    this.pageTitle = sessionStorage.getItem("pageTitle");

    if (!sessionStorage.getItem("fontClass")) {
      sessionStorage.setItem("fontClass", "fontsize-m");
    }
    this.renderer.addClass(document.body, sessionStorage.getItem("fontClass"));
    this.renderer.addClass(document.body, "toggle_menu_active");

    //--------------------------------------------------------------------------------//

    /*======== Data showing in Quick Link   =========*/

    // this.nodes = [
    //   {
    //     key: "0",
    //     label: "Front Office",
    //     children: [
    //       {
    //         label: "Registrations",
    //         data: "/registration",
    //         type: "url",
    //         children: [
    //           {
    //             label: "New Registrations",
    //             data: "/AddRegistration",
    //             type: "url",
    //           },
    //           {
    //             label: "Edit Registrations",
    //             data: "/EditRegistration",
    //             type: "url",
    //           },
    //         ],
    //       },

    //       {
    //         key: "0-1",
    //         label: "Consultations",
    //         data: "/consultation",
    //         type: "url",
    //         children: [
    //           {
    //             label: "New Consultations",
    //             data: "/AddConsultation",
    //             type: "url",
    //           },
    //           {
    //             label: "Edit Consultations",
    //             data: "/EditConsultation",
    //             type: "url",
    //           },
    //         ],
    //       },
    //       {
    //         key: "0-2",
    //         label: "Appointments",
    //         data: "/checkappointment",
    //         type: "url",
    //         children: [
    //           {
    //             label: "New Appointments",
    //             data: "/AddAppointment",
    //             type: "url",
    //           },
    //           {
    //             label: "Edit Appointments",
    //             data: "/EditAppointment",
    //             type: "url",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     key: "1",
    //     label: "Configuration",
    //     children: [{ key: "1-0", label: "Branches", data: "", type: "url" }],
    //   },
    // ];

       this.localMenus=localStorage.getItem('localMenus');
       this.localMenus=JSON.parse(this.localMenus);


      //  const output = Object.keys(this.localMenus).map((key) => {
      //   return {
      //     key: parseInt(key, 10), // This bit
      //     Name: this.localMenus[key]
      //   }
      // });
    if(this.localMenus && this.localMenus.length>0)
    {
      this.generateNode();
    }
    else{
      var user= localStorage.getItem('user_id');
       if(user){
        this.userservice.GetLeftmenu(Number(user),this.BranchesId).subscribe((res) => {
          this.localMenus = res.response;
           let submenus=Array();
             localStorage.setItem('localMenus', JSON.stringify(this.localMenus));
             this.generateNode();
          });
       }
    }
  }
  // getChild(activatedRoute: any) {
  //   if (activatedRoute.firstChild) {
  //     return this.getChild(activatedRoute.firstChild);
  //   } else {
  //     return activatedRoute;
  //   }
  // }

  //-----------------------------------END-------------------------------------------//

  /*======== footer on click fetching data in select branch modal    =========*/
generateNode()
{
  this.localMenus=localStorage.getItem('localMenus');
  console.log( this.localMenus);
  
  this.localMenus=JSON.parse(this.localMenus);

       this.nodes= this.localMenus.map(val => ({
        key: val.mid,
        label: val.menuAlias,
        children: val.subMenus.map(sub=>({
          label:sub.subMenuAlias,
          data:sub.subMenuLink,
          type: "url",
          Sid:sub.sId,
           }))
      }));
      console.log(  this.nodes);
 
}

handleClick() {
    if (typeof this.selectedItem.hospitalId == 'undefined') {
      this.toastr.error("Please Select Branch");
    }
    else {
      if (typeof this.selectedLoc.locationId == 'undefined') {
        this.toastr.error("Please Select Location")
      }
      else {
        this.globalvars.HospitalId = this.selectedItem.hospitalId;
        this.globalvars.Location = this.selectedLoc;
        this.globalvars.HospitalName = this.selectedItem.hospitalName + "(" + this.selectedLoc.locationName + ")";
        this.sharedservice.setHospital(this.globalvars);
        localStorage.setItem('GLobalVarInfo', JSON.stringify(this.globalvars));
        location.reload();

      }

    }

  }
  GetLocationonUser(event) {
    var user = localStorage.getItem('user_id');
    if (typeof user != 'undefined') {
      this.hospitalsService.GetUserSpecificHospitalLocations(Number(user), Number(this.selectedItem.hospitalId)).subscribe((res) => {
        this.LocationList = res.response;
        if (this.globalvars.Location != null && typeof this.globalvars.Location != 'undefined') {
          this.selectedLoc = this.globalvars.Location;
        }
      });
    }

  }
  /*======== Fetching data and binding data to branches & locations    =========*/
  GetUserHospitals() {
    var user = localStorage.getItem('user_id');
    if (typeof user != 'undefined' && user) {
      this.hospitalsService.GetUserSpecificHospitals(Number(user)).subscribe((res) => {
        this.hospitalList = res.response;
        if (
          typeof sessionStorage.getItem("globalvars") == "undefined" ||
          !sessionStorage.getItem("globalvars") ||
          sessionStorage.getItem("globalvars") == null
        ) {
          this.globalvars.HospitalId = this.hospitalList[0].hospitalId;
          this.globalvars.HospitalName = this.hospitalList[0].hospitalName;
          this.sharedservice.setHospital(this.globalvars);
        }
      });
    }

  }


  /*======== header showing Open full screen data   =========*/

  openfullScreen() {
    let elem = document.documentElement;
    let methodToBeInvoked =
      elem.requestFullscreen ||
      elem.requestFullscreen ||
      elem["mozRequestFullscreen"] ||
      elem["msRequestFullscreen"];
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
    this.isFullScreen = true;
  }

  /*======== showing Closing full screen data    =========*/
  closeFullScreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
      /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) {
      /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    this.isFullScreen = false;
  }

  /*======== Header switching Toggle theme mode   =========*/

  TogglethemeDarkMode(e) {
    const className = document.getElementById("LeHealth");
    const sidebarName = document.getElementById("left-sidebar");
    let currenttheme = className.getAttribute("data-theme");
    if (currenttheme == "light") {
      className.setAttribute("data-theme", "dark");
      sessionStorage.setItem("Sidebar", "");
      sidebarName.classList.remove("light_active");
    } else {
      className.setAttribute("data-theme", "light");
      sessionStorage.setItem("Sidebar", "light_active");
      sidebarName.classList.add("light_active");
    }
  }
  /*========Header branch and location Modal opening showing data  =========*/
  BranchModal() {
    if (this.globalvars?.HospitalId != null && typeof this.globalvars?.HospitalId != 'undefined') {
      this.selectedItem = this.hospitalList.find(a => a.hospitalId === this.globalvars?.HospitalId);

    }
    this.displayModal = true;
    this.GetLocationonUser(this.selectedItem.hospitalId)
  }

  /*========change fontsize  =========*/
  changeFontSize(size) {
    this.renderer.removeClass(document.body, "fontsize-s");
    this.renderer.removeClass(document.body, "fontsize-m");
    this.renderer.removeClass(document.body, "fontsize-l");

    if (size == "s") {
      sessionStorage.setItem("fontsize", "10px");
      sessionStorage.setItem("fontClass", "fontsize-s");
    } else if (size == "l") {
      sessionStorage.setItem("fontsize", "16px");
      sessionStorage.setItem("fontClass", "fontsize-l");
    } else {
      sessionStorage.setItem("fontsize", "13px");
      sessionStorage.setItem("fontClass", "fontsize-m");
    }

    this.renderer.addClass(document.body, sessionStorage.getItem("fontClass"));
  }

  /*========click function of help modal =========*/
  showMaximizableDialog() {
    this.displayMaximizable = true;
  }
  /*========click function of support modal =========*/
  showBasicDialog() {
    this.displayBasic = true;
  }

  checkForShift(){
    const payload = {
      LocationId: this.globals?.Location?.locationId,
      BranchId: this.globals?.HospitalId,
      UserId: Number(localStorage.getItem('user_id')),
      ShiftDate: this.datePipe.transform(new Date(), 'dd-MM-yyyy')
    }

    if(payload.BranchId && payload.LocationId && payload.UserId && this.shiftChecking){
      this.shiftChecking = false;
      this.userservice.getShiftStatus(payload).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200){
            let shiftStatus = response.response[0];
            if(shiftStatus.enableBilling && shiftStatus.enableShift && !shiftStatus.isShiftOPen){

              let initialState = {
                heading: 'Warning..!',
                content: '<p>could you like to open the shift..?</p>',
                isReasonNeeded: false,
                yesButton: 'Open Shift',
                noButton: 'Close'
              }
              let historyConfig: ModalOptions = {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-dialog-scrollable'
              }
              const confirmModalRef= this.modalService.show(ConfirmModalComponent,Object.assign(
                {class: "modal-dialog-centered "}, 
                historyConfig, 
                { class: 'modal-sm', initialState }) );

              confirmModalRef.content.confirmEmitter.subscribe((res: any)=>{
                if(res.status){
                  let initialState = {
                    shiftdata:{}
                  }

                  let config: ModalOptions = {
                    backdrop: true,
                    ignoreBackdropClick: true,
                  }
                  const modalRef = this.modalService.show(OpenshiftComponent, Object.assign({}, config, { class: 'modal-l', initialState }))
                }
              })
            }
          }else{
            this.toastr.error(response.errorMessage.message);
          }
        },error: (error: any)=>{
          this.toastr.error('Something wents wrong..! Try again.')
        }
      })
    }else{
      console.log('u/ser not logged in')
    }
  }
  navigateToMenu(path,menuId)
  {
   // Replace with your actual menu ID
   // alert(menuId);

    localStorage.setItem('selectedMenu',menuId);
    

    this.router.navigate(['/'+path]);
  }
  toggleClick(){
    this.mobileResBtnClick = !this.mobileResBtnClick
  }
}
