import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GuiCellView, GuiColumn, GuiPaging, GuiPagingDisplay, GuiSearching, GuiSorting, GuiColumnMenu, GuiInfoPanel, GuiDataType } from '@generic-ui/ngx-grid';
import { GeneralActionComponent } from '../../general-action/general-action.component';
import { ServiceOrderingService } from '../../services/ServiceOrderingService';
import { SharedService } from '../../../../shared/shared.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { log } from 'console';
import { ConfigurationService } from '../../services/configuration.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TelerikPrintComponent } from '../../../../shared/modals/telerik-print/telerik-print.component';
@Component({
  selector: 'app-pendingOrdersComponent',
  templateUrl: './pendingordering.component.html',
  styleUrls: ['./pendingordering.component.scss'],

})
export class pendingOrdersComponent implements OnInit {
  serviceorder: boolean = true;
  orderdetails: boolean;
  pendingorder: boolean;
  sourceProducts: any[];
  roducts: any[];
  targetProducts: any[];
  columnDefs: ColDef[] = [];
  columnDefsDetails: ColDef[] = [];

  activeState: boolean[] = [true, false, false];
  //  current date
  displayBasic: boolean;
  valueDate: any;
  rowData: any;
  subscription: Subscription;
  globals: any;
  BranchesId: any;
  searchForm: FormGroup;
  active: string;
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.status == "C";

      },
      "c-reds2": function (params) {
        return params.data.isCancelled == 1;

      },
    },

    domLayout: "autoHeight",
  };

  paging: GuiPaging = {
    enabled: true,
    page: 1,
    pageSize: 10,
    pageSizes: [10, 25, 50],
    pagerTop: false,
    pagerBottom: true,
    display: GuiPagingDisplay.ADVANCED
  };

  sorting: GuiSorting = {
    enabled: true
  };

  searching: GuiSearching = {
    enabled: true,
    placeholder: 'Search Here'
  };
  GuiColumnMenu = {
    enabled: true,
    columnsManager: true
  };

  infoPanel: GuiInfoPanel = {
    enabled: true,
    infoDialog: false,
    columnsManager: true,

  };
  patId: any;
  selectedOrderId: any;
  OrderDetailsById: any;
  valueDate2: any;
  startDateFrom: any;
  startDateTo: any;
  OrderDetails: any;
  ItemIds: any;
  OrderId: any;
  blockorunblock: any;
  confirmblock: boolean;
  BlockResponse: any;
  itemId: any;
  PrintOrder: any;
  OrderById: any;
  Itemdata: any;
  ConsultantId: any;
  userType: any;
  functionPermissionData: any;

  constructor(public datepipe: DatePipe,
    private ServiceOrderingService: ServiceOrderingService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private ConfigurationService: ConfigurationService,
    private modalService: BsModalService,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        // this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId

      }
    });

  }


  onTab(number) {
    this.serviceorder = false;
    this.orderdetails = false;
    this.pendingorder = false;


    if (number == '1') {
      this.serviceorder = true;
    }
    else if (number == '2') {
      this.orderdetails = true;
    }
    else if (number == '3') {
      this.pendingorder = true;
    }

  }
  ngOnInit(): void {
    this.userType = localStorage.getItem("usertype");
    this.ConsultantId = localStorage.getItem('Id');
    let currentappDate = new Date();
    this.valueDate2 = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    let convertDate = currentappDate.setDate(currentappDate.getDate() - 7);
    this.valueDate = this.datepipe.transform(convertDate, 'dd-MM-yyyy');

    this.GetPatientPendingOrders();
    //this.onSelectionChanged(0);

    this.searchForm = this.formBuilder.group({

      blockReason: new FormControl(''),

    });
    this.columnDefs = [

      {
        headerName: "Reg.No",
        minWidth: 150,
        field: 'regNo',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Patient Name",
        minWidth: 150,
        field: 'firstName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },

      {
        headerName: "Order No",
        minWidth: 150,
        field: "orderNo",
        enableRowGroup: false,
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },

      {
        headerName: "Order Date",
        minWidth: 120,
        field: 'orderDate',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,
      },



      {
        headerName: "Mobile",
        minWidth: 150,
        field: 'mobile',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },

      {
        headerName: "Phone",
        minWidth: 150,
        field: 'resNo',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Ordered By",
        minWidth: 180,
        field: 'consultantName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "OrderDetails" },

      },


    ];

    this.columnDefsDetails = [
      {
        headerName: "Service Item",
        minWidth: 180,
        field: 'itemName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 1,
      },
      {
        headerName: "Status",
        minWidth: 180,
        field: 'status',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,
      },
      {
        headerName: "Payment",
        minWidth: 180,
        field: 'payStatus',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Action",
        maxWidth: 100,
        sortable: false,
        pinned: "right",
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "ServiceList" },
      },
    ];




    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.functionPermission();
  }
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);

  // }

  // cancel orders
  showCancelOrders(orderId, grid) {
    let actionId;
    if (grid == 'OS') {
      actionId = 254;
    } else if(grid == 'OD') {
      actionId = 256;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
      this.OrderDetails = orderId.subType
      let OrderIds = orderId.orderId
      this.ItemIds = orderId.itemId
      this.OrderId = OrderIds
      this.blockorunblock = true;
      this.active = 'Cancel';
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  confirmblocking() {
    this.confirmblock = true;

  }
  ConfirmBlock() {

    let blockReason = this.searchForm.get("blockReason").value;
    if (blockReason == "") {
      this.toastr.warning("Enter Block Reason");
    }
    else {
      this.ServiceOrderingService.CancelServiceOrder(Number(this.OrderId), blockReason, Number(this.ItemIds)).subscribe((res) => {
        this.BlockResponse = res.response;
      });
    }
    this.searchForm.get("blockReason").reset();
    setTimeout(() => {
      this.GetPatientPendingOrders();
      this.onSelectionChanged(0);
    }, 1000);
  }


  GetAllServicesOrdersById(selectedOrderId) {
    if (selectedOrderId) {
      this.ServiceOrderingService.GetAllServicesOrdersById(this.selectedOrderId).subscribe((res) => {
        this.OrderById = res.response;
      });
    }
    else {
      this.noRowsTemplate = "No Rows To Show"
      this.gridApi.hideOverlay();
    }
  }


  //searfch patient click modal
  showBasicDialog() {
    this.displayBasic = true;
  }
  //       //Getting Api call for listing service orders
  //   GetAllServicesOrders(){

  //     this.ServiceOrderingService.GetPendingOrders(0,this.globals.HospitalId).subscribe((res) => {
  //       this.rowData = res.response;


  //     });
  // }

  //common search event
  searchevent($event) {
    console.log($event);

    let AppStartFrom = $event.value.ServiceDateFrom;
    let AppStartTo = $event.value.sDateFromapp;
    let BranchesId = $event.value.clinicname;
    if (!BranchesId || BranchesId == 0) {
      BranchesId = this.globals.hospitalId;
    }

    if (typeof (AppStartFrom) == "object") {
      this.startDateFrom = this.datepipe.transform(AppStartFrom, 'dd-MM-yyyy');

    }
    else {
      this.startDateFrom = AppStartFrom;
    }
    if (typeof (AppStartTo) == "object") {
      this.startDateTo = this.datepipe.transform(AppStartTo, 'dd-MM-yyyy');

    }
    else {
      this.startDateTo = AppStartTo;
    }
    BranchesId = Number(BranchesId);

    this.ServiceOrderingService.GetPendingOrdersDetails($event, 0, BranchesId, this.startDateFrom, this.startDateTo).subscribe((res) => {
      this.rowData = res.response;
      this.OrderDetailsById = [];
      if (this.rowData.length == 0) {
        this.toastr.warning("No Orders found");
      }
    });
  }

  GetPatientPendingOrders() {
    this.patId
    this.ServiceOrderingService.GetPendingOrdersDetails("", this.patId, this.globals.HospitalId, this.valueDate, this.valueDate2).subscribe((res) => {
      this.rowData = res.response;
      if (this.rowData.length == 0) {
        this.toastr.warning("No Orders found");
      }
      this.onSelectionChanged(0);
    });
  }

  onSelectionChanged(eventData) {
    if (eventData == 0) {
      this.noRowsTemplate = "No Rows To Show"
      this.OrderDetailsById = []
      //this.gridApi.hideOverlay();

    } else if (eventData) {
      this.selectedOrderId = eventData.data.orderId;
      let selectedOrderDetails = this.rowData.filter(
        (availservice) => availservice['orderId'] == this.selectedOrderId
      );
      this.OrderDetailsById = selectedOrderDetails[0]['itemData'];
    }

  }

  //print reports
  PrintOrderReportCard(orderId, pagetype, itemId) {
    this.itemId = itemId;
    this.PrintOrder = orderId
    this.ServiceOrderingService.GetAllServicesOrdersById(this.selectedOrderId).subscribe((res) => {
      this.OrderById = res.response;
      let ServiceData = this.OrderById.filter((oppdata) => oppdata['itemId'] == this.itemId);

      this.Itemdata = ServiceData[0]['itemName'];

    });

    let ItemArray = [];
    this.OrderById.forEach(function (value) {
      ItemArray.push(value['itemName'])
      // console.log(ItemArray);

    })


    this.ServiceOrderingService.GetServicesOrderLoadByConsultantId(this.globals.HospitalId, this.valueDate, this.valueDate2, this.ConsultantId).subscribe((res) => {
      this.rowData = res.response;
      let ServiceData = this.rowData.filter((oppdata) => oppdata['orderId'] == this.PrintOrder);
      let printContents = '';
      //contact detatils
      let itemName = (pagetype == "OrderDetails") ? ItemArray.toString() : (pagetype == "ServiceList") ? this.Itemdata : ''
      printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:100%">';
      printContents = printContents + ' <table class="print-table1"><tr><td><h3> Jupiter Multi Speciality Center</h3></td></tr>';
      printContents = printContents + ' <tr><td><strong>Item Name:</strong></td><td>' + ItemArray[0] + '</td></tr>';

      printContents = printContents + ' <tr><td><strong>Patient Name:</strong></td><td>' + ServiceData[0]['firstName'] + ' ' + ServiceData[0]['middleName'] + ' ' + ServiceData[0]['lastName'] + '</td></tr>';

      printContents = printContents + ' <tr><td><strong>Order No:</strong></td><td>' + ServiceData[0]['orderNo'] + '</td></tr>';

      printContents = printContents + ' <tr><td><strong>Consultant Name:</strong></td><td>' + ServiceData[0]['consultantName'] + '</td></tr>';
      printContents = printContents + ' <tr><td><strong>Order Date:</strong></td><td>' + ServiceData[0]['orderDate'] + '</td></tr>';
      printContents = printContents + ' <tr><td><strong>Mobile:</strong></td><td>' + ServiceData[0]['mobile'] + '</td></tr>';

      var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
      mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
      mywindow.document.write('</head><body >');
      mywindow.document.write(printContents);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    });
    return true;
  }
  PrintOrderReportCardAll(orderId, pagetype, itemId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 253)
    if (foundElement.id == 253 && foundElement.isAllowed == true) {
      let initialState = {
        modalHeading: 'Print Service Order Details',
        reportName: 'RptServiceOrderDetails.trdp',
        reportParams: {
          ConsultationId: 0,
          OrderId: orderId,
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    } else {
      this.toastr.warning("You are not allowed");
    }
    // const foundElement = this.functionPermissionData.find(x => x.id === 253)
    // if (foundElement.id == 253 && foundElement.isAllowed == true) {
    // this.itemId = itemId;
    // this.PrintOrder = orderId
    // this.ServiceOrderingService.GetAllServicesOrdersById(this.selectedOrderId).subscribe((res) => {
    //   this.OrderById = res.response;
    //   let ServiceData = this.OrderById.filter((oppdata) => oppdata['itemId'] == this.itemId);

    //   this.Itemdata = ServiceData[0]['itemName'];

    // });

    // let ItemArray = [];
    // this.OrderById.forEach(function (value) {
    //   ItemArray.push(value['itemName'])
    // })


    // this.ServiceOrderingService.GetServicesOrderLoadByConsultantId(this.globals.HospitalId, this.valueDate, this.valueDate2, this.ConsultantId).subscribe((res) => {
    //   this.rowData = res.response;
    //   let ServiceData = this.rowData.filter((oppdata) => oppdata['orderId'] == this.PrintOrder);
    //   let printContents = '';
    //   //contact detatils
    //   let itemName = (pagetype == "OrderDetails") ? ItemArray.toString() : (pagetype == "ServiceList") ? this.Itemdata : ''
    //   printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:100%">';
    //   printContents = printContents + ' <table class="print-table1"><tr><td colspan="2" style="text-align:center"><h3> ' + ServiceData[0]['hospitalName'] + '</h3></td></tr>';
    //   printContents = printContents + ' <tr><td><strong></strong></td><td>Order Date:' + ServiceData[0]['orderDate'] + '</td></tr>';
    //   printContents = printContents + ' <tr><td><strong>Patient Name:</strong></td><td>' + ServiceData[0]['firstName'] + ' ' + ServiceData[0]['middleName'] + ' ' + ServiceData[0]['lastName'] + '</td></tr>';
    //   printContents = printContents + ' <tr><td><strong>Reg No:</strong></td><td>' + ServiceData[0]['regNo'] + '</td></tr>';
    //   printContents = printContents + ' <tr><td><strong>Age:</strong></td><td>' + ServiceData[0]['ageData'] + '</td></tr>';
    //   printContents = printContents + ' <tr><td><strong>Gender:</strong></td><td>' + ServiceData[0]['gender'] + '</td></tr>';
    //   printContents = printContents + ' <tr><td><strong>Item Names</strong></td><td></td></tr>';
    //   for (let i = 0; i < ItemArray.length; i++) {
    //     printContents = printContents + ' <tr><td colspan="2"><strong>' + String(i + 1) + ')</strong>' + ItemArray[i] + '</td></tr>';
    //   }
    //   printContents = printContents + ' <tr><td><strong></strong></td><td>Consultant Name:' + ServiceData[0]['consultantName'] + '</td></tr>';
    //   printContents = printContents + ' <tr><td><strong></strong></td><td>Consultant Code:' + ServiceData[0]['consultantCode'] + '</td></tr></table>';
    //   var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
    //   mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
    //   mywindow.document.write('</head><body>');
    //   mywindow.document.write(printContents);
    //   mywindow.document.write('</body></html>');

    //   mywindow.document.close();
    //   mywindow.focus();

    //   mywindow.print();
    //   mywindow.close();
    // });
    // return true;
    // } else {
    //   this.toastr.warning("You are not allowed");
    // }
  }
  GetServicesOrderLoadByPending() {
    this.rowData = [];
    this.OrderById = [];
    this.toastr.warning("No Orders found");


  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  autoInitiate(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 255)
    if (foundElement.id == 255 && foundElement.isAllowed == true) {
      // need to write the functionalities inside this if condition(This is for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  openWorkListModal(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 257)
    if (foundElement.id == 257 && foundElement.isAllowed == true) {
      // this.router.navigate(['/ServiceList/'+data.orderId+'/report/',data.itemId]);
      // need to write the functionalities inside this if condition(This is for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  newInvestigation(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 595)
    if (foundElement?.id == 595 && foundElement.isAllowed == true) {
      // need to write definition inside this if condition
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
}

