import { Component, EventEmitter, Input, OnInit,Output,TemplateRef ,ViewChild  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../client/general-action/general-action.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ResultAddParameterComponent } from '../result-addParameter/result-addParameter.component';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-result-form',
  templateUrl: './result-parameter.component.html',
  styleUrls: ['./result-parameter.component.scss']
})
export class ResultparameterComponent implements OnInit {

  @Input() RformId:0
  @Input() UserId:0

  @Output() resultEmitter=new EventEmitter()


  @ViewChild("template") modaltemp: any;

  resultForm: FormGroup;
  parameterForm: FormGroup;
  parameterDetails:any;
  public gridApi;
  public defaultColDef: ColDef;
  parameterColumnDefs: ColDef[] = [];

  parameterData:any;
  rowGroupPanelShow = "always";
  public context;
  public statusBar;
  public parametergridOptions = {
    pagination: true,
    paginationPageSize: 10,
    // rowClassRules: {
    // },
    suppressRowTransform: true

  }
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  addParameterPopupHeading:String="Add Parameter"
  isLoading=false;

  constructor(private modalRef: BsModalRef,
    private modalService: BsModalService, private toastr: ToastrService,
    private configurationService: ConfigurationService,

  ) { }

  ngOnInit(): void {
    this.resultForm =  new FormGroup({
    });
    this.context = { componentParent: this };

    this.parameterColumnDefs = [
      {
        headerName: " Order Number",
        field: "paramOrder",
        resizable: false,
        sortable: true,
        filter: true,
        flex: 2,
        minWidth: 100,
        enableRowGroup: true,

      },
      {
        headerName: "Parameter Name",
        field: "paramName",
        resizable: false,
        sortable: true,
        filter: true,
        minWidth: 100,
        enableRowGroup: true,
        flex: 2,
      },
      {
        headerName: "Unit",
        field: "paramUnit",
        resizable: false,
        filter: true,
        sortable: true,
        minWidth: 100,
        enableRowGroup: true,
        flex: 2,
      },
      {
        headerName: "Result Type",
        field: "resultTypeName",
        resizable: false,
        filter: true,
        sortable: true,
        minWidth: 70,
        enableRowGroup: true,
        flex: 2,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "parameterList" },
      },
    ];
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.getParameterList();
  }
  onFilterTextBoxChanged(event) {
    this.gridApi.setQuickFilter(
      event.target.value
    );
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

close(){
  this.modalRef.hide();
}
openModalParametrCompany() {
  let initialState = {
    RformId:this.RformId,
    UserId:this.UserId,
    addParameterPopupHeading:"Add Parameter",
  }
console.log();

  const modalRef = this.modalService.show(ResultAddParameterComponent, Object.assign({ class: "gray modal-lg"},{initialState}))
  modalRef.content.parameterEmitter.subscribe((res)=>{
    console.log(res)
    if(res){
    this.getParameterList()
    }
  })

}

editResultParameterForm(data){
  let initialState = {
    RformId:this.RformId,
    addParameterPopupHeading:"Edit Parameter",
    ParamId:data.paramId,
    UserId:this.UserId,

  }

  const modalRef = this.modalService.show(ResultAddParameterComponent, Object.assign({ class: "gray modal-lg"},{initialState }))

  modalRef.content.parameterEmitter.subscribe((res)=>{
    if(res){
    this.getParameterList()
    }
  })
 }
 deleteResultParameterForm(data:any) {
  let formdata = {
    ParamId: data.paramId,
  }
  let resultParamDtl;
  this.configurationService.EditParameterList(formdata).subscribe((res:any)=>{
    resultParamDtl = res.response[0]
    let rangeDtl = resultParamDtl.paramDetails[0]
    this.delResultParams(resultParamDtl , rangeDtl)
  })

  console.log(data)
 }
 delResultParams(data:any, i:any){
 let params :any ={} ;
 let colourDetails= [{"fromAge": 0,"toAge": 0,"gender": 0,"colour": 0}]
let rangeDetails= [{"fromAge": 0,"toAge": 0,"gender": 0,"fromRange": "0","toRange": "0"}]
let positiveDetails= [{"fromAge": 0,"toAge": 0,"gender": 0,"normalValue": 0}]
let clarityDetails= [{"fromAge": 0,"toAge": 0,"gender": 0,"clarity": 0}]
let consistencyDetails= [{"fromAge": 0,"toAge": 0,"gender": 0,"consistency": 0}]
let presenceDetails= [{"fromAge": 0,"toAge": 0,"gender": 0, "presence": 0}]
let reactionDetails= [ {"fromAge": 0,"toAge": 0,"gender": 0,"reaction": 0}]
 if(data){
  params.ResultType = data.resultType;
  params.ParamId = data.paramId;
  params.GroupId = 0
  params.ParamName = data.paramName;
  params.Description = data.description;
  params.ParamUnit = data.paramUnit;
  params.MethodName = data.method;
  params.ParamOrder = data.paramOrder;
  params.IsDisplayed = data.isDisplayed;
  params.IsAccredited = data.isAccredited;
  params.IsDeleted = 1;
  params.UserId = Number(this.UserId);
  params.defaultValue = data.defaultValue;
  params.defaultValue1 = i.fromRange ;
  params.defaultValue2 = i.toRange ;
  if(data.resultType == 1){
      params.colourDetails= [
        {
          "fromAge": i.fromAge,
          "toAge": i.toAge,
          "gender": i.gender,
          "colour": i.colour
        }
      ],
      params.rangeDetails= rangeDetails,
      params.positiveDetails= positiveDetails,
      params.clarityDetails= clarityDetails,
      params.consistencyDetails= consistencyDetails,
      params.presenceDetails= presenceDetails,
      params.reactionDetails= reactionDetails
  } else if(data.resultType == 2){
    params.colourDetails= colourDetails,
    params.rangeDetails=  [
      {
        "fromAge": i.fromAge,
        "toAge": i.toAge,
        "gender": i.gender,
        "fromRange": i.fromRange,
        "toRange": i.toRange
      }
    ],
    params.positiveDetails= positiveDetails,
    params.clarityDetails= clarityDetails,
    params.consistencyDetails= consistencyDetails,
    params.presenceDetails= presenceDetails,
    params.reactionDetails= reactionDetails
  } else if(data.resultType == 3){
    params.colourDetails= colourDetails,
    params.rangeDetails= rangeDetails,
    params.positiveDetails= [
      {
        "fromAge": i.fromAge,
        "toAge": i.toAge,
        "gender": i.gender,
        "normalValue": i.normalvalue
      }
    ],
    params.clarityDetails= clarityDetails,
    params.consistencyDetails= consistencyDetails,
    params.presenceDetails= presenceDetails
    params.reactionDetails= reactionDetails
  } else if(data.resultType == 4){
    params.colourDetails= colourDetails,
    params.rangeDetails= rangeDetails,
    params.positiveDetails= positiveDetails,
    params.clarityDetails= [
      {
        "fromAge": i.fromAge,
        "toAge": i.toAge,
        "gender": i.gender,
        "clarity": i.clarity
      }
    ],
    params.consistencyDetails= consistencyDetails,
    params.presenceDetails= presenceDetails,
    params.reactionDetails= reactionDetails
  } else if(data.resultType == 5){
    params.colourDetails= colourDetails
    params.rangeDetails= rangeDetails
    params.positiveDetails= positiveDetails
    params.clarityDetails= clarityDetails
    params.consistencyDetails= [
      {
        "fromAge": i.fromAge,
        "toAge": i.toAge,
        "gender": i.gender,
        "consistency": i.consistency
      }
    ],
    params.presenceDetails= presenceDetails,
    params.reactionDetails= reactionDetails
  } else if(data.resultType == 6){
    params.colourDetails= colourDetails,
    params.rangeDetails= rangeDetails,
    params.positiveDetails= positiveDetails,
    params.clarityDetails= clarityDetails,
    params.consistencyDetails= consistencyDetails,
    params.presenceDetails= [
      {
        "fromAge": i.fromAge,
        "toAge": i.toAge,
        "gender": i.gender,
        "presence": i.presence
      }
    ],
    params.reactionDetails= reactionDetails
  } else if(data.resultType == 7){
    params.colourDetails= colourDetails,
    params.rangeDetails= rangeDetails,
    params.positiveDetails= positiveDetails,
    params.clarityDetails= clarityDetails,
    params.consistencyDetails= consistencyDetails,
    params.presenceDetails= presenceDetails,
    params.reactionDetails= [
      {
        "fromAge": i.fromAge,
        "toAge": i.toAge,
        "gender": i.gender,
        "reaction": i.reaction
      }
    ]
  }
 }
 console.log(params)
  this.configurationService.saveParameter(params).subscribe((res:any)=>{
    if(res.status == 200 && res.message == 'Success'){
      this.toastr.success(res.message)
      this.getParameterList()
    } else {
      this.toastr.error('Prameter is already used in Patient result.')
    }
  })
 }
getParameterList(){
  let input={
    RformId:this.RformId
  }
  this.configurationService.GetParameterList(input).subscribe((res) => {
    this.parameterData = res.response;
  });

}

}
