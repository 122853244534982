import { Component, OnInit,Input,Output,EventEmitter,ViewChild ,ElementRef, OnChanges, SimpleChanges} from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import {  Subscription } from 'rxjs';

import { ColDef, GridApi } from 'ag-grid-community';
import { ServiceOrderingService } from '../../client/services/ServiceOrderingService';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Select2OptionData } from 'ng-select2';
import { FormGroup,FormControl, FormBuilder } from '@angular/forms';
import { BillingGeneralActionComponent } from "../billing-general-action/billing-general-action.component";
import { ToastrService } from "ngx-toastr";
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { PackageandprofileService } from '../../configurations/package-and-profile/packageandprofile.service';
import { BillingService } from '../services/billing.service';
import Swal from "sweetalert2";
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-new-billing-quick-modal',
  templateUrl: './new-billing-quick-modal.component.html',
  styleUrls: ['./new-billing-quick-modal.component.scss']
})
export class NewBillingQuickModalComponent implements OnInit, OnChanges {

  modalRef: BsModalRef;
  packageModalRef: BsModalRef;
  profileModalRef: BsModalRef;
  globals: globalvars = new globalvars();
  subscription: Subscription;

  @Output() packageOutput = new EventEmitter();
  @Output() profileOutput = new EventEmitter();
  @Output() returnevent = new EventEmitter();
  @Output() specialDiscountEmit = new EventEmitter();
  @Input() patientDetails;
  @ViewChild('template') modaltemp: ElementRef;
  @ViewChild('PackageTemplate') modaltempPackage: ElementRef;
  @ViewChild('ProfileTemplate') modaltempProfile: ElementRef;
  @ViewChild('ManageLOCTemplate') modaltempManageLOC: ElementRef;
  @ViewChild('PendingitemsTemplate') modaltempPendingItems: ElementRef;

  @Input() specialDiscountObject: any;
  @Input() creditId: any;
  @Input() billingItems: any[];
  @Input() billCategory: string = '';
  @Input() RateGroupId: number = 0;


  packageList: any;
  //packageListsData: any;

  //ag grid setup
  public statusBar;
  rowGroupPanelShow = "always";
  public gridApi: GridApi;
  public profileGridApi: GridApi;
  public packageGridApi: GridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  rowData: any[] = [];
  dropdownSettings: IDropdownSettings = {};

  ProfileItems:ColDef[] = [];
  ManageLocItems:ColDef[] = [];
  PendingItems:ColDef[] = [];
  ProfileList: any;
  packageListsData: Array<Select2OptionData> = [];
  packageForm:FormGroup;
  profileForm:FormGroup;
  packageId: number;
  profileId:number;
  itemRateData: any;
  targetProducts: any;
  profileItemData:any;
  pendingItemsData:any;
  profilerowDatas:any=[];
  SponsorName: boolean=true;
  PatientOwn: boolean;
  ManageLoc:any[];
  PatientOwnLoc:any[];
  specialDiscount:FormGroup;

  constructor(
    private modalService: BsModalService,
    private sharedservice: SharedService,
    private packageandprofileService: PackageandprofileService,
    private formBuilder: FormBuilder,
    private billingService: BillingService,
    private NewBillingService:NewBillingService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {

    this.packageForm = new FormGroup({
      PackName: new FormControl(''),
      PackAmount: new FormControl(''),
      Remarks: new FormControl('')

    });
    this.profileForm = new FormGroup({
      profileDesc: new FormControl(''),

    });
    this.specialDiscount = this.formBuilder.group({
      discountPer: new FormControl(this.specialDiscountObject ? this.specialDiscountObject.spclDiscPercentage : '0.00'),
      discountAmount:new FormControl(this.specialDiscountObject ? this.specialDiscountObject.spclDiscAmount : '0.00'),
      remarks:new FormControl(this.specialDiscountObject ? this.specialDiscountObject.spclDiscRemarks : '0.00'),
    });
    
    this.columnDefs = [

        {
          headerName: "Item Name",
          field: "itemName",
          enableRowGroup: false,
          resizable: true,
          sortable: true,
          flex: 2,
        },

        {
          headerName: "Rate",
          field: 'rate',
          sortable: true,
          filter: true,
          enableRowGroup: false,
          resizable: true,
          flex: 2,
        },



    ];
    this.ProfileItems = [

      {
        headerName: "Item Name",
         field: "itemName",
        //field: "profileDesc", 
        enableRowGroup: false,
        resizable: true,
        sortable: true,
        flex: 2,
      },


  ];
  this.ManageLocItems=[
    {
      headerName: "Item Name",
      field: "itemName",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Rate",
      field: "rate",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Quantity",
      field: "qty",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Amount",
      field: "amount",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Action",
      resizable: true,
      unSortIcon: true,
      field: "Id",
      flex: 1,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "ManageLOC" },
    },
  ];
  this.PendingItems=[

    {
      headerName: "Post Date",
      minWidth:100,
      field: "postDate",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Item Name",
      minWidth:100,
      field: "itemName",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Nos",
      minWidth:100,
      field: "nos",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },

    {
      headerName: "Location",
      minWidth:100,
      field: "location",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "UserName",
      minWidth:100,
      field: "user",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Action",
      maxWidth: 80,
      resizable: true,
      sortable: false,
      field: "Id",
      pinned: 'right' ,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "PendingItems" },
    },

  ]


    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `<span>No items to show/Select a package to show items</span>`;
    this.frameworkComponents = {
      buttonRender: BillingGeneralActionComponent,
    };
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'profileId',
      textField: 'profileDesc',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
      defaultOpen: false
    };

// this.ManageLoc=[{itemId: 2,itemName: "Specialist",Rate: "20",Quantity: 1,Amount: 1460}]
// this.PatientOwnLoc=[{itemId: 2,itemName: "Registration",Rate: "2113",Quantity: 10,Amount: 2000}]
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['specialDiscount']) {
      this.specialDiscount?.patchValue({
        discountPer: this.specialDiscountObject ? this.specialDiscountObject.spclDiscPercentage : '0.00',
        discountAmount: this.specialDiscountObject ? this.specialDiscountObject.spclDiscAmount : '0.00',
        remarks: this.specialDiscountObject ? this.specialDiscountObject.spclDiscRemarks : '0.00' 
      })
    }
    
    // console.log(changes)
    // this.ManageLoc = [];
    // this.PatientOwnLoc = [];
    // if(this.billingItems.length > 0){
    //   this.billingItems.forEach((item)=>{
    //     if(item?.insCovered){
    //       this.ManageLoc.push(item);
    //     }else{
    //       this.PatientOwnLoc.push(item);
    //     }
    //   })
    // }

    // if(this.RateGroupId){
    //   this.getPackageList();
    //   this.GetProfileList();
    // }
  }
  
  open() {
    this.modalRef = this.modalService.show(this.modaltemp,);
  }

  onCloseSpecialPackage(){
    if(this.specialDiscount.get('discountPer').dirty || this.specialDiscount.get('discountAmount').dirty){

      let initialState = {
        heading: 'Are you sure?',
        content: `Close window without save special discount..?`,
        confirmButtonText: 'No, Save Special Discount',
        cancelButtonText: 'Yes, Close',
        isReasonNeeded: false
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef= this.modalService.show(ConfirmModalComponent,Object.assign( {}, config, { class: 'modal-sm', initialState }) );
      modalRef.content.confirmEmitter.subscribe(res=>{
        if(res.status){
          this.saveBillDiscount();
        }else{
          this.specialDiscount.patchValue({
            discountPer: this.specialDiscountObject.spclDiscPercentage,
            discountAmount: this.specialDiscountObject.spclDiscAmount,
            remarks: this.specialDiscountObject.spclDiscRemarks,
          })
          this.modalRef.hide();
        }
      })
      // Swal.fire({
      //   title: "Are you sure?",
      //   text: "Close window without save special discount..?",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonText: "No, Save Special Discount",
      //   cancelButtonText: "Yes, Close",
      // }).then((result) => {
      //   if (result.value) {
      //     this.saveBillDiscount();
      //   }else{
      //     this.modalRef.hide();
      //     this.specialDiscount.patchValue({
      //       discountPer: this.specialDiscountObject.spclDiscPercentage,
      //       discountAmount: this.specialDiscountObject.spclDiscAmount,
      //       remarks: this.specialDiscountObject.spclDiscRemarks,
      //     })
      //   }
      // });
    }else{
      this.modalRef.hide();
    }
  }

    // to get package list for grid

    getPackageList() {
      let input = {
        "PackId": 0,
        "ShowAll": 1,
        "BranchId": this.globals.HospitalId,
        category: this.billCategory,
        RateGroupId: this.RateGroupId
      }
      this.packageandprofileService.getpackages(input).subscribe((res) => {
        this.packageList = res.response;
        this.packageListsData = [];
        this.packageListsData.push({
          id: '',
          text: 'Select a Package',
        });
        this.packageList.forEach((a) => {
          this.packageListsData.push({
            id: a.packId.toString(),
            text: a.packDesc,
          });
        });
      });
    }

    PackageModalopen() {
      let input = {
        "PackId": 0,
        "ShowAll": 1,
        "BranchId": this.globals.HospitalId,
        category: this.billCategory,
        RateGroupId: this.RateGroupId
      }
      this.packageandprofileService.getpackages(input).subscribe((res) => {
        this.packageList = res.response;
        this.packageListsData = [];
        this.packageListsData.push({
          id: '',
          text: 'Select a Package',
        });
        this.packageList.forEach((a) => {
          this.packageListsData.push({
            id: a.packId.toString(),
            text: a.packDesc,
          });
        });
        this.packageModalRef = this.modalService.show(this.modaltempPackage,{ class: "gray modal-lg" });
      });
      
    }

      //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }

  onProfileGridReady(params){
    this.profileGridApi = params.api;
  }

    //on changing package getting package amount
    loadamount(event) {

      let packageDetail = this.packageList.filter(
        (patientData) => patientData.packId == event
      );

      let packageAmount = packageDetail[0]['packAmount'];
      let Remarks=packageDetail[0]['remarks'];
      this.packageForm.patchValue({PackAmount:packageAmount });
      this.packageForm.patchValue({Remarks:Remarks });
      this.loadPackageItem(event);
    }

    loadPackageItem(event) {
      let payload = {
        PackId: Number(event),
        CreditId: Number(this.creditId),
        Category: this.billCategory
      }
      this.gridApi.showLoadingOverlay();
      this.billingService.getPackageItems(payload).subscribe((res) => {
        this.rowData = res.response;
        this.gridApi.hideOverlay();
      });
    
    }

    GetProfileList() {
      let payload = {
        ProfileId: 0,
        ShowAll: 0,
        BranchId: this.globals.HospitalId,
        category: this.billCategory,
        RateGroupId: this.RateGroupId
      }
      this.billingService.GetProfile(payload).subscribe((res) => {
        this.ProfileList = res.response;

      });

    }


    ProfileSubItems() {
      let profileId = this.profileForm.get("profileDesc").value;
      let profileIds = [];
      profileId.forEach((element) => {
        profileIds.push(element.profileId);
      });
     
      let input = {
        ProfileIdList: profileIds,
        BranchId: this.globals.HospitalId,
        Category: this.billCategory,
        CreditId: this.creditId,
        RateGroupId: this.RateGroupId
      }
      this.profileGridApi.showLoadingOverlay();
      this.NewBillingService.getProfileItems(input).subscribe((res) => {
      this.profileItemData = res.response;
      this.profilerowDatas = []; 
      if(this.profileItemData){
        this.profileItemData.forEach((element) => {
          element.profileItems?.forEach((a) => {
            console.log(a);
            
            this.profilerowDatas.push(a);
          })
        });
        this.profileGridApi.setRowData(this.profilerowDatas);
        this.profileGridApi.hideOverlay();
      }
      });

    }


    ProfileModalopen() {
      let payload = {
        ProfileId: 0,
        ShowAll: 0,
        BranchId: this.globals.HospitalId,
        category: this.billCategory,
        RateGroupId: Number(this.RateGroupId)
      }
      this.billingService.GetProfile(payload).subscribe((res) => {
        this.ProfileList = res.response;
        this.profileModalRef = this.modalService.show(this.modaltempProfile,{ class: "gray modal-lg" });
      });
    }

    ManageLOCModalopen() {
      this.modalRef = this.modalService.show(this.modaltempManageLOC,{ class: "gray modal-lg" });
    }
    PendingItemsModalopen(){
      this.modalRef = this.modalService.show(this.modaltempPendingItems,{ class: "gray modal-lg" });
      this.pendingItems();
    }

    selectpackage() {
      let packageId = this.packageForm.get("PackName").value;
      this.packageId=packageId;
      let selectpackageDetail = this.packageList.filter(
        (Itemsdata) => Itemsdata.packId == packageId
      );
      this.packageOutput.emit({data: this.rowData, packageName: selectpackageDetail[0]?.packDesc, packageId: packageId});
      this.packageForm.reset();
      this.rowData = [];
      this.packageModalRef.hide()
    }

    selectProfile(){
        this.profileOutput.emit(this.profilerowDatas);
        this.profileModalRef.hide()
    }


pendingItems() {
  if(this.NewBillingService.PatientDataDetails){
    let PatientId=this.NewBillingService.PatientDataDetails.data.patientId;
    let ConsultantId=this.NewBillingService.PatientDataDetails.data.consultantId;
    let InputData={
      "PatientId":PatientId,
      "ConsultantId":ConsultantId,
      "External":0,
      "BranchId":this.globals.HospitalId,
      "ShowAll":0
    }
        this.NewBillingService.getPendingItems(InputData).subscribe((res) => {
          this.pendingItemsData = res.response;
        });
  }
 
      // this.selectpackage()
    }
  

    pendingItemsCheckingList(event){

    }
    onTab(number) {
      this.SponsorName = false;
      this.PatientOwn = false;
  
      if (number == '1') {
        this.SponsorName = true;
      }
      else if (number == '2') {
        this.PatientOwn = true;
      }
  
    }


    saveBillDiscount(){
      debugger
      let discountPer = this.specialDiscount.get("discountPer").value;
      let discountAmount = this.specialDiscount.get("discountAmount").value;
      let remarks = this.specialDiscount.get("remarks").value;
      let specialdiscounts=[{discountPer:discountPer,discountAmount:discountAmount,remarks:remarks}]
      this.specialDiscountEmit.emit(specialdiscounts);
      this.modalRef.hide()
    }

    onDiscountInput(type: string){
      let percentage = Number(this.specialDiscount.get('discountPer').value);
      let amount = Number(this.specialDiscount.get('discountAmount').value);
      switch(type){
        case 'percentage' : this.specialDiscount.get('discountAmount').setValue(0.00);
                            this.specialDiscount.get('discountPer').setValue( percentage > 100 ? 100 : (percentage < 0.00 ? 0 : percentage));
                            break;
                      
        case 'amount'     : this.specialDiscount.get('discountPer').setValue(0.00);
                            this.specialDiscount.get('discountAmount').setValue( amount < 0.00 ? 0 : amount);
                            break;

        default           : this.specialDiscount.get('discountPer').setValue(0.00);
                            this.specialDiscount.get('discountAmount').setValue(0.00);
      }
    }

    onPercentageChange(){
      let percentage = Number(this.specialDiscount.get('discountPer').value);
      this.specialDiscount.get('discountPer').setValue( percentage > 100 ? 100 : (percentage < 0 ? 0 : percentage));
    }

}
