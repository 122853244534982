import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emr-infobar',
  templateUrl: './emr-infobar.component.html',
  styleUrls: ['./emr-infobar.component.scss']
})
export class EMRInfobarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
