import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PrevVitalSignsModalComponent } from '../modals/prev-vital-signs-modal/prev-vital-signs-modal.component';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EMRService } from '../../services/emr.service';
import { PrevMenstrualHistoryModalComponent } from '../modals/prev-menstrual-history-modal/prev-menstrual-history-modal.component';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { Settings } from '../../../settings/model/settings.model';

@Component({
  selector: 'app-menstrual',
  templateUrl: './menstrual.component.html',
  styleUrls: ['./menstrual.component.scss']
})
export class MenstrualComponent implements OnInit,OnChanges {
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  disabled:boolean=false

  public EmrPatientInfo:EMRPatientInfo;
  private FormValueChangeChecker: boolean = false;
  modalRefAlert?: BsModalRef;
  public menstrualForm: FormGroup;
  ConsultationTime: FormGroup;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  firstDate: any;
  secondDate: any;
  AlertList: any;
  alertId: any;
  isEdit: any = false;
  constructor(private modalService: BsModalService,
              private EmrService: EMRService,private sharedservice: SharedService,
              private toastrService: ToastrService,private datepipe: DatePipe,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.EmrPatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initform();
    this.GetMenstrualHistory();
    this.ConsultationTime = new FormGroup({
      StartTime: new FormControl(''),
      EndTime: new FormControl(''),
      Details: new FormControl(''),
      IsActive: new FormControl(true),
    });
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.globals.HospitalId
        this.BranchesId = this.globals.HospitalId;
      }

    });
    this.GetAlert()
  }

 ngOnChanges(changes: SimpleChanges): void {
  if (changes.emrConfig) {
    const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
      // Replace 'dynamicSettId' with the actual dynamic settId value
      return setting.settId == 38 && setting.settValue == '0' && this.billEdit == true;
    });
    
    if (isSettingWithValueZeroPresent) {
      // Your logic when a setting with value 0 is present and billEdit is true
    
      this.disabled=true
    }
    
  }
}
  GetAlert() {
    let input = {
       "PatientId": this.EmrPatientInfo.PatientId,
       "BranchId": this.BranchesId,
       "AlertId": 0,
       "AlertType": '',
       };
    this.AlertList = [];
    this.EmrService.getAlertList(input).subscribe(
      (res:any) => {
        if (res.status == 200) {
          let Response = res.response
          if (Response.length > 0) {
            this.AlertList = res.response;
            let date = new Date()
            this.AlertList.map((res:any)=>{
              if(res.isDisplayed && res.alertType === 'LMP') {
                this.isEdit = true;
                this.menstrualForm.get('StartTime').setValue(res.validFrom)
                this.alertId = res.alertId
              }
              if(res.isDisplayed){
                let date1 = moment(res.validTo).isSame(date);
                let date2 = moment(res.validTo).isBefore(date);
                if(date2){
                  res.validDate = true;
                } else {
                  res.validDate = false
                }
              }
            })
          }
        }
      });
  }
  initform(){
    this.menstrualForm = this.formBuilder.group({
      mid: new FormControl(0),
      menarche: new FormControl(""),
      cycle: new FormControl(""),
      lmp: new FormControl(""),
      flow: new FormControl(""),
      StartTime: new FormControl(""),
      contraception: new FormControl(""),
      papSmear: new FormControl(""),
      memogram: new FormControl(""),
      obstertrichHistory: new FormControl(""),
      visitId: new FormControl(this.EmrPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
      LMP2: new FormControl(0),
      Date: new FormControl(''),
      HTN: new FormControl(false),
      DM: new FormControl(false),
      PSY: new FormControl(false),
      Other: new FormControl(false),
      Gravida: new FormControl(""),
      Parity: new FormControl(""),
      EDC: new FormControl(""),
      Infertility: new FormControl(""),
      Infertility1: new FormControl(false),
      Infertility2: new FormControl(false),
      Anaemia: new FormControl(""),
      Oedema: new FormControl(""),
      CV: new FormControl(0),
      RS: new FormControl(0),
      BloodGroupNote: new FormControl(""),
      BloodGroup: new FormControl(0),
      BreastFeeding: new FormControl(""),
      BreastFeeding1: new FormControl(false),
      BreastFeeding2: new FormControl(false),
      NormalDelivery: new FormControl(false),
      NormalDeliveryNote: new FormControl(""),
      Caesaerian: new FormControl(false),
      CaesaerianNote: new FormControl(""),
      LCB: new FormControl(false),
      LCBNote: new FormControl(""),
      GynecHistory: new FormControl(""),
      UVScan: new FormControl(""),
    })
  }

  GetMenstrualHistory(){
    let input={
      VisitId:this.EmrPatientInfo.VisitId,
      PatientId:this.EmrPatientInfo.PatientId,
      ShowAll:1
    };

    this.EmrService.GetMenstrualHistory(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          // this.menstrualForm.patchValue(response);
          if(res.response.length !== 0){
          this.menstrualForm.patchValue({
            mid: response?.mid,
            menarche: response?.menarche,
            cycle: response?.cycle,
            lmp: response?.lmp,
            flow: response?.flow,
            contraception: response?.contraception,
            papSmear: response?.papSmear,
            memogram: response?.memogram,
            obstertrichHistory: response?.obstertrichHistory,
            visitId: response?.visitId,
            userId: response?.userId,
            LMP2: response?.lmP2,
            HTN: response?.htn,
            DM: response?.dm,
            PSY: response?.psy,
            Other: response?.other,
            Gravida: response?.gravida,
            Parity: response?.parity,
            EDC: response?.edc,
            // Infertility: response.infertility,
            Anaemia: response?.anaemia,
            Oedema: response?.oedema,
            CV: response?.cv,
            RS: response?.rs,
            BloodGroupNote: response?.bloodGroupNote,
            BloodGroup: response?.bloodGroup,
            // BreastFeeding: response.breastFeeding,
            NormalDelivery: response?.normalDelivery,
            NormalDeliveryNote: response?.normalDeliveryNote,
            Caesaerian: response?.caesaerian,
            CaesaerianNote: response?.caesaerianNote,
            LCB: response?.lcb,
            LCBNote: response?.lcbNote,
            GynecHistory: response?.gynecHistory,
            UVScan: response?.uvScan,
          });
          if(response?.infertility === 'Threated'){
            this.menstrualForm.patchValue({
              Infertility1: true,
              Infertility2: false
            })
          } else if(response?.infertility === 'UnThreated'){
            this.menstrualForm.patchValue({
              Infertility2: true,
              Infertility1: false,
            })
          }
          if(response?.breastFeeding === 'no'){
            this.menstrualForm.patchValue({
              BreastFeeding2: true,
              BreastFeeding1: false,
            })
          } 
          else if(response?.breastFeeding === 'yes'){
            this.menstrualForm.patchValue({
              BreastFeeding1: true,
              BreastFeeding2: false,
            })
          }
        }
          this.menstrualForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker=true;
           })
        }
      });
  }

  SaveMenstrualHistory(){
    if(this.EmrPatientInfo.CrudType!=3 &&
      this.EmrPatientInfo.VisitId>0 && this.FormValueChangeChecker)
    {
      let MenstrualHistory = this.menstrualForm.getRawValue();
      MenstrualHistory.userId=Number(localStorage.getItem('user_id'));
      if(MenstrualHistory.Infertility1 == true) {
        MenstrualHistory.Infertility = "Threated"
      } else if(MenstrualHistory.Infertility2 == true){
        MenstrualHistory.Infertility = "UnThreated"
      }

      if(MenstrualHistory.BreastFeeding1 == true) {
        MenstrualHistory.BreastFeeding = 'yes'
      } else if(MenstrualHistory.BreastFeeding2 == true){
        MenstrualHistory.BreastFeeding = 'no'
      }

      MenstrualHistory.LMP2 = Number(MenstrualHistory.LMP2);
      MenstrualHistory.CV = Number(MenstrualHistory.CV);
      MenstrualHistory.RS = Number(MenstrualHistory.RS);
      MenstrualHistory.BloodGroup = Number(MenstrualHistory.BloodGroup);

      this.EmrService.SaveMenstrualHistory(
        MenstrualHistory
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastrService.success("Success: " + "Menstrual History Details Saved Successfully...");
          }
          else{
           this.toastrService.error(res.errorMessage.message)
          }
        });
    }
    else{
      if(!this.FormValueChangeChecker){
       // this.toastr.warning("Info: " + "No changes detected in the entry");
      }
    }
  }
  openModalAlert(template: TemplateRef<any>) {
    this.modalRefAlert = this.modalService.show(template, { class: 'modal-lg modal-custom-style' });
    if(this.menstrualForm.get('StartTime').value && !this.isEdit) {
      this.firstDate = this.datepipe.transform(this.menstrualForm.get("StartTime").value, 'dd-MM-yyyy');
      this.ConsultationTime.controls.StartTime.setValue(this.firstDate)
      let date1= moment(this.menstrualForm.get("StartTime").value).add(10, 'month').toDate(); // March 28
      this.secondDate = this.datepipe.transform(date1, 'dd-MM-yyyy');
      this.ConsultationTime.get("EndTime").setValue(this.secondDate)
      this.ConsultationTime.get("Details").setValue(this.secondDate)
    } else {
      this.AlertList.map((res:any)=>{
        if(res.alertType === 'LMP'){
          this.firstDate = res.validFrom
          this.secondDate = res.validTo
          this.ConsultationTime.controls.StartTime.setValue(res.validFrom)
          this.ConsultationTime.get("EndTime").setValue(res.validTo) 
          this.ConsultationTime.get("Details").setValue(res.alertNote) 
          this.ConsultationTime.get("IsActive").setValue(res.isDisplayed) 
        }
      })
    }
  }
  openHistory(){
    let initialState = {
      EmrPatientInfo: this.EmrPatientInfo
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const historyModalRef= this.modalService.show(PrevMenstrualHistoryModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }
  saveAlert(){
    if(this.ConsultationTime.get("StartTime").value === ""){
      this.toastrService.error("Please Choose Start Date");
    } else if(this.ConsultationTime.get("EndTime").value === ""){
      this.toastrService.error("Please Choose End Date");
    } else {
      let FirstDate;
      let SecondDate
      if(this.firstDate === this.ConsultationTime.get("StartTime").value) {
        FirstDate = this.ConsultationTime.get("StartTime").value
        FirstDate = FirstDate.replaceAll("/", "-");
      } else {
        FirstDate = this.datepipe.transform(this.ConsultationTime.get("StartTime").value, 'dd-MM-yyyy');
      }
      if(this.secondDate === this.ConsultationTime.get("EndTime").value) {
        SecondDate = this.ConsultationTime.get("EndTime").value
        SecondDate = SecondDate.replaceAll("/", "-");
      } else {
        SecondDate = this.datepipe.transform(this.ConsultationTime.get("EndTime").value, 'dd-MM-yyyy');
      }
      let data= {
        ValidFrom: FirstDate,
        ValidTo:SecondDate,
        AlertNote:this.ConsultationTime.get("Details").value !== null ? this.ConsultationTime.get("Details").value : '',
        AlertId:this.isEdit ? this.alertId :  0,
        PatientId:this.EmrPatientInfo.PatientId,
        AlertType:'LMP',
        UserId:Number(localStorage.getItem('user_id')),
        BranchId:Number(this.globals.HospitalId),
        IsDeleted:0,
        IsDisplayed:this.ConsultationTime.get("IsActive").value ? this.ConsultationTime.get("IsActive").value : false
      }
      this.EmrService.addAlert(data).subscribe((res:any)=>{
        if(res.status === 200 && res.message == "Success"){
          this.toastrService.success("Success : Alert is added Successfully.");
          this.ConsultationTime.reset()
          this.modalRefAlert.hide();
          this.GetAlert();
        } else {
          this.toastrService.error("Failed : " + res.message);
        }
      },(err:any)=>{
        this.toastrService.error(err?.error?.message);
      })
    }
  }
  datePicker(i:any){
    let date1= moment(i).add(10, 'month').toDate();; // March 28
    this.secondDate = this.datepipe.transform(date1, 'dd-MM-yyyy');
    this.ConsultationTime.get("EndTime").setValue(this.secondDate)
  }

}
