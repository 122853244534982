import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { RegistrationReportComponent } from './registration-report/registration-report.component';

import { SharedModule } from '../../shared/models/shared.module';
import { ConsultationReportComponent } from './consultation-report/consultation-report.component';
import { AppointmentReportComponent } from './appointment-report/appointment-report.component';
import { BillReportComponent } from './bill-report/bill-report.component';
import { BillSettlementDetailsComponent } from './bill-settlement-details/bill-settlement-details.component';
import { DayEndReportComponent } from './day-end-report/day-end-report.component';
import { ReceiptAndPaymentReportComponent } from './receipt-and-payment-report/receipt-and-payment-report.component';
import { TreatmentDetailsComponent } from './treatment-details/treatment-details.component';
import { RevenueReportsComponent } from './revenue-reports/revenue-reports.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { UserLogReportComponent } from './user-log-report/user-log-report.component';
import { CollectionSummarayComponent } from './collection-summaray/collection-summaray.component';
import { CollectionSummarayDaywiseComponent } from './collection-summaray-daywise/collection-summaray-daywise.component';
import { CollectionSummarayGroupwiseComponent } from './collection-summaray-groupwise/collection-summaray-groupwise.component';
import { ConsultantDrugSalesComponent } from './consultant-drug-sales/consultant-drug-sales.component';
import { ConsultantWiseBillDetailsComponent } from './consultant-wise-bill-details/consultant-wise-bill-details.component';
import { DailyCollectionSummaryComponent } from './daily-collection-summary/daily-collection-summary.component';
import { DailySalesReportComponent } from './daily-sales-report/daily-sales-report.component';
import { DiscountedBillDetailsComponent } from './discounted-bill-details/discounted-bill-details.component';
import { EPrescriptionReportComponent } from './e-prescription-report/e-prescription-report.component';
import { ItemWiseSaleComponent } from './item-wise-sale/item-wise-sale.component';
import { ItemWiseSaleSummaryComponent } from './item-wise-sale-summary/item-wise-sale-summary.component';
import { PackageSaleComponent } from './package-sale/package-sale.component';
import { RevenueItemwiseDiscountComponent } from './revenue-itemwise-discount/revenue-itemwise-discount.component';
import { RevenueSummaryReportComponent } from './revenue-summary-report/revenue-summary-report.component';
import { SpecialDiscountsDetailsComponent } from './special-discounts-details/special-discounts-details.component';
import { SponsorRevenueComponent } from './sponsor-revenue/sponsor-revenue.component';
import { SponsorRevenueMonthwiseComponent } from './sponsor-revenue-monthwise/sponsor-revenue-monthwise.component';


@NgModule({
  declarations: [
    ReportsComponent,
    RegistrationReportComponent,
    ConsultationReportComponent,
    AppointmentReportComponent,
    BillReportComponent,
    BillSettlementDetailsComponent,
    DayEndReportComponent,
    ReceiptAndPaymentReportComponent,
    TreatmentDetailsComponent,
    RevenueReportsComponent,
    PdfViewerComponent,
    UserLogReportComponent,
    CollectionSummarayComponent,
    CollectionSummarayDaywiseComponent,
    CollectionSummarayGroupwiseComponent,
    ConsultantDrugSalesComponent,
    ConsultantWiseBillDetailsComponent,
    DailyCollectionSummaryComponent,
    DailySalesReportComponent,
    DiscountedBillDetailsComponent,
    EPrescriptionReportComponent,
    ItemWiseSaleComponent,
    ItemWiseSaleSummaryComponent,
    PackageSaleComponent,
    RevenueItemwiseDiscountComponent,
    RevenueSummaryReportComponent,
    SpecialDiscountsDetailsComponent,
    SponsorRevenueComponent,
    SponsorRevenueMonthwiseComponent,
],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedModule
  ]
})
export class ReportsModule { }
