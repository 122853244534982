import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from "rxjs";
import { SharedService } from "../../../../shared/shared.service";
import { UserpermissionService } from "../../userpermission.service";
import { ToastrService } from "ngx-toastr";
import { timeStamp } from 'console';

@Component({
  selector: 'app-user-functionality',
  templateUrl: './user-functionality.component.html',
  styleUrls: ['./user-functionality.component.scss']
})
export class UserFunctionalityComponent implements OnInit {
  @Input() UserId: 0
  masterSelected: any;
  groupmap: FormGroup;
  public form: FormGroup;
  subscription: Subscription;
  branchesId: any;
  userData: any;
  isLoading = false;
  result: any;
  checkedList: any;
  innerUserData: any
  public submitted: boolean = false;
  public checkedItems = [];
  checkedValues: number[] = [];
  public isCollapsed: boolean = false;
  constructor(private modalRef: BsModalRef,
    private sharedservice: SharedService,
    private Userservice: UserpermissionService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        let globals = glob;
        this.branchesId = globals.HospitalId;

      }
    });

  }

  ngOnInit(): void {
    this.getUserPErmissionData();
    this.groupmap = new FormGroup({
      MenuActionIdList: new FormControl([]),
      UserId: new FormControl(this.UserId),
    });
  }
  updateCheckedValues(event: any) {
    var isChecked = event.target.checked;
    var data = event.target.id;
    console.log('Checkbox is checked:', isChecked);
    console.log('Checkbox is checked:', data);


  }
  getUserPErmissionData() {
    let input = {
      UserId:this.UserId,
      // UserId: 376,
      BranchId: this.branchesId
    }
    this.Userservice.getUserPermission(input).subscribe((res) => {
      this.userData = res.response;

      this.userData.forEach(item => {
        item.subMenus.forEach(data => {
          if (data.menuActions != null) {
            data.menuActions.forEach(datalist => {
              if (datalist.isAllowed == true) {
                this.checkedValues.push(datalist.id);
              }
            })
          }
        });

      })
      return this.checkedValues;

    });

    console.log(this.checkedValues);

  }

  save() {
    this.submitted = true;
    if (this.submitted == true && this.groupmap) {
      var formdata = this.groupmap.getRawValue();
      formdata.MenuActionIdList = this.checkedValues;
      this.isLoading = true;
      this.Userservice.saveUserPermission(formdata).subscribe((res) => {
        this.submitted = false;

        this.result = res;

        if (this.result.status == 200 && this.result.message == 'Success') {
          this.isLoading = false;
          this.toastr.success("Service Item Assigned successfully");

          this.close();
          // this.getitemEmitter.emit({ status: true });
        }
        else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      })

    }


  }


  checkboxChanged(checked: boolean, value: number) {
    if (checked) {
      this.checkedValues.push(value);
    } else {
      const index = this.checkedValues.indexOf(value);
      if (index > -1) {
        this.checkedValues.splice(index, 1);
      }
    }

  }
  close() {
    this.modalRef.hide();
  }
  selectAll(domobj) {
    let element333 = domobj.target.parentElement.parentElement.querySelectorAll('.menuacioncls input');
    element333.forEach(item => {
      if (domobj.target.checked == true) {
        item.checked = true;
        let itemdata =  this.checkedValues.find(x => x == Number(item.value));    
        if(itemdata== undefined){
          this.checkedValues.push(Number(item.value))

        }
      }
      else {
        item.checked = false;
        let index = this.checkedValues.findIndex(x => x==Number(item.value));
       this.checkedValues.splice(index, 1);
    
  
      }
    });
  }

}


