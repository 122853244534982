import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import SignaturePad from 'signature_pad';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigurationManager } from '../../../../../../assets/configuration/configuration-manager';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from '../../../services/patient.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-informed-static-consent',
  templateUrl: './informed-static-consent.component.html',
  styleUrls: ['./informed-static-consent.component.scss']
})
export class InformedStaticConsentComponent implements OnInit {

  @Input() consultationId: number;
  @Input() branchId: number;
  @Input() ConsentPatientId: any;
  @Input() cType: string;
  @Input() SelectedSponsor: number;
  @Input() data: any;

  private canvasContext: CanvasRenderingContext2D;
  private baseUrl: string = "";
  private signStarted: boolean = false;

  public documentLoading: boolean = false;
  public signatureAdded: boolean = false;

  public reportName: string = "";
  public serviceUrl: string = "";
  public viewerContainerStyle = {
    position: "relative",
    width: "100%",
    height: "550px",
    ["font-family"]: "ms sans serif",
  };
  public reportParams;
  update:boolean=true
  signaturePad: SignaturePad;
  documentFile: File;
  telerikName: string;
  @ViewChild("canvas") canvasEl: ElementRef;
  @ViewChild("witnessCanvas") canvasEl1: ElementRef;
  witnessName: any = "";
  signaturePadWitness: SignaturePad;
  witnessSign: File;
selectedprocedures: any;
procedureItems: any;
dropdownSettings: IDropdownSettings = {};

  constructor(
    private activeModal: BsModalRef,
    private sanitizer: DomSanitizer,
    private configurationManager: ConfigurationManager,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private patientService: PatientService,
  ) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'itemId',
      textField: 'itemName',
      // selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };

    this.reportParams = {
      ConsultationId: this.consultationId,
      BranchId: this.branchId,
      SympMultLine: 0,
      SignMultLine: 0,
      DiagMultLine: 0,
      DrugMultLine: 0,
      SOMultLine: 0,
      PatientId: this.ConsentPatientId,
      ConsentType: 'IC'
    };
    this.telerikName = 'Rpt_InformedConsent.trdp';
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.serviceUrl = (this.configurationManager as any).reportServiceUrl;
    this.GetServiceItemsByConsultationId()
  }

  ngAfterViewInit() {
    let canvasRef: any = <HTMLCanvasElement>(
      document.getElementById("canvas-signature")
    );
    let canvas: CanvasRenderingContext2D = canvasRef.getContext("2d");
    canvas.lineWidth = 1;
    canvas.lineJoin = "round";
    canvas.strokeStyle = "#3e3f40";
    canvas.fillStyle = "#3e3f40";
    this.canvasContext = canvas;
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.signaturePad.maxWidth = 0.9;
    this.signaturePadWitness = new SignaturePad(this.canvasEl1.nativeElement);
    this.signaturePadWitness.maxWidth = 0.9;
  }

  close() {
    this.activeModal.hide();
  }

  startDrawing(event: MouseEvent) {
    this.canvasContext.beginPath();
    this.canvasContext.moveTo(event.offsetX, event.offsetY);
    this.signStarted = true;
  }

  draw(event: MouseEvent) {
    if (this.signStarted) {
      this.canvasContext.lineTo(event.offsetX, event.offsetY);
      this.canvasContext.stroke();
    }
  }

  stopDrawing() {
    this.canvasContext.closePath();
    this.signStarted = false;
  }

  clearSignature() {
    this.canvasContext.clearRect(0, 0, 150, 70);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }

  savePatientSignature() {
    const itemNames = this.selectedprocedures.map(item => item.itemName).join(', ');
    debugger
    if (this.signaturePad.isEmpty() == true) {
      this.toastrService.warning("Signpad is empty!");
    } else {
      const signName = "sign.jpeg";
      let canvas = document.getElementById(
        "canvas-signature"
      ) as HTMLCanvasElement;
      let base64Data = canvas.toDataURL();
      let signsplit = base64Data.split(";");
      const signBlob = this.dataURItoBlob(signsplit[1].split(",")[1]);
      const signFile = new File([signBlob], signName, { type: "image/jpeg" });
      this.documentFile = signFile;
      const formData = new FormData();
      formData.append("Sign", this.documentFile, this.documentFile.name);
      var AppData: any;
      let TypeOfConsent = this.cType;
      if (TypeOfConsent == "IC") {
        AppData = {
          patientId: this.ConsentPatientId,
          ConsentId: 0,
          sponsorId: 0,
          BranchId: this.branchId,
          TypeOfConsent: TypeOfConsent,
          consentContent: "",
          StaffId: 0,
          WitnessName: this.witnessName ? this.witnessName : "",
          ConsultantId: 0,
          ConsultationId: this.data?.consultationId,
          Treatments:itemNames
        };
      }
      if (this.signaturePadWitness.isEmpty() !== true) {
        const signName1 = "witnessSign.jpeg";
        const base64Data1 = this.signaturePadWitness.toDataURL();

        let signsplit1 = base64Data1.split(";");

        const signBlob1 = this.dataURItoBlob(signsplit1[1].split(",")[1]);

        const signFile1 = new File([signBlob1], signName1, {
          type: "image/jpeg",
        });

        this.witnessSign = signFile1;
        formData.append(
          "WitnessSign",
          this.witnessSign,
          this.witnessSign.name
        );
      } else {
        formData.append("WitnessSign", null);
      }
      formData.append("ConsentJson", JSON.stringify(AppData));
      this.update = false

      this.patientService.ConsentFormDataSave(formData).subscribe((res) => {
        if (res.status == 200 && res.message == 'Success') {
          this.toastrService.success("Saved Your Signature  successfully");
          this.update = true
          // this.clearPad();
        } else {
          this.toastrService.error("Failed.Please try later");
        }
      });
    }
  }

  clearPad() {
    this.signaturePad.clear();
    this.signaturePadWitness.clear();
    this.witnessName = "";
    this.selectedprocedures=[]
  }

  GetServiceItemsByConsultationId(){
    let payload={
      ConsultationId:this.consultationId ? this.consultationId : 0
    }
    this.patientService.GetServiceItemsByConsultationId(payload).subscribe({
      next:(res)=>{
        this.procedureItems=res.response
      }
    })
  }
}
