import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConsentService } from '../../client/services/consent.service';
import { ToastrService } from 'ngx-toastr';
import { FormValidationService } from '../../client/services/form-validation.service';
import { AllOperatorsList } from '../../client/interfaces/OperatorList';
import { ActiveFilterMenuTriggerDirective } from '@generic-ui/ngx-grid/structure/source/feature/counter/filter/active-filter-menu-trigger.directive';
import { ConsentHistory } from '../../client/interfaces/ConsentHistory';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { ConfigurationService } from "../../client/services/configuration.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import * as customEditor from '../../../../../ckeditor/build/ckeditor';
import { SettingsService } from '../../settings/services/settings.service';
import { Settings } from '../../settings/model/settings.model';
import { Response } from '../../../_Models/Response';
import _ from 'lodash';
import { ViewStaticPatientConsentComponent } from './view-static-patient-consent/view-static-patient-consent.component';

@Component({
  selector: 'app-consentform',
  templateUrl: './consentform.component.html',
  styleUrls: ['./consentform.component.scss']
})
export class ConsentFormComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModalInformed: boolean;
  displayModalPatient: boolean;
  displayModalSponsor: boolean;
  supervisor = "";
  locationname = "";
  Mobilenumber = "";
  public submittedSponsor: boolean = false;
  public submittedInformed: boolean = false;
  public submittedPatient: boolean = false;

  form: any;
  result: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  InformedConsent: boolean = true;
  CountryId: any;
  NationalityModal: boolean;
  SponsorConsent: boolean;
  PaientConsent: boolean;
  Nationality: boolean;
  consentDefs: ColDef[] = [];
  PatientcolumnDefs: ColDef[] = [];
  SponsorcolumnDefs: ColDef[] = [];
  NationalitycolumnDefs = [];
  public gridApi;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  modalRef: BsModalRef;
  modalRefPatient: BsModalRef;
  modalRefSponsor: BsModalRef;
  @ViewChild("template") modaltemp: any;
  @ViewChild("templatePatient") modaltempPatient: any;
  @ViewChild("templateSponsor") modaltempSponsor: any;
  private gridColumnApi;
  public rowSelection = true;
  public signdefaultColDef: ColDef;
  public vitalsigndefaultColDef: ColDef;
  public symptomdefaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public consentForm: FormGroup;
  public PatientForm: FormGroup;
  public SponsorForm: FormGroup;
  public NationalityForm: FormGroup;
  CountryModal: boolean;
  stateModal: boolean;
  stateList: any;
  StateId: any;
  CityList: any;
  CityModal: boolean;
  CityId: any;
  Consents: any;
  PatientConsents: any;
  PatientConsentId: any;
  SponsorConsents: any;
  Sponsors: any;
  sponsorId: any;
  SponsorConsentId: any;
  contentId: number;
  InformedConsentId: any;
  ConsentGroup: any;
  isLoading = false;
  ConsentformPopupHeading: String = "Add Consent Form";
  PatientConsentformPopupHeading: String = "Add Patient Consent ";
  SponserConsentformPopupHeading: String = "Add Insurance Consent ";

  public Editor = customEditor;
  public arabicEditorConfig = {
    language: {
      content: 'ar',
      ui: 'en'
    }
  }
  public englishEditorConfig = {
    language: {
      content: 'en',
      ui: 'en'
    }
  }
  functionPermissionData: any;
  public consentSettings: Settings[] = [];
  public patConsent: Settings[] = [];

  private changeCreditModalRef?: BsModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private ConsentService: ConsentService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private settingsService: SettingsService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.consentForm = new FormGroup({
      Consentgroups: new FormControl("", [Validators.required]),
      DisplayOrder: new FormControl("", [Validators.required]),
      CTEnglish: new FormControl("", [Validators.required]),
      CTArabic: new FormControl("", [Validators.required]),
      IsDisplayedInformed: new FormControl(true)
    });

    this.PatientForm = new FormGroup({
      CTEnglishPatient: new FormControl("", [Validators.required]),
      CTArabicPatient: new FormControl("", [Validators.required]),
      IsDisplayedPatient: new FormControl(true),
      IsWitnessSign: new FormControl(false),
      IsStaffSign: new FormControl(false),
      IsConsultantSign: new FormControl(false),
      IsConsentName: new FormControl(""),
      constentType: new FormControl(""),
      patientId: new FormControl(0),
      branchId: new FormControl(0),
    });

    this.SponsorForm = new FormGroup({
      SponsorName: new FormControl("", [Validators.required]),
      CTEnglishSponsor: new FormControl("", [Validators.required]),
      CTArabicSponsor: new FormControl("", [Validators.required]),
      IsDisplayedSponsor: new FormControl(true)
    });

    this.GetAllConsents();
    this.GetPatientConsent();
    this.GetSponsors();
    this.GetSponsorConsent();
    this.GetConsentGroup();
    this.functionPermission();
    this.getApplicationSettings();

    this.consentDefs = [
      {
        headerName: "Consent English",
        field: "ctEnglish",
        minWidth: 200,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        valueFormatter: params=> {
          return params.data.ctEnglish.replace(/<[^>]*>/g, '').replaceAll('&nbsp;', '');
        },
      },
      {
        headerName: "Consent Arabic",
        field: "ctArabic",
        minWidth: 200,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        valueFormatter: params=> {
          return params.data.ctArabic.replace(/<[^>]*>/g, '').replaceAll('&nbsp;', '');
        },
      },
      {
        headerName: "Display order",
        field: "displayOrder",
        minWidth: 200,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
      },
      {
        headerName: "Group name",
        field: "cGroupName",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Informed Consent" },
      },
    ];

    this.PatientcolumnDefs = [
      {
        headerName: "Consent English",
        field: "ctEnglish",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        valueFormatter: params=> {
          return params.data.ctEnglish.replace(/<[^>]*>/g, '').replaceAll('&nbsp;', '');
        },
      },
      {
        headerName: "Consent Arabic",
        field: "ctArabic",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        valueFormatter: params=> {
          return params.data.ctArabic.replace(/<[^>]*>/g, '').replaceAll('&nbsp;', '');
        },
      },
      {
        headerName: "Consent name",
        field: "consentName",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Patient Consent" },
      },
    ];

    this.SponsorcolumnDefs = [
      {
        headerName: "Insurance Name",
        field: "sponsorName",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
      },
      {
        headerName: "Consent English",
        field: "ctEnglish",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        valueFormatter: params=> {
          return params.data.ctEnglish.replace(/<[^>]*>/g, '').replaceAll('&nbsp;', '');
        },
      },
      {
        headerName: "Consent Arabic",
        field: "ctArabic",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        valueFormatter: params=> {
          return params.data.ctArabic.replace(/<[^>]*>/g, '').replaceAll('&nbsp;', '');
        },
      },
      {
        headerName: "Action",
        maxWidth: 100,
        filter: false,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Sponsor Consent" },
      },
    ];



    this.context = { componentParent: this };
    this.signdefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }

  onTab(number) {
    this.InformedConsent = false;
    this.PaientConsent = false;
    this.SponsorConsent = false;

    if (number == "1") {
      this.InformedConsent = true;
    } else if (number == "2") {
      this.PaientConsent = true;
    } else if (number == "3") {
      this.SponsorConsent = true;
    }
  }

  onGridReady(params) {
    console.log(params)
    this.gridApi = params.api;
  }

  showInformedModalDialog(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 67)
    if (foundElement.id == 67 && foundElement.isAllowed == true) {
    this.ConsentformPopupHeading = "Add Informed consent";
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: "gray modal-lg" }));
    this.InformedConsentId = 0;
    this.clearInformedForm();
  } else {
    this.toastr.warning("You are not allowed to add data");
  }
  }


  SaveInformedConsentForm() {

    this.submittedInformed = true;
    if (this.consentForm && this.consentForm.valid) {
      var AppData = {
        Consentgroups: this.consentForm.get("Consentgroups").value,
        CTEnglish: this.consentForm.get("CTEnglish").value,
        CTArabic: this.consentForm.get("CTArabic").value,
        DisplayOrder: this.consentForm.get("DisplayOrder").value,
        IsDisplayed: Number(this.consentForm.get("IsDisplayedInformed").value)
      };
      var saveupdateId = 0;
      this.isLoading = true;
      if (this.InformedConsentId > 0) {
        saveupdateId = this.InformedConsentId;
      }
      this.configurationService
        .InsertUpdateInformedConsentForm(
          AppData,
          this.globals.HospitalId,
          saveupdateId,
          0
        )
        .subscribe((res) => {
          this.result = res;
          this.submittedInformed = false;
          if (this.result.status == 200 && this.result.message == "Success") {
            if (saveupdateId == 0) {
              this.isLoading = false;
              this.toastr.success("Informed Consent Added successfully");
              this.clearInformedForm();
            } else {
              this.isLoading = false;
              this.toastr.success("Informed Consent Updated successfully");
              this.clearInformedForm();
            }
            this.modalRef.hide();
            this.GetAllConsents();

          } else {
            this.isLoading = false;
            this.toastr.error("Failed : " + this.result.message);
          }
        });
      this.GetAllConsents();

    }
  }

  GetAllConsents() {

    this.ConsentService.GetInformedConsent(this.globals.HospitalId).subscribe((res) => {
      this.Consents = res.response;
      if (this.Consents.length == 0) {
        // this.toastr.warning("No Consent Form found");
      }
    });
  }

  EditInformedConsent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 68)
    if (foundElement.id == 68 && foundElement.isAllowed == true) {
    this.ConsentformPopupHeading = "Edit Informed consent";
    this.modalRef = this.modalService.show(
      this.modaltemp,
      Object.assign({}, { class: "gray modal-lg" })
    );
    if (data.isDisplayed == 1) {
      data.isDisplayed = true;
    }
    else {
      data.isDisplayed = false;
    }
    if (data.contentId) {
      this.InformedConsentId = data.contentId;
      this.consentForm.patchValue({
        Consentgroups: data.cGroupId,
        CTEnglish: data.ctEnglish,
        CTArabic: data.ctArabic,
        DisplayOrder: data.displayOrder,
        IsDisplayedInformed: data.isDisplayed
      });
    }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  DeleteInformedConsent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 69)
    if (foundElement.id == 69 && foundElement.isAllowed == true) {
    this.InformedConsentId = data.contentId;
    var AppData = {
      Consentgroups: data.cGroupId,
      CTEnglish: data.ctEnglish,
      CTArabic: data.ctArabic,
      DisplayOrder: data.displayOrder,
      IsDisplayed: Number(this.consentForm.get("IsDisplayedInformed").value)
    };
    var saveupdateId = 0;
    if (this.InformedConsentId > 0) {
      saveupdateId = this.InformedConsentId;
    }

    this.configurationService
      .InsertUpdateInformedConsentForm(
        AppData,
        this.globals.HospitalId,
        this.InformedConsentId, 1
      )
      .subscribe((res) => {
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Deleted Successfully") {

          this.toastr.success("Informed Consent Deleted successfully");


          this.GetAllConsents();
        } else {
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }


  GetConsentGroup() {
    this.ConsentService.GetConsentGroup().subscribe((res) => {
      this.ConsentGroup = res.response;
    });
  }
  GetPatientConsent() {
    this.ConsentService.GetPatientConsent(this.globals.HospitalId, 1).subscribe((res) => {
      console.log(res,this.globals.HospitalId)
      this.PatientConsents = res.response;
      // if (this.Consents.length == 0) {
      //   // this.toastr.warning("No Consent Form found");
      // }
    });
  }
  showPatientmodal(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 70)
    if (foundElement.id == 70 && foundElement.isAllowed == true) {
    this.PatientConsentformPopupHeading = "Add Patient Consent";
    this.modalRefPatient = this.modalService.show(template, Object.assign({}, { class: "gray modal-lg" }));
    this.PatientConsentId = 0
    this.clearPatientForm();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }

  SaveConsentPatientForm() {
    this.submittedPatient = true;
    if(this.PatientForm.get('CTEnglishPatient').valid || this.PatientForm.get('CTArabicPatient').valid){
      if(this.PatientForm.get('CTEnglishPatient').value !== "") {
        this.PatientForm.get('CTArabicPatient').clearValidators()
        this.PatientForm.get('CTArabicPatient').updateValueAndValidity()
      } else if(this.PatientForm.get('CTArabicPatient').value !== ""){
        this.PatientForm.get('CTEnglishPatient').clearValidators()
        this.PatientForm.get('CTEnglishPatient').updateValueAndValidity()
      } else {
        this.PatientForm.get('CTEnglishPatient').setValidators
        this.PatientForm.get('CTArabicPatient').setValidators
      }
    }
    if (this.PatientForm && this.PatientForm.valid) {
      var AppData = {
        CTEnglishPatient: this.PatientForm.get("CTEnglishPatient").value,
        CTArabicPatient: this.PatientForm.get("CTArabicPatient").value,
        IsDisplayed: Number(this.PatientForm.get("IsDisplayedPatient").value),
        IsWitnessSign: Number(this.PatientForm.get("IsWitnessSign").value),
        IsStaffSign: Number(this.PatientForm.get("IsStaffSign").value),
        IsConsultantSign: Number(this.PatientForm.get("IsConsultantSign").value),
        ConsentName: this.PatientForm.get("IsConsentName").value,
        consentType: this.PatientForm.get("constentType").value,
        patientId: this.PatientForm.get("patientId").value,
        sponsorId: 0,
      };
      var saveupdateId = 0;
      this.isLoading = true;
      if (this.PatientConsentId > 0) {
        saveupdateId = this.PatientConsentId;
      }
      this.configurationService
        .InsertUpdateConsentForm(
          AppData,
          this.globals.HospitalId,
          saveupdateId, 0
        )
        .subscribe((res) => {
          this.result = res;
          if (this.result.status == 200 && this.result.message == "Success") {
            if (saveupdateId == 0) {
              this.isLoading = false;
              this.toastr.success("Patient Consent Added successfully");
              this.clearPatientForm();
            } else {
              this.isLoading = false;
              this.toastr.success("Patient Consent Updated successfully");
              this.clearPatientForm();
            }
          } else {
            this.isLoading = false;
            this.toastr.error("Failed : " + this.result.message);
          }
          this.GetPatientConsent();
          this.modalRefPatient.hide();
        });
      this.GetPatientConsent();
    }
  }
  EditPatientConsent(data) {
    console.log(data, this.functionPermissionData)
    const foundElement = this.functionPermissionData.find(x => x.id === 71)
    if (foundElement.id == 71 && foundElement.isAllowed == true) {
    this.PatientConsentformPopupHeading = "Edit Patient Consent";
    this.modalRefPatient = this.modalService.show(
      this.modaltempPatient,
      Object.assign({}, { class: "gray modal-lg" })
    );
    if (data.isDisplayed == 1) {
      data.isDisplayed = true;
    }
    else {
      data.isDisplayed = false;
    }
    if (data.contentId) {
      this.PatientConsentId = data.contentId;
      this.PatientForm.patchValue({
        CTEnglishPatient: data.ctEnglish,
        CTArabicPatient: data.ctArabic,
        IsDisplayedPatient: data.isDisplayed,
        IsWitnessSign: data.isWitnessSign ,
        IsStaffSign: data.isStaffSign,
        // IsConsultantSign: data.IsConsultantSign,
        IsConsentName: data.consentName,
        constentType: data.cType,
        // patientId: ,
      });
    }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  DeletePatientConsent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 72)
    if (foundElement.id == 72 && foundElement.isAllowed == true) {
    this.PatientConsentId = data.contentId;
    if (data.isDisplayed == 1) {
      data.isDisplayed = true;
    }
    else {
      data.isDisplayed = false;
    }
    var AppData = {
      CTEnglishPatient: data.ctEnglish,
      CTArabicPatient: data.ctArabic,
      IsDisplayed: Number(data.isDisplayed)
    };
    var saveupdateId = 0;
    if (this.PatientConsentId > 0) {
      saveupdateId = this.PatientConsentId;
    }

    this.configurationService
      .InsertUpdateConsentForm(
        AppData,
        this.globals.HospitalId,
        this.PatientConsentId,
        1
      )
      .subscribe((res) => {
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Deleted Successfully") {
          this.toastr.success("Patient Consent Deleted successfully");
          this.GetPatientConsent();
        } else {
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    this.PatientConsentId = 0;
    // this.GetPatientConsent();
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }
  //---------------------------SPONSOR CONSENT FORM------------------------------//
  GetSponsorConsent() {
    let SponsorId = 0;
    this.ConsentService.GetSponserConsent(this.globals.HospitalId, SponsorId).subscribe((res) => {
      this.SponsorConsents = res.response;
      // if (this.Consents.length == 0) {
      //   // this.toastr.warning("No Consent Form found");
      // }
    });
  }

  GetSponsors() {
    this.ConsentService.GetSponsors(this.globals.HospitalId).subscribe((res) => {
      this.Sponsors = res.response;
      // if (this.Consents.length == 0) {
      //   // this.toastr.warning("No Consent Form found");
      // }
    });
  }
  openModalProfession(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  showSponsorConsentmodal(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 73)
    if (foundElement.id == 73 && foundElement.isAllowed == true) {
    this.SponserConsentformPopupHeading = "Add Insurance Consent";
    this.modalRefSponsor = this.modalService.show(template, Object.assign({}, { class: "gray modal-lg" }));
    this.SponsorConsentId = 0;
    this.clearSponsorForm();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }

  SaveConsentSponsorForm() {

    this.submittedSponsor = true;
    if (this.SponsorForm && this.SponsorForm.valid) {

      var AppData = {
        SponsorName: this.SponsorForm.get("SponsorName").value,
        CTEnglishSponsor: this.SponsorForm.get("CTEnglishSponsor").value,
        CTArabicSponsor: this.SponsorForm.get("CTArabicSponsor").value,
        IsDisplayed: Number(this.SponsorForm.get("IsDisplayedSponsor").value)
      };
      var saveupdateId = 0;
      this.isLoading = true;
      if (this.SponsorConsentId > 0) {
        saveupdateId = this.SponsorConsentId;
      }
      this.configurationService
        .InsertUpdateSponsorConsentForm(
          AppData,
          this.globals.HospitalId,
          saveupdateId, 0
        )
        .subscribe((res) => {
          this.result = res;
          this.submittedSponsor = false;
          if (this.result.status == 200 && (this.result.message == "Success" || this.result.message == "Updated Successfully")) {
            if (saveupdateId == 0) {
              this.clearSponsorForm();
              this.isLoading = false;
              this.toastr.success("Insurance Consent Added successfully");
            } else {
              this.isLoading = false;
              this.toastr.success("Insurance Consent Updated successfully");
            }
          } else {
            this.isLoading = false;
            this.toastr.error("Failed : " + this.result.message);
          }
          this.modalRefSponsor.hide();
          this.GetSponsorConsent();
        });
      this.GetSponsorConsent();
    }
  }
  EditSponsorConsent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 74)
    if (foundElement.id == 74 && foundElement.isAllowed == true) {
    this.SponserConsentformPopupHeading = "Edit Insurance Consent";
    this.modalRefSponsor = this.modalService.show(
      this.modaltempSponsor,
      Object.assign({}, { class: "gray modal-lg" })
    );
    if (data.isDisplayed == 1) {
      data.isDisplayed = true;
    }
    else {
      data.isDisplayed = false;
    }
    if (data.contentId) {
      this.SponsorConsentId = data.contentId;
      this.SponsorForm.patchValue({
        CTEnglishSponsor: data.ctEnglish,
        CTArabicSponsor: data.ctArabic,
        SponsorName: data.sponsorId,
        IsDisplayedSponsor: data.isDisplayed,

      });
    }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  DeleteSponsorConsent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 75)
    if (foundElement.id == 75 && foundElement.isAllowed == true) {
    this.SponsorConsentId = data.contentId;
    var AppData = {
      CTEnglishSponsor: data.ctEnglish,
      CTArabicSponsor: data.ctArabic,
      SponsorName: data.sponsorId,
      IsDisplayed: Number(this.SponsorForm.get("IsDisplayedSponsor").value)
    };
    var saveupdateId = 0;
    if (this.SponsorConsentId > 0) {
      saveupdateId = this.SponsorConsentId;
    }

    this.configurationService
      .InsertUpdateSponsorConsentForm(
        AppData,
        this.globals.HospitalId,
        this.SponsorConsentId, 1
      )
      .subscribe((res) => {
        this.result = res;

        if (this.result.status == 200 && this.result.message == "Deleted Successfully") {
          this.toastr.success("Insurance Consent Deleted successfully");
          this.GetSponsorConsent();
        } else {
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    // this.GetSponsorConsent();
    this.SponsorConsentId = 0;
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  get informedf() {
    return this.consentForm.controls;
  }

  get patientf() {
    return this.PatientForm.controls;
  }
  get sponsorf() {
    return this.SponsorForm.controls;
  }
  clearInformedForm() {
    this.consentForm.reset({
      Consentgroups: '',
      DisplayOrder: '',
      CTEnglish: '',
      CTArabic: '',
      IsDisplayedInformed: true
    });
    this.submittedInformed = false;
  }
  clearPatientForm() {
    this.PatientForm.reset({
      CTEnglishPatient: '',
      CTArabicPatient: '',
      IsDisplayedPatient: true
    });
    this.submittedPatient = false;
  }
  clearSponsorForm() {
    this.SponsorForm.reset({
      SponsorName: '',
      CTEnglishSponsor: '',
      CTArabicSponsor: '',
      IsDisplayedSponsor: true
    });
    this.submittedSponsor = false;
  }
  ResetFunctionInformed() {
    this.InformedConsentId = 0;
    this.clearInformedForm();
  }

  ResetFunctionPatient() {
    this.PatientConsentId = 0
    this.clearPatientForm();
  }

  ResetFunctionSponsorForm() {
    this.SponsorConsentId = 0;
    this.clearSponsorForm();
  }


  onReadyEditor(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  async getApplicationSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }
    let settingsData = await this.settingsService.getApplicationSettings(payload).toPromise();
    if(settingsData.status == 200){
      this.consentSettings = _.filter(settingsData.response, {tabId: 5});
      this.patConsent = _.filter(this.consentSettings, {displayName: 'PatientConsent'});
    }
  }

  viewStaticPatientConsent(data){
    let initialState = {
      consentData: data,
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.changeCreditModalRef = this.modalService.show(ViewStaticPatientConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    this.changeCreditModalRef.content.creditChange.subscribe(res=>{
    })
  }


}
