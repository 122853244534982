import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  private globalvars: globalvars = new globalvars();

  constructor(
    private router: Router,
    private sharedservice: SharedService,
  ) { }

  ngOnInit(): void {
    localStorage.clear();
    sessionStorage.clear()
    this.sharedservice.setHospital(this.globalvars);
    localStorage.removeItem("usertype");
    this.router.navigate(['/']);
  }

}
