import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ConfigurationManager } from '../assets/configuration/configuration-manager';
import { FrontofficeComponent } from './modules/client/frontoffice/frontoffice.component';
import { ClientModule } from './modules/client/client.module';
import { AuthsGuard } from './auths.guard';
import { DataTablesModule } from 'angular-datatables';
import { UserPermissionComponent } from './modules/user-permission/user-permission.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { SharedModule } from './shared/models/shared.module';
import { GeneralGridActionComponent } from './shared/general-grid-action/general-grid-action.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CommonValidationComponent } from './shared/common-validation/common-validation.component';
import { NodeService } from './modules/client/serviceordering/serviceorder/nodeservice';
import { TreeviewModule } from 'ngx-treeview';
import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { AddHeaderInterceptor } from './AddHeaderInterceptor ';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { AuthLayOutComponent } from './app-layout/auth-lay-out/auth-lay-out.component';


import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { LoginGuard } from './login.guard';
import { ConfirmModalComponent } from './shared/modals/confirm-modal/confirm-modal.component';

import { QRCodeModule } from 'angularx-qrcode';
import { QrcodeScanModalComponent } from './shared/modals/qrcode-scan-modal/qrcode-scan-modal.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BarcodePrintComponent } from './modules/print/barcode-print/barcode-print.component';
import { ViewReportComponent } from './modules/view-report/view-report.component';
import { TelerikPrintComponent } from './shared/modals/telerik-print/telerik-print.component';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import {ImageCropperModule} from 'ngx-image-cropper'
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ConfirmationModalComponent } from './shared/modals/confirmation-modal/confirmation-modal.component';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


export let configurationFactory = (): any => {
	return ConfigurationManager.getInstance();
};
@NgModule({
  declarations: [
    AppComponent,
    UserPermissionComponent,
    AppLayoutComponent,
    GeneralGridActionComponent,
    CommonValidationComponent,
    AutocompleteOffDirective,
    ConfirmModalComponent,
    QrcodeScanModalComponent,
    ViewReportComponent,
    TelerikPrintComponent,
    ConfirmationModalComponent,
    // BarcodePrintComponent,
    
    // AuthLayOutComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    QRCodeModule,
    ZXingScannerModule,
    // FormsModule,
    BrowserAnimationsModule,
    TelerikReportingModule,
    ImageCropperModule,
    BsDropdownModule,
    // BsDatepickerModule.forRoot(),
    // DataTablesModule,
    // NgxDatatableModule,
    // TreeviewModule.forRoot(),

    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),

     TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
               }
        })
  ],

  providers: [
    AuthsGuard,NodeService,LoginGuard,
    BsDropdownConfig,
    BsDatepickerModule,
    {
       provide: ConfigurationManager,
       useFactory: configurationFactory
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },{
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
