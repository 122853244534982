import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurationManager } from '../../assets/configuration/configuration-manager';
import { UserHospitals } from '../modules/client/interfaces/UserHospitals';
import { HospitalsService } from '../modules/client/services/hospitals.service';
import { globalvars } from './models/GlobalVars';
import { Response } from '../_Models/Response';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private SelectedHospital = new BehaviorSubject(null);
  sessionhospital: globalvars;
  hospitalList: UserHospitals[] = [];
  private baseUrl = "";
  newDate:any;
  timeZoneData:any;
   private globalVariable: string;
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private hospitalsService: HospitalsService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.newDate=moment.tz.setDefault('Asia/Dubai');

  }
  setHospital(globalvars: globalvars) {
    sessionStorage.setItem("globalvars", JSON.stringify(globalvars));
    this.SelectedHospital.next(globalvars);
  }

  getHospital(): BehaviorSubject<globalvars> {
    this.sessionhospital = JSON.parse(sessionStorage.getItem("globalvars"));

    if (this.sessionhospital == null || typeof this.sessionhospital == 'undefined') {
      this.GetUserHospitals();
    }
    return this.SelectedHospital;
  }
  checkglobals(glob: globalvars) {
    var retval = false;
    if (glob != null && typeof glob != "undefined" && glob.HospitalId != 0) {
      retval = true
    }
    else {
      let GLobalVarInfo = localStorage.getItem('GLobalVarInfo');
      if (GLobalVarInfo) {
        this.setHospital(JSON.parse(GLobalVarInfo));
        this.timeZoneData=this.newDate.defaultZone.abbrs[1];

      }
    }
    return retval;
  }
  AgStringsort(valueA, valueB, nodeA, nodeB, isInverted) {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  }

  GetUserHospitals() {

    this.hospitalsService.GetUserHospitals().subscribe((res) => {
      this.hospitalList = res.response;
      if (typeof sessionStorage.getItem("globalvars") == 'undefined' || !sessionStorage.getItem("globalvars") || sessionStorage.getItem("globalvars") == null) {
        this.sessionhospital.HospitalId = this.hospitalList[0].hospitalId;
        this.sessionhospital.HospitalName = this.hospitalList[0].hospitalName;
        this.setHospital(this.sessionhospital);
        this.timeZoneData=this.newDate.defaultZone.abbrs[1];

      }

    });
  }

  UpdateLockCform(payload: {
    Id: number,
    IsCformLock: boolean,
    TypeData: string
  }): Observable<Response<any>> {
    var url = "api/Bill/ChangeCFormLockStaus";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  getGetMaxPatientsPerDayForConsultant(payload){
    var url = "api/TodaysPatient/GetMaxPatientsPerDayForConsultant"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }
}
