import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsultaionsService } from '../../../services/consultations.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dha-login',
  templateUrl: './dha-login.component.html',
  styleUrls: ['./dha-login.component.scss']
})
export class DhaLoginComponent implements OnInit {
  @Input() functionPermissionData
  dhaForm:FormGroup
submitted: any;
viewPassword: boolean=true

  constructor(
    private fb:FormBuilder,
    private tostr:ToastrService,
    private consultationService: ConsultaionsService,
  ) { }

  ngOnInit(): void {
    this.getDhaLoginData()
    this.formInit()
  }

  formInit(){
    this.dhaForm=this.fb.group({
      DHAUserName:['',Validators.required],
      DHAPassword:['',Validators.required],
    })
  }

  get f() {
    return this.dhaForm.controls;
  }

  save(){
    const foundElement = this.functionPermissionData.find(x => x.id === 544)
    if(foundElement && foundElement.isAllowed == false){ 
      this.tostr.warning("you are not allowed")
      return
    }
    this.submitted=true
    if(this.dhaForm.invalid) return
    let payload={
      ...this.dhaForm.value,
      ConsultantId:Number(localStorage.getItem('Id')),
    }
    this.consultationService.saveConsultantDHALoginCredentials(payload).subscribe({
      next:(res)=>{
        this.submitted=false
        this.tostr.success("Addedd successfully")
      }
    })

  }

  getDhaLoginData(){
    let payload={
      ConsultantId:Number(localStorage.getItem('Id'))
    }
    this.consultationService.GetConsultantDHALoginCredentials(payload).subscribe({
      next:(res)=>{
        if(res.status == 200){
          this.dhaForm.patchValue({
            DHAUserName:res.response[0].dhaUserName,
            DHAPassword:res.response[0].dhaPassword
          })
          this.viewPassword=false
        }
      }
    })
  }

  toggle(){
    debugger
  }


}
