import { Component, OnInit } from '@angular/core';
import { CellEditingStartedEvent, CellEditingStoppedEvent, ColDef, RowEditingStartedEvent, RowEditingStoppedEvent } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { DentalService } from '../services/dental.service';
import { EMRPatientInfo, EMRSponsorInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from '../services/emr.service';
import { Observable, Observer, Subscription, noop, of } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { servicItems } from '../models/dental.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Response } from '../../../_Models/Response';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { EstimateGridCellDropdownComponent } from './estimate-grid-cell-dropdown/estimate-grid-cell-dropdown.component';
import { ConfigurationService } from '../../client/services/configuration.service';
import { remove } from 'lodash';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dental-estimation',
  templateUrl: './dental-estimation.component.html',
  styleUrls: ['./dental-estimation.component.scss']
})
export class DentalEstimationComponent implements OnInit {
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  columnDefs: ColDef[] = [];
  rowData: any;
  orderColumnDefs: ColDef[] = [];
  orderData: any;
  public noRowsTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    // domLayout: "autoHeight",
  };

  estimationDetails: any;
  EMRPatientInfo: EMRPatientInfo;
  BranchesId: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  public treatmentForm: FormGroup;
  public searchTreatment: string = '';
  public suggestions$?: Observable<servicItems[]>;
  private baseUrl: string = '';
  public CPTModifiersList: any[] = [];
  makeOrderIds: any[] = [];
  total = 0;
  selectedTotal = 0;
  orderedTotal = 0;
  inputQty: number;
  inputRate: number;
  showInstructor: any;
  instructorList: any;
  public markingSaveLoading: boolean = false;
  getEstimateId: any;
  public formValueChangeChecker: boolean = false;
  makeOrderConfirm = false;
  proceedFlag = false;
  EMRSponsorInfo:EMRSponsorInfo;
  selectAllFlag = false;
  allcheckedFlag = false;
  latestEstimateId: number;

  constructor(private dentalService: DentalService,
    private EMRService: EMRService,
    private sharedservice: SharedService,
    private toastrService: ToastrService,
    private http: HttpClient,
    private configurationManager: ConfigurationManager,
    private ConfigurationService: ConfigurationService,
    private modalService:BsModalService,
    private datepipe: DatePipe,
    ) { 
      this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
        if (this.sharedservice.checkglobals(glob)) {
          this.globals = glob;
          this.BranchesId = this.globals.HospitalId;
        }
      });
      this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
      this.EMRSponsorInfo = this.EMRService.GetEMRSponsorInfo();
      this.baseUrl = (this.configurationManager as any).baseUrl;
      this.showInstructor = localStorage.getItem('isAprovedAuthority');
    }

  ngOnInit(): void {
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
      dropDownRender: EstimateGridCellDropdownComponent
    };
    this.columnDefs = [
      {
        headerName: "Estimate No",
        minWidth: 100,
        field: 'estimateNo',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Orderd By",
        minWidth: 250,
        field: 'consultantName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,
      },
      {
        headerName: "Estimate Date",
        minWidth: 250,
        field: 'estimateDate',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "EstimateSummary" },

      },
    ];

    this.orderColumnDefs = [
      {
        headerName: "Order No",
        minWidth: 100,
        field: 'orderNo',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Order Date",
        minWidth: 250,
        field: 'orderDate',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "OrderSummary" },

      },
    ];

    this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.searchTreatment);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<servicItems[]>>(
              `${this.baseUrl}api/EMR/GetEMRServiceItem`,
              {
                ServiceName: query,
                branchId: this.BranchesId,
                GroupId: 0,
                consultantId: 0,
                GroupCode: "114",
                CurrentDate: this.datepipe.transform(new Date(), "dd-MM-yyyy"),
                CreditId: this.EMRSponsorInfo.CreditId,
                Category: 'N'
              }
            )
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  this.toastrService.error(err ? err.message : 'Something goes wrong');
                  // this.errorMessage =
                  //   (err && err.message) || "Something goes wrong";
                }
              )
            );
        }

        return of([]);
      })
    );
    
    this.getAllCPTModifier();
    this.GetEstimateSummary();
    this.initTreatmentForm();
    this.GetAuthorisedConsultant();
    // this.GetDentalEstimateDetails();
  }

  initTreatmentForm(){
    this.treatmentForm = new FormGroup({
      itemDetails: new FormArray([]),
      views: new FormControl(''),
      typeOfOrder: new FormControl('D'),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      branchId: new FormControl(this.BranchesId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
      isMissing: new FormControl(false),
      isMarkedAsAdult: new FormControl(),
      OrderId: new FormControl(0),
      EstimateId: new FormControl(0),
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  GetEstimateSummary() {
    let input = {
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ConsultantId: this.EMRPatientInfo.ConsultantId,
      BranchId: this.BranchesId,
      ConsultationId: this.EMRPatientInfo.ConsultationId,
      CreditId: this.EMRSponsorInfo.CreditId
    };

    this.dentalService.GetEstimateLoad(input).subscribe((res) => {
      if (res.status == 200) {
        this.rowData = res.response;
      }
    });
  }

  GetDentalEstimateDetails(estimateId) {
    this.latestEstimateId = estimateId;
    let input = {
      VisitId: 0,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 0,
      BranchId: this.BranchesId,
      EstimateId: estimateId,
      CreditId: this.EMRSponsorInfo.CreditId
    };

    this.dentalService.GetDentalEstimateItemsEMR(input).subscribe((res) => {
      if (res.status == 200) {
        this.estimationDetails = res.response.itemDetails;
        let response = res.response[0];

        if (response) {
          if (response.itemDetails) {
            this.estimationDetails = response.itemDetails;
            this.addMapFormGroup(response.itemDetails, 1, 'get');
            this.selectedTotal = this.orderedTotal;
          }
        }
      }
    });
  }

  getAllCPTModifier() {
    this.ConfigurationService.GetCPTModifier(
      this.BranchesId,
      0
    ).subscribe((res) => {
      this.CPTModifiersList = res.response;
    });
  }

  SaveEstimation(){
  }

  onSelectionChanged(eventData) {
    this.getEstimateId = eventData;
    if (eventData == 0) {
      this.noRowsTemplate = "No Rows To Show"
      this.gridApi.hideOverlay();

    } else if (eventData) {
      this.GetDentalEstimateDetails(this.getEstimateId.data.estimateId);
      this.GetServiceOrderByEstimateId(this.getEstimateId.data.estimateId);
    }
  }

  onSelectTreatment(data: any){
    this.addMapFormGroup(data, 0, 'add');
  }

  public addMapFormGroup(data: any[] = [], saved = 0, action) {
    const ServiceItems = this.treatmentForm.get("itemDetails") as FormArray;
    if(action == 'get') {
      ServiceItems.clear();
    }
    const values = Object.keys(data).map((key) => data[key]);

    if (data.length > 0) {
      data.forEach((element, i) => {
        let filteredArray = ServiceItems.value.filter(
          // (value) => value.itemId == element.itemId
          (value) => (value.itemId == element.itemId) && (value.teeths == element.teeths)
        );
        if (filteredArray.length == 0) {
          ServiceItems.push(this.createListGroup(element, saved));
        } else {
          this.toastrService.error("Failure: " + "Service Items Already added");
        }
      });
    } else if (values.length > 0) {
      let filteredArray = ServiceItems.value.filter(
        (value) => value.itemId == values[0]
      );
      if (filteredArray.length == 0) {
        ServiceItems.push(this.createListGroup(data, saved));
      } else {
        this.toastrService.error("Failure: " + "Service Items Already added");
      }
    }
    this.searchTreatment = "";
    this.getTotalRate(data);
  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    if (saved == 0) {
      if (!data["qty"]) data["qty"] = 1;
    }

  if(data["teeths"] == undefined && data["teeths"] == null) {
    data["teeths"] = 1;
  }
    return new FormGroup({
      itemId: new FormControl(data["itemId"]),
      itemName: new FormControl( data["itemName"] + " - " + data["itemCode"]),
      teeths: new FormControl(data["teeths"]),
      notes: new FormControl(data["notes"]),
      cptModifierId: new FormControl( data["cptModifierId"] ? data['cptModifierId'] : ''),
      qty: new FormControl(data["qty"]),
      Rate: new FormControl(data["rate"]),
      Instructor: new FormControl(data["instructor"] ? data['instructor'] : 0),
      ConfirmedServiceOrder: new FormControl(data["confirmedServiceOrder"]),
      EstimateDetId: new FormControl(data["estimateDetId"] ? data['estimateDetId'] : 0),
      insCovered: new FormControl(data["insCovered"])
    });
  }

  getTotalRate(data) {
    this.total = 0;
    this.orderedTotal = 0;
    this.treatmentForm.get('itemDetails')['controls'].forEach((element,index)=>{
      this.total = this.total + (element?.get('qty').value * element?.get('Rate').value);
   });

   this.treatmentForm.get('itemDetails')['controls'].forEach((element,index)=>{
    if(element?.get('ConfirmedServiceOrder').value == true) {
      this.orderedTotal = this.orderedTotal + (element?.get('qty').value * element?.get('Rate').value);
    }
 });
 this.selectedTotalRate(0, 0);
  }

  RemoveFromServiceList(item, index) {
    if (item !== 0 && index !== 0) {
      let itemId = this.treatmentForm.get("itemDetails").value[index].itemId;
      // let itemSet = this.treatmentForm.get("itemDetails").value[index];
      if (this.makeOrderIds.length !== 0) {
        let indexToRemove = this.makeOrderIds.findIndex(x => x.itemId === itemId);
        if (indexToRemove !== -1) {
          this.makeOrderIds.splice(indexToRemove, 1);
        }
      }
    }
    this.selectedTotalRate(0, 0);
    var Value = new FormArray([]);
    Value = this.treatmentForm.get("itemDetails") as FormArray;
    Value.removeAt(index);
    this.formValueChangeChecker = true;
    this.getTotalRate(item);
  }

  isAllCheckBoxChecked(index) {
    let item = this.treatmentForm.get("itemDetails").value[index].itemId;
    let teeth = this.treatmentForm.get("itemDetails").value[index].teeths;
    let itemSet = this.treatmentForm.get("itemDetails").value[index];
    let notConfrimed = this.treatmentForm.get("itemDetails").value.filter(
      (value) => value.ConfirmedServiceOrder !== true
    );
    if (this.makeOrderIds.length == 0) {
      this.makeOrderIds.push(itemSet);
      if(this.makeOrderIds?.length == notConfrimed?.length){this.allcheckedFlag = true;}
    } else {
      let indexToRemove = this.makeOrderIds.findIndex(x => (x.itemId === item) && (x.teeths === teeth));
      if (indexToRemove !== -1) {
        this.makeOrderIds.splice(indexToRemove, 1);
        console.log('this.allcheckedFlag', this.allcheckedFlag);
        if(this.allcheckedFlag == true){this.allcheckedFlag = false;}
      }
      else {
        this.makeOrderIds.push(itemSet);
        if(this.makeOrderIds?.length == notConfrimed?.length){this.allcheckedFlag = true;}
      }
    }
    this.selectedTotalRate(0, 0);
  }

  selectAll() {
    console.log('selectAllFlag before', this.selectAllFlag);
    this.selectAllFlag = !this.selectAllFlag;
    this.allcheckedFlag = this.selectAllFlag;
    console.log('selectAllFlag after', this.selectAllFlag);
    if(this.selectAllFlag == true){
      this.makeOrderIds = [];
      // this.treatmentForm.get("itemDetails").value.find(x => {
        this.makeOrderIds = this.treatmentForm.get("itemDetails").value.filter(
          (value) => value.ConfirmedServiceOrder !== true
        );
      // }
      // )
    } else{
      this.makeOrderIds = [];
    }
    this.selectedTotalRate(0, 0);
    console.log('this.makeOrderIds', this.makeOrderIds);
  }

  selectedTotalRate(item, index) {
    // this.selectedTotal = 0;
    if(item !== 0 && index !== 0){
    let itemId = this.treatmentForm.get("itemDetails").value[index].itemId;
    let itemSet = this.treatmentForm.get("itemDetails").value[index];
    if (this.makeOrderIds.length !== 0) {
      let indexToRemove = this.makeOrderIds.findIndex(x => x.itemId === itemId);
      if (indexToRemove !== -1) {
        this.makeOrderIds.splice(indexToRemove, 1);
        this.makeOrderIds.push(itemSet);
      }
      }
    }
    this.selectedTotal = this.orderedTotal;
    this.makeOrderIds.forEach((element, index) => {
      this.selectedTotal = this.selectedTotal + (element.qty * element.Rate);
    });
  }

  SaveTreatMent() {
    this.markingSaveLoading = true;
    let payload = this.treatmentForm.getRawValue();

    _.forEach(payload.itemDetails, (item: any) => {
      item.cptModifierId = item.cptModifierId ? Number(item.cptModifierId) : 0;
    })

    _.forEach(payload.itemDetails, (item: any) => {
      item.Instructor = item.Instructor ? Number(item.Instructor) : 0;
    })

    payload.userId = Number(localStorage.getItem("user_id"));
    payload.branchId = Number(this.BranchesId);
    payload.typeOfOrder = 'D';
    payload.visitId = this.EMRPatientInfo.VisitId;

    this.dentalService.InsertEstimateItemsEMR(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200 && response.message == "Success") {
          this.toastrService.success(
            "Success: " + "Treatment items Saved Successfully..."
          );
          this.makeOrderConfirm = false;
          this.formValueChangeChecker = false;
          this.makeOrderIds = [];
          this.selectAllFlag = false;
          this.allcheckedFlag = false;
          this.onSelectionChanged(this.getEstimateId);
        } else {
          this.toastrService.error(
            "Error"
          );
        }
        this.markingSaveLoading = false;
      }, error: (error: any) => {
        this.toastrService.error(
          "Somthing wents wrong, Try again..!"
        );
        this.markingSaveLoading = false;
      }
    })
  }

  onInputQtyChange(item, index) {
    // The inputValue property will automatically be updated when the input field changes.
    this.getTotalRate(item);
    this.selectedTotalRate(item, index);
  }

  onInputRateChange(item, index) {
    // The inputValue property will automatically be updated when the input field changes.
    this.getTotalRate(item);
    this.selectedTotalRate(item, index);
  }

  GetAuthorisedConsultant() {
    let payload = {
      ConsultantId: 0,
      BranchId: this.BranchesId
    }

    this.ConfigurationService.GetAuthorisedConsultant(payload).subscribe((res) => {
      this.instructorList = res.response;
    })
  }

  makeServiceOrder() {
    if(this.formValueChangeChecker){
      this.makeOrderConfirm = true;
    } else{
    let payload = this.treatmentForm.getRawValue();
    payload.itemDetails = this.makeOrderIds;

    _.forEach(payload.itemDetails, (item: any) => {
      item.cptModifierId = item.cptModifierId ? Number(item.cptModifierId) : 0;
    })

    _.forEach(payload.itemDetails, (item: any) => {
      item.Instructor = item.Instructor ? Number(item.Instructor) : 0;
    })

    payload.userId = Number(localStorage.getItem("user_id"));
    payload.branchId = Number(this.BranchesId);
    payload.typeOfOrder = 'D';
    payload.EstimateId = this.latestEstimateId;
  
    this.EMRService.SaveServiceOrder(payload).subscribe((res) => {
      if (res.status == 200 && res.message == "Success") {
        this.toastrService.success(
          "Success: " + "Treatment Plan Details Saved Successfully..."
        );
        this.onSelectionChanged(this.getEstimateId);
        this.selectAllFlag = false;
        this.allcheckedFlag = false;
        this.makeOrderIds = [];
      } else {
        Swal.fire(
          "Error!",
          "Some unexpected error occures! try again later.",
          "error"
        );
      }
      this.formValueChangeChecker = false;
    });
  }
  }

  printEstimate(data: any) {
    let initialState = {
      modalHeading: 'Print Estimate',
      reportName: 'RptEstimate.trdp',
      reportParams: {
        ConsultationId: data.consultationId,
        EstimateId: data.estimateId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  }

  GetServiceOrderByEstimateId(estimateId) {
    let input = {
      PatientId: this.EMRPatientInfo.PatientId,
      ConsultationId: this.EMRPatientInfo.ConsultationId,
      EstimateId: estimateId
    };

    this.dentalService.GetServiceOrderByEstimateId(input).subscribe((res) => {
      if (res.status == 200) {
        this.orderData = res.response;
      }
    });
  }

  printOrder(data: any) {
    let initialState = {
      modalHeading: 'Print Order',
      reportName: 'RptServiceOrderDetails.trdp',
      reportParams: {
        ConsultationId: this.EMRPatientInfo.ConsultationId,
        OrderId: data.orderId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  }
}
