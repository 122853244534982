import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Response } from '../../../_Models/Response';
import { FormValidation } from '../interfaces/FormValidation';
import { ErrorHandleService } from './error-handle.service';
import { catchError, retry } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
@Injectable({
  providedIn: 'root'
})
export class FormValidationService {


  private baseUrl = "";

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle:ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;

  }
  GetFormValidation(FormId:number,DepartmentId:number)
  {
    var url = "api/FormValidation/GetFormValidation/"+FormId+"/"+DepartmentId;
    return this.http.post<Response<FormValidation[]>>(`${this.baseUrl}${url}`, { }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

}
