import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ColDef, GridApi } from "ag-grid-community";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { SponsorsService } from "../../client/services/sponsors.service";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Sponsors } from "../../client/interfaces/Sponsors";
import { ConfigurationService } from "../../client/services/configuration.service";
import { SponsorForm } from '../../client/interfaces/SponsorForm';
import { SubAgentList } from '../../client/interfaces/subAgent';
import { AppointmentsService } from "../../client/services/appointments.service";
import { ToastrService } from "ngx-toastr";
import { ConsentService } from '../../client/services/consent.service';
import { Router } from "@angular/router";
import { SponsorFormConfig } from '../../client/services/sponsorFormConfigService';
import * as XLSX from 'xlsx';
import _ from "lodash";

@Component({
  selector: "app-sponsor-and-subagent",
  templateUrl: "./sponsor-and-subagent.component.html",
  styleUrls: ["./sponsor-and-subagent.component.scss"],
})
export class SponsorAndSubagentComponent implements OnInit {
  Sponsor: boolean = true;
  SubAgent: boolean;
  DrugList: boolean;
  AttachFile: boolean;
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  modalRefConsultantReduction: BsModalRef;
  modalRefSponsorRuleDrugs: BsModalRef;
  SponsorForms: SponsorForm;
  SponsorSubAgentForms: SubAgentList;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  DrugListTab: boolean = true;
  AttachFileTab: boolean;
  isLoading: false;
  public submittedSponsorConsent: boolean = false;
  public submittedConsultantReduction: boolean = false;
  public submittedDrugList: boolean = false;
  public submittedDrugListXcel: boolean = false;
  public SponsorForm: FormGroup;
  public SponsorSubAgentForm: FormGroup;
  public SponsorConsentForm: FormGroup;
  public ConsultantReductionForm: FormGroup;
  public SponsorDrugListForm: FormGroup;
  public DrugsAttachfileForm: FormGroup;
  SponsorcolumnDefs = [];
  SubAgentscolumnDefs = [];
  ConsultantReduction = [];
  sponsorColumnRule = [];
  DrugListDef = [];
  AttachDrugList = [];
  attachListFile = [];
  CountryListData: any[];
  countryList: any;
  StateListData: any[];
  CountryId: any;
  state1List: any;
  GetSponsorTypesData: any[];
  public defaultColDef: ColDef;
  dropdownSettings: any = {};
  public gridApi: GridApi;
  private gridApiDrugs: GridApi;
  public gridApiSponsor;
  public gridApiRule;
  public gridApiSubAgent;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  public submitted: boolean = false;
  public submitted1: boolean = false;
  Consultant
  public rowSelection = true;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  SponsorPopupHeading: String = "Add Insurance";
  SubAgentPopupHeading: String = "Add TPA";
  @ViewChild("template") modaltemp: any;
  @ViewChild("template2") modaltemp2: any;
  @ViewChild("template3") modaltemp3: any;
  @ViewChild("template4") modaltemp4: any;
  @ViewChild("templateSponsor") modaltempSponsor: any;
  sponsorList: any;
  modalRefSponsor: BsModalRef;
  ConsultantReductionlist: any;
  SponsorConsentId: any;
  GetSponsorTypes: Sponsors[];
  GetAllSubAgents: any;
  GetSponsorForms: Sponsors[];
  GetLedgers: any;
  GetLedgersData: any[];
  GetSponsorFormsData: any[];
  GetAllSponsorsData: any;
  SponsorId: number;
  DeleteSponsorId: any;
  SponsorConsents: any;
  Consents: any;
  result: any;
  sponsorName: any;
  Sponsors: any;
  IsSponsor: boolean = false;
  SponsorRules: any;
  SponsorRulesData: any;
  Isdeleted: any;
  SponsorSubAgent: any;
  GetSponsorFormsList: any;
  GetAllSubAgentsDatas: any;
  AgentId: any;
  GetAllConsultantReduction: any;
  RowData: any;
  GetRowdData: any;
  GetSponsorDrugListData: any[] = [];
  GetSponsorDrugsData: any;
  SponsorRuleChecking: any[] = [];
  attachFiles: any;
  IsSponsorList: boolean = true;
  IsSponsorRule1: boolean = true;
  IsSubAgentList: boolean = true;
  selectedSponsorId: any;
  id: Number;
  ldid: Number;
  sponsorFormId:any

  countryId: number;
  PartyId: number;
  public overlayNoRowsTemplate;
  functionPermissionData: any;

  constructor(
    private AppointmentsService: AppointmentsService,
    private modalService: BsModalService,
    private SponsorsService: SponsorsService,
    private sharedservice: SharedService,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private ConsentService: ConsentService,
    private router: Router,
    private SponsorFormConfig: SponsorFormConfig,


  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
    this.onTab1(4); // default tab set
    this.overlayNoRowsTemplate = "<span>This is a custom 'no rows' overlay</span>";

  }

  ngOnInit(): void {
    this.SponsorForm = new FormGroup({
      SponsorName: new FormControl("", [Validators.required]),
      SFormId: new FormControl(0),
      SponsorType: new FormControl(0),
      //ID: new FormControl(""),
      SortOrder: new FormControl(0),
      VATRegNo: new FormControl(""),
      SponsorLimit: new FormControl(0),
      AgentforSponsorList: new FormControl([]),
      Remarks: new FormControl(""),
      Address1: new FormControl(""),
      Address2: new FormControl(""),
      Street: new FormControl(""),
      PlacePO: new FormControl(""),
      PIN: new FormControl(""),
      City: new FormControl(""),
      CountryId: new FormControl(0),
      State: new FormControl(""),
      Mobile: new FormControl(""),
      ResNo: new FormControl(""),
      Fax: new FormControl(""),
      URL: new FormControl(""),
      Email: new FormControl(""),
      ContactPerson: new FormControl(""),
      AuthorizationMode: new FormControl(""),
      EnableLimit: new FormControl(false),
      IsDisplayed: new FormControl(true),
      IsDeleted: new FormControl(0),

      EnableConsent: new FormControl(false),
      UserId: new FormControl(Number(localStorage.getItem('user_id'))),
      SessionId: new FormControl(0),
      SponsorId: new FormControl(0),
      Phone: new FormControl(""),
      DedAmount: new FormControl(0),
      CoPayPcnt: new FormControl(0),
      PartyId: new FormControl(0),
      BranchId: new FormControl(Number(this.globals.HospitalId)),
      dhaNo: new FormControl("", [Validators.required]),
      UnclaimedId: new FormControl(0),
    });

    this.SponsorConsentForm = new FormGroup({
      SponsorNameConsent: new FormControl("", [Validators.required]),
      CTEnglishSponsor: new FormControl("", [Validators.required]),
      CTArabicSponsor: new FormControl("", [Validators.required]),
      IsDisplayedSponsorConsent: new FormControl(true)
    });

    this.SponsorSubAgentForm = new FormGroup({
      AgentName: new FormControl("", [Validators.required]),
      PayerId: new FormControl("", [Validators.required]),
      VatRegNo: new FormControl("", [Validators.required]),
      Address1: new FormControl(""),
      Address2: new FormControl(""),
      Street: new FormControl(""),
      PlacePo: new FormControl(""),
      Pin: new FormControl(""),
      City: new FormControl(""),
      CountryId: new FormControl(""),
      State: new FormControl(""),
      Mobile: new FormControl(""),
      Phone: new FormControl(""),
      Email: new FormControl(""),
      Fax: new FormControl(""),
      Remarks: new FormControl(""),
      IsDisplayed: new FormControl(true),
      AgentId: new FormControl(""),
      ContactPerson: new FormControl(""),
      IsDeleted: new FormControl(0),
      HospitalId: new FormControl((this.globals.HospitalId)),
      DhaNo: new FormControl(""),
    });
    this.ConsultantReductionForm = new FormGroup({
      Consultant: new FormControl("", [Validators.required]),
    });

    this.SponsorDrugListForm = new FormGroup({
      SponsorRuleDesc: new FormControl("", [Validators.required]),
    });
    this.DrugsAttachfileForm = new FormGroup({
      SponsorRuleDescXcel: new FormControl("", [Validators.required]),
    });

    if (this.IsSponsorList == true) {
      this.GetAllSponsorsTypes();
      this.GetAllSponsorForms();
      this.GetAllLedgers();
      this.LoadCountry();
      this.GetAllSubAgentsData();

    }

    this.GetAllSponsors();
    this.functionPermission();


    this.dropdownSettings = {
      singleSelection: false,
      minWidth: 180,
      idField: "agentId",
      textField: "agentName",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    this.SponsorcolumnDefs = [
      {
        headerName: "Insurance Name",
        field: "sponsorName",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Contact Person",
        field: "contactPerson",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Address",
        field: "address1",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Contact Number",
        field: "mobile",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Sort Order",
        field: "sortOrder",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "SponsorList" },
      },
    ];

    this.ConsultantReduction = [
      {
        headerName: "Group Name",
        field: "itemGroupName",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Discount Perc",
        field: "discPerc",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: true,
      },
    ];

    this.DrugListDef = [
      {
        headerName: "Drug Name",
        field: "drugName",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "DDC Code",
        field: "tradeCode",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "SponsorDrugList" },
      },

    ];

    this.attachListFile = [
      {
        headerName: "DDC Code",
        field:"DDC_CODE",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },

      {
        headerName: "Remarks",
        field: "Remarks",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
    ];


    this.sponsorColumnRule = [
      {
        headerName: "Insurance Network Desc.",
        field: "ruleDesc",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Insurance Name",
        field: "sponsorName",
        minWidth: 180,
        nableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Rate Group",
        field: "rGroupName",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "SponsorsRule" },
      },


    ];

    this.SubAgentscolumnDefs = [

      {
        headerName: "TPA",
        field: "agentName",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Payer Id",
        field: "payerId",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Vat Reg.No.",
        field: "vatRegNo",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "SubAgentist" },
      },


    ];

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    
    this.GetSponsors();
  }



  onTab(number) {
    this.Sponsor = false;
    this.SubAgent = false;
    this.SponsorRules = false;
    if (number == "1") {
      this.Sponsor = true;
      if (this.IsSponsorList == true) {
        this.IsSponsorList = false;
      }

    } else if (number == "2") {
      this.SubAgent = true;
      if (this.IsSubAgentList == true) {
        this.GetAllSubAgentsList();
      }


    }
    else if (number == "3") {
      this.SponsorRules = true;
      if (this.IsSponsorRule1 == true) {
        this.GetSponsorRule();
      }

    }

  }
  onTab1(number) {

    this.DrugListTab = false;
    this.AttachFileTab = false;

    if (number == "4") {
      this.DrugListTab = true;

    }
    else if (number == "5") {
      this.AttachFileTab = true;
    }
  }

  openModalSponsor(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 125)
    if (foundElement.id == 125 && foundElement.isAllowed == true) {
    this.SponsorPopupHeading = "Add Insurance";
    this.modalRef = this.modalService.show(template, { class: "modal-xl" });
    this.SponsorId = 0;
    this.ResetFunction();
    // this.clearForm();
    // let index = this.CountryListData.findIndex(asdf => asdf.text === 'United Arab Emirates')
    // let cid = this.CountryListData[index].id;
    // let ctext = this.CountryListData[index].text;
    // setTimeout(() => {
    //   //this.SponsorForm.controls['CountryId'].setValue({ id: cid, text: ctext });
    //   this.SponsorForm.controls['CountryId'].patchValue({
    //     id: cid
    //   });
    // }, 5000);

    // this.SponsorForms.CountryId = Number(this.SponsorForm.controls['CountryId'].value.id);

    // this.SponsorForm.controls['CountryId'].patchValue({
    //    id: cid
    // });
    } else {
      this.toastr.warning("You are not allowed to add insurance");
    }

  }



  //  checking Agi grid is loaded completely
  onGridReadySponsor(params) {
    this.gridApiSponsor = params.api;
    setTimeout(() => {
      this.GetSponsorRule();
    }, 1000);

  }
  onGridReadyRule(params) {
    this.gridApiRule = params.api;
  }
  onGridReadySubAgent(params) {
    this.gridApiSubAgent = params.api;
  }
  onFilterTextBoxChangedSponsor() {
    this.gridApiSponsor.setQuickFilter(
      (document.getElementById('filter-text-box-sponsor') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedRule() {
    this.gridApiRule.setQuickFilter(
      (document.getElementById('filter-text-box-rule') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedSubAgent() {
    this.gridApiSubAgent.setQuickFilter(
      (document.getElementById('filter-text-box-subagent') as HTMLInputElement).value
    );
  }

  openModalSubAgent(template2: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 134)
    if (foundElement.id == 134 && foundElement.isAllowed == true) {
    this.SubAgentPopupHeading = "Add TPA";
    this.modalRef1 = this.modalService.show(template2, { class: "modal-xl" });
    this.AgentId = 0;
    this.clearSubAgent();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }

  openModalConsultantReduction(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 130)
    if (foundElement.id == 130 && foundElement.isAllowed == true) {
    this.SponsorId = data.sponsorId;
    this.modalRefConsultantReduction = this.modalService.show(
      this.modaltemp3
      // Object.assign({}, { class: "gray modal-lg" })
    );

    this.GetConsultantReduction();
    this.GetAllConsultants();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  openModalDrugList(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 129)
    if (foundElement.id == 129 && foundElement.isAllowed == true) {
    //this.modalRef = this.modalService.show(template3, { class: "modal-lg" });
    this.modalRefSponsorRuleDrugs = this.modalService.show(
      this.modaltemp4,
      Object.assign({}, { class: "gray modal-lg" })
    );
    this.DrugList = true;
    // this.GetSponsorDrugList();
    this.GetSponsorRuleDrugs();
    // this. GetSponsorDrugList();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onTab2(number) {
    this.DrugList = false;
    this.AttachFile = false;

    if (number == "1") {
      this.DrugList = true;
    } else if (number == "2") {
      this.AttachFile = true;
    }
  }

  EditSponsorConsent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 128)
    if (foundElement.id == 128 && foundElement.isAllowed == true) {
    let SponsorId = data.sponsorId;
    this.sponsorName = data.sponsorName;
    this.SponsorConsentForm.patchValue({
      SponsorNameConsent: SponsorId,
    });
    this.ConsentService.GetSponserConsent(this.globals.HospitalId, SponsorId).subscribe((res) => {
      this.SponsorConsents = res.response;
      this.SponsorConsentForm.patchValue({
        SponsorNameConsent: SponsorId,
        CTEnglishSponsor: this.SponsorConsents[0] ? this.SponsorConsents[0]?.ctEnglish: "",
        CTArabicSponsor: this.SponsorConsents[0] ? this.SponsorConsents[0]?.ctArabic: "",
        IsDisplayedSponsor: this.SponsorConsents[0] ? this.SponsorConsents[0].isDisplayed : 1
      });
      
    });

    this.modalRefSponsor = this.modalService.show(
      this.modaltempSponsor,
      Object.assign({}, { class: "gray modal-lg" })
    );
    //  this.GetSponsors();
    // this.ResetFunction();

    //  setTimeout(() => {

    // }, 1000);
    } else {
      this.toastr.warning("You are not allowed");
    }

  }
  GetAllSponsors() {

    let Input = {
      "SponsorId": 0,
      "Showall": 1,
      "BranchId": this.globals.HospitalId
    }
    this.SponsorsService.GetAllSponsorList(Input).subscribe((res) => {
      this.GetAllSponsorsData = res.response;
      this.IsSponsorList = false;
    });
  }
  GetAllSponsorsTypes() {
    this.SponsorsService.GetSponsorTypes().subscribe((res) => {
      this.GetSponsorTypes = res.response;
      this.GetSponsorTypesData = [];
      this.GetSponsorTypes.forEach((a) => {
        this.GetSponsorTypesData.push({
          id: a.sTypeId.toString(),
          text: a.sTypeDesc,
        });
      });

    });


  }

  GetAllSubAgentsData() {
    let Input = {
      "AgentId": 0,
      "ShowAll": 0,
      "HospitalId": this.globals.HospitalId
    }
    this.SponsorsService.GetAgents(Input).subscribe((res) => {
      this.GetAllSubAgents = res.response;
      if (this.GetAllSubAgents) {
        this.GetAllSubAgents.forEach((a) => {
          (a.id = a.agentId), (a.text = a.agentName);
        });
      }

    });

  }


  GetAllSponsorForms() {
    let Input = {
      "ShowAll": 0,
      "SFormid": 0

    }
    this.SponsorFormConfig.GetAllSponsorForm(Input).subscribe((res) => {
      this.GetSponsorFormsList = res.response;
      this.GetSponsorFormsData = [];
      if (this.GetSponsorFormsList) {
        this.id = this.GetSponsorFormsList[0].sFormId;
        this.GetSponsorFormsList.forEach((a) => {
          this.GetSponsorFormsData.push({
            id: a.sFormId.toString(),
            text: a.sFormName,
          });
        });
      }

    });

  }


  GetAllLedgers() {
    let LedgerId = 0;
    let showALL = 0;
    let BranchId = this.globals.HospitalId;

    this.configurationService
      .GetLedger(LedgerId, showALL, BranchId)
      .subscribe((res) => {
        this.GetLedgers = res.response;
        this.GetLedgersData = [];
        this.GetLedgers.forEach((a) => {
          this.GetLedgersData.push({
            id: a.headId.toString(),
            text: a.headDesc,
          });
        });


      });
  }

  //LoadCountry method called from AppointmentService
  LoadCountry() {
    this.AppointmentsService.GetCountry(this.globals.HospitalId).subscribe(
      (res) => {
        this.countryList = res.response;
        this.CountryListData = [];
        this.countryList.forEach((a) => {
          this.CountryListData.push({
            id: a.countryId.toString(),
            text: a.countryName,
          });
        });


      }
    );
  }

  //ng-autocomplete countrylist select event
  selectEvent(CountryId: any) {
    this.CountryId = CountryId;
    this.GetState();

  }

  //Get state from current country selected inputs
  GetState() {
    if (this.CountryId) {
      let ShowAll = 0;
      this.configurationService
        .GetState(this.globals.HospitalId, this.CountryId, ShowAll).subscribe((res) => {
          this.state1List = res.response;
          this.StateListData = [];
          this.state1List.forEach((a) => {
            this.StateListData.push({
              id: a.stateId.toString(),
              text: a.stateName,
            });
          });
        });
    }
  }
  onSelectSponorChange(i:any){
    this.sponsorFormId = Number(i)
  }
  SaveSponsor() {
    this.submitted = true;
    if (this.SponsorForm && this.SponsorForm.valid) {
      this.SponsorForms = this.SponsorForm.getRawValue();
      if (this.SponsorForms.IsDisplayed) {
        this.SponsorForms.IsDisplayed = 1;
      }
      else {
        this.SponsorForms.IsDisplayed = 0;
      }

      if (this.SponsorId) {
        this.SponsorForms.SponsorId = this.SponsorForms.SponsorId ? this.SponsorId : 0;
      }
      if (this.DeleteSponsorId) {
        this.SponsorForms.IsDeleted = 1;
      }
      else {
        this.SponsorForms.IsDeleted = 0;
      }
      if (this.SponsorForms.CountryId == 0) {
        let index = this.CountryListData.findIndex(asdf => asdf.text === 'United Arab Emirates');
        if(index){
          let cid = this.CountryListData[index].id;
          this.SponsorForms.CountryId=Number(cid);
        }else{
          this.SponsorForms.CountryId=0;
        }


      }
      else {
        this.SponsorForms.CountryId = Number(this.SponsorForms.CountryId);

      }
      if (this.SponsorForms.PartyId == 0) {
        let index = this.GetLedgersData.findIndex(asdf => asdf.text === 'Sample Ledger')
        if(index){
          let lid = this.GetLedgersData[index].id;
          this.SponsorForms.PartyId=Number(lid);
        }
        else{
          this.SponsorForms.PartyId=0;
        }
      }
      else {
        this.SponsorForms.PartyId = Number(this.SponsorForms.PartyId);

      }
      if (this.SponsorForms.UnclaimedId == 0) {
        let index = this.GetLedgersData.findIndex(asdf => asdf.text === 'Sample Ledger')
        if(index){
          let lid = this.GetLedgersData[index].id;
          this.SponsorForms.UnclaimedId=Number(lid);

        }
        else{
          this.SponsorForms.UnclaimedId=0;
        }

      }
      else {
        this.SponsorForms.UnclaimedId = Number(this.SponsorForms.PartyId);

      }
      if (this.SponsorForms.AgentforSponsorList == null) {
        this.SponsorForms.AgentforSponsorList = [];
      }
      debugger
      console.log("this.SponsorForms",this.SponsorForms)
      // if(this.SponsorForms.EnableLimit==1){

      // }
      this.SponsorForms.SFormId = Number(this.sponsorFormId ? this.sponsorFormId : 0);
      this.SponsorForms.SponsorType = Number(this.SponsorForms.SponsorType);
      this.SponsorForms.UserId = Number(this.SponsorForms.UserId);
      this.SponsorForms.UnclaimedId = Number(this.SponsorForms.UnclaimedId);
      this.SponsorForms.SortOrder = Number(this.SponsorForms.SortOrder);
      this.SponsorForms.SponsorLimit = Number(this.SponsorForms.SponsorLimit);
      if(this.SponsorForms.EnableLimit==true){
         this.SponsorForms.enableSponsorLimit=1
      }
      else{
        this.SponsorForms.enableSponsorLimit=0
      }
      if(this.SponsorForms.EnableConsent==true){
        this.SponsorForms.enableSponsorConsent=1
     }
     else{
       this.SponsorForms.enableSponsorConsent=0
     }

      this.SponsorsService.SaveSponsorForm(this.SponsorForms ).subscribe((res) => {
          this.submitted = false;

          if (res.status == 200 && res.message == "Success") {
            if (this.SponsorId > 0) {
              this.toastr.success("Success: " + "Insurance Updated Successfully...");
              //this.IsSponsorList=true;
              this.SponsorId = 0;
              this.submitted = false;
              this.GetAllSponsors();
              this.clearForm();

            }
            else {

              this.toastr.success("Success: " + "Insurance Saved Successfully...");
              // this.IsSponsorList=true;
              this.submitted = false;
              this.GetAllSponsors();
              // this.clearForm();

            }


          }
          else {

            this.toastr.error("Failed : " + res.message);
            // this.clearForm();

          }
        });
      this.GetAllSponsors();
      this.modalRef.hide();
      //  this.clearForm();
    }
  }

  /// For object key name convertion
  upper(obj) {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      let first = key.substr(0, 1).toUpperCase();
      first = first + key.substr(1);

      newobj[first] = obj[key];
    }
    return newobj;
  }


  EditSponsor(data) {
    debugger
    const foundElement = this.functionPermissionData.find(x => x.id === 126)
    if (foundElement.id == 126 && foundElement.isAllowed == true) {
    this.SponsorPopupHeading = "Edit Insurance";
    this.SponsorId = data.sponsorId;
    this.modalRef = this.modalService.show(this.modaltemp, { class: "modal-xl" });
    let SponsorPatch = this.upper(data);
    this.SponsorForm.patchValue(SponsorPatch);
    this.SponsorForm.patchValue({
      dhaNo: data.dhaNo,
      PIN:data.pin,
      PlacePO:data.placePo,
      URL:data.url,
      VATRegNo:data.vatRegNo,
      EnableConsent:data.enableSponsorConsent,
      EnableLimit:data.enableSponsorLimit,

    });
    this.selectEvent(this.SponsorForm.value.CountryId)
    } else {
      this.toastr.warning("You are not allowed to edit insurance data");
    }
  }

  DeleteSponsor(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 127)
    if (foundElement.id == 127 && foundElement.isAllowed == true) {
    let Input = {
      "SponsorId": data.sponsorId,
      "IsDeleted": 1
    }
    this.SponsorsService.DeleteSponsor(Input).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {

        this.toastr.success("Insurance deleted successfully");
        this.GetAllSponsors();
        this.SponsorId = 0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);

      }

    },
    );
    } else {
      this.toastr.warning("You are not allowed to delete insurance data");
    }

  }

  clearForm() {
    this.SponsorForm.reset({
      SponsorName: '',
      SFormId: '',
      SponsorType: '',
      SortOrder: '',
      VATRegNo: '',
      SponsorLimit: '',
      AgentforSponsorList: [],
      Remarks: '',
      Address1: '',
      Address2: '',
      Street: '',
      PlacePO: '',
      PIN: '',
      City: '',
      CountryId: '',
      State: '',
      Mobile: '',
      ResNo: '',
      Fax: '',
      URL: '',
      Email: '',
      ContactPerson: '',
      AuthorizationMode: '',
      Phone: '',
      PartyId: '',
      UnclaimedId: '',
      DedAmount: 0,
      dhaNo: '',
      CoPayPcnt: 0,
      DepartmentDescription: '',
      IsDisplayed: true,
      EnableConsent: false,
      EnableLimit: false,
      SponsorId: 0,
      SessionId: 0,
      BranchId: this.globals.HospitalId,
      UserId: localStorage.getItem('user_id')
    });

  }

  // for geting all form items

  get f() {
    return this.SponsorForm.controls;
  }
  SaveConsentSponsorForm() {
    this.submittedSponsorConsent = true;
    if (this.SponsorConsentForm && this.SponsorConsentForm.valid) {

      var AppData = {
        SponsorName: this.SponsorConsentForm.get("SponsorNameConsent").value,
        CTEnglishSponsor: this.SponsorConsentForm.get("CTEnglishSponsor").value,
        CTArabicSponsor: this.SponsorConsentForm.get("CTArabicSponsor").value,
        IsDisplayed: Number(this.SponsorConsentForm.get("IsDisplayedSponsorConsent").value)
      };
      var saveupdateId = 0;
      if (this.SponsorConsentId > 0) {
        saveupdateId = this.SponsorConsentId;
      }
      this.configurationService
        .InsertUpdateSponsorConsentForm(
          AppData,
          this.globals.HospitalId,
          saveupdateId, 0
        )
        .subscribe((res) => {
          this.result = res;
          this.submittedSponsorConsent = false;
          if (this.result.status == 200 && this.result.message == "Success") {
            if (saveupdateId == 0) {
              //this.clearSponsorForm();
              this.toastr.success("Insurance Consent Added successfully");
            } else if (this.result.status == 200 && this.result.message == "Updated Successfully") {
              this.toastr.success("Insurance Consent Updated successfully");
              this.SponsorId = 0;
            }
          } else {
            this.toastr.success("Success : " + this.result.message);
          }
          this.modalRefSponsor.hide();
          this.clearSponsorForm();
        });
      this.GetAllSponsors();
    }
  }

  GetSponsors() {
    this.ConsentService.GetSponsors(this.globals.HospitalId).subscribe((res) => {
      this.Sponsors = res.response;

    });
  }
  clearSponsorForm() {
    this.SponsorConsentForm.reset({
      SponsorNameConsent: '',
      CTEnglishSponsor: '',
      CTArabicSponsor: '',
      IsDisplayedSponsorConsent: true
    })
    this.submitted = false;
  }


  newAddSponsorRule() {
    this.router.navigate(["/SponsorRules"])
  }

  DeleteSponsorRule(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 133)
    if (foundElement.id == 133 && foundElement.isAllowed == true) {
    let Input = {
      'RuleId': data.ruleId,
      'IsDeleted': 1,
    }

    this.SponsorsService.DeleteSponsorRule(Input).subscribe(
      (res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Insurance network deleted successfully");
          this.GetSponsorRule();

        } else {
          this.toastr.error("Failure: " + this.result.message);

        }
      }
    );
    } else {
      this.toastr.warning("You are not allowed to delete insurance network");
    }
  }

  SaveAgent() {
    this.submitted1 = true;
    if (this.SponsorSubAgentForm && this.SponsorSubAgentForm.valid) {
      this.SponsorSubAgent = this.SponsorSubAgentForm.getRawValue();
      this.SponsorSubAgent.CountryId = Number(this.SponsorSubAgent.CountryId);
      this.SponsorSubAgent.AgentId = Number(this.SponsorSubAgent.AgentId) ? Number(this.SponsorSubAgent.AgentId) : 0;
      this.SponsorSubAgent.dhaNo = "";

      this.SponsorsService.SaveSubAgent(this.SponsorSubAgent).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            if (this.AgentId > 0) {
              this.toastr.success("TPA Adedd Successfully Done ...");
              this.AgentId = 0;
              // this.GetAllSponsors();
              this.submitted1 = false;
              this.IsSubAgentList = true;
            }
            else {

              this.toastr.success("TPA Saved Successfully...");
              this.submitted1 = false;
              this.IsSubAgentList = true;
            }
            this.GetAllSubAgentsList();

          }
          else {

            this.toastr.error("Failed : " + res.message);
          }
        });
      //this.GetAllSponsors();
      this.modalRef1.hide();
    }
  }
  GetAllSubAgentsList() {
    let Input = {
      "AgentId": 0,
      "ShowAll": 1,
      "HospitalId": this.globals.HospitalId
    }
    this.SponsorsService.GetAgents(Input).subscribe((res) => {
      this.GetAllSubAgentsDatas = res.response;
      this.IsSubAgentList = false;
    });
  }
  // for geting all form items

  get f1() {
    return this.SponsorSubAgentForm.controls;
  }

  EditSubAgent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 135)
    if (foundElement.id == 135 && foundElement.isAllowed == true) {
    this.SubAgentPopupHeading = "Edit TPA";
    this.AgentId = data.agentId;
    this.modalRef1 = this.modalService.show(this.modaltemp2, { class: "modal-xl" });
    let SubAgentPatch = this.upper(data);
    this.SponsorSubAgentForm.patchValue(SubAgentPatch);
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  DeleteSubAgent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 136)
    if (foundElement.id == 136 && foundElement.isAllowed == true) {
    let Input = {
      "AgentId": data.agentId,
      "IsDeleted": 1
    }
    this.SponsorsService.DeleteSubAgents(Input).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {

        this.toastr.success("TPA  deleted successfully");
        this.GetAllSubAgentsList();
        this.AgentId = 0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);

      }

    },
    );
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }
  clearattachment(){

  }
  clearSubAgent() {
    this.SponsorSubAgentForm.reset({
      AgentName: '',
      PayerId: '',
      VatRegNo: '',
      Address1: '',
      Address2: '',
      Street: '',
      PlacePo: '',
      Pin: '',
      City: '',
      CountryId: '',
      State: '',
      Mobile: '',
      Phone: '',
      Email: '',
      Fax: '',
      Remarks: '',
      IsDisplayed: true,
      ContactPerson: '',
      HospitalId: this.globals.HospitalId,
      DhaNo: '',
      AgentId: '',
      IsDeleted: 0
    })
    this.submitted1 = false;
  }

  GetConsultantReduction() {
    let Input = {
      "SponsorId": this.SponsorId,
      "ConsultantId": 0
    }
    this.SponsorsService.GetConsultantReduction(Input).subscribe((res) => {
      this.GetAllConsultantReduction = res.response;

    });
  }

  GetAllConsultants() {

    this.configurationService.GetAllConsultants(this.globals.HospitalId).subscribe((res) => {
      this.RowData = res.response;
      this.GetRowdData = [];
      this.RowData.forEach((a) => {
        this.GetRowdData.push({
          id: a.consultantId.toString(),
          text: a.consultantName,
        });
      })
    });
  }
  SaveConsultantReduction() {
    {
      let ConsultantId = this.ConsultantReductionForm.get("Consultant").value;
      let GetConsultantReductions = [];
      this.GetAllConsultantReduction.forEach((element) => {
        let item = {
          discPerc: Number(element.discPerc),
          consultantId: element.consultantId,
          sponsorId: element.sponsorId,
          itemGroupId: element.itemGroupId,
          itemGroupName: element.itemGroupName
        };
        GetConsultantReductions.push(item);
      });

      let Input = {
        "ConsultantId": Number(ConsultantId),
        "SponsorId": Number(this.SponsorId),
        "ConsultantReductionList": GetConsultantReductions
      }
      this.submittedConsultantReduction = true;
      if (this.ConsultantReductionForm && this.ConsultantReductionForm.valid) {
        this.SponsorsService.SaveConsultantReduction(Input).subscribe((res) => {
          this.result = res;
          this.submittedConsultantReduction = false;
          if (this.result.status == 200 && this.result.message == 'Saved Successfully') {

            this.toastr.success("Consultant Reduction added successfully");

            this.modalRefConsultantReduction.hide();
            this.GetSponsors();
          }
          else {
            this.toastr.error("Failed : " + this.result.message);
          }
        })
      }
    }
  }

  selectConsultantGroups(event) {
    let Input = {
      "SponsorId": this.SponsorId,
      "ConsultantId": Number(event ? event : 0)
    }
    this.SponsorsService.GetConsultantReduction(Input).subscribe((res) => {
      this.GetAllConsultantReduction = res.response;
    });
  }

  GetSponsorDrugList() {
    let Input = {
      "DrugId": 0,
      "Ruleid": 0,
      "BranchId": this.globals.HospitalId
    }
    this.SponsorsService.GetSponsorDrugList(Input).subscribe((res) => {
      this.GetSponsorDrugListData = res.response;

    });
  }
  GetSponsorRuleDrugs() {
    let Input = {
      "RuleId": 0,
      "SponsorId": Number(this.selectedSponsorId),
      "BranchId": this.globals.HospitalId,
      "ShowAll": 0
    }
    this.SponsorsService.GetSponsorRule(Input).subscribe((res) => {
      this.SponsorRulesData = res.response;
      this.GetSponsorDrugsData = [];
      this.SponsorRulesData.forEach((a) => {
        this.GetSponsorDrugsData.push({
          id: a.ruleId.toString(),
          text: a.ruleDesc,
        });
      })
    });

  }

  closeDrugModal(){
    this.modalRefSponsorRuleDrugs.hide();
    this.GetSponsorDrugListData = [];
    this.SponsorDrugListForm.patchValue({
      SponsorRuleDesc: ''
    })
  }
  closeDrugModalattachment(){
    this.modalRefSponsorRuleDrugs.hide();
    this.attachFiles = [];
    this.DrugsAttachfileForm.patchValue({
      SponsorRuleDescXcel: ''
    })
  }

  selectSponsorRuleDesc(event) {
    if(event){
      let Input = {
        "DrugId": 0,
        "Ruleid": Number(event),
        "BranchId": this.globals.HospitalId
      }
      this.SponsorRuleChecking = [];
      this.gridApiDrugs.showLoadingOverlay();
      this.SponsorsService.GetSponsorDrugList(Input).subscribe((res) => {
        this.GetSponsorDrugListData = res.response;
        _.forEach(this.GetSponsorDrugListData, (drug:any)=>{
          if(drug.selected){
            this.SponsorRuleChecking.push(drug.drugId);
          }
        })
        this.gridApiDrugs.hideOverlay();
      });
    }else{
      this.GetSponsorDrugListData = []
    }
  }
  InsertSponsorRuleDrugList() {

    let RuleId = this.SponsorDrugListForm.get("SponsorRuleDesc").value;


    let Input =
    {
      "Ruleid": Number(RuleId),
      "DrugListbySponsor": this.SponsorRuleChecking,
      "DrugsexcelforSponsorJSON": ""
    }
    this.submittedDrugList = true;
    if (this.SponsorDrugListForm && this.SponsorDrugListForm.valid) {
      this.SponsorsService.InsertSponsorRuleDrugList(Input).subscribe((res) => {
        this.result = res;
        this.submittedDrugList = false;
        if (this.result.status == 200 && this.result.message == 'Success') {

          this.toastr.success("Insurance Network DrugList added successfully");

          this.closeDrugModal();
          this.GetSponsors();
        }
        else {
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }

  }

  SponsorRuleCheckingList(checkvalue, Ischecked) {

    if (Ischecked == "checked") {
      this.SponsorRuleChecking.push(checkvalue.drugId);
    }
    else {
      this.SponsorRuleChecking = this.SponsorRuleChecking.filter((oppdata) => oppdata != checkvalue.drugId);

    }
  }

  // for geting all form items
  get ReductionF() {
    return this.ConsultantReductionForm.controls;
  }
  get DrugF() {
    return this.SponsorDrugListForm.controls;
  }
  get DrugXcel() {
    return this.DrugsAttachfileForm.controls;
  }

  uploadData(event) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(<unknown>event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      this.attachFiles = data
      console.log(this.attachFiles);

    }
  }

  saveAttachFiles() {
    let RuleId = this.DrugsAttachfileForm.get("SponsorRuleDescXcel").value;

    let Input =
    {
      "Ruleid": Number(RuleId),
      "DrugexcelbySponsorList": this.attachFiles,
    }
    this.submittedDrugListXcel = true;
    if (this.DrugsAttachfileForm && this.DrugsAttachfileForm.valid) {
      this.SponsorsService.DrugsUploadExcel(Input).subscribe((res) => {
        this.result = res;
        this.submittedDrugListXcel = false;
        if (this.result.status == 200 && this.result.message == 'Success') {

          this.toastr.success("Insurance Attach Drug Files added successfully");

          this.modalRefSponsorRuleDrugs.hide();
          this.closeDrugModalattachment();
          this.GetSponsors();
        }
        else {
          this.toastr.error("Failed : " + this.result.message);
        }
      })


    }
  }

  //On selection changed
  onSelectionChanged(eventData) {
    this.selectedSponsorId = eventData.data.sponsorId;
    this.GetSponsorRule()
  }


  GetSponsorRule() {
    let Input = {
      "RuleId": 0,
      "SponsorId": Number(this.selectedSponsorId),
      "BranchId": this.globals.HospitalId,
      "ShowAll": 0
    }
    if (this.selectedSponsorId) {
      this.SponsorsService.GetSponsorRule(Input).subscribe((res) => {
        this.SponsorRulesData = res.response;
      });
    }

    else {
      this.noRowsTemplate = "No Rows To Show"
      this.gridApiRule.hideOverlay();
    }
  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;


  }

  onGridReadyDrugs(params) {
    this.gridApiDrugs = params.api;
  }
  SponsorRuleAdd() {
    const foundElement = this.functionPermissionData.find(x => x.id === 131)
    if (foundElement.id == 131 && foundElement.isAllowed == true) {
    if(this.selectedSponsorId){
      this.router.navigate(['/config/SponsorRules', this.selectedSponsorId]);
    }else{
      this.router.navigate(['/config/SponsorRules']);
    }
    } else {
      this.toastr.warning("You are not allowed to add a new insurance network");
    }
  }
  //clear input fields
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  //Numeric Pattern set
  numericPattern = /^[0-9]*$/;
  numericOnly(event) {
    return this.numericPattern.test(event.key);
  }
  ResetFunction() {
    this.SponsorId = 0;
    this.submitted = false;
    this.clearForm();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  EditSponsorRule(data, sponsorId) {
    debugger
    const foundElement = this.functionPermissionData.find(x => x.id === 132)
    if (foundElement?.id == 132 && foundElement?.isAllowed == true) {
      this.router.navigate(["/config/SponsorRules/" + sponsorId + '/' + data,]);
    } else {
      this.toastr.warning("You are not allowed to edit insurance network");
    }
  }
  EditConsultationSlap(data, sponsorId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 523)
    console.log(this.functionPermissionData)
    if (foundElement.id == 523 && foundElement.isAllowed == true) {
      this.router.navigate(["/config/SponsorConsultanFee/" + sponsorId + '/' + data,]);
    } else {
      this.toastr.warning("You are not allowed to edit Insurance Consultant Fee");
    }
  }
}
