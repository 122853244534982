import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, retry } from "rxjs/operators";
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";

import { Response } from "../../../_Models/Response";
import { UserProfile } from "../interfaces/UserProfile";
import { ErrorHandleService } from "./error-handle.service";
import { Sponsors } from "../interfaces/Sponsors";
import { SponsorForm } from "../interfaces/SponsorForm";
import { SponsorRule } from "../interfaces/SponsorRule";
import { SubAgentList } from "../interfaces/subAgent";
@Injectable({
  providedIn: "root",
})
export class SponsorsService {
  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  GetSponsorTypes() {
    var url = "api/Sponsor/GetSponsorTypes";
    return this.http.post<Response<Sponsors[]>>(`${this.baseUrl}${url}`, "");
  }

  GetSponsorForms() {
    var url = "api/Sponsor/GetSponsorForms";
    return this.http.post<Response<Sponsors[]>>(`${this.baseUrl}${url}`, "");
  }
  GetAllSubAgent(Input) {
    var url = "api/Agent/GetAgents";
    return this.http.post<Response<Sponsors[]>>(`${this.baseUrl}${url}`, Input);
  }

  SaveSponsorForm(FormData){
    var url = "api/Sponsor/InsertUpdateSponsor";
    return this.http.post<Response<SponsorForm[]>>(`${this.baseUrl}${url}`, FormData);
  }

  GetAllSponsorList(Input) {
    var url = "api/Sponsor/GetSponsor";
    return this.http.post<Response<Sponsors[]>>(`${this.baseUrl}${url}`, Input);
  }

  GetRuleDescription(Input) {
    var url = "api/Sponsor/GetRuleDescription";
    return this.http.post<Response<Sponsors[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetSponsorGroupForRules(Input) {
    var url = "api/Sponsor/GetSponsorGroupForRule";
    return this.http.post<Response<Sponsors[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetSponsorItemForRules(Input){
    var url = "api/Sponsor/GetSponsorItemForRule";
    return this.http.post<Response<Sponsors[]>>(`${this.baseUrl}${url}`, Input);
  }
  InsertUpdateSponsorRule(Input){
    var url = "api/Sponsor/InsertUpdateSponsorRule";
    return this.http.post<Response<SponsorRule[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetSponsorRule(Input){
    var url = "api/Sponsor/GetSponsorRule";
    return this.http.post<Response<SponsorRule[]>>(`${this.baseUrl}${url}`, Input);

  }
  DeleteSponsorRule(Input){
    var url = "api/Sponsor/DeleteSponsorRule";
    return this.http.post<Response<SponsorRule[]>>(`${this.baseUrl}${url}`, Input);
  }
  SaveSubAgent(FormData){
    var url = "api/Agent/Save";
    return this.http.post<Response<SubAgentList[]>>(`${this.baseUrl}${url}`, FormData);
  }
  GetAgents(Input) {
    var url = "api/Agent/GetAgents";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,Input);
  }
  DeleteSubAgents(Input) {
    var url = "api/Agent/DeleteAgent";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,Input);
  }
  GetConsultantReduction(Input){
    var url = "api/Sponsor/GetConsultantReduction";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,Input);
  }
  SaveConsultantReduction(Input){
    var url = "api/Sponsor/InsertConsultantReduction";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,Input);
  }

  DeleteSponsor(Input){
    var url = "api/Sponsor/DeleteSponsor";
    return this.http.post<Response<Sponsors[]>>(`${this.baseUrl}${url}`, Input);
  }

  GetSponsorDrugList(Input){
    var url = "api/Sponsor/GetDrugBySponsorRule";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,Input);
  }

  InsertSponsorRuleDrugList(Input){
    var url = "api/Sponsor/InsertSponsorRuleDrugList";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,Input);
  }

  DrugsUploadExcel(Input){
    var url = "api/Sponsor/UploadedExcelSave";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,Input);
  }

  getSponsorSlapFee(Input){
    var url = "api/Sponsor/GetRateForSponsorItem";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,Input);
  }
  addSponsorFee(Input){
    var url = "api/Sponsor/InsertUpdateSponsorRuleRate";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,Input);
  }

  getSubSpnList(params){
    var url = "api/MasterData/GetServiceItemByMasterItemid";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,params);
  }
}
