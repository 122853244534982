import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Form, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CellValueChangedEvent, ColDef, GridOptions } from "ag-grid-community";
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { ConsultationsList } from '../../client/interfaces/ConsultationsList';
import { ConsultaionsService } from '../../client/services/consultations.service';
import { TreatmentGeneralActionComponent } from "../treatment-general-action/treatment-general-action.component";
import { HospitalsService } from "../../client/services/hospitals.service";
import { PatientService } from '../../client/services/patient.service';
import { progressBarList } from '../../client/interfaces/ProgressBarList';

import { BsModalService, BsModalRef, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ToastrService } from 'ngx-toastr';
import { PatientHistory } from '../../emr/interfaces/PatientHistory';
import { EMRService } from '../../emr/services/emr.service';
import { DrugsForm } from '../../emr/interfaces/DrugsForm';
import { ReportRequest } from '../../emr/interfaces/ReportRequest';
import { EMRVitalSign } from '../../emr/interfaces/EMRVitalSign';
import { ItemDetail, Treatmentform } from '../interfaces/TreatmentForm';

import { TreatmentsService } from '../services/treatments.service';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ConfigurationService } from '../../client/services/configuration.service';
import { EMRPatientInfo } from '../../emr/interfaces/EMRPatientInfo';
import { PrevVitalSignsModalComponent } from '../../emr/consultation-general-single-page/modals/prev-vital-signs-modal/prev-vital-signs-modal.component';
import { QrcodeScanModalComponent } from '../../../shared/modals/qrcode-scan-modal/qrcode-scan-modal.component';
import { StaffList } from '../../client/interfaces/StaffList';
import { LabService } from '../../lab/services/lab.service';
import { PatientHistoryComponent } from '../modals/patient-history/patient-history.component';
import { ConsultantDeskService } from '../../client/services/consultant.service';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import _ from 'lodash';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public defaultColDef: ColDef;
  public defaultColDef1: ColDef;

  public context;
  public gridApi;
  public gridApiVital;
  public gridApi1;

  public statusBar;
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  Request: ReportRequest = new ReportRequest();
  treatmentDetails: any;
  form: FormGroup;
  reasonforVistForm: FormGroup;
  PatientHistoryForm: FormGroup;
  VitalSignForm: FormGroup;

  PrevDrugsHistory: DrugsForm[] = [];
  globals: globalvars = new globalvars();
  subscription: Subscription;
  departments: any;
  rowData: ConsultationsList[] = [];
  ConsultOngoing: number = 0;
  Consultwaiting: number = 0;
  Consultfinished: number = 0;
  ConsultCancelled: number = 0;
  progressBarData: progressBarList[] = [];
  vitalSignList: EMRVitalSign[] = [];
  rowData1: any;
  selectTreatmentId: any;
  patientId: any;
  treatmnetd: any;
  IsApplyTreatment:number;
  departmentsListData: any;
  consultants: any;
  vitalsignData: any;
  consultantsListData: any;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: "autoHeight",
    rowClassRules: {
      "row-cancelled": function (params) { return params.data.status == "C"; },
      "hidden-row": function (params) {
        return params.data.vitalSignName === 'PainScale';
      },
    },
   
      

  };

  public consultationGridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: "autoHeight",
    rowClassRules: {
      "row-cancelled": function (params) { return params.data.status == "C"; },
      "c-completed": function (params) {return params.data.status.toLocaleLowerCase() == "f"},
      "c-urgent": function (params) {
        return (params.data.status.toLocaleLowerCase() == "w" && params.data.emergency == 1);
      },
    },

  };

  rowGroupPanelShow = "always";

  columnDefs: ColDef[] = [
    {
      headerName: "Patient Name",
      field: "patientName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
      cellRenderer: (params) => {

        if (params.data.gender == 1) {
          if(params.data.emergency == '1'){
            return (
              " <img   src='assets/images/xs/maleurgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );

          }
          else{
          return (
            " <img  src='assets/images/xs/malegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );}
        } else if (params.data.gender == 2) {
          if(params.data.emergency == '1'){
            return (
              " <img   src='assets/images/xs/femaleurgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );

          }
          else{
          return (
            " <img  src='assets/images/xs/femalegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );}
        } else {
          if(params.data.emergency == '1'){
            return (
              " <img   src='assets/images/xs/defaulturgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );

          }
          else{
          return (
            " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );}
        }
      },

    },
    {
      headerName: "V Sign",
      minWidth:50,
      field: "vitalSignStatus",
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
      cellRenderer:(params)=>{
        if(params.data.vitalSignStatus == 1){
          return ('<i class="fa fa-heartbeat text-info" aria-hidden="true "></i>')
        }
      }
    },
    {
      headerName: "Consultant Name",
      field: "consultantName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Reg. No.",
      field: "regNo",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,

    },
    {
      headerName: "Insurance",
      field: "sponsor",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: false,
      flex: 2,

    },
    {
      headerName: "Actions",
      maxWidth: 75,
      filter: false,
      pinned: "right",
      resizable: false,
      // unSortIcon: true,
      field: "Id",
      flex: 1,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "treatmentFrontOffice" },
    },
  ];

  vitalColumnDefs = [
    {
      headerName: "Vital Sign",
      field: "vitalSignName",
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      flex: 2,
      filter: true,
    },
    {
      headerName: "Uom",
      field: "uom",
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      flex: 2,
      filter: true,
    },
    {
      headerName: "Vital Sign Value",
      field: "vitalSignValue",
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      flex: 2,
      editable: (params) => params.data.vitalSignName !== 'PainScale'

    },
  ];


  columnDefs1: ColDef[] = [
    {
      headerName: "Treatment Number",
      minWidth: 150,
      field: "treatmentNumber",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Item Name",
      field: "itemName",
      minWidth: 120,
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 3,

    },
    {
      headerName: "Qty",
      minWidth: 60,
      field: "qty",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: false,
      flex: 2,
    },
    {
      headerName: "Payment Status",
      minWidth: 60,
      field: "paymentStatus",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: false,
      flex: 3,
    },
    {
      headerName: "Treatment Status",
      minWidth: 180,
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
      cellRenderer: params => {
        // console.log('params.data', params.data);
        // console.log('params.data.isFinished', params.data.isFinished);
        if(params.data.isFinished == 1) {
          return 'Finished'
        } else{
          return 'Not Finished'
        }
      },
    },
    {
      headerName: "Notes",
      field: "notes",
      minWidth: 180,
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Actions",
      maxWidth: 75,
      filter: false,
      pinned: "right",
      resizable: false,
      // unSortIcon: true,
      field: "Id",
      flex: 1,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "treatmentDetailsList" },
    },
];
  BranchesId: any;


  // PopUps

  modalRef?: BsModalRef;
  patientmodalRef?: BsModalRef;
  drugsmodalRef?: BsModalRef;
  vitalSignmodalRef?: BsModalRef;

  @ViewChild('modalResonForVisit') modalResonForVisit: ModalDirective;
  @ViewChild('modalPatientHistory') modalPatientHistory: ModalDirective;
  @ViewChild('modalVitalSign') modalVitalSign: ModalDirective;
  @ViewChild('modalDrugs') modalDrugs: ModalDirective;

  dropdownSettings: IDropdownSettings = {};
  symptomList: any[];
  symptomDetails: ConsultationsList[];
  consultationId: number = 0;
  

  patientHistory: PatientHistory;
  userId: number;
  locationId: number;

  private baseUrl : string = "";
  functionPermissionData: any;
  patientInfo: EMRPatientInfo;
  NurseData:any;
  Staffs: StaffList[] = [];
  CurrentDate: any;
  bp1:any =false;
  bp2:any=false;
  @ViewChildren('since') since:  QueryList<ElementRef>;
  treatmentData: any;
  getDetails: any;
  serviceItemData: any[]=[];
  SpointId =0;
  vitalVisitId = 0;
  filterData: { status: any; date: string; DepartmentId: number; consultantId: number; };
  painVal: any=1;
  HealthInformationPlatform: any;
  vitalSignListCopy: EMRVitalSign[];
  constructor(
    private consultationsService: ConsultaionsService,
    private datePipe: DatePipe,
    private sharedservice: SharedService,
    private router: Router,
    private hospitalsService: HospitalsService,
    private patientService: PatientService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private EMRService: EMRService,
    private datepipe: DatePipe,
    private configurationManager: ConfigurationManager,
    private TreatmentsService: TreatmentsService,
    private ConfigurationService: ConfigurationService,
    private labService:LabService,
    private ConsultantDeskService: ConsultantDeskService,
    private ngxLoader: NgxUiLoaderService,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
    if(this.router.getCurrentNavigation().extras.state){ 
      this.getDetails=this.router.getCurrentNavigation().extras.state.getDetails
      this.filterData=this.router.getCurrentNavigation().extras.state.filterData
      this.GetTreatment(this.getDetails)
    }

  }

  ngOnInit(): void {
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.CurrentDate = new Date();
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: TreatmentGeneralActionComponent,
    };

    this.context = { componentParent: this };
    this.reasonforVistForm = new FormGroup({
      Symptoms: new FormControl(),
      otherreasonforvisit: new FormControl(''),
    });
    this.form = new FormGroup({
      departmenId: new FormControl(""),
      DateFromList: new FormControl( this.CurrentDate),
      consultantId: new FormControl(""),
      patientStatus: new FormControl(''),
      appointmentStatus: new FormControl(""),
      reasonDataReason: new FormControl(""),
      ConsultationId: new FormControl(""),
      Symptoms: new FormControl(""),
      otherreasonforvisit: new FormControl(""),
      blockReason: new FormControl(""),
      PostPonedDepartment: new FormControl(""),
      PostPonedConsultant: new FormControl(""),
      appDate: new FormControl(""),
      slicetime: new FormControl(""),
      DeptId: new FormControl(""),
      consultId: new FormControl(""),
      sliceNo: new FormControl(""),
      consultantDepartment: new FormControl(""),
      consultantName: new FormControl(""),
    });

    if(this.filterData){
      // debugger
    this.form.patchValue({
      patientStatus :this.filterData.status,
      DateFromList : this.filterData.date,
      departmenId : this.filterData.DepartmentId,
      consultantId : this.filterData.consultantId,
    })
    this.SpointId=this.getDetails.SpointId
    this.loadConsultant()
  }

    this.PatientHistoryForm = new FormGroup({
      id: new FormControl(0),
      pastMedicalHistory: new FormControl(""),
      familyHistory: new FormControl(""),
      socialHistory: new FormControl(""),
      currentMedication: new FormControl(""),
      immunization: new FormControl(""),
      cancerHistory: new FormControl(""),
      surgicalHistory: new FormControl(""),
      others: new FormControl(""),
      tobaccoStatus: new FormControl(0),
      patientId: new FormControl(),
      visitId: new FormControl(0),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
    });


    this.VitalSignForm = new FormGroup({
      eid: new FormControl(0),
      vitalSignDataList: new FormArray([]),
      createdTime: new FormControl(''),
      visitId: new FormControl(0),
      consultationId: new FormControl(0),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
      PerformedStaffId: new FormControl("0"),
    });


    this.GetConsultations();
    this.GetDepartments();
    this.GetProgressBar();
    this.GetTemplateReasonForVisit();
    this.GetPerformingStaff();
    this.servicepoint()

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'symptomId',
      textField: 'newsymDesc',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 6,
      allowSearchFilter: true
    };
    this.functionPermission();
    sessionStorage?.removeItem('getItemId');
    sessionStorage?.removeItem('getOrderId');
    sessionStorage?.removeItem('apply');
    this.getApplicationSettings()
  }


  onGridReady(params) {
    this.gridApi = params.api;

  }
  onGridReadyVital(params) {
    this.gridApiVital = params.api;
  }
  onGridReady1(params) {
    this.gridApi1 = params.api;
  }


  /* ============  fetching data to the Consultations grid ============ */
  GetConsultations() {
    this.GetProgressBar()
    let status = this.form.get("patientStatus").value;
    let date = this.datePipe.transform(this.form.get("DateFromList").value, "yyyy-MM-dd");
    let DepartmentId = Number(this.form.get("departmenId").value);
    let consultantId = Number(this.form.get("consultantId").value);

    this.filterData={
      status : status,
      date : this.form.get("DateFromList").value,
      DepartmentId : DepartmentId,
      consultantId : consultantId,
    }

    this.consultationsService
      .GetConsultations(date, status, consultantId, DepartmentId, this.globals.HospitalId)
      .subscribe((res) => {
        this.rowData = res.response;
        consultantId = 0;
      });
    //this.GetAppointments();
  }
  dateChanger(i:any){
    this.GetConsultations()
  }
  // get treatment

  GetTreatment(input) {
    if (input.patientId) {
      this.TreatmentsService.GetTreatments(input).subscribe((res) => {

        let response=res.response;
        const list=[];
        response.forEach(element => {
          
          element.itemDetails?.forEach(x => {

            let item={
              treatmentNumber:element.treatmentNumber,
              treatmentDate:element.treatmentDate,
              itemName:x.itemName,
              qty:x.qty,
              notes:x.notes,
              id: element.id,
              consultationId: element.consultationId,
              itemId: x.itemId,
              paymentStatus: x.paymentStatus,
              orderId: x.orderId,
              isFinished: x.isFinished
             };
             list.push(item);
             
          });


        });
        this.rowData1=list;
      });
    }
    else {
      this.noRowsTemplate = "No Row To Show";
      this.gridApi1.hideOverlay();
    }


  }
  GoToTreatment(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 230)
    if (foundElement.id == 230 && foundElement.isAllowed == true) {
    if(data.patientId){
      this.IsApplyTreatment=1

    this.router.navigate(['/Treatment_details'], { state: { id: data.patientId ,consultationId:data.consultationId,IsApplyTreatment:this.IsApplyTreatment, creditId:data.creditId ,SpointId:this.SpointId,getDetails:this.getDetails,filterData:this.filterData,prevUrl:"/Treatment_details"} });

    }
    } else {
      this.toastr.warning("You are not allowed to apply treatment");
    }
  }

  GoToApplyPhysiotherapy(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 583)
    if (foundElement?.id == 583 && foundElement.isAllowed == true) {
    this.patientInfo = {
      'VisitId':data.visitId,
      'ConsultantId':data.consultantId,
      'ConsultationId': data.consultationId,
      'PatientId': data.patientId,
      'VisitType': 0,
      'VisitStartTime': '',
      'VisitEndTime': '',
      'UserId': Number(localStorage.getItem('user_id')),
      'CrudType': 0,
      InsAmountLimit: 0,
      insCovered: 0
    }
    localStorage.setItem("emrPhyConsultationID", JSON.stringify(data.consultationId));

    let OutPut = this.EMRService.SetEMRPatientInfo(this.patientInfo);


    this.router.navigate(['/physiothearapy-treatment'], { state: { consultationId: data.consultationId,id: data.patientId,consultantId:data.consultantId,visitId:data.visitId } } );
	} else {
    this.toastr.warning("You are not allowed");
  }
    
  }


  GetDepartments() {
    let showall = 0;
    this.hospitalsService.GetDepartments(this.BranchesId, showall).subscribe((res) => {
      this.departments = res.response;

    });
  }

  /* ============  fetching data to be filled in select department dropdowns ============ */
  /* ============ loading consultant Onchanging Departments  ============ */
  loadConsultant() {

    let departmentId = Number(this.form.get("departmenId").value);
    let consultantId = Number(this.form.get("consultantId").value);
    if (departmentId == 0) {
    }

    this.hospitalsService.GetConsultant(departmentId).subscribe((res) => {
      this.consultants = res.response;
    });
    if(!this.filterData){this.form.controls['consultantId'].setValue(0)}

    this.GetConsultations();
  }


  /* ============  fetching data to be filled in progress bar ============ */
  // GetProgressBar() {
  //   let CurrentDate = new Date();
  //   let date = this.datePipe.transform(CurrentDate, "dd-MM-yyyy");
  //   this.patientService.GetProgressBar(date, this.globals.HospitalId).subscribe((res) => {
  //     this.progressBarData = res.response;
  //     this.Consultwaiting = this.progressBarData[0].consPercW;
  //     this.ConsultOngoing = this.progressBarData[0].consPercO;
  //     this.ConsultCancelled = this.progressBarData[0].consPercC;
  //     this.Consultfinished = this.progressBarData[0].consPercF;

  //     // this.searchForm.patchValue({blockReason:''})
  //   });
  // }


  GetProgressBar() {
    let CurrentDate = new Date();
    let consultantId = Number(this.form.get("consultantId").value);
    // let date = this.datePipe.transform(CurrentDate, "dd-MM-yyyy");
    let date = this.datePipe.transform(this.form.get("DateFromList").value, "dd-MM-yyyy");
    this.ConsultantDeskService.GetProgressBar(date, consultantId, this.globals.HospitalId).subscribe((res) => {
       this.progressBarData = res.response;
      // this.waiting = this.progressBarData[0]['appStatCountW'];
      // this.Arrived = this.progressBarData[0]['appStatCountA'];
      // this.Cancelled = this.progressBarData[0]['appStatCountC'];
      // this.Confirmed = this.progressBarData[0]['appStatCountCF'];

      this.Consultwaiting = this.progressBarData[0]['conStatCountW'];
      this.ConsultOngoing = this.progressBarData[0]['conStatCountO'];
      this.ConsultCancelled = this.progressBarData[0]['conStatCountC'];
      this.Consultfinished = this.progressBarData[0]['conStatCountF'];

    });
  }

  ReasonForVisit(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 231)
    if (foundElement.id == 231 && foundElement.isAllowed == true) {
    // GetSaved Items
    this.consultationId = data.consultationId;
    this.consultationsService.Getsymptoms(data.consultationId).subscribe((res) => {
      this.symptomDetails = res.response;
      this.reasonforVistForm.patchValue({ Symptoms: this.symptomDetails['symptoms'] });
      if (this.symptomDetails['reasonForVisit']) {
        this.reasonforVistForm.patchValue({ otherreasonforvisit: this.symptomDetails['reasonForVisit'] });
      }

      document.getElementById("myDiv").click();
    })

    this.modalRef = this.modalService.show(this.modalResonForVisit, { class: "gray", animated: true, ignoreBackdropClick: true });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }



  GetTemplateReasonForVisit() {
    let ShowAll = 0;
    this.consultationsService.GetTemplateReasonForvisit(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.symptomList = res.response;
      this.symptomList.forEach((element) => {
        element["newsymDesc"] = element["SymptomDesc"] ? element["SymptomDesc"] : element["symptomDesc"];
      });
    });
  }

  SaveReasonForVisit() {


    var AppData = {
      ConsultationId: this.consultationId,
      Symptoms: this.reasonforVistForm.get("Symptoms").value,
      otherreasonforvisit: this.reasonforVistForm.get("otherreasonforvisit").value,
    };

    if (!AppData.Symptoms) AppData.Symptoms = [];
    if (!AppData.otherreasonforvisit || AppData.otherreasonforvisit == "") AppData.otherreasonforvisit = "";

    this.consultationsService.SaveReasonVisit(AppData).subscribe((res) => {
      if (res.status == 200) {
        this.toastr.success("Reason For Visit added successfully");
        this.reasonforVistForm.patchValue({ otherreasonforvisit: '' });
        this.reasonforVistForm.patchValue({ symptoms: [] });

        this.modalRef?.hide();
      } else {
        this.toastr.error("Reason For Visit failed");
      }
    });
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChanged1() {
    this.gridApi1.setQuickFilter(
      (document.getElementById('filter-text-box1') as HTMLInputElement).value
    );
  }

  hideReasonModal() {
    this.modalRef?.hide();
    this.reasonforVistForm.patchValue({ symptoms: [] });
    this.reasonforVistForm.controls['otherreasonforvisit'].setValue("");
  }

  closehistory() {
    this.patientmodalRef?.hide();
    this.PatientHistoryForm.patchValue({
      pastMedicalHistory: "",
      familyHistory: "",
      socialHistory: "",
      currentMedication: "",
      immunization: "",
      cancerHistory: "",
      surgicalHistory: "",
      others: "",
      tobaccoStatus: 0,
      patientId: 0
    })
  }

  PatientHistory(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 234)
    if (foundElement.id == 234 && foundElement.isAllowed == true) {
    let pid = data.patientId;
    this.PatientHistoryForm.controls['patientId'].setValue(pid);


    let input = {
      "PatientId": pid,
    };
    this.EMRService.GetPatientHistory(input).subscribe(
      (res) => {
        if (res.status == 200) {
          this.PatientHistoryForm.patchValue(res.response);
        }
      });

      let initialState = {
        visitId:data.visitId,
        patientId:data.patientId,
        functionPermissionData:this.functionPermissionData,
        HealthInformationPlatform:this.HealthInformationPlatform,
        ConsultationId:data.consultationId
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }

      let modalRef= this.modalService.show(PatientHistoryComponent,Object.assign( {}, config, { class: 'modal-xl', initialState }) );
    modalRef.content.saveEmitter.subscribe(res=>{
      if(res?.status){
        
      }
    });


    // this.patientmodalRef = this.modalService.show(this.modalPatientHistory, { class: "gray modal-lg", animated: true, ignoreBackdropClick: true });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  saveHistory() {
    this.patientHistory = this.PatientHistoryForm.getRawValue();
    this.patientHistory.userId = Number(localStorage.getItem('user_id'));
    this.patientHistory.tobaccoStatus = Number(this.patientHistory.tobaccoStatus);

    this.EMRService.SavePatientHistory(
      this.patientHistory
    ).subscribe(
      (res) => {
        if (res.status == 200 && res.message == "Success") {
          this.toastr.success("Success: " + "Patient History Details Saved Successfully...");
        }
        else {
          this.toastr.error("Error: " + "Some unexpected error occured! please try again later");
        }
      });
  }

  PrintMedication(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 232)
    if (foundElement.id == 232 && foundElement.isAllowed == true) {
    let input = {
      VisitId: 0,
      PatientId: data.patientId,
      ShowAll: 1,
    };

    this.EMRService.GetDrugsEMR(input).subscribe((res) => {
      if (res.status == 200) {
        this.PrevDrugsHistory = res.response;
        this.drugsmodalRef = this.modalService.show(this.modalDrugs, { class: "modal-lg modal-custom-style", animated: true, ignoreBackdropClick: true });
      }
    });
    } else {
      this.toastr.warning("You are not allowed to print");
    }
  }
  printDrug(vsitId, pid) {

    this.Request.ReportType = 1;

    let ReportInput = {
      VisitId: vsitId,
      PatientId: pid,
    };
    this.Request.ReportInput = ReportInput;

    localStorage.setItem("ReportRequest", JSON.stringify(this.Request));
    const url = "#/EMR_prints";
    window.open(url);
  }

  AddVitalSign(data) {
     this.consultationId=data.consultationId
     this.patientId=data.patientId
    this.vitalVisitId = 0;
    const foundElement = this.functionPermissionData.find(x => x.id === 233)
    if (foundElement.id == 233 && foundElement.isAllowed == true) {
    console.log(data);
    this.vitalVisitId = data.visitId;
    this.GetAllVitalSign()
    this.vitalSignmodalRef = this.modalService.show(this.modalVitalSign, { class: "gray modal-lg custom_style", animated: true, ignoreBackdropClick: true });

    this.VitalSignForm.controls['consultationId'].setValue(data.consultationId);
    let input = {
      "VisitId": 0,
      "BranchId": this.BranchesId,
      "ConsultationId": data.consultationId,
      "IsFromEMR":0
    };
    this.EMRService.GetEMRVitalSign(input).subscribe(
      (res) => {
        if (res.status == 200) {
          this.vitalSignList = res.response;

          if (this.vitalSignList) {
            let eid = this.vitalSignList[0].eid;
            this.VitalSignForm.controls['PerformedStaffId'].setValue(this.vitalSignList[0].performedStaffId);
            if (eid > 0) {
              this.VitalSignForm.controls['eid'].setValue(this.vitalSignList[0].eid);
              this.VitalSignForm.controls['PerformedStaffId'].setValue(this.vitalSignList[0].performedStaffId);
            }

            this.vitalSignList?.forEach((res:any)=>{
              if(res.vitalSignName == 'PainScale'){
                this.changeStyle(res.vitalSignValue)
              }
            })
            this.vitalSignListCopy=_.cloneDeep(this.vitalSignList)

          }
        }
      });
      this.patientInfo = {
        'VisitId':data.visitId,
        'ConsultantId': Number(localStorage.getItem('Id')),
        'ConsultationId': data.consultationId,
        'PatientId': data.patientId,
        'VisitType': 0,
        'VisitStartTime': '',
        'VisitEndTime': '',
        'UserId': Number(localStorage.getItem('user_id')),
        'CrudType': 0,
        InsAmountLimit: 0,
        insCovered: 0
      }

      let OutPut = this.EMRService.SetEMRPatientInfo(this.patientInfo);
    } else {
      this.toastr.warning("You are not allowed to add vital sign");
    }
  }
  // get vital sign mandatory
  GetAllVitalSign() {
    this.ConfigurationService.GetVitalSign(this.globals.HospitalId).subscribe((res) => {
      this.vitalsignData = res.response;
    });
  }
  // selection changed

  onSelectionChanged(eventData) {
    this.treatmentData = eventData;
    // console.log(eventData.data);
    let pId=eventData.data.patientId;
    let date= new Date()
    let cId = eventData.data?.consultationId ? eventData.data?.consultationId : 0;
    let dateFrom = this.datepipe.transform(date, 'dd-MM-yyyy');
    let input = {
      "patientId": Number(pId),
      "branchId":this.BranchesId,
      "DateFrom": dateFrom,
      "DateTo":"",
      "PatientName":"",
      "Mobile": "",
      "RegNo": "",
      "TreatmentNumber": "",
      "Id": 0,
      "consultationId": cId,
      SpointId:this.SpointId
    }
    this.getDetails = input;
this.GetTreatment(input);


    // if(eventData==0){
    //   this.noRowsTemplate="No Row To Show"
    //   this.treatmentDetails=[];
    // } else if(eventData){
    // this.selectedOrderId = eventData.data.patientId;
    // this.GetAllServicesOrdersById(this.selectedOrderId)

    // }
  }
 //changing urgent consultation
 ChangeAsUrgent(id) {
  this.consultationId = id;

  this.userId = Number(localStorage.getItem("user_id"));
  this.locationId = 1;

  this.consultationsService
    .ChangeAsUrgent(this.consultationId, this.userId, this.locationId, this.globals.HospitalId)
    .subscribe((res) => {
      if (res.message == "success") {
        this.toastr.success("Consultation changed as Urgent");
        this.GetConsultations();
      } else {
        this.toastr.error("Failure: " + res["errorMessage"]["message"]);
      }
    });
  this.GetConsultations();
}
  closeVitalSign() {
    // console.log('perstaff bef', this.reasonforVistForm.controls.value);
    this.vitalSignmodalRef?.hide();
    this.vitalSignList = [];
    this.reasonforVistForm.patchValue({ PerformedStaffId: '0' });
    // console.log('perstaff', this.reasonforVistForm.controls.value);
    // this.VitalSignForm.controls.performingStaff.reset();
  }
  saveVitalSign() {
    this.gridApiVital.stopEditing();
    let formdata = this.VitalSignForm.getRawValue();
    this.vitalSignList=this.vitalSignList.map((sign)=>{
      if(sign.vitalSignName=='PainScale'){
        return{
          ...sign,
          vitalSignValue:String(this.painVal)
        }
      }
      else{
        return sign
      }
    });
    const areEqualvitalSignList=_.isEqual(this.vitalSignList,this.vitalSignListCopy)
    let bp = this.vitalSignList;
    // let vitalVal1 = [];
    // let vitalVal2 = [];
    
    // 
    let save=true
    this.vitalsignData.forEach((val:any)=>{
      if(val.mandatory === 1 || val.inValidValidation){
        // vitalVal1.push(val)
        this.vitalSignList.forEach((res:any)=>{
          if(res.vitalSignName === val.signName){
            if(res.vitalSignValue == "" && val.mandatory === 1){
              this.toastr.error(`Please add ${res.vitalSignName}`)
              save=false
            } 
            if(val.inValidValidation && res.vitalSignValue != ""){
              const enteredValue = parseFloat(res.vitalSignValue)
              if (isNaN(enteredValue) || enteredValue < val.minValidationValue || enteredValue > val.maxValidationValue) {
                // Show a Toastr message
                this.toastr.error(res.vitalSignName + ' value is outside the allowed range ' + val.minValidationValue +'-'+val.maxValidationValue);
                save=false
                // Clear the value or handle accordingly
               
              }
            }
          }
          this.checkBpError(bp)
        })
      }
    })
    // 
    
    if(this.bp1 && !this.bp2){
      this.toastr.error('Please add BP Diastolic')
    }else if(!this.bp1 && this.bp2){
      this.toastr.error('Please add BP Systolic')
    } else {
      if(save) {
        formdata.vitalSignDataList = this.vitalSignList;
        let ctime = new Date();
        formdata.CreatedTime = this.datepipe.transform( new Date(), 'dd-MM-yyyy HH:mm');
        formdata.userId = Number(localStorage.getItem('user_id'));
        formdata.PerformedStaffId = Number(this.VitalSignForm.controls.PerformedStaffId.value);
        formdata.c
        formdata.IsFromEMR=0;
        formdata.visitId = Number(this.vitalVisitId)
        if(areEqualvitalSignList) return
        this.EMRService.SaveEMRVitalSigns(formdata).subscribe(
          (res) => {
            if (res.status == 200 && res.message == "Success") {
              // this.sendDataToNabid()
              this.toastr.success("Success: " + "Vital Sign details Saved Successfully...");
              this.GetConsultations()
              this.vitalSignListCopy=_.cloneDeep(this.vitalSignList)
            }
            else {
              this.toastr.error("Error: " + "Some unexpected error occures Please try again...");
            }
          });
      }
    }
  }
  checkBpError(bp){
   return bp.map((res:any)=>{
      if(res.vitalSignName == 'BP Systolic'){
        if(res.vitalSignValue != "" ){
          this.bp1 = true;
        } else {
          this.bp1 = false;
        }
      }
      if(res.vitalSignName == 'BP Diastolic'){
        if(res.vitalSignValue != "" ){
          this.bp2 = true;
        } else {
          this.bp2 = false;
        }
      }
    })
  }
  GotoEMR(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 235)
    if (foundElement.id == 235 && foundElement.isAllowed == true) {
    let patientInfo = {
      'VisitId': 0,
      'ConsultantId': Number(localStorage.getItem('Id')),
      'ConsultationId': 0,
      'PatientId': data.patientId,
      'VisitType': 0,
      'VisitStartTime': '',
      'VisitEndTime': '',
      'UserId': Number(localStorage.getItem('user_id')),
      'CrudType': 0,
      'InsAmountLimit': 0,
      insCovered: 0
    }
    let OutPut = this.EMRService.SetEMRPatientInfo(patientInfo);
    if (OutPut) {
      this.router.navigate(['/EMR_Home'],{state:{patientDatas:true}});
    }
    } else {
      this.toastr.warning("You are not allowed to goto EMR");
    }
  }


  gotoedit(row) {
    const foundElement = this.functionPermissionData.find(x => x.id === 236)
    if (foundElement.id == 236 && foundElement.isAllowed == true) {
    this.IsApplyTreatment=0
    // this.router.navigateByUrl('/Treatment_details', { state: { id:row.data.id } });
    this.router.navigate(['/Treatment_details'], { state: { id: row.id ,consultationId:row.consultationId,IsApplyTreatment:this.IsApplyTreatment,SpointId:this.SpointId,getDetails:this.getDetails,filterData:this.filterData,prevUrl:"/Treatment_details"} });
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }



printTreatment(data){
  console.log(data)
  const foundElement = this.functionPermissionData.find(x => x.id === 237)
  if (foundElement.id == 237 && foundElement.isAllowed == true) {
  // let input = {
  //   branchId: Number(this.globals.HospitalId),
  //   patientId: data.patientId,
  //   DateFrom: this.datePipe.transform(new Date(), 'dd-MM-yyyy'),
  //   DateTo: this.datePipe.transform(new Date(), 'dd-MM-yyyy'),
  //   Mobile: '',
  //   RegNo:"",
  //   PatientName: '',
  //   TreatmentNumber: data.treatmentNumber
  // };
    // let input=this.TreatmentListform.getRawValue();
  //   let DateFrom = this.TreatmentListform.get("DateFrom").value;
  //   let DateTo = this.TreatmentListform.get("DateTo").value;
  // if (typeof (DateFrom) == "object") {
  //   DateFrom = this.datepipe.transform(DateFrom, 'dd-MM-yyyy');
  //   input.DateFrom = DateFrom;

  //   }
  //   if (typeof (DateTo) == "object") {
  //     DateTo = this.datepipe.transform(DateTo, 'dd-MM-yyyy');
  //     input.DateTo = DateTo;

  //   }

    // this.TreatmentsService.PrintTreatment(input).subscribe(
    //   (res) => {
    //     if(res.status==200){
    //       let response=res.response;
    //       window.open(this.baseUrl + response[0].reportPath, "_blank");

    //     }
  
    //   });

    let initialState = {
      modalHeading: 'Print treatment details',
      reportName: 'RptPrintTreatmentDetails.trdp',
      reportParams: {
        BranchId :this.globals.HospitalId,
        TreatmentNumber: (data.treatmentNumber)
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  reportWorkLIst(data) {
    console.log(data)
    const foundElement = this.functionPermissionData.find(x => x.id === 584)
    if (foundElement.id == 584 && foundElement.isAllowed == true) {
      let payload={
        patientId:data.patientId,
        consultationId:data.consultationId,
        orderDetId:data.orderDetId
      }
      localStorage.setItem("reportWorkListData",JSON.stringify(payload))
    this.router.navigate(['/ServiceList/' + data.id + '/report/', data.itemId]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  openHistory(){
    // console.log('openhis');
    let initialState = {
      EmrPatientInfo: this.patientInfo
    }
    // console.log('initialState', initialState);
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const historyModalRef= this.modalService.show(PrevVitalSignsModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

  GetPerformingStaff() {
    let Input = {
      "StaffId": 0,
      "ShowAll": 0,
      "BranchId": this.globals.HospitalId,
    }

    this.TreatmentsService.GetPerformingStaff(Input).subscribe((res) => {
      this.Staffs = res.response;
      this.NurseData = this.Staffs.filter(x => x.categoryId === 4);

    });
  }

  openQrCodeScanner(){
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      availableStaffs: this.NurseData
    }
    const modalRef = this.modalService.show(QrcodeScanModalComponent, Object.assign({}, config,  { class: 'modal-sm', initialState }));
    modalRef.content.staffEmitter.subscribe((res: any)=>{
      if(res.status){
        this.VitalSignForm.patchValue({
          PerformedStaffId: res.staffId
        })
      }
    })
  }

  getBMI(){
    let height = 0;
    let weight = 0;

    this.gridApiVital.forEachNode((node) => {
      console.log(node.data)
      if(node.data.reservedId == 6){
        height = Number(node.data.vitalSignValue);
      }
      if(node.data.reservedId == 5){
        weight = Number(node.data.vitalSignValue);
      }
    })

    if(height == 0 || height == null || height == undefined){
      this.toastr.warning("Please enter height");
      return
    }

    if(weight == 0 || weight == null || weight == undefined){
      this.toastr.warning("Please enter weight");
      return
    }

    let meterHeight = height/100;
    let bmi = (weight / (meterHeight * meterHeight));

    this.gridApiVital.forEachNode((node) => {
      if(node.data.reservedId == 8){
        node.data.vitalSignValue = bmi.toFixed(2);
      }
    })
    // this.gridApiVital.refreshCells();
    this.gridApiVital.redrawRows();
  }

  applyTreatment(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 586)
    if (foundElement?.id == 586 && foundElement.isAllowed == true) {
    this.IsApplyTreatment=0
    sessionStorage.setItem('getItemId', JSON.stringify(data.itemId))
    sessionStorage.setItem('getOrderId', JSON.stringify(data.orderId))
    sessionStorage.setItem('apply', JSON.stringify(1))
    this.router.navigate(['/Treatment_details'], { state: { id: data.id ,consultationId:data.consultationId,IsApplyTreatment:this.IsApplyTreatment,addItemDisable:true,SpointId:this.SpointId,getDetails:this.getDetails,filterData:this.filterData,prevUrl:"/Treatment_details"} });
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  generateTreatmentNumber(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 585)
    if (foundElement?.id == 585 && foundElement.isAllowed == true) {
    let items = [];
    let itemDet = {};

    this.rowData1.find(x => {
      if (x.orderId == data.orderId) {
        itemDet = {
          itemId: x.itemId,
          itemName: x.itemName,
          qty: x.qty,
          orderDate: this.datePipe.transform(this.CurrentDate, "dd-MM-yyyy"),
          route: 0,
          dosage: 0,
          startDate: this.datePipe.transform(this.CurrentDate, "dd-MM-yyyy"),
          startTime: "",
          endDate: this.datePipe.transform(this.CurrentDate, "dd-MM-yyyy"),
          endTime: "",
          notes: "",
          isFinished: 0
        }

        items.push(itemDet);
      }
    })

    let payload = {
      IsApplyTreatment: 0,
      OrderId: data.orderId,
      PatientName: this.treatmentData.data.patientName,
      RegNo: this.treatmentData.data.regNo,
      branchId: this.BranchesId,
      consultationId: this.treatmentData.data.consultationId,
      id: 0,
      itemDetails: items,
      patientId: this.treatmentData.data.patientId,
      performingStaff: 0,
      servicePoint: 0,
      treatmentDate: this.datePipe.transform(this.CurrentDate, "dd-MM-yyyy"),
      treatmentDetails: "",
      treatmentNumber: "",
      treatmentRemarks: "",
    }

    this.ngxLoader.start();
    this.TreatmentsService.SaveTreatmentDetails(payload).subscribe(
      (res) => {

        if (res.status == 200 && res.message == "Success") {
          this.ngxLoader.stop();
          this.toastr.success("success");
          this.GetTreatment(this.getDetails);
        }
        else {
          this.ngxLoader.stop();
          this.toastr.error(res.message);
        }
      });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  servicepoint() {
    let input = {
      LocationId: this.globals.Location.locationId
    }
    this.labService.GetservicePoin(input).subscribe((res) => {
      this.serviceItemData = res.response;
      this.SpointId=this.serviceItemData[0].spointId
      if(this.getDetails?.SpointId){
        this.SpointId=this.getDetails.SpointId
      }
    });
  }

  servicepointfilter(data) {
    // debugger
    this.SpointId = Number(data)
    this.getDetails={
      ...this.getDetails,
      SpointId:this.SpointId
    }
     this.GetTreatment(this.getDetails)

  }

  onCellValueChanged(event){
    if (event.data.reservedId == 5) {
      let weightValue = Number(event.data.vitalSignValue);
      if (typeof weightValue === 'number' && !isNaN(weightValue) && weightValue !== 0 && weightValue !== null) {        
        this.gridApiVital.forEachNode((node) => {
          let vitalValue = Number(node.data.vitalSignValue);
          if (node.data.reservedId == 6 && (typeof vitalValue === 'number' && !isNaN(vitalValue) && vitalValue !== 0 && vitalValue !== null)) {
            this.calculateBMI();
          }
        });
      }
    } else if (event.data.reservedId == 6) {
      let heightValue = Number(event.data.vitalSignValue);
      if (typeof heightValue === 'number' && !isNaN(heightValue) && heightValue !== 0 && heightValue !== null) {
        this.gridApiVital.forEachNode((node) => {
          let vitalValue = Number(node.data.vitalSignValue);
          if (node.data.reservedId == 5 && (typeof vitalValue === 'number' && !isNaN(vitalValue) && vitalValue !== 0 && vitalValue !== null)) {
            this.calculateBMI();
          }
        });
      }
    }
  }

  calculateBMI(){
    let height = 0;
    let weight = 0;

    this.gridApiVital.forEachNode((node) => {
      if(node.data.reservedId == 6){
        height = Number(node.data.vitalSignValue);
      }
      if(node.data.reservedId == 5){
        weight = Number(node.data.vitalSignValue);
      }
    })

    let meterHeight = height/100;
    let bmi = (weight / (meterHeight * meterHeight));

    this.gridApiVital.forEachNode((node) => {
      if(node.data.reservedId == 8){
        node.data.vitalSignValue = bmi.toFixed(2);
      }
    })
    this.gridApiVital.refreshCells();
  }    

  changeStyle(id, isInit = false) {
    this.painVal = id
   
    for (let i = 1; i <= id; i++) {
      var element = document.getElementById(i.toString()+'pain');
      element.classList.add("pain-scale__level--active");
    }
    for (let i = id + 1; i <= 10; i++) {
      var element = document.getElementById(i.toString()+'pain');
      element.classList.remove("pain-scale__level--active");
    }
    if(!isInit){
      // this.FormValueChangeChecker = true;
    }
  }

  getApplicationSettings() {
    let payload = {
      UserId: Number(localStorage.getItem("user_id")),
      LocationId: this.globals?.Location?.locationId,
      BranchId: this.globals.HospitalId,
    };
    this.patientService.getApplicationSettings(payload).subscribe((res) => {
      let HealthInformationPlatform = _.find(res.response, {
        displayName: "HealthInformationPlatform",
      });
      this.HealthInformationPlatform = HealthInformationPlatform;
    });
  }

  sendDataToNabid() {
    const currentDate = this.datepipe.transform(new Date(), "dd-MM-yyyy");
    const nbPayload = {
      EventId: 1,
      Consultationid: this.patientInfo.ConsultationId,
      EntryDate: currentDate,
      PatientId: this.patientInfo.PatientId,
      BranchId: this.globals.HospitalId,
    };
    debugger
    const Permission = this.functionPermissionData.find((x) => x.id === 560);

    
    
    // Check if the platform is 'Nabidh' and permissions are allowed
    if (this.HealthInformationPlatform.settValue === "Nabidh" ) {
      if (Permission?.isAllowed ) {
         this.sendToNabidh(nbPayload)
      } else {
        this.toastr.warning("No permission to send data to nabith");
      }
    }
  }

  private sendToNabidh(payload: any) {
    this.patientService.SendDetailstoNabith(payload).subscribe({
      next: (res: any) => {
        this.toastr.info("Response from Nabidh: " + res);
      },
    });
  }

 
}
