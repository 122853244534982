import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { Response } from "../../../_Models/Response";
import { DatePipe } from "@angular/common";
import { ConfigurationList } from "../interfaces/ConfigurationList";
import { catchError, retry } from "rxjs/operators";
import { ErrorHandleService } from "./error-handle.service";
import { ZoneList } from "../interfaces/ZoneList";
import { HospitalBranchesList } from "../interfaces/HospitalBranchesList";
import { RegSchemeList } from "../interfaces/RegSchemeList";
import { LeadAgentList } from "../interfaces/LeadAgentList";
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";
import { Ledger } from "../interfaces/Ledger";
import { BidiModule } from "@angular/cdk/bidi";
import { AnimateShowChangeCellRenderer } from "ag-grid-community";
import { Observable } from "rxjs";
import { Consultants } from '../interfaces/Consultants';

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService
  ) // private datePipe: DatePipe
  {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }
  // Zone Api
  GetZones(BranchId) {
    var url = "api/MasterData/GetZone/";
    return this.http
      .post<Response<ZoneList>>(`${this.baseUrl}${url}`, {
        "Id": 0,
        "ShowAll": 1,
        "BranchId": BranchId
      })

  }
  //  save Zone Api
  SaveZones(appData) {
    var url = "api/MasterData/InsertUpdateZone";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      ZoneCountry: appData.ZoneCountry,
      ZoneName: appData.ZoneName,
      ZoneCode: appData.ZoneCode,
      ZoneDescription: appData.ZoneDescription,
      ZoneOperator: appData.ZoneOperator,
      BlockReason: "",
    });
  }

  UpdateZone(appData, id) {
    var url = "api/MasterData/UpdateZone";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      id: id,
      ZoneCountry: appData.ZoneCountry,
      ZoneName: appData.ZoneName,
      ZoneCode: appData.ZoneCode,
      ZoneDescription: appData.ZoneDescription,
      ZoneOperator: appData.ZoneOperator,
    });
  }

  DeleteZones(id: Number) {
    var url = "api/MasterData/DeleteZone/" + id;
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, { id: id })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  //  save Branches Api
  SaveBranches(formData) {
    var url = "api/MasterData/InsertUpdateUserHospital";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, formData);
  }
  GetHospitalBranches() {
    var url = "api/MasterData/GetUserHospitals/0";
    return this.http
      .post<Response<HospitalBranchesList[]>>(`${this.baseUrl}${url}`, {})
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  UpdateBranches(appData, id) {
    var url = "api/MasterData/UpdateZone";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      id: id,
      ZoneCountry: appData.ZoneCountry,
      ZoneName: appData.ZoneName,
      ZoneCode: appData.ZoneCode,
      ZoneDescription: appData.ZoneDescription,
      ZoneOperator: appData.ZoneOperator,
    });
  }
  DeleteBranches(id: Number) {
    var url = "api/MasterData/DeleteZone/" + id;
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, { id: id })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetAllRegSchemes() {
    var url = "api/MasterData/GetRegScheme/0";
    return this.http
      .post<Response<RegSchemeList[]>>(`${this.baseUrl}${url}`, {})
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetLeadAgent(branchId) {
    var url = "api/MasterData/GetLeadAgent";
    return this.http
      .post<Response<LeadAgentList[]>>(`${this.baseUrl}${url}`, {
        LeadAgentId: 0,
        ShowAll: 1,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  SaveLeadAgent(formData) {
    var url = "api/MasterData/InsertUpdateLeadAgent";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, formData);
  }
  DeleteLeadAgent(id: Number) {
    var url = "api/MasterData/DeleteLeadAgent/" + id;
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, { id: id })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetCpctCodePagination(InputDetails) {
    var url = "api/MasterData/GetCPTCodeMaster";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }
  GetCpctCode(branchId, showAll = 0) {
    var url = "api/MasterData/GetCPTCode/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        CPTCodeId: 0,
        CPTCode: "",
        ShowAll: 1,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetICDCategory(categid, groupId, showall, branchId) {
    var url = "api/MasterData/GetICDCategory";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        CatgId: categid,
        ICDGroupId: groupId,
        ShowAll: showall,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetICDGroup(branchId) {
    var url = "api/MasterData/GetICDGroup";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        GroupId: 0,
        ShowAll: 1,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetQuestionGroup() {
    var url = "api/MasterData/GetQuestionGroup";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {})
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }


  InsertUpdateCPTCode(form, branchId, codeId) {
    {
      var url = "api/MasterData/InsertUpdateCommonMasterItem/";
      return this.http
        .post<Response<any[]>>(`${this.baseUrl}${url}`, {
          MasterFieldType: "CPTCode",
          Id: codeId,
          CodeData: form.cptcode,
          DescriptionData: form.cptdesc,
          IsDisplayed: form.IsDisplayedGroup,
          BranchId: branchId,
        })
        .pipe(retry(1), catchError(this.errorHandle.handleError));
    }
  }
  DeleteCPTCode(id: Number) {
    var url = "api/MasterData/DeleteCPTCode/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        CPTCodeId: id,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  DeleteICDLabel(id: Number) {
    var url = "api/MasterData/DeleteICDLabel";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        LabelId: id,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  DeleteICDGroup(id: Number) {
    var url = "api/MasterData/DeleteICDGroup";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "GroupId": id
      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  DeleteICDCategory(id: Number) {
    var url = "api/MasterData/DeleteCommonMasterItem";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "Id": id,
        "MasterFieldType": "ICDCategory",
      }

    )
  }
  DeleteRateGroup(id: Number) {
    var url = "api/MasterData/DeleteRateGroup";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        RGroupId: id,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetCPTModifier(branchId, showAll = 1) {
    var url = "api/MasterData/GetCPTModifier/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        Id: 0,
        ShowAll: showAll,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  GetIcdLabelPagination(InputDetails) {
    var url = "api/MasterData/GetICDLabelMaster";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetICDLabel(groupid, categoryid, branchId, isShowAll) {
    var url = "api/MasterData/GetICDLabel";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        LabelDesc: '',
        LabelId: 0,
        ICDGroupId: groupid,
        CatgId: categoryid,
        ShowAll: isShowAll,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetVitalSign(branchId) {
    var url = "api/MasterData/GetVitalSign";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "SignId": 0,
        "ShowAll": 1,
        "BranchId": branchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetSign(branchId, showall = 1) {
    var url = "api/MasterData/GetCommonMasterItem";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "Id": 0,
        "MasterFieldType": "Sign",
        "BranchId": branchId,
        "ShowAll": showall
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetSymptom(branchId, showAll) {
    var url = "api/MasterData/GetSymptom";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        SymptomId: 0,
        ShowAll: showAll,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  InsertUpdateCPTModifier(form, branchId, modifierId) {
    {
      var url = "api/MasterData/InsertUpdateCPTModifier/";
      return this.http
        .post<Response<any[]>>(`${this.baseUrl}${url}`, {
          Id: modifierId,
          CPTModifier: form.CPTValue,
          CPTDescription: form.cptdesc,
          IsDisplayed: form.IsDisplayedGroup,
          BranchId: branchId,
          UserId: Number(localStorage.getItem("user_id")),
        })

    }
  }
  InsertUpdateSign(form, branchId, modifierId) {
    {
      var url = "api/MasterData/InsertUpdateCommonMasterItem";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
        {
          "MasterFieldType": "Sign",
          "Id": modifierId,
          "NameData": "",
          "DescriptionData": form.signDesc,
          "CodeData": "",
          "IsDisplayed": form.isDisplayed,
          "UserId": Number(localStorage.getItem('user_id')),
          "BranchId": branchId
        }
      ).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
    }
  }
  searchBySign(sign,param){
    {
      let url ;
      if(sign){
        url = "api/MasterData/GetSign"
      } else {
        url = "api/MasterData/GetSymptomEMR"
      }
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
        param
      ).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
    }
  }
  InsertUpdateVitalSign(form, branchId, modifierId) {
    {
      var url = "api/MasterData/InsertUpdateVitalSign";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
        {
          "SignId": modifierId,
          "SignName": form.vitalsignName,
          "Mandatory": form.vitalsignMandatory,
          "SignCode": form.vitalsignCode,
          "SignUnit": form.vitalsignUnit,
          "MinValue": Number(form.vitalsignMin),
          "MaxValue": Number(form.vitalsignMax),
          "SortOrder": Number(form.vitalsortOrder),
          "IsDisplayed": form.isDisplayed,
          "UserId": Number(localStorage.getItem('user_id')),
          "BranchId": branchId,
          "MinValidationValue":Number(form.minValidationValue),
          "MaxValidationValue":Number(form.maxValidationValue),
          "InValidValidation":form.inValidValidation
        }
      ).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
    }
  }
  InsertUpdateSymptom(form, branchId, modifierId) {
    {
      var url = "api/MasterData/InsertUpdateCommonMasterItem";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
        {
          "MasterFieldType": "Symptom",
          "Id": modifierId,
          "NameData": "",
          "DescriptionData": form.symptomDesc,
          "CodeData": "",
          "IsDisplayed": form.isDisplayed,
          "UserId": Number(localStorage.getItem('user_id')),
          "BranchId": branchId
        }
      ).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
    }
  }

  InsertUpdateICDLabel(form, branchId, modifierId) {
    {
      var url = "api/MasterData/InsertUpdateICDLabel";
      return this.http
        .post<Response<any[]>>(`${this.baseUrl}${url}`, {
          LabelId: modifierId,
          LabelDesc: form.IcdlabelDesc,
          LabelCode: form.IcdlabelCode,
          ICDGroupId: Number(form.IcdGroup),
          CatgId: Number(form.IcdCategory),
          IsDisplayed: form.IsDisplayedLabel,
          BranchId: branchId,
          UserId: Number(localStorage.getItem("user_id")),
          LabelSigns: form.LabelSigns,
          LabelSymptoms: form.LabelSymptoms,
          ModifiedBy: Number(localStorage.getItem("user_id")),
          ModifiedLoc: Number(form.ModifiedLoc),
          IsChronic: form.IsChronic
        })
        .pipe(retry(1), catchError(this.errorHandle.handleError));
    }
  }
  GetRateGroup(input) {
    var url = "api/MasterData/GetRateGroup/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      input
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  GetSketchIndicator(BranchId) {
    var url = "api/MasterData/GetSketchIndicators";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        IndicatorId: 0,
        ShowAll: 1,
        BranchId: BranchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetTax(TaxId, ShowAll, BranchId) {
    var url = "api/MasterData/GetTax";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        TaxId: TaxId,
        ShowAll: ShowAll,
        BranchId: BranchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  InsertUpdateRateGroup(form, branchId, modifierId, rateList = []) {
    {
      var url = "api/MasterData/InsertUpdateRateGroup";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
        {
          "RGroupId": modifierId,
          "RGroupName": form.rategroupName,
          "Description": form.rategroupDesc,
          "EffectFrom": form.rategroupEF,
          "EffectTo": form.rategroupET,
          "IsDisplayed": form.isDisplayed,
          "BranchId": branchId,
          "IsStandard": form.IsStandardRate,
          "BaseCostData": rateList,
          "UserId": Number(localStorage.getItem('user_id')),
        }
      ).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

    }
  }
  GetLedger(LedgerId, ShowAll, BranchId) {
    var url = "api/MasterData/GetLedgerHead";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        HeadId: LedgerId,
        ShowAll: ShowAll,
        BranchId: BranchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  InsertUpdateTax(form, branchId, taxId) {
    {
      var url = "api/MasterData/InsertUpdateTax";
      return this.http
        .post<Response<any[]>>(`${this.baseUrl}${url}`, {
          TaxId: taxId,
          TaxDesc: form.taxname,
          TaxPcnt: Number(form.taxpcnt),
          HeadId: Number(form.taxhead),
          UserId: Number(localStorage.getItem("user_id")),
          IsDisplayed: Number(form.isDisplayed),
          BranchId: branchId,
        })
        .pipe(retry(1), catchError(this.errorHandle.handleError));
    }
  }
  DeleteTax(taxDetail) {
    var url = "api/MasterData/DeleteTax";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        TaxId: Number(taxDetail.taxId),
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  DeleteCPTModifier(id: Number) {
    var url = "api/MasterData/DeleteCPTModifier/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        Id: id,
        UserId: Number(localStorage.getItem("user_id"))
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  DeleteSign(signDetail) {
    var url = "api/MasterData/DeleteCommonMasterItem";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        MasterFieldType: "Sign",
        Id: Number(signDetail),
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  DeleteVitalSign(vitalSignDetail) {
    var url = "api/MasterData/DeleteCommonMasterItem";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        MasterFieldType: "VitalSign",
        Id: Number(vitalSignDetail),
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  DeleteSymptom(symptomDetail) {
    var url = "api/MasterData/DeleteCommonMasterItem";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        MasterFieldType: "Symptom",
        Id: Number(symptomDetail),
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  GetCompany(BranchId) {
    var url = "api/MasterData/GetCommonMasterItem/";
    return this.http
      .post<Response<any>>(
        `${this.baseUrl}${url}`,

        {
          MasterFieldType: "Company",
          Id: 0,
          ShowAll: 1,
          BranchId: BranchId,
        }
      )
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  InsertUpdateCompany(form, cmpyId, branchId) {
    var url = "api/MasterData/InsertUpdateCommonMasterItem/";
    return this.http
      .post<Response<any[]>>(
        `${this.baseUrl}${url}`,

        {
          MasterFieldType: "Company",
          Id: cmpyId,
          NameData: form.companyname,
          DescriptionData: form.companydesc,
          CodeData: "",
          IsDisplayed: form.IsDisplayedGroup,
          UserId: Number(localStorage.getItem("user_id")),
          BranchId: branchId,
        }

      )
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  InsertUpdateSketchIndicator(form, branchId, cmpyId, IsDisplayed) {
    var url = "api/MasterData/InsertUpdateSketchIndicator";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, form);
  }
  SaveStaff(InputDetails) {
    var url = "api/MasterData/InsertUpdateDeleteStaff";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }


  DeleteCompany(CmpId) {
    var url = "api/MasterData/DeleteCompany/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        CmpId: CmpId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  InsertUpdateICDCategory(form, branchId, Id) {
    var url = "api/MasterData/InsertUpdateICDCategory";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "CatgId": Id,
        "ICDGroupId": Number(form.icdGroupId),
        "CatgName": form.icdcategoryName,
        "CatgDesc": form.icdcategoryDesc,
        "IsDisplayed": form.isDisplayed,
        "UserId": Number(localStorage.getItem('user_id')),
        "BranchId": branchId
      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  InsertUpdateICDGroup(form, branchId, Id) {
    {
      var url = "api/MasterData/InsertUpdateICDGroup";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
        {
          "GroupId": Id,
          "GroupDesc": form.icdgroupDesc,
          "GroupRange": form.icdgroupRange,
          "IsDisplayed": form.isDisplayed,
          "BranchId": branchId,
          "UserId": Number(localStorage.getItem('user_id')),
        }
      ).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
    }
  }

  GetAllAvailableServices(branchId, GroupIdList = 0) {
    var url = "api/ServiceOrder/GetAvailableService";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {
      PatientId: 0,
      GroupIdList: [GroupIdList],
      BranchId: Number(branchId),
    });
  }
  GetDepartments(branchId, showall) {
    var url = "api/MasterData/GetDepartment";
    // return this.http.post<Response<Departments[]>>(`${this.baseUrl}${url}`, null)
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "DepartmentId": 0,
        "ShowAll": showall,
        "BranchId": branchId
      }).pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  //  save Branches Api
  SaveServiceItems(formData, itemId, Taxarray, ServiceItemArray) {
    console.log(formData)
    if (Taxarray == undefined) {
      var url = "api/MasterData/InsertUpdateServiceItem/";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,

        {
          "ItemId": itemId,
          "ItemCode": formData.value.code,
          "ItemName": formData.value.name,
          "GroupId": Number(formData.value.group),
          // "parentId":Number(parentId),
          "ValidityDays": Number(formData.value.ValidityDays) ? Number(formData.value.ValidityDays) : 0,
          "ValidityVisits": Number(formData.value.NoVisits) ? Number(formData.value.NoVisits) : 0,
          "AllowRateEdit": formData.value.AllowRate ? formData.value.AllowRate : false,
          "AllowDisc": formData.value.AllowDiscount ? formData.value.AllowDiscount : false,
          "AllowPP": formData.value.AllowPostPayments ? formData.value.AllowPostPayments : false,
          "IsVSign": formData.value.VitalSignItem ? formData.value.VitalSignItem : false,
          "ResultOn": Number(formData.value.resultOn),
          "STypeId": Number(formData.value.sampleTypeId),
          "sampleTypeId": Number(formData.value.sampleTypeId),
          "MasterItemId": Number(formData.value.MasterItemId),
          "TotalTaxPcnt": 0,
          "AllowCommission": formData.value.AllowCommission ? formData.value.AllowCommission : false,
          "CommPcnt": 0,
          "CommAmt": 0,
          // "DeptId": Number(formData.value.deptId),
          "MaterialCost": Number(formData.value.materialCost),
          "BaseCost": Number(formData.value.basecost),
          "HeadId": Number(formData.value.ledgerhead),
          "SortOrder": Number(formData.value.sortorder),
          "UserId": Number(localStorage.getItem('user_id')),
          "SessionId": 1,
          "ExternalItem": formData.value.ExternalItem,
          "DrugTypeId": Number(formData.value.drugtype),
          "CPTCodeId": Number(formData.value.cptcode),
          "BranchId": Number(formData.value.BranchId),
          "DefaultTAT": formData.value.defaulttat ? formData.value.defaulttat : "",
          "ItemTaxList": [],
          "ItemRateList": ServiceItemArray,
          "vaccineTypeId": Number(formData.value.vaccinetype),
          "containerId": Number(formData.value.containers),
          "staffMandatory": formData.value.StaffMandatory,
          "IsDisplayed": formData.value.IsDisplayedGroup,
          IsVaccine: formData.value.isVaccine,
          RouteId: Number(formData.value.routeId),
          Unit: formData.value.Unit,
          VaccineValidation:formData.value.VaccineValidation,
          SPointlist:formData.value.SPointlist ? formData.value.SPointlist : [],
          InsuranceGroupId:Number(formData.value.InsuranceGroupId)
        }
      )
    }
    else {
      var url = "api/MasterData/InsertUpdateServiceItem/";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,

        {
          "ItemId": itemId,
          "ItemCode": formData.value.code,
          "ItemName": formData.value.name,
          "GroupId": Number(formData.value.group),
          "MasterItemId": Number(formData.value.MasterItemId),
          // "parentId":Number(parentId),
          "ValidityDays": Number(formData.value.ValidityDays) ? Number(formData.value.ValidityDays) : 0,
          "ValidityVisits": Number(formData.value.NoVisits) ? Number(formData.value.NoVisits) : 0,
          "AllowRateEdit": formData.value.AllowRate ? formData.value.AllowRate : false,
          "AllowDisc": formData.value.AllowDiscount ? formData.value.AllowDiscount : false,
          "AllowPP": formData.value.AllowPostPayments ? formData.value.AllowPostPayments : false,
          "IsVSign": formData.value.VitalSignItem ? formData.value.VitalSignItem : false,
          "ResultOn": Number(formData.value.resultOn),
          "STypeId": Number(formData.value.sampleTypeId),
          "sampleTypeId": Number(formData.value.sampleTypeId),
          "TotalTaxPcnt": 0,
          "AllowCommission": formData.value.AllowCommission ? formData.value.AllowCommission : false,
          "CommPcnt": 0,
          "CommAmt": 0,
          "MaterialCost": Number(formData.value.materialCost),
          "BaseCost": Number(formData.value.basecost),
          "HeadId": Number(formData.value.ledgerhead),
          "SortOrder": Number(formData.value.sortorder),
          "UserId": Number(localStorage.getItem('user_id')),
          "SessionId": 1,
          // "DeptId": Number(formData.value.deptId),
          "ExternalItem": formData.value.ExternalItem,
          "DrugTypeId": Number(formData.value.drugtype),
          "CPTCodeId": Number(formData.value.cptcode),
          "BranchId": Number(formData.value.BranchId),
          "DefaultTAT": formData.value.defaulttat ? formData.value.defaulttat : "",
          "ItemTaxList": Taxarray,
          "ItemRateList": ServiceItemArray,
          "vaccineTypeId": Number(formData.value.vaccinetype),
          "containerId": Number(formData.value.containers),
          "staffMandatory": formData.value.StaffMandatory,
          "IsDisplayed": formData.value.IsDisplayedGroup,
          IsVaccine: formData.value.isVaccine,
          RouteId: Number(formData.value.routeId),
          Unit: formData.value.Unit,
          VaccineValidation:formData.value.VaccineValidation,
          SPointlist:formData.value.SPointlist ? formData.value.SPointlist : [],
          InsuranceGroupId:Number(formData.value.InsuranceGroupId)
        }
      )
    }
  }


  DeleteServiceItem(id: Number) {
    var url = "api/MasterData/DeleteServiceItem/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        ItemId: id,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  GetAvailableDepartments(branchId) {
    var url = "api/MasterData/GetDepartment/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        DeptId: 0,
        ShowAll: 1,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetAllQuestions(branchId) {
    var url = "api/MasterData/GetQuestionareEMR/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        QnId: 0,
        ShowAll: 1,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  InsertUpdateDepartment(form, DepartmentId, branchId) {
    var url = "api/MasterData/InsertUpdateDepartment/";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
        DeptId: DepartmentId,
        DeptName: form.Departmentname,
        DeptCode: form.Departmentcode,
        BranchId: branchId,
        Description: form.DepartmentDescription,
        TimeSlice: 0,
        UserId: Number(localStorage.getItem("user_id")),
        IsDisplayed: form.IsDisplayedGroup,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  DeleteDepartment(DepartmentId) {
    var url = "api/MasterData/DeleteDepartment/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        DeptId: DepartmentId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  InsertUpdateDeleteQuestion(form, QuestionDatas, branchId) {
    var url = "api/MasterData/InsertUpdateDeleteQuestionareEMR/";
    return this.http
      .post<Response<any[]>>(`${this.baseUrl}${url}`, {
        QnId: QuestionDatas,
        GroupId: Number(form.GroupId),
        Question: form.QuestionText,
        SortOrder: Number(form.SortOrderText),
        BranchId: branchId,
        IsDeleting: form.IsDeleting,
        UserId: Number(localStorage.getItem("user_id")),
        IsDisplayed: form.IsDisplayedGroup,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }


  GetAllConsultants(BranchId) {
    var url = "api/Consultant/GetAllConsultants/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {
      "BranchId": BranchId,
      "ConsultantType": 2
    });
  }

  GetGroupData(branchId) {
    var url = "api/ServiceOrder/GetItemsGroup";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {
      GroupId: 0,
      ShowAll: 1,
      BranchId: Number(branchId),
    });
  }

  GetVaccineType(branchId) {
    var url = "api/MasterData/GetCommonMasterItem/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        MasterFieldType: "VaccineType",
        Id: 0,
        ShowAll: 1,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  GetContainers(branchId) {
    var url = "api/MasterData/GetCommonMasterItem/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        MasterFieldType: "Container",
        Id: 0,
        ShowAll: 1,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  GetDrugTypeData(branchId) {
    var url = "api/MasterData/GetCommonMasterItem/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        MasterFieldType: "DrugType",
        Id: 0,
        ShowAll: 1,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  GetLedgerHead(branchId) {
    var url = "api/MasterData/GetLedgerHead/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        HeadId: 0,
        ShowAll: 1,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  GetServiceItem(showall, branchId, groupId = 0, consultantId) {
    var url = "api/MasterData/GetServiceItem/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        Id: 0,
        ShowAll: showall,
        BranchId: branchId,
        GroupId: groupId,
        ConsultantId: Number(consultantId)
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }
  GetItemRateAmountById(branchId, ItemId) {
    var url = "api/MasterData/GetItemRateAmountById";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        Id: ItemId,
        BranchId: branchId,
      })
  }
  GetItemTax(ItemId, BranchId) {
    var url = "api/ServiceOrder/GetItemTax/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        ItemId: ItemId,
        BranchId: BranchId
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  GetCommissionRules(branchId) {
    var url = "api/MasterData/GetCommonMasterItem";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        MasterFieldType: "CommissionRule",
        Id: 0,
        ShowAll: 1,
        BranchId: branchId,
      })
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  InsertUpdateConsultant(FormData) {
    var url = "api/Consultant/InsertUpdateConsultant";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, FormData);
  }

  getUserGroups(branchId) {
    var url = "api/UserPermission/getUserGroups/" + branchId;
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, null);
  }

  getconsultantbasecost(inputData) {
    var url = "api/Consultant/GetConsultantBaseCost";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData);
  }
  saveconsultantbasecost(data) {
    var url = "api/Consultant/InsertUpdateConsultantBaseCost";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, data)
  }

  GetLocations(branchId) {
    var url = "api/MasterData/GetLocation";
    return this.http.post<Response<Location[]>>(`${this.baseUrl}${url}`,

      {
        "LocationId": 0,
        "ShowAll": 1,
        "HospitalId": branchId
      }

    )
  }

  GetConsultantById(Id) {
    var url = "api/Consultant/GetConsultantById/" + Id;
    return this.http.post<Response<Location[]>>(`${this.baseUrl}${url}`,

      {}

    )
  }

  GetCountry(BranchId) {
    var url = "api/MasterData/GetCountry";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "CountryId": 0,
        "ShowAll": 1,
        "BranchId": BranchId
      }

    )
  }

  InsertUpdateCountry(Appdata, countryId, BranchId) {
    var url = "api/MasterData/InsertUpdateCountry";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {

        "CountryId": countryId,
        "ReportCode": Appdata.ReportCode,
        "CountryName": Appdata.CountryName,
        "CountryCode": Appdata.CountryCode,
        "NGroupId": Number(Appdata.NationalityGroups),
        "NationalityName": Appdata.Natioanlity,
        "IsDisplayed": Appdata.IsDisplayedGroup,
        "BranchId": BranchId,
        "UserId": Number(localStorage.getItem('user_id')),

      }


    )
  }




  GetStaff(BranchId) {
    var url = "api/MasterData/GetStaff";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "StaffId": 0,
        "ShowAll": 1,
        "BranchId": BranchId,
      }
    )
  }

  DeleteCountry(CountryId) {
    var url = "api/MasterData/DeleteCountry/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "CountryId": CountryId
      }
    )
  }

  GetState(BranchId, CountryId = 0, showall = 0) {
    var url = "api/MasterData/GetState";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "StateId": 0,
        "ShowAll": showall,
        "BranchId": BranchId,
        "CountryId": Number(CountryId)
      }

    )
  }
  GetStandardRate(bid, rgid) {
    var url = "api/MasterData/GetStandardRate";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {
      "BranchId": bid,
      "ItemIds": rgid,

    });
  }

  InsertUpdateState(Appdata, StateId, BranchId) {
    var url = "api/MasterData/InsertUpdateState";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "StateId": StateId,
        "StateName": Appdata.StateName,
        "CountryId": Number(Appdata.CountryId),
        "UserId": Number(localStorage.getItem('user_id')),
        "IsDisplayed": Appdata.IsDisplayedGroup,
        "BranchId": BranchId,
        "StateCode": Appdata.StateCode
      }

    )
  }

  DeleteState(stateId) {
    var url = "api/MasterData/DeleteState/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "StateId": stateId
      }
    )
  }

  GetCity(BranchId) {
    var url = "api/MasterData/GetCity";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "CityId": 0,
        "ShowAll": 1,
        "BranchId": BranchId
      }

    )
  }

  InsertUpdateCity(Appdata, CityId, BranchId) {
    var url = "api/MasterData/InsertUpdateCity";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "CityId": CityId,
        "CityName": Appdata.CityName,
        "CountryId": Number(Appdata.CountryId),
        "StateId": Number(Appdata.StateId),
        "IsDisplayed": Appdata.IsDisplayedGroup,
        "BranchId": BranchId,
        "UserId": Number(localStorage.getItem('user_id')),
      }

    )
  }

  DeleteCity(cityId) {
    var url = "api/MasterData/DeleteCity/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "CityId": cityId
      }
    )
  }

  GetSalutation(BranchId, showall) {
    var url = "api/MasterData/GetCommonMasterItem";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "MasterFieldType": "Salutation",
        "Id": 0,
        "ShowAll": showall,
        "BranchId": BranchId
      }

    )
  }

  InsertUpdateSalutation(Appdata, SalutationId, BranchId) {
    var url = "api/MasterData/InsertUpdateCommonMasterItem";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "MasterFieldType": "Salutation",
        "Id": SalutationId,
        "NameData": Appdata.SalutationName,
        "IsDisplayed": Appdata.IsDisplayedGroup,
        "BranchId": BranchId,
        "UserId": Number(localStorage.getItem('user_id')),
      }
    )
  }

  DeleteSalutation(Id) {
    var url = "api/MasterData/DeleteCommonMasterItem/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "MasterFieldType": "Salutation",
        "Id": Id
      }
    )
  }

  SaveCommonMasters(InputData) {
    var url = "api/MasterData/InsertUpdateCommonMasterItem";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputData)
  }
  GetTendernesList(Input) {
    var url = "api/MasterData/GetCommonMasterItem";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, Input)
  }
  GetSpasticityList(Input) {
    var url = "api/MasterData/GetSpasticity";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, Input)
  }
  SaveSpesticity(InputData) {
    var url = "api/MasterData/InsertUpdateDeleteSpasticity";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputData)
  }

  GetMovementsRange(Input) {
    var url = "api/MasterData/GetMovementRange";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, Input)
  }
  GetModality(Input) {
    var url = "api/MasterData/GetModality";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, Input)
  }

  SaveMovementsRange(InputData) {
    var url = "api/MasterData/InsertUpdateDeleteMovementRange";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputData)
  }

  saveModality(InputData) {
    var url = "api/MasterData/InsertUpdateDeleteModality";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputData)
  }



  DeleteConsultant(Id) {
    var url = "api/Consultant/DeleteConsultant/" + Id;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "Id": Id
      }
    )
  }

  DeleteCommonMaster(inputData) {
    var url = "api/MasterData/DeleteCommonMasterItem";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  GetTMovementsList(Input) {
    var url = "api/MasterData/GetCommonMasterItem";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, Input)
  }
  SaveCommunication(Input) {
    var url = "api/MasterData/InsertUpdateCommunicationConfiguration";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, Input)
  }
  GetCommunicationConfiguration(Input) {
    var url = "api/MasterData/GetCommunicationConfiguration";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, Input)

  }

  GetNationalityGroups(BranchId) {
    var url = "api/MasterData/GetNationalityGroup";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "NGroupId": 0,
        "ShowAll": 1,
        "BranchId": BranchId
      }

    )
  }

  InsertUpdateNationalityGroup(Appdata, NgroupId, BranchId) {
    var url = "api/MasterData/InsertUpdateNationalityGroup";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "NGroupId": NgroupId,
        "NGroupDesc": Appdata.NationalityGroup,
        "RegionCode": Appdata.RegionCode,
        "UserId": Number(localStorage.getItem('user_id')),
        "IsDisplayed": Appdata.IsDisplayedGroup,
        "BranchId": BranchId
      }
    );
  }



  DeleteNationalityGroup(NgroupId) {
    var url = "api/MasterData/DeleteNationalityGroup/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "NGroupId": NgroupId
      }
    )
  }
  GetScientificName(BranchId) {
    var url = "api/MasterData/GetScientificName";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "ScientificId": 0,
        "ShowAll": 1,
        "BranchId": BranchId
      }

    )

  }
  GetScientificNamePagination(InputDetails) {
    var url = "api/MasterData/GetScientificNameMaster";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }

  InsertUpdateScientificName(Appdata, BranchId, ScientificId, IsDeleted) {
    var url = "api/MasterData/InsertUpdateScientificName";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "ScientificId": ScientificId,
        "ScientificName": Appdata.ScientificName,
        "ScientificCode": Appdata.ScientificCode,
        "ZoneId": Number(Appdata.ZoneId),
        "IsDisplayed": Appdata.IsDisplayed,
        "BranchId": BranchId,
        "IsDeleted": IsDeleted,
        "UserId": Number(localStorage.getItem('user_id')),
      }

    )
  }

  GetCardType(BranchId) {
    var url = "api/MasterData/GetCardType";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "CardId": 0,
        "ShowAll": 1,
        "BranchId": BranchId
      }

    )
  }

  InsertUpdateCardType(Appdata, CardId, BranchId) {
    var url = "api/MasterData/InsertUpdateCardType";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "CardId": CardId,
        "CardName": Appdata.CardType,
        "ServiceCharge": Number(Appdata.ServiceCharge),
        "UserId": Number(localStorage.getItem('user_id')),
        "IsDisplayed": Appdata.IsDisplayedGroup1,
        "BranchId": BranchId
      }

    )
  }
  DeleteCardType(cardId) {
    var url = "api/MasterData/DeleteCardType/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {
      CardId: cardId,
    });
  }



  GetRoute(BranchId) {
    var url = "api/MasterData/GetRoute";
    return this.http.post<Response<ZoneList[]>>(`${this.baseUrl}${url}`, {
      RouteId: 0,
      BranchId: BranchId,
    });
  }

  GetDrugPagination(InputDetails) {
    var url = "api/MasterData/GetDrugMaster";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }


  GetDrug(BranchId) {
    var url = "api/MasterData/GetDrug";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      DrugId: 0,
      DrugTypeId: 0,
      ShowAll: 1,
      BranchId: BranchId,
    });
  }
  GetDrugType(BranchId) {
    var url = "api/MasterData/GetCommonMasterItem";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      MasterFieldType: "DrugType",
      Id: 0,
      ShowAll: 0,
      BranchId: BranchId,
    });
  }



  SaveLedger(InputData: Ledger) {
    var url = "api/MasterData/InsertUpdateLedgerHead";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputData);
  }

  GetLedgerData(InputData) {
    var url = "api/MasterData/GetLedgerHead";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputData);
  }

  GetTradeName(BranchId) {
    var url = "api/MasterData/GetTradeName";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "TradeId": 0,
        "ShowAll": 1,
        "BranchId": BranchId
      }
    )
  }

  GetActiveTradeName(BranchId) {
    var url = "api/MasterData/GetActiveTradeName";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "TradeId": 0,
        "ShowAll": 1,
        "BranchId": BranchId
      }
    )
  }

  GetTradeNamePagination(InputDetails) {
    var url = "api/MasterData/GetTradeNameMaster";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  InsertUpdateCurrency(Appdata, CurrencyId, BranchId) {
    var url = "api/MasterData/InsertUpdateCurrency";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      CurrencyId: CurrencyId,
      CurrencyName: Appdata.CurrencyName,
      CurrencyDesc: Appdata.CurrencyDesc,
      IsDisplayed: Appdata.IsDisplayedGroup,
      BranchId: BranchId,
      UserId: Number(localStorage.getItem("user_id")),
    });
  }

  GetCurrency(BranchId) {
    var url = "api/MasterData/GetCurrency";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      CurrencyId: 0,
      ShowAll: 1,
      BranchId: BranchId,
    });
  }

  DeleteCurrency(CurrencyId) {
    var url = "api/MasterData/DeleteCurrency/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {
      CurrencyId: CurrencyId,
    });
  }

  GetDosage(BranchId, ShowAll) {
    var url = "api/MasterData/GetDosage";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      DosageId: 0,
      ShowAll: ShowAll,
      BranchId: BranchId,
    });
  }

  InsertUpdateDosage(Appdata, BranchId, DosageId, IsDeleted) {
    var url = "api/MasterData/InsertUpdateDeleteDosage";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {

      "DosageId": DosageId,
      "DosageDesc": Appdata.DosageDescription,
      "DosageValue": Number(Appdata.Dosagevalue),
      "IsDisplayed": Number(Appdata.IsDisplayed),
      "IsDeleted": IsDeleted,
      "BranchId": BranchId,
      "UserId": Number(localStorage.getItem("user_id")),
      "ZoneId": Number(Appdata.ZoneIdDosage)

    });
  }
  GetFrequency(BranchId, ShowAll) {
    var url = "api/MasterData/GetFrequency";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      FreqId: 0,
      ShowAll: ShowAll,
      BranchId: BranchId,
    });
  }
  InsertUpdateDeleteFrequency(Appdata, BranchId, FreqId, IsDeleted) {
    var url = "api/MasterData/InsertUpdateDeleteFrequency";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      "FreqId": FreqId,
      "FreqDesc": Appdata.FrequencyDescription,
      "FreqValue": Number(Appdata.Frequencyvalue),
      "IsDisplayed": Appdata.isDisplayed,
      "IsDeleted": IsDeleted,
      "BranchId": BranchId,
      "UserId": Number(localStorage.getItem("user_id")),
      "ZoneId": Number(Appdata.ZoneIdFreq)
    });
  }
  InsertManualFrequency(Appdata,) {
    var url = "api/MasterData/InsertManualFrequency";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Appdata);
  }
  InsertUpdateConsentForm(Appdata, BranchId, ContentId, IsDeleted) {

    var url = "api/MasterData/InsertUpdateDeletePatientConsent";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      "consentType":Appdata.consentType,
      // "patientId":Appdata.patientId,
      "branchId":BranchId,
      "sponsorId":Appdata.sponserId,
      "ContentId": ContentId,
      "DisplayOrder": 0,
      "CTEnglish": Appdata.CTEnglishPatient,
      "CTArabic": Appdata.CTArabicPatient,
      "BranchId": BranchId,
      "UserId": Number(localStorage.getItem("user_id")),
      "IsDeleted": IsDeleted,
      "IsDisplayed": Appdata.IsDisplayed,
      "IsWitnessSign": Appdata.IsWitnessSign,
      "IsStaffSign": Appdata.IsStaffSign,
      "IsConsultantSign": Appdata.IsConsultantSign,
      "ConsentName": Appdata.ConsentName,
    });
  }

  InsertUpdateSponsorConsentForm(Appdata, BranchId, ContentId, IsDeleted) {
    var url = "api/MasterData/InsertUpdateDeleteSponsorConsent";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      "ContentId": ContentId,
      "SponsorId": Number(Appdata.SponsorName),
      "DisplayOrder": 0,
      "CTEnglish": Appdata.CTEnglishSponsor,
      "CTArabic": Appdata.CTArabicSponsor,
      "BranchId": BranchId,
      "UserId": Number(localStorage.getItem("user_id")),
      "IsDeleted": IsDeleted,
      "IsDisplayed": Appdata.IsDisplayed
    });
  }
  InsertUpdateDeleteTradeName(Appdata, BranchId, TradeId, Isdisplayed, IsDeleted) {
    var url = "api/MasterData/InsertUpdateDeleteTradeName";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "TradeId": TradeId,
        "TradeName": Appdata.TradeName,
        "ScientificId": Number(Appdata.ScientificName),
        "RouteId": Number(Appdata.RouteId),
        "DosageForm": Appdata.dosageFrom,
        "IngredentStrength": Appdata.Ingredient,
        "PackagePrice": Appdata.PackagePrice,
        "GranularUnit": Appdata.Granular,
        "Manufacturer": Appdata.Manufacturer,
        "RegisteredOwner": Appdata.RegisteredOwner,
        "IsDisplayed": Appdata.IsDisplayed,
        "IsDeleted": IsDeleted,
        "BranchId": BranchId,
        "UserId": Number(localStorage.getItem('user_id')),
        "ZoneId": Number(Appdata.ZoneIdTrade),
        "TradeCode": Appdata.DDCcode
      }

    )

  }


  InsertUpdateDeleteDrug(Appdata, BranchId, DrugId, IsDeleted) {
    var url = "api/MasterData/InsertUpdateDeleteDrug";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "DrugId": DrugId,
        "DrugCode": Appdata.DrugCode,
        "DrugName": Appdata.DisplayName,
        "Ingredient": Appdata.Ingredients,
        "Form": Appdata.form,
        "MarketStatus": Number(0 ? 0 : Appdata.marketStatus),
        "Remarks": Appdata.Remarks,
        "DrugTypeId": Number(Appdata.DrugType),
        "ScientificId": Number(Appdata.ScientificName),
        "RouteId": Number(Appdata.RouteId),
        "IsDisplayed": Appdata.IsDisplayedDrug,
        "BranchId": BranchId,
        "UserId": Number(localStorage.getItem('user_id')),
        "ZoneId": Number(Appdata.ZoneIdTrade),
        "TradeCode": Appdata.DdcCode,
        "IsDeleted": IsDeleted,
        "IngredientStrength": Appdata.IngredientStrength,
        "PackageNo": Appdata.packageno,
        "DDCCode": Appdata.DdcCode,
        "DosageForm": Appdata.DosageForm,
        TradeId: Appdata.TradeId,
        QtyinMl: Appdata.QtyinMl
      }

    )
  }



  InsertUpdateInformedConsentForm(Appdata, BranchId, ContentId, IsDeleted) {

    var url = "api/MasterData/InsertUpdateDeleteInformedConsent";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {

      "ContentId": ContentId,
      "CGroupId": Number(Appdata.Consentgroups),
      "DisplayOrder": Appdata.DisplayOrder,
      "CTEnglish": Appdata.CTEnglish,
      "CTArabic": Appdata.CTArabic,
      "BranchId": BranchId,
      "UserId": Number(localStorage.getItem("user_id")),
      "IsDeleted": IsDeleted,
      "IsDisplayed": Appdata.IsDisplayed
    });
  }

  DeleteLedegr(InputData) {
    var url = "api/MasterData/DeleteLedgerHead";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputData)
  }
  GetMarketStatus(branchId) {
    var url = "api/MasterData/GetCommonMasterItem";
    return this.http.post<Response<Location[]>>(`${this.baseUrl}${url}`,

      {
        "MasterFieldType": "MarketStatus",
        "Id": 0,
        "ShowAll": 1,
        "BranchId": branchId
      }

    )
  }

  GetUnAssigned(branchId) {
    var url = "api/UserPermission/GetUnAssignedUsers/branchId";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, branchId)

  }

  GetConsultantItems(BranchId, ShowAll = 0) {
    var url = "api/MasterData/GetItemsByType";
    return this.http.post<Response<RegSchemeList[]>>(`${this.baseUrl}${url}`,
      {
        "GroupCode": "112",
        "BranchId": BranchId,
        "ShowAll": ShowAll
      })
  }

  InsertUpdateServiceItemGroup(GroupId, ParentId, AppData, BranchId, IsDelete) {
    var url = "api/MasterData/InsertUpdateServiceItemGroup";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "GroupId": GroupId,
        "ParentId": ParentId,
        "GroupName": AppData.SubGroupName,
        "GroupTypeId": Number(AppData.subTypeId),
        "BranchId": BranchId,
        "UserId": Number(localStorage.getItem("user_id")),
        "IsDeleting": IsDelete
      }

    )
  }

  GetItemGroupType(InputData) {
    var url = "api/MasterData/GetItemGroupType";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputData);
  }
  SaveBodyparts(InputData) {
    var url = "api/MasterData/InsertUpdateDeleteBodyPart";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputData);
  }
  GetBodyparts(InputData) {
    var url = "api/MasterData/GetBodyPartbyBodyId";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputData);
  }
  NumberSettingsupdate(InputDetails) {
    var url = "api/MasterData/UpdateNumberTable";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }
  GetNumber(InputDetails) {
    var url = "api/MasterData/GetNumber";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }
  PrintServiceOrder(payload: any) {
    var url = "api/Report/PrintServicesOrderDetail";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,payload);
  }

  getPageSectionsForSavingScheme(payload: any): Observable<Response<any>>{
    const endPoint = 'api/MasterData/GetPageSections';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getSavingSchemeData(payload: any): Observable<Response<any>>{
    const endPoint = 'api/MasterData/GetSavingScheme';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getTabSchemeData(payload: any): Observable<Response<any>>{
    const endPoint = 'api/MasterData/GetTabScheme';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  UpdateSavingScheme(payload: any): Observable<Response<any>>{
    const endPoint = 'api/MasterData/UpdateSavingScheme';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  UpdateTabScheme(payload: any): Observable<Response<any>>{
    const endPoint = 'api/MasterData/UpdateTabScheme';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  saveReportWorkListTemplate(payload: any): Observable<Response<any>>{
    const endPoint = 'api/MasterData/InsertUpdateDeleteTemplate';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getReportWorklistTemplate(payload: any): Observable<Response<any>>{
    const endPoint = 'api/MasterData/GetTemplate';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getTemplatesOServiceItem(payload): Observable<Response<any>>{
    const endPoint = 'api/ServiceOrder/GetItemTemplates';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  assignTemplatesServiceItem(payload): Observable<Response<any>>{
    const endPoint = 'api/ServiceOrder/SaveItemTemplates';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getResultForm(payload): Observable<Response<any>>{
    const endPoint = 'api/Lab/GetResultForm';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }
  saveResultForm(InputDetails) {
    var url = "api/Lab/InsertUpdateResultForm";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }
  GetParameterList(payload): Observable<Response<any>>{
    const endPoint = 'api/Lab/GetParameterByResultForm';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }
  GetResultType() {
    var url = "api/Lab/GetResultType";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {})
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  GetResultTypeData(payload): Observable<Response<any>>{
    const endPoint = 'api/Lab/GetResultSubTypeByType';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }
  saveParameter(payload): Observable<Response<any>>{
    const endPoint = 'api/Lab/InsertUpdateParameter';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }
  saveSampleType(InputDetails) {
    var url = "api/Lab/InsertUpdateSampleType";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }
  GetSampleType(payload): Observable<Response<any>>{
    const endPoint = 'api/Lab/GetSampleType';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }
  EditParameterList(payload): Observable<Response<any>>{
    const endPoint = 'api/Lab/GetParameterDetails';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }
  addEditexternalLab(payload): Observable<Response<any>>{
    const endPoint = 'api/MasterData/InsertUpdateExternalLab';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }
  saveAssignResult(InputDetails) {
    var url = "api/Lab/InsertUpdateItemResultForm";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }
  GetAssignResult(InputDetails) {
    var url = "api/Lab/GetResultParamsForItem";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }
  getPreviewResultForm(InputDetails) {
    var url = "api/Lab/GetResultDetails";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }
  SaveResultEntry(InputDetails) {
    var url = "api/Lab/InsertUpdateResult";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }
  GetcategoryStaff() {
    var url = "api/MasterData/GetStaffType";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,0);
  }
  SaveResultPrint(InputDetails) {
    var url = "api/MasterData/UpdateResultConfiguration";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,InputDetails);
  }
  getResultprint(payload): Observable<Response<any>>{
    const endPoint = 'api/MasterData/GetResultConfiguration';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getCFormOrder(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/Consultant/GetConsultantEMRTabView`;
    return this.http.post<Response<any>>(endPoint, payload);
  }
 
  saveCFormOrder(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/Consultant/InsertUpdateEMRTabView`;
    return this.http.post<Response<any>>(endPoint, payload);
  }
  getServiceGroupItems(InputDetails) {
    var url = "api/ServiceOrder/GetServicesGroups/";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,InputDetails);
  }

  getAssignSerrvice(InputDetails) {
    var url = "api/MasterData/GetServicePointServiceItem/";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,InputDetails);
  }
  assignServiceItem(InputDetails) {
    var url = "api/MasterData/InsertUpdateServicePointServiceItem";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }

  getVaccinationRoutes(payload: any){
    var url = "api/MasterData/GetVaccinationRoute";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }
  functionPermission(InputDetails) {
    var url = "api/MasterData/GetUserFuncPermissionOnPage";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
  }

  updateDrugDetailsFromExcel(payload: any): Observable<Response<any>>{
    const endPoint = 'api/MasterData/UpdateDrugList';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  GetExpiredDrugMaster(payload) {
    var url = "api/MasterData/GetExpiredDrugMaster";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload)
  }

  UpdateExpiredDrugList(payload: any): Observable<Response<any>>{
    const endPoint = 'api/MasterData/UpdateExpiredDrugList';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  GetAuthorisedConsultant(payload: any) {
    var url = "api/Consultant/GetAuthorisedConsultant";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  
  // delete result form
  deleteResultForm(payload: any) {
    var url = "api/Consultant/GetAuthorisedConsultant";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  // ICD 
  GetAllICDContradictory(payload:any) {
    var url = "api/MasterData/GetContradictoryICD";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, payload)
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }

  InsertUpdateContradictory(payload:any) {

    var url = "api/MasterData/InsertUpdateDeleteContradictoryICD";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  GetConsumptionlist(payload:any) {
    var url = "api/MasterData/GetConsumption";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  UpdateConsumption(payload){
    var url = "api/MasterData/InsertUpdateDeleteConsumption";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  GetResultFormV1(payload){
    var url = "api/Lab/GetResultFormV1";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  GetInsuranceGroup(payload){
    var url = "api/Bill/GetItemsGroups";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  GetHeaderList(payload){
    var url = "api/Lab/GetHeaderByResultForm";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  InsertUpdateHeader(payload){
    var url = "api/Lab/InsertUpdateResultFormHeader";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  GetCheckListResultTypeData(payload){
    var url = "api/Lab/GetCheckListResultTypeData";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  InsertManualRoute(payload){
    var url = "api/MasterData/InsertManualRoute";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }



}
