import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PrevVitalSignsModalComponent } from '../modals/prev-vital-signs-modal/prev-vital-signs-modal.component';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { EMRService } from '../../services/emr.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PrevReviewOfSystemsModalComponent } from '../modals/prev-review-of-systems-modal/prev-review-of-systems-modal.component';
import { Settings } from '../../../settings/model/settings.model';

@Component({
  selector: 'app-review-of-systems',
  templateUrl: './review-of-systems.component.html',
  styleUrls: ['./review-of-systems.component.scss']
})
export class ReviewOfSystemsComponent implements OnInit ,OnChanges{
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  disabled:boolean=false

  public EmrPatientInfo:EMRPatientInfo;
  private FormValueChangeChecker: boolean = false;

  public reviewSystemForm: FormGroup;

  constructor(private modalService: BsModalService,
              private EmrService: EMRService,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.EmrPatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initForm();
    this.GetReviewOfSymptoms();
  }

 ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 45 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (isSettingWithValueZeroPresent) {
        // Your logic when a setting with value 0 is present and billEdit is true
      
        this.disabled=true
      }
      
    }
  }
  initForm(){
    this.reviewSystemForm = this.formBuilder.group({
      sRMId: new FormControl(0),
      constitutional: new FormControl(""),
      respiratory: new FormControl(""),
      phychiatry: new FormControl(""),
      gastrointestinial: new FormControl(""),
      hemotology: new FormControl(""),
      neurological: new FormControl(""),
      skin: new FormControl(""),
      cardiovascular: new FormControl(""),
      endocrinal: new FormControl(""),
      genitourinary: new FormControl(""),
      ent: new FormControl(""),
      immunological: new FormControl(""),
      visitId: new FormControl(this.EmrPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
    })
  }

  GetReviewOfSymptoms(){
    let input={
      VisitId:this.EmrPatientInfo.VisitId,
      PatientId:this.EmrPatientInfo.PatientId,
      ShowAll:1
    };

    this.EmrService.GetReviewOfSymptoms(input).subscribe(
      (res) => {
        if(res.status==200){
          let response=res.response[0];
          this.reviewSystemForm.patchValue(response);
          this.reviewSystemForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker=true;
           })
        }
      });
  }

  SaveReviewOfSymptoms(){
    if(this.EmrPatientInfo.CrudType!=3 &&
      this.EmrPatientInfo.VisitId>0 && this.FormValueChangeChecker){
      let ReviewOfSymptoms = this.reviewSystemForm.getRawValue();
      ReviewOfSymptoms.userId=Number(localStorage.getItem('user_id'));

      this.EmrService.SaveReviewOfSymptoms(
        ReviewOfSymptoms
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastrService.success("Success: " + "Review Of Symptoms  Saved Successfully...");
          }
          else{
            this.toastrService.error(res.errorMessage.message)
          }
        });
    }
    else{
        if(!this.FormValueChangeChecker){

          //this.toastr.warning("Info: " + "No changes detected in the entry");
        }
    }
  }

  openHistory(){
    let initialState = {
      EmrPatientInfo: this.EmrPatientInfo
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const historyModalRef= this.modalService.show(PrevReviewOfSystemsModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

}
