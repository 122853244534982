import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { SignupComponent } from './pages/signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmailComponent } from './email/email.component';
import { ChartsComponent } from './charts/charts.component';
import { FilemanagerComponent } from './filemanager/filemanager.component';
import { ComposeEmailComponent } from './email/compose-email/compose-email.component';
import { ComposeEmailDetailsComponent } from './email/compose-email-details/compose-email-details.component';
import { InvoicesComponent } from './payments/invoices/invoices.component';
import { InvoiceDetailComponent } from './payments/invoices/invoice-detail/invoice-detail.component';
import { PaymentsComponent } from './payments/payments.component';
import { AddpaymentsComponent } from './payments/addpayments/addpayments.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { EventsComponent } from './doctors/doctors-schedule/events/events.component';
import { CovidComponent } from './dashboard/covid/covid.component';
import { RegistrationComponent } from './frontoffice/registration/registration.component';
import { AddRegistrationComponent } from './frontoffice/addregistration/addregistration.component';
import { ConsultationComponent } from './frontoffice/consultation/consultation.component';
import { AppointmentComponent } from './frontoffice/checkappointment/appointment.component';
import { AddConsultationComponent } from './frontoffice/addconsultation/addconsultation.component';
import { AddAppointmentComponent } from './frontoffice/addappointment/addappointment.component';
import { FrontofficeComponent } from './frontoffice/frontoffice.component';

import { editRegistrationComponent } from './frontoffice/editregistration/editregistration.component';
import { editConsultationComponent } from './frontoffice/editconsultation/editconsultation.component';
import { editAppointmentComponent } from './frontoffice/editappointment/editappointment.component';
import { addscheduledComponent } from './frontoffice/appointmentscheduled/appointmentschedule.component';
import { consultantdeskComponent } from './consultantdesk/consultantdesk.component';
import { todayspatientComponent } from './consultantdesk/todayspatient/todayspatient.component';

import { ConfigComponent } from './configuration/config.component';

import { ServiceOrderingComponent } from './serviceordering/serviceorderdetails.component';
import { ServiceOrdersComponent } from './serviceordering/serviceorder/serviceordering.component';
import { pendingOrdersComponent } from './serviceordering/pendingOrders/pendingordering.component';
import { ServiceListComponent } from './serviceordering/service-list/service-list.component';
import { AddServiceComponent } from './serviceordering/add-service/add-service.component';

import { DateComponent } from './dateformat/date-format.component';
import { personalisationComponent } from './consultantdesk/personalisation/personalisation.component';
import { addDiseaseComponent } from './consultantdesk/personalisation/addDisease/addDisease.component';
import { addServiceItemsComponent } from './consultantdesk/personalisation/addServiceItems/addServiceItems.component';
import { addMarksComponent } from './consultantdesk/personalisation/addMarks/addMarks.component';
import { addDrugsComponent } from './consultantdesk/personalisation/addDrugs/add_drugs.component';
import { AddSketchComponent } from './consultantdesk/personalisation/add-sketch/add-sketch.component';





import { PatientPrintComponent } from './patient-print/patient-print.component';
import { PatientStickerComponent } from './patient-sticker/patient-sticker.component';

import { PatientRegistrationComponent } from './frontoffice/patient-registration/patient-registration.component';
import { AuthsGuard } from '../../auths.guard';
import { ReportManageComponent } from './serviceordering/report-manage/report-manage.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SearchPrescriptionComponent } from './frontoffice/search-prescription/search-prescription.component';

const routes: Routes = [
  // {
  //   path: 'dashboard',
  //   component: DashboardComponent,
  //   data: { title: 'LeHealth | Dashboard', pageName: 'Dashboard' },
  //   canActivate : [AuthsGuard]
  // },

  {
    path: 'app-inbox',
    component: EmailComponent,
    data: { title: 'LeHealth | Inbox', pageName: 'Inbox' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'chartelement',
    component: ChartsComponent,
    data: { title: 'LeHealth | Chart-element', pageName: 'Chart' },
    canActivate : [AuthsGuard]
  },


  {
    path: 'filemanager',
    component: FilemanagerComponent,
    data: { title: 'LeHealth | Filemanager', pageName: 'File Manager' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'composeemail',
    component: ComposeEmailComponent,
    data: { title: 'LeHealth | ComposeEmail', pageName: 'Compose EMail' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'composeemail/composeemail-details',
    component: ComposeEmailDetailsComponent,
    data: { title: 'LeHealth | ComposeEmailDetails', pageName: 'Compose EMail Details' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'doctorschedule/events',
    component: EventsComponent,
    data: { title: 'LeHealth | Events', pageName: 'Events' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'payments',
    component: PaymentsComponent,
    data: { title: 'LeHealth | Payments', pageName: 'Payments' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'payments/addpayment',
    component: AddpaymentsComponent,
    data: { title: 'LeHealth | AddPayments', pageName: 'Add Payments' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'payments/invoices',
    component: InvoicesComponent,
    data: { title: 'LeHealth | Invoices', pageName: 'Invoices' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'payments/invoices/invoice-detail',
    component: InvoiceDetailComponent,
    data: { title: 'LeHealth | InvoiceDetail', pageName: 'Invoice Detail' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'pages/signup',
    component: SignupComponent,
    data: { title: 'LeHealth | Signup', pageName: 'Sign Up' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'doctors',
    component: DoctorsComponent,
    data: { title: 'LeHealth | Doctors', pageName: 'Doctors' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'covid-19',
    component: CovidComponent,
    data: { title: 'LeHealth | Covid-19', pageName: 'Covid-19' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    data: { title: 'LeHealth | Registration', pageName: 'Registration' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'consultation',
    component: ConsultationComponent,
    data: { title: 'LeHealth | Consultation', pageName: 'Consultation' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'checkappointment',
    component: AppointmentComponent,
    data: { title: 'LeHealth | checkappointment', pageName: 'Appointment' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'AddRegistration',
    component: AddRegistrationComponent,
    data: { title: 'LeHealth | AddRegistration', pageName: 'Add Registration' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'AddRegistration/:id',
    component: AddRegistrationComponent,
    data: { title: 'LeHealth | EditRegistration', pageName: 'Edit Registration' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'AddConsultation',
    component: AddConsultationComponent,
    data: { title: 'LeHealth | AddConsultation', pageName: 'Add Consultation' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'AddConsultation/:id',
    component: AddConsultationComponent,
    data: { title: 'LeHealth | EditConsultation', pageName: 'Add Consultation' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'AddAppointment',
    component: AddAppointmentComponent,
    data: { title: 'LeHealth | AddAppointment', pageName: 'Add Appointment' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'AddAppointment/:id',
    component: AddAppointmentComponent,
    data: { title: 'LeHealth | EditAppointment', pageName: 'Edit Appointment' },
    canActivate : [AuthsGuard]
  },


  {
    path: 'FrontOffice',
    component: FrontofficeComponent,
    data: { title: 'LeHealth | FrontOffice', pageName: 'Front Office' },
    canActivate : [AuthsGuard]
  },


  {
    path: 'EditRegistration',
    component: editRegistrationComponent,
    data: { title: 'LeHealth | EditRegistration', pageName: 'Edit Registration'  },
    canActivate : [AuthsGuard]
  },

  {
    path: 'EditConsultation',
    component: editConsultationComponent,
    data: { title: 'LeHealth | EditConsultation', pageName: 'Edit Consultation'  },
    canActivate : [AuthsGuard]
  },
  {
    path: 'EditAppointment',
    component: editAppointmentComponent,
    data: { title: 'LeHealth | EditAppointment', pageName: 'Edit Appointment'  },
    canActivate : [AuthsGuard]
  },

  {
    path: 'scheduledappoinment',
    component: addscheduledComponent,
    data: { title: 'LeHealth | scheduledappoinment', pageName: 'Appointment'  },
    canActivate : [AuthsGuard]

  },

  {
    path: 'scheduledappoinment/:id',
    component: addscheduledComponent,
    data: { title: 'LeHealth | EditScheduled', pageName: 'Edit Scheduled Appointment'  },
    canActivate : [AuthsGuard]
  },

  {
    path: 'consultantdesk/:pagetype',
    component: consultantdeskComponent,
    data: { title: 'LeHealth | consultantdesk', pageName: 'Consultant Desk'  },
    canActivate : [AuthsGuard]

  },

  {
    path: 'todayspatient',
    component: todayspatientComponent,
    data: { title: 'LeHealth | Todays Patient', pageName: 'Today\'s Patient'  },
    canActivate : [AuthsGuard]
  },

  // ServiceOrdering start
  {
    path: 'ServiceOrdering',
    component: ServiceOrderingComponent,
    data: { title: 'LeHealth | ServiceOrdering', pageName: 'Service Ordering'  },
    canActivate : [AuthsGuard]
  },

  {
    path: 'serviceOrder',
    component: ServiceOrdersComponent,
    data: { title: 'LeHealth | serviceOrder', pageName: 'Service Order'  },
    canActivate : [AuthsGuard]
  },
  {
    path: 'serviceOrder/:id',
    component: ServiceOrdersComponent,
    data: { title: 'LeHealth | serviceOrder', pageName: 'Service Order'  },
    canActivate : [AuthsGuard]
  },
  {
    path: 'pendingOrders',
    component: pendingOrdersComponent,
    data: { title: 'LeHealth | pendingOrders', pageName: 'Pending Orders'  },
    canActivate : [AuthsGuard]
  },
  {
    path: 'ServiceList',
    component: ServiceListComponent,
    data: { title: 'LeHealth | ServiceOrdering', pageName: 'Service Ordering'  },
    canActivate : [AuthsGuard]
  },
  {
    path: 'ServiceList/:orderId/report/:orderItemId',
    component: ReportManageComponent,
    data: { title: 'LeHealth | Service Order Report', pageName: 'Service Order Report'  },
    canActivate : [AuthsGuard]
  },
  {
    path: 'AddService',
    component: AddServiceComponent,
    data: { title: 'LeHealth | AddService', pageName: 'Add Service'  },
    canActivate : [AuthsGuard]
  },

  {
    path: 'AddService/:id',
    component: AddServiceComponent,
    data: { title: 'LeHealth | EditService', pageName: 'Edit Service'  },
    canActivate : [AuthsGuard]
  },

  // ServiceOrdering ends



  {
    path: 'DateFormat',
    component: DateComponent,
    data: { title: 'LeHealth | DateFormat' , pageName: 'Date Format' },
    canActivate : [AuthsGuard]
  },


  {
    path: 'personalisation',
    component: personalisationComponent,
    data: { title: 'LeHealth | personalisation', pageName: 'Personalisation'  },
    canActivate : [AuthsGuard]
  },
  {
    path: 'addDisease',
    component: addDiseaseComponent,
    data: { title: 'LeHealth | addDisease', pageName: 'Add Disease'  },
    canActivate : [AuthsGuard]
  },
  {
    path: 'addServiceItems',
    component: addServiceItemsComponent,
    data: { title: 'LeHealth | addServiceItems', pageName: 'Add Service Item'  },
    canActivate : [AuthsGuard]

  },

  {
    path: 'addMarks',
    component: addMarksComponent,
    data: { title: 'LeHealth | addMarks', pageName: 'Add Mark'  },
    canActivate : [AuthsGuard]

  },

  {
    path: 'addDrugs',
    component: addDrugsComponent,
    data: { title: 'LeHealth | addDrugs', pageName: 'Add Drugs'  },
    canActivate : [AuthsGuard]

  },

  {
    path: 'addMarks/:id',
    component: addMarksComponent,
    data: { title: 'LeHealth | addMarks', pageName: 'Edit Marks'  },
    canActivate : [AuthsGuard]
  },

  {
    path: 'addDisease/:id',
    component: addDiseaseComponent,
    data: { title: 'LeHealth | addDisease', pageName: 'Edit Disease'  },
    canActivate : [AuthsGuard]
  },
  {

    path: 'addSketch',
    component: AddSketchComponent,
    data: { title: 'LeHealth | addSketch', pageName: 'Add Sketch'  },
    canActivate : [AuthsGuard]

  },

  {

    path: 'addDrugs/:id',
    component: addDrugsComponent,
    data: { title: 'LeHealth | addDrugs', pageName: 'Edit Drug'  },
    canActivate : [AuthsGuard]

  },

  {
    path: 'PatientPrint',
    component: PatientPrintComponent,
    data: { title: 'LeHealth | PatientPrint', pageName: 'Patient Print'  },
    canActivate : [AuthsGuard]

  },

  {
    path: 'PatientPrint/:id',
    component: PatientPrintComponent,
    data: { title: 'LeHealth | PatientPrint', pageName: 'Patient Print'  },
    canActivate : [AuthsGuard]
  },

  {
    path: 'PatientSticker/:id',
    component: PatientStickerComponent,
    data: { title: 'LeHealth | PatientSticker', pageName: 'Patient Sticker Print'  },
    canActivate : [AuthsGuard]
  },



  {
    path: 'PatientRegistration',
    component: PatientRegistrationComponent,
    data: { title: 'LeHealth | PatientRegistration', pageName: 'Patient Registration'  },
    canActivate : [AuthsGuard]
  },
  {
    path: 'userprofile',
    component: UserProfileComponent,
    data: { title: 'LeHealth | userprofile', pageName: 'User Profile'  },
    canActivate : [AuthsGuard]
  },
  {
    path: 'search-prescription',
    component: SearchPrescriptionComponent,
    data: { title: 'LeHealth | Search Prescription', pageName: 'Search Prescription' },
    canActivate : [AuthsGuard]
  },


  //emr-pages
  { path: 'EMR', loadChildren: () => import('../emr/emr.module').then(m => m.EMRModule),
  canActivate : [AuthsGuard] },

];
const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  // ...any other options you'd like to use
};
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule {
  static components = [
    SignupComponent,
    DashboardComponent

  ];

}
