import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxBarcodeModule } from 'ngx-barcode';
import { LabService } from '../../services/lab.service';
import { BarcodeModalComponent } from '../barcode.modal copy/barcode-modal.component';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-result-form',
  templateUrl: './barcodeType-modal.component.html',
  styleUrls: ['./barcodeType-modal.component.scss']
})
export class BarcodeTypeModalComponent implements OnInit {
  barcodeSelect: Number
  @Input() regno: 0;
  @Input() patientName: any
@Input()startDateFrom:any
@Input() barcodeInvNo:any

  constructor(private modalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService,


  ) { }

  ngOnInit(): void {
  }

  close() {
    this.modalRef.hide();
  }
  investigationBarcode(data) {
    this.barcodeSelect = data
  }
  timeStampBarcode(data) {
    this.barcodeSelect = data

  }
  openBarcode() {
    if(  this.barcodeSelect==undefined){
      this.toastr.warning("Please Select Barcode Type ")

    }
    else{
    let initialState = {
      barcodeSelect: this.barcodeSelect ,
      regno:this.regno,
      patientName:this.patientName,
      startDateFrom:[this.startDateFrom],
      barcodeInvNo:[this.barcodeInvNo]

    }
    this.close() 


    const modalRef = this.modalService.show(BarcodeModalComponent, Object.assign({ class: "gray modal-lg" },{initialState}))
  }
  }
}