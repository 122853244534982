import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AppLayoutComponent } from "./app-layout/app-layout.component";
// import { AppLayoutComponent } from "./app-layout/app-layout.component";
import { AuthsGuard } from "./auths.guard";
// import { PreloadModulesStrategy } from "./core/strategies/preload-module.strategy";
import { AuthModule } from "./modules/auth/auth.module";
import { LoginComponent } from "./modules/auth/login/login.component";

import { BillingSettlementModule } from "./modules/billing-settlement/billing-settlement.module";
import { ClientModule } from "./modules/client/client.module";
import { ConfigurationsModule } from "./modules/configurations/configurations.module";
// import { FrontofficeComponent } from "./modules/client/frontoffice/frontoffice.component";
// import { EMRDoctorModule } from "./modules/emr-doctor/emr-doctor.module";
import { EMRModule } from "./modules/emr/emr.module";

import { ReportsModule } from "./modules/reports/reports.module";
import { TreatmentModule } from "./modules/treatment/treatment.module";
import { UserPermissionModule } from "./modules/user-permission/user-permission.module";
import { NabithModule } from "./modules/nabith/nabith.module";
import { LoginGuard } from "./login.guard";


const routes: Routes = [

  { path: "", pathMatch: "full", redirectTo: "auth/Signin" },
  {
    path: "config", children: [
      {
        path: "configurations",
        loadChildren: () =>
          import("./modules/configurations/configurations.module").then(
            (m) => m.ConfigurationsModule
          ),
          canActivate: [AuthsGuard]
      },
    ]
  },
  {
    path: "hms",
    component: AppLayoutComponent,
    children: [
      {
        path: "client",
        loadChildren: () =>
          import("./modules/client/client.module").then((m) => m.ClientModule),
        canActivate: [AuthsGuard]
      },
      {
        path: "Userpermission",
        loadChildren: () =>
          import("./modules/user-permission/user-permission.module").then(
            (m) => m.UserPermissionModule
          ),
        canActivate: [AuthsGuard]
      },
      {
        path: "emr",
        loadChildren: () =>
          import("./modules/emr/emr.module").then((m) => m.EMRModule),
        canActivate: [AuthsGuard]
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./modules/reports/reports.module").then(
            (m) => m.ReportsModule
          ),
        canActivate: [AuthsGuard]
      },
      {
        path: "treatment",
        loadChildren: () =>
          import("./modules/treatment/treatment.module").then(
            (m) => m.TreatmentModule
          ),
          canActivate: [AuthsGuard]
      },
      {
        path: "billing-settlement",
        loadChildren: () =>
          import("./modules/billing-settlement/billing-settlement.module").then(
            (m) => m.BillingSettlementModule
          ),
          canActivate: [AuthsGuard]
      },
      // {
      //   path: "nabith",
      //   loadChildren: () =>
      //     import("./modules/nabith/nabith.module").then(
      //       (m) => m.NabithModule
      //     ),
      // },


    ]
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
      canActivate: [LoginGuard]
  },
  {
    path: "nabith", children: [
      {
        path: "nabith",
        loadChildren: () =>
          import("./modules/nabith/nabith.module").then(
            (m) => m.NabithModule
          ),
          canActivate: [AuthsGuard]
      },
    ]
  },
  {
    path: 'claim',
    loadChildren: () =>
          import("./modules/claim/claim.module").then(
            (m) => m.ClaimModule
          ),
    canActivate: [AuthsGuard]
  },
  {
    path: 'lab',
    loadChildren: () =>
          import("./modules/lab/lab.module").then(
            (m) => m.LabModule
          ),
    canActivate: [AuthsGuard]
  },
  {
    path: "dashboard",
    loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
    canActivate: [AuthsGuard]
  },
  {
    path: "reports2",
    loadChildren: () =>
          import("./modules/reporting/reporting.module").then(
            (m) => m.ReportingModule
          ),
    canActivate: [AuthsGuard]
  },
  {
    path: "settings",
    loadChildren: () =>
          import("./modules/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
    canActivate: [AuthsGuard]
  },
  {
    path: "integrations",
    loadChildren: () =>
          import("./modules/integrations/integrations.module").then(
            (m) => m.IntegrationsModule
          ),
    canActivate: [AuthsGuard]
  },
  {
    path: "masters",
    loadChildren: () =>
          import("./modules/masters/masters.module").then(
            (m) => m.MastersModule
          ),
    canActivate: [AuthsGuard]
  },
  {
    path: "student-workdesk",
    loadChildren: () =>
          import("./modules/student-workdesk/student-workdesk.module").then(
            (m) => m.StudentWorkDeskModule
          ),
    canActivate: [AuthsGuard]
  },

  { path: "**", redirectTo: "/dashboard" },

  // {
  //   path: "",
  //   component: AppLayoutComponent,
  //   children: [

  //   ],
  // },

  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
    AuthModule,
    ClientModule,
    UserPermissionModule,
    TreatmentModule,
    BillingSettlementModule,
    EMRModule,
    ReportsModule,
    ConfigurationsModule,
    NabithModule
  ],
  exports: [RouterModule],
  providers: [AuthsGuard],
})
export class AppRoutingModule { }
