import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sponsor-rule-item-note',
  templateUrl: './sponsor-rule-item-note.component.html',
  styleUrls: ['./sponsor-rule-item-note.component.scss']
})
export class SponsorRuleItemNoteComponent implements OnInit {

  @Input() noteFieldData;
  @Input() action;
  @Output() noteDataEmitter = new EventEmitter();
  noteData: any;

  constructor(
    private activeModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.noteData = this.noteFieldData;
  }

  close(){
    this.activeModalRef.hide();
  }

  addData() {
    this.noteDataEmitter.emit({status: 'AddData', data: this.noteData})
    this.activeModalRef.hide();
  }

}
