import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConsultantDeskService } from '../../../services/consultant.service';
import { Response } from '../../../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-change-fee',
  templateUrl: './change-fee.component.html',
  styleUrls: ['./change-fee.component.scss']
})
export class ChangeFeeComponent implements OnInit {

  @Input() branchId: number;
  @Input() consultationId: number;
  @Input() patientId: number;
  @Input() consultantId: number;
  @Input() consultationDate: string;

  public changeFeeForm: FormGroup;

  private consultationFee: Number = 0;

  constructor(private activeModal: BsModalRef,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private datepipe: DatePipe,
              private consultantService: ConsultantDeskService) { }

  ngOnInit(): void {
    this.initForm();
    this.getFeeDetails();
  }

  initForm(){
    this.changeFeeForm = this.formBuilder.group({
      type: new FormControl(1),
      updatedFee: new FormControl({value: '0.00', disabled: true}),
      discount: new FormControl(0.00),
      discountValue: new FormControl(0.00)
    })
  }

  getFeeDetails(){
    const payload = {
      Consultantid: this.consultantId,
      ConsultationId: this.consultationId,
      PatientId: this.patientId,  
      BranchId: this.branchId,
    }

    this.consultantService.getFeeDetails(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.consultationFee = response.response[0]?.rate;
          this.changeFeeForm.patchValue({
            updatedFee: this.consultationFee,
            discountValue: response.response[0]?.discAmount,
            type: response.response[0]?.discAmount > 0 ? 2 : 1
          })
          if(response.response[0]?.discAmount > 0){
            this.onDiscountInput('value');
          }
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong.. Try again');
      }
    })
  }

  onChangeType(){
    this.changeFeeForm.patchValue({
      discountValue: 0.00,
      discount: 0.00,
      updatedFee: this.consultationFee
    })
    let type = this.changeFeeForm.get('type').value;
    if(type == 1){
      this.changeFeeForm.get('updatedFee').disable();
      this.changeFeeForm.get('updatedFee').clearValidators();
      
      this.changeFeeForm.get('discount').enable();
      this.changeFeeForm.get('discountValue').enable();

    }else{
      this.changeFeeForm.get('discount').disable();
      this.changeFeeForm.get('discount').clearValidators();
      this.changeFeeForm.get('discountValue').disable();
      this.changeFeeForm.get('discountValue').clearValidators();

      this.changeFeeForm.get('updatedFee').enable();
    }

    this.changeFeeForm.get('discount').updateValueAndValidity();
    this.changeFeeForm.get('updatedFee').updateValueAndValidity();
    this.changeFeeForm.get('discountValue').updateValueAndValidity();
  }

  onDiscountInput(type: string){
    let fee = parseFloat(this.changeFeeForm.get('updatedFee').value);
    switch(type){
      case 'percentage': let perc = parseFloat(this.changeFeeForm.get('discount').value);
                         perc = perc > 100 ? 100 : (perc < 0.00 ? 0 : perc)
                         let value = (perc * fee)/100;
                         this.changeFeeForm.patchValue({
                          discountValue: value,
                          discount: perc
                         });
                         break;
      case 'value' : let a = parseFloat(this.changeFeeForm.get('discountValue').value);
                     a = a > fee ? fee : a; 
                     let b = (a * 100)/fee;
                     this.changeFeeForm.patchValue({
                        discount: b,
                        discountValue: a
                     });
                     break;
      default: this.changeFeeForm.patchValue({
                    discount: 0.00,
                    discountValue: 0.00
                });
    }
  }

  close(){
    this.activeModal.hide();
  }

  onChangeFeeClick(){
    const controls = this.changeFeeForm.controls;
    if(this.changeFeeForm.invalid){
      Object.keys(controls).forEach(control=>{
        controls[control].markAllAsTouched()
      });

      return 
    }

    const payload = {
      Consultationid: this.consultationId,
      PatientId: this.patientId,
      ConsultantId: this.consultantId,
      ChangeFee: this.changeFeeForm.get('type').value == 1 ? false : true,
      ApplyDiscount: this.changeFeeForm.get('type').value == 1 ? true : false,
      BranchId: this.branchId,
      DiscAmount: this.changeFeeForm.get('type').value == 1 ? this.changeFeeForm.get('discountValue').value : 0,
      ConsultationFee: this.changeFeeForm.get('type').value == 1 ? 0 : this.changeFeeForm.get('updatedFee').value,
      ConsultDate: this.datepipe.transform(new Date(), 'dd-MM-yyyy')
    }

    this.consultantService.changeConsultationFee(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == "success"){
          this.toastrService.success('Fees changed successfully');
          this.close();
        }else{
          this.toastrService.error(response.errorMessage.message)
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong.. Try again');
      }
    })
  }

}
