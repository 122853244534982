import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { TreatmentsService } from '../services/treatments.service';
import { TreatmentGeneralActionComponent } from "../treatment-general-action/treatment-general-action.component";
import { FormControl, FormGroup } from '@angular/forms';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigurationService } from '../../client/services/configuration.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-treatment-list',
  templateUrl: './treatment-list.component.html',
  styleUrls: ['./treatment-list.component.scss']
})
export class TreatmentListComponent implements OnInit {

  public statusBar;
  rowGroupPanelShow = "always";
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  subscription: Subscription;
  private baseUrl = "";
  isPdfLoaded:boolean=false;
  patientId:number;
  RegNo: any;
  TreatmentId: any;
  treatmentData: any;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  TreatmentDetails: any;
  currentDate: any;
  treatmentFromDate: any;
  TreatmentListform: FormGroup;
  globals: globalvars = new globalvars();
  BranchesId: any;
  src :any= '';
  IsApplyTreatment:number;
  functionPermissionData: any;

  constructor(
    private datepipe: DatePipe,
    private TreatmentsService: TreatmentsService,
    private router: Router,
    private sharedservice: SharedService,
    private configurationManager: ConfigurationManager,
    private sanitizer: DomSanitizer,
    private ConfigurationService: ConfigurationService,
    private toastr: ToastrService,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;

   }

  ngOnInit(): void {

    this.columnDefs = [

      {
        headerName: "Patient Name",
        field: "patientName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },


      {
        headerName: "Reg. No.",
        field: "regNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Staff Name",
        field: "performingStaffName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Mobile No",
        minWidth: 180,
        field: "mobile",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Treatment No.",
        minWidth: 180,
        field: "treatmentNumber",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Treatment Date",
        minWidth: 180,
        field: "treatmentDate",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "treatmentDetailsList" },
      },

    ];


    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: TreatmentGeneralActionComponent,
    };


    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    let dte = new Date();
    dte.setDate(dte.getDate() - 7);
    this.treatmentFromDate = this.datepipe.transform(dte, 'dd-MM-yyyy');;
    let currentappDate = new Date();
    this.currentDate = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');


    this.TreatmentListform = new FormGroup({
      PatientName: new FormControl(""),
      Mobile: new FormControl(""),
      RegNo: new FormControl(''),
      DateFrom: new FormControl(this.treatmentFromDate),
      DateTo: new FormControl(this.currentDate),
      TreatmentNumber: new FormControl(""),

    });
    this.GetTreatmentDetails();
    this.functionPermission();
  }
  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  Activetab(pagetitle) {
    const foundElement = this.functionPermissionData.find(x => x.id === 238)
    if (foundElement.id == 238 && foundElement.isAllowed == true) {
      sessionStorage.setItem("pageTitle", pagetitle);
      this.router.navigate(["/Treatment_details"]);
    } else {
      this.toastr.warning("You are not allowed to add treatment");
    }
  }

  GetTreatmentDetails() {
    let DateFrom = this.TreatmentListform.get("DateFrom").value;
    let DateTo = this.TreatmentListform.get("DateTo").value;
  if (typeof (DateFrom) == "object") {
    DateFrom = this.datepipe.transform(DateFrom, 'dd-MM-yyyy');
    }
    if (typeof (DateTo) == "object") {
      DateTo = this.datepipe.transform(DateTo, 'dd-MM-yyyy');
      this.TreatmentListform.value.DateTo = DateTo;
    }

    let input = {
      "DateFrom": DateFrom,
      "DateTo": DateTo,
      "PatientId": 0,
      "PatientName": this.TreatmentListform.get("PatientName").value,
      "Mobile": this.TreatmentListform.get("Mobile").value,
      "RegNo": this.TreatmentListform.get("RegNo").value,
      "Id": 0,
      "TreatmentNumber": this.TreatmentListform.get("TreatmentNumber").value,
      "branchId":this.globals.HospitalId
    };

    this.TreatmentsService.GetTreatments(input).subscribe(
      (res) => {
        if (res.status == 200) {

          this.TreatmentDetails = res.response;
          this.patientId=this.TreatmentDetails.itemDetails.patientId;
        }
      });
  }
  gotoedit(row) {
    const foundElement = this.functionPermissionData.find(x => x.id === 240)
    if (foundElement.id == 240 && foundElement.isAllowed == true) {
    this.IsApplyTreatment=0
    // this.router.navigateByUrl('/Treatment_details', { state: { id:row.data.id } });
    this.router.navigate(['/Treatment_details'], { state: { id: row.id ,consultationId:row.consultationId,IsApplyTreatment:this.IsApplyTreatment} });
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  printTreatment(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 241)
    if (foundElement.id == 241 && foundElement.isAllowed == true) {
    let input=this.TreatmentListform.getRawValue();
    let DateFrom = this.TreatmentListform.get("DateFrom").value;
    let DateTo = this.TreatmentListform.get("DateTo").value;
  if (typeof (DateFrom) == "object") {
    DateFrom = this.datepipe.transform(DateFrom, 'dd-MM-yyyy');
    input.DateFrom = DateFrom;

    }
    if (typeof (DateTo) == "object") {
      DateTo = this.datepipe.transform(DateTo, 'dd-MM-yyyy');
      input.DateTo = DateTo;

    }
    input.branchId = Number(this.globals.HospitalId);
    input.patientId=data.patientId;
    this.TreatmentsService.PrintTreatment(input).subscribe(
      (res) => {
        if(res.status==200){
          let response=res.response;
          this.src=response[0].reportPath;
          this.src=this.baseUrl+this.src;
          // this.src=this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
          // this.isPdfLoaded=true;
          window.open(this.src, "_blank");

        }
  
      });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  reportWorkLIst(data) {
    // console.log(data)
    const foundElement = this.functionPermissionData.find(x => x.id === 587)
    if (foundElement.id == 587 && foundElement.isAllowed == true) {
      let payload={
        patientId:data.patientId,
        consultationId:data.consultationId,
        orderDetId:data.orderDetId
      }
      localStorage.setItem("reportWorkListData",JSON.stringify(payload))
    this.router.navigate(['/ServiceList/' + data.id + '/report/', 0]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }


}
