import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { da } from 'date-fns/locale';
@Component({
  selector: 'app-billing-general-action',
  templateUrl: './billing-general-action.component.html',
  styleUrls: ['./billing-general-action.component.scss']
})
export class BillingGeneralActionComponent implements ICellRendererAngularComp {
  public params: any;
  public pagetype: string;
  items: any[] = [];
  constructor(private router: Router,
              private toastr: ToastrService) { }


  agInit(params: any): void {
    this.params = params;
    this.pagetype = this.params.PageType;
    if (this.pagetype == "BillingList") {
      this.items = [
        {
          label: "Edit Bill",
          command: (x: any) => {
            let data = 1;
            let details = this.params.data;
            this.params.context.componentParent.EditBill(data,details);
          },
        },
        { separator: true },
        {
          label: "ViewBill",
          command: (x: any) => {

          },
        },
        { separator: true },
        {
          label: "Print UpFront",
          command: (x: any) => {

          },
        },
        { separator: true },
        {
          label: "Print Invoice",
          command: (x: any) => {

          },
        },
        { separator: true },
        {
          label: "Print Bill",
          command: (x: any) => {

          },
        },
        { separator: true },
        {
          label: "Cancel Settlement",
          command: (x: any) => {

          },
        },
        { separator: true },
        {
          label: "Cancel Bill",
          command: (x: any) => {

          },
        },
        { separator: true },
        {
          label: "Change Approval No.",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.openModalChangeApprovalNo(data);
          },
        },
        { separator: true },
        {
          label: "Send Email/SMS",
          command: (x: any) => {

          },
        },
        { separator: true },
      ];

    }
    else if(this.pagetype == "manageCredit"){
      this.items = [
        { separator: true },
        {
          label: "Edit Credit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.editCredit(data);
          },
          // disabled: this.params.data.active == 0 ? true : false
        },
        { separator: true },
        {
          label: "Close Credit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.closeCredit(data);
          },
           disabled: this.params.data.active == 0 ? true : false
        },
        { separator: true },
        // {
        //   label: "Transaction List"
        // },
        {
          label: "Transaction List",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.viewTransaction(data);
          },
        },
        { separator: true },
        {
          label: "Set Credit As Default",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.setCreditAsDefault(data);
          },
          disabled: this.params.data.active == 0 ? true : false
        },
        { separator: true },
        {
          label: "Approve Credit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.approveCredit(data);
          },
          disabled: this.params.data.active == 0 ? true : false
        },

      ]
    }
    else if(this.pagetype == "TodaysBilling"){
      this.items = [
        {
          label: "External Items",
          command: (x: any) => {
            let details = this.params.data;
            this.params.context.componentParent.externalItems(details);
            // sessionStorage.setItem('bill-patient-regNo', JSON.stringify({regNo: details?.regNo,
            //                                                              consulatantID: details?.consultantId,
            //                                                              creditId: details?.creditId,
            //                                                              consulatationId: details?.consultationId}));
                                                              
            // let external = {
            // isExternalStatus: true
            // }
            // sessionStorage.setItem('isExternalLabBill', JSON.stringify(external));
            // this.router.navigate(['../newbill']);
          },

        },
        { separator: true },

        {
          label: "Change Consultation Credit",
          command: (x: any) => {
            let details = this.params.data;
            this.params.context.componentParent.changeConsultationCredit(details);
          },

        },
        { separator: true },
        {
          label: "Open Credit",
          command: (x: any) => {
            let details = this.params.data;
            this.params.context.componentParent.onOpenCredit(details);
          },

        },
        { separator: true },
        {
          label: "Edit Credit",
          command: (x: any) => {
            let details = this.params.data;
            this.params.context.componentParent.editCredit(details);
          },

        },
        { separator: true },
        {
          label: "New Bill",
          command: (x: any) => {
            let details = this.params.data;
            this.params.context.componentParent.onActionNewBill(details);
            // sessionStorage.setItem('bill-patient-regNo', JSON.stringify({regNo: details?.regNo,
            //                                                              consulatantID: details?.consultantId,
            //                                                              creditId: details?.creditId,
            //                                                              consulatationId: details?.consultationId}));
            // this.router.navigate(['../newbill']);
          },

        },
        { separator: true },
        {
          label: "New Receipts",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onNewReciept(data);
          },
        },
        { separator: true },
        {
          label: "New Payments",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onNewPayment(data);
          },
        },
        { separator: true },

      ];
    }

    else if (this.pagetype == "ManageLOC") {
      this.items = [
        {
          label: "Move Item",
          command: (x: any) => {
            let data = this.params.data;
           // this.params.context.componentParent.openModalChangeApprovalNo(data);
          },
        },
        { separator: true },

      ];

    }

   else if (this.pagetype == "NewBillAdd") {
      this.items = [
        {
          label: "Edit Credit",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.editCredit(data);
          },
        }

      ];

    }
    else if(this.pagetype=="manageClaim"){
      this.items = [
        {
          label: "Create Test XML",
        },
        {
          label: "Create Production XML",
        },
        {
          label: "Create Test Investigation XML",
        },
        {
          label: "Create Production Investigation XML",
        },
        { separator: true },
        
          {
            label: "Create Resubmition",
            command: (x: any) => {
              let data = this.params.data;
             this.params.context.componentParent.Resubmission(data);
            },
          },
          { separator: true },
  
        
        { separator: true },
        {
          label: "Create Test XML (NS Code)",
        },
        {
          label: "Create Production XML (NS Code)",
        },
        { separator: true },
        {
          label: "Online Submission",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.onlineSubmission(data);
          },
        },
        {
          label: "Claim Receipt",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.claimReceipt(data);
          },
        },
        { separator: true },

        { separator: true },
        {
          label: "Edit Claim",
        },
        {
          label: "Cancel Claim",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.cancelItem(data);
          },
        },
      ];
    }
    else if(this.pagetype=="manageReceipt"){
      this.items = [
        {
          label: "Edit Receipt",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.editReceipt(data);
          },
          disabled: this.params.data.status == "C" ? true : false,

        },
        { separator: true },
        {
          label: "Cancel Receipt",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.cancelItem(data);
          },
          disabled: this.params.data.status == "C" ? true : false,

        },
        { separator: true },
        {
          label: "Print Receipt",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.printReceipt(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
      ]
    }
    else if(this.pagetype=="managePayment"){
      this.items = [
        {
          label: "Edit Payment",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.editPayment(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },
        {
          label: "Cancel Payment",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.cancelItem(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },
        {
          label: "Print Payment",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.printPayment(data);
          },
          disabled: this.params.data.status == "C" ? true : false,

        },
      ]
    }
    else if(this.pagetype=="manageprior"){
      this.items = [
        {
          label: "Lock Cform",
        },
        { separator: true },
        {
          label: "Prior Request",
        },
        { separator: true },
        {
          label: "Print",
        },
        { separator: true },
        {
          label: "Download Authorization",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.Downloadeprior(data);
          },
        },
      ]
    }
    else if(this.pagetype=="pendingBills"){
      this.items = [
        {
          label: "Print Bill",
        },
        { separator: true },
      ]
    }
    else if(this.pagetype=="manageRemittance"){
      this.items = [
        {
          label: "Cancel ",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.cancelItem(data);
          },
        },
        { separator: true },
        {
          label: "Create Resubmition",
          command: (x: any) => {
            let data = this.params.data;
           this.params.context.componentParent.Resubmission(data);
          },
        },
        { separator: true },

      ]
    }
    else if(this.pagetype=="billDetails"){
      console.log('bills');
      this.items = [
        {
          label: "Settlement",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onSettlement(data);
            // if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
            //   this.params.context.componentParent.openSettlmentScreen(data);
            // }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
            //   this.toastr.error('This bill is already settled..');
            // }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
            //   this.toastr.error('This bill is cancelled..');
            // }
          },
          disabled: this.params.data.status.toLowerCase() === 'resettled' ? true : false
        },
        { separator: true },
        {
          label: "Receipts",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onNewRecieptBill(data);
          },
        },
        { separator: true },
        {
          label: "Print UpFront",
          command: (x: any) => {
            let data = this.params.data;
            // if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
            //   this.toastr.error('This bill is not settled yet..');
            // }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
            //   this.params.context.componentParent.onPrintInvoice(data);
            // }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
            //   this.toastr.error('This bill is cancelled..');
            // }
            this.params.context.componentParent.onPrintUpFront(data);
          },
        },
        { separator: true },
        {
          label: "View / Print Bill",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onViewPrintBill(data);
            // let patientData = {
            //   patientData: data,
            //   OtherData: null
            // }
            // sessionStorage.setItem('billDetails', JSON.stringify(patientData));
            // sessionStorage.setItem('viewOnly', JSON.stringify({status: true}));
            // this.router.navigate(['../billitems/'+data.transId],{queryParams: {returnUrl: 'billdetails'}});
          }
        },
        { separator: true },
        {
          label: "Print Invoice",
          command: (x: any) => {
            let data = this.params.data;
            // if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
            //   this.toastr.error('This bill is not settled yet..');
            // }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
            //   this.params.context.componentParent.onPrintInvoice(data);
            // }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
            //   this.toastr.error('This bill is cancelled..');
            // }
            this.params.context.componentParent.onPrintInvoice(data);
          },
        },
        { separator: true },
        {
          label: "Cancel Settlement",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelSettlement(data);
            // if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
            //   this.toastr.error('This bill is not settled yet..');
            // }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
            //   this.params.context.componentParent.openCancelSettlmentModal(data);
            // }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
            //   this.toastr.error('This bill is already cancelled..');
            // }
          },
          // disabled: this.params.data.status.toLowerCase() === 'resettled' ? true : false
        },
        { separator: true },

        {
          label: "Cancel Bill ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.cancelBill(data);
            // if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
            //   this.params.context.componentParent.cancelItem(data);
            // }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
            //   this.toastr.error('This bill is already settled, Cancel settlement first to cancel bill..');
            // }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
            //   this.toastr.error('This bill is already cancelled..');
            // }
          },
          disabled: (this.params.data.status == "Settled" || this.params.data.status == 'ReSettled') ? true : false,

        },
        { separator: true },
        {
          label: "Edit Bill",
          command: (x: any) => {
           let data = this.params.data;
           this.params.context.componentParent.onActionEditBill(data);
          //  if( data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
          //     this.toastr.error('Settled bills are not allowed to edit..');
          //  }else if( data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c' ){
          //   this.toastr.error('Cancelled bills are not allowed to edit..');
          // }else{
          //   // let patientData = {
          //   //   patientData: data,
          //   //   OtherData: null
          //   // }
          //   // sessionStorage.setItem('billDetails', JSON.stringify(patientData));
          //   // this.router.navigate(['../billitems/'+data.transId], {queryParams: {returnUrl: 'billdetails'}});
          //   this.params.context.componentParent.onEditBill(data);
          //  }
          },
          disabled: this.params.data.status.toLowerCase() === 'resettled' ? true : false
        },
        { separator: true },
        {
          label: "Change Approval Number",
          command: (x: any) => {
           let data = this.params.data;
           this.params.context.componentParent.onChangeApproval(data);
          },
        },
        { separator: true },
        {
          label: "Resettlement",
          command: (x: any) => {
           let data = this.params.data;
           this.params.context.componentParent.reSettlement(data);
          },
          disabled: this.params.data.status.toLowerCase() !== 'settled' ? true : false
        },

      ]
    }
    else if(this.pagetype=="externalBillDetails"){
      this.items = [
        {
          label: "Settlment",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onLabSettlement(data);
            // if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
            //   this.params.context.componentParent.openSettlmentScreen(data);
            // }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
            //   this.toastr.error('This bill is already settled..');
            // }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
            //   this.toastr.error('This bill is cancelled..');
            // }
          },
        },
        { separator: true },
        {
          label: "Receipts",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onNewRecieptBill(data);
          },
        },
        { separator: true },
        {
          label: "Print UpFront",
          command: (x: any) => {
            let data = this.params.data;
            // if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
            //   this.toastr.error('This bill is not settled yet..');
            // }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
            //   this.params.context.componentParent.onPrintInvoice(data);
            // }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
            //   this.toastr.error('This bill is cancelled..');
            // }
            this.params.context.componentParent.onPrintUpFront(data);
          },
        },
        { separator: true },
        {
          label: "View / Print Bill",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onViewOrPrintBill(data);
            // let patientData = {
            //   patientData: data,
            //   OtherData: null,
            //   isExternal: true
            // }
            // sessionStorage.setItem('billDetails', JSON.stringify(patientData));
            // sessionStorage.setItem('viewOnly', JSON.stringify({status: true}));
            // this.router.navigate(['../billitems/'+data.transId],{queryParams: {returnUrl: 'external-lab-bills'}});
          }
        },
        { separator: true },
        {
          label: "Print Invoice",
          command: (x: any) => {
            let data = this.params.data;
            // if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
            //   this.toastr.error('This bill is not settled yet..');
            // }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
            //   this.params.context.componentParent.onPrintInvoice(data);
            // }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
            //   this.toastr.error('This bill is cancelled..');
            // }
            this.params.context.componentParent.onPrintInvoice(data);
          },
        },
        { separator: true },
        {
          label: "Cancel Settlement",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onCancelSettlement(data);
            // if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
            //   this.toastr.error('This bill is not settled yet..');
            // }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
            //   this.params.context.componentParent.openCancelSettlmentModal(data);
            // }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
            //   this.toastr.error('This bill is already cancelled..');
            // }
          },
        },
        { separator: true },

        {
          label: "Cancel Bill ",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onCancelBill(data);
            // if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
            //   this.params.context.componentParent.cancelItem(data);
            // }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
            //   this.toastr.error('This bill is already settled, Cancel settlement first to cancel bill..');
            // }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
            //   this.toastr.error('This bill is already cancelled..');
            // }
          },
        },
        { separator: true },
        {
          label: "Edit Bill",
          command: (x: any) => {
           let data = this.params.data;
           this.params.context.componentParent.onLabEditBill(data);
          //  if( data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
          //     this.toastr.error('Settled bills are not allowed to edit..');
          //  }else if( data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c' ){
          //   this.toastr.error('Cancelled bills are not allowed to edit..');
          // }else{
          //   let patientData = {
          //     patientData: data,
          //     OtherData: null,
          //     isExternal: true
          //   }
          //   sessionStorage.setItem('billDetails', JSON.stringify(patientData));
          //   this.router.navigate(['../billitems/'+data.transId], {queryParams: {returnUrl: 'external-lab-bills'}});
          //  }
          },
        },
        { separator: true },
        {
          label: "Change Approval Number",
          command: (x: any) => {
           let data = this.params.data;
           this.params.context.componentParent.onChangeApproval(data);
          },
        },

      ]
    }else if(this.pagetype == 'manageShift'){
      this.items = [
        {
          label: "Close Shift",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.openmodalforcloseshift(data);
          },
          disabled: this.params.data.status.toLowerCase() == 'closed' ? true : false
        },
      ]
    }

  }
  CheckedValuesBill(isCheckCons) {
    if (this.pagetype == "NewBillAdd") {
      if (isCheckCons == true) {

        this.params.context.componentParent.sponsorCheckingList(this.params.data, "checked");

      }
      else {

        this.params.context.componentParent.sponsorCheckingList(this.params.data, "unchecked");
      }
    }
  }


  refresh(): boolean {
    return false;
  }

  CheckingPendingItems(isChecked){
    if (this.pagetype == "PendingItems") {
      if (isChecked == true) {

        this.params.context.componentParent.pendingItemsCheckingList(this.params.data, "checked");
      }
      else {

        this.params.context.componentParent.pendingItemsCheckingList(this.params.data, "unchecked");
      }
    }
  }


}



