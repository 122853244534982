import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, GridApi } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../client/general-action/general-action.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from "ngx-toastr";
import { ConfigurationService } from "../../../client/services/configuration.service";
import { ResultForm } from '../../../client/interfaces/ResultForm';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forEach } from 'lodash';

@Component({
  selector: 'app-assignservice',
  templateUrl: './assignservice.component.html',
  styleUrls: ['./assignservice.component.scss']
})
export class AssignserviceComponent implements OnInit {
  @Input() BranchesId: any
  @Input() ServicePointId: any
  @Output() getitemEmitter = new EventEmitter()


  @Output() resultEmitter = new EventEmitter()


  @ViewChild("template") modaltemp: any;
  ItemIds: any[] = [];
  resultForm: FormGroup;
  DropdownSettings: IDropdownSettings = {};
  itemList: any;
  public modalColDef: ColDef;
  modalcolumnDefs: ColDef[] = [];
  selectedItem: ColDef[] = [];
  ServicesGroups: any;
  ServicesGroupsData:any[] = [];
  serviceItemBasket: any[] = [];
  rowGroupPanelShow = "always";
  searchTextGroups: '';

  public context;
  public gridApi;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 8,
    rowClassRules: {
      // 'c-reds': function (params) { return params.data.active == '0'; },
      // 'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    }

  }
  OriginalServiceGroups: any[];

  public submitted: boolean = false;
  groupId: Number
  addedItem: any[] = [];
  selectedTempItem: any[] = [];
  sourceOriginal: any;
  selectedFiles: any;
  EventId: any
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  public itemGridApi: GridApi;
  sourceProducts: any;
  isLoading = false;
  result: any;

  constructor(private modalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private configurationService: ConfigurationService,) { }

  ngOnInit(): void {
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `<span">no rows to show</span>`;

    this.resultForm = new FormGroup({
      listid: new FormControl(0),
      searchTextGroups: new FormControl(''),

    });
    this.modalcolumnDefs = [

      {
        headerName: "Item Name",
        field: "itemName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
    ]
    this.selectedItem = [
      {
        headerName: "Item Name",
        field: "itemName",
        minWidth: 150,
        // maxWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
      {
        headerName: "Remove",
        maxWidth: 80,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "AssignSerive" },

      },

    ]

    this.modalColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.context = { componentParent: this };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.getServiceGroupItems()
    this.GetAssignServiceItem()
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.hideOverlay()
  }
  onGridReadyItem(params) {
    this.itemGridApi = params.api;
    this.itemGridApi.hideOverlay()
  }
  onFilterTextBoxChangedBill(event) {
    this.gridApi.setQuickFilter(
      event.target.value
    );
  }
  onFilterTextBoxChangeditem(event) {
    this.itemGridApi.setQuickFilter(
      event.target.value
    );
  }

  close() {
    this.modalRef.hide();
  }
  getServiceGroupItems() {
    let input = {

      "BranchId": this.BranchesId,
      "PageCode": "SO"

    }
    this.configurationService.getServiceGroupItems(input).subscribe((res) => {
      this.ServicesGroups = res.response;
      this.OriginalServiceGroups = res.response;

    });
  }
  getAssignServicePoint(data) {
    this.groupId = data.node.groupId

    let input = {
      BranchId: this.BranchesId,
      ServicePointId: this.ServicePointId,
      GroupIdList: [data.node.groupId]
    }
    this.configurationService.getAssignSerrvice(input).subscribe((res) => {
      this.ServicesGroupsData = res.response;
      // this.OriginalServiceGroups = res.response;

    });
    this.selectedFiles = this.selectedFiles.filter((oppdata) => oppdata.groupId == this.EventId);

  }
  nodeUnselect(event) {
    this.ServicesGroups = [];
  }
  SelectAll() {
    const combinedArray = [...this.addedItem, ...this.ServicesGroupsData];
  const uniqueArray = combinedArray.reduce((acc, item) => {
  const key = item.itemId; // Replace 'propertyToCheck' with the actual property you want to check for uniqueness
  if (!acc[key]) {
    acc[key] = item;
  }
  return acc;
}, {});

this.addedItem = Object.values(uniqueArray);
    console.log(this.addedItem)
    // this.addedItem = this.ServicesGroupsData
    this.ServicesGroupsData = []
  }
  onCellClicked(Eventdata) {
    
    let index = this.ServicesGroupsData.findIndex(x => x.itemId === Eventdata.data.itemId);
    let removeData = this.ServicesGroupsData.splice(index, 1);
    let index2 =  this.addedItem.find(x => x.itemId === Eventdata.data.itemId);    
    if (index2==undefined) {
      removeData[0].groupid=this.groupId
      this.addedItem.push(removeData[0])
      console.log(removeData[0]);

    }else{
      this.toastr.warning("Item Is Already Added");

    }
    this.gridApi.setRowData(this.ServicesGroupsData);
    this.itemGridApi.setRowData(this.addedItem);

  }
  removeitem(data) {
    
    let index = this.addedItem.findIndex(x => x.itemId === data.itemId);
    let removeData = this.addedItem.splice(index, 1);
    // if(removeData[0].groupid==this.groupId){
      this.ServicesGroupsData.push(removeData[0])

    // }
    this.gridApi.setRowData(this.ServicesGroupsData);
    this.itemGridApi.setRowData(this.addedItem);

  }
  save() {
    this.submitted = true;
    if (this.submitted == true && this.resultForm) {
      this.ItemIds = this.addedItem.map(items => { return items.itemId });
      
      let input = {
        ServicePointId: this.ServicePointId,
        ItemIds: this.ItemIds,
        groupId: this.groupId
      }
      if(this.ItemIds.length==0){
        this.toastr.warning("Please Select Item");

      }
   else{
      this.isLoading = true;
      this.configurationService.assignServiceItem(input).subscribe((res) => {
        this.submitted = false;

        this.result = res;

        if (this.result.status == 200 && this.result.message == 'Success') {
          this.isLoading = false;
          this.toastr.success("Service Item Assigned successfully");

          this.close();
          this.getitemEmitter.emit({ status: true });
        }
        else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }
  }
  }

  GetAssignServiceItem() {
    let input = {
      BranchId: this.BranchesId,
      ServicePointId: this.ServicePointId,
      GroupIdList: []
    }
    this.configurationService.getAssignSerrvice(input).subscribe((res) => {
      let editAssignItem = []
      editAssignItem = res.response;
      this.addedItem = editAssignItem.filter(x => x.isSaved !== 0);
    });

  }
  onFilterServiceGroups() {
    let serviceGroups = this.resultForm.get("searchTextGroups").value;
    let selectAvailbaleService1 = this.OriginalServiceGroups.filter(function (availservice) {

      if (availservice.label.toLocaleLowerCase().indexOf(serviceGroups.toLocaleLowerCase()) !== -1) {

        return availservice;
      }

    }

    );
    this.ServicesGroups = selectAvailbaleService1;
  }

}
