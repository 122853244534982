import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ConfigurationsRoutingModule } from "./configurations-routing.module";
import { ConfigurationsComponent } from "./configurations.component";
import { SharedModule } from "../../shared/models/shared.module";
import { AgGridModule } from "ag-grid-angular";
import { CommunicationConfigComponent } from "./communication-config/communication-config.component";

import { ZonesComponent } from "./zones/zone.component";
import { branchesComponent } from "./branches/branches.component";
import { UsermanagementComponent } from "./usermanagement/usermanagement.component";
import { UserRightsComponent } from "./userrights/userrights.component";

import { consultantComponent } from "./consultant/consultant.component";
import { itembaseComponent } from "./consultant/itembasecount/itembase.component";
import { addconsultantComponent } from "./consultant/addconsultant/addconsultant.component";
import { OperatorComponent } from "./operator/operator.component";
import { countryComponent } from "./country/country.component";
import { CompanyComponent } from "./company/company.component";
import { ProfessionComponent } from "./profession/profession.component";
import { ConsentFormComponent } from "./consentform/consentform.component";
import { LeadAgentComponent } from "./LeadAgent/LeadAgent.component";
import { CpctCodeComponent } from "./cpctcode/cpctcode.component";
import { RateGroupComponent } from "./rategroup/rategroup.component";
import { CPTModifierComponent } from "./cptmodifier/cptmodifier.component";
import { DepartmentComponent } from "./department/department.component";
import { serviceItemsComponent } from "./serviceItems/serviceItems.component";
import { TaxComponent } from "./tax/tax.component";
import { ItemBaseCostComponent } from "./itembasecost/itembasecost.component";
import { SketchIndicatorComponent } from "./sketchindicator/sketchindicator.component";
import { consultantworkingdaysComponent } from "./consultant/consultantworkingdays/consultantworkingdays.component";
import { CategoryComponent } from "./category/category.component";
import { IcdlabelComponent } from "./icdlabel/icdlabel.component";
import { SymptomComponent } from "./symptom/symptom.component";
import { SubLocationSettingsComponent } from "./sub-location-settings/sub-location-settings.component";
import { PackageAndProfileComponent } from "./package-and-profile/package-and-profile.component";
import { PhysicalExaminationSettingsComponent } from "./physical-examination-settings/physical-examination-settings.component";
import { CurrencyManagementComponent } from "./CurrencyManagement/CurrencyManagement.component";
import { SketchConfigurationComponent } from "./sketch-configuration/sketch-configuration.component";

import { drugsManagementComponent } from "./drugsManagement/drugsManagement.component";
import { LedgerSettingsComponent } from "./ledger-settings/ledger-settings.component";
import { VitalSymptomsComponent } from "./vital-symptoms/vital-symptoms.component";
import { SponsorAndSubagentComponent } from "./sponsor-and-subagent/sponsor-and-subagent.component";
import { SponsorRulesComponent } from "./sponsor-and-subagent/sponsor-rules/sponsor-rules.component";
import { SponsorFormComponent } from "./sponsor-form/sponsor-form.component";

import { TableModule } from "primeng/table";
import { PaginationModule, PaginationConfig } from "ngx-bootstrap/pagination";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { QuestionmasterComponent } from "./questionmaster/questionmaster.component";
import { StaffComponent } from "./staff/staff.component";
import { CommissionRulesComponent } from "./commission-rules/commission-rules.component";
import { SavingschemeComponent } from "./savingscheme/savingscheme.component";
import { BodyPartsComponent } from "./bodyparts/bodyparts.component";
import { NumberSettingsComponent } from "./number-settings/number-settings.component";
import { PrintSponserFormComponent } from "./print-sponser-form/print-sponser-form.component";
import { SettingsComponent } from "./settings/settings.component";
import { ReportWorklistComponent } from "./report-worklist/report-worklist.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { AssignTemplateModalComponent } from "./modals/assign-template-modal/assign-template-modal.component";
import { AddEditTemplateComponent } from "./add-edit-template/add-edit-template.component";
import { ResultFormComponent } from "./result-form/result-form.component";
import { ResultModalComponent } from "./modals/result-modal/result-modal.component";
import { ResultAddParameterComponent } from "./modals/result-addParameter/result-addParameter.component";
import { ResultparameterComponent } from "./modals/result-parameter/result-parameter.component";
import { AssignREsultFormComponent } from "./modals/assign-resultform/assign-resultform.component";
import { ExternallabComponent } from "./externallab/externallab.component";
import { SampleTyeComponent } from "./sample-tye/sample-tye.component";
import { AddEditExternalLabComponent } from "./modals/add-edit-external-lab/add-edit-external-lab.component";
import { PreviewResultFormComponent } from "./modals/preview-result-form/preview-result-form.component";
import { ResultPrintComponent } from "./resultPrint/resultPrint.component";
import { CFormOrderModalComponent } from "./consultant/c-form-order-modal/c-form-order-modal.component";
import { AssignServiceModalComponent } from "./modals/assignService-modal copy/assignService-modal.component";
import { AssignserviceComponent } from "./modals/assignservice/assignservice.component";
import { ShowQrcodeModalComponent } from "./staff/show-qrcode-modal/show-qrcode-modal.component";
import { QRCodeModule } from "angularx-qrcode";
import { ExcelDataFetchModalComponent } from "./modals/excel-data-fetch-modal/excel-data-fetch-modal.component";
import { ExpiredDrugsModalComponent } from "./modals/expired-drugs-modal/expired-drugs-modal.component";
import { DesignationComponent } from './designation/designation.component';
import { ViewStaticPatientConsentComponent } from './consentform/view-static-patient-consent/view-static-patient-consent.component';
import { FormsModule } from "@angular/forms";
import { SponsorConsultantFeeComponent } from './sponsor-and-subagent/sponsor-consultant-fee/sponsor-consultant-fee.component';
import { CopySponsorConsultantFeeComponent } from './modals/copy-sponsor-consultant-fee/copy-sponsor-consultant-fee.component';
import { SponsorRuleItemNoteComponent } from './modals/sponsor-rule-item-note/sponsor-rule-item-note.component';
import { PreviewMultipleResultFormComponent } from './modals/preview-multiple-result-form/preview-multiple-result-form.component';

import { ResultFormHeadersComponent } from './modals/result-form-headers/result-form-headers.component';
import { AddResultFormHeaderComponent } from './modals/add-result-form-header/add-result-form-header.component';

@NgModule({
  declarations: [
    ConfigurationsComponent,
    CommunicationConfigComponent,
    branchesComponent,
    UsermanagementComponent,
    UserRightsComponent,
    consultantComponent,
    itembaseComponent,
    addconsultantComponent,
    OperatorComponent,
    ZonesComponent,
    countryComponent,
    CompanyComponent,
    ProfessionComponent,
    ConsentFormComponent,
    LeadAgentComponent,
    DepartmentComponent,
    CpctCodeComponent,
    RateGroupComponent,
    CPTModifierComponent,
    serviceItemsComponent,
    TaxComponent,
    ItemBaseCostComponent,
    SketchIndicatorComponent,
    consultantworkingdaysComponent,
    CategoryComponent,
    IcdlabelComponent,
    SymptomComponent,
    SubLocationSettingsComponent,
    PackageAndProfileComponent,
    PhysicalExaminationSettingsComponent,
    CurrencyManagementComponent,
    SketchConfigurationComponent,

    drugsManagementComponent,
    LedgerSettingsComponent,
    VitalSymptomsComponent,
    SponsorAndSubagentComponent,
    SponsorRulesComponent,
    SponsorFormComponent,
    QuestionmasterComponent,
    StaffComponent,
    CommissionRulesComponent,
    SavingschemeComponent,
    BodyPartsComponent,
    NumberSettingsComponent,
    PrintSponserFormComponent,
    SettingsComponent,
    ReportWorklistComponent,
    AssignTemplateModalComponent,
    AddEditTemplateComponent,
    ResultFormComponent,
    ResultModalComponent,
    ResultAddParameterComponent,
    ResultparameterComponent,
    AssignREsultFormComponent,
    ExternallabComponent,
    SampleTyeComponent,
    AddEditExternalLabComponent,
    PreviewResultFormComponent,
    ResultPrintComponent,
    CFormOrderModalComponent,
    AssignServiceModalComponent,
    AssignserviceComponent,
    ShowQrcodeModalComponent,
    ExcelDataFetchModalComponent,
    ExpiredDrugsModalComponent,
    DesignationComponent,
    ViewStaticPatientConsentComponent,
    SponsorConsultantFeeComponent,
    CopySponsorConsultantFeeComponent,
    SponsorRuleItemNoteComponent,
    PreviewMultipleResultFormComponent,
    ResultFormHeadersComponent,
    AddResultFormHeaderComponent,
  ],
  imports: [
    CommonModule,
    ConfigurationsRoutingModule,
    SharedModule,
    AgGridModule.withComponents(),
    TableModule,
    PaginationModule,
    TypeaheadModule,
    CKEditorModule,
    FormsModule,
    QRCodeModule,
  ],
})
export class ConfigurationsModule {}
