import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CountryList } from "../../client/interfaces/CountryList";
import { AppointmentsService } from "../../client/services/appointments.service";
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { OperatorService } from "../../client/services/operator.service";
import { ToastrService } from "ngx-toastr";
import { FormValidationService } from "../../client/services/form-validation.service";
import { ConfigurationService } from "../../client/services/configuration.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Observable, Observer, Subscription, noop, of } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { Select2OptionData } from "ng-select2";
import { map, switchMap, tap } from "rxjs/operators";
import { Response } from "../../../_Models/Response";
import { HttpClient } from "@angular/common/http";
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";
import { ExcelDataFetchModalComponent } from "../modals/excel-data-fetch-modal/excel-data-fetch-modal.component";
import { ExpiredDrugsModalComponent } from "../modals/expired-drugs-modal/expired-drugs-modal.component";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-drugsManagement",
  templateUrl: "./drugsManagement.component.html",
  styleUrls: ["./drugsManagement.component.scss"],
})
export class drugsManagementComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;
  supervisor = "";
  locationname = "";
  Mobilenumber = "";
  public submitted: boolean = false;
  public submitted1: boolean = false;
  public submitted2: boolean = false;
  public submitted3: boolean = false;
  public submitted4: boolean = false;
  public submitted5:boolean=false
  countryList: CountryList[] = [];
  currentPage:number=1;

  form: any;
  result: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  ScientificName: boolean = true;
  CountryId: any;
  DrugModal: boolean;
  TradeName: boolean;
  Drug: boolean;
  Dosage: boolean;
  TradeListRows:number=0;
  Frequency: boolean;
  ScientificcolumnDefs = [];
  TradeListcolumnDefs = [];
  DrugListcolumnDefs = [];
  DosagecolumnDefs = [];
  FrequencycolumnDefs = [];
  public gridApi;
  public gridApiTrade;
  public gridApiDrug;
  public gridApiDosage;
  public gridApiFrequency;
  public gridApiConsumption
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  public gridOptionsTradeList = {
    pagination: true,
    paginationPageSize: 20,
  };
  public gridOptionsDrugList = {
    pagination: true,
    paginationPageSize: 20,
  };

  totalsctficItems=0;

  GetTradeNameInput={
     "TradeId": 0,
      "ShowAll": 1,
      "BranchId": this.globals.HospitalId,
      "PageNumber":1,
      "TradeName":""
  };

  GetDrugsInput={
    "DrugId": 0,
    "DrugTypeId": 0,
    "BranchId": this.globals.HospitalId,
    "PageNumber":1,
    "FilterText":"",
    "ShowAll": 1,
  };

  GetSctficNameInput={
    "ScientificId":0,
    "ShowAll":1,
    "BranchId":this.globals.HospitalId,
    "SearchText":"",
    "PageNumber":1
  };


  private gridColumnApi;
  public rowSelection = true;
  public signdefaultColDef: ColDef;
  public vitalsigndefaultColDef: ColDef;
  public symptomdefaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public ScientificForm: FormGroup;
  public TradeNameForm: FormGroup;
  public DrugForm: FormGroup;
  public DosageForm: FormGroup;
  public FrequencyForm: FormGroup;
  public ConsumptionForm :FormGroup;
  ScientficModal: boolean;
  DisplayedId: any;
  IsDisplayed: any;
  TradeNameModal: boolean;
  FrequencyModal: boolean;
  DosageModal: boolean;
  GetScientificNameList: any;
  StateId: any;
  CityList: any;
  CityModal: boolean;
  CityId: any;
  Zones: any;
  ScientificId: any;
  scientificId: any;
  IsDeleted: any;
  TradeList: any;
  Routes: any;
  GetDrugList: any;
  GetDrugTypeList: any;
  tradeId: any;
  drugId: any;
  GetDosageList: any;
  dosageId: any;
  GetFrequencyList: any;
  freqId: any;
  modalRefScientific: BsModalRef;
  modalRefTrade: BsModalRef;
  modalRefDrug: BsModalRef;
  modalRefDose: BsModalRef;
  modalRefFrequency: BsModalRef;
  ScientificNameData: Array<Select2OptionData> = [];
  RoutesData: Array<Select2OptionData> = [];
  ZonesData: Array<Select2OptionData> = [];
  IsScientificName:boolean = true;
  IsTradeName:boolean = true;
  IsDrugName:boolean = true;
  IsDosage:boolean = true;
  IsFrequency:boolean = true;
  DrugScientificNamePopupHeading:String="Add Scientific Name";
  DrugTradeNamePopupHeading:String="Add Trade Name";
  DrugsPopupHeading:String="Add Drugs Name";
  DrugDosagePopupHeading:String="Add Dosage Name";
  DrugFrequencyPopupHeading:String="Add Frequency Name";
  
   @ViewChild("templateScientific") modaltempScientific: any;
  @ViewChild("templateTrade") modaltempTrade: any;
  @ViewChild("templateDrug") modaltempDrug: any;
  @ViewChild("templateDose") modaltempDose: any;
  @ViewChild("templateFrequency") modaltempFrequency: any;
  @ViewChild("templateConsumption") modaltempConsumption: any;
  GetMarketStatusList: any;
  options:any;
  ajax:any;
  DrugsRows: number=0;
  isLoading=false;
  CUId=0
  public suggestions$?: Observable<any[]>;
  private baseUrl: string = "";
  functionPermissionData: any;
  consumptionUnit: boolean
  isConsumptionUnit: boolean=true;
  consumptionlist:any
  ConsumptioncolumnDefs=[]
  consumptionPopupHeading: string;
  modalRefConsumption: BsModalRef;
  Units: any=[{
    name:"Ml"
  },{
    name:'No.s'
  },
  {
    name:'Puffs'
  }];
  ConsumptionId: number;
  UnitValue: any='';

  constructor(
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private appointmentService: AppointmentsService,
    private ngxLoader: NgxUiLoaderService,
    private OperatorService: OperatorService,
    private toastr: ToastrService,
    private formValidationService: FormValidationService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private http: HttpClient,
    private configurationManager: ConfigurationManager ,
    public datepipe: DatePipe,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {

    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.ScientificForm = new FormGroup({
      ScientificCode: new FormControl("", [Validators.required]),
      ScientificName: new FormControl("", [Validators.required]),
      ZoneId: new FormControl("", [Validators.required]),
      IsDisplayedScientific: new FormControl(true)
    });

    this.options = {
      multiple: false,
      minimumInputLength: 2,
    }



    this.TradeNameForm = new FormGroup({
      DDCcode: new FormControl("", [Validators.required]),
      TradeName: new FormControl("", [Validators.required]),
      ScientificName: new FormControl("", [Validators.required]),
      ZoneIdTrade: new FormControl("", [Validators.required]),
      RouteId: new FormControl("", [Validators.required]),
      Ingredient: new FormControl("", [Validators.required]),
      dosageFrom: new FormControl("", [Validators.required]),
      PackagePrice: new FormControl("", [Validators.required]),
      Granular: new FormControl("", [Validators.required]),
      Manufacturer: new FormControl("", [Validators.required]),
      RegisteredOwner: new FormControl("", [Validators.required]),
      IsDisplayedTrade: new FormControl(true)
    });

    this.DrugForm = new FormGroup({
      DrugType: new FormControl("", [Validators.required]),
      DrugCode: new FormControl("", [Validators.required]),
      DdcCode: new FormControl("", [Validators.required]),
      DisplayName: new FormControl("", [Validators.required]),
      ZoneIdTrade: new FormControl(""),
      RouteId: new FormControl("", [Validators.required]),
      IngredientStrength: new FormControl(""),
      dosageFrom: new FormControl("", [Validators.required]),
      Ingredients: new FormControl(""),
      form: new FormControl(""),
      marketStatus: new FormControl(""),
      Remarks: new FormControl(""),
      packageno: new FormControl("", [Validators.required]),
      ScientificName: new FormControl("", [Validators.required]),
      IsDisplayedDrug: new FormControl(true),
      TradeId: new FormControl('', Validators.required),
      TradeName: new FormControl('', Validators.required),
      QtyinMl: new FormControl('')
    });

    this.DosageForm = new FormGroup({
      DosageDescription: new FormControl("", [Validators.required]),
      Dosagevalue: new FormControl("", [Validators.required]),
      ZoneIdDosage: new FormControl("", [Validators.required]),
      IsDisplayedDosage: new FormControl(true)
    });
    this.FrequencyForm = new FormGroup({
      FrequencyDescription: new FormControl("", [Validators.required]),
      Frequencyvalue: new FormControl("", [Validators.required]),
      ZoneIdFreq: new FormControl("", [Validators.required]),
      IsDisplayedFrequency: new FormControl(true)
    });
    this.ConsumptionForm=new FormGroup({
      DrugTypeId: new FormControl("", [Validators.required]),
      ConsumptionUnit: new FormControl("", [Validators.required]),
    });


    if(this.IsScientificName==true){
      this.GetScientificName();
      this.GetScientificNameGrid();
    }



    this.GetAllZones();
    this.GetAllRoutes();
    this.GetDrugType();
    this.GetConsumptionlist()


    this.GetMarketStatus();
    this.ScientificcolumnDefs = [
      {
        headerName: "Scientific Name",
        field: "scientificName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Scientific Code",
        field: "scientificCode",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "ScientificName" },
      },
    ];

    this.TradeListcolumnDefs = [
      {
        headerName: "Trade Name",
        field: "tradeName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },

      {
        headerName: "Dosage Form",
        field: "dosageForm",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Ingredient Strength",
        field: "ingredentStrength",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Granular Unit",
        field: "granularUnit",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Manufacturer",
        field: "manufacturer",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },


      {
        headerName: "Package Price",
        field: "packagePrice",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Trade Code",
        minWidth:180,
        field: "tradeCode",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Route",
        field: "routeDesc",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Scientific Name",
        field: "scientificName",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Registered Owner ",
        field: "registeredOwner",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },


      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "TradeList" },
      },
    ];

    this.DrugListcolumnDefs = [
      {
        headerName: "Drug Name",
        field: "drugName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Drug Code",
        field: "drugCode",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Drug Type",
        field: "drugTypeName",
        minWidth:180,
        nableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },

      {
        headerName: "Form",
        field: "form",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },

      {
        headerName: "DDC Code",
        field: "ddcCode",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Pack No.",
        field: "packageNo",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Route",
        field: "routeDesc",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Scientific Name",
        field: "scientificName",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },


      {
        headerName: "Market Status",
        minWidth:180,
        field: "status",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Ingredient",
        minWidth:180,
        field: "ingredient",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Ingredient Strength",
        field: "ingredientStrength",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Remarks",
        field: "remarks",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },

      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "DrugList" },
      },
    ];
    this.DosagecolumnDefs = [
      {
        headerName: "Dosage Name",
        minWidth:180,
        field: "dosageDesc",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Dosage" },
      },
    ];

    this.FrequencycolumnDefs = [
      {
        headerName: "Frequency Name",
        minWidth:180,
        field: "freqDesc",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },

      {
        headerName: "Frequency Value",
        field: "freqValue",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },

      {
        headerName: "Action",
        maxWidth:100,
        filter: false,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Frequency" },
      },
    ];

    this.ConsumptioncolumnDefs = [
      {
        headerName: "Drug type",
        minWidth:180,
        field: "drugTypeId",
        valueGetter: params => this.getDrugTypeDescription(params.data.drugTypeId),
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },

      {
        headerName: "unit",
        field: "consumptionUnit",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },

      {
        headerName: "Action",
        maxWidth:100,
        filter: false,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Consumption" },
      },
    ];
    this.context = { componentParent: this };
    this.signdefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.functionPermission();
  }

  onTab(number) {
    debugger
    this.ScientificName = false;
    this.TradeName = false;
    this.Drug = false;
    this.Dosage = false;
    this.Frequency = false;
    this.consumptionUnit = false;
    if (number == "1") {
      this.ScientificName = true;
    } else if (number == "2") {
      if(this.IsTradeName==true){
        this.GetTradeNameInput.PageNumber=1;

        this.GetTradeName();
      }
      this.TradeName = true;
    } else if (number == "3") {
        if(this.IsDrugName==true){
          this.GetTradeNamesList();
          this.GetDrug();
      }
      this.Drug = true;
    }
    else if (number == "4") {
        if(this.IsDosage==true){
          this.GetDosage();
        }
      this.Dosage = true;
    }
    else if (number == "5") {
      if(this.IsFrequency==true){
        this.GetFrequency();
      }
      this.Frequency = true;
    }
    else if (number == '6'){
      if(this.isConsumptionUnit == true){
        this.GetConsumptionlist();
      }
      this.consumptionUnit=true
    }

  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
  onGridReadyTrade(params) {
    this.gridApiTrade = params.api;
  }
  onGridReadyDrug(params) {
    this.gridApiDrug = params.api;
  }
  onGridReadyFrequency(params) {
    this.gridApiFrequency = params.api;
  }
  onGridReadyDosage(params) {
    this.gridApiDosage = params.api;
  }
  onGridReadyConsumption(params){
    this.gridApiConsumption = params.api;
  }
  onFilterTextBoxChanged() {
    // this.gridApi.setQuickFilter(
    //   (document.getElementById('filter-text-box') as HTMLInputElement).value
    // );

    this.GetSctficNameInput.SearchText=(document.getElementById('filter-text-box') as HTMLInputElement).value;
    this.GetSctficNameInput.PageNumber=1;
    this.GetScientificNameGrid(1);

  }
  onFilterTextBoxChangedTrade() {
    // this.gridApiTrade.setQuickFilter(
    //   (document.getElementById('filter-text-box-trade') as HTMLInputElement).value
    // );

    this.GetTradeNameInput.TradeName=(document.getElementById('filter-text-box-trade') as HTMLInputElement).value;
    this.GetTradeNameInput.PageNumber=1;
    this.GetTradeName(1);
  }
  onFilterTextBoxChangedDrug() {
    // this.gridApiDrug.setQuickFilter(
    //   (document.getElementById('filter-text-box-drug') as HTMLInputElement).value

    // );

    this.GetDrugsInput.FilterText=(document.getElementById('filter-text-box-drug') as HTMLInputElement).value;
    this.GetDrugsInput.PageNumber=1;
    this.GetDrug(1);
  }
  onFilterTextBoxChangedFrequency() {
    this.gridApiFrequency.setQuickFilter(
      (document.getElementById('filter-text-box-frequency') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedDosage() {
    this.gridApiDosage.setQuickFilter(
      (document.getElementById('filter-text-box-dosage') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedConsuption() {
    this.gridApiDosage.setQuickFilter(
      (document.getElementById('filter-text-box-consumption') as HTMLInputElement).value
    );
  }

  EditScientificName(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 78)
    if (foundElement.id == 78 && foundElement.isAllowed == true) {
    this.DrugScientificNamePopupHeading="Edit Scientific Name"
    this.modalRefScientific = this.modalService.show(
      this.modaltempScientific,
      // Object.assign({}, { class: "gray modal-lg" })
    );
    if (data.isDisplayed == 1) {
      data.isDisplayed = true;
    }
    else {
      data.isDisplayed = false;
    }
    if (data.scientificId) {
      this.ScientificId = data.scientificId;
      this.DisplayedId = data.isDisplayed;
      this.ScientificForm.patchValue({
        ScientificCode: data.scientificCode,
        ScientificName: data.scientificName,
        ZoneId: data.zoneId,
        IsDisplayedScientific: data.isDisplayed
      });
    }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  DeleteScientificName(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 79)
    if (foundElement.id == 79 && foundElement.isAllowed == true) {
    this.ScientificId = data.scientificId;
    let DeletedId = 1
    var AppData = {
      ScientificCode: data.scientificCode,
      ScientificName: data.scientificName,
      ZoneId: data.zoneId,
    };
    var saveupdateId = 0;
    if (this.ScientificId > 0) {
      saveupdateId = this.ScientificId;
    }
    if (this.IsDeleted > 0) {
      DeletedId = 1;
    }
    this.configurationService
      .InsertUpdateScientificName(
        AppData,
        this.globals.HospitalId,
        this.ScientificId, 1
      )
      .subscribe((res) => {
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Deleted Successfully") {

          this.toastr.success("Scientific Name Deleted successfully");

          //  else {
          //   this.toastr.success("Scientific Name Updated successfully");
          // }
          //this.signModal = false;
          this.GetSctficNameInput.PageNumber=1;
          this.GetScientificNameGrid(1);
          this.GetScientificName();
        } else {
          this.toastr.error("Failed : " + this.result.message);
        }
      });
      this.IsScientificName=true;
    this.GetScientificName();
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }

  }




  GetScientificName() {
    this.configurationService
      .GetScientificName(this.globals.HospitalId)
      .subscribe((res) => {
        let data = res.response;
        this.IsScientificName=false;
        // this.ScientificNameData = [];
        // data.forEach((a) => {
        //   this.ScientificNameData.push({
        //     id: a.scientificId.toString(),
        //     text: a.scientificName,
        //   });
        // });

        this.ScientificNameData=data.filter(item=>item.isDisplayed == 1).map(a=>{
          return{
            id: a.scientificId.toString(),
            text: a.scientificName,
          }
        })


      });
  }

  GetScientificNameGrid(loader=0){
    this.GetSctficNameInput.BranchId=this.globals.HospitalId;
    if(loader==1) this.gridApi.showLoadingOverlay();
    this.configurationService
    .GetScientificNamePagination(this.GetSctficNameInput)
    .subscribe((res) => {
      let response=res.response;
      this.totalsctficItems=response['totalCount'];
      this.GetScientificNameList =  response['scientificNameData'];
      if(loader==1)this.gridApi.hideOverlay();
     });
  }
  showScientificModalDialog(template) {
    const foundElement = this.functionPermissionData.find(x => x.id === 77)
    if (foundElement.id == 77 && foundElement.isAllowed == true) {
    this.DrugScientificNamePopupHeading="Add Scientific Name"
    this.modalRefScientific = this.modalService.show(template);
    this.ScientificId = 0;
    this.clearFormScientific();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }

  //check and uncheck of registration date

  SaveUpdateScientficName() {
    this.submitted = true;
    if (this.ScientificForm && this.ScientificForm.valid) {
      var AppData = {
        ScientificCode: this.ScientificForm.get("ScientificCode").value,
        ScientificName: this.ScientificForm.get("ScientificName").value,
        ZoneId: this.ScientificForm.get("ZoneId").value,
        IsDisplayed: Number(this.ScientificForm.get("IsDisplayedScientific").value),
      };
      var saveupdateId = 0;
      var DeletedId = 0;
      if (this.ScientificId > 0) {
        saveupdateId = this.ScientificId;
      }
      this.isLoading=true;

      this.configurationService
        .InsertUpdateScientificName(AppData, this.globals.HospitalId, saveupdateId, 0)
        .subscribe((res) => {
          this.result = res;
          this.submitted = false;
          if (this.result.status == 200 && this.result.message == "Success") {
            if (saveupdateId == 0) {
              this.isLoading=false;
              this.toastr.success("Scientific Name Added successfully");
            } else {
              this.isLoading=false;
              this.toastr.success("Scientific Name Updated successfully");
              this.ScientificId = 0
            }
            this.modalRefScientific.hide();
            this.IsScientificName=true;
            this.GetScientificNameGrid(1);
            this.GetScientificName();
            this.clearFormScientific();
          } else {
            this.isLoading=false;
            this.toastr.error("Failed : " + this.result.message);
          }
        });
    }
  }

  GetAllZones() {

    this.configurationService.GetZones(this.globals.HospitalId).subscribe((res) => {
      this.Zones = res.response;
      this.ZonesData = [];
      this.Zones.forEach((a) => {
        this.ZonesData.push({
          id: a.id.toString(),
          text: a.zoneName,
        });
      });
      if (this.Zones?.length == 0) {
        this.toastr.warning("No Zones found");
      }
    });
  }

  GetAllRoutes() {

    this.configurationService.GetRoute(this.globals.HospitalId).subscribe((res) => {
      this.Routes = res.response;
      this.RoutesData = [];
      this.Routes.forEach((a) => {
        this.RoutesData.push({
          id: a.routeId.toString(),
          text: a.routeDesc,
        });
      });
      if (this.Zones.length == 0) {
        this.toastr.warning("No Zones found");
      }
    });
  }

  /*--------------------------------------------------State----------------------------------------*/
  GetTradeName(loader=0) {
    // this.configurationService
    //   .GetTradeName(this.globals.HospitalId)
    //   .subscribe((res) => {
    //     this.TradeList = res.response;
    //     this.IsTradeName=false;
    //   });


    //Pagination based get
    this.GetTradeNameInput.BranchId=this.globals.HospitalId;
    if(loader==1) this.gridApiTrade.showLoadingOverlay();
    this.configurationService
      .GetTradeNamePagination(this.GetTradeNameInput)
      .subscribe((res) => {
        const response= res.response;
        if(response){
          this.TradeListRows=response['totalCount'];
          this.TradeList =  response['tradeNameData'];
          this.IsTradeName=false;
          if(loader==1)this.gridApiTrade.hideOverlay();
        }

      });


  }
  showTradeNamemodal(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 80)
    if (foundElement.id == 80 && foundElement.isAllowed == true) {
    this.DrugTradeNamePopupHeading="Add  Trade Name";
    this.modalRefTrade = this.modalService.show(template, Object.assign({}, { class: "gray modal-lg" }));
    this.tradeId = 0;
    this.clearFormTrade();
    } else {
     this.toastr.warning("You are not allowed to add data");
   }
  }

  SaveUpdateTrade() {

    this.submitted1 = true;
    if (this.TradeNameForm && this.TradeNameForm.valid) {
      var AppData = {
        DDCcode: this.TradeNameForm.get("DDCcode").value,
        TradeName: this.TradeNameForm.get("TradeName").value,
        ScientificName: this.TradeNameForm.get("ScientificName").value,
        ZoneIdTrade: this.TradeNameForm.get("ZoneIdTrade").value,
        RouteId: this.TradeNameForm.get("RouteId").value,
        Ingredient: this.TradeNameForm.get("Ingredient").value,
        dosageFrom: this.TradeNameForm.get("dosageFrom").value,
        PackagePrice: this.TradeNameForm.get("PackagePrice").value,
        Granular: this.TradeNameForm.get("Granular").value,
        Manufacturer: this.TradeNameForm.get("Manufacturer").value,
        RegisteredOwner: this.TradeNameForm.get("RegisteredOwner").value,
        IsDisplayed: this.TradeNameForm.get("IsDisplayedTrade").value
      };
      var saveupdateId = 0;
      var DeletedId = 0;
      if (this.tradeId > 0) {
        saveupdateId = this.tradeId;
      }
      if (this.IsDeleted > 0) {
        DeletedId = 1;
      }
      if (AppData.IsDisplayed == true) {
        AppData.IsDisplayed = 1;
      }
      else {
        AppData.IsDisplayed = 0;
      }
      this.isLoading=true;

      this.configurationService
        .InsertUpdateDeleteTradeName(
          AppData,
          this.globals.HospitalId,
          saveupdateId,
          this.IsDisplayed, 0
        )
        .subscribe((res) => {
          this.result = res;
          this.submitted1 = false;
          if (this.result.status == 200 && this.result.message == "Success") {
            if (saveupdateId == 0) {
              this.isLoading=false;
              this.toastr.success("Trade Name Added successfully");

            } else {
              this.isLoading=false;
              this.toastr.success("Trade Name Updated successfully");
              this.tradeId = 0
            }

            this.GetTradeNameInput.PageNumber=1;
            this.GetTradeName();
            this.modalRefTrade.hide();
            this.clearFormTrade();
            this.IsTradeName=true;

          } else {
            this.isLoading=false;
            this.toastr.error(this.result.message);
          }
        });
        //this.GetTradeName();
    }
  }

  EditTradeList(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 81)
    if (foundElement.id == 81 && foundElement.isAllowed == true) {
    this.DrugTradeNamePopupHeading="Edit Trade Name";
    this.modalRefTrade = this.modalService.show(
      this.modaltempTrade,
      Object.assign({}, { class: "gray modal-lg" })
    );
    if (data.isDisplayed == 1) {
      data.isDisplayed = true;
    }
    else {
      data.isDisplayed = false;
    }
    if (data.tradeId) {
      this.tradeId = data.tradeId;
      this.DisplayedId = data.isDisplayed;
      this.TradeNameForm.patchValue({
        DDCcode: data.tradeCode,
        TradeName: data.tradeName,
        ScientificName: data.scientificId,
        ZoneIdTrade: data.zoneId,
        Ingredient: data.ingredentStrength,
        dosageFrom: data.dosageForm,
        PackagePrice: data.packagePrice,
        Granular: data.granularUnit,
        Manufacturer: data.manufacturer,
        RegisteredOwner: data.registeredOwner,
        RouteId: data.routeId,
        IsDisplayedTrade: data.isDisplayed
      });
    }
  } else {
    this.toastr.warning("You are not allowed to edit data");
  }
  }
  DeleteTradeList(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 82)
    if (foundElement.id == 82 && foundElement.isAllowed == true) {
    this.ScientificId = data.tradeId;
    let DeletedId = 1;

    var AppData = {
      DDCcode: data.tradeCode,
      TradeName: data.tradeName,
      ScientificName: data.scientificId,
      ZoneIdTrade: data.zoneId,
      RouteId: data.routeId,
      Ingredient: data.ingredentStrength,
      dosageFrom: data.dosageForm,
      PackagePrice: data.packagePrice,
      Granular: data.granularUnit,
      Manufacturer: data.manufacturer,
      RegisteredOwner: data.registeredOwner,
    };
    var saveupdateId = 0;
    //var DeletedId=0;
    if (this.ScientificId > 0) {
      saveupdateId = this.ScientificId;
    }
    if (this.IsDeleted > 0) {
      DeletedId = 1;
    }
    this.configurationService
      .InsertUpdateDeleteTradeName(
        AppData,
        this.globals.HospitalId,
        this.ScientificId,
        this.IsDisplayed, 1
      )
      .subscribe((res) => {
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Deleted Successfully") {

          this.toastr.success("Trade Name Deleted successfully");
          this.ScientificId = 0;
          //  else {
          //   this.toastr.success("Scientific Name Updated successfully");
          // }
          //this.signModal = false;
          this.GetTradeNameInput.PageNumber=1;
            this.GetTradeName();

        } else {
          this.toastr.error("Failed : " + this.result.message);
        }
      });
      this.IsTradeName=true;
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  /*-------------------------------------------Drug---------------------------------------*/

  GetDrug(loading=0) {

    // this.configurationService
    //   .GetDrug(this.globals.HospitalId)
    //   .subscribe((res) => {
    //     this.GetDrugList = res.response;
    //     this.IsDrugName=false;
    //   });

    // For Pagination based Loading
    this.GetDrugsInput.BranchId=this.globals.HospitalId;

    if(loading==1)this.gridApiDrug.showLoadingOverlay();
    this.configurationService
    .GetDrugPagination(this.GetDrugsInput)
    .subscribe((res) => {
      let response= res.response;
      this.GetDrugList = response['drugData'];
      this.DrugsRows=response['totalCount'];
      this.IsDrugName=false;
      if(loading==1)this.gridApiDrug.hideOverlay();
    });


  }
  GetDrugType() {
    this.configurationService
      .GetDrugType(this.globals.HospitalId)
      .subscribe((res) => {
        this.GetDrugTypeList = res.response;
      });
  }

  showDrugmodal(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 83)
    if (foundElement.id == 83 && foundElement.isAllowed == true) {
    this.DrugsPopupHeading="Add Drug";
    this.modalRefDrug = this.modalService.show(template, Object.assign({}, { class: "gray modal-lg" }));
    this.drugId = 0;
    this.clearFormDrugs();
    this.getConsumptionUnit()
    // this.modalRefDrug,
    // Object.assign({}, { class: "gray modal-lg" })
    } else {
      this.toastr.warning("You are not allowed to add data");
    }

  }

  GetTradeNamesList() {
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.DrugForm.get('TradeName').value);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<any[]>>(
              `${this.baseUrl}api/MasterData/GetActiveTradeName`,
              {
                TradeId: 0,
                TradeName: this.DrugForm.get('TradeName').value,
                ShowAll: 0,
                BranchId: this.globals.HospitalId
              }
            )
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  onSelectTrade(tradeInfo){
    this.DrugForm.patchValue({
      TradeName: tradeInfo.tradeName,
      TradeId: tradeInfo.tradeId
    })
  }

  SaveUpdateDrugs() {
    this.submitted2 = true;

    if (this.DrugForm && this.DrugForm.valid) {
      var AppData = {
        DrugType: this.DrugForm.get("DrugType").value,
        DrugCode: this.DrugForm.get("DrugCode").value,
        DdcCode: this.DrugForm.get("DdcCode").value,
        DisplayName: this.DrugForm.get("DisplayName").value,
        RouteId: this.DrugForm.get("RouteId").value,
        ScientificName: this.DrugForm.get("ScientificName").value,
        IngredientStrength: this.DrugForm.get("IngredientStrength").value,
        Ingredients: this.DrugForm.get("Ingredients").value,
        DosageForm: this.DrugForm.get("dosageFrom").value,
        form: this.DrugForm.get("form").value,
        marketStatus: this.DrugForm.get("marketStatus").value,
        Remarks: this.DrugForm.get("Remarks").value,
        packageno: this.DrugForm.get("packageno").value,
        ZoneIdTrade: this.DrugForm.get("ZoneIdTrade").value,
        IsDisplayedDrug: this.DrugForm.get("IsDisplayedDrug").value,
        TradeId: Number(this.DrugForm.get("TradeId").value),
        QtyinMl: this.DrugForm.get('QtyinMl').value ? this.DrugForm.get('QtyinMl').value : 0
      };
      if (AppData.IsDisplayedDrug == true) {
        AppData.IsDisplayedDrug = 1;
      }
      else {
        AppData.IsDisplayedDrug = 0;
      }
      var saveupdateId = 0;

      if (this.drugId > 0) {
        saveupdateId = this.drugId;
      }
      this.isLoading=true;
      this.configurationService
        .InsertUpdateDeleteDrug(
          AppData,
          this.globals.HospitalId,
          saveupdateId, 0
        )
        .subscribe((res) => {
          this.result = res;
          this.submitted2 = false;
          if (this.result.status == 200 && this.result.message == "Success") {
            if (saveupdateId == 0) {
              this.isLoading=false;
              this.toastr.success("Drugs Added successfully");
            } else {
              this.isLoading=false;
              this.toastr.success("Drugs Updated successfully");
              this.drugId = 0;
            }
            this.modalRefDrug.hide();
            this.clearFormDrugs();
            this.IsDrugName=true;
            this.GetDrug();
          } else {
            this.isLoading=false;
            this.toastr.error("Failed : " + this.result.message);
          }
        });

    }

  }

  EditDrugList(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 84)
    if (foundElement.id == 84 && foundElement.isAllowed == true) {
    this.DrugsPopupHeading="Edit Drug";
    this.modalRefDrug = this.modalService.show(
      this.modaltempDrug,
      Object.assign({}, { class: "gray modal-lg" })
    );
    if (data.isDisplayed == 1) {
      data.isDisplayed = true;
    }
    else {
      data.isDisplayed = false;
    }
    if (data.drugId) {
      this.drugId = data.drugId;
      this.DisplayedId = data.isDisplayed;
      this.DrugForm.patchValue({
        DrugType: data.drugTypeId,
        DrugCode: data.drugCode,
        DdcCode: data.ddcCode,
        DisplayName: data.drugName,
        ZoneIdTrade: data.zoneId,
        RouteId: data.routeId,
        IngredientStrength: data.ingredientStrength,
        Ingredients: data.ingredient,
        marketStatus: data.marketStatus,
        form: data.form,
        packageno: data.packageNo,
        dosageFrom: data.dosageForm,
        Remarks: data.remarks,
        ScientificName: data.scientificId,
        IsDisplayedDrug: data.isDisplayed,
        TradeId: data.tradeId,
        TradeName: data.tradeName,
        QtyinMl: data.qtyinMl
      });
      this.getConsumptionUnit()
    }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  DeleteDrugList(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 85)
    if (foundElement.id == 85 && foundElement.isAllowed == true) {
    this.ScientificId = data.drugId;
    let DeletedId = 1;

    var AppData = {
      DrugType: data.drugTypeId,
      DrugCode: data.drugCode,
      DdcCode: data.ddcCode,
      DisplayName: data.drugName,
      RouteId: data.routeId,
      ScientificName: data.scientificId,
      IngredientStrength: data.ingredient,
      Ingredients: data.ingredient,
      DosageForm: data.dosageForm,
      form: data.form,
      marketStatus: data.marketStatus,
      Remarks: data.remarks,
      packageno: data.packageNo,
      ZoneIdTrade: data.zoneId,
    };
    var saveupdateId = 0;
    //var DeletedId=0;
    if (this.ScientificId > 0) {
      saveupdateId = this.ScientificId;
    }
    if (this.IsDeleted > 0) {
      DeletedId = 1;
    }
    this.configurationService
      .InsertUpdateDeleteDrug(
        AppData,
        this.globals.HospitalId,
        this.ScientificId, 1
      )
      .subscribe((res) => {
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Success") {

          this.toastr.success("Drug Deleted successfully");
          this.ScientificId = 0;

          this.GetDrug();
        } else {
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    this.GetDrug();
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }


  /*-----------------------------------showDosagemodal------------------------*/

  GetDosage() {
    let showAll = 1;
    this.configurationService
      .GetDosage(this.globals.HospitalId, showAll)
      .subscribe((res) => {
        this.GetDosageList = res.response;
        this.IsDosage=false;
      });
  }

  showDosagemodal(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 86)
    if (foundElement.id == 86 && foundElement.isAllowed == true) {
    this.DrugDosagePopupHeading="Add Dosage";
    this.modalRefDose = this.modalService.show(template);
    this.dosageId = 0;
    this.clearFormDosage();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }
  SaveDosage() {
    this.submitted3 = true;

    if (this.DosageForm && this.DosageForm.valid) {
      var AppData = {
        DosageDescription: this.DosageForm.get("DosageDescription").value,
        Dosagevalue: this.DosageForm.get("Dosagevalue").value,
        ZoneIdDosage: this.DosageForm.get("ZoneIdDosage").value,
        IsDisplayed: this.DosageForm.get("IsDisplayedDosage").value
      };
      if (AppData.IsDisplayed == true) {
        AppData.IsDisplayed = 1;
      }
      else {
        AppData.IsDisplayed = 0;
      }
      var saveupdateId = 0;
      if (this.dosageId > 0) {
        saveupdateId = this.dosageId;
      }
      this.isLoading=true;

      this.configurationService
        .InsertUpdateDosage(
          AppData,
          this.globals.HospitalId,
          saveupdateId, 0
        )
        .subscribe((res) => {
          this.result = res;
          this.submitted3 = false;
          if (this.result.status == 200 && this.result.message == "Success") {
            if (saveupdateId == 0) {
              this.isLoading=false;
              this.toastr.success("Dosage Added successfully");
            } else {
              this.isLoading=false;
              this.toastr.success("Dosage Updated successfully");
              this.dosageId = 0;
            }
            this.modalRefDose.hide();
            this.clearFormDosage();
            this.IsDosage=true;
            this.GetDosage();
          } else {
            this.isLoading=false;
            this.toastr.error("Failed : " + this.result.message);
          }
        });
    }
  }
  EditDosage(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 87)
    if (foundElement.id == 87 && foundElement.isAllowed == true) {
    this.DrugDosagePopupHeading="Edit Dosage";
    this.modalRefDose = this.modalService.show(
      this.modaltempDose,
      // Object.assign({}, { class: "gray modal-lg" })
    );
    if (data.isDisplayed == 1) {
      data.isDisplayed = true;
    }
    else {
      data.isDisplayed = false;
    }
    if (data.dosageId) {
      this.dosageId = data.dosageId;
      this.DosageForm.patchValue({
        DosageDescription: data.dosageDesc,
        Dosagevalue: data.dosageValue,
        ZoneIdDosage: data.zoneId,
        IsDisplayedDosage: data.isDisplayed
      });
    }
  } else {
    this.toastr.warning("You are not allowed to edit data");
  }
  }

  DeleteDosage(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 88)
    if (foundElement.id == 88 && foundElement.isAllowed == true) {
    this.ScientificId = data.dosageId;
    let DeletedId = 1;
    if (data.isDisplayed == 1) {
      data.isDisplayed = true;
    }
    else {
      data.isDisplayed = false;
    }
    var AppData = {
      DosageDescription: data.dosageDesc,
      Dosagevalue: data.dosageValue,
      ZoneIdDosage: data.zoneId,
      IsDisplayed: data.isDisplayed
    };
    var saveupdateId = 0;
    //var DeletedId=0;
    if (this.ScientificId > 0) {
      saveupdateId = this.ScientificId;
    }
    if (this.IsDeleted > 0) {
      DeletedId = 1;
    }
    this.configurationService
      .InsertUpdateDosage(AppData, this.globals.HospitalId, this.ScientificId, 1)
      .subscribe((res) => {
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Deleted Successfully") {
          this.GetDosage();
          this.toastr.success("Dosage Deleted successfully");
          this.modalRefDose.hide();
          this.ScientificId = 0;
        } else {
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    this.IsDosage=true;
    this.GetDosage();
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  /*-----------------------------------Frequencyemodal------------------------*/

  GetFrequency() {
    let ShowAll = 1;
    this.configurationService
      .GetFrequency(this.globals.HospitalId, ShowAll)
      .subscribe((res) => {
        this.GetFrequencyList = res.response;
        this.IsFrequency=false;
      });
  }

  showFrequencymodal(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 89)
    if (foundElement.id == 89 && foundElement.isAllowed == true) {
    this.DrugFrequencyPopupHeading="Add Frequency";
    this.modalRefFrequency = this.modalService.show(template);
    this.freqId = 0;
    this.clearFormFrequency();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }
  SaveFrequency() {
    this.submitted4 = true;
    if (this.FrequencyForm && this.FrequencyForm.valid) {
var AppData = {
        Frequencyvalue: this.FrequencyForm.get("Frequencyvalue").value,
        FrequencyDescription: this.FrequencyForm.get("FrequencyDescription").value,
        ZoneIdFreq: this.FrequencyForm.get("ZoneIdFreq").value,
        isDisplayed: this.FrequencyForm.get("IsDisplayedFrequency").value
      };
      if (AppData.isDisplayed == true) {
        AppData.isDisplayed = 1;
      }
      else {
        AppData.isDisplayed = 0;
      }
      var saveupdateId = 0;
      if (this.freqId > 0) {
        saveupdateId = this.freqId;
      }
      this.isLoading=true;

      this.configurationService
        .InsertUpdateDeleteFrequency(
          AppData,
          this.globals.HospitalId,
          saveupdateId, 0
        )
        .subscribe((res) => {
          this.result = res;
          this.submitted4 = false;
          if (this.result.status == 200 && this.result.message == "Success") {
            if (saveupdateId == 0) {
              this.isLoading=false;
              this.toastr.success("Frequency Added successfully");
            } else {
              this.isLoading=false;
              this.toastr.success("Frequency Updated successfully");
              this.freqId = 0;
            }
            this.modalRefFrequency.hide();
            this.clearFormFrequency();
            this.IsFrequency=true;
            this.GetFrequency();
          } else {
            this.isLoading=false;
            this.toastr.error("Failed : " + this.result.message);
          }
        });
    }
  }
  EditFrequency(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 90)
    if (foundElement.id == 90 && foundElement.isAllowed == true) {
    this.DrugFrequencyPopupHeading="Edit Frequency";
    this.modalRefFrequency = this.modalService.show(
      this.modaltempFrequency,
      // Object.assign({}, { class: "gray modal-lg" })
    )
    if (data.isDisplayed == 1) {
      data.isDisplayed = true;
    }
    else {
      data.isDisplayed = false;
    }
    if (data.freqId) {
      this.freqId = data.freqId;
      this.FrequencyForm.patchValue({
        FrequencyDescription: data.freqDesc,
        Frequencyvalue: data.freqValue,
        ZoneIdFreq: data.zoneId,
        IsDisplayedFrequency: data.isDisplayed
      });
    }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  DeleteFrequency(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 91)
    if (foundElement.id == 91 && foundElement.isAllowed == true) {
    this.ScientificId = data.freqId;
    let DeletedId = 1;

    var AppData = {
      FrequencyDescription: data.freqDesc,
      Frequencyvalue: data.freqValue,
      ZoneIdFreq: data.zoneId,
      IsDisplayedFrequency: data.isDisplayed
    };
    var saveupdateId = 0;
    //var DeletedId=0;
    if (this.ScientificId > 0) {
      saveupdateId = this.ScientificId;
    }
    if (this.IsDeleted > 0) {
      DeletedId = 1;
    }
    this.configurationService
      .InsertUpdateDeleteFrequency(
        AppData,
        this.globals.HospitalId,
        this.ScientificId, 1
      )
      .subscribe((res) => {
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Deleted Successfully") {

          this.toastr.success("Frequency Deleted successfully");

          this.ScientificId = 0;
          this.IsFrequency=true;
          this.GetFrequency();
        } else {
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    this.GetFrequency();
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }
  }

  showConsumptionmodal(template: TemplateRef<any>){
    const foundElement = this.functionPermissionData.find(x => x.id === 637)
    if (foundElement.id == 637 && foundElement.isAllowed == true) {
    this.consumptionPopupHeading="Add consumption";
    this.modalRefConsumption = this.modalService.show(template);
    this.ConsumptionId = 0;
    this.clearFormFrequency();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  get scientificf() {
    return this.ScientificForm.controls;
  }
  get tradef() {
    return this.TradeNameForm.controls;
  }
  get drugf() {
    return this.DrugForm.controls;
  }
  get dosagef() {
    return this.DosageForm.controls;
  }
  get frequencyf() {
    return this.FrequencyForm.controls;
  }
  get consumptionf(){
    return this.ConsumptionForm.controls
  }
  clearFormScientific() {
    this.ScientificForm.reset({
      ScientificCode: '',
      ScientificName: '',
      ZoneId: '',
      IsDisplayedScientific: true
    });
    this.submitted = false;
  }
  clearFormTrade() {
    this.TradeNameForm.reset({
      DDCcode: '',
      TradeName: '',
      ScientificName: '',
      ZoneIdTrade: '',
      RouteId: '',
      Ingredient: '',
      dosageFrom: '',
      PackagePrice: '',
      Granular: '',
      Manufacturer: '',
      RegisteredOwner: '',
      IsDisplayedTrade: true
    });
    this.submitted1 = false;
  }

  clearFormDrugs() {

    this.DrugForm.reset({
      DrugType: '',
      DrugCode: '',
      DdcCode: '',
      DisplayName: '',
      ZoneIdTrade: '',
      RouteId: '',
      IngredientStrength: '',
      dosageFrom: '',
      Ingredients: '',
      form: '',
      marketStatus: '',
      Remarks: '',
      packageno: '',
      ScientificName: '',
      IsDisplayedDrug: true
    });
    this.submitted2 = false;
  }

  clearFormDosage() {
    this.DosageForm.reset({
      DosageDescription: '',
      Dosagevalue: '',
      ZoneIdDosage: '',
      IsDisplayedDosage: new FormControl(true)
    });
    this.submitted3 = false;
  }
  pageChangedForDrug(event){
    this.GetDrugsInput.PageNumber=event.page;
    this.GetDrug(1);
  }
  pageChanged(event){
    this.GetTradeNameInput.PageNumber=event.page;
    this.GetTradeName(1);
  }
  pageChangedforScientfic(event){

  }

  clearFormFrequency() {
    this.FrequencyForm.reset({
      FrequencyDescription: '',
      Frequencyvalue: '',
      ZoneIdFreq: '',
      IsDisplayedFrequency: new FormControl(true)
    });
    this.submitted4 = false;
  }

  clearFormConsumption(){
    this.ConsumptionForm.reset({
      DrugTypeId:'',
      ConsumptionUnit:''
    });
    this.submitted5 = false;
  }


  GetMarketStatus() {
    this.configurationService
      .GetMarketStatus(this.globals.HospitalId)
      .subscribe((res) => {
        this.GetMarketStatusList = res.response;
      });
  }



  ResetFunction() {
    this.ScientificId = 0;
    this.clearFormScientific();
  }

  ResetFunction1() {
    this.tradeId = 0;
    this.clearFormTrade();
  }
  ResetFunction2() {
    this.drugId = 0;
    this.clearFormDrugs();
  }
  ResetFunction3() {
    this.dosageId = 0;
    this.clearFormDosage();
  }
  ResetFunction4() {
    this.freqId = 0;
    this.clearFormFrequency();
  }
  ResetFunction5() {
    this.ConsumptionId = 0;
    this.clearFormConsumption();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }

  openExcelUploadModal(){
    let initialState = {
      branchId: this.globals.HospitalId
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(ExcelDataFetchModalComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
    modalRef.content.updateEmitter.subscribe((res: any)=>{
      if(res.status){
        this.GetDrug();
        this.GetScientificNameGrid();
        this.GetTradeName();
      }
    })
  }

  openExpiredDrugs(){
    let initialState = {
      branchId: this.globals.HospitalId,
      todaysDate: this.datepipe.transform(new Date(), 'dd-MM-yyyy')
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(ExpiredDrugsModalComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
  }

  GetConsumptionlist(){
    let payload={
      branchId:this.globals.HospitalId,
    }
    this.configurationService.GetConsumptionlist(payload).subscribe({
      next:(res)=>{
        this.isConsumptionUnit=false;
        this.consumptionlist=res.response
      }
    })
  }

  SaveConsumption(){
    this.submitted5=true
    if(this.ConsumptionForm.valid){
      let payload={
        ...this.ConsumptionForm.value,
        IsDeleted:0,
        IsDisplayed:1,
        BranchId:this.globals.HospitalId,
        DrugTypeId:Number(this.ConsumptionForm.value.DrugTypeId),
        CUId:this.ConsumptionId
      }
      this.configurationService.UpdateConsumption(payload).subscribe({
        next:(res)=>{
         if( res.status==200){
          this.ResetFunction5()
          this.GetConsumptionlist()
          this.modalRefConsumption.hide()
         }
        }
      })
    }
  }

  getDrugTypeDescription(drugTypeId: number): string {
    const drugType = this.GetDrugTypeList.find(item => item.id === drugTypeId);
    return drugType ? drugType.descriptionData : '';
  }

  EditConsumptionOpen(data){
    // debugger
    const foundElement = this.functionPermissionData.find(x => x.id === 638)
    if (foundElement.id == 638 && foundElement.isAllowed == true) {
    this.ConsumptionForm.patchValue({
      DrugTypeId:data.drugTypeId,
      ConsumptionUnit:data.consumptionUnit
    })
    this.ConsumptionId =data.cuId
    this.consumptionPopupHeading="edit consumption unit";
    this.modalRefConsumption = this.modalService.show(this.modaltempConsumption);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  DeleteConsumption(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 639)
    if (foundElement.id == 639 && foundElement.isAllowed == true) {
    this.ConsumptionId =data.cuId
    let payload={
      ...this.ConsumptionForm.value,
      IsDeleted:1,
      IsDisplayed:1,
      BranchId:this.globals.HospitalId,
      DrugTypeId:Number(this.ConsumptionForm.value.DrugTypeId),
      CUId:this.ConsumptionId
    }
    this.configurationService.UpdateConsumption(payload).subscribe({
      next:(res)=>{
       if( res.status==200){
        this.ResetFunction5()
        this.GetConsumptionlist()
        this.toastr.success(res.message)
       }
      }
    })
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

 
    getConsumptionUnit(value?) {
      debugger
      const consumptionItem = this.consumptionlist.find(item => item.drugTypeId === Number(this.DrugForm.value.DrugType));
      this.UnitValue = consumptionItem ? consumptionItem.consumptionUnit : '';
    }
  
  
}
