import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import BaseImage from "../../../models/base-image.enum";
import { AddSketchService } from "../../client/consultantdesk/personalisation/add-sketch/add-sketch.service";
import { Location } from "@angular/common";
import Konva from "konva";
import { EMRPatientInfo } from "../interfaces/EMRPatientInfo";
import { EMRService } from "../../emr/services/emr.service";
import { SharedService } from "../../../shared/shared.service";
import { Subscription } from "rxjs";
import { globalvars } from "../../../shared/models/GlobalVars";
import { timeStamp } from "console";

@Component({
  selector: "app-emr-sketch",
  templateUrl: "./emr-sketch.component.html",
  styleUrls: ["./emr-sketch.component.scss"],
})
export class EmrSketchComponent implements OnInit {
  isLoading = false;
  subscription: Subscription;

  baseImage = BaseImage;
  baseImageList: any[] = [];
  indicatorsList: any[] = [];
  customImages: any[] = [];
  form: FormGroup;
  maploc: any;
  Id: any;
  EMRPatientInfo: EMRPatientInfo;
  patientId: any;
  ConsultantId: any;
  @ViewChild("container") container!: ElementRef<HTMLDivElement>;
  result: any;
  globals: globalvars = new globalvars();
  BranchesId: Number;
  GetSketchList: any;
  toggleBtn: boolean = false;
  tooggleMsg = "Eraser";

  constructor(
    private _drawService: AddSketchService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private _location: Location,
    private EMRService: EMRService,
    private sharedservice: SharedService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    this.customImages = this._drawService.customImages;
    this.Id = localStorage.getItem("Id");
    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
    this.patientId = this.EMRPatientInfo.PatientId;
  }

  ngOnInit(): void {
    this.getbaseImages();
    this.getIndicators();
    this.GetAllSketches();
    this.form = this.formBuilder.group({
      SketchName: [""],
      BodyPartId: [0],
    });
    this.ConsultantId = localStorage.getItem("Id");
  }

  ngAfterViewInit(): void {
    this._drawService.createStageInstance(this.container.nativeElement);
    //this._drawService.changeBaseImage(BaseImage.FullBody);
  }

  onBaseImageChange(event: any, type: BaseImage) {
    this._drawService.clear();
    this._drawService.changeBaseImage(type);
  }

  setDraggingElement(ev: any) {
    this._drawService.dragging = ev.currentTarget.dataset.type;
    this._drawService.indicatorsList = this.indicatorsList;
    if (ev.currentTarget.dataset.type === "pencil") {
      this.toggleBtn = true;
    } else {
      this.toggleBtn = false;
    }
  }

  resetDraggingElement(ev: any) {
    this._drawService.dragging = "";
  }

  toggleEraser() {
    this._drawService.toggleEraser();
    this.tooggleMsg = this._drawService.isEraserMode ? "Pencil" : "Eraser";
  }

  @HostListener("window:keydown.delete", ["$event"])
  deleteLayer(ev: KeyboardEvent) {
    this._drawService.deleteLayer();
  }

  @HostListener("window:keydown.esc", ["$event"])
  removeResizer(ev: KeyboardEvent) {
    this._drawService.removeResizer();
  }

  @HostListener("window:keydown.control.s", ["$event"])
  save(ev: KeyboardEvent) {
    ev.preventDefault();

    this._drawService.getImage((imageData) => {
      const a = document.createElement("a");
      a.download = `download-${new Date().toLocaleString()}`;
      a.href = imageData.src;
      a.click();
    });
  }

  getbaseImages() {
    console.log(this.EMRPatientInfo);

    this._drawService.GetBodyPart().subscribe((res) => {
      this.baseImageList = res.response;
    });
  }

  getIndicators() {
    this._drawService.getIndicators().subscribe((res) => {
      this.indicatorsList = res.response;
    });
  }
  // saveSketch(){
  // // var width = window.innerWidth;
  // // var height = window.innerHeight;

  // var stage = new Konva.Stage({
  //   container: 'container',
  //   width: 500,
  //   height: 600,
  // });

  // var layer = new Konva.Layer();
  // var json = stage.toJSON();

  //   console.log(json);

  // }

  loadBaseImage(id) {
    let item = this.baseImageList.filter((x) => x.bodyId == id)[0];
    if (item) {
      let image = item.bodyPartImageLocation;
      if (image) {
        this._drawService.clear();
        this._drawService.changeBaseImage(image);
      } else {
        alert("No image found");
      }
    } else {
      alert("No image found");
      this.toastr.error("failed");
    }
  }

  goback() {
    this._location.back();
  }
  saveSketch() {
    if (this.form.value.SketchName == "") {
      this.toastr.error("Please enter sketch name");
    } else {
      let saveData = this.form.getRawValue();
      this._drawService.getImage((imageData) => {
        const a = document.createElement("a");
        let image = imageData.src;
        saveData.Base64Img = image;
        saveData.PatientId = this.patientId;
        saveData.branchId = Number(localStorage.getItem("BranchId"));
        saveData.ConsultantId = Number(this.ConsultantId);
        this.isLoading = true;

        this._drawService.saveSketch(saveData).subscribe((res) => {
          this.result = res;

          if (this.result.status == 200) {
            this.isLoading = false;

            this.toastr.success("Added Successfully");
            this.GetAllSketches();
            this.clearForm();
          } else {
            this.isLoading = false;
            this.toastr.error("failed");
          }
        });
      });
    }
  }
  clearForm() {
    this.form.reset({
      SketchName: "",
    });
  }

  GetAllSketches() {
    this._drawService
      .GetSketches(Number(this.Id), this.BranchesId, this.patientId)
      .subscribe((res) => {
        this.GetSketchList = res.response;
      });
  }
  loadSavedImage(id) {
    let item = this.GetSketchList.filter((x) => x.id == id)[0];
    let savedImage = item.fileLocation;
    this._drawService.clear();
    this._drawService.changeBaseImage(savedImage);
  }
}
