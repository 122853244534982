import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss']
})
export class ViewReportComponent implements OnInit {
  barcodeSelect:any;
  regno:any;
  patientName:any;
  startDateFrom:any;
  barcodeInvNo:any;
  count=[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _location: Location

  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.barcodeSelect = this.router.getCurrentNavigation().extras.state.barcodeSelect;
      this.regno=this.router.getCurrentNavigation().extras.state.regno;
      this.patientName = this.router.getCurrentNavigation().extras.state.patientName;
      this.startDateFrom=this.router.getCurrentNavigation().extras.state.startDateFrom;
      this.barcodeInvNo = this.router.getCurrentNavigation().extras.state.barcodeInvNo;
      this.count=this.router.getCurrentNavigation().extras.state.count

    }

   }

  ngOnInit(): void {
    // this.activatedRoute.params.subscribe((params) => {
    //   this.barcodeSelect = this.barcodeSelect;
    //   this.regno =  this.regno;
      
    // });
    const stateData = JSON.parse(localStorage.getItem('state'));
  if (stateData) {
    this.barcodeSelect = stateData.barcodeSelect;
    this.regno = stateData.regno;
    this.patientName = stateData.patientName;
    this.startDateFrom = stateData.startDateFrom;
    this.barcodeInvNo = stateData.barcodeInvNo;
    this.count = stateData.count;
  }
  // Add the beforeunload event listener
  window.addEventListener('beforeunload', this.clearStateData);

  }
  printPage(){
    
    window.print();


}
goback() {
  this._location.back();
}

clearStateData = (event: BeforeUnloadEvent) => {
  // Remove the state data from the localStorage
  localStorage.removeItem('state');
};

ngOnDestroy() {
  // Remove the beforeunload event listener
  window.removeEventListener('beforeunload', this.clearStateData);
}
}
