import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationManager } from '../../../assets/configuration/configuration-manager';
import { Response } from '../../_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrl: any;

  constructor(    private http: HttpClient,
    private configurationManager: ConfigurationManager,
) {     this.baseUrl = (this.configurationManager as any).baseUrl;
}
  GetConsultationData(InputDetails) {
    var url = "api/MasterData/GetConsultationDashboardData";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetRegistartionData(InputDetails) {
    var url = "api/MasterData/GetPatientRegDashboardData";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetGeneralDashboardData(InputDetails) {
    var url = "api/MasterData/GetGeneralDashboardData";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetConsultationmonthData(InputDetails) {
    var url = "api/MasterData/GetConsultantDashboardData";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetAllConsultants(InputDetails) {
    var url = "api/Consultant/GetAllConsultants/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails);
  }

}
