import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { UserHospitals } from '../../client/interfaces/UserHospitals';
import { HospitalsService } from '../../client/services/hospitals.service';
import { LoginService } from '../../client/services/login.service';

import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../../app.component';
import arLogin from '../../../../assets/i18n/ar.json'
import enLogin from '../../../../assets/i18n/en.json'
import _ from 'lodash';

@Component({
  selector: 'app-Signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  loginForm: FormGroup;
  selectedItem: any = {};
  //userProfile: UserProfile[] = [];
  result: any;
  userType: string;
  loginName: string;
  id: string;
  userId: any;
  departmentName: string;
  deptId: string;
  token: string = "";
  submitted: boolean;
  displayModal: boolean = false;
  globalvars: globalvars = new globalvars();
  @ViewChild('username') username: ElementRef;
  @ViewChild('password') password: ElementRef;
  hospitalList: UserHospitals[];
  LocationList: any[];
  selectedLoc: any = {};
  greetings: number;
  greetingsImage: string = "";
  geertingsMsg: string = "";
  UserName: any;
  currentLanguage: string;
  isAprovedAuthority: any;
  staffId: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private loginServices: LoginService,
    private hospitalsService: HospitalsService,
    private sharedservice: SharedService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    this.useLanguage('en');
    this.submitted = false;
  }
  BranchModal() {
    this.displayModal = true;
    this.clear();
  }
  ngOnInit(): void {

    document.getElementById("userName").focus()

    // clear all localstorage
    // this.clearStorage(); // Remaped in logout component
    this.wish();
  }
  clear(){
    this.selectedItem = {
      hospitalId: '',
      hospitalName: ''
    }

    this.selectedLoc = {
      locationId: '',
      locationName: ''
    }
  }
  handleClick() {

    if (typeof this.selectedItem.hospitalId == 'undefined') {
      this.toastr.error("Please Select Branch");
    }
    else {

      if (typeof this.selectedLoc.locationId == 'undefined') {
        this.toastr.error("Please Select Location")
      }

      else {
        let hospital = _.filter(this.hospitalList, {hospitalId: Number(this.selectedItem?.hospitalId)})[0];
        let location = _.filter(this.LocationList, {locationId: Number(this.selectedLoc?.locationId)})[0];

        hospital.hospitalId = Number(hospital.hospitalId);
        location.locationId = Number(location.locationId);
        this.globalvars.HospitalId = hospital.hospitalId;
        this.globalvars.ClientId = hospital.clientId;
        this.globalvars.Location = location;
        this.globalvars.HospitalName = hospital.hospitalName + "(" + location.locationName + ")";
        this.sharedservice.setHospital(this.globalvars);
        localStorage.setItem('GLobalVarInfo', JSON.stringify(this.globalvars));
        if (localStorage.getItem("usertype") == "G") {
          this.router.navigate(['/todayspatient'])
        }
        else {
          this.router.navigate(['/FrontOffice']);
        }
      }
    }

  }
  clearStorage() {

    localStorage.clear();
    localStorage.removeItem("usertype");
  }
  GetLocationonUser(hospitalId) {
    var user = localStorage.getItem('user_id');
    if (typeof user != 'undefined') {
      this.hospitalsService.GetUserSpecificHospitalLocations(Number(user), Number(hospitalId)).subscribe((res) => {
        this.LocationList = res.response;
        let location = _.filter(this.LocationList, {isDefault: true});
        if(location.length){
          this.selectedLoc = location[0];
        }
        // this.selectedItem = _.filter(this.hospitalList, {hospitalId: Number(hospitalId)})[0];
      });
    }

  }
  GetUserHospitals() {
    var user = localStorage.getItem('user_id');
    if (typeof user != 'undefined' && user) {
      this.hospitalsService.GetUserSpecificHospitals(Number(user)).subscribe((res) => {
        this.hospitalList = res.response;
        let hospital = _.filter(this.hospitalList, {isDefault: true});
        if(hospital.length){
          this.selectedItem = hospital[0]
          this.GetLocationonUser(this.selectedItem.hospitalId);
        }
        //  if (
        //    typeof sessionStorage.getItem("globalvars") == "undefined" ||
        //    !sessionStorage.getItem("globalvars") ||
        //    sessionStorage.getItem("globalvars") == null
        //  ) {
        //    //alert("in")
        //    this.globalvars.HospitalId= this.hospitalList[0].hospitalId;
        //   this.globalvars.HospitalName= this.hospitalList[0].hospitalName;
        //    this.sharedservice.setHospital(this.globalvars);
        //  }
      });
    }

  }

  //sign in call function from api and getting user details
  Login() {
    this.ngxLoader.start();
    var username = this.username.nativeElement.value;// this.loginForm.get("username").value;
    var password = this.password.nativeElement.value;
    if (username == '' || password == '') {
      this.toastr.clear();
      this.toastr.error('Username and Password Required');
      return false;
    }
    // this.loginForm.get("password").value;
    this.submitted = true;
    this.toastr.clear();
    this.loginServices.Login(username, password).subscribe((res) => {
      this.result = res;
      if (this.result.response.length > 0) {
        let active = this.result.response[0].userActive;
      }

      // this.userProfile = res.response;
      this.userType = res.response.map(function (a) { return a["usertype"]; })[0];
      this.loginName = res.response.map(function (a) { return a["usersname"]; })[0];
      this.UserName = res.response.map(function (a) { return a["username"]; })[0];
      this.userId = res.response.map(function (a) { return a["userId"]; })[0];
      this.id = res.response.map(function (a) { return a["id"]; })[0];
      this.departmentName = res.response.map(function (a) { return a["deptName"]; })[0];
      this.deptId = res.response.map(function (a) { return a["deptId"]; })[0];
      this.token = res.response.map(function (a) { return a["token"]; })[0];
      this.isAprovedAuthority = res.response.map(function (a) { return a["isAprovedAuthority"]; })[0];
      this.staffId = res.response.map(function (a) { return a["staffId"]; })[0];
      if (this.result.status == 200 && this.result.message == "success") {
        this.submitted = false;
        this.ngxLoader.stop();
        if (res.response == '') {
          this.toastr.error("Incorrect username or password");
          // this.username.nativeElement.value='';
          // this.password.nativeElement.value='';
        }
        else if (res.response[0].userActive == "False") {
          this.toastr.error("User Is Inactive");
        }
        else {
          localStorage.setItem("usertype", this.userType);
          localStorage.setItem('sec_token', this.token);
          localStorage.setItem('logout', "0");

          if (localStorage.getItem("usertype") == "U") {
            localStorage.setItem('user_role', 'admin');
            localStorage.setItem('user_id', this.userId);
            localStorage.setItem('username', this.UserName);
            localStorage.setItem('Id', this.id);
            localStorage.setItem('departmentName', this.departmentName);
            localStorage.setItem('departmentId', this.deptId);
            localStorage.setItem('selectedMenu', '23');
            localStorage.setItem('staffId', this.staffId);

            // let token=res.response[0].token;
            // localStorage.setItem('sec_token',token);
            this.BranchModal();
            this.GetUserHospitals();
            // this.router.navigate(['/FrontOffice']);
          }
          else if (localStorage.getItem("usertype") == "S") {
            // localStorage.setItem('user_role', 'admin');
            localStorage.setItem('user_id', this.userId);
            localStorage.setItem('username', this.loginName);
            localStorage.setItem('Id', this.id);
            localStorage.setItem('departmentName', this.departmentName);
            localStorage.setItem('departmentId', this.deptId);
            localStorage.setItem('staffId', this.staffId);

            this.BranchModal();
            this.GetUserHospitals();
            // this.router.navigate(['/FrontOffice']);
          }
          else if (localStorage.getItem("usertype") == "G") {
            localStorage.setItem('user_role', 'consultant');
            localStorage.setItem('user_id', this.userId);
            localStorage.setItem('username', this.loginName);
            localStorage.setItem('Id', this.id);
            localStorage.setItem('departmentName', this.departmentName);
            localStorage.setItem('departmentId', this.deptId);
            localStorage.setItem('selectedMenu', '24');
            localStorage.setItem('isAprovedAuthority', this.isAprovedAuthority);
            localStorage.setItem('staffId', this.staffId);

            this.BranchModal();
            this.GetUserHospitals();
            // this.router.navigate(['/todayspatient']);
          }
          //this.BranchModal();
        }
      }
      else {
        this.submitted = false;
        this.ngxLoader.stop();
        this.toastr.error(this.result.message);
      }
      // else  if (this.result.status == 200 && this.result.message=="Invalid credentials") {
      //   this.submitted = false;
      //   this.ngxLoader.stop();
      //   this.toastr.error("Invalid Username Or Password");
      // }
      // else  if (this.result.status == 200 && this.result.message=="Invalid credentials") {
      //   this.submitted = false;
      //   this.ngxLoader.stop();
      //   this.toastr.error("Invalid Username Or Password");
      // }


    })
  }

  wish() {
    var day = new Date();
    var hr = day.getHours();

    if (hr >= 0 && hr < 12) {

      this.greetings = 1;
      this.greetingsImage = "assets/images/morning.png";
      if (this.currentLanguage == 'ar') {
        this.geertingsMsg = arLogin.login.geertingsMsg['Good Morning !'];
      } else if(this.currentLanguage == 'en') {
        this.geertingsMsg = enLogin.login.geertingsMsg['Good Morning !'];
      }
    } else if (hr == 12) {
      this.greetings = 2;
      this.greetingsImage = "assets/images/afternoon.png";
      if (this.currentLanguage == 'ar') {
        this.geertingsMsg = arLogin.login.geertingsMsg['Good Noon !'];
      } else if(this.currentLanguage == 'en') {
        this.geertingsMsg = enLogin.login.geertingsMsg['Good Noon !'];
      }

    } else if (hr >= 12 && hr <= 17) {

      this.greetingsImage = "assets/images/afternoon.png";
      this.greetings = 3;
      if (this.currentLanguage == 'ar') {
        this.geertingsMsg = arLogin.login.geertingsMsg['Good Afternoon !'];
      } else if(this.currentLanguage == 'en') {
        this.geertingsMsg = enLogin.login.geertingsMsg['Good Afternoon !'];
      }
    } else {
      this.greetingsImage = "assets/images/evening.png";

      this.greetings = 4;
      if (this.currentLanguage == 'ar') {
        this.geertingsMsg = arLogin.login.geertingsMsg['Good Evening !'];
      } else if(this.currentLanguage == 'en') {
        this.geertingsMsg = enLogin.login.geertingsMsg['Good Evening !'];
      }
    }
  }

  useLanguage(language: string) {
    this.currentLanguage = language;
    this.translate.use(language);
    this.wish();
    // const currentLang = this.translate.currentLang;

  }
}


