import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConsultationsList } from '../interfaces/ConsultationsList';
import { Response } from '../../../_Models/Response';
import { FormGroup } from '@angular/forms';
import { SponsorList } from '../interfaces/SponsorList';
import { RecentConsultationList } from '../interfaces/RecentConsultationList';
import { SchemeList } from '../interfaces/SchemeList';
import { DatePipe } from '@angular/common';
import { SymptomList } from '../interfaces/SymptomList';
import { ErrorHandleService } from './error-handle.service';
import { catchError, retry } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { AnyAaaaRecord } from 'dns';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ConsultaionsService {


  private baseUrl = "";

  constructor(
    private configurationManager: ConfigurationManager,
    private datePipe: DatePipe,
    private http: HttpClient,
    private errorHandle: ErrorHandleService,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }
  //Getting all sponsor list
  LoadSponsorTable(patientId: number) {
    var url = "api/TodaysPatient/GetSponsorListByPatientId/" + patientId
    return this.http.post<Response<SponsorList[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

  GetSponsorDetailsByPatient(payload: any): Observable<any>{
    var url = "api/Bill/GetSponsorDetailsByPatient";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  //Getting Symtoms
  GetTemplateReasonForvisit(BranchId, showall) {
    var url = "api/MasterData/GetSymptom"
    return this.http.post<Response<SchemeList[]>>(`${this.baseUrl}${url}`,
      {
        "SymptomId": 0,
        "ShowAll": showall,
        "BranchId": BranchId

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  //Getting token no by consultant
  GetTokenNo(consultantId: number, consultDate: any) {
    var url = "api/TodaysPatient/GetNewTokenNumber";
    return this.http.post<Response<SchemeList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": consultantId,
        "ConsultDate": consultDate
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  //Getting Recent Consultaions by Today
  GetRecentConsultations() {
    var url = "api/TodaysPatient/GetRecentConsultationData";
    return this.http.post<Response<RecentConsultationList[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  //Getting configuration for patients
  GetPatientConfig(consultantId: Number, patientId: any) {
    var url = "api/TodaysPatient/GetConsultationByPatientId";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "PatientId": patientId,
        "ConsultantId": consultantId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
   //Getting configuration for patients
   GetPatientConsultConfig(data:any) {
    var url = "api/Consultant/GetConsultationTypeByPatientId";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      data).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetRegDetails(patientId: any) {
    var url = "api/TodaysPatient/GetPatRegByPatientId";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "PatientId": patientId,
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  //Getting Consultaions by Today
  GetConsultations(date: string, status: string, ConsultantId: number, DepartmentId: number, BranchId: number) {
    var url = "api/TodaysPatient/GetConsultation";
    return this.http.post<Response<ConsultationsList[]>>(`${this.baseUrl}${url}`,
      {
        "DeptId": DepartmentId,
        "ConsultantId": ConsultantId,
        "ConsultantDate": date,
        "Status": status,
        "BranchId": BranchId
      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }



  GetConsultantScheme(consultantId = 0, BranchId: Number, emergency =0 ,data) {
    //var url = "api/TodaysPatient/GetSchemeByConsultant";
    var url = "api/Consultant/GetConsultantItemByType";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {
      "ConsultantId": Number(consultantId),
      "BranchId": BranchId,
      "Emergency": emergency,
      "CreditId": data.creditid,
      "DeptId": data.deptid,
      "IsFollowup":data.followup,
      "InsCovered":data.inscovered,
      "MasterItemId":data.mastermindId,
    }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  //getting all consultation for filling data  in the fields
  GetAllConsultation() {
    var url = "api/TodaysPatient/GetAllConsultation";
    return this.http.post<Response<ConsultationsList[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  //searching all consultation for filling data  in the fields
  SearchConsultation(searchForm: any, ConsultantId) {
    console.log(searchForm,'searchForm')
    var url = "api/TodaysPatient/SearchConsultation";

    let input = {
      "BranchId": Number(searchForm.get("sBranch").value),
      "PatientName": searchForm.get("sName").value.trim(),
      "RegNo": searchForm.get("sRegNo").value.trim(),
      "Mobile": searchForm.get("sMobile").value,
      "FromDate": searchForm.get("sDateFrom").value,
      "ToDate": searchForm.get("sDateTo").value,
      "ConsultantId": Number(searchForm.get("sconsultant").value)?Number(searchForm.get("sconsultant").value):0,
      "Address": searchForm.get("sAddress").value.trim(),
      "PIN": searchForm.get("sPin").value,
      "NationalId":searchForm.get("sEmiratesNo").value?searchForm.get("sEmiratesNo").value:"",
    }

    if (searchForm.get("sConsultant")) {
      input.ConsultantId = Number(searchForm.get("sConsultant").value);
    }

    return this.http.post<Response<ConsultationsList[]>>(`${this.baseUrl}${url}`, input
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );

  }

  //adding or update consultation
  SaveConsultation(form: any, consultFee: Number, emergency: any, branchesId) {
    let symptoms = form.get('Symptoms').value
    var url = "api/TodaysPatient/InsertUpdateConsultation";
    let date = this.datePipe.transform(new Date(), "dd-MM-yyyy hh:mm a");

    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultationId": 0,
        "AppId": Number(form.get("appId").value),
        "ConsultDate": date,
        "ConsultantId": Number(form.get("consultant").value),
        "PatientId": Number(form.get("patientId").value),
        "Symptoms": form.get('Symptoms').value,
        "ConsultFee": Number(consultFee),//Number(form.get("fee").value),
        // "ConsultType": form.get("patientId").value == "0" ? 1 : 2,
        "ConsultType": form.get("newConsultation").value == "0" ? 1 : 2,
        "EmerFee": 0,
        "Emergency": emergency,
        "ItemId": Number(form.get("scheme").value),
        "AgentId": 0,
        "LocationId": 1,
        CreditId: Number(form.get('CreditId').value),
        // "LeadAgentId": Number(form.get("refBy").value),
        "InitiateCall": 1,
        "otherreasonforvisit": form.get('otherreasonforvisit').value,
        "ConsentReason": form.get('ConsentReason').value,
        "UserId": Number(localStorage.getItem('user_id')),
        "Hook": form.get('hook').value?form.get('hook').value:"",
        "ReferedBy": form.get('ReferedBy').value?form.get('ReferedBy').value:"",
        "ConsentOptionId": form.get("ConsentOptionId").value !== null ?  Number(form.get("ConsentOptionId").value) : 0,
        //"SessionId": null,
        "BranchId": Number(branchesId),
        // "RetVal": 0,
        // "RetDesc": "",
        // "RetSeqNo": 0
      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

  GetConsultationDetailsbyId(consultationId: number) {
    var url = "api/TodaysPatient/GetConsultationDetailsbyId";
    return this.http.post<Response<ConsultationsList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultationId": consultationId,

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  ChangeAsUrgent(consultationId: Number, userId: Number, locationId: Number, branchId: Number) {
    var url = "api/TodaysPatient/SetUrgentConsultation";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "ConsultationId": consultationId,
        "LocationId": locationId,
        "UserId": userId,
        branchId: branchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  CancelConsultation(patientId: Number, blockReason: string) {
    var url = "api/TodaysPatient/CancelConsultation";
    return this.http.post<Response<ConsultationsList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultationId": patientId,
        "CancelReason": blockReason,
        "UserId": Number(localStorage.getItem('user_id'))
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  SaveReasonVisit(AppData) {
    var url = "api/TodaysPatient/UpdateConsultationSymptoms";
    return this.http.post<Response<ConsultationsList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultationId": AppData.ConsultationId,
        "OtherReasonForVisit": AppData.otherreasonforvisit,
        "Symptoms": AppData.Symptoms,

        // "UserId": Number(localStorage.getItem('user_id'))
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }
  billingInstructions(payload): Observable<Response<any>>{
    var url = "api/Consultant/UpdateBillingInstruction";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }


  Getsymptoms(consultationId: number) {

    var url = "api/TodaysPatient/GetConsultationDataById/" + consultationId;
    return this.http.post<Response<ConsultationsList[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  //

  GetConsultantConsultations(consultantId, date, branchId, status, DepartmentId) {
    var url = "api/Consultant/GetMyConsultations";
    return this.http.post<Response<ConsultationsList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": consultantId,
        "ConsultantDate": date,
        "BranchId": branchId,
        "Status": status,
        "DeptId": DepartmentId


      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
    //To display progressbar monitor
    GetProgressBar(input) {
      var url = "api/Consultant/GetFrontOfficeProgressBarByConsultantId";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input);
    }

    specialDiscount(input) {
      var url = "api/Consultant/UpdateSpecialDiscount";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input);
    }

    updateDefaultCredit(input) {
      var url = "api/Bill/UpdateDefaultCredit";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input);
    }

    updateConsultationStatus(input) {
      var url = "api/TodaysPatient/UpdateConsultationStatus";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input);
    }
    
    GetConsultationCount(input) {
      var url = "api/TodaysPatient/GetConsultationCount";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input);
    }


    saveConsultantDHALoginCredentials(input) {
      var url = "api/MasterData/InsertUpdateConsultantDHALogin";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input);
    }

    GetConsultantDHALoginCredentials(input) {
      var url = "api/MasterData/GetConsultantDHALoginCredentials";
      return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input);
    }

}
