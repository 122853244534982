import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthsGuard } from "../../auths.guard";
import { DashboardComponent } from "../client/dashboard/dashboard.component";
import { SignupComponent } from "../client/pages/signup/signup.component";
import { LocationTypesComponent } from "./location-types/location-types.component";
import { LocationComponent } from "./location/location.component";
import { MenuComponent } from "./menu/menu.component";
import { SubmenuComponent } from "./submenu/submenu.component";
import { UserGroupMenumapComponent } from "./user-group-menumap/user-group-menumap.component";
import { UserGroupsComponent } from "./user-groups/user-groups.component";
import { UserComponent } from "./user/user.component";

const routes: Routes = [
    {
      path: 'User',
      component: UserComponent,
      data: { title:'LeHealth | User', pageName: 'User' },
      canActivate : [AuthsGuard]
    },
    {
      path: 'LocationType',
      component: LocationTypesComponent,
      data: { title:'LeHealth | LocationType', pageName: 'Location Type' },
      canActivate : [AuthsGuard]
    },{
      path: 'Location',
      component: LocationComponent,
      data: { title:'LeHealth | Location', pageName: 'Location' },
      canActivate : [AuthsGuard]
    },
    {
      path: 'UserGroups',
      component: UserGroupsComponent,
      data: { title:'LeHealth | User Groups', pageName: 'User Groups' },
      canActivate : [AuthsGuard]
    },
    {
      path: 'SubMenu',
      component: SubmenuComponent,
      data: { title:'LeHealth | SubMenu', pageName: 'Sub Menu' },
      canActivate : [AuthsGuard]
    },
    {
      path: 'Menu',
      component: MenuComponent,
      data: { title:'LeHealth | Menu', pageName: 'Menu' },
      canActivate : [AuthsGuard]
    },
    {
      path: 'MenuMap',
      component: UserGroupMenumapComponent,
      data: { title:'LeHealth | MenuMap', pageName: 'Menu Map' },
      canActivate : [AuthsGuard]
    },
    ]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class UserPermissionRoutingModule {
    static components = [
      SignupComponent,
      DashboardComponent
  
    ];
  
  }