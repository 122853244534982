import { DatePipe } from "@angular/common";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import SignaturePad from "signature_pad";
import { globalvars } from "../../../../../shared/models/GlobalVars";
import { SharedService } from "../../../../../shared/shared.service";
import { PatientService } from "../../../services/patient.service";
import { ConfigurationService } from "../../../services/configuration.service";
import { ConsentService } from "../../../services/consent.service";
import { FormControl, FormGroup } from "@angular/forms";
import moment from "moment";
import { ConfigurationManager } from "../../../../../../assets/configuration/configuration-manager";

@Component({
  selector: "app-sponsor-consent",
  templateUrl: "./sponsor-consent.component.html",
  styleUrls: ["./sponsor-consent.component.scss"],
})
export class SponsorConsentComponent implements OnInit {
  @Input() ConsentPatientId: any;
  @Input() cType: string;
  @Input() SelectedSponsor: number;
  @Input() data: any;
  SelectedConsent: any;
  SelectedStaff: any;
  baseUrl: any;
  @ViewChild("canvas") canvasEl: ElementRef;
  @ViewChild("canvas1") canvasEl1: ElementRef;

  @ViewChild("print") print: ElementRef;

  signaturePad: SignaturePad;
  signaturePadWitness: SignaturePad;

  globals: globalvars = new globalvars();
  ConsentHistory: any;
  consentHistoryPatientName: any;
  consentHistorySignature: any;
  consentHistoryWitnessSignature: any;
  consentHistoryList: string;
  consentHistoryDate: any;
  consentHistoryListArabic: any;
  IsConsentShowing: boolean;
  IsConsentShow: boolean;
  historyconsent: boolean;
  Sponsors: any[];
  Staffs: any[];
  PatientConsents: any[];
  sponsorId: number;
  consentDate: string;
  isWitnessSign: boolean = false;
  isStaffSign: boolean = false;
  isConsultantSign: boolean = false;
  signatureImg: string;
  signatureWitnessImg: string;
  documentFile2: File;
  witnessSign: File;
  patientHeading: string;
  consentHistoryListTemp: string;
  // patientData: any;
  consentNameValue: any;
  consultantSign: any;
  witnessName: any;
  staffDtl: any;
  staffName: any;
  staffSign: any;
  logo: any;
  public PatientForm: FormGroup;
  consentHistoryPatientAge: any;
  consentHistoryPatientSex: any;
  consentHistoryPatientDob: any;
  isStaffSelected:boolean=false
  constructor(
    private patientService: PatientService,
    private sharedservice: SharedService,
    public modalRef: BsModalRef,
    private datepipe: DatePipe,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private ConsentService: ConsentService,
    private configurationManager: ConfigurationManager,
  ) {
    const subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchesId = this.globals.HospitalId
      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  ngOnInit(): void {
    this.consentDate = this.datepipe.transform(new Date(), "dd-MM-yyyy");
    this.getStaff();
    // if(this.data) {
    //   this.SelectedStaff
    // }
    this.PatientForm = new FormGroup({
      witnessName: new FormControl(""),
      staffId: new FormControl(""),
      ConsultantId: new FormControl(""),
    });
    console.log(this.cType,this.data)
  }

  ngAfterViewInit() {
    // this.GetSponsors();
    if (this.cType === "PC") {
      this.GetPatientConsent();
    } else {
      this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
      this.signaturePad.maxWidth = 0.9;
      this.consentHistory(this.ConsentPatientId, this.cType);
    }
  }
  GetPatientConsent() {
    this.ConsentService.GetPatientConsent(this.globals.HospitalId, 1).subscribe(
      (res) => {
        this.PatientConsents = res.response;
      }
    );
  }

  // GetSponsors() {
  //   this.patientService.GetSponsors(this.globals.HospitalId).subscribe((res) => {
  //     this.Sponsors = res.response;
  //     this.sponsorId = this.Sponsors['sponsorId'];
  //   });
  // }
  consentName(id: any) {
    this.consentNameValue = id;
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.signaturePad.maxWidth = 0.9;
    this.consentHistory(this.ConsentPatientId, this.cType);
  }
  consentSponsorHistory(sponserId) {
    var AppData = {
      consentType: "SC",
      patientId: Number(this.ConsentPatientId),
      branchId: Number(this.globals.HospitalId),
      sponsorId: Number(sponserId),
      contentId: Number(sponserId),
    };

    this.patientService.ConsentHistoryModal(AppData).subscribe((res) => {
      this.ConsentHistory = res.response;
      this.consentHistoryList = this.ConsentHistory[0]["consentContent"];
      this.consentHistoryListTemp = this.consentHistoryList;
      this.consentHistoryPatientName = this.ConsentHistory[0]["patientName"];
      this.consentHistoryDate = this.ConsentHistory[0]["dateOfConsent"];
      this.consentHistoryPatientAge = this.ConsentHistory[0]["age"];
      this.consentHistoryPatientSex = this.ConsentHistory[0]["gender"];
      this.consentHistoryPatientDob = this.ConsentHistory[0]["dob"];
      debugger

      if (this.ConsentHistory[0]["fileLoc"] !== "") {
        this.IsConsentShow = true;
        this.consentHistorySignature = this.ConsentHistory[0]["fileLoc"];
      } else {
        this.IsConsentShowing = true;
      }
    });
  }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }

  consentHistoryPrint(patient: any) {
    // window.print()
    let head = '';
    let printContents = "";
    // let tempSignature=this.consentHistorySignature.replace("/", "//");
    let staffId = this.staffDtl?.length
      ? this.staffDtl[0]?.staffId
      : this.ConsentHistory[0]?.staffId;
      let witnessSign;
      if (this.ConsentHistory[0]["witnessSign"] !== ""){
        witnessSign =this.baseUrl+this.consentHistoryWitnessSignature;
      }else{
        witnessSign = ""
      }
    // let witnessSign =this.baseUrl+this.consentHistoryWitnessSignature;
    let witnessName =this.witnessName
      ? this.witnessName
      : this.ConsentHistory[0]?.witnessName ;
    let consultantName = this.data?.consultantName
      ? this.data?.consultantName
      : this.data?.consultant;

    printContents =
      printContents +
      '<div style="padding: 30px; font-size: 12px;text-align: justify;"> <table class="print-table1"> <thead>';
    printContents =
      printContents +
      ' <tr><th style="display:flex;flex-direction:coloumn;gap:10px;margin-bottom:10px"><div style=" text-align: left;width:100%;"><img src="' +
      this.logo +
      '" alt="image" /></div></th></tr>';
    printContents =
      printContents +
      ' <tr style="display:flex;flex-direction:row;gap:40px;"><th style=" text-align: left;margin-bottom:10px"><div style="width:450px"><strong style="width:200px">Patient Name</strong>:' +
      this.consentHistoryPatientName +
      '</div></th><th style=" text-align: right;"><div style="width:200px"><strong>Date</strong>:' +
      this.consentDate +
      "</div</th></tr>";
    printContents =
      printContents +
      ' <tr style="display:flex;flex-direction:row;gap:40px;margin-bottom:20px;"><th style=" text-align: left;"><div style="width:450px"><strong style="width:250px">Date of Birth</strong>:' +
      this.ConsentHistory[0]?.dob +
      '</div></th><th style=" text-align: right;"><div style="width:200px"><strong>Reg No</strong>:' +
      this.ConsentHistory[0].regNo +
      "</div</th></tr>";
    printContents =
      printContents +
      "<tr><th> <p>" +
      this.consentHistoryList +
      "</p></th></tr>";

    printContents =
      printContents +
      ' <tbody><tr style="display: flex;flex-direction: row;gap:16px; flex-wrap:wrap;"><td style="display: flex;flex-direction: column; width:22%;"><div style="style="display: flex;flex-direction: column;width:400px"><strong>Patient Name :</strong><div style="width:400px">' +

      // ' <tbody><tr style="display: flex;flex-direction: row;gap:16px; flex-wrap:wrap;"><td style="display: flex;flex-direction: column; width:40%;"><div style="display: flex;flex-direction: row; flex-wrap: wrap;"><strong>Patient Name :</strong><div>' +
      this.consentHistoryPatientName +
      "</div><div>";

      printContents =
      printContents +
      ' <tbody><tr style="display: flex;flex-direction: row;gap:16px; flex-wrap:wrap;"><td style="display: flex;flex-direction: column; width:22%;"><div style="style="display: flex;flex-direction: column;width:400px"><strong>Age :</strong><div style="width:400px">' +

      // ' <tbody><tr style="display: flex;flex-direction: row;gap:16px; flex-wrap:wrap;"><td style="display: flex;flex-direction: column; width:40%;"><div style="display: flex;flex-direction: row; flex-wrap: wrap;"><strong>Patient Name :</strong><div>' +
      this.consentHistoryPatientAge +
      "</div><div>";

      printContents =
      printContents +
      ' <tbody><tr style="display: flex;flex-direction: row;gap:16px; flex-wrap:wrap;"><td style="display: flex;flex-direction: column; width:22%;"><div style="style="display: flex;flex-direction: column;width:400px"><strong>Sex :</strong><div style="width:400px">' +

      // ' <tbody><tr style="display: flex;flex-direction: row;gap:16px; flex-wrap:wrap;"><td style="display: flex;flex-direction: column; width:40%;"><div style="display: flex;flex-direction: row; flex-wrap: wrap;"><strong>Patient Name :</strong><div>' +
      this.consentHistoryPatientSex +
      "</div><div>";

    // printContents = printContents + ' <div><strong>Date :</strong> ' + this.consentDate + '</div> ' ;
    if (this.consentHistorySignature !== "") {
      printContents =
        printContents +
        ' <div style="display:flex;align-items:Start;"><span> <strong style="margin-top:50px;">Sign</strong></span>:' +
        '<span><img style="width: 60px;height: 50px;" src="' +
        this.consentHistorySignature +
        '" /></span>' +
        "</div></td>";
    } else {
      printContents =
        printContents +
        "<div ><strong>Sign</strong>: ................</div></td>";
    }
    if (this.isWitnessSign) {
      printContents =
        printContents +
        '<td style="display: flex;flex-direction: column; width:22%;"><div style="style="display: flex;flex-direction: column;width:400px"><strong>Witness Name :</strong><div style="width:400px">' +
        witnessName +
        "</div></div>";
      if (witnessSign !== "") {
        printContents =
          printContents +
          ' <div style="display:flex;align-items:Start;"><span> <strong style="margin-top:50px;">Sign</strong></span>:' +
          '<span><img style="width: 60px;height: 50px;" src="' +
          witnessSign +
          '" /></span>' +
          "</div></td>";
      } else {
        printContents =
          printContents +
          "<div ><strong>Sign</strong>: ................</div></td>";
      }
    }
    if (this.isStaffSign) {
      printContents =
        printContents +
        '<td style="display: flex;flex-direction: column; width:22%;"><div style="style="display: flex;flex-direction: column;width:400px"><strong>Staff Name :</strong><div style="width:400px">' +
        this.staffName +
        "</div></div>";
      printContents =
        printContents +
        ' <div style="display:flex;align-items:Start;"><span> <strong style="margin-top:50px;">Sign</strong></span>:' +
        '<span><img style="width: 60px;height: 50px;" src="' +
        this.staffSign +
        '" /></span>' +
        "</div></td>";
    }
    if(this.isConsultantSign) {
      printContents =
        printContents +
        '<td style="display: flex;flex-direction: column; width:22%;"><div style="style="display: flex;flex-direction: column;width:400px"><strong>Consultant Name :</strong><div style="width:400px">' +
        consultantName +
        "</div></div>";
      if (this.consultantSign !== "") {
        printContents =
          printContents +
          ' <div style="display:flex;align-items:Start;"><span> <strong style="margin-top:50px;">Sign</strong></span>:' +
          '<span><img style="width: 60px;height: 50px;" src="' +
          this.baseUrl+this.consultantSign +
          '" /></span>' +
          "</div></td>";
      } else {
        printContents =
          printContents +
          "<div ><strong>Sign</strong>: ................</div></td></tr></tbody></table></div>";
      }
    }

    var mywindow = window.open("", "PRINT", "height=1000,width=1200");
    mywindow.document.write(
      '<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' +
        '&nbsp' +
        "</title>"
    );
    // mywindow.document.write(
    //   '<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">'
    // );
    mywindow.document.write("</head><body >");
    mywindow.document.write(printContents);

    mywindow.document.write("</body></html>");
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;

    // myWindow.print();
  }

  getStaff() {
    this.configurationService
      .GetStaff(this.globals.HospitalId)
      .subscribe((res) => {
        this.Staffs = res.response;
        if (this.Staffs.length == 0) {
          this.toastr.warning("No Staff found");
        }
      });
  }
  savePad() {
    if (this.signaturePad.isEmpty() == true) {
      this.toastr.warning("Signpad is empty!");
    } else {
      this.ConsentHistory;
      const signName = "sign.jpeg";
      const base64Data = this.signaturePad.toDataURL();

      let signsplit = base64Data.split(";");

      const signBlob = this.dataURItoBlob(signsplit[1].split(",")[1]);

      const signFile = new File([signBlob], signName, { type: "image/jpeg" });

      this.documentFile2 = signFile;

      const formData = new FormData();
      formData.append("Sign", this.documentFile2, this.documentFile2.name);

      var AppData: any;
      let TypeOfConsent = this.cType;
      if (TypeOfConsent == "PC") {
        AppData = {
          patientId: this.ConsentPatientId,
          ConsentId: this.consentNameValue,
          sponsorId: 0,
          BranchId: this.globals.HospitalId,
          TypeOfConsent: TypeOfConsent,
          StaffId: this.staffDtl?.length
            ? this.staffDtl[0].staffId
            : this.ConsentHistory[0].staffId,
          WitnessName: this.witnessName
            ? this.witnessName
            : this.ConsentHistory[0].witnessName,
          ConsultantId: this.data?.consultantId,
          consentContent: this.consentHistoryList,
          ConsultationId: this.data?.consultationId,
          Treatments:''
        };
        // witness sign
        if (this.isWitnessSign) {
          const signName1 = "witnessSign.jpeg";
          const base64Data1 = this.signaturePadWitness.toDataURL();

          let signsplit1 = base64Data1.split(";");

          const signBlob1 = this.dataURItoBlob(signsplit1[1].split(",")[1]);

          const signFile1 = new File([signBlob1], signName1, {
            type: "image/jpeg",
          });

          this.witnessSign = signFile1;
          formData.append(
            "WitnessSign",
            this.witnessSign,
            this.witnessSign.name
          );
        } else {
          formData.append("WitnessSign", null);
        }
      } else if (TypeOfConsent == "IC") {
        AppData = {
          patientId: this.ConsentPatientId,
          ConsentId: 0,
          sponsorId: 0,
          BranchId: this.globals.HospitalId,
          TypeOfConsent: TypeOfConsent,
          consentContent: this.consentHistoryList,
          StaffId: 0,
          WitnessName: "",
          ConsultantId: 0,
          ConsultationId: this.data?.consultationId,
          Treatments:''
        };
      } else if (TypeOfConsent == "SC") {
        AppData = {
          patientId: this.ConsentPatientId,
          ConsentId: 0,
          sponsorId: this.SelectedSponsor,
          BranchId: this.globals.HospitalId,
          TypeOfConsent: TypeOfConsent,
          consentContent: this.consentHistoryList,
          StaffId: 0,
          WitnessName: "",
          ConsultantId: 0,
          ConsultationId: this.data?.consultationId,
          Treatments:''
        };
      }
      formData.append("ConsentJson", JSON.stringify(AppData));
      this.patientService.ConsentFormDataSave(formData).subscribe((res) => {
        if (res.status == 200) {
          this.consentHistory(this.ConsentPatientId, TypeOfConsent);
          this.toastr.success("Saved Your Signature  successfully");
          this.clearPad();
          // this.consentSponsorHistory(Number(this.sponsorId));
        } else {
          this.toastr.error("Failed.Please try later");
          //this.clearPad()
        }
      });
    }
  }
  staffUpdate(i) {
    this.Staffs.map((res: any) => {
      if (res.staffId === Number(i)) {
        this.staffDtl = [];
        this.staffDtl.push(res);
      }
    });
    console.log(this.staffDtl);
    this.staffSign=this.staffDtl[0]?.signatureLoc
  }

  consentHistory(patientId: any, cType) {
    // this.patientData=patient
    this.cType = cType;
    this.clearPad();
    let sponserId = 0;
    if (cType == "PC") {
      this.patientHeading = "Patient Consent";
    } else if (cType == "IC") {
      this.patientHeading = "Informed Consent";
    } else if (cType == "SC") {
      this.patientHeading = "Sponsor Consent";
      sponserId = this.SelectedSponsor;
      // your sponserid here
    }
    var AppData = {
      consentType: cType,
      patientId: Number(patientId),
      branchId: Number(this.globals.HospitalId),
      sponsorId: Number(sponserId),
      ContentId: this.consentNameValue ? Number(this.consentNameValue) : 0,
      ConsultationId: Number(this.data?.consultationId),
    };
    this.historyconsent = true;
    this.ConsentPatientId = patientId;

    this.patientService.ConsentHistoryModal(AppData).subscribe((res) => {
      this.ConsentHistory = [];
      this.ConsentHistory = res.response;
      this.consentHistoryList = this.ConsentHistory[0]["consentContent"];
      this.consentHistoryListTemp = this.consentHistoryList;
      this.consentHistoryPatientName = this.ConsentHistory[0]["patientName"];
      this.consentHistoryDate = this.ConsentHistory[0]["dateOfConsent"];
      this.consentHistoryPatientAge = this.ConsentHistory[0]["age"];
      this.consentHistoryPatientSex = this.ConsentHistory[0]["gender"];
      this.consentHistoryPatientDob = this.ConsentHistory[0]["dob"];

      this.isWitnessSign =
        this.ConsentHistory[0]?.isWitnessSign == 1 ? true : false;
      this.isStaffSign =
        this.ConsentHistory[0]?.isStaffSign == 1
          ? true
          : this.ConsentHistory[0].staffId != 0
          ? true
          : false;
      this.consentHistoryWitnessSignature = "";
      this.isConsultantSign = this.ConsentHistory[0]?.isConsultantSign
      this.consentHistorySignature = "";
      this.witnessName = "";
      this.consultantSign = this.ConsentHistory[0]?.consultantSign == 1 ? true : false;
      this.staffSign = "";
      this.logo = this.ConsentHistory[0]?.reportLogo;

      if (this.isStaffSign) {
        this.Staffs.map((res: any) => {
          if (res.staffId === this.ConsentHistory[0]?.staffId) {
            this.staffSign = res.signatureLoc;
          }
        });
        if (this.ConsentHistory[0].witnessSign != "") {
          this.SelectedStaff = this.ConsentHistory[0].staffId;
          // this.staffSign = this.ConsentHistory[0].staffSign
        }
      }
      //  this.consentHistorySignature="https://testapi.lehealthcloud.com/uploads/documents/96293714-5617-4fc5-9d54-4eae77b19394.jpeg"
      if (this.ConsentHistory[0]["fileLoc"] !== "") {
        this.IsConsentShow = true;
        this.consentHistorySignature = this.ConsentHistory[0]["fileLoc"];
      } else {
        this.IsConsentShowing = true;
        this.consentHistorySignature = "";
      }
      if (this.ConsentHistory[0]["witnessSign"] !== "") {
        this.IsConsentShow = true;
        this.consentHistoryWitnessSignature =
          this.ConsentHistory[0]["witnessSign"];
        this.witnessName = this.ConsentHistory[0]["witnessName"];
        // this.signaturePadWitness = new SignaturePad(this.canvasEl1?.nativeElement);
        // this.signaturePadWitness.maxWidth = 0.9;
      }
      if (this.ConsentHistory[0]["staffSign"] !== "") {
        // this.staffSign = this.ConsentHistory[0]["staffSign"];
        this.staffName = this.ConsentHistory[0]["staffName"];
      }
      if (this.isWitnessSign) {
        this.signaturePadWitness = new SignaturePad(
          this.canvasEl1?.nativeElement
        );
        this.signaturePadWitness.maxWidth = 0.9;
      }
       if (this.isStaffSign) {
        this.isStaffSelected = true;
      }else if (this.isWitnessSign) {
        this.isStaffSelected = false;
      }
    });
  }

  printbutton() {}

  close() {
    this.modalRef.hide();
  }

  getStaffName(sId){
    let staf= this.Staffs.find(item => item.staffId == sId)
    return staf ? staf.name : ""
  }
}
