import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TreatmentsService } from '../../services/treatments.service';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../../../_Models/Response';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';

@Component({
  selector: 'app-health-summary-modal',
  templateUrl: './health-summary-modal.component.html',
  styleUrls: ['./health-summary-modal.component.scss']
})
export class HealthSummaryModalComponent implements OnInit {
  
  @Input() patientId: any;
  @Input() branchId: any;

  public healthSummary: any;
  public healthSummaryLoading: boolean = false;
  public summaryForm: FormGroup;
  public today = new Date();

  private baseUrl: string = '';

  constructor(private modalRef: BsModalRef,
              private treatmentService: TreatmentsService,
              private formBuilder: FormBuilder,
              private configurationManager: ConfigurationManager,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.initSummaryForm();
    this.gethealthSummaryReport();

    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  initSummaryForm(){
    this.summaryForm = this.formBuilder.group({
      printAllergy: new FormControl(true),
      printMedication: new FormControl(true),
      printDisease: new FormControl(true),
      printVaccine: new FormControl(true),
      visitId: 0,
      patientId: Number(this.patientId),
      branchId: Number(this.branchId),
    })
  }

  gethealthSummaryReport(){
    let payload = {
      PatientId: this.patientId
    }

    this.healthSummaryLoading = true;
    this.treatmentService.getHealthSummaryChart(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.healthSummary = response.response[0]
        }else{
          this.toastrService.error(response.errorMessage.message)
        }
        this.healthSummaryLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again.');
        this.healthSummaryLoading = false;
      }
    })
  }

  onPrintSummaryReport(){
    const payload = {
      VisitId: this.summaryForm.controls.visitId.value,
      PatientId: this.summaryForm.controls.patientId.value,
      BranchId: this.summaryForm.controls.branchId.value,
      OpenAllergy: this.summaryForm.controls.printAllergy.value ? 1 : 0,
      OpenMedication: this.summaryForm.controls.printMedication.value ? 1 : 0,
      OpenActiveDisease: this.summaryForm.controls.printDisease.value ? 1 : 0,
      OpenVaccine: this.summaryForm.controls.printVaccine.value ? 1 : 0
    }

    this.treatmentService.printHealthSummaryReport(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          const url = this.baseUrl + response.response[0].reportPath
          window.open(url, '_blank');
        }else{
          this.toastrService.error(response.errorMessage.message)
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong.. Try again');
      }
    })
  }

  close(){
    this.modalRef.hide();
  }

}
