import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-resettle-advance-modal',
  templateUrl: './resettle-advance-modal.component.html',
  styleUrls: ['./resettle-advance-modal.component.scss']
})
export class ResettleAdvanceModalComponent implements OnInit {

  @Output() advConfirmEmitter = new EventEmitter();
  @Input() advanceAmt;

  constructor(
    private activeModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.advConfirmEmitter.emit({status: false});
    this.activeModalRef.hide();
  }
  
  save(){
    this.advConfirmEmitter.emit({status: true});
    this.activeModalRef.hide();
  }

}
