import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { PrevVitalSignsModalComponent } from "../modals/prev-vital-signs-modal/prev-vital-signs-modal.component";
import { EMRPatientInfo } from "../../interfaces/EMRPatientInfo";
import { globalvars } from "../../../../shared/models/GlobalVars";
import { Observable, Observer, Subscription, noop, of } from "rxjs";
import { EMRService } from "../../services/emr.service";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "../../../../shared/shared.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Response } from "../../../../_Models/Response";
import * as _ from "lodash";
import { DatePipe } from "@angular/common";
import { map, switchMap, take, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ConfigurationManager } from "../../../../../assets/configuration/configuration-manager";
import { PrevSignsSymptomsIcdModalComponent } from "../modals/prev-signs-symptoms-icd-modal/prev-signs-symptoms-icd-modal.component";
import { Settings } from "../../../settings/model/settings.model";
import { ConfirmModalComponent } from "../../../../shared/modals/confirm-modal/confirm-modal.component";

@Component({
  selector: "app-signs-symptoms-icd",
  templateUrl: "./signs-symptoms-icd.component.html",
  styleUrls: ["./signs-symptoms-icd.component.scss"],
})
export class SignsSymptomsIcdComponent implements OnInit, OnDestroy,OnChanges {

  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  @Input() addDisease:number
  disabled:boolean=false
  
  public EmrPatientInfo: EMRPatientInfo;
  private globals: globalvars = new globalvars();
  private subscription: Subscription;

  public symptomsObservable$?: Observable<any[]>;
  public signsObservable$?: Observable<any[]>;
  public icdObservable$?: Observable<any[]>;

  public signsSymptomsForm: FormGroup;
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "diseaseId",
    textField: "diseaseDesc",
    enableCheckAll: false,
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  public allDiseases: any[] = [];
  public FormValueChangeChecker: boolean = false;

  public SelectedIcdList: any[] = [];

  public symptomsSearch: string = "";
  public signsSearch: string = "";
  public icdSearch: string = "";

  private baseUrl: string = "";

  // @ViewChild('since') since: ElementRef;
  // @ViewChild('duration') duration: ElementRef;
  @Output() icdVal = new EventEmitter<any>();

  @ViewChildren("since") since: QueryList<ElementRef>;
  @ViewChildren("sinceSign") sinceSign: QueryList<ElementRef>;

  constructor(
    private modalService: BsModalService,
    private EmrService: EMRService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private datePipe: DatePipe,
    private http: HttpClient,
    private configurationManager: ConfigurationManager,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
      }
    });

    this.EmrPatientInfo = this.EmrService.GetEMRPatientInfo();
    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.initForm();
    this.GetSignSymptomAndICDS();

    this.searchSymptoms();
    this.searchSigns();
    this.searchIcds();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 40 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (isSettingWithValueZeroPresent) {
        // Your logic when a setting with value 0 is present and billEdit is true
        // this.signsSymptomsForm.disable()
        this.disabled=true
      }
      
    }
    if(changes.addDisease){
      if(this.addDisease>0){
        this.getConsultantDiseases()
      }
    }
  }

  GetSignSymptomAndICDS() {
    let input = {
      VisitId: this.EmrPatientInfo.VisitId,
      PatientId: this.EmrPatientInfo.PatientId,
      ShowAll: 1,
      ConsultationId: this.EmrPatientInfo.ConsultationId,
    };

    this.EmrService.GetNarrativeDiagonosis(input).subscribe((res) => {
      
      if (res.status == 200) {
        // debugger
        let response = res.response[0];
        if (response) {
          this.signsSymptomsForm.patchValue(response);
          this.signsSymptomsForm.patchValue({
            ObservationNote: response.observationNote,
          });
          this.addMapFormGroup(
            response.patSymptomList ? response.patSymptomList : []
          );
          this.addSignFormGroup(
            response.patSignList ? response.patSignList : []
          );
          if (response.icdLabelList) {
            this.SelectedIcdList = response.icdLabelList.map((icd: any) => {
              return {
                ...icd,
                icdType: icd.icdType == 0 ? "" : icd.icdType,
                onsetDate:icd.onsetDate
              };
            });
          }
        }
        this.getConsultantDiseases();
        this.signsSymptomsForm.valueChanges.subscribe((x) => {
          this.FormValueChangeChecker = true;
        });
      }
    });
    this.getConsultantDiseases();
  }

  searchSymptoms() {
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.symptomsObservable$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.symptomsSearch);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<any[]>>(
              `${this.baseUrl}api/MasterData/GetSymptomEMR`,
              {
                SymptomId: 0,
                ShowAll: 0,
                BranchId: this.globals.HospitalId,
                SymptomDesc: this.symptomsSearch,
              }
            )
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  let errorMessage =
                    (err && err.message) || "Something goes wrong";
                  this.toastrService.error(errorMessage);
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  searchSigns() {
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.signsObservable$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.signsSearch);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<any[]>>(`${this.baseUrl}api/MasterData/GetSign`, {
              Id: 0,
              ShowAll: 1,
              BranchId: this.globals.HospitalId,
              SignDesc: this.signsSearch,
            })
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  let errorMessage =
                    (err && err.message) || "Something goes wrong";
                  this.toastrService.error(errorMessage);
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  searchIcds() {
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.icdObservable$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.icdSearch);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<[]>>(`${this.baseUrl}api/MasterData/GetICDLabel`, {
              LabelDesc: query,
              LabelId: 0,
              ShowAll: 0,
              BranchId: this.globals.HospitalId,
            })
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  let errorMessage =
                    (err && err.message) || "Something goes wrong";
                  this.toastrService.error(errorMessage);
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  initForm() {
    this.signsSymptomsForm = new FormGroup({
      nid: new FormControl(0),
      narrativeDiagnosis: new FormControl(""),
      ObservationNote: new FormControl(""),
      icdLabelList: new FormArray([]),
      visitId: new FormControl(this.EmrPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
      patSymptomList: new FormArray([]),
      PatientId: new FormControl(this.EmrPatientInfo.PatientId),
      ConsultationId: new FormControl(this.EmrPatientInfo.ConsultationId),
      EntryDate: new FormControl(""),
      patDiseaseList: new FormControl([]),
      patSignList: new FormArray([]),
    });
  }

  getConsultantDiseases() {
    let payload = {
      ConsultantId: Number(localStorage.getItem("Id")),
    };

    this.EmrService.getDiseases(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200) {
          this.allDiseases = response.response;
        } else {
          this.toastrService.error(response.errorMessage.message);
        }
      },
      error: (error: any) => {
        this.toastrService.error("Something wents  wrong, Try again..!");
      },
    });
  }

  openHistory() {
    let initialState = {
      EmrPatientInfo: this.EmrPatientInfo,
    };
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-scrollable",
    };
    const historyModalRef = this.modalService.show(
      PrevSignsSymptomsIcdModalComponent,
      Object.assign({ class: "modal-dialog-centered " }, historyConfig, {
        class: "modal-lg",
        initialState,
      })
    );
  }

  onSelectDisease(disease: any, isSelected: boolean) {
    let payload = {
      diseasId: disease.diseaseId,
    };

    this.EmrService.getDetailsOfDisease(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200) {
          const data = response.response[0];
          if (isSelected) {
            this.addMapFormGroup(data.symptomList);
            this.addSignFormGroup(data.signList);
            _.forEach(data.icdLabelList, (icd: any) => {
              this.LoadSelectedICD(icd);
            });
          } else {
            _.forEach(data.symptomList, (symptom: any) => {
              this.removeSymptomFromList(symptom);
            });

            _.forEach(data.signList, (sign: any) => {
              this.removeSignFromList(sign);
            });

            _.forEach(data.icdLabelList, (icd: any) => {
              // this.RemoveFromIcdList(icd.labelId)
              this.RemoveIcdOnDiseaseDelete(icd.labelId);
            });
          }
        } else {
          this.toastrService.error(response.errorMessage.message);
        }
      },
      error: (error: any) => {
        this.toastrService.error("Something wents  wrong, Try again..!");
      },
    });
  }

  RemoveIcdOnDiseaseDelete(labelId) {
    const index = this.SelectedIcdList.findIndex((x) => x.labelId === labelId);
    this.SelectedIcdList.splice(index, 1);
    this.FormValueChangeChecker = true;
  }

  onSelectSymptom(symptom) {
    this.addMapFormGroup(symptom);
    this.FormValueChangeChecker = true;
    setTimeout(() => {
      this.since.last.nativeElement.focus();
    }, 300);
  }
  valueEnter(i: any) {
    if (i) {
      setTimeout(() => {
        this.sinceSign.last.nativeElement.focus();
      }, 300);
    }
  }
  public addMapFormGroup(data: any = [], saved = 0) {
    const symptoms = this.signsSymptomsForm.get("patSymptomList") as FormArray;
    if (data != null) {
      const values = Object.keys(data).map((key) => data[key]);
      if (data.length > 0) {
        data.forEach((element, i) => {
          let filteredArray = symptoms.value.filter(
            (value) => value.symptomId == element.symptomId
          );
          if (filteredArray.length == 0) {
            symptoms.insert(0, this.createListGroup(element, saved));
            // this.since.nativeElement.focus();
            // this.duration.nativeElement.focus();
          } else {
            this.toastrService.error("Failure: " + "Symptom Already added");
          }
        });
      } else if (values.length > 0) {
        let filteredArray = symptoms.value.filter(
          (value) => value.symptomId == data.symptomId
        );

        if (filteredArray.length == 0) {
          symptoms.insert(symptoms.length, this.createListGroup(data, saved));
          // this.since.nativeElement.focus();
        } else {
          //this.toastr.error("Failure: " + "Service Items Already added");
        }
      }
    }

    this.symptomsSearch = "";
  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    return new FormGroup({
      entryId: new FormControl(data["entryId"] ? data["entryId"] : 0),
      symptomId: new FormControl(data["symptomId"]),
      symptomDesc: new FormControl(data["symptomDesc"]),
      status: new FormControl(data["status"] ? data["status"] : "A"),
      duration: new FormControl(data["duration"] ? data["duration"] : "1"),
      since: new FormControl(data["since"] ? data["since"] : "1"),
    });
  }

  removeSymptomFromList(symptom: any) {
    var Value = new FormArray([]);
    Value = this.signsSymptomsForm.get("patSymptomList") as FormArray;
    let index = _.findIndex(Value.value, (data: any) => {
      return symptom.symptomId == data.symptomId;
    });
    if (index >= 0) {
      Value.removeAt(index);
      this.FormValueChangeChecker = true;
    }
  }

  onSelectSign(sign) {
    this.addSignFormGroup(sign);
    this.FormValueChangeChecker = true;
    // setTimeout(()=>{
    //   this.sinceSign.last.nativeElement.focus()
    // },300)
  }

  public addSignFormGroup(data: any = [], saved = 0) {
    const signs = this.signsSymptomsForm.get("patSignList") as FormArray;
    if (data != null) {
      const values = Object.keys(data).map((key) => data[key]);
      if (data.length > 0) {
        data.forEach((element, i) => {
          let filteredArray = signs.value.filter(
            (value) => value.signId == element.signId
          );
          if (filteredArray.length == 0) {
            signs.insert(0, this.createSignListGroup(element, saved));
          }
        });
      } else if (values.length > 0) {
        let filteredArray = signs.value.filter(
          (value) => value.signId == data.signId
        );

        if (filteredArray.length == 0) {
          signs.insert(signs.length, this.createSignListGroup(data, saved));
        }
      }
    }
    this.signsSearch = "";
  }

  private createSignListGroup(data: any[] = [], saved = 0): FormGroup {
    return new FormGroup({
      entryId: new FormControl(0),
      signId: new FormControl(data["signId"]),
      signDesc: new FormControl(data["signDesc"]),
      status: new FormControl(data["status"] ? data["status"] : "A"),
      duration: new FormControl(data["duration"] ? data["duration"] : "1"),
      since: new FormControl(data["since"] ? data["since"] : "1"),
      entryDate: new FormControl(
        this.datePipe.transform(new Date(), "dd-MM-yyyy")
      ),
    });
  }

  removeSignFromList(symptom: any) {
    var Value = new FormArray([]);
    Value = this.signsSymptomsForm.get("patSignList") as FormArray;
    let index = _.findIndex(Value.value, (data: any) => {
      return symptom.signId == data.signId;
    });
    if (index >= 0) {
      Value.removeAt(index);
      this.FormValueChangeChecker = true;
    }
  }

  LoadSelectedICD(item) {
    // console.log(item)
    let itemId = item.labelId;
    if (itemId > 0) {
      if (item) {
        let exist = 0;
        // let icdContradictory = 0;
        if (this.SelectedIcdList)
          exist = this.SelectedIcdList.filter(
            (x) => x.labelId == item.labelId
          )[0];
            // console.log(item,this.SelectedIcdList)
        if (exist) {
          this.toastrService.error("Failure: " + "ICD Already added");
        } else {
          // console.log(icdContradictory)
          let itemArray = {
            labelId: item.labelId,
            labelDesc: item.labelDesc,
            contradictoryICDCode:( item.contradictoryICDCode == "" ||  item.contradictoryICDCode == null) ? item.labelCode : item.contradictoryICDCode,
            labelCode: item.labelCode,
            catgDesc: item.catgDesc,
            // icdType:2,
             icdType: this.SelectedIcdList.length <= 0 ? 1 : 2,
             onsetDate:item.onsetDate
          };
          let icdLabel=[];
          if(this.SelectedIcdList.length){
            this.SelectedIcdList.forEach((res:any)=>{
              if(res.labelCode !== item.contradictoryICDCode && res.contradictoryICDCode !== itemArray.contradictoryICDCode){
                icdLabel.push(true)
              } else {
                if(res.labelCode == item.contradictoryICDCode){
                  this.toastrService.error(`The diagnosis code (${item.labelCode}) is not allowed with the diagnosis code (${item.contradictoryICDCode})`);
                } else if(res.contradictoryICDCode == itemArray.contradictoryICDCode){
                  this.toastrService.error(`The diagnosis code (${res.labelCode}) is not allowed with the diagnosis code (${itemArray.contradictoryICDCode})`);
                }
              }
            })
            // console.log(this.SelectedIcdList.length , icdLabel.length)
            if(this.SelectedIcdList.length == icdLabel.length){
              this.SelectedIcdList.push(itemArray);
            }
          } else {
            this.SelectedIcdList.push(itemArray);
          }
          if (this.SelectedIcdList) {
            this.FormValueChangeChecker = true;
          }
          this.icdSearch = "";
          this.icdVal.emit(this.SelectedIcdList)
        }
      }
    }
  }

  RemoveFromIcdList(index) {
    this.SelectedIcdList.splice(index, 1);
    this.FormValueChangeChecker = true;
  }

  markHook(info) {
    let hookString = "";
    hookString = "icd: " + info.labelCode + " ,  " + info.labelDesc;
    if (this.EmrPatientInfo.PatientId > 0) {
      let formdata = {
        hookid: 0,
        hookDesc: hookString,
        patientId: Number(this.EmrPatientInfo.PatientId),
        branchId: this.globals.HospitalId,
        IsDisplayed: true,
        IsDeleted: 0,
      };

      this.EmrService.SaveHook(formdata).subscribe((res) => {
        if (res.status == 200 && res.message == "Success") {
          this.toastrService.success(
            info.labelCode + " Successfully added to hook list"
          );
        } else if (res.status == 200) {
          this.toastrService.error("Failure: " + res.message);
        } else {
          this.toastrService.error(
            "Some unexpected error occures! try again later."
          );
        }
      });
    }
  }

  SaveNarrativeDiagonosis() {
    if (
      this.EmrPatientInfo.CrudType != 3 &&
      this.EmrPatientInfo.VisitId > 0 &&
      this.FormValueChangeChecker
    ) {
      let formValue = this.signsSymptomsForm.getRawValue();
      let icdList = this.SelectedIcdList.map((icd: any) => {
        let onsetDate: string;
        if (typeof icd.onsetDate === 'string') {
          onsetDate = icd.onsetDate;
        } else if (icd.onsetDate instanceof Date) {
          onsetDate = this.datePipe.transform(icd.onsetDate, 'dd-MM-yyyy');
        } else {
          onsetDate = '';
        }
        return {
          ...icd,
          // icdType: icd.icdType ? Number(icd.icdType) : 0,
          icdType: icd?.icdType ? icd.icdType.toString() : "",
          onsetDate:onsetDate
        };
      });
      formValue.icdLabelList = icdList;
      formValue.userId = Number(localStorage.getItem("user_id"));

      _.forEach(formValue.patDiseaseList, (diseae: any) => {
        (diseae.entryId = 0),
          (diseae.entryDate = this.datePipe.transform(
            new Date(),
            "dd-MM-yyyy"
          )),
          (diseae.diseaseId = Number(diseae.diseaseId));
      });
      formValue.patDiseaseList =
        formValue.patDiseaseList?.length > 0 ? formValue.patDiseaseList : [];

      _.forEach(formValue.patSymptomList, (symptom: any) => {
        symptom.since = Number(symptom.since);
        symptom.duration = symptom.duration.toString();
      });

      _.forEach(formValue.patSignList, (sign: any) => {
        sign.since = Number(sign.since);
        sign.duration = sign.duration.toString();
      });

      formValue.EntryDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");

      const payload = {
        nid: formValue.nid,
        narrativeDiagnosis: formValue.narrativeDiagnosis,
        ObservationNote: formValue.ObservationNote,
        IcdLabelList: formValue.icdLabelList,
        visitId: formValue.visitId,
        userId: formValue.userId,
        PatSymptomList: formValue.patSymptomList,
        PatientId: formValue.PatientId,
        ConsultationId: formValue.ConsultationId,
        EntryDate: formValue.EntryDate,
        PatDiseaseList: formValue.patDiseaseList,
        PatSignList: formValue.patSignList,
      };

      this.EmrService.SaveNarrativeDiagnosis(payload).subscribe((res) => {
        if (res.status == 200 && res.message == "Success") {
          this.toastrService.success(
            "Success: " + "Narrative diagnosis details Saved Successfully..."
          );
          this.FormValueChangeChecker = false;
        } else {
          this.toastrService.error(res.errorMessage.message);
        }
      });
    } else {
      if (!this.FormValueChangeChecker) {
        // this.toastr.warning("Info: " + "No changes detected in the entry");
      }
    }
  }

  searchValUpdate(i: any) {
    if (i.sign) {
      this.addSignFormGroup(i.data);
    } else {
      this.addMapFormGroup(i.data);
    }
    this.FormValueChangeChecker = true;
  }

  onIcdTypeSelectChange(icd:any){
    
  this.FormValueChangeChecker=true
    if(icd.icdType == '1' && icd.labelCode.toLowerCase().startsWith("r")){
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      let initialState={
        heading:"Confirm",
        content:"Do you want to set primary",
        isReasonNeeded:false,
        yesButton:"Yes",
        noButton:"No"
      }
      const modalRef = this.modalService.show(ConfirmModalComponent, Object.assign({}, config, { class: 'modal-md', initialState }))
      modalRef.content.confirmEmitter.subscribe(res=>{
        if(res.status){

        }else{
          icd.icdType = "2"
        }
      })
    }

    if(icd.icdType == "1"){
    this.SelectedIcdList=this.SelectedIcdList.map(item => {
      if (item.labelId !== icd.labelId && item.icdType == "1") {
        return { ...item, icdType: 2 };
      }
      return item;
    });
    }

    }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
