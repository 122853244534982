import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emr-quick-menu',
  templateUrl: './emr-quick-menu.component.html',
  styleUrls: ['./emr-quick-menu.component.scss']
})
export class EmrQuickMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openMenu(event)
  {
    var element = document.getElementById('themesetting');

    if(element.classList.contains('open'))
    {
      element.classList.remove('open');
    }
    else{
      element.classList.add('open');
    }


  }
}
