import { Component, Input, OnInit } from '@angular/core';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';

import { emotionEffectsData, fearsData, hallucinationData, maniaData, memoryData, mindData, moodDispositionData, speechData } from '../checkboax.data'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HomeoService } from '../../services/homeo.service';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../../../_Models/Response';
import { EMRService } from '../../services/emr.service';
import _ from 'lodash';

@Component({
  selector: 'app-mental-generals',
  templateUrl: './mental-generals.component.html',
  styleUrls: ['./mental-generals.component.scss']
})
export class MentalGeneralsComponent implements OnInit {

  public emotionEffects : any[] = emotionEffectsData;
  public fears : any[] = fearsData;
  public hallucinations : any[] = hallucinationData;
  public manias : any[] = maniaData;
  public memorys : any[] = memoryData;
  public minds : any[] = mindData;
  public moodDispositions : any[] = moodDispositionData;
  public speechs : any[] = speechData;
  // public emotionEffects : any[] = emotionEffectsData;

  @Input() globals: globalvars;

  public EMRpatientInfo: EMRPatientInfo;

  public mentalGeneralForm: FormGroup;

  private FormValueChangeChecker: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private homeoService: HomeoService,
              private EmrService: EMRService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.EMRpatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initform();
    this.getMentalGenerals();
  }

  initform(){
    this.mentalGeneralForm = this.formBuilder.group({
      mgId: new FormControl(0),
      addiction: new FormControl(''),
      delirium: new FormControl(''),
      demantia: new FormControl(''),
      hysteria: new FormControl(''),
      loquacity: new FormControl(''),
      gesture: new FormControl(''),
      emotionEffects: new FormArray([]),
      hallucination: new FormArray([]),
      memory: new FormArray([]),
      mind: new FormArray([]),
      fears: new FormArray([]),
      moodDisposition: new FormArray([]),
      speech: new FormArray([]),
      mania: new FormArray([]),
    })
  }

  getMentalGenerals(){
    let payload = {
      PatientId: this.EMRpatientInfo.PatientId,
      ConsultationId: this.EMRpatientInfo.ConsultationId
    }
    this.homeoService.getMentalGenerals(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          if(response.response.length){
            let data = response.response[0];
            this.mentalGeneralForm.patchValue({
              mgId: data.mgId,
              addiction: data.addiction,
              delirium: data.delirium,
              demantia: data.dementia,
              hysteria: data.hysteria,
              loquacity: data.loquacity,
              gesture: data.gesture,
            })
            this.fetchCheckBoxes(data.emotionEffectsList ? data.emotionEffectsList : [], 'emotionEffects');
            this.fetchCheckBoxes(data.hallucinationList ? data.hallucinationList : [], 'hallucination');
            this.fetchCheckBoxes(data.memmoryList ? data.memmoryList : [], 'memory');
            this.fetchCheckBoxes(data.mindList ? data.mindList : [], 'mind');
            this.fetchCheckBoxes(data.fearList ? data.fearList : [], 'fears');
            this.fetchCheckBoxes(data.moodDispositionList ? data.moodDispositionList : [], 'moodDisposition');
            this.fetchCheckBoxes(data.speechList ? data.speechList : [], 'speech');
            this.fetchCheckBoxes(data.maniaList ? data.maniaList : [], 'mania');
          }
          this.mentalGeneralForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker = true;
          })
        }else{
          this.toastrService.error(response.errorMessage.message)
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again')
      }
    })
  }

  fetchCheckBoxes(values : any[], formControName: string){
    const formArray: FormArray = this.mentalGeneralForm.get(formControName) as FormArray;
    _.forEach(values, (item)=>{
      formArray.push(new FormControl(item))
    })
  }

  onCheckBoxChange(event, formControName: string){
    const formArray: FormArray = this.mentalGeneralForm.get(formControName) as FormArray;
    if(event.target.checked){
      formArray.push(new FormControl(event.target.value))
    }else{
      let index = formArray.controls.findIndex((item)=> item.value == event.target.value);
      formArray.removeAt(index);
    };
  }

  onSaveMentalGenerals(){
    if(this.EMRpatientInfo.CrudType == 3 ||
      this.EMRpatientInfo.VisitId == 0 || !this.FormValueChangeChecker){
      return
    }

    let formValue = this.mentalGeneralForm.value;
    let payload = {
      MgId: parseInt(formValue.mgId),
      ConsultationId: this.EMRpatientInfo.ConsultationId,
      PatientId: this.EMRpatientInfo.PatientId,
      Addiction: formValue.addiction,
      EmotionEffectsList: formValue.emotionEffects,
      HallucinationList: formValue.hallucination,
      MemmoryList: formValue.memory,
      Gesture: formValue.gesture,
      Delirium: formValue.delirium,
      FearList: formValue.fears,
      Loquacity: formValue.loquacity,
      MindList: formValue.mind,
      MoodDispositionList: formValue.moodDisposition,
      Dementia: formValue.demantia,
      Hysteria: formValue.hysteria,
      ManiaList: formValue.mania,
      SpeechList: formValue.speech,
      UserId: parseInt(localStorage.getItem('user_id'))
    }

    this.homeoService.saveMentalGenerals(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == "Success"){
          this.mentalGeneralForm.patchValue({
            mgId: response.response[0].mgId
          });
          this.toastrService.success('Mental Generals Saved Successfully');
          this.FormValueChangeChecker = false;
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

}
