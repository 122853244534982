import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '../../../_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class HomeoService {

  private baseUrl: string = '';

  constructor(private configurationManager: ConfigurationManager,
              private httpClient: HttpClient,) {
                this.baseUrl = (this.configurationManager as any).baseUrl;
               }

  getMentalGenerals(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetHomeoMentalGenerals`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  saveMentalGenerals(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/InsertHomeoMentalGenerals`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getPhysicalGenerals(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetHomeoPhysicalGenerals`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  savePhysicalGenerals(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/InsertHomeoPhysicalGenerals`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }
}
