import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '../../../_Models/Response';
import { UserHospitals } from '../interfaces/UserHospitals';
import { Departments } from '../interfaces/Departments';
import { Consultants } from '../interfaces/Consultants';
import { catchError, retry } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandleService } from './error-handle.service';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { WorkingDaysModel } from '../interfaces/WorkingDaysModel';
@Injectable({
  providedIn: 'root'
})
export class HospitalsService {
  GetConsultantDays(obj: WorkingDaysModel) {
    var url = "api/Consultant/GetConsultantTimeSchedule";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, obj).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  GetUserSpecificHospitalLocations(user: number, branch: number) {
    var url = "api/MasterData/GetUserSpecificHospitalLocations/" + user + "/" + branch;
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  GetUserSpecificHospitals(user) {
    var url = "api/MasterData/GetUserSpecificHospitals/";
    return this.http.post<Response<UserHospitals[]>>(`${this.baseUrl}${url}${user}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

  private baseUrl = "";
  errorMessage: any;

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private toastr: ToastrService,
    private errorHandle: ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;

  }
  InsertConsultantSchedules(obj: WorkingDaysModel) {
    var url = "api/Consultant/InsertUpdateConsultantTimeSchedule";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, obj).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  GetUserHospitals() {
    var url = "api/MasterData/GetUserHospitals/0";
    return this.http.post<Response<UserHospitals[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  GetDepartments(branchId, showall) {
    var url = "api/MasterData/GetDepartment";
    // return this.http.post<Response<Departments[]>>(`${this.baseUrl}${url}`, null)
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "DepartmentId": 0,
        "ShowAll": showall,
        "BranchId": branchId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetConsultant(department: Number,currentDate?) {
    var url = "api/TodaysPatient/GetConsultant";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "DeptId": Number(department?department:0),
        "ShowExternal": false,
        CurrentDate:currentDate ? currentDate : ''
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetConsultantList(departmentList: any, Consultant: any) {
    var url = "api/TodaysPatient/GetConsultantByArray/";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      { "Departments": departmentList, "ShowExternal": false }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetBranchByDepartments(branchId: any) {
    var url = "api/MasterData/GetDepartmentByHospital/" + branchId;
    return this.http.post<Response<Departments[]>>(`${this.baseUrl}${url}`, null).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

  GetConsultantData() {
    var url = "api/MasterData/GetConsultant";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId":0
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  Getlocation(inputData) {
    var url = "api/MasterData/GetLocation";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
 
  }

  GetAllDisease() {
    var url = "api/Consultant/GetAllDisease/0";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }

  GetSearchEPrescription(payload) {
    var url = "api/MasterData/GetSearchEPrescription";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetSearchEPrescriptionDetails(payload) {
    var url = "api/MasterData/GetSearchEPrescriptionDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  InsertUpdatePrescriptionDetails(payload) {
    var url = "api/Masterdata/InsertUpdatePrescriptionDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }


}
