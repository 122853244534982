import { Component, OnInit, Renderer2 } from "@angular/core";
import { HospitalsService } from "../services/hospitals.service";
import { AppointmentsService } from "../services/appointments.service";
import { DatePipe } from "@angular/common";
import { Departments } from "../interfaces/Departments";
import { Consultants } from "../interfaces/Consultants";
import { AppointmentsList } from "../interfaces/AppointmentsList";
import { FormBuilder, FormGroup, FormControl, FormArray } from "@angular/forms";
import { ConsultaionsService } from "../services/consultations.service";
import { ConsultationsList } from "../interfaces/ConsultationsList";
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralActionComponent } from "../general-action/general-action.component";
import { PatientService } from "../services/patient.service";
import { progressBarList } from "../interfaces/ProgressBarList";
import { ToastrService } from "ngx-toastr";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Router } from "@angular/router";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { EMRService } from '../../emr/services/emr.service';
import {
  GuiCellView,
  GuiColumn,
  GuiPaging,
  GuiPagingDisplay,
  GuiSearching,
  GuiSorting,
  GuiColumnMenu,
  GuiInfoPanel,
  GuiDataType,
} from "@generic-ui/ngx-grid";
import { SponsorConsentComponent } from "./modals/sponsor-consent/sponsor-consent.component";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { dataSeries } from "../charts/column-chart/series-data";
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";
import { PrintAsoapComponent } from "./modals/print-asoap/print-asoap.component";
import { Response } from "../../../_Models/Response";
import { ConfigurationList } from "../interfaces/ConfigurationList";
import { ConfigurationService } from "../services/configuration.service";
import { HealthSummaryModalComponent } from "../../treatment/modals/health-summary-modal/health-summary-modal.component";
import { StaticPatientConsentComponent } from "./modals/static-patient-consent/static-patient-consent.component";
import { SettingsService } from "../../settings/services/settings.service";
import { Settings } from "../../settings/model/settings.model";
import _ from "lodash";
import { NabithConsentComponent } from "./addregistration/nabith-consent/nabith-consent.component";
import { TelerikPrintComponent } from "../../../shared/modals/telerik-print/telerik-print.component";
import { ServiceListModalComponent } from "../serviceordering/modals/service-list-modal/service-list-modal.component";
import { StatusInfoModalComponent } from "./modals/status-info-modal/status-info-modal.component";
import { InformedStaticConsentComponent } from "./modals/informed-static-consent/informed-static-consent.component";
import { RiayatiConsentComponent } from "./addregistration/riayati-consent/riayati-consent.component";

@Component({
  selector: "app-FrontOfficeComponent",
  templateUrl: "./frontoffice.component.html",
  styleUrls: ["./frontoffice.component.scss"],
  providers: [DatePipe],
})
export class FrontofficeComponent implements OnInit {
  form: FormGroup;
  countForm: FormGroup;
  displayBasic: boolean;
  UrgentVisit: boolean;
  patientId: number = 0;
  position: string;
  users: any = [];
  displayBasicAppoint: boolean;
  displayPosition: boolean;
  departments: any;
  consultants: Consultants[] = [];
  appointments: AppointmentsList[] = [];
  userType: any;
  username: any;
  departmentName: any;
  totalConsultations: any;
  totalAppointments: any;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingsPostPoned: IDropdownSettings = {};
  gender: any;
  patientName: any;
  CancelButton: boolean;
  PostponedAppointments: boolean;
  blockId: any;
  links: any[] = ["link1.com", "link2.com", "link3.com"];
  minDate = new Date();
  mailText: string = "";
  searchForm: FormGroup;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  userImg:any
  userAppointmentImg:any
  columns: Array<GuiColumn> = [
    {
      header: "Patient Name",
      field: "patientName",
    },
    {
      header: "Reg.No",
      field: "regNo",
      view: GuiCellView.CHIP,
    },
    {
      header: "TimeDDD",
      field: "timeNo",
    },

    {
      header: "Status",
      field: "status",
    },

    {
      header: "",
      field: "",
    },
  ];

  //consultation generic grid
  columns1: Array<GuiColumn> = [
    {
      header: "Token",
      field: "tokenNO",
    },
    {
      header: "Patient Name",
      field: "patientName",
      view: GuiCellView.CHIP,
    },
    {
      header: "Reg.No",
      field: "regNo",
    },

    {
      header: "TimeBBBB",
      field: "timeNo",
    },

    {
      header: "Sponsor",
      field: "sponsor",
    },
    {
      header: "Status",
      field: "status",
    },
  ];

  paging: GuiPaging = {
    enabled: true,
    page: 1,
    pageSize: 10,
    pageSizes: [10, 25, 50],
    pagerTop: false,
    pagerBottom: true,
    display: GuiPagingDisplay.ADVANCED,
  };

  sorting: GuiSorting = {
    enabled: true,
  };

  searching: GuiSearching = {
    enabled: true,
    placeholder: "Search Here",
  };
  GuiColumnMenu = {
    enabled: true,
    columnsManager: true,
  };

  infoPanel: GuiInfoPanel = {
    enabled: true,
    infoDialog: false,
    columnsManager: true,
  };

  rowGroupPanelShow = "always";

  rowData: ConsultationsList[] = [];
  //ag grid setup
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public noRowsTemplate;
  public loadingTemplate;

  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.status == "C";
      },
    },

    domLayout: "autoHeight",
  };

  public consultationGridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.status == "C";
      },
      "c-urgent": function (params) {
        return (params.data.status.toLocaleLowerCase() == "w" && params.data.emergency == 1);
      },
      "c-completed": function (params) {
        return params.data.status.toLocaleLowerCase() == "f";
      },
    },

    domLayout: "autoHeight",

  };

  public statusBar;
  // appoinements ag Grid
  columnDefs: ColDef[] = [
    {
      headerName: "Token No.",
      minWidth: 90,
      field: "tokenNO",
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      flex: 1.5,
    },
    {
      headerName: "INV",
      minWidth:50,
      field: "",
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
      onCellClicked: this.openInfo.bind(this),
      cellRenderer:(params)=>{
        switch (params.data.overallLabStatus) {
          case 'New Investigation':
            return ('<span class="newi">N</span>')
            break;

          case 'Sample Collected':
            return ('<span class="inv">SC</span>')          
            break;

          case "Test Initiated":
            return ('<span class="invi">TI</span>')           
            break;

          case "Result Entered":
            return ('<span class="RE">RE</span>')
            break;

          case "Published":
            return ('<span class="RP">P</span>')
            break;

          case "Cancelled":
            return ('<span >C</span>')
            break;

          
        }
      }
    },
    {
      headerName: "V Sign",
      minWidth:50,
      field: "vitalSignStatus",
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
      cellRenderer:(params)=>{
        if(params.data.vitalSignStatus == 1){
          return ('<i class="fa fa-heartbeat text-info" aria-hidden="true "></i>')
        }
      }
    },
    {
      headerName: "C stat",
      minWidth:50,
      field: "patientConsentStatus",
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
      cellRenderer:(params)=>{
        if(params.data.patientConsentStatus == 1){
          return ('<i class="fa fa-file-text text-info" aria-hidden="true "></i>')
        }
      }
    },
    {
      headerName: "Patient Name",
      minWidth: 120,
      field: "patientName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
      onCellClicked: this.showuserDialog.bind(this),
      cellRenderer: (params) => {
        if(params.data.profilePicLocation){
          return (
            " <img  src='"+ params.data.profilePicLocation +"' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle '+ params.data.emergency == '1' ? 'emergency' : '' +'' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        }else{
          if (params.data.gender == 1) {
            if (params.data.emergency == 1) {
              return (
                " <img  src='assets/images/xs/maleurgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
            }
            return (
              " <img  src='assets/images/xs/malegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          } else if (params.data.gender == 2) {
            if (params.data.emergency == 1) {
              return (
                " <img  src='assets/images/xs/femaleurgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
            }

            return (
              " <img  src='assets/images/xs/femalegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          } else if (params.data.gender == 0) {
            if (params.data.emergency == 1) {
              return (
                " <img  src='assets/images/xs/defaulturgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
            }
            return (
              " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          } else if (params.data.gender == 3) {
            if (params.data.emergency == 1) {
              return (
                " <img  src='assets/images/xs/defaulturgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
                "&nbsp" +
                "&nbsp" +
                params.value
              );
            }
            return (
              " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          }
        }
      },
    },
    {
      headerName: "Reg. No.",
      minWidth: 105,
      field: "regNo",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,

    },
    {
      headerName: "Insurance",
      minWidth: 90,
      field: "sponsor",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Time",
      minWidth: 70,
      field: "consultationTime",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,

    },
    {
      headerName: "Status",
      minWidth: 45,
      field: "status",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Consultant",
      minWidth: 100,
      field: "consultantName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "User",
      minWidth: 100,
      field: "userName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    // {
    //   headerName: "Fees",
    //   minWidth:100,
    //   field: "feesAmount",
    //   sortable: true,
    //   filter: true,
    //   enableRowGroup: true,
    //   resizable: true,
    //   flex: 2,
    // },


    {
      headerName: "Action",
      pinned: "right",
      maxWidth: 60,
      filter: false,
      sortable: false,
      resizable: false,
      unSortIcon: false,
      field: "Id",
      flex: 1,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "FrontofficeConsultationList" },
    },
  ];

  ConsultationBlockId: number;
  active: string;
  consultationId: number;
  blockorunblock: boolean;
  confirmBlock: boolean;
  patId: number = 0;

  // Appointmnet Ag=grid
  rowDataAppt: AppointmentsList[] = [];

  columnDefs_appt: ColDef[] = [
    {
      headerName: "Patient Name",
      minWidth: 130,
      field: "patientName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
      cellRenderer: (params) => {

        if (params.data.gender == 1) {
          return (
            " <img  src='assets/images/xs/malegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        } else if (params.data.gender == 2) {
          return (
            " <img  src='assets/images/xs/femalegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        } else {
          return (
            " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        }
      },
    },
    {
      headerName: "Reg. No.",
      minWidth: 120,
      field: "regNo",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,

    },
    {
      headerName: "Time",
      minWidth: 75,
      field: "appTime",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,

    },

    {
      headerName: "Status",
      minWidth: 75,
      field: "status",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,

    },
    // {
    //   headerName: "Fees",
    //   minWidth:100,
    //   field: "feesAmount",
    //   sortable: true,
    //   filter: true,
    //   enableRowGroup: true,
    //   resizable: true,
    //   flex: 2,
    // },
    {
      headerName: "Action",
      maxWidth: 70,
      pinned: "right",
      sortable: false,
      filter: false,
      resizable: false,
      unSortIcon: true,
      field: "Id",
      flex: 1,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "FrontAppointmentList" },
    },
  ];
  confirmMarkasUrgent: boolean = false;
  progressBarData: progressBarList[] = [];
  userId: number;
  locationId: number;
  ReasonVisit: boolean;
  reasonDataReason: any;
  result: any;
  consultId: boolean;
  OtherSymptom: any;
  symptomList: any;
  CancelAppointments: boolean;
  confirmblockApp: boolean;
  confirmedblockApp: boolean;
  Confirmed: any;
  Arrived: any;
  Cancelled: any;
  waiting: any;
  ConsultCancelled: any;
  Consultfinished: any;
  ConsultOngoing: any;
  Consultwaiting: any;
  AppointmentDataDetails: AppointmentsList[];
  appDate: string;
  AppId: any;
  emergency: any;
  BranchId: number;
  consultDataId: any[];
  departmentList: any;

  valueDate: string;
  curentdDate: any;
  scheduleTime: any;
  postponeddate: any;
  sliceNo: any;
  EditStatus: boolean;
  EditStatusAppId: any;
  symptomDetails: any;
  BranchesId: any;
  addressapp: any;
  mobapp: any;
  regNoapp: any;
  emailapp: boolean;
  appdate: any;
  regNoconsult: any;
  regNoaddress: any;
  regNomobile: any;
  consultdate: any;
  consultemail: any;
  postponnedconsultId: any;
  baseUrl: any='';
  functionPermissionData: any;
  public consentSettings: Settings[] = [];
  modalRef: BsModalRef;
  consultationCount = -1;
  healthPlatform: any;

  constructor(
    private formBuilder: FormBuilder,
    private hospitalsService: HospitalsService,
    private appointmentsService: AppointmentsService,
    private patientService: PatientService,
    private consultationsService: ConsultaionsService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private appointmentService: AppointmentsService,
    private sharedservice: SharedService,
    private router: Router,
    private EMRService: EMRService,
    private modalService: BsModalService,
    private configurationManager:ConfigurationManager,
    private ConfigurationService: ConfigurationService,
    private settingsService: SettingsService,
    public datepipe: DatePipe,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId;
      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.getApplicationSettings();

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };


    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    // this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    // this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };

    this.minDate.setDate(this.minDate.getDate());
  }
  //Initailasing functions on Page Load
  ngOnInit() {
    this.functionPermission();
    let CurrentDate = new Date();
    this.valueDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.curentdDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.dropdownSettings = {
      singleSelection: false,
      idField: "symptomId",
      textField: "newsymDesc",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
    this.dropdownSettingsPostPoned = {
      singleSelection: false,
      idField: 'sliceNo',
      textField: 'sliceTime',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };

    this.userType = localStorage.getItem("usertype"); //U As admin otherwise consultant
    this.username = localStorage.getItem("username");
    this.departmentName = localStorage.getItem("departmentName");

    // Appointments Action grid
    this.form = this.formBuilder.group({
      departmenId: [0],
      DateFromList: [CurrentDate],
      consultantId: [0],
      patientStatus: [""],
      appointmentStatus: new FormControl(""),
      reasonDataReason: new FormControl(""),
      ConsultationId: new FormControl(""),
      Symptoms: new FormControl(""),
      otherreasonforvisit: new FormControl(""),
      blockReason: new FormControl(""),
      PostPonedDepartment: new FormControl(""),
      PostPonedConsultant: new FormControl(""),
      appDate: new FormControl(""),
      slicetime: new FormControl(""),
      DeptId: new FormControl(""),
      consultId: new FormControl(""),
      sliceNo: new FormControl(""),
      consultantDepartment: new FormControl(this.departmentName),
      consultantName: new FormControl(this.username),
    });

    // Modal form initialization
    this.searchForm = this.formBuilder.group({
      Symptoms: new FormControl(),
      otherreasonforvisit: new FormControl(""),
      blockReason: new FormControl(""),
    });

    let cTime = this.datepipe.transform(new Date(), 'dd-MM-yyyy hh:mm a');
    cTime = cTime.split(' ')[1] + " " + cTime.split(' ')[2];
    this.countForm = this.formBuilder.group({
      fromTimeCount: new FormControl("08:00 AM"),
      toTimeCount: new FormControl(cTime),
    });

    this.GetConsultationCount();

    this.GetDepartments(); //on load api function getting departments
    this.GetAppointments(); //on load api function getting appoinments
    this.GetConsultations();
    this.GetProgressBar();
    this.GetTemplateReasonForVisit();
  }
  dateChanger(i:any){
    this.GetAppointments();
    this.GetConsultations();
  }
  /* ============  fetching data to be filled in progress bar ============ */
  GetProgressBar() {
    let CurrentDate = new Date();
    let date = this.datePipe.transform(CurrentDate, "dd-MM-yyyy");
    this.patientService.GetProgressBar(date, this.globals.HospitalId).subscribe((res) => {
      this.progressBarData = res.response;
      this.waiting = this.progressBarData[0]?.appPercW;
      this.Arrived = this.progressBarData[0]?.appPercA;
      this.Cancelled = this.progressBarData[0]?.appPercC;
      this.Confirmed = this.progressBarData[0]?.appStatCF;

      this.Consultwaiting = this.progressBarData[0]?.consPercW;
      this.ConsultOngoing = this.progressBarData[0]?.consPercO;
      this.ConsultCancelled = this.progressBarData[0]?.consPercC;
      this.Consultfinished = this.progressBarData[0]?.consPercF;

      // this.searchForm.patchValue({blockReason:''})
    });
  }
  /* ============  fetching data to be filled in select department dropdowns ============ */
  GetDepartments() {
    let showall = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showall).subscribe((res) => {
      this.departments = res.response;
    });
  }
  /* ============ Onchanging data Appointments by consultations ============ */
  loadConsultant(DepartmentId: number) {
    let departmentId = Number(this.form.get("departmenId").value);
    this.hospitalsService.GetConsultant(departmentId).subscribe((res) => {
      this.consultants = res.response;
      this.form.patchValue({ consultantId: "" });
      this.GetAppointments();
      this.GetConsultations();
    });
  }

  /* ============  fetching data to the appointments grid ============ */
  GetAppointments() {
    // let CurrentDate = new Date();
    let date = this.datePipe.transform(this.form.get("DateFromList").value, "dd-MM-yyyy");
    let DepartmentId = Number(this.form.get("departmenId").value);
    let consultantId = Number(this.form.get("consultantId").value);
    this.appointmentsService
      .GetAppointments(date, DepartmentId, consultantId, this.globals.HospitalId)
      .subscribe((res) => {
        this.appointments = res.response;
        this.totalAppointments = this.appointments?.length;

        this.rowDataAppt = res.response;
        this.totalAppointments = this.rowDataAppt?.length;
        this.GetConsultations();
      });
  }
  /* ============  fetching data to the Consultations grid ============ */
  GetConsultations() {
    let status = this.form.get("patientStatus").value;
    let CurrentDate = new Date();
    let date = this.datePipe.transform(this.form.get("DateFromList").value, "yyyy-MM-dd");
    let DepartmentId = Number(this.form.get("departmenId").value);
    let consultantId = Number(this.form.get("consultantId").value);
    this.consultationsService
      .GetConsultations(date, status, consultantId, DepartmentId, this.globals.HospitalId)
      .subscribe((res) => {
        this.rowData = res.response;
        this.totalConsultations = this.rowData?.length;
      });
    //this.GetAppointments();
  }

  /* ============  userinformation opening modal data  ============ */

  showuserDialog(consultation: any) {
    consultation = consultation.data;
    this.patientId = consultation.patientId
    this.patientName = consultation.patientName;
    this.gender = consultation.gender;
    this.emergency = consultation.emergency;
    this.regNoconsult = consultation.regNo;
    this.regNoaddress = consultation.address;
    this.regNomobile = consultation.mobile;
    this.consultdate = consultation.consultDate;
    //this.consultemail=consultation.data.mobile;
    this.displayBasic = true;
    this.userImg=consultation.profilePicLocation ? consultation.profilePicLocation : undefined
  }

  showuserDialogAppointments(product: any) {
    this.patientId = product.data.patientId
    this.gender = product.data.gender;
    this.patientName = product.data.patientName;
    this.addressapp = product.data.address1;
    this.mobapp = product.data.mobile;
    this.regNoapp = product.data.regNo;
    this.emailapp = product.data.email;
    this.appdate = product.data.appDate
    // this.emergency = consultation.emergency;
    this.displayBasicAppoint = true;
    this.userImg=product.data.profilePicLocation ? product.data.profilePicLocation : undefined
  }

  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  }

  showuserDialog3() {
    this.displayBasic = true;
  }

  save(param) {
    return false;
  }
  drop() {
    return false;
  }
  mailMe() {
    this.mailText =
      "mailto:abc@abc.com?subject=files&body=" + this.links.join(" ,");
    window.location.href = this.mailText;
  }

  // Activetab(pagetitle = "Front Office") {
  //   sessionStorage.setItem("pageTitle", pagetitle);
  // }

  //Mark as urgent Confirmation Popup
  ConfirmMarkasUrgent(rowDatas) {
    this.confirmMarkasUrgent = true;
  }

  confirmrugentConslt(rowDatas) {
    let consultationId = Number(rowDatas.consultationId);
    // this.consultationsService.MarkasUrgentConsultation(consultationId).subscribe((res)=>{

    //     this.GetConsultations();
    // });

    this.confirmMarkasUrgent = false;
  }

  //changing urgent consultation
  ChangeAsUrgent(id) {
    this.consultationId = id;

    this.userId = Number(localStorage.getItem("user_id"));
    this.locationId = 1;

    this.consultationsService
      .ChangeAsUrgent(this.consultationId, this.userId, this.locationId, this.globals.HospitalId)
      .subscribe((res) => {
        if (res.message == "success") {
          this.toastr.success("Consultation changed as Urgent");
          this.GetConsultations();
        } else {
          this.toastr.error("Failure: " + res["errorMessage"]["message"]);
        }
      });
    this.GetConsultations();
  }
  // Consulation cancel confirmation modal
  showBlockUnblock(rowDatas) {
    const foundElement = this.functionPermissionData.find(x => x.id === 186)
    if (foundElement.id == 186 && foundElement.isAllowed == true) {
    this.searchForm.patchValue({ blockReason: "" });
    this.active = rowDatas.status == "W" ? "Cancel" : "Cancelled";
    this.consultationId = Number(rowDatas.consultationId);
    this.patId = Number(rowDatas.patientId)
    this.blockorunblock = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  // Consulation cancel confirmation is true then ask reason
  confirmblocking() {
    if (this.active != "Cancelled") {
      this.confirmBlock = true;
    }
  }

  // Function to cancel consultation with reason
  ConfirmBlock() {
    let blockReason = this.searchForm.get("blockReason").value;
    this.consultationsService
      .CancelConsultation(this.consultationId, blockReason)
      .subscribe((res) => {
        this.GetConsultations();
        let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
        if (res.status == 200 && res.message == 'success') {
          if (this.healthPlatform.settValue === "Nabidh") {
          const foundElement = this.functionPermissionData.find(x => x.id === 563)
          if (foundElement.id == 563 && foundElement.isAllowed == true) {
          let nabPayload = {
            EventId: 5,
            Consultationid: this.consultationId,
            EntryDate: currentDate,
            PatientId: this.patId,
            BranchId : this.globals.HospitalId
          }
          this.patientService.SendDetailstoNabith(nabPayload).subscribe((res) => {
            this.toastr.info('Response from Nabith:' + res);
          })
        } else {
          this.toastr.warning("No permission to send data to nabith");
        }
      }
      }
      });
    this.confirmBlock = false;
  }

  // //Getting teplates for symtoms and reason for visit
  GetTemplateReasonForVisit() {
    let showAll = 0;
    this.consultationsService.GetTemplateReasonForvisit(this.globals.HospitalId, showAll).subscribe((res) => {
      this.symptomList = res.response;
      let syptomReasonList = [];
      syptomReasonList.push({
        SymptomId: 0,
        SymptomDesc: "None Of the Above",
        Active: 1,
        BlockReason: "",
      });
      this.symptomList = syptomReasonList.concat(this.symptomList);
      this.symptomList.forEach((element) => {
        element["newsymDesc"] = element["SymptomDesc"] ? element["SymptomDesc"] : element["symptomDesc"];
      });
    });
  }

  //  checking Agi grid is loaded completely

  onGridReady(params) {
    this.gridApi = params.api;
  }
  //Modal showing reason visits
  ReasonForVisitModal(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 180)
    if (foundElement.id == 180 && foundElement.isAllowed == true) {
    //this.rowData;

    // this.consultationId = id.consultationId;
    // this.GetTemplateReasonForVisit();
    // this.consultationsService
    //   .Getsymptoms(this.consultationId)
    //   .subscribe((res) => {
    //     this.symptomDetails = res.response;

    //     this.searchForm.patchValue({
    //       Symptoms: this.symptomDetails["symptoms"],
    //     });
    //   });

    // this.ReasonVisit = true;
    this.searchForm.patchValue({ Symptoms: [] });
      this.searchForm.patchValue({ otherreasonforvisit: "" });
    this.consultationId = id.consultationId;
    this.GetTemplateReasonForVisit();
    this.consultationsService.Getsymptoms(this.consultationId).subscribe((res) => {
      this.symptomDetails = res.response;
      this.searchForm.patchValue({ Symptoms: this.symptomDetails['symptoms'] });
      if (this.symptomDetails['reasonForVisit']) {
        this.OtherSymptom = true;
        this.searchForm.patchValue({ otherreasonforvisit: this.symptomDetails['reasonForVisit'] });
      }
      document.getElementById("myDiv").click();
    })


    this.ReasonVisit = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  //updating reason for visit
  SaveReasonForVisit() {
    var AppData = {
      ConsultationId: this.consultationId,
      Symptoms: this.searchForm.get("Symptoms").value,

      otherreasonforvisit: this.searchForm.get("otherreasonforvisit").value,
    };

    this.consultationsService.SaveReasonVisit(AppData).subscribe((res) => {
      this.result = res;

      if (this.result.status == 200) {
        this.toastr.success("Reason For Visit added successfully");
        this.GetConsultations();
        this.clearFormReason()
      } else {
        this.toastr.error("Reason For Visit failed");
      }
    });
  }
  //Modal showing for urgent Consultations
  UrgentVisitmodal(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 181)
    if (foundElement.id == 181 && foundElement.isAllowed == true) {
    this.consultId = id.consultationId;
    this.UrgentVisit = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  //confirm for saving Urgent consultation
  ConfirmUrgentConsultation(id) {
    this.ChangeAsUrgent(id);
  }

  //reason for visit symptoms list
  // SymptomReason() {
  //   let val = this.searchForm.get("Symptoms").value;

  //   if (val.length > 0) {
  //     if (val[0]["newsymDesc"] == "None Of the Above") {
  //       this.OtherSymptom = true;
  //     } else {
  //       this.OtherSymptom = false;
  //     }
  //   } else {
  //     this.OtherSymptom = false;
  //   }
  // }
  //Modal showing cancel Appointment
  showCancelAppointment(rowDatas, consultationId = 0) {
    const foundElement = this.functionPermissionData.find(x => x.id === 176)
    if (foundElement.id == 176 && foundElement.isAllowed == true) {
    if (consultationId > 0) {
      this.toastr.error("Unable to cancel! because consultation is taken");
    } else {
      let appointmentsData = this.appointments.filter(
        (oppdata) => oppdata["appId"] == rowDatas
      );

      this.form.patchValue({ blockReason: "" });
      this.active =
        appointmentsData[0]["appStatus"] == "Cancelled"
          ? "Already Cancelled this Appointment"
          : "Are you sure that you want to this Cancel Appointment?";
      this.blockId = rowDatas;
      this.CancelAppointments = true;
      this.CancelButton =
        appointmentsData[0]["appStatus"] == "Cancelled" ? false : true;
    }
    } else {
      this.toastr.warning("You are not allowed");
    }

  }
  // Consulation cancel confirmation is true then ask reason
  confirmblockingAppointment(blockId) {
    this.confirmblockApp = true;


  }

  // Function to cancel consultation with reason
  ConfirmBlockingApp() {
    let blockReason = this.form.get("blockReason").value;

    this.appointmentService
      .
      CancelAppointment(this.blockId, blockReason)
      .subscribe((res) => {
        this.GetAppointments();
        this.reload();
        //this.LoadAppTable();
        if (res.status == 200 &&res.message == 'Success') {
          this.toastr.success("Appointment Cancelled");

        }
      });
    this.confirmedblockApp = false;
  }

  //print appointment card
  PrintAppointmentCard(appId) {
    this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      let sliceDatatime = [];
      this.AppointmentDataDetails['sliceData'].forEach(a => {
        sliceDatatime.push(a.sliceTime);
      });
      let printContents = "";
      printContents =
        printContents +
        '<div align="center"><div class="card" style="border:1px solid #000;width:50%">';
      printContents =
        printContents +
        ' <table class="print-table1"><tr style="text-align:center"><td colspan="2"><h3>Appointment Detail</h3></td></tr><tr><td><strong>Appointment Date.</strong></td><td>' +
        this.AppointmentDataDetails["appDate"] +
        "</td></tr>";

      printContents =
        printContents +
        " <tr><td><strong>App Time:</strong></td><td>" +
        sliceDatatime.toString() +
        "</td></tr>";

      printContents =
        printContents +
        " <tr><td><strong>Consultant Name:</strong></td><td>" +
        this.AppointmentDataDetails["consultantName"] +
        "</td></tr>";
      printContents =
        printContents +
        " <tr><td><strong>Patient Name:</strong></td><td>" +
        this.AppointmentDataDetails["firstName"] + " " + this.AppointmentDataDetails["middleName"] + " " + this.AppointmentDataDetails["lastName"] +
        "</td></tr>";

      var mywindow = window.open("", "PRINT", "height=1000,width=1200");
      mywindow.document.write(
        '<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' +
        document.title +
        "</title>"
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write(printContents);
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    });
    return true;

  }

  printAppointment(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 175)
    if (foundElement.id == 175 && foundElement.isAllowed == true) {
    const payload={
      AppId :data,
      BranchId:this.BranchesId
    }
    let initialState = {
      modalHeading: 'Print Appointment',
      reportName: 'PrintAppointment.trdp',
      reportParams: {
        AppId: data,
        BranchId : this.globals.HospitalId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  //apoointment postponned modal
  AppointmentPostPoned(appId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 174)
    if (foundElement.id == 174 && foundElement.isAllowed == true) {
    this.AppId = appId;
    this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      this.departmentList = [this.AppointmentDataDetails["deptId"]];
      this.consultDataId = [this.AppointmentDataDetails["consultantId"]];
      this.postponnedconsultId = this.AppointmentDataDetails["consultantId"];
      this.form.patchValue({
        PostPonedDepartment: this.AppointmentDataDetails["departmentName"],
      });
      this.form.patchValue({
        PostPonedConsultant: this.AppointmentDataDetails["consultantName"],
      });
      this.form.patchValue({
        appDate: this.AppointmentDataDetails["appDate"],
      });
      this.form.patchValue({
        slicetime: this.AppointmentDataDetails["sliceData"],
      });



      // this.form.patchValue({
      //   slicetime: this.AppointmentDataDetails[0]["sliceTime"],
      // });
      this.form.patchValue({
        sliceNo: this.AppointmentDataDetails["sliceNo"],
      });
      //this.form.patchValue({date:  this.appDate});


      this.PostponedAppointments = true;
    });
    setTimeout(() => {
      this.GetScheduledData();
    }, 1000);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onDateChange(value) {

    if (this.curentdDate != value) {
      if (typeof (value) == "object") {
        this.valueDate = this.datePipe.transform(value, 'dd-MM-yyyy');
      }
      this.GetScheduledData();
    }
  }
  //Getschedule method called from AppointmentService
  GetScheduledData() {

    this.appointmentService
      .GetAppointmentSchedule(
        this.consultDataId,
        this.valueDate,
        this.departmentList,
        this.BranchesId
      )
      .subscribe((res) => {

        if (res.response[0]) {
          this.scheduleTime = res.response[0]['labels'];
          this.scheduleTime = this.scheduleTime.map(v => {
            return v.appId > 0 && v.appId != this.AppId ? { ...v, isDisabled: true } : { ...v, isDisabled: false }
          });
        }
        else {
          this.scheduleTime = [];
        }

        if (this.curentdDate == this.valueDate) {
          this.form.patchValue({ slicetime: this.AppointmentDataDetails["sliceData"] });
        }
        else {
          this.form.patchValue({ slicetime: [] });
        }

      });
  }

  //Save PostPonned Appointments method
  InsertPostponnedAppointments(appId) {
    this.postponeddate = this.form.get("appDate").value;

    if (typeof this.postponeddate == "object") {
      this.postponeddate = this.datePipe.transform(
        this.postponeddate,
        "dd-MM-yyyy"
      );
    } else {
      this.postponeddate = this.form.get("appDate").value;
    }
    let sliceData = this.form.get("slicetime").value;
    let sliceDataDetails = [];
    sliceData.forEach(a => {
      sliceDataDetails.push({ "sliceNo": Number(a.sliceNo), "sliceTime": a.sliceTime });
    });
    var AppData = {
      appId: this.AppId,
      PostPonedConsultant: this.postponnedconsultId,
      PostPonedDepartment: this.form.get("PostPonedDepartment").value,
      AppDate: this.postponeddate,
      SliceTime: sliceDataDetails,
      UserId: Number(localStorage.getItem("user_id")),
    };
    this.appointmentService
      .SavePostponnedAppointments(AppData)
      .subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Postponned Appointment added successfully");
          this.GetAppointments();
          this.PostponedAppointments = false;


        } else {
          this.toastr.error("Postponned Appointment failed");
        }
      });
  }

  //Details to show Appoinment Status modal
  EditStatusModal(appId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 177)
    if (foundElement.id == 177 && foundElement.isAllowed == true) {
    this.EditStatusAppId = appId;
    this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      this.form.patchValue({
        appointmentStatus: this.AppointmentDataDetails["appStatus"],
      });
    });
    this.EditStatus = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  //Inserting Appointment Status
  SaveAppointmentStatus(appId) {
    appId = this.EditStatusAppId;
    var AppData = {
      appId: this.EditStatusAppId,
      appointmentStatus: this.form.get("appointmentStatus").value,
    };

    // return false;
    this.appointmentService.SaveAppointmentstatus(AppData).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success(" Appointment status added successfully");

        this.GetAppointments();
        this.GetProgressBar();
      } else {
        this.toastr.error("Appointment status failed");
      }
    });
  }

  reload() {
    // this.GetDepartments(); //on load api function getting departments
    this.GetAppointments(); //on load api function getting appoinments
    this.GetConsultations();//on load api function getting Consultations
    this.GetProgressBar();//on load api function getting Progressbar
    this.GetTemplateReasonForVisit();//on load api function getting reasonforvisit
    // this.toastr.success("Data Refreshed");
  }
  openEmr() {

    let patientInfo = {
      'VisitId': 0,
      'ConsultantId': Number(localStorage.getItem('Id')),
      'ConsultationId': 0,
      'lastConsultationId': 0,
      'PatientId': this.patientId,
      'VisitType': 0,
      'VisitStartTime': '',
      'VisitEndTime': '',
      'UserId': Number(localStorage.getItem('user_id')),
      'CrudType': 0,
      InsAmountLimit: 0,
      insCovered: 0
    }
    let OutPut = this.EMRService.SetEMRPatientInfo(patientInfo);
    if (OutPut) {
      this.router.navigate(['/EMR_Home'],{state:{patientDatas:true}});
    }
  }

  showNewBill(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 185)
    if (foundElement.id == 185 && foundElement.isAllowed == true) {
    sessionStorage.setItem('bill-patient-regNo', JSON.stringify({regNo: data?.regNo,
                                                                  consulatantID: data?.consultantId ? data.consultantId : 0,
                                                                  consulatationId: data?.consultationId}));
    this.router.navigate(['/newbill'], {queryParams: {returnUrl: 'FrontOffice'}});
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  progressBar() {

    let CurrentDate = new Date();
    let date = this.datePipe.transform(CurrentDate, "dd-MM-yyyy");
    let consultantId = Number(this.form.get("consultantId").value);
    let input = {
      "ConsultantId": Number(consultantId),
      "AppDate": date,
      "BranchId": this.BranchesId
    }
    this.consultationsService.GetProgressBar(input).subscribe((res) => {
      this.progressBarData = res.response;

      this.waiting = this.progressBarData[0]['appStatCountW'];
      this.Arrived = this.progressBarData[0]['appStatCountA'];
      this.Cancelled = this.progressBarData[0]['appStatCountC'];
      this.Confirmed = this.progressBarData[0]['appStatCountCF'];

      this.Consultwaiting = this.progressBarData[0]['conStatCountW'];
      this.ConsultOngoing = this.progressBarData[0]['conStatCountO'];
      this.ConsultCancelled = this.progressBarData[0]['conStatCountC'];
      this.Consultfinished = this.progressBarData[0]['conStatCountF'];
    })
  }
  //api/Consultant/GetFrontOfficeProgressBarByConsultantId

  openCredit(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 179)
    if (foundElement.id == 179 && foundElement.isAllowed == true) {
    if(data?.patientId){
      let input={
        patientId: data.patientId,
        creditId: '',
        patientName: data.patientName,
        regNo: data.regNo,
        consultationId:data.consultationId
     }
     this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  SponsrorConsent(data,type, actionId){
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    if(type == 'SC' && data.sponsorId == 0){
      this.toastr.warning("Patient doesn't have sponsor..!!");
      return
    }

    if(type == 'SC' && data.consentStatus == 0){
      this.toastr.warning("Sponsor doesn't have consent..!!");
      return
    } console.log(data)

      if (this.consentSettings[1].settValue == '0' && type == 'PC') {
        let initialState = {
          rowData: data
        }
        let config: ModalOptions = {
          backdrop: true,
          ignoreBackdropClick: true,
        }
        const modalRef = this.modalService.show(StaticPatientConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
      } else if (this.consentSettings[0].settValue == '0' && type == 'IC') {
        let initialState = {
          ConsentPatientId: data.patientId,
          cType: type,
          data:data,
          branchId: this.globals.HospitalId,
          consultationId: data.consultationId
        }
        let config: ModalOptions = {
          backdrop: true,
          ignoreBackdropClick: true,
        }
        const modalRef = this.modalService.show(InformedStaticConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
      }  else {
        let initialState = {
          ConsentPatientId: data.patientId,
          cType: type,
          SelectedSponsor: data.sponsorId,
          data:data
        }
        let config: ModalOptions = {
          backdrop: true,
          ignoreBackdropClick: true,
        }
        const modalRef = this.modalService.show(SponsorConsentComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
      }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  printAsoap(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 183)
    if (foundElement.id == 183 && foundElement.isAllowed == true) {
    let initialState = {
      consultationId: data.consultationId,
      branchId: this.BranchesId,
      sponsorId: data.sponsorId,
      telerikName:data.telerikName
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(PrintAsoapComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
    } else {
      this.toastr.warning("You are not allowed");
    }
  }


  clearFormReason() {
    this.searchForm.reset({
      Symptoms:0,
      otherreasonforvisit:'',
      blockReason:'',

    })
  }

  onPrintSticker(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 184)
    if (foundElement.id == 184 && foundElement.isAllowed == true) {
    const payload = {
      PatientId: data.patientId,
      BranchId: this.globals.HospitalId,
      ConsultationId: 0
    }
    this.patientService.printSticker(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          window.open(this.baseUrl + response.response[0].reportPath, '_blank')
        }else{
          this.toastr.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastr.error('Something wents wrong..! Try again.')
      }
    })
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  onRegisterfromAppoinment(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 173)
    if (foundElement.id == 173 && foundElement.isAllowed == true) {
    this.router.navigate(['/AddRegistration'], {state: {appoinmentId: data.appId}});
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  editAppointment(appId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 171)
    if (foundElement.id == 171 && foundElement.isAllowed == true) {
      this.router.navigate(["/AddAppointment", appId]);
    } else {
      this.toastr.warning("You are not allowed to edit appointment");
    }
  }

  gotoConsultation(appId?) {
    const foundElement = this.functionPermissionData.find(x => x.id === 172)
    if (foundElement.id == 172 && foundElement.isAllowed == true) {
      if(appId){
        this.router.navigate(["/AddConsultation", appId]);
      } else{
        this.router.navigate(["/AddRegistration"]);
      }
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  gotoServiceOrder(patientId, consultationsId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 178)
    if (foundElement.id == 178 && foundElement.isAllowed == true) {
      this.router.navigate(['/serviceOrder'], { state: { patientId: patientId ,consultationId:consultationsId} });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  gotoPrint(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 182)
    if (foundElement.id == 182 && foundElement.isAllowed == true) {

    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  showHealthSummary(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 484)
    if (foundElement.id == 484 && foundElement.isAllowed == true) {
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      patientId: data.patientId,
      branchId: this.globals.HospitalId
    }
    const modalRef = this.modalService.show(HealthSummaryModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }));
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  getApplicationSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }

    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.consentSettings = _.filter(response.response, {tabId: 5});
          console.log('consentSettings', this.consentSettings);
          this.healthPlatform=response.response.find(item => item.settId == 25)
        }
      }
    })
  }

  nabithConsent(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 574)
    if (foundElement?.id == 574 && foundElement.isAllowed == true) {
    if (this.healthPlatform.settValue === "Nabidh") {
    let initialState = {
      patientId: data.patientId,
      ConsultationId: data.consultationId,
      action: 1,
      formName:this.healthPlatform.settValue
    }

    // debugger
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(NabithConsentComponent,Object.assign( {}, config, { class: 'modal-xl', initialState }) );
    this.modalRef.content.fileEmitter.subscribe((response: any)=>{
      if(response.status == true){
        // this.toastr.success("The signature updated successfully");
      }
    })
    } else if (this.healthPlatform.settValue === "Riayati") {
      let initialState = {
        patientId: data.patientId,
        ConsultationId: data.consultationId,
      }

      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.modalRef = this.modalService.show(RiayatiConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
      this.modalRef.content.fileEmitter.subscribe((response: any) => {
        if (response.status == true) {
          // this.toastr.success("The signature updated successfully");
        }
      })
    }
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  GetConsultationCount(){
    let fTime = '';
    let tTime = '';

    fTime = this.countForm.controls.fromTimeCount.value;
    tTime = this.countForm.controls.toTimeCount.value;

    if(fTime == '' || fTime == null || fTime == undefined){
      this.toastr.warning('Pick a From Time');
      return
    }

    if(tTime == '' || tTime == null || tTime == undefined){
      this.toastr.warning('Pick a To Time');
      return
    }

    let  payload = {
      BranchId:this.globals.HospitalId,
      FromTime: this.datepipe.transform(new Date(), 'dd-MM-yyyy') + " " + this.countForm.controls.fromTimeCount.value,
      ToTime: this.datepipe.transform(new Date(), 'dd-MM-yyyy') + " " + this.countForm.controls.toTimeCount.value,
      ConsultantDate: this.datepipe.transform(new Date(), 'dd-MM-yyyy')
    }

    this.consultationsService.GetConsultationCount(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.consultationCount = response.response[0].consultationCount;
        }
      }
    })
  }

  onPrintToken(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 576)
    if (foundElement?.id == 576 && foundElement.isAllowed == true) {
    let initialState = {
      modalHeading: 'Print Token',
      reportName: 'RptPrintToken.trdp',
      reportParams: {
        BranchId: this.BranchesId,
        ConsultationId : data.consultationId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  editCredit(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 539)
    if (foundElement?.id == 539 && foundElement.isAllowed == true) {
   let input={
       patientId:data.patientId,
       creditId:data.creditId,
       patientName:data.patientName,
       regNo:data.regNo
    }
    this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }

  openServiceOrder(data){
    // debugger
    const foundElement = this.functionPermissionData.find(x => x.id === 575)
    if (foundElement?.id == 575 && foundElement.isAllowed == true) {
    let initialState = {
      ConsultationId: data.consultationId
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(ServiceListModalComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  openInfo(event){
    let data=event.data
    let orderIds=event.data.orderIds?.map((item => item.orderId))
    let initialState = {
        data:{
          BranchId : this.globals.HospitalId,
          patientId:data.patientId,
          ConsultationId:data.consultantId,
          OrderDate:"",
          Today:this.convertDateFormat(data.consultDate),
          locationId:this.globals?.Location?.locationId,
          SpointId:0,
          OrderIds:orderIds ? orderIds :[]
        }    
    }
    debugger
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(StatusInfoModalComponent, Object.assign({}, config, { class: 'modal-m', initialState }))
    
  }

  convertDateFormat(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    const date = new Date(+year, +month - 1, +day); // Months are 0-based in JavaScript Date
    return this.datePipe.transform(date, 'dd-MM-YYYY') ?? '';
  }

}
