import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { Router } from '@angular/router';
import { globalvars } from '../../../shared/models/GlobalVars';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ChangeClaimDetailsModalComponent } from '../../claim/modals/change-claim-details-modal/change-claim-details-modal.component';
import { EMRService } from '../services/emr.service';
import { Response } from '../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../../../shared/modals/confirm-modal/confirm-modal.component';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';

@Component({
  selector: 'app-service-orders-home',
  templateUrl: './service-orders-home.component.html',
  styleUrls: ['./service-orders-home.component.scss']
})
export class ServiceOrdersHomeComponent implements OnInit {

  @Input() serviceOrders: any[] = [];
  @Input() globals: globalvars;

  @Output() serviceOrderRefresh = new EventEmitter()

  public allStaffList: any[] = [];

  private gridApi: GridApi;
  public columnDefs: ColDef[] = [];
  public context = { componentParent: this };
  public frameworkComponents = {
    buttonRender: GeneralActionComponent,
  };
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules :{
      'cell-expired':function(params){
        return params.data.status == 'Cancelled' ? true : false
      },
      'ag-row-selected' : function(params) {
          return true
      },
    },
    domLayout: 'autoHeight'
  };

  private EMRPatientInfo: EMRPatientInfo;
  private baseUrl: string = '';
  today: any;
  BranchesId: number;
  subscription: any;

  constructor(private router: Router,
              private modalService: BsModalService,
              private toastrService: ToastrService,
              private configurationManager: ConfigurationManager, 
              private emrService: EMRService,
              private sharedservice: SharedService,
              private datepipe: DatePipe) { 
                this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
                  if (this.sharedservice.checkglobals(glob)) {
                    this.globals = glob;
                    this.BranchesId = this.globals.HospitalId;
                  }
                });
              }

  ngOnInit(): void {
    this.getAllStaffs();
    this.columnDefs = [
      {
        headerName: "Item Name",
        minWidth: 100,
        field: "itemName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 4,
      },
      {
        headerName: "Status",
        minWidth: 100,
        field: "status",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Pay Status",
        minWidth: 100,
        field: "payStatus",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Consultant",
        minWidth: 100,
        field: "consultantName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Treatment Status",
        minWidth: 180,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        cellRenderer: params => {
          if(params.data.isFinished == 1) {
            return 'Finished'
          } else{
            return 'Not Finished'
          }
        },
      },
      {
        headerName: "Document Name",
        minWidth: 100,
        field: "documentName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Action",
        maxWidth: 80,
        resizable: true,
        sortable: false,
        field: "Id",
        cellStyle: {color: '', 'text-decoration': 'none'},
        pinned: "right",
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "serviceOrderEMRHome" },
      },
    ];

    this.EMRPatientInfo = this.emrService.GetEMRPatientInfo();
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.today = this.datepipe.transform(new Date(), 'dd/MM/yyyy');

    
  }

  getAllStaffs(){
    let staffPayload = {
      StaffId: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.emrService.getAllStaffs(staffPayload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.allStaffList = response.response;
        }else{
          this.toastrService.error(response.message)
        }
      },error: (error: any)=>{
        this.toastrService.error('Failed to load staff details, Try again..!');
      }
    })
  }


  onGridReady(params) {
    this.gridApi = params.api;
  }

  onReportWorkList(data: any){
    let payload={
      patientId:data.patientId,
      consultationId:data.consultationId,
      orderDetId:data.orderDetId
    }
    localStorage.setItem("reportWorkListData",JSON.stringify(payload))
    this.router.navigate([`ServiceList/${data?.orderId}/report/${data?.itemId}`])
  }

  onViewDocument(data: any){
    window.open(this.baseUrl+data?.documentName, '_blank')
  }

  onChangeDetails(data: any){
    let initialState = {
      transId: 0,
      branchId: this.globals.HospitalId,
      orderId: data?.orderId,
      allStaffList: this.allStaffList
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(ChangeClaimDetailsModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
  }

  onPrinttem(data: any){
    let initialState = {
      modalHeading: 'Print Service Order Details',
      reportName: 'RptServiceOrderDetails.trdp',
      reportParams: {
        ConsultationId: this.EMRPatientInfo.ConsultationId,
        OrderId: data.orderId,
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
  }

  PrintServiceOrder(order:any) {
    let initialState = {
      modalHeading: 'Print Service Order Details',
      reportName: 'RptServiceOrderDetails.trdp',
      reportParams: {
        ConsultationId: this.EMRPatientInfo.ConsultationId,
        OrderId: order.orderId,
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
  }
   
  PrintServiceOrderOnDate(order:any){
    let initialState = {
      modalHeading: 'Result on Date',
      reportName: 'RptLabResultOnDate.trdp',
      reportParams: {
        PatientId: order?.patientId,
        ConsultantId: order?.consultantId,
        DateValue: this.convertDateFormat(order.orderDate),
        BranchId: this.BranchesId,
        UserId: Number(localStorage.getItem('user_id'))
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  }

  printItemWithstamp(data: any){
    let initialState = {
      modalHeading: 'Print Result With Stamp',
      reportName: 'RptLabResultWithStamp.trdp',
      reportParams: {
        RformId: data.rFormId,
        ResultId: data.resultId,
        BranchId: this.BranchesId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  }

  printItemWithOutstamp(data: any){
    let initialState = {
      modalHeading: 'Print Result Without Stamp',
      reportName: 'RptLabResult.trdp',
      reportParams: {
        RformId: data.rFormId,
        ResultId: data.resultId,
        BranchId: this.BranchesId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  }

  convertDateFormat(dateString: string): string {
    // Parse the input date string in the format dd/MM/yyyy
    const [day, month, year] = dateString.split('/');
    const date = new Date(+year, +month - 1, +day); // Months are 0-based in JavaScript Date

    // Use DatePipe to transform the date to yyyy-MM-dd format
    return this.datepipe.transform(date, 'yyyy-MM-dd') ?? '';
  }

  onAddOrder(){
    let input={
      patientId: this.EMRPatientInfo.PatientId,
      consultationId: this.EMRPatientInfo.ConsultationId,
    }
    this.router.navigate(['/serviceOrder'], {state: input})
  }

  onCancelItem(data: any){
    let initialState = {
      heading: 'Confirm Cancel Service Order',
      content: `<p>Are you sure you want to cancel service order with order number <b>${data?.orderNo}</b></p>`,
      isReasonNeeded: true,
      yesButton: 'Confirm',
      noButton: 'Cancel'
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(ConfirmModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    modalRef.content.confirmEmitter.subscribe((res)=>{
      if(res.status){
        let payload = {
          CancelReason: res?.reason,
          OrderId: data?.orderId,
          ItemId: data?.itemId,
          UserId: Number(localStorage.getItem('user_id')),
          SessionId: 0
        }

        this.emrService.CancelServiceOrder(payload).subscribe({
          next: (response: Response<any>)=>{
            if(response.status == 200 && response.message == 'Success'){
              this.toastrService.success('Service order cancelled successfully');
              this.serviceOrderRefresh.emit({refresh: true});
            }else{
              this.toastrService.error(response.message)
            }
          },error: (error: any)=>{
            this.toastrService.error('Something wents wrong.. Try again');
          }
        })
      }
    })
  }

}
