import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from '../../emr/services/emr.service';
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DentalExamination } from '../interfaces/DentalExamination';

@Component({
  selector: 'app-dental-examination',
  templateUrl: './dental-examination.component.html',
  styleUrls: ['./dental-examination.component.scss']
})
export class DentalExaminationComponent implements OnInit {

  EMRPatientInfo:EMRPatientInfo;

  form: FormGroup; // form variable
  Examination:DentalExamination;
  PrevExamination:DentalExamination[]=[];

  Loading:boolean=false;
  // FormValueChangeChecker: boolean = false;

  constructor(
    private EMRService:EMRService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();
  }

  ngOnInit(): void {

    this.form= new FormGroup({
      id: new FormControl(0),
      extraOral: new FormControl(""),
      softTissue: new FormControl(""),
      hardTissue: new FormControl(""),
      others: new FormControl(""),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
    });

    this.GetExamination();
    this.GetPrevExamination();
  }

  SaveExamination(){
    if(this.EMRPatientInfo.VisitId>0 && this.Loading)
    {
      this.Examination=this.form.getRawValue();
      this.Examination.userId=Number(localStorage.getItem('user_id'));
      this.EMRService.SaveDentalExamination(
        this.Examination
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastr.success("Success: " + "Examination Details Saved Successfully...");
          }
          else{
            Swal.fire(
              'Error!',
              'Some unexpected error occures! try again later.',
              'error'
            )
          }
      });

    }
    // else {
    //   if (!this.FormValueChangeChecker) {
    //     // this.toastr.warning("Info: " + "No changes detected in the entry");
    //   }
    // }
  }

  GetExamination(){
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };
  this.EMRService.GetDentalExamination(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.form.patchValue(response);

        }
      });
      this.Loading=true;

  }

  GetPrevExamination(){
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":0
    };
  this.EMRService.GetDentalExamination(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.PrevExamination=res.response;
        }
      });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
