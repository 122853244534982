import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  billTab: boolean = true;
  configurationTab: boolean;
  othersTab: boolean;
  billForm: FormGroup;
  configurationForm: FormGroup;
  otherForm: FormGroup;
  constructor() { }

  ngOnInit(): void {

  }
  onTab(number) {
    this.billTab = false;
    this.configurationTab = false;
    this.othersTab = false;

    if (number == "1") {
      this.billTab = true;
    } else if (number == "2") {
      this.configurationTab = true;
    } else if (number == "3") {
      this.othersTab = true;
    }
  }

}
