import { Component, OnInit, ViewChild, Input, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { noop, Observable, Observer, of, Subscription } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { Response } from "../../../_Models/Response";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { EMRPatientInfo } from "../interfaces/EMRPatientInfo";
import { EMRService } from "../../emr/services/emr.service";
import { ToastrService } from "ngx-toastr";
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";
import { Drugs } from "../interfaces/Drugs";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { DrugsForm } from "../interfaces/DrugsForm";
import Swal from "sweetalert2";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { ReportRequest } from "../interfaces/ReportRequest";
import { ConfigurationService } from "../../client/services/configuration.service";
import { ConsultantDeskService } from "../../client/services/consultant.service";
import * as _ from "lodash";

import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { TelerikPrintComponent } from "../../../shared/modals/telerik-print/telerik-print.component";
import { SettingsService } from "../../settings/services/settings.service";
import { DrugDosageComponent } from "../consultation-general-single-page/modals/drug-dosage/drug-dosage.component";
import { AddEditFrequencyComponent } from "../modals/add-edit-frequency/add-edit-frequency.component";
import { DatePipe } from "@angular/common";
import { PatientService } from "../../client/services/patient.service";

interface drugs {
  drugId: number;
  DosageId: number;
  drugName: string;
  branchId: number;
  packNo: number,
  packagePrice: number,
  granularUnit: number
}

@Component({
  selector: "app-general-drugs-medication",
  templateUrl: "./general-drugs-medication.component.html",
  styleUrls: ["./general-drugs-medication.component.scss"],
})
export class GeneralDrugsMedicationComponent implements OnInit, OnDestroy {
  @Input() IsViewtab;
  @Input() functionPermissionData
  EMRPatientInfo: any;
  search?: string;
  public searchType: Number = 1;
  suggestions$?: Observable<drugs[]>;
  ConsultantSuggestions$?: Observable<drugs[]>;
  errorMessage?: string;
  private baseUrl: string = "";
  IsDisabled: boolean = true;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;
  DrugRow: Drugs[];
  DrugsForm: DrugsForm;
  PrevDrugsHistory: DrugsForm[] = [];
  form: FormGroup; // form variable
  ModalDrugs: any[] = [];
  GetDosageListing: any;
  GetRouteListing: any;
  GetFrequencyListing: any;
  Request: ReportRequest = new ReportRequest();
  modalRef?: BsModalRef;
  @ViewChild("template") modaltemp: any;
  ConsultantId: any;
  DrugsResponse: any;
  dropdownSettings: any = {};
  GetDrugList: any;
  DrugSel: any;
  GetDrugListData: any;
  FormValueChangeChecker: boolean = false;
  Drugsdetails: any;
  ishookError:boolean=false;
  isCancelERX:boolean=false

  public showAllItems: boolean = false;

  public totalAmount: any = 0.00;

  public dropdownOptions: Options;
  public dropdownDataConfig: any = {
    showCovered: true,
    showFrequent: true
  }
  configSetting :any;
  eReference: any;
  dhaRule: boolean;
  HealthInformationPlatform: any;
  constructor(
    private http: HttpClient,
    private sharedservice: SharedService,
    private EMRService: EMRService,
    private toastr: ToastrService,
    private configurationManager: ConfigurationManager,
    private modalService: BsModalService,
    private settingsService: SettingsService,
    private ConfigurationService: ConfigurationService,
    private ConsultantDeskService: ConsultantDeskService,
    private datePipe:DatePipe,
    private patientService:PatientService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  ngOnInit(): void {

    if (this.IsViewtab == true) {
      this.IsDisabled = false;
    } else if (this.IsViewtab == "undefined") {
      this.IsDisabled = true;
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: "drugId",
      textField: "drugName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };

    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.search);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<drugs[]>>(
              `${this.baseUrl}api/EMR/GetDrugsAutoComplete`,
              {
                drugName: this.searchType == 1 ? query : '',
                branchId: this.BranchesId,
                //"consultantId":this.EMRPatientInfo.ConsultantId
                consultantId: 0,
                TradeName: this.searchType == 2 ? query : '',
                ScientificName: this.searchType == 3 ? query : '',
                ConsultationId: this.EMRPatientInfo.ConsultationId
              }
            )
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  this.errorMessage =
                    (err && err.message) || "Something goes wrong";
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */



    this.form = new FormGroup({
      drugDetails: new FormArray([]),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
    });
    this.LoadDrugFrequently();
    this.GetDrugsEMR();
    this.GetPrevDrugsEMR();
    this.GetDosage();
    this.GetRoute();
    this.GetFrequency();
    this.getAppSettings();
    this.GetEPrescriptionDetails()
    this.dropdownOptions = {
      templateResult: this.templateResult,
    }
    
  }

  // function for result template
  public templateResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    let icon = '<i class="fa" aria-hidden="true"></i>';
    if (state.additional.insCovered) {
      icon = '<i class="fa fa-umbrella" aria-hidden="true"></i>';
    }
    return jQuery('<span class="d-flex justify-content-between"><b>'+ state.text + '   '+icon+ '</b></span>');
  }

  LoadDrug(drugInfo) {
    let input = {
      consultantId: 0,
      drugId: drugInfo.drugId,
      BranchId: this.globals.HospitalId,
      ConsultationId: this.EMRPatientInfo.ConsultationId
    };
    input;
    this.EMRService.GetConsultantDrugs(input).subscribe((res) => {
      if (res.status == 200) {
        this.DrugRow = res.response;

        this.addMapFormGroup(this.DrugRow);
      }
    });
  }


  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    if (!data['duration']) data['duration'] = "";
    if (!data['qty']) data['qty'] = "";

    if (!data['instruction']) data['instruction'] = "";
    return new FormGroup({
      drugId: new FormControl(data["drugId"]),
      drugName: new FormControl(data["drugName"]),
      tradeName: new FormControl(data["tradeName"]),
      scientificName: new FormControl(data["scientificName"]),
      routeId: new FormControl(data["routeId"]),
      duration: new FormControl(data["duration"]),
      freqId: new FormControl(data["freqId"]),
      DurationMode: new FormControl(data["durationMode"]),
      qtyinMl: new FormControl(data['qtyinMl']),
      qty: new FormControl(data["qty"] ? data['qty'] : 1),
      instruction: new FormControl(data["instruction"]),
      DosageId: new FormControl(data["dosageId"]),
      insCovered: new FormControl(data['insCovered']),
      packagePrice: new FormControl(data['packagePrice']? data['packagePrice'] : 0),
      granularUnit: new FormControl(data['granularUnit']? data['granularUnit'] : 0),
      packNo: new FormControl(data['packNo']? data['packNo'] : 0),
      rate: new FormControl((Number(data['granularUnit']) !== 0) ? (Number(data["packagePrice"])/Number(data["granularUnit"])).toFixed(2) : 0),
      intakeInstruction:new FormControl(data['intakeInstruction'] ? data['intakeInstruction'] : 'After food'),
      ConsumptionUnit:new FormControl(data['consumptionUnit'] )
    });
  }

  public addMapFormGroup(data: any[] = [], saved = 0) {
    const Drugs = this.form.get("drugDetails") as FormArray;
    if (data.length > 0) {
      data.forEach((element, i) => {
        let filteredArray = Drugs.value.filter(value => value.drugId == element.drugId);
        if (filteredArray.length == 0) {
          //  Drugs.push(this.createListGroup(element, saved));
          Drugs.insert(0, this.createListGroup(element, saved));
        }
        else {
          this.toastr.error("Failure: " + "Drug Already added");
        }
      });
      // setTimeout(() => {
      //   document?.getElementById("qty0").focus();

      // }, 500);

      setTimeout(() => {
        // document?.getElementById("routeId-"+(Drugs.length - 1)).focus();
        document?.getElementById("routeId-"+0).focus();


      }, 500);

    }
    // else if(data){
    //   Drugs.push(this.createListGroup(data,saved));
    // }


    this.search = "";
    this.calculateTotalAmount();
  }

  saveDrugs() {
    if (
      this.EMRPatientInfo.CrudType != 3 &&
      this.EMRPatientInfo.VisitId > 0 && this.FormValueChangeChecker) {
      this.DrugsForm = this.form.getRawValue();
      this.DrugsForm.drugDetails.forEach((a) => {
        a.DosageId = Number(a.DosageId);
        a.routeId = Number(a.routeId);
        a.freqId = Number(a.freqId);
        a.qty=Number(a.qty);
        a.DurationMode=Number(a.DurationMode)
      });
      this.DrugsForm.userId = Number(localStorage.getItem("user_id"));
      this.EMRService.SaveDrugs(this.DrugsForm).subscribe((res) => {
        if (res.status == 200 && res.message == "Success") {
          this.toastr.success(
            "Success: " + "Drugs And Medication Details Saved Successfully..."
          );
          this.sendDataToNabid(this.DrugsForm.drugDetails)
        } else {
          Swal.fire(
            "Error!",
            "Some unexpected error occures! try again later.",
            "error"
          );
        }
      });
    }
    else {
      if (!this.FormValueChangeChecker) {
        //this.toastr.warning("Info: " + "No changes detected in the entry");
      }
    }
  }

  GetDrugsEMR() {
    let input = {
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 1,
      ConsultationId: this.EMRPatientInfo.ConsultationId
    };

    this.EMRService.GetDrugsEMR(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];
        this.Drugsdetails = response;
        if (response) {
          this.dhaRule=response.isDHARuleEnabled == 1 ? true : false 
          if (response.drugDetails) {
            this.addMapFormGroup(response.drugDetails);
          }
        }
        this.form.valueChanges.subscribe(x => {
          this.FormValueChangeChecker = true;
        })
      }
    });
  }

  GetPrevDrugsEMR() {
    let input = {
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 0,
      ConsultationId: this.EMRPatientInfo.ConsultationId
    };

    this.EMRService.GetDrugsEMR(input).subscribe((res) => {
      if (res.status == 200) {
        this.PrevDrugsHistory = res.response;
      }
    });
  }

  openModal(DrugDetails) {
    this.ModalDrugs = DrugDetails;
    this.modalRef = this.modalService.show(this.modaltemp, {
      class: "modal-lg modal-custom-style",
    });
  }

  print() {
    let initialState;
    if(this.searchType === 1) {
      initialState = {
        modalHeading: 'Print Drugs Prescription',
        reportName: 'RptPrintDrugsPrescription.trdp',
        reportParams: {
          VisitId: this.EMRPatientInfo.VisitId,
          ShowAll: 1,
          PatientId: this.EMRPatientInfo.PatientId,
          ConsultationId: this.EMRPatientInfo.ConsultationId,
          BranchId: this.globals.HospitalId
        }
      }
    } else if(this.searchType === 2) {
      initialState = {
        modalHeading: 'Print Trade Prescription',
        reportName: 'RptPrintDrugsPrescriptionwithTradeName.trdp',
        reportParams: {
          VisitId: this.EMRPatientInfo.VisitId,
          ShowAll: 1,
          PatientId: this.EMRPatientInfo.PatientId,
          ConsultationId: this.EMRPatientInfo.ConsultationId,
          BranchId: this.globals.HospitalId
        }
      }
    } else if(this.searchType === 3){
      initialState = {
        modalHeading: 'Print Scientific Prescription',
        reportName: 'RptPrintDrugsPrescriptionwithScientificName.trdp',
        reportParams: {
          VisitId: this.EMRPatientInfo.VisitId,
          ShowAll: 1,
          PatientId: this.EMRPatientInfo.PatientId,
          ConsultationId: this.EMRPatientInfo.ConsultationId,
          BranchId: this.globals.HospitalId
        }
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    // this.Request.ReportType = 1;

    // let ReportInput = {
    //   VisitId: this.EMRPatientInfo.VisitId,
    //   PatientId: this.EMRPatientInfo.PatientId,
    // };
    // this.Request.ReportInput = ReportInput;

    // localStorage.setItem("ReportRequest", JSON.stringify(this.Request));
    // const url = "#/EMR_prints";
    // window.open(url);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  checkValue(i, formValue) {
    let form = this.form.controls["drugDetails"].value;
    if (formValue == "duration") {
      let duration = form[i].duration;
      if (!duration) {
        this.toastr.warning(
          "Warning: " + "Duration cannot be empty, It may cause error"
        );
      }
    }

    if (formValue == "qty") {
      let qty = form[i].qty;
      if (!qty) {
        this.toastr.warning(
          "Warning: " + "Quantity cannot be empty, It may cause error"
        );
      }
    }

  }

  GetDosage() {
    let ShowAll = 0;
    this.ConfigurationService.GetDosage(
      this.globals.HospitalId,
      ShowAll
    ).subscribe((res) => {
      this.GetDosageListing = res.response;
    });
  }

  GetRoute() {
    this.ConsultantDeskService.GetRoute(this.globals.HospitalId).subscribe(
      (res) => {
        this.GetRouteListing = res.response;
      }
    );
  }

  GetFrequency() {
    let ShowAll = 0;
    this.ConfigurationService.GetFrequency(
      this.globals.HospitalId,
      ShowAll
    ).subscribe((res) => {
      this.GetFrequencyListing = res.response;
    });
  }

  RemoveFromDrugList(index) {
    var Value = new FormArray([]);
    Value = this.form.get("drugDetails") as FormArray;
    Value.removeAt(index);
    this.calculateTotalAmount();
  }

  LoadDrugFrequently() {
    let input = {
      consultantId: this.EMRPatientInfo.ConsultantId,
      drugId: 0,
      BranchId: this.globals.HospitalId,
      ConsultationId: this.EMRPatientInfo.ConsultationId,
      IsInsured: this.dropdownDataConfig.showCovered ? 1 : 0,
      IsFreq: this.dropdownDataConfig.showFrequent ? 1 : 0
    };
    this.EMRService.GetConsultantDrugs(input).subscribe((res) => {
      if (res.status == 200) {
        this.GetDrugList = res.response;
        this.GetDrugListData = [];
        this.GetDrugList.forEach((a) => {
          this.GetDrugListData.push({
            id: a.drugId.toString(),
            text: a.drugName,
            additional: {
              insCovered: a.insCovered
            }
          });
        });
        
        this.GetDrugListData=this.GetDrugListData.reduce((acc, current) => {
          if (!acc.some(item => item.id === current.id)) {
            acc.push(current);
          }
          return acc;
        }, []);
        console.log(this.GetDrugListData)
      }
    });
  }

  LoadFrequentlyUsedDrugs(drugInfo) {
    if (drugInfo) {
      let input = {
        consultantId: this.EMRPatientInfo.ConsultantId,
        drugId: Number(drugInfo),
        BranchId: this.globals.HospitalId,
        ConsultationId: this.EMRPatientInfo.ConsultationId,
        IsInsured: this.dropdownDataConfig.showCovered ? 1 : 0,
        IsFreq: this.dropdownDataConfig.showFrequent ? 1 : 0
      };
      this.EMRService.GetConsultantDrugs(input).subscribe((res) => {
        if (res.status == 200) {
          this.DrugSel = res.response;
          this.addMapFormGroup(this.DrugSel);
        }
      });
    }
  }

  RemoveFromSelect(event) {
    var EventValue = new FormArray([]);
    EventValue = this.form.get("drugDetails") as FormArray;
    EventValue.removeAt(EventValue.value.findIndex(element => element.drugId === event.drugId));
  }

  markHook(info){
    if(this.ishookError) return
      this.ishookError=true
      let hookString="";
      hookString="Drug: "+info.drugName  + " , Duration : " + info.duration;
      if(info.instruction){
        hookString=hookString+" , Notes: "+info.instruction;
      }
    if (this.EMRPatientInfo.PatientId > 0) {

    let formdata={
      hookid:0,
      hookDesc:hookString,
      patientId:Number(this.EMRPatientInfo.PatientId),
      branchId: this.globals.HospitalId,
      IsDisplayed: true,
      IsDeleted: 0
    }

        this.EMRService.SaveHook(formdata).subscribe((res) => {

          if (res.status == 200 && res.message == "Success") {
            this.toastr.success(info.drugName + " Successfully added to hook list");
            this.ishookError=false
          }
          else if (res.status == 200) {
            this.toastr.error("Failure: " + res.message);
            setTimeout(() => {
              this.ishookError=false
            },5000);
          }
          else {
           
            setTimeout(() => {
              this.ishookError=false
            },5000);
            this.toastr.error("Some unexpected error occures! try again later.");
          }
        });


    }
  }

  generateInstruction(drug: FormGroup){
    let instruction ='Take';
    let dosageIndex = _.findIndex(this.GetDosageListing, {dosageId: Number(drug.value.DosageId)});
    if(dosageIndex >= 0){
      instruction = instruction+' '+this.GetDosageListing[dosageIndex].dosageDesc;
    }

    let frequencyIndex = _.findIndex(this.GetFrequencyListing, {freqId: Number(drug.value.freqId)});
    if(frequencyIndex >= 0){
      instruction = instruction+' '+this.GetFrequencyListing[frequencyIndex].freqDesc;
    }

    if(drug.value.duration){
      instruction = instruction+' For '+drug.value.duration;
    }

    if(drug.value.DurationMode){
      if(drug.value.DurationMode == 1){
        instruction = instruction+' '+ (drug.value.duration == 1 ? 'Hour' : 'Hours');
      }
      if(drug.value.DurationMode == 2){
        instruction = instruction+' '+ (drug.value.duration == 1 ? 'Day' : 'Days');
      }
      if(drug.value.DurationMode == 3){
        instruction = instruction+' '+ (drug.value.duration == 1 ? 'Week' : 'Weeks');
      }
      
    }

    instruction=instruction + ' ' + (drug.value.intakeInstruction)

    drug.patchValue({
      instruction: instruction
    })
  }

  calculateTotalAmount(){
    let drugItems: any[] = (this.form.get("drugDetails") as FormArray).value;
    this.totalAmount = 0;
    _.forEach(drugItems, (drug: any)=>{
      const unitPrice = (Number(drug.packagePrice)/Number(drug.granularUnit)).toFixed(2);
      const qunatity = Number(drug.qty);
      this.totalAmount = (Number(this.totalAmount) + Number(unitPrice) * Number(qunatity)).toFixed(2);
    });
  }

  calculateQuantity(drug: FormGroup){
    debugger
    let dosageIndex = _.findIndex(this.GetDosageListing, {dosageId: Number(drug.value.DosageId)});
    let dosage: number = dosageIndex>=0 ? this.GetDosageListing[dosageIndex].dosageValue : 0;
    let frequencyIndex = _.findIndex(this.GetFrequencyListing, {freqId: Number(drug.value.freqId)});
    let frequency: number = frequencyIndex>=0 ? this.GetFrequencyListing[frequencyIndex].freqValue : 0;
    let duration: number = drug.value.duration != '' ? Number(drug.value.duration) : 0;;
    let qunatity: number;
    
    if( Number(drug.value.packNo) == 1 && drug.value.ConsumptionUnit == "Ml"){
      let totalUsage = (dosage*frequency)*duration;
      if(totalUsage > 0){
        // let quotient = Math.floor(totalUsage/Number(drug.value.qtyinMl));
        // let reminder = totalUsage % Number(drug.value.qtyinMl);
        // qunatity = reminder > 0 ? (quotient+1) : quotient;
        qunatity = Math.ceil(totalUsage / Number(drug.value.qtyinMl));
      }else{
        qunatity = 1;
      }
    }else{
      let totalUsage = (dosage*frequency)*duration;
      if(totalUsage > 0){
        if(totalUsage/drug.value.qtyinMl <= 1){
          qunatity= drug.value.qtyinMl 
      }else{
        let addOn=totalUsage % drug.value.qtyinMl == 0 ? 0 : 1
        qunatity=(Math.floor(totalUsage/drug.value.qtyinMl) +addOn) * drug.value.qtyinMl  
      }
      }
      // qunatity = (dosage*frequency)*duration;
    }

    drug.patchValue({
      qty: qunatity ? qunatity : 1
     });
     this.calculateTotalAmount();
  }
  generateErx(){
    let val = this.configSetting.filter((item) => item.settId == 33)[0].settValue
    let input:any = {
      ConsultationId: this.EMRPatientInfo.ConsultationId,
      BranchId:  this.globals.HospitalId,
      SponsorId: this.EMRPatientInfo.SponsorId,
      PatientId:this.EMRPatientInfo.PatientId,
      VisitId:this.EMRPatientInfo.VisitId,
      ConsultantId:this.EMRPatientInfo.ConsultantId,
      DispositionFlag: val == '1' ? 'TEST' : 'PRODUCTION'
    };
    this.EMRService.generateErx(input).subscribe((res:any)=>{
      if (res.status == 200 && res.message == "Success") {
        this.toastr.success("Success: " + "Generate ERX Successfully.");
      } else {
        if(res.errorMessage.message == null) {
          this.toastr.error(res.message)
        } else {
          this.toastr.error(res.errorMessage.message)
        }
      }
    })
  }
  getAppSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }
  
    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          let val = response.response
          this.configSetting = val.filter((item) => item.settId == 33 || item.settId == 34);
           this.HealthInformationPlatform=_.find(response.response,{displayName:"HealthInformationPlatform"})
      }
    }
    })
  }
  showAllToggle(){
    this.showAllItems = !this.showAllItems;
  }
  cancelErx(){
    let val = this.configSetting.filter((item) => item.settId == 34)[0].settValue
    let input:any = {
      ConsultationId: this.EMRPatientInfo.ConsultationId,
      BranchId:  this.globals.HospitalId,
      SponsorId: this.EMRPatientInfo.SponsorId,
      PatientId:this.EMRPatientInfo.PatientId,
      VisitId:this.EMRPatientInfo.VisitId,
      ConsultantId:this.EMRPatientInfo.ConsultantId,
      DispositionFlag: val == '1' ? 'TEST' : 'PRODUCTION'
    };
    this.EMRService.cancelErx(input).subscribe((res:any)=>{
      if (res.status == 200 && res.message == "Success") {
        this.toastr.success("Success: " + "Cancel ERX Successfully.");
      } else {
        if(res.errorMessage.message == null) {
          this.toastr.error(res.message)
        } else {
          this.toastr.error(res.errorMessage.message)
        }
      }
    })
  }
  GetEPrescriptionDetails(){
    let input:any = {
      ConsultationId: this.EMRPatientInfo.ConsultationId,
      PatientId:this.EMRPatientInfo.PatientId,
    };
    this.EMRService.GetEPrescriptionDetails(input).subscribe((res:any)=>{
      if (res.status == 200) {
        if(res.response.length){
          this.isCancelERX=res.response.some(item => item.status === 'S')
          const objWithRStatus = res.response.find(obj => obj.status.trim() === "S");
          this.eReference = objWithRStatus ? objWithRStatus.eReferenceNo : null;
        }else{
          this.isCancelERX=false
        }
      } else {
        if(res.errorMessage.message == null) {
          this.toastr.error(res.message)
        } else {
          this.toastr.error(res.errorMessage.message)
        }
      }
    })
  }

  openDosageModal(data,drug){
    this.calculateQuantity(drug)
    if(data != '.0') return
    let initialState = {
      BranchId:this.globals.HospitalId,
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: ''
    }
    const historyModalRef= this.modalService.show(DrugDosageComponent,Object.assign(
      {}, historyConfig, { class: 'modal-md', initialState }) );
      historyModalRef.content.dosageEvent.subscribe(res=>{
        if(res?.data){
          this.GetDosage()
          drug.patchValue({
            DosageId:res.data
          })
           this.calculateQuantity(drug)
        }
      });
  }

  openFrequencyModal(data,drug){
    if(data != '.0') return
    let initialState = {
      DrugFrequencyPopupHeading:"Add",
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: ''
    }
    const frequencyModalRef= this.modalService.show(AddEditFrequencyComponent,Object.assign(
      {}, historyConfig, { class: 'modal-md', initialState }) );
      frequencyModalRef.content.frequencyEvent.subscribe(res=>{
        if(res.data){
          debugger
          this.GetFrequency()
          drug.patchValue({
            freqId:res.data
          })
           this.calculateQuantity(drug)
        }
      });
  }

  trackByDosageId(index: number, item: any): number {
    return item.dosageId;
  }

  trackByfreqId(index: number, item: any): number {
    return item.freqId;
  }

  truncatedText(text): string {
    return text.length > 100 ? text.substring(0, 100) + '...' : text;
  }

  sendDataToNabid(drugList) {
    const consultationId = this.EMRPatientInfo.ConsultationId;
    const patientId = this.EMRPatientInfo.PatientId;
    const currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
  
    const drugPermission = this.functionPermissionData.find(x => x.id === 567);
    
  
    // Check if the platform is 'Nabidh' and permissions are allowed
    if (this.HealthInformationPlatform.settValue === "Nabidh") {
      // Send  drug data if permission is allowed and there are drugs
      if (drugPermission?.isAllowed && drugList?.length > 0) {
        const nabPayload = {
          EventId: 12,
          Consultationid: consultationId,
          EntryDate: currentDate,
          PatientId: patientId,
          BranchId : this.globals.HospitalId
        };
        this.sendToNabidh(nabPayload);
      }else if(drugPermission?.isAllowed == false){
        this.toastr.warning('No permission to send drug data to nabith');
      }
    }
  }
  
  private sendToNabidh(payload: any) {
    this.patientService.SendDetailstoNabith(payload).subscribe({
      next: (res: any) => {
        this.toastr.info('Response from Nabidh: ' + res);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
