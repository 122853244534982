import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Response } from '../../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-expired-drugs-modal',
  templateUrl: './expired-drugs-modal.component.html',
  styleUrls: ['./expired-drugs-modal.component.scss']
})
export class ExpiredDrugsModalComponent implements OnInit {

  @Input() branchId: number;
  @Input() todaysDate: string;
  columnDefs: ColDef[] = [];
  expiredDrugList = [];
  public gridApi: GridApi;
  public rowSelection;

  constructor(private configurationService: ConfigurationService,
    private modalRef: BsModalRef,
    private toastrService: ToastrService
    ) { }

  ngOnInit(): void {
    this.getExpiredDrugs();
    this.columnDefs = [
      {
        headerName: "",
        field: "empty",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        maxWidth: 80,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: true,
        cellRenderer: params => {
            params.node.setSelected(true);
            return null
        },
      },
      {
        headerName: "Drug Name",
        minWidth: 100,
        field: "drugName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Drug Code",
        minWidth: 100,
        field: "drugCode",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Drug Type",
        minWidth: 100,
        field: "drugTypeName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Form",
        minWidth: 100,
        field: "form",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "DDC Code",
        minWidth: 100,
        field: "ddcCode",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Pack No.",
        minWidth: 100,
        field: "packageNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Route",
        minWidth: 100,
        field: "routeDesc",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Scientific Name",
        minWidth: 100,
        field: "scientificName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Market Status",
        minWidth: 100,
        field: "status",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Ingredient",
        minWidth: 100,
        field: "ingredient",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Ingredient Strength",
        minWidth: 100,
        field: "ingredientStrength",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Remarks",
        minWidth: 100,
        field: "remarks",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
    ];
    this.rowSelection = 'multiple'
  }

  getExpiredDrugs() {
    let payload = {
      BranchId: this.branchId,
      ValidUpto: this.todaysDate
    }
    this.configurationService.GetExpiredDrugMaster(payload).subscribe((res) => {
      this.expiredDrugList = res.response;
    });
  }

  close() {
    this.modalRef.hide();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  save() {
    const selectedDrugs = this.gridApi.getSelectedRows();
    let selectedDrugIds = [];
    selectedDrugs.find(x => {
      selectedDrugIds.push(x.drugId);
    })
    let payload = {
      BranchId: this.branchId,
      ExpiredDrugList: selectedDrugIds,
    }
    if (selectedDrugIds.length == 0) {
      this.toastrService.warning('No item selected');
    } else if (selectedDrugIds.length !== 0) {
      this.configurationService.UpdateExpiredDrugList(payload).subscribe({
        next: (response: Response<any>) => {
          if (response.status == 200) {
            this.toastrService.success('Drugs Removed Successfully');
            this.modalRef.hide();
          } else {
            this.toastrService.error(response.message);
          }
        }, error: (error: any) => {
          this.toastrService.error('Something wents wrong, Try again');
        }
      });
    }
  }

}
