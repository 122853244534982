import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';


@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    loginForm: any;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    @ViewChild('username') username: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
    ) { 
        // redirect to home if already logged in
        // if (this.authenticationService.userValue) { 
        //     this.router.navigate(['/']);
        // }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
    // OnInput(form: any) {
    //     this.username = form;

    //     }
    // convenience getter for easy access to form fields
   // get f() { return this.loginForm.controls; }

    onSubmit() {

        this.submitted = true;
alert(this.username.nativeElement.value);
        // stop here if form is invalid
        // if (this.loginForm.invalid) {
        //     return;
        // }

        this.loading = true;
        // this.authenticationService.login(this.f.username.value, this.f.password.value)
        //     .pipe(first())
        //     .subscribe(
        //         data => {sssssssssssssssssss
        //             this.router.navigate([this.returnUrl]);
        //         },
        //         error => {
        //             this.error = error;
        //             this.loading = false;
        //         });
    }
}
