import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { ColDef ,GridOptions} from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { AddEditExternalLabComponent } from '../modals/add-edit-external-lab/add-edit-external-lab.component';
import { BillingService } from '../../billing-settlement/services/billing.service';
import { ConfigurationService } from '../../client/services/configuration.service';
import { constant } from 'lodash';
@Component({
  selector: 'app-externallab',
  templateUrl: './externallab.component.html',
  styleUrls: ['./externallab.component.scss']
})
export class ExternallabComponent implements OnInit {
  public gridApi;
  columnDefs: ColDef[] = [];
  public defaultColDef: ColDef;
  public context;
  public statusBar;
  public noRowsTemplate;
  public frameworkComponents;
  public loadingTemplate = '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';
  public gridOptions = {
    pagination: true,
    paginationPageSize: 20,
  }
  isLoading = false;
  currentPage: number = 1;
  externallabRows: number = 0;
  externallab: any;
  rowGroupPanelShow = "always";
  ExternalLabForm: FormGroup;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  modalRef: BsModalRef;
  @ViewChild("template") modaltemp: any;
  public submitted: boolean = false;
  functionPermissionData: any;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private modalService: BsModalService,
    private billingService: BillingService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {
    this.columnDefs = [

      {
        headerName: "Code",
        minWidth: 150,
        field: "labCode",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,

      },
      {
        headerName: "Name",
        field: "labName",
        minWidth: 150,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Address",
        field: "address",
        minWidth: 150,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        filter: false,
        resizable: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        unSortIcon: true,
        field: "externallabid",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "ExternalLab" },
      },

    ]
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };

    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    this.getExternalLab()
    this.functionPermission();
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }


  getExternalLab() {
    let payload = {
      LabId: 0,
      ShowAll: 1,
      Branchid: this.globals.HospitalId
    }
    this.billingService.getExternalLabs(payload).subscribe(res => {
      if (res.status == 200) {
        this.externallab = res.response
      }
    })
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  openModalExternalLab(data?) {  
    let foundElement;
    if(data == undefined) {
      foundElement = this.functionPermissionData.find(x => x.id === 169)
    } else if(data !== undefined) {
      foundElement = this.functionPermissionData.find(x => x.id === 170)
    }
    const fId = foundElement.id;
    if (foundElement.id == fId && foundElement.isAllowed == true) {
    let initialState = {
      labData:data

    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }

    const modalRef = this.modalService.show(AddEditExternalLabComponent, Object.assign({}, config, { class: 'modal-l', initialState }))
    modalRef.content.createEmitter.subscribe(res => {
      if (res.status) {
        this.getExternalLab()
      }
    })

    // this.modalRef = this.modalService.show(template);
    } else {
      this.toastr.warning("You are not allowed");
    }  
  }
  pageChanged(event) {

  }

    functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }
}
