import { Component, ElementRef, OnInit, QueryList, ViewChildren, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Consultants } from "../../interfaces/Consultants";
import { CountryList } from "../../interfaces/CountryList";
import { Departments } from "../../interfaces/Departments";
import { AppointmentsService } from "../../services/appointments.service";
import { HospitalsService } from "../../services/hospitals.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientService } from "../../services/patient.service";
import { PatientList } from "../../interfaces/PatientList";
import { ScheduleService } from "../../services/schedule.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { State1List } from "../../interfaces/State1List";
import { FormValidationService } from "../../services/form-validation.service";
import { AppointmentTypesList } from "../../interfaces/AppointmentTypesList";
import { FabricCloseIconModule } from "@generic-ui/fabric/common/icons/close-icon/fabric-close-icon.module";
import { TitleList } from "../../interfaces/TitlteList";
import { DatePipe, formatDate } from "@angular/common";
import { AppointmentsList } from "../../interfaces/AppointmentsList";
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralActionComponent } from "../../general-action/general-action.component";
import { CommonValidationComponent } from '../../../../shared/common-validation/common-validation.component';
import { AppointmentScheduleList } from '../../interfaces/AppointmentScheduleList';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { globalvars } from "../../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { SharedService } from "../../../../shared/shared.service";
import { GuiCellView, GuiColumn, GuiPaging, GuiPagingDisplay, GuiSearching, GuiSorting, GuiColumnMenu, GuiInfoPanel, } from "@generic-ui/ngx-grid";
import { SearchPatientModalComponent } from '../../frontoffice/searchpatient-modal/searchpatient-modal.component';
import { ConfigurationService } from "../../services/configuration.service";
import { Location } from '@angular/common';
import { log } from "console";
import { GenderList } from "../../interfaces/GenderList";
import { MaritalStatusList } from "../../interfaces/MaritalStatusList";
import { DomainEventBus } from "@generic-ui/hermes";
import moment from "moment";
import _ from "lodash";

@Component({
  selector: "app-AddAppointmentComponent",
  templateUrl: "./addappointment.component.html",
  styleUrls: ["./addappointment.component.scss"],
})
export class AddAppointmentComponent implements OnInit {
  dropdownSettings: IDropdownSettings = {};
  countryList: CountryList[] = [];
  form: FormGroup;
  departments: any; // adding variable for departments and consultants
  consultants: Consultants[] = [];
  result: any; //check status code
  submitted = false;
  checkvalue: boolean = true;
  displayBasic: boolean;
  products: any;
  selectedPatient: any; // selected patient saved in session from 'Add Appointment' screen.
  isReminderChecked = false;
  patients: PatientList[] = [];
  private service;
  schedule: any;
  data: CountryList[];
  //userForm: FormGroup;
  posts = [];
  dtOptions: any = {};
  info: string;
  state1List: State1List[] = [];
  state2List: State1List[] = [];
  state3List: State1List[] = [];
  scheduleTime: AppointmentScheduleList[];
  keyword = "countryName";
  keyword1 = "stateName";
  consultDataId: any[];
  titleList: TitleList[] = [];
  selectedDay: string = "";
  appointmentId: any;
  allowEdit: boolean = false;
  butDisabled: boolean = false;
  // referenceList: any;
  appointmentTypesList: AppointmentTypesList[] = [];
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  @ViewChildren("checkboxes2") checkboxes2: QueryList<ElementRef>;
  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;
  enableNewCons: any;
  valueDate: string;
  DateValue: string;
  Time: string;
  NoOfSlots;
  patId: Number;
  AppointmentDataDetails: AppointmentsList[] = [];
  PatientId: any;
  appId: number;
  DepartmentId: number;
  departmentId: any;
  BranchId: number;
  Schedulelist: any;
  // scheduleTime: any;
  departmentList: any;
  consultantId: any;
  deptIds: any;
  isLoading = false;
  datadata1: any;
  datadata2: any;
  isShow = null;
  IsRegNo = false;
  scheduleDisable = false;
  deptId: any;
  sliceDataDetail: any;
  Searchform: FormGroup;
  pagetitle: any;
  pageTitle: any;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;
  Days = [1, 3, 7, 14, 30];
  checkedCommunicationDays1 = false;
  checkedCommunicationDays2 = false;
  dayData1: any;
  dayData2: any;
  typeData: any;
  $event: any
  genderList: GenderList[] = [];
  maritalStatusList: MaritalStatusList[] = [];
  appReasonList = [
    {id: 1, appReason: "Check up"},
    {id: 2, appReason: "Emergency"},
    {id: 3, appReason: "Routine"},
    {id: 4, appReason: "Follow up"},
    {id: 5, appReason: "Walk-in"}
  ];
  maxDate: Date;

  //adding appointments service
  constructor(
    private formBuilder: FormBuilder,
    //private formControl:FormControl,
    private appointmentService: AppointmentsService,
    private hospitalsService: HospitalsService,
    private toastr: ToastrService,
    private patientService: PatientService,
    private scheduleService: ScheduleService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private formValidationService: FormValidationService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private _location: Location,

  ) {
    this.service = scheduleService;
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  //AG-Grid set up
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public validator = new CommonValidationComponent();
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  getSelectedRows: 'getSelectedRows';
  public getRowStyle;
  public statusBar;
  Reminder = []
  isChecked: boolean = false;
  appointmentdata: any[] = [];

  onSelectedRows() { }

  paging: GuiPaging = {
    enabled: true,
    page: 1,
    pageSize: 10,
    pageSizes: [10, 25, 50],
    pagerTop: false,
    pagerBottom: true,
    display: GuiPagingDisplay.ADVANCED,
  };

  sorting: GuiSorting = {
    enabled: true,
  };

  searching: GuiSearching = {
    enabled: true,
    placeholder: "Search Here",
  };
  GuiColumnMenu = {
    enabled: true,
    columnsManager: true,
  };

  infoPanel: GuiInfoPanel = {
    enabled: true,
    infoDialog: false,
    columnsManager: true,
  };

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'sliceNo',
      textField: 'sliceTime',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.activatedRoute.params.subscribe(params => {
      this.appId = Number(params.id);
    });
    if (this.appId) {
      this.allowEdit = true;
    }
    else {
      this.allowEdit = false;
    }

    //this.form.controls['date'].setValue(this.schedule.date );
    this.readSchedule(); //get schedule from schedule component

    this.BuildForm(1, 1); //1=formId,1=departmentId
    this.GetTitle();
    this.GetAppointmentType();
    this.LoadCountry();
    this.GetDepartments();
    this.fetchId();
    this.maxDate = new Date();

    // setTimeout(function () {
    //  },1000);



    //this.GetAppointments();
    this.products = [
      {
        regno: "R5678900",
        name: "Admin",
        address: "28",
        age: "28",
        pin: "28",
        phoneno: "28",
        policyno: "POl788928",
        emiratedid: "EM6778828",
      },
      {
        regno: "z5678900",
        name: "user2",
        address: "28",
        age: "28",
        pin: "28",
        phoneno: "28",
        policyno: "POl788928",
        emiratedid: "EM6778828",
      },
    ];
    this.GetGender();
    this.GetMaritalStatus();
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }
  //pagetitle to be displayed at the header

  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);
  // }
  //Loading departments and consultant
  GetDepartments() {
    let showall = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showall).subscribe((res) => {
      this.departments = res.response;
    });
  }
  loadConsultant(DepartmentId: number) {



    if (this.appointmentId) {

      // let deptId=Number(DepartmentId);
      this.deptId = DepartmentId;
      let departId = this.form.get("deptId").value;
      if (departId) {

        this.deptId = Number(departId);
      }
      this.hospitalsService.GetConsultant(this.deptId).subscribe((res) => {
        this.consultants = res.response;
      });
    }
    else {

      let deptId = this.departmentList
      this.hospitalsService.GetConsultant(deptId).subscribe((res) => {
        this.consultants = res.response;
      });
    }

  }
  //formbuilder data
  BuildForm(FormId, DepartmentId) {
    this.formValidationService
      .GetFormValidation(FormId, DepartmentId)
      .subscribe((res) => {
        let response = res.response;
        this.GenerateForm(response);
        this.fetchDetails(this.schedule, this.appointmentId);

        if (this.allowEdit == false) {
          this.pathValuesForEdit();
        }
      });
  }
  //Generate form method data
  GenerateForm(formValidations) {

    this.form = this.formBuilder.group({
      appDate: [""],
      departmenId: ["0"],
      consultantId: new FormControl(''),
      deptId: new FormControl(''),
      firstName: new FormControl("", [Validators.required]),
      middleName: new FormControl(""),
      lastName: new FormControl(""),
      Gender: new FormControl(''),
      MaritalStatus: new FormControl(0),
      AppReason: new FormControl(0),
      DOB: new FormControl(new Date()),
      date: new FormControl(""),
      slicetime: new FormControl("", [Validators.required]),
      title: new FormControl(""),
      regNo: new FormControl(""),
      resPhoneNo: new FormControl(""),
      offNo: new FormControl(""),
      mobile: new FormControl("",[Validators.required]),
      remarks: new FormControl(""),
      email: new FormControl("", [Validators.email]),
      appType: [1],
      address1: new FormControl(""),
      address2: new FormControl(""),
      street: new FormControl(""),
      place: new FormControl(""),
      city: new FormControl(""),
      pin: new FormControl(""),
      select: new FormControl(0),

      CommunicationMethod1: new FormControl(0),
      CommunicationDays1: new FormControl(0),
      CommunicationMethod2: new FormControl(0),
      CommunicationDays2: new FormControl(0),


      state: new FormControl(""),
      countryName: new FormControl(""),


    });
    this.Searchform = this.formBuilder.group({
      sMobile: [""],
      sPhoneNo: [""],
      sAddress: [""],
      sName: [""],
      sRegNo: [""],
      sEmiratesNo: [""],
      sPolicyNo: [""],
      sPin: [""],
    });

    this.getApplicationSettings();

  }
  fetchId() {
    this.activatedRoute.params.subscribe((params) => {
      this.appointmentId = Number(params.id);
    });



    if (!this.appointmentId) {
      this.isShow = true;

      this.scheduleDisable = true;
    }




  }
  readSchedule() {
    // call service method to read message
    this.schedule = this.service.readSchedule();
  }
  //LoadCountry method called from AppointmentService
  LoadCountry() {
    this.appointmentService.GetCountry(this.globals.HospitalId).subscribe((res) => {
      this.countryList = res.response;
    });
  }
  daysDAta(value, type) {
      let index = this.Reminder.findIndex(x => x.CommunicationMethod === type);

      if (index != undefined) {
        this.Reminder.splice(index, 1);

        this.Reminder.push({
          CommunicationMethod: type,
          CommunicationDays:Number( value)
        });

      }
      else {
        this.Reminder.push({
          CommunicationMethod: type,
          CommunicationDays: Number( value)
        });

      }

   

console.log(this.Reminder);

  }
  message($event, typeData) {
    if ($event == true) {
      let item2 = this.Reminder.filter(x => x.CommunicationMethod == typeData)[0];

      if (item2 == undefined) {
        this.Reminder.push({
          CommunicationMethod: typeData,
          CommunicationDays: typeData == 1 ? Number(this.form.get('CommunicationDays1').value) : Number(this.form.get('CommunicationDays2').value),
        });

      }
    }
    else {
      let index = this.Reminder.findIndex(x => x.CommunicationMethod === typeData);
      this.Reminder.splice(index, 1);

    }
    console.log(this.Reminder);

  }
  // adding new appointments
  SaveAppointment() {

    this.result
    this.submitted = true;
    let rr = this.form.getRawValue();
    console.log(rr);

    // let countryIdData = this.form.get("countryName").value;
    let StateData = this.form.get("state").value;
    // let StateDataId = StateData.stateId;
    let titleId = this.form.get("title").value;
    let mob = String(this.form.get("mobile").value);
    let ResNum = this.form.get("resPhoneNo").value;
    let OffNum = this.form.get("offNo").value;
    let deptId = this.form.get("deptId").value;
    let consultantId = this.form.get("consultantId").value;

    let sliceData = this.form.get("slicetime").value;
    let sliceDataDetails = [];
    if (sliceData != "") {
      sliceData.forEach(a => {
        sliceDataDetails.push({ "sliceNo": Number(a.sliceNo), "sliceTime": a.sliceTime });
      });
    }
    let dobData;
    if (typeof (this.form.get("DOB").value) == "string") {
      dobData = this.form.get("DOB").value;
    } else {
      dobData = new Date(this.form.get("DOB").value);
      dobData = this.datepipe.transform(dobData, 'dd-MM-yyyy');
    }

    var AppData = {
      BranchId: this.BranchId,
      patientId: this.PatientId,
      regNo: this.form.get("regNo").value,
      title: Number(titleId),
      deptmntId: Number(deptId),
      consultantsId: Number(consultantId),
      firstName: this.form.get("firstName").value,
      middleName: this.form.get("middleName").value,
      lastName: this.form.get("lastName").value,
      Gender: this.form.get("Gender").value,
      MaritalStatus: this.form.get("MaritalStatus").value,
      AppReason: this.form.get("AppReason").value,
      DOB: dobData,
      resPhoneNo: (this.form.get("resPhoneNo").value == "null" || this.form.get("resPhoneNo").value == null) ? "" : String(this.form.get("resPhoneNo").value),
      email: this.form.get("email").value,
      mobile: mob == "null" ? "" : mob,
      remarks: this.form.get("remarks").value,
      appType: this.form.get("appType").value,
      Address1: (this.form.get("address1").value == "null" || this.form.get("address1").value == null) ? "" : String(this.form.get("address1").value),
      Address2: this.form.get("address2").value,
      street: this.form.get("street").value,
      place: this.form.get("place").value,
      city: this.form.get("city").value,
      pin: this.form.get("pin").value == null ? "" : String(this.form.get("pin").value),
      CountryId: this.form.get("countryName").value ? Number(this.form.get("countryName").value) : 0,
      State: this.form.get("state").value ? Number(this.form.get("state").value) : 0,
      offNo: String(OffNum),
      timeSlot: sliceDataDetails,
      AppStatus: 'W',
      ReminderData: this.Reminder,
      //timeSlot: this.form.get("timeSlot").value,
    };
    console.log(this.Reminder);

    if (sliceData == "") {
      this.toastr.error("Failure: " + "Please select time");
    }
    else if (Number(consultantId) == 0) {
      this.toastr.error("Failure: " + "Invalid Consultant");
    }
    else if (Number(deptId) == 0) {
      this.toastr.error("Failure: " + "Invalid Department");
    }
    else if (this.appointmentId > 0) {
      
      if (this.form && this.form.valid) {
        this.isLoading = true;

        this.ngxLoader.start();
        let pageTitle = sessionStorage.getItem("pageTitle");

        this.appointmentService.UpdateAppointment(AppData, this.schedule, this.appointmentId).subscribe((res) => {
          this.result = res;

          if (this.result.status == 200) {
            if (this.result.message != "error") {
              this.isLoading = false;

              this.ngxLoader.stop();
              
              this.toastr.success("Appointment updated successfully");
              if (pageTitle == "Front Office") {
                this.router.navigate(['/FrontOffice']);
              }
              else if (pageTitle == "Schedule") {
                 this.router.navigate(['/scheduledappoinment']);
                // this.router.navigate(['/scheduledappointment']).then(() => {
                //   location.reload();
                // });
              }
              else if (pageTitle == "Appointments") {
                this.router.navigate(['/checkappointment']);
              }

             

            } else {
              this.isLoading = false;

              this.ngxLoader.stop();

              this.toastr.error("Failure: " + this.result.errorMessage);
            }
          } else {
            this.isLoading = false;

            this.ngxLoader.stop();

            this.toastr.error("Failure: " + this.result.errorMessage.message);
          }
        });
      } else {
        
        let result = this.f;
        if (result.slicetime.value.length == 0) {
          this.toastr.error("Time Slot Required", "", { timeOut: 1000000 });
        }
        if (result.firstName.value == "") {
          this.toastr.error("FirstName Required", "", { timeOut: 1000000 });
        }
        if (result.mobile.value == "") {
          this.toastr.error("Phone number Required", "", { timeOut: 1000000 });
        }


      }
    } else {
      //return false;
      // stop here if form is invalid
      
      if (this.form && this.form.valid) {
        AppData.ReminderData = this.Reminder;
        console.log('AppData', AppData);
        this.appointmentService
          .SaveAppointment(AppData, this.schedule)
          .subscribe((res) => {
            this.result = res;
            this.ngxLoader.start();
            if (this.result.status == 200) {

              this.ngxLoader.stop();
              this.toastr.success("Appointment added successfully");
              // this.router.navigate(['/scheduledappointment']).then(() => {
              //   location.reload();
              // });
              this.goback()

            } else {
              this.ngxLoader.stop();
              this.toastr.error("Appointment failed");
            }
          });
      }

      else {
        
        let result = this.f;

        if (result.slicetime.value.length == 0) {
          this.toastr.error("Time Slot Required", "", { timeOut: 1000000 });
        }
        if (result.firstName.value == "") {
          this.toastr.error("FirstName Required", "", { timeOut: 1000000 });
        }
        if (result.mobile.value == "") {
          this.toastr.error("Phone number Required", "", { timeOut: 1000000 });
        }


      }
    }
  }


  showBasicDialog() {

    this.modaltemp.open();
    this.displayBasic = true;
  }

  // toast message for success
  Showtoast_success($message, $title) {
    this.toastr.success($message, $title);
  }

  // toast message for error
  Showtoast_error($message, $title) {
    this.toastr.error($message, $title);
  }

  //Listing Appointments
  SearchPatient() {

    this.patientService.SearchPatient(this.Searchform).subscribe((res) => {
      this.patients = res.response;
      this.result = res;
      {
        if (this.patients.length > 0) {
          if (this.patients.length == 1 && this.patients[0].active == "0") {
            this.toastr.warning("This Patient is blocked");
            this.patients = [];
          }
          else {
            this.patients = this.patients.filter((oppdata) => oppdata.active == "1");
            //this.ClearForm();
            if (this.patients.length == 0) {
              this.toastr.warning("No Patient Found");
            }
          }


        }
        else {
          this.toastr.warning("No Patient Found");
        }
      }
    });
  }

  //getting patient details by entering regno

  GetPatientByRegNo() {
    this.patientService.SearchPatientByRegNo(this.form.get("regNo").value).subscribe((res) => {
      this.patients = res.response;
      if(this.patients[0].active != "0" &&this.patients.length > 0){
        this.fillForm(this.patients[0]);

      }
      this.result = res;

      if (this.patients.length > 0) {

        if (this.patients[0].active == "0") {

          this.toastr.warning("This Patient is blocked");


          // this.ClearForm();

          this.form.patchValue({ regNo: this.patients[0].regNo });
      
          this.patients = [];
        }

      } else {

        this.toastr.warning("No patients found");
      }

    });
  }

  //Getting Appointments Details
  fetchDetails(schedule: any, Id: any) {
    if (Id) {
      this.appointmentService.GetAppoinmentDetailsbyId(Id).subscribe((res) => {

        //this.appId = Id;
        this.patId = Id;
        if (Id > 0) {
          this.scheduleDisable = true;
          this.isShow = true;
        }
        this.AppointmentDataDetails = res.response;
        let appointmentdeptId = this.AppointmentDataDetails["deptId"];
        let departmentId = Number(appointmentdeptId);
        this.loadConsultant(departmentId);
        this.consultDataId = [this.AppointmentDataDetails["consultantId"]];
        this.departmentList = [this.AppointmentDataDetails["deptId"]];
        this.BranchId = this.AppointmentDataDetails["branchId"];
        if(this.AppointmentDataDetails["dob"] !== "" && this.AppointmentDataDetails["dob"] !== null){
          this.form.controls.DOB.reset();
        }
        if (this.AppointmentDataDetails["patientRegNo"] == "") {
          this.IsRegNo = false;
        }
        else {
          this.IsRegNo = true;
          this.form.patchValue({
            regNo: this.AppointmentDataDetails["patientRegNo"],
          });
        }
        this.form.patchValue({
          date: this.AppointmentDataDetails["appDate"],
        });
        this.form.patchValue({
          email: this.AppointmentDataDetails["email"],
        });

        this.form.patchValue({
          firstName: this.AppointmentDataDetails["firstName"],
        });
        this.form.patchValue({
          middleName: this.AppointmentDataDetails["middleName"],
        });
        this.form.patchValue({
          lastName: this.AppointmentDataDetails["lastName"],
        });
        this.form.patchValue({
          AppReason: this.AppointmentDataDetails["appReason"],
        });
        this.form.patchValue({
          Gender: this.AppointmentDataDetails["gender"],
        });
        this.form.patchValue({
          MaritalStatus: this.AppointmentDataDetails["maritalStatus"],
        });
        this.form.patchValue({
          DOB: this.datepipe.transform(this.AppointmentDataDetails["dob"], "dd-MM-yyyy"),
        });
        this.form.patchValue({
          mobile: this.AppointmentDataDetails["mobile"],
        });
        this.form.patchValue({ pin: this.AppointmentDataDetails["pin"] });
        this.form.patchValue({
          place: this.AppointmentDataDetails["placePO"],
        });
        this.form.patchValue({
          street: this.AppointmentDataDetails["street"],
        });
        this.form.patchValue({ city: this.AppointmentDataDetails["city"] });
        this.form.patchValue({ deptName: this.AppointmentDataDetails["departmentName"] });
        this.form.patchValue({ consultantName: this.AppointmentDataDetails["consultantName"] });
        this.form.patchValue({ consultantId: this.AppointmentDataDetails["consultantId"] });
        this.form.patchValue({ slicetime: this.AppointmentDataDetails["sliceData"] });
        this.form.patchValue({ CommunicationDays1: this.AppointmentDataDetails["communicationDays1"] });
        this.form.patchValue({ CommunicationMethod1: this.AppointmentDataDetails["communicationMethod1"] });
        this.form.patchValue({ CommunicationDays2: this.AppointmentDataDetails["communicationDays2"] });
        this.form.patchValue({ CommunicationMethod2: this.AppointmentDataDetails["communicationMethod2"] });
        let rr = this.form.getRawValue();
        if (rr.CommunicationMethod1 == 1) {
          this.checkedCommunicationDays1 = true;
        }
        else {
          this.checkedCommunicationDays1 = false;

        }
        if (rr.CommunicationMethod2 == 1) {
          this.checkedCommunicationDays2 = true;
        }
        else {
          this.checkedCommunicationDays2 = false;

        }
        if (rr.CommunicationMethod1 == 1) {
          this.Reminder.push({
            CommunicationMethod: rr.CommunicationMethod1,
            CommunicationDays: rr.CommunicationDays1,
          });

        }
        if (rr.CommunicationMethod2 == 1) {
          this.Reminder.push({
            CommunicationMethod: rr.CommunicationMethod2,
            CommunicationDays: rr.CommunicationDays2,
          });

        }

        this.form.patchValue({
          title: this.AppointmentDataDetails["title"],
        });
        this.form.patchValue({
          resPhoneNo: this.AppointmentDataDetails["resPhone"],
        });
        this.form.patchValue({
          offNo: this.AppointmentDataDetails["offPhone"],
        });
        this.form.patchValue({
          remarks: this.AppointmentDataDetails["remarks"],
        });
        this.form.patchValue({
          address2: this.AppointmentDataDetails["address2"],
        });
        this.form.patchValue({
          address1: this.AppointmentDataDetails["address1"],
        });
        this.form.patchValue({
          countryName: this.AppointmentDataDetails["countryId"] ? this.AppointmentDataDetails["countryId"] : this.AppointmentDataDetails["countryName"],
        });

        this.form.patchValue({ deptId: this.AppointmentDataDetails["deptId"] });
        this.form.patchValue({
          state: this.AppointmentDataDetails["state"] ? this.AppointmentDataDetails["state"] : this.AppointmentDataDetails["stateName"],
        });
        this.form.patchValue({
          title: this.AppointmentDataDetails["title"],
        });

        let datestring = this.datepipe.transform(new Date(), "dd-MM-yyyy");

        this.schedule = {
          //time: this.AppointmentDataDetails[0]["sliceTime"],
          consultantName: this.AppointmentDataDetails["consultantName"],
          slicetime: this.AppointmentDataDetails["sliceData"],
          deptName: this.AppointmentDataDetails["departmentName"],
          consultantId: this.AppointmentDataDetails["consultantId"],
          deptId: this.AppointmentDataDetails["deptId"],
          date: this.AppointmentDataDetails["appDate"],
        };

        if(this.AppointmentDataDetails["countryId"]){
          this.GetState(this.AppointmentDataDetails["countryId"], 1)
        }
      });
    }
    else {

      this.consultDataId = [this.schedule.consultantId];
      this.departmentList = this.schedule.departmentId;
      this.BranchId = this.schedule.BranchId;

      this.deptIds = this.schedule.departmentId;
      this.loadConsultant(this.deptIds);
      this.GetScheduledData();

    }
  }
  pathValuesForEdit() {

    this.form.patchValue({ date: this.schedule.date });
    this.form.patchValue({ consultantName: this.schedule.consultantName });
    this.form.patchValue({ sliceNo: this.schedule.sliceNo });
    this.form.patchValue({ deptName: this.schedule.deptName });
    this.form.patchValue({ deptId: this.schedule.departmentId });
    this.form.patchValue({ consultantId: this.schedule.consultantId });
    this.GetScheduledData();
  }
  fillForm(patient: any) {
    this.form.patchValue({
      regNo: patient["regNo"],
      patientName: patient["patient"],
      patientId: patient["patientId"],
      firstName: patient['firstName'],
      middleName: patient["middleName"],
      lastName: patient["lastName"],
      Gender: patient["gender"],
      MaritalStatus: patient["maritalStatus"],
      DOB: this.datepipe.transform(patient["dob"], 'dd-MM-yyyy'),
      // DOB: patient["dob"],
      mobile: patient["mobile"],
      resPhoneNo: patient["telephone"],
      offNo: patient["officeNo"],
      email: patient["email"],
      address1: patient["address1"],
      address2: patient["address2"],
      street: patient["street"],
      place: patient["place"],
      city: patient["city"],
      pin: patient["pin"],
      countryName:patient["countryId"],
      state:patient["state"]
    });
    let statevalue=patient.countryId
    this.GetState(statevalue,1)
    this.form.patchValue({
      state:patient["state"]
    });

    this.PatientId = patient["patientId"];
    //this.form.patchValue({ sEmiratesNo: patient["emirateID"] });

    //this.form.patchValue({ telephone: patient.telephone });

    this.displayBasic = false;
  }
  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();
    document.querySelector('#selectedRows').innerHTML =
      selectedRows.length === 1 ? selectedRows[0].athlete : '';
  }
  fillForm1(patient: any) {
    this.form.patchValue({ regNo: patient.data.regNo });
    this.form.patchValue({ patientName: patient.data.patient });
    this.form.patchValue({ patientId: patient.data.patientId });
    this.form.patchValue({ firstName: patient.data.firstName });
    this.form.patchValue({ middleName: patient.data.middleName });
    this.form.patchValue({ lastName: patient.data.lastName });
    this.form.patchValue({ email: patient.data.email });
    this.form.patchValue({ mobile: patient.data.mobile });
    this.form.patchValue({ telephone: patient.data.telephone });
    this.form.patchValue({ countryName: patient.data.countryId });
    this.form.patchValue({ state: patient.data.state });

    this.PatientId = patient.data.patientId;

    //this.form.patchValue({ sEmiratesNo: patient[0]["source"]["emirateID"] });

    //this.form.patchValue({ telephone: patient.telephone });

    this.displayBasic = false;
  }
  // checking if 'selectedPatient' session is set. The schedule link will activates only if a patient is selected from add appointment screen
  // GotoSchedule(e){
  //   this.selectedPatient=  sessionStorage.getItem("selectedPatient");
  //   if(!this.selectedPatient){
  //     this.Showtoast_error('Please select a patient', 'Error!');
  //     this.router.navigate(['/scheduledappoinment']);
  //   }else{
  //     this.router.navigate(['/scheduledappoinment']);
  //   }

  // }

  //Get state from current country selected inputs
  GetState(countryId: any, state: any) {
    let ShowAll = 0;
    this.ConfigurationService
      .GetState(this.globals.HospitalId, (Number(countryId)), ShowAll).subscribe((res) => {
        if (state == "1") {
          this.state1List = res.response;
        }
      });
  }
  selectEvent(item: any, state: any) {
    this.GetState(item.countryId, state);
  }

  ///Get Appointment type list for patient
  GetAppointmentType() {
    this.appointmentService.GetAppointmentTypes().subscribe((res) => {
      this.appointmentTypesList = res.response;
    });
  }

  SearchPatientDetail(patient: any) {
    let _this = this;
    this.GetPatientByRegNo();
    // console.log(this.patients);
    
    // _this.fillForm(this.patients);
    // if (patientDetail.length > 0) {
    //   
    //   // this.fillForm(patientDetail[0]);
    // }

    setTimeout(function () {
      let patientDetail = _this.patients.filter(
        (patientData) => patientData.regNo == patient
      );
    }, 1000);
  }

  //check and uncheck new consultations
  selectchecking() {
    this.checkvalue = !this.checkvalue;
  }
  // setting new consultation
  EnableOrDisableNewCons(isNewCons: any) {
    this.enableNewCons = isNewCons;
    this.checkboxes2.forEach((element) => {
      if (element.nativeElement.checked) element.nativeElement.checked = false;
    });
  }

  //Reseting Form
  ClearForm() {
    this.checkboxes.forEach((element) => {
      if (element.nativeElement.checked) element.nativeElement.checked = false;
    });
    this.checkboxes2.forEach((element) => {
      if (element.nativeElement.checked) element.nativeElement.checked = false;
    });
    if (!this.checkvalue) {
      this.selectchecking();
    }
    this.form.reset({
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      Gender: '',
      MaritalStatus: '',
      AppReason: "",
      mobile: "",
      place: "",
      pin: "",
      street: "",
      city: "",
      resPhoneNo: "",
      remarks: "",
      address2: "",
      address1: "",
      countryName: "",
      state: "",
      CommunicationMethod1: 0,
      CommunicationMethod2: 0,
      CommunicationDays1: 0,
      CommunicationDays2: 0
    });
    this.form.clearValidators();
    this.SearchPatient();
  }



  //Getschedule timeslot
  GetTimeSlotData(_NoOfSlots) {
    let requiredslots = Number(_NoOfSlots);
    let TimeSliceFirst = Number(this.schedule.sliceNo);
    this.appointmentService
      .GetScheduledDataAvailbilty(
        this.schedule.consultantId,
        this.schedule.date,
        TimeSliceFirst,
        requiredslots
      )
      .subscribe((res) => {
        this.result = res;
        if (this.result.message == "Available") {
          this.toastr.success("Slots Available");
        } else if (this.result.message == "Not Available") {
          this.toastr.error("Slots Not Available");
        }
      });
  }

  //Getting Salutation
  GetTitle() {
    this.patientService.GetTitle(this.globals.HospitalId).subscribe((res) => {
      this.titleList = res.response;
    });
  }

  //Getschedule method called from AppointmentService
  GetScheduledData() {

    if (this.appId) {
      this.deptId = this.departmentList;
    }
    else {
      this.deptId = [this.departmentList]
    }

    this.valueDate = this.schedule.date;//this.AppointmentDataDetails["appDate"];//this.datepipe.transform(new Date(), "dd-MM-yyyy");

    //this.appointmentService.GetAppointmentSchedule(this.consultDataId, this.schedule.date, this.deptId, this.BranchId).subscribe((res) => {//NIKHIL Check
    this.appointmentService.GetAppointmentSchedule(this.consultDataId, this.valueDate, this.deptId, this.BranchId).subscribe((res) => {

      // this.scheduleTime = res.response['doctorData'][0]['labels'];
      this.scheduleTime = res.response[0]['labels'];
      this.Schedulelist = this.scheduleTime;

      if (!this.appId) {
        this.form.patchValue({ slicetime: this.schedule['SliceData'] });
      }
    })
  }

  isremind($event) {
    if (this.isReminderChecked == false) {
      this.isReminderChecked = true;
    }
    else {
      this.isReminderChecked = false;
    }

  }

  //coomon search event modal filling of user details in form

  searchevent(patient: any) {
    if(patient.data.dob !== "") {
      console.log('if');
      this.form.controls.DOB.reset();
    }
    this.form.patchValue({
      title: patient.data.salutationId,
      regNo: patient.data.regNo,
      patientName: patient.data.patient,
      patientId: patient.data.patientId,
      firstName: patient.data.firstName,
      middleName: patient.data.middleName,
      lastName: patient.data.lastName,
      Gender: patient.data.gender,
      MaritalStatus: patient.data.maritalStatus,
      DOB: this.datepipe.transform(patient.data.dob, 'dd-MM-yyyy'),
      // DOB: patient.data.dob,
      mobile: patient.data.mobile,
      resPhoneNo: patient.data.telephone,
      offNo: patient.data.officeNo,
      email: patient.data.email,
      address1: patient.data.address1,
      address2: patient.data.address2,
      street: patient.data.street,
      place: patient.data.place,
      city: patient.data.city,
      pin: patient.data.pin,
      state: patient.data.state,
      countryName: patient.data.countryId
    })
    this.form.patchValue({ regNo: patient.data.regNo });
    this.form.patchValue({ patientName: patient.data.patient });
    this.form.patchValue({ patientId: patient.data.patientId });
    this.form.patchValue({ firstName: patient.data.firstName });
    this.form.patchValue({ middleName: patient.data.middleName });
    this.form.patchValue({ lastName: patient.data.lastName })
    this.form.patchValue({ mobile: patient.data.mobile });
    this.form.patchValue({ resPhoneNo: patient.data.telephone });
    this.form.patchValue({ email: patient.data.email });
    this.form.patchValue({ address1: patient.data.address1 });
    this.form.patchValue({ address2: patient.data.address2 });
    this.PatientId = patient.data.patientId;
    this.displayBasic = false;

    if(patient.data.countryId > 0){
      this.GetState(patient.data.countryId, 1)
    }

  }


  showBasicDialogSearch($event) {
    this.displayBasic = $event;
  }
  goback() {
    this._location.back();

// setTimeout(() => {
//       location.reload();
//     }, 100); 
  }

  GetGender() {
    this.patientService.GetGender().subscribe((res) => {
      this.genderList = res.response;

    })
  }

  GetMaritalStatus() {
    this.patientService.GetMaritalStatus(this.globals.HospitalId).subscribe((res) => {
      this.maritalStatusList = res.response;

    })
  }

  getApplicationSettings() {
    let payload= {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }
    this.patientService.getApplicationSettings(payload).subscribe((res) => {
      let defaultValueList = _.filter(res.response, {tabId: 3});
      // this.defaultValueList = res.response;
      defaultValueList.find(x => {
        if(x.displayName == 'DefaultCountry') {
          this.form.get('countryName').setValue(x.settValue);
          if(x.settValue != 0){
            this.GetState(x.settValue, 1);
          }
        }
        if(x.displayName == 'DefaultState') {
          this.form.get('state').setValue(x.settValue);
        }
        if(x.displayName == 'DefaultTitle'){
          this.form.get('title').setValue(x.settValue);
        }
        if(x.displayName == 'DefaultMartialStatus'){
          this.form.get('MaritalStatus').setValue(x.settValue);
        }
        if(x.displayName == 'DefaultGender'){
          this.form.get('Gender').setValue(x.settValue);
        }
        
      });

    })
  }

  setValidationForEmail(){
    if(this.form?.controls['CommunicationMethod1'].value){
      this.form.get('email')?.setValidators([Validators.email,Validators.required]);
    }else{
      // this.form.controls['email'].clearValidators()
      this.form.get('email')?.setValidators([Validators.email]);
    }
    this.form.controls['email'].updateValueAndValidity()
  }

  changeTitle(){
    let gender = this.form.value.Gender
    if(gender == 1){
      this.form.patchValue({title:5})
    }else if(gender == 2){
      this.form.patchValue({title:7})
    } 
  }

  changeGender(){
    let title = this.form.value.title
    if(title == 3 || title == 5 ){
      this.form.patchValue({Gender:1})
    }else if(title == 7 || title == 6 ){
      this.form.patchValue({Gender:2})
    }   
  }
}
