import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { EMRService } from '../../services/emr.service';
import { Response } from '../../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ConfirmModalComponent } from '../../../../shared/modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-copy-emr-modal',
  templateUrl: './copy-emr-modal.component.html',
  styleUrls: ['./copy-emr-modal.component.scss']
})
export class CopyEmrModalComponent implements OnInit {

  @Output() copyEmitter = new EventEmitter();

  @Input() EmrPatientInfo: EMRPatientInfo;
  @Input() branchId: any;

  public copyForm: FormGroup;
  public visitHistoryList: any[] = [];

  public copyLoading: boolean = false;

  constructor(private activeModal: BsModalRef,
              private emrService: EMRService,
              private formBuilder: FormBuilder,
              private modalService: BsModalService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllVisitsofPatient();
  }

  initForm(){
    this.copyForm = this.formBuilder.group({
      ConsultationId: new FormControl(this.EmrPatientInfo.ConsultationId, Validators.required),
      BranchId: new FormControl(this.branchId, Validators.required),
      VisitId: new FormControl(this.EmrPatientInfo.VisitId, Validators.required),
      PatientId: new FormControl(this.EmrPatientInfo.PatientId, Validators.required),
      OldConsultationId: new FormControl('', Validators.required),
      OldVisitId: new FormControl('', Validators.required),
      UserId: new FormControl(Number(localStorage.getItem('user_id')), Validators.required)
    })
  }

  getAllVisitsofPatient(){
    // this.EmrPatientInfo.ConsultantId
    this.emrService.GetVisits(this.EmrPatientInfo.PatientId,this.EmrPatientInfo.ConsultantId).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.visitHistoryList = response.response;
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
        this.close();
      }
    });
  }

  onSelectVisit(event){
    const selectedVisit = _.filter(this.visitHistoryList, (item)=>{
      return item.visitId == event.target.value
    });
    localStorage.setItem("emrPhyConsultationID", JSON.stringify(selectedVisit[0].consultationId));

    this.copyForm.patchValue({
      OldConsultationId: selectedVisit[0].consultationId,
      OldVisitId: selectedVisit[0].visitId
    })
  }

  onNextClicked(){
    let initialState = {
      heading: 'Confirm Copy EMR.!',
      content: `<p>Are you sure that you want to copy this EMR data of selected visit.?</p>`,
      isReasonNeeded: false,
      yesButton: 'Confirm',
      noButton: 'Cancel'
    }
    let modalConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const confirmModalRef= this.modalService.show(ConfirmModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      modalConfig, 
      { class: 'modal-sm', initialState }) );

    confirmModalRef.content.confirmEmitter.subscribe(res=>{
      if(res.status){
        this.copyLoading = true;
        this.emrService.copyEMRData(this.copyForm.value).subscribe({
          next: (response: Response<any>)=>{
            if(response.status == 200 && response.message == 'Success'){
              this.toastrService.success('EMR details copied successfully.');
              this.copyEmitter.emit({status: true});
              this.close();
            }else{
              this.toastrService.error(response.errorMessage.message);
            }
            this.copyLoading = false;
          },error: (error: any)=>{
            this.toastrService.error('Something wents wrong.! Try again.');
            this.copyLoading = false;
          }
        })
      }
    })
  }

  close(){
    this.activeModal.hide();
  }

}
