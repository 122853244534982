import { Component, OnInit, TemplateRef } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Router } from "@angular/router";

@Component({
  selector: "app-treatment-general-action",
  templateUrl: "./treatment-general-action.component.html",
  styleUrls: ["./treatment-general-action.component.scss"],
})
export class TreatmentGeneralActionComponent
  implements ICellRendererAngularComp
{
  public params: any;
  public pagetype: string;
  items: any[] = [];
  selectedValues: boolean;
  appId: any;
  checkvalue: any[] = [];
  orderDetail: any[] = [];
  //data=this.params.data
  selectedMenu = 0;

  constructor(private router: Router) {
    this.selectedMenu = Number(localStorage.getItem('selectedMenu'));
  }

  agInit(params: any): void {
    this.params = params;
    this.pagetype = this.params.PageType;

    if (this.pagetype == "treatmentFrontOffice") {
      this.items = [
        {
          label: "New Treatment",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.GoToTreatment(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },

        {
          label: "Apply Physiotherapy",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.GoToApplyPhysiotherapy(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },

        { separator: true },

        {
          label: "Reason For Visit",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.ReasonForVisit(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },
        {
          label: "Print Medication",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.PrintMedication(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },
        {
          label: "Add Vital Signs",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.AddVitalSign(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },
        {
          label: "Patient History",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.PatientHistory(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },
        {
          label: "EMR",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.GotoEMR(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },

        { separator: true },
      ];
    }
    if (this.pagetype == "treatmentDetailsList") {
      this.items = [
        {
          label: "Edit Treatment",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.gotoedit(data);
          },
          disabled: this.params.data.id == 0 ? true : false,
          // hide: JSON.parse(sessionStorage.getItem('pageTitle')) == 'Treatment' ? true : false
        },
        { separator: true },
        {
          label: "Print Treatment Details",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.printTreatment(data);
          },
        },
        { separator: true },
        {
          label: "Report Work List",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.reportWorkLIst(data);
          },
        },
        { separator: true },
        {
          label: "Generate Treatment Number",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.generateTreatmentNumber(data);
          },
          disabled: ((this.params.data.id !== 0) || (this.selectedMenu == 69)) ? true : false,
        },
        { separator: true },
        {
          label: "Apply Treatment",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.applyTreatment(data);
          },
          disabled: ((this.params.data.id == 0) || (this.selectedMenu == 69)) ? true : false,
          // onHide: sessionStorage.getItem('pageTitle') == 'Treatment' ? true : false
        },
      ];
    }

    if (this.pagetype == "physiotherapyDetailsList") {
      this.items = [
        {
          label: "Add / Edit Session details",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.AddSessionDetails(data);
          },
          disabled: this.params.data.status == "C" ? true : false,
        },
        { separator: true },

        // {
        //   label: "Edit Session details",
        //   command: (x: any) => {
        //     let data = this.params.data;
        //     this.params.context.componentParent.EditSessionDetails(data);
        //   },
        //   disabled: this.params.data.status == "C" ? true : false,
        // },

        // { separator: true },
      ];
    }

    if (this.pagetype == "vaccinationDetailsList") {
      this.items = [
        {
          label: "Apply Vaccine",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onApplyVaccine(data);
          },
          disabled: this.params.data.status == 1 ? true : false,
        },
        {
          label: "Health Summary",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.openHealthChart(data, 242);
          },
        },
        {
          label: "Edit Vaccine",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onEditVaccine(data);
          },
          disabled: this.params.data.status == 0 ? true : false,
        },
        // {
        //   label: "Vaccine Chart",
        //   command: (x: any) => {
        //     let data = this.params.data;
        //     this.params.context.componentParent.vaccinationChart(data);
        //   },
        // },
      ];
    }

    if (this.pagetype == "vaccineChartList") {
      this.items = [
        {
          label: "Health Summary",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.openHealthChart(data, 593);
          },
        },
        {
          label: "Vaccine Chart",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.vaccinationChart(data);
          },
        },
      ];
    }

    if (this.pagetype == "vaccineChartSecondList") {
      this.items = [
        {
          label: "Apply Treatment",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.applyTreatmentFromChart(data);
          },
        },
        {
          label: "Apply Vaccine",
          command: (x: any) => {
            let data = this.params.data;
            this.params.context.componentParent.onApplyVaccine(data);
          },
        },
      ];
    }
  }

  refresh(): boolean {
    return false;
  }
}
