import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProfessionList } from '../../client/interfaces/ProfessionList';
import { ProfessionService } from '../../client/services/profession.service';
import { ColDef } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfigurationService } from '../../client/services/configuration.service';

@Component({
  selector: 'app-profession',
  templateUrl: './profession.component.html',
  styleUrls: ['./profession.component.scss']
})
export class ProfessionComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;

  public submitted: boolean = false;
  professionForm: FormGroup;
  result: any;
  professionId: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  deleteprofession: boolean;
  oppid: any;
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  isLoading=false;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.active == '0'; },
      'c-reds2': function (params) { return params.data.appStatus == 'Cancelled'; },
    },

  }
  rowData: any;
  public statusBar;
  rowGroupPanelShow = "always";
  Professions: ProfessionList[] = [];
  ProfId: any;
  IsDisplayed: any;
  DisplayedId: any;
  modalRef: BsModalRef;
  ProfessionPopupHeading:string="Add Profession";//change the name
  @ViewChild("template") modaltemp: any;
  functionPermissionData: any;

  constructor(
    private ProfessionService: ProfessionService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });


  }

  ngOnInit(): void {
    this.professionForm = new FormGroup({
      profName: new FormControl('', [Validators.required]),
      profCode: new FormControl('', [Validators.required]),
      IsDisplayedGroup: new FormControl(true)

    });
    this.GetAllProfession();

    this.columnDefs = [

      {
        headerName: "Profession Name",
        minWidth:180,
        field: "profName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,

      },
      {
        headerName: "Profession Code",
        minWidth:180,
        field: "profCode",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,

      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        pinned:"right",
        sortable: false,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "professionList" },
      },

    ]


    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };





    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };



    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.functionPermission();

  }


  // for geting all form items

  get f() {
    return this.professionForm.controls;
  }



  openModalProfession(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 95)
    if (foundElement.id == 95 && foundElement.isAllowed == true) {
    this. ProfessionPopupHeading="Add Profession";
    this.modalRef = this.modalService.show(template);
    this.ProfId = 0;
    this. ResetFunction();
  } else {
    this.toastr.warning("You are not allowed to add data");
  }
  }
  showModalDialog(orderId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 96)
    if (foundElement.id == 96 && foundElement.isAllowed == true) {
    this. ProfessionPopupHeading="Edit Profession";
    this.modalRef = this.modalService.show(
      this.modaltemp,
      Object.assign({}, {})
    );
    this.displayModal = true;
    this.ProfId = orderId.profId;
    this.DisplayedId = orderId.isDisplayed;
    this.professionForm.patchValue({
      profName: orderId.profName,
      profCode: orderId.profCode,
      IsDisplayedGroup: orderId.isDisplayed
    });
  } else {
    this.toastr.warning("You are not allowed to edit data");
  }

  }
  showModalDialogedit(id: any) {
    this.professionId = id;

    this.displayModal = true;
  }
  // to clear form
  clearForm() {
    this.professionForm.reset({
      profName: '',
      profCode: '',
      IsDisplayedGroup: true
    });
  }

  //pagetitle to be displayed at the header
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);

  // }


  SaveProfession() {
    this.submitted = true;
    if (this.professionForm && this.professionForm.valid) {
      var AppData = {
        profName: this.professionForm.get("profName").value,
        profCode: this.professionForm.get("profCode").value,
        IsDisplayedGroup: this.professionForm.get("IsDisplayedGroup").value,

      }
      if (AppData.IsDisplayedGroup == true) {
        AppData.IsDisplayedGroup = 1;
      }
      else {
        AppData.IsDisplayedGroup = 0;
      }

      let Profdata = 0;
      if (this.ProfId > 0) {
        Profdata = this.ProfId;
      }    
      this.isLoading = true;

      this.ProfessionService.AddProfession(AppData, Profdata, this.globals.HospitalId).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == 'Success') {
          if (Profdata == 0) {
            this.isLoading = false;
            this.toastr.success("Profession added successfully");
          }
          else {
            this.isLoading = false;
            this.toastr.success("Profession Updated successfully");
            this.ProfId = 0;
          }
          this.modalRef.hide();
          this.GetAllProfession();
          this.clearForm();
        }
        else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      })
    }
  }




  GetAllProfession() {

    this.ProfessionService.GetAllProfession(this.globals.HospitalId).subscribe((res) => {
      this.Professions = res.response;
      if (this.Professions.length == 0) {
        this.toastr.warning("No Profession found");
      }
    });
  }

  DeleteProfession(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 97)
    if (foundElement.id == 97 && foundElement.isAllowed == true) {
    this.professionId = id.profId;
    this.ProfessionService.DeleteProfession(this.professionId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {

        this.toastr.success("Profession deleted successfully");
        this.displayModal = false;
        this.GetAllProfession();
        this.clearForm();
        this.ProfId = 0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
        this.displayModal = false;
      }

    },
    );
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }

  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }


  ResetFunction() {
    this.ProfId = 0;
    this.submitted = false;
    this.clearForm();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }
}
