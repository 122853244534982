import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-covid',
  templateUrl: './covid.component.html',
  styleUrls: ['./covid.component.scss']
})
export class CovidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  dashboardMenu() {
    document.getElementById('navbarNavDropdown').classList.toggle("show");
  }
}
