import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '../../../_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class OphthalmologyService {
  
  private baseUrl: string = '';

  constructor(private configurationManager: ConfigurationManager,
              private httpClient: HttpClient,) {
                this.baseUrl = (this.configurationManager as any).baseUrl;
               }

  getUnaidedDropdown(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetUnaided`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getOcularMotulityDropdown(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetOcularMotility`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getStrabismusDropdown(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetStrabismus`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getVisualFieldDropdown(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetVisualField`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getIOPDropdown(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetIOP`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getGetPowerWithGlassDropdown(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetPowerwithGlass`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getGetPowerWithRefractionDropdown(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetPowerwithRefraction`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getNearVisionWithNearAidDropdown(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetNearVisionwithNearAid`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getGetGetPreauricularLymphnodesDropdown(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetPreauricularLymphnodes`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getLensSpecification(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetPatEyeTest`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  saveLensSpecification(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/InsertUpdatePatEyeTest`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getVisionAcuity(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetPatientVisualActivity`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  saveVisionAcuity(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/InsertUpdateVisualActivity`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getSitelampExamination(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetPatientSiteLampExamination`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  saveSitelampExamination(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/InsertUpdateSiteLampExamination`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  getAdnexaDetails(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/GetPatientAdnexa`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }

  saveAdnexaDetails(payload: any): Observable<Response<any>>{
    const endpoint = `${this.baseUrl}api/EMR/InsertUpdateSAdnexa`;
    return this.httpClient.post<Response<any>>(endpoint, payload);
  }
  
}
