import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from '../../services/patient.service';
import { SearchPatientModalComponent } from '../searchpatient-modal/searchpatient-modal.component';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { GeneralActionComponent } from '../../general-action/general-action.component';
import { HospitalsService } from '../../services/hospitals.service';
import { SharedService } from '../../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-search-prescription',
  templateUrl: './search-prescription.component.html',
  styleUrls: ['./search-prescription.component.scss']
})
export class SearchPrescriptionComponent implements OnInit {

  @ViewChild(SearchPatientModalComponent)modaltemp: SearchPatientModalComponent;
  @ViewChild(SearchPatientModalComponent)modalTwoTemp: SearchPatientModalComponent;
  public prescriptionSearchForm: FormGroup;
  public patientSearchForm: FormGroup;
  displayBasic = false;
  displayTwoBasic = false;
  consultationList: ColDef[] = [];
  prescriptionList: ColDef[] = [];
  private globals: globalvars = new globalvars();
  consultations: any;
  public frameworkComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public context;
  public gridApi: GridApi;
  rowGroupPanelShow = "always";
  public statusBar;
  public defaultColDef: ColDef;
  public presGridApi: GridApi;
  presDetailData = [];
  departmentList: any;
  consultantList: any;
  subscription: Subscription;
  masterId: number = 0;
  functionPermissionData: any;

  public gridOptions = {
    pagination: true,
    paginationPageSize: 8,
    // rowClassRules: {
    //   "row-cancelled": function (params) { return params.data.isTransfered == 1; },
    // },
    domLayout: 'autoHeight'
  }

  public presGridOptions : GridOptions = {
    pagination: true,
    paginationPageSize: 8,
    domLayout: 'autoHeight',
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
  }

  constructor(
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private toastrService: ToastrService,
    private patientService: PatientService,
    private hospitalsService: HospitalsService,
    private sharedservice: SharedService,
    private ngxLoader: NgxUiLoaderService,
    private ConfigurationService: ConfigurationService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });
   }

  ngOnInit(): void {
    this.initForm();
    this.gridConfig();
    this.GetDepartments();
    this.search();
    this.functionPermission();
  }

  initForm(){
    this.prescriptionSearchForm = this.formBuilder.group({
      // patientId: new FormControl(''),
      RegNo: new FormControl(''),
      PatientName: new FormControl({value: '', disabled: true}),
      DeptId: new FormControl('0'),
      ConsultantId: new FormControl('0'),
      FromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ToDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
    })

    this.patientSearchForm = this.formBuilder.group({
      sMobile: new FormControl(''),
      sPhoneNo: new FormControl(''),
      sPin: new FormControl(''),
      sPolicyNo: new FormControl(''),
      sRegNo: new FormControl(''),
      sName: new FormControl(''),
      sAddress: new FormControl(''),
      sResNo: new FormControl(''),
      sEmiratesNo: new FormControl(''),
      BranchId: new FormControl(this.globals.HospitalId)
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.search();
  }

  onPresGridReady(params) {
    this.presGridApi = params.api;
    this.presGridApi.hideOverlay();
  }

  onFirstDataRendered(params: any) {
    // params.api.forEachNode((node) =>
    //   node.setSelected(!!node.data && node.data.paramSelect)
    // );
  }

  gridConfig(){
    this.context = { componentParent: this };

    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `<span">no rows to show</span>`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    this.consultationList = [
      {
        headerName: "Reg.No",
        minWidth: 90,
        field: "patRegNo",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Consult Date",
        minWidth: 115,
        field: "consultDate",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Patient Name",
        minWidth: 115,
        field: "patientName",
        filter: true,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Consultant Name",
        minWidth: 120,
        field: "consultant",
        enableRowGroup: true,
        filter: true,
        resizable: false,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Sponsor",
        minWidth: 120,
        field: "consultationSponsor",
        enableRowGroup: true,
        filter: true,
        resizable: false,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Rule",
        minWidth: 110,
        field: "ruleDesc",
        enableRowGroup: true,
        filter: true,
        resizable: false,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Cons.Status",
        minWidth: 120,
        field: "consultationStatus",
        enableRowGroup: true,
        filter: true,
        resizable: false,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Disp.Status",
        minWidth: 110,
        enableRowGroup: true,
        filter: true,
        resizable: false,
        sortable: true,
        flex: 3,
        cellRenderer:(params)=>{
          if(params.data.isDispensed == true){
            return 'Dispensed'
          }else if(params.data.isDispensed == false){
            return 'Not Dispensed'
          }else{
            return ''
          }
        }
      },
      {
        headerName: "Disp By",
        minWidth: 100,
        field: "dispensedUserName",
        enableRowGroup: true,
        filter: true,
        resizable: false,
        sortable: true,
        flex: 3,
      },
    ]

    this.prescriptionList = [
      {
        headerName: "Drug Code",
        minWidth: 100,
        field: "drugCode",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Drug Name",
        minWidth: 120,
        field: "drugName",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Qty",
        minWidth: 60,
        field: "qty",
        filter: true,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Disp Qty",
        minWidth: 90,
        field: "dispQty",
        enableRowGroup: true,
        filter: true,
        resizable: false,
        sortable: true,
        flex: 3,
        editable: true
      },
      {
        headerName: "Dispensed",
        minWidth: 100,
        field: "isDispensed",
        enableRowGroup: true,
        filter: true,
        resizable: false,
        sortable: true,
        flex: 3,
        checkboxSelection: true,
        cellRenderer: params => {
          if(params.data.isDispensed == true) {
            params.node.setSelected(true)
          }
          return null
        },
      },
      {
        headerName: "ERX Number",
        minWidth: 110,
        field: "erxNumber",
        enableRowGroup: true,
        filter: true,
        resizable: false,
        sortable: true,
        flex: 3,
        editable: true
      },
      {
        headerName: "Comments",
        minWidth: 110,
        field: "comments",
        enableRowGroup: true,
        filter: true,
        resizable: false,
        sortable: true,
        flex: 3,
        editable: true
      },
    ]

  }

  GetDepartments() {
    let showAll = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showAll).subscribe((res) => {
      this.departmentList = res.response;
    });
  }

  loadConsultant(DepartmentId: Number) {
    let deptId = Number(this.prescriptionSearchForm.get("DeptId").value);
    this.prescriptionSearchForm.patchValue({ConsultantId: 0});
    this.hospitalsService.GetConsultant(deptId).subscribe((res) => {
      this.consultantList = res.response;
    });
  }

  //calling patients details Api
  GetPatientByRegNo(event, tab) {
    if(tab == 1){
      this.displayBasic = true;
    } else if(tab == 2){
      this.displayTwoBasic = true;
    }
    this.patientService.SearchPatientByRegNo(event.target.value).subscribe((res) => {
      if (res.response.length > 0) {
        if (res.response[0].active == "0") {
          this.toastrService.warning("This Patient is blocked");
        }
        let patient = {
          data: res.response[0]
        }
        this.searchevent(patient);
      } else {
        this.toastrService.warning("No patients found");
      }

    });
  }

  showBasicDialog() {
    this.displayBasic = true;
    this.modaltemp.open();
  }

  showBasicPatDialog() {
    this.displayTwoBasic = true;
    this.modalTwoTemp.open();
  }

  searchevent(patient: any) {
    if (this.displayBasic == true) {
      this.prescriptionSearchForm.patchValue({
        RegNo: patient.data.regNo,
        PatientName: patient.data.patient,
        // patientId: patient.data.patientId
      })
      this.displayBasic = false;
    } else if (this.displayTwoBasic == true) {
      this.patientSearchForm.patchValue({
        sRegNo: patient.data.regNo,
        sName: patient.data.patient,
        sMobile: patient.data.mobile
      })
      this.displayTwoBasic = false;
    }
  }

  onFilterTextBoxChanged(event) {
    this.gridApi.setQuickFilter(
      event.target.value
    );
  }

  onFilterTextBoxChangedPrescription(event) {
    this.presGridApi.setQuickFilter(
      event.target.value
    );
  }

  getPrescriptionDetails(event){
    this.masterId = event.data.id;
    let payload = {
      ConsultationId: event.data.consultationId,
      BranchId: Number(this.globals.HospitalId)
    }

    this.hospitalsService.GetSearchEPrescriptionDetails(payload).subscribe((res) => {
      this.presDetailData = res.response;
    });
  }

  search(){
    this.presDetailData = [];
    let payload = {
      RegNo: this.prescriptionSearchForm.controls.RegNo.value,
      FromDate: typeof(this.prescriptionSearchForm.controls.FromDate.value) == 'object' ? this.datepipe.transform(this.prescriptionSearchForm.controls.FromDate.value, 'dd-MM-yyyy') : this.prescriptionSearchForm.controls.FromDate.value,
      ToDate: typeof(this.prescriptionSearchForm.controls.ToDate.value) == 'object' ? this.datepipe.transform(this.prescriptionSearchForm.controls.ToDate.value, 'dd-MM-yyyy') : this.prescriptionSearchForm.controls.ToDate.value,
      DeptId: Number(this.prescriptionSearchForm.controls.DeptId.value),
      ConsultantId: Number(this.prescriptionSearchForm.controls.ConsultantId.value),
      BranchId: Number(this.globals.HospitalId)
    }

    this.hospitalsService.GetSearchEPrescription(payload).subscribe((res) => {
      this.consultations = res.response;
    });
  }

  save(){
    const foundElement = this.functionPermissionData.find(x => x.id === 582)
    if (foundElement?.id == 582 && foundElement.isAllowed == true) {
    let selectedData = this.presGridApi.getSelectedRows();
    if (selectedData.length <= 0) {
      this.toastrService.warning("Select atleast one row...!");
      return;
    }

    let selectedDataList = this.presGridApi.getSelectedRows().map((item) => {
      return {
        DrugId: item.drugId,
        IsDispensed: true,
        DispQty: Number(item.dispQty),
        Comments: item.comments ? item.comments : "",
        DispensedUserId: Number(localStorage.getItem('user_id'))
      };
    });

    let payload = {
      PrescriptionDetailList: selectedDataList,
      MasterId: this.masterId, 
      BranchId: Number(this.globals.HospitalId),
      DispensedUserId: Number(localStorage.getItem('user_id'))
    }

    this.ngxLoader.start();
    this.hospitalsService.InsertUpdatePrescriptionDetails(payload).subscribe((res) => {
      if (res.status == 200 && res.message == 'Success') {
        this.ngxLoader.stop();
        this.toastrService.success("Saved Successfully");
        this.search();
      }else{
        this.ngxLoader.stop();
        this.toastrService.error(res.message);
      }
    });
  } else {
    this.toastrService.warning("You are not allowed");
  }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }
}
