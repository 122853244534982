import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {
  errorMessage: string;

  constructor() { }
  handleError(error) {



    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.errorMessage= 'Client-side: '+error.error.message;
    } else {
      //Server side error
      this.errorMessage = 'Server-side: '+error.status+'\nMessage:'+ error.message;

    }

      if(error.status==401){

      //  let logoutCount=Number(localStorage.getItem('logout'));
      //  logoutCount++;
      //   if(logoutCount==1){
      //      localStorage.setItem('logout','2');
      //      alert("Login token expired! Please login Again");
      //      window.open('#/logout','_self');
      //   }


  }else{
    return throwError(this.errorMessage);
  }



  }
}
