import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { MentalGeneralsComponent } from './mental-generals/mental-generals.component';
import { PhysicalGeneralsComponent } from './physical-generals/physical-generals.component';
import { Settings } from '../../settings/model/settings.model';

@Component({
  selector: 'app-consultation-homeopathy',
  templateUrl: './consultation-homeopathy.component.html',
  styleUrls: ['./consultation-homeopathy.component.scss']
})
export class ConsultationHomeopathyComponent implements OnInit, OnDestroy,OnChanges {
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  

  @ViewChild(MentalGeneralsComponent) MG: MentalGeneralsComponent;
  @ViewChild(PhysicalGeneralsComponent) PG: PhysicalGeneralsComponent;

  public globals: globalvars = new globalvars();

  private subscription: Subscription;
  MentalGeneralsDisabled: boolean=false;
  PhysicalGeneralsDisabled: boolean=false;

  constructor(private sharedService: SharedService) {
    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
      }
    });
   }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const MentalGenerals = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 51 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (MentalGenerals) {
        // Your logic when a setting with value 0 is present and billEdit is true
      this.MentalGeneralsDisabled=true
       
      }

      const PhysicalGenerals = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 52 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (PhysicalGenerals) {
        // Your logic when a setting with value 0 is present and billEdit is true
      this.PhysicalGeneralsDisabled=true
       
      }
      
    }
  }

  onSaveClick(tabNo: Number){
    switch(tabNo){
      case 1 :  this.MG.onSaveMentalGenerals();
                break;

      case 2 :  this.PG.onSavePhysicalGenerals();
                break;

      default: 
               this.MG.onSaveMentalGenerals();
               this.PG.onSavePhysicalGenerals();
    }
  }

  ngOnDestroy(): void {
    this.onSaveClick(10);
  }

}
