import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PrevVitalSignsModalComponent } from '../modals/prev-vital-signs-modal/prev-vital-signs-modal.component';
import { EMRPatientInfo, EMRSponsorInfo } from '../../interfaces/EMRPatientInfo';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Observable, Observer, Subscription, noop, of } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../../shared/shared.service';
import { EMRService } from '../../services/emr.service';
import { Response } from '../../../../_Models/Response';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';
import * as _ from 'lodash';
import { ConsultantDeskService } from '../../../client/services/consultant.service';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { PrevSeviceOrdesModalComponent } from '../modals/prev-sevice-ordes-modal/prev-sevice-ordes-modal.component';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { TelerikPrintComponent } from '../../../../shared/modals/telerik-print/telerik-print.component';
import { DatePipe } from '@angular/common';
import { SettingsService } from '../../../settings/services/settings.service';
import { Settings } from '../../../settings/model/settings.model';
import { SponsorRuleItemNoteComponent } from '../../../configurations/modals/sponsor-rule-item-note/sponsor-rule-item-note.component';

@Component({
  selector: 'app-service-orders',
  templateUrl: './service-orders.component.html',
  styleUrls: ['./service-orders.component.scss']
})
export class ServiceOrdersComponent implements OnInit, OnDestroy,OnChanges {
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  disabled:boolean=false

  public EmrPatientInfo:any;
  private globals: globalvars = new globalvars();
  private subscription: Subscription;
  private FormValueChangeChecker: boolean = false;

  public serviceOrderForm: FormGroup;
  public searchService: string = '';
  public serviceObservale$: Observable<any[]>;

  private baseUrl: string = '';

  public CPTModifiers: any[] = [];
  private frequentlyUsedItems: any[] = [];
  public frequentlyUsedDropDown: any[] = [];

  public totalAmount: any = 0;
  public orderId: any = 0;
  public dropdownOptions: Options;
  public dropdownDataConfig: any = {
    showCovered: true,
    showFrequent: true
  }
  @Output() serviceTotal = new EventEmitter<any>()
  totalCopay = 0;
  totalDeduction = 0;
  public configurationSettings: Settings[] = [];
  showInsuranceDet: any;
  EMRSponsorInfo:EMRSponsorInfo;
 

  constructor(private modalService: BsModalService,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private sharedService: SharedService,
              private http: HttpClient,
              private ConfigurationService: ConfigurationService,
              private ConsultantDeskService: ConsultantDeskService,
              private configurationManager: ConfigurationManager,
              private EmrService: EMRService,
              private datepipe: DatePipe,
              private settingsService: SettingsService,) { }

  ngOnInit(): void {
    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
      }
    });

    this.EmrPatientInfo = this.EmrService.GetEMRPatientInfo();
    this.EMRSponsorInfo = this.EmrService.GetEMRSponsorInfo();
    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.initForm();
    this.getApplicationSettings();
    this.searchServiceOrder();
    this.GetEMRServiceItems();
    this.getFrequentlyServiceItem();
    this.GetAllCPTModifier();
    this.dropdownOptions = {
      templateResult: this.templateResult,
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 41 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (isSettingWithValueZeroPresent) {
        // Your logic when a setting with value 0 is present and billEdit is true
      
        this.disabled=true
      }
      
    }
  }

  // function for result template
  public templateResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    let icon = '<i class="fa" aria-hidden="true"></i>';
    if (state.additional.insCovered) {
      icon = '<i class="fa fa-umbrella" aria-hidden="true"></i>';
    }
    return jQuery('<span class="d-flex justify-content-between">'+ state.text + '   '+icon+ '</span>');
  }

  initForm(){
    this.serviceOrderForm = this.formBuilder.group({
      itemDetails: new FormArray([]),
      typeOfOrder: new FormControl('G'),
      visitId: new FormControl(this.EmrPatientInfo.VisitId),
      branchId: new FormControl(this.globals.HospitalId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
    })
  }

  GetEMRServiceItems() {
    let date = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    let input = {
      VisitId: this.EmrPatientInfo.VisitId,
      PatientId: this.EmrPatientInfo.PatientId,
      // ShowAll: 1,
      ConsultationId: this.EmrPatientInfo.ConsultationId,
      CurrentDate:date
    };

    // this.EmrService.GetServiceItemsEMR(input).subscribe(
      this.EmrService.GetGeneralServiceItemsEMR(input).subscribe(
      (res) => {
        if (res.status == 200) {
          let response = res.response[0];
          if (response) {
            this.orderId = response.orderId;
            if (response.itemDetails) {
              this.addMapFormGroup(response.itemDetails, 1);
            }
          }
          this.serviceOrderForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker = true;
          })
        }

      });
  }

  searchServiceOrder(){
     /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.serviceObservale$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.searchService);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          return this.http.post<Response<any[]>>(
            `${this.baseUrl}api/EMR/GetEMRServiceItem`, {
            "ServiceName": query,
            "branchId": this.globals.HospitalId,
            "GroupId": 0,
            // "consultantId":this.EMRPatientInfo.ConsultantId
            "consultantId": 0,
            PatientId: this.EmrPatientInfo.PatientId,
            ConsultationId: this.EmrPatientInfo.ConsultationId,
            CurrentDate: this.datepipe.transform(new Date(), "dd-MM-yyyy"),
            CreditId: this.EMRSponsorInfo.CreditId,
            Category: 'N'
          }).pipe(
            map((data) => _.forEach(data.response, e=>{
              e.itemDropDownName = e.itemName+' - '+e.cptCode;
              return e
            }) || []),
            tap(() => noop, err => {
              let errorMessage = err && err.message || 'Something goes wrong';
              this.toastrService.error(errorMessage);
            })
          );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  openHistory(){
    let initialState = {
      EmrPatientInfo: this.EmrPatientInfo
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const historyModalRef= this.modalService.show(PrevSeviceOrdesModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

  getFrequentlyServiceItem() {
    this.frequentlyUsedDropDown = [];
    this.ConsultantDeskService.GetServiceItems(
      Number(this.EmrPatientInfo.ConsultantId), 
      this.globals.HospitalId, 
      Number(this.EmrPatientInfo.ConsultationId), 
      this.dropdownDataConfig,
      this.datepipe.transform(new Date(), "dd-MM-yyyy"),
      this.EMRSponsorInfo.CreditId,
      'N'
      ).subscribe((res) => {
      this.frequentlyUsedItems = res.response;
      this.frequentlyUsedDropDown = [];
      this.frequentlyUsedItems.forEach((a) => {
        this.frequentlyUsedDropDown.push({
          id: a.itemId.toString(),
          text: a.itemName + ' - '+a.cptCode,
          additional: {
            insCovered: a.insCovered
          }
        });
      });
    });
  }

  getFrequentlyServiceItemData(event) {
    if (event) {
      let filteredArray = []
      this.frequentlyUsedItems.forEach((a) => {
        let itemFilter = filteredArray.filter(value => value.itemId == a.itemId);
        if (a.itemId == event && itemFilter.length == 0) {
          filteredArray.push(a);
        }
      });
      this.addMapFormGroup(filteredArray, 0);
    }
  }

  GetAllCPTModifier() {
    this.ConfigurationService.GetCPTModifier(this.globals.HospitalId, 0).subscribe((res) => {
      this.CPTModifiers = res.response;
    });
  }

  LoadItems(item) {
    this.addMapFormGroup(item, 0);
  }

  public addMapFormGroup(data: any[] = [], saved = 0) {
    const ServiceItems = this.serviceOrderForm.get("itemDetails") as FormArray;
    const values = Object.keys(data).map(key => data[key]);

    if (data.length > 0) {
      data.forEach((element, i) => {
        let filteredArray = ServiceItems.value.filter(
          value => value.itemId == element.itemId
          // (value) => ((value.teeths !== '') ? ((value.itemId == element.itemId) && (value.teeths == element.teeths)) : (value.itemId == element.itemId))
          );
        if (filteredArray.length == 0) {
          ServiceItems.push(this.createListGroup(element, saved));
        }
        else {
          this.toastrService.error("Failure: " + "Service Items Already added");
        }
      });

    }
    else if (values.length > 0) {
      let filteredArray = ServiceItems.value.filter(value => value.itemId == values[0]);
      if (filteredArray.length == 0) {
        ServiceItems.push(this.createListGroup(data, saved));
      }
      else {
        this.toastrService.error("Failure: " + "Service Items Already added");
      }
    }
    this.searchService = "";
    this.calculateTotalAmount();
    this.getTotalRate();
  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    console.log(data)
    if (saved == 0) {
      if (!data["qty"]) data["qty"] = 1;
    }
    if (saved == 0) {
      this.FormValueChangeChecker = true;
    }
    let temName = data["itemName"] + " - " + data["itemCode"];
    let cptModifierId = 0;

    if (data["cptModifierId"]) cptModifierId = data["cptModifierId"];
    return new FormGroup({
      itemId: new FormControl(data["itemId"]),
      itemName: new FormControl(temName),
      notes: new FormControl(data["notes"]),
      cptModifierId: new FormControl(cptModifierId),
      authReq: new FormControl(data['authReq']),
      qty: new FormControl(data["qty"]),
      cptCode: new FormControl(data['cptCode'] ? data['cptCode'] : ''),
      rate: new FormControl(data['itemRate']? data['itemRate'] : (data['rate']? data['rate'] : 0)),
      insCovered: new FormControl(data["insCovered"]),
      serviceValidity: new FormControl(data["serviceValidity"]),
      remainingDays: new FormControl(data["remainingDays"]),
      RuleId: new FormControl(data["ruleId"] ? data["ruleId"] : 0),
      dedAmt: new FormControl(data["dedAmt"] ? data["dedAmt"] : 0),
      dedPer: new FormControl(data["dedPer"] ? data["dedPer"] : 0),
      coPayAmt: new FormControl(data["coPayAmt"] ? data["coPayAmt"] : 0),
      copayPer: new FormControl(data["copayPer"] ? data["copayPer"] : 0),
      taxPcnt: new FormControl(data["taxPcnt"] ? data["taxPcnt"] : 0),
      copayMaxLimit: new FormControl(data["copayMaxLimit"] ? data["copayMaxLimit"] : 0),
      maxLimit: new FormControl(data["maxLimit"] ? data["maxLimit"] : 0),
      note: new FormControl(data["note"] ? data["note"] : ''),
    });
  }

  RemoveFromServiceList(index) {
    var Value = new FormArray([]);
    Value = this.serviceOrderForm.get("itemDetails") as FormArray;
    Value.removeAt(index);
    this.calculateTotalAmount();
    this.getTotalRate();

  }

  PrintServiceOrder() {
    let initialState = {
      modalHeading: 'Print Service Order Details',
      reportName: 'RptServiceOrderDetails.trdp',
      reportParams: {
        ConsultationId: this.EmrPatientInfo.ConsultationId,
        OrderId: this.orderId,
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
    // const payload = {
    //   sid: this.orderId,
    //   clientid: Number(this.globals.ClientId)
    // }
    // this.ConfigurationService.PrintServiceOrder(payload).subscribe((res) => {
    //   if (res.status == 200) {
    //     let response = res.response;
    //     let src = response[0].reportPath;
    //     src = this.baseUrl + src;
    //     window.open(src, "_blank");
    //   }
    // })
  }

  calculateTotalAmount(){
    let serviceItems: any[] = (this.serviceOrderForm.get("itemDetails") as FormArray).value;
    this.totalAmount = 0;
    _.forEach(serviceItems, (item: any)=>{
      const unitPrice = Number(item.rate);
      const qunatity = Number(item.qty);
      this.totalAmount = (Number(this.totalAmount) + Number(unitPrice) * Number(qunatity)).toFixed(2);
      this.serviceTotal.emit(this.totalAmount)
    });
  }

  SaveServiceOrder() {
    if ( this.EmrPatientInfo.CrudType != 3 &&
      this.EmrPatientInfo.VisitId > 0 && this.FormValueChangeChecker) {

      let ServiceOrderForm = this.serviceOrderForm.getRawValue();
      ServiceOrderForm.userId = Number(localStorage.getItem('user_id'));
      ServiceOrderForm.branchId = Number(this.globals.HospitalId);
      ServiceOrderForm.LocationId = Number(this.globals.Location.locationId);
      ServiceOrderForm.typeOfOrder = 'G';

      ServiceOrderForm.itemDetails.forEach((a) => {
        if(a.serviceValidity == false){
          a.RuleId = 0;
        }
      });

      this.EmrService.SaveServiceOrder(
        ServiceOrderForm
      ).subscribe(
        (res) => {
          this.FormValueChangeChecker=false
          if (res.status == 200 && res.message == "Success") {
            this.orderId = res.response['orderId'];
            this.toastrService.success("Success: " + "Service Order Details Saved Successfully...");
          }else {
            this.toastrService.error(res.errorMessage.message)
          }
        });
    }
    else {
      if (!this.FormValueChangeChecker) {
        // this.toastrService.warning("Info: " + "No changes detected in the entry");
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getTotalRate() {
    let maxLimit;
    this.totalDeduction = 0;
    this.serviceOrderForm.get('itemDetails')['controls'].forEach((element, index) => {
      
      let taxAmount = Number(((Number(element.get('taxPcnt').value) * element.get('rate').value) / 100).toFixed(2));

      if (element.get('insCovered').value && this.EMRSponsorInfo.SponsorId) {
        let deduction: number = 0;
        let isDeductionApplied: boolean = false;

        if (element.get('dedAmt').value && element.get('dedAmt').value != 0) {
          deduction = Number(element.get('dedAmt').value);
          
        } else {
          deduction = Number(((Number(element.get('dedPer').value) * (element.get('rate').value + taxAmount)) / 100).toFixed(2));
          
        }
        deduction = deduction > element.get('rate').value ? element.get('rate').value : deduction;
        isDeductionApplied = deduction > 0 ? true : false;
        
        this.totalDeduction = Number((this.totalDeduction + deduction).toFixed(2));

        let copay: number = 0;

        if (element.get('coPayAmt').value && element.get('coPayAmt').value != 0) {
          copay = Number(element.get('coPayAmt').value);
        } else {
          copay = Number(((Number(element.get('copayPer').value) * ((element.get('rate').value - deduction) + taxAmount)) / 100).toFixed(2));
        }
        copay = copay > element.get('rate').value ? (isDeductionApplied && deduction < element.get('rate').value ? element.get('rate').value : 0.00) : (isDeductionApplied && (copay > element.get('rate').value - deduction) ? element.get('rate').value - deduction : copay);
        copay = ((copay > Number(element.get('copayMaxLimit').value)) && (Number(element.get('copayMaxLimit').value) > 0)) ? Number(element.get('copayMaxLimit').value) : copay;
        this.totalCopay = Number((this.totalCopay + copay).toFixed(2));
      }
      maxLimit = element.get('maxLimit').value
    });
    if ((Number(maxLimit) !== 0) && (this.totalCopay > Number(maxLimit))) {
      this.totalCopay = Number((maxLimit).toFixed(2))
    }
    
  }

  getApplicationSettings() {
    let payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId: this.globals.HospitalId
    }

    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200) {
          this.configurationSettings = _.filter(response.response, { tabId: 1 });
          let showInsure = this.configurationSettings.filter((item) => item.settId == 29);
          
          this.showInsuranceDet = Number(showInsure[0].settValue);
        }
      }
    })
  }

  openServiceNote(data){
    let initialState = {
      noteFieldData: data,
      action: 'view'
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(SponsorRuleItemNoteComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
  }

  generateErx(){
    let input:any = {
      ConsultationId: this.EmrPatientInfo.ConsultationId,
      BranchId:  this.globals.HospitalId,
      SponsorId: this.EmrPatientInfo.SponsorId,
      PatientId:this.EmrPatientInfo.PatientId,
      VisitId:this.EmrPatientInfo.VisitId,
      ConsultantId:this.EmrPatientInfo.ConsultantId
    };
    this.EmrService.generateErx(input).subscribe((res:any)=>{
      if (res.status == 200 && res.message == "Success") {
        this.toastrService.success("Success: " + "Generate ERX Successfully.");
      } else {
        if(res.errorMessage.message == null) {
          this.toastrService.error(res.message)
        } else {
          this.toastrService.error(res.errorMessage.message)
        }
      }
    })
  }

}
