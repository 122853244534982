import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationList } from '../interfaces/ConfigurationList';
import { ConfigurationService } from '../services/configuration.service';
import { ToastrService } from 'ngx-toastr';
import { UserHospitals } from '../interfaces/UserHospitals';
import { HospitalsService } from '../services/hospitals.service';
import { CountryList } from '../interfaces/CountryList';
import { AppointmentsService } from '../services/appointments.service';
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { ColDef, GridOptions } from 'ag-grid-community';
@Component({
  selector: 'app-ConfigComponent',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  products: any = {};
  zones: any = {};
  Company: boolean
  Localization: boolean = true;
  Permissions: boolean;
  Email: boolean;
  Invoice: boolean;
  Notifications: boolean;
  Changepassword: boolean;
  displayModal: boolean;
  displayModal1: boolean;
  displayModaluser: boolean;
  displayModaluserrights: boolean;
  displayuserrights: boolean;
  displaybranch: boolean;
  rowData: any = [];
  username = '';
  password = '';
  subscription: Subscription;
  globals: globalvars = new globalvars();
  result: any;//check status code
  columnDefs: ColDef[] = [];
  users: any = [];
  selectedCities: any[] = [];
  availableProducts: any = [];
  selectedProducts: any = [];
  draggedProduct: any = [];

  form: FormGroup;   // form initialization for Zone 

  formBranch: FormGroup; // form initialization for Branches 

  zonesList: ConfigurationList[] = []; // adding variable for zones
  hospitalList: UserHospitals[] = [];   //  brancheslist declaration

  countryList: CountryList[] = [];
  BranchesId: any;


  public defaultColDef: ColDef;

  constructor(private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private hospitalsService: HospitalsService,
    private appointmentService: AppointmentsService,
    private sharedservice: SharedService,
    // private configList:ConfigurationList,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }


  onTab(number) {
    this.Localization = false;
    this.Company = false;
    this.Permissions = false;
    this.Email = false;
    this.Invoice = false;
    this.Notifications = false;
    this.Changepassword = false;

    if (number == '1') {
      this.Localization = true;
    }
    else if (number == '2') {
      this.Company = true;
    }
    else if (number == '3') {
      this.Permissions = true;
    }
    else if (number == '4') {
      this.Email = true;
    }
    else if (number == '5') {
      this.Invoice = true;
    }
    else if (number == '6') {
      this.Notifications = true;
    }
    else if (number == '7') {
      this.Changepassword = true;
    }
  }

  ngOnInit(): void {
    this.hospitalsService.GetUserHospitals().subscribe((res) => {
      this.hospitalList = res.response;
      setTimeout(() => {
        $('#datatablebranch').DataTable({
          pagingType: 'full_numbers',
          pageLength: 10,
          processing: true,
          lengthMenu: [10, 20, 30],    // show entries
          autoWidth: true,
          initComplete: function () {    // show dT after complete loading
            $('h2.loading').remove();
            $(this).removeClass('d-none');
            $(this).show();
          }

        });
      }, 0);

    });

    this.form = this.formBuilder.group({
      zoneCountryId: ['0'],
      zoneDescription: [''],
      zoneCode: [''],
      zoneOperator: ['0'],

    });

    // form initialization for branches

    this.formBranch = this.formBuilder.group({
      zoneId: ['0'],
      hospitalCode: [''],
      hospitalName: [''],
      address: [''],
      street: [''],
      city: [''],


    });

    this.products = [{ 'code': 'Aj1002', 'name': 'Vasumathi Eye Clinic', 'Address1': '1st floor,Lullu Mall', 'address2': 'Edapally', 'street': 'Kochi', 'city': 'Ernakulam' }, { 'code': 'TMC_Kerala', 'name': 'Government Medical College TVM', 'Address1': 'Medical College PO', 'address2': 'TVM', 'street': 'ULLOOR', 'city': 'Thiruvananthapuram' }, { 'code': 'KIM', 'name': 'KIMS', 'Address1': '', 'address2': '', 'street': '', 'city': '' }, { 'code': 'AIIMS', 'name': 'All India Institute Of Medical Sciences', 'Address1': 'Delhi', 'address2': 'Delhi', 'street': 'Delhi', 'city': 'Delhi' }];
    this.rowData = [

      {
        data: { brand: 'Emergency', lastYearSale: 'unni', thisYearSale: '123456888099', lastYearProfit: '$223,132', thisYearProfit: '$156,061' },
        children: [

          {
            data: { brand: 'Super Speciality', lastYearSale: 'admin', thisYearSale: '7890654321', lastYearProfit: '$200,000', thisYearProfit: '$156,061' },
          },
          {
            data: { brand: 'OHI', lastYearSale: 'admin', thisYearSale: '45789000666', lastYearProfit: '$200,000', thisYearProfit: '$156,061' },
          }
        ]
      },




    ];

    this.users = [{ 'code': 'test' }, { 'code': 'user1' }, { 'code': 'test1' }, { 'code': 'test' }];
    this.availableProducts = [{ 'id': '1', 'category': 'Administration', 'name': 'bl', 'price': '100' }, { 'id': '2', 'category': 'Management', 'name': 'Bhagya', 'price': '500' }];
    this.GetUserHospitals();   // Initializing Branches Listing Api
  }

  showModalDialog() {
    this.displayModal = true;
    this.LoadCountry();
  }
  showModalDialogedit() {
    this.username = 'user1';
    this.password = '123';
    this.displayModal1 = true;
  }
  showModalDialogbranch() {
    this.displaybranch = true;
  }

  showModalDialoguser() {
    this.displayModaluserrights = true;
  }

  ShowUserRights() {
    this.displayuserrights = true;
  }
  SaveZone() {
    var AppData = {
      zoneCountryId: this.form.get("zoneCountryId").value,
      zoneDescription: this.form.get("zoneDescription").value,
      zoneCode: this.form.get("zoneCode").value,
      zoneOperator: this.form.get("zoneOperator").value,

    }
    this.configurationService.SaveZones(AppData).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Zone added successfully");
      }
      else {
        this.toastr.error("Failed.Please try later");
      }
    })
  }
  GetUserHospitals() {
    this.hospitalsService.GetUserHospitals().subscribe((res) => {
      this.hospitalList = res.response;
    });
  }
  LoadCountry() {
    this.appointmentService.GetCountry(this.globals.HospitalId).subscribe((res) => {
      this.countryList = res.response;
    })
  }
}
