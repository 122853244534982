import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, GridApi } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import * as customEditor from '../../../../../../../ckeditor/build/ckeditor';

@Component({
  selector: 'app-work-list-manage',
  templateUrl: './work-list-manage.component.html',
  styleUrls: ['./work-list-manage.component.scss']
})
export class WorkListManageComponent implements OnInit {

  @Input() itemData: any;

  public Editor = customEditor;
  public workListForm: FormGroup;
  public saveWorkListLoading: boolean = false;

  public workLists: any[] = [
    {
      name: 'test',
      id: 1
    },
    {
      name: 'test 2',
      id: 2
    }
  ]

  constructor(public modalRef: BsModalRef,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.initWorkListForm()
  }

  initWorkListForm(){
    this.workListForm = this.formBuilder.group({
      workListId: new FormControl(0, Validators.required),
      workListType: new FormControl('', Validators.required),
      workListFile: new FormControl(''),
      workListTemplateId: new FormControl(''),
      workListTemplateData: new FormControl(''),
    })
  }

  onReady(editor) {
    editor.ui.height=30;
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  onWorkListTypeChange(type){
    if(type == 1){
      this.workListForm.get('workListTemplateId').setValidators(Validators.required);
      this.workListForm.get('workListTemplateData').setValidators(Validators.required);

      this.workListForm.get('workListFile').clearValidators();
    }else if(type == 2){
      this.workListForm.get('workListTemplateId').clearValidators();
      this.workListForm.get('workListTemplateData').clearValidators();

      this.workListForm.get('workListFile').setValidators(Validators.required);
    }else{
      this.workListForm.get('workListTemplateId').clearValidators();
      this.workListForm.get('workListTemplateData').clearValidators();
      this.workListForm.get('workListFile').clearValidators();
    }

    this.workListForm.get('workListTemplateId').updateValueAndValidity();
    this.workListForm.get('workListTemplateData').updateValueAndValidity();
    this.workListForm.get('workListFile').updateValueAndValidity();    
  }

  saveWorkList(){
    const controls = this.workListForm.controls;
    if(this.workListForm.invalid){
      Object.keys(controls).forEach(control=>{
        controls[control].markAllAsTouched();
      });

      return
    }
  }

}
