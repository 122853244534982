import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ErrorHandleService } from '../../client/services/error-handle.service';
import { Response } from '../../../../app/_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  GetitemGroups(Input: { BranchId: number; GroupId: number; ShowAll: number; }) {
    throw new Error('Method not implemented.');
  }

  private baseUrl = "";

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
   }

   GetRegistrationReport(inputData) {
    var url = "api/Report/GetRegistrationReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  // ///////////////////////////////////////////////////////////////////
  GetConsultantReport(inputData) {
    var url = "api/Report/GetConsultantReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  ////////////////////////////////////////////
  GetAppointmentReport(inputData) {
    var url = "api/Report/GetAppointmentReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  ///////////////////////////////////////////////////////

  GetRevenueReport(inputData) {
    var url = "api/Report/GetRevenueReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }

  GetItemGroups(inputData){
    var url = "api/Bill/GetItemsGroups";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  GetTreatmentReport(inputData){
    var url = "api/Report/GetTreatmentDetailsReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  Getlocation(inputData) {
    var url = "api/MasterData/GetLocation";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
 
  }
  GetReceiptReport(inputData) {
    var url = "api/Report/GetReceiptDetailsReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
 
  }
  GetPaymentreport(inputData) {
    var url = "api/Report/GetPaymentDetailsReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
 
  }
  GetBillSettlementReport(inputData) {
    var url = "api/Report/GetBillSettlementReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  getBillDetailsreport(inputData) {
    var url = "api/Report/GetBillingDetailsReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  CollectionSummaryReport(inputData) {
    var url = "api/Report/GetCollectionSummaryReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  GetCollectionSummaryGroupwiseReport(inputData) {
    var url = "api/Report/GetCollectionSummaryGroupwiseReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }

  CollectionSummaryReportDaywise(inputData) {
    var url = "api/Report/GetCollectionSummaryDaywiseReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }

  SearchPatientByRegNo(regNo: string) {
    // api/TodaysPatient/GetPatientByRegNo
    var url = "api/TodaysPatient/GetPatientByRegNo";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "RegNo": regNo
      }).pipe(
      );
  }

}
