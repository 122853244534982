export interface IConfig {
	[key: string]: string;
}

export class ConfigurationManager {
	private static config: IConfig = {};

	/**
	 * @summary
	 * Load instance of ConfigurationManager
	 *
	 * @example
	 * ConfigurationManager.loadInstance('config-dev.txt.json')
	 *   .then(() => {
	 *    platformBrowserDynamic().bootstrapModule(AppModule)
	 *   .catch(err => console.log(err));
	 *    });
	 */
	public static loadInstance(filePath: string) {
		return new Promise((resolve, reject) => {
			const xobj = new XMLHttpRequest();
			xobj.overrideMimeType("application/json");
			xobj.open("GET", filePath, true);
			xobj.onreadystatechange = () => {
				if (xobj.readyState === 4) {
					if (xobj.status === 200) {
						this.config = JSON.parse(xobj.responseText);
						resolve(1);
					}
				}
			};
			xobj.send(null);
		});
	}

	public static getInstance() {
		return ConfigurationManager.config;
	}
}
