import { ElementRef, HostListener, Injectable, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, retry } from "rxjs/operators";
//import { ErrorHandleService } from './error-handle.service';
import Konva from "konva";
import { ConfigurationManager } from "../../../../../../assets/configuration/configuration-manager";
import { Response } from "../../../../../_Models/Response";
import { globalvars } from "../../../../../shared/models/GlobalVars";
import { Subject, Subscription } from "rxjs";
import { SharedService } from "../../../../../shared/shared.service";
import { Stage } from "konva/lib/Stage";
import { Layer } from "konva/lib/Layer";
import { Line } from "konva/lib/shapes/Line";
import { KonvaEventObject } from "konva/lib/Node";
@Injectable({
  providedIn: "root",
})
export class AddSketchService {
  dragging = "";
  indicatorsList: any;
  BranchesId: any;
  BranchId: any;
  private baseUrl = "";
  errorMessage: any;
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private sharedservice: SharedService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
        localStorage.setItem("BranchId", this.BranchesId);
      }
      this.BranchId = localStorage.getItem("BranchId");
    });
  }
  subscription: Subscription;
  globals: globalvars = new globalvars();
  private _stageInstance!: Konva.Stage;
  private _elementId = "";
  private _parentWidth = 0;
  private _parentHeight = 0;
  private _xSnaps = 0;
  private _ySnaps = 0;
  private _cellWidth = 0;
  private _cellHeight = 0;
  private _markingLayer!: Konva.Layer | null;
  private _tr!: Konva.Transformer | null;

  customImages = [
    {
      type: "star",
      path: "http://localhost:58946/uploads/sketchindicators/circle.png",
    },
    {
      type: "danger",
      path: "/assets/sketch/danger.jpg",
    },
    {
      type: "quick",
      path: "/assets/sketch/quick.jpg",
    },
    {
      type: "spoted",
      path: "/assets/sketch/spotted.jpg",
    },
  ];
  stage: Konva.Stage;
  layer: Konva.Layer;
  isDrawing = false;
  lastLine: Konva.Line;
  isEraserMode = false; // Flag to track eraser mode
  drawingLayer: Layer;
  private iSErase = new Subject<boolean>();

  startDrawing() {
    this.isDrawing = true;
    const pos = this._stageInstance.getPointerPosition();
    this.lastLine = new Konva.Line({
      stroke: this.isEraserMode ? "white" : "black", // Adjust color for eraser mode
      strokeWidth: 5,
      globalCompositeOperation: this.isEraserMode
        ? "destination-out"
        : "source-over",
      points: [pos.x, pos.y],
    });
    this.layer.add(this.lastLine);
  }

  stopDrawing() {
    this.isDrawing = false;
    this.layer.batchDraw();
  }

  toggleEraser() {
    this.isEraserMode = !this.isEraserMode;
    this.iSErase.next(this.isEraserMode);
  }

  draw() {
    if (!this.isDrawing) {
      return;
    }

    const pos = this._stageInstance.getPointerPosition();
    const newPoints = this.lastLine.points().concat([pos.x, pos.y]);

    if (this.isEraserMode) {
      // If in eraser mode, change the stroke color and composite operation
      this.lastLine.stroke("white");
      this.lastLine.globalCompositeOperation("destination-out");
    }

    this.lastLine.points(newPoints);
    this.layer.batchDraw();
  }

  createStageInstance(parentElement: HTMLDivElement) {
    this._parentWidth = parentElement.clientWidth;
    this._parentHeight = parentElement.clientHeight;
    this._elementId = parentElement.id;

    const stage = new Konva.Stage({
      container: this._elementId,
      width: parentElement.clientWidth,
      height: parentElement.clientHeight,
    });

    this._xSnaps = Math.round(stage.width() / 100);
    this._ySnaps = Math.round(stage.height() / 100);
    this._cellWidth = stage.width() / this._xSnaps;
    this._cellHeight = stage.height() / this._ySnaps;

    this._stageInstance = stage;
    this._addBackgroundLayer();

    this._attchFIleDropHandler(parentElement);
    return this._stageInstance;
  }

  private _addBackgroundLayer() {
    const background = new Konva.Rect({
      x: -50,
      y: 0,
      width: this._stageInstance.width(),
      height: this._stageInstance.height(),
      fill: "#FFF",
    });

    const bgLayer = new Konva.Layer();
    bgLayer.add(background);
    this._stageInstance.add(bgLayer);
  }

  private _attchFIleDropHandler(parentElement: HTMLDivElement) {
    parentElement.addEventListener("drop", (e) => this._fileDropHandler(e));
    parentElement.addEventListener("dragover", (e) => this._dragOverHandler(e));
  }

  get stageInstance() {
    if (this._stageInstance) {
      return this._stageInstance;
    } else {
      throw "Intance is not initialized, call createStageInstance() method first";
    }
  }

  clear() {
    this._stageInstance.removeChildren();
    this._markingLayer = null;
  }

  addText(text: string) {
    const complexText = new Konva.Text({
      x: 20,
      y: 60,
      text,
      fontSize: 18,
      fontFamily: "Calibri",
      fill: "#555",
      width: 300,
      padding: 20,
      align: "center",
    });

    const rect = new Konva.Rect({
      x: 20,
      y: 60,
      stroke: "#555",
      strokeWidth: 5,
      fill: "#ddd",
      width: 300,
      height: complexText.height(),
      shadowColor: "black",
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10,
    });

    const group = new Konva.Group({
      x: 20,
      y: 60,
      draggable: true,
    });

    group.add(rect);
    group.add(complexText);

    if (!this._markingLayer) {
      this._markingLayer = new Konva.Layer();
      this._stageInstance.add(this._markingLayer);
    }

    this._markingLayer?.add(group);
    group.on("pointerdown", () => this.addResizer(this._markingLayer!, group));
  }

  addLine() {
    const redLine = new Konva.Line({
      points: [
        this.stageInstance.width() / 2,
        this.stageInstance.height() / 2,
        this.stageInstance.width() / 2 + 300,
        this.stageInstance.height() / 2,
      ],
      stroke: "red",
      strokeWidth: 10,
      draggable: true,
    });

    if (!this._markingLayer) {
      this._markingLayer = new Konva.Layer();
      this._stageInstance.add(this._markingLayer);
    }

    this._markingLayer?.add(redLine);
    redLine.on("pointerdown", () =>
      this.addResizer(this._markingLayer!, redLine)
    );
  }
  addPencil() {
    this.drawingLayer = new Layer({ zIndex: 5 }); // Higher zIndex for drawing

    this._stageInstance.add(this.drawingLayer);

    this.layer = new Konva.Layer();
    this._stageInstance.add(this.layer);

    this._stageInstance.on("mousedown touchstart", () => {
      this.startDrawing();
    });

    this._stageInstance.on("mouseup touchend", () => {
      this.stopDrawing();
    });

    this._stageInstance.on("mousemove touchmove", () => {
      this.draw();
    });
  }
  handleMouseDown = (event: KonvaEventObject<MouseEvent>) => {
    this.isDrawing = true;

    const pos = this._stageInstance.getPointerPosition();
    this.lastLine = new Line({
      stroke: "black",
      strokeWidth: 2,
      globalCompositeOperation: "source-over",
      points: [pos.x, pos.y],
    });

    this.layer.add(this.lastLine);
  };

  handleMouseUp = () => {
    this.isDrawing = false;
  };

  handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    if (!this.isDrawing) {
      return;
    }

    const pos = this._stageInstance.getPointerPosition();
    const newPoints = this.lastLine.points().concat([pos.x, pos.y]);
    this.lastLine.points(newPoints);
    this.layer.batchDraw();
  };

  getImage(callback: (img: HTMLImageElement) => void) {
    this.stageInstance.toImage({
      x: 0,
      y: 0,
      callback,
    });
  }

  private _fileDropHandler(ev: DragEvent) {
    // 'File(s) dropped';
    ev.preventDefault();
    if (this.dragging === "circle") {
      const circle = new Konva.Circle({
        x: this.stageInstance.width() / 2,
        y: this.stageInstance.height() / 2,
        radius: 10,
        fill: "red",
        stroke: "black",
        draggable: true,
        strokeWidth: 2,
      });
      if (!this._markingLayer) {
        this._markingLayer = new Konva.Layer();
        this._stageInstance.add(this._markingLayer);
      }

      circle.moveTo(this._markingLayer);
      circle.on("pointerdown", () =>
        this.addResizer(this._markingLayer!, circle)
      );
      return;
    } else if (this.dragging === "square") {
      const square = new Konva.Rect({
        x: this.stageInstance.width() / 2,
        y: this.stageInstance.height() / 2,
        width: 20,
        height: 20,
        fill: "red",
        stroke: "black",
        draggable: true,
        strokeWidth: 2,
      });
      if (!this._markingLayer) {
        this._markingLayer = new Konva.Layer();
        this._stageInstance.add(this._markingLayer);
      }

      square.moveTo(this._markingLayer);
      square.on("pointerdown", () =>
        this.addResizer(this._markingLayer!, square)
      );
      return;
    } else if (this.dragging === "line") {
      this.addLine();
    } else if (this.dragging === "text") {
      const text: string = prompt("Enter Text") ?? "";
      this.addText(text);
    } else if (this.dragging === "pencil") {
      this.addPencil();
    } else {
      const image = this.indicatorsList.find(
        (ci) => ci.indicatorDesc === this.dragging
      );

      if (image) {
        console.log(image);
        this._loadImage(image.imageUrl);
      }
    }

    if (ev.dataTransfer && ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          const file = ev.dataTransfer.items[i].getAsFile() || null;
          this._loadDropedImage(file!);
        }
      }
    } else if (ev.dataTransfer) {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        this._loadDropedImage(ev.dataTransfer.files[i]);
      }
    }
  }

  private _loadImage(path: string) {

    const image = new Image();
    image.onload = () => {
      // image axis
      const imageSnap = new Konva.Image({
        x: this._stageInstance!.children!.length * 20,
        y: this._stageInstance!.children!.length * 20,
        image,
        width: (image.width / this._parentWidth) * 100,
        height: (image.height / this._parentHeight) * 100,
        draggable: true,
      });
      // text axis
      const textNode = new Konva.Text({
        text: "Some text here",
        x: this._stageInstance!.children!.length * 200,
        y: this._stageInstance!.children!.length * 60,
        fontSize: 20,
        draggable: true,
        width: 200,
      });

      // arrow axis
      const arrow = new Konva.Arrow({
        points: [
          imageSnap.x() + imageSnap.width(),
          imageSnap.y() + imageSnap.height() / 2,
          textNode.x() - 5,
          textNode.y() + textNode.fontSize() / 2,
        ],
        pointerLength: 20,
        pointerWidth: 20,
        fill: "black",
        stroke: "black",
        strokeWidth: 4,
        draggable: false,
      });

    // for Group
      var group = new Konva.Group({
        x: 100,
        y: 100,
        draggable: true,
      });
      const layer = new Konva.Layer();
      group.add(arrow);
      group.add(imageSnap);
      group.add(textNode);
      layer.add(group);
      this.stageInstance.add(layer);
      // text area axis update to arrow
      textNode.on("dragmove", function () {
        var p: any = [imageSnap.x() + imageSnap.width(), imageSnap.y()+ imageSnap.height() / 2, textNode.x(), textNode.y()];
        arrow._setAttr('points',p)
        layer.draw();
      });
      // image axis update to arrow
      imageSnap.on("dragmove", function () {
        var p: any = [imageSnap.x() + imageSnap.width(), imageSnap.y()+ imageSnap.height() / 2, textNode.x(), textNode.y()];
        arrow._setAttr('points',p)
        layer.draw();
      });

      imageSnap.on("pointerdown", () => {
        this.addResizer(layer, imageSnap);
      });
      textNode.on("dblclick dbltap", () => {
        // create textarea over canvas with absolute position

        // first we need to find position for textarea
        // how to find it?

        // at first lets find position of text node relative to the stage:
        var textPosition = textNode.absolutePosition();

        // then lets find position of stage container on the page:
        var stageBox = this._stageInstance.container().getBoundingClientRect();

        // so position of textarea will be the sum of positions above:
        var areaPosition = {
          x: stageBox.left + textPosition.x,
          y: stageBox.top + textPosition.y,
        };

        // create textarea and style it
        var textarea = document.createElement("textarea");
        document.body.appendChild(textarea);

        textarea.value = textNode.text();
        textarea.style.position = "absolute";
        textarea.style.top = areaPosition.y + "px";
        textarea.style.left = areaPosition.x + "px";
        textarea.style.width = textNode.getWidth();

        textarea.focus();

        textarea.addEventListener("keydown", function (e) {
          // hide on enter
          if (e.keyCode === 13) {
            textNode.text(textarea.value);
            document.body.removeChild(textarea);
          }
        });
        console.log(textNode);
      });
    };
    image.src = path;
    image.crossOrigin = "Anonymous";
  }

  private _loadDropedImage(file: File) {
    const image = new Image();
    image.onload = () => {
      const imageSnap = new Konva.Image({
        x: this._stageInstance!.children!.length * 20,
        y: this._stageInstance!.children!.length * 20,
        image,
        width: (image.width / this._parentWidth) * 100,
        height: (image.height / this._parentHeight) * 100,
        draggable: true,
      });
      const layer = new Konva.Layer();
      this.stageInstance.add(layer);
      layer.add(imageSnap);

      imageSnap.on("pointerdown", () => {
        const tr = this.addResizer(layer, imageSnap);
      });
    };
    const fr = new FileReader();
    fr.onload = () => {
      image.src = fr.result as string;
    };
    fr.readAsDataURL(file);
  }

  changeBaseImage(src: string) {
    const image = new Image();
    image.onload = () => {
      const imageSnap = new Konva.Image({
        x: this._stageInstance!.children!.length * 20,
        y: this._stageInstance!.children!.length * 20,
        image,
        width: image.width / 1.2,
        height: image.height / 1.2,
        draggable: false,
      });
      const layer = new Konva.Layer();
      this.stageInstance.add(layer);
      layer.add(imageSnap);

      imageSnap.on("pointerdown", () => {
        this.addResizer(layer, imageSnap);
      });
    };
    image.crossOrigin = "Anonymous";
    image.src = src;
  }

  removeResizer() {
    if (this._tr) {
      this._tr.remove();
      this._tr = null;
    }
  }

  deleteLayer() {
    this._tr?._nodes.forEach((n) => {
      n.removeEventListener("pointerdown");
      n.remove();
    });
    this.removeResizer();
  }

  addResizer(
    layer: Konva.Layer,
    node: Konva.Image | Konva.Rect | Konva.Circle | Konva.Group | Konva.Line
  ) {
    this.removeResizer();

    this._tr = new Konva.Transformer({
      nodes: [node],
      anchorDragBoundFunc: (oldPos: any, newPos: any, event: any) => {
        // oldPos - is old absolute position of the anchor
        // newPos - is a new (possible) absolute position of the anchor based on pointer position
        // it is possible that anchor will have a different absolute position after this function
        // because every anchor has its own limits on position, based on resizing logic

        // do not snap rotating point

        if (!this._tr) return;

        if (this._tr.getActiveAnchor() === "rotater") {
          return newPos;
        }

        const dist = Math.sqrt(
          Math.pow(newPos.x - oldPos.x, 2) + Math.pow(newPos.y - oldPos.y, 2)
        );

        // do not do any snapping with new absolute position (pointer position)
        // is too far away from old position
        if (dist > 10) {
          return newPos;
        }

        const closestX =
          Math.round(newPos.x / this._cellWidth) * this._cellWidth;
        const diffX = Math.abs(newPos.x - closestX);

        const closestY =
          Math.round(newPos.y / this._cellHeight) * this._cellHeight;
        const diffY = Math.abs(newPos.y - closestY);

        const snappedX = diffX < 10;
        const snappedY = diffY < 10;

        // a bit different snap strategies based on snap direction
        // we need to reuse old position for better UX
        if (snappedX && !snappedY) {
          return {
            x: closestX,
            y: oldPos.y,
          };
        } else if (snappedY && !snappedX) {
          return {
            x: oldPos.x,
            y: closestY,
          };
        } else if (snappedX && snappedY) {
          return {
            x: closestX,
            y: closestY,
          };
        }
        return newPos;
      },
    });
    layer.add(this._tr);
    return this._tr;
  }

  private _dragOverHandler(ev: Event) {
    ev.preventDefault();
  }

  GetBodyPart() {
    var url = "api/MasterData/GetBodyPart";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      BodyId: 0,
      ShowAll: 0,
      BranchId: this.BranchesId,
    });
  }

  getIndicators() {
    var url = "api/MasterData/GetSketchIndicators";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      IndicatorId: 0,
      ShowAll: 1,
      BranchId: this.BranchesId,
    });
  }

  saveSketch(formData) {
    var url = "api/Consultant/InsertConsultantSketch";
    return this.http
      .post<Response<any[]>>(`${this.baseUrl}${url}`, {
        Id: 0,
        BranchId: formData.branchId,
        Base64Img: formData.Base64Img,
        SketchName: formData.SketchName,
        BodyPartId: formData.BodyPartId,
        ConsultantId: formData.ConsultantId,
        PatientId: formData.PatientId,
      })
      .pipe(
        retry(1)
        //catchError(this.errorHandle.handleError)
      );
  }
  GetSketches(Id, BranchId, patientId) {
    var url = "api/Consultant/GetConsultantSketch";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        ConsultantId: Id,
        BranchId: BranchId,
        PatientId: patientId,
      })
      .pipe(retry(1));
  }
}
