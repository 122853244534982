import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Router } from "@angular/router";

@Component({
  selector: 'app-claim-action-btn',
  templateUrl: './claim-action-btn.component.html',
  styleUrls: ['./claim-action-btn.component.scss']
})
export class ClaimActionBtnComponent  implements ICellRendererAngularComp {
  public params: any;
  public pagetype: string;
  items: any[] = [];

  constructor(
    private router: Router
  ) { }

  agInit(params: any): void {

  }
  refresh(): boolean {
    return false;
  }

}
