import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EMRService } from '../../services/emr.service';
import { SharedService } from '../../../../shared/shared.service';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { BasicPatientInfo } from '../../interfaces/BasicPatientInfo';
import moment from 'moment';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
@Component({
  selector: 'app-popup-alert',
  templateUrl: './popup-alert.component.html',
  styleUrls: ['./popup-alert.component.scss']
})
export class PopupAlertComponent implements OnInit {
  // @Input() data;
  // @Input() type;
  // EMRPatientInfo: EMRPatientInfo;
  // BasicPatientInfo: BasicPatientInfo = new BasicPatientInfo();
  // ConsultationTime: FormGroup;
  // subscription: Subscription;
  // globals: globalvars = new globalvars();
  // BranchesId: any;
  // alertId: any;
  // firstDate: any;
  // isEdit:any = false;
  // secondDate: any;
  // public fileEmitter: EventEmitter<any> = new EventEmitter();
  constructor(
    // private EMRService: EMRService,
    // private toastrService: ToastrService,
    // private router: Router,
    // private modalService: BsModalService,
    // private datepipe: DatePipe,
    // private activeModal: BsModalRef,
    // private sharedservice: SharedService,
    // private _location: Location,
    // private configurationManager: ConfigurationManager,
    // private sanitizer: DomSanitizer,
  ) {
  // if(!(router.getCurrentNavigation().extras.state)){
  //   sessionStorage.removeItem("EMRPatientInfo")
  // }
    // this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
    //   if (this.sharedservice.checkglobals(glob)) {
    //     this.globals = glob;
    //     //this.globals.HospitalId
    //     this.BranchesId = this.globals.HospitalId;
    //   }

    // });  
  }

  ngOnInit(): void {
    // this.formCreate()
    // if(this.data?.alertId){
    //     this.isEdit = true;
    //     this.alertId = this.data?.alertId
    //     this.firstDate = this.data?.validFrom
    //     this.secondDate = this.data?.validTo
    //     this.ConsultationTime.get('StartTime').setValue(this.data?.validFrom)
    //     this.ConsultationTime.get('EndTime').setValue(this.data?.validTo)
    //     this.ConsultationTime.get('AlertType').setValue(this.data?.alertType)
    //     this.ConsultationTime.get('Details').setValue(this.data?.alertNote)
    //     this.ConsultationTime.get('IsActive').setValue(this.data?.isDisplayed)
    //   } else {
    //     this.isEdit = false;
    //     let date = new Date()
    //     let date1= moment(date).add(20, 'years').toDate();
    //     this.ConsultationTime.get('StartTime').setValue(date)
    //     this.ConsultationTime.get('EndTime').setValue(date1)
    //   }
  }
  // formCreate(){
  //   this.ConsultationTime = new FormGroup({
  //     StartTime: new FormControl('' , [Validators.required]),
  //     EndTime: new FormControl('' , [Validators.required]),
  //     Details: new FormControl(''),
  //     month: new FormControl(0),
  //     AlertType: new FormControl(''),
  //     IsActive: new FormControl(true),
  //   });
  // }
  // saveAlert(){
  //   if(this.ConsultationTime.get("StartTime").value === ""){
  //     this.toastrService.error("Please Choose Start Date");
  //   } else if(this.ConsultationTime.get("EndTime").value === ""){
  //     this.toastrService.error("Please Choose End Date");
  //   } else {
  //     let FirstDate;
  //     let SecondDate
  //     if(this.firstDate === this.ConsultationTime.get("StartTime").value) {
  //       FirstDate = this.ConsultationTime.get("StartTime").value
  //       FirstDate = FirstDate.replaceAll("/", "-");
  //     } else {
  //       FirstDate = this.datepipe.transform(this.ConsultationTime.get("StartTime").value, 'dd-MM-yyyy');
  //     }
  //     if(this.secondDate === this.ConsultationTime.get("EndTime").value) {
  //       SecondDate = this.ConsultationTime.get("EndTime").value
  //       SecondDate = SecondDate.replaceAll("/", "-");
  //     } else {
  //       SecondDate = this.datepipe.transform(this.ConsultationTime.get("EndTime").value, 'dd-MM-yyyy');
  //     }
  //     let data= {
  //       ValidFrom: FirstDate,
  //       ValidTo:SecondDate,
  //       AlertNote:this.ConsultationTime.get("Details").value !== null ? this.ConsultationTime.get("Details").value : '',
  //       AlertId:this.isEdit ? this.alertId :  0,
  //       PatientId:this.EMRPatientInfo.PatientId,
  //       AlertType: this.type,
  //       UserId:Number(localStorage.getItem('user_id')),
  //       BranchId:Number(this.globals.HospitalId),
  //       IsDeleted:0,
  //       IsDisplayed:this.ConsultationTime.get("IsActive").value ? this.ConsultationTime.get("IsActive").value : false
  //     }
  //     this.EMRService.addAlert(data).subscribe((res:any)=>{
  //       if(res.status === 200 && res.message == "Success"){
  //         this.toastrService.success("Success : Alert is added Successfully.");
  //         this.ConsultationTime.reset()
  //       } else {
  //         this.toastrService.error("Failed : " + res.message);
  //       }
  //     },(err:any)=>{
  //       this.toastrService.error(err?.error?.message);
  //     })
  //   }
  // }
  // datePicker(i:any){
  //   let date1= moment(i).add(10, 'month').toDate();; // March 28
  //   this.secondDate = this.datepipe.transform(date1, 'dd-MM-yyyy');
  //   this.ConsultationTime.get("EndTime").setValue(this.secondDate)
  // }
  // onMnthChange(i:any){
  //   let date1;
  //   if(i == '0') {
  //     date1= moment(this.ConsultationTime.get("StartTime").value).add(20, 'years').toDate(); // March 28
  //     this.secondDate = this.datepipe.transform(date1, 'dd-MM-yyyy');
  //     this.ConsultationTime.get("EndTime").setValue(this.secondDate)
  //   } else {
  //     date1= moment(this.ConsultationTime.get("StartTime").value).add(i, 'month').toDate(); // March 28
  //     this.secondDate = this.datepipe.transform(date1, 'dd-MM-yyyy');
  //     this.ConsultationTime.get("EndTime").setValue(this.secondDate)
  //   }
  // }
  // close(){
  //   this.fileEmitter.emit({
  //     status: true,
  //   });
  //   this.activeModal.hide();
  // }
}
