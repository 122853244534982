import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { ColDef } from 'ag-grid-community';
import { BillingService } from '../services/billing.service';
import { BillingGeneralActionComponent } from '../billing-general-action/billing-general-action.component';
import { Router } from '@angular/router';
import Swal from "sweetalert2";
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TransactionListModalComponent } from '../modals/transaction-list-modal/transaction-list-modal.component';
import { ConsultaionsService } from '../../client/services/consultations.service';
import { ConfigurationService } from '../../client/services/configuration.service';
@Component({
  selector: 'app-manage-credit',
  templateUrl: './manage-credit.component.html',
  styleUrls: ['./manage-credit.component.scss']
})
export class ManageCreditComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild(SearchPatientModalComponent)modaltemp: SearchPatientModalComponent;
  @ViewChild("transactionTemplate") modaltransaction: any;
  // @ViewChild("cancelTemplate") modalcancel: any;
  fromDate:string="";
  todate:string="";
  form: FormGroup;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: number;

  // AG GRID ITEMS

  public statusBar;
  rowGroupPanelShow = "always";
  public gridApi;
  columnDefs: ColDef[] = [];
  creditDetails:any[]=[];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules :{
      'cell-closed':params=> params.data.active == 0,
      "approve-green": params=> params.data.status == "A"
    }
  }
  public context;
  public frameworkComponents;
  public loadingTemplate;
  public noRowsTemplate;
  functionPermissionData: any;

  constructor(
    private datepipe: DatePipe,
    private sharedservice: SharedService,
    private billingService:BillingService,
    private router: Router,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private consultationService: ConsultaionsService,
    private ConfigurationService: ConfigurationService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {

    this.context = { componentParent: this };

    let dte = new Date();
    dte.setDate(dte.getDate() - 7);
    this.fromDate = this.datepipe.transform(dte, 'dd-MM-yyyy');;
    let currentappDate = new Date();
    this.todate = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');


    this.form = new FormGroup({
      FromDate: new FormControl(this.fromDate),
      ToDate: new FormControl(this.todate),
      RegNo: new FormControl(""),
      PatName: new FormControl(''),
      Active: new FormControl(''),
      BranchId: new FormControl(this.BranchesId),
      DisableDates: new FormControl(false),
    });

    this.columnDefs = [
      {
        headerName: "Open Date",
        field: "openDate",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Ref No",
        field: "refNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Reg.No.",
        field: "regNo",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Patient Name",
        field: "patientName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Insurance",
        field: "sponsorName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Copay Amt",
        field: "coPayPcnt",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Copay Per",
        field: "coPayPcnt",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Deb Amt",
        field: "coPayPcnt",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Deb Perc",
        field: "coPayPcnt",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "manageCredit" },
      },
    ];

    this.getCreditData();
    this.frameworkComponents = { buttonRender: BillingGeneralActionComponent};
    this.functionPermission();
  }

  showBasicDialog() {
    this.modaltemp.open();

  }

  searchevent(patient: any) {
    this.form.controls['RegNo'].setValue(patient.data.regNo);
    // this.patientId=patient.data.patientId;
    // this.PatientInformation=patient.data;
    // this.loadPreviousCredits();
    // this.getOpenCreditsByPatient();
  }
  GetCreditList(){

  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  getCreditData(){
    let input=this.form.getRawValue();
    if (input.FromDate && (typeof (input.FromDate) == "object")) {
      input.FromDate = this.datepipe.transform(new Date(input.FromDate), 'dd-MM-yyyy');
    }
    if (input.ToDate && (typeof (input.ToDate) == "object")) {
      input.ToDate = this.datepipe.transform(new Date(input.ToDate), 'dd-MM-yyyy');
    }
    input.Active = input.Active ? Number(input.Active) : -1;

    this.billingService.GetManageCreditForBilling(input).subscribe((res) => {
      this.creditDetails=res.response;
    });

  }

  editCredit(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 328)
    if (foundElement.id == 328 && foundElement.isAllowed == true) {
   let input={
       patientId:data.patientId,
       creditId:data.creditId,
       patientName:data.patientName,
       regNo:data.regNo
    }
    this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    } else {
      this.toastrService.warning("You are not allowed to edit data");
    }
  }

  closeCredit(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 329)
    if (foundElement.id == 329 && foundElement.isAllowed == true) {
    let initialState = {
      heading: 'Confirm Close Credit',
      content: `Are you sure that you want to close this credit with reference number ${data?.refNo}.?`,
      isReasonNeeded: false
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(ConfirmModalComponent,Object.assign( {}, config, { class: 'modal-sm', initialState }) );
    modalRef.content.confirmEmitter.subscribe(res=>{
      if(res.status){
        let input={
            Creditid: data.creditId,
            Active: 0,
            Branchid: this.BranchesId,
            Userid: Number(localStorage.getItem('user_id')),
            Sessionid: 0
        }
        this.billingService.CloseCredit(input).subscribe((res) => {
                if (res.status == 200 && res.message == "Credit Closed For the Patient") {
                    this.toastrService.success('Credit Closed For the Patient Successfully')
                    this.getCreditData();
                  }else{
                    this.toastrService.error(res.message)
                  }
      })
    }
  })
  } else {
    this.toastrService.warning("You are not allowed");
  }
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "To close this credit",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonText: "Yes, do it!",
    //   cancelButtonText: "No",
    // }).then((result) => {
    //   if (result.value) {

    //     this.billingService.CloseCredit(
    //       input
    //     ).subscribe((res) => {
    //       if (res.status == 200 && res.message == "Credit Closed For the Patient") {
    //         Swal.fire("Done!", "Credit Closed For the Patient", "success");
    //         this.getCreditData();
    //       }
    //       else if(res.status == 200){
    //         Swal.fire(
    //           "Error!",
    //           res.message,
    //           "error"
    //         );
    //       }
    //       else {
    //         Swal.fire(
    //           "Error!",
    //           "Some unexpected error occures! try again later.",
    //           "error"
    //         );
    //       }
    //     });
    //   }
    // });

  }

  viewTransaction(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 330)
    if (foundElement.id == 330 && foundElement.isAllowed == true) {
    let initialState = {
      patientId: Number(data.patientId),
      sponsorName: data.sponsorName,
      regNo: data.regNo,
      patientName: data.patientName,
      openDate: data.openDate,
      branchId: this.globals.HospitalId,
      creditId: data.creditId
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(TransactionListModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }
  //  cancelItem(data){
  //   this.modalRef= this.modalService.show(
  //      this.modalcancel, { class: "gray" })
  //  }

  setCreditAsDefault(data: any) {
    const foundElement = this.functionPermissionData.find(x => x.id === 331)
    if (foundElement.id == 331 && foundElement.isAllowed == true) {
    if (data.isExpired == true || data.active !== 1 || data.isDeleted == 1) {
      this.toastrService.warning('Credit expired: Cannot set as default');
    } else if (data.isExpired == false) {
      let payload = {
        CreditId: data.creditId,
        PatientId: data.patientId,
        BranchId: this.globals.HospitalId
      }
      this.consultationService
        .updateDefaultCredit(payload)
        .subscribe((res) => {
          if (res.status == 200) {
            this.getCreditData();
            this.toastrService.success("Credit saved as default");
          }
        });
    }
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  gotoOpenCredit() {
    const foundElement = this.functionPermissionData.find(x => x.id === 327)
    if (foundElement?.id == 327 && foundElement.isAllowed == true) {
      this.router.navigate(["/OpenCredit"]);
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  approveCredit(data: any) {
    // const foundElement = this.functionPermissionData.find(x => x.id === 331)
    // if (foundElement.id == 331 && foundElement.isAllowed == true) {
    if (data.isExpired == true || data.active !== 1 || data.isDeleted == 1) {
      this.toastrService.warning('Credit expired');
    } else {
      let payload = {
        CreditId: data.creditId,
        BranchId: this.globals.HospitalId,
        UserId: Number(localStorage.getItem('user_id')),
        Status: 'A'
      }
      this.billingService
        .ApproveCredit(payload)
        .subscribe((res) => {
          if (res.status == 200) {
            this.getCreditData();
            this.toastrService.success("Credit Approved");
          }
        });
    }
    // } else {
    //   this.toastrService.warning("You are not allowed");
    // }
  }

  onDisableConsultant(){
    if(this.form.get('DisableDates').value){
      this.form.get('FromDate').disable();
      this.form.get('ToDate').disable();
      this.form.patchValue({
        FromDate: '',
        ToDate: ''
      });
    }else{
      this.form.get('FromDate').enable();
      this.form.get('ToDate').enable();
      this.form.patchValue({
        FromDate: this.fromDate,
        ToDate: this.todate
      });
    }
  }
}
