import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { Response } from '../../../_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class LabService {
  baseUrl: any;

  constructor(
    private http: HttpClient,
    private configurationManager: ConfigurationManager,

  ) { 
    this.baseUrl = (this.configurationManager as any).baseUrl;

  }
  SearchConsultation(Input:any) {
    var url = "api/Lab/GetPatientListForInvestigation";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetservicePoin(Input:any) {
    var url = "api/MasterData/GetServicePointByLocation";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetServiceItem(Input:any) {
    var url = "api/Lab/GetTodaysServiceOrders";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  SearchTable(Input:any) {
    var url = "api/Lab/SearchInvestigation";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  pendingItem(Input:any) {
    var url = "api/Lab/GetNewServiceOrders";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  investigationItemData(Input:any) {
    var url = "api/Lab/GetItemForInvestigationTEST";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetItemForInvestigationTESTbyServicePoint(Input:any) {
    var url = "api/Lab/GetItemForInvestigationTESTbyServicePoint";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  SavwInvestigation(Input:any) {
    var url = "api/Lab/InsertUpdateInvestigation";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  cancelInvestigation(Input:any) {
    var url = "api/Lab/CancelInvestigation";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetLabResult(Input:any) {
    var url = "api/Report/GetLabResult";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  cancelResult(Input:any) {
    var url = "api/Lab/CancelResult";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  publishResult(Input:any) {
    var url = "api/Lab/PublishResult";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetnvestigationServiceItem(Input:any) {
    var url = "api/Lab/GetResultEntryGrid";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetSample(Input:any) {
    var url = "api/Lab/GetSampleOfPatient";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetSampleType(Input:any) {
    var url = "api/Lab/GetInvestigation";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  SaveRepeat(Input:any) {
    var url = "api/Lab/InsertInvestigationRepeat";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  ResultOnDate(Input:any) {
    var url = "api/Report/GetLabResultOnDate";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  testInitiated(Input:any) {
    var url = "api/Lab/AutoInitiate";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  getinternalLab(Input:any) {
    var url = "api/MasterData/GetTransferToDestinationDetail";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  labTransfer(Input:any) {
    var url = "api/Lab/SampleReferToBranch";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  getTransferPatientDetails(Input:any) {
    var url = "api/Lab/GetTransferedSample";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  getTrasferedSampleadetails(Input:any) {
    var url = "api/Lab/GetTransferedSampleDetail";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }

  GetLabPrintOrder(Input:any) {
    var url = "api/Report/GetLabPrintOrder";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetExportResult(Input:any) {
    var url = "api/Report/GetLabResultExport";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  GetResultAllDocument(Input:any) {
    var url = "api/Report/PrintWorklist";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }
  PrintMailResult(Input:any) {
    var url = "api/Report/SendLabResultMail";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }

  AutoInitiateInvestigation(Input:any) {
    var url = "api/Lab/AutoInitiateInvestigation";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }

  TestInitiateForMultipleItems(Input:any) {
    var url = "api/Lab/TestInitiateForMultipleItems";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }

}