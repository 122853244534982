export class WorkingDaysModel
{
scheMid:number;
consultantId:number;
branchId:number;
userId:number;
alldaySameFlag:boolean;
timeSchedules:WorkingDaysChildModel[];
}
export class WorkingDaysChildModel
{
    dayId:number;
    fromHour:number;
    fromMinute:number;
    fromAmPm:string;
    toHour:number;
    toMinute:number;
    toAmPm:string;
    fromTime:string;
    toTime:string;


}