import { Component, OnInit, TemplateRef, ViewChild,Input, OnDestroy } from '@angular/core';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from '../../emr/services/emr.service';
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ReviewOfSymptoms } from '../interfaces/ReviewOfSymptoms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';

@Component({
  selector: 'app-general-review-of-symptoms',
  templateUrl: './general-review-of-symptoms.component.html',
  styleUrls: ['./general-review-of-symptoms.component.scss']
})
export class GeneralReviewOfSymptomsComponent implements OnInit, OnDestroy {
  @Input() IsViewtab;
  EMRPatientInfo:EMRPatientInfo;

  form: FormGroup; // form variable
  ReviewOfSymptoms:ReviewOfSymptoms;
  ReviewOfSymptomsModalVal:ReviewOfSymptoms;
  ReviewOfSymptomsList:ReviewOfSymptoms[]=[];

  Loading:boolean=false;
  FormValueChangeChecker:boolean=false;
  modalRef?: BsModalRef;
  @ViewChild("template") modaltemp: any;
  private globals: globalvars = new globalvars();
  private subscription: Subscription;

  IsDisabled: boolean = true;
  constructor(
    private EMRService:EMRService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private sharedservice: SharedService,
  ) {
    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });
  }

  ngOnInit(): void {

    if (this.IsViewtab == true) {
      this.IsDisabled = false;
    } else if (this.IsViewtab == "undefined") {
      this.IsDisabled = true;
    }

      this.form=new FormGroup({
        sRMId: new FormControl(0),
        constitutional: new FormControl(""),
        respiratory: new FormControl(""),
        phychiatry: new FormControl(""),
        gastrointestinial: new FormControl(""),
        hemotology: new FormControl(""),
        neurological: new FormControl(""),
        skin: new FormControl(""),
        cardiovascular: new FormControl(""),
        endocrinal: new FormControl(""),
        genitourinary: new FormControl(""),
        ent: new FormControl(""),
        immunological: new FormControl(""),
        visitId: new FormControl(this.EMRPatientInfo.VisitId),
        userId: new FormControl(Number(localStorage.getItem('user_id'))),
      });

      this.GetReviewOfSymptoms();
      this.GetPrevReviewOfSymptoms();
  }
  SaveReviewOfSymptoms()
  {
    if(
      this. EMRPatientInfo.CrudType!=3 &&
      this.EMRPatientInfo.VisitId>0 && this.Loading && this.FormValueChangeChecker)
    {
      this.ReviewOfSymptoms=this.form.getRawValue();
      this.ReviewOfSymptoms.userId=Number(localStorage.getItem('user_id'));

      this.EMRService.SaveReviewOfSymptoms(
        this.ReviewOfSymptoms
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastr.success("Success: " + "Review Of Symptoms  Saved Successfully...");
          }
          else{
            Swal.fire(
              'Error!',
              'Some unexpected error occures! try again later.',
              'error'
            )
          }
        });
    }
    else{
        if(!this.FormValueChangeChecker){

          //this.toastr.warning("Info: " + "No changes detected in the entry");
        }
    }
  }

  GetReviewOfSymptoms()
  {
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };

    this.EMRService.GetReviewOfSymptoms(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.form.patchValue(response);
          //
          this.Loading=true;
          this.form.valueChanges.subscribe(x => {
            this.FormValueChangeChecker=true;
           })
        }
      });
  }
  GetPrevReviewOfSymptoms()
  {
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":0
    };
    this.EMRService.GetReviewOfSymptoms(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.ReviewOfSymptomsList=res.response;
        }
      });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  openModal(VisitId) {

    let input={
      "VisitId":VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };
    this.EMRService.GetReviewOfSymptoms(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.ReviewOfSymptomsModalVal=response;
          this.modalRef = this.modalService.show(
            this.modaltemp,
            {class: 'modal-lg modal-custom-style' }
          );
        }
      });


  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
