import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConsultationsList } from '../../../client/interfaces/ConsultationsList';
import { SharedService } from '../../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { DatePipe } from '@angular/common';
import { ConsultaionsService } from '../../../client/services/consultations.service';
import { Router } from '@angular/router';
import _ from 'lodash';

@Component({
  selector: 'app-apply-treatment-from-vaccine',
  templateUrl: './apply-treatment-from-vaccine.component.html',
  styleUrls: ['./apply-treatment-from-vaccine.component.scss']
})
export class ApplyTreatmentFromVaccineComponent implements OnInit {

  public frameworkComponents;
  public noRowsTemplate;
  public loadingTemplate;
  patientColumnDefs = [];
  public gridApi;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  rowData: ConsultationsList[] = [];
  subscription: Subscription;
  globals: globalvars = new globalvars();
  IsApplyTreatment:number;

  constructor(
    private modalRef: BsModalRef,
    private sharedservice: SharedService,
    private datePipe: DatePipe,
    private consultationsService: ConsultaionsService,
    private router: Router,
    ) {
      this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
        if (this.sharedservice.checkglobals(glob)) {
          this.globals = glob;
        }
      });
      this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
      this.noRowsTemplate = `"<span">no rows to show</span>"`;
     }

  ngOnInit(): void {
    this.patientColumnDefs = [
      {
        headerName: "Patient Name",
        field: "patientName",
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,  
      },
      {
        headerName: "Consultant Name",
        field: "consultantName",
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
      },
      {
        headerName: "Reg. No.",
        field: "regNo",
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
  
      },
      {
        headerName: "Sponsor",
        field: "sponsor",
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: false,
        flex: 2,
  
      },
    ]

    this.GetConsultations();
  }

  GetConsultations() {
    let CurrentDate = new Date();

    let BranchId = this.globals.HospitalId;
    let ConsultantDate = this.datePipe.transform(CurrentDate, "yyyy-MM-dd");
    let ConsultantId = 0;
    let DeptId = 0;
    let Status = '';
    let role = localStorage.getItem("user_role");
    console.log('role', role);

    this.consultationsService
      .GetConsultations(ConsultantDate, Status, ConsultantId, DeptId, BranchId)
      .subscribe((res) => {
        if(role == 'consultant'){
          this.rowData = _.filter(res.response, {consultantId: Number(localStorage.getItem("Id"))})
        } else{
        this.rowData = res.response;
        }
        // consultantId = 0;
      });
  }

  close(){
    this.modalRef.hide();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  applyTreatment(event){
    this.IsApplyTreatment=1
    this.modalRef.hide();
    this.router.navigate(['/Treatment_details'], { state: { id: event.data.patientId ,consultationId:event.data.consultationId,IsApplyTreatment:this.IsApplyTreatment, creditId: event.data.creditId } });
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

}
