import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfigurationService } from '../../../services/configuration.service';
import { globalvars } from '../../../../../shared/models/GlobalVars';
import { Observable, Observer, Subscription, noop, of } from 'rxjs';
import { SharedService } from '../../../../../shared/shared.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Response } from '../../../../../_Models/Response';
import * as _ from 'lodash';
import { ConsultantDeskService } from '../../../services/consultant.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationManager } from '../../../../../../assets/configuration/configuration-manager';

@Component({
  selector: 'app-add-disease-modal',
  templateUrl: './add-disease-modal.component.html',
  styleUrls: ['./add-disease-modal.component.scss']
})
export class AddDiseaseModalComponent implements OnInit {

  AddDiseaseForm: FormGroup;

  @Output() diseaseEmitter = new EventEmitter();

  symptomsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'symptomId',
    textField: 'symptomDesc',
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 20,
    allowSearchFilter: true,
  };

  signDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'descriptionData',
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 20,
    allowSearchFilter: true,
  };

  SymptomsList: any[];
  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;
  BranchId: any;
  SignList: any[];
  public suggestions$?: Observable<any[]>;
  private baseUrl: string = "";
  public submitted: boolean = false;
  Id: any;
  SaveDiseasesResponse: any;
  result: any;
  @Input() diseaseId;
  GetDiseaseDetails: any;
  GetDiseaseDetailSymptoms: any;
  GetDiseaseDetailSigns: any;
  symptomId: any[] = [];
  SignIdArray: any[] = [];

  constructor(public modalRef: BsModalRef,
    private ConfigurationService: ConfigurationService,
    private sharedservice: SharedService,
    private http: HttpClient,
    private ConsultantDeskService: ConsultantDeskService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private configurationManager: ConfigurationManager,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
        localStorage.setItem('BranchId', this.BranchesId);
      }
      this.BranchId = localStorage.getItem('BranchId');
    });
  }

  ngOnInit(): void {
    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.AddDiseaseForm = new FormGroup({
      DiseaseName: new FormControl('', [Validators.required]),
      symptomSelect: new FormControl([]),
      signSelect: new FormControl([]),
      labelDesc: new FormControl(''),
      labelId: new FormControl('', [Validators.required]),
    });

    this.Id = localStorage.getItem('Id');

    if (this.diseaseId != -1) {
      setTimeout(() => {
        this.GetDetailsFetching();
      }, 1000);
    }else{
      this.getSymptomsList();
      this.getSigns();
    }

    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.AddDiseaseForm.get('labelDesc').value);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<any[]>>(
              // `${this.baseUrl}api/EMR/SearchICD`,
              `${this.baseUrl}api/EMR/SearchICDLabel`,
              {
                LabelCode: "",
                LabelDesc: this.AddDiseaseForm.get('labelDesc').value,
                ConsultationId: 0,
                BranchId: this.globals.HospitalId
              }
            )
            .pipe(
              map((data) => _.map(data.response, (item) => {
                let newField = {
                  ...item,
                  itemDropdownData: item.labelCode + ' - ' + item.labelDesc
                  
                }
                return newField;
              }) || [] ),
              tap(
                () => noop,
                (err) => {

                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  get f() {
    return this.AddDiseaseForm.controls;
  }

  ClearForm() {
    this.AddDiseaseForm.reset();
    this.AddDiseaseForm.clearValidators();
  }

  close() {
    this.modalRef.hide();
  }

  getSymptomsList() {
    this.ConfigurationService.GetSymptom(this.globals.HospitalId, 1).subscribe((res) => {
      this.SymptomsList = res.response;
    });
  }

  getSigns() {
    this.ConfigurationService.GetSign(this.globals.HospitalId, 1).subscribe((res) => {
      this.SignList = res.response;
    });
  }

  onSelectICDLabel(icdInfo){
    this.AddDiseaseForm.patchValue({
      labelDesc: icdInfo.labelDesc,
      labelId: icdInfo.labelId
    })
  }

  InsertConsultantDiseases() {
    let diseaseName = this.AddDiseaseForm.get("DiseaseName").value;
    let icdLabel = this.AddDiseaseForm.get("labelId").value;
    let symptoms: any[] = [];
    let signs: any[] = [];
    _.forEach(this.AddDiseaseForm.get("symptomSelect").value, (selectedSymptom: any)=>{
      let index = _.findIndex(this.SymptomsList, {symptomId: selectedSymptom.symptomId})
      if(index >= 0){
        symptoms.push({
          SymptomId: this.SymptomsList[index].symptomId,
          DiseaseId: this.diseaseId > 0 ? this.diseaseId : 0,
          Since: 0,
          Duration: "",
          SymptomDesc: this.SymptomsList[index].symptomDesc
        })
      }
    });

    _.forEach(this.AddDiseaseForm.get("signSelect").value, (selectedSign: any)=>{
      let index = _.findIndex(this.SignList, {id: selectedSign.id})
      if(index >= 0){
        signs.push({
          SignId: this.SignList[index].id,
          DiseaseId: this.diseaseId > 0 ? this.diseaseId : 0,
          Since: 0,
          Duration: "",
          SignDesc: this.SignList[index].descriptionData
        })
      }
    });
    let DiseaseId = 0;
    if (this.diseaseId > 0) {
      DiseaseId = this.diseaseId;
    }
    this.submitted = true;

    if(this.AddDiseaseForm && this.AddDiseaseForm.valid ) {
      this.ConsultantDeskService.InsertSaveConsultantDiseases(diseaseName, this.Id, Number(icdLabel), symptoms, signs, DiseaseId).subscribe((res) => {
        this.SaveDiseasesResponse = res.response;
        this.result = res;
        {
          if (this.result.message == "Success") {
            this.ngxLoader.stop();
            if (DiseaseId == 0) {
              this.toastr.success("Diseases added successfully");
              this.submitted = false;
            }
            else {
              this.toastr.success("Diseases Updated successfully");
            }
            this.diseaseEmitter.emit({
              status: true
            });
            this.close();
          }
          else {
            this.ngxLoader.stop();
            this.toastr.error("Failure: " + this.result.message);
          }
          this.submitted = false;
        }
      });
    }
    // this.ClearForm();
  }

  GetDetailsFetching() {
    this.ConsultantDeskService.getDiseaseDetailsAdd(this.diseaseId, this.globals.HospitalId).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.GetDiseaseDetails = response.response;
          this.GetDiseaseDetailSymptoms = this.GetDiseaseDetails[0].symptoms;
          this.GetDiseaseDetailSymptoms?.forEach((a) => {
            if (a.selected == "true") {
              this.symptomId.push({
                symptomId: a.symptomId,
                symptomDesc: a.symptomDesc
              });
            }
          });

          this.GetDiseaseDetailSigns = this.GetDiseaseDetails[0].signs;
          this.GetDiseaseDetailSigns?.forEach((a) => {
            if (a.selected == "true") {
              this.SignIdArray.push({
                id: a.signId,
                descriptionData: a.signDesc
              });
            }
          });

          this.AddDiseaseForm.patchValue({
            DiseaseName: this.GetDiseaseDetails[0].diseaseDesc,
            labelId: this.GetDiseaseDetails[0].labelId,
            labelDesc: this.GetDiseaseDetails[0].labelDesc,
            symptomSelect: this.symptomId,
            signSelect: this.SignIdArray
          });
          this.getSymptomsList();
          this.getSigns();
        }else{
          this.toastr.error(response.errorMessage.message)
        }
      }
    })
  }

}
