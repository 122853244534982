import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DentalTreatmentPlanComponent } from '../dental-treatment-plan/dental-treatment-plan.component';
import { DentalExaminationComponent } from '../dental-examination/dental-examination.component';
import { debounce, map } from 'rxjs/operators';
import { Observable, fromEvent, merge, timer } from 'rxjs';
import { DentalEstimationComponent } from '../dental-estimation/dental-estimation.component';
import { Settings } from '../../settings/model/settings.model';

@Component({
  selector: 'app-consultation-dental',
  templateUrl: './consultation-dental.component.html',
  styleUrls: ['./consultation-dental.component.scss']
})
export class ConsultationDentalComponent implements OnInit {

  @Input() IsView;
  public SelectedMenu:string="";

  @ViewChild(DentalTreatmentPlanComponent ) TP: DentalTreatmentPlanComponent ;
  @ViewChild(DentalExaminationComponent ) DE: DentalExaminationComponent ;
  @ViewChild(DentalEstimationComponent) ES: DentalEstimationComponent;
  

  constructor() { }

  ngOnInit(): void {
     this.ChangeFrame('TP');
  this.bindKeypressEvent().subscribe(($event: KeyboardEvent) => this.onKeyPress($event));
}


onKeyPress($event: KeyboardEvent) {
  
  if(($event.altKey || $event.metaKey) && ($event.key == 't' || $event.key == 'T'))
  this.ChangeFrame('TP')

  if(($event.altKey || $event.metaKey) && ($event.key == 'e' || $event.key == 'E'))
  this.ChangeFrame('DE');

  if(($event.altKey || $event.metaKey) && ($event.key == 'h' || $event.key == 'H'))
  this.ChangeFrame('TH')

  if(($event.altKey || $event.metaKey) && ($event.key == 's' || $event.key == 'S'))
  this.ChangeFrame('ES')
}
private bindKeypressEvent(): Observable<KeyboardEvent> {
 
  const eventsType$ = [
      fromEvent(window, 'keypress'),
      fromEvent(window, 'keydown')
  ];
  // we merge all kind of event as one observable.
  return merge(...eventsType$)
      .pipe(
          // We prevent multiple next by wait 10ms before to next value.
          debounce(() => timer(10)),
          // We map answer to KeyboardEvent, typescript strong typing...
          map(state => (state as KeyboardEvent))
      );
}

ChangeFrame(tab:string)
{
  
  let activaeTab= this.SelectedMenu;
  switch(activaeTab)
  {
    case  "TP":
    {
      this.TP.SaveTreatMent();
      break;
    }
    case  "DE":
    {
       this.DE.SaveExamination();
      break;
    }
    case  "ES":
      {
         this.ES.SaveEstimation();
        break;
      }
    
   
  }

  this.SelectedMenu=tab;
}

}
