import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../../_Models/Response';
import { HttpClient } from '@angular/common/http';
import { ConfigurationManager } from '../../../assets/configuration/configuration-manager';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  baseUrl: any;

  constructor(
    private http: HttpClient,
    private configurationManager: ConfigurationManager,
  ) { 
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  GetMessageType(payload = 1): Observable<Response<any>>{
    var url = "api/Nabith/GetMessageType";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  GetConsultationForDateRange(payload): Observable<Response<any>>{
    var url = "api/Nabith/GetConsultationForDateRange";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  GetNabidhDetails(payload): Observable<Response<any>>{
    var url = "api/Nabith/GetNabidhDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  SendBulkDetailstoNabith(payload): Observable<Response<any>>{
    var url = "api/Nabith/SendBulkDetailstoNabith";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  MergePatient(payload): Observable<Response<any>>{
    var url = "api/Nabith/MergePatient";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }
}
