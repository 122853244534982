import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { noop, Observable, Observer, of, Subscription } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { Response } from "../../../_Models/Response";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { EMRPatientInfo, EMRSponsorInfo } from "../interfaces/EMRPatientInfo";
import { EMRService } from "../../emr/services/emr.service";
import { ToastrService } from "ngx-toastr";
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import Swal from "sweetalert2";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { ReportRequest } from "../interfaces/ReportRequest";
import { TreatmentPlanForm } from "../interfaces/TreatmentPlanForm";
import { ConfigurationService } from "../../client/services/configuration.service";
import { ServiceOrderForm } from "../interfaces/ServiceOrderForm";
import * as _ from "lodash";
import { ListToothModalComponent } from "../modals/list-tooth-modal/list-tooth-modal.component";
import { MarkOnTeethModalComponent } from "../modals/mark-on-teeth-modal/mark-on-teeth-modal.component";
import { ToothChartModalComponent } from "../modals/tooth-chart-modal/tooth-chart-modal.component";
import { DentalService } from "../services/dental.service";
import { DatePipe } from "@angular/common";
interface servicItems {
  itemId: number;
  itemCode: string;
  itemName: string;
}

@Component({
  selector: "app-dental-treatment-plan",
  templateUrl: "./dental-treatment-plan.component.html",
  styleUrls: ["./dental-treatment-plan.component.scss"],
})
export class DentalTreatmentPlanComponent implements OnInit {

  public treatmentLoading: boolean = false;
  public formValueChangeChecker: boolean = false;
  
  lefttop = [1, 2, 3, 4, 5, 6, 7, 8];

  CPTModifiers: any[] = [];
  showTeethSystem: boolean = false;
  EMRPatientInfo: EMRPatientInfo;
  search?: string;
  suggestions$?: Observable<servicItems[]>;
  errorMessage?: string;
  private baseUrl: string = "";
  selectedRow: any;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;

  TreatmentPlanForm: TreatmentPlanForm;
  PrevTreatmentPlanForm: TreatmentPlanForm[] = [];
  form: FormGroup; // form variable
  ModalDrugs: any[] = [];

  public teethMapDetails: any[] = [];
  public allTreatmentDetails: any[] = [];
  Instructor: any[] = [];

  Request: ReportRequest = new ReportRequest();
  modalRef?: BsModalRef;
  @ViewChild("template") modaltemp: any;
  PrevServiceItems: ServiceOrderForm[] = [];
  showInstructor: any;
  instructorList: any;
  EMRSponsorInfo:EMRSponsorInfo;
  surfaceItem: any[] = [
    { name: 'Labial', code: 'Labial' },
    { name: 'Lingual', code: 'Lingual' },
    { name: 'Mesial', code: 'Mesial' },
    { name: 'Distal', code: 'Distal' },
    { name: 'Occlusal', code: 'Occlusal' },
    { name: 'Lervical', code: 'Lervical' },
    { name: 'Inscisal', code: 'Inscisal' },
  ];

  constructor(
    private http: HttpClient,
    private sharedservice: SharedService,
    private EMRService: EMRService,
    private toastr: ToastrService,
    private configurationManager: ConfigurationManager,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,
    private dentalService: DentalService,
    private datepipe: DatePipe,
  ) {
    this.showInstructor = localStorage.getItem('isAprovedAuthority');
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.EMRSponsorInfo = this.EMRService.GetEMRSponsorInfo();
  }

  ngOnInit(): void {
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.search);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<servicItems[]>>(
              `${this.baseUrl}api/EMR/GetEMRServiceItem`,
              {
                ServiceName: query,
                branchId: this.BranchesId,
                GroupId: 0,
                consultantId: 0,
                GroupCode: "114",
                CurrentDate: this.datepipe.transform(new Date(), "dd-MM-yyyy"),
                CreditId: this.EMRSponsorInfo.CreditId,
                Category: 'N'
              }
            )
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  this.errorMessage =
                    (err && err.message) || "Something goes wrong";
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */

    //  this.form=new FormGroup({
    //   procedureDetails: new FormArray([]),
    //   planDescription:new FormControl(''),
    //   visitId: new FormControl(this.EMRPatientInfo.VisitId),
    //   userId: new FormControl(Number(localStorage.getItem('user_id'))),
    // });

    this.form = new FormGroup({
      itemDetails: new FormArray([]),
      typeOfOrder: new FormControl('D'),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      branchId: new FormControl(this.BranchesId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
    });

    this.GetAllCPTModifier();
    this.GetEMRServiceItems();
    this.GetAuthorisedConsultant();
    //this.GetPrevServiceItems();

    // this.GetPrevDrugsEMR();
  }

  // SaveTreatMent(){
  //   if(this.EMRPatientInfo.VisitId>0)
  //   {
  //     this.TreatmentPlanForm=this.form.getRawValue();
  //     this.TreatmentPlanForm.userId=Number(localStorage.getItem('user_id'));

  //     this.EMRService.SaveTreatmentPlan(
  //       this.TreatmentPlanForm
  //     ).subscribe(
  //       (res) => {
  //         if (res.status == 200 && res.message == "Success") {
  //           this.toastr.success("Success: " + "Treatment Plan Details Saved Successfully...");
  //           this.GetDentalProcedureEMR();
  //         }
  //         else{
  //           Swal.fire(
  //             'Error!',
  //             'Some unexpected error occures! try again later.',
  //             'error'
  //           )
  //         }
  //       });

  //   }
  // }

  SaveTreatMent() {
    if(this.formValueChangeChecker){
      // this.markingSaveLoading = true;
      let payload = this.form.getRawValue();
      if(payload.itemDetails){
        payload.itemDetails.forEach((res:any,index:number)=>{
          let val = []
          if(res?.notes == null) {
            res.notes = ''
          }
          if(res?.surface?.length){
            res?.surface.forEach((i:any)=>{
              val.push(i.name)
            })
          }
          if(val?.length){
            res.surface = val.toString()
          } else {
            res.surface = ''
          }
        })
      }
      _.forEach(payload.itemDetails, (item: any)=>{
        item.cptModifierId = item.cptModifierId ? Number(item.cptModifierId) : 0;
      })
    
      _.forEach(payload.itemDetails, (item: any)=>{
        item.Instructor = item.Instructor ? Number(item.Instructor) : 0;
      })
    
      payload.userId = Number(localStorage.getItem("user_id"));
      payload.branchId = Number(this.BranchesId);
      payload.typeOfOrder='D';
      payload.visitId = this.EMRPatientInfo.VisitId;
    
      this.dentalService.InsertEstimateItemsEMR(payload).subscribe({
        next: (response: Response<any>)=>{
          if (response.status == 200 && response.message == "Success") {
            this.toastr.success(
              "Success: " + "Treatment items Saved Successfully..."
            );
            this.GetEMRServiceItems();
            // this.saveTreatmentDetailsEmitter.emit({status: true});
            // this.close();
          } else {
            Swal.fire(
              "Error!",
              "Some unexpected error occures! try again later.",
              "error"
            );
          }
        this.formValueChangeChecker = false;
          // this.markingSaveLoading = false;
        },error: (error: any)=>{
          this.toastr.error(
            "Somthing wents wrong, Try again..!"
          );
          // this.markingSaveLoading = false;
        }
      })
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  LoadItems(item) {
    this.addMapFormGroup(item, 0, 'load');
  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    // if(!data["approvalStatus"]) data["approvalStatus"]=0;
    // if(!data["billingMode"]) data["billingMode"]=0;
    // if(!data["isCompleted"]) data["isCompleted"]=0;
    // if(!data["approvalNumber"]) data["approvalNumber"]="";
    // if(!data["id"]) data["id"]="";

    // return new FormGroup({
    //   id:new FormControl(data["id"]),
    //   itemId: new FormControl(data["itemId"]),
    //   teeths	: new FormControl(data["teeths"]),
    //   qty	: new FormControl(data["qty"]),
    //   notes: new FormControl(data["notes"]),
    //   approvalStatus: new FormControl(data["approvalStatus"]),
    //   approvalNumber: new FormControl(data["approvalNumber"]),
    //   billingMode: new FormControl(data["billingMode"]),
    //   itemName: new FormControl(data["itemName"]),
    //   isCompleted:new FormControl(data["isCompleted"]),
    // });

    if (saved == 0) {
      if (!data["qty"]) data["qty"] = 1;
    }

    let temName = data["itemName"] + " - " + data["itemCode"];
    let cptModifierId = 0;
    let val = []
    if(data["surface"] != ""){
      let i = data["surface"]?.split(",");
      i?.forEach((res:any)=>{
        val.push({name :res, code:res})
      })
    }
    if (data["cptModifierId"]) cptModifierId = data["cptModifierId"];
    return new FormGroup({
      itemId: new FormControl(data["itemId"]),
      itemName: new FormControl(temName),
      teeths: new FormControl(data["teeths"]),
      notes: new FormControl(data["notes"]),
      cptModifierId: new FormControl(cptModifierId),
      qty: new FormControl(data["qty"]),
      surface: new FormControl(val.length ? val :[]),
      Rate: new FormControl(data["rate"]),
      Instructor: new FormControl(data["instructor"] ? data['instructor'] : 0),
      ConfirmedServiceOrder: new FormControl(data["confirmedServiceOrder"]),
      insCovered: new FormControl(data["insCovered"])
    });
  }

  public addMapFormGroup(data: any[] = [], saved = 0, action) {
    const ServiceItems = this.form.get("itemDetails") as FormArray;
    if(action == 'get') {
      ServiceItems.clear();
    }
    const values = Object.keys(data).map((key) => data[key]);

    if (data.length > 0) {
      data.forEach((element, i) => {
        let filteredArray = ServiceItems.value.filter(
          (value) => value.itemId == element.itemId
          // (value) => (value.itemId == element.itemId) && (value.teeths == element.teeths)
        );
        ServiceItems.push(this.createListGroup(element, saved));
        
      });
    } else if (values.length > 0) {
      let filteredArray = ServiceItems.value.filter(
        (value) => value.itemId == values[0]
      );
      ServiceItems.push(this.createListGroup(data, saved));
      
    }
    // else if(data){

    //  ServiceItems.push(this.createListGroup(data, saved));
    // }
    this.search = "";
  }

  GetDentalProcedureEMR() {
    let input = {
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 1,
    };

    this.treatmentLoading = true;
    this.EMRService.GetDentalProcedureEMR(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];
        if (response) {
          if (response.procedureDetails) {
            this.addMapFormGroup(response.procedureDetails, 0, 'procedure');
          }
        }
        this.treatmentLoading = false;
      }
    });
  }
  GetPrevDrugsEMR() {
    // let input={
    //   "VisitId":this.EMRPatientInfo.VisitId,
    //   "PatientId":this.EMRPatientInfo.PatientId,
    //   "ShowAll":0
    // };
    // this.EMRService.GetDrugsEMR(input).subscribe(
    //   (res) => {
    //     if(res.status==200)
    //     {
    //       this.PrevDrugsHistory=res.response;
    //     }
    //   });
  }

  showTeeth() {}

  changeColor(teethId, sub_count) {
    let elid = "teeth" + teethId;

    for (let i = 1; i <= sub_count; i++) {
      let subeid = elid + "_" + i;
      let element = document.getElementById(subeid);

      if (element.classList.contains("markedTeeth")) {
        element.classList.remove("markedTeeth");
      } else {
        element.classList.add("markedTeeth");
      }
    }
  }
  SetColor(teethId, universal = 0, fds = 0) {
    alert(teethId);
    let elid = "teeth_" + teethId;
    let element = document.getElementById(elid);

    if (element.classList.contains("markedTeeth")) {
      element.classList.remove("markedTeeth");
      element.style.fill = "rgba(255, 255, 255, 0)";
    } else {
      element.classList.add("markedTeeth");
      element.style.fill = "blue";
    }
  }

  compleProcedure(i) {
    const procedureDetails = this.form.controls["procedureDetails"].value;
    let selectedProcedure = procedureDetails[i];
    if (selectedProcedure.isCompleted == 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "To mark this procedure as finished",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, do it!",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          selectedProcedure.isCompleted = 1;
          this.EMRService.CompleteDentalProcedureEMR(
            selectedProcedure
          ).subscribe((res) => {
            if (res.status == 200 && res.message == "Success") {
              Swal.fire("Done!", "Status updated successfully.", "success");
              this.GetDentalProcedureEMR();
            } else {
              Swal.fire(
                "Error!",
                "Some unexpected error occures! try again later.",
                "error"
              );
            }
          });
        }
      });
    } else {
      this.toastr.error(
        "Error: " + "This procedure already marked as completed"
      );
    }
  }

  GetAllCPTModifier() {
    this.ConfigurationService.GetCPTModifier(
      this.globals.HospitalId,
      0
    ).subscribe((res) => {
      this.CPTModifiers = res.response;
    });
  }

  RemoveFromServiceList(index) {
    var Value = new FormArray([]);
    Value = this.form.get("itemDetails") as FormArray;
    Value.removeAt(index);
    this.formValueChangeChecker = true;
  }
  RemoveFromSelect(event) {
    var EventValue = new FormArray([]);
    EventValue = this.form.get("itemDetails") as FormArray;
    EventValue.removeAt(
      EventValue.value.findIndex((element) => element.itemId === event.itemId)
    );
  }

  GetEMRServiceItems() {
    let input = {
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 1,
      BranchId: this.BranchesId,
      EstimateId: 0,
      CreditId: this.EMRSponsorInfo.CreditId
    };

    this.dentalService.GetDentalEstimateItemsEMR(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];

        if (response) {
          if (response.itemDetails) {
            this.allTreatmentDetails = response.itemDetails;
            console.log('itemDetails', response.itemDetails);
            this.allTreatmentDetails?.forEach((a) => {
              this.Instructor = [];
              // if (a.selected == "true") {
                this.Instructor.push({
                  consultantId: a.instructor,
                  consultantName: a.instructorName
                });
              // }
              a.Instructor = this.Instructor;
            });
            console.log('this.allTreatmentDetails', this.allTreatmentDetails);
            this.addMapFormGroup(response.itemDetails, 1, 'get');
          }
        }
        this.form.valueChanges.subscribe((x) => {
          //  this.FormValueChangeChecker = true;
        });
      }
    });
  }
  GetPrevServiceItems() {
    let input = {
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ShowAll: 0,
    };

    this.EMRService.GetServiceItemsEMR(input).subscribe((res) => {
      if (res.status == 200) {
        this.PrevServiceItems = res.response;
      }
    });
  }

  markteeth(teethNo) {
    let teethId = teethNo;
    let eid = "teeth_" + teethId;
    const item = document.getElementById(eid);

    let existvlues = this.selectedRow.value.teeths;

    if (item.classList.contains("teethBorder")) {
      item.classList.remove("teethBorder");

      var revalue = teethNo + ",";
      var newval = existvlues.replace(revalue, "");
      this.selectedRow.get("teeths").setValue(newval);
    } else {
      item.classList.add("teethBorder");
      if (existvlues != "") {
        var totvalue = existvlues + teethNo + ",";
      } else {
        var totvalue = teethNo + ",";
      }

      this.selectedRow.get("teeths").setValue(totvalue);
    }
  }
  dispTeeth(selectedRow) {
    this.showTeethSystem = true;
    //unselect all tetthsbefore selecting

    if(this.selectedRow){
      for (let x = 1; x <= 32; x++) {
        let eid = "teeth_" + x;
        const item = document.getElementById(eid);
        item.classList.remove("teethBorder");
      }

    }

    // console.log(selectedRow)
    this.selectedRow = selectedRow;


    //   const procedureDetails = this.form.controls['itemDetails'].value;
    //   let selectedProcedure=procedureDetails[i]; selectedRow.value.teeths
    let teeths = selectedRow.value.teeths;
    if (teeths) {
      let teethList = teeths.split(",");
      for (let i = 0; i < teethList.length; i++) {
        let eid = "teeth_" + teethList[i];
        const item = document.getElementById(eid);
        if(item){
          item.classList.add("teethBorder");
        }

      }
    }
  }

  onTreatmentDetailsChanged(event){
    if(event.status){
      const ServiceItems = this.form.get("itemDetails") as FormArray;
      ServiceItems.clear();
      this.GetEMRServiceItems();
    }
  }

  GetAuthorisedConsultant() {
    let payload = {
      ConsultantId: 0,
      BranchId: this.BranchesId
    }
    this.ConfigurationService.GetAuthorisedConsultant(payload).subscribe((res) => {
      this.instructorList = res.response;
    })
  }

  multiselectSurface(data:any,index:number){
    let val = [];
    if(data.value?.length){
      data.value.forEach((res:any)=>{
        val.push(res.name)
      })
    }
    let itemDtl = this.form.get('itemDetails')['controls'][index]
    itemDtl.get('surface').setValue(val)
  }

  ////////////////test ////////////////////
}
