



    export const emotionEffectsData : any[]  = [
        {
            text: 'Anger',
            value: 'Anger'
        },
        {
            text: 'Bad News',
            value: 'Bad News'
        },
        {
            text: 'Disappointment',
            value: 'Disappointment'
        },
        {
            text: 'Vaccination',
            value: 'Vaccination'
        },
        {
            text: 'Fear / Fright',
            value: 'Fear / Fright'
        },
        {
            text: 'Grief / Sorrow',
            value: 'Grief / Sorrow'
        },
        {
            text: 'Jealousy',
            value: 'Jealousy'
        },
        {
            text: 'Excessive Joy',
            value: 'Excessive Joy'
        },
    ]


    export const fearsData : any[]  = [
        {
            text: 'Dark',
            value: 'Dark'
        },
        {
            text: 'Ghosts',
            value: 'Ghosts'
        },
        {
            text: 'Touch',
            value: 'Touch'
        },
        {
            text: 'Stage',
            value: 'Stage'
        },
        {
            text: 'Motion',
            value: 'Motion'
        },
        {
            text: 'Death',
            value: 'Death'
        },
        {
            text: 'Diseases',
            value: 'Diseases'
        },
        {
            text: 'Noise',
            value: 'Noise'
        },
        {
            text: 'People',
            value: 'People'
        },
        {
            text: 'Water',
            value: 'Water'
        },
        {
            text: 'Pointed Object',
            value: 'Pointed Object'
        },
        {
            text: 'Solitude',
            value: 'Solitude'
        },
        {
            text: 'Downward motion / Falling',
            value: 'Downward motion / Falling'
        },
    ]

    export const hallucinationData : any[]  = [
        {
            text: 'Auditory',
            value: 'Auditory'
        },
        {
            text: 'Olfactory',
            value: 'Olfactory'
        },
        {
            text: 'Tactile',
            value: 'Tactile'
        },
        {
            text: 'Visual',
            value: 'Visual'
        },
    ]


    export const maniaData : any[]  = [
        {
            text: 'Nymphomania',
            value: 'Nymphomania'
        },
        {
            text: 'Lypemaniac',
            value: 'Lypemaniac'
        },
        {
            text: 'Kleptomania',
            value: 'Kleptomania'
        }
    ]


    export const memoryData : any[]  = [
        {
            text: 'Forget fullness',
            value: 'Forget fullness'
        },
        {
            text: 'Difficulty in fixing attention',
            value: 'Difficulty in fixing attention'
        },
        {
            text: 'Unable to think',
            value: 'Unable to think'
        }
    ]


    export const mindData : any[]  = [
        {
            text: 'Absence',
            value: 'Absence'
        },
        {
            text: 'Cloudiness / Confusion',
            value: 'Cloudiness / Confusion'
        },
        {
            text: 'Excitement',
            value: 'Excitement'
        }
    ];


    export const moodDispositionData : any[]  = [
        {
            text: 'Anxious',
            value: 'Anxious'
        },
        {
            text: 'Fixed ideas',
            value: 'Fixed ideas'
        },
        {
            text: 'Apathetic',
            value: 'Apathetic'
        },
        {
            text: 'Cleanliness',
            value: 'Cleanliness'
        },
        {
            text: 'Aversion to work',
            value: 'Aversion to work'
        },
        {
            text: 'Aggravation',
            value: 'Aggravation'
        },
        {
            text: 'Timid',
            value: 'Timid'
        },
        {
            text: 'Beating',
            value: 'Beating'
        },
        {
            text: 'Complaining',
            value: 'Complaining'
        },
        {
            text: 'Biting',
            value: 'Biting'
        },
        {
            text: 'Fault finding',
            value: 'Fault finding'
        },
        {
            text: 'Hyperactive',
            value: 'Hyperactive'
        },
        {
            text: 'Fretful/ Cross',
            value: 'Fretful/ Cross'
        },
        {
            text: 'Punctual',
            value: 'Punctual'
        },
        {
            text: 'Diligent/ Non-Diligent',
            value: 'Diligent/ Non-Diligent'
        },
        {
            text: 'Irritable',
            value: 'Irritable'
        },
        {
            text: 'Lazy',
            value: 'Lazy'
        },
        {
            text: 'Quarrelsome',
            value: 'Quarrelsome'
        },
        {
            text: 'Throwing object',
            value: 'Throwing object'
        },
        {
            text: 'Hypersensitive',
            value: 'Hypersensitive'
        },
        {
            text: 'Disobedient',
            value: 'Disobedient'
        },
        {
            text: 'Changeable',
            value: 'Changeable'
        },
        {
            text: 'Extrovert',
            value: 'Extrovert'
        },
        {
            text: 'Impatient',
            value: 'Impatient'
        },
        {
            text: 'Introverted',
            value: 'Introverted'
        },
        {
            text: 'Indolent',
            value: 'Indolent'
        },
        {
            text: 'Hurry',
            value: 'Hurry'
        },
        {
            text: 'Jealous',
            value: 'Jealous'
        },
        {
            text: 'Melancholic',
            value: 'Melancholic'
        },
        {
            text: 'Laughing',
            value: 'Laughing'
        },
        {
            text: 'Violent',
            value: 'Violent'
        },
        {
            text: 'Nervous',
            value: 'Nervous'
        },
        {
            text: 'Fastidious',
            value: 'Fastidious'
        },
        {
            text: 'Restless',
            value: 'Restless'
        },
        {
            text: 'Perfectionist',
            value: 'Perfectionist'
        },
        {
            text: 'Mild gentle yielding',
            value: 'Mild gentle yielding'
        },
        {
            text: 'Destructive',
            value: 'Destructive'
        },
        {
            text: 'Sad/ Weeping',
            value: 'Sad/ Weeping'
        },
        {
            text: 'Stupid',
            value: 'Stupid'
        },
        {
            text: 'Suicidal',
            value: 'Suicidal'
        },
        {
            text: 'Weeping',
            value: 'Weeping'
        },
    ];

    export const speechData : any[]  = [
        {
            text: 'Hurried',
            value: 'Hurried'
        },
        {
            text: 'Lost',
            value: 'Lost'
        },
        {
            text: 'Nasal',
            value: 'Nasal'
        },
        {
            text: 'Slow',
            value: 'Slow'
        },
        {
            text: 'Stammering',
            value: 'Stammering'
        }
    ];


    export const nailsData : any[]  = [
        {
            text: 'Bitten',
            value: 'Bitten'
        },
        {
            text: 'Deformed',
            value: 'Deformed'
        },
        {
            text: 'Broken',
            value: 'Broken'
        },
        {
            text: 'Splitting’s',
            value: 'Splitting’s'
        }
    ];


    export const builtData : any[]  = [
        {
            text: 'Lean',
            value: 'Lean'
        },
        {
            text: 'Healthy',
            value: 'Healthy'
        },
        {
            text: 'Obese',
            value: 'Obese'
        }
    ];

    export const hairData : any[]  = [
        {
            text: 'Loss',
            value: 'Loss'
        },
        {
            text: 'Split',
            value: 'Split'
        },
        {
            text: 'Dry',
            value: 'Dry'
        },
        {
            text: 'Damaged',
            value: 'Damaged'
        },
        {
            text: 'Oily',
            value: 'Oily'
        },
        {
            text: 'Patch',
            value: 'Patch'
        },
        {
            text: 'Breaking',
            value: 'Breaking'
        }
    ];


    export const eruptionsData : any[]  = [
        {
            text: 'Urticaria',
            value: 'Urticaria'
        },
        {
            text: 'Acne',
            value: 'Acne'
        },
        {
            text: 'Psoriasis',
            value: 'Psoriasis'
        },
        {
            text: 'Eczema',
            value: 'Eczema'
        },
        {
            text: 'Lichen',
            value: 'Lichen'
        },
        {
            text: 'Boils',
            value: 'Boils'
        },
        {
            text: 'Warts',
            value: 'Warts'
        },
        {
            text: 'Viral warts',
            value: 'Viral warts'
        },
        {
            text: 'Cysts',
            value: 'Cysts'
        },
        {
            text: 'Skin Tags',
            value: 'Skin Tags'
        },
        {
            text: 'Pustules',
            value: 'Pustules'
        },
        {
            text: 'Scaling',
            value: 'Scaling'
        }
    ];


    export const perspirationPartialData : any[]  = [
        {
            text: 'Face',
            value: 'Face'
        },
        {
            text: 'Neck',
            value: 'Neck'
        },
        {
            text: 'Underarms',
            value: 'Underarms'
        },
        {
            text: 'Hands',
            value: 'Hands'
        },
        {
            text: 'Feet’s',
            value: 'Feet’s'
        },
        {
            text: 'Chest',
            value: 'Chest'
        },
        {
            text: 'Back',
            value: 'Back'
        },
        {
            text: 'Head',
            value: 'Head'
        }
    ];

    export const toungueData : any[]  = [
        {
            text: 'Mapped',
            value: 'Mapped'
        },
        {
            text: 'Discoloration',
            value: 'Discoloration'
        },
        {
            text: 'White Coated',
            value: 'White Coated'
        },
        {
            text: 'Yellow Coated',
            value: 'Yellow Coated'
        },
        {
            text: 'Strawberry',
            value: 'Strawberry'
        },
        {
            text: 'Red',
            value: 'Red'
        }
    ];

    export const stoolData : any[]  = [
        {
            text: 'Frequency',
            value: 'Frequency'
        },
        {
            text: 'Colour',
            value: 'Colour'
        },
        {
            text: 'Odour',
            value: 'Odour'
        },
    ];

    export const urineData : any[]  = [
        {
            text: 'Diarrhea',
            value: 'Diarrhea'
        },
        {
            text: 'Constipation',
            value: 'Constipation'
        },
        {
            text: 'Frequency',
            value: 'Frequency'
        },
        {
            text: 'Colour',
            value: 'Colour'
        },
        {
            text: 'Pain',
            value: 'Pain'
        },
        {
            text: 'Burning',
            value: 'Burning'
        },
        {
            text: 'Smell',
            value: 'Smell'
        },
    ];

    export const mensesData : any[]  = [
        {
            text: 'Dysmenorrhea',
            value: 'Dysmenorrhea'
        },
        {
            text: 'Amenorrhea',
            value: 'Amenorrhea'
        }
    ];

    export const deliveryData : any[]  = [
        {
            text: 'Caesarian',
            value: 'Caesarian'
        },
        {
            text: 'Normal',
            value: 'Normal'
        }
    ];

    export const abortionData : any[]  = [
        {
            text: 'Yes',
            value: 'Yes'
        },
        {
            text: 'No',
            value: 'No'
        }
    ];

    export const thermalData : any[]  = [
        {
            text: 'Hot',
            value: 'Hot'
        },
        {
            text: 'Ambi Hot',
            value: 'Ambi Hot'
        },
        {
            text: 'Cold',
            value: 'Cold'
        },
        {
            text: 'Ambi Cold',
            value: 'Ambi Cold'
        }
    ];

    export const sleepData : any[]  = [
        {
            text: 'Sleepiness',
            value: 'Sleepiness'
        },
        {
            text: 'Sleeplessness',
            value: 'Sleeplessness'
        },
        {
            text: 'Not feeling Fresh',
            value: 'Not feeling Fresh'
        },
        {
            text: 'Dream',
            value: 'Dream'
        }
    ];