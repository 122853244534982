import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NabithRoutingModule } from './nabith-routing.module';
import { NabithComponent } from './nabith.component';
import { NabitIntegrationComponent } from './nabit-integration/nabit-integration.component';
import { SharedModule } from '../../shared/models/shared.module';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  declarations: [
    NabithComponent,
    NabitIntegrationComponent
  ],
  imports: [
    CommonModule,
    NabithRoutingModule,
    SharedModule,
    AgGridModule.withComponents(),

  ]
})
export class NabithModule { }
