import { Component, OnInit, TemplateRef, ViewChild,Input, OnDestroy } from '@angular/core';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { EMRService } from '../../emr/services/emr.service';
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MedicalDecition } from '../interfaces/MedicalDecition';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard/dashboard.service';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';

@Component({
  selector: 'app-general-medical-decision',
  templateUrl: './general-medical-decision.component.html',
  styleUrls: ['./general-medical-decision.component.scss']
})
export class GeneralMedicalDecisionComponent implements OnInit, OnDestroy {
  @Input() IsViewtab;
  EMRPatientInfo:EMRPatientInfo;
  form: FormGroup; // form variable
  MedicalDecisions:MedicalDecition;
  MedicalDecisionsModalVal:MedicalDecition;
  PrevMedicalDecisions:MedicalDecition[]=[];
  Loading:boolean=false;
  modalRef?: BsModalRef;
  FormValueChangeChecker:boolean=false;
  IsDisabled: boolean = true;
  private subscription: Subscription;
  private globals: globalvars = new globalvars();
  internalConsultantlistData: any=[];
  externalConsultantlistData: any=[];

  @ViewChild("template") modaltemp: any;
  referredToList: any[];

  constructor(
    private EMRService:EMRService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private DashboardService:DashboardService,
    private sharedservice:SharedService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
      }
    });
    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();
    this.GetMedicalDecision();
    this.GetPrevMedicalDecision();
   }

  ngOnInit(): void {

    this.GetExternalConsultants()
    this.GetInternalConsultants()
    

    if (this.IsViewtab == true) {
      this.IsDisabled = false;
    } else if (this.IsViewtab == "undefined") {
      this.IsDisabled = true;
    }

    this.form=new FormGroup({
      mDId: new FormControl(0),
      labOrder: new FormControl(""),
      radiologyOrder: new FormControl(""),
      treatmentOrder: new FormControl(""),
      oldMedicalRecord: new FormControl(""),
      referToPhysician: new FormControl(0),
      externalConsultant:new FormControl(false),
      differencialDiagnosis: new FormControl(""),
      referralNote: new FormControl(""),
      remarks: new FormControl(""),
      referedTo: new FormControl(0),
      clinicalImpression: new FormControl(""),
      eligibility: new FormControl(""),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
    });
    this.GetReferredTo();
  }

  SaveMedicalDecision()
  {
    if(
      this. EMRPatientInfo.CrudType!=3 &&
      this.EMRPatientInfo.VisitId>0 && this.Loading && this.FormValueChangeChecker)
    {
      this.form.controls.referedTo.patchValue(Number(this.form.controls.referedTo.value));
      this.MedicalDecisions=this.form.getRawValue();
      this.MedicalDecisions.userId=Number(localStorage.getItem('user_id'));
      this.EMRService.SaveMedicalDecision(
        this.MedicalDecisions
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastr.success("Success: " + "Medical Decision Details Saved Successfully...");
          }
          else{
            Swal.fire(
              'Error!',
              'Some unexpected error occures! try again later.',
              'error'
            )
          }
        });
    }
    else{
      if(!this.FormValueChangeChecker){
       // this.toastr.warning("Info: " + "No changes detected in the entry");
      }
  }
  }

  GetMedicalDecision()
  {
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };

    this.EMRService.GetMedicalDecision(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.form.patchValue(response);
          this.Loading=true;
          this.form.valueChanges.subscribe(x => {
            this.FormValueChangeChecker=true;
           })
        }
      });
  }
  GetPrevMedicalDecision()
  {
    let input={
      "VisitId":this.EMRPatientInfo.VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":0
    };

    this.EMRService.GetMedicalDecision(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          this.PrevMedicalDecisions=res.response;

        }
      });
  }

  openModal(VisitId) {

    let input={
      "VisitId":VisitId,
      "PatientId":this.EMRPatientInfo.PatientId,
      "ShowAll":1
    };
    this.EMRService.GetMedicalDecision(input).subscribe(
      (res) => {
        if(res.status==200)
        {
          let response=res.response[0];
          this.MedicalDecisionsModalVal=response;
          this.modalRef = this.modalService.show(
            this.modaltemp,
            {class: 'modal-lg modal-custom-style' }
          );
        }
      });


  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  GetReferredTo(){
    this.EMRService.GetReferredTo().subscribe((res) => {
      this.referredToList = res.response;
    })
  }

  GetInternalConsultants() {
    let input={
      BranchId:Number(this.globals.HospitalId),
      ConsultantType: 0
    }
    this.DashboardService.GetAllConsultants(input).subscribe((res) => {
      this.internalConsultantlistData = res.response;

    });
  }

  GetExternalConsultants() {
    let input={
      BranchId:Number(this.globals.HospitalId),
      ConsultantType: 1
    }
    this.DashboardService.GetAllConsultants(input).subscribe((res) => {
      this.externalConsultantlistData = res.response;
    });
  }

  resetReferToPhysician(){
    this.form.controls.referToPhysician.patchValue(0)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
