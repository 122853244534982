import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup,Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../client/services/configuration.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-CurrencyManagement',
  templateUrl: './CurrencyManagement.component.html',
  styleUrls: ['./CurrencyManagement.component.scss']
})
export class CurrencyManagementComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;
  supervisor = "";
  locationname = "";
  Mobilenumber = "";
  public submitted: boolean = false;
  public submitted1: boolean = false;
  form: any;
  result: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  CardType: boolean
  CountryId: any;

  Currency: boolean = true;
  CardTypes: any;
  GetCurrencyList: any;
  CurrencyPopupHeading:String="Add Currency ";
  CurrencyCardtypePopupHeading:String="Add Card Type";
  CurrencycolumnDefs = [];
  CardTypecolumnDefs = [];

  public gridApiCurrency;
  public gridApiCardType;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  public rowSelection = true;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;


  CurrencyModal: boolean;
  CardTypeModal:boolean;
  DisplayedId: any;
  IsDisplayed: any;
  CurrencyForm: FormGroup;
  CardTypeForm: FormGroup;
  CurrencyId: any;
  CardTypeId: any;
  modalRef: BsModalRef;
  isLoading=false;
  @ViewChild("template") modaltemp: any;
  @ViewChild("template2") modaltemp2: any;
  functionPermissionData: any;

  constructor(
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {

    this.CurrencyForm = new FormGroup({
      CurrencyName: new FormControl("", [Validators.required]),
      CurrencyDesc: new FormControl("", [Validators.required]),
      IsDisplayedGroup: new FormControl(true),
    });

    this.CardTypeForm = new FormGroup({
      CardType: new FormControl("", [Validators.required]),
      ServiceCharge: new FormControl("", [Validators.required]),
      IsDisplayedGroup1: new FormControl(true),
    });

    this.GetCardType();
    this.GetCurrency();
    this.functionPermission();
    this.CurrencycolumnDefs = [
      {
        headerName: "Currency Name",
        field: "currencyName",
        minWidth:200,
        flex:3,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        editable: false,
      },
      {
        headerName: "Currency Description",
        field: "currencyDesc",
        mixWidth:200,
        flex:3,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        editable: false,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "Currency" },
      },
    ];



    this.CardTypecolumnDefs = [
      {
        headerName: "Card Name",
        field: "cardName",
        minWidth:200,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
      },

      {
        headerName: "Service Charge",
        field: "serviceCharge",
        minWidth:200,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        editable: false,
      },

      {
        headerName: "Action",

        pinned:"right",
        filter: false,
        maxWidth:100,
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "CardType" },
      },
    ];
    this.context = { componentParent: this };


    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }

  onTab(number) {
    this.Currency = false;
    this.CardType = false;

    if (number == "1") {
      this.Currency = true;
    } else if (number == "2") {
      this.CardType = true;
    }
  }

  onGridReadyCurrency(params) {
    this.gridApiCurrency = params.api;
  }
  onGridReadyCardType(params) {
    this.gridApiCardType = params.api;
  }
  onFilterTextBoxChangedCurrency() {
    this.gridApiCurrency.setQuickFilter(
      (document.getElementById('filter-text-box-currency') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedCardType() {
    this.gridApiCardType.setQuickFilter(
      (document.getElementById('filter-text-box-cardtype') as HTMLInputElement).value
    );
  }

  EditCurrency(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 42)
    if (foundElement.id == 42 && foundElement.isAllowed == true) {
    this.CurrencyPopupHeading="Edit Currency ";
    this.modalRef = this.modalService.show(
      this.modaltemp,
      // Object.assign({}, { class: "gray modal-lg" })
    );
    if (data.currencyId) {
      this.CurrencyId = data.currencyId;
      this.DisplayedId = data.isDisplayed;
      this.CurrencyForm.patchValue({
        CurrencyName: data.currencyName,
        CurrencyDesc: data.currencyDesc,
        IsDisplayedGroup: data.isDisplayed,
      });
    }
    } else {
      this.toastr.warning("You are not allowed to edit");
    }
  }
  DeleteCurrency(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 43)
    if (foundElement.id == 43 && foundElement.isAllowed == true) {
    this.CurrencyId = data.currencyId;
    this.configurationService.DeleteCurrency(this.CurrencyId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Currency  deleted successfully");

        this.CurrencyId=0;
      } else {
        this.toastr.error("Failure: " + this.result.message);
        this.displayModal = false;
      }
      this.GetCurrency();
      this.clearForm();

    });
    } else {
      this.toastr.warning("You are not allowed to delete");
    }
  }

  GetCurrency(){
    this.configurationService.GetCurrency(this.globals.HospitalId).subscribe((res) => {
      this.GetCurrencyList = res.response;
    });
   }


  SaveUpdateCurrencyy() {

    this.submitted = true;

    if (this.CurrencyForm && this.CurrencyForm.valid) {
      var AppData = {
        CurrencyName: this.CurrencyForm.get("CurrencyName").value,
        CurrencyDesc: this.CurrencyForm.get("CurrencyDesc").value,
        IsDisplayedGroup: this.CurrencyForm.get("IsDisplayedGroup").value,
      };

      let Currencydata = 0;
      if (this.CurrencyId > 0) {
        Currencydata = this.CurrencyId;
      }
      this.isLoading=true;
      this.configurationService.InsertUpdateCurrency(AppData,Currencydata, this.globals.HospitalId ).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Success") {
          if (Currencydata == 0) {
            this.isLoading=false;
            this.toastr.success("Currency added successfully");
          } else {
            this.isLoading=false;
            this.toastr.success("Currency Updated successfully");
            this.CurrencyId=0;
          }
          this.modalRef.hide();
          this.GetCurrency();
          this.clearForm();
        } else {
          this.isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    }

  }

   // for Opening Modal Templates
   openModalCurrency(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 41)
    if (foundElement.id == 41 && foundElement.isAllowed == true) {
    this.CurrencyPopupHeading="Add Currency ";
    this.modalRef = this.modalService.show(template);
    this.CurrencyId=0;
    this.clearForm();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

   // for geting all form items
   get f() {
    return this.CurrencyForm.controls;
  }
 // to clear form
 clearForm() {
  this.CurrencyForm.reset({
    CurrencyName: '',
    CurrencyDesc: '',
    IsDisplayedGroup:true
  });
  this.submitted = false;
}

 //-------------------------------------CardType------------------------------//
  // for Opening Modal Templatess
 openModalCard(template2: TemplateRef<any>){
  const foundElement = this.functionPermissionData.find(x => x.id === 44)
  if (foundElement.id == 44 && foundElement.isAllowed == true) {
  this.CurrencyPopupHeading="Add Card Type";
  this.modalRef = this.modalService.show(template2);
  this.CardTypeId=0;
  this.clearForm2();
  } else {
    this.toastr.warning("You are not allowed");
  }
 }
 GetCardType(){
  this.configurationService.GetCardType(this.globals.HospitalId).subscribe((res) => {
    this.CardTypes = res.response;
  });
 }

 SaveUpdateCard(){

  this.submitted1 = true;

    if (this.CardTypeForm && this.CardTypeForm.valid) {
      var AppData = {
        CardType: this.CardTypeForm.get("CardType").value,
        ServiceCharge: this.CardTypeForm.get("ServiceCharge").value,
        IsDisplayedGroup1: this.CardTypeForm.get("IsDisplayedGroup1").value,
      };

      let Carddata = 0;
      if (this.CardTypeId > 0) {
        Carddata = this.CardTypeId;
      }
      this.isLoading=true;
      this.configurationService.InsertUpdateCardType(AppData,Carddata,this.globals.HospitalId ).subscribe((res) => {
        this.submitted1 = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Success") {
          if (Carddata == 0) {
            this.isLoading=false;
            this.toastr.success("Card Type added successfully");
          } else {
            this.isLoading=false;
            this.toastr.success("Card Type Updated successfully");
            this.CardTypeId=0;
          }
          this.modalRef.hide();
          this.GetCardType();
          this.clearForm2();
        } else {
          this.isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    }

 }

 EditCardType(data) {
  const foundElement = this.functionPermissionData.find(x => x.id === 45)
  if (foundElement.id == 45 && foundElement.isAllowed == true) {
  this.CurrencyCardtypePopupHeading="Edit Card Type";

  this.modalRef = this.modalService.show(
    this.modaltemp2,
    // Object.assign({}, { class: "gray modal-lg" })
  );

  if (data.cardId) {
    this.CardTypeId = data.cardId;
    this.DisplayedId = data.isDisplayed;
    this.CardTypeForm.patchValue({
      CardType: data.cardName,
      ServiceCharge: data.serviceCharge,
      IsDisplayedGroup1: data.isDisplayed,
    });
  }
  } else {
    this.toastr.warning("You are not allowed to edit");
  }
}
DeleteCardType(data) {
  const foundElement = this.functionPermissionData.find(x => x.id === 46)
  if (foundElement.id == 46 && foundElement.isAllowed == true) {
  this.CardTypeId = data.cardId;
  this.configurationService.DeleteCardType(this.CardTypeId).subscribe((res) => {
    this.result = res;
    if (this.result.status == 200) {
      this.toastr.success("CardType  deleted successfully");
      this.displayModal = false;
      this.CardTypeId=0;
    } else {
      this.toastr.error("Failure: " + this.result.message);
      this.displayModal = false;
    }
    this.GetCardType();
    this.clearForm2();

  });
  } else {
    this.toastr.warning("You are not allowed to delete");
  }
}
// for geting all form items
get f2() {
  return this.CardTypeForm.controls;
}

 // to clear form
 clearForm2() {
  this.CardTypeForm.reset({
    CardType: '',
    ServiceCharge: '',
    IsDisplayedGroup1:true
  });
  this.submitted1 = false;
}
ResetFunction(){
  this.CurrencyId=0;
  this.clearForm();
}

ResetFunction2(){
  this.CardTypeId=0;
  this.clearForm2();
}

functionPermission() {
  let input = {
    MenuId: Number(localStorage.getItem('selectedMenu')),
    UserId: Number(localStorage.getItem("user_id"))
  }
  this.configurationService.functionPermission(input).subscribe((res) => {
    this.functionPermissionData = res.response;
  });

}
}
