export class PatientRegistration {

  IsManualRegNo:Number;
  PatientId: Number;
  RegId: Number;
  RegNo: String;
  RegDate: String;
  RegAmount: Number;
  ItemId: Number;
  ExpiryDate: String;
  AddType: Number;
  Address1: String;
  Address2: String;
  Street: String;
  PlacePO: String;
  City: String;
  State: String;
  Pin: String;
  CountryId: Number;
  Salutation: Number;
  FirstName: String;
  MiddleName: String;
  LastName: String;
  IdentityType: Number;
  IdentityNo: String;
  Gender: Number;
  DOB: String;
  MaritalStatus: Number;
  KinName: String;
  KinDOB: String;
  KinRelation: Number;
  KinContactNo: String;
  KinNationalId: any;
  KinType: any;
  Mobile: String;
  ResNo: String;
  OffNo: String;
  Email: String;
  FaxNo: String;
  Religion: Number;
  CmpId: Number;
  Status: Number;
  PatState: Number;
  ProfId: Number;
  RGroupId: Number;
  Mode: String;
  ProfilePicLocation:string;
  Remarks: String;
  NationalityId: Number;
  NationalId:string;
  ConsultantId: number;
  Active: Number;
  AppId: Number;
  RefBy: String;
  PrivilegeCard: false;
  UserId: Number;
  LocationId: Number;
  WorkEnvironMent: String;
  ProfessionalNoxious: String;
  ProfessionalExperience: String;
  VisaTypeId: Number;
  SessionId: Number;
  BranchId: Number;
  RetVal: Number;
  RetDesc: String;
  RetRegNo: String;
  Hook: String;
  RegFromDate: String;
  RegToDate: String;
  PolicyNo: String;
  PatientName: String;
  Age: String;
  Consultant: String;
  Telephone: String;
  SponsorName: String;
  SponsorId: String;
  EnableSponsorConsent: String;
  EmirateID: String;
  CompanyName: String;
  BlockReason: String;
  Company:Number;
  CommunicationType: Number;
  RegAddress:RegAddress[];
  RegIdentities:RegIdentities[];
  Consultation:Consultation;
  RegDet:RegDet;
  Place1:string;
  Pin1:string;
  City1:string;
  State1:string;
  Country1:string;
  Address3:string;
  Address4:string;
  Place2:string;
  Pin2:string;
  City2:string;
  State2:string;
  Street2:string;
  Street3:string;
  Country2:Number;
  Address5:string;
  Address6:string;
  Place3:string;
  Pin3:string;
  City3:string;
  State3:string;
  Country3:Number;
  Symptoms: String;
  ConsultFee: Number;
  PinOrvid:string;
  PassportNo:string;
  DrivingLicNo:string;
  HealthCardId:string;
  LabourCardNo:string;
  VisaOrpermitNo:string;
  Scheme2:Number;
  Profession:Number;
  ManualRegNo:Number;
  otherreason: string;
 // otherreasonvisit: string;
  //OtherReasonForVisit: any;
  OtherReasonVisit: string;
  otherreasonforvisit: string;
  ConfCodeId: number | Number;
  ConsentOptionId: number | Number;
 // RegAmount:Number;

  PatientRegistration()
  {
    this.RegAddress= [new RegAddress];
    this.RegIdentities=[new RegIdentities];
    this.PatientId= 0;
    this.RegId= 0;
    this.RegNo= "";
    this.RegDate= "";
    this.RegAmount= 0;
    this.ItemId= 0;
    this.ExpiryDate= "";
    this.AddType= 0;
    this.Address1= "";
    this.Address2= "";
    this.Street= "";
    this.PlacePO= "";
    this.City= "";
    this.State= "";
    this.Pin= "";
    this.CountryId= 0;
    this.Salutation= 0;
    this.FirstName= "";
    this.MiddleName= "";
    this.LastName= "";
    this.IdentityType= 0;
    this.IdentityNo= "";
    this.Gender= 0;
    this.DOB= "";
    this.MaritalStatus= 0;
    this.KinName= "";
    this.KinDOB= "";
    this.KinRelation= 0;
    this.KinContactNo= "";
    this.Mobile= "";
    this.ResNo= "";
    this.OffNo= "";
    this.Email= "";
    this.FaxNo= "";
    this.Religion= 0;
    this.CmpId= 0;
    this.Status= 0;
    this.PatState= 0;
    this.ProfId= 0;
    this.RGroupId= 0;
    // this.rGroupId= 0;
    this.Mode= "";
    this.Remarks= "";
    this.NationalityId= 0;
    this.NationalId="";
    this.ConsultantId= 0;
    this.Active= 0;
    this.AppId= 0;
    this.RefBy= "";
    this.PrivilegeCard= false;
    this.UserId= 0;
    this.LocationId= 0;
    this.WorkEnvironMent= "";
    this.ProfessionalNoxious= "";
    this.ProfessionalExperience= "";
    this.VisaTypeId= 0;
    this.SessionId= 0;
    this.BranchId= 0;
    this.RetVal= 0;
    this.RetDesc= "";
    this.RetRegNo= "";
    this.Hook= "";
    this.RegFromDate= "";
    this.RegToDate= "";
    this.PolicyNo= "";
    this.PatientName= "";
    this.Age= "";
    this.Consultant= "";
    this.Telephone= "";
    this.SponsorName= "";
    this.SponsorId= "";
    this.EnableSponsorConsent= "";
    this.EmirateID= "";
    this.Company=0;
    this.CompanyName= "";
    this.BlockReason= "";
    this.CommunicationType= 0;
    this.Place1="";
    this.Pin1="";
    this.City1="";
    this.State1="";
    this.Country1="";
    this.Address3="";
    this.Address4="";
    this.Place2="";
    this.Pin2="";
    this.City2="";
    this.State2="";
    this.Country2=0;
    this.Address5="";
    this.Address6="";
    this.Place3="";
    this.Pin3="";
    this.City3="";
    this.State3="";
    this.Country3=0;
    this.Symptoms= "";
    this.ConsultFee= 0;
    this.PinOrvid="";
    this.PassportNo="";
    this.DrivingLicNo="";
    this.HealthCardId="";
    this.LabourCardNo="";
    this.VisaOrpermitNo="";
    this.Scheme2=0;
    this.ManualRegNo=0;
    this.otherreason= "";
    this.OtherReasonVisit= "";
  }


}

export class RegAddress
{

  AddType: Number;
  Address1: String;
  Address2: String;
  Street: String;
  PlacePO: String;
  PIN: String;
  City: String;
  State: String;
  CountryId: Number;
  RegAddress(){
    this.AddType=0;
    this.Address1='';
    this.Address2='';
    this.Street='';
    this.PlacePO='';
    this.PIN='';
    this.City='';
    this.State='';
    this.CountryId=0;
   }
}

export class RegIdentities
{
  IdentityType: Number;
  IdentityNo: String;
  PatientId: Number;
  RegIdentities()
  {
    this.IdentityType=0;
    this.IdentityNo="";
    this.PatientId=0;
  }
}

export class Consultation
{
  EnableConsultation: boolean;
  TokenNO: String;
  PatientName: String;
  TimeNo: String;
  RegNo: String;
  Sponsor: String;
  ConsultationId: number;
  AppId: number;
  ConsultDate: String;
  ConsultantId: Number;
  PatientId: Number;
  Symptoms: String;
  Gender: Number;
  ConsultType: Number;
  ConsultFee: Number;
  EmerFee: Number;
  Emergency: Number;
  ItemId: Number;
  AgentId: String;
  LocationId: Number;
  LeadAgentId: String;
  InitiateCall: Number;
  UserId: Number;
  SessionId: Number;
  RetVal: Number;
  RetDesc: String;
  RetSeqNo: Number;
  Status: String;
  Consultant: String;
  ConsultType2: String;
  PIN: String;
  Mobile: String;
  Telephone: String;
  Address: String;
  FromDate: String;
  ToDate: String;
  CancelReason: String;
  BranchId:number;
  PatientPhoto:string;
  otherreasonforvisit: string;
  Hook:string;

}

export class RegDet{
  PatientDetId: number;
  CommType : number;
}
