import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { EMRService } from "../../emr/services/emr.service";
import { ToastrService } from "ngx-toastr";
import { EMRPatientInfo } from "../interfaces/EMRPatientInfo";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { ConfigurationService } from "../../client/services/configuration.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { SharedService } from "../../../shared/shared.service";
import { Subscription } from "rxjs";
import { Settings } from "../../settings/model/settings.model";

@Component({
  selector: "app-emr-physiotherapy-master",
  templateUrl: "./emr-physiotherapy-master.component.html",
  styleUrls: ["./emr-physiotherapy-master.component.scss"],
})
export class EmrPhysiotherapyMasterComponent implements OnInit,OnChanges {
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  disabled:boolean=false

  physiotForm: FormGroup;
  GetGaitAnalysisdata: [];
  Physiotherapist: any[] = [];
  globals: globalvars = new globalvars();
  // @Input() IsView;
  conId:Number;
  GetPosturalAnalysisdata: [];
  GetPalpationdata: [];
  GetDeepTendonReflexdata: [];
  GetDeepSensationdata: [];
  GetSuperficialSensationdata: [];
  GetToneAssesmentdata: [];
  GetSkinAssesmentdata: [];
  GetAmputationdata: [];
  GetBodyBuiltdata: [];
  GetConsciousnessdata: [];
  GetMentalStatusdata: [];
  GetHandednessdata: [];
  GetTypeOfPaindata: [];
  GetPainDescriptiondata: [];
  GetSpasticitydata: [];
  subscription: Subscription;
  pID: any;
  Selectedtab: string;
  Ortho: boolean = true;

  public isLoading: boolean = false;
  public submitted: boolean = false;
  result: any;
  saveDataId: any;
  EMRPatientInfo: EMRPatientInfo;
  consultationId: any;
  constructor(
    private EMRService: EMRService,
    private toastr: ToastrService,
    private router: Router,
    private configurationService: ConfigurationService,
    private sharedservice: SharedService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });
    this.conId= Number(localStorage.getItem("emrPhyConsultationID"));
    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
  }

  ngOnInit(): void {
    this.Scrolltotab("Ortho");
    this.physiotForm = new FormGroup({
      consultationId: new FormControl(this.EMRPatientInfo.ConsultationId),
      physioTherapistId: new FormControl(0),
      informantReliability: new FormControl(""),
      PainDescription: new FormControl(0),
      siteOfPain: new FormControl(""),
      typeOfPain: new FormControl(0),
      onsetDuration: new FormControl(""),
      handedness: new FormControl(0),
      mentalStatus: new FormControl(0),
      painScale: new FormControl(""),
      levelOfConsciousness: new FormControl(0),
      bodyBuilt: new FormControl(0),
      amputation: new FormControl(0),
      skinAssesment: new FormControl(0),
      toneAssesment: new FormControl(0),
      gradeOfSpasticity: new FormControl(0),
      superficialSensation: new FormControl(0),
      sensoryAssesment: new FormControl(""),
      deepSensation: new FormControl(0),
      deepTendonReflexGrading: new FormControl(0),
      palpation: new FormControl(0),
      reflexTesting: new FormControl(""),
      posturalAnalysis: new FormControl(0),
      gaitAnalysis: new FormControl(0),
      ActivityOfDailyLiving: new FormControl(""),
      GeneralDataOne: new FormControl(""),
      GeneralDataTwo: new FormControl(""),
      GeneralDataThree: new FormControl(""),
      GeneralDataFour: new FormControl(""),
      GeneralDataFive: new FormControl(""),
    });
    this.GetGaitAnalysis();
    this.GetPosturalAnalysis();
    this.GetPalpation();
    this.GetDeepTendonReflex();
    this.GetDeepSensation();
    this.GetSuperficialSensation();
    this.GetToneAssesment();
    this.GetSkinAssesment();
    this.GetAmputation();
    this.GetBodyBuilt();
    this.GetConsciousness();
    this.GetMentalStatus();
    this.GetHandedness();
    this.GetTypeOfPain();
    this.GetPainDescription();
    this.GetSpasticity();
    this.EmrPhysioAnalysisGet();
    this.GetAllStaff();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 46 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (isSettingWithValueZeroPresent) {
        // Your logic when a setting with value 0 is present and billEdit is true
      
        this.disabled=true
      }
      
    }
  }
  GetGaitAnalysis() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetGaitAnalysis(input).subscribe((res) => {
      this.GetGaitAnalysisdata = res.response;
    });
  }
  GetPosturalAnalysis() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetPosturalAnalysis(input).subscribe((res) => {
      this.GetPosturalAnalysisdata = res.response;
    });
  }
  GetPalpation() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetPalpation(input).subscribe((res) => {
      this.GetPalpationdata = res.response;
    });
  }
  GetDeepTendonReflex() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetDeepTendonReflex(input).subscribe((res) => {
      this.GetDeepTendonReflexdata = res.response;
    });
  }
  GetDeepSensation() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetDeepSensation(input).subscribe((res) => {
      this.GetDeepSensationdata = res.response;
    });
  }
  GetSuperficialSensation() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetSuperficialSensation(input).subscribe((res) => {
      this.GetSuperficialSensationdata = res.response;
    });
  }
  GetToneAssesment() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetToneAssesment(input).subscribe((res) => {
      this.GetToneAssesmentdata = res.response;
    });
  }
  GetSkinAssesment() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetSkinAssesment(input).subscribe((res) => {
      this.GetSkinAssesmentdata = res.response;
    });
  }
  GetAmputation() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetAmputation(input).subscribe((res) => {
      this.GetAmputationdata = res.response;
    });
  }
  GetBodyBuilt() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetBodyBuilt(input).subscribe((res) => {
      this.GetBodyBuiltdata = res.response;
    });
  }
  GetConsciousness() {
    let input = {
      Id: 0,

    };
    this.EMRService.GetConsciousness(input).subscribe((res) => {
      this.GetConsciousnessdata = res.response;
    });
  }
  GetMentalStatus() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetMentalStatus(input).subscribe((res) => {
      this.GetMentalStatusdata = res.response;
    });
  }
  GetHandedness() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetHandedness(input).subscribe((res) => {
      this.GetHandednessdata = res.response;
    });
  }
  GetTypeOfPain() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetTypeOfPain(input).subscribe((res) => {
      this.GetTypeOfPaindata = res.response;
    });
  }
  GetPainDescription() {
    let input = {
      Id: 0,
    };
    this.EMRService.GetPainDescription(input).subscribe((res) => {
      this.GetPainDescriptiondata = res.response;
    });
  }
  GetSpasticity() {
    this.pID = this.EMRPatientInfo.PatientId;
    let input = {
      Id: 0,
      showAll:0,
      branchId:Number(this.globals.HospitalId)
      };
    this.EMRService.GetSpasticity(input).subscribe((res) => {
      this.GetSpasticitydata = res.response;
    });
  }
  save() {
      this.submitted = true;
      if (this.physiotForm && this.physiotForm.valid) {
        var formdata = this.physiotForm.getRawValue();
        formdata.PainDescription = Number(formdata.PainDescription);
        formdata.typeOfPain = Number(formdata.typeOfPain);
        formdata.handedness = Number(formdata.handedness);
        formdata.mentalStatus = Number(formdata.mentalStatus);
        formdata.bodyBuilt = Number(formdata.bodyBuilt);
        formdata.amputation = Number(formdata.amputation);
        formdata.skinAssesment = Number(formdata.skinAssesment);
        formdata.toneAssesment = Number(formdata.toneAssesment);
        formdata.gradeOfSpasticity = Number(formdata.gradeOfSpasticity);
        formdata.superficialSensation = Number(formdata.superficialSensation);
        formdata.deepSensation = Number(formdata.deepSensation);
        formdata.palpation = Number(formdata.palpation);
        formdata.posturalAnalysis = Number(formdata.posturalAnalysis);
        formdata.gaitAnalysis = Number(formdata.gaitAnalysis);
        formdata.physioTherapistId=Number(formdata.physioTherapistId);
        formdata.deepTendonReflexGrading = Number(
          formdata.deepTendonReflexGrading
        );
        formdata.levelOfConsciousness=Number(formdata.levelOfConsciousness)
        if (formdata.informantReliability == "") {
          this.toastr.warning("Please Select Informent / Relability");
        } else {
          this.isLoading = true;
          this.EMRService.EmrPhysioAnalysisSave(formdata).subscribe((res) => {
            this.submitted = false;
            this.result = res;
            if (this.result.status == 200 && this.result.message == "Success") {
              this.isLoading = false;
              this.toastr.success("Data added Successfully");
            } else {
              this.isLoading = false;
              this.toastr.error("Failed : " + this.result.message);
            }
          });
        }
      }
    
  }
  EmrPhysioAnalysisGet() {
    let consultationId = Number(localStorage.getItem("emrPhyConsultationID"));
    // console.log(consultationId);
    let input = {
      consultationId:consultationId
    };

    this.EMRService.EmrPhysioAnalysisGet(input).subscribe((res) => {
      let getEmrPhysioAnalysis = res.response[0];
      
      this.physiotForm.controls["ActivityOfDailyLiving"].setValue(getEmrPhysioAnalysis.activityOfDailyLiving)
      this.physiotForm.controls["PainDescription"].setValue(getEmrPhysioAnalysis.painDescription)
      
      this.physiotForm.patchValue(getEmrPhysioAnalysis);
      this.physiotForm.controls["GeneralDataOne"].setValue(getEmrPhysioAnalysis.generalDataOne);
      this.physiotForm.controls["GeneralDataTwo"].setValue(getEmrPhysioAnalysis.generalDataTwo);
      this.physiotForm.controls["GeneralDataThree"].setValue(getEmrPhysioAnalysis.generalDataThree);
      this.physiotForm.controls["GeneralDataFour"].setValue(getEmrPhysioAnalysis.generalDataFour);
      this.physiotForm.controls["GeneralDataFive"].setValue(getEmrPhysioAnalysis.generalDataFive);

    });
  }

  Scrolltotab(elem) {
    this.Selectedtab = elem;
  }
  get f() {
    return this.physiotForm.controls;
  }
  GetAllStaff() {
    this.configurationService
      .GetStaff(this.globals.HospitalId)
      .subscribe((res) => {
        let StaffData = res.response;
        this.Physiotherapist = StaffData.filter((x) => x.categoryId === 7);
      });
  }
  // Activetab(pagetitle) {
  //   let consultationId = Number(localStorage.getItem("consultationId"));

  //   sessionStorage.setItem("pageTitle", pagetitle);
  //   this.router.navigate(["/physiothearapy-treatment"], {
  //     state: { consultationId: consultationId },
  //   });
  // }
}
