import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { Response } from '../../../_Models/Response';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { BillingGeneralActionComponent } from '../billing-general-action/billing-general-action.component';
import { CancelBillModalComponent } from '../modals/cancel-bill-modal/cancel-bill-modal.component';
import { CancelSettlmentModalComponent } from '../modals/cancel-settlment-modal/cancel-settlment-modal.component';
import { NewRecieptModalComponent } from '../modals/new-reciept-modal/new-reciept-modal.component';
import { SettlementModalComponent } from '../modals/settlement-modal/settlement-modal.component';
import { BillingService } from '../services/billing.service';
import { ConfigurationService } from '../../client/services/configuration.service';
import { ChangeApprovalModalComponent } from '../../claim/modals/change-approval-modal/change-approval-modal.component';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';

@Component({
  selector: 'app-external-bill',
  templateUrl: './external-bill.component.html',
  styleUrls: ['./external-bill.component.scss']
})
export class ExternalBillComponent implements OnInit {

  modalRef: BsModalRef;
  @ViewChild(SearchPatientModalComponent)modaltemp: SearchPatientModalComponent;

  private globals: globalvars = new globalvars();

  public gridApi;
  columnDefs: ColDef[] = [];
  public externalLabBillDetails : any[] = [];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules :{
      'row-cancelled':params=> params.data.status.toLowerCase() == 'cancelled' || params.data.status.toLowerCase() == 'c'
    }
  }
  public context;
  public frameworkComponents;
  public loadingTemplate;
  public noRowsTemplate;

  public billListingForm: FormGroup;

  private subscription: Subscription;
  private CurrentDate: string;
  private baseUrl: string;
  public allLocations: any[] = [];
  functionPermissionData: any;
  credDetails: any;

  constructor(private datepipe: DatePipe,
              private configurationManager: ConfigurationManager,
              private sharedService: SharedService,
              private billingService: BillingService,
              private router: Router,
              private modalService: BsModalService,
              private toastrService: ToastrService,
              private ConfigurationService: ConfigurationService,
              ) { }

  ngOnInit(): void {


    sessionStorage.removeItem('isExternalLabBill');
    sessionStorage.removeItem('bill-patient-regNo');
    this.CurrentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');

    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });

    this.context = { componentParent: this };
    this.columnDefs = [
      {
        headerName: "Date",
        field: "billDate",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Bill No.",
        field: "billNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Reg No.",
        field: "regNo",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Patient Name",
        field: "patientName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Consultant",
        field: "consultantName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Insurance",
        field: "sponsorName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Bill Amt.",
        field: "billAmount",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Due",
        field: "dueAmt",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Location",
        field: "location",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Status",
        field: "status",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Settled By",
        field: "settledUser",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      // {
      //   headerName: "Comm. Status",
      //   field: "settledBy",
      //   resizable: true,
      //   filter: true,
      //   sortable: true,
      //   flex: 2,
      // },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "externalBillDetails" },
      },
    ];
    this.frameworkComponents = { buttonRender: BillingGeneralActionComponent};

    this.billListingForm = new FormGroup({
      RegNos: new FormControl(""),
      PatientNames: new FormControl(""),
      BillNos: new FormControl(""),
      DateFromList: new FormControl(this.CurrentDate),
      DateToList: new FormControl(this.CurrentDate),
      PayStatus: new FormControl('All'),
      Location: new FormControl(0),
    });

    this.getLocations();
    this.functionPermission();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.GetBillList();
  }

  onFilterTextBoxChangedItem(event){
    this.gridApi.setQuickFilter(
      event.target.value
    );
  }

  getLocations(){
    let payload = {
      "LocationId": 0,
      "ShowAll": 1,
      "HospitalId": this.globals.HospitalId
    }

    this.billingService.getAllLocations(payload).subscribe({
      next: (response: Response<any>)=>{
        this.allLocations = response.response;
      },error: (error: any)=>{
        this.toastrService.error('Failed to load locations, Try again.')
      }
    })
  }

  searchevent(patient: any) {
    this.billListingForm.patchValue({
      RegNos: patient.data.regNo,
      PatientNames: patient.data.patient
    })
  }

  showBasicDialog() {
    this.modaltemp.open();
  }

  GetBillList() {
    let StartDateObj = this.billListingForm.value.DateFromList;
    let EndDateObj = this.billListingForm.value.DateToList;
    // date convertion
    if (typeof (StartDateObj) == "object") {
      let DateFromList = this.datepipe.transform(StartDateObj, 'dd-MM-yyyy');
      this.billListingForm.value.DateFromList = DateFromList;
    }
    if (typeof (EndDateObj) == "object") {
      let endDate = this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      this.billListingForm.value.DateToList = endDate;
    }

    let input = {
      "Locationid": this.billListingForm.value.Location ? Number(this.billListingForm.value.Location) : 0,
      "TransFromDate": this.billListingForm.value.DateFromList ? this.billListingForm.value.DateFromList : "",
      "TransToDate": this.billListingForm.value.DateToList ? this.billListingForm.value.DateToList : "",
      "RegNo": this.billListingForm.value.RegNos ? this.billListingForm.value.RegNos : "",
      "BranchId": this.globals.HospitalId,
      "Externalstatus": 1,
      "PatientName": this.billListingForm.value.PatientNames ? this.billListingForm.value.PatientNames : "",
      "BillNo": this.billListingForm.value.BillNos ? this.billListingForm.value.BillNos : "",
      "Status" : this.billListingForm.value.PayStatus ? this.billListingForm.value.PayStatus : "",
    }
    this.billingService.getBillingList(input).subscribe((res) => {
      this.externalLabBillDetails = res.response;
      //this.getConsultationResponse=this.BillingListData
    });
  }

  addNewExternalBill(){
    const foundElement = this.functionPermissionData.find(x => x.id === 351)
    if (foundElement.id == 351 && foundElement.isAllowed == true) {
    let external = {
      isExternalStatus: true
    }
    sessionStorage.setItem('isExternalLabBill', JSON.stringify(external));
    this.router.navigate(['/newbill'], { state: { isExternalLab: true }, queryParams: {returnUrl: 'external-lab-bills'} })
    } else {
      this.toastrService.warning("You are not allowed to add external lab bills");
    }
  }

  cancelItem(data){
    let initialState = {
      TransId: Number(data.transId),
      isExternal: true
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(CancelBillModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
    this.modalRef.content.cancelEvent.subscribe(res=>{
      if(res.status){
        this.GetBillList();
      }
    });
    
  }

  openCancelSettlmentModal(data: any){
  let initialState = {
    TransId: Number(data.transId),
    isExternal: true
  }
  let config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: true,
  }
  this.modalRef= this.modalService.show(CancelSettlmentModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
  this.modalRef.content.cancelSettlementEvent.subscribe(res=>{
    if(res.status){
      this.GetBillList();
    }
  });

  }

  openSettlmentScreen(data){
    let initialState = {
      transId: Number(data.transId),
      branchId: Number(this.globals.HospitalId),
      locationId: Number(this.globals.Location.locationId),
      isExternal: true,
      action: "settlement"
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(SettlementModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    this.modalRef.content.settlementEventEmit.subscribe(res=>{
      if(res.status){
        this.GetBillList();
      }
    });
  }

  onPrintInvoice(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 356)
    if (foundElement.id == 356 && foundElement.isAllowed == true) {
      let initialState = {
        modalHeading: 'Print Invoice',
        reportName: 'PrintExternalInvoice.trdp',
        reportParams: {
          TransId : Number(data.transId),
          BranchId :this.globals.HospitalId,
          CreditId: Number(data?.creditId)
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
      
  // let payload = {
  //   TransId : Number(data.transId),
  //   BranchId :this.globals.HospitalId,
  //   IsExternal: true
  // }

  // this.billingService.printBillInvoice(payload).subscribe({
  //   next: (response: Response<any>)=>{
  //     if(response.status == 200){
  //       window.open(this.baseUrl+response.response[0]?.reportPath, '_blank')
  //     }else{
  //       this.toastrService.error(response.response)
  //     }
  //   },error: (error: any)=>{
  //     this.toastrService.error('Something wents wrong, Try again...');
  //   }
  // })
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  onNewRecieptBill(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 353)
    if (foundElement.id == 353 && foundElement.isAllowed == true) {
    let initialState = {
      patientId: Number(data.patientId),
      globals: this.globals,
      regNo: data.regNo,
      transId: Number(data.transId)
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(NewRecieptModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  onPrintUpFront(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 354)
    if (foundElement.id == 354 && foundElement.isAllowed == true) {
      let initialState = {
        modalHeading: 'Print External Upfront',
        reportName: 'PrintExternalUpfront.trdp',
        reportParams: {
          TransId : Number(data.transId),
          ShowPackName: false,
          BranchId :this.globals.HospitalId,
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    // let payload = {
    //   IsExternal: true,
    //   ShowPackName: true,
    //   TransId : Number(data.transId),
    //   BranchId :this.globals.HospitalId
    // }

    // this.billingService.printUpfrontInvoice(payload).subscribe({
    //   next: (response: any)=>{
    //     if(response.status == 200){
    //       window.open(this.baseUrl+response.response[0]?.reportPath, '_blank')
    //     }else{
    //       this.toastrService.error(response.errorMessage?.message)
    //     }
    //   },error: (error: any)=>{
    //     this.toastrService.error('Something wents wrong, Try again...');
    //   }
    // })
    } else {
      this.toastrService.warning("You are not allowed");
    }
   }

   functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  onLabSettlement(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 352)
    if (foundElement.id == 352 && foundElement.isAllowed == true) {
      if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
        this.openSettlmentScreen(data);
      }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
        this.toastrService.error('This bill is already settled..');
      }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
        this.toastrService.error('This bill is cancelled..');
      }    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  async onViewOrPrintBill(data) {
    await this.loadSponsorTable(data);
    const foundElement = this.functionPermissionData.find(x => x.id === 355)
    if (foundElement.id == 355 && foundElement.isAllowed == true) {
      let patientData = {
        patientData: data,
        OtherData: null,
        isExternal: true,
        action: 'view'
      }
      sessionStorage.setItem('billDetails', JSON.stringify(patientData));
      sessionStorage.setItem('viewOnly', JSON.stringify({status: true}));
      this.router.navigate(['../billitems/'+data.transId],{queryParams: {returnUrl: 'external-lab-bills'}});
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  onCancelSettlement(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 357)
    if (foundElement.id == 357 && foundElement.isAllowed == true) {
      if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
        this.toastrService.error('This bill is not settled yet..');
      }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
        this.openCancelSettlmentModal(data);
      }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
        this.toastrService.error('This bill is already cancelled..');
      }    } else {
      this.toastrService.warning("You are not allowed to cancel settlement");
    }
  }

  onCancelBill(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 358)
    if (foundElement.id == 358 && foundElement.isAllowed == true) {
      if(data.status.toLowerCase() == 'new' || data.status.toLowerCase() == 'n'){
        this.cancelItem(data);
      }else if(data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's'){
        this.toastrService.error('This bill is already settled, Cancel settlement first to cancel bill..');
      }else if(data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c'){
        this.toastrService.error('This bill is already cancelled..');
      }    } else {
      this.toastrService.warning("You are not allowed to cancel bill");
    }
  }

  async onLabEditBill(data) {
    await this.loadSponsorTable(data);
    const foundElement = this.functionPermissionData.find(x => x.id === 359)
    if (foundElement.id == 359 && foundElement.isAllowed == true) {
      if (data.status.toLowerCase() == 'settled' || data.status.toLowerCase() == 's') {
        this.toastrService.error('Settled bills are not allowed to edit..');
      } else if (data.status.toLowerCase() == 'cancelled' || data.status.toLowerCase() == 'c') {
        this.toastrService.error('Cancelled bills are not allowed to edit..');
      } else {
        let patientData = {
          patientData: data,
          OtherData: null,
          isExternal: true,
          credDetails: this.credDetails,
          action: 'edit'
        }
        sessionStorage.setItem('billDetails', JSON.stringify(patientData));
        this.router.navigate(['../billitems/' + data.transId], { queryParams: { returnUrl: 'external-lab-bills' } });
      }
    } else {
      this.toastrService.warning("You are not allowed to edit bill");
    }
  }

  async loadSponsorTable(data:any) {
    let selectedSponsor;
    let payload = {
      PatientId: data.patientId,
      Branchid: this.globals.HospitalId,
      ShowExpired: 1
    }
    let sponsorList = await this.billingService.GetSponsorDetailsByPatient(payload).toPromise();
    // this.billingService.GetSponsorDetailsByPatient(payload).subscribe((res) => {
    //   this.sponsorList = res.response;
    // });
    console.log('sponsorList', sponsorList);
    sponsorList.response.find(x => {
      console.log('x', x);
      if(x.isPrimary == true){
        this.credDetails = x;
      }
    });
  }

  onChangeApproval(data: any){
    let initialState = {
      transId: Number(data.transId),
      branchId: this.globals.HospitalId,
      isExternal: true
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(ChangeApprovalModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
  }
}
