import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../assets/configuration/configuration-manager';
import { Observable } from 'rxjs';
import { Response } from '../../_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingsService {

  private baseUrl: string = '';
  constructor(private httpClient: HttpClient,
              private configurationManager: ConfigurationManager) { 

    this.baseUrl = (this.configurationManager as any).baseUrl;

  }

  getApplicationSettings(payload: any): Observable<Response<any>>{
    const url = `${this.baseUrl}api/MasterData/GetApplicationSettings`;
    return this.httpClient.post<Response<any>>(url, payload);
  }
}
