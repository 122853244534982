import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CellValueChangedEvent, GridApi, RowValueChangedEvent } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../../../_Models/Response';
import { BillingService } from '../../services/billing.service';

@Component({
  selector: 'app-sponsor-data',
  templateUrl: './sponsor-data.component.html',
  styleUrls: ['./sponsor-data.component.scss']
})
export class SponsorDataComponent implements OnInit {

  @Input() private creditId: any;
  @Input() private branchId: any;
  @Input() patientId: any;
  @Input() billType: string = 'N'

  @Output() sponsorDataEvent = new EventEmitter();

  public loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
  public noRowsTemplate = `"<span">no rows to show</span>"`;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight'
  };
  public gridApi: GridApi;
  public maternityGrid: GridApi;
  public dataColumns: any[] = [];

  public sponsorData: any[] = [];
  public normalSponsorData: any[] = [];
  public maternitySponsorData: any[] = [];
  private creditData: any;

  public maternityCredit: boolean = false;
  public normalCredit: boolean = true;

  public defaultValueForm: FormGroup;
  public maternityDefaultValueForm: FormGroup;

  public creditDataSaveLoading: boolean = false;

  constructor(public modalRef: BsModalRef,
    private billingService: BillingService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.billType == 'M' ? (this.normalCredit = false, this.maternityCredit = true) : (this.normalCredit = true, this.maternityCredit = false);
    
    this.dataColumns = [
      {
        headerName: "Group Name",
        field: "groupName",
        resizable: true,
        sortable: true,
        flex: 4,
        filter: true,
        editable: false
      },
      {
        headerName: "Ded. Amount",
        field: "dedAmt",
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
      {
        headerName: "Ded. %",
        field: "dedPer",
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
      {
        headerName: "Copay Amount",
        field: "coPayAmt",
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
      {
        headerName: "Copay %",
        field: "copayPer",
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
      {
        headerName: "Copay Max. Limit",
        field: "coPayMaxLimitAmt",
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
    ]

    this.defaultValueForm = this.formBuilder.group({
      dedAmount: new FormControl(0),
      dedPercent: new FormControl(0),
      copayPercent: new FormControl(0),
      copayAmount: new FormControl(0),
      copayMaxLimit: new FormControl(0)
    })

    this.maternityDefaultValueForm = this.formBuilder.group({
      dedAmount: new FormControl(0),
      dedPercent: new FormControl(0),
      copayPercent: new FormControl(0),
      copayAmount: new FormControl(0),
      copayMaxLimit: new FormControl(0)
    })

    this.getCreditData();
  }

  getCreditData() {
    let payload = {
      Creditid: this.creditId,
      Branchid: this.branchId
    }
    this.billingService.GetCredit(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200) {
          this.creditData = response.response[0];
          this.sponsorData = response.response[0].creditItemGroupList;
          this.normalSponsorData = this.sponsorData.filter(data=> data.category == 'N');
          this.maternitySponsorData = this.sponsorData.filter(data=> data.category == 'M');
        } else {
          this.toastrService.show(response.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong, Try again.');
      }
    })
  }

  onTab(number) {
    this.normalCredit = false;
    this.maternityCredit = false;
  
    if (number == "1") {
      this.normalCredit = true;
    } else if (number == "2") {
      this.maternityCredit = true;
    } 
    
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onMaternityGridReady(params) {
    this.maternityGrid = params.api;
  }

  onRowValueChanged() {
    this.creditDataSaveLoading = true;
    this.creditData.creditItemGroupList = this.sponsorData;
    let creditItems: any [] = [];
    this.normalSponsorData.forEach(item=>{
      creditItems.push(
        {
          category :  item.category,
          coPayMaxLimitAmt :  item.coPayMaxLimitAmt ? item.coPayMaxLimitAmt : 0,
          copayAmt :  item.coPayAmt ? item.coPayAmt : 0,
          copayPer :  item.copayPer ? item.copayPer : 0,
          dedAmt : item.dedAmt ? item.dedAmt : 0,
          dedPer : item.dedPer ? item.dedPer : 0,
          entryId: item.entryId,
          groupId: item.groupId,
          groupName : item.groupName
        }
      )
    });

    this.maternitySponsorData.forEach(item=>{
      creditItems.push(
        {
          category :  item.category,
          coPayMaxLimitAmt :  item.coPayMaxLimitAmt ? item.coPayMaxLimitAmt : 0,
          copayAmt :  item.coPayAmt ? item.coPayAmt : 0,
          copayPer :  item.copayPer ? item.copayPer : 0,
          dedAmt : item.dedAmt ? item.dedAmt : 0,
          dedPer : item.dedPer ? item.dedPer : 0,
          entryId: item.entryId,
          groupId: item.groupId,
          groupName : item.groupName
        }
      )
    });

    let payload = {
      active : this.creditData.active,
      agentId : this.creditData.agentId,
      branchId : this.creditData.branchId,
      certificateNo : this.creditData.certificateNo,
      coPayAmnt : this.creditData.coPayAmnt || 0,
      coPayPcnt : this.creditData.coPayPcnt,
      creditAvailed : this.creditData.creditAvailed,
      creditId : this.creditData.creditId,
      creditItemGroupList : creditItems,
      creditLimit : this.creditData.creditLimit,
      creditRefNo : this.creditData.creditRefNo,
      creditType : this.creditData.creditType,
      currentCreditCategory : this.creditData.currentCreditCategory,
      dedAmount : this.creditData.dedAmount,
      dedPcnt : this.creditData.dedPcnt || 0,
      dependantNo : this.creditData.dependantNo,
      expiryDate : this.creditData.expiryDate.replace(/\//g, "-"),
      maxLimit : this.creditData.maxLimit,
      openDate : this.creditData.openDate.replace(/\//g, "-"),
      patientId : this.patientId,
      payerId : this.creditData.payerId,
      policyDate : this.creditData.policyDate.replace(/\//g, "-"),
      policyNo : this.creditData.policyNo,
      priority : this.creditData.priority,
      ruleId : this.creditData.ruleId,
      sponsorId : this.creditData.sponsorId,
      userId : Number(localStorage.getItem('user_id')),
      validUpto : this.creditData.validUpto.replace(/\//g, "-"),
      waitingPeriod: this.creditData.waitingPeriod.replace(/\//g, "-"),
    }

    const formData = new FormData();
    formData.append('CreditJson', JSON.stringify(payload));
    formData.append('InsCardImg', "");
    this.billingService.InsertCredit(formData).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.toastrService.success('Credit details updated successfully');
          this.sponsorDataEvent.emit({isChanged: true, creditData: creditItems});
          this.modalRef.hide();
        }else{
          this.toastrService.error(response.message);
        }
        this.creditDataSaveLoading = false;
      },error: (error:any)=>{
        this.toastrService.error('Something wents wrong, Try again');
        this.creditDataSaveLoading = false;
      }
    })
  }

  applyToAll(){
    let dedAmount=this.defaultValueForm.get("dedAmount").value;
    let dedPcnt=this.defaultValueForm.get("dedPercent").value;
    let coPayAmnt=this.defaultValueForm.get("copayAmount").value;
    let coPayPcnt=this.defaultValueForm.get("copayPercent").value;
    let copayMaxLimit=this.defaultValueForm.get("copayMaxLimit").value;

    this.normalSponsorData.forEach((element,i)=>{
        element.dedAmt = dedAmount ? dedAmount : 0;
        element.dedPer = dedPcnt ? dedPcnt : 0;
        element.coPayAmt = coPayAmnt ? coPayAmnt : 0;
        element.copayPer = coPayPcnt ? coPayPcnt : 0;
        element.coPayMaxLimitAmt = copayMaxLimit ? copayMaxLimit : 0;
      });

      this.gridApi.redrawRows();
      // this.onRowValueChanged();

  }

  applyMaternityToAll(){
    let dedAmount=this.maternityDefaultValueForm.get("dedAmount").value;
    let dedPcnt=this.maternityDefaultValueForm.get("dedPercent").value;
    let coPayAmnt=this.maternityDefaultValueForm.get("copayAmount").value;
    let coPayPcnt=this.maternityDefaultValueForm.get("copayPercent").value;
    let copayMaxLimit=this.maternityDefaultValueForm.get("copayMaxLimit").value;

    this.maternitySponsorData.forEach((element,i)=>{
        element.dedAmt = dedAmount ? dedAmount : 0;
        element.dedPer = dedPcnt ? dedPcnt : 0;
        element.coPayAmt = coPayAmnt ? coPayAmnt : 0;
        element.copayPer = coPayPcnt ? coPayPcnt : 0;
        element.coPayMaxLimitAmt = copayMaxLimit ? copayMaxLimit : 0;
      });

    this.maternityGrid.redrawRows();
    // this.onRowValueChanged();
  }

  onNormalCellValueChanged(event: CellValueChangedEvent){
    if(event.colDef.field == 'dedAmt'){
      this.normalSponsorData[event.rowIndex].dedPer = 0;
    }else if(event.colDef.field == 'dedPer'){
      this.normalSponsorData[event.rowIndex].dedAmt = 0;
    }

    if(event.colDef.field == 'coPayAmt'){
      this.normalSponsorData[event.rowIndex].copayPer = 0;
    }else if(event.colDef.field == 'copayPer'){
      this.normalSponsorData[event.rowIndex].coPayAmt = 0;
    }
    this.gridApi.setRowData(this.normalSponsorData)
  }

  onMaternityCellValueChanged(event: CellValueChangedEvent){
    if(event.colDef.field == 'dedAmt'){
      this.maternitySponsorData[event.rowIndex].dedPer = 0;
    }else if(event.colDef.field == 'dedPer'){
      this.maternitySponsorData[event.rowIndex].dedAmt = 0;
    }

    if(event.colDef.field == 'coPayAmt'){
      this.maternitySponsorData[event.rowIndex].copayPer = 0;
    }else if(event.colDef.field == 'copayPer'){
      this.maternitySponsorData[event.rowIndex].coPayAmt = 0;
    }
    this.maternityGrid.setRowData(this.maternitySponsorData)
  }

  onNormalDataInput(field: string){
    if(field == 'dedPercent'){
      this.defaultValueForm.get('dedAmount').setValue(0);
    }else if(field == 'dedAmount'){
      this.defaultValueForm.get('dedPercent').setValue(0);
    }

    if(field == 'copayPercent'){
      this.defaultValueForm.get('copayAmount').setValue(0);
    }else if(field == 'copayAmount'){
      this.defaultValueForm.get('copayPercent').setValue(0);
    }
  }

  onMaternityDataInput(field: string){
    if(field == 'dedPercent'){
      this.maternityDefaultValueForm.get('dedAmount').setValue(0);
    }else if(field == 'dedAmount'){
      this.maternityDefaultValueForm.get('dedPercent').setValue(0);
    }

    if(field == 'copayPercent'){
      this.maternityDefaultValueForm.get('copayAmount').setValue(0);
    }else if(field == 'copayAmount'){
      this.maternityDefaultValueForm.get('copayPercent').setValue(0);
    }
  }

}
