import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ErrorHandleService } from '../../client/services/error-handle.service';
import { EMRPatientInfo, EMRSponsorInfo } from '../interfaces/EMRPatientInfo';
import { Response } from '../../../_Models/Response';
import { BasicPatientInfo } from '../interfaces/BasicPatientInfo';
import { ConsultationList } from '../interfaces/ConsultationList';
import { VisitHistory } from '../interfaces/VisitHistory';
import { ChiefComplaints } from '../interfaces/ChiefComplaints';
import { PhysicalExamination } from '../interfaces/PhysicalExamination';
import { ReviewOfSymptoms } from '../interfaces/ReviewOfSymptoms';
import { MedicalDecition } from '../interfaces/MedicalDecition';
import { PlanAndProcedure } from '../interfaces/PlanAndProcedure';
import { MenstrualHistory } from '../interfaces/MenstrualHistory';
import { EMRVitalSign, EMRVitalSignForm } from '../interfaces/EMRVitalSign';
import { DrugsForm } from '../interfaces/DrugsForm';
import { PatientHistory } from '../interfaces/PatientHistory';
import { Questionare } from '../interfaces/Questionare';
import { ServiceOrderForm } from '../interfaces/ServiceOrderForm';
import { DentalExamination } from '../interfaces/DentalExamination';
import { TreatmentPlanForm } from '../interfaces/TreatmentPlanForm';

import { AllergyForm } from '../interfaces/AllergyForm';
import { Notes } from '../interfaces/Notes';
import { Router } from '@angular/router';
import { Treatmentfollowup } from '../interfaces/Treatmentfollowup';
import { Hooks } from '../interfaces/Hooks';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EMRService {

  private baseUrl: string = "";

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService,
    private router: Router,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  // to set and get global information for patient consultation sponsor
  SetEMRPatientSponsor(EMRSponsorInfo: EMRSponsorInfo) {
    sessionStorage.setItem('EMRSponsorInfo', JSON.stringify(EMRSponsorInfo));
    return true;
  }

  GetEMRSponsorInfo() {
    let EMRINFO = sessionStorage.getItem('EMRSponsorInfo');
    if (EMRINFO) {
      EMRINFO = JSON.parse(EMRINFO);
      let NewEMRINFO = {
        'CreditId': EMRINFO["CreditId"],
        'SponsorId': EMRINFO["SponsorId"],
        'SponsorName': EMRINFO["SponsorName"],
        'RuleName': EMRINFO["RuleName"],
        'WaitingPeriod':EMRINFO["WaitingPeriod"],
        'DhaRule':EMRINFO["DhaRule"],
      }
      return NewEMRINFO;
    }
    else {
      let NewEMRINFO = {
        'CreditId': 0,
        'SponsorId': 0,
        'SponsorName': '',
        'RuleName': '',
        'WaitingPeriod':"",
        'DhaRule':false
      }
      return NewEMRINFO;
    }
  }

  // to set and get global information for EMR
  SetEMRPatientInfo(EMRPatientInfo: EMRPatientInfo) {
    //  debugger
    sessionStorage.setItem('EMRPatientInfo', JSON.stringify(EMRPatientInfo));
    return true;
  }
  GoToEmr(patientData) {
    let patientInfo = {
      'VisitId': 0,
      'ConsultantId': Number(localStorage.getItem('Id')),
      'ConsultationId': 0,
      'PatientId': patientData.patientId,
      'VisitType': 0,
      'VisitStartTime': '',
      'VisitEndTime': '',
      'UserId': Number(localStorage.getItem('user_id')),
      'CrudType': 0,
      InsAmountLimit: 0,
      'insCovered': 0
    }

    let OutPut = this.SetEMRPatientInfo(patientInfo);
    if (OutPut) {
      this.router.navigate(['/EMR_Home']);
    }

  }

  GetEMRPatientInfo() {
    let EMRINFO = sessionStorage.getItem('EMRPatientInfo');
    if (EMRINFO) {
      EMRINFO = JSON.parse(EMRINFO);
      let NewEMRINFO = {
        'VisitId': EMRINFO["VisitId"],
        'ConsultantId': EMRINFO["ConsultantId"],
        'ConsultationId': EMRINFO["ConsultationId"],
        'PatientId': EMRINFO["PatientId"],
        'VisitType': EMRINFO["VisitType"],
        'VisitStartTime': EMRINFO["VisitStartTime"],
        'VisitEndTime': EMRINFO["VisitEndTime"],
        'UserId': EMRINFO["UserId"],
        'CrudType': EMRINFO["CrudType"],
        'lastConsultationId': EMRINFO["lastConsultationId"],
        'SponsorId': EMRINFO["SponsorId"],
        'InsAmountLimit': EMRINFO["InsAmountLimit"],
        // 'insCovered': EMRINFO["insCovered"]
        'insCovered': EMRINFO["InsCovered"],
        'ConsultationFee': EMRINFO["ConsultationFee"],
      }
      return NewEMRINFO;
    }
    else {
      let NewEMRINFO = {
        'VisitId': 0,
        'ConsultantId': Number(localStorage.getItem('Id')),
        'ConsultationId': 0,
        'lastConsultationId': 0,
        'PatientId': 0,
        'VisitType': 0,
        'VisitStartTime': '',
        'VisitEndTime': '',
        'UserId': Number(localStorage.getItem('user_id')),
        'CrudType': 0,
        'InsAmountLimit': 0,
        'insCovered': 0
      }
      return NewEMRINFO;
    }

  }
  // end of emr global info


  upper(obj) {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      let first = key.substr(0, 1).toUpperCase();
      first = first + key.substr(1);

      newobj[first] = obj[key];
    }
    return newobj;
  }
  // get basic patient info

  GetPatientInfo(PatientId: Number) {
    var url = "api/EMR/GetBasicPatientDetails";
    return this.http.post<Response<BasicPatientInfo>>(`${this.baseUrl}${url}`,
      {
        'PatientId': PatientId
      })
  }
  GetConsultation(InputDetails) {
    var url = "api/EMR/GetConsultation";
    return this.http.post<Response<ConsultationList[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  InsertVisit(InputDetails: EMRPatientInfo) {
    var url = "api/EMR/InsertVisit";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetVisits(PatientId: Number,ConsultantId:any) {
    var url = "api/EMR/GetVisitDetails";
    return this.http.post<Response<VisitHistory[]>>(`${this.baseUrl}${url}`,
      {
        'PatientId': PatientId,
        'ConsultantId':ConsultantId
      })
  }

  GetVisit(data:{PatientId:Number,VisitId:Number}) {
    var url = "api/EMR/GetVisitDetails";
    return this.http.post<Response<VisitHistory[]>>(`${this.baseUrl}${url}`,
      {
        'PatientId': data.PatientId,
        'Visitid':data.VisitId
      })
  }

  /***********************************CHEIF COMPLAINTS */
  SaveCheifComplaints(InputDetails: ChiefComplaints) {
    var url = "api/EMR/InsertUpdateChiefComplaints";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetChiefComplaints(InputDetails) {
    var url = "api/EMR/GetChiefComplaints";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  /**************************************Physical Examination */
  SavePhysicalExaminationDetails(InputDetails: PhysicalExamination) {
    var url = "api/EMR/InsertUpdatePhysicalExaminationDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetPhysicalExaminationDetails(InputDetails) {
    var url = "api/EMR/GetPhysicalExaminationDetails";
    return this.http.post<Response<PhysicalExamination[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  /********************************************Reveiew of symptoms********* */

  SaveReviewOfSymptoms(InputDetails: ReviewOfSymptoms) {
    var url = "api/EMR/InsertUpdateReviewOfSymptoms";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetReviewOfSymptoms(InputDetails) {
    var url = "api/EMR/GetReviewOfSymptoms";
    return this.http.post<Response<ReviewOfSymptoms[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  /**********************************************Medical Decistion */

  SaveMedicalDecision(InputDetails: MedicalDecition) {
    var url = "api/EMR/InsertUpdateMedicalDecision";
    return this.http.post<Response<MedicalDecition>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetMedicalDecision(InputDetails) {
    var url = "api/EMR/GetMedicalDecision";
    return this.http.post<Response<MedicalDecition[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetReferredTo() {
    var url = "api/EMR/GetRefferedTo";
    return this.http.post<Response<MedicalDecition[]>>(`${this.baseUrl}${url}`, {Id: 0})
  }
  /*****************************************************Plan and Procedure */

  SavePlanAndProcedure(InputDetails: PlanAndProcedure) {
    var url = "api/EMR/InsertUpdatePlanAndProcedure";
    return this.http.post<Response<PlanAndProcedure>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetPlanAndProcedure(InputDetails) {
    var url = "api/EMR/GetPlanAndProcedure";
    return this.http.post<Response<PlanAndProcedure[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  /*******************************************Menstrual History*** */
  SaveMenstrualHistory(InputDetails: MenstrualHistory) {
    var url = "api/EMR/InsertUpdateMenstrualHistory";
    return this.http.post<Response<MenstrualHistory>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetMenstrualHistory(InputDetails) {
    var url = "api/EMR/GetMenstrualHistory";
    return this.http.post<Response<MenstrualHistory[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  ////////////////////////////////////////////////////////////

  GetICDLabel(InputDetails) {
    var url = "api/MasterData/GetICDLabel";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)

  }

  /*******************************************Narrative Diagonisis & ICD*** */
  SaveNarrativeDiagnosis(InputDetails: any) {
    var url = "api/EMR/InsertUpdateNarrativeDiagnosisICD";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetNarrativeDiagonosis(InputDetails: any): Observable<any> {
    const url = "api/EMR/GetNarrativeDiagnosisICD";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails).pipe(
      map(response => {
        response.response.forEach(item => {
          item.icdLabelList.forEach(icd => {
            if (icd.onsetDate === '01/01/1900') {
              icd.onsetDate = '';
            }
          });
        });
        return response;
      })
    );
  }

  GetICDFOREMRHome(InputDetails: any): Observable<any> {
    const url = "api/EMR/GetICDFOREMRHome";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails).pipe(
      map(response => {
        response.response.forEach(item => {
          item.icdLabelList.forEach(icd => {
            if (icd.onsetDate === '01/01/1900') {
              icd.onsetDate = '';
            }
          });
        });
        return response;
      })
    );
  }
  ///////////////////////////////////////VITAL SIGN///////////////////////////////
  GetEMRVitalSign(InputDetails) {
    var url = "api/EMR/GetEMRVitalSign";
    return this.http.post<Response<EMRVitalSign[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  SaveEMRVitalSigns(InputDetails: EMRVitalSignForm) {
    var url = "api/EMR/InsertUpdateEMRVitalSign";
    return this.http.post<Response<EMRVitalSignForm>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetAllEMRVitalSignByVisitId(InputDetails) {
    var url = "api/EMR/GetAllEMRVitalSignByVisitId";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetPrevVitalSigns(InputDetails) {
    var url = "api/EMR/GetEMRVitalSignHistory";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  //////////////////////////DRUGS AND MEDICATION///////////////////////////////////////////////////
  GetDrugsAutoComplete(InputDetails) {
    var url = "api/EMR/GetDrugsAutoComplete";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetConsultantDrugs(InputDetails) {
    var url = "api/Consultant/GetConsultantDrugs";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  SaveDrugs(InputDetails: DrugsForm) {
    var url = "api/EMR/InsertUpdateDrugsEMR";
    return this.http.post<Response<DrugsForm>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetDrugsEMR(InputDetails) {
    var url = "api/EMR/GetDrugsEMR";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetMedicationForEMRHome(InputDetails) {
    var url = "api/EMR/GetMedicationForEMRHome";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  /////////////////////////////////Patient History/////////////////////////////////////////////////////////
  SavePatientHistory(InputDetails: PatientHistory) {
    var url = "api/EMR/InsertUpdatePatientHistoryEMR";
    return this.http.post<Response<PatientHistory>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetPatientHistory(InputDetails) {
    var url = "api/EMR/GetPatientHistoryEMR";
    return this.http.post<Response<PatientHistory>>(`${this.baseUrl}${url}`, InputDetails)
  }
  //////////////////////////////Questionare/////////////////////////////////////////////////////

  GetQuestionare(InputDetails) {
    var url = "api/EMR/GetPatientQuestionareEMR";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  SaveQuestionare(InputDetails: Questionare[]) {
    var url = "api/EMR/InsertUpdatePatientQuestionareEMR";
    return this.http.post<Response<Questionare[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  ////////////////////////////////DOCUMENTS////////////////////////////////////////////////////////////
  CreateFolder(InputDetails) {
    var url = "api/EMR/InsertUpdateFolderEMR";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetFolders(InputDetails) {
    var url = "api/EMR/GetPatientFoldersEMR";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  UploadDocuments(InputDetails) {
    var url = "api/EMR/UploadFileEMR";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetListingOfFiles(InputDetails) {
    var url = "api/EMR/GetPatientFoldersEMR";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }

  DeleteFIle(InputDetails) {
    var url = "api/EMR/DeleteFileEMR";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }

  ////////////////////ServiceOrders//////////////////////////////////////
  SaveServiceOrder(InputDetails: ServiceOrderForm) {
    var url = "api/EMR/InsertServiceItemsEMR";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetServiceItemsEMR(InputDetails) {
    var url = "api/EMR/GetServiceItemsEMR";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetGeneralServiceItemsEMR(InputDetails) {
    var url = "api/EMR/GetGeneralServiceItemsEMR";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetDentalServiceItemsEMR(InputDetails) {
    var url = "api/EMR/GetDentalServiceItemsEMR";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetAllServiceItemsForEMRHome(InputDetails) {
    var url = "api/EMR/GetAllServiceItemsForEMRHome";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetAllResultPublishedServiceItems(InputDetails) {
    var url = "api/EMR/GetAllResultPublishedServiceItems";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  generateErx(InputDetails: any) {
    var url = "api/ERX/GenerateERX";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  cancelErx(InputDetails: any) {
    var url = "api/ERX/CancelERX";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetEPrescriptionDetails(InputDetails: any) {
    var url = "api/ERX/GetEPrescriptionDetails";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  ///////////////////////////Dental Examination/////////////////////////////////////////////
  SaveDentalExamination(InputDetails: DentalExamination) {
    var url = "api/EMR/InsertDentalExamination";
    return this.http.post<Response<DentalExamination>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetDentalExamination(InputDetails) {
    var url = "api/EMR/GetDentalExaminationEMR";
    return this.http.post<Response<DentalExamination[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  /////////////////////////////Treatment Plan Form////////////////////////////////////////////////////////
  // used for followup for all department
  SaveTreatmentPlan(InputDetails: TreatmentPlanForm) {
    var url = "api/EMR/InsertDentalProcedureEMR";
    return this.http.post<Response<TreatmentPlanForm>>(`${this.baseUrl}${url}`, InputDetails)
  }
  SaveAllergy(InputDetails: AllergyForm) {
    var url = "api/EMR/InsertUpdateDeleteAllergy";
    return this.http.post<Response<AllergyForm>>(`${this.baseUrl}${url}`, InputDetails)
  }
  SaveNotes(InputDetails: Notes) {
    var url = "api/EMR/InsertUpdateDeletePatNotes";
    return this.http.post<Response<Notes>>(`${this.baseUrl}${url}`, InputDetails)
  }
  SaveHook(InputDetails:Hooks) {
    var url = "api/MasterData/InsertUpdateDeleteHook";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }
  GetHook(InputDetails) {
    var url = "api/MasterData/GetHook";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetDentalProcedureEMR(InputDetails) {
    // var url = "api/EMR/GetDentalProcedureEMR";
    var url = "api/EMR/GetFollowUpProcedureEMR";

    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetFollowUpStatus(InputDetails) {
    var url = "api/EMR/GetDentalProcedureEMR";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  CompleteDentalProcedureEMR(InputDetails) {
    var url = "api/EMR/CompleteDentalProcedureEMR";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }
  /////////////////////FOLLO UP SERVICES/////////////////////////////////////////

  SaveFollowp(InputDetails: Treatmentfollowup) {
    var url = "api/EMR/InsertUpdateDeleteTreatmentFollowUp";
    return this.http.post<Response<Treatmentfollowup>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetFollowUpDetails(InputDetails) {
    var url = "api/EMR/GetTreatmentFollowUp";
    return this.http.post<Response<Treatmentfollowup[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  /////////////////////////////////////////////////////////////////////////

  GetAllergyType(Input) {
    var url = "api/EMR/GetAllergyType/";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  GetAllergySeverity(Input) {
    var url = "api/EMR/GetAllergySeverity/";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );

  }
  GetAllergy(InputDetails) {
    var url = "api/EMR/GetAllergy";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }
  SaveSickLeave(InputDetails) {
    var url = "api/EMR/InsertUpdateDeleteSickLeave";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)

  }
  getSick(patientId, branchId) {
    var url = "api/EMR/GetSickLeave";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        patientId: patientId,
        branchId: branchId
      })

  }
  getNotes(noteId, patientId, branchId, ConsultantId, NoteType) {
    var url = "api/EMR/GetPatNotesForEMR";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        noteId: noteId,
        patientId: patientId,
        branchId: branchId,
        ConsultantId: ConsultantId,
        NoteType: NoteType
      })

  }

  printSickLeave(entryId,branchId, clientId) {
    var url = "api/Report/PrintSickleave";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
    {
      entryId:entryId,
      branchId:branchId,
      clientId: clientId
    })

  }
  printRefferLetter(InputDetails) {
    var url = "api/Report/PrintReferalLetter";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }

  getSymptoms(payload: any): Observable<Response<any>>{
    var url = "api/MasterData/GetSymptom";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getDiseases(payload: any): Observable<Response<any>>{
    var url = "api/EMR/GetConsultantDisease";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getDetailsOfDisease(payload: any): Observable<Response<any>>{
    var url = `api/Consultant/GetDiseaseDetails/${payload.diseasId}`;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getAllSigns(payload: any): Observable<Response<any>>{
    var url = `api/MasterData/GetSign`;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getGeneralEntryStatus(payload: any): Observable<Response<any>>{
    var url = `api/EMR/GetEMREntryStatus`;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getCFormOrder(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/Consultant/GetConsultantEMRTabView`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  copyEMRData(payload): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/EMR/CopyEMR`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  getInvestigationFlowSheetData(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/EMR/GetInvestigationFlowSheet`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  getAllStaffs(payload: any): Observable<Response<any>>{
    var url = "api/MasterData/GetStaff";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  CancelServiceOrder(payload: any) {
    var url = "api/ServiceOrder/CancelServiceOrder";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetGaitAnalysis(payload: any) {
    var url = "api/MasterData/GetGaitAnalysis";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetPosturalAnalysis(payload: any) {
    var url = "api/MasterData/GetPosturalAnalysis";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetPalpation(payload: any) {
    var url = "api/MasterData/GetPalpation";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetDeepTendonReflex(payload: any) {
    var url = "api/MasterData/GetDeepTendonReflex";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetDeepSensation(payload: any) {
    var url = "api/MasterData/GetDeepSensation";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetSuperficialSensation(payload: any) {
    var url = "api/MasterData/GetSuperficialSensation";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetToneAssesment(payload: any) {
    var url = "api/MasterData/GetToneAssesment";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetSkinAssesment(payload: any) {
    var url = "api/MasterData/GetSkinAssesment";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetAmputation(payload: any) {
    var url = "api/MasterData/GetAmputation";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetBodyBuilt(payload: any) {
    var url = "api/MasterData/GetBodyBuilt";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetConsciousness(payload: any) {
    var url = "api/MasterData/GetConsciousness";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetMentalStatus(payload: any) {
    var url = "api/MasterData/GetMentalStatus";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetHandedness(payload: any) {
    var url = "api/MasterData/GetHandedness";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetTypeOfPain(payload: any) {
    var url = "api/MasterData/GetTypeOfPain";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetPainDescription(payload: any) {
    var url = "api/MasterData/GetPainDescription";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetSpasticity(payload: any) {
    var url = "api/MasterData/GetSpasticity";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  EmrPhysioAnalysisSave(payload: any) {
    var url = "api/EMR/EmrPhysioAnalysisSave";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  EmrPhysioAnalysisGet(payload: any) {
    var url = "api/EMR/EmrPhysioAnalysisGet";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  VerifyInstructor(payload: any) {
    var url = "api/EMR/VerifyInstructor";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  // Add Alert
  getAlertList(payload: any) {
    var url = "api/EMR/GetPatientAlert";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  addAlert(payload: any) {
    var url = "api/EMR/InsertUpdateDeletePatAlerts";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetFamilyRelations() {
    var url = "api/EMR/GetFamilyRelations";
    return this.http.get<Response<any>>(`${this.baseUrl}${url}`)
  }

  GetSocialHistoryTypeOptions() {
    var url='api/EMR/GetSocialHistoryTypeOptionsJson'
    return this.http.get<Response<any>>(`${this.baseUrl}${url}`)
  }

  GetBillListByTransIdForEdit(payload) {
    var url='api/Bill/GetBillListByTransIdForEdit'
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload)
  }

  InsertManualDosage(payload) {
    var url='api/MasterData/InsertManualDosage'
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload)
  }

  GetAllergen(payload) {
    var url='api/EMR/GetAllergen'
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload)
  }

  GetAllergenReaction(payload) {
    var url='api/EMR/GetAllergenReaction'
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload)
  }

  SearchDrugForAllergy(payload) {
    var url='api/EMR/SearchDrugForAllergy'
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload)
  }

  
}
