import { Component, OnInit, QueryList, ViewChild, } from "@angular/core";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { SearchPatientModalComponent } from "../../client/frontoffice/searchpatient-modal/searchpatient-modal.component";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { noop, Observable, Observer, of, Subscription } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Response } from '../../../_Models/Response';
import { globalvars } from "../../../shared/models/GlobalVars";
import { SharedService } from "../../../shared/shared.service";
import { map, switchMap, tap } from 'rxjs/operators';
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";
import { EMRService } from '../../emr/services/emr.service';
import { DatePipe } from "@angular/common";
import { Treatmentform } from "../interfaces/TreatmentForm";
import { ToastrService } from "ngx-toastr";
import { TreatmentsService } from '../services/treatments.service';
import { SubLocationSettingsService } from "../../configurations/sub-location-settings/sub-location-settings.service";
import Swal from 'sweetalert2';
import { ServicePoint } from "../../client/interfaces/ServicePoint";
import { BasicPatientInfo } from '../../emr/interfaces/BasicPatientInfo';
import { ConfigurationService } from "../../client/services/configuration.service";
import { ConsultantDeskService } from "../../client/services/consultant.service";
import { StaffList } from '../../client/interfaces/StaffList';
import { Location } from '@angular/common';
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { QrcodeScanModalComponent } from "../../../shared/modals/qrcode-scan-modal/qrcode-scan-modal.component";
import { EMRSponsorInfo } from "../../emr/interfaces/EMRPatientInfo";
import { da } from "date-fns/locale";
import { ApplyVaccineModalComponent } from "../modals/apply-vaccine-modal/apply-vaccine-modal.component";
import { SponsorRuleItemNoteComponent } from "../../configurations/modals/sponsor-rule-item-note/sponsor-rule-item-note.component";
import { DrugDosageComponent } from "../../emr/consultation-general-single-page/modals/drug-dosage/drug-dosage.component";
import { AddRouteComponent } from "../modals/add-route/add-route.component";
import { PatientService } from "../../client/services/patient.service";

interface servicItems {
  itemId: number;
  itemCode: string;
  itemName: string
}


@Component({
  selector: "app-treatment-details",
  templateUrl: "./treatment-details.component.html",
  styleUrls: ["./treatment-details.component.scss"],
})
export class TreatmentDetailsComponent implements OnInit {
  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;


  suggestions$?: Observable<servicItems[]>;
  search?: string;
  errorMessage?: string;
  private baseUrl: string = "";
  BranchesId: any;
  currentDate: any;
  currentTime: any;
  isLoading: boolean = false;
  patients: any[] = [];
  PatientInformation = {
    "regNo": "",
    "patient": "",
    "patientId":"",
    "patientName":"",
    "consultantName":''
  };

  BasicPatientInfo: BasicPatientInfo = new BasicPatientInfo();
  ServicePointList: ServicePoint[] = [];
  globals: globalvars = new globalvars();
  subscription: Subscription;
  public submitted: boolean = false;

  TreatmentDetailsform: FormGroup;
  GetDosageListing: any;
  TreatmentEditId: number = 0;
  TreatmentDetailsEdit: any;
  Treatmentform: Treatmentform = new Treatmentform();
  patientId: any;
  NurseDAta:any;
  GetRouteListing: any;
  Staffs: StaffList[] = [];
  consultationId: any;
  IsApplyTreatment=0;
  creditId = 0;
  getItemId = 0;
  getOrderId = 0;
  isApply = 0;
  serviceitemDetails:any=[]
  addItemDisable: boolean=false;
  SpointId: any;
  filterData: any;
  getDetails: any;
  prevUrl: any;
  vaccinationRouteList: any;
  healthPlatform: any;
  functionPermissionData: any;

  constructor(private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private sharedservice: SharedService,
    private configurationManager: ConfigurationManager,
    private EMRService: EMRService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private TreatmentsService: TreatmentsService,
    private subLocationSettingsService: SubLocationSettingsService,
    private router: Router,
    private ConfigurationService: ConfigurationService,
    private ConsultantDeskService: ConsultantDeskService,
    private _location: Location,
    private modalService: BsModalService,
    private patientService: PatientService,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;


    if (this.router.getCurrentNavigation().extras.state) {
      this.TreatmentEditId = this.router.getCurrentNavigation().extras.state.id;
      this.patientId = this.router.getCurrentNavigation().extras.state.id;
      this.consultationId=this.router.getCurrentNavigation().extras.state.consultationId
      this.IsApplyTreatment=this.router.getCurrentNavigation().extras.state.IsApplyTreatment
      this.creditId = this.router.getCurrentNavigation().extras.state.creditId;
      this.addItemDisable = this.router.getCurrentNavigation().extras.state?.addItemDisable == true ? true :false
      this.SpointId=this.router.getCurrentNavigation().extras.state.SpointId;
      // this.getItemId = this.router.getCurrentNavigation()?.extras?.state?.getItemId;
      // console.log('this.getItemId', this.getItemId);
      this.filterData=this.router.getCurrentNavigation().extras.state.filterData;
      this.getDetails=this.router.getCurrentNavigation().extras.state.getDetails;
      this.prevUrl=this.router.getCurrentNavigation().extras.state.prevUrl
      // debugger

    }

    if (JSON.parse(sessionStorage.getItem('getItemId'))) {
      this.getItemId = Number(JSON.parse(sessionStorage.getItem('getItemId')))
    }
    if (JSON.parse(sessionStorage.getItem('getItemId'))) {
      this.getOrderId = Number(JSON.parse(sessionStorage.getItem('getOrderId')))
    }
    if (JSON.parse(sessionStorage.getItem('getItemId'))) {
      this.isApply = Number(JSON.parse(sessionStorage.getItem('apply')))
    }

  }

  ngOnInit(): void {

    let currentappDate = new Date();
    this.currentDate = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    this.currentTime = this.datepipe.transform(new Date(), 'dd-MM-yyyy hh:mm a');
    this.currentTime = this.currentTime.split(' ')[1] + " " + this.currentTime.split(' ')[2];

    this.TreatmentDetailsform = new FormGroup({
      id: new FormControl(0),
      RegNo: new FormControl(""),
      PatientName: new FormControl(""),
      patientId: new FormControl(0),
      performingStaff: new FormControl("0"),
      treatmentDate: new FormControl(this.currentDate),
      treatmentDetails: new FormControl(""),
      treatmentRemarks: new FormControl(""),
      treatmentNumber: new FormControl("<<New>>"),
      branchId: new FormControl(this.BranchesId),
      itemDetails: new FormArray([]),
      servicePoint: new FormControl( this.SpointId ? Number(this.SpointId) :0),
      IsApplyTreatment:new FormControl(this.IsApplyTreatment),
      OrderId: new FormControl(0),
    });

    // if (this.SpointId) {
    //   setTimeout(() => {
    //     this.TreatmentDetailsform.get('servicePoint').patchValue(Number(this.SpointId));
    //   }, 2000);
    // }

    console.log(this.TreatmentDetailsform.value.servicePoint)

   this.dropDownAutoComplete()


    this.getApplicationSettings();
    this.functionPermission();
    this.GetServicePoints();
    this.getPatientDetails();
    this.GetDosage();
    this.GetRoute();
    this.GetPerformingStaff();
    this.TreatmentDetailsform.patchValue({
      performingStaff: Number(localStorage.getItem('staffId'))
    })

    if (this.TreatmentEditId > 0) {
      this.GetEditDetails(this.TreatmentEditId);
    } 
    // else if(this.getOrderId > 0){
    //   this.GetEditDetails(0);
    // }
    this.getVaccinationRoutes()

  }

  dropDownAutoComplete(){
     /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
     this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.search);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          return this.http.post<Response<servicItems[]>>(
            `${this.baseUrl}api/EMR/GetEMRServiceItem`, {
            "ServiceName": query,
            "branchId": this.BranchesId,
            "GroupId": 0,
            "consultantId": 0,
            CurrentDate: this.datepipe.transform(new Date(), "dd-MM-yyyy"),
            CreditId: this.creditId,
            Category: 'N',
            SPointId:Number(this.TreatmentDetailsform.value.servicePoint),
          }).pipe(
            map((data) => data && data.response || []),
            tap(() => noop, err => {
              this.errorMessage = err && err.message || 'Something goes wrong';
            })
          );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  searchevent(patient: any) {
    this.TreatmentDetailsform.patchValue({ patientId: patient.data.patientId });
    this.TreatmentDetailsform.patchValue({ RegNo: patient.data.regNo });
    this.TreatmentDetailsform.patchValue({ PatientName: patient.data.patient });
  }
  showBasicDialog() {
    this.modaltemp.open();
  }
  loadItem(itemInfo) {
    // let input={
    //   "consultantId": 0,
    //   "drugId": drugInfo.drugId
    // };
    // input
    // this.EMRService.GetConsultantDrugs(input).subscribe(
    //   (res) => {
    //     if(res.status==200)
    //     {
    //       this.addMapFormGroup(res.response);
    //     }
    //   });

    this.addMapFormGroup(itemInfo);
  }

  public addMapFormGroup(data: any[] = [], saved = 0) {
    const ServiceItems = this.TreatmentDetailsform.get("itemDetails") as FormArray;
    const values = Object.keys(data).map(key => data[key]);
    if (data.length > 0) {
      this.serviceitemDetails=data
  
      data.forEach((element, i) => {
        let filteredArray = ServiceItems.value.filter(value => value.itemId == element.itemId);
        if (filteredArray.length == 0) {
          ServiceItems.push(this.createListGroup(element, saved));
          
        }
        else {
          this.toastr.error("Failure: " + "Service Items Already added");
        }
      });

    }
    else if (values.length > 0) {
  
      let filteredArray = ServiceItems.value.filter(value => value.itemId == values[0]);
      if (filteredArray.length == 0) {
        ServiceItems.push(this.createListGroup(data, saved));
        this.serviceitemDetails.push(data)
      }
      else {
        this.toastr.error("Failure: " + "Service Items Already added");
      }

    }
    // else if(data){

    //  ServiceItems.push(this.createListGroup(data, saved));
    // }
    this.search = "";
    console.log("after",this.serviceitemDetails)
  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {

    if (!data['startDate']) data['startDate'] = this.currentDate;
    if (!data['endDate']) data['endDate'] = this.currentDate;
    if (!data['startTime']) data['startTime'] = this.currentTime;
    if (!data['endTime']) data['endTime'] = '';

    if (saved == 0) {
      if (!data['qty']) { data['qty'] = 1; }
    }
    if (!data['dosage']) data['dosage'] = 0;
    if (!data['route']) data['route'] = 0;

    return new FormGroup({
      itemId: new FormControl(data["itemId"]),
      itemName: new FormControl(data["itemName"]),
      qty: new FormControl(data['qty']),
      orderDate: new FormControl(this.currentDate),
      route: new FormControl(data['route']),
      dosage: new FormControl(data['dosage']),
      startDate: new FormControl(data['startDate']),
      startTime: new FormControl(data['startTime']),
      endDate: new FormControl(data['endDate']),
      endTime: new FormControl(data['endTime']),
      notes: new FormControl(data['notes']),
      isFinished: new FormControl((data['isFinished'] == 1 || (data["itemId"] == this.getItemId && this.isApply == 1)) ? true : false),
    });

  }
  goback() {
    sessionStorage.removeItem('getItemId');
    sessionStorage.removeItem('getOrderId');
    sessionStorage.removeItem('apply');

    // Navigate back
    if(this.prevUrl){
      this.router.navigate(['/Treatment_Dashboard'], { state:{filterData:this.filterData,getDetails:this.getDetails}})
    }else{
      this._location.back();
    }
  }

  save(crud) {
    if (this.submitted) { return;}

    this.Treatmentform = this.TreatmentDetailsform.getRawValue();

    let performingStaff = this.Treatmentform.performingStaff;//performingStaff CONVERSION TO INT
    this.Treatmentform.performingStaff = Number(performingStaff);

    if(this.Treatmentform.performingStaff == 0){
      this.toastr.warning('Please choose performing staff!')
      return
    }

    let endTimeArray = [];
    let applyVaccine=[]
    console.log('itemDetails', this.TreatmentDetailsform.get('itemDetails'));
    this.TreatmentDetailsform.get('itemDetails').value.find(x => {
      
      if(x.isFinished == true && x.endTime == '') {
        endTimeArray.push(x);
      }

     if(this.serviceitemDetails.some(item => item.itemId === x.itemId && item.vaccinationStatus == false && x.isFinished && item.vaccineValidation)){
      applyVaccine.push(x)
     };
      
    })

    console.log('endTimeArray.length', endTimeArray.length);
    if(endTimeArray.length !== 0){
      this.toastr.warning('End time is required!');
      return
    }

    if(applyVaccine.length !== 0 && this.addItemDisable){
      this.toastr.warning('Apply vaccine for '+applyVaccine[0].itemName);
      return
    }

    let servicePoint = this.Treatmentform.servicePoint;//servicePoint CONVERSION TO INT
    this.Treatmentform.servicePoint = Number(servicePoint);

    if (this.Treatmentform.patientId > 0) {
      if (this.Treatmentform.itemDetails.length > 0) {
        this.submitted = true;

        this.Treatmentform.itemDetails.forEach((a) => {
          a.route = Number(a.route);
          a.dosage = Number(a.dosage);
          this.submitted === false;
          a.isFinished = (a.isFinished == true ? 1 : 0);
        });
       
        this.isLoading = true; 
        
        
        this.TreatmentsService.SaveTreatmentDetails({
          ...this.Treatmentform,
          consultationId:this.consultationId
        }
        ).subscribe(
          (res) => {

            if (res.status == 200 && res.message == "Success") {
              if (crud == 2) {
                this.isLoading = false;

                this.toastr.success("Success: " + "Treatment details Updated Successfully...");
              }
              else {
                this.isLoading = false;

                this.toastr.success("Success: " + "Treatment details Saved Successfully...");
              }

            }
            else {
              this.isLoading = false;

              Swal.fire(
                'Error!',
                'Some unexpected error occures! try again later.',
                'error'
              )
            }
          });

      }

      else {

        // this.isLoading = false;

        this.toastr.warning("Warning: " + "Please select at least one item to continue");
      }
    }
    else {
      // this.isLoading = false;

      this.toastr.warning("Warning: " + "Please select a patient to continue");
    }
  }
  onDateChange(date, name) {
    if (typeof (date) == "object") {
      let cdate = this.datepipe.transform(date, 'dd-MM-yyyy');
      this.TreatmentDetailsform.controls['treatmentDate'].patchValue(cdate);
    }
  }
  onDayChange(date, i, controlName) {
    if (typeof (date) == "object") {
      let cdate = this.datepipe.transform(date, 'dd-MM-yyyy');
      const faControl = (<FormArray>this.TreatmentDetailsform.controls['itemDetails']).at(i);
      if (controlName == "startDate") {
        faControl['controls'].startDate.patchValue(cdate);
      }
      else if (controlName == "endDate") {
        faControl['controls'].endDate.patchValue(cdate);
      }

    }
  }

  RemoveFromTreatmentList(index,itemDetails) {
    var Value = new FormArray([]);
    Value = this.TreatmentDetailsform.get("itemDetails") as FormArray;
    Value.removeAt(index);
  }
  // RemoveFormArray(index, data?) {
  //   var Value = new FormArray([]);
  //   Value = this.resultForm.get(data) as FormArray;
  //   Value.removeAt(index);
  // }


  GetServicePoints() {
    //GetAssociativeLocation
    let input = {
      SPointId: 0,
      ShowAll: 0,
      BranchId: this.globals.HospitalId,
      LocationId: this.globals.Location.locationId
    };
    this.subLocationSettingsService.GetServicePoints(input).subscribe((res) => {
      this.ServicePointList = res.response;
    });
  }

  getPatientDetails() {
    this.EMRService.GetPatientInfo(this.patientId).subscribe((res) => {
      this.BasicPatientInfo = res.response[0];
      this.TreatmentDetailsform.patchValue({ patientId: this.BasicPatientInfo?.patientId });
      this.TreatmentDetailsform.patchValue({ RegNo: this.BasicPatientInfo?.regNo });
      this.TreatmentDetailsform.patchValue({ PatientName: this.BasicPatientInfo?.patientName });
      // if (this.SpointId) {
      //   this.TreatmentDetailsform.get('servicePoint').patchValue(Number(this.SpointId));
      // }
    })
  }
  GetEditDetails(id) {
    let input = {
      "DateFrom": '',
      "DateTo": '',
      "PatientId": 0,
      "Id": id,
      "PatientName": "",
      "Mobile": "",
      "RegNo": "",
      "TreatmentNumber": '',
      "branchId": this.BranchesId,
      "ConsultationId": this.consultationId,
      SpointId:Number(this.TreatmentDetailsform.value.servicePoint),    
    };

    this.TreatmentsService.GetTreatments(input).subscribe(
      (res) => {
        if (res.status == 200) {
          this.TreatmentDetailsEdit = res.response[0];
          
          if (this.TreatmentDetailsEdit) {           
            this.TreatmentDetailsform.patchValue({...this.TreatmentDetailsEdit,servicePoint:this.TreatmentDetailsform.value.servicePoint,itemDetails:[]});
            this.patientId = this.TreatmentDetailsEdit.patientId;
            this.getPatientDetails();
            this.clearAllItems()
            this.addMapFormGroup(this.TreatmentDetailsEdit.itemDetails, 1);
          }

        }
      });
  }

  clearAllItems() {
    const itemDetailsArray = this.TreatmentDetailsform.get('itemDetails') as FormArray;
    while (itemDetailsArray.length) {
      itemDetailsArray.removeAt(0);
    }
  }

  GetDosage() {
    let ShowAll = 0;
    this.ConfigurationService.GetDosage(
      this.globals.HospitalId,
      ShowAll
    ).subscribe((res) => {
      this.GetDosageListing = res.response;
    });
  }


  GetRoute() {
    this.ConsultantDeskService.GetRoute(this.globals.HospitalId).subscribe(
      (res) => {
        this.GetRouteListing = res.response;
      }
    );
  }
  async GetPerformingStaff() {
    let Input = {
      "StaffId": 0,
      "ShowAll": 0,
      "BranchId": this.globals.HospitalId,
    }

    let staffData = await this.TreatmentsService.GetPerformingStaff(Input).toPromise();
    // console.log('staffData', staffData);
    // this.TreatmentsService.GetPerformingStaff(Input).subscribe((res) => {
      this.Staffs = staffData.response;
      this.NurseDAta = this.Staffs.filter(x => x.categoryId === 4);

    // });
  }
  clearForm() {
    this.TreatmentDetailsform.patchValue({
      id: 0,
      PatientName: '',
      // isDisplayed: true,
      // taxLedger: ''
    });
  }
  SearchPatientDetail(patient: any) {
    this.GetPatientByRegNo();
    }
   GetPatientByRegNo() {
    this.TreatmentsService.SearchPatientByRegNo(this.TreatmentDetailsform.get("RegNo").value).subscribe((res) => {
      this.patients = res.response;
      this.patientId = this.patients[0].patientId;
      this.PatientInformation=this.patients[0]
      this.TreatmentDetailsform.patchValue({ PatientName: this.PatientInformation.patient});
      this.TreatmentDetailsform.patchValue({ patientId: this.PatientInformation.patientId });
      this.TreatmentDetailsform.patchValue({ RegNo: this.PatientInformation.regNo });

      if (this.patients.length > 0) {
        if (this.patients[0].active == "c") {
          this.toastr.warning("This Patient is blocked");
          this.clearForm();
          this.TreatmentDetailsform.patchValue({ regNo: this.patients[0].RegNo });
          this.patients = [];
        }
      } else {
        this.toastr.warning("No patients found");
      }
    });
  }

  openQrCodeScanner(){
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      availableStaffs: this.NurseDAta
    }
    const modalRef = this.modalService.show(QrcodeScanModalComponent, Object.assign({}, config,  { class: 'modal-sm', initialState }));
    modalRef.content.staffEmitter.subscribe((res: any)=>{
      if(res.status){
        this.TreatmentDetailsform.patchValue({
          performingStaff: res.staffId
        })
      }
    })
  }

  getvaccineValidation(id:any){
    const targetObject = this.serviceitemDetails.find(item => item.itemId === id);

    // Retrieve the "vaccineValidation" value or provide a default value if not found
    const vaccineValidationValue = targetObject ? targetObject.vaccineValidation : false;
    return vaccineValidationValue
  }

  applyVaccine(id){
    
    const targetObject = this.serviceitemDetails.find(item => item.itemId === id);
    const targetObjectIndex = this.serviceitemDetails.findIndex(item => item.itemId === id);
    console.log("targetObject",targetObject)
    console.log("this.TreatmentDetailsEdit",this.TreatmentDetailsEdit)
    let vaccineData:VaccineData={
      patientName: this.TreatmentDetailsEdit.patientName,
      consultantName: this.TreatmentDetailsEdit.consultantName,
      itemName: targetObject.itemName,
      orderDetId: targetObject.orderDetId,
      vpId: targetObject.vpId,
      vdqId: targetObject.vdqId,
      vduId: targetObject.vduId,
      patientId: this.TreatmentDetailsEdit.patientId,
      admQty: targetObject.admQty,
      lotNumber: targetObject.lotNumber,
      expiryDate: targetObject.expiryDate,
      vaccineDate: targetObject.vaccineDate,
      staffId: targetObject.staffId,
      branchId: this.globals.HospitalId,
      status: targetObject.vaccinationStatus ? 1 :0,
      vPurposeId: targetObject.vPurposeId,
      vaccinationDate: targetObject.vaccinationDate
    }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      data: vaccineData,
      branchId: this.globals.HospitalId,
      action: targetObject.vaccinationStatus ? 'edit' :'add',
      consultationId: this.TreatmentDetailsEdit.consultationId ? Number(this.TreatmentDetailsEdit.consultationId) : 0,
      permissionData: this.functionPermissionData,
      healthInsurancePlatform: this.healthPlatform,
    }
    const modalRef = this.modalService.show(ApplyVaccineModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }));
    modalRef.content.vaccineAppliedEmitter.subscribe((res)=>{
      if(res.status){
        let updatedObject={
          ...targetObject,
          ...res.itemData,
          vaccinationStatus: true 
        }
        this.serviceitemDetails = [
          ...this.serviceitemDetails.slice(0, targetObjectIndex),
          updatedObject,
          ...this.serviceitemDetails.slice(targetObjectIndex + 1)
        ];
      }
    })
  }

  addNotes(index){


      const itemDetailsGroup = this.TreatmentDetailsform.get('itemDetails')['controls'][index] as FormGroup;

      // Get the value of the 'notes' control
      const notesValue = itemDetailsGroup.get('notes').value;
   
        let initialState = {
          noteFieldData: notesValue,
          action: 'addEdit'
        }
        let config: ModalOptions = {
          backdrop: true,
          ignoreBackdropClick: true,
        }
        const modalRef = this.modalService.show(SponsorRuleItemNoteComponent, Object.assign({}, config, { class: 'modal-md', initialState }))
         modalRef.content.noteDataEmitter.subscribe((res) => {
          if (res.status == 'AddData') {
            
             ;
             itemDetailsGroup.get('notes').patchValue(res.data);
          
          }
        })
  
      
    
  }

  onServicePointchange(){
    if (this.TreatmentEditId > 0) {
      this.GetEditDetails(this.TreatmentEditId);
    }
  }

  openDosageModal(data,drug){
   
    if(data != '.0') return
    let initialState = {
      BranchId:this.globals.HospitalId,
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: ''
    }
    const historyModalRef= this.modalService.show(DrugDosageComponent,Object.assign(
      {}, historyConfig, { class: 'modal-md', initialState }) );
      historyModalRef.content.dosageEvent.subscribe(res=>{
        if(res.data){
          this.GetDosage()
          drug.patchValue({
            dosage:res.data
          })
          
        }
      });
  }

  openRouteModal(data,drug){
   
    if(data != '.0') return
    let initialState = {
      BranchId:this.globals.HospitalId,
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: ''
    }
    const historyModalRef= this.modalService.show(AddRouteComponent,Object.assign(
      {}, historyConfig, { class: 'modal-md', initialState }) );
      historyModalRef.content.dosageEvent.subscribe(res=>{
        if(res.data){
          this.getVaccinationRoutes()
          drug.patchValue({
            route:res.data
          })
          
        }
      });
  }

  getVaccinationRoutes(){
    let payload = {
      RouteId: 0
    }

    this.ConfigurationService.getVaccinationRoutes(payload).subscribe(res=>{
      this.vaccinationRouteList = res.response
    })
  }

  getApplicationSettings() {
    let payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId: this.globals.HospitalId
    }
    this.patientService.getApplicationSettings(payload).subscribe((res) => {
      this.healthPlatform = res.response.find(item => item.settId == 25)
    })
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }


}
interface VaccineData {
  patientName: string;
  consultantName: string;
  itemName: string;
  orderDetId: number;
  admQty: string;
  lotNumber: string;
  expiryDate: string;
  vaccineDate: string;
  vpId: number;
  vdqId: number;
  vduId: number;
  staffId: number;
  branchId: number;
  status: number;
  vPurposeId: number;
  vaccinationDate: string;
  patientId: number;
  // ... add other properties as needed
}

