import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ConfigurationService } from "../../client/services/configuration.service";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { TreatmentsService } from "../services/treatments.service";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
import { EMRService } from '../../emr/services/emr.service';
import { EMRPatientInfo } from "../../emr/interfaces/EMRPatientInfo";
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";
import { LockConfirmComponent } from "../modals/lock-confirm/lock-confirm.component";

@Component({
  selector: "app-physiothearapy-summary",
  templateUrl: "./physiothearapy-summary.component.html",
  styleUrls: ["./physiothearapy-summary.component.scss"],
})
export class PhysiothearapySummaryComponent implements OnInit {
  consultationId: any;
  treatmentTab: boolean;
  summaryTab: boolean = true;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  StaffData: any;
  Physiotherapist: any[] = [];
  getSummaryData: any[] = [];
  form: FormGroup;
  public isLoading: boolean = false;
  public submitted: boolean = false;
  isChecked: boolean = false;
  result: any;
  src: any = "";
  private baseUrl = "";
  isPdfLoaded: boolean = false;
  is_locked: any;
  EMRPatientInfo: EMRPatientInfo;
  constructor(
    private router: Router,
    private configurationService: ConfigurationService,
    private sharedservice: SharedService,
    private TreatmentsService: TreatmentsService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private configurationManager: ConfigurationManager,
    private EMRService: EMRService,
    private modalService: BsModalService,

  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.consultationId = this.router.getCurrentNavigation().extras.state.id;
    }
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  ngOnInit(): void {
    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();

    this.form = new FormGroup({
      consultationId: new FormControl(this.EMRPatientInfo.ConsultationId),
      evaluationStaff: new FormControl(0, [Validators.required]),
      summaryText: new FormControl("", [Validators.required]),
      isLocked: new FormControl(0),
    });

    this.GetAllStaff();
    this.getsummary();
  }
  GetAllStaff() {
    this.configurationService.GetStaff(this.BranchesId).subscribe((res) => {
      this.StaffData = res.response;
      this.Physiotherapist = this.StaffData.filter((x) => x.categoryId === 7);
    });
  }
  getsummary() {
    let input = {
      ConsultationId: this.EMRPatientInfo.ConsultationId,
    };
    this.TreatmentsService.GetSummary(input).subscribe((res) => {
      this.getSummaryData = res.response[0];
      this.form.patchValue(this.getSummaryData);
      this.is_locked = this.getSummaryData["isLocked"];

    });
  }
  save() {
    this.submitted = true;
    if (this.form && this.form.valid) {
      var formdata = this.form.getRawValue();      
      formdata.evaluationStaff = Number(formdata.evaluationStaff);
      if (formdata.isLocked == true) {
        formdata.isLocked = 1;
      } else {
        formdata.isLocked = 0;
      }
 
       if(this.is_locked==0){
      this.isLoading = true;
      this.TreatmentsService.saveSummary(formdata).subscribe((res) => {
        this.submitted = false;
        this.result = res;
        if (this.result.status == 200 && this.result.message == "Success") {
          this.isLoading = false;
          this.toastr.success("Summary added Successfully");
          this.getsummary();
        } else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    }else{
      this.toastr.warning("This Section is locked");
    }
    }
  }
  PhyGetReport() {
    this.isLoading = true;
    let input={
      BranchId:this.globals.HospitalId,
      consultationId:this.EMRPatientInfo.ConsultationId,
      VisitId:this.EMRPatientInfo.VisitId?this.EMRPatientInfo.VisitId:0

    }
    this.TreatmentsService.PhyGetReport(input).subscribe(
      (res) => {
        if (res.status == 200) {
          let response = res.response;
          this.src = response[0].reportPath;
          this.src = this.baseUrl + this.src;
          window.open(this.src, "_blank");

          // this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
          // this.isPdfLoaded = true;
        }
      }
    );
  }
  get f() {
    return this.form.controls;
  }

  //tab
  onTab(number) {
    this.treatmentTab = false;
    this.summaryTab = false;
    if (number == "1") {
      this.treatmentTab = true;
      // let id = this.consultationId
      this.router.navigate(["/physiothearapy-treatment"], {
        state: { consultationId: this.consultationId },
      });
    } else if (number == "4") {
      this.summaryTab = true;
    }
  }
  Activetab() {
    this.router.navigate(["/physiothearapy-treatment"], {
      state: { consultationId: this.consultationId },
    });
  }
  openmodal(event){
    let checked=event.target.checked;
    let is_checked=0;
    if(checked==true){
    is_checked=1
    }else if(checked==false){
      is_checked=0
    }
    let initialState = {
      is_locked:is_checked,
    }

    const modalRef = this.modalService.show(LockConfirmComponent, Object.assign({ class: "gray" }, {initialState  }))
    modalRef.content.getitemEmitter.subscribe((res) => {

      if (res) {
        if(res.status==false){
          this.isChecked = !checked;
        }
        else if(res.status==true){
          this. getsummary();
        }
    
      }
    })

  }
}
