import { Component, ElementRef, OnInit, TemplateRef, QueryList, ViewChild, ViewChildren, ɵɵtrustConstantResourceUrl, HostListener, Renderer2 } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PatientService } from '../../services/patient.service';
import { HospitalsService } from '../../services/hospitals.service';
import { AppointmentsService } from '../../services/appointments.service';
import { Consultants } from '../../interfaces/Consultants';
import { CountryList } from '../../interfaces/CountryList';
import { Departments } from '../../interfaces/Departments';
import { ToastrService } from 'ngx-toastr';
import { RegisterNumberList } from '../../interfaces/RegisterNumberList';
import { ReferenceList } from '../../interfaces/ReferenceList';
import { CompanyList } from '../../interfaces/CompanyList';
import { ProfessionList } from '../../interfaces/ProfessionList';
import { ConsultaionsService } from '../../services/consultations.service';
import { SymptomList } from '../../interfaces/SymptomList';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ReligionList } from '../../interfaces/ReligionList';
import { RegSchemeList } from '../../interfaces/RegSchemeList';
import { SchemeList } from '../../interfaces/SchemeList';
import { State1List } from '../../interfaces/State1List';
import { RateGroupList } from '../../interfaces/RateGroupList';
import { SchemeRateList } from '../../interfaces/SchemeRateList';
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MaritalStatusList } from '../../interfaces/MaritalStatusList';
import { CommunicationTypeList } from '../../interfaces/CommunicationTypeList';
import { VisaTypeList } from '../../interfaces/VisaTypeList';
import { DatePipe, Location } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import _, { find, get, isEmpty, pull } from 'lodash';
import { PatientList } from '../../interfaces/PatientList';
import { regIdentities } from "../../interfaces/regIdentities";
import { TitleList } from '../../interfaces/TitlteList';
import { GenderList } from '../../interfaces/GenderList';
import { KinRelationList } from '../../interfaces/KinRelationList';
import { PatientRegistration, RegIdentities, Consultation } from '../../interfaces/PatientRegistration';
import { RegAddress } from '../../interfaces/PatientRegistration';
import { RegDocLocation } from '../../interfaces/RegDocLocation';
import SignaturePad from 'signature_pad';
import { CommonValidationComponent } from '../../../../shared/common-validation/common-validation.component';
import { NgSelect2Component, Select2OptionData } from 'ng-select2';
import { SharedService } from '../../../../shared/shared.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConfigurationService } from "../../services/configuration.service";
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { UpperCasePipe } from '@angular/common';
import { Response } from '../../../../_Models/Response';
import { WebCameraModalComponent } from '../../../../shared/modals/web-camera-modal/web-camera-modal.component';
import { FetchEmiratesCardModalComponent } from '../modals/fetch-emirates-card-modal/fetch-emirates-card-modal.component';
import { SearchPatientModalComponent } from '../searchpatient-modal/searchpatient-modal.component';
import { AttachmentModalComponent } from '../modals/attachment-modal/attachment-modal.component';
import { NabithConsentComponent } from './nabith-consent/nabith-consent.component';
import { Settings } from '../../../settings/model/settings.model';
import { distinctUntilChanged, retry } from 'rxjs/operators';
import { fil } from 'date-fns/locale';
import { RiayatiConsentComponent } from './riayati-consent/riayati-consent.component';


@Component({
  selector: 'app-AddRegistrationComponent',
  templateUrl: './addregistration.component.html',
  styleUrls: ['./addregistration.component.scss']
})
export class AddRegistrationComponent implements OnInit {
  @ViewChild('myForm') regFormIdentifier!: ElementRef<HTMLFormElement>;
  validateEmail = true;
  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl: ElementRef;


  @ViewChild('dobcalc') dobcalc: ElementRef;
  modalRef: BsModalRef;
  @ViewChild("template") modaltemp: any;

  signatureImg: string;
  @ViewChild('tagInput') tagInputRef: ElementRef;
  @ViewChild('myfiles') myfiles: ElementRef;
  IsManualRegDate: boolean = true;
  exampleData: any[] = [];
  options: any;
  tags: string[] = [];
  myfile: any;
  checkvalue: boolean = false;
  checkdate: boolean = true;
  age: any;
  formSubmited: boolean = false;
  showAge;
  birthMonth;
  emerFees: Number = 0;
  opened = false;
  urgentCons: boolean = true;
  sticky: boolean = true;
  displayBasic: boolean;
  displayBasicdragdrop: boolean;
  displayBasicFetchCard: boolean;
  BarCode: boolean;
  BarCodePrintingModal: boolean;
  HistoryConsent: boolean;
  uploadedFiles: any[] = [];
  public showWebcam = false;
  currentDate = new Date();
  userForm: FormGroup;
  status: any;
  result: any;
  nabithReturnUrl: any = "";
  countryList: CountryList[] = [];
  NationalityData: Array<Select2OptionData> = [];
  CountryListData: Array<Select2OptionData> = [];
  StateListData: Array<Select2OptionData> = [];
  StateListData2: Array<Select2OptionData> = [];
  StateListData3: Array<Select2OptionData> = [];
  employerListData: Array<Select2OptionData> = [];
  professionListData: Array<Select2OptionData> = [];
  regSchemeListData: Array<Select2OptionData> = [];
  rateGroupListData: Array<Select2OptionData> = [];
  consultants: Consultants[] = [];
  departments: any;
  symptomList: any;
  regNumberList: RegisterNumberList[] = [];
  referenceList: ReferenceList[] = [];
  employerList: CompanyList[] = [];
  dropdownSettings: IDropdownSettings = {};
  professionList: ProfessionList[] = [];
  religionList: ReligionList[] = [];
  regSchemeList: RegSchemeList[] = [];
  schemeList: SchemeList[] = [];
  schemeRateList: SchemeRateList[] = [];
  state1List: State1List[] = [];
  state2List: State1List[] = [];
  state3List: State1List[] = [];
  rateGroupList: RateGroupList[] = [];
  regDocLocation: RegDocLocation[] = [];
  documentList: RegDocLocation[] = [];
  isUrgentConsultation = false;
  manualRegNo1: Number = 0;
  enableNewCons = true;
  enableRegButton = false;
  amount: Number;
  patId: Number = 0;
  propertyRegDate = true;
  propertyRegNo = true;
  router_url = this.router.url;
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  @ViewChildren("checkboxes2") checkboxes2: QueryList<ElementRef>;
  //  current date
  IsConsultantId = null;
  valueDate: any;
  externalSrc: any;
  showHealthInsurancePlatform = false;

  public PatientRegistration: PatientRegistration;
  public validator = new CommonValidationComponent();
  public Consultation: Consultation;
  public RegAddress: RegAddress[] = [];
  public RegIdentities: RegIdentities[] = [];
  public RegAddressObj: RegAddress = <RegAddress>{};
  public ConsultationObj: Consultation = <Consultation>{};
  public RegIdentitiesObj: RegIdentities = <RegIdentities>{};
  CreditBill: boolean;
  public submitted: boolean = false;
  user_id: any;
  patCheckId = 0;
  checkboxselect: false;
  patientId = 0;
  patientName: string = '';
  patientDetails: PatientList[] = [];
  maritalStatusList: MaritalStatusList[] = [];
  CommunicationTypeList: CommunicationTypeList[] = [];
  VisaTypeList: VisaTypeList[] = [];
  isShowRegDetails: boolean = true;
  isShowRegscheme = null;
  patientAddress: any;
  regIdentities: regIdentities[] = [];
  titleList: TitleList[] = [];
  genderList: GenderList[] = [];
  kinRelationList: KinRelationList[] = [];
  myFile: any;
  fileToUpload: File | null = null;
  printer: Window;
  myForm: FormGroup;
  myObservable$: Subject<unknown>;
  dob: string;
  data: CountryList[];
  data1: CompanyList[];
  item: any;
  generatedImage: string;
  generatedSignImage: string;
  symptom: any[];
  otherreason: any;
  OtherReasonVisit: any;
  PrintSerials: { SerialId: string; Name: string; }[];
  items: { label: any; icon: string; items: { items: ({ label: string; icon: string; url?: undefined; } | { label: string; url: string; icon: string; })[]; }[][]; }[];
  username: any;
  consentHistoryList: any;
  consentHistoryListArabic: any;
  consentHistoryPatientName: any;
  OtherSymptom: any;
  ConsultationsPopUp: boolean;
  maxDate: Date;
  regNoModal: boolean;
  RegNo: any;
  credConsultationId: any;
  RegDate: any;
  RegistrationDate: string;
  valueDate3: string;
  Emergency: number;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  userType: any;
  departmentId: any;
  ConsultantId: any;
  isShowAddReg: boolean = false;
  DisplayedId: any;
  modalConsent: BsModalRef;
  isLoading = false;

  private tabClickCount: number = 0;
  private tabOrders: any [] = [];
  consentHistoryListTemp: any='';
  ConfCodeIdList:any=[]
  ConsentOptionIdList:any=[];

  private appoinmentID: number = 0;

  defaultValueList = [];
  public configurationSettings: Settings[] = [];
  defaultCountry1: any;
  defaultCountry2: any;
  defaultCountry3: any;
  defaultState1: any;
  defaultState2: any;
  defaultState3: any;
  defaultScheme: any;
  defaultRateGroup: any;
  defaultPatConsentOption: any;
  defaultConfidentialityCode: any;
  defaultCommunicationType: any;
  defaultConsultScheme: any;
  defaultNationality: any;
  patients: PatientList[] = [];
  @ViewChild(SearchPatientModalComponent) searchModal: SearchPatientModalComponent;
  displayModalBasic: boolean;
  kinId: number;
  kinTypeList = [
    {id: 1, kinType: 'Registered Patient'},
    {id: 2, kinType: 'Unregistered Patient'},
  ];
  attachmentTypeList = [];
  attachDoc: FormGroup;
  paramValue = 0;
  attachFlag: boolean;
  modalRefer: BsModalRef;
  dataShare: boolean;
  nabithOptInOptOut: any;
  kinValidation: any;
  validationForReg: any;
  functionPermissionData: any;
  HealthInformationPlatform: any;
  healthPlatform: any;
  isEdit: boolean=false;

  constructor(private formBuilder: FormBuilder,
    private hospitalsService: HospitalsService,
    private appointmentService: AppointmentsService,
    private patientService: PatientService,
    private consultationService: ConsultaionsService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private router: Router,
    private renderer: Renderer2,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private _location: Location,
  ) {


    if(this.router.getCurrentNavigation().extras.state){
      this.appoinmentID =this.router.getCurrentNavigation().extras.state.appoinmentId;
      this.nabithReturnUrl = this.router.getCurrentNavigation().extras.state.returnUrl;
      // console.log('this.appoinmentID1', this.appoinmentID);
    }


    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId

      }
    });

    this.exampleData = [
      {
        id: 1,
        text: 'Multiple 1'
      },
      {
        id: 2,
        text: 'Multiple 2'
      },
      {
        id: 3,
        text: 'Multiple 3'
      }
    ];
    this.options = {
      width: '300',
      multiple: true,
      tags: true
    };
  }





  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  public documentFile: File[] = null;
  public documentFile1: File = null;
  public documentFile2: File = null;
  keyword = 'countryName';
  keyword1 = 'stateName';
  keyword2 = 'stateName';
  keyword3 = 'stateName';
  keyword5 = 'employeename';
  keyword7 = 'nationalityName';
  keyword8 = 'cmpName';
  keyword9 = 'profName';

  ngOnInit(): void {


    this.DisplayedId = 0;
    this.userType = localStorage.getItem("usertype");
    this.departmentId = localStorage.getItem("departmentId");
    this.ConsultantId = localStorage.getItem('Id');
    this.maxDate = new Date();
    this.items = [
      {
        label: this.username, icon: 'fa fa-print',
        items: [
          [
            {
              items: [
                { label: 'My profile', icon: 'fa fa-user' },
                { label: 'Messages', icon: 'fa fa-envelope' },
                { label: 'Settings', icon: 'fa fa-gear' },
                { label: 'Logout', url: '/', icon: 'fa fa-power-off' }]
            },
          ],
        ]
      }
    ]
    this.PrintSerials = [{
      SerialId: "PAT REG NUMBER",
      Name: 'A'
    },
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'symptomId',
      textField: 'newsymDesc',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
      defaultOpen: false
    };


    this.activatedRoute.params.subscribe(params => {
      this.patientId = Number(params.id);
    });

    if (this.patientId) {
      this.checkvalue = true
      this.isEdit=true
    }else{
      this.getApplicationSettings();
    }
    this.fetchId();    // Fetching id if page is loaded for registration edit
    this.user_id = localStorage.getItem('user_id');
    this.valueDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    this.userForm = this.formBuilder.group({
      PatientId: new FormControl(),
      uploadfile: new FormControl(),
      RegDate: new FormControl(),
      RegNo: new FormControl('<<NEW>>', ),
      ItemId: new FormControl('', ),
      Scheme2: new FormControl(0),
      RGroupId: new FormControl('', ),
      Salutation: new FormControl(0),
      FirstName: new FormControl('', ),
      MiddleName: new FormControl(''),
      LastName: new FormControl(''),
      Nationality: new FormControl(""),
      Gender: new FormControl('', ),
      Age: new FormControl('', ),
      Month: new FormControl('', ),
      Days: new FormControl('', ),
      NationalityId: new FormControl(''),
      NationalId: new FormControl(''),
      MaritalStatus: new FormControl(0),
      ConfCodeId:new FormControl(0),
      ConsentOptionId :new FormControl(-1),
      ConsentReason: new FormControl(''),
      Religion: new FormControl(0),
      DOB: new FormControl('', ),
      Mobile: new FormControl(''),
      ResNo: new FormControl(''),
      OffNo: new FormControl(''),
      Email: new FormControl('', [Validators.email, 
        // Validators.pattern('^[^\s@]+@[^\s@]+\.[^\s@]{2,}$')
      ]),
      // FaxNo: new FormControl(''),
      Mode: new FormControl(''),
      ProfilePicLocation: new FormControl(''),
      PassportNo: new FormControl(''),
      ConsultantId: new FormControl(''),
      DepartmentId: new FormControl(0),
      ConsultFee: new FormControl('0'),
      ReferedBy: new FormControl(''),
      VisaTypeId: new FormControl(0),
      Address1: new FormControl(''),
      Address2: new FormControl(''),
      Street1: new FormControl(''),
      Place1: new FormControl(''),
      City1: new FormControl(''),
      Pin1: new FormControl(''),
      State1: new FormControl(''),
      Country1: new FormControl(''),
      Address3: new FormControl(''),
      Address4: new FormControl(''),
      Street2: new FormControl(''),
      Place2: new FormControl(''),
      City2: new FormControl(''),
      Pin2: new FormControl(''),
      State2: new FormControl(''),
      Country2: new FormControl(''),
      Address5: new FormControl(''),
      Address6: new FormControl(''),
      Street3: new FormControl(''),
      Place3: new FormControl(''),
      City3: new FormControl(''),
      Pin3: new FormControl(''),
      State3: new FormControl(''),
      Country3: new FormControl(''),
      Active: new FormControl(1),
      HealthCardId: new FormControl(''),
      PinOrvid: new FormControl(''),
      VisaOrpermitNo: new FormControl(''),
      DrivingLicNo: new FormControl(''),
      LabourCardNo: new FormControl(''),
      CmpId: new FormControl(''),
      ProfId: new FormControl(''),
      Country: new FormControl(''),
      CommunicationType: new FormControl(0),
      ReasonForVisit: new FormControl(''),
      CountryId: new FormControl(0),
      Remarks: new FormControl(''),
      otherreasonforvisit: new FormControl(''),
      KinRelation: new FormControl(0),
      KinName: new FormControl(''),
      KinMiddleName : new FormControl(''),
      KinLastName: new FormControl(''),
      KinPatId: new FormControl(''),
      KinContactNo: new FormControl(''),
      KinEmail:new FormControl('',[Validators.email]),
      KinNationalId : new FormControl(''),
      KinType: new FormControl(0),
      WorkEnvironment: new FormControl(''),
      ProfessionalNoxious: new FormControl(''),
      ProfessionalExperience: new FormControl(''),
      EnableConsultation: new FormControl(''),
      ConsultationId: new FormControl(''),
      AppId: new FormControl('0'),
      ConsultDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy hh:mm a')),
      Symptoms: new FormControl([]),
      ConsultType: new FormControl('1'),
      Emergency: new FormControl(''),
      EmerFee: new FormControl('100'),
      LocationId: new FormControl('1'),
      InitiateCall: new FormControl('1'),
      AgentId: new FormControl(''),
      LeadAgentId: new FormControl(''),
      UserId: new FormControl(localStorage.getItem('user_id')),
      SessionId: new FormControl('0'),
      BranchId: new FormControl(this.globals.HospitalId),
      RetVal: new FormControl('0'),
      RetDesc: new FormControl(''),
      RetSeqNo: new FormControl(''),
      PatientPhoto: new FormControl(''),
      AddType: new FormControl('0'),
      RegAddress: new FormArray(([])),
      Consultation: new FormControl(''),
      RegIdentities: new FormArray(([])),
      Hook: new FormControl(''),
      IsVIP:new FormControl(false),
    });

    let datecon = this.datepipe.transform(new Date(), 'dd-MM-yyyy')
    this.userForm.controls['RegDate'].setValue(datecon);

    this.functionPermission();
    this.GetTitle();
    this.GetKinRelation();
    this.GetGender();
    this.GetReference();
    this.GetCompany();
    this.GetProfession();
    this.GetMaritalStatus();
    // this.GetConsentOption();
    this.GetConfidentiality()
    this.GetCommunicationType();
    this.GetVisaType();
    this.GetReligion();
    this.GetRegScheme();
    //this.GetRateGroup();
    this.GetTemplateReasonForVisit();
    this.GetDepartments();
    this.LoadCountry();
    if (this.userType !== "U") {
      this.userForm.controls['DepartmentId'].disable();
      this.userForm.controls['ConsultantId'].disable();
      this.IsConsultantId = true;
      this.userForm.patchValue({ DepartmentId: this.departmentId });
      this.loadConsultant(this.departmentId);
      this.userForm.patchValue({ ConsultantId: this.ConsultantId });
      this.GetScheme();
    }
  

    this.getRegistrationPageDynamicValidators();
    this.getRegistrationPageTabOrder();

    if(this.appoinmentID && this.appoinmentID > 0){
      this.getAppoinmentDetails(this.appoinmentID);
    }
    // this.attachDoc = new FormGroup({
    //   DocId: new FormControl('', Validators.required),
    // });
    // this.getDocumentType();
    this.activatedRoute.params.subscribe(params => {
      this.paramValue = Number(params.id);
    });
    this.userForm.patchValue({ KinType: this.kinTypeList[1].id });

    // ######################
    let data1;
    let data2 = [];
    let data3;

    if (sessionStorage.getItem('pageTitle') == 'Add Registration') {
      this.userForm.get('NationalId').valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
        if (value) {
          data1 = this.userForm.get('NationalId').value.toString();
          if (data1.includes('-')) {
            data2 = data1.match(/\d+/g);
            console.log('data2', data2);
            if (data2?.length == 4) {
              data3 = data2[0] + data2[1] + data2[2] + data2[3];
              console.log('data3', data3);
              if (data3?.length == 15) {
                let payload = {
                  BranchId: this.BranchesId,
                  EmiratesCardNo: this.userForm.get('NationalId').value
                }
                this.patientService.CheckPatRegistration(payload).subscribe((res) => {
                  if(res.message !== 'success'){
                    this.toastr.warning(res.message);
                  }
                })
              }
            }
          } else {
            if (this.userForm.get('NationalId').value.toString()?.length == 15) {
              let payload = {
                BranchId: this.BranchesId,
                EmiratesCardNo: this.userForm.get('NationalId').value.toString()
              }
              this.patientService.CheckPatRegistration(payload).subscribe((res) => {
                if(res.message !== 'success'){
                  this.toastr.warning(res.message);
                }
              })
            }
          }
        }
        // else {
        //   console.log('Field is empty');
        // }
      });
    }
  }

  // @HostListener('window:keydown', ['$event'])
  // tabClickEvent(event: KeyboardEvent){
  //   if(event.key === 'Tab'){
  //     this.tabClickCount++;
  //     event.preventDefault();
  //     for(let field of this.tabOrders){
  //       if(field.objectOrder == this.tabClickCount){
  //         this.changeFocus(field.objectName);
  //         return;
  //       }
  //     }
  //   }
  // }


  getTabIndex(objectName:string){
    const object=this.tabOrders.find(item=>item.objectName == objectName)
    return object ?  object.objectOrder : 100
  }


  get f() { return this.userForm.controls; }
  //clear input fields
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  ClearForm() {
    this.checkboxes.forEach((element) => {
      if (element.nativeElement.checked)
        element.nativeElement.checked = false;
    });
    this.checkboxes2.forEach((element) => {
      if (element.nativeElement.checked)
        element.nativeElement.checked = false;
    });
    if (!this.checkvalue) {
      this.selectchecking();
    }

    this.userForm.reset(
      {
        "RegNo": '<<NEW>>',
        "RegDate": this.valueDate,
        "ItemId": 0,
        "RGroupId": 0,
        "rateGroup": 0,
        "NationalityId": 0,
        "Gender": '',
        "ConsultFee": 0,
        "Salutation": 0,
        "MaritalStatus": 0,
        "ConfCodeId" : 0,
        "ConsentOptionId " : -1,
        "nationality": 0,
        "Religion": 0,
        "Country1": "",
        "State1": "",
        "VisaTypeId": 0,
        "CommunicationType": 0,
        "Country2": "",
        "State2": "",
        "Country3": 0,
        "State3": "",
        "DepartmentId": 0,
        "ReferedBy": '',
        "ConsultantId": '',
        "Scheme2": 0,
        "KinName": "",
        "KinMiddleName": "",
        "KinLastName": "",
        "KinNationalId": "",
        "KinPatId": 0,
        "KinContactNo": "",
        "KinEmail":"",
        "Remarks": "",
        "otherreasonforvisit": "",
        "ProfessionalExperience": "",
        "workEnvironment": "",
        "ProfessionalNoxious": "",
        "CmpId": 0,
        "ProfId": 0,
        "KinRelation": 0,
        "KinType": 0,
        "Active": 1,
        "AppId": 0,
        "LocationId": 1,
        "InitiateCall": 1,
        "UserId": localStorage.getItem('user_id'),
        "SessionId": 0,
        "BranchId": 0,
        "RetVal": 0,
        "RetDesc": "",
        "RetSeqNo": "",
        "PatientPhoto": '',
        "AddType": '',
        "Symptoms": "",
        "Pin3": "",
        "City3": "",
        'Mobile': "",
        "ResNo": "",
        "OffNo": "",
        // "FaxNo": "",
        "WorkEnvironment": "",
        "DrivingLicNo": "",
        "LabourCardNo": "",
        "VisaOrpermitNo": "",
        "PinOrvid": "",
        "HealthCardId": "",
        "employerListData": "",
        "professionListData": "",
        "Address5": "",
        "Address6": "",
        "Place3": "",
        "Street3": "",
        "NationalId": "",
        "Address1": "",
        "Address2": "",
        "Place1": "",
        "City1": "",
        "Pin1": "",
        "Address3": "",
        "Address4": "",
        "Place2": "",
        "City2": "",
        "Pin2": "",
        "Street2": "",
        "ConsentReason" :""
      }

    );

    this.userForm.clearValidators();
    this.GetRegScheme();
    this.GetReligion();
    //this.GetRateGroup();
    this.GetDepartments();
    this.GetProfession();
    this.GetCompany();
    this.GetReference();
  }

  // select 2 change example
  select2change(event) {
    let val = this.userForm.get('Symptoms').value;
  }

  GetTitle() {
    this.patientService.GetTitle(this.globals.HospitalId).subscribe((res) => {
      this.titleList = res.response;

    })
  }
  GetGender() {
    this.patientService.GetGender().subscribe((res) => {
      this.genderList = res.response;

    })
  }
  GetKinRelation() {
    this.patientService.GetKinRelation().subscribe((res) => {
      this.kinRelationList = res.response;

    })
  }
  GetMaritalStatus() {
    this.patientService.GetMaritalStatus(this.globals.HospitalId).subscribe((res) => {
      this.maritalStatusList = res.response;

    })
  }

  GetConfidentiality() {
    this.patientService.GetConfidentiality(0).subscribe((res) => {
      this.ConfCodeIdList = res.response;

    })
  }

  GetConsentOption() {
    this.patientService.GetConsentOption(-1).subscribe((res) => {
      this.ConsentOptionIdList = res.response.filter(item=> item.receivedBy == this.HealthInformationPlatform.settValue);   
    })
  }


  GetCommunicationType() {
    this.patientService.GetCommunicationType(this.globals.HospitalId).subscribe((res) => {
      this.CommunicationTypeList = res.response;

    })
  }

  GetVisaType() {
    this.patientService.GetVisaType(this.globals.HospitalId).subscribe((res) => {
      this.VisaTypeList = res.response;

    })
  }
  //Get state from current country selected inputs
  GetState(countryId: any, state: any) {
    let ShowAll = 0;
    this.ConfigurationService
      .GetState(this.globals.HospitalId, (Number(countryId)), ShowAll).subscribe((res) => {
        if (state == '1') {
          this.state1List = res.response;
          this.StateListData = [];
          this.state1List.forEach(a => {
            this.StateListData.push({ id: a.stateId.toString(), text: a.stateName });

          });
        }
        else if (state == '2') {
          this.state2List = res.response;

          this.StateListData2 = [];
          this.state2List.forEach(a => {
            this.StateListData2.push({ id: a.stateId.toString(), text: a.stateName });
          });
        }
        else if (state == '3') {
          this.state3List = res.response;

          this.StateListData3 = [];
          this.state3List.forEach(a => {
            this.StateListData3.push({ id: a.stateId.toString(), text: a.stateName });
          });
        }

      })


  }

  //Get Rate Group List
  // GetRateGroup() {
  //   this.patientService.GetRateGroup(this.globals.HospitalId).subscribe((res) => {
  //     this.rateGroupList = res.response;
  //     this.rateGroupListData = [];
  //     this.rateGroupList.forEach(a => {
  //       this.rateGroupListData.push({ id: a.rGroupId.toString(), text: a.rGroupName });


  //     });
  //   })
  // }
  //Get reference list for patient
  GetReference() {
    this.patientService.GetReference(this.globals.HospitalId).subscribe((res) => {
      this.referenceList = res.response;

    })
  }
  //Get company details of patient
  GetCompany() {
    this.patientService.GetCompany(this.globals.HospitalId).subscribe((res) => {
      this.employerList = res.response;
      this.employerListData = [];
      this.employerList.forEach(a => {
        this.employerListData.push({ id: a.id.toString(), text: a.nameData });

      });

    })
  }
  //Get profession details of patient
  GetProfession() {
    this.patientService.GetProfession(this.globals.HospitalId).subscribe((res) => {
      this.professionList = res.response;
      this.professionListData = [];
      this.professionList.forEach(a => {
        this.professionListData.push({ id: a.profId.toString(), text: a.profName });

      });

    })
  }
  // //Getting teplates for symtoms and reason for visit
  GetTemplateReasonForVisit() {
    let showAll = 0;
    this.consultationService.GetTemplateReasonForvisit(this.globals.HospitalId, showAll).subscribe((res) => {
      this.symptomList = res.response;
      this.symptomList.forEach((element) => {
        element['newsymDesc'] = (element['SymptomDesc']) ? element['SymptomDesc'] : element['symptomDesc'];
      });
    })
  }
  //Getting religion list
  GetReligion() {
    this.patientService.GetReligion(this.globals.HospitalId).subscribe((res) => {
      this.religionList = res.response;
    })
  }
  //Getting Reg Scheme List
  GetRegScheme() {
    let ShowAll = 0
    this.patientService.GetRegScheme(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.regSchemeList = res.response;
      this.regSchemeListData = [];
      this.regSchemeList.forEach(a => {
        // this.regSchemeListData.push({ id: a.itemId.toString(), text: a.itemName + " " + ("(fee)") + "-" + +a.rate });
        this.regSchemeListData.push({ id: a.itemId.toString(), text: a.itemName });
      });

    })
  }

  GetRegSchemeByRateGroup(event) {

    if (typeof (event) == "object") {
      event = 0;
    }
    let RateGroupId = event;//this.userForm.get("RGroupId").value;
    let Input = {
      "Id": Number(RateGroupId),
      "BranchId": this.globals.HospitalId
    }
    this.patientService.GetRateGroupId(Input).subscribe((res) => {
      this.rateGroupList = res.response;
      this.rateGroupListData = [];
      this.rateGroupList.forEach(a => {
        this.rateGroupListData.push({ id: a.rGroupId.toString(), text: a.rGroupName + " " + ("(fee-" + a.rateStr + ")") });


      });

    });
    // this.patientService.GetRegSchemeByRateGroup(this.globals.HospitalId, RateGroupId).subscribe((res) => {
    //   this.regSchemeList = res.response;
    //   this.regSchemeListData = [];
    //   if (this.regSchemeList.length != 0) {
    //     this.regSchemeList.forEach(a => {
    //       this.regSchemeListData.push({ id: a.itemId.toString(), text: a.itemName + " " + ("(fee-" + a.rate + ")") });
    //     });
    //   }
    // })
  }

  //Getting scheme by consultant selection
  GetScheme() {
    this.schemeList = null;
    this.userForm.patchValue({ Scheme2: '' })
    this.ConsultantId = Number(this.userForm.get("ConsultantId").value);

    let data = {
      followup: false,
      inscovered: false,
      creditid:0,
      deptid: Number(this.userForm.get("DepartmentId").value),
      mastermindId: 0,
    }
    this.consultationService.GetConsultantScheme(this.ConsultantId, this.globals.HospitalId, this.Emergency,data).subscribe((res) => {
      this.schemeList = res.response;
      const schemes = res.response;
      schemes.find(x => {
        if(x.isDefault == true) {
          this.defaultConsultScheme = x.itemId;
        }
      });
      if (this.Emergency == 1) {
        if (schemes.length == 1) {
          this.defaultConsultScheme = schemes[0].itemId;
        }
      }
      this.GetSchemeRate();
      this.result = res;
      if (this.ConsultantId) {
        if (this.result.message == "Empty") {
          this.toastr.warning("No Scheme For this Consultant");
          return false;
        }
      }

    })
  }
  //getting patient fees and urgent consultation fees
  GetSchemeRate() {
    let consultantId = Number(this.userForm.get("ConsultantId").value);
    this.userForm.get("Scheme2").setValue(this.defaultConsultScheme);
    this.userForm.get("RGroupId").setValue(this.defaultRateGroup);
    let itemId = Number(this.userForm.get("Scheme2").value);
  
    let RGroupId = this.userForm.get("RGroupId").value;//Number(this.userForm.get("RGroupId").value);
    if (RGroupId == "" || RGroupId == 0) {
      this.toastr.warning("Please select Rate Group");
    }
    else {
      this.patientService.GetSchemeRate(itemId, consultantId, Number(RGroupId), this.Emergency).subscribe((res) => {
        this.schemeRateList = res.response;
        let rate = this.schemeRateList[0]?.rate || 0;
        this.amount = 0;
        let emergencyFees = this.schemeRateList[0]?.emergencyFees || 0;
        this.emerFees = emergencyFees;
        if (this.isUrgentConsultation) {
          let rate = this.schemeRateList[0]?.rate || 0;
          this.amount = Number(rate) + Number(emergencyFees);
        }
        else {
          this.amount = Number(this.schemeRateList[0]?.rate || 0);
        }
        this.userForm.patchValue({ ConsultFee: this.amount })
      })
    }
  }
  //checking urgent consultation is assign
  CheckUrgentCons(isCheckCons) {
    if (isCheckCons == true) {
      this.isUrgentConsultation = true;
      this.Emergency = 1;
    }
    else {
      this.isUrgentConsultation = false;
      this.Emergency = 0;
    }
    if(this.userForm.controls.Scheme2.value !== "" && this.userForm.controls.Scheme2.value !== "" && this.userForm.controls.Scheme2.value !== undefined){
      this.GetScheme();
    }
    // this.GetSchemeRate();
  }
  // setting new consultation
  EnableOrDisableNewCons(isNewCons: any) {
    this.DisplayedId = 1;
    this.enableNewCons = isNewCons;
    this.checkboxes2.forEach((element) => {
      if (element.nativeElement.checked)
        element.nativeElement.checked = false;

    });
    if (!isNewCons) {
      this.userForm.controls['DepartmentId'].setValue(0);
      this.userForm.controls['ConsultantId'].setValue('');
      // this.userForm.controls['Scheme2'].setValue(0);
      this.userForm.controls['otherreasonforvisit'].setValue('');
      this.userForm.controls['Symptoms'].setValue([]);
      this.userForm.controls['ReferedBy'].setValue('');
    }


  }
  //pagetitle to be displayed at the header
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);

  }

  goBack(){
    console.log('this.nabithReturnUrl', this.nabithReturnUrl);
    if(this.userType == 'U' && this.nabithReturnUrl == ""){
      this.Activetab('Registration List');
      this.router.navigate(['/registration']);
      // this._location.back();
    }else if(this.userType == 'G' && this.nabithReturnUrl == ""){
      this.Activetab('My Patients');
      // this._location.back();
      this.router.navigate(['/consultantdesk/myPatients']);
    } else {
      this._location.back();
    }
  }


  SaveRegistrations() { // calling api and Adding a new Patient Details
    console.log(this.userForm)
    this.PatientRegistration = new PatientRegistration();
    this.submitted = true;
    this.PatientRegistration = this.userForm.getRawValue();
    this.userForm.controls.State1.setValue(this.defaultState1);
    this.userForm.controls.State2.setValue(this.defaultState2);
    this.userForm.controls.State3.setValue(this.defaultState3);
    if (typeof (this.age) == "string") {
      this.PatientRegistration.DOB = this.age;
    }
    else {
      if (this.age) {
        this.PatientRegistration.DOB = this.datepipe.transform(new Date(this.age), 'dd-MM-yyyy');
      }

    }


    // RegDate

    let registrationdate = this.userForm.get('RegDate').value;
    if (this.IsManualRegDate) {

      if (typeof (registrationdate) == "string") {
        this.PatientRegistration.RegDate = registrationdate;
      }
      else {
        this.PatientRegistration.RegDate = this.datepipe.transform(registrationdate, 'dd-MM-yyyy');
      }
    }
    else {
      this.PatientRegistration.RegDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');

    }

    this.PatientRegistration.IsManualRegNo = this.manualRegNo1;
    this.PatientRegistration.OtherReasonVisit = this.OtherReasonVisit;
    this.PatientRegistration.PatientId = this.patId;
    this.PatientRegistration.Mode = 'R';
    this.PatientRegistration.NationalityId = this.PatientRegistration.NationalityId ? this.PatientRegistration.NationalityId : 0;
    this.PatientRegistration.CmpId = this.PatientRegistration.CmpId ? this.PatientRegistration.CmpId : 0;
    this.PatientRegistration.ProfId = this.PatientRegistration.ProfId ? this.PatientRegistration.ProfId : 0;
    this.PatientRegistration.ConsultantId = this.PatientRegistration.ConsultantId > 0 ? this.PatientRegistration.ConsultantId : 0;

    this.PatientRegistration.ConfCodeId = this.PatientRegistration.ConfCodeId ? Number(this.PatientRegistration.ConfCodeId) : 0;
    this.PatientRegistration.ConsentOptionId = this.PatientRegistration.ConsentOptionId ? Number(this.PatientRegistration.ConsentOptionId) : -1;



    this.RegAddressObj = new RegAddress();
    this.RegAddressObj.AddType = 0;
    this.RegAddressObj.Address1 = this.PatientRegistration.Address1;
    this.RegAddressObj.Address2 = this.PatientRegistration.Address2;
    this.RegAddressObj.Street = "";
    this.RegAddressObj.PlacePO = this.PatientRegistration.Place1;
    this.RegAddressObj.PIN = this.PatientRegistration.Pin1;
    this.RegAddressObj.City = this.PatientRegistration.City1;
    this.RegAddressObj.CountryId = Number(this.PatientRegistration.Country1 ? this.PatientRegistration.Country1 : 0);
    this.RegAddressObj.State = this.PatientRegistration.State1 ? this.PatientRegistration.State1 : "";

    this.PatientRegistration.RegAddress.push(this.RegAddressObj);

    this.RegAddressObj = new RegAddress();
    this.RegAddressObj.AddType = 1;
    this.RegAddressObj.Address1 = this.PatientRegistration.Address3;
    this.RegAddressObj.Address2 = this.PatientRegistration.Address4;
    this.RegAddressObj.Street = this.PatientRegistration.Street2;
    this.RegAddressObj.PlacePO = this.PatientRegistration.Place2;
    this.RegAddressObj.PIN = this.PatientRegistration.Pin2;
    this.RegAddressObj.City = this.PatientRegistration.City2;
    this.RegAddressObj.CountryId = Number(this.PatientRegistration.Country2 ? this.PatientRegistration.Country2 : 0);
    this.RegAddressObj.State = this.PatientRegistration.State2 ? this.PatientRegistration.State2 : "";


    this.PatientRegistration.RegAddress.push(this.RegAddressObj);

    this.RegAddressObj = new RegAddress();
    this.RegAddressObj.AddType = 2;
    this.RegAddressObj.Address1 = this.PatientRegistration.Address5;
    this.RegAddressObj.Address2 = this.PatientRegistration.Address6;
    this.RegAddressObj.Street = this.PatientRegistration.Street3;
    this.RegAddressObj.PlacePO = this.PatientRegistration.Place3;
    this.RegAddressObj.PIN = this.PatientRegistration.Pin3;
    this.RegAddressObj.City = this.PatientRegistration.City3;
    this.RegAddressObj.CountryId = Number(this.PatientRegistration.Country3 ? this.PatientRegistration.Country3 : 0);
    this.RegAddressObj.State = this.PatientRegistration.State3 ? this.PatientRegistration.State3 : "";

    this.PatientRegistration.RegAddress.push(this.RegAddressObj);
    this.ConsultationObj = new Consultation();
    this.ConsultationObj.EnableConsultation = this.enableNewCons;
    // this.ConsultationObj.AppId = 0;
    // console.log('this.appoinmentID', this.appoinmentID);
    this.ConsultationObj.AppId = Number(this.appoinmentID);

    this.ConsultationObj.ConsultDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy hh:mm a');
    this.ConsultationObj.ConsultantId =  Number(this.PatientRegistration.ConsultantId);
    this.ConsultationObj.PatientId = 0;
    this.ConsultationObj.ConsultationId = 0;

    const symptoms = this.userForm.get('Symptoms');

    this.ConsultationObj.ConsultFee = this.PatientRegistration.ConsultFee;
    this.ConsultationObj.ItemId = this.PatientRegistration.Scheme2;
    this.ConsultationObj.ConsultType = 1;
    this.ConsultationObj.Emergency = this.Emergency;
    this.ConsultationObj.EmerFee = 100;
    this.ConsultationObj.LocationId = 1;
    this.ConsultationObj.InitiateCall = 1;
    this.ConsultationObj.AgentId = null;
    this.ConsultationObj.LeadAgentId = null;
    this.ConsultationObj.UserId = Number(localStorage.getItem('user_id'));
    this.ConsultationObj.SessionId = 0;
    this.ConsultationObj.BranchId = this.globals.HospitalId;
    this.ConsultationObj.RetVal = 0;
    this.ConsultationObj.RetDesc = '';
    this.ConsultationObj.RetSeqNo = 0;
    this.ConsultationObj.PatientPhoto = "";
    this.ConsultationObj.ConsultFee = this.PatientRegistration.ConsultFee;
    this.ConsultationObj.otherreasonforvisit = this.PatientRegistration.otherreasonforvisit;
    this.PatientRegistration.Consultation = this.ConsultationObj;

    for (let i = 0; i < 7; i++) {
      this.RegIdentitiesObj = new RegIdentities();
      this.RegIdentitiesObj.IdentityType = i;
      if (i == 0) this.RegIdentitiesObj.IdentityNo = this.PatientRegistration.PinOrvid;
      else if (i == 1) this.RegIdentitiesObj.IdentityNo = this.PatientRegistration.PassportNo;
      else if (i == 2) this.RegIdentitiesObj.IdentityNo = this.PatientRegistration.DrivingLicNo;
      else if (i == 3) this.RegIdentitiesObj.IdentityNo = this.PatientRegistration.HealthCardId;
      else if (i == 4) this.RegIdentitiesObj.IdentityNo = this.PatientRegistration.LabourCardNo;
      else if (i == 5) this.RegIdentitiesObj.IdentityNo = this.PatientRegistration.VisaOrpermitNo;
      else if (i == 6) {
        if (this.PatientRegistration.NationalId) {
          this.RegIdentitiesObj.IdentityNo = this.PatientRegistration.NationalId ? this.PatientRegistration.NationalId : "111-1111-1111111-1";


        }
        else {
          this.RegIdentitiesObj.IdentityNo = "";
        }
      }
      this.RegIdentitiesObj.IdentityType = i;
      this.RegIdentitiesObj.PatientId = this.patId;
      this.PatientRegistration.RegIdentities.push(this.RegIdentitiesObj);
    }

    // if((Number(this.PatientRegistration.Age) < 18) && (this.kinValidation[0].settValue == '1')){
    //   if(this.PatientRegistration.KinName == ''){
    //     this.toastr.error("Kin Name is required");
    //     return;
    //   } else if(this.PatientRegistration.KinRelation == 0){
    //     this.toastr.error("Kin Relation is required");
    //     return;
    //   } else if(this.PatientRegistration.KinContactNo == ''){
    //     this.toastr.error("Kin Contact Number is required");
    //     return;
    //   }
    // }
  
    // National id validation
// debugger
 console.log(this.PatientRegistration.ConsentOptionId)
 if (this.HealthInformationPlatform.settValue === "Nabidh" &&
  this.nabithOptInOptOut[0].settValue === "1" &&
  this.PatientRegistration.ConsentOptionId !== 0 &&
  this.PatientRegistration.ConsentOptionId !== 1 &&
  this.PatientRegistration.ConsentOptionId !== 2) {

this.toastr.warning("Please choose a consent option for Nabidh");
return;
}

else if (this.HealthInformationPlatform.settValue.trim() === "Riayati" && 
       this.nabithOptInOptOut[0].settValue === "1" &&
       this.PatientRegistration.ConsentOptionId !== 3 &&
       this.PatientRegistration.ConsentOptionId !== 4) {

this.toastr.warning("Please choose a consent option for Riayati");
return;
}


    if(this.userForm.get('NationalId').value.length > 0) {
      if (this.userForm.get('NationalId').value.includes('_')) {
        this.toastr.error("Please enter valid National Id");
        return
      }
    // Date of Year
    let doy;
    if (typeof this.userForm.get('DOB').value === 'string') {
      doy = this.userForm.get('DOB').value.split('-')[2];
    } else if (this.userForm.get('DOB').value instanceof Date) {
      const dateObject = new Date(this.userForm.get('DOB').value);
      doy = dateObject.getFullYear();
    }
    let NationalIdtrim=this.userForm.get('NationalId').value.substring(0, 3)
    if(NationalIdtrim != '000'&& NationalIdtrim != '111'&& NationalIdtrim != '222'&& NationalIdtrim != '784'){
      this.toastr.error("Please enter valid National Id");
      return
    }
    if (NationalIdtrim == '784' && this.userForm.get('NationalId').value.substring(4, 8) != doy){
      this.toastr.error("Please enter valid DOB ");
      return
    }
    if((NationalIdtrim == '111' && this.userForm.get('NationalId').value != '111-1111-1111111-1') ||
        (NationalIdtrim == '000' && this.userForm.get('NationalId').value != '000-0000-0000000-0') || 
        (NationalIdtrim == '222' && this.userForm.get('NationalId').value != '222-2222-2222222-2')){
      this.toastr.error("Please enter valid Id ")
      return
    }
   
  }

  // kinNationalidvalidation
  if(this.userForm.get('KinNationalId').value.length > 0) {
    if (this.userForm.get('KinNationalId').value.includes('_')) {
      this.toastr.error("Please enter valid Kin National Id");
      return
    }
    
    let doy;
    if (typeof this.userForm.get('DOB').value === 'string') {
      doy = this.userForm.get('DOB').value.split('-')[2];
    } else if (this.userForm.get('DOB').value instanceof Date) {
      const dateObject = new Date(this.userForm.get('DOB').value);
      doy = dateObject.getFullYear();
    }
    let NationalIdtrim=this.userForm.get('KinNationalId').value.substring(0, 3)
    if(NationalIdtrim != '000'&& NationalIdtrim != '111'&& NationalIdtrim != '222'&& NationalIdtrim != '784'){
      this.toastr.error("Please enter valid Kin National Id");
      return
    }
    if((NationalIdtrim == '111' && this.userForm.get('KinNationalId').value != '111-1111-1111111-1') ||
    (NationalIdtrim == '000' && this.userForm.get('KinNationalId').value != '000-0000-0000000-0') || 
    (NationalIdtrim == '222' && this.userForm.get('KinNationalId').value != '222-2222-2222222-2')){
  this.toastr.error("Please enter valid Kin National Id")
  return
} 


  }


    const formData = new FormData();

    //this.PatientRegistration['NationalId']=this.PatientRegistration.NationalId ? this.PatientRegistration.NationalId  : "111-1111-1111111-1";
    console.log(this.PatientRegistration)
    formData.append('PatientJson', JSON.stringify({...this.PatientRegistration,FaxNo:""}));
    if (this.documentFile) {
      for (var i = 0; i < this.documentFile.length; i++) {
        formData.append('PatientDocs', this.documentFile[i], this.documentFile[i].name);
      }
    }

    if (this.documentFile1) {
      //  for (var i = 0; i < this.documentFile1.length; i++) {
      // formData.append('PatientDocs', this.documentFile1, this.documentFile1.name);
      formData.append('PatientPhoto', this.documentFile1, this.documentFile1.name ? this.documentFile1.name : "pattientImage");
      // }
    }

    // formData.append('PatientPhoto', '');
    // formData.append('PatientPhoto', this.documentFile1, this.documentFile1.name);
    var url = "api/Registration/InsertPatientRegistration";
    //return false;

    if (this.patientId > 0) {

      if (this.userForm && this.userForm.valid) {
        this.isLoading=true;
        this.ngxLoader.start();
        // edit registration
        
        let payload = {
          BranchId: this.BranchesId,
          EmiratesCardNo: this.userForm.get('NationalId').value.toString()
        }
        this.patientService.CheckPatRegistration(payload).subscribe((res) => {
          // console.log('res', res);
          if(res.message == 'success' || true){
        this.patientService.SaveRegistration(formData).subscribe((res) => {
          this.submitted = false;
          this.result = res;
          this.enableRegButton = false;

          if (this.result.status == 200) {

            if (this.result.message != 'error') {
              this.ngxLoader.stop();
              //   Add Reg No from response here
              this.PrintSerials = [{
                SerialId: this.result.response[0].regNo,
                Name: 'A'
              },
              ];

              this.isLoading=false;
              this.toastr.success("Registration updated successfully");
              this.patCheckId = this.result.response[0]['patientId'];
              this.myFile = [];
              let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
              const sMenuId = Number(localStorage.getItem('selectedMenu'));
              let foundId;
              if(sMenuId == 13){
                foundId = 560;
              }else if(sMenuId == 4){
                foundId = 560;
              }else if(sMenuId == 124){
                foundId = 560;
              }
              if (this.HealthInformationPlatform.settValue === "Nabidh") {
              const foundElement = this.functionPermissionData.find(x => x.id === foundId)
              if (foundElement.id == foundId && foundElement.isAllowed == true) {
                let nabPayload = {
                  EventId: 1,
                  Consultationid: 0,
                  PatientId: this.patientId,
                  EntryDate: currentDate,
                  BranchId : this.globals.HospitalId
                }
                this.patientService.SendDetailstoNabith(nabPayload).subscribe((res) => {
                  this.toastr.info('Response from Nabith:' + res);
                })
              } else{
                this.toastr.warning("No permission to send data to nabith");
              }
            }
            }


            else {
              this.ngxLoader.stop();
              this.isLoading=false;
              this.toastr.error("Failure: " + this.result.errorMessage);
            }

          }


        })
      }else{
        this.toastr.error("Please enter unique id")
      }
    })
      }

      else {
        // debugger
        let result = this.f;

        this.isLoading = false;
        this.toastr.error("Please fill all required fields..", "");
        
      }

    }
    else if (this.userForm && this.userForm.valid) {

      let continueSave = true;

      if (this.enableNewCons) {

        // consulatation checled =true
        if (this.userForm.get("DepartmentId").value == "" || this.userForm.get("DepartmentId").value == 0) {
          this.toastr.error("Please select a department for consultation", "");
          continueSave = false;
          return
        }
        else if (this.userForm.get("ConsultantId").value == "" || this.userForm.get("ConsultantId").value == 0) {
          this.toastr.error("Please select a consultant for consultation", "");
          continueSave = false;
          return
        }
        else if (this.userForm.get("Scheme2").value == "" || this.userForm.get("Scheme2").value == 0) {
          this.toastr.error("Please select a Scheme for consultation", "");
          this.userForm.patchValue({
            Scheme2:0
          })
          continueSave = false;
          return
        }

      }
      if (continueSave) {
        this.isLoading = true;
        this.ngxLoader.start();
        let payload = {
          BranchId: this.BranchesId,
          EmiratesCardNo: this.userForm.get('NationalId').value.toString()
        }
        
        this.patientService.CheckPatRegistration(payload).subscribe((res) => {
          // console.log('res', res);
          if(res.message == 'success'){

        //   }
        // })
        this.patientService.SaveRegistration(formData).subscribe((res) => {
          this.result = res;
          this.enableRegButton = false;
          if (this.result.status == 200) {
            this.submitted = false;
            if (this.result.response[0]['errorMessage'] == 'success') {
              this.ngxLoader.stop();
              this.PrintSerials = [{
                SerialId: this.result.response[0].regNo,
                Name: 'A'
              }];
              this.toastr.success("Registration added successfully");
              this.myFile = [];
              this.user_id = this.result.response[0]['patientId'];
              this.patCheckId = this.result.response[0]['patientId'];
              this.paramValue = this.result.response[0]['patientId'];
              this.RegNo = this.result.response[0]['regNo']
              this.credConsultationId = this.result.response[0]['consultationId'];
              this.ClearForm();
              // this.RegNoModalPopUp();
              debugger
              if((this.result.response[0]['consentOptionId'] == 0 || this.result.response[0]['consentOptionId'] == 1) && this.nabithOptInOptOut[0].settValue == '1'){
                debugger
                this.nabithConsent(this.result.response[0]['patientId'],this.result.response[0]['consultationId']);
              } else{
                this.RegNoModalPopUp();
              }
              this.dataShare = true;
              const sMenuId = Number(localStorage.getItem('selectedMenu'));
              let foundId;
              if(sMenuId == 13){
                foundId = 562;
              }else if(sMenuId == 4){
                foundId = 562;
              }
              let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
              if (this.HealthInformationPlatform.settValue === "Nabidh") {
              const foundElement = this.functionPermissionData.find(x => x.id === foundId)
              if (foundElement.id == foundId && foundElement.isAllowed == true) {
                if (this.result.response[0]['consultationId'] !== 0 && this.result.response[0]['consultationId'] !== null) {
                  let nabPayload = {
                    EventId: 4,
                    Consultationid: this.result.response[0].consultationId,
                    EntryDate: currentDate,
                    PatientId: this.result.response[0].patientId,
                    BranchId : this.globals.HospitalId
                  }
                  this.patientService.SendDetailstoNabith(nabPayload).subscribe((res) => {
                    // console.log('res', res);
                    this.toastr.info('Response from Nabith:' + res);
                  })
                }
              }else{
                this.toastr.warning('No permission to send data to nabith');
              }
            }

            }
            else {
              this.isLoading = false;
              this.ngxLoader.stop();
              this.toastr.error("Failure: " + this.result.response[0]['errorMessage']);
            }
          } else {
            this.isLoading = false;
            this.ngxLoader.stop();
            this.toastr.error("Failure: " + this.result.response[0]['errorMessage']);
          }
        })
      }else {
        this.isLoading = false;
        this.ngxLoader.stop();
        this.toastr.warning(res.message);
      }
    })
        
      } // consulatation required checking

    }

    else {
      // show validation msgs
        this.toastr.error("Please fill all required fields..", "");

    }

    return false;


  }

  SymptomReason() {
    let val = this.userForm.get('Symptoms').value;

    if (val.length > 0) {

      if (val[0]['newsymDesc'] == 'None Of the Above') {

        this.OtherSymptom = true;
      }
      else {

        this.OtherSymptom = false;
      }
    }
    else {
      this.OtherSymptom = false;
    }
  }

  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]
  };

  CreditBillModal() {
    this.CreditBill = true;
    this.attachFlag = false;
  }
  BarCodeModal() {
    this.BarCode = true;
  }
  BarcodePrint() {


    // this.BarCode = true;
  }
  BarcodePrintModal() {

    this.PrintSerials;
    this.user_id;
    this.BarCodePrintingModal = true;

  }

  ConsentHistory() {
    let Appdata={
      patientId:Number(this.user_id),
      branchId:Number(this.globals.HospitalId),
      sponsorId:0,
      consentType:"PC"

    }

    this.patientService.ConsentHistoryModal(Appdata).subscribe((res) => {
      this.ConsentHistory = res.response;
      this.consentHistoryList = this.ConsentHistory[0]['consentContent']
      this.consentHistoryListTemp=this.consentHistoryList;
      // this.consentHistoryListArabic = this.ConsentHistory[0]['consentContentValue'][0].ctArabic;
      this.consentHistoryPatientName = this.ConsentHistory[0]['patientName'];
      this.HistoryConsent = true;
      this.BarCodePrintingModal = false;

      // this.modalRef = this.modalService.show(
      //   this.modaltemp,{ class: "modal-lg modal-custom-style" }

      // );

    });
  }

  ConsultationModals() {

    this.ConsultationsPopUp = true;
  }
  ConsultationPage() {
    let PatientId = this.user_id
    this.router.navigate(['/AddConsultation', PatientId]);
  }
  AddRegistrationPage() {
    let PatientId = this.user_id
    this.router.navigate(['/AddRegistration', PatientId]);
  }

  RegistrationPage() {
    this.router.navigate(['/registration']);
  }
  camerclick() {
    let initialState = {
    }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRef= this.modalService.show(WebCameraModalComponent,Object.assign( {}, config, { class: 'modal-md', initialState }) );
    this.modalRef.content.fileEmitter.subscribe((response: any)=>{
      if(response.status == true){
        this.documentFile1 = response.file;
      }
    })
  }

  onCloseCameraModal(){
    this.displayBasic = false;
    this.showWebcam = false;
  }

  fetchcard() {
    this.displayBasicFetchCard = true;
  }

  triggerSnapshot(): void {
    this.myObservable$ = new Subject();
    this.myObservable$.next();
    this.trigger.next();
  }
  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;

    const imageName = "patient.jpeg";// new Date().toString();
    const base64 = webcamImage.imageAsDataUrl.split('base64,');

    const imageBlob = this.dataURItoBlob(base64[1]);
    const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    this.documentFile1 = imageFile;

    this.generatedImage = window.URL.createObjectURL(imageFile);

  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }
  GetDepartments() {
    let showall = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showall).subscribe((res) => {
      this.departments = res.response;
    });
  }
  openWin() {
    this.patientService.GetPatientDetailsbyId(this.patientId).subscribe((res) => {
      this.patientDetails = res.response;
      this.patientAddress = this.patientDetails[0].regAddress;
      this.regIdentities = this.patientDetails[0].regIdentities;
    })
    let printer1 = window.open('', '', 'width=500,height=600');
    printer1.document.write("<html>");
    printer1.document.write("<head><title></title></head>");
    printer1.document.write("<body><table>");
    printer1.document.write("<tr><td><label>RegNo:</label> </td><td><p>" + this.patientDetails['regNo'] + "</p></td></tr>");
    printer1.document.write("<tr><td><label>PatientName</label></td><td><p>" + this.patientDetails['patientName'] + "</p></td><tr>");
    printer1.document.write("<tr><td><label>mobile:</label></td><td><p>" + this.patientDetails['mobile'] + "</p></td><tr>");
    printer1.document.write("<tr><td><label>email</label></td><td><p>" + this.patientDetails['email'] + "</p></td><tr>");
    printer1.document.write("</table></body>");
    printer1.document.write("</html>");
    printer1.document.close(); //missing code
    printer1.focus();
    printer1.print();
  }
  //Loading consultant method called from Service
  loadConsultant(DepartmentId: number) {
    this.consultants = null;
    this.schemeList = null;
    this.userForm.patchValue({ ConsultantId: '' });
    this.userForm.patchValue({ Scheme2: '' });
    let departmentId = Number(this.userForm.get("DepartmentId").value);
    let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
    this.hospitalsService.GetConsultant(departmentId,currentDate).subscribe((res) => {
      this.consultants = res.response;
      // debugger
      if(this.consultants.length && this.consultants.length == 1){
        this.userForm.patchValue({ ConsultantId: this.consultants[0].consultantId });
        this.GetScheme()
      }
    })
  }
  //LoadCountry method called from AppointmentService
  LoadCountry() {
    this.appointmentService.GetCountry(this.globals.HospitalId).subscribe((res) => {
      this.countryList = res.response;

      this.NationalityData = [];
      this.CountryListData = [];
      this.countryList.forEach(a => {
        this.NationalityData.push({ id: a.countryId.toString(), text: a.nationalityName });

        this.CountryListData.push({ id: a.countryId.toString(), text: a.countryName });

      });

    })

  }
  //Getting File Images in Modal
  // attachments() {
  //   this.displayBasicdragdrop = true;

  //   if (this.patientId) {
  //     this.patientService.GetPatientDocuments(Number(this.patientId)).subscribe((res) => {
  //       this.regDocLocation = res.response;
  //       this.documentList = this.regDocLocation[0]?.regDocLocation
  //     },
  //       (error) => {
  //         this.toastr.error("Failure: " + error);
  //       })
  //   }

  // }


  //Giving DOB method for calculating Age

  // ageCalculator(event) {
  //   // event = '11-12-2005';
  //   // this.userForm.get("DOB").setValue('11-12-2005');
  //   if (typeof event === "object" || typeof event == 'string') {

  //     this.age = this.userForm.get("DOB").value;
  //     let convertAge;
  //     if (typeof this.age == 'string') {
  //       let [day, month, year] = this.age.split('-')
  //       convertAge = new Date(+year, +month - 1, +day)
  //     } else {
  //       convertAge = new Date(this.age);
  //     }
  //     if (convertAge.toString() == "Invalid Date") {
  //       let datstring = this.age.split("-");
  //       if (datstring) {
  //         let nedob = datstring[2] + "/" + datstring[1] + "/" + datstring[0];
  //         var today = new Date();
  //         var birthDate = new Date(nedob);
  //         var age = today.getFullYear() - birthDate.getFullYear();
  //         var m = today.getMonth() - birthDate.getMonth();
  //         if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
  //           age--;
  //         }
  //         // this.userForm.patchValue({ Month: 0 });
  //         this.userForm.patchValue({ Age: age });
  //         this.userForm.patchValue({ Month: m });
  //       }
  //     }
  //     else {
  //       if (this.age) {
  //         let convertAge;
  //         if (typeof this.age == "string") {
  //           let [day, month, year] = this.age.split('-')
  //           convertAge = new Date(+year, +month - 1, +day)
  //         } else {
  //           convertAge = new Date(this.age);
  //         }
  //         const timeDiff = Math.abs(Date.now() - convertAge.getTime());
  //         this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  //         if (new Date().getMonth() >= convertAge.getMonth()) {
  //           this.birthMonth = Math.abs(new Date().getMonth() - convertAge.getMonth());
  //         }
  //         else {
  //           let newD = (new Date().getMonth() - convertAge.getMonth());
  //           this.birthMonth = Math.abs(newD + 12);
  //         }
  //         this.userForm.patchValue({ Month: this.birthMonth });
  //         this.userForm.patchValue({ Age: this.showAge });
  //       }
  //     }
  //   }
  //   this.updateKinValidators()
  // }

  ageCalculator(event) {
    // debugger;
    if (typeof event === "object" || typeof event === 'string') {
      this.age = this.userForm.get("DOB").value;
      let birthDate;
      if (typeof this.age === 'string') {
        let [day, month, year] = this.age.split('-');
        birthDate = new Date(+year, +month - 1, +day);
      } else {
        birthDate = new Date(this.age);
      }
      
      if (birthDate.toString() === "Invalid Date") {
        let datstring = this.age.split("-");
        if (datstring) {
          let nedob = datstring[2] + "/" + datstring[1] + "/" + datstring[0];
          var today = new Date();
          birthDate = new Date(nedob);
          var years = today.getFullYear() - birthDate.getFullYear();
          var months = today.getMonth() - birthDate.getMonth();
          var days = today.getDate() - birthDate.getDate();
          if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
            years--;
            months = 12 + months;
          }
          if (days < 0) {
            months--;
            days = new Date(today.getFullYear(), today.getMonth(), 0).getDate() + days;
          }
          // debugger;
          this.userForm.patchValue({ Age: years });
          this.userForm.patchValue({ Month: months });
          this.userForm.patchValue({ Days: days });
        }
      } else {
        var today = new Date();
        var years = today.getFullYear() - birthDate.getFullYear();
        var months = today.getMonth() - birthDate.getMonth();
        var days = today.getDate() - birthDate.getDate();
        if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
          years--;
          months = 12 + months;
        }
        if (days < 0) {
          months--;
          days = new Date(today.getFullYear(), today.getMonth(), 0).getDate() + days;
        }
        // debugger;
        this.userForm.patchValue({ Age: years });
        this.userForm.patchValue({ Month: months });
        this.userForm.patchValue({ Days: days });
      }
    }
    this.updateKinValidators();
  }
  

  //Age reversing method for calculating DOB
  dobCalculator() {

    document.removeEventListener("ngModelChange", this.ageCalculator, true);

    const now = new Date();

    this.showAge = this.userForm.get("Age").value;

    if (this.showAge) {
      now.setFullYear(now.getFullYear() - this.showAge);
    }


    let month = this.userForm.get("Month").value;
    if (month) {
      now.setMonth(now.getMonth() - month);
    }


    // this.birthMonth = this.userForm.get("Month").value;
    // if (this.birthMonth) {
    //   now.setMonth(now.getMonth() - this.birthMonth);
    // }
    this.age = this.datepipe.transform(now, 'dd-MM-yyyy')
    // console.log('age first', this.age);
    // console.log('age first type', typeof this.age);
    this.userForm.controls['DOB'].setValue(this.age, { emitEvent: false });
  }

  calculateMonthsFromDays() {
  // debugger
  document.removeEventListener("ngModelChange", this.ageCalculator, true);

  const now = new Date();

  const age = this.userForm.get("Age").value;
  const days = this.userForm.get("Days").value;

  if (age) {
    now.setFullYear(now.getFullYear() - age);
  }

  if (days) {
    now.setDate(now.getDate() - days);
  }

  this.age = this.datepipe.transform(now, 'dd-MM-yyyy');
  this.userForm.controls['DOB'].setValue(this.age, { emitEvent: false });
  }

  dobCalculatorMonth() {
    // console.log('dob month');
    let month = this.userForm.get("Month").value;
    // console.log('month', month);
    const now = new Date();
    let age = this.userForm.get("Age").value;
    // console.log('age month', age);
    if (age) {
      now.setFullYear(now.getFullYear() - age);
    }
    else {
      this.userForm.controls['Age'].setValue(0);
    }


    now.setMonth(now.getMonth() - month);
    let bday = this.datepipe.transform(now, 'dd-MM-yyyy');
    //alert(bday);
    this.userForm.controls['DOB'].setValue(bday, { emitEvent: false });
  }
  openModalConsent(template: TemplateRef<any>) {

    this.modalConsent = this.modalService.show(template, Object.assign({}, { class: "modal-lg modal-custom-style" }));

  }

  //check and uncheck new consultations
  selectchecking() {
    // debugger
    this.checkvalue = !this.checkvalue;
    this.DisplayedId = 1;
    this.updateNewConsultationValidators()

  }
  //check and uncheck of registration date
  selectcheckdate(evt) {
    if (evt.target.checked) {
      this.IsManualRegDate = true;
      this.userForm.controls['RegDate'].enable();
    }
    else {
      this.IsManualRegDate = false;
      this.userForm.controls['RegDate'].disable();
      let datecon = this.datepipe.transform(new Date(), 'dd-MM-yyyy')
      this.userForm.controls['RegDate'].setValue(datecon);
    }

  }

  //datepicker shows till todays date
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }

  // ManualRegdate checkbox onchange

  ManualRegdate(val) {
    if (val) {
      this.propertyRegDate = false;
    } else {
      this.propertyRegDate = true;

    }

  }

  // ManualRegNo checkbox onchange
  ManualRegNo(val) {

    if (val) {
      this.propertyRegNo = false;
      this.manualRegNo1 = 1;
      this.userForm.patchValue({ RegNo: '' });



    } else {
      this.propertyRegNo = true;
      this.manualRegNo1 = 0;
      this.userForm.patchValue({ RegNo: '<<NEW>>' });


      // this.GetRegNo();
    }

  }

  // fetchId for edit
  async fetchId() {
    this.activatedRoute.params.subscribe(params => {
      this.patientId = Number(params.id);

    });
    if (this.patientId) {
      this.isShowAddReg = true;
      this.isShowRegscheme = true;
      let res = await this.getApplicationSettings();
      this.fetchDetails(this.patientId);
      this.Activetab("Edit Patient Details");
      this.isShowRegDetails = false;


    }
  }

  // Fetch patient details by id for abinding and editing
  fetchDetails(Id: Number) {
    this.patientService.GetPatientDetailsbyId(Id).subscribe((res) => {
      this.patId = Id;
      this.patientDetails = res.response;
      this.patientAddress = this.patientDetails[0]['regAddress'];
      this.patCheckId = this.patientDetails[0]['patientId'];
      if (this.patientDetails[0]['regIdentities']) {
        this.regIdentities = this.patientDetails[0]['regIdentities'];
      }
      else {
        this.regIdentities = [];
      }

      for (var index in this.patientAddress) {
        var AddType = this.patientAddress[index].addType;
        if (this.patientAddress[index].countryId && this.patientAddress[index].state) {
          this.GetState(this.patientAddress[index].countryId, this.patientAddress[index].addType + 1);
        }

        // setTimeout(() => {
        if (AddType == 0) {
          this.userForm.patchValue({

            //address type0
            Address1: this.patientAddress[index].address1,
            Address2: this.patientAddress[index].address2,
            Street1: this.patientAddress[index].street,
            City1: this.patientAddress[index].city,
            Place1: this.patientAddress[index].placePO,
            Pin1: this.patientAddress[index].pin,
            NationalityId: this.patientDetails[0].nationalityId,
            Country1: this.patientAddress[index].countryId,
            State1: this.patientAddress[index].state,

          });
        }
        else if (AddType == 1) {
          this.userForm.patchValue({

            //address type0
            Address3: this.patientAddress.length > 0 ? this.patientAddress[index].address1 : "",
            Address4: this.patientAddress[index].address2,
            Street2: this.patientAddress[index].street,
            City2: this.patientAddress[index].city,
            Place2: this.patientAddress[index].placePO,
            Pin2: this.patientAddress[index].pin,
            Country2: this.patientAddress[index].countryId,
            State2: this.patientAddress[index].state,


          });
        }
        else if (AddType == 2) {
          this.userForm.patchValue({

            Address5: this.patientAddress[index].address1,
            Address6: this.patientAddress[index].address2,
            Street3: this.patientAddress[index].street,
            City3: this.patientAddress[index].city,
            Place3: this.patientAddress[index].placePO,
            Pin3: this.patientAddress[index].pin,
            Country3: this.patientAddress[index].countryId,
            State3: this.patientAddress[index].state,


          });
        }
      // },1000)

      }


      let dateobj = new Date(this.patientDetails[0].dob);
      this.dob = this.datepipe.transform(dateobj, 'yyyy-MM-dd');

      this.RegNo = this.patientDetails[0].regNo;
      this.RegistrationDate = this.patientDetails[0].regDate;
      this.userForm.patchValue({
        RegNo: this.RegNo,
        RegDate: this.RegistrationDate,
        FirstName: this.patientDetails[0].firstName,
        MiddleName: this.patientDetails[0].middleName,
        LastName: this.patientDetails[0].lastName,
        Salutation: this.patientDetails[0].salutation,
        NationalityId: this.patientDetails[0].nationalityId,
        //NationalityId:this.patientDetails[0].nationalityName,

        //  DOB:  this.age,
        Religion: this.patientDetails[0].religion,
        MaritalStatus: this.patientDetails[0].maritalStatus,
        ConfCodeId: this.patientDetails[0].confCodeId,
        ConsentOptionId : this.patientDetails[0].consentOptionId ,
        ConsentReason : this.patientDetails[0].consentReason ,
        DOB: dateobj,
        Age: this.patientDetails[0].ageInYear,
        Month: this.patientDetails[0].ageInMonth,
        Days:this.patientDetails[0]?.ageInDays,
        Gender: this.patientDetails[0].gender == 0 ? '0' : this.patientDetails[0].gender,
        Email: this.patientDetails[0].email,
        OffNo: this.patientDetails[0].offNo,
        ResNo: this.patientDetails[0].resNo,
        // FaxNo: this.patientDetails[0].faxNo,
        Mobile: this.patientDetails[0].mobile,
        RGroupId: this.patientDetails[0].rGroupId,
        ItemId: this.patientDetails[0].itemId,
        CommunicationType: this.patientDetails[0].communicationType,
        VisaTypeId: this.patientDetails[0].visaTypeId,
        KinName: this.patientDetails[0].kinName,
        KinPatId: this.patientDetails[0].kinPatId,
        KinNationalId: this.patientDetails[0].kinNationalId,
        KinMiddleName: this.patientDetails[0].kinMiddleName,
        KinLastName: this.patientDetails[0].kinLastName,
        KinType: this.patientDetails[0].kinType == 0 ? 0 : this.patientDetails[0].kinType,
        KinRelation: this.patientDetails[0].kinRelation == 0 ? 0 : this.patientDetails[0].kinRelation,
        Remarks: this.patientDetails[0].remarks,
        OtherReasonVisit: this.patientDetails[0].OtherReasonVisit,
        KinContactNo: this.patientDetails[0].kinContactNo ? this.patientDetails[0].kinContactNo : "",
        KinEmail:this.patientDetails[0].kinEmail ? this.patientDetails[0].kinEmail : "",
        WorkEnvironment: this.patientDetails[0].workEnvironMent,
        ProfessionalExperience: this.patientDetails[0].professionalExperience,
        //ProfId: this.patientDetails[0].profId == 0 ? 0 : this.patientDetails[0].profId,
        ProfId: this.patientDetails[0].profId,

        // CmpId: this.patientDetails[0].cmpId,
        CmpId: this.patientDetails[0].cmpId,

        ProfessionalNoxious: this.patientDetails[0].professionalNoxious,
        IsVIP:this.patientDetails[0].isVIP,
      });


      if (this.regIdentities.length > 0) {
        this.userForm.patchValue({
          //identities type0
          PinOrvid: this.regIdentities[0].identityNo,
          //identities type1
          PassportNo: this.regIdentities[1].identityNo,
          //identities type2
          DrivingLicNo: this.regIdentities[2].identityNo,
          //identities type3
          HealthCardId: this.regIdentities[3].identityNo,
          //identities type4
          LabourCardNo: this.regIdentities[4].identityNo,
          //identities type4
          VisaOrpermitNo: this.regIdentities[5].identityNo,
          //identities type4
          NationalId: this.regIdentities[6].identityNo,//==0?"":this.regIdentities[6].identityNo
        });
      }
      this.onVisaChange()
      // this.FillDefaultForm(res.response);
    })
  }



  // function for hiding the loader
  hideloader() {


    // Setting display of spinner
    // element to none
    document.getElementById('loadingbody').style.display = 'none';
  }

  // function for showing loader
  showloader() {

    // Setting display of SaveRegistrations
    // element to none
    document.getElementById('loadingbody').style.display = 'block';
  }

  //reason for visit Typeable tag UI
  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.userForm.controls.reasonForVisit.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Space') {
        this.addTag(inputValue);
        this.userForm.controls.reasonForVisit.setValue('');
      }
    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.tags, tag)) {
      this.tags.push(tag);
    }
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      pull(this.tags, tag);
    } else {
      this.tags.splice(-1);
    }
  }


  onUpload(event) {
    const formData = new FormData();
    formData.append('firstname', 'test');
    this.documentFile = <File[]>event.files;
    this.displayBasicdragdrop = false;
    this.toastr.success("Files will be uploaded when patient details saved");

  }

  webCamSave(event) {
    const formData = new FormData();
    formData.append('firstname', 'test');
    this.onCloseCameraModal();
    this.toastr.success("Image will be uploaded when patient details saved");
  }
  //ng-autocomplete countrylist select event
  selectEvent(item: any, state: any) {

    this.defaultValueList.find(x => {
      if(x.displayName == "DefaultCountry" && state == 1 && x.settValue !== this.userForm.controls.Country1.value) {
        this.userForm.controls.State1.reset();
      }
      if(x.displayName == "DefaultCountry" && state == 2 && x.settValue !== this.userForm.controls.Country2.value) {
        this.userForm.controls.State2.reset();
      }
      if(x.displayName == "DefaultCountry" && state == 3 && x.settValue !== this.userForm.controls.Country3.value) {
        this.userForm.controls.State3.reset();
      }
    });
    this.GetState(item, state);
  }
  onChangeClearCountry(item: any, stateRef: any) {

    if (stateRef == 1) {
      // State1:{'stateId':this.patientAddress[index].state ? this.patientAddress[index].state : '0','stateName':this.patientAddress[index].stateName ? this.patientAddress[index].stateName : ""},
      this.state1List = [];
      this.userForm.patchValue({ State1: { 'stateId': 0, 'stateName': "" } });

    } else if (stateRef == 2) {
      this.state2List = [];
      this.userForm.patchValue({ State2: { 'stateId': 0, stateName: "" } });
    } else if (stateRef == 3) {
      this.state3List = [];
      this.userForm.patchValue({ State3: { 'stateId': 0, stateName: "" } });
    }


  }


  onChangeSearch(item: any, state: any) {
    this.GetState(item.countryId, state)
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  // delete Uploaded documents
  DeleteUploadedDoc(docId) {
    if (confirm("Are you sure that you want to delete this file ?")) {
      this.patientService.DeleteUploadedDoc(Number(docId)).subscribe((res) => {
        this.result = res;
        if (this.result.message == 'success') {
          // this.attachments();
          // this.displayBasicdragdrop = false;

          this.toastr.success("The file deleted successfully");
          
        }
        else {
          this.toastr.error("Failure: " + this.result.message);
          
        }

      },
        (error) => {
          this.toastr.error("Failure: " + error);
        });
    }
  }

  clearDoc(){

    }



  //Getting teplates for symtoms and reason for visit
  GetReasonForVisit() {
    let ShowAll = 0;
    let reasonvisit = [];
    this.consultationService.GetTemplateReasonForvisit(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.symptomList = res.response;
      this.symptomList.forEach((element, key) => {
        reasonvisit.push({ 'id': element['symptomId'], 'text': element['symptomDesc'] });

      });
      this.symptom = reasonvisit;

    })
  }
  SaveBarcode() {
    this.BarcodePrintModal();
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.signaturePad.maxWidth = 0.9;
  }

  startDrawing(event: Event) {

    // works in device not in browser

  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }
  //Saving Signature Pad
  savePad() {
    this.ConsentHistory;
    const signName = "sign.jpeg";
    const base64Data = this.signaturePad.toDataURL();
    let signsplit = base64Data.split(';');
    const signBlob = this.dataURItoBlob(signsplit[1].split(',')[1]);
    const signFile = new File([signBlob], signName, { type: 'image/jpeg' });
    this.documentFile2 = signFile;
    const formData = new FormData();
    formData.append('Sign', this.documentFile2, this.documentFile2.name);
    var AppData = {
      patientId: this.user_id,
      ConsentId:0,
      BranchId: this.globals.HospitalId,
      TypeOfConsent:"Pc",
      consentContent:this.consentHistoryList

    }
    formData.append('ConsentJson', JSON.stringify(AppData));
    this.patientService.ConsentFormDataSave(formData).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Saved Your Signature  successfully");
      }
      else {
        this.toastr.error("Failed.Please try later");
      }
    })
  }


  //print modal token
  PrintModalToken() {
    let printContents = '';
    //contact detatils
    printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:100%">';
    printContents = printContents + ' <table class="print-table1"><tr><td><h3>Kindly Present This Token To Doctor-Cashier-Lab-Treatment Room</h3></td></tr><tr><td><strong><h3>Occupational HealthCare International04-230640</h3></strong></td></tr>';
    printContents = printContents + ' <tr><td><strong>Name:</strong></td><td>' + 'TestCase' + '</td></tr>';

    printContents = printContents + ' <tr><td><strong>Age:</strong></td><td>' + '29' + '</td></tr>';

    printContents = printContents + ' <tr><td><strong>RegNo:</strong></td><td>' + + '</td></tr>';
    printContents = printContents + ' <tr><td>   <ngx-barcode  [bc-display-value]="true"> </ngx-barcode> </td><td>' + + '</td></tr>';

    printContents = printContents + ' <tr><td><strong>Mobile:</strong></td><td>' + + '</td></tr>';

    var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
    mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(printContents);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  }
  //showing regno popup message

  RegNoModalPopUp() {
    this.regNoModal = true;
    this.RegNo;
  }

  nabithConsent(patientId,consultationId) {
    if(this.HealthInformationPlatform.settValue === "Nabidh"){
      let initialState = {
        patientId: patientId,
        ConsultationId: 0,
        action: 0,
        formName:this.HealthInformationPlatform.settValue
      }
  
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.modalRef= this.modalService.show(NabithConsentComponent,Object.assign( {}, config, { class: 'modal-xl', initialState }) );
      this.modalRef.content.fileEmitter.subscribe((response: any)=>{
        if(response.status == true){
          this.RegNoModalPopUp();
        }
      })
    } else if (this.HealthInformationPlatform.settValue === "Riayati") {
      let initialState = {
        patientId: patientId,
        ConsultationId: consultationId ? consultationId : 0,
      }

      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.modalRef = this.modalService.show(RiayatiConsentComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
      this.modalRef.content.fileEmitter.subscribe((response: any) => {
        if (response.status == true) {
          // this.toastr.success("The signature updated successfully");
        }
      })
    }
   
  }

  //open image modal
  OpenImage(filePath: string) {//document view
    window.open(filePath, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
  }

  newAdd() {
    this.router.navigate(['/AddRegistration']);
  }

  getRegistrationPageDynamicValidators(){
    let payload= {
      FormName:"Registration",
      BranchId:this.globals.HospitalId
    }
    this.ConfigurationService.getSavingSchemeData(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status){
          let dynamicValidation = response.response;
          this.validationForReg = response.response;
          for(let field of dynamicValidation){
            if(field.schemeValue){
             
              this.userForm.get(field.propertyName)?.setValidators([Validators.required]);
              if(field.propertyName == 'Salutation' || field.propertyName == 'MaritalStatus' ||
                 field.propertyName == 'Religion' || field.propertyName == 'VisaTypeId' || 
                 field.propertyName == 'CommunicationType' || field.propertyName == 'DepartmentId' ||
                 field.propertyName == 'Scheme2' || field.propertyName == 'KinRelation'){
                  this.userForm.get(field.propertyName).setValidators(Validators.min(1));
    
            }
            if(field.propertyName == 'Email'){
              this.userForm.get(field.propertyName)?.setValidators([Validators.email,Validators.required]);
              }
              if(!this.isShowRegDetails){
                this.userForm.get('DepartmentId')?.clearValidators();
                this.userForm.get('ConsultantId')?.clearValidators();
                this.userForm.get('Scheme2')?.clearValidators();
                this.userForm.get('ConsultFee')?.clearValidators();
                this.userForm.get('Emergency')?.clearValidators();
                this.userForm.get('ReferedBy')?.clearValidators();
                this.userForm.get('Symptoms')?.clearValidators();
                this.userForm.get('otherreasonforvisit')?.clearValidators();
                this.userForm.get('ReferedBy')?.clearValidators();
  
              }
              console.log(field.propertyName)
              this.userForm.get(field.propertyName)?.updateValueAndValidity();
            }
 
          }
           this.updateNewConsultationValidators()

          this.updateKinValidators()
          this.onVisaChange()
          console.log(this.userForm)
        }
      }
    })
    console.log(this.userForm)
  }

  updateKinValidators() {
    const isAgeLessThan18 = this.userForm.controls.Age.value ? this.userForm.controls.Age.value < 18 :false;
    // Fetch the keys for which validators need to be updated
    const keysToUpdateValidators = [
      'KinMiddleName',
      'KinLastName',
      'KinPatId',
      'KinContactNo',
      'KinEmail',
      'KinNationalId',
      'KinType',
      'KinName',
      'KinRelation',
      'Remarks'
    ];
  
    keysToUpdateValidators.forEach(key => {
      const ValidationObj = this.validationForReg.find(item => item.propertyName === key);
      const control = this.userForm.controls[key];
      if (ValidationObj.schemeValue && this.kinValidation[0].settValue == '1' && isAgeLessThan18) {
        // If conditions are met, set validators.required
        control.setValidators([Validators.required]);
        if(key === "KinEmail"){
          control.setValidators([Validators.email,Validators.required]);
        }
      } else {
        // If conditions are not met, clear validators
        control.clearValidators();
        if(key === "KinEmail"){
          control.setValidators([Validators.email]);
        }
        
      }
      // Update the control's status
      control.updateValueAndValidity();
    });
    if(this.userForm.controls.Age?.value < 18 && this.kinValidation[0].settValue == '1'){
      this.userForm.controls['KinName'].setValidators([Validators.required]);
      this.userForm.controls['KinName'].updateValueAndValidity()
    }
    
  }

  // update validators in new consultation
  updateNewConsultationValidators() {
    
    const keysToUpdateValidators = [
      'DepartmentId',
      'ConsultantId',
      'Scheme2',
      'ConsultFee',
      'Emergency',
      'ReferedBy',
      'Symptoms',
      'otherreasonforvisit'
    ];
    
    if ( this.checkvalue) {
      
      keysToUpdateValidators.forEach(controlName => {
        this.userForm.get(controlName)?.clearValidators();
        this.userForm.get(controlName).updateValueAndValidity();
      });
    }else{
      keysToUpdateValidators.forEach(key => {
        
        const ValidationObj = this.validationForReg.find(item => item.propertyName === key);
        const control = this.userForm.controls[key];
        if (ValidationObj?.schemeValue) {
          // If conditions are met, set validators.required
          control.setValidators([Validators.required]);
        } else {
          // If conditions are not met, clear validators
          control.clearValidators();
        }
        // Update the control's status
        control.updateValueAndValidity();
      });
    }

   
  }
  

  getRegistrationPageTabOrder(){
    let payload= {
      ScreenName:"Registration",
      BranchId:this.globals.HospitalId
    }
    this.ConfigurationService.getTabSchemeData(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.tabOrders = response.response;
          let first = this.tabOrders.find(item=>{
            return item.objectOrder == 1;
          })
          this.changeFocus(first.objectName);
          this.tabClickCount = 1;
        }else{

        }
      },error: (error: any)=>{

      }
    })
  }

  changeFocus(fieldName: string){
    document.getElementById(fieldName).focus();
  //  let element =  document.getElementById(fieldName);
  //  let selectNode = (element.getElementsByTagName('select'));
  //  let spanNode = (element.getElementsByTagName('span'));
  //  if(selectNode && spanNode){
  //   console.log('ok')
  //   // selectNode[0].focus();
  //   console.log(this.ngSelect)
  //   this.ngSelect.element[0].focus();
  //   this.ngSelect.element[0].click();
  //   console.log(this.ngSelect)
    
  //   // this.renderer.addClass(spanNode[0], 'select2-container--below')
  //   // this.renderer.addClass(spanNode[0], 'select2-container--open')
  //   // spanNode[0].getElementsByTagName('span')[0].getElementsByTagName('span')[0].getElementsByTagName('span')[0].click();
  //  }
   
   
  }

  getAppoinmentDetails(appoinmentId: number){
    this.appointmentService.GetAppoinmentDetailsbyId(Number(appoinmentId)).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          const data = response.response;
          let convertAge = new Date(data.dob);
          const timeDiff = Math.abs(Date.now() - convertAge.getTime());
          this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
          this.userForm.patchValue({ Age: this.showAge });
          this.birthMonth = Math.abs(new Date().getMonth() - convertAge.getMonth());
          this.userForm.patchValue({ Month: this.birthMonth });
          this.userForm.patchValue({DepartmentId: data.deptId});
          this.loadConsultant(data.deptId);
          this.userForm.patchValue({
            Salutation: data.title,
            FirstName: data.firstName,
            MiddleName: data.middleName,
            LastName: data.lastName,
            Gender: data.gender,
            MaritalStatus: data.maritalStatus,
            DOB: this.datepipe.transform(data.dob, 'dd-MM-yyyy'),
            // NationalityId: data.countryId,
            Mobile: data.mobile,
            ResNo: data.resPhone,
            OffNo: data.offPhone,
            Email: data.email,
            Address1: data.address1,
            Address2: data.address2,
            Place1: data.placePO,
            City1: data.city,
            Pin1: data.pin,
            Country1: data.countryId,
            State1: data.state,
            AppId: appoinmentId,
          })
          this.userForm.patchValue({ConsultantId: data.consultantId})
          this.GetScheme();
        }else{
          this.toastr.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastr.error('Something wents wrong... Try again.!');
      }
    })
  }

  gotoOpenCredit(){
    console.log('credconsult', this.credConsultationId);
    let input = {
      patientId: Number(this.user_id),
      creditId: '',
      patientName: this.PatientRegistration.FirstName + ' '+this.PatientRegistration.MiddleName+ ' '+this.PatientRegistration.LastName,
      regNo: this.RegNo,
      credConsultationId: this.credConsultationId
    }

    this.router.navigate(['/OpenCredit'], { state: { editData: input } });
  }

  // openFetchCardModal(){
  //   let test = '456-5657-5676687-8';
  //   let data;
  //   if(test.includes('-')){
  //     console.log('has hiphen', test);
  //     data = test.replace(/-/g, '');
  //   }
  //   console.log('data', data);
  //   this.userForm.patchValue({
  //     NationalId: '784198668027109'
  //   })
  // }

  convertBase64ToFile(base64String: string): File {
    const binaryString = atob(base64String);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'image/png' }); // Replace 'image/png' with the appropriate MIME type if needed
    const file = new File([blob], 'filename.png', { type: 'image/png' }); // 
    return file
  }

  openFetchCardModal(){

    let initialState = {
    }

    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const modalRef= this.modalService.show(FetchEmiratesCardModalComponent,Object.assign({class: "modal-dialog-centered "}, historyConfig, { class: 'modal-md', initialState }) );
    modalRef.content.emirateCardDataEmitter.subscribe((response: any)=>{
      if(response.status){
        console.log('response', response);
        console.log('cardData', response.cardData);
        let cardData = response.cardData;
        this.documentFile1=this.convertBase64ToFile(cardData.cardHolderPhoto)
        // console.log('fullNameEnglish', cardData?.nonModifiablePublicData?.fullNameEnglish);
        // console.log('firstname', cardData?.nonModifiablePublicData?.fullNameEnglish?.split(','))[0];
        // console.log('middlename', cardData?.nonModifiablePublicData?.fullNameEnglish?.split(','))[1];
        // console.log('lastname', cardData?.nonModifiablePublicData?.fullNameEnglish?.split(','))[2];
        let test = cardData?.nonModifiablePublicData?.fullNameEnglish?.split(',');
        console.log('test', test);
        let arr =[];
        test.find(x => {
          if(x !== ''){
            arr.push(x);
          }
        })
        console.log('arr.length', arr.length);
        if(arr.length == 2){
          this.userForm.patchValue({
            FirstName: arr[0],
            LastName: arr[1],
          })
        } else if(arr.length == 1){
          this.userForm.patchValue({
            FirstName: arr[0],
            LastName: '',
          })
        } else if(arr.length == 3 || arr.length > 3){
          this.userForm.patchValue({
            FirstName: arr[0],
            MiddleName: arr[1],
            LastName: arr[2],
          })
        }

        let nId = '0';
        let nation = cardData?.nonModifiablePublicData?.nationalityEnglish;
        console.log('nation', nation);
        if(this.CountryListData.length !== 0){
          this.CountryListData.find(x => {
            let tx = x.text.replace(/[^\w\s]/gi, '').replace(/\s+/g, '').toLowerCase();
            let data = nation.replace(/[^\w\s]/gi, '').replace(/\s+/g, '').toLowerCase();
            if(tx == data){
              nId = x.id;
            }
          })
          console.log('nId', nId);
          this.userForm.patchValue({
            NationalityId: nId
          })
        }
        this.userForm.patchValue({
          // FirstName: (cardData?.nonModifiablePublicData?.fullNameEnglish?.split(','))[0],
          // MiddleName: (cardData?.nonModifiablePublicData?.fullNameEnglish?.split(','))[1],
          // LastName: (cardData?.nonModifiablePublicData?.fullNameEnglish?.split(','))[2],
          Gender: cardData?.nonModifiablePublicData?.gender == 'M' ? 1 : (cardData?.nonModifiablePublicData?.gender == 'F' ? 2 : 3),
          NationalId: this.formatNumericString(cardData?.iDNumber),
          DOB: (cardData?.nonModifiablePublicData?.dateOfBirth?.replaceAll('/','-')),
          ResNo: _.isEmpty(cardData?.homeAddress?.residentPhoneNumber) ? '' : cardData?.homeAddress?.residentPhoneNumber,
          OffNo: _.isEmpty(cardData?.workAddress?.mobilePhoneNumber) ? '' : cardData?.workAddress?.mobilePhoneNumber,
          PassportNo: _.isEmpty(cardData?.modifiablePublicData?.passportNumber) ? '' : cardData?.modifiablePublicData?.passportNumber,
          Address1: _.isEmpty(cardData?.homeAddress?.flatNo) ? '' : cardData?.homeAddress?.flatNo,
          Address2: _.isEmpty(cardData?.homeAddress?.buildingNameEnglish) ? '' : cardData?.homeAddress?.buildingNameEnglish,
          Place1: _.isEmpty(cardData?.homeAddress?.streetEnglish) ? '' : cardData?.homeAddress?.streetEnglish,
          City1: _.isEmpty(cardData?.homeAddress?.cityDescEnglish) ? '' : cardData?.homeAddress?.cityDescEnglish,
          Pin1: _.isEmpty(cardData?.homeAddress?.pOBOX) ? '' : cardData?.homeAddress?.pOBOX,
          Address3: _.isEmpty(cardData?.homeAddress?.flatNo) ? '' : cardData?.homeAddress?.flatNo,
          Address4: _.isEmpty(cardData?.homeAddress?.buildingNameEnglish) ? '' : cardData?.homeAddress?.buildingNameEnglish,
          Place2: _.isEmpty(cardData?.homeAddress?.streetEnglish) ? '' : cardData?.homeAddress?.streetEnglish,
          City2: _.isEmpty(cardData?.homeAddress?.cityDescEnglish) ? '' : cardData?.homeAddress?.cityDescEnglish,
          Pin2: _.isEmpty(cardData?.homeAddress?.pOBOX) ? '' : cardData?.homeAddress?.pOBOX,
          Street2: _.isEmpty(cardData?.homeAddress?.streetEnglish) ? '' : cardData?.homeAddress?.streetEnglish,
          Address5: _.isEmpty(cardData?.workAddress?.companyNameEnglish) ? '' : cardData?.workAddress?.companyNameEnglish,
          Address6: _.isEmpty(cardData?.workAddress?.buildingNameEnglish) ? '' : cardData?.workAddress?.buildingNameEnglish,
          Place3: _.isEmpty(cardData?.workAddress?.areaDescEnglish) ? '' : cardData?.workAddress?.areaDescEnglish,
          City3: _.isEmpty(cardData?.workAddress?.cityDescEnglish) ? '' : cardData?.workAddress?.cityDescEnglish,
          Pin3: _.isEmpty(cardData?.workAddress?.pOBOX) ? '' : cardData?.workAddress?.pOBOX,
          Street3: _.isEmpty(cardData?.workAddress?.streetEnglish) ? '' : cardData?.workAddress?.streetEnglish,
          
        })
        if(!this.isEdit){
          this.userForm.patchValue({
            Email: _.isEmpty(cardData?.homeAddress?.email) ? '' : cardData?.homeAddress?.email,
            Mobile: cardData?.homeAddress?.mobilePhoneNumber,
          })
        }
        this.setTitle()
        this.ageCalculator(this.userForm.controls.DOB.value);
        this.onVisaChange()
      }
    })
  }

  formatNumericString(input: string): string {
    // Remove any non-numeric characters
    const numericOnly = input.replace(/\D/g, '');
  
    // Check if the length is exactly 15 characters
    if (numericOnly.length === 15) {
      // Format the string using the specified pattern
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3, 7)}-${numericOnly.slice(7, 14)}-${numericOnly.slice(14)}`;
    } else {
      // Return the original string if it doesn't match the expected length
      return input;
    }
  }

  async getApplicationSettings() {
    let payload= {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }
    let res = await this.patientService.getApplicationSettings(payload).toPromise();
    // console.log('res', res);
    // this.patientService.getApplicationSettings(payload).subscribe((res) => {
    // console.log('res', res);
      this.GetConsentOption()
      this.defaultValueList = _.filter(res.response, {tabId: 3});
      this.configurationSettings = _.filter(res.response, {tabId: 1});
      this.nabithOptInOptOut = _.filter(this.configurationSettings, {displayName: 'EnableNabOptinOptOutforRegistration'});
      this.kinValidation = _.filter(this.configurationSettings, {displayName: 'EnablekinForMinor'});
      // this.defaultValueList = res.response;
      this.HealthInformationPlatform=res.response.find(item => item.settId == 25)
      this.defaultValueList.find(x => {
        if(x.displayName == 'DefaultCountry') {
          this.defaultCountry1 = x.settValue;
          this.defaultCountry2 = x.settValue;
          this.defaultCountry3 = x.settValue;
        }
        if(x.displayName == 'DefaultState') {
          this.defaultState1 = x.settValue;
          this.defaultState2 = x.settValue;
          this.defaultState3 = x.settValue;
        }
        if(x.displayName == 'DefaultScheme') {
          this.defaultScheme = x.settValue;
        }
        if(x.displayName == 'DefaultRateGroup') {
          this.defaultRateGroup = x.settValue;
        }
        if(x.displayName == 'DefaultPatConsentOption') {
          this.defaultPatConsentOption = x.settValue;
        }
        if(x.displayName == 'DefaultConfidentialityCode') {
          this.defaultConfidentialityCode = x.settValue;
        }
        if(x.displayName == 'DefaultCommunicationType') {
          this.defaultCommunicationType = x.settValue;
        }
        if(x.displayName == 'DefaultVisaType'){
          this.userForm.get('VisaTypeId').setValue(x.settValue)
        }
        if(x.displayName == 'DefaultTitle'){
          this.userForm.get('Salutation').setValue(x.settValue)
        }
        if(x.displayName == 'DefaultMartialStatus'){
          this.userForm.get('MaritalStatus').setValue(x.settValue)
        }
        if(x.displayName == 'DefaultGender'){
          this.userForm.get('Gender').setValue(x.settValue)
        }
        if(x.displayName == 'DefaultNationality'){
          this.defaultNationality = x.settValue;
          this.userForm.get('NationalityId').setValue(x.settValue)
        }
        if(x.displayName == 'HealthInsurancePlatform'){
          if(x.settValue === 'None') {
            this.showHealthInsurancePlatform = false
          } else {
            this.showHealthInsurancePlatform = true
            this.externalSrc = x.settValue.charAt(0)?.toUpperCase();
          }
        }
      });

      this.onVisaChange()

    // })
  }

  SearchPatientDetail(patient: any) {
    let _this = this;
    this.GetPatientByRegNo();
  }

  GetPatientByRegNo() {
    this.patientService.SearchPatientByRegNo(this.userForm.get("KinPatId").value).subscribe((res) => {
      this.patients = res.response;
      
      if (this.patients.length > 0) {
        if (this.patients[0].active == "0") {
          this.toastr.warning("This Patient is blocked");
          this.ClearForm();
          this.userForm.patchValue({ KinPatId: this.patients[0].regNo });
          this.patients = [];
          return
        }
        this.FillForm1(this.patients[0]);
      } else {
        this.toastr.warning("No patients found");
      }
    });
  }

  showBasicDialog() {
    this.searchModal.open();
    this.userForm.patchValue({ KinName: "" });
    this.userForm.patchValue({ KinMiddleName : "" });
    this.userForm.patchValue({ KinLastName: "" });
    this.userForm.patchValue({ KinPatId : "" });
    this.userForm.patchValue({ KinContactNo: "" });
    this.displayModalBasic = true;
  }

  showBasicDialogSearch($event) {
    this.displayModalBasic = $event;
  }

  //common function added for filling kin details on clicking search patient modal
  searchevent(patient: any) {

    this.kinId = patient.data.patientId;

    this.userForm.patchValue({ KinPatId: patient.data.regNo });
    this.userForm.patchValue({ KinName: patient.data.firstName });
    this.userForm.patchValue({ KinMiddleName: patient.data.middleName });
    this.userForm.patchValue({ KinLastName: patient.data.lastName });
    this.userForm.patchValue({ KinContactNo: patient.data.mobile });
    this.userForm.patchValue({ KinEmail: patient.data.email });
    this.userForm.patchValue({ KinType: this.kinTypeList[0].id });
    this.userForm.patchValue({ KinNationalId: patient.data.nationalityId });
    this.displayBasic = false;
  }

  // on entering reg no filling patient details on form
  FillForm1(patient: any) {

    this.userForm.patchValue({ KinPatId: patient["regNo"] });
    this.userForm.patchValue({ KinName: patient["firstName"] });
    this.userForm.patchValue({ KinMiddleName: patient["middleName"] });
    this.userForm.patchValue({ KinLastName: patient["lastName"] });
    this.userForm.patchValue({ KinContactNo: patient["mobile"] });
    this.userForm.patchValue({ KinEmail: patient["email"] });
    this.userForm.patchValue({ KinType: this.kinTypeList[0].id });
    this.userForm.patchValue({ KinNationalId: patient['nationalityId'] });
    this.displayBasic = false;
  }

  attachments(route){
      let patId;
    if(route == 'new'){
      patId = Number(this.paramValue);
    } else{
      this.activatedRoute.params.subscribe(params => {
        patId = Number(params.id);

      });
    }
      let patientData = {
        patientId: patId,
      }
    let initialState = {
      rowDatas: patientData
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.modalRefer = this.modalService.show(AttachmentModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
    this.modalRefer.content.docEmitter.subscribe((response: any)=>{
      if(response.status == 'closed'){
        if(route == 'new') {
          this.CreditBillModal();
        }
      }
    })
  }

  attachDocModal() {
    this.attachFlag = true;
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  // custom validators
   nationalIdValidator(dob: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
  
      // Check if the first 3 digits are 784 or 111
      const isValidPrefix = /^(784|111)/.test(value.substring(0, 3));
  
      // Check if the next 4 digits match the provided DOB
      const isValidDOB = value.substring(3, 7) === dob;
  
      // Return validation errors if conditions are not met
      return !(isValidPrefix && isValidDOB) ? { invalidNationalId: true } : null;
    };
  }

  getMaxPatientsPerDayForConsultant(){
    let currentappDate = new Date();
   this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    let payload={
      ConsultDate:this.datepipe.transform(currentappDate, 'yyyy-MM-dd'),
      ConsultantId:this.userForm.value.ConsultantId
    }
    this.sharedservice.getGetMaxPatientsPerDayForConsultant(payload).subscribe({
      next:(res)=>{
        console.log(res)
      }
    })
  }

  setTitle(){
    const Gender=this.userForm.value.Gender
    if(Gender == 1){
      this.userForm.patchValue({
        Salutation:5
      })
    }else if(Gender == 2){
      this.userForm.patchValue({
        Salutation:7
      })
    }
    
  }

  setGender(){
    const Salutation=this.userForm.value.Salutation
    if(Salutation == 3 || Salutation == 5 ){
      this.userForm.patchValue({
        Gender:1
      })
    }else if(Salutation == 7 || Salutation == 6 ){
      this.userForm.patchValue({
        Gender:2
      })
    }   
  }

  onVisaChange(){
   let VisaTypeId =this.userForm.value.VisaTypeId
   this.validationForReg
   const ValidationObj = this.validationForReg?.find(item => item.propertyName === 'PassportNo');
   if(!ValidationObj?.schemeValue) this.userForm.get('PassportNo')?.clearValidators();
   if(VisaTypeId == 2){
     this.userForm.get('PassportNo')?.setValidators([Validators.required]);
   }
    this.userForm.get('PassportNo').updateValueAndValidity();
  }


}
