import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { ConsultantDeskService } from '../services/consultant.service';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-fullcalender',
  templateUrl: './fullcalender.component.html',
  styleUrls: ['./fullcalender.component.scss']
})
export class FullcalenderComponent implements OnInit {
  scheduleTime: any;
  LoadSchedule: any;
  scheduleTimeDelete: any;
  scheduletitle: any = [];
  calendarOptions: CalendarOptions;
  Id: any;
  submitted:boolean=false;
  result: any;
  ScheduleId: any;
  subscription: Subscription;
  globals: any;
  BranchesId: any;
  modalRef: BsModalRef;
  @ViewChild("template") modaltemp: any;
  ScheduleForm: FormGroup;
  GetEventDetails: any;
  calendarApiObj:any;
  // calendarVisible = true;

  constructor(
    private ConsultantDeskService: ConsultantDeskService,
    private toastr: ToastrService,
    private router: Router,
    private sharedservice: SharedService,
    private modalService: BsModalService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        // this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId

      }
    });
  }
  ngOnInit(): void {
    this.Id = localStorage.getItem("Id");
    this.LoadScheduleEvents();

    this.ScheduleForm = new FormGroup({
      EventTypeId: new FormControl('',[Validators.required]),
      Title: new FormControl('',[Validators.required]),

    });
    setTimeout(() => {

      this.calendarOptions = {
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },

        initialView: 'timeGridWeek',
        initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        weekends: true,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this),
        events: this.scheduletitle
      };

    }, 2000);

  }


  currentEvents: EventApi[] = [];

  // handleCalendarToggle() {
  //   this.calendarVisible = !this.calendarVisible;
  // }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.modalRef = this.modalService.show(
      this.modaltemp,
      Object.assign({})
    );
    this.GetEventType();

    // const title = prompt('Please enter  title for your event');
     this.calendarApiObj = selectInfo;
     this.clearForm();
    //calendarApi.unselect(); // clear date selection

    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay,
    //   });
    // }

}

get f() {
  return this.ScheduleForm.controls;
}

  InsertSchedule(){
    this.submitted = true;
    const calendarApi = this.calendarApiObj.view.calendar;
    this.GetEventDetails= this.ScheduleForm.getRawValue();
    if (this.ScheduleForm && this.ScheduleForm.valid)
    {
     calendarApi.unselect(); // clear date selection
      let EventType= this.result.filter(value => value.id == this.GetEventDetails.EventTypeId);
      let EventTypename=EventType[0].nameData;
      if(this.GetEventDetails){
        calendarApi.addEvent({
          id: createEventId(),
          title:"("+EventTypename+")"+this.GetEventDetails.Title,
          start: this.calendarApiObj.startStr,
          end: this.calendarApiObj.endStr,
          allDay: this.calendarApiObj.allDay,
        });
      }
      this.ConsultantDeskService.InsertUpdateSchedule(Number(this.GetEventDetails.EventTypeId),this.GetEventDetails.Title, this.calendarApiObj.startStr, this.calendarApiObj.endStr, this.Id).subscribe((res) => {
        this.scheduleTime = res.response;
        this.result = res;
        this.submitted = false;
        if (this.result.message == "Success") {
           this.toastr.success("Inserted successfully");
          this.LoadScheduleEvents();
        }
      });
      this.modalRef.hide();

    }
    else{

    }




  }
  LoadScheduleEvents() {

    this.ConsultantDeskService.GetScheduleTitle(this.Id, this.globals.HospitalId).subscribe((res) => {
      this.LoadSchedule = res.response;

      this.LoadSchedule.forEach((element) => {
        this.scheduletitle.push({ 'title': "("+element.eventTypeName+") "+element.title , 'start': element.startTime, 'end': element.endTime, 'id': element.scheduleId });
        this.ScheduleId = element.scheduleId;
      });

    });



  }

  handleEventClick(clickInfo: EventClickArg) {

// const calendarApi = clickInfo.view.calendar;


    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      this.ConsultantDeskService.DeleteSchedule(Number(this.ScheduleId)).subscribe((res) => {
        this.scheduleTimeDelete = res.response;
        this.result = res
        if (this.result.message == "Deleted") {
          this.toastr.success("Deleted successfully");
          // window.location.reload();
          // this.LoadScheduleEvents();

         // this.router.navigate(['/consultantdesk/mySchedule']);
        // this.LoadScheduleEvents();
           clickInfo.event.remove();

        } else {

          this.toastr.error("Deleted failed");
        }
      });

    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;

  }


  GetEventType(){
    let Input={

        "MasterFieldType":"EventType",
        "Id":0

    }
    this.ConsultantDeskService.GetEventType(Input).subscribe((res) => {
      this.result = res.response;
    })

  }
  // to clear form
  clearForm() {
    this.ScheduleForm.reset({
      EventTypeId: '',
      Title: '',
    });
  }
}
