import { Component, OnInit,Renderer2 } from '@angular/core';

import {MatListModule} from '@angular/material/list';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

 size:any;
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    
        
  }



}
