import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-types',
  templateUrl: './location-types.component.html',
  styleUrls: ['./location-types.component.scss']
})
export class LocationTypesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
