import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Output() confirmEmitter = new EventEmitter();

  @Input() heading: string = '';
  @Input() content: string = '';
  @Input() isReasonNeeded: boolean = false;
  @Input() yesButton: string = 'Confirm';
  @Input() noButton: string = 'Cancel'

  public confirmForm: FormGroup;

  constructor(private modalRef: BsModalRef,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.confirmForm = this.formBuilder.group({
      reason: new FormControl('')
    })

    if(this.isReasonNeeded){
      this.confirmForm.get('reason').setValidators(Validators.required);
      this.confirmForm.get('reason').updateValueAndValidity();
    }
  }

  onConfirm(){
    if(this.confirmForm.invalid){
      const controls = this.confirmForm.controls;
      Object.keys(controls).forEach(control=> {
        controls[control].markAllAsTouched();
      });
      return
    }

    this.confirmEmitter.emit({
      status: true,
      reason: this.isReasonNeeded ? this.confirmForm.get('reason').value : undefined
    });
    this.modalRef.hide();
  }

  close(){
    this.confirmEmitter.emit({
      status: false,
    })
    this.modalRef.hide();
  }
}
