import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthsGuard } from "../../auths.guard";
import { DashboardComponent } from "../client/dashboard/dashboard.component";
import { SignupComponent } from "../client/pages/signup/signup.component";
import { EMRConsultationComponent } from "./emr-consultation/emr-consultation.component";
import { EmrDocumentsComponent } from "./emr-documents/emr-documents.component";
import { EmrFollowUpComponent } from "./emr-follow-up/emr-follow-up.component";
import { EMRHomeComponent } from "./emr-home/emr-home.component";
import { EmrPatientHistoryComponent } from "./emr-patient-history/emr-patient-history.component";
import { EmrPrintsComponent } from "./emr-prints/emr-prints.component";
import { EmrServiceOrderMedicationsComponent } from "./emr-service-order-medications/emr-service-order-medications.component";
import { EmrSickLeaveComponent } from "./emr-sick-leave/emr-sick-leave.component";
import { EmrSketchComponent } from "./emr-sketch/emr-sketch.component";
import { EmrTreatmentdetailsComponent } from "./emr-treatmentdetails/emr-treatmentdetails.component";
import { EMRComponent } from "./emr.component";
import { EmrPhysiothearapyComponent } from "./emr-physiothearapy/emr-physiothearapy.component";
import { EmrPhysiotherapyViewComponent } from "./emr-physiotherapy-view/emr-physiotherapy-view.component";

// const routes: Routes = [ { path: '', component: EMRComponent }];
const routes: Routes = [
  {
    path: "EMR_Home",
    component: EMRHomeComponent,
    data: { title: "LeHealth | EMR Home", pageName: "EMR Home" },
    canActivate: [AuthsGuard],
  },
  {
    path: "EMR_Consultation",
    component: EMRConsultationComponent,
    data: {
      title: "LeHealth | EMR Consultation",
      pageName: "EMR Consultation",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "EMR_PatientHistory",
    component: EmrPatientHistoryComponent,
    data: {
      title: "LeHealth | EMR Patient History",
      pageName: "EMR Patient History",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "EMR_ServiceAndMedications",
    component: EmrServiceOrderMedicationsComponent,
    data: {
      title: "LeHealth | EMR Service Order and Medicatons",
      pageName: "EMR Service Order And Medications",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "EMR_Documents",
    component: EmrDocumentsComponent,
    data: { title: "LeHealth | EMR Documents", pageName: "EMR Documents" },
    canActivate: [AuthsGuard],
  },
  {
    path: "EMR_prints",
    component: EmrPrintsComponent,
    data: { title: "LeHealth | EMR Print", pageName: "EMR Print" },
    canActivate: [AuthsGuard],
  },
  {
    path: "EMR_followup",
    component: EmrFollowUpComponent,
    data: { title: "LeHealth | Follow Up", pageName: "Follow Up" },
    canActivate: [AuthsGuard],
  },
  {
    path: "EMR_SickLeave",
    component: EmrSickLeaveComponent,
    data: { title: "LeHealth | Sick Leave", pageName: "Sick Leave" },
    canActivate: [AuthsGuard],
  },
  {
    path: "EMR_TreatmentDetails",
    component: EmrTreatmentdetailsComponent,
    data: {
      title: "LeHealth | Treatmentdetails",
      pageName: "Treatment Details",
    },
    canActivate: [AuthsGuard],
  },
  {
    path: "EMR_Sketch",
    component: EmrSketchComponent,
    data: { title: "LeHealth | Treatmentdetails", pageName: "EMR Sketch" },
    canActivate: [AuthsGuard],
  },
  {
    path: "emr-physiothearapy-view",
    component: EmrPhysiothearapyComponent,
    data: {title: "LeHealth | Treatmentdetails",pageName: "EMR Physiotherapy",
    },
    canActivate: [AuthsGuard],
  },

  {
    path: "emr-physiothearapy",
    component: EmrPhysiotherapyViewComponent,
    data: {
      title: "LeHealth |EMR Physiotherapy",
      pageName: "EMR Physiotherapy",
    },
    canActivate: [AuthsGuard],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EMRRoutingModule {
  static components = [SignupComponent, DashboardComponent];
}
