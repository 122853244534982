import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SharedService } from '../../../../shared/shared.service';
import { Response } from '../../../../_Models/Response';
import { BillingService } from '../../services/billing.service';

@Component({
  selector: 'app-cancel-bill-modal',
  templateUrl: './cancel-bill-modal.component.html',
  styleUrls: ['./cancel-bill-modal.component.scss']
})
export class CancelBillModalComponent implements OnInit {

  @Input() TransId: number = 0;
  @Input() isExternal: boolean = false;
  @Output() cancelEvent = new EventEmitter();

  public cancelBillLoading: boolean = false;
  public billCancelForm: FormGroup;
  private subscription: Subscription;
  private CurrentDate: any;

  private globals: globalvars = new globalvars();

  constructor(private billingService: BillingService,
              private toastrService: ToastrService,
              private sharedService: SharedService,
              private datepipe: DatePipe,
              public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.CurrentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');

    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });

    this.initCancelForm();
  }

  initCancelForm(){
    this.billCancelForm = new FormGroup({
      TransId: new FormControl(this.TransId, Validators.required),
      CancelReason: new FormControl('', Validators.required),
      UserId: new FormControl(Number(localStorage.getItem('user_id')), Validators.required),
      OrderDate: new FormControl(this.CurrentDate, Validators.required),
      LocationId: new FormControl(this.globals.Location?.locationId, Validators.required),
      SessionId: new FormControl(0, Validators.required),
      IsExternal: new FormControl(this.isExternal)
    })
  }

  cancelBill(){
    const controls = this.billCancelForm.controls;
    if(this.billCancelForm.invalid){
      Object.keys(controls).forEach(control=>{
        controls[control].markAllAsTouched();
      });
      return
    }

    this.cancelBillLoading = true;
    this.billingService.cancelBill(this.billCancelForm.value).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.response == 'Success'){
          this.modalRef.hide();
          this.toastrService.success('Bill canceled successfully..');
          this.cancelEvent.emit({status: true});
        }else{
          this.toastrService.error(response.response)
        }
        this.cancelBillLoading= false;
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong, Try again...');
        this.cancelBillLoading = false;
      }
    })
   }

}
