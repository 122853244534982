import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { Response } from '../../../_Models/Response';
import { ErrorHandleService } from './error-handle.service';
import { SponsorFormConfigList } from "../interfaces/SponsorFormConfig";
@Injectable({
  providedIn: 'root'
})
export class SponsorFormConfig {
  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle:ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  GetAllSponsorForm(Input) {
    var url = "api/Sponsor/GetSponsorForm";
    return this.http.post<Response<SponsorFormConfigList[]>>(`${this.baseUrl}${url}`,Input).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
InsertSponsorForm(Input){
  
  var url = "api/Sponsor/InsertUpdateSponsorForms";
  return this.http.post<Response<SponsorFormConfigList[]>>(`${this.baseUrl}${url}`,Input).pipe(
    retry(1),
    catchError(this.errorHandle.handleError)
  );
}
PrintSponsorForm(InputDetails) {
  var url = "api/Sponsor/GetSponsorFormPreview";
  return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails);
}

   

}

