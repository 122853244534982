import { Component, OnInit, ViewChild } from '@angular/core';
// import { MDBBootstrapModule } from 'angular-bootstrap-md';
// import { CarouselModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'
import { CarouselModule } from 'primeng/carousel';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { HospitalsService } from '../../services/hospitals.service';
import { Departments } from '../../interfaces/Departments';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Consultants } from '../../interfaces/Consultants';
import { Router } from '@angular/router';
import { ScheduleService } from '../../services/schedule.service';
import { UserHospitals } from '../../interfaces/UserHospitals';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { AppointmentsService } from '../../services/appointments.service';
import { AppointmentScheduleList } from '../../interfaces/AppointmentScheduleList';
import { prepareSyntheticListenerFunctionName } from '@angular/compiler/src/render3/util';
import { NgxUiLoaderModule, NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../shared/shared.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { ApplicationSettings } from '../../../../shared/models/applicationSettings';
import { ApplicationSettingsService } from '../../../../shared/services/application-settings.service';
import { Response } from '../../../../_Models/Response';
import _ from 'lodash';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ShowOverbookedAppoinmentsModalComponent } from '../modals/show-overbooked-appoinments-modal/show-overbooked-appoinments-modal.component';
import { ConfigurationService } from '../../services/configuration.service';
import { TelerikPrintComponent } from '../../../../shared/modals/telerik-print/telerik-print.component';
@Component({
  selector: 'app-addscheduledComponent',
  templateUrl: './appointmentschedule.component.html',
  styleUrls: ['./appointmentschedule.component.scss'],

})

export class addscheduledComponent {
  PostponeObj: any;
  userdetails: any;
  CancelAppId: number;
  selectedItems=[];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings2: IDropdownSettings = {};
  DateValue: string;
  Consultant: Number;
  selectedDept = [];
  selectedConsultant = [];
  selectedDate = [];
  consultantList = [];
  spinnerType = SPINNER.circle;
  pageNumber: number = 1;
  totalCount: number = 0;
  currentPage: number = 1;

  //slider setting variable
  responsiveOptions;

  consultants: any;
  //  current date

  valueDate: any;
  storeDeptId: any;

  // selected patient from appointment
  selectedPatient: any;
  //hospital list or branches
  hospitalList: UserHospitals[] = [];

  // department array
  departments: any;
  // consultants array
  consultants_array: Consultants[] = [];
  //define variable to store dynamic consultants data
  AppointmentList = [];
  SlectedSlotNow = [];
  loader: boolean = false;
  TotalSlectedSlot = [];
  cities: any[];
  AppointmentScheduleList: AppointmentScheduleList[] = [];
  consultDataId: any[]
  selectedconsultantName: string;
  selectedconsultantDept: string;
  selectedconsultantDeptId: number;
  SlotSelected: boolean = false;
  form: FormGroup;
  public service;
  consultants1: { id: number; drName: string; labels: { time: string; name: string; }[]; }[];
  DeptId: any;
  BranchId: any;
  spinner: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  Maxslotlength: number;
  maxlabels: any[];
  selectedconsultantId: number = 0;
  BranchByDepartments: any;
  branchId: number;
  BranchesId: any;
  //UserDetails: number;
  displayBasicAppoint: boolean=false;
  PatientName: any;
  PatientData: any;
  PatientRegNo: any;
  PatientMob: any;
  Slicetime: any;
  appointments: any[];
  appId: any;
  UserDetails: { AppId: number; BranchId: number; };
  storeConsultId: any;
  user_id: string;
  userType: any;
  departmentId: any;
  ConsultantId: any;
  UserName: any;
  departmentName: any;
  selectedDepartments: any[];
  selectedConsultants: any[];
  IsConsultantId = null;
  SelectAllDepartments: any[] = [];
  selectedDepts: any[] = [];
  minDate: Date;

  public applicationSettings: ApplicationSettings;
  functionPermissionData: any;
  prevUrl: any;

  constructor(private http: HttpClient,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private hospitalsService: HospitalsService,
    private router: Router,
    private appointmentService: AppointmentsService,
    private scheduleService: ScheduleService,
    private ngxService: NgxUiLoaderService,
    private modalService: BsModalService,
    private settingsService: ApplicationSettingsService,
    private ConfigurationService: ConfigurationService,
  ) {
    
    this.service = scheduleService
    this.valueDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    this.consultDataId = [];

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId

        //this.GetDepartments();
        // this.loadConsultant([]);

      }
    });




    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];


  }

  //cancel appointment confirmation  modal
  CancelAppointment(appid) {
    let actionId;
    if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
      actionId = 212;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 507;
    } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
      actionId = 507;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    this.CancelAppId = Number(appid);
    } else {
      this.toastr.warning("You are not allowed to cancel appointment");
    }
  }
  //userdetails appointment details in popup
  UserDetailsAppointment(rowdatas) {
    let actionId;
    if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
      actionId = 213;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 505;
    } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
      actionId = 505;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    this.displayBasicAppoint = true;
    this.PatientName = rowdatas.patientName;
    this.PatientRegNo = rowdatas.regNo;
    this.PatientMob = rowdatas.mobileNumber;
    this.Slicetime = rowdatas.sliceTime;

    let branch = Number(this.form.get("hospitalId").value);
    if (branch > 0) {
      let obj = {
        AppId: Number(rowdatas.appId),
        BranchId: branch
      }
      this.UserDetails = obj;
    }
    else {
      this.toastr.warning("Please Select Branch", "Warning!");
    }
    } else {
      this.toastr.warning("You are not allowed to view details");
    }
  }

  //slot selection  in tables
  SlotSelection(consultantId, departmentId, sliceNo, appId, sliceIndex) {
    let element: Element = document.getElementById("td_" + consultantId + "_" + departmentId + "_" + sliceNo + "_" + sliceIndex);
    if (Number(appId) == 0) {
      if (this.selectedconsultantId != consultantId) {
        this.selectedconsultantId = consultantId;
        this.selectedconsultantDeptId = departmentId;
        this.SlectedSlotNow.forEach((a, i) => {
          delete this.SlectedSlotNow[i];
          let selelement: Element = document.getElementById(a);
          console.log(selelement)
          selelement.classList.remove('sel-slot', 'sel-over-slot');
        });
        this.SlectedSlotNow=[];
      }
      if (!element.className.includes("sel-slot")) {
        if (this.SlectedSlotNow.length > 0) {
          const lastArrayIndex = this.SlectedSlotNow.length - 1;
          const lastselslotnowindex = this.SlectedSlotNow[lastArrayIndex].split("_")[4];
          if(lastselslotnowindex<sliceIndex)
          {
            for (var i = Number(lastselslotnowindex) + 1; i <= sliceIndex; i++) {
              let consult = this.consultants.find(a => a.id === consultantId);
              let sliNo = consult.labels.find(a => a.sliceIndex === i).sliceNo;
              let idString = "td_" + consultantId + "_" + departmentId + "_" + sliNo + "_" + i;
              let innerelement: Element = document.getElementById(idString);
              this.SlectedSlotNow.push(idString);
              innerelement.classList.add('sel-slot');
            }
          }
          else
          {
            for (var i = Number(lastselslotnowindex) - 1; i >= sliceIndex; i--) {
              let consult = this.consultants.find(a => a.id === consultantId);
              let sliNo = consult.labels.find(a => a.sliceIndex === i).sliceNo;
              let idString = "td_" + consultantId + "_" + departmentId + "_" + sliNo + "_" + i;
              let innerelement: Element = document.getElementById(idString);
              this.SlectedSlotNow.push(idString);
              innerelement.classList.add('sel-slot');
            }
          }


        }
        else {
          this.SlectedSlotNow.push("td_" + consultantId + "_" + departmentId + "_" + sliceNo + "_" + sliceIndex);
          element.classList.add('sel-slot');;
        }

      }
      else {
        delete this.SlectedSlotNow[this.SlectedSlotNow.indexOf("td_" + consultantId + "_" + departmentId + "_" + sliceNo+"_"+sliceIndex)];

        element.classList.remove('sel-slot');
      }
      this.SlectedSlotNow = this.SlectedSlotNow.filter(a => a != null);
    }else{
      if (this.selectedconsultantId != consultantId) {
        this.selectedconsultantId = consultantId;
        this.selectedconsultantDeptId = departmentId;
        this.SlectedSlotNow.forEach((a, i) => {
          delete this.SlectedSlotNow[i];
          let selelement: Element = document.getElementById(a);
          selelement.classList.remove('sel-slot', 'sel-over-slot')
        });
        this.SlectedSlotNow=[];
      }
      if (!element.classList.contains("sel-over-slot")) {
        if (this.SlectedSlotNow.length > 0) {
          
          let consult = this.consultants.find(a => a.id === consultantId);
          let sliNo = consult.labels.find(a => a.sliceIndex === sliceIndex).sliceNo;
          let idString = "td_" + consultantId + "_" + departmentId + "_" + sliNo + "_" + sliceIndex;
          let innerelement: Element = document.getElementById(idString);
          this.SlectedSlotNow.push(idString);
          innerelement.classList.add('sel-over-slot');
            
        }
        else {
          this.SlectedSlotNow.push("td_" + consultantId + "_" + departmentId + "_" + sliceNo + "_" + sliceIndex);
          element.classList.add('sel-over-slot');
        }
      }
      else {
        delete this.SlectedSlotNow[this.SlectedSlotNow.indexOf("td_" + consultantId + "_" + departmentId + "_" + sliceNo+"_"+sliceIndex)];
        element.classList.remove('sel-over-slot');
      }
      this.SlectedSlotNow = this.SlectedSlotNow.filter(a => a != null);
    }

    this.SlotSelected = (this.SlectedSlotNow.length > 0);
  }
  ngOnInit(): void {

    this.form = this.formBuilder.group({
      departmentId: [],
      consultantId: [],
      ConsultantId: ['0'],
      valueDate: [''],
      hospitalId: ['0'],
      deptList: [],
      consultantList: [],
      sconsultant: '',

    });

    let prevUrl = this.appointmentService.getPreviousUrl().split("/");
    this.prevUrl = prevUrl[1];
    console.log('this.prevUrl', this.prevUrl);
    this.minDate = new Date();
    this.userType = localStorage.getItem("usertype");
    this.departmentId = localStorage.getItem("departmentId");
    this.ConsultantId = localStorage.getItem('Id');
    this.user_id = localStorage.getItem('user_id');

    this.UserName = localStorage.getItem('username');
    this.departmentName = localStorage.getItem('departmentName');

    this.globals;
    // if (this.appointmentService.getPreviousUrl() == '/AddAppointment') {
    //   console.log('if1 depList', JSON.parse(sessionStorage.getItem('depList')));
    //   this.storeDeptId = (sessionStorage.getItem('depList')) ? JSON.parse(sessionStorage.getItem('depList')) : [];
    //   // this.form.get("deptList").setValue(this.storeDeptId);
    //   // console.log('if1 form deptList', this.form.get("deptList").value);
    // } else{
    //   console.log('else1 depList', JSON.parse(sessionStorage.getItem('depList')));
    //   console.log('else1 departmentId', JSON.parse(sessionStorage.getItem('departmentId')));
    //   this.storeDeptId = (sessionStorage.getItem('departmentId')) ? JSON.parse(sessionStorage.getItem('departmentId')) : [];
    // }
    // this.storeDeptId = (sessionStorage.getItem('departmentId')) ? JSON.parse(sessionStorage.getItem('departmentId')) : [];
    // this.storeConsultId = (sessionStorage.getItem('consultantId')) ? JSON.parse(sessionStorage.getItem('consultantId')) : [];
    

    // if (this.appointmentService.getPreviousUrl() == '/AddAppointment') {
    if (this.prevUrl == 'AddAppointment') {
      // console.log('if1 depList', JSON.parse(sessionStorage.getItem('depList')));
      this.storeDeptId = (sessionStorage.getItem('depList')) ? JSON.parse(sessionStorage.getItem('depList')) : [];
      this.form.get("deptList").setValue(this.storeDeptId);
      this.selectedDate = (sessionStorage.getItem('appDate')) ? JSON.parse(sessionStorage.getItem('appDate')) : this.valueDate;
      this.form.get("valueDate").setValue(this.selectedDate);
      // console.log('if1 form deptList', this.form.get("deptList").value);
    } else{
      // console.log('else1 depList', JSON.parse(sessionStorage.getItem('depList')));
      // console.log('else1 departmentId', JSON.parse(sessionStorage.getItem('departmentId')));
      this.storeDeptId = (sessionStorage.getItem('departmentId')) ? JSON.parse(sessionStorage.getItem('departmentId')) : [];
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'deptId',
      textField: 'deptName',
      // selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true,

    };

    this.dropdownSettings2 = {
      singleSelection: false,
      idField: 'consultantId',
      textField: 'consultantName',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    // this.GetDepartments();//on load api function getting departments
    // setTimeout(() => {
    // this.loadDepartment(this.BranchesId);
    // },3000);
    let object = [];

    // this.GetUserHospitals();

    this.getApplicationSettings();
    this.functionPermission();

    // if (this.appointmentService.getPreviousUrl() == '/AddAppointment') {
    if (this.prevUrl == 'AddAppointment') {
      // debugger
      // console.log('if2 depList', JSON.parse(sessionStorage.getItem('depList')));
      this.selectedDept = (sessionStorage.getItem('depList')) ? JSON.parse(sessionStorage.getItem('depList')) : [];
      this.selectedConsultant = (sessionStorage.getItem('consList')) ? JSON.parse(sessionStorage.getItem('consList')) : [];
      this.form.get("deptList").setValue(this.selectedDept);
      this.selectedDate = (sessionStorage.getItem('appDate')) ? JSON.parse(sessionStorage.getItem('appDate')) : this.valueDate;
      this.form.get("valueDate").setValue(this.selectedDate);
      // console.log('if2 form deptList', this.form.get("deptList").value);
    } else {
      this.selectedConsultant = [];
    }
    this.getfilterData()
    // this.GetScheduledData();
  }

  onDeptSelect(selectedDepts: any) { // select multiple departments
    sessionStorage.setItem('consultantId', '');
    this.storeConsultId = [];
    let deptList = this.form.get("deptList").value;
    console.log(deptList);
    this.loadConsultant(deptList);

    //this.scheduledfilters();
  }
  onDeptDeSelect(event) {
    sessionStorage.setItem('consultantId', '');
    this.storeConsultId = [];
    let deptList = this.form.get("deptList").value;
    this.loadConsultant(deptList);
    // this.GetScheduledData();
    //  this.scheduledfilters();
  }

  onDeSelectAll() {
    sessionStorage.setItem('consultantId', '');
    this.storeConsultId = [];
    let deptList = [];
    this.loadConsultant(deptList);
    // alert("5");
    // this.GetScheduledData();
    // this.scheduledfilters();
  }
  onSelectAll(selectedDepts: any) {

    this.loadConsultant(selectedDepts);
    // alert("10");
    // this.GetScheduledData();
    // this.scheduledfilters();
  }

  LoadBasedOnConsultant(event) {

    let consultantId = this.form.get("consultantList").value;
    if (consultantId.length) {
      //     this.scheduledfilters();
    }
  }
  scheduledfilters() {
    // console.log('scheduledfilters');
    let date = this.form.get("valueDate").value;
    // console.log('date', date);
    this.BranchId = this.form.get('hospitalId').value;
    // console.log('this.BranchId', this.BranchId);
    if (typeof (date) == "object") {
      this.valueDate = this.datepipe.transform(date, 'dd-MM-yyyy');

    }
    // console.log('this.valueDate', this.valueDate);

    let departmentId = this.form.get("deptList").value;
    // console.log('department', departmentId);
    let consultantId = this.form.get("consultantList").value;
    let deptArry = [];
    let consultantArry = [];
    if (departmentId) {
      departmentId.forEach(element => {
        deptArry.push(element.deptId);
      });
      this.DeptId = deptArry;
      // console.log('this.DeptId schedule', this.DeptId);
    }

    if(this.DeptId.length==0 && this.selectedDept.length>0)
    {
      deptArry.push(Number(this.BranchByDepartments[0]['deptId']));
    }
    // console.log('this.DeptId schedule2', this.DeptId);

    if (consultantId) {
      consultantId.forEach(element => {
        consultantArry.push(element.consultantId);
      });
      this.consultDataId = consultantArry;
    }
    this.GetScheduledData();
    sessionStorage.setItem('consultantId', JSON.stringify(consultantId));
    sessionStorage.setItem('departmentId', JSON.stringify(departmentId));


  }
  GoforAppointment() {
    sessionStorage.setItem('depList', JSON.stringify(this.form.get("deptList").value));
    sessionStorage.setItem('consList', JSON.stringify(this.form.controls.consultantList.value));
    sessionStorage.setItem('appDate', JSON.stringify(this.form.controls.valueDate.value));
    sessionStorage.setItem('branchIdSearch', JSON.stringify(this.form.get("hospitalId").value));
    let actionId;
    if(localStorage.getItem("usertype") == "U") {
      actionId = 208;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 500;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    var selectedLabels = [];
    var consultantlabels = this.consultants.find(a => a.id == this.selectedconsultantId);
    consultantlabels.labels.forEach(a => {
      this.SlectedSlotNow.forEach(b => {
        var slotarray = b.split("_");
        if (Number(a.sliceNo) == Number(slotarray[3])) {
          selectedLabels.push(a);
        }
      })
    });
    this.AddPatients(this.selectedconsultantId, selectedLabels);
    } else {
      this.toastr.warning("You are not allowed to add appointment");
    }
  }
  Test() {
    this.SlotSelected = false;
    if (this.consultants) {
      if (this.consultants[0].labels) {
        var filter = this.consultants[0].labels.filter(a => a.isActive == true);
        if (filter.length > 0) {
          this.SlotSelected = true;
        }
      }

    }


  }

  // select multiple consultant
  onConsultantSelect(consultantId, Name, Department, i, depId) {

    this.consultants_array.forEach(a => a.isActive = false);
    this.consultDataId = [];
    this.consultDataId.push(consultantId);
    this.selectedconsultantDept = Department;
    this.selectedconsultantName = Name;
    this.consultants_array[i].isActive = true;
    this.selectedconsultantDeptId = depId;
    // alert("7");
    // this.GetScheduledData();
    // this.scheduledfilters();
  }
  onConsultantDeSelect() {

    //this.GetScheduledData();
  }
  GetConsultantIds(consultantList) {
    let consultantIds = [];
    consultantList.forEach((element) => {
      consultantIds.push(element.consultantId);

    });
    return consultantIds;
  }
  //getting consultants
  loadConsultant(deptList: any) {
    this.consultantList = [];
    let Consultant = "";
    this.consultants_array = [];

    for (var i = 0; i < deptList.length; i++) {
      this.consultantList.push(Number(deptList[i].deptId));
    }

    let departmentList = this.consultantList.length > 0 ? this.consultantList : [0]
    this.hospitalsService.GetConsultantList(departmentList, Consultant).subscribe((res) => {

      if (res.status == 200) {
        this.consultants_array = res.response;
        if (this.storeConsultId) {
          // this.form.patchValue({ consultantList: this.storeConsultId });
          //this.loadConsultant(this.storeDeptId);
        }
        if (this.userType == "G") {
          this.IsConsultantId = true;
          if (this.ConsultantId) {
            this.selectedConsultants = [];
            let Consultant = {
              'consultantId': Number(this.ConsultantId),
              'consultantName': this.UserName
            }
            this.selectedConsultants.push(Consultant);
            this.form.patchValue({ consultantList: this.selectedConsultants });

          }
        }

        this.consultants_array.forEach(a => a.isActive = false);

      }


      else {
        this.toastr.error("No consultants list found");
      }

    })

  }
  //send schedule details to add appointment component
  sendSchedule(consultantId, labels: any) {
    var sliceTime = "";
    var sliceNo = ""
    var sliceData = [];
    labels.forEach(a => {
      sliceTime = sliceTime == "" ? sliceTime.concat(a.sliceTime) : sliceTime.concat("," + a.sliceTime);
      sliceData.push({ sliceNo: Number(a.sliceNo), sliceTime: a.sliceTime });
    });


    let scheduleList = {
      "departmentId": this.selectedconsultantDeptId,
      "consultantId": consultantId,
      "date": this.form.get("valueDate").value,
      "consultantName": this.selectedconsultantName,
      "time": sliceTime,
      "sliceNo": sliceNo,
      "deptName": this.selectedconsultantDept,
      "SliceData": sliceData,
      "BranchId": this.BranchId,
    }
    this.service.setSchedule(scheduleList);
  }
  GetDepartments() {
    let showall = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showall).subscribe((res) => {
      this.departments = res.response;
      if (this.storeDeptId) {
        this.form.patchValue({ deptList: this.storeDeptId });
        this.loadConsultant(this.storeDeptId);
      }
      //For Assigning Consultant Wise department and consultant//


      //End//
      
      let storeDeptId = (sessionStorage.getItem('departmentId')) ? JSON.parse(sessionStorage.getItem('departmentId')) : [];
      this.form.patchValue({ deptList: storeDeptId });
    });

  }

  //  function used to keep the page title at header
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);

  }

  // To  display action list when clicking on consultant table row.
  ShowActions(index_p, index) {
    // index_p is the unique id of consultants and index is the key of child labels

    // first adding class d-none to all child elements of class 'actionlist' to hide the actions
    var elements = document.querySelectorAll('.actionlist');
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add('d-none');
    }

    // then removing the display none class and adding display block to the particular row which is clicked
    document.getElementById('action_' + index_p + '_' + index).classList.remove("d-none")
    document.getElementById('action_' + index_p + '_' + index).style.display = 'block'
  }


  decrement() {


    var temp = new Date(this.valueDate);
    temp.setDate(temp.getDate() - 1);
    this.valueDate = temp;
    this.valueDate = this.datepipe.transform(this.valueDate, 'dd-MM-yyyy');


  }
  increment() {
    let date = this.form.get("valueDate").value;
    if (typeof (date) == "object") {
      this.valueDate = this.datepipe.transform(date, 'dd-MM-yyyy');

    }
    var temp = this.valueDate;
    temp.setDate(temp.getDate() + 1);
    this.valueDate = temp;
    this.valueDate = this.datepipe.transform(this.valueDate, 'dd-MM-yyyy');


  }

  AddPatients(consultantId, labels) {

    this.sendSchedule(consultantId, labels);// store schedule data
    this.router.navigate(['/AddAppointment'], consultantId);



  }

  Showtoast_success() {

    this.toastr.success('Hello world!', 'Toastr fun!');
  }

  //Global settings for Branch ID
  GetUserHospitals() {
    this.hospitalsService.GetUserSpecificHospitals(this.user_id).subscribe((res) => {
      this.hospitalList = res.response;
      // let BranchId = this.prevUrl == 'AddAppointment' ? "" :this.globals.HospitalId
      let BranchId = this.globals.HospitalId
      this.form.patchValue({ hospitalId: BranchId });
      this.loadDepartment(BranchId)
      
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  //Getschedule method called from AppointmentService
  GetScheduledData() {
    // debugger
    // this.ngxService.start(); // start foreground loading with 'default' id

    // // Stop the foreground loading after 5s
    // setTimeout(() => {
    //   this.ngxService.stop(); // stop foreground loading with 'default' id
    // }, 3200);

    // // OR
    // this.ngxService.startBackground('do-background-things');
    // // Do something here...
    // this.ngxService.stopBackground('do-background-things');

    let date = this.form.get("valueDate").value;
    if (typeof (date) == "object") {
      this.valueDate = this.datepipe.transform(date, 'dd-MM-yyyy');

    }
    this.BranchId = this.form.get('hospitalId').value;

    if (typeof this.consultDataId == "undefined") this.consultDataId = [];
    if (typeof this.DeptId == "undefined") this.DeptId = [];
    if(this.ConsultantId!=0){
      this.consultDataId=[Number(this.ConsultantId)];
    }
    if (this.prevUrl == 'AddAppointment') {
      this.selectedDate = (sessionStorage.getItem('appDate')) ? JSON.parse(sessionStorage.getItem('appDate')) : this.valueDate;
      this.form.get("valueDate").setValue(this.selectedDate);
    } 
    if (this.BranchId > 0) {
      if(this.consultDataId.length === 0){
        this.toastr.warning("select filter properly")
        this.consultants=[]
        return
      }
      this.loader = true;
      this.appointmentService.GetAppointmentSchedule(this.consultDataId, this.valueDate, this.DeptId, this.BranchId, this.pageNumber).subscribe((res) => {
        // this.consultants = res.response['doctorData'];
        // let response  = res.response;
        // _.forEach(response, (consultant: any)=>{
        //   consultant.labels = _.map(consultant.labels, (label: any)=>{
        //     return {
        //       ...label,
        //       patientDataList: _.filter(consultant.patientDataList, {sliceNo: label.sliceNo.toString()})
        //     }
        //   })
        // });
        this.consultants = res.response;
        console.log('consultants', this.consultants);
        // this.totalCount = res.response['totalCount'];
        this.Maxslotlength = 0;
        if(this.consultants==null || !this.consultants || this.consultants.length==0){
          this.loader = false;
          this.toastr.warning("No time slot found. Please check consultant working days", "Warning!");
        }
        if(this.consultants){
          this.consultants.forEach(element => {
            if (this.Maxslotlength < element.labels.length) {
              this.Maxslotlength = element.labels.length;
              this.maxlabels = element.labels;
            }
            this.loader = false;
          });

        }

      })

  }

  }
  DateChanged(event) {
    let date = new Date();
    let cdate = this.datepipe.transform(date, 'dd-MM-yyyy');
    if (event != cdate) {
      this.GetScheduledData();
      this.scheduledfilters()
    }

  }
  loadDepartment(hospitalId: any) {
    
    let prevUrl = this.appointmentService.getPreviousUrl().split("/");
    // this.form.get("deptList").patchValue([]);
    this.BranchId = Number(hospitalId)
    this.hospitalsService.GetDepartments(this.BranchId, 0).subscribe((res) => {
      
      this.BranchByDepartments = res.response;
      // if (this.appointmentService.getPreviousUrl() == '/AddAppointment') {
      if (this.prevUrl == 'AddAppointment') {
        this.selectedDept = (sessionStorage.getItem('depList')) ? JSON.parse(sessionStorage.getItem('depList')) : [];
        this.form.controls.deptList.setValue(this.selectedDept);
        // console.log('if load', this.selectedDept);
      } else {
        
  //  this.selectedDept = [{
  //       'deptId': Number(this.BranchByDepartments[0]['deptId']),
  //       'deptName':this.BranchByDepartments[0]['deptName']
  //     },
  //     ];
  //     this.form.controls.deptList.setValue(this.selectedDept);
      
      // console.log('else load', this.selectedDept);
      // console.log('if form load', this.form.controls.deptList.value);
    }


      if (this.userType == "G") {
        this.IsConsultantId = true;
        if (this.departmentId) {
          this.selectedDepartments = [];
          let Department = {
            'deptId': Number(this.departmentId),
            'deptName': this.departmentName
          }
          this.selectedDepartments.push(Department);
          this.form.patchValue({ deptList: this.selectedDepartments });

          this.loadConsultant(this.selectedDepartments);


        }
        else{
          this.loadConsultant(this.selectedDept);
        }
      }
      else{

          this.loadConsultant(this.selectedDept);

      }
      if (hospitalId > 0) {

        this.scheduledfilters();
      }
    });


  }
  EditAppointment(appId) {
    sessionStorage.setItem('depList', JSON.stringify(this.form.get("deptList").value));
    sessionStorage.setItem('consList', JSON.stringify(this.form.controls.consultantList.value));
    sessionStorage.setItem('appDate', JSON.stringify(this.form.controls.valueDate.value));
    sessionStorage.setItem('branchIdSearch', JSON.stringify(this.form.get("hospitalId").value));
    let actionId;
    if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
      actionId = 209;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 502;
    } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
      actionId = 502;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    this.router.navigate(['/AddAppointment', appId]);
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  PostponeAppointment(appId) {
    let actionId;
    if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
      actionId = 210;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 503;
    } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
      actionId = 503;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    let branch = Number(this.form.get("hospitalId").value);
    if (branch > 0) {
      let obj = {
        AppId: Number(appId),
        BranchId: branch
      }
      this.PostponeObj = obj;
    }
    else {
      this.toastr.warning("Please Select Branch", "Warning!");
    }
    // this.scheduledfilters();
    // this.GetScheduledData();
    } else {
      this.toastr.warning("You are not allowed to postpone appointment");
    }
  }


  PrintAppointmentCard(rowdatas) {
    let actionId;
    if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
      actionId = 211;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 504;
    } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
      actionId = 504;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    this.appId = rowdatas.appId

    let initialState = {
      modalHeading: 'Print Appointment',
      reportName: 'PrintAppointment.trdp',
      reportParams: {
        AppId: this.appId,
        BranchId : this.globals.HospitalId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))

    // this.PatientName = rowdatas.patientName;
    // this.PatientRegNo = rowdatas.regNo;
    // this.PatientMob = rowdatas.mobileNumber;
    // this.Slicetime = rowdatas.sliceTime
    // let printContents = '';


    // printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:50%">';
    // printContents = printContents + ' <table class="print-table1"><tr><td><h3>Appointment Details</h3></td></tr>';

    // printContents = printContents + ' <tr><td><strong>App Reg.No:</strong></td><td>' + this.PatientRegNo + '</td></tr>';

    // printContents = printContents + ' <tr><td><strong>App Time:</strong></td><td>' + this.Slicetime + '</td></tr>';

    // printContents = printContents + ' <tr><td><strong>Mobile Number:</strong></td><td>' + this.PatientMob + '</td></tr>';
    // printContents = printContents + ' <tr><td><strong>Patient Name:</strong></td><td>' + this.PatientName + '</td></tr>';

    // var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
    // mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
    // mywindow.document.write('</head><body >');
    // mywindow.document.write(printContents);
    // mywindow.document.write('</body></html>');

    // mywindow.document.close(); 
    // mywindow.focus(); 

    // mywindow.print();
    // mywindow.close();
    // });
    return true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  // adding reload for child userdetails click
  reloaded() {
    //alert("2");
    // this.GetScheduledData();
    this.scheduledfilters();
  }


  pageChanged(event) {
    this.pageNumber = event.page;
    this.GetScheduledData();
  }

  getApplicationSettings(){
    const payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }
    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.applicationSettings = _.filter(response.response, {displayName: 'AppoitmentSearchButton'})[0];
        }else{
          this.toastr.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastr.error('Something wents wrong.! Try again');
      }
    })
  }

  onConsultantChange(){
    if(this.applicationSettings.settValue == 1){
      this.scheduledfilters();
    }
    console.log('form', this.form.controls.consultantList.value);
  }

  showAppoinments(timeSlot, consultant){
    sessionStorage.setItem('depList', JSON.stringify(this.form.get("deptList").value));
    sessionStorage.setItem('consList', JSON.stringify(this.form.controls.consultantList.value));
    sessionStorage.setItem('appDate', JSON.stringify(this.form.controls.valueDate.value));
    sessionStorage.setItem('branchIdSearch', JSON.stringify(this.form.get("hospitalId").value));
    let actionId;
    if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
      actionId = 214;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 506;
    } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
      actionId = 506;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    timeSlot = {
      ...timeSlot,
      consultant: consultant?.drName,
      consultantId: consultant?.id,
      department: consultant?.deptName,
      departmentId: consultant?.deptId
    }

    let initialState = {
      timeSlot: timeSlot,
      branchId: this.globals.HospitalId
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    debugger
    const modalRef= this.modalService.show(ShowOverbookedAppoinmentsModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    modalRef.content.modalEventEmitters.subscribe(res=>{
      if(res.status){
        if(res.eventType == 'cancel'){
          this.CancelAppointment(res.data);
        }if(res.eventType == 'postpone'){
          this.PostponeAppointment(res.data);
        }
      }else{
        
      }
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  setFilters(){
    const foundElement = this.functionPermissionData.find(x => x.id === 578)
    if (foundElement?.id == 578 && foundElement.isAllowed == true) {
    let payload={
      BranchId:this.form.get('hospitalId').value,
      Consultants:this.selectedConsultant,
      Departments:this.selectedDept.map(item=>{
        return{
          departmentId:item.deptId,
          departmentName:item.deptName
        }
      }),
    }
    this.appointmentService.setFilterData(payload).subscribe({
      next:(res)=>{
        if(res.status == 200 && res.message == 'Saved Successfully'){
          this.toastr.success('Successfully set as default');
        }else{
          this.toastr.error(res.message);
        }
      }
    })
  } else {
    this.toastr.warning("You are not allowed");
  }
  }

  getfilterData(){
    let payload={
      BranchId:this.globals.HospitalId
    }
    this.appointmentService.getFilterData(payload).subscribe({
      next:(res)=>{
        let resData=res.response[0]
        this.selectedDept = resData.departments ? resData.departments.map(item=>{
          return{
            deptId:item.departmentId,
            deptName:item.departmentName
          }
        }) :[]
        this.selectedConsultant=resData.consultants ? resData.consultants : []
        debugger
        this.form.patchValue({ 
          hospitalId: this.globals.HospitalId ,
          deptList:this.selectedDept,
          consultantList: this.selectedConsultant,
        }
        );

        this.GetUserHospitals()
      }
    })


  }
}





