import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColDef, GridApi } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import { ConfigurationService } from '../../client/services/configuration.service';
import { Response } from '../../../_Models/Response';

@Component({
  selector: 'app-report-worklist',
  templateUrl: './report-worklist.component.html',
  styleUrls: ['./report-worklist.component.scss']
})
export class ReportWorklistComponent implements OnInit, OnDestroy {
  title = 'customEditor';
  
  onReady(editor) {
    editor.ui.height=30;
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }
  isLoading=false;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  modalRef: BsModalRef;

  public gridApi: GridApi;
  columnDefs: ColDef[] = [];
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  rowData: any;
  public statusBar;
  rowGroupPanelShow = "always";
  heading="Add Template";
  templateList:any[]=[];
  public loadingTemplate;
  public noRowsTemplate = `"<span">no rows to show</span>"`;

  @ViewChild("template") modaltemp: any;

  public displayAddModal: boolean = false;
  functionPermissionData: any;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private sharedService: SharedService,
    private configService: ConfigurationService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {

    this.columnDefs = [

      {
        headerName: "Template Name",
        minWidth:180,
        field: "templateName",
        resizable: true,
        sortable: true,
        flex: 2,

      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        pinned:"right",
        sortable: false,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "templateList" },
      },

    ];

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    this.context = { componentParent: this };

    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
    this.functionPermission();

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.getTemplateList();
  }

  openModal(){
    const foundElement = this.functionPermissionData.find(x => x.id === 157)
    if (foundElement.id == 157 && foundElement.isAllowed == true) {
    this.router.navigate(['/config/ReportWorkList/add'], {});
    } else {
      this.toastrService.warning("You are not allowed to add template");
    }
  }

  getTemplateList(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }
    this.gridApi.showLoadingOverlay();
    this.configService.getReportWorklistTemplate(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.templateList = response.response;
        }else{
          this.toastrService.error(response.message);
        }
        this.gridApi.hideOverlay();
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong, Try again.');
        this.gridApi.hideOverlay();
      }
    })
  }

  ResetFunction(){

  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  deleteTemplate(template: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 159)
    if (foundElement.id == 159 && foundElement.isAllowed == true) {
    let payload = {
      Id: Number(template.id),
      TemplateName : template.templateName,
      TemplateData : template.templateData,
      IsDisplayed: template.isDisplayed,
      IsDeleting : 1,
      BranchId : Number(this.globals.HospitalId)
    }

    this.configService.saveReportWorkListTemplate(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == 'Success'){
          this.toastrService.success('Template deleted successfully.');
          this.getTemplateList();
        }else{
          this.toastrService.error(response.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong.. Try again.');
      }
    })
  } else {
    this.toastrService.warning("You are not allowed to delete template");
  }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  editTemplate(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 158)
    if (foundElement.id == 158 && foundElement.isAllowed == true) {
      this.router.navigate(['/config/ReportWorkList/edit/' + data.id]);
    } else {
      this.toastrService.warning("You are not allowed to edit template");
    }
  }

}
