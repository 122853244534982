import { Component, OnInit,ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { ReportsService } from '../services/reports.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';

@Component({
  selector: 'app-bill-report',
  templateUrl: './bill-report.component.html',
  styleUrls: ['./bill-report.component.scss']
})
export class BillReportComponent implements OnInit {
  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;

  form: FormGroup; // form variable
  subscription: Subscription;
  globals: globalvars = new globalvars();
  consultants:any[]=[];
  GetConsultantsData: any;
  isPdfLoaded:boolean=false;
  // private pdf: PDFDocumentProxy;
  isLoading=false;
  patients: any[] = [];
  private baseUrl = "";
  private homeUrl="";
  location: any[];
  PatientInformation = {
    "regNo": "",
    "patient": ""
  };
  branchLoadId:any;
  src :any= '';
  fromDate: string = "";
  todate: string = "";


  public document;
  patientId:any;
  constructor(
    public  datepipe: DatePipe,
    private sharedservice: SharedService,
    private NewBillingService:NewBillingService,
    private ReportsService:ReportsService,
    private sanitizer: DomSanitizer,
    private configurationManager: ConfigurationManager,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.branchLoadId=this.globals.HospitalId
      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;
   }

  ngOnInit(): void {
    let dte = new Date();
    dte.setDate(dte.getDate() - 7);
    this.fromDate = this.datepipe.transform(dte, 'dd-MM-yyyy');
    let currentappDate = new Date();
    this.todate = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');


    this.form=new FormGroup({
      FromDate: new FormControl(this.fromDate),
      ToDate: new FormControl(this.todate),
      ConsultantId: new FormControl(0),
      BranchId: new FormControl(this.branchLoadId),
      locationId:new FormControl(0),
      patientId: new FormControl(0),
      patientName: new FormControl(""),
      regNo: new FormControl(''),
      // hideData:new FormControl(0)
    });
    this.Getlocation();
    this.loadConsultants();
  }

  generateReport(){
    this.isLoading=true;
    let input=this.form.getRawValue();
    if (typeof (input.FromDate) == "object") {
      let dobConverted = this.datepipe.transform(input.FromDate, 'dd-MM-yyyy');
      input.FromDate = dobConverted;
    }

    if (typeof (input.ToDate) == "object") {
      let dobConverted = this.datepipe.transform(input.ToDate, 'dd-MM-yyyy');
      input.ToDate = dobConverted;
    }

      input.ConsultantId=Number(input.ConsultantId);
      if(this.patientId>0 ){
        input.patientId=Number(this.patientId);
      }
      else{
        input.patientId==0;
      }
        input.locationId=Number(input.locationId);
        input.BranchId=Number(this.branchLoadId);
    //   input.hideData = 1;
    // }
    // else {
    //   input.hideData = 0;
    // }

    this.ReportsService.getBillDetailsreport(input).subscribe((res) => {
      this.isLoading=false;
      if(res.status==200){
        let response=res.response;
        this.src=response[0].reportPath;
        this.src=this.baseUrl+this.src;
        this.src=this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
        this.isPdfLoaded=true;
        this.clearForm();      }
     });

  }
  Getlocation() {

    let input = {
      "showAll": 0,
      "HospitalId": this.globals.HospitalId,
      "locationId": 0
    }
    this.ReportsService.Getlocation(input).subscribe((res) => {
      this.location = res.response;
    });

  }
  loadConsultants() {
    let Input = {
      "BranchId": Number(this.globals.HospitalId),
      "IsExternal": 3
    }

    this.NewBillingService.getConsultantByHospital(Input).subscribe((res) => {
      this.consultants = res.response;

      this.GetConsultantsData = [];
      this.consultants.forEach((a) => {
        this.GetConsultantsData.push({
          id: a.consultantId.toString(),
          text: a.consultantName,
        });
      });

    });

  }
  showBasicDialog() {
    this.modaltemp.open();
  }
  searchevent(patient: any) {
    this.patientId = patient.data.patientId;
    this.PatientInformation = patient.data;
    this.form.patchValue({ patientName: this.PatientInformation.patient});
    this.form.patchValue({ regNo: this.PatientInformation.regNo});

  }

  SearchPatientDetail(patient: any) {
   
     this.GetPatientByRegNo();
 
   }

   GetPatientByRegNo() {
    this.ReportsService.SearchPatientByRegNo(this.form.get("regNo").value).subscribe((res) => {
      this.patients = res.response;
      this.patientId = this.patients[0].patientId;
      this.PatientInformation=this.patients[0]
      this.form.patchValue({ patientName: this.PatientInformation.patient});
       if (this.patients.length > 0) {
        if (this.patients[0].active == "c") {
          // this.toastr.warning("This Patient is blocked");
          // this.clearForm();
          this.form.patchValue({ regNo: this.patients[0].regNo });
          this.patients = [];
        }
      }
      //  else {
      //   this.toastr.warning("No patients found");
      // }
    });
  }

   onPaste(event){
    let clipboardData = event.clipboardData 
    let pastedText = clipboardData.getData('text');
    console.log(pastedText)
    this.SearchPatientDetail(pastedText)
  }
  clearForm() {
    this.form.patchValue({
      locationId: 0,
      branchId:0,
      ConsultantId: 0,
      patientName:'',
      regNo: '',
      patientId:0
    })
    this.patientId=0
  }
  
}
