import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SponsorsService } from '../../../client/services/sponsors.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SharedService } from '../../../../shared/shared.service';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CopySponsorConsultantFeeComponent } from '../../modals/copy-sponsor-consultant-fee/copy-sponsor-consultant-fee.component';

@Component({
  selector: 'app-sponsor-consultant-fee',
  templateUrl: './sponsor-consultant-fee.component.html',
  styleUrls: ['./sponsor-consultant-fee.component.scss']
})
export class SponsorConsultantFeeComponent implements OnInit {
  public SponsorConsultant: FormGroup;
  subconsultant:any;
  EventId:any;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  CptCode=[];
  itemName=[];
  sponsorFee:any;
  BaseCptCode:any;
  BaseitemName:any;
  ruleId:any;
  result:any;
  BranchesId:any;
  subCptcodeList:any;
  deptList:any;
  sponsorId:any;
  deptId:any;
  fee:any ="";
  feeList=[];
  submitted: any;
  getSponsorFeeDtl:any;
  getSubSponsorList:any;
  public dataSaveLoading: boolean = false;
  constructor(private fb:FormBuilder ,private SponsorsService: SponsorsService,
    private toastr: ToastrService,private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,private router:Router,private activatedRoute: ActivatedRoute,
    private sharedservice: SharedService,) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.ruleId = Number(params.id);
      this.sponsorId =  Number(params.sponsorId);
      if(this.ruleId){
        this.getDept()
        this.getCptCode();
      }
    });
  }
  getDept(){
    let show = 1
    this.ConfigurationService.GetDepartments(this.BranchesId,show).subscribe((res:any)=>{
      this.deptList = res.response
      this.deptId = this.deptList[0].deptId
    })
  }
  onSelectVisit(i:any){
    this.deptId = Number(i)
    this.getSponsorFee();
  }
  formCreation(){
    this.SponsorConsultant = this.fb.group({
      end: [6],
      SponsorRuleItemRateDetailList: this.sponsorFeeDtl(),
    });
  }
  newSponsorForm(i:any) {
    return this.fb.group({
      ItemId: [i.itemId ? i.itemId : ''],
      CptCode: [i.cptCode ? i.cptCode : ''],
      DayFrom: [i.dayFrom ? i.dayFrom : '', Validators.required],
      DayTo: [i.dayTo ? i.dayTo : '', Validators.required],
      Rate: [i.rate ? i.rate : '', Validators.required],
      Percentage: [i.percentage ? i.percentage : '', Validators.required],
    })
  }
  SponsorField(): FormArray {
    return this.SponsorConsultant.get('SponsorRuleItemRateDetailList') as FormArray;
  }
  addFormArrayData(i:any) {
    if(this.SponsorConsultant.get('SponsorRuleItemRateDetailList').value[i].DayTo !== ""){
      let day = Number(this.SponsorConsultant.get('SponsorRuleItemRateDetailList').value[i].DayTo) + 1
      let param = {
        itemId: '',
        CptCode: '',
        dayFrom: day,
        dayTo: '',
        rate: '',
        percentage: '',
      }
      this.SponsorField().push(this.newSponsorForm(param));
      // console.log(i ,this.SponsorConsultant,this.SponsorConsultant.get('SponsorRuleItemRateDetailList'),param)
    } else {
      if(this.SponsorConsultant.get('SponsorRuleItemRateDetailList').value[i].DayFrom === "" ||
      this.SponsorConsultant.get('SponsorRuleItemRateDetailList').value[i].DayTo === "" ||
      this.SponsorConsultant.get('SponsorRuleItemRateDetailList').value[i].Rate === "" ||
      this.SponsorConsultant.get('SponsorRuleItemRateDetailList').value[i].ItemId === ""){
        this.toastr.error("Please add Sub consultaion value");
      } else {
        this.SponsorField().push(this.newSponsorForm({}));
      }
    }
  }
  sponsorFeeDtl(){
    const SponsorDataFA = this.fb.array([]);
    if (this.getSponsorFeeDtl?.length) {
      this.getSponsorFeeDtl.forEach(
        (eachExp: any) => {
          SponsorDataFA.push(this.newSponsorForm(eachExp));
          debugger
        }
      );
    } else {
      SponsorDataFA.push(this.newSponsorForm({}));
    }
    debugger
    return SponsorDataFA;
  }
  removeSponsorData(index:any){
    this.SponsorField().removeAt(index);
    this.CptCode.splice(index , 1)
    // console.log(this.SponsorConsultant)
  }
  getSponsorFee(){
    let params= {
    "Ruleid": this.ruleId,
    "SponsorId": this.sponsorId,
    "DeptId": this.deptId,
    "MasterItemid":this.sponsorFee.itemId,
    "ItemId": 0
    }
    this.SponsorsService.getSponsorSlapFee(params).subscribe((res:any)=>{
      this.getSponsorFeeDtl = res.response
        if(this.getSponsorFeeDtl?.length) {
          this.formCreation()
          let day =  Number(this.getSponsorFeeDtl[0].dayFrom) - 1
          this.SponsorConsultant.get('end').setValue(day)
          // console.log(day,this.SponsorConsultant)
        } else {
          this.formCreation()
        }
    })
  }
  getCptCode(){
    let showall = 1;
    let consultantId = 0;
    this.EventId = 2;

    this.ConfigurationService.GetServiceItem(
      showall,
      this.globals.HospitalId,
      this.EventId, consultantId
    ).subscribe((res) => {
      this.subconsultant = res.response
      this.BaseCptCode = this.subconsultant[0].cptCode
      this.BaseitemName = this.subconsultant[0].itemName
      this.sponsorFee = this.subconsultant[0]
      if(this.sponsorFee.itemId) {
        this.getSponsorFee();
        this.FetchingDetails();
        this.getSUbConsultentVal(this.sponsorFee)
      }
    });
  }
  getSUbConsultentVal(i:any){
    let params = {
      "Id": 0,
      "ShowAll": 1,
      "BranchId": this.BranchesId,
      "GroupId": i.groupId,
      "ConsultantId": 0,
      "MasterItemId":i.itemId
    }
    this.SponsorsService.getSubSpnList(params).subscribe((res:any)=>{
      this.getSubSponsorList = res.response
    })
  }
  changeCpt(i:any,index:any){
    this.subconsultant.map((res:any)=>{
      if(res.itemId === Number(i)) {
        this.sponsorFee = res
        this.itemName.push(res.itemName)
        this.CptCode.push(res.cptCode)
      }
      this.subCptcodeList = this.subconsultant
    })
  }
  changeCptVal(i:any){
    this.subconsultant.map((res:any)=>{
      if(res.itemId === Number(i)) {
        this.sponsorFee = res
        this.BaseCptCode = res.cptCode
        this.BaseitemName = res.itemName
        // console.log(this.SponsorConsultant,this.CptCode,this.itemName)
        this.getSUbConsultentVal(res);
        this.getSponsorFee();
        let breakFlag = false;
        this.feeList.forEach((res:any)=>{
          if (breakFlag) return;
            if(res.itemId == Number(i)) {
              this.fee = res.rate
              breakFlag = true;
            } else {
              this.fee = ""
            }
        })
      }
      this.subCptcodeList = this.subconsultant
    })
  }
  InsertUpdateSponsorRule(){
    // this.SponsorConsultant.get('SponsorRuleItemRateDetailList').value.sort((x,y)=>{
    //   console.log('l',x,'k',y, y.DayTo < x.DayFrom )
    // })
    if(this.SponsorConsultant.controls.SponsorRuleItemRateDetailList.value[0].DayFrom == '0'){
      this.toastr.error("Please add Start day");
    } else if(this.SponsorConsultant.controls.SponsorRuleItemRateDetailList.value[0].DayTo == '0'){
      this.toastr.error("Please add end day");
    } else if(this.SponsorConsultant.controls.SponsorRuleItemRateDetailList.value[0].Rate == '0'){
      this.toastr.error("Please add rate");
    } else {
      this.SponsorConsultant.controls.SponsorRuleItemRateDetailList.value.map((res:any)=>{
        res.ItemId = Number(res.ItemId)
        res.DayFrom = Number(res.DayFrom),
        res.DayTo = Number(res.DayTo),
        res.Rate = Number(res.Rate),
        res.Percentage = Number(res.Percentage)
      })
      let params= {
        "Ruleid": this.ruleId,
        "SponsorId": this.sponsorId,
        'DeptId':this.deptId,
        // "ItemId": this.sponsorFee.itemId,
        "SponsorRuleItemRateDetailList": this.SponsorConsultant.controls.SponsorRuleItemRateDetailList.value
      }
      this.SponsorsService.addSponsorFee(params).subscribe((res:any)=>{
        this.result = res;
          this.submitted = false;
          if (this.result.status == 200 && this.result.message == "Success") {
              this.toastr.success("Sponsor Consultation Fee Added successfully");
              this.SponsorConsultant.reset()
            this.router.navigate(['/config/SponsorAndSubagent'])
            this.submitted = false;
          } else {
            this.toastr.error("Failed : " + this.result.message);
          }
        this.dataSaveLoading = true;
      })
    }
  }
  FetchingDetails(){
    // this.ruleId = event ? Number(event) : this.ruleId;
      let Input={
        "ruleId":this.ruleId,
        "ShowAll":0,
        "BranchId":this.globals.HospitalId,
        "SponsorId": this.sponsorId
      }
        this.SponsorsService.GetSponsorRule(Input).subscribe((res:any) => {
          let data = res.response[0].sponsorRuleItemList
          let groupItems = [];
         groupItems = data.filter(value => value.insuranceGroupId == 2);
         data.map((res:any)=>{
          if(res.insuranceGroupId == 2) {
            this.feeList.push(res)
          }
         })
        });
        this.feeList.forEach((res:any)=>{
          this.subconsultant.forEach((val:any)=>{
            if(res.itemId == val.itemId) {
              this.fee = res.rate
            }
          })
        })
  }
  goBack(){
    if(!this.dataSaveLoading){
      this.router.navigate(['/config/SponsorAndSubagent'], {replaceUrl: true})
    }
  }
  copyEmrModal() {

    let initialState = {
      EmrPatientInfo: 1,
      branchId: this.globals.HospitalId,
    };
    let modalConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-scrollable",
    };
    const copyModalRef = this.modalService.show(
      CopySponsorConsultantFeeComponent,
      Object.assign({ class: "modal-dialog-centered " }, modalConfig, {
        class: "modal-md",
        initialState,
      })
    );
    copyModalRef.content.copyEmitter.subscribe((res) => {
      if (res.status) {
        this.getSponsorFee();
      }
    });
  }
}
