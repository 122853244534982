import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ManageLocComponent } from '../../modals/manage-loc/manage-loc.component';

@Component({
  selector: 'app-service-validity-modal',
  templateUrl: './service-validity-modal.component.html',
  styleUrls: ['./service-validity-modal.component.scss']
})
export class ServiceValidityModalComponent implements OnInit {

  @Input() billingItems: any[];
  @Output() manageLOCEmitter = new EventEmitter();
  @Output() serviceValidityEmitter= new EventEmitter();

  constructor(
    private activeModalRef: BsModalRef,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.activeModalRef.hide();
  }

  ManageLOCModal(){
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    }
    let initialState = {
      billItems: this.billingItems
    }
    const modalRef = this.modalService.show(ManageLocComponent, Object.assign(
      {}, 
      config, 
      { class: 'modal-lg', initialState }
    ));
    modalRef.content.manageLocEmitter.subscribe((res)=>{
      // this.manageLOCEmitter.emit(res);
      this.serviceValidityEmitter.emit({status: 'manageLoc', data: res});
    })
    // this.openMenu();
    this.activeModalRef.hide();

  }

  cancel(){
    this.serviceValidityEmitter.emit({status: 'cancel', data: 1});
    this.activeModalRef.hide();
  }

  save(){
    this.serviceValidityEmitter.emit({status: 'proceed', data: 2});
    this.activeModalRef.hide();
  }

}
