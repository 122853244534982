import { Component, EventEmitter, Input, OnInit,Output,TemplateRef ,ViewChild, ViewEncapsulation  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../client/general-action/general-action.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from "ngx-toastr";
import { ConfigurationService } from "../../../client/services/configuration.service";
import { ResultForm } from '../../../client/interfaces/ResultForm';

@Component({
  selector: 'app-result-form',
  templateUrl: './result-modal.component.html',
  styleUrls: ['./result-modal.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ResultModalComponent implements OnInit {

  @Input() BranchId:any
  @Input() UserId:any
  @Input() resultPopupHeading:""
  @Input() rformId:any = 0;
  @Input() rformName:""
  @Input() rfooter:""
  @Input() technologist:false;
  @Input() pathologist:false;
  @Input() radiologist:false;
  @Input() microbiologist:false;


  @Output() resultEmitter=new EventEmitter()


  @ViewChild("template") modaltemp: any;

  resultForm: FormGroup;
  parameterForm: FormGroup;

  public gridApi;
  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];
  parameterColumnDefs: ColDef[] = [];
  AddparameterColumnDefs: ColDef[] = [];
  public submitted: boolean = false;
  result: any;

  resultFormFata:any;
  parameterData:any;
  rowGroupPanelShow = "always";
  public context;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    // rowClassRules: {
    // },

  }
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  parameterPopupHeading:String="Result Parameter List"
  addParameterPopupHeading:String="Add Parameter"
  isLoading=false;
  RformId:any;
  constructor(private modalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private configurationService: ConfigurationService,


  ) { }

  ngOnInit(): void {
    this.resultForm =  new FormGroup({
      RformName: new FormControl(this.rformName, [Validators.required]),
      Rfooter: new FormControl(this.rfooter),
      Technologist: new FormControl(this.technologist),
      Pathologist: new FormControl(this.pathologist),
      Radiologist: new FormControl(this.radiologist),
      Microbiologist: new FormControl(this.microbiologist),
      userId: new FormControl(this.UserId),
      branchId: new FormControl(this.BranchId),
      RformId: new FormControl(this.rformId),

    });
    this.context = { componentParent: this };

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
  }

close(){
  this.modalRef.hide();
}
save(){
  this.submitted = true;
  if (this.resultForm && this.resultForm.valid) {
    var formdata = this.resultForm.getRawValue();
    formdata.branchId=this.BranchId;
    if(formdata.Technologist!=true){
      formdata.Technologist=false;
    }
    if(formdata.Pathologist!=true){
      formdata.Pathologist=false;
    }
    if(formdata.Radiologist!=true){
      formdata.Radiologist=false;
    }
    if(formdata.Microbiologist!=true){
      formdata.Microbiologist=false;
    }

    formdata.userId=Number(this.UserId);
    let dataid = 0;
    dataid = formdata.RformId;

    this.isLoading = true;
    this.configurationService.saveResultForm(formdata).subscribe((res) => {
      this.submitted = false;
      this.result = res;
      if (this.result.status == 200 && this.result.message == 'Success') {
        if (dataid == 0) {
          this.isLoading = false;
          this.toastr.success("Result Form added Successfully");
        }
        else {
          this.isLoading = false;
          this.toastr.success("Result Form Edited Successfully");
          this.RformId = 0;
        }
        this.resultEmitter.emit({status: true});
        this.close();
        this.clearForm();
      }
      else {
        this.isLoading = false;
        this.toastr.error("Failed : " + this.result.message);
      }
    })

  }

}
clearForm() {
  this.resultForm.reset({
    RformId: 0,
    RformName: '',
    Rfooter: '',
    Technologist: false,
    Pathologist:false,
    Radiologist: false,
    Microbiologist:false,
    BranchId:this.BranchId,
  })
}
get f() {
  return this.resultForm.controls;
}

}