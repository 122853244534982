import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { da, tr } from 'date-fns/locale';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { from, noop, Observable, Observer, of, Subscription } from "rxjs";
import { groupBy, map, mergeMap, switchMap, take, tap, toArray } from "rxjs/operators";
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { Response } from '../../../_Models/Response';
import { ConfigurationService } from '../../client/services/configuration.service';
import { ServiceOrderingService } from '../../client/services/ServiceOrderingService';
import { EMRService } from '../../emr/services/emr.service';
import { BillingForm } from '../interfaces/BillingForm';
import { SettlementModalComponent } from '../modals/settlement-modal/settlement-modal.component';
import { SponsorDataComponent } from '../modals/sponsor-data/sponsor-data.component';
import { NewBillingService } from '../new-bill/new-bill.services';
import { BillingService } from '../services/billing.service';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
import { ServiceValidityModalComponent } from './service-validity-modal/service-validity-modal.component';
import { ResettlementConfirmModalComponent } from './resettlement-confirm-modal/resettlement-confirm-modal.component';
import { ResettleAdvanceModalComponent } from './resettle-advance-modal/resettle-advance-modal.component';
// import { Location } from '@angular/common';

interface servicItems {
  itemId: number;
  itemCode: string;
  itemName: string;
}

@Component({
  selector: 'app-manage-bill',
  templateUrl: './manage-bill.component.html',
  styleUrls: ['./manage-bill.component.scss']
})
export class ManageBillComponent implements OnInit, OnDestroy {

  patientData: any;

  private subscription: Subscription;
  public globals: globalvars = new globalvars();
  private currentDate: any;
  private baseUrl: string = '';
  private errorMessage?: string;

  public NewBillform: FormGroup;
  private BillingForm: BillingForm;

  public search: string = '';
  public submitted: boolean = false;

  public rateGroups: any[] = [];
  public allConsultants: any[] = [];

  public suggestions$?: Observable<servicItems[]>;

  public specialDiscount :any;

  private sponsorModalRef?: BsModalRef;

  public isPackageApplied: boolean = false;
  public packageName: string = '';

  public discountPercentage: any = 0;
  public discountAmount: any = 0;

  public approvalNo: string = '';

  public transId: any = '';

  public ViewOnly: boolean = false;
  public printBillLoading: boolean = false;

  private returnUrl: string = 'newbill';

  private billSaveLoading: boolean = false;
  PatientImage: string;

  public billingSettings: any;
  credSponDetails: any;
  public billList: any[] = [];
  transacId: any;
  printData: any;
  public tempAdavanceAmt: any[] = [];

  // @Output() serviceValidityEmitter= new EventEmitter();

  constructor(private sharedservice: SharedService,
    private datepipe: DatePipe,
    private http: HttpClient,
    private configurationManager: ConfigurationManager,
    private ConfigurationService: ConfigurationService,
    private NewBillingService: NewBillingService,
    private billingService: BillingService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private modalService: BsModalService,
    private ServiceOrderingService: ServiceOrderingService,
    private EMRService:EMRService,
    // private _location: Location
    ) {
      // this.activateRoute.queryParams.subscribe(params=>{
      //   this.returnUrl = params.returnUrl? params.returnUrl : 'newbill';
      // })
    }

  ngOnInit(): void {
    this.router.onSameUrlNavigation = 'reload';
  //   this.router.routeReuseStrategy.shouldReuseRoute = function() {
  //     return false;
  // };
  // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });
    let date = this.datepipe.transform(new Date(), "dd-MM-yyyy")
    this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.search);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<servicItems[]>>(
              `${this.baseUrl}api/Bill/GetBillServiceItem`,
              {
                ServiceName: query,
                branchId: this.globals.HospitalId,
                GroupId: 0,
                // "consultantId":this.EMRPatientInfo.ConsultantId
                // consultantId: this.NewBillform.get('consultantId').value ? Number(this.NewBillform.get('consultantId').value) : 0,
                consultantId: 0,
                CreditId: this.patientData.patientData.sponsor? this.patientData.patientData.sponsor.creditId : 0,
                Category: this.NewBillform.get('category').value,
                RateGroupId: Number(this.NewBillform.get('rGroupId').value),
                CurrentDate: this.datepipe.transform(new Date(), "dd-MM-yyyy"),
                PatientId:this.patientData.patientData.patientId
              }
            )
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  this.errorMessage =
                    (err && err.message) || "Something goes wrong";
                }
              )
            );
        }

        return of([]);
      })
    );
    if(localStorage.getItem('billDetails')){
      let sessionSt = JSON.parse(localStorage.getItem('billDetails'));
      sessionStorage.setItem('billDetails', JSON.stringify(sessionSt));
      sessionStorage.setItem('viewOnly', JSON.stringify({status: true}));
      localStorage.removeItem('billDetails');
      localStorage.removeItem('viewOnly');
    }
    if(!sessionStorage.getItem('billDetails')){
      this.router.navigate(['../newbill'], {replaceUrl: true});
    }
    let sessionData = sessionStorage.getItem('billDetails');
    this.patientData = JSON.parse(sessionData);

    this.getPatientInfo(this.patientData.patientData.patientId)

    if(sessionStorage.getItem('viewOnly')){
      this.ViewOnly = true
    }

    this.currentDate = this.datepipe.transform(new Date(), "dd-MM-yyyy hh:mm a");
    this.NewBillform = new FormGroup({
      transDetailList: new FormArray([]),
      transNo: new FormControl(""),
      transDate: new FormControl(this.currentDate),
      rGroupId: new FormControl({value: this.patientData ? this.patientData.OtherData?.rGroupId : '', disabled: true}, Validators.required),
      consultantId: new FormControl({value: this.patientData ? this.patientData.OtherData?.consultantId : '', disabled: true}, Validators.required),
      consultationId: new FormControl(this.patientData ? this.patientData.OtherData?.consultationId : '', Validators.required),
      category: new FormControl(this.patientData ? this.patientData.OtherData?.category : ''),
      instructions: new FormControl(this.patientData ? this.patientData.OtherData?.instructions : ''),
      patientType: new FormControl(this.patientData ? this.patientData.OtherData?.patientType : ''),
      remarks: new FormControl(this.patientData ? this.patientData.OtherData?.remarks : ''),
      externalType: new FormControl(this.patientData ? this.patientData.OtherData?.externalType : '3'),
      transId: new FormControl(0),
      transFlag: new FormControl(1),
      patientId: new FormControl(this.patientData ? this.patientData.patientData?.patientId : 0, Validators.required),
      totalAmount: new FormControl(0.00, [Validators.required]),
      totalDiscount: new FormControl(0.00, [Validators.required]),
      totalTax: new FormControl(0.00),
      spdiscPcnt: new FormControl(this.patientData ? this.patientData.OtherData?.spdiscPcnt : 0.00),
      spdiscAmount: new FormControl(this.patientData ? this.patientData.OtherData?.spdiscAmount : 0.00),
      netAmount: new FormControl(0.00),
      totalDeduction: new FormControl(0.00),
      totalCopay: new FormControl(0.00),
      totalSponsored: new FormControl(0.00),
      totalNonInsured: new FormControl(0.00),
      deductionSurplus: new FormControl(0.00),
      status: new FormControl('N'),
      duplicate: new FormControl(0),
      locationId: new FormControl(Number(this.globals.Location?.locationId)),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
      packId: new FormControl(this.patientData ? this.patientData.OtherData?.packId : 0),
      splDiscRemarks: new FormControl(""),
      itmDiscRemarks: new FormControl(""),
      branchId: new FormControl(Number(this.globals.HospitalId)),
      isExtLabBill: new FormControl(this.patientData ? this.patientData.OtherData?.isExtLabBill : false),
      extLabId: new FormControl(this.patientData ? this.patientData.OtherData?.extLabId : 0),
      sessionId: new FormControl(0),
      transactionLOCList: new FormControl(""),
      transactionTaxList: new FormControl(""),
      taxPcnt: new FormControl(0),
      locItemList: new FormControl(""),
      transCreditItemGroupList: new FormControl(""),
      sponsorName: new FormControl(this.patientData ? this.patientData.patientData?.sponsorName : ''),
      agentName: new FormControl(this.patientData ? this.patientData.OtherData?.agentName : ''),
      patientPay: new FormControl(0),
      discountType: new FormControl(""),
      discountValue: new FormControl(""),
      ruleId: new FormControl(this.patientData ? this.patientData?.credDetails?.ruleId : 0)
    });

    this.baseUrl = (this.configurationManager as any).baseUrl;
    let extType = this.patientData.isExternal ? 1 : this.NewBillform.get('externalType').value;
    this.loadConsultantType(extType);
    this.GetAllRateGroup();

    this.specialDiscount = {
      spclDiscAmount : this.NewBillform.get('spdiscAmount').value,
      spclDiscPercentage : this.NewBillform.get('spdiscPcnt').value,
      spclDiscRemarks: this.NewBillform.get('splDiscRemarks').value
    }

    this.getBillingSettings();

    this.activateRoute.queryParams.subscribe(params=>{
      this.returnUrl = params.returnUrl? params.returnUrl : 'newbill';
    })
    this.tempAdavanceAmt = [];
  }

  getBillingSettings(){
    const payload = {
      LocationId: this.globals?.Location?.locationId,
      BranchId: this.globals?.HospitalId,
      UserId: Number(localStorage.getItem('user_id')),
      ShiftDate: this.datepipe.transform(new Date(), 'dd-MM-yyyy')
    }

    this.billingService.getBillingSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.billingSettings = response.response[0];
          this.discountPercentage = this.discountPercentage.toFixed(this.billingSettings?.currencyDecimals);
          this.discountAmount = this.discountAmount.toFixed(this.billingSettings?.currencyDecimals);
          this.activateRoute.params.subscribe((params) => {
            this.transId = Number(params.transId);
            if(this.transId && this.transId != ''){
              this.getTransactionDetails();
            }else{
              this.getUnbilledItems();
            }
          });
        }else{
          this.toastr.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastr.error('Something wents wrong..! Try again');
      }
    })
  }

   // to get patient details
   getPatientInfo(PatientId: any) {
    if (PatientId > 0) {
      this.EMRService.GetPatientInfo(PatientId).subscribe((res) => {

        if (res.response[0].profilePicLocation) {
          this.PatientImage = res.response[0]?.profilePicLocation ? res.response[0]?.profilePicLocation : undefined;
        }
      });
    }

  }

  getTransactionDetails(){
    let payload = {
      TransId: this.transId,
      BranchId: this.globals.HospitalId
    }

    if(this.patientData.isExternal){
      this.billingService.getExternalBillDetailsByTransId(payload).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200){
            let billDetails: any = response.response[0];
            // console.log('billDetails', billDetails);
            this.NewBillform.patchValue({
              transNo: billDetails.billNo,
              transId: billDetails.transId,
              transDate:  billDetails.billDate.replaceAll('/', '-'),
              rGroupId: billDetails.rGroupId,
              consultantId: billDetails.consultantId,
              consultationId: billDetails.consultationId,
              category: billDetails.category,
              instructions: billDetails.billingInstruction,
              remarks: billDetails.remarks ? billDetails.remarks : '',
              externalType: billDetails.externalstatus,
              transFlag: billDetails.transFlag,
              patientId: billDetails.patientId,
              totalAmount: billDetails.totalAmount,
              totalDiscount: billDetails.totalDiscount,
              totalTax: billDetails.totalTax,
              spdiscPcnt: billDetails.spdiscPcnt,
              spdiscAmount: billDetails.spdiscAmount,
              netAmount: billDetails.netAmount,
              totalDeduction: billDetails.totalDeduction,
              totalCopay: billDetails.totalCoPay,
              totalSponsored: billDetails.totalSponsored,
              totalNonInsured: billDetails.totalNonInsured,
              patientPay: billDetails.totalNonInsured + billDetails.totalCoPay + billDetails.totalDeduction,
              deductionSurplus: billDetails.deductionSurplus,
              // status: billDetails.status == 'New' ? 'N' : 'M',
              status: billDetails.status == 'New' ? 'N' : (billDetails.status == 'Settled' && this.patientData.action == 'resettlement' ? 'R' : ''),
              locationId: billDetails.locationId,
              packId: billDetails.packId,
              sponsorName: billDetails.sponsor,
              agentName: billDetails.agent,
              branchId: billDetails.branchId,
              extLabId: billDetails.extLabId,
              isExtLabBill: true,
            });
            let sponsorData = {
              limit: billDetails.creditBalance,
              creditId: billDetails.creditId
            }
            billDetails.sponsor = sponsorData;
            this.patientData.patientData = billDetails;
            this.patientData.OtherData = this.NewBillform.getRawValue()

            billDetails?.transDetailList?.forEach((item)=>{
              if(item.itemName != 'REGISTRATION FEE_1'){
                item.coPayAmt = item.copayAmt;
                this.addMapFormGroup(item, 0, 3);
              }else if( item.rate > 0){
                item.coPayAmt = item.copayAmt;
                this.addMapFormGroup(item, 0, 3);
              }
            });

            if(billDetails.packId && billDetails.packId != 0){
              this.isPackageApplied = true;
            }else{
              this.isPackageApplied = false;
            }

          }else{
            this.toastr.error(response.message);
          }
          this.getDoctorInstructions();
        }
      })
    }else{
      this.billingService.getBillDetailsByTransId(payload).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200){
            let billDetails: any = response.response[0];
            // console.log('billDetails two', billDetails);
            this.NewBillform.patchValue({
              transNo: billDetails.billNo,
              transId: billDetails.transId,
              transDate:  billDetails.billDate.replaceAll('/', '-'),
              rGroupId: billDetails.rGroupId,
              consultantId: billDetails.consultantId,
              consultationId: billDetails.consultationId,
              category: billDetails.category,
              instructions: billDetails.billingInstruction,
              remarks: billDetails.remarks ? billDetails.remarks : '',
              externalType: billDetails.externalstatus,
              transFlag: billDetails.transFlag,
              patientId: billDetails.patientId,
              totalAmount: billDetails.totalAmount,
              totalDiscount: billDetails.totalDiscount,
              totalTax: billDetails.totalTax,
              spdiscPcnt: billDetails.spdiscPcnt,
              spdiscAmount: billDetails.spdiscAmount,
              netAmount: billDetails.netAmount,
              totalDeduction: billDetails.totalDeduction,
              totalCopay: billDetails.totalCoPay,
              totalSponsored: billDetails.totalSponsored,
              totalNonInsured: billDetails.totalNonInsured,
              patientPay: billDetails.totalNonInsured + billDetails.totalCoPay + billDetails.totalDeduction,
              deductionSurplus: billDetails.deductionSurplus,
              // status: billDetails.status == 'New' ? 'N' : 'M',
              status: billDetails.status == 'New' ? 'N' : (billDetails.status == 'Settled' && this.patientData.action == 'resettlement' ? 'R' : ''),
              locationId: billDetails.locationId,
              packId: billDetails.packId,
              sponsorName: billDetails.sponsorName,
              agentName: billDetails.agent,
              branchId: billDetails.branchId,
              extLabId: billDetails.extLabId,
              isExtLabBill: false,
            });
            let sponsorData = {
              limit: billDetails.creditBalance,
              creditId: billDetails.creditId
            }
            billDetails.sponsor = billDetails.creditId !=0 ? sponsorData : null;
            this.patientData.patientData = billDetails;
            this.patientData.OtherData = this.NewBillform.value

            billDetails?.transDetailList?.forEach((item)=>{
              if(item.itemName != 'REGISTRATION FEE_1'){
                item.coPayAmt = item.copayAmt;
                this.addMapFormGroup(item, 0, 3);
              }else if( item.rate > 0){
                item.coPayAmt = item.copayAmt;
                this.addMapFormGroup(item, 0, 3);
              }
            });
            this.itemsCalculations();
            if(billDetails.packId && billDetails.packId != 0){
              this.isPackageApplied = true;
            }else{
              this.isPackageApplied = false;
            }

          }else{
            this.toastr.error(response.message);
          }
          this.getDoctorInstructions();
        }
      })
    }
  }
  getUnbilledItems(){
    let payload = {
      PatientId: this.patientData.patientData.patientId ,
      ConsultantId: Number(this.patientData.OtherData.consultantId) ,
      External: this.patientData.OtherData.isExtLabBill ? 1 : 0,
      BranchId: this.globals.HospitalId,
      ShowAll: 0,
      CreditId: this.patientData.patientData.sponsor? this.patientData.patientData.sponsor.creditId : 0,
      Category: this.NewBillform.get('category').value,
      RateGroupId: Number(this.NewBillform.get('rGroupId').value),
      ConsultationId: Number(this.patientData.patientData.consultationId),
      CurrentDate: this.datepipe.transform(new Date(), "dd-MM-yyyy"),
    }

    this.billingService.getUnbilledItesm(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          // debugger
          let unbilledItems: any[] = response.response;
          unbilledItems.forEach((item)=>{
            if(item.itemName != 'REGISTRATION FEE_1'){
              this.addMapFormGroup(item, 0, 3);
            }else if(item.rate > 0){
              this.addMapFormGroup(item, 0, 3);
            }
          });
          this.itemsCalculations();
        }else{
          this.toastr.error(response.message);
        }
      }
    })

  }

  get formControls(){
    return this.NewBillform.controls
  }

  GetAllRateGroup() {
    let input = {
      RGroupId: 0,
      ShowAll: 0,
      BranchId: this.globals.HospitalId,
    };
    this.ConfigurationService.GetRateGroup(input).subscribe((res) => {
      this.rateGroups = res.response;
    })
  }

  loadConsultantType(consultantTypeId: any = 3) {
    let consultantId = consultantTypeId;
    let hospitalIds = this.globals.HospitalId;

    this.ServiceOrderingService.GetConsultantByHospital(
      Number(hospitalIds),
      consultantId
    ).subscribe((res) => {
      // this.consultantsTypes = res.response;
      this.allConsultants = res.response;
    });
  }

  LoadItems(item) {
    this.addMapFormGroup(item, 0, 3);
    this.itemsCalculations();
  }


  public addMapFormGroup(data: any, saved = 0, type) {
    const ServiceItems = this.NewBillform.get("transDetailList") as FormArray;
    if(data.cPtCode){
      data.cptCode =data.cPtCode
    }
    console.log(data)
    // console.log('ServiceItems', ServiceItems);
    const values = Object.keys(data).map((key) => data[key]);
    // console.log('values', values);
    // console.log('if data', data);
    if (data.length > 0) {
      // console.log('if data', data);
      let filteredArray;
      data.forEach((element, i) => {
        // console.log('element', element);
        if(type !== 0 ){
        filteredArray = ServiceItems.value.filter(
          (value) => ((value.teeths !== '') ? ((value.itemId == element.itemId) && (value.teeths == element.teeths)) : (value.itemId == element.itemId))
        );
        } else {
          filteredArray = ServiceItems.value.filter(
          (value) =>  value.itemId == data.itemId
          )
        }
        console.log('filteredArray', filteredArray);
        if (filteredArray.length == 0) {
          ServiceItems.insert(
            ServiceItems.length,
            this.createListGroup(element, saved)
          );
        } else {
          //this.toastr.error("Failure: " + "Service Items Already added");
        }
      });
    } else if (values.length > 0) {
      let filteredArray;
      if(type !== 0){
      filteredArray = ServiceItems.value.filter(
        // (value) =>  value.itemId == data.itemId
        (value) => ((value.teeths !== '') ? ((value.itemId == data.itemId) && (value.teeths == data.teeths)) : ((value.itemId == data.itemId) && (value.physioDetId == data.physioDetId && data.physioDetId > 0))  )
        );
        // debugger
      } else {
        filteredArray = ServiceItems.value.filter(
          (value) =>  value.itemId == data.itemId
          )      }
      if (filteredArray.length == 0) {
        ServiceItems.insert(
          ServiceItems.length,
          this.createListGroup(data, saved)
        );
      } else {
        //this.toastr.error("Failure: " + "Service Items Already added");
      }
    }

    this.search = "";
    // this.itemsCalculations();  
    setTimeout(() => {
      let lastItem = ServiceItems.value[ServiceItems.length - 1];
      if(lastItem.rate == 0 && lastItem.allowRateEdit){
        document.getElementById('rate-' + (ServiceItems.length - 1)).focus();
      }else{
        document.getElementById('qty-' + (ServiceItems.length - 1)).focus();
      }

    }, 100);


    console.log(this.NewBillform.value)
  }

  addSpecialDiscount(event){
    let specialDiscount=event[0];
    this.NewBillform.patchValue({
      spdiscAmount: 0.00,
      spdiscPcnt: 0.00,
      spclDiscRemarks: ''
    });
    this.itemsCalculations();

    if(specialDiscount){
      this.NewBillform.patchValue({
        spdiscAmount: specialDiscount.discountAmount > Number(this.NewBillform.get('patientPay').value) ? Number(this.NewBillform.get('patientPay').value): specialDiscount.discountAmount ,
        spdiscPcnt: specialDiscount.discountPer,
        spclDiscRemarks: specialDiscount.remarks
      });
      this.itemsCalculations();
    }
  }

  removeItemCalculation(removedData){
    let removeItemRate = Number(removedData.get('rate').value * removedData.get('qty').value) - Number(removedData.get('discountValue').value);
    let removeTaxAmount = Number(((Number(removedData.get('taxPcnt').value) * (removedData.get('rate').value * removedData.get('qty').value))/100).toFixed(this.billingSettings?.currencyDecimals));
    if(removedData){
      
    }
    const groupedItems: any[][] = [];
    let tempList = [];
    let billDatas: any[] = this.NewBillform.getRawValue().transDetailList;

    billDatas.forEach((data: any)=>{
      const groupName = data.insuranceGroupId;
        const groupIndex = groupedItems.findIndex(group => group.length > 0 && group[0].insuranceGroupId === groupName);
        if (groupIndex === -1) {
          groupedItems.push([data]);
        } else {
          groupedItems[groupIndex].push(data);
        }

        tempList = groupedItems.map((items) => {
          if(items.length !== 0){
            let totalRate = 0;
            let grpId = 0;
            items.find(x => {
              totalRate = totalRate + Number(x.usedRate);
              grpId = x.insuranceGroupId
            })
            return{
              ...items,
              totalRate: Number(totalRate.toFixed(this.billingSettings?.currencyDecimals)),
              grpId: grpId
            }
          }
        })
    })
    let findGrpId = false;
    if(removedData){
      tempList.find(x => {
        if(x.grpId == removedData.get('insuranceGroupId').value){
          findGrpId = true;
        }
      })
    }
    
    let returnDeduction: number = 0;
    let returnCopay: number = 0;
    let isReturnDeductionApplied: boolean = false;

    if(removedData.get('dedAmt').value && removedData.get('dedAmt').value != 0){
      if(findGrpId == false){
        // returnDeduction = data.dedAmt;
        returnDeduction = Number(removedData.get('dedAmt').value)
      }
    }else{
      returnDeduction = Number(((Number(removedData.get('dedPer').value) * (removeItemRate + removeTaxAmount))/100).toFixed(this.billingSettings?.currencyDecimals));
      // deduction = Number(((Number(data.dedPer) * (itemRate + taxAmount))/100).toFixed(this.billingSettings?.currencyDecimals));
      // returnDeduction = deduction;
    }
    returnDeduction = returnDeduction > removeItemRate ? removeItemRate : returnDeduction;
    isReturnDeductionApplied = returnDeduction > 0 ? true : false;

    if(removedData.get('coPayAmt').value && removedData.get('coPayAmt').value != 0){
      if(findGrpId == false){
        returnCopay = Number(removedData.get('coPayAmt').value);
      }
    }else{
      returnCopay = Number(((Number(removedData.get('copayPer').value) * ((removeItemRate - returnDeduction) + removeTaxAmount))/100).toFixed(this.billingSettings?.currencyDecimals));
    }
    

    returnCopay = returnCopay > removeItemRate ? (returnDeduction && returnDeduction < removeItemRate ? removeItemRate : 0.00) : (returnDeduction && (returnCopay > removeItemRate - returnDeduction) ? removeItemRate - returnDeduction : returnCopay);
    returnCopay = ((returnCopay > Number(removedData.get('copayMaxLimit').value)) && (Number(removedData.get('copayMaxLimit').value) > 0)) ? Number(removedData.get('copayMaxLimit').value) : returnCopay;

    let advanceAmt = (returnDeduction + returnCopay);

    this.tempAdavanceAmt.push(advanceAmt);
    let advSum = this.tempAdavanceAmt.reduce((acc, value) => acc + value, 0);

  }

  itemsCalculations(removedData?) {

    const groupedItems: any[][] = [];
    let creditLimit = Number(this.patientData?.patientData?.sponsor?.limit) || 0 ;
    let itemsTotal: number = 0;
    let totalItemsDiscont: number =  0;
    let totalTaxAmount: number = 0;
    let totalNonCovered: number = 0;
    let totalCopay: number = 0 ;
    let totalDeduction: number = 0;
    let sponseredAmount: number = 0;
    let tempList = [];
    let billDatas: any[] = this.NewBillform.getRawValue().transDetailList;

    billDatas.forEach((data: any)=>{
      const groupName = data.insuranceGroupId;
        const groupIndex = groupedItems.findIndex(group => group.length > 0 && group[0].insuranceGroupId === groupName);
        if (groupIndex === -1) {
          groupedItems.push([data]);
        } else {
          groupedItems[groupIndex].push(data);
        }

        tempList = groupedItems.map((items) => {
          if(items.length !== 0){
            let totalRate = 0;
            let grpId = 0;
            items.find(x => {
              totalRate = totalRate + Number(x.usedRate);
              grpId = x.insuranceGroupId
            })
            return{
              ...items,
              totalRate: Number(totalRate.toFixed(this.billingSettings?.currencyDecimals)),
              grpId: grpId
            }
          }
        })
    })



    billDatas.forEach((data: any)=>{
      itemsTotal = Number((itemsTotal + (Number(data.amount))).toFixed(this.billingSettings?.currencyDecimals));
      totalItemsDiscont = Number((totalItemsDiscont + Number(data.discountValue)).toFixed(this.billingSettings?.currencyDecimals));
      let itemRate = Number(data.amount) - Number(data.discountValue);
      let taxAmount = Number(((Number(data.taxPcnt) * itemRate)/100).toFixed(this.billingSettings?.currencyDecimals));
      totalTaxAmount = Number((totalTaxAmount + taxAmount).toFixed(this.billingSettings?.currencyDecimals));

      if(data.insCovered && this.patientData?.patientData?.sponsor ){
        let deduction: number = 0;
        // let returnDeduction: number = 0;
        // let returnCopay: number = 0;
        let isDeductionApplied: boolean = false;
        if(data.dedAmt && data.dedAmt != 0){
          // deduction = Number(data.dedAmt);
          tempList.find(x => {
            if(x.grpId == data.insuranceGroupId){

            if(Number(x.totalRate) !== 0){
              // debugger
              // deduction = Number((Number(data.dedAmt)*(Number(data.usedRate)/Number(x.totalRate))).toFixed(this.billingSettings?.currencyDecimals));
              deduction = Number((Number(data.dedAmt)*(Number(data.usedRate)/Number(x.totalRate))));
            }else{
              deduction = 0;
            }
              data.splittedDedAmt = deduction;
            }
          })

        }else{
          deduction = Number(((Number(data.dedPer) * (itemRate + taxAmount))/100).toFixed(this.billingSettings?.currencyDecimals));

        }
        deduction = deduction > itemRate ? itemRate : deduction;

        this.NewBillform.get('transDetailList')['controls'].forEach((gItem: any)=>{
          if(gItem.get('itemId').value == data.itemId){
            gItem.get('splittedDedAmt').setValue(Number(deduction).toFixed(this.billingSettings?.currencyDecimals));
          }
        })
        isDeductionApplied = deduction > 0 ? true : false;
        // totalDeduction = Number((totalDeduction + deduction).toFixed(this.billingSettings?.currencyDecimals));
        totalDeduction = Number((totalDeduction + deduction));
        let copay: number = 0;

        if(data.coPayAmt && data.coPayAmt != 0){
          // copay = Number(data.coPayAmt);
          tempList.find(x => {
            if(x.grpId == data.insuranceGroupId){

            if(Number(x.totalRate) !== 0){
              // debugger
              copay = (Number(data.coPayAmt)*(Number(data.usedRate)/Number(x.totalRate)));
            }else{
              copay = 0;
            }
            data.splittedCopayAmt = copay;
            }
          })
        }else{
          copay = Number(((Number(data.copayPer) * ((itemRate - deduction) + taxAmount))/100).toFixed(this.billingSettings?.currencyDecimals));
        }
        copay = copay > itemRate ? (isDeductionApplied && deduction < itemRate ? itemRate : 0.00) : (isDeductionApplied && (copay > itemRate - deduction) ? itemRate - deduction : copay);
        copay = ((copay > Number(data.copayMaxLimit)) && (Number(data.copayMaxLimit) > 0)) ? Number(data.copayMaxLimit) : copay;

        // totalCopay = Number((totalCopay + copay).toFixed(this.billingSettings?.currencyDecimals));
        totalCopay = Number((totalCopay + copay));

        // sponseredAmount = Number((sponseredAmount + ((itemRate + taxAmount) - (copay + deduction))).toFixed(this.billingSettings?.currencyDecimals));
        sponseredAmount = Number((sponseredAmount + ((itemRate + taxAmount) - (copay + deduction))));
        sponseredAmount  = sponseredAmount < 0 ? 0.00 : sponseredAmount;
      }else{
        totalNonCovered = Number((totalNonCovered + (itemRate + taxAmount)).toFixed(this.billingSettings?.currencyDecimals))
      }

    });

    let oldTotalCopay = totalCopay;
    // if(totalCopay > Number(this.patientData?.credDetails?.maxLimit)) {
    //   totalCopay = Number((this.patientData?.credDetails?.maxLimit).toFixed(this.billingSettings?.currencyDecimals))
    // }
    if((Number(this.patientData?.credDetails?.maxLimit) !== 0) && (totalCopay > Number(this.patientData?.credDetails?.maxLimit))) {
      // totalCopay = Number((this.patientData?.credDetails?.maxLimit).toFixed(this.billingSettings?.currencyDecimals))
      this.toastr.warning("Copay limit " + this.patientData?.credDetails?.maxLimit + " exceeded");
    }

    let netAmount = Number(((itemsTotal - totalItemsDiscont + totalTaxAmount)).toFixed(this.billingSettings?.currencyDecimals));

    // let sponseredAmount: number = Number((netAmount - (totalCopay + totalDeduction)).toFixed(2));
    if((Number(creditLimit) !== 0) && (sponseredAmount > Number(creditLimit))) {
    // sponseredAmount = sponseredAmount > creditLimit ? creditLimit : sponseredAmount;
    this.toastr.warning("credit limit " + creditLimit + " exceeded");
    }
    let patientPay = netAmount - sponseredAmount;
    let spclDiscount = 0;
    let spclPerc = Number(this.NewBillform.get('spdiscPcnt').value);
    let spclAmount = Number(this.NewBillform.get('spdiscAmount').value);
    if(spclPerc && spclPerc > 0.00){
      spclDiscount = Number(((spclPerc * patientPay) / 100).toFixed(this.billingSettings?.currencyDecimals));
    }else{
      spclDiscount = spclAmount;
    }

    spclDiscount = spclDiscount > patientPay && !this.transId ? patientPay : spclDiscount;
    netAmount = Number((netAmount - spclDiscount).toFixed(this.billingSettings?.currencyDecimals));
    patientPay = Number((netAmount - sponseredAmount).toFixed(this.billingSettings?.currencyDecimals));
    patientPay = patientPay < 0 ? 0 : patientPay;

    this.NewBillform.patchValue({
      patientPay: patientPay.toFixed(this.billingSettings?.currencyDecimals),
      totalAmount: itemsTotal.toFixed(this.billingSettings?.currencyDecimals),
      totalDiscount: totalItemsDiscont.toFixed(this.billingSettings?.currencyDecimals),
      netAmount: netAmount.toFixed(this.billingSettings?.currencyDecimals),
      spdiscAmount: spclDiscount.toFixed(this.billingSettings?.currencyDecimals),
      totalTax: totalTaxAmount.toFixed(this.billingSettings?.currencyDecimals),
      totalDeduction: totalDeduction.toFixed(this.billingSettings?.currencyDecimals),
      totalCopay: totalCopay.toFixed(this.billingSettings?.currencyDecimals),
      totalSponsored: sponseredAmount.toFixed(this.billingSettings?.currencyDecimals),
      totalNonInsured: totalNonCovered.toFixed(this.billingSettings?.currencyDecimals)
    });

    this.specialDiscount = {
      spclDiscAmount : this.NewBillform.get('spdiscAmount').value,
      spclDiscPercentage : this.NewBillform.get('spdiscPcnt').value,
      spclDiscRemarks: this.NewBillform.get('splDiscRemarks').value
    }

  }

  calculate(drug,controlName?:string) {
    let amount: any = 0;
    amount = (drug.value.qty * drug.value.usedRate).toFixed(this.billingSettings?.currencyDecimals);
    drug.get('amount').setValue(amount);
    this.calculateDiscount(drug, 'percentage');

    // drug.get(controlName).setValue(Number(drug.value[controlName] *1).toFixed(0));
  }

  get defaultValue(){
    return Number(0).toFixed(this.billingSettings?.currencyDecimals)
  }

  private createListGroup(data: any[] = [], saved: any): FormGroup {
    if (saved == 0) {
      if (!data["qty"]) data["qty"] = 1;
    }
    return new FormGroup({
      transDetId: new FormControl(data['transDetId']? data['transDetId'] : 0),
      transLOcItemId: new FormControl(data['transLOcItemId'] ? data['transLOcItemId'] : 0),
      actualRate: new FormControl(Number(data['rate'])?.toFixed(this.billingSettings?.currencyDecimals)),
      orderDetId: new FormControl(data['orderDetId']),
      postId: new FormControl(data['postId']),
      creditId: new FormControl(0),
      physioDetId: new FormControl(data['physioDetId']),
      itemId: new FormControl(data["itemId"]),
      itemName: new FormControl(data["itemName"]),
      cptCode: new FormControl(data["cptCode"]),
      qty: new FormControl({value: data["qty"] , disabled: this.ViewOnly}),
      taxId: new FormControl(data['taxId']),
      taxPcnt: new FormControl(Number(data['taxPcnt'])?.toFixed(this.billingSettings?.currencyDecimals)),
      dedPer: new FormControl(Number(data['dedPer'])?.toFixed(this.billingSettings?.currencyDecimals)),
      dedAmt: new FormControl(Number(data['dedAmt'])?.toFixed(this.billingSettings?.currencyDecimals)),
      copayPer: new FormControl(Number(data['copayPer'])?.toFixed(this.billingSettings?.currencyDecimals)),
      coPayAmt: new FormControl(Number(data['coPayAmt'])?.toFixed(this.billingSettings?.currencyDecimals)),
      copayMaxLimit: new FormControl(Number(data['copayMaxLimit'])?.toFixed(this.billingSettings?.currencyDecimals)),
      insCovered: new FormControl(data['insCovered']),
      groupId: new FormControl(data['groupId']),
      sponsoredRate: new FormControl(Number(data['sponsoredRate'])?.toFixed(this.billingSettings?.currencyDecimals)),
      rate: new FormControl(Number(data['rate'])?.toFixed(this.billingSettings?.currencyDecimals) ),
      allowRateEdit: new FormControl(data['allowRateEdit']),
      baseCost: new FormControl(Number(data['baseCost'])?.toFixed(this.billingSettings?.currencyDecimals)),
      allowDisc: new FormControl(data['allowDisc']),
      usedRate: new FormControl({value: Number(data['rate'])?.toFixed(this.billingSettings?.currencyDecimals), disabled: !data['allowRateEdit'] || this.ViewOnly}),
      discountPercentage: new FormControl({value: data["discountPercentage"]? Number(data['discountPercentage']).toFixed(this.billingSettings?.currencyDecimals) : (data['discPcnt']? Number(data['discPcnt']).toFixed(this.billingSettings?.currencyDecimals) : (0).toFixed(this.billingSettings?.currencyDecimals)), disabled: !data['allowDisc'] || this.ViewOnly}),
      discountValue: new FormControl({value: data["discountValue"]? Number(data['discountValue']).toFixed(this.billingSettings?.currencyDecimals) : (data['discAmount']? Number(data['discAmount']).toFixed(this.billingSettings?.currencyDecimals) : (0).toFixed(this.billingSettings?.currencyDecimals)), disabled: !data['allowDisc'] || this.ViewOnly}),
      amount: new FormControl(Number(((data['rate']) * data["qty"])).toFixed(this.billingSettings?.currencyDecimals)),
      approvalNo: new FormControl({value: data["aprovalNo"]? data['aprovalNo'] : '', disabled: this.ViewOnly}),
      teeths: new FormControl(data['teeths']? data['teeths'] : ''),
      serviceValidity: new FormControl(data['serviceValidity']),
      RuleId: new FormControl(0),
      insuranceGroupId: new FormControl(data['insuranceGroupId']),
      insuranceGroupName: new FormControl(data['insuranceGroupName']),
      splittedDedAmt: new FormControl(0),
      splittedCopayAmt: new FormControl(0)
    });


  }

  calculateDiscount(drug, type: string) {
    let percentage: any = 0;
    let amount: any = 0;
    switch(type){
      case 'percentage':
                          percentage = Number(drug.controls['discountPercentage'].value);
                          amount = ((percentage * Number(drug.value.amount))/100).toFixed(this.billingSettings?.currencyDecimals);
                          drug.get('discountValue').setValue(amount);
                          break;
      case  'amount':
                          amount = Number(drug.controls['discountValue'].value);
                          percentage = ((amount * 100)/Number(drug.value.amount)).toFixed(this.billingSettings?.currencyDecimals);
                          drug.get('discountPercentage').setValue(percentage);
                          break;
      default:
                          drug.get('discountPercentage').setValue(0.00);
                          drug.get('discountValue').setValue(0.00)
    }

    this.itemsCalculations();
  }

  onApplyDiscount(type: string){
    let key = '';
    let value = 0;
    if(type == 'percentage'){
      this.discountAmount = 0;
      key = 'discountPercentage';
      value = this.discountPercentage ? this.discountPercentage : (0).toFixed(this.billingSettings?.currencyDecimals);
    }else{
      this.discountPercentage = 0;
      key = 'discountValue';
      value = this.discountAmount ? this.discountAmount : (0).toFixed(this.billingSettings?.currencyDecimals);
    }
    const ServiceItems = this.NewBillform.get("transDetailList") as FormArray;
    ServiceItems.controls.forEach((form: FormGroup)=>{
      if(form.controls['allowDisc'].value){
        let data = (type != 'percentage' && value > Number(form.controls['amount'].value) ) ? Number(form.controls['amount'].value) : value
        form.controls[key].setValue(data.toFixed(this.billingSettings?.currencyDecimals));
        this.calculateDiscount(form, type);
      }
    });
  }

  onApplyApprovalNumber(){
    const ServiceItems = this.NewBillform.get("transDetailList") as FormArray;
    ServiceItems.controls.forEach((form: FormGroup)=>{
      form.controls['approvalNo'].setValue(this.approvalNo)
    });
  }

  RemoveFromServiceList(index, rowData) {

    if (this.patientData?.action == 'resettlement') {
      if (rowData.get('insCovered').value && rowData.get('approvalNo').value == "" && ((rowData.get('copayPer').value !== 0) || (rowData.get('copayAmt').value !== 0))) {
        let initialState = {
          // billingItems: this.NewBillform.getRawValue()?.transDetailList,
        }
        let config: ModalOptions = {
          backdrop: true,
          ignoreBackdropClick: true,
        }
        const modalRef = this.modalService.show(ResettlementConfirmModalComponent, Object.assign({}, config, { class: 'modal-md', initialState }))
        modalRef.content.resettlementEmitter.subscribe((res) => {
          if (res.status == true) {
            var Value = new FormArray([]);
            Value = this.NewBillform.get("transDetailList") as FormArray;
            Value.removeAt(index);
            this.itemsCalculations(rowData);
            this.removeItemCalculation(rowData);
          }
        })
      }
    }else{
      var Value = new FormArray([]);
      Value = this.NewBillform.get("transDetailList") as FormArray;
      Value.removeAt(index);
      this.itemsCalculations();
    }
  }

  async confirmModal(){
    if(!this.billSaveLoading){
      this.submitted = true;
      if (this.NewBillform && this.NewBillform.valid) {
        this.BillingForm = this.NewBillform.getRawValue();

        if(this.BillingForm.patientId == 0){
          this.toastr.error('Please select a patient to continue...');
          return
        }

        if(this.BillingForm.rGroupId == 0){
          this.toastr.error('Please select a rate group to continue...');
          return
        }

        if(this.BillingForm.consultantId == 0){
          this.toastr.error('Please select a consultant to continue...');
          return
        }

        if(this.BillingForm.transDetailList.length <= 0){
          this.toastr.error('Please add items to the bill in order to save the bill details...');
          return
        }

        // let toRange = formdata.rangeDetails.filter((item) => item.toRange == 0)

        let service = this.NewBillform.getRawValue()?.transDetailList.filter((item) => item.serviceValidity == false);
        let insurance;
        if (service.length > 0) {
          insurance = service.filter((item) => item.insCovered == true)
          if(insurance.length > 0){
          let initialState = {
            billingItems: this.NewBillform.getRawValue()?.transDetailList,
          }
          let config: ModalOptions = {
            backdrop: true,
            ignoreBackdropClick: true,
          }
          const modalRef = this.modalService.show(ServiceValidityModalComponent, Object.assign({}, config, { class: 'modal-md', initialState }))
          await modalRef.content.serviceValidityEmitter.subscribe((res) => {

            if (res.data == 'cancel') {

              return
            } else if (res.status == 'proceed') {
              // this.saveBillDetails();
              if(this.patientData.action == 'resettlement' && this.tempAdavanceAmt?.length !== 0){
                this.advanceConfirmModal();
              }else{
                this.saveBillDetails();
              }
            } else if (res.status == 'manageLoc') {
              this.onManageLOCChange(res.data);
            }
          })
        }else{
          // this.saveBillDetails();
          if(this.patientData.action == 'resettlement' && this.tempAdavanceAmt?.length !== 0){
            this.advanceConfirmModal();
          }else{
            this.saveBillDetails();
          }
        }
        } else{
          // this.saveBillDetails();
          if(this.patientData.action == 'resettlement' && this.tempAdavanceAmt?.length !== 0){
            this.advanceConfirmModal();
          }else{
            this.saveBillDetails();
          }
        }
      }
    }
  }

  advanceConfirmModal(){
    let advConfirmFlag = false;
    if(this.patientData.action == 'resettlement' && this.tempAdavanceAmt?.length !== 0){
      let reSettleSum = this.tempAdavanceAmt?.reduce((acc, value) => acc + value, 0);
      if(reSettleSum > 0){
        let initialState = {
          advanceAmt: reSettleSum
        }
        let config: ModalOptions = {
          backdrop: true,
          ignoreBackdropClick: true,
        }
        const modalRef = this.modalService.show(ResettleAdvanceModalComponent, Object.assign({}, config, { class: 'modal-md', initialState }))
        modalRef.content.advConfirmEmitter.subscribe((res) => {
          if (res.status == true) {
            this.saveBillDetails();
          }
        })
      }

    }

  }

  async saveBillDetails(){
    if(!this.billSaveLoading){
      this.submitted = true;
      let newOne;
      if (this.NewBillform && this.NewBillform.valid) {
        this.BillingForm = this.NewBillform.getRawValue();

        if(this.BillingForm.patientId == 0){
          this.toastr.error('Please select a patient to continue...');
          return
        }

        if(this.BillingForm.rGroupId == 0){
          this.toastr.error('Please select a rate group to continue...');
          return
        }

        if(this.BillingForm.consultantId == 0){
          this.toastr.error('Please select a consultant to continue...');
          return
        }

        if(this.BillingForm.transDetailList.length <= 0){
          this.toastr.error('Please add items to the bill in order to save the bill details...');
          return
        }

        let rGroupId = this.BillingForm.rGroupId; // rGroupId Id CONVERSION TO INT
        this.BillingForm.rGroupId = Number(rGroupId);
        let consultantId = this.BillingForm.consultantId; // consultant Id CONVERSION TO INT
        this.BillingForm.consultantId = Number(consultantId);
        let externalType = this.BillingForm.externalType; // externalType Id CONVERSION TO INT
        this.BillingForm.externalType = Number(externalType);
        this.BillingForm.patientId = Number(this.patientData.patientData.patientId);
        let totalDiscount = this.BillingForm.totalDiscount;
        this.BillingForm.totalDiscount= Number(totalDiscount);

          this.BillingForm.transactionLOCList= [];
          this.BillingForm.transactionTaxList=[];
          this.BillingForm.locItemList=[];
          this.BillingForm.transCreditItemGroupList=[];


          this.BillingForm.transDetailList.forEach((a) => {
            a.itemId = Number(a.itemId);
            a.qty = Number(a.qty);
            a.rate = Number(a.usedRate);
            a.amount = Number(a.qty)*Number(a.usedRate);
            a.discountPercentage = Number(a.discountPercentage);
            a.discountValue = Number(a.discountValue);
            a.taxPcnt = Number(a.taxPcnt)
            // a.totalAmount = Number(a.totalAmount);

          });
          this.BillingForm.userId = Number(localStorage.getItem("user_id"));
          // console.log('ruleId', this.BillingForm.ruleId);
          let payload = {
            TransId: this.BillingForm.transId,
            TransDate: this.BillingForm.transDate,
            PatientId: this.BillingForm.patientId,
            TransNo: this.BillingForm.transNo,
            TransFlag: this.BillingForm.transFlag,
            BillingInstruction: this.BillingForm.instructions,
            Remarks: this.BillingForm.remarks,
            TotalAmount: Number(this.BillingForm.totalAmount),
            TotalDiscount: Number(this.BillingForm.totalDiscount),
            TotalTax: Number(this.BillingForm.totalTax),
            SpdiscPcnt: this.BillingForm.spdiscPcnt,
            SpdiscAmount: Number(this.BillingForm.spdiscAmount),
            NetAmount: Number(this.BillingForm.netAmount),
            TotalDeduction: Number(this.BillingForm.totalDeduction),
            TotalCopay: Number(this.BillingForm.totalCopay),
            TotalSponsored: Number(this.BillingForm.totalSponsored),
            TotalNonInsured: Number(this.BillingForm.totalNonInsured),
            DeductionSurplus: this.BillingForm.deductionSurplus,
            Status: this.BillingForm.status,
            Duplicate: this.BillingForm.duplicate,
            LocationId: this.BillingForm.locationId,
            ConsultantId: this.BillingForm.consultantId,
            ConsultationId: Number(this.NewBillform.get('consultationId').value),
            UserId: this.BillingForm.userId,
            SessionId: this.BillingForm.sessionId,
            RGroupId: this.BillingForm.rGroupId,
            PackId: Number(this.BillingForm.packId),
            ExtLabId: Number(this.BillingForm.extLabId),
            SplDiscRemarks: this.BillingForm.splDiscRemarks,
            ItmDiscRemarks: this.BillingForm.itmDiscRemarks,
            BranchId: this.BillingForm.branchId,
            // Category: this.BillingForm.status,
            Category: this.BillingForm.category,
            TransactionLOCList: [],
            TransDetailList: [],
            TransCreditItemGroupList: [],
            RuleId: (this.BillingForm.ruleId !== null && this.BillingForm.ruleId !== undefined) ? this.BillingForm.ruleId : 0,
          }

          this.BillingForm.transDetailList.forEach(item=>{

            let taxItem = {
              TaxId: item.taxId,
              TaxPcnt: Number(item.taxPcnt),
              TaxAmount: Number(((Number(item.taxPcnt) * (Number(item.amount) - Number(item.discountValue))) / 100).toFixed(this.billingSettings?.currencyDecimals)),
            }
            // payload.TransactionTaxList.push(taxItem);
            let deduction: number = 0;
            let copay: number = 0;
            if(item.insCovered){

              if(item.dedAmt && item.dedAmt != 0){
                deduction = Number(item.dedAmt);
              }else{
                deduction = Number(((Number(item.dedPer) * (item.amount + taxItem.TaxAmount))/100).toFixed(this.billingSettings?.currencyDecimals));
              }

              if(item.coPayAmt && item.coPayAmt != 0){
                copay = Number(item.coPayAmt);
              }else{
                copay = Number(((Number(item.copayPer) * (item.amount + taxItem.TaxAmount))/100).toFixed(this.billingSettings?.currencyDecimals));
              }
              copay = ((copay > Number(item.copayMaxLimit)) && (Number(item.copayMaxLimit) > 0)) ? Number(item.copayMaxLimit) : copay;

            }

            let billItem = {
              TransDetId: 0,
              ItemId: item.itemId,
              Qty: item.qty,
              Rate: Number(item.usedRate),
              ActualRate: item.rate,
              DiscPcnt: item.discountPercentage,
              DiscAmount:item.discountValue,
              OrderDetId: item.orderDetId,
              PostId: item.postId,
              CreditId: item.insCovered ? (this.patientData.patientData?.sponsor? this.patientData.patientData.sponsor.creditId : 0) : 0,
              AprovalNo:item.approvalNo,
              PhysioDetId: item.physioDetId,
              TaxId: item.taxId,
              TaxPcnt: Number(item.taxPcnt),
              TaxAmount: Number(((Number(item.taxPcnt) * (Number(item.amount) - Number(item.discountValue))) / 100).toFixed(this.billingSettings?.currencyDecimals)),
              TransLOcItemId: item.transLOcItemId,
              Copay: copay,
              BaseCost: this.NewBillform.get('isExtLabBill').value ? Number(item.baseCost) : undefined,
              Deduction:deduction,
              CopayPer: Number(item.copayPer),
              CopayAmt: Number(item.coPayAmt),
              DedPer: Number(item.dedPer),
              DedAmt: Number(item.dedAmt),
              InsCovered: item.insCovered
            }
            payload.TransDetailList.push(billItem);

          });

          let transLOC = {
            CreditId: this.patientData.patientData?.sponsor? this.patientData.patientData.sponsor.creditId : 0,
            SerialNo: this.patientData.patientData?.sponsor? this.patientData.patientData.sponsor.creditId : 0,
            DeductionAmount: Number(this.BillingForm.totalDeduction),
            CoPayAmount: Number(this.BillingForm.totalCopay),
            SponsoredAmount: Number(this.BillingForm.totalSponsored),
            AppliedDed: Number(this.BillingForm.totalDeduction),
            AppliedCoPay: Number(this.BillingForm.totalCopay)
          }
          payload.TransactionLOCList.push(transLOC);

          let creditGroup = {
            CreditId: this.patientData.patientData?.sponsor? this.patientData.patientData.sponsor.creditId : 0,
            // Category: this.BillingForm.status
            Category: this.BillingForm.category
          }
          payload.TransCreditItemGroupList.push(creditGroup);


          if(this.NewBillform.get('isExtLabBill').value){
            let extPaylod: any = payload;
            extPaylod.pStatus = 0;

            this.billSaveLoading = true;
            this.NewBillingService.saveExternalBillingDetails(extPaylod).subscribe(async (res) => {
              this.submitted = false;
              if (res.status == 200 && res.message == "Success") {
                if(this.transId && this.transId != ''){
                  this.toastr.success(
                    "Success: " + "Billing Details Updated Successfully..."
                  );

                }else{
                  this.toastr.success(
                    "Success: " + "Billing Details Saved Successfully..."
                  );
                }
                this.transacId = res.response[0]?.transId;
                await this.getPatientBillList();
                this.openSettlmentScreen(res.response[0]?.transId, true)
              } else {
                this.toastr.error(
                  "Failure: " + "Billing Details Not Saved..."
                );

              }
              this.billSaveLoading = false;
            });
          }else{
            this.billSaveLoading = true;
            this.NewBillingService.saveBillingDetails(payload).subscribe(async (res) => {
              this.submitted = false;
              if (res.status == 200 && res.message == "Success") {
                if(this.transId && this.transId != ''){
                  this.toastr.success(
                    "Success: " + "Billing Details Updated Successfully..."
                  );

                }else{
                  this.toastr.success(
                    "Success: " + "Billing Details Saved Successfully..."
                  );
                }
                this.transacId = res.response[0]?.transId;
                await this.getPatientBillList();
                this.openSettlmentScreen(res.response[0]?.transId, false)
              } else {
                this.toastr.error(
                  "Failure: " + "Billing Details Not Saved..."
                );

              }
              this.billSaveLoading = false;
            });
          }
        if (this.patientData?.action == 'resettlement' && this.tempAdavanceAmt.length !== 0) {
          let advSum = this.tempAdavanceAmt?.reduce((acc, value) => acc + value, 0);

          if (advSum !== 0 && advSum > 0) {
            let advancePayload = {
              receiptId: 0,
              receiptNo: "",
              recDate: this.datepipe.transform(new Date(), "dd-MM-yyyy"),
              recType: 1,
              headId: 0,
              patientId: Number(this.patientData.patientData.patientId),
              creditId: Number(this.patientData.credDetails.creditId),
              amount: advSum,
              mode: 1,
              remarks: "The amount " + advSum + " is credited against the resettlement of bill no: " + this.patientData.patientData.billNo,
              cardId: 0,
              outstandingCreditBalace: 0,
              dueNo: 0,
              cardNo: "",
              chqNo: "",
              chqDate: "",
              chqBranch: "",
              userId: Number(localStorage.getItem("user_id")),
              status: "N",
              locationId: Number(this.globals.Location.locationId),
              shiftId: 0,
              transId: 0,
              sponsorId: Number(this.patientData.credDetails.sponsorId),
              branchId: this.globals.HospitalId,
              isDisplayed: 1,
              isDeleted: 0,
              sessionId: 0,
              regNo: this.patientData.patientData.regNo,
              patientName: this.patientData.patientData.patientName,
              BranchId: this.globals.HospitalId
            }
            this.billingService.saveReceiptLists(advancePayload).subscribe((res) => {
              this.submitted = false;
              let result = res;
              if (result.status == 200 && result.message == 'Success') {
                this.toastr.success('Excess amount moved to advance payment');
              }
            })
          }
        }

        }else{
          this.toastr.error('Please fill all fields...');
      }
    }
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('billDetails');
    sessionStorage.removeItem('viewOnly');
  }

  viewSponsorData(){
    if(!this.ViewOnly){
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      let initialState = {
        creditId: this.patientData.patientData?.sponsor? this.patientData.patientData.sponsor.creditId : 0,
        branchId : this.globals.HospitalId,
        patientId: this.patientData.patientData.patientId,
        billType: this.NewBillform.get('category').value
      }
      this.sponsorModalRef = this.modalService.show(SponsorDataComponent, Object.assign(
        {}, config,
        { class: 'modal-lg', initialState }
        ));
      this.sponsorModalRef.content.sponsorDataEvent.subscribe(res=>{
        if(res.isChanged){
          let creditData: any[] = res.creditData;
          let billDatas = this.NewBillform.get('transDetailList') as FormArray;
          creditData.forEach((creditItem)=>{
            billDatas.controls.forEach((billItem: any)=>{
              if(billItem.controls['groupId'].value == creditItem.groupId && creditItem.category == this.NewBillform.get('category').value){
                billItem.patchValue({
                  dedPer: creditItem.dedPer,
                  dedAmt: creditItem.dedAmt,
                  copayPer: creditItem.copayPer,
                  coPayAmt: creditItem.copayAmt,
                  copayMaxLimit: creditItem.coPayMaxLimitAmt
                })
              }
            });
          });
          this.itemsCalculations();
        }
      })
    }
  }

  onPackageSelected(data: any, saved: any, type){
    const ServiceItems = this.NewBillform.get("transDetailList") as FormArray;
    ServiceItems.clear();
    this.itemsCalculations();
    this.addMapFormGroup(data.data,saved,type);
    this.isPackageApplied = true;
    this.packageName = data.packageName;
    this.NewBillform.patchValue({
      packId: data.packageId
    })
  }

  removePackage(){
    const ServiceItems = this.NewBillform.get("transDetailList") as FormArray;
    ServiceItems.clear();
    this.itemsCalculations();
    this.isPackageApplied =false;
    this.packageName = '';
    this.NewBillform.patchValue({
      packId: 0
    })
    this.getUnbilledItems();
  }

  onManageLOCChange(event){
    // console.log('event', event);
    let locData: any[] = event.data;
    let billDatas = this.NewBillform.get('transDetailList') as FormArray;
    locData.forEach((locitItem)=>{
      billDatas.controls.forEach((billItem: any)=>{
        // if(billItem.controls['itemId'].value == locitItem.itemId){
          if((billItem.controls['teeths'].value !== '') ? ((billItem.controls['itemId'].value == locitItem.itemId) && (billItem.controls['teeths'].value == locitItem.teeths)) : (billItem.controls['itemId'].value == locitItem.itemId)){
          billItem.patchValue({
            insCovered: locitItem.insCovered,
            rate: locitItem.rate,
          })
        }
      });
    });
    let data = billDatas.getRawValue();
    billDatas.clear();
    // this.itemsCalculations();
    this.addMapFormGroup(data, 0, 3);
    // this.addMapFormGroup(data, 0, 0);
    this.itemsCalculations();
  }

  onProfileSelected(data: any, saved: any, type){
    this.addMapFormGroup(data,saved, type);
  }

  onPendingItemsSelected(data: any, saved: any, type){
    if(data.data){
      this.addMapFormGroup(data.data, saved, type);
    }
  }

  goBack(){
    this.router.navigate(['../'+this.returnUrl], {replaceUrl: true});
  }
  // goBack() {
  //   this._location.back();
  // }


  printBill(){
    // let payload = {
    //   TransId: Number(this.NewBillform.get('transId').value),
    //   ShowPackName: false,
    //   BranchId: this.globals.HospitalId
    // }

    // if(!this.printBillLoading){
    let modalHeading;
    let reportName;
      if(this.patientData.isExternal) {
        modalHeading = 'Print External Bill';
        reportName = 'PrintExternalBill.trdp';
      } else{
        modalHeading = 'Print Bill';
        reportName = 'PrintBill.trdp';
      }
      console.log(this.patientData)
      let initialState = {
        modalHeading: modalHeading,
        reportName: reportName,
        reportParams: {
          TransId: Number(this.NewBillform.get('transId').value),
          ShowPackName: false,
          BranchId : this.globals.HospitalId,
          // CreditId: this.patientData?.patientData?.sponsor?.creditId
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))

    //   this.billingService.printBill(payload).subscribe({
    //     next: (response: Response<any>)=>{
    //       if(response.status == 200){
    //         window.open(this.baseUrl+response.response[0]?.reportPath, '_blank');
    //       }else{
    //         this.toastr.error(response.response)
    //       }
    //       this.printBillLoading = false;
    //     },error: (error: any)=>{
    //       this.toastr.error('Something wents wrong, Try again...');
    //       this.printBillLoading = false;
    //     }
    //   })
    // }
  }

  openSettlmentScreen(transId: any, isExternal: boolean){
    let initialState = {
      transId: Number(transId),
      branchId: Number(this.globals.HospitalId),
      locationId: Number(this.globals.Location.locationId),
      isExternal: isExternal,
      action: this.patientData?.action ? this.patientData?.action : ""
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef= this.modalService.show(SettlementModalComponent,Object.assign( {}, config, { class: 'modal-lg', initialState }) );
    modalRef.content.settlementEventEmit.subscribe(async res=>{
      if(res.status){
        // this.goBack();
        await this.onViewPrintBill(this.printData);
      }else{
        // this.goBack();
        await this.onViewPrintBill(this.printData);
      }
    });
  }

  async onViewPrintBill(data: any){
    await this.loadSponsorTable(data);
    this.router.navigate(['../billitems/'+data.transId],{queryParams: {returnUrl: 'todaysbilling'}});
  }

  async getPatientBillList(){
    this.billList = [];
    let payload = {
      Consultationid: this.patientData ? Number(this.patientData.OtherData?.consultationId) : 0,
      BranchId: this.globals.HospitalId,
      PatientId: this.patientData ? this.patientData.patientData?.patientId : 0
    }
    let billList = await this.billingService.getConsultationBills(payload).toPromise();

        this.billList = billList.response;

    if(this.billList.length !== 0){
      this.billList.find(x => {
        if(x.transId == this.transacId){
          this.printData = x;
        }
      })
    }
  }

  async loadSponsorTable(data:any) {
    let selectedSponsor;
    let payload = {
      PatientId: data.patientId,
      Branchid: this.globals.HospitalId,
      ShowExpired: 1
    }
    let sponsorList = await this.billingService.GetSponsorDetailsByPatient(payload).toPromise();
    sponsorList.response.find(x => {
      if(x.isPrimary == true){
        this.credSponDetails = x;
      }
    });
    let patientData = {
      patientData: data,
      OtherData: null,
      credDetails: this.credSponDetails
    }
    // sessionStorage.removeItem('billDetails');
    // sessionStorage.setItem('billDetails', JSON.stringify(patientData));
    // sessionStorage.setItem('viewOnly', JSON.stringify({status: true}));
    localStorage.setItem('billDetails', JSON.stringify(patientData));
    localStorage.setItem('viewOnly', JSON.stringify({status: true}));
  }

  getDoctorInstructions(){
    let consultant = Number(this.NewBillform.get('consultantId').value);
    if(consultant && consultant !=0){
      let payload = {
        patientId: Number(this.NewBillform.get('patientId').value),
        consultantId: consultant,
        billDate: (this.NewBillform.get('transDate').value.split(' '))[0]
      }
      this.billingService.getBillingInstruction(payload).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200){
            let instructions = response.response;
            if(instructions.length > 0){
              this.NewBillform.get('instructions').setValue(instructions[0].billingInstruction);
            }
          }else{
            this.toastr.error(response.Message);
          }
        },error: (error: any)=>{

        }
      })
    }
  }
}
