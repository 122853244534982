import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, GridApi } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../client/general-action/general-action.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { LabService } from '../../services/lab.service';
import { SharedService } from "../../../../shared/shared.service";
import { Subscription } from 'rxjs';
import { globalvars } from "../../../../shared/models/GlobalVars";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';
import { BarcodeModalComponent } from '../barcode.modal copy/barcode-modal.component';
import { BarcodeTypeModalComponent } from '../barcodeType.modal copy 2/barcodeType-modal.component';
import { initialState } from 'ngx-bootstrap/timepicker/reducer/timepicker.reducer';
import moment from 'moment';
import { QrcodeScanModalComponent } from '../../../../shared/modals/qrcode-scan-modal/qrcode-scan-modal.component';
import { TreatmentsService } from '../../../treatment/services/treatments.service';
import { StaffList } from '../../../client/interfaces/StaffList';
import { SearchPatientModalComponent } from '../../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { PatientService } from '../../../client/services/patient.service';

@Component({
  selector: 'app-result-form',
  templateUrl: './investigation-modal.component.html',
  styleUrls: ['./investigation-modal.component.scss']
})
export class IvenstigationModalComponent implements OnInit {

  @Input() patientId: 0;
  @Input() patientName: any
  @Input() regNo: any
  @Input() consultationId: any;
  @Input() BranchesId: any
  @Output() getitemEmitter = new EventEmitter()
  @Output() getInvestigationEmitter = new EventEmitter()
  @Input() orderId: 0
  @Input() IsFromSourceLab: 0
  @Input() SpointId:0
  @Input() isToday:0
  @Input() SampletypeId:0

  @ViewChild("template") modaltemp: any;
  @ViewChild(SearchPatientModalComponent) modaltempSearch: SearchPatientModalComponent;

  resultForm: FormGroup;
  parameterForm: FormGroup;
  src: any = '';
  startDateFrom:any
  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];
  columnDefsItem: ColDef[] = [];
  OrderDetList: any;
  sampleData: any;
  investigationData: any;
  barcodeInvNo:any;
  rowGroupPanelShow = "always";
  public context;
  api: GridApi
  public statusBar;
  public gridOptions = {
    api: 0,
    pagination: true,
    paginationPageSize: 5,
    rowClassRules: {
    },
    refreshView: 0
  }
  private baseUrl = "";

  public submitted: boolean = false;
  public billGridApi: GridApi;
  public gridApi: GridApi;
  deleteData: any;
  serviceItemData: any;
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;
  public isLoading: boolean = false;
  public isSaved: boolean = false;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  locationId: any;
  result: any;
  ToDates: any;
  InvestgnId: any;
  EndDateObj:any
  newDate:any;
  NurseData:any;
  Staffs: StaffList[] = [];

  displayBasic: boolean;

  constructor(private modalRef: BsModalRef,
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private labService: LabService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private configurationManager: ConfigurationManager,
    private TreatmentsService: TreatmentsService,
    private patientService:PatientService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.locationId = this.globals.Location.locationId;

      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;

  }

  ngOnInit(): void {

    let timeZoneData=this.sharedservice.timeZoneData
    this.ToDates = this.datepipe.transform(new Date(), 'dd-MM-yyyy HH:mm',timeZoneData);

    this.resultForm = new FormGroup({
      InvestgnId: new FormControl(0),
      PatientId: new FormControl(this.patientId),
      LocationId: new FormControl(this.locationId),
      InvestgnNo: new FormControl(''),
      InvestgnDate: new FormControl(this.ToDates),
      StypeId: new FormControl(this.SampletypeId),
      SpointId: new FormControl(this.SpointId ? this.SpointId : 0,),
      Status: new FormControl(''),
      Remarks: new FormControl(''),
      OrderDetList: new FormControl([]),
      SessionId: new FormControl(0),
      UserId: new FormControl(localStorage.getItem("user_id")),
      regNo: new FormControl(this.regNo),
      patientName: new FormControl(this.patientName),
      BranchId: new FormControl(this.BranchesId),
      consultationId: new FormControl(this.consultationId),
      StaffId: new FormControl("0"),

    });
    this.context = { componentParent: this };

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.getResultForm();
    this.servicepoint();
    this.GetInvestigationTable();
    this.GetPerformingStaff();
    this.resultForm.patchValue({
      StaffId: Number(localStorage.getItem('staffId'))
    })

    this.columnDefs = [
      {
        headerName: "Order Data",
        minWidth: 75,
        field: "orderDate",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Service Item",
        minWidth: 75,
        field: "itemName",
        filter: true,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Consultant Name",
        minWidth: 75,
        field: "consultantName",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
    ]
    this.columnDefsItem = [
      {
        headerName: "Item Code",
        minWidth: 75,
        field: "itemCode",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Item Name",
        minWidth: 75,
        field: "itemName",
        filter: true,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Sample Type",
        minWidth: 75,
        field: "sampleType",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Order Date and Time",
        minWidth: 75,
        field: "orderDate",
        enableRowGroup: true,
        filter: true,
        resizable: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Action",
        field: "action",
        enableRowGroup: true,
        resizable: false,
        sortable: false,
        flex: 2,
        maxWidth: 80,
        pinned: "right",
        filter: false,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "itemSampleType" },

      },

    ]
    this.pendingTable();
  }

  close() {
    this.modalRef.hide();
  }
  onFilterTextBoxChangedConsultation(event) {
    this.billGridApi.setQuickFilter(
      event.target.value
    );
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }


  onFilterTextBoxChangedBill(event) {
    this.gridApi.setQuickFilter(
      event.target.value
    );
  }
  onBillGridReady(params) {
    this.billGridApi = params.api;
  }
  getResultForm() {

    let input = {
      "StypeId": 0,

    }
    this.configurationService.GetSampleType(input).subscribe((res) => {
      this.sampleData = res.response;
    });

  }
  servicepoint() {
    let input = {
      LocationId: this.locationId
    }
    this.labService.GetservicePoin(input).subscribe((res) => {
      this.serviceItemData = res.response;
    });
  }
  pendingTable() {
    let input = {
      Id: Number(this.resultForm.get('PatientId').value),
      SPointId: Number(this.resultForm.get('SpointId').value)
    }

    this.labService.pendingItem(input).subscribe((res) => {
      this.investigationData = res.response;
    });

  }
  GetInvestigationTable() {
    let input = {
      PatientId: Number(this.resultForm.get('PatientId').value),
      ResultOn: 1,
      SpointId: Number(this.resultForm.get('SpointId').value),
      branchId: this.BranchesId,
      orderId: this.orderId,
      IsFromSourceLab: this.IsFromSourceLab,
      StypeId: this.resultForm.get('StypeId').value ? Number(this.resultForm.get('StypeId').value) : 0,
      istoday:this.isToday
    }
    this.labService.GetItemForInvestigationTESTbyServicePoint(input).subscribe((res) => {
      this.OrderDetList = res.response;
    });

  }
  delete(data) {
    let arrayData = this.OrderDetList;
    let index = arrayData.findIndex(x => x.itemId === data.itemId);
    arrayData.splice(index, 1);
    this.OrderDetList = arrayData;
    this.billGridApi.setRowData(this.OrderDetList);
  }
  save() {
    this.submitted = true;
    if (this.submitted == true && this.resultForm && this.resultForm.valid && this.isSaved == false) {
      var formdata = this.resultForm.getRawValue();

      this.EndDateObj = this.resultForm.get("InvestgnDate").value

      if (typeof (this.EndDateObj) == "object") {

        this.startDateFrom = this.datepipe.transform(this.EndDateObj, 'dd-MM-yyyy HH:mm ');
        formdata.InvestgnDate = this.startDateFrom;
      }
      else{

      }
      let itemarayList = this.OrderDetList.map(items => { return items.orderDetId });
      formdata.OrderDetList = itemarayList;
      formdata.StypeId = Number(formdata.StypeId);
      formdata.SpointId = Number(formdata.SpointId)
      formdata.StaffId = Number(formdata.StaffId);

      formdata.UserId = Number(formdata.UserId)


      if (formdata.SpointId == 0) {
        this.toastr.warning("Please Select Service Point");
      }
      else if (formdata.StypeId == 0) {
        this.toastr.warning("Please Select Sample type");

      }
      else if (formdata.OrderDetList.length == 0) {
        this.toastr.warning("Please Select Valid Sample Type");

      }
      else if (formdata.StaffId == 0) {
        this.toastr.warning("Please Select Staff");

      }

      else {
        let dataid = 0;
        dataid = formdata.InvestgnId;

        this.isLoading = true;

        this.labService.SavwInvestigation(formdata).subscribe((res) => {
          this.submitted = false;

          this.result = res;
          this.barcodeInvNo=this.result.response.investigationNumber
          
          if (this.result.status == 200 && this.result.message == 'Success') {
            if (dataid == 0) {
              this.isLoading = false;
              // this.isSaved = true;
              this.toastr.success("Investigation Added successfully");

            }
            else {
              this.isLoading = false;
              this.toastr.success("Investigation Edited successfully");
              this.InvestgnId = 0;
            }
            // this.close();
            this.getitemEmitter.emit({ status: true });
            this.getInvestigationEmitter.emit({ status: true });
            this.openBarcode()
          }
          else {
            this.isLoading = false;
            this.toastr.error("Failed : " + this.result.message);
          }
        })

        //   this.submitted = false;
        //   this.result = res;

        //   if (this.result.status == 200 && this.result.message == "Success") {
        //     this.isLoading = false;

        //     this.toastr.success(dataid == 0 ? "Investigation Added successfully" : "Investigation Edited successfully")
        //     this.getitemEmitter.emit({ status: true });
        //     this.getInvestigationEmitter.emit({ status: true });

        //     this.close();
        //   }
        //   else {
        //     this.isLoading = false;
        //     this.toastr.error("Failed : " + this.result.message);
        //   }

        // })
      }
    }
  }
  get f() {
    return this.resultForm.controls;
  }
  openBarcode() {
    let initialState = {
      patientName: this.patientName,
      regno: this.regNo,
      startDateFrom:this.EndDateObj,
      barcodeInvNo:this.barcodeInvNo
    }
    const modalRef = this.modalService.show(BarcodeTypeModalComponent, Object.assign({ initialState }))

  }

  async GetPerformingStaff() {
    let Input = {
      "StaffId": 0,
      "ShowAll": 0,
      "BranchId": this.globals.HospitalId,
    }
    let staffData = await this.TreatmentsService.GetPerformingStaff(Input).toPromise();
    // this.TreatmentsService.GetPerformingStaff(Input).subscribe((res) => {
      this.Staffs = staffData.response;
      this.NurseData = this.Staffs.filter(x => x.categoryId === 4);

    // });
  }

  reloadInvestigationItems(event){
    // debugger
    this.orderId=event.data.orderId
    this.isToday=0
    this.resultForm.patchValue({
      StypeId:event.data.sampleTypeId
    })
    this.GetInvestigationTable()
  }

  searchPatientOpemModal(){
    this.modaltempSearch.open();
  }
  
  showBasicDialogSearch($event) {
    this.displayBasic = $event;
  }

  searchevent(patient: any) {
    this.displayBasic = false;
    
    this.resultForm.patchValue({
      PatientId:patient.data.patientId,
      regNo:patient.data.regNo,
      patientName:patient.data.patient
    })

    this.pendingTable()
  }

  GetPatientByRegNo() {
    this.patientService.SearchPatientByRegNo(this.resultForm.get("regNo").value).subscribe((res) => {
      let patients = res.response;


      if (patients.length > 0) {
        if (patients[0].active == "0") {
          this.toastr.warning("this patient is blocked")
          
        }
        this.resultForm.patchValue({
          PatientId:patients[0].patientId,
          regNo:patients[0].regNo,
          patientName:patients[0].patient
        })
        this.pendingTable()

      } else {

        this.toastr.warning("No patients found");
      }

    });
  }
}