import { Component, OnInit,TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AllAppointmentsList } from '../../interfaces/AllAppointmentsList';
import { AppointmentsList } from '../../interfaces/AppointmentsList';
import { AppointmentsService } from '../../services/appointments.service';
import { Router } from '@angular/router';
import { Departments } from '../../interfaces/Departments';
import { Consultants } from '../../interfaces/Consultants';
import { HospitalsService } from '../../services/hospitals.service';
import { DatePipe } from '@angular/common';
import {AppointmentTypesList} from '../../interfaces/AppointmentTypesList';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../general-action/general-action.component';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { AppointmentScheduleList } from '../../interfaces/AppointmentScheduleList';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../shared/shared.service';
import { GuiCellView, GuiColumn,GuiPaging, GuiPagingDisplay, GuiSearching, GuiSorting,GuiColumnMenu, GuiInfoPanel  } from '@generic-ui/ngx-grid';
import { ConfigurationService } from '../../services/configuration.service';
import { TelerikPrintComponent } from '../../../../shared/modals/telerik-print/telerik-print.component';
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  modalRef?: BsModalRef;

  products: any = {};
  items: any = {};
  update: any = {};
  delete: any = {};
  appointmentResult: any;
  form: FormGroup;
  appointments: AllAppointmentsList[] = [];
  CancelAppId:number;
customer:any[];
PostponeObj:any;
  posts = [];
  dtOptions: any = {};
  info: string;
  active: string;
  confirmblock:boolean;
  user_role:any;
  user_id :any;
  router_url=this.router.url;
  appointmentTypesList:AppointmentTypesList[]=[];
  departments:any;
  consultants: Consultants[] = [];
  valueDate: any;
  valueDate2: string;
  CancelAppointments: boolean;
  PostponedAppointments:boolean;
  globals: globalvars = new globalvars();
  CancelButton:boolean;
  blockorunblock: boolean;
  searchForm:FormGroup
  //blockId: number;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings2: IDropdownSettings = {};
  consultants_array: Consultants[] = [];
  PostPonedDepartment:any;

  blockId:any;
  blockResult:any;
  //params: any;
  public params: any;
  editData: any;
  isCollapsed = true;
  //AG-Grid set up
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize:10,
    rowClassRules: {
      'c-reds': function (params) { return params.data.appStatus == 'Cancelled'; },
    },

  }
  public getRowStyle;
  public statusBar;


  columnDefs: ColDef[] = [];

  paging: GuiPaging = {
    enabled: true,
    page: 1,
    pageSize: 10,
    pageSizes: [10, 25, 50],
    pagerTop: false,
    pagerBottom: true,
    display: GuiPagingDisplay.ADVANCED
  };

  sorting: GuiSorting = {
    enabled: true
  };

  searching: GuiSearching = {
    enabled: true,
    placeholder: 'Search Here'
  };
  GuiColumnMenu = {
    enabled: true,
    columnsManager: true
  };

  infoPanel: GuiInfoPanel = {
    enabled:true,
    infoDialog:false,
    columnsManager:true,

   };
  AppointmentDataDetails: AppointmentsList[];
  consultantName: any;
  appDate: any;
  BranchId: any;
  consultDataId: any[];
  consultantList = [];
  DeptId: any;
  consultant: any;
  sconsultant: any[];
  departmentList: any;
  scheduleTime: AppointmentScheduleList[];
  result: any;
  AppId: any;
  Consultant: string;
  postponeddate: any;
  minDate:any
  minDate1 = new Date();
  //sconsultant: any;
  subscription: Subscription;
  BranchesId: any;
  appointmentDate: string;
  CurrentappointmentDate: string;
  startDateFrom: any;
  startDateTo: any;
  functionPermissionData: any;


  constructor(private appointmentService: AppointmentsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private hospitalsService: HospitalsService,
    private datepipe: DatePipe,
    private modalService: BsModalService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
  )
  {
    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    this.minDate1.setDate(this.minDate1.getDate() - 1);
    let currentappDate = new Date();
    this.CurrentappointmentDate=this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    let convertDate=currentappDate.setDate(currentappDate.getDate() + 7);
    this.appointmentDate=this.datepipe.transform(convertDate, 'dd-MM-yyyy');

//loading table grid
    this.columnDefs=[
      {
        headerName: "App.Date",
        minWidth:100,
        field: "appDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,

      },
      {
        headerName: "App.No/Time",
        minWidth:120,
        valueGetter: this.getTimeAndNo,
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Consultant",
        minWidth:130,
        field: 'cFirstName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

        comparator: customComparator
      },
      {
        headerName: "Reg.No",
        minWidth:120,
        field: 'regNo',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 1.5,

      },
      {
        headerName: "Patient Name",
        minWidth:150,
        field: 'patientName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },
      {
        headerName: "Mobile",
        minWidth:150,
        field: 'mobile',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Status",
        minWidth:85,
        field: 'appStatus',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Insurance",
        minWidth:90,
        field: 'sponsorName',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },
      {
        headerName: "PIN",
        minWidth:100,
        field: 'pin',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Address",
        minWidth:150,
        field: 'address1',
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        flex: 2,
        comparator: customComparator,

      },

      {
        headerName: "Action",
        maxWidth:100,
        filter: false,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "AppoinmentList" },
      },
    ];
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
  };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    this.statusBar = {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'center',
        },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' },
      ],
    };

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
         this.BranchesId=this.globals.HospitalId

      }
    });

   }

   getTimeAndNo(params){
    return params.data.appNo + '/'+ params.data.sliceTime
   }

   AppointmentPostPoned(appId)
   {
    let actionId;
    if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
      actionId = 478;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 511;
    } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
      actionId = 511;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
     let obj={
       AppId:appId,
       BranchId:this.BranchesId
    }
    this.PostponeObj=obj;
      } else {
        this.toastr.warning("You are not allowed");
      }
   }

  ngOnInit(): void {

    this.minDate = new Date();

    this.dropdownSettings = {
    singleSelection: false,
    idField: 'deptId',
    textField: 'deptName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
    };


    this.dropdownSettings2 = {
      singleSelection: false,
      idField: 'consultantId',
      textField: 'consultantName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.user_role = localStorage.getItem('user_role');
    this.user_id =  localStorage.getItem('user_id');

    this.valueDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    let dte = new Date();
    dte.setDate(dte.getDate() - 7);
    this.valueDate2 = this.datepipe.transform(dte.toString(), 'dd-MM-yyyy');



    this.form = this.formBuilder.group({
      sName: new FormControl(''),
      sMobile: new FormControl(''),
      sRegNo: new FormControl(''),
      sAddress: new FormControl(''),
      sEmiratesNo: new FormControl(''),
      sPin: new FormControl(''),
      sDate: new FormControl(''),
     // sResNo: new FormControl(''),
      sDepartment: new FormControl(''),
      sconsultant: new FormControl(''),
      sDateFromapp: new FormControl(this.CurrentappointmentDate),
      sDateToapp: new FormControl(this.appointmentDate),
      sAppointmentType: new FormControl(''),
       blockReason: new FormControl(''),
       consultantList:new FormControl(''),
       sBranch:  this.globals.HospitalId,
    })


    this.LoadAppTable();
    this.GetAppointmentType();
    //Edit Action Data

    this.items = [

      {

       label: 'Edit',command: (x:any) =>{
          this.editData;
        let appId=this.editData.appId;
          this.router.navigate(['/AddAppointment',appId]);

       }
      },
      {
        label: 'Postponed Appointments',command: () => {
            this.router.navigate(['/scheduledappoinment']);
        }
      },

      {
        label: 'Print Appointments',command: () => {
          let appId=this.editData.appId;

          this.PrintAppointmentCard(appId);
        }
      },
      {
        label: 'Cancel',command: () => {
          let appId=this.editData.appId;
        this.showCancelAppointment(appId);
        }
      }

    ];

    this.GetDepartments();
    this.functionPermission();
  }
  // page title to be displayed in header
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);

  }
  Storedata(data:any){
   this.editData=data;

  }


  onDeptSelect(selectedDepts: any) { // select multiple departments
    let deptList = this.form.get("PostPonedDepartment").value;

    this.loadConsultant1(deptList);
  }
  //loading consultants by department by ID
  loadConsultant1(deptList) {
     this.departmentList = [];
     this.Consultant="";
      deptList.forEach((element) => {
        this.departmentList.push(element.deptId);
      });
      this.hospitalsService.GetConsultantList(this.departmentList,this.Consultant).subscribe((res) => {
        if (res.status == 200){
          this.consultants_array = res.response;

        }
        else
          this.toastr.error("No consultants list found");
      });
  }
  onConsultantSelect(selectedDepts: any) { // select multiple consultant
    let consultantist = this.form.get("PostPonedConsultant").value;

  }



  LoadAppTable() {

    this.SearchAppointment();
    //this.searchevent
    if(this.searchevent){
    this.searchevent;
    }
    return false;
    this.appointmentService.GetAllAppointments().subscribe((res) => {
      this.appointments = res.response;


      if(this.user_role =='consultant' && this.router_url=='/consultantdesk'){

        if(this.user_id){
          this.appointments=this.appointments.filter(appointment => appointment.consultantId == this.user_id);

        }
      }

    });

  }
  //loading appointments listing
  SearchAppointment() {
    this.appointmentService.SearchAppointment(this.form,this.CurrentappointmentDate,this.appointmentDate).subscribe((res) => {
      this.appointments = res.response;
      if (this.appointments.length == 0) {
        this.toastr.warning("No Appointments found");
      }
    });
  }

  RowSelected(appointment: any) {

  }

  //getting departments and consultants
  GetDepartments() {
    let ShowAll=0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId,ShowAll).subscribe((res) => {
      this.departments = res.response;
    });
  }
  //get consultant by dept id


  loadConsultant(DepartmentId: Number) {
    this.consultants = null;
    let deptId = Number(this.form.get("sDepartment").value);
    this.hospitalsService.GetConsultant(deptId).subscribe((res) => {
      this.consultants = res.response;

    })
  }


  //datepicker shows till todays date
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }


//Get Appointment type list for patient
GetAppointmentType() {
  this.appointmentService.GetAppointmentTypes().subscribe((res) => {
    this.appointmentTypesList = res.response;
  })
}

showCancelAppointment(rowDatas) {
  let actionId;
  if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
    actionId = 482;
  } else if(localStorage.getItem("usertype") == "G"){
    actionId = 515;
  } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
    actionId = 515;
  }
  const foundElement = this.functionPermissionData.find(x => x.id === actionId)
  if (foundElement.id == actionId && foundElement.isAllowed == true) {
 this.CancelAppId=rowDatas;
  } else {
    this.toastr.warning("You are not allowed");
  }
}


// Consulation cancel confirmation is true then ask reason
confirmblocking(blockId){

    this.confirmblock=true;


}

// Function to cancel consultation with reason
ConfirmBlock(blockId){

  let blockReason=this.form.get("blockReason").value;
  this.appointmentService.CancelAppointment(this.blockId,blockReason).subscribe((res)=>{

})
this.LoadAppTable();
  this.confirmblock=false;
}


/*===================Bhagya Changes==================================*/

//print appointment card details

PrintAppointmentCard(appId) {
  let actionId;
  if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
    actionId = 479;
  } else if(localStorage.getItem("usertype") == "G"){
    actionId = 512;
  } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
    actionId = 512;
  }
  const foundElement = this.functionPermissionData.find(x => x.id === actionId)
  if (foundElement.id == actionId && foundElement.isAllowed == true) {


    let initialState = {
      modalHeading: 'Print Appointment',
      reportName: 'PrintAppointment.trdp',
      reportParams: {
        AppId: appId,
        BranchId : this.globals.HospitalId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))

  // this.appointmentService.SearchAppointment(this.form,this.CurrentappointmentDate,this.appointmentDate).subscribe((res) => {
  //   this.appointments = res.response;
  //   let appointmentsData=this.appointments.filter((oppdata) => oppdata['appId'] == appId);
  //   let printContents = '';
  //   //contact detatils

  //   printContents = printContents + '<div align="center"><div class="card" style="border:1px solid #000;width:50%">';
  //   printContents = printContents + ' <table class="print-table1"><tr><td><h3>Appointment Details</h3></td></tr><tr><td><strong>Entry Date.</strong></td><td>' + this.appointments[0].appDate + '</td></tr>';

  //   printContents = printContents + ' <tr><td><strong>App Date:</strong></td><td>' + appointmentsData[0]['appDate'] + '</td></tr>';

  //   printContents = printContents + ' <tr><td><strong>App Time:</strong></td><td>' + appointmentsData[0]['sliceTime'] + '</td></tr>';

  //   printContents = printContents + ' <tr><td><strong>Consultant Name:</strong></td><td>' +  appointmentsData[0]['cFirstName'] + '</td></tr>';
  //   printContents = printContents + ' <tr><td><strong>Patient Name:</strong></td><td>' + appointmentsData[0]['patientName']+ '</td></tr>';
  //   printContents = printContents + ' <tr><td><strong>Mobile:</strong></td><td>' + this.appointments[0].mobile + '</td></tr>';

  //   var mywindow = window.open('', 'PRINT', 'height=1000,width=1200');
  //   mywindow.document.write('<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' + document.title + '</title>');
  //   mywindow.document.write('</head><body >');
  //   mywindow.document.write(printContents);
  //   mywindow.document.write('</body></html>');

  //   mywindow.document.close(); // necessary for IE >= 10
  //   mywindow.focus(); // necessary for IE >= 10*/

  //   mywindow.print();
  //   mywindow.close();
  // });
  return true;
  } else {
    this.toastr.warning("You are not allowed");
  }
}


openModal(template: TemplateRef<any>) {

  const modalRef = this.modalService.show(template);
  modalRef.content.reload.subscribe(res=>{
    this.SearchAppointment()
    if(res){
      // this.LoadAppTable();
      // this.GetAppointmentType();
     this.SearchAppointment()
      }
  });

}
reloading(data){
  this.SearchAppointment()
}
  //Getschedule method called from AppointmentService



  fromdatechange(event){
    let StartDateObj=this.form.get("sDateFrom").value;
    this.minDate = StartDateObj;
    }

    //common search event
    searchevent($event){
      let AppStartFrom=$event.value.sDateFromapp;
      let AppStartTo=$event.value.sDateToapp;
      if(typeof(AppStartFrom) =="object"){
        this.startDateFrom=this.datepipe.transform(AppStartFrom, 'dd-MM-yyyy');

      }
      else{
        this.startDateFrom=AppStartFrom;
      }
      if(typeof(AppStartTo) =="object"){
        this.startDateTo=this.datepipe.transform(AppStartTo, 'dd-MM-yyyy');

      }
      else{
        this.startDateTo=AppStartTo;
      }
      this.appointmentService.SearchAppointment($event,this.startDateFrom,this.startDateTo).subscribe((res) => {
        this.appointments = res.response;
        if (this.appointments.length == 0) {
          this.toastr.warning("No Appointments found");
        }
      });
    }

    // adding reload for child userdetails click
  reload(){
    this.LoadAppTable()
  }

  returnFooter(eventData){
    this.SearchAppointment();
  }
    /*===================Bhagya Changes end==================================*/

   //  checking Agi grid is loaded completely
   onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged(){
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  onRegisterfromAppoinment(data: any){
    let actionId;
    if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
      actionId = 481;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 514;
    } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
      actionId = 514;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    this.router.navigate(['/AddRegistration'], {state: {appoinmentId: data.appId}});
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  appointmentEdit(appId) {
    let actionId;
    if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
      actionId = 477;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 510;
    } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
      actionId = 510;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
      this.router.navigate(["/AddAppointment", appId]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  consultationAction(appId) {
    let actionId;
    if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "6") {
      actionId = 480;
    } else if(localStorage.getItem("usertype") == "G"){
      actionId = 513;
    } else if(localStorage.getItem("usertype") == "U" && localStorage.getItem("selectedMenu") == "21") {
      actionId = 513;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
      if (appId) {
        this.router.navigate(["/AddConsultation", appId]);
      } else {
        this.router.navigate(["/AddRegistration"]);
      }    } else {
      this.toastr.warning("You are not allowed");
    }
  }
}


