import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Response } from '../../../_Models/Response';
import { AppointmentsList } from '../interfaces/AppointmentsList';
import { CountryList } from '../interfaces/CountryList';
import { DatePipe } from '@angular/common';
import { State1List } from '../interfaces/State1List';
import { catchError, retry } from 'rxjs/operators';
import { ErrorHandleService } from './error-handle.service';
import { AppointmentTypesList } from '../interfaces/AppointmentTypesList';
import { AppointmentScheduleList } from '../interfaces/AppointmentScheduleList';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  private baseUrl = "";

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle:ErrorHandleService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(event.url);
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  GetAppointments(date: string,DepartmentId: number, ConsultantId: number,BranchId:number) {
    var url = "api/TodaysPatient/GetAppointments";
    return this.http.post<Response<AppointmentsList[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": ConsultantId,
        "AppDate": date,
        "DeptId": DepartmentId,
        "BranchId":BranchId
      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  //Get all country list from api
    GetCountry(branchId) {
    var url = "api/MasterData/GetCountry";
    return this.http.post<Response<CountryList[]>>(`${this.baseUrl}${url}`,
    {
      "CountryId":0,
      "ShowAll":0,
      "BranchId":branchId
  }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  
  GetAllAppointments() {
    var url = "api/TodaysPatient/GetAllAppointments";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,{"AppId":0}).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
    }

    //delete appointment by App id
    // DeleteAppointment(appId:any){
    //   var url = "api/TodaysPatient/DeleteApppointment";
    //   return this.http.post<Response<any>>(`${this.baseUrl}${url}`,{"AppId":appId})
    // }
  //Saving new Appointments
  SaveAppointment(appData: any,schedule: any) {

    let date=new Date();
     let currentDate = this.datePipe.transform(date, "yyyy-MM-dd");
    var url = "api/TodaysPatient/InsertAppointment";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId":Number(schedule.consultantId),//Number(appData.consultantId),
        "AppDate":schedule.date, //.appDate,
        "consultantname":schedule.consultant,
        "BranchId":Number(schedule.BranchId),
        "departmentname":schedule.deptName,
        "PatientId":appData.patientId,
        "FirstName": appData.firstName,
        "MiddleName": appData.middleName,
        "LastName": appData.lastName,
        "Gender": Number(appData.Gender),
        "MaritalStatus": Number(appData.MaritalStatus),
        "AppReason": Number(appData.AppReason),
        "DOB": appData.DOB,
        "SliceTime": schedule.time,
        "SliceData":schedule.SliceData,
        "Title": appData.title,
        "Address1": appData.Address1,
        "Address2": appData.Address2,
        "street": appData.street,
        "PlacePo": appData.place,
        "PIN": appData.pin,
        "City": appData.city,
        "Mobile": appData.mobile,
        "ResPhone": appData.resPhoneNo,
        "OffPhone": appData.offNo,
        "Email":appData.email,
        "Remarks": appData.remarks,
        "AppType":Number(appData.appType),
        "CountryId": appData.CountryId,
        "State": appData.State,
        "regNo":appData.regNo,
        "AppStatus": appData.AppStatus,
        "UserId": Number(localStorage.getItem('user_id')),
         "CancelReason": "",
         "ReminderData":appData.ReminderData,
         //"RetDesc": "",
         //"SlotCount":appData.timeSlot
      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }


  UpdateAppointment(appData: any,schedule: any,appId:any) {
    //alert(JSON.stringify(schedule));
    let date=new Date();
     let currentDate = this.datePipe.transform(date, "yyyy-MM-dd");
    var url = "api/TodaysPatient/UpdateAppointment";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "appId":appId,
      //  "ConsultantId":Number(schedule.consultantId),//Number(appData.consultantId),
        "AppDate":schedule.date, //.appDate,
        "consultantname":appData.consultantName,
        "PatientId":appData.patientId,
        "FirstName": appData.firstName,
        "BranchId":appData.BranchId,
        //"SliceData":schedule.timeSlot,
        "MiddleName": appData.middleName,
        "LastName": appData.lastName,
        "Gender": Number(appData.Gender),
        "MaritalStatus": Number(appData.MaritalStatus),
        "AppReason": Number(appData.AppReason),
        "DOB": appData.DOB,
         "SliceData": appData.timeSlot,
        "Title": appData.title,
        "Address1": appData.Address1,
        "Address2": appData.Address2,
        "street": appData.street,
        "PlacePo": appData.place,
        "PIN": appData.pin,
        "City": appData.city,
        "Mobile": appData.mobile,
        "ResPhone": appData.resPhoneNo,
        "OffPhone": appData.offNo,
        "ConsultantId": appData.consultantsId,
        "deptId": appData.deptmntId,
        "Email":appData.email,
        "Remarks": appData.remarks,
        "AppType":Number(appData.appType),
        "CountryId": appData.CountryId,
        "State": appData.State,
        "regNo":appData.regNo,
        "AppStatus": appData.AppStatus,
        "UserId": Number(localStorage.getItem('user_id')),
        "ReminderData":appData.ReminderData,

         //"SlotCount":appData.timeSlot
      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }
  SearchAppointment(form,datefrom,dateto){
    var url = "api/TodaysPatient/SearchAppointment";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
    {
      "ConsultantId": Number(form.get("sconsultant").value),
      "BranchId":form.get("sBranch").value,
        "Name": form.get("sName").value,
        "RegNo": form.get("sRegNo").value,
        "PIN": form.get("sPin").value,
        "Address": form.get("sAddress").value,
        "Mobile": form.get("sMobile").value,
        "AppFromDate": datefrom,
        "AppToDate": dateto,
        "Phone": "",
        "AppType":Number(form.get("sAppointmentType").value),

    }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

 //Getting Appointmentype listing
 GetAppointmentTypes() {
  var url = "api/MasterData/GetAppType";
  return this.http.post<Response<AppointmentTypesList[]>>(`${this.baseUrl}${url}`, { "apptTypeId": 0 }).pipe(
    retry(1),
    catchError(this.errorHandle.handleError)
  );
}

//cancel appointments
CancelAppointment(appId: Number, blockReason: string) {

  var url = "api/TodaysPatient/DeleteAppointment";
  return this.http.post<Response<AppointmentsList[]>>(`${this.baseUrl}${url}`,
    {
      "AppId": appId,
      "Reason": blockReason,
      "UserId": Number(localStorage.getItem('user_id'))
    }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
}



GetAppointmentSchedule(Consultant,DateValue:string,DeptId,BranchId,pageNumber=1){

 // var url = "api/TodaysPatient/GetScheduleDataV2";
 var url = "api/Consultant/GetConsultantWorkingTimeSlice";

   //var url = "api/TodaysPatient/GetScheduleData";
  return this.http.post<Response<AppointmentScheduleList[]>>(`${this.baseUrl}${url}`,
   {
    "Consultant":Consultant,
    "DateValue":DateValue,
    "Departments": DeptId,
    "BranchId":Number(BranchId),
    "PageNumber":pageNumber

  }).pipe(
    retry(1),
    catchError(this.errorHandle.handleError)
  );
}

//get scheduled timeslot availablity
GetScheduledDataAvailbilty(ConsultantId,AppDate:string,TimeSliceFirst:number,RequiredSlots:number,) {

  var url = "api/TodaysPatient/AppoinmentValidCheck";
  return this.http.post<Response<AppointmentsList[]>>(`${this.baseUrl}${url}`,
    {
      "ConsultantId":ConsultantId,
      "AppDate":AppDate,
      //"Time":Time,
      "RequiredSlots":RequiredSlots,
      "TimeSliceFirst":TimeSliceFirst,


    }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
}

PrintAppointment(payload){
  var url = "api/Report/PrintAppointment";
  return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
    {
      "AppId": payload.AppId,
      "BranchId":payload.BranchId
    }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
}


GetAppoinmentDetailsbyId(appId) {
  var url = "api/TodaysPatient/GetAppointmentById";
  return this.http.post<Response<AppointmentsList[]>>(`${this.baseUrl}${url}`,
    {
      "appId": appId,
    }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
}
SavePostponnedAppointments(AppData) {
  var url = "api/TodaysPatient/PostponeAppointment";
  return this.http.post<Response<AppointmentsList[]>>(`${this.baseUrl}${url}`,
    {
      appId : AppData.appId,
      ConsultantId: AppData.PostPonedConsultant,
      AppDate: AppData.AppDate,
      AppNo:0,
      SliceData: AppData.SliceTime,
      AppType: 1,
      BranchId:AppData.BranchId,
      PatientId:AppData.PatientId,
      UserId: Number(localStorage.getItem('user_id')),

    }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
}
SaveAppointmentstatus(AppData) {
  var url = "api/TodaysPatient/UpdateAppointmentStatus";
  return this.http.post<Response<AppointmentsList[]>>(`${this.baseUrl}${url}`,
    {
      appId : AppData.appId,
      NewStatus: AppData.appointmentStatus,
      Reason:"",
      UserId: Number(localStorage.getItem('user_id')),

    }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
}

GetConsultantAppointments(date,deptId,consultantId,branchId){
  var url = "api/Consultant/GetMyAppointments";
  return this.http.post<Response<AppointmentsList[]>>(`${this.baseUrl}${url}`,
  {
    "ConsultantId":consultantId,
    "AppDate":date,
    "BranchId":branchId,
    "DeptId":deptId
}).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
}

SaveConsultantstatus(AppData) {
  var url = "api/TodaysPatient/UpdateAppointmentStatus";
  return this.http.post<Response<AppointmentsList[]>>(`${this.baseUrl}${url}`,
    {
      appId : AppData.appId,
      NewStatus: AppData.appointmentStatus,
      Reason:"",
      UserId: Number(localStorage.getItem('user_id')),

    }).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
}

SaveTimer(starttime,endtime,consultantId){
  // var url = "api/TodaysPatient/";
  // return this.http.post<Response<AppointmentsList[]>>(`${this.baseUrl}${url}`,
  //   {
  //     startTime : starttime,
  //     EndTime: endtime,
  //     patientId:146,
  //     ConsultantId:consultantId,
  //     UserId: Number(localStorage.getItem('user_id')),

  //   }).pipe(
  //     retry(1),
  //     catchError(this.errorHandle.handleError)
  //   );
}


getScheduleSlice(InputDetails){
  var url = "api/Consultant/GetConsultantWorkingTimeSlice";
  return this.http.post<Response<AppointmentScheduleList[]>>(`${this.baseUrl}${url}`, InputDetails)
}

printAsoap(InputDetails){
  var url = "api/Report/PrintAsoap";
  return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
}

getAsoapSignedFile(payload: any): Observable<Response<any>>{
  var url = "api/Report/GetSignedPdf";
  return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
}

getAsoapSignedErdp(payload: any): Observable<Response<any>>{
  var url = "api/MasterData/UploadAsoapSign";
  return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
}

getFilterData(payload: any): Observable<Response<any>>{
  var url = "api/MasterData/GetDefaultDepartmentsAndConsultants";
  return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
}

setFilterData(payload: any): Observable<Response<any>>{
  var url = "api/MasterData/InsertUpdateDefaultDepartmentsAndConsultants";
  return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
}


}

