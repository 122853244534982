import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FormValidationService } from '../../client/services/form-validation.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActiveFilterMenuTriggerDirective } from '@generic-ui/ngx-grid/structure/source/feature/counter/filter/active-filter-menu-trigger.directive';
import { ProfessionList } from '../../client/interfaces/ProfessionList';
import { ProfessionService } from '../../client/services/profession.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Observable, Observer, Subscription, noop, of } from 'rxjs';
import { ConfigurationService } from '../../client/services/configuration.service';
import { Select2OptionData } from 'ng-select2';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Response } from "../../../_Models/Response";
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
@Component({
  selector: 'app-icdlabel',
  templateUrl: './icdlabel.component.html',
  styleUrls: ['./icdlabel.component.scss']
})
export class IcdlabelComponent implements OnInit {

  icdGroupTab: boolean;
  icdLabelTab: boolean = true;
  icdCategoryTab: boolean;
  icdContradictoryTab: boolean;
  products: any = {};
  // dropdownSettings: IDropdownSettings = {};
  // signDropdownSettings: IDropdownSettings = {};
  displayModalLabel: boolean;
  displayModalGroup: boolean;
  displayModalCategory: boolean;
  public submitted: boolean = false;
  public submitted1: boolean = false;
  public submitted3: boolean = false;
  ICDlabelPopupHeading:String="Add ICD Label";
  ICDGroupPopupHeading:String="Add ICD Group";
  ICDCategoryPopupHeading:String="Add ICD Ctegory";
  ICDContradictoryPopupHeading:String="Add ICD Contradictory";
  result: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  public statusBar;
  rowGroupPanelShow = "always";
  //ag grid setup
  public gridApiLabel;
  public gridApiGroup;
  public gridApiCategory;
  public gridApiContradictory;
  columnDefsLabel: ColDef[] = [];
  columnDefsGroup: ColDef[] = [];
  columnDefsCategory: ColDef[] = [];
  columnDefsContradictory: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate="Please wait...";
  public icdtotalRows:number=0;
  private baseUrl: string = "";
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }

  GetIcdInput={
    "LabelId": 0,
    "ICDGroupId": 0,
    "CatgId": 0,
    "ShowAll": 1,
    "BranchId": this.globals.HospitalId,
    "LabelDesc": "",
    "PageNumber":1
  };

  public gridOptionsManageIcd = {
    pagination: true,
    paginationPageSize: 20,
  }

  currentPage:number=1;
  rowData: any;
  unlistedCode: any;
  ICDLabelForm: FormGroup;
  IcdlabelId: any;
  IcdlabelDatas: any;
  ICDLabelTabFilterGroupData: Array<Select2OptionData> = [];
  ICDLabelTabFilterCategoryData: Array<Select2OptionData> = [];
  ICDLabelTabModalGroupData: Array<Select2OptionData> = [];
  ICDLabelTabModalCategoryData: Array<Select2OptionData> = [];
  ICDCategoryForm: FormGroup;
  ICDCategoryId: any;
  ICDContradictoryId: any;
  icdcategoryDatas: any;
  icdcategoryDatasTemp: any;
  icdcontradictory: any;
  ICDCategoryTabFilterGroupData: Array<Select2OptionData> = [];
  ICDGroupForm: FormGroup;
  ICDContradictoryForm: FormGroup;
  ICDGroupId: any;
  icdGroupDatas: any;
  // SymptomsList: any[];
  // SignList: any[];
  loader:boolean=false;
  selectedSigns: any[] = [];
  selectedSymptoms: any[] = [];
  modalRefLabel: BsModalRef;
  modalRefGroup: BsModalRef;
  modalRefCategory: BsModalRef;
  modalRefContradictory: BsModalRef;
  isLoading=false;
  @ViewChild("templateLabel") modaltempLabel: any;
  @ViewChild("templateGroup") modaltempGroup: any;
  @ViewChild("templateCategory") modaltempCategory: any;
  @ViewChild("templateContradictory") modaltempContradictory: any;
  functionPermissionData: any;

  public icdObservable$?: Observable<any[]>;
  public icdSearch: string = "";
  public icdObservable1$?: Observable<any[]>;
  public icdSearch1: string = "";

  constructor(private formBuilder: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private ProfessionService: ProfessionService,
    private http: HttpClient,
    private toastr: ToastrService,
    private formValidationService: FormValidationService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private configurationManager: ConfigurationManager,
    private modalService: BsModalService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });
  }
  ngOnInit(): void {
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.ICDLabelForm = new FormGroup({
      icdgroup: new FormControl('', [Validators.required]),
      icdcategory: new FormControl('', [Validators.required]),
      icdlabelDesc: new FormControl('', [Validators.required]),
      icdlabelCode: new FormControl('', [Validators.required]),
      symptomSelect: new FormControl(),
      signSelect: new FormControl(),
      ICDlabelTabFilterGroupDDL: new FormControl(),
      ICDlabelTabFilterCategoryDDL: new FormControl(),
      IsDisplayedLabel: new FormControl(true),
      IsChronic: new FormControl(false),
    });
    this.ICDGroupForm = new FormGroup({
      icdgroupDesc: new FormControl('', [Validators.required]),
      icdgroupRange: new FormControl('', [Validators.required]),
      IsDisplayedGroup: new FormControl(true),
    });
    this.ICDCategoryForm = new FormGroup({
      icdgroupTwo: new FormControl(''),
      ICDCategoryModalGroupDDL: new FormControl('', [Validators.required]),
      icdcategoryName: new FormControl('', [Validators.required]),
      icdcategoryDesc: new FormControl('', [Validators.required]),
      IsDisplayedCategory: new FormControl(true),
    });
    this.ICDContradictoryForm = new FormGroup({
      ICDCode: new FormControl('', [Validators.required]),
      ICDContradictory: new FormControl('', [Validators.required]),
      IsDisplayedCategory: new FormControl(true),
    });
   // this.GetAllICDLabel();
    this.GetICDcategoryByGroup("0");
    this.GetAllICDGroup();
    this.GetICDContradictory();
    this.functionPermission();
    // this.getSymptomsList();
    // this.getSigns();
    this.columnDefsLabel = [
      {
        headerName: "ICD Label Description",
        field: "labelDesc",
        minWidth:150,
        enableRowGroup: true,
        resizable: true,
       // sortable: true,
        flex: 2,
        editable: false,
        
      },
      {
        headerName: "Label Code",
        field: "labelCode",
        minWidth:150,
        enableRowGroup: true,
        resizable: true,
      //  sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Group",
        field: "groupDesc",
        minWidth:150,
        enableRowGroup: true,
        resizable: true,
      //  sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Category",
        field: "catgName",
        minWidth:150,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Action",
        maxWidth:100,
        filter: false,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "labelId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "IcdlabelList" },
      },
    ]
    this.columnDefsGroup = [
      {
        headerName: "ICD Group Name",
        minWidth:150,
        field: "groupDesc",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Group Range",
        minWidth:150,
        field: "groupRange",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Action",
        maxWidth:100,
        pinned:"right",
        filter: false,
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "ICDGroupList" },
      },
    ]
    this.columnDefsCategory = [
      {
        headerName: "ICD Category Name",
        field: "catgName",
        minWidth:150,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Description",
        field: "catgDesc",
        minWidth:150,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Action",
        maxWidth:100,
        pinned:"right",
        filter: false,
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "catgId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "ICDCategoryList" },
      },
    ]
    this.columnDefsContradictory = [
      {
        headerName: "ICD Code",
        field: "icdCode",
        minWidth:150,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "ICD Contradictory code",
        field: "contradictoryICDCode",
        minWidth:150,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        editable: false
      },
      {
        headerName: "Action",
        maxWidth:100,
        pinned:"right",
        filter: false,
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "catgId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "ICDContradictoryList" },
      },
    ]
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;


    // this.dropdownSettings = {
    //   singleSelection: false,
    //   idField: 'symptomId',
    //   textField: 'symptomDesc',
    //   unSelectAllText: "UnSelect All",
    //   itemsShowLimit: 20,
    //   allowSearchFilter: true,

    // };

    // this.signDropdownSettings = {
    //   singleSelection: false,
    //   idField: 'id',
    //   textField: 'descriptionData',
    //   unSelectAllText: "UnSelect All",
    //   itemsShowLimit: 20,
    //   allowSearchFilter: true,
    // }


    this.GetIcdLabes();
    this.searchIcds();
    this.searchIcds1();

  }
  GetIcdLabes(loading=0){



    this.loader=true;
    this.GetIcdInput.BranchId=this.globals.HospitalId;

    if(loading==1)this.gridApiLabel.showLoadingOverlay();
    this.ConfigurationService.GetIcdLabelPagination(this.GetIcdInput).subscribe((res) => {
      const response= res.response;
      this.icdtotalRows=response['totalCount'];
      this.IcdlabelDatas = response['labelData'];
      if(loading==1)this.gridApiLabel.hideOverlay();
      this.loader=false;
    });

  }


  openModalLabel(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 10);
    if (foundElement.id == 10 && foundElement.isAllowed == true) {
      this.ICDlabelPopupHeading = "Add ICD Label";
      this.modalRefLabel = this.modalService.show(template);
      this.IcdlabelId = 0;
      this.clearLabelForm();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  openModalGroup(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 13);
    if (foundElement.id == 13 && foundElement.isAllowed == true) {
    this.ICDGroupPopupHeading="Add ICD Group";
    this.modalRefGroup = this.modalService.show(template);
    this.ICDGroupId = 0;
    this.clearGroupForm();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  openModalCategory(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 16);
    if (foundElement.id == 16 && foundElement.isAllowed == true) {
    this.ICDCategoryPopupHeading="Add ICD Category";
    this.modalRefCategory = this.modalService.show(template);
    this.ICDCategoryId = 0;
    this.clearCategoryForm();
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  openModalContradictory(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 16);
    // if (foundElement.id == 16 && foundElement.isAllowed == true) {
    this.ICDContradictoryPopupHeading="Add ICD Contradictory";
    this.modalRefContradictory = this.modalService.show(template);
    this.ICDContradictoryId = 0;
    this.clearCategoryForm();
    // } else {
    //   this.toastr.warning("You are not allowed");
    // }
  }


  showModalDialogLabel(IcdlabelData) {
    const foundElement = this.functionPermissionData.find(x => x.id === 11)
    if (foundElement.id == 11 && foundElement.isAllowed == true) {
    this.ICDlabelPopupHeading="Edit ICD Label";
    this.modalRefLabel = this.modalService.show(
      this.modaltempLabel,
      // Object.assign({}, { class: "gray modal-lg" })
    );
    this.IcdlabelId = IcdlabelData.labelId;
    let labelsigns = IcdlabelData['labelSigns'];
    let labelsymptoms = IcdlabelData['labelSymptoms'];
    this.selectedSigns = [];
    this.selectedSymptoms = [];
    if (labelsigns != null) {
      labelsigns.forEach(element => {
        let item = {
          'id': element.signId,
          'descriptionData': element.signDesc
        }
        this.selectedSigns.push(item);
      });
    }

    if (labelsymptoms != null) {
      labelsymptoms.forEach(element => {
        let item = {
          'symptomId': element.symptomId,
          'symptomDesc': element.symptomDesc
        }
        this.selectedSymptoms.push(item);
      });
    }
    this.ICDLabelForm.patchValue({
      icdgroup: IcdlabelData.icdGroupId,
      icdcategory: IcdlabelData.catgId,
      icdlabelDesc: IcdlabelData.labelDesc,
      icdlabelCode: IcdlabelData.labelCode,
      IsDisplayedLabel: IcdlabelData.isDisplayed,
      IsChronic: IcdlabelData.isChronic
    });
    this.ICDGroupId = IcdlabelData.groupId;
    this.GetICDcategoryByGroupModal(this.ICDGroupId)
    if (this.selectedSigns.length != 0) {
      this.ICDLabelForm.controls['signSelect'].setValue(this.selectedSigns);
    }
    if (this.selectedSymptoms.length != 0) {
      this.ICDLabelForm.controls['symptomSelect'].setValue(this.selectedSymptoms);
    }
    } else {
      this.toastr.warning("You are not allowed");
    }

  }
  showModalDialogGroup(icdgroupData) {
    const foundElement = this.functionPermissionData.find(x => x.id === 14)
    if (foundElement.id == 14 && foundElement.isAllowed == true) {
    this.ICDGroupPopupHeading="Edit ICD Group";
    this.modalRefGroup = this.modalService.show(
      this.modaltempGroup,
      // Object.assign({}, { class: "gray modal-lg" })
    );
    if (icdgroupData.isDisplayed == 1) {
      icdgroupData.isDisplayed = true;
    }
    else {
      icdgroupData.isDisplayed = false;
    }
    this.ICDGroupId = icdgroupData.groupId
    this.ICDGroupForm.patchValue({
      icdgroupDesc: icdgroupData.groupDesc,
      icdgroupRange: icdgroupData.groupRange,
      IsDisplayedGroup: icdgroupData.isDisplayed
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  showModalDialogCategory(icdCategoryData) {
    const foundElement = this.functionPermissionData.find(x => x.id === 17)
    if (foundElement.id == 17 && foundElement.isAllowed == true) {
    this.ICDCategoryPopupHeading="Edit ICD Group";
    this.modalRefCategory = this.modalService.show(
      this.modaltempCategory,
    );
    this.ICDCategoryId = icdCategoryData.catgId
    if (icdCategoryData.isDisplayed == 1) {
      icdCategoryData.isDisplayed = true;
    }
    else {
      icdCategoryData.isDisplayed = false;
    }
    this.ICDCategoryForm.patchValue({
      ICDCategoryModalGroupDDL: icdCategoryData.icdGroupId,
      icdcategoryName: icdCategoryData.catgName,
      icdcategoryDesc: icdCategoryData.catgDesc,
      IsDisplayedCategory: icdCategoryData.isDisplayed,

    });
    } else {
      this.toastr.warning("You are not allowed");
    }

  }
  showModalDialogContradictory(icdContradictoryData){
    this.ICDCategoryPopupHeading="Edit ICD Contradictory";
    this.modalRefContradictory = this.modalService.show(this.modaltempContradictory);
    console.log(icdContradictoryData)
    this.ICDContradictoryId = icdContradictoryData.id
    if (icdContradictoryData.isDisplayed == 1) {
      icdContradictoryData.isDisplayed = true;
    }
    else {
      icdContradictoryData.isDisplayed = false;
    }
    this.icdSearch = icdContradictoryData.icdCode
    this.icdSearch1 = icdContradictoryData.contradictoryICDCode
    this.ICDContradictoryForm.patchValue({
      ICDCode: icdContradictoryData.icdCode,
      ICDContradictory: icdContradictoryData.contradictoryICDCode,
      IsDisplayedCategory: icdContradictoryData.isDisplayed,

    });
  }
  //pagetitle to be displayed at the header
  onTab(number) {
    this.icdLabelTab = false;
    this.icdGroupTab = false;
    this.icdCategoryTab = false;
    this.icdContradictoryTab = false;
    if (number == "1") {
      this.icdLabelTab = true;
    } else if (number == "2") {
      this.icdGroupTab = true;
    }
    else if (number == "3") {
      this.icdCategoryTab = true;
    }
    else if (number == "4") {
      this.icdContradictoryTab = true;
    }
  }
  SaveICDLabel() {

    this.submitted3 = true;
    if (this.ICDLabelForm && this.ICDLabelForm.valid) {
      // let symlist = this.ICDLabelForm.controls['symptomSelect'].value;
      // let siglist = this.ICDLabelForm.controls['signSelect'].value;
      // let NewSymList = Array();
      // let NewSigList = Array();
      // if(symlist){
      //   symlist.forEach(element => {
      //     NewSymList.push({
      //       'SymptomId': element.symptomId,
      //       'SymptomDesc': element.symptomDesc
      //     });
      //   });
      // }

      // if(siglist){
      //   siglist.forEach(element => {
      //     NewSigList.push({
      //       'SignId': element.id,
      //       'SignDesc': element.descriptionData
      //     });
      //   });
      // }

      var AppData = {
        IcdGroup: this.ICDLabelForm.value.icdgroup,
        IcdCategory: this.ICDLabelForm.value.icdcategory,
        IcdlabelDesc: this.ICDLabelForm.value.icdlabelDesc,
        IcdlabelCode: this.ICDLabelForm.value.icdlabelCode,
        // LabelSigns: NewSigList,
        // LabelSymptoms: NewSymList,
        IsDisplayedLabel: this.ICDLabelForm.value.IsDisplayedLabel,
        ModifiedLoc: this.globals.Location.locationId,
        IsChronic: this.ICDLabelForm.value.IsChronic
      }
      if (AppData.IsDisplayedLabel == true) {
        AppData.IsDisplayedLabel = 1;
      }
      else {
        AppData.IsDisplayedLabel = 0;
      }
      this.  isLoading=true;

      this.ConfigurationService.InsertUpdateICDLabel(AppData, this.globals.HospitalId, this.IcdlabelId).subscribe((res) => {
        this.result = res;
        this.submitted3 = false;
        if (this.result.status == 200 && this.result.message == 'Success') {

          if (this.IcdlabelId > 0) {
            this.  isLoading=false;
            this.toastr.success("ICD Label Updated successfully");
            this.GetIcdLabes(1);
            this.IcdlabelId = 0;
          }
          else {
            this.  isLoading=false;
            this.toastr.success("ICD Label added successfully");
            this.GetIcdInput.PageNumber=1;
            this.GetIcdLabes(1);
          }
          this.modalRefLabel.hide();
          
         // this.GetAllICDLabel();
          this.clearLabelForm();

        }
        else {
          this.  isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    }
  }
  SaveICDGroup() {
    this.submitted = true;
    this.  isLoading=true;
    if (this.ICDGroupForm && this.ICDGroupForm.valid) {
      var AppData = {
        icdgroupDesc: this.ICDGroupForm.get("icdgroupDesc").value,
        icdgroupRange: this.ICDGroupForm.get("icdgroupRange").value,
        isDisplayed: Number(this.ICDGroupForm.get("IsDisplayedGroup").value)
      }
      this.ConfigurationService.InsertUpdateICDGroup(AppData, this.globals.HospitalId, this.ICDGroupId).subscribe((res) => {
        this.result = res;
        this.submitted = false;
        if (this.result.status == 200 && this.result.message == 'Success') {

          if (this.ICDGroupId > 0) {
            this.  isLoading=false;
            this.toastr.success("ICD Group Updated successfully");
            this.ICDGroupId = 0;
          }
          else {
            this.  isLoading=false;
            this.toastr.success("ICD Group added successfully");
          }
        }
        else {
          this.  isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
        this.modalRefGroup.hide();
        this.GetAllICDGroup();
        this.clearGroupForm();

        this.GetIcdInput.PageNumber=1;
        this.GetIcdLabes(1);
      })
    }
  }
  SaveICDCategory() {
    this.submitted1 = true;
    // if (typeof (this.ICDCategoryForm.get("icdgroupTwo").value) == 'object') {
    //   this.toastr.error("Please select ICD Group");
    // }
    // else
    this.  isLoading=true;
    if (this.ICDCategoryForm && this.ICDCategoryForm.valid) {
      var AppData = {
        icdGroupId: this.ICDCategoryForm.get("ICDCategoryModalGroupDDL").value,
        icdcategoryName: this.ICDCategoryForm.get("icdcategoryName").value,
        icdcategoryDesc: this.ICDCategoryForm.get("icdcategoryDesc").value,
        isDisplayed: this.ICDCategoryForm.get("IsDisplayedCategory").value,
      }
      this.ConfigurationService.InsertUpdateICDCategory(AppData, this.globals.HospitalId, this.ICDCategoryId).subscribe((res) => {
        this.result = res;
        this.submitted1 = false;
        if (this.result.status == 200 && this.result.message == 'Success') {

          if (this.ICDCategoryId > 0) {
            this.  isLoading=false;
            this.toastr.success("ICD Category Updated successfully");
            this.ICDCategoryId = 0;
          }
          else {
            this.  isLoading=false;
            this.toastr.success("ICD Category added successfully");
          }
          this.modalRefCategory.hide();
          this.clearCategoryForm();
          // this.GetICDcategoryByGroup(this.ICDLabelForm.controls['icdgroupTwo'].value);
        }
        else {
          this.  isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }
        this.GetICDcategoryByGroup("0");
        this.GetIcdInput.PageNumber=1;
        this.GetIcdLabes(1);
      })
    }
  }
  SaveICDContradictory(){
    this.submitted1 = true;
    this.  isLoading=true;
    if(this.icdSearch == '' || this.icdSearch1 == '') {
      this.toastr.error("Please Fill ICD code");
      this.submitted1 = false;
      this.  isLoading=false;
  } else {
    // if (this.ICDContradictoryForm && this.ICDContradictoryForm.valid) {
      var AppData = {
        Id: this.ICDContradictoryId == 0 ? 0 : this.ICDContradictoryId,
        BranchId:this.globals.HospitalId,
        ICDCode: this.ICDContradictoryForm.get("ICDCode").value ? this.ICDContradictoryForm.get("ICDCode").value : this.icdSearch,
        ContradictoryICDCode: this.ICDContradictoryForm.get("ICDContradictory").value ? this.ICDContradictoryForm.get("ICDContradictory").value : this.icdSearch1,
        IsDisplayed: this.ICDContradictoryForm.get("IsDisplayedCategory").value ? 1 : 0,
        IsDeleted: 0,
      }
      this.apiTrigerContradictory(AppData)
    // }
  }
  }

  apiTrigerContradictory(AppData:any){
    this.ConfigurationService.InsertUpdateContradictory(AppData).subscribe((res) => {
      this.result = res;
      this.submitted1 = false;
      if (this.result.status == 200 && this.result.message == 'Success') {

        if(AppData.IsDeleted == 0) {
          if (this.ICDContradictoryId > 0) {
            this.  isLoading=false;
            this.toastr.success("ICD Contradictory Updated successfully");
            this.ICDContradictoryId = 0;
          }
          else {
            this.  isLoading=false;
            this.toastr.success("ICD Contradictory added successfully");
          }
          this.GetICDContradictory();
        } else {
          this.GetICDContradictory();
          this.  isLoading=false;
            this.toastr.success("ICD Contradictory deleted successfully");
        }
        this.modalRefContradictory.hide();
        this.clearContrasictoryForm();
        // this.GetICDContradictory();
        // this.GetICDcategoryByGroup(this.ICDLabelForm.controls['icdgroupTwo'].value);
      }
      else {
        this.  isLoading=false;
        this.toastr.error("Failed : " + this.result.message);
      }
      // this.GetICDcategoryByGroup("0");
      // this.GetIcdInput.PageNumber=1;
      // this.GetIcdLabes(1);
    })
  }
  // GetAllICDLabel() {
  //   this.ConfigurationService.GetICDLabel(0, 0, this.globals.HospitalId, 1).subscribe((res) => {
  //     this.IcdlabelDatas = res.response;
  //   });
  // }
  GetAllICDGroup() {
    this.ConfigurationService.GetICDGroup(this.globals.HospitalId).subscribe((res) => {
      this.icdGroupDatas = res.response;
      this.ICDLabelTabModalGroupData = [];
      this.ICDLabelTabModalGroupData.push({
        id: '0',
        text: 'Select a group',
      });
      this.icdGroupDatas.forEach((a) => {
        this.ICDLabelTabModalGroupData.push({
          id: a.groupId.toString(),
          text: a.groupDesc,
        });
      });
    });

  }
  GetICDContradictory() {
    let payload = {
      Id:0,
      BranchId:this.globals.HospitalId
  }
    this.ConfigurationService.GetAllICDContradictory(payload).subscribe((res) => {
      this.icdcontradictory = res.response
    });

  }

  clearLabelForm() {
    this.ICDLabelForm.reset({
      icdgroup: '',
      icdcategory: '',
      icdlabelDesc: '',
      icdlabelCode: '',
      symptomSelect: [],
      signSelect: [],
      // BranchId: this.globals.HospitalId,
      IsDisplayedLabel: true,
      IsChronic: false
    });
    this.submitted3 = false;
  }
  clearGroupForm() {
    this.ICDGroupForm.reset({
      icdgroupDesc: '',
      icdgroupRange: '',
      IsDisplayedGroup: true
    });
    this.submitted = false;
  }
  clearCategoryForm() {
    this.ICDCategoryForm.reset({
      icdcategoryName: '',
      icdcategoryDesc: '',
      IsDisplayedCategory: true
    });
    this.submitted1 = false;
  }
  clearContrasictoryForm() {
    this.ICDContradictoryForm.reset({
      ICDCode: '',
      ICDContradictory: '',
      IsDisplayedCategory: true
    });
    this.icdSearch = ''
    this.icdSearch1 = ''
    this.submitted1 = false;
  }

  GetICDcategoryByGroup(event) {
    if (typeof (event) == "object") {
      event = 0;
    }

    this.ConfigurationService.GetICDCategory(0, Number(event), 1, this.globals.HospitalId).subscribe((res) => {
      this.icdcategoryDatas = res.response;
    });
  }
  GetICDcategoryByGroupLabelFilter(event) {

    if (typeof (event) == "object" || typeof (event) == "undefined") {
      event = 0;
    }

    if(event>0){
    this.ConfigurationService.GetICDCategory(0, Number(event), 1, this.globals.HospitalId).subscribe((res) => {
      this.icdcategoryDatasTemp = res.response;
      this.ICDLabelTabFilterCategoryData = [];
      this.ICDLabelTabFilterCategoryData.push({
        id: '0',
        text: 'Select a category',
      });
      this.icdcategoryDatasTemp.forEach((a) => {
        this.ICDLabelTabFilterCategoryData.push({
          id: a.catgId.toString(),
          text: a.catgName,
        });
      });
    });
  }
    // Chnaged Now
    // this.ConfigurationService.GetICDLabel(Number(event), 0, this.globals.HospitalId, 1).subscribe((res) => {
    //   this.IcdlabelDatas = res.response;
    //   this.gridApiLabel.setRowData(this.IcdlabelDatas);
    // });
    if(event>0){
      this.GetIcdInput.ICDGroupId=Number(event);
      this.GetIcdInput.PageNumber=1;
       this.GetIcdLabes(1);
    }

  }

  GetICDcategoryByFilter(event) {
    let GroupId = this.ICDLabelForm.get("ICDlabelTabFilterGroupDDL").value
    // changed now
    // this.ConfigurationService.GetICDLabel(Number(GroupId), Number(event), this.globals.HospitalId, 1).subscribe((res) => {
    //   this.IcdlabelDatas = res.response;
    //   this.gridApiCategory.setRowData(this.IcdlabelDatas);
    // });
    // this.ConfigurationService.GetICDCategory(Number(event), Number(GroupId), 1, this.globals.HospitalId).subscribe((res) => {
    //   this.icdcategoryDatasTemp = res.response;

    //   this.ICDLabelTabFilterCategoryData = [];
    //   this.ICDLabelTabFilterCategoryData.push({
    //     id: '0',
    //     text: 'Select a category',
    //   });
    //   this.icdcategoryDatasTemp.forEach((a) => {
    //     this.ICDLabelTabFilterCategoryData.push({
    //       id: a.catgId.toString(),
    //       text: a.catgName,
    //     });
    //   });
    // });
    if(event>0){
      this.GetIcdInput.CatgId=Number(event);
      this.GetIcdInput.ICDGroupId=Number(GroupId);
      this.GetIcdInput.PageNumber=1;
      this.GetIcdLabes(1);
    }

  }



  GetICDcategoryByGroupModal(event) {
    if (typeof (event) == "object") {
      event = 0;
    }
    else {
      this.ConfigurationService.GetICDCategory(0, Number(event), 1, this.globals.HospitalId).subscribe((res) => {
        this.icdcategoryDatasTemp = res.response;
        this.ICDLabelTabModalCategoryData = [];
        this.icdcategoryDatasTemp.forEach((a) => {
          this.ICDLabelTabModalCategoryData.push({
            id: a.catgId.toString(),
            text: a.catgName,
          });
        });
      });
    }
  }




  DeleteICDLabel(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 12)
    if (foundElement.id == 12 && foundElement.isAllowed == true) {
    this.IcdlabelId = id.labelId;
    this.ConfigurationService.DeleteICDLabel(this.IcdlabelId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("ICD Label deleted successfully");
       // this.GetAllICDLabel();
        this.IcdlabelId = 0;
        this.GetIcdInput.PageNumber=1;
        this.GetIcdLabes(1);
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
      }
    },
    );
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  DeleteICDGroup(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 15)
    if (foundElement.id == 15 && foundElement.isAllowed == true) {
    this.ICDGroupId = id.groupId;
    this.ConfigurationService.DeleteICDGroup(this.ICDGroupId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("ICD Group deleted successfully");
        this.GetAllICDGroup();
        this.ICDGroupId = 0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
      }
    },
    );
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  DeleteICDCategory(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 18)
    if (foundElement.id == 18 && foundElement.isAllowed == true) {
    this.ICDCategoryId = id.catgId;
    this.ConfigurationService.DeleteICDCategory(this.ICDCategoryId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("ICD Category deleted successfully");
        //this.GetICDcategoryByGroup(this.ICDLabelForm.controls['icdgroupTwo'].value);
        this.GetICDcategoryByGroup("0");
        this.ICDCategoryId = 0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
      }
    },
    );
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  deleteICDContradictory(data:any){
    this.submitted1 = true;
    this.  isLoading=true;
      var AppData = {
        Id: data.id,
        BranchId:data.branchid,
        ICDCode: data.icdCode,
        ContradictoryICDCode: data.contradictoryICDCode,
        IsDisplayed: data.isDisplayed ? 1 : 0,
        IsDeleted: 1,
      }
      this.apiTrigerContradictory(AppData)
  }
  //check and uncheck of registration date
  selectchecking(evt) {
    if (evt.target.checked == true) {
      this.unlistedCode = 1;
    }
    else {
      this.unlistedCode = 0;
    }
  }
  //  checking Agi grid is loaded completely
  onGridReadyLabel(params) {
    this.gridApiLabel = params.api;
  }

  onGridReadyGroup(params) {
    this.gridApiGroup = params.api;
  }
  onGridReadyCategory(params) {
    this.gridApiCategory = params.api;
  }
  onGridReadyContradictory(params) {
    console.log(this.gridApiContradictory,params)
    this.gridApiContradictory = params.api;
  }
  onFilterTextBoxChangedLabel() {
    // this.gridApiLabel.setQuickFilter(
    //   (document.getElementById('filter-text-box-label') as HTMLInputElement).value
    // );

    // Paginator Search
    this.GetIcdInput.LabelDesc=(document.getElementById('filter-text-box-label') as HTMLInputElement).value;
    this.GetIcdInput.PageNumber=1;
    this.GetIcdLabes(1);
  }
  onFilterTextBoxChangedGroup() {
    this.gridApiGroup.setQuickFilter(
      (document.getElementById('filter-text-box-group') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedCategory() {
    this.gridApiCategory.setQuickFilter(
      (document.getElementById('filter-text-box-category') as HTMLInputElement).value
    );
  }
  onFilterTextBoxChangedContradictory() {
    console.log(this.gridApiContradictory)
    this.gridApiContradictory.setQuickFilter(
      (document.getElementById('filter-text-box-Contradictory') as HTMLInputElement).value
    );
  }

  // getSymptomsList() {
  //   this.ConfigurationService.GetSymptom(this.globals.HospitalId, 0).subscribe((res) => {
  //     this.SymptomsList = res.response;
  //   });
  // }
  // getSigns() {
  //   this.ConfigurationService.GetSign(this.globals.HospitalId, 0).subscribe((res) => {
  //     this.SignList = res.response;
  //   });
  // }

  get labelf() {
    return this.ICDLabelForm.controls;
  }
  get groupf() {
    return this.ICDGroupForm.controls;
  }
  get categoryf() {
    return this.ICDCategoryForm.controls;
  }
  ResetFunction() {
    this.IcdlabelId = 0;
    this.clearLabelForm();

  }
  ResetFunction2() {
    this.ICDGroupId = 0;
    this.clearGroupForm();

  }
  ResetFunction3() {
    this.ICDCategoryId = 0;
    this.clearCategoryForm();

  }
  ResetFunction4(){
    this.clearContrasictoryForm()
  }
  pageChanged(event){
    // console.log(event);
    // console.log(this.icdtotalRows);
      this.GetIcdInput.PageNumber=event.page;
      this.GetIcdLabes(1);
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  // load while add input ICD codtrationary
  LoadSelectedICD(id:any){
    console.log(id)
    this.ICDContradictoryForm.get('ICDCode').setValue(id.labelCode)
  }
  LoadSelectedICD1(id:any){
    this.ICDContradictoryForm.get('ICDContradictory').setValue(id.labelCode)
    console.log(id)
  }
  searchIcds() {
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.icdObservable$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.icdSearch);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<[]>>(`${this.baseUrl}api/MasterData/GetICDLabel`, {
              LabelDesc: query,
              LabelId: 0,
              ShowAll: 0,
              BranchId: this.globals.HospitalId,
            })
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  let errorMessage =
                    (err && err.message) || "Something goes wrong";
                  this.toastr.error(errorMessage);
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }
  searchIcds1() {
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.icdObservable1$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.icdSearch1);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<[]>>(`${this.baseUrl}api/MasterData/GetICDLabel`, {
              LabelDesc: query,
              LabelId: 0,
              ShowAll: 0,
              BranchId: this.globals.HospitalId,
            })
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  let errorMessage =
                    (err && err.message) || "Something goes wrong";
                  this.toastr.error(errorMessage);
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }
}
