import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';

@Component({
  selector: 'app-emr-service-order-medications',
  templateUrl: './emr-service-order-medications.component.html',
  styleUrls: ['./emr-service-order-medications.component.scss']
})
export class EmrServiceOrderMedicationsComponent implements OnInit {
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  Selectedtab:string;
  constructor(private sharedservice: SharedService,) { }

  ngOnInit(): void {
    this.Scrolltotab('ServiceOrder');
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.globals.HospitalId
        this.BranchesId = this.globals.HospitalId;
      }

    });
  }

  Scrolltotab(elem)
  {

   this.Selectedtab=elem;
  }

}
