import { Component, Input, OnInit } from '@angular/core';
import { OphthalmologyService } from '../../services/ophthalmology.service';
import { Response } from '../../../../_Models/Response';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EMRService } from '../../services/emr.service';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import _ from 'lodash';

@Component({
  selector: 'app-slitlamp-examination',
  templateUrl: './slitlamp-examination.component.html',
  styleUrls: ['./slitlamp-examination.component.scss']
})
export class SlitlampExaminationComponent implements OnInit {

  @Input() globals: globalvars;

  public sitelmapForm: FormGroup;
  public IOPDropdown: any[] = [];
  public EMRpatientInfo: EMRPatientInfo;

  private FormValueChangeChecker: boolean = false;

  constructor(private ophthalmologyService: OphthalmologyService,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private EmrService: EMRService) { }

  ngOnInit(): void {
    this.EMRpatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initForm();
    this.getIOPDropdown();

    this.getSitlampExamination();
  }

  initForm(){
    this.sitelmapForm = this.formBuilder.group({
      SLEId: new FormControl(0),
      leftIop: new FormControl(''),
      rightIop:new FormControl(''),
      leftEyeLashes: new FormControl(''),
      rightEyeLashes: new FormControl(''),
      leftTearFilm: new FormControl(''),
      rightTearFilm: new FormControl(''),
      leftConjuctiva: new FormControl(''),
      rightConjuctiva: new FormControl(''),
      leftCornea: new FormControl(''),
      rightCornea: new FormControl(''),
      leftSclera: new FormControl(''),
      rightSclera: new FormControl(''),
      leftAntChamber: new FormControl(''),
      rightAntChamber: new FormControl(''),
      leftIris: new FormControl(''),
      rightIris: new FormControl(''),
      leftPupil: new FormControl(''),
      rightPupil: new FormControl(''),
      leftLens: new FormControl(''),
      rightLens: new FormControl(''),
      leftVitreous: new FormControl(''),
      rightVitreous: new FormControl(''),
      leftOpticDisc: new FormControl(''),
      rightOpticDisc: new FormControl(''),
      leftRetina: new FormControl(''),
      rightRetina: new FormControl(''),
    })
  }

  getIOPDropdown(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getIOPDropdown(payload).subscribe({
      next: (response: Response<any>)=>{
        this.IOPDropdown = response.response;
      }
    })
  }

  getSitlampExamination(){
    let payload = {
      PatientId: this.EMRpatientInfo.PatientId,
      ConsultationId: this.EMRpatientInfo.ConsultationId,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getSitelampExamination(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          if(response.response[0]){
            let data = response.response[0];
            let rightEye = _.find(data?.siteLampExaminationDetailList, {eye: 'R'});
            let leftEye = _.find(data?.siteLampExaminationDetailList, {eye: 'L'});
            this.sitelmapForm.patchValue({
              SLEId: data?.sleId,
              rightIop: rightEye?.iopId != 0 ? rightEye?.iopId : '',
              leftIop: leftEye?.iopId != 0 ? leftEye?.iopId : '',
              rightEyeLashes: rightEye?.eyeLashes,
              leftEyeLashes: leftEye?.eyeLashes,
              rightTearFilm: rightEye?.tearFilm,
              leftTearFilm: leftEye?.tearFilm,
              rightConjuctiva: rightEye?.conjuctiva,
              leftConjuctiva: leftEye?.conjuctiva,
              rightCornea: rightEye?.cornea,
              leftCornea: leftEye?.cornea,
              rightSclera: rightEye?.sclera,
              leftSclera: leftEye?.sclera,
              rightAntChamber: rightEye?.antChamber,
              leftAntChamber: leftEye?.antChamber,
              rightIris: rightEye?.iris,
              leftIris: leftEye?.iris,
              rightPupil: rightEye?.pupil,
              leftPupil: leftEye?.pupil,
              rightLens: rightEye?.lens,
              leftLens: leftEye?.lens,
              rightVitreous: rightEye?.vitreous,
              leftVitreous: leftEye?.vitreous,
              rightOpticDisc: rightEye?.opticDisc,
              leftOpticDisc: leftEye?.opticDisc,
              rightRetina: rightEye?.retina,
              leftRetina: leftEye?.retina
            });
          }
          this.sitelmapForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker = true;
          })
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

  saveSitelampExamination(){
    if(this.EMRpatientInfo.CrudType == 3 &&
      this.EMRpatientInfo.VisitId == 0 && !this.FormValueChangeChecker){
      return
    }

    let payload = {
      SLEId: Number(this.sitelmapForm.get('SLEId').value),
      PatientId: this.EMRpatientInfo.PatientId,
      ConsultationId: this.EMRpatientInfo.ConsultationId,
      VisitId: this.EMRpatientInfo.VisitId,
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals.Location.locationId,
      BranchId: this.globals.HospitalId,
      SiteLampExaminationDetailList: [
        {
          Eye: "R",
          IOPId: Number(this.sitelmapForm.get('rightIop').value),
          EyeLashes: this.sitelmapForm.get('rightEyeLashes').value,
          TearFilm: this.sitelmapForm.get('rightTearFilm').value,
          Conjuctiva: this.sitelmapForm.get('rightConjuctiva').value,
          Cornea: this.sitelmapForm.get('rightCornea').value,
          Sclera: this.sitelmapForm.get('rightSclera').value,
          AntChamber: this.sitelmapForm.get('rightAntChamber').value,
          Iris: this.sitelmapForm.get('rightIris').value,
          Pupil: this.sitelmapForm.get('rightPupil').value,
          Lens: this.sitelmapForm.get('rightLens').value,
          Vitreous: this.sitelmapForm.get('rightVitreous').value,
          OpticDisc: this.sitelmapForm.get('rightOpticDisc').value,
          Retina: this.sitelmapForm.get('rightRetina').value,
          SLEDId: 0
        },
        {
          Eye: "L",
          IOPId: Number(this.sitelmapForm.get('leftIop').value),
          EyeLashes: this.sitelmapForm.get('leftEyeLashes').value,
          TearFilm: this.sitelmapForm.get('leftTearFilm').value,
          Conjuctiva: this.sitelmapForm.get('leftConjuctiva').value,
          Cornea: this.sitelmapForm.get('leftCornea').value,
          Sclera: this.sitelmapForm.get('leftSclera').value,
          AntChamber: this.sitelmapForm.get('leftAntChamber').value,
          Iris: this.sitelmapForm.get('leftIris').value,
          Pupil: this.sitelmapForm.get('leftPupil').value,
          Lens: this.sitelmapForm.get('leftLens').value,
          Vitreous: this.sitelmapForm.get('leftVitreous').value,
          OpticDisc: this.sitelmapForm.get('leftOpticDisc').value,
          Retina: this.sitelmapForm.get('leftRetina').value,
          SLEDId: 0
        }
      ]
    }

    this.ophthalmologyService.saveSitelampExamination(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == 'Success'){
          this.sitelmapForm.patchValue({
            SLEId: response.response[0].sleId
          });
          this.FormValueChangeChecker = false;
          this.toastrService.success('Sitelamp Examination Details Saved Successfully');
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

}
