import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EMRRoutingModule } from './emr-routing.module';
import { EMRComponent } from './emr.component';
import { EMRHomeComponent } from './emr-home/emr-home.component';
import { EMRConsultationComponent } from './emr-consultation/emr-consultation.component';
import { EMRInfobarComponent } from './emr-infobar/emr-infobar.component';
import { EMRMenubarComponent } from './emr-menubar/emr-menubar.component';
import { AgGridModule } from 'ag-grid-angular';
import { TreeviewModule } from 'ngx-treeview';
import { SharedModule } from '../../shared/models/shared.module';
import { PatientBarComponent } from './patient-bar/patient-bar.component';
import { EmrQuickMenuComponent } from './emr-quick-menu/emr-quick-menu.component';
import { ConsultationGeneralComponent } from './consultation-general/consultation-general.component';
import { GeneralChiefComplaintsComponent } from './general-chief-complaints/general-chief-complaints.component';
import { ConsultationDentalComponent } from './consultation-dental/consultation-dental.component';
import { GeneralPhysicalCommunicationComponent } from './general-physical-communication/general-physical-communication.component';
import { GeneralReviewOfSymptomsComponent } from './general-review-of-symptoms/general-review-of-symptoms.component';
import { GeneralMedicalDecisionComponent } from './general-medical-decision/general-medical-decision.component';
import { GeneralNarrativeDiagonosisComponent } from './general-narrative-diagonosis/general-narrative-diagonosis.component';
import { GeneralPlanProcedureComponent } from './general-plan-procedure/general-plan-procedure.component';
import { GeneralMenstrualHistoryComponent } from './general-menstrual-history/general-menstrual-history.component';
import { GeneralDrugsMedicationComponent } from './general-drugs-medication/general-drugs-medication.component';
import { EmrPatientHistoryComponent } from './emr-patient-history/emr-patient-history.component';
import { EmrServiceOrderMedicationsComponent } from './emr-service-order-medications/emr-service-order-medications.component';
import { EmrDocumentsComponent } from './emr-documents/emr-documents.component';
import { GeneralVitalSignComponent } from './general-vital-sign/general-vital-sign.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { EmrPrintsComponent } from './emr-prints/emr-prints.component';
import { GeneralServiceOrderComponent } from './general-service-order/general-service-order.component';
import { DentalTreatmentPlanComponent } from './dental-treatment-plan/dental-treatment-plan.component';
import { DentalCompleteProcedureComponent } from './dental-complete-procedure/dental-complete-procedure.component';
import { DentalExaminationComponent } from './dental-examination/dental-examination.component';
import { EmrFollowUpComponent } from './emr-follow-up/emr-follow-up.component';
import { EmrSickLeaveComponent } from './emr-sick-leave/emr-sick-leave.component';
import { FollowUpStatusComponent } from './follow-up-status/follow-up-status.component';
import { FollowUpTreatmentDetailsComponent } from './follow-up-treatment-details/follow-up-treatment-details.component';
import { EmrTreatmentdetailsComponent } from './emr-treatmentdetails/emr-treatmentdetails.component';
import { EmrSketchComponent } from './emr-sketch/emr-sketch.component';
import { ListToothModalComponent } from './modals/list-tooth-modal/list-tooth-modal.component';
import { MarkOnTeethModalComponent } from './modals/mark-on-teeth-modal/mark-on-teeth-modal.component';
import { ToothChartModalComponent } from './modals/tooth-chart-modal/tooth-chart-modal.component';
import { DentalTreatmentHistoryComponent } from './dental-treatment-history/dental-treatment-history.component';
import { ConsultationGeneralSinglePageComponent } from './consultation-general-single-page/consultation-general-single-page.component';
import { ChiefComplaintsComponent } from './consultation-general-single-page/chief-complaints/chief-complaints.component';
import { PhysicalExaminationComponent } from './consultation-general-single-page/physical-examination/physical-examination.component';
import { ReviewOfSystemsComponent } from './consultation-general-single-page/review-of-systems/review-of-systems.component';
import { MedicalDecisionComponent } from './consultation-general-single-page/medical-decision/medical-decision.component';
import { SignsSymptomsIcdComponent } from './consultation-general-single-page/signs-symptoms-icd/signs-symptoms-icd.component';
import { PlansProcedureComponent } from './consultation-general-single-page/plans-procedure/plans-procedure.component';
import { MenstrualComponent } from './consultation-general-single-page/menstrual/menstrual.component';
import { VitalSignsComponent } from './consultation-general-single-page/vital-signs/vital-signs.component';
import { DrugsMedicationComponent } from './consultation-general-single-page/drugs-medication/drugs-medication.component';
import { ServiceOrdersComponent } from './consultation-general-single-page/service-orders/service-orders.component';
import { PrevVitalSignsModalComponent } from './consultation-general-single-page/modals/prev-vital-signs-modal/prev-vital-signs-modal.component';
import { PrevChiefComplaintsModalComponent } from './consultation-general-single-page/modals/prev-chief-complaints-modal/prev-chief-complaints-modal.component';
import { PrevPhysicalExaminationModalComponent } from './consultation-general-single-page/modals/prev-physical-examination-modal/prev-physical-examination-modal.component';
import { PrevSignsSymptomsIcdModalComponent } from './consultation-general-single-page/modals/prev-signs-symptoms-icd-modal/prev-signs-symptoms-icd-modal.component';
import { PrevSeviceOrdesModalComponent } from './consultation-general-single-page/modals/prev-sevice-ordes-modal/prev-sevice-ordes-modal.component';
import { PrevMedicalDecisionModalComponent } from './consultation-general-single-page/modals/prev-medical-decision-modal/prev-medical-decision-modal.component';
import { PrevPlanProcedureModalComponent } from './consultation-general-single-page/modals/prev-plan-procedure-modal/prev-plan-procedure-modal.component';
import { PrevMenstrualHistoryModalComponent } from './consultation-general-single-page/modals/prev-menstrual-history-modal/prev-menstrual-history-modal.component';
import { PrevReviewOfSystemsModalComponent } from './consultation-general-single-page/modals/prev-review-of-systems-modal/prev-review-of-systems-modal.component';
import { PrevDrugsAndMedicationModalComponent } from './consultation-general-single-page/modals/prev-drugs-and-medication-modal/prev-drugs-and-medication-modal.component';
import { ViewChiefComplaintModalComponent } from './consultation-general-single-page/modals/view-chief-complaint-modal/view-chief-complaint-modal.component';
import { ViewDrugsMedicationModalComponent } from './consultation-general-single-page/modals/view-drugs-medication-modal/view-drugs-medication-modal.component';
import { ViewMedicalDecisionModalComponent } from './consultation-general-single-page/modals/view-medical-decision-modal/view-medical-decision-modal.component';
import { ViewMenstrualHistoryModalComponent } from './consultation-general-single-page/modals/view-menstrual-history-modal/view-menstrual-history-modal.component';
import { ViewPhysicalExaminationModalComponent } from './consultation-general-single-page/modals/view-physical-examination-modal/view-physical-examination-modal.component';
import { ViewPlanProcedureModalComponent } from './consultation-general-single-page/modals/view-plan-procedure-modal/view-plan-procedure-modal.component';
import { ViewReviewSystemsModalComponent } from './consultation-general-single-page/modals/view-review-systems-modal/view-review-systems-modal.component';
import { ViewServiceOrdersModalComponent } from './consultation-general-single-page/modals/view-service-orders-modal/view-service-orders-modal.component';
import { ViewSignSymptomsIcdModalComponent } from './consultation-general-single-page/modals/view-sign-symptoms-icd-modal/view-sign-symptoms-icd-modal.component';
import { ViewVitalSignModalComponent } from './consultation-general-single-page/modals/view-vital-sign-modal/view-vital-sign-modal.component';
import { CopyEmrModalComponent } from './modals/copy-emr-modal/copy-emr-modal.component';
import { ConsultationOphthalmologyComponent } from './consultation-ophthalmology/consultation-ophthalmology.component';
import { VisionAcuityComponent } from './consultation-ophthalmology/vision-acuity/vision-acuity.component';
import { SlitlampExaminationComponent } from './consultation-ophthalmology/slitlamp-examination/slitlamp-examination.component';
import { AdnexaComponent } from './consultation-ophthalmology/adnexa/adnexa.component';
import { MedicalLensSpecificationComponent } from './consultation-ophthalmology/medical-lens-specification/medical-lens-specification.component';
import { InvestigationFlowSheetComponent } from './investigation-flow-sheet/investigation-flow-sheet.component';
import { ServiceOrdersHomeComponent } from './service-orders-home/service-orders-home.component';
import { EmrPhysiothearapyComponent } from './emr-physiothearapy/emr-physiothearapy.component';
import { ConsultationHomeopathyComponent } from './consultation-homeopathy/consultation-homeopathy.component';
import { MentalGeneralsComponent } from './consultation-homeopathy/mental-generals/mental-generals.component';
import { PhysicalGeneralsComponent } from './consultation-homeopathy/physical-generals/physical-generals.component';
import { EmrPhysiotherapyMasterComponent } from './emr-physiotherapy-master/emr-physiotherapy-master.component';
import { DentalEstimationComponent } from './dental-estimation/dental-estimation.component';
import { EstimateGridCellDropdownComponent } from './dental-estimation/estimate-grid-cell-dropdown/estimate-grid-cell-dropdown.component';
import { EmrPhysiotherapyViewComponent } from './emr-physiotherapy-view/emr-physiotherapy-view.component';
import { InstructorAuthenticationComponent } from './modals/mark-on-teeth-modal/instructor-authentication/instructor-authentication.component';
import { PopupAlertComponent } from './modals/popup-alert/popup-alert.component';
import { SignPopupComponent } from './modals/sign-popup/sign-popup.component';
import { DrugDosageComponent } from './consultation-general-single-page/modals/drug-dosage/drug-dosage.component';
import { AddEditFrequencyComponent } from './modals/add-edit-frequency/add-edit-frequency.component';
// import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [
    EMRComponent,
    EMRHomeComponent,
    EMRConsultationComponent,
    EMRInfobarComponent,
    EMRMenubarComponent,
    PatientBarComponent,
    EmrQuickMenuComponent,
    ConsultationGeneralComponent,
    GeneralChiefComplaintsComponent,
    ConsultationDentalComponent,
    GeneralPhysicalCommunicationComponent,
    GeneralReviewOfSymptomsComponent,
    GeneralMedicalDecisionComponent,
    GeneralNarrativeDiagonosisComponent,
    GeneralPlanProcedureComponent,
    GeneralMenstrualHistoryComponent,
    GeneralDrugsMedicationComponent,
    EmrPatientHistoryComponent,
    EmrServiceOrderMedicationsComponent,
    EmrDocumentsComponent,
    GeneralVitalSignComponent,
    EmrPrintsComponent,
    GeneralServiceOrderComponent,
    DentalTreatmentPlanComponent,
    DentalCompleteProcedureComponent,
    DentalExaminationComponent,
    EmrFollowUpComponent,
    EmrSickLeaveComponent,
    FollowUpStatusComponent,
    FollowUpTreatmentDetailsComponent,
    EmrTreatmentdetailsComponent,
    EmrSketchComponent,
    ListToothModalComponent,
    MarkOnTeethModalComponent,
    ToothChartModalComponent,
    DentalTreatmentHistoryComponent,
    ConsultationGeneralSinglePageComponent,
    ChiefComplaintsComponent,
    PhysicalExaminationComponent,
    ReviewOfSystemsComponent,
    MedicalDecisionComponent,
    SignsSymptomsIcdComponent,
    PlansProcedureComponent,
    MenstrualComponent,
    VitalSignsComponent,
    DrugsMedicationComponent,
    ServiceOrdersComponent,
    PrevVitalSignsModalComponent,
    PrevChiefComplaintsModalComponent,
    PrevPhysicalExaminationModalComponent,
    PrevSignsSymptomsIcdModalComponent,
    PrevSeviceOrdesModalComponent,
    PrevMedicalDecisionModalComponent,
    PrevPlanProcedureModalComponent,
    PrevMenstrualHistoryModalComponent,
    PrevReviewOfSystemsModalComponent,
    PrevDrugsAndMedicationModalComponent,
    ViewChiefComplaintModalComponent,
    ViewDrugsMedicationModalComponent,
    ViewMedicalDecisionModalComponent,
    ViewMenstrualHistoryModalComponent,
    ViewPhysicalExaminationModalComponent,
    ViewPlanProcedureModalComponent,
    ViewReviewSystemsModalComponent,
    ViewServiceOrdersModalComponent,
    ViewSignSymptomsIcdModalComponent,
    ViewVitalSignModalComponent,
    CopyEmrModalComponent,
    ConsultationOphthalmologyComponent,
    VisionAcuityComponent,
    SlitlampExaminationComponent,
    AdnexaComponent,
    MedicalLensSpecificationComponent,
    InvestigationFlowSheetComponent,
    ServiceOrdersHomeComponent,
    EmrPhysiothearapyComponent,
    ConsultationHomeopathyComponent,
    MentalGeneralsComponent,
    PhysicalGeneralsComponent,
    EmrPhysiotherapyMasterComponent,
    DentalEstimationComponent,
    EstimateGridCellDropdownComponent,
    EmrPhysiotherapyViewComponent,
    InstructorAuthenticationComponent,
    PopupAlertComponent,
    SignPopupComponent,
    DrugDosageComponent,
    AddEditFrequencyComponent

  ],
  imports: [
    CommonModule,
    EMRRoutingModule,
    SharedModule,
    // MultiSelectModule,
    TreeviewModule.forRoot(),
    AgGridModule.withComponents(),
    AccordionModule.forRoot(),
     TypeaheadModule.forRoot(),
  ] ,
  exports:[
    GeneralServiceOrderComponent
  ]
})
export class EMRModule { }
