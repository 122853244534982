import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { BillingGeneralActionComponent } from "../billing-general-action/billing-general-action.component";
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SponsorList } from "../../client/interfaces/SponsorList";
import { ConsultaionsService } from "../../client/services/consultations.service";
import { PatientService } from "../../client/services/patient.service";
import { ToastrService } from "ngx-toastr";
import { GenderList } from '../../client/interfaces/GenderList';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { BillingDashboardService } from '../../billing-settlement/dashboard/billingdashboard.service';
import { SharedService } from '../../../shared/shared.service';
import { DatePipe } from '@angular/common';
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { Router } from '@angular/router';
import { HospitalsService } from "../../client/services/hospitals.service";
import { CommonValidationComponent } from '../../../shared/common-validation/common-validation.component';
import {Location} from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;
  @ViewChild("ChangeApprovaltemplate") modaltempApproval: any;
  public SelectedMenu: string = "";
  public title: string = "";
  public addData: boolean = false;
  public searchFilter: boolean = false;
  public searchFilterBilling: boolean = false;
  public patientDetails: any;
  //ag grid setup
  public statusBar;
  rowGroupPanelShow = "always";
  globals: globalvars = new globalvars();
  subscription: Subscription;

  public gridApi;
  columnDefs: ColDef[] = [];
  todaysBilling: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.status == "C";
      },
    },
  }
  rowData: any;
  BillingList: FormGroup;
  sponsorList: SponsorList[] = [];
  PatientId: number;
  patients: any;
  genderList: GenderList[] = [];
  genderListData: any[];
  IsDisabled: boolean = true;
  ApprovalNoItems: ColDef[] = [];
  BillingListData: any;
  CurrentDate: any;
  billListingForm: FormGroup;
  getConsultationResponse: any;
  consultDate: any;
  departments: any;
  consultants: any;
  todaysConsultation: FormGroup;
  datePipe: any;
  getBillingDetails: any;
  typeId: number;
  userId: number;
  hospitalList: any;
  consultantTypeId: number;
  public validator = new CommonValidationComponent();


  constructor(
    private consultationService: ConsultaionsService,
    private patientService: PatientService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private BillingDashboardService: BillingDashboardService,
    private sharedservice: SharedService,
    public datepipe: DatePipe,
    private NewBillingService: NewBillingService,
    private router: Router,
    private hospitalsService: HospitalsService,
    private _location: Location

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
    if (this.router.getCurrentNavigation().extras.state) {
      this.typeId = this.router.getCurrentNavigation().extras.state.id;

    }
  }

  ngOnInit(): void {

    this.consultDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.CurrentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    this.BillingList = new FormGroup({
      id: new FormControl(0),
      RegNo: new FormControl(""),
      PatientName: new FormControl(""),
      patientId: new FormControl(0),
      Address: new FormControl(""),
      MobileNo: new FormControl(""),
      Dob: new FormControl(""),
      Gender: new FormControl(""),

    });

    this.billListingForm = new FormGroup({
      RegNos: new FormControl(""),
      PatientNames: new FormControl(""),
      BillNos: new FormControl(""),
      DateFromList: new FormControl(this.CurrentDate),
      DateToList: new FormControl(this.CurrentDate),
      PayStatus: new FormControl(),

    });

    this.todaysConsultation = new FormGroup({
      department: new FormControl(""),
      consultant: new FormControl(""),
      billedListStatus: new FormControl(""),
      hospitalId: new FormControl(""),
      consultantType: new FormControl(""),
    });

    this.ChangeFrame('TB');

    this.columnDefs = [

      {
        headerName: "Bill No.",
        minWidth: 150,
        field: "billNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Bill Date",
        minWidth: 150,
        field: "billDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Reg.No.",
        minWidth: 150,
        field: "regNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Patient Name",
        minWidth: 150,
        field: "patientName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Consultant",
        minWidth: 150,
        field: "consultantName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Sponsor",
        minWidth: 150,
        field: "sponsorName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Location",
        minWidth: 150,
        field: "location",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Bill Amount",
        minWidth: 150,
        field: "billAmount",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Bill Due",
        minWidth: 150,
        field: "dueAmt",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Bill Status",
        minWidth: 150,
        field: "status",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "UserName",
        minWidth: 150,
        field: "settledUser",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Communication",
        minWidth: 150,
        field: "mode",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        maxWidth: 80,
        resizable: false,
        pinned: "right",
        sortable: false,
        field: "Id",
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "BillingList" },
      },


    ];
    this.ApprovalNoItems = [

      {
        headerName: "Code",
        field: "BillNo",
        minWidth: 100,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Item Name",
        minWidth: 100,
        field: "BillDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Quantity",
        minWidth: 100,
        field: "RegnNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Rate",
        minWidth: 100,
        field: "PatientName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Amount",
        minWidth: 100,
        field: "PatientName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Approval No.",
        minWidth: 100,
        field: "PatientName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },


      {
        headerName: "Action",
        resizable: false,
        maxWidth: 100,
        pinned: "right",
        unSortIcon: true,
        field: "Id",
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "BillingList" },

      },

    ];
    this.todaysBilling = [

      {
        headerName: "Patient Name",
        minWidth: 150,
        field: "patientName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,

      },


      {
        headerName: "Regn.No.",
        minWidth: 150,
        field: "regNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Sponsor",
        minWidth: 150,
        field: "sponsorName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Mdn",
        minWidth: 150,
        field: "mdnstatus",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Pay Status",
        minWidth: 150,
        field: "payStatus",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Action",
        maxWidth: 80,
        resizable: false,
        pinned: "right",
        sortable: false,
        field: "Id",
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "TodaysBilling" },
      },


    ];

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: BillingGeneralActionComponent,
    };
    this.GetGender();
    this.GetBillList();
    this.getTodaysConsultations();
    //this.getDepartments();
    if (this.typeId == 1) {
      this.addDataScreen(1)
    }
    this.userId = Number(localStorage.getItem("user_id"));
    this.GetUserHospitals();

  }
  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }

  addDataScreen(flag) {
    this.GetBillList();
    this.clearForm();
    if (flag == 1) {
      this.addData = true;

    }
    else if (flag == 2) {
      this.addData = false;
      this.getTodaysConsultations();
    }


  }
  ChangeFrame(tab: string) {
    this.SelectedMenu = tab;

    switch (tab) {
      case "TB":
        {
          this.searchFilter = true;
          this.searchFilterBilling = false;
          this.title = "New Billing";
          break;
        }
      case "BD":
        {
          this.searchFilterBilling = true;
          this.searchFilter = false;
          this.title = "Bill Listing";
          break;
        }
      case "RP":
        {
          this.title = "Receipts Details";
          break;
        }
      case "PT":
        {
          this.title = "Payment Details";
          break;
        }
    }

  }

  //modal open for searching patient details
  showBasicDialog() {
    this.modaltemp.open();


  }
  searchevent(patient: any) {
    this.patientDetails = patient;
    this.PatientId = patient.data.patientId
    this.BillingList.patchValue({ Address: patient.data.address });
    this.BillingList.patchValue({ RegNo: patient.data.regNo });
    this.BillingList.patchValue({ PatientName: patient.data.patient });
    this.BillingList.patchValue({ MobileNo: patient.data.mobile });
    this.BillingList.patchValue({ Dob: patient.data.age });
    this.BillingList.patchValue({ Gender: patient.data.gender });
    this.loadSponsorTable();
    this.NewBillingService.PatientDataDetails = this.patientDetails;

  }

  //getting sponsors list
  loadSponsorTable() {

    this.consultationService.LoadSponsorTable(this.PatientId).subscribe((res) => {
      this.sponsorList = res.response;
    });

  }

  SearchPatientDetail(patient: any) {

    let _this = this;
    this.GetPatientByRegNo();
    setTimeout(function () {
      let patientDetail = _this.patients.filter(
        (patientData) => patientData.regNo == patient
      );

      if (patientDetail.length > 0) {
        _this.FillForm1(patientDetail[0]);

      }

    }, 1000);

  }

  GetPatientByRegNo() {
    this.patientService.SearchPatientByRegNo(this.BillingList.get("RegNo").value).subscribe((res) => {
      this.patients = res.response;


      if (this.patients.length > 0) {
        if (this.patients[0].active == "0") {

          this.toastr.warning("This Patient is blocked");


          this.BillingList.patchValue({ RegNo: this.patients[0].regNo });
          this.patients = [];
        }

      } else {

        this.toastr.warning("No patients found");
      }

    });
  }
  // on entering reg no filling patient details on form
  FillForm1(patient: any) {
    this.patientDetails = patient;
    this.PatientId = patient["patientId"];
    this.BillingList.patchValue({ Address: patient["address"] });
    this.BillingList.patchValue({ RegNo: patient["regNo"] });
    this.BillingList.patchValue({ PatientName: patient["patient"] });
    this.BillingList.patchValue({ MobileNo: patient["mobile"] });
    this.BillingList.patchValue({ Dob: patient["age"] });
    this.BillingList.patchValue({ Gender: patient["gender"] });
    this.loadSponsorTable();
  }
  GetGender() {
    this.patientService.GetGender().subscribe((res) => {
      this.genderList = res.response;
      this.genderListData = [];
      this.genderList.forEach((a) => {
        this.genderListData.push({
          id: a.id.toString(),
          text: a.genderName,
        });
      });
    })
  }

  openModalChangeApprovalNo() {
    this.modalRef = this.modalService.show(
      this.modaltempApproval, { class: "gray modal-lg" });
  }
  GetBillList() {
    let StartDateObj = this.billListingForm.value.DateFromList;
    let EndDateObj = this.billListingForm.value.DateToList;
    // date convertion
    if (typeof (StartDateObj) == "object") {
      let DateFromList = this.datepipe.transform(StartDateObj, 'dd-MM-yyyy');
      this.billListingForm.value.DateFromList = DateFromList;
    }
    if (typeof (EndDateObj) == "object") {
      let endDate = this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      this.billListingForm.value.DateToList = endDate;
    }

    let input = {
      "Locationid": 0,
      "TransFromDate": this.billListingForm.value.DateFromList ? this.billListingForm.value.DateFromList : "",
      "TransToDate": this.billListingForm.value.DateToList ? this.billListingForm.value.DateToList : "",
      "RegNo": this.billListingForm.value.RegNos ? this.billListingForm.value.RegNos : "",
      "BranchId": this.globals.HospitalId,
      "Externalstatus": 0,
      "PatientName": this.billListingForm.value.PatientNames ? this.billListingForm.value.PatientNames : "",
      "BillNo": this.billListingForm.value.BillNos ? this.billListingForm.value.BillNos : "",
    }
    this.BillingDashboardService.getBillingList(input).subscribe((res) => {
      this.BillingListData = res.response;
      //this.getConsultationResponse=this.BillingListData
    });
  }
  clearForm() {
    this.BillingList.reset({
      RegNo: "",
      PatientName: "",
      patientId: "",
      Address: "",
      MobileNo: "",
      Dob: "",
      Gender: ""

    });
    this.BillingList.clearValidators();

  }


  getTodaysConsultations() {
    this.consultDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    let Input = {
      "consultantId": 0,
      "externalstatus": 0,
      "consultDate": this.consultDate,
      "payStatusinput": -1,
      "branchId": this.globals.HospitalId,
    }

    this.NewBillingService.getBillingListConsultation(Input).subscribe((res) => {
      this.getConsultationResponse = res.response;
    });
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  /* ============  fetching data to be filled in select department dropdowns ============ */
  getDepartments() {
    let input = {
      "DepartmentId": 0,
      "ShowAll": 0,
      "BranchId": this.globals.HospitalId
    }
    this.NewBillingService.getDepartment(input).subscribe((res) => {
      this.departments = res.response;
    });
  }

  /* ============ Onchanging data Appointments by consultations ============ */
  loadConsultant(DepartmentId: number) {
    this.todaysConsultation.get('consultantType').reset();
    this.todaysConsultation.get('consultant').reset();
    let Input = {
      "DeptId": Number(DepartmentId ? DepartmentId : 0),
      "ShowExternal": false
    }
    this.NewBillingService.getConsultant(Input).subscribe((res) => {
      this.consultants = res.response;
    });
  }

  /* ============  fetching data to the Consultations grid ============ */
  // GetConsultations() {
  //   let status = this.todaysConsultation.get("billedListStatus").value;
  //   this.consultDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  //   let DepartmentId = Number(this.todaysConsultation.get("department").value);
  //   let consultantId = Number(this.todaysConsultation.get("consultant").value);
  //   let input={
  //     "ConsultantDate":this.consultDate,
  //     "Status":status?status:"",
  //     "ConsultantId":consultantId,
  //     "DeptId":DepartmentId,
  //     "BranchId":this.globals.HospitalId
  //   }
  //   this.NewBillingService.getConsultations(input).subscribe((res) => {
  //      // this.getConsultationResponse = res.response;
  //     });
  //   //this.GetAppointments();
  // }
  getBillingType() {
    let status = Number(this.todaysConsultation.get("billedListStatus").value);
    let consultantId = Number(this.todaysConsultation.get("consultant").value);
    this.consultDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    let Input = {
      "consultantId": consultantId,
      "externalstatus": 0,
      "consultDate": this.consultDate,
      "payStatusinput": status,
      "branchId": this.globals.HospitalId
    }
    this.NewBillingService.getBillingListConsultation(Input).subscribe((res) => {
      this.getConsultationResponse = res.response;
    });
  }
  //Global settings for Branch ID
  GetUserHospitals() {
    this.hospitalsService.GetUserSpecificHospitals(this.userId).subscribe((res) => {
      this.hospitalList = res.response;
      this.todaysConsultation.patchValue({ hospitalId: this.globals.HospitalId });

    });
  }

  //Loading consultants by consultants type

  loadConsultantType(consultantTypeId: any) {
    this.consultantTypeId = this.todaysConsultation.get("consultantType").value;
    let hospitalId = this.todaysConsultation.get("hospitalId").value;
    let Input = {
      "BranchId": Number(hospitalId),
      "IsExternal": Number(this.consultantTypeId)
    }

    this.NewBillingService.getConsultantByHospital(Input).subscribe((res) => {
      this.consultants = res.response;
    });

  }
  GetBillListppp() {
    let StartDateObj = this.billListingForm.value.DateFromList;
    let EndDateObj = this.billListingForm.value.DateToList;
    // date convertion
    if (typeof (StartDateObj) == "object") {
      let DateFromList = this.datepipe.transform(StartDateObj, 'dd-MM-yyyy');
      this.billListingForm.value.DateFromList = DateFromList;
    }
    if (typeof (EndDateObj) == "object") {
      let endDate = this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
      this.billListingForm.value.DateToList = endDate;
    }

    let input = {
      // "Locationid":0,
      "transFromDate": this.billListingForm.value.DateFromList ? this.billListingForm.value.DateFromList : "",
      "transToDate": this.billListingForm.value.DateToList ? this.billListingForm.value.DateToList : "",
      "regNo": this.billListingForm.value.RegNos ? this.billListingForm.value.RegNos : "",
      "branchId": this.globals.HospitalId,
      "patientName": this.billListingForm.value.PatientNames ? this.billListingForm.value.PatientNames : "",
      "payStatus": this.billListingForm.value.PayStatus ? this.billListingForm.value.PayStatus : "",

    }

    this.BillingDashboardService.getTodaysConsultationFilter(input).subscribe((res) => {
      this.getConsultationResponse = res.response;
      //this.getConsultationResponse=this.BillingListData
    });
  }

  goback() {
    this._location.back();
  }

  NewBill(data, details) {
    if (data == 1) {
      this.addDataScreen(1)
    }
    this.BillingList.patchValue({ RegNo: details.regNo });
    this.BillingList.patchValue({ PatientName: details.patientName });
    this.BillingList.patchValue({ Address: details.address });
    this.BillingList.patchValue({ MobileNo: details.mobile });
    this.BillingList.patchValue({ Dob: details.dob, });
    this.BillingList.patchValue({ Gender: details.gender });
    this.PatientId = details.patientId
    this.loadSponsorTable();
  }

  EditBill(data, details) {
    if (data == 1) {
      this.addDataScreen(1)
    }
    this.BillingList.patchValue({ RegNo: details.regNo });
    this.BillingList.patchValue({ PatientName: details.patientName });

  }

}
