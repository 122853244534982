import { DatePipe, Time } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SharedService } from '../../../../shared/shared.service';

import { BillingService } from '../../services/billing.service';

@Component({
  selector: 'app-openshift',
  templateUrl: './openshift.component.html',
  styleUrls: ['./openshift.component.scss']
})
export class OpenshiftComponent implements OnInit, OnDestroy {
  @Input() shiftdata

  @Output() createEmitter = new EventEmitter()

  public submitted: boolean;
  public isSaveClicked: boolean=false;

  public cancelBillLoading: boolean = false;
  public OpenShiftForm: FormGroup;

  private globals: globalvars = new globalvars();
  public isLoading: Boolean = false;
  private subscription: Subscription;

  private localTodayDate: Date = new Date();

  constructor( private billingService: BillingService,
                private toastrService: ToastrService,
                private sharedService: SharedService,
                private fb: FormBuilder,
                public datepipe: DatePipe,
                public modalRef: BsModalRef) {
                
                }

  
  ngOnInit(): void {
    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
    this.formInit();

  }

  formInit(){
    let localDate = this.datepipe.transform(this.localTodayDate, 'yyyy-MM-dd')
    let localTime = this.datepipe.transform(this.localTodayDate, 'hh:mm');
    const currentDateTime = localDate+'T'+localTime;
    this.OpenShiftForm=this.fb.group({
      UserId:new FormControl(Number(localStorage.getItem("user_id"))),
      ShiftFrom:new FormControl(currentDateTime, Validators.required),
      ShiftNo:new FormControl(0),
      ShiftDate:new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      locationName:new FormControl(this.globals.Location.locationName),
      LocationId:new FormControl(this.globals.Location?.locationId ? this.globals.Location.locationId : "",),
      ShiftId:new FormControl(0),
      OpeningBalance:new FormControl(0),
      Status:new FormControl("O"),
      BranchId:new FormControl(this.globals.HospitalId)
    })
  }
  
  save(){
    let payload=this.OpenShiftForm.getRawValue();
    if (this.shiftdata) {
      payload.shiftId = this.shiftdata.ShiftId; // set ShiftId to the existing shift's ShiftId
    }
    payload.ShiftNo = Number(payload.ShiftNo)
    payload.OpeningBalance = Number(payload.OpeningBalance);
    payload.ShiftFrom = this.datepipe.transform(payload.ShiftFrom, 'dd-MM-yyyy HH:mm')
      this.billingService.InsertShift(payload).subscribe((res) => {
        this.submitted = false;
        let result = res;
        if (result.status == 200 && result.message == 'Saved Successfully') {
            this.isLoading = false;
            this.toastrService.success("Saved SuccessFully'");
            this.createEmitter.emit({Status:true})
            this.close();
        }
        else {
          this.isLoading = false;
          this.toastrService.error("Failed : " + result.message);
        }
    })
  }

  close(){
    this.modalRef.hide()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
