import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { SigninComponent } from './signin/signin.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { ViewReportComponent } from './view-report/view-report.component';

const routes: Routes = [
{
  path: 'login',
  component: LoginComponent,
  data: { title: 'Log In', pageName: 'Login'  }
},
{
  path: 'forgot-password',
  component: ForgotPasswordComponent,
  data: { title: 'Forgot Password', pageName: 'Forgot Password'  }
},
{
  path: 'Signin',
  component: SigninComponent,
  data: { title: 'Signin ', pageName: 'Sign In'  }
},
{
  path: 'logout',
  component: LogoutComponent,
  data: { title: 'logout ', pageName: 'Log Out'  }
},
{
  path: 'view_report',
  component: ViewReportComponent,
  data: { title: 'view_report ', pageName: 'View Report'  }
},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
  static components = [
    LoginComponent,
    ForgotPasswordComponent,
    SigninComponent,
    ViewReportComponent
  ];

}
