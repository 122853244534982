import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-eclaim-submited',
  templateUrl: './eclaim-submited.component.html',
  styleUrls: ['./eclaim-submited.component.scss']
})
export class EClaimSubmitedComponent implements OnInit {
  columnDefs: ColDef[] = [];
   ECliamSubmited: any[] = [];
   rowGroupPanelShow = "always";
   public context;
   public gridApi;
   public statusBar;
   public gridOptions = {
     pagination: true,
     paginationPageSize: 10,
   }
   public loadingTemplate;
   public noRowsTemplate;
   public frameworkComponents;
 
  constructor() { }

  ngOnInit(): void {
    this.context = { componentParent: this };
    this.columnDefs = [
      {
        headerName: "Claim refNo.",
        field: "claimRefNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Sponsor Name",
        field: "sponsorName",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Sponsor Rule",
        field: "sponsorRule",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Upload Date",
        field: "uploadDate",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Download",
        field: "download",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
    ];
    // this.frameworkComponents = { buttonRender: BillingGeneralActionComponent};

  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

}
