import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EMRService } from '../../services/emr.service';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../shared/shared.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { Response } from '../../../../_Models/Response';
import { ToastrService } from 'ngx-toastr';
import { PrevChiefComplaintsModalComponent } from '../modals/prev-chief-complaints-modal/prev-chief-complaints-modal.component';
import { Settings } from '../../../settings/model/settings.model';

@Component({
  selector: 'app-chief-complaints',
  templateUrl: './chief-complaints.component.html',
  styleUrls: ['./chief-complaints.component.scss']
})
export class ChiefComplaintsComponent implements OnInit, OnDestroy,OnChanges {
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  disabled:boolean=false

  public chielfComplaintForm: FormGroup;
  public EMRpatientInfo: EMRPatientInfo;
  public FormValueChangeChecker: boolean = false;
  painVal:any=0;
  private subscription: Subscription;
  private globals: globalvars = new globalvars();

  constructor(private modalService: BsModalService,
              private formBuilder: FormBuilder,
              private EmrService: EMRService,
              private toastrService: ToastrService,
              private sharedservice: SharedService) { }

  ngOnInit(): void {
    this.EMRpatientInfo = this.EmrService.GetEMRPatientInfo();

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });
    this.initForm();
    this.GetChiefComplaints();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 36 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (isSettingWithValueZeroPresent) {
        // Your logic when a setting with value 0 is present and billEdit is true
        // this.chielfComplaintForm.disable()
        this.disabled=true
      }
      
    }
  }

  changeStyle(id, isInit = false) {
    this.painVal = id
    this.chielfComplaintForm.patchValue({
      painScale: id
    })
    for (let i = 1; i <= id; i++) {
      var element = document.getElementById(i.toString());
      element.classList.add("pain-scale__level--active");
    }
    for (let i = id + 1; i <= 10; i++) {
      var element = document.getElementById(i.toString());
      element.classList.remove("pain-scale__level--active");
    }
    if(!isInit){
      this.FormValueChangeChecker = true;
    }
  }

  initForm(){
    this.chielfComplaintForm = this.formBuilder.group({
      complaintId: new FormControl(0),
      chiefComplaint: new FormControl(""),
      complaintOf: new FormControl(""),
      site: new FormControl(""),
      symptomSince: new FormControl(""),
      severity: new FormControl(0),
      course: new FormControl(0),
      symptom: new FormControl(0),
      tobaccoStatus: new FormControl(0),
      associatedSigns: new FormControl(""),
      chiefComplaintsBy: new FormControl(""),
      painScale: new FormControl(0),
      visitId: new FormControl(this.EMRpatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
    })
    
  }

   //to get saved data
   GetChiefComplaints() {
    let input = {
      VisitId: this.EMRpatientInfo.VisitId,
      PatientId: this.EMRpatientInfo.PatientId,
      ShowAll: 1,
    };
    this.EmrService.GetChiefComplaints(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];
        this.chielfComplaintForm.patchValue(response);
        if (response) {
          this.changeStyle(response.painScale, true);
        }

        this.chielfComplaintForm.valueChanges.subscribe((x) => {
          this.FormValueChangeChecker = true;
        });
      }
    });
  }

  saveChiefComplaints(){
    if(this.EMRpatientInfo.CrudType != 3 &&
      this.EMRpatientInfo.VisitId > 0 && this.FormValueChangeChecker){
      let ChiefComplaints = this.chielfComplaintForm.getRawValue();
      ChiefComplaints.severity = Number(ChiefComplaints.severity);
      ChiefComplaints.course = Number(ChiefComplaints.course);
      ChiefComplaints.symptom = Number(ChiefComplaints.symptom);
      ChiefComplaints.tobaccoStatus = Number(ChiefComplaints.tobaccoStatus);
      ChiefComplaints.painScale = ChiefComplaints.painScale;
      ChiefComplaints.userId = Number(localStorage.getItem("user_id"));

      this.EmrService.SaveCheifComplaints(ChiefComplaints).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200 && response.message == 'Success'){
            this.toastrService.success("Success: " + "Chief Complaints Saved Successfully...");
            this.FormValueChangeChecker = false;
          }else{
            this.toastrService.error(response.errorMessage.message);
          }
        }
      })
    }
  }


  openHistory(){
    let initialState = {
      EmrPatientInfo: this.EMRpatientInfo
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const historyModalRef= this.modalService.show(PrevChiefComplaintsModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
