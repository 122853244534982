import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '../../../_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  getApplicationSettings(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/MasterData/GetApplicationSettings`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  getConsultationSchemes(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/Consultant/GetConsultantItemByType`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  GetRegScheme(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/MasterData/GetItemsByType`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  GetRateGroup(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/MasterData/GetItemRateAmountById`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  GetCountries(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/MasterData/GetCountry`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  GetStates(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/MasterData/GetState`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  GetCommunicationType(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/MasterData/GetCommunicationType`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  GetPatientConsentOptions(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/MasterData/GetConsentOption`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  GetConfidentialityCodes(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/MasterData/GetConfidentialityCode`;
    return this.http.post<Response<any>>(endPoint, payload);
  }

  insertUpdateSettings(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/MasterData/InsertUpdateAppicationSettings`;
    return this.http.post<Response<any>>(endPoint, payload);
  }


   //Getting  patient salutation
  GetTitle(payload: any) : Observable<Response<any>> {
    var url = "api/MasterData/GetCommonMasterItem";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetGender() : Observable<Response<any>> {
    var url = "api/MasterData/GetGender";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, null)
  }

  GetMaritalStatus(payload: any) : Observable<Response<any>> {
    var url = "api/MasterData/GetMaritalStatus";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetVisaTypes(payload: any) : Observable<Response<any>> {
    var url = "api/MasterData/GetVisaType";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetUsers(branchid) {
    var url="api/UserPermission/GetUsers/"+branchid;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,null);
  }

  InsertUpdateReportSettings(payload) {
    var url="api/MasterData/InsertUpdateReportSettings";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetReportSettings(payload) {
    var url="api/MasterData/GetReportSettings";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetFacilityDHALoginCredentials(payload) {
    var url="api/MasterData/GetFacilityDHALoginCredentials";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  UpdateFacilityDHALogin(payload) {
    var url="api/MasterData/UpdateFacilityDHALogin";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }



}
