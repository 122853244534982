import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../../../billing-settlement/modals/confirm-modal/confirm-modal.component';
import { SponsorsService } from '../../../client/services/sponsors.service';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { globalvars } from '../../../../shared/models/GlobalVars';
import _ from 'lodash';
import { SharedService } from '../../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-copy-sponsor-consultant-fee',
  templateUrl: './copy-sponsor-consultant-fee.component.html',
  styleUrls: ['./copy-sponsor-consultant-fee.component.scss']
})
export class CopySponsorConsultantFeeComponent implements OnInit {

  @Output() copyEmitter = new EventEmitter();

  @Input() EmrPatientInfo: any;
  @Input() branchId: any;
  EventId:any;
  getSponsorFeeDtl:any;
  GetAllSponsorsData:any;
  SponsorRulesData:any;
  sponsorId:any;
  ruleId:any;
  modalRefSponsor: BsModalRef;
  globals: globalvars = new globalvars();
  public copyForm: FormGroup;
  subscription: Subscription;
  public visitHistoryList: any[] = [];

  public copyLoading: boolean = false;

  constructor(private activeModal: BsModalRef,private SponsorsService: SponsorsService,
              private ConfigurationService: ConfigurationService,private router : Router,
              private formBuilder: FormBuilder,private sharedservice: SharedService,
              private modalService: BsModalService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
    this.GetAllSponsors();
    // this.getAllVisitsofPatient();
  }
  GetAllSponsors() {

    let Input = {
      "SponsorId": 0,
      "Showall": 1,
      "BranchId": this.globals.HospitalId
    }
    this.SponsorsService.GetAllSponsorList(Input).subscribe((res) => {
      this.GetAllSponsorsData = res.response;
    });
  }
  GetSponsorRule(i:any) {
    let Input = {
      "RuleId": 0,
      "SponsorId": Number(i),
      "BranchId": this.globals.HospitalId,
      "ShowAll": 0
    }
    if (i) {
      this.SponsorsService.GetSponsorRule(Input).subscribe((res) => {
        this.SponsorRulesData = res.response;
      });
    }

    // else {
    //   this.noRowsTemplate = "No Rows To Show"
    //   this.gridApiRule.hideOverlay();
    // }
  }

  onSelectRule(i:any){
    this.ruleId = i.target.value
  }
  onSelectVisit(event){
    this.sponsorId = event.target.value
    this.GetSponsorRule(event.target.value)
  }

  onNextClicked(){
    this.router.navigate(["/config/SponsorRules/" + this.sponsorId + '/' + this.ruleId,]);
    this.activeModal.hide();
  }

  close(){
    this.activeModal.hide();
  }
}
