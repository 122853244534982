import { Component, OnInit, Input, ViewChild, QueryList, ElementRef } from "@angular/core";
import { ColDef, GridApi, GridOptions } from "ag-grid-community";
import { BillingGeneralActionComponent } from "../billing-general-action/billing-general-action.component";
import { FormGroup, FormControl, FormArray,Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { noop, Observable, Observer, of, Subscription } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ConfigurationManager } from "../../../../assets/configuration/configuration-manager";
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Response } from "../../../_Models/Response";
import { ConfigurationService } from "../../client/services/configuration.service";
import { ServiceOrderingService } from "../../client/services/ServiceOrderingService";
import { BillingForm } from "../..//billing-settlement/interfaces/BillingForm";
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from "@angular/router";
import { BillingService } from "../services/billing.service";

interface servicItems {
  itemId: number;
  itemCode: string;
  itemName: string;
}

@Component({
  selector: "app-new-bill",
  templateUrl: "./new-bill.component.html",
  styleUrls: ["./new-bill.component.scss"],
})
export class NewBillComponent implements OnInit {
  @Input() patientDetails;
  @Input() sponsorList;
  @Input() consultationList: any[] = [];
  @Input() patientDetailsLoading: boolean;
  search?: string;
  suggestions$?: Observable<servicItems[]>;

  @ViewChild('quantity') quantityInput: QueryList<ElementRef>;

  private baseUrl: string = "";
  //ag grid setup
  public statusBar;
  rowGroupPanelShow = "always";
  public gridApi: GridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
 
  rowData: any;

  NewBillform: FormGroup;
  currentDate: any;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  errorMessage?: string;
  rateGroupDatas: any;
  GetSponsorsRateData: any;
  GetConsultantsData: any;
  consultantsTypes: any;
  isCollapsed: boolean = true;
  collapse: boolean = true;
  BillingForm:BillingForm;
  patientId:number;
  transactionLOCList: any[];
  locationId:number;
  credDetails: any;

  public submitted: boolean = false;

  public isExtLabBill: boolean = false;
  public externalLabs: any[] = [];

  private returnUrl: string;
  public creditDetailsLoading: boolean = false;
  gridOptions = {
      pagination: true,
      paginationPageSize: 10,
      rowClassRules :{
        'cell-expired':function(params){
          return params.data.expired
        },
        'ag-row-selected' : function(params) {
            return true
        },
      }
    };

  constructor(
    private datepipe: DatePipe,
    private http: HttpClient,
    private configurationManager: ConfigurationManager,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private ServiceOrderingService: ServiceOrderingService,
    private NewBillingService:NewBillingService,
    private billingService: BillingService,
    private router: Router,
    private toastr: ToastrService,
    private activateRoute: ActivatedRoute
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        let locationId=this.globals.Location.locationId;
        this.locationId=locationId;
        this.globals.HospitalId;
      }
    });

    this.baseUrl = (this.configurationManager as any).baseUrl;


    
  }

  ngOnInit(): void {

   

    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.suggestions$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.search);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<servicItems[]>>(
              `${this.baseUrl}api/Bill/GetBillServiceItem`,
              {
                ServiceName: query,
                branchId: this.globals.HospitalId,
                GroupId: 0,
                // "consultantId":this.EMRPatientInfo.ConsultantId
                consultantId: 0,
                CurrentDate: this.datepipe.transform(new Date(), "dd-MM-yyyy")
              }
            )
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  this.errorMessage =
                    (err && err.message) || "Something goes wrong";
                }
              )
            );
        }

        return of([]);
      })
    );

    /*****************************END  DROP DOWN AUTO COMPLETE****************** */

    this.currentDate = this.datepipe.transform(new Date(), "dd-MM-yyyy");
    this.NewBillform = new FormGroup({
      transDetailList: new FormArray([]),
      transNo: new FormControl("<<New>>"),
      transDate: new FormControl(this.currentDate),
      rGroupId: new FormControl("", Validators.required),
      consultantId: new FormControl("", Validators.required),
      consultationId: new FormControl(0, Validators.required),
      category: new FormControl("N"),
      instructions: new FormControl(""),
      patientType: new FormControl(1),
      remarks: new FormControl(""),
      externalType: new FormControl("0"),
      transId: new FormControl(0),
      transFlag:new FormControl(0),
      patientId:new FormControl("", Validators.required),
      totalAmount:new FormControl("",[Validators.required]),
      totalDiscount:new FormControl("",[Validators.required]),
      totalTax:new FormControl(0),
      spdiscPcnt:new FormControl(0),
      spdiscAmount:new FormControl(0),
      netAmount:new FormControl(""),
      totalDeduction:new FormControl(0),
      totalCopay:new FormControl(0),
      totalSponsored:new FormControl(0),
      totalNonInsured:new FormControl(0),
      deductionSurplus:new FormControl(0),
      status:new FormControl(""),
      duplicate:new FormControl(0),
      locationId:new FormControl(Number(this.locationId)),
      userId:new FormControl(Number(localStorage.getItem('user_id'))),
      packId:new FormControl(0),
      splDiscRemarks:new FormControl(""),
      itmDiscRemarks:new FormControl(""),
      branchId:new FormControl(Number(this.globals.HospitalId)),
      extLabId:new FormControl(0),
      isExtLabBill:new FormControl(false),
      sessionId:new FormControl(0),
      transactionLOCList:new FormControl(""),
      transactionTaxList:new FormControl(""),
      taxPcnt:new FormControl(0),
      locItemList:new FormControl(""),
      transCreditItemGroupList:new FormControl(""),
      sponsorName:new FormControl(""),
      agentName:new FormControl(""),
      patientPay:new FormControl(0),
      discountType:new FormControl(""),
      discountValue:new FormControl(""),
    });

    if(sessionStorage.getItem('isExternalLabBill')){
      let external = JSON.parse(sessionStorage.getItem('isExternalLabBill'));
      if(external.isExternalStatus && external.isExternalStatus == true){
        this.NewBillform.patchValue({
          isExtLabBill: true,
          externalType: 3
        });
        this.NewBillform.get('extLabId').setValidators(Validators.required);
      }else{
        this.NewBillform.patchValue({
          isExtLabBill: false,
          externalType: 0
        });
        this.NewBillform.get('extLabId').clearValidators();
      }
      this.NewBillform.get('extLabId').updateValueAndValidity();
    }



    this.GetAllRateGroup();
    this.loadConsultantType(4);
    this.getExternalLab();

    this.columnDefs = [
      {
        headerName: "",
        field: "empty",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        maxWidth: 80,
        checkboxSelection: true,
        filter: true,
        cellRenderer: params => {
          if(params.data.creditId === this.patientDetails.creditId) {
            params.node.setSelected(true);
          }else if(params.data.isPrimary == true) {
            params.node.setSelected(true)
          }
          return null
        },
      },
      {
        headerName: "Insurance",
        minWidth: 100,
        field: "sponsorName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "TPA",
        minWidth: 100,
        field: "agentName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Open Date",
        minWidth: 100,
        field: "openDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Ref.No",
        minWidth: 100,
        field: "refNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Policy No",
        minWidth: 100,
        field: "policyNo",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Validity",
        minWidth: 100,
        field: "expiryDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Action",
        maxWidth: 80,
        resizable: true,
        sortable: false,
        field: "Id",
        cellStyle: {color: '', 'text-decoration': 'none'},
        pinned: "right",
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "NewBillAdd" },
      },
    ];

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: BillingGeneralActionComponent,
    };

    this.activateRoute.queryParams.subscribe(params=>{
      this.returnUrl = params.returnUrl? params.returnUrl : 'todaysbilling';
    });
  }

  ngOnChanges() {
    if (this.patientDetails) {
      this.patientId=this.patientDetails.patientId;
      let PatientForm = this.patientDetails;
      if (PatientForm) {
        this.NewBillform.patchValue({
          consultantId: this.patientDetails.consultantId,
          consultationId: this.patientDetails.consultationId ? this.patientDetails.consultationId : 0,
          rGroupId: this.patientDetails.rGroupId,
          extLabId: this.patientDetails?.extLabId ? this.patientDetails.extLabId : 0,
        });
      }
      this.checkForConsultation();
      this.getDoctorInstructions();
    }
  }

  checkForConsultation(){
    this.gridApi.forEachNode(node=>{
      if(node.data.isPrimary == true) {
        node.setSelected(true);
      }
    });
    // if(this.NewBillform.get('consultationId').value == 0){
    //   this.columnDefs[0].checkboxSelection = false;
    //   this.gridApi.redrawRows();
    // }
  }

  getDoctorInstructions(){
    let consultant = Number(this.NewBillform.get('consultantId').value);
    if(consultant && consultant !=0){
      let payload = {
        patientId: this.patientId,
        consultantId: consultant,
        billDate: this.NewBillform.get('transDate').value
      }
      this.billingService.getBillingInstruction(payload).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200){
            let instructions = response.response;
            if(instructions.length > 0){
              this.NewBillform.get('instructions').setValue(instructions[0].billingInstruction);
              this.NewBillform.get('spdiscPcnt').setValue(Number(instructions[0].specialDiscount));
            }
          }else{
            this.toastr.error(response.Message);
          }
        },error: (error: any)=>{
          
        }
      })
    }
  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }

  NextBillingDetail() {
    if(this.creditDetailsLoading || this.patientDetailsLoading){
      return
    }

    if(!this.patientDetails || !this.patientDetails?.patientId){
      this.toastr.error('Please select a patient to continue...');
      return
    }

    else if( this.NewBillform.value?.rGroupId == 0){
      this.toastr.error('Please select a rate group to continue...');
      return
    }
    
    else if(!this.NewBillform.value?.consultantId || this.NewBillform.value?.consultantId == 0){
      this.toastr.error('Please select a consultant to continue...');
      return
    }

    else if(this.NewBillform.value?.isExtLabBill && this.NewBillform.value?.extLabId == 0){
      this.toastr.error('Please select a lab to continue...');
      return
    }

    this.patientDetails.consultationId = Number(this.NewBillform.value?.consultationId);

    // if(!this.patientDetails?.sponsor){
    //   this.toastr.warning('Please select a sponsor to continue..');
    //   return
    // }
    let billData = {
      patientData: this.patientDetails,
      OtherData: this.NewBillform.value,
      credDetails: this.credDetails
    }
    sessionStorage.setItem('billDetails', JSON.stringify(billData));
    this.router.navigate(['../billitems'], {queryParams: {returnUrl: this.returnUrl}});
  }


  GetAllRateGroup() {
    let input = {
      RGroupId: 0,
      ShowAll: 0,
      BranchId: this.globals.HospitalId,
    };
    this.ConfigurationService.GetRateGroup(input).subscribe((res) => {
      this.rateGroupDatas = res.response;
      this.GetSponsorsRateData = [];
      this.rateGroupDatas.forEach((a) => {
        this.GetSponsorsRateData.push({
          id: a.rGroupId.toString(),
          text: a.rGroupName,
        });
      });
    });
  }

  loadConsultantType(consultantTypeId: any) {
    let consultantId = this.NewBillform.get('externalType').value;
    let hospitalIds = this.globals.HospitalId;

    this.ServiceOrderingService.GetConsultantByHospital(
      Number(hospitalIds),
      consultantId
    ).subscribe((res) => {
      this.consultantsTypes = res.response;
      this.GetConsultantsData = [];
      this.consultantsTypes.forEach((a) => {
        this.GetConsultantsData.push({
          id: a.consultantId.toString(),
          text: a.consultantName,
        });
      });
    });
  }

  get f() {
    return this.NewBillform.controls;
  }


  onSelectSponsor(event){  
    if(event.node.selected){
      if(this.NewBillform.get('consultationId').value && this.NewBillform.get('consultationId').value != 0){
        let selectedSponsor = this.gridApi.getSelectedRows()[0];
        if(selectedSponsor){
          let payload = {
            CreditId:selectedSponsor.creditId,
            SponsorId:selectedSponsor.sponsorId,
            Branchid: this.globals.HospitalId
          }
          this.creditDetailsLoading = true;
          this.billingService.getCreditDetailsForBilling(payload).subscribe({
            next: (response: Response<any>)=>{
              if(response.status == 200){
                selectedSponsor.creditData = response.response[0];
                this.credDetails = response.response[0];
                this.NewBillform.patchValue({
                  agentName: selectedSponsor?.agentName,
                  sponsorName: selectedSponsor?.sponsorName
                });
                this.patientDetails.sponsor = selectedSponsor;
                this.patientDetails.sponsorId = selectedSponsor?.sponsorId;
                this.patientDetails.sponsorName = selectedSponsor?.sponsorName;
              }else{
                this.toastr.error(response.message);
              }
              this.creditDetailsLoading = false;
            },error: (error: any)=>{
              this.creditDetailsLoading = false;
            }
          });
        }
      }else{
        this.toastr.warning('Please select a consultation to select an Insurance.');
        this.gridApi.forEachNode(node=>{
          node.setSelected(false);
        });
      }
    }else{
      this.NewBillform.patchValue({
        agentName: '',
        sponsorName: ''
      });
      this.patientDetails.sponsor = null;
      this.patientDetails.sponsorId = 0;
      this.patientDetails.sponsorName = '';
    }
  }

  editCredit(data){
    let input={
      patientId: this.patientDetails.patientId,
      creditId:data.creditId,
      patientName: this.patientDetails.patient,
      regNo: this.patientDetails.regNo,
    }
    sessionStorage.setItem('bill-patient-regNo', JSON.stringify({
      regNo: this.patientDetails.regNo,
      consulatantID: this.NewBillform.value.consultantId,
      consulatationId: this.NewBillform.value.consultationId
    }));
    this.router.navigate(['/OpenCredit'], { state: { editData: input } });
  }

  openCredit(){
    if(this.patientDetails?.patientId){
      console.log('this.patientDetails.consultationId', this.patientDetails.consultationId);
      let input={
        patientId: this.patientDetails.patientId,
        creditId: '',
        patientName: this.patientDetails.patient,
        credConsultationId: this.patientDetails.consultationId,
        regNo: this.patientDetails.regNo,
      }
      sessionStorage.setItem('bill-patient-regNo', JSON.stringify({
        regNo: this.patientDetails.regNo,
        consulatantID: this.NewBillform.value.consultantId,
        consulatationId: this.NewBillform.value.consultationId,
        rGroupId: this.NewBillform.value.rGroupId,
        extLabId: this.NewBillform.value.extLabId
      }));

      this.router.navigate(['/OpenCredit'], { state: { editData: input } });
    }else{
      this.toastr.warning('Please select a patient to continue..')
    }
  }

  getExternalLab(){
    let payload = {
      LabId: 0,
      ShowAll:0,
      Branchid: this.globals.HospitalId
    }
    this.billingService.getExternalLabs(payload).subscribe(res=>{
      if(res.status == 200){
        this.externalLabs = res.response
      }
    })
  }

}
