import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { ReportsService } from '../services/reports.service';
import { HospitalsService } from '../../client/services/hospitals.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';

@Component({
  selector: 'app-appointment-report',
  templateUrl: './appointment-report.component.html',
  styleUrls: ['./appointment-report.component.scss']
})
export class AppointmentReportComponent implements OnInit {


  form: FormGroup; // form variable
  subscription: Subscription;
  globals: globalvars = new globalvars();
  consultants:any[]=[];
  GetConsultantsData: any;
  isPdfLoaded:boolean=false;
  // private pdf: PDFDocumentProxy;
  isLoading=false;
  src :any= '';
  departments: any;
  private baseUrl = "";

  constructor(
    public  datepipe: DatePipe,
    private sharedservice: SharedService,
    private ReportsService:ReportsService,
    private hospitalsService: HospitalsService,
    private sanitizer: DomSanitizer,
    private configurationManager: ConfigurationManager,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });
    this.baseUrl = (this.configurationManager as any).baseUrl;

   }

  ngOnInit(): void {
    this.form=new FormGroup({
      AppFromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      AppToDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ConsultantId: new FormControl(0),
      DeptId: new FormControl(0),
      BranchId: new FormControl(this.globals.HospitalId),
    });

    this.GetDepartments(); //on load api function getting departments

  }
  GetDepartments() {
    let showall = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showall).subscribe((res) => {
      this.departments = res.response;
    });
  }
  loadConsultant(DepartmentId: number) {
    let departmentId = Number(this.form.get("DeptId").value);
    if(departmentId>0){
      this.hospitalsService.GetConsultant(departmentId).subscribe((res) => {
      this.consultants = res.response;
    });
    }
    else{
      this.consultants = [];
    }
  }

  generateReport(){
    this.isLoading=true;
    let input=this.form.getRawValue();

    if (typeof (input.AppFromDate) == "object") {
      let dobConverted = this.datepipe.transform(input.AppFromDate, 'dd-MM-yyyy');
      input.AppFromDate = dobConverted;
    }
    if (typeof (input.AppToDate) == "object") {
      let dobConverted = this.datepipe.transform(input.AppToDate, 'dd-MM-yyyy');
      input.AppToDate = dobConverted;
    }


    this.ReportsService.GetAppointmentReport(input).subscribe((res) => {
      this.isLoading=false;
      if(res.status==200){
        let response=res.response;
        this.src=response[0].reportPath;
        this.src=this.baseUrl+this.src;
        this.src=this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
        this.isPdfLoaded=true;
      }
     });
  }


}
