import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { SponsorFormConfig } from '../../client/services/sponsorFormConfigService';
import { Location } from '@angular/common';
@Component({
  selector: 'app-print-sponser-form',
  templateUrl: './print-sponser-form.component.html',
  styleUrls: ['./print-sponser-form.component.scss']
})
export class PrintSponserFormComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
  printInput:any;
  src :any= '';
  private baseUrl = "";
  htmlContent="....";

  constructor(
    private router: Router,
    private SponsorFormConfig: SponsorFormConfig,
    private configurationManager: ConfigurationManager,
    private _location: Location
  ) {


    if(this.router.getCurrentNavigation().extras.state){
      this.printInput=this.router.getCurrentNavigation().extras.state.input;

    }

    this.baseUrl = (this.configurationManager as any).baseUrl;
   }

  ngOnInit(): void {
    if(this.printInput){
      this.getPrintDetails();
    }
  }
  getPrintDetails(){
        this.SponsorFormConfig.PrintSponsorForm(this.printInput).subscribe(
      (res) => {
        if(res.status==200){
          let response=res.response;
          this.src=response[0].formReturnPath;
          this.src=this.baseUrl+this.src;
        //  window.open(this.src, "_blank");

            var xmlHttp = null;

            xmlHttp = new XMLHttpRequest();
            xmlHttp.open( "GET", this.src, false );
            xmlHttp.send( null );
            this.htmlContent=xmlHttp.responseText;
           // console.log(this.htmlContent);
       }

      });

  }
  printPage(){
    window.print();
  }

  goback() {
    this._location.back();
  }
}
