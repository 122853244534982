import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() header: any
  @Input() content: any
  @Input() confirmButtonTxt:any;
  @Input() cancelButtonTxt: any;
  @Output() confirmEmitter=new EventEmitter()
  isLoading: any;

  constructor(
    private modalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
  }

  confirm(){
    this.close()
    this.confirmEmitter.emit({ status: true });
  }

  close() {
    this.modalRef.hide();
  }

}
