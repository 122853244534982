import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SharedService } from '../../../../shared/shared.service';
import { Response } from '../../../../_Models/Response';
import { BillingService } from '../../services/billing.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pending-items',
  templateUrl: './pending-items.component.html',
  styleUrls: ['./pending-items.component.scss']
})
export class PendingItemsComponent implements OnInit {

  @Output() pendingItemsEmitter= new EventEmitter();

  private gridApi: GridApi;

  @Input() patientData: any;

  public PendingItems: any[] = [

    {
      headerName: "Post Date",
      minWidth:100,
      field: "postDate",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      checkboxSelection: true,
      flex: 2,
    },
    {
      headerName: "Item Name",
      minWidth:100,
      field: "itemName",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Nos",
      minWidth:100,
      field: "nos",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },

    {
      headerName: "Location",
      minWidth:100,
      field: "location",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "UserName",
      minWidth:100,
      field: "user",
      enableRowGroup: false,
      resizable: true,
      sortable: true,
      flex: 2,
    },
  ];

  private subscription: Subscription;
  private globals: globalvars = new globalvars();

  public loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
  public noRowsTemplate = `"<span">no rows to show</span>"`;
  public pendingItemsData: any[] = [];

  constructor(public modalRef: BsModalRef,
              private billingService: BillingService,
              private sharedservice: SharedService,
              private toastrService: ToastrService,
              private datepipe: DatePipe,
              ) { }

  ngOnInit(): void {
  }

  onGridReady(params){
    this.gridApi = params.api; 
    this.getUnbilledItems();
  }

  getUnbilledItems(){
    let payload = {
      PatientId: this.patientData?.PatientId ,
      ConsultantId: 0 ,
      External: Number(this.patientData?.External),
      BranchId: this.patientData?.BranchId,
      ShowAll: 0,
      CreditId: this.patientData?.CreditId,
      Category: this.patientData.billCategory,
      ConsultationId: 0,
      CurrentDate: this.datepipe.transform(new Date(), "dd-MM-yyyy"),
    }
    this.gridApi.showLoadingOverlay();
    this.billingService.getUnbilledItesm(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.pendingItemsData = response.response;
          this.pendingItemsData = this.pendingItemsData.filter(item=> {
            if(item.itemName != 'REGISTRATION FEE_1'){
              return item
            }else if(item.insCovered ? item.sponsoredRate > 0 : item.rate > 0){
              return item
            }
          })
        }else{
          this.toastrService.error(response.message);
        }
        this.gridApi.hideOverlay();
      }
    })
    
  }

  onAddPendingItems(){
    let pendingItems = this.gridApi.getSelectedRows();
    this.pendingItemsEmitter.emit({data: pendingItems});
    this.modalRef.hide();
  }

}
