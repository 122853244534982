import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonValidationComponent } from '../../../../shared/common-validation/common-validation.component';
import { SearchPatientModalComponent } from '../../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { IntegrationsService } from '../../integrations.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from '../../../client/services/patient.service';
import { PatientList } from '../../../client/interfaces/PatientList';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SharedService } from '../../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../../settings/services/settings.service';

@Component({
  selector: 'app-merge-patient-modal',
  templateUrl: './merge-patient-modal.component.html',
  styleUrls: ['./merge-patient-modal.component.scss']
})
export class MergePatientModalComponent implements OnInit {

  @Input() functionPermissionData;
  public statusEmitter: EventEmitter<any> = new EventEmitter();
  mergeForm: FormGroup;
  public validator = new CommonValidationComponent();
  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;
  displayBasic: boolean;
  PriorPatientId = 0;
  PatientId = 0;
  priorFlag = false;
  retainFlag = false;
  patients: PatientList[] = [];
  subscription: Subscription;
  globals: globalvars = new globalvars();
  healthPlatform: any;
  public mergeLoading: boolean = false;
  public sendMergeLoading: boolean = false;

  constructor(
    private modalRef: BsModalRef,
    private integrationsService: IntegrationsService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private patientService: PatientService,
    private sharedservice: SharedService,
    private settingsService:SettingsService
    ) { 
      this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
        if (this.sharedservice.checkglobals(glob)) {
          this.globals = glob;
        }
      });
    }

  ngOnInit(): void {
    this.initForm();
    this.getApplicationSettings()
    this.displayBasic = false;
  }

  initForm() {
    this.mergeForm = new FormGroup({
      priorRegNo: new FormControl('', [Validators.required]),
      regNoToRetain: new FormControl('', [Validators.required]),
      priorName: new FormControl(''),
      nameRetain: new FormControl(''),
    })
  }

  close(){
    this.statusEmitter.emit({
      status: true,
    });
    this.modalRef.hide();
  }

  showBasicDialog(flag) {
    if(flag == 1) {
      this.priorFlag = true;
    } else if(flag == 2) {
      this.retainFlag = true;
    }
    this.modaltemp.open();
    this.displayBasic = true;

  }

  showBasicDialogSearch($event) {
    this.displayBasic = $event;
  }

  searchevent(patient: any) {
    if(this.priorFlag == true) {
    this.mergeForm.patchValue({ priorRegNo: patient.data.regNo });
    this.mergeForm.patchValue({ priorName: patient.data.patient });
    this.PriorPatientId = patient.data.patientId;
    this.priorFlag = false;
    }else if(this.retainFlag == true){
      this.mergeForm.patchValue({ regNoToRetain: patient.data.regNo });
      this.mergeForm.patchValue({ nameRetain: patient.data.patient });
      this.PatientId = patient.data.patientId;
      this.retainFlag = false;
    }
    this.displayBasic = false;
  }

  merge() {
    if (this.mergeForm.controls.priorRegNo.value !== '' && this.mergeForm.controls.regNoToRetain.value !== '') {
      let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
      let payload = {
        MergeId: 0,
        PriorPatientId: this.PriorPatientId,
        PatientId: this.PatientId,
        EntryDate: currentDate
      }
      this.mergeLoading = true;
      this.integrationsService.MergePatient(payload).subscribe((res) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          this.mergeLoading = false;
        }
        this.mergeLoading = false;
      })
    } else {
      this.toastr.error('Please fill both fields');
    }
  }

  sendPatientMerge() {
    if (this.healthPlatform.settValue === "Nabidh") {
    const foundElement = this.functionPermissionData.find(x => x.id === 568)
    if (foundElement.id == 568 && foundElement.isAllowed == true) {
      if (this.mergeForm.controls.priorRegNo.value !== '' && this.mergeForm.controls.regNoToRetain.value !== '') {
        let currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
        let payload = {
          EventId: 13,
          Consultationid: 0,
          PatientId: this.PatientId,
          EntryDate: currentDate,
          BranchId : this.globals.HospitalId
        }
        this.sendMergeLoading = true;
        this.patientService.SendDetailstoNabith(payload).subscribe((res) => {
          this.toastr.info('Response from Nabith:' + res);
          this.sendMergeLoading = false;
        })
      } else {
        this.toastr.error('Please fill both fields');
        this.sendMergeLoading = false;
      }
    } else {
      this.toastr.warning('No permission to send data to nabith');
    }
  }
  }

  getApplicationSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }

    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: any)=>{
        if(response.status == 200){
          this.healthPlatform=response.response.find(item => item.settId == 25)
        }
      }
    })
  }

}
