import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpHeaders
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Observable } from 'rxjs';
import { catchError } from "rxjs/internal/operators/catchError";
import { RouterModule } from '@angular/router';
import { SharedService } from "./shared/shared.service";
import { globalvars } from "./shared/models/GlobalVars";
@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  subscription: Subscription;
  globals: globalvars = new globalvars();
  locationId:any
  constructor(private router: Router,
    private sharedservice: SharedService,
    ){
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.locationId=this.globals?.Location?.locationId;
      }
    });

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.toLocaleLowerCase().includes('localhost:80/papservice') || req.url.toLocaleLowerCase().includes('version')){
      return next.handle(req);
    }else{
      // Clone the request to add the new header
      const GetToken=localStorage.getItem('sec_token');
      const PassToken="Bearer "+GetToken;
      const GetUserId=localStorage.getItem('user_id');
      const PassUserId="Bearer "+GetUserId;
      const GetLocationId=this.globals.Location?.locationId ? this.globals.Location.locationId : "";
      const PassLocationId="Bearer "+GetLocationId;

      let headers = new HttpHeaders();
   headers = headers.append('Authorization', PassToken);
   headers = headers.append('UserId',PassUserId);
   headers = headers.append('LocationId',PassLocationId);

      let clonedRequest = req.clone({ headers});

   // Pass the cloned request instead of the original request to the next handle
     //  return next.handle(clonedRequest);

     return next.handle(clonedRequest)
       .pipe(catchError((err: any) => {
           if (err instanceof HttpErrorResponse) {
               if (err.status === 401) {
                 if (this.router.url !== '/auth/Signin') {
                       alert("Login token expired! Please login again")
                        this.router.navigate(['/logout']);
                   }
                   //Un authrizd

               }
           }

         return new Observable<HttpEvent<any>>();
       }));

 }
    }
    
}
