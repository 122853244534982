import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';

@Component({
  selector: 'app-view-static-patient-consent',
  templateUrl: './view-static-patient-consent.component.html',
  styleUrls: ['./view-static-patient-consent.component.scss']
})
export class ViewStaticPatientConsentComponent implements OnInit {

  documentPath: any;
  @Input() consentData;
  private baseUrl: string = '';

  constructor(private activeModal: BsModalRef,
    private sanitizer: DomSanitizer,
    private configurationManager: ConfigurationManager,
    ) { 
      this.baseUrl = (this.configurationManager as any).baseUrl;
    }

  ngOnInit(): void {
    this.documentPath = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl+this.consentData.staticPatConsentLoc);
  }

  close(){
    this.activeModal.hide();
  }

}
