import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editConsultationComponent',
  templateUrl: './editconsultation.component.html',
  styleUrls: ['./editconsultation.component.scss']
})
export class editConsultationComponent implements OnInit {
  opened = false;
  constructor() { }

  ngOnInit(): void {
  }
 
}
