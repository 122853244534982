import { Component, OnInit, TemplateRef, ViewChild ,Input, ViewChildren, QueryList, ElementRef, OnDestroy} from '@angular/core';
import { EMRPatientInfo } from '../interfaces/EMRPatientInfo';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EMRService } from '../../emr/services/emr.service';
import { ToastrService } from "ngx-toastr";
import { globalvars } from "../../../shared/models/GlobalVars";
import { noop, Observable, Observer, of, Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { map, switchMap, tap } from 'rxjs/operators';
import { Response } from '../../../_Models/Response';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { HttpClient } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { SignPopupComponent } from '../modals/sign-popup/sign-popup.component';
import { HealthSummaryModalComponent } from '../../treatment/modals/health-summary-modal/health-summary-modal.component';
import { PrintAsoapComponent } from '../../client/frontoffice/modals/print-asoap/print-asoap.component';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
import { ConfirmModalComponent } from '../../../shared/modals/confirm-modal/confirm-modal.component';
import { AddDiseaseModalComponent } from '../../client/consultantdesk/modals/add-disease-modal/add-disease-modal.component';

interface icds{
  labelId:number;
  labelDesc:string;
  labelCode:string;
  catgDesc:string;
}

@Component({
  selector: 'app-general-narrative-diagonosis',
  templateUrl: './general-narrative-diagonosis.component.html',
  styleUrls: ['./general-narrative-diagonosis.component.scss']
})
export class GeneralNarrativeDiagonosisComponent implements OnInit, OnDestroy {
  @Input() IsViewtab;
  EMRPatientInfo:any;

  icdList:any[]=[];
  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;
  SelectedIcdList=new Array;
  NarrativeDiagonosisModalVal:any;
  form: FormGroup; // form variable
  PrevHistory:any[]=[];
  search?: string;
  form1: FormGroup; // form variable

  modalRef?: BsModalRef;
  @ViewChild("template") modaltemp: any;
  IsDisabled: boolean = true;
  suggestions$?: Observable<icds[]>;
  private baseUrl:string = "";
  errorMessage?: string;

  public symptomsObservable$?: Observable<any[]>;
  public signsObservable$?: Observable<any[]>;

  public dropdownSettings: IDropdownSettings = {};
  public signDropdownSettings: IDropdownSettings = {};
  public allDiseases: any[] = [];
  public allSymptoms: any[] = [];
  public allSigns: any[] = [];
  public signsData: any[] = [];
  public selectedSigns: any;
  public symptomData: any;
  public selectedSymptoms: any;
  modalRefAlert?: BsModalRef;
  public dataLoading: boolean = false;
  printForm : FormGroup;
  FormValueChangeChecker:boolean=false;

  @ViewChildren('since') since:  QueryList<ElementRef>;
  @ViewChildren('sinceSign') sinceSign:  QueryList<ElementRef>;
  addDiseaseCount: number=0;
  constructor(
    private EMRService:EMRService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private datePipe: DatePipe,private fb:FormBuilder,
    
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    this.EMRPatientInfo=this.EMRService.GetEMRPatientInfo();
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  ngOnInit(): void {

    if (this.IsViewtab == true) {
      this.IsDisabled = false;
    } else if (this.IsViewtab == "undefined") {
      this.IsDisabled = true;
    }
    this.searchSymptoms();
    this.searchSigns();
      /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
      this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
        observer.next(this.search);
      }).pipe(
        switchMap((query: string) => {
          if (query) {

            return this.http.post<Response<icds[]>>(
              `${this.baseUrl}api/MasterData/GetICDLabel`, {
              "LabelDesc": query,
              "LabelId": 0,
              "ShowAll": 0,
              "BranchId": this.BranchesId

            }).pipe(
              map((data) => data && data.response || []),
              tap(() => noop, err => {
                this.errorMessage = err && err.message || 'Something goes wrong';
              })
            );
          }

          return of([]);
        })
      );
       /*****************************END  DROP DOWN AUTO COMPLETE****************** */


    //this.GetICDLabels();
  
    this.form= new FormGroup({
      nid: new FormControl(0),
      narrativeDiagnosis:new FormControl(""),
      ObservationNote: new FormControl(""),
      icdLabelList: new FormArray([]),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
      patSymptomList: new FormArray([]),
      PatientId: new FormControl(this.EMRPatientInfo.PatientId),
      ConsultationId: new FormControl(this.EMRPatientInfo.ConsultationId),
      EntryDate: new FormControl(''),
      patDiseaseList: new FormControl([]),
      patSignList: new FormArray([])
    });

    // this.form1 = new FormGroup({
    //   SelectedIcdList: new FormArray([]),
    //   visitId: new FormControl(this.EMRPatientInfo.VisitId),
    //   userId: new FormControl(Number(localStorage.getItem("user_id"))),
    // });
    // this.getAllSigns();
    this.GetPrevNarrativeDiagonosis();
    this.GetNarrativeDiagonosis();


    this.dropdownSettings = {
      singleSelection: false,
      idField: "diseaseId",
      textField: "diseaseDesc",
      enableCheckAll: false,
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    this.createForm()
  }
  createForm(){
    this.printForm =  this.fb.group({
      printVal: ['']
    })
  }
  searchSymptoms(){
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.symptomsObservable$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.selectedSymptoms);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          return this.http.post<Response<icds[]>>(
            `${this.baseUrl}api/MasterData/GetSymptomEMR`, {
              SymptomId: 0,
              ShowAll: 0,
              BranchId: this.globals.HospitalId,
              SymptomDesc: this.selectedSymptoms

          }).pipe(
            map((data) => data && data.response || []),
            tap(() => noop, err => {
              this.errorMessage = err && err.message || 'Something goes wrong';
            })
          );
        }

        return of([]);
      })
    );
     /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  searchSigns(){
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.signsObservable$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.selectedSigns);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          return this.http.post<Response<icds[]>>(
            `${this.baseUrl}api/MasterData/GetSign`, {
              Id: 0 ,
              ShowAll: 1,
              BranchId: this.globals.HospitalId,
              SignDesc: this.selectedSigns

          }).pipe(
            map((data) => data && data.response || []),
            tap(() => noop, err => {
              this.errorMessage = err && err.message || 'Something goes wrong';
            })
          );
        }

        return of([]);
      })
    );
     /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  getConsultantDiseases(){
    let payload = {
      ConsultantId: Number(localStorage.getItem('Id'))
    }
    
    this.EMRService.getDiseases(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.allDiseases = response.response
        }else{
          this.toastr.error(response.errorMessage.message);
        }   

      },error: (error: any)=>{
        this.toastr.error('Something wents  wrong, Try again..!');

      }
    })
  }


  onSelectDisease(disease: any, isSelected: boolean){
    let payload = {
      diseasId: disease.diseaseId
    }
    this.EMRService.getDetailsOfDisease(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          const data = response.response[0];
          if(isSelected){
            this.addMapFormGroup(data.symptomList);
            this.addSignFormGroup(data.signList);
            _.forEach(data.icdLabelList, (icd: any)=>{
              this.LoadSelectedICD(icd)
            })
          }else{
            _.forEach(data.symptomList, (symptom: any)=>{
              this.removeSymptomFromList(symptom)
            })

            _.forEach(data.signList, (sign: any)=>{
              this.removeSignFromList(sign)
            })

            _.forEach(data.icdLabelList, (icd: any)=>{
              // this.RemoveFromIcdList(icd.labelId)
              this.RemoveIcdOnDiseaseDelete(icd.labelId);
            })
          }
        }else{
          this.toastr.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastr.error('Something wents  wrong, Try again..!')
      }
    })


  }

  RemoveIcdOnDiseaseDelete(labelId)
  {
    const index = this.SelectedIcdList.findIndex(x => x.labelId === labelId);
   this.SelectedIcdList.splice(index, 1);
   this.FormValueChangeChecker=true;
  }

  onSelectSymptom(symptom){
    this.addMapFormGroup(symptom);
    this.FormValueChangeChecker = true;
    // setTimeout(()=>{
    //   this.since.last.nativeElement.focus()
    // },300)
  }

  public addMapFormGroup(data: any = [], saved = 0) {
    const symptoms = this.form.get("patSymptomList") as FormArray;
    if (data != null) {
      const values = Object.keys(data).map((key) => data[key]);
      if (data.length > 0) {
        data.forEach((element, i) => {
          let filteredArray = symptoms.value.filter(value => value.symptomId == element.symptomId);
          if (filteredArray.length == 0) {
            symptoms.insert(0, this.createListGroup(element, saved));
          }
          else {
            this.toastr.error("Failure: " + "Symptom Already added");
          }
        });
      } else if (values.length > 0) {
        let filteredArray = symptoms.value.filter(
          (value) => value.symptomId == data.symptomId);

        if (filteredArray.length == 0) {
          symptoms.insert(
            symptoms.length,
            this.createListGroup(data, saved)
          );
        } else {
          //this.toastr.error("Failure: " + "Service Items Already added");
        }
      }
    }
    this.selectedSymptoms = "";
  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    return new FormGroup({
      entryId: new FormControl(data['entryId'] ? data['entryId'] : 0),
      symptomId: new FormControl(data["symptomId"]),
      symptomDesc: new FormControl(data["symptomDesc"]),
      status: new FormControl(data['status'] ? data['status'] : 'A'),
      duration: new FormControl(data['duration'] ? data['duration'] : '1'),
      since: new FormControl(data['since'] ? data['since'] : '1')
    });
  }

  removeSymptomFromList(symptom: any) {
    var Value = new FormArray([]);
    Value = this.form.get("patSymptomList") as FormArray;
    let index = _.findIndex(Value.value, (data: any)=>{
      return symptom.symptomId == data.symptomId
    });
    if(index>= 0){
      Value.removeAt(index);
      this.FormValueChangeChecker = true;
    }
    
  }


  onSelectSign(sign){
    this.addSignFormGroup(sign);
    this.FormValueChangeChecker = true;
    // setTimeout(()=>{
    //   this.sinceSign.last.nativeElement.focus()
    // },300)
  }

  public addSignFormGroup(data: any = [], saved = 0) {
    const signs = this.form.get("patSignList") as FormArray;
    if (data != null) {
      const values = Object.keys(data).map((key) => data[key]);
      if (data.length > 0) {
        data.forEach((element, i) => {
          let filteredArray = signs.value.filter(value => value.signId == element.signId);
          if (filteredArray.length == 0) {
            signs.insert(0, this.createSignListGroup(element, saved));
          }
        });
      } else if (values.length > 0) {
        let filteredArray = signs.value.filter(
          (value) => value.signId == data.signId);

        if (filteredArray.length == 0) {
          signs.insert(
            signs.length,
            this.createSignListGroup(data, saved)
          );
        }
      }
    }
    this.selectedSigns = "";
  }

  private createSignListGroup(data: any[] = [], saved = 0): FormGroup {
    return new FormGroup({
      entryId: new FormControl(0),
      signId: new FormControl(data["signId"]),
      signDesc: new FormControl(data["signDesc"]),
      status: new FormControl(data['status'] ? data['status'] : 'A'),
      duration: new FormControl(data['duration'] ? data['duration'] : '1'),
      since: new FormControl(data['since'] ? data['since'] : '1'),
      entryDate: new FormControl(this.datePipe.transform(new Date(), 'dd-MM-yyyy'))
    });
  }

  removeSignFromList(symptom: any) {
    var Value = new FormArray([]);
    Value = this.form.get("patSignList") as FormArray;
    let index = _.findIndex(Value.value, (data: any)=>{
      return symptom.signId == data.signId
    });
    if(index>= 0){
      Value.removeAt(index);
      this.FormValueChangeChecker = true;
    }
    
  }
  
  SaveNarrativeDiagonosis()
  {
debugger
    if(
      this. EMRPatientInfo.CrudType!=3 &&
      this.EMRPatientInfo.VisitId>0 && this.FormValueChangeChecker)
    {
      let formValue=this.form.getRawValue();
      let icdList = this.SelectedIcdList.map((icd: any)=>{
        let onsetDate: string;
        if (typeof icd.onsetDate === 'string') {
          onsetDate = icd.onsetDate;
        } else if (icd.onsetDate instanceof Date) {
          onsetDate = this.datePipe.transform(icd.onsetDate, 'dd-MM-yyyy');
        } else {
          onsetDate = '';
        }
        return{
          ...icd,
          icdType: icd.icdType ? Number(icd.icdType) : 0,
          onsetDate:onsetDate

        }
      })
      formValue.icdLabelList = icdList;
      formValue.userId=Number(localStorage.getItem('user_id'));

      _.forEach(formValue.patDiseaseList, (diseae: any)=>{
        diseae.entryId = 0,
        diseae.entryDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy'),
        diseae.diseaseId = Number(diseae.diseaseId)
      })
      formValue.patDiseaseList = formValue.patDiseaseList?.length > 0 ? formValue.patDiseaseList : [];

      _.forEach(formValue.patSymptomList, (symptom: any)=>{
        symptom.since = Number(symptom.since);
        symptom.duration = symptom.duration.toString();
      });

      _.forEach(formValue.patSignList, (sign: any)=>{
        sign.since = Number(sign.since);
        sign.duration = sign.duration.toString();
      });

      formValue.EntryDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');

      const payload = {
        nid: formValue.nid,
        narrativeDiagnosis: formValue.narrativeDiagnosis,
        ObservationNote: formValue.ObservationNote,
        IcdLabelList: formValue.icdLabelList,
        visitId: formValue.visitId,
        userId: formValue.userId,
        PatSymptomList: formValue.patSymptomList,
        PatientId: formValue.PatientId,
        ConsultationId: formValue.ConsultationId,
        EntryDate: formValue.EntryDate,
        PatDiseaseList: formValue.patDiseaseList,
        PatSignList: formValue.patSignList
      }

      this.EMRService.SaveNarrativeDiagnosis(
        payload
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastr.success("Success: " + "Narrative diagnosis details Saved Successfully...");
            this.FormValueChangeChecker = false;
          }
          else{
            Swal.fire(
              'Error!',
              'Some unexpected error occures! try again later.',
              'error'
            )
          }
        });

    }
    else{
      if(!this.FormValueChangeChecker){
       // this.toastr.warning("Info: " + "No changes detected in the entry");
      }
  }
  }

  GetICDLabels() {

    let input={
      "LabelId": 0,
      "ShowAll": 0,
      "BranchId": this.BranchesId
      };
    this.EMRService.GetICDLabel(input).subscribe((res) => {
      this.icdList = res.response;
      this.icdList.forEach((a) => {
        (a.id = a.labelId), (a.text = a.labelDesc);
      });

    });
  }

  LoadSelectedICD(item)
  {

    let itemId=item.labelId;
    if(itemId>0){

      //let item = this.icdList.filter(x => x.labelId == itemId)[0];
        if(item){
          let exist=0;
          if(this.SelectedIcdList)
           exist=this.SelectedIcdList.filter(x => x.labelId == item.labelId)[0];

          if(exist){
            this.toastr.error("Failure: " + "ICD Already added");
          }
          else{
           let itemArray={
            labelId: item.labelId,
            labelDesc: item.labelDesc,
            contradictoryICDCode: item.contradictoryICDCode == "" ? item.labelCode : item.contradictoryICDCode,
            labelCode: item.labelCode,
            catgDesc: item.catgDesc,
            icdType:  2,
            onsetDate:item.onsetDate
            // icdType: this.SelectedIcdList.length <= 0 ? 1 : 2            
           };

           let icdLabel=[];
           if(this.SelectedIcdList.length){
             this.SelectedIcdList.forEach((res:any)=>{
               if(res.labelCode !== item.contradictoryICDCode){
                 icdLabel.push(true)
               } else {
                 this.toastr.error(`The diagnosis code (${item.labelCode}) is not allowed with the diagnosis code (${item.contradictoryICDCode})`);
               }
             })
             console.log(this.SelectedIcdList.length , icdLabel.length)
             if(this.SelectedIcdList.length == icdLabel.length){
               this.SelectedIcdList.push(itemArray);
             }
           } else {
             this.SelectedIcdList.push(itemArray);
           }
          if(this.SelectedIcdList){
            this.FormValueChangeChecker=true;
          }
          this.search="";
          }

        }
    }
 }

 RemoveFromIcdList(index)
 {
  this.SelectedIcdList.splice(index, 1);
  this.FormValueChangeChecker=true;
 }

 GetNarrativeDiagonosis()
 {
  let input={
    "VisitId":this.EMRPatientInfo.VisitId,
    "PatientId":this.EMRPatientInfo.PatientId,
    "ShowAll":1,
    ConsultationId: this.EMRPatientInfo.ConsultationId
  };

  this.EMRService.GetNarrativeDiagonosis(input).subscribe(
    (res) => {
      if(res.status==200)
      {
        let response=res.response[0];
        if(response){
        this.form.patchValue(response);
        this.form.patchValue({ObservationNote: response.observationNote});
        this.addMapFormGroup(response.patSymptomList ? response.patSymptomList : []);
        this.addSignFormGroup(response.patSignList ? response.patSignList : []);
        if(response.icdLabelList){
          this.SelectedIcdList = response.icdLabelList.map((icd: any)=>{
            return{
              ...icd,
              icdType: icd.icdType == 0 ? '' : icd.icdType,
              onsetDate:icd.onsetDate
            }
          })

        }
      }
      this.getConsultantDiseases();
        this.form.valueChanges.subscribe(x => {
          this.FormValueChangeChecker=true;
         })
        }
    });
    this.getConsultantDiseases();
 }

 GetPrevNarrativeDiagonosis()
 {
  let input={
    "VisitId":this.EMRPatientInfo.VisitId,
    "PatientId":this.EMRPatientInfo.PatientId,
    "ShowAll":0,
    ConsultationId: this.EMRPatientInfo.ConsultationId
  };

  this.EMRService.GetNarrativeDiagonosis(input).subscribe(
    (res) => {
      if(res.status==200)
      {
        let response=res.response
        this.PrevHistory=response;
      }
    });
 }

 openModal(VisitId) {

  let input={
    "VisitId":VisitId,
    "PatientId":this.EMRPatientInfo.PatientId,
    "ShowAll":1,
    ConsultationId: this.EMRPatientInfo.ConsultationId
  };
  this.EMRService.GetNarrativeDiagonosis(input).subscribe(
    (res) => {
      if(res.status==200)
      {
        let response=res.response[0];
        this.NarrativeDiagonosisModalVal=response;
        this.modalRef = this.modalService.show(
          this.modaltemp,
          {class: 'modal-lg modal-custom-style' }
        );
      }
    });


}

scroll(el: HTMLElement) {
  el.scrollIntoView({ behavior: 'smooth', block: 'center' });
}


markHook(info) {

  let hookString = "";
  hookString = "icd: " + info.labelCode + " ,  " + info.labelDesc;
  if (this.EMRPatientInfo.PatientId > 0) {

    let formdata = {
      hookid: 0,
      hookDesc: hookString,
      patientId: Number(this.EMRPatientInfo.PatientId),
      branchId: this.globals.HospitalId,
      IsDisplayed: true,
      IsDeleted: 0
    }

    this.EMRService.SaveHook(formdata).subscribe((res) => {

      if (res.status == 200 && res.message == "Success") {
        this.toastr.success(info.labelCode + " Successfully added to hook list");
      }
      else if (res.status == 200) {
        this.toastr.error("Failure: " + res.message);
      }
      else {
        this.toastr.error("Some unexpected error occures! try again later.");
      }
    });


  }

  
}
// show popup
signPopup(){
  let initialState = {
          // EMRPatientInfo: this.EMRPatientInfo,
          branchId: this.globals.HospitalId,
        };
        let modalConfig: ModalOptions = {
          backdrop: true,
          ignoreBackdropClick: true,
          class: "modal-dialog-scrollable",
        };
        const copyModalRef = this.modalService.show(
          SignPopupComponent,
          Object.assign({ class: "modal-dialog-centered " }, modalConfig, {
            class: "modal-md",
            initialState,
          })
        );
        copyModalRef.content.copyEmitter.subscribe((res) => {
          console.log(res)
          if (res.status) {
            if(res.sign) {
              this.addSignFormGroup(res.data);
            } else {
              this.addMapFormGroup(res.data);
            }
            this.FormValueChangeChecker = true;
          }
        });
}

showHealthSummary(data: any){
  // const foundElement = this.functionPermissionData.find(x => x.id === 484)
  // if (foundElement.id == 484 && foundElement.isAllowed == true) {
  let config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: true,
  }
  let initialState = {
    patientId: this.EMRPatientInfo.PatientId,
    branchId: this.globals.HospitalId
  }
  this.close()
  const modalRef = this.modalService.show(HealthSummaryModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }));
  // } else {
    // this.toastr.warning("You are not allowed");
  // }
}
printAsoap(data){
  let asoapPatInfo = JSON.parse(localStorage.getItem('asoapPatInfo'));
  // const foundElement = this.functionPermissionData.find(x => x.id === 183)
  // if (foundElement.id == 183 && foundElement.isAllowed == true) {
  let initialState = {
    consultationId: this.EMRPatientInfo.ConsultationId,
    branchId: this.globals.HospitalId,
    sponsorId: this.EMRPatientInfo.SponsorId,
    telerikName:asoapPatInfo.telerikName
  }
  console.log(initialState,this.EMRPatientInfo)
  let config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: true,
  }
  this.close()
  const modalRef = this.modalService.show(PrintAsoapComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
  // } else {
    // this.toastr.warning("You are not allowed");
  // }
}
treatmentDetails(data){
    let initialState = {
      modalHeading: 'Treatment Summary',
      reportName: 'TreatmentSummary.trdp',
      reportParams: {
        PatientId : this.EMRPatientInfo.PatientId,
        FromDate : (this.EMRPatientInfo.VisitStartTime.split(" ")[0]),
        ToDate: (this.EMRPatientInfo.VisitStartTime.split(" ")[0]),
        ConsultationId : this.EMRPatientInfo.ConsultationId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.close()
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
}
printReferal(data){
  let initialState = {
    modalHeading: 'Referral Letter',
    reportName: 'ReferralLetter.trdp',
    reportParams: {
      branchId: this.globals.HospitalId,
      ConsultationId : this.EMRPatientInfo.ConsultationId
    }
  }
  let config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: true,
  }
  this.close()
  const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
}
printMedicalReport(){
  let initialState = {
    modalHeading: 'Medical Report',
    reportName: 'RptMedicalReport.trdp',
    reportParams: {
      PatientId: this.EMRPatientInfo.PatientId,
      ConsultationId : this.EMRPatientInfo.ConsultationId
    }
  }
  let config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: true,
  }
  this.close()
  const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
}
openModala(template: TemplateRef<any>,i:any) {
  this.modalRefAlert = this.modalService.show(template, { class: 'modal-lg modal-custom-style' });
}
close(){
  this.modalRefAlert.hide()
  this.printForm?.reset()
}
next(){
  let val = this.printForm.value.printVal
  if(val =='RL'){
    this.printReferal('')
  } else if(val == 'TS'){
    this.treatmentDetails('')
  } else if(val == 'AP'){
    this.printAsoap('')
  } else if(val == 'HS'){
    this.showHealthSummary('')
  } else if(val =='MR'){
    this.printMedicalReport()
  }
  console.log(this.printForm)
}

onIcdTypeSelectChange(icd:any){
  this.FormValueChangeChecker=true
    if(icd.icdType == '1' && icd.labelCode.toLowerCase().startsWith("r")){
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      let initialState={
        heading:"Confirm",
        content:"Do you want to set primary",
        isReasonNeeded:false,
        yesButton:"Yes",
        noButton:"No"
      }
      const modalRef = this.modalService.show(ConfirmModalComponent, Object.assign({}, config, { class: 'modal-md', initialState }))
      modalRef.content.confirmEmitter.subscribe(res=>{
        if(res.status){

        }else{
          icd.icdType = "2"
        }
      })
    }
    if(icd.icdType == "1"){
      this.SelectedIcdList=this.SelectedIcdList.map(item => {
        if (item.labelId !== icd.labelId && item.icdType == "1") {
          return { ...item, icdType: 2 };
        }
        return item;
      });
      }
    }

    addDisease() {
  
      let initialState = {
        diseaseId: -1
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(AddDiseaseModalComponent, Object.assign({}, config, { class: 'modal-md', initialState }));
      modalRef.content.diseaseEmitter.subscribe((response: any)=>{
        if(response.status){
          this.addDiseaseCount=this.addDiseaseCount+1
        }
      })
     }

ngOnDestroy(): void {
  this.subscription.unsubscribe();
}
}
