import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TreatmentRoutingModule } from "./treatment-routing.module";
import { TreatmentComponent } from "./treatment.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ApplyTreatmentComponent } from "./apply-treatment/apply-treatment.component";
import { TreatmentDetailsComponent } from "./treatment-details/treatment-details.component";
import { SignaturePadComponent } from "./signature-pad/signature-pad.component";
import { AddVitalSignComponent } from "./add-vital-sign/add-vital-sign.component";
import { PhysiotherapyListComponent } from "./physiotherapy-list/physiotherapy-list.component";
import { ClinicalExaminationAndResultsComponent } from "./clinical-examination-and-results/clinical-examination-and-results.component";
import { SharedModule } from "../../shared/models/shared.module";
import { TreeviewModule } from "ngx-treeview";
import { AgGridModule } from "ag-grid-angular";
import { TreatmentGeneralActionComponent } from "./treatment-general-action/treatment-general-action.component";
import { TreatmentListComponent } from "./treatment-list/treatment-list.component";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { VaccinationDetailsComponent } from "./vaccination-details/vaccination-details.component";
import { ApplyVaccineModalComponent } from "./modals/apply-vaccine-modal/apply-vaccine-modal.component";
import { HealthSummaryModalComponent } from "./modals/health-summary-modal/health-summary-modal.component";
import { PhysiothearapyTreatmentComponent } from "./physiothearapy-treatment/physiothearapy-treatment.component";
import { PhysiothearapyTreatmentDetailsComponent } from "./physiothearapy-treatment-details/physiothearapy-treatment-details.component";
import { PhysiothearapySummaryComponent } from "./physiothearapy-summary/physiothearapy-summary.component";
import { LockConfirmComponent } from './modals/lock-confirm/lock-confirm.component';
import { VaccineChartComponent } from './vaccination-details/vaccine-chart/vaccine-chart.component';
import { ApplyTreatmentFromVaccineComponent } from './vaccination-details/apply-treatment-from-vaccine/apply-treatment-from-vaccine.component';
import { PatientHistoryComponent } from './modals/patient-history/patient-history.component';
import { AddRouteComponent } from './modals/add-route/add-route.component';
import { ChooseIcdModalComponent } from './modals/choose-icd-modal/choose-icd-modal.component';

@NgModule({
  declarations: [
    TreatmentComponent,
    DashboardComponent,
    ApplyTreatmentComponent,
    TreatmentDetailsComponent,
    SignaturePadComponent,
    AddVitalSignComponent,
    PhysiotherapyListComponent,
    ClinicalExaminationAndResultsComponent,
    TreatmentGeneralActionComponent,
    TreatmentListComponent,
    VaccinationDetailsComponent,
    ApplyVaccineModalComponent,
    HealthSummaryModalComponent,
    PhysiothearapyTreatmentComponent,
    PhysiothearapyTreatmentDetailsComponent,
    PhysiothearapySummaryComponent,
    LockConfirmComponent,
    VaccineChartComponent,
    ApplyTreatmentFromVaccineComponent,
    PatientHistoryComponent,
    AddRouteComponent,
    ChooseIcdModalComponent,
  ],
  imports: [
    CommonModule,
    TreatmentRoutingModule,
    SharedModule,
    TreeviewModule.forRoot(),
    AgGridModule.withComponents(),
    TypeaheadModule.forRoot(),
    AccordionModule.forRoot(),
  ],
})
export class TreatmentModule {}
