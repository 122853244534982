import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthsGuard } from '../../auths.guard';
import { AddEditClaimComponent } from './add-edit-claim/add-edit-claim.component';
import { BillDetailsComponent } from './bill-details/bill-details.component';
import { BillingSettlementComponent } from './billing-settlement.component';
import { ClaimDashboardComponent } from './claim-dashboard/claim-dashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DownloadClaimComponent } from './download-claim/download-claim.component';
import { EClaimSubmitedComponent } from './eclaim-submited/eclaim-submited.component';
import { ExternalBillComponent } from './external-bill/external-bill.component';
import { ManageBillComponent } from './manage-bill/manage-bill.component';
import { ManageClaimComponent } from './manage-claim/manage-claim.component';
import { ManageCreditComponent } from './manage-credit/manage-credit.component';
import { ManageRemittanceComponent } from './manage-remittance/manage-remittance.component';
import { NewBillComponent } from './new-bill/new-bill.component';
import { NewBillsComponent } from './new-bills/new-bills.component';
import { OpenCreditModalComponent } from './open-credit-modal/open-credit-modal.component';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { PendingBillComponent } from './pending-bill/pending-bill.component';
import { PersonRegisterComponent } from './person-register/person-register.component';
import { PriorRequestComponent } from './prior-request/prior-request.component';
import { ReceiptsListComponent } from './receipts-list/receipts-list.component';
import { TodaysBillingComponent } from './todays-billing/todays-billing.component';
import { UnbilledItemComponent } from './unbilled-item/unbilled-item.component';
import { ManageShiftComponent } from './manage-shift/manage-shift.component';
const routes: Routes = [
  {
    path: 'Billing_Dashboard',
    component: DashboardComponent,
    data: { title:'LeHealth | Billing Dashboard', pageName: 'Billing Dashboard' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'todaysbilling',
    component: TodaysBillingComponent,
    data: { title:'LeHealth | Todays Billing', pageName: 'Todays Billing' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'newbill',
    component: NewBillsComponent,
    data: { title:'LeHealth | New Bill', pageName: 'New Bill' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'newbill/:consultationId',
    component: NewBillsComponent,
    data: { title:'LeHealth | New Bill', pageName: 'New Bill' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'billitems',
    component: ManageBillComponent,
    data: { title:'LeHealth | Bill Items', pageName: 'Manage Bill' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'billitems/:transId',
    component: ManageBillComponent,
    data: { title:'LeHealth | Bill Items', pageName: 'Manage Bill' },
    canActivate : [AuthsGuard]
  },

  {
    path: 'OpenCredit',
    component: OpenCreditModalComponent,
    data: { title:'LeHealth | Open Credit', pageName: 'Open Credit' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'managecredit',
    component: ManageCreditComponent,
    data: { title:'LeHealth | Manage Credit', pageName: 'Manage Credit' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'manageclaim',
    //component: ManageClaimComponent,
    component: ClaimDashboardComponent,
    data: { title:'LeHealth | Manage Claim', pageName: 'Manage Claim' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'payment',
    component: PaymentListComponent,
    data: { title:'LeHealth | Payment List', pageName: 'Payment List' },

    canActivate : [AuthsGuard]
  },
  {
    path: 'receipt',
    component: ReceiptsListComponent,
    data: { title:'LeHealth | Receipt List', pageName: 'Receipt List' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'PriorRequest',
    component: PriorRequestComponent,
    data: { title:'LeHealth | Prior Request', pageName: 'Prior Request' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'manageshift',
    component: ManageShiftComponent,
    data: { title:'LeHealth | Manage Shift', pageName: 'Manage Shift' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'pendingbills',
    component: PendingBillComponent,
    data: { title:'LeHealth | Pending Bills', pageName: 'Pending Bills' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'billdetails',
    component: BillDetailsComponent,
    data: { title:'LeHealth | Bill Details', pageName: 'Bill Detail' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'personregister',
    component: PersonRegisterComponent,
    data: { title:'LeHealth | Person Register', pageName: 'Person Register' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'manageremittance',
    component: ManageRemittanceComponent,
    data: { title:'LeHealth |Manage Remittance', pageName: 'Manage Remittance' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'downloadclaim',
    component: DownloadClaimComponent,
    data: { title:'LeHealth |Download Claim', pageName: 'Download Claim RA' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'ecliamSubmited',
    component: EClaimSubmitedComponent,
    data: { title:'LeHealth |E-Claim Submited', pageName: 'Search E-claim Submitted' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'unbilled-items',
    component: UnbilledItemComponent,
    data: { title:'LeHealth | Unbilled Items ', pageName: 'Unbilled Items' },
    canActivate : [AuthsGuard]
  },
  {
    path: 'external-lab-bills',
    component: ExternalBillComponent,
    data: { title:'LeHealth | External Lab Bills', pageName: 'External Lab Bills' },
    canActivate : [AuthsGuard]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingSettlementRoutingModule { }
