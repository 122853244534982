import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CommonValidationComponent } from '../../../../shared/common-validation/common-validation.component';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { SearchPatientModalComponent } from '../../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { BillingService } from '../../services/billing.service';

@Component({
  selector: 'app-new-payment-modal',
  templateUrl: './new-payment-modal.component.html',
  styleUrls: ['./new-payment-modal.component.scss']
})
export class NewPaymentModalComponent implements OnInit {

  @Input() globals: globalvars;
  @Input() patientId: number;
  @Input() regNo: string = '';

  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;

  public PaymentPopupHeading: string = "Add Payment";
  public balancePopupHeading = "O/S Balance";

  public validator = new CommonValidationComponent();

  public PatientData: boolean = true;
  public Ledgerlist: boolean = true;
  public submitted: boolean = false;
  public isLoading: boolean = false;

  public GetLedgers: any[];
  public CardTypes: any[];
  public creditList: any[];
  public billBalance: any[];
  private billBasket: any[] = [];

  public isCardVisble: boolean = false;
  public isChequeateVisible: boolean = false;
  public refvisible: boolean = false;

  public form: FormGroup;

  public PatientInformation = {
    "regNo": "",
    "patient": ""
  };

  constructor(private modalRef: BsModalRef,
              private datePipe: DatePipe,
              private billingService: BillingService,
              private toastr: ToastrService) { }

  ngOnInit(): void {

    this.initPaymentForm();

    if(this.regNo){
      this.GetPatientByRegNo();
    }

    this.GetAllLedgers();
  }

  initPaymentForm(){
    this.form = new FormGroup({
      paymentNo: new FormControl(""),
      paymentId: new FormControl(0),
      FromDate: new FormControl(this.datePipe.transform(new Date().setDate(new Date().getDate()-7), 'dd-MM-yyyy')),
      ToDate: new FormControl(this.datePipe.transform(new Date(), 'dd-MM-yyyy')),
      payDate: new FormControl(this.datePipe.transform(new Date(), 'dd-MM-yyyy'), [Validators.required] ),
      payType: new FormControl('', [Validators.required]),
      headId: new FormControl(0),
      patientId: new FormControl(0),
      creditId: new FormControl(0),
      amount: new FormControl('', [Validators.required] ),
      cardId: new FormControl(0),
      soNo: new FormControl(''),
      mode: new FormControl('', [Validators.required] ),
      remarks: new FormControl(""),
      // chqNo: new FormControl("", [Validators.required] ),
      chqNo: new FormControl(""),
      chqDate: new FormControl(""),
      cardNo: new FormControl(''),
      chqBranch: new FormControl(""),
      userId: new FormControl(0),
      status: new FormControl(""),
      locationId: new FormControl(this.globals.Location.locationId),
      isDisplayed: new FormControl(0),
      isDeleted: new FormControl(0),
      sessionId: new FormControl(0),
      transId: new FormControl(),
      branchId: new FormControl(Number(this.globals.HospitalId)),
      regNo: new FormControl(this.regNo),
      patientName: new FormControl(),
      date: new FormControl(''),
    });
  }

  close(){
    this.modalRef.hide();
  }

  ///////////////patient dtails hide///////////////
  hidePatientDetails(value) {
    // alert(value);
    if (value == 2) {
      this.PatientData = false;
      //  this.Ledgerlist=true;
    }
    else {
      this.PatientData = true;
      //  this.Ledgerlist=false;

    }
  }

  hideledger(value) {
    if (value == 0 || value == 1) {
      this.Ledgerlist = false;
    }
    else {
      this.Ledgerlist = true;
    }
  }

  //////////////////get Credit card type/////////////////////
  GetCardType(value) {

    if (value == 2) {
      this.billingService.GetCardTypes(this.globals.HospitalId).subscribe((res) => {
        this.CardTypes = res.response;
      });
    }
  }

  ChangeModeOfpay(modeofpay) {

    if (modeofpay == 2) {
      this.isCardVisble = true;
      this.isChequeateVisible = false;
      this.refvisible=true;
      this.GetCardType(modeofpay);
    }
    else if (modeofpay == 3) {
      this.isChequeateVisible = true;
      this.isCardVisble = false;
      this.refvisible=true;
    }
    else if (modeofpay == 1) {
      this.refvisible = false;
      this.isChequeateVisible = false;
      this.isCardVisble = false;

    }
    else {
      this.isChequeateVisible = false;
      this.isCardVisble = false;
      this.refvisible = true;
    }
  }

  get f() {
    return this.form.controls;
  }

  //////////get Credit balance  /////////
  GetCreditBalance() {
    let input = {
      "branchId": this.globals.HospitalId,
      "patientId": this.patientId
    }
    this.billingService.getCreditBalance(input).subscribe((res) => {
      this.creditList = res.response;
    });

  }

  osBalance(creditId) {
    let item = this.creditList.filter(x => x.creditId == creditId)[0];
    this.form.patchValue({ soNo: item.outstandingCreditBalace });
  }

  creditheadchange(value){
    if(value==1){
      this.balancePopupHeading="Available Balance";

    }else{
      this.balancePopupHeading="O/S Balance";

    }
  }

  GetPatientByRegNo() {
    this.billingService.SearchPatientByRegNo(this.form.get("regNo").value).subscribe((res) => {
      let patients = res.response;
      if (patients.length > 0) {
        if (patients[0].active == "c") {
          this.toastr.warning("This Patient is blocked");
        }
        this.PatientInformation = patients[0];
        this.patientId = patients[0]?.patientId;
        this.form.patchValue({ 
          patientName: this.PatientInformation.patient,
          regNo: this.PatientInformation.regNo
        });
        this.GetCreditBalance();
        this.GetBillBalance();
        this.getAdvanceBalance();
      } else {
        this.toastr.warning("No patients found");
      }
    });
  }

  GetBillBalance() {
    let input = {
      "branchId": this.globals.HospitalId,
      "patientId": this.patientId
    }
    this.billingService.GetBillBalance(input).subscribe((res) => {
      this.billBalance = res.response;
    });

  }

  loadAmount(transId) {
    let item = this.billBalance.filter(x => x.transId == transId)[0];
    this.form.patchValue({ amount: item.amt });
    if (transId > 0) {
      let item = this.billBalance.filter(x => x.transId == transId)[0];
      let item2 = this.billBasket.filter(x => x.transId == transId)[0];
      if (item2 == undefined) {
        let itemarray = {
          transId: item.transId,
          amount:item.amount
        }
        this.billBasket.push(itemarray);
      }
      else {

      }
    }

  }

  showBasicDialog() {
    this.modaltemp.open();
  }

  searchevent(patient: any) {
    this.patientId = patient.data.patientId;
    this.PatientInformation = patient.data;
    this.GetCreditBalance();
    this.GetBillBalance();
    this.getAdvanceBalance();
  }


  savePaymentlist() {
    // if (this.submitted) { return;}
    // this.submitted = true;
    this.submitted = true;
    if (this.form && this.form.valid) {
      let formdata = this.form.getRawValue();
      formdata.BranchId = Number(this.globals.HospitalId);
      formdata.locationId = Number(this.globals.Location.locationId);
      formdata.headId = Number(formdata.headId);
      formdata.creditId = Number(formdata.creditId);
      formdata.patientId = Number(this.patientId);
      formdata.userId = Number(localStorage.getItem("user_id"));
      formdata.mode = Number(formdata.mode);
      formdata.transId = Number(formdata.transId);
      formdata.amount = Number(formdata.amount);
      formdata.cardId = Number(formdata.cardId);
      formdata.payType = Number(formdata.payType);
      formdata.date = Number(formdata.date);

      if (formdata.chqDate && (typeof (formdata.chqDate) == "object")) {
        formdata.chqDate = this.datePipe.transform(new Date(formdata.chqDate), 'dd-MM-yyyy');
      }
      if (formdata.payDate && (typeof (formdata.payDate) == "object")) {
        formdata.payDate = this.datePipe.transform(new Date(formdata.payDate), 'dd-MM-yyyy');
      }
      formdata.isDisplayed = 1;
      formdata.status = "N";
      let dataid = 0;
      dataid = formdata.paymentId;
      if (formdata.patientId == null) {
        formdata.patientId = 0;
      }
      else {
        formdata.patientId = Number(this.patientId);

      }
      if ( (formdata.payType == 0 || formdata.payType == 1 ) && (formdata.patientId == 0 || !formdata.patientId )) {
        this.toastr.warning("please select patient details");
      }
      else if((formdata.patientId != 0 )&&(formdata.creditId==0)&&(formdata.payType==0)){
        this.toastr.warning("please select credit");
      }
            else if((formdata.patientId != 0 )&&(formdata.transId==0)&&(formdata.payType==0)){
        this.toastr.warning("please select Bill");
      }

      else if((formdata.mode != 1 )&&(formdata.chqNo==0)){
        this.toastr.warning("please select Refferance no");
      }
      else if((formdata.patientId != 0 )&&(formdata.amount > formdata.soNo)&&(formdata.payType==1)){
        this.toastr.warning("Insufficient advance balance to return.");
      }

     else {
      this.isLoading = true;
      this.billingService.SavePaymentList(formdata).subscribe((res) => {
        this.submitted = false;
        let result = res;
        if (result.status == 200 && result.message == 'Success') {
          if (dataid == 0) {
            this.isLoading = false;
            this.toastr.success("Payment Saved Successfully");
          }
          else {
            this.isLoading = false;
            this.toastr.success("Payment Edited Successfully");
          }
          this.close();
        }
        else {
          this.isLoading = false;
          this.toastr.error("Failed : " + result.message);
        }
      })

    }
   }
  

  }

  GetAllLedgers() {
    let LedgerId = 0;
    let showALL = 0;
    let BranchId = this.globals.HospitalId;

    this.billingService.GetLedger(LedgerId, showALL, BranchId).subscribe((res) => {
      this.GetLedgers = res.response;
    });
  }

  getAdvanceBalance(isEdit: boolean = false){
    if(this.form.get('payType').value == 1 && this.patientId > 0){
      let payload = {
        Patientid: this.patientId,
        BranchId: this.globals.HospitalId
      }
  
      this.billingService.getAdvanceBalance(payload).subscribe(res=>{
        if(res.status){
          this.form.patchValue({
            soNo: isEdit ? res.response[0].advBal + Number(this.form.get('amount').value) : res.response[0].advBal,
            amount: isEdit ? this.form.get('amount').value : res.response[0].advBal,
          })
        }
      })
    }
  }

}
