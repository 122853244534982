import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OperatorService } from '../../client/services/operator.service';
import { ToastrService } from 'ngx-toastr';
import { FormValidationService } from '../../client/services/form-validation.service';
import { AllOperatorsList } from '../../client/interfaces/OperatorList';
import { ConfigurationService } from '../../client/services/configuration.service';
import { LeadAgentList } from '../../client/interfaces/LeadAgentList';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';

import { ActiveFilterMenuTriggerDirective } from '@generic-ui/ngx-grid/structure/source/feature/counter/filter/active-filter-menu-trigger.directive';
@Component({
  selector: 'app-LeadAgentComponent',
  templateUrl: './LeadAgent.component.html',
  styleUrls: ['./LeadAgent.component.scss']
})
export class LeadAgentComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;
  supervisor = '';
  locationname = '';
  Mobilenumber = '';
  public submitted: boolean = false;
  LeadAgentform: FormGroup;
  result: any;
  Operators: AllOperatorsList[] = [];
  LeadAgentId: any
  deleteoperator: boolean;
  oppid: any;
  BranchId: any;
  BranchesId: any;
  Agents: LeadAgentList[];
  subscription: Subscription;
  globals: globalvars = new globalvars();

  constructor(private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private OperatorService: OperatorService,
    private toastr: ToastrService,
    private formValidationService: FormValidationService,
    private sharedservice: SharedService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
        localStorage.setItem('BranchId', this.BranchesId);
      }
      this.BranchId = localStorage.getItem('BranchId');

    });

  }

  ngOnInit(): void {
    this.BuildForm(1, 1);
    this.GetLeadAgent();
  }


  BuildForm(FormId, DepartmentId) {
    this.GenerateForm();
  }
  GenerateForm() {
    this.LeadAgentform = this.formBuilder.group({
      LeadAgentName: new FormControl(''),
      LeadAgentContact: new FormControl(''),

    });
  }

  showModalDialog() {
    this.displayModal = true;
  }
  showModalDialogedit(id: any) {
    this.LeadAgentId = id;
    let OperatorData = this.Agents.filter((oppdata) => oppdata['leadAgentId'] == id);
    this.LeadAgentform.patchValue({
      LeadAgentName: OperatorData[0]['name'],
      LeadAgentContact: OperatorData[0]['contactNo'],
      //operatorDescription: OperatorData[0]['operatorDescription']
    });
    this.displayModal = true;
  }

  showModalDialogdelete(id: any) {
    this.oppid = id;
    this.deleteoperator = true;
    this.DeleteOperator
  }

  //pagetitle to be displayed at the header
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);

  // }


  SaveLeadAgent() {
    const formData = new FormData();
    var AppData = {

      Name: this.LeadAgentform.get("LeadAgentName").value,
      ContactNo: this.LeadAgentform.get("LeadAgentContact").value,
      BlockReason: "",
      //operatorDescription: this.LeadAgentform.get("operatorDescription").value,
    }

    formData.append('appda', JSON.stringify(AppData));
    if (this.LeadAgentId > 0) {
      this.configurationService.SaveLeadAgent(formData).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("LeadAgent Updated successfully");
        }
        else {
          this.toastr.error("Failed.Please try later");
        }
      })
    }
    else {
      this.configurationService.SaveLeadAgent(AppData).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("LeadAgent added successfully");
        }
        else {
          this.toastr.error("Failed.Please try later");
        }
      })
    }
  }

  GetLeadAgent() {
    this.configurationService.GetLeadAgent(this.globals.HospitalId).subscribe((res) => {
      this.Agents = res.response;
      if (this.Agents.length == 0) {
        this.toastr.warning("No Agents found");
      }
    });
  }

  DeleteOperator(id) {

    this.LeadAgentId = id;
    this.configurationService.DeleteLeadAgent(this.LeadAgentId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {

        this.toastr.success("Agent deleted successfully");
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
      }

    },
    );

  }

}
