import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ColDef, Grid, GridOptions } from "ag-grid-community";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { GeneralGridActionComponent } from "../../../shared/general-grid-action/general-grid-action.component";
import { globalvars } from "../../../shared/models/GlobalVars";
import { SharedService } from "../../../shared/shared.service";
import { Menu } from "../../client/interfaces/Menu";
import { UserGroups } from "../../client/interfaces/usergroup";
import { UserpermissionService } from "../userpermission.service";
import { TreeviewItem, TreeviewConfig, TreeviewHelper } from "ngx-treeview";
import { ToastrService } from 'ngx-toastr';
import { GroupId } from "@generic-ui/ngx-grid/composition/core/api/group/group.id";
import { ConfigurationService } from "../../client/services/configuration.service";



@Component({
  selector: "app-user-groups",
  templateUrl: "./user-groups.component.html",
  styleUrls: ["./user-groups.component.scss"],
})
export class UserGroupsComponent implements OnInit {
  usergroup: any;
  columnDefs = [];
  allemnus: any[];
  public gridApi;
  private gridColumnApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public form: FormGroup;
  public formmapmenu: FormGroup;
  displayModal: boolean;
  displayModal1: boolean;
  showReason: boolean = false;
  supervisor = "";
  modalRef2?: BsModalRef;
  locationname = "";
  Mobilenumber = "";
  globals: globalvars = new globalvars();
  products: any = {};
  rowData: any = [];
  subscription: Subscription;
  @ViewChild("templateMenu") modalmap: any;
  selectedCities: any[] = [];
  statusBar: any;
  public submitted: boolean = false;
  addedgroups: boolean = true;
  addgroup: boolean;
  menus: any[];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    // rowClassRules: {
    //   'c-reds': function (params) { return params.data.active == '0'; },
    // },
  }
  items: any;
  simpleItems: any;
  result: any;

  config = {

    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 380
  }
  UserGroupId: number;
  branchId: number;
  functionPermissionData: any;

  constructor(
    private toastr: ToastrService,
    private userpermissionservice: UserpermissionService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        // this.GetLocations();
      }
    });
    this.getUserGroups();
    this.Getmenus();
    this.form = new FormGroup({
      userGroup: new FormControl("", [
        Validators.required,
      ]),
      userGroupId: new FormControl(0),
      isBranchSpecific: new FormControl(true),
      active: new FormControl(true),
      blockReason: new FormControl(""),
      submenuIds: new FormControl("")
    });
    this.formmapmenu = new FormGroup({
      groupName: new FormControl(""),
      groupId: new FormControl(0),
      menuIds: new FormControl("", []),
    });


    this.columnDefs = [
      {
        headerName: "User Group",
        minWidth:180,
        field: "userGroup",
        resizable: false,
        unSortIcon: true,
        flex: 3,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        field: "userGroupId",
        enableRowGroup: true,
        resizable: false,
        sortable:false,

        cellRenderer: "actionsComponentRenderer",
        cellRendererParams: {
          Page: "UserGroups",
        },
        flex: 2,
      },

    ];
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };

    this.frameworkComponents = {
      actionsComponentRenderer: GeneralGridActionComponent,
    };
    this.context = { componentParent: this };
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  Getmenus() {
    this.userpermissionservice.GetLeftmenu(0, 0).subscribe((res) => {
      this.menus = res.response;

      this.menus?.map((a) => {
        (a.text = a.menuAlias),
          (a.value = a.mid),
          a.subMenus?.map((b) => {
            (b.text = b.subMenuAlias), (b.value = b.sId);
          }),
          (a.children = a.subMenus);
      });
      this.items = this.getItems(this.menus);

    });
  }
  Savemenumap() {
    let data = this.formmapmenu.getRawValue();
    this.userpermissionservice.SaveMenumap(data).subscribe((res) => {
      this.displayModal = false;
      alert(res.response);
      this.formmapmenu.reset();
    });

  }
  Save() {
    this.usergroup = this.form.getRawValue();
    this.usergroup.branchId = 0;
    if (this.usergroup.isBranchSpecific) {
      this.usergroup.branchId = this.globals.HospitalId;
    }
    this.submitted = true;

    if (this.form && this.form.valid) {
      this.userpermissionservice
        .SaveUserGroup(this.usergroup)
        .subscribe((res) => {
          this.submitted = false;
          this.displayModal = false;
          //  alert(res.message);
          if (res.status == 200 && (res.message == 'Saved Successfully' || res.message == 'Updated Successfully')) {
            this.toastr.success(res.message);
            this.getUserGroups();
            this.RefreshUser()
          }
          else {
            this.toastr.error("Failed :" + res.message);
          }
          this.form.controls['userGroup'].setValue("");
          this.getUserGroups();
        });
    }

  }
  MapMenu(groupid, Groupname) {
    this.formmapmenu.get("groupId").patchValue(groupid);
    this.formmapmenu.get("groupName").patchValue(Groupname);
    this.userpermissionservice.getMenuMap(groupid).subscribe((res) => {
      this.formmapmenu.get("menuIds").patchValue(res.response);
    });
    this.modalRef2 = this.modalService.show(
      this.modalmap,
      Object.assign({}, { class: "gray modal-lg" })
    );
  }
  getUserGroup(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 493)
    if (foundElement.id == 493 && foundElement.isAllowed == true) {
    this.addedgroups = false;
    this.addgroup = true;

    this.userpermissionservice.getUserGroup(id).subscribe((res) => {
      this.usergroup = res.response;
      this.form.patchValue(this.usergroup);
      // let items=this.items;
      this.items.forEach(item => {
        item.setCheckedRecursive(false);
      });
      this.usergroup.submenuIds.forEach(e => {
        const found = TreeviewHelper.findItemInList(this.items, e);
        found.checked = true;
        this.items.forEach(element => {
          element.correctChecked();

        });


      });
      // alert(this.usergroup.branchId);
      var IsBranchSpecific = this.usergroup.branchId > 0 ? true : false;
      this.form.get("isBranchSpecific").patchValue(IsBranchSpecific);
      this.displayModal = true;
    });
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  getUserGroups() {
    var bidv = 0;
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        bidv = this.globals.HospitalId
      }
    });
    var branchid = bidv;
    this.userpermissionservice.getUserGroups(branchid).subscribe((res) => {

      this.rowData = res.response;
    });
  }

  ngOnInit(): void {
    this.functionPermission();
   }

  get f() { return this.form.controls; }

  showModalDialog() {
    this.displayModal = true;
  }

  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach((set) => {
      itemsArray.push(new TreeviewItem(set));
    });
    return itemsArray;
  }

  onTab(number) {
    this.addedgroups = false;
    this.addgroup = false;

    if (number == "1") {
      this.addedgroups = true;
    } else if (number == "2") {
      this.addgroup = true;
    }
  }


  getitemsChecked(event) {
    this.form?.get('submenuIds').patchValue(event);
  }

  showHideReason(event) {

    if (event.currentTarget.checked) {
      this.showReason = false;
    }
    else {
      this.showReason = true;
    }
  }

  RefreshUser() {
    this.form.reset({
      userGroupId: 0,
      isBranchSpecific: true,
      active: true,
      blockReason: '',
      submenuIds: []


    })

  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }
}
