import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { TreatmentGeneralActionComponent } from '../treatment-general-action/treatment-general-action.component';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ApplyVaccineModalComponent } from '../modals/apply-vaccine-modal/apply-vaccine-modal.component';
import { HealthSummaryModalComponent } from '../modals/health-summary-modal/health-summary-modal.component';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { PatientService } from '../../client/services/patient.service';
import { TreatmentsService } from '../services/treatments.service';
import { Response } from '../../../_Models/Response';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { ConfigurationService } from '../../client/services/configuration.service';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
import { VaccineChart } from './VaccineChart';
import { ApplyTreatmentFromVaccineComponent } from './apply-treatment-from-vaccine/apply-treatment-from-vaccine.component';
import { Router } from '@angular/router';
import _ from 'lodash';

@Component({
  selector: 'app-vaccination-details',
  templateUrl: './vaccination-details.component.html',
  styleUrls: ['./vaccination-details.component.scss']
})

export class VaccinationDetailsComponent implements OnInit {

  private subscription: Subscription;
  private globals: globalvars = new globalvars();

  private gridApi: GridApi;
  public vaccinationColDef: ColDef[] = [];
  public vaccineChartColDef: ColDef[] = [];
  public patientColDef: ColDef[] = [];
  public context = { componentParent: this };;
  public frameworkComponents = {
    buttonRender: TreatmentGeneralActionComponent,
  };
  public noRowsTemplate = `<span>no rows to show</span>`;
  public loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  public vaccineData: any[] = [];

  public vaccineSearchForm: FormGroup;
  public patientSearchForm: FormGroup;
  vaccineList: VaccineChart[] = [];
  rowGroupPanelShow = "always";

  @ViewChild(SearchPatientModalComponent)modaltemp: SearchPatientModalComponent;
  @ViewChild(SearchPatientModalComponent)modalTwoTemp: SearchPatientModalComponent;
  functionPermissionData: any;
  vaccineTab: boolean = true;
  vaccineChart: boolean;
  public defaultColDef: ColDef;
  public gridApiChart;
  vaccineChartForm: FormGroup;
  public patientData: any[] = [];
  displayBasic = false;
  displayTwoBasic = false;
  previousUrl = '';
  backNav = false;
  IsApplyTreatment: number;
  patId: any;
  storeData: any;
  healthPlatform: any;

  constructor(private modalService: BsModalService,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private patientService: PatientService,
              private sharedservice: SharedService,
              private treatmentService: TreatmentsService,
              private datepipe: DatePipe,
              private ConfigurationService: ConfigurationService,
              private router: Router,
              private _location: Location,
              ) {
                if (this.router.getCurrentNavigation()?.extras?.state) {
                  this.previousUrl = this.router.getCurrentNavigation().extras.state.pUrl; 
                  if(this.previousUrl == '/EMR_Home' || localStorage.getItem("vaccEmrBack") == '/EMR_Home'){
                    this.backNav = true;
                  }           
                }
               }

  ngOnInit(): void {
    if(this.previousUrl == '/EMR_Home' || localStorage.getItem("vaccEmrBack") == '/EMR_Home'){
      this.backNav = true;
    }  
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });

    this.vaccinationColDef = [
      {
          headerName: "Reg.No.",
          minWidth: 75,
          field: "regNo",
          enableRowGroup: true,
          resizable: true,
          filter: true,
          sortable: true,
          flex: 6,
      },
      {
        headerName: "Patient Name",
        minWidth: 75,
        field: "patientName",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 6,
      },
      {
        headerName: "Consultant Name",
        minWidth: 75,
        field: "consultantName",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 6,
      },
      {
        headerName: "Consult Date",
        minWidth: 75,
        field: "consultDate",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 4,
      },
      {
        headerName: "Status",
        minWidth: 75,
        field: "responseStatus",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 4,
      },
      {
        headerName: "Item Name",
        minWidth: 75,
        field: "itemName",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 6,
      },
      {
        headerName: "Adm Qty",
        minWidth: 75,
        field: "admQty",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Unit",
        minWidth: 75,
        field: "unit",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Lot Number",
        minWidth: 75,
        field: "lotNumber",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 3,
      },
      {
        headerName: "Expiry Date",
        minWidth: 75,
        field: "expiryDate",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 4,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "vaccinationDetailsList" },
      }
    ]

    this.patientColDef = [
      {
          headerName: "Reg.No.",
          minWidth: 75,
          field: "regNo",
          enableRowGroup: true,
          resizable: true,
          filter: true,
          sortable: true,
          flex: 6,
      },
      {
        headerName: "Patient Name",
        minWidth: 75,
        field: "patient",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 6,
      },
      {
        headerName: "Mobile",
        minWidth: 75,
        field: "mobile",
        enableRowGroup: true,
        resizable: true,
        filter: true,
        sortable: true,
        flex: 6,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "vaccineChartList" },
      }
    ]

    this.vaccineChartColDef = [
      {
        headerName: "Childs Age",
        field: "childsAge",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Vaccine and Dose",
        field: "vaccineandDose",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Protects Against",
        field: "protectsAgainst",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Vaccination Date",
        field: "vaccinationDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
        // editable: (params) => params.data.vPurposeId == 1
      },
      {
        headerName: "Date Received",
        field: "vaccinatedDate",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      // {
      //   headerName: "Action",
      //   maxWidth: 100,
      //   pinned: "right",
      //   sortable: false,
      //   resizable: false,
      //   unSortIcon: true,
      //   field: "Id",
      //   filter: false,
      //   flex: 1,
      //   cellRenderer: "buttonRender",
      //   cellRendererParams: { PageType: "vaccineChartSecondList" },
      // }
    ];
    this.functionPermission();
    this.initForm();
    this.getApplicationSettings();
  }

  initForm(){
    this.vaccineSearchForm = this.formBuilder.group({
      patientId: new FormControl(''),
      regNo: new FormControl(''),
      patientName: new FormControl({value: '', disabled: true}),
      status: new FormControl('0'),
      fromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      toDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
    })

    this.patientSearchForm = this.formBuilder.group({
      sMobile: new FormControl(''),
      sPhoneNo: new FormControl(''),
      sPin: new FormControl(''),
      sPolicyNo: new FormControl(''),
      sRegNo: new FormControl(''),
      sName: new FormControl(''),
      sAddress: new FormControl(''),
      sResNo: new FormControl(''),
      sEmiratesNo: new FormControl(''),
      BranchId: new FormControl(this.globals.HospitalId)
    })

    this.vaccineChartForm = new FormGroup({
      PatientId: new FormControl(),
      VaccineDetailList: new FormArray([]),
    });
  }

  onGridReady(params){
    this.gridApi = params.api;
    this.searchForVaccine();
  }

  showBasicDialog() {
    this.displayBasic = true;
    this.modaltemp.open();
  }

  showBasicPatDialog() {
    this.displayTwoBasic = true;
    this.modalTwoTemp.open();
  }

  //calling patients details Api
  GetPatientByRegNo(event, tab) {
    if(tab == 1){
      this.displayBasic = true;
    } else if(tab == 2){
      this.displayTwoBasic = true;
    }
    this.patientService.SearchPatientByRegNo(event.target.value).subscribe((res) => {
      if (res.response.length > 0) {
        if (res.response[0].active == "0") {
          this.toastrService.warning("This Patient is blocked");
        }
        let patient = {
          data: res.response[0]
        }
        this.searchevent(patient);
      } else {
        this.toastrService.warning("No patients found");
      }

    });
  }

  searchevent(patient: any) {
    if (this.displayBasic == true) {
      this.vaccineSearchForm.patchValue({
        regNo: patient.data.regNo,
        patientName: patient.data.patient,
        patientId: patient.data.patientId
      })
      this.displayBasic = false;
    } else if (this.displayTwoBasic == true) {
      this.patientSearchForm.patchValue({
        sRegNo: patient.data.regNo,
        sName: patient.data.patient,
        sMobile: patient.data.mobile
      })
      this.displayTwoBasic = false;
    }
  }


  searchForVaccine(){
    if(this.vaccineSearchForm.invalid){
      const controls = this.vaccineSearchForm.controls;
      Object.keys(controls).forEach(control=>{
        controls[control].markAllAsTouched();
      })
      return
    }

    let payload = {
      FromDate: this.vaccineSearchForm.controls.fromDate.value,
      ToDate: this.vaccineSearchForm.controls.toDate.value,
      RegNo: this.vaccineSearchForm.controls.regNo.value,
      PatName: this.vaccineSearchForm.controls.patientName.value,
      BranchId: this.globals.HospitalId,
      Status: this.vaccineSearchForm.controls.status.value ? Number(this.vaccineSearchForm.controls.status.value) : -1
    }

    if(payload.FromDate && (typeof (payload.FromDate) == "object")) {
      payload.FromDate = this.datepipe.transform(new Date(payload.FromDate), 'dd-MM-yyyy');
    }
    if(payload.ToDate && (typeof (payload.ToDate) == "object")) {
      payload.ToDate = this.datepipe.transform(new Date(payload.ToDate), 'dd-MM-yyyy');
    }
    this.gridApi.showLoadingOverlay();
    let role = localStorage.getItem("user_role");
    this.treatmentService.searchForVaccine(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          // this.vaccineData = response.response;
          if(role == 'consultant'){
            this.vaccineData = _.filter(response.response, {consultantId: Number(localStorage.getItem("Id"))})
          } else{
            this.vaccineData = response.response;
          }
        }else{
          this.toastrService.error(response.errorMessage.message)
        }
        this.gridApi.hideOverlay();
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again.');
        this.gridApi.hideOverlay();
      }
    })
  }

  onApplyVaccine(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 244)
    if (foundElement.id == 244 && foundElement.isAllowed == true) {
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      data: data,
      branchId: this.globals.HospitalId,
      action: 'apply',
      consultationId: data.consultationId ? Number(data.consultationId) : 0,
      permissionData: this.functionPermissionData,
      healthInsurancePlatform: this.healthPlatform,
    }
    const modalRef = this.modalService.show(ApplyVaccineModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }));
    modalRef.content.vaccineAppliedEmitter.subscribe((res)=>{
      if(res.status){
        this.searchForVaccine();
      }
    })
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  openHealthChart(data: any, actionId){
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    // if(!this.vaccineSearchForm.controls.patientId.value){
    //   this.toastrService.warning('Please select a patient to view health summary');
    //   return;
    // }

    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      patientId: data.patientId,
      branchId: this.globals.HospitalId
    }
    const modalRef = this.modalService.show(HealthSummaryModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }));
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  openVaccineChart(y){
    if(!this.patientSearchForm.controls.patientId.value){
      this.toastrService.warning('Please select a patient to view vaccine chart');
      return;
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  // vaccinationChart(data: any) {
  //   const foundElement = this.functionPermissionData.find(x => x.id === 243)
  //   if (foundElement.id == 243 && foundElement.isAllowed == true) {
  //     // need to write the functionality of vaccination chart inside this if condition
      // let initialState = {
      //   modalHeading: "",
      //   reportName: "RptVaccineChart.trdp",
      //   reportParams: {
      //     Patientid: data.patientId
      //   }
      // }
      // let config: ModalOptions = {
      //   backdrop: true,
      //   ignoreBackdropClick: true,
      // }
      // const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  //   } else {
  //     this.toastrService.warning("You are not allowed");
  //   }
  // }

  onTab(number) {
    this.vaccineTab = false;
    this.vaccineChart = false;
    if (number == "1") {
      this.vaccineTab = true;
    } else if (number == "2") {
      this.vaccineChart = true;
    }
  }

  SearchForPatient() {
    // this.isLoading = true;
    this.vaccineList = [];
    this.patientService.SearchPatient(this.patientSearchForm).subscribe((res) => {
      this.patientData = res.response;
    });
  }

  vaccinationChart(data: any) {
    this.storeData = data;
    this.patId = data?.patientId;
    const foundElement = this.functionPermissionData.find(x => x.id === 243)
    if (foundElement.id == 243 && foundElement.isAllowed == true) {
      let input = {
        PatientId: data.patientId,
      }
      this.treatmentService.GetVaccineChart(input).subscribe((res) => {
        this.vaccineList = res.response;
      });
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  onGridReadyChart(params) {
    this.gridApiChart = params.api;
  }

  applyTreatment(){
    const foundElement = this.functionPermissionData.find(x => x.id === 590)
    if (foundElement?.id == 590 && foundElement.isAllowed == true) {
    let initialState = {

    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(ApplyTreatmentFromVaccineComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  } else {
    this.toastrService.warning("You are not allowed");
  }
  }

  goback(){
    localStorage.removeItem("vaccEmrBack");
    this._location.back();
  }

  applyTreatmentFromChart(data){
    console.log('datat', data);
    this.IsApplyTreatment = 1
    // this.modalRef.hide();
    // this.router.navigate(['/Treatment_details'], { state: { id: event.data.patientId ,consultationId:event.data.consultationId,IsApplyTreatment:this.IsApplyTreatment } });
  }

  saveVaccineChart() {
    this.gridApiChart.stopEditing();
    let formdata = this.vaccineChartForm.getRawValue();
    formdata.VaccineDetailList = this.vaccineList;
    formdata.PatientId = Number(this.patId);
    this.treatmentService.InsertUpdateVaccineChartDetails(formdata).subscribe(
      (res) => {
        if (res.status == 200 && res.message == "Success") {
          this.toastrService.success("Success: " + "Vaccine chart details Saved Successfully...");
          this.vaccinationChart(this.storeData);
        }
        else {
          this.toastrService.error("Error: " + "Some unexpected error occures Please try again...");
        }
      });
  }

  printChart(){
    let initialState = {
      modalHeading: "",
      reportName: "RptPatientVaccineChart.trdp",
      reportParams: {
        PatientId: this.patId
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  }

  onEditVaccine(data: any){
    // console.log('data', data);
    const foundElement = this.functionPermissionData.find(x => x.id === 591)
    if (foundElement.id == 591 && foundElement.isAllowed == true) {
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      data: data,
      branchId: this.globals.HospitalId,
      action: 'edit',
      consultationId: data.consultationId ? Number(data.consultationId) : 0,
      permissionData: this.functionPermissionData,
      healthInsurancePlatform: this.healthPlatform,
    }
    const modalRef = this.modalService.show(ApplyVaccineModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }));
    modalRef.content.vaccineAppliedEmitter.subscribe((res)=>{
      if(res.status){
        this.searchForVaccine();
      }
    })
    } else {
      this.toastrService.warning("You are not allowed");
    }
  }

  getApplicationSettings() {
    let payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId: this.globals.HospitalId
    }
    this.patientService.getApplicationSettings(payload).subscribe((res) => {
      this.healthPlatform = res.response.find(item => item.settId == 25)
    })
  }

}
