import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../client/services/configuration.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-CpctCode',
  templateUrl: './cpctcode.component.html',
  styleUrls: ['./cpctcode.component.scss']
})
export class CpctCodeComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;
  currentPage:number=1;
  public submitted: boolean = false;
  //professionForm: FormGroup;
  result: any;
  // Operators: AllOperatorsList[] = [];
  professionId: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  deleteprofession: boolean;
  CPTCodePopupHeading:String="Add CPT code";//change the cpt name
  CptCodeInput={
    "CPTCodeId": 0,
    "CPTCode": "",
    "ShowAll": 1,
    "BranchId": this.globals.HospitalId,
    "PageNumber":1
  }
  cpctcodeRows:number=0;
  isLoading=false;
  oppid: any;
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate = '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';
  public gridOptions = {
    pagination: false,
    paginationPageSize: 20,
  }
  rowData: any;
  cpctcode: any;
  unlistedCode: any;
  CPCTForm: FormGroup;
  CptCodeId: any;
  rowGroupPanelShow = "always";
  public statusBar;
  IsDisplayed: any;
  DisplayedId: any;
  modalRef: BsModalRef;
  @ViewChild("template") modaltemp: any;
  functionPermissionData: any;

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private ConfigurationService: ConfigurationService,
    private modalService: BsModalService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {
    this.CPCTForm = new FormGroup({
      cptcode: new FormControl('', [Validators.required]),
      cptdesc: new FormControl('', [Validators.required]),
      IsDisplayedGroup: new FormControl(true)

    });

    this.GetAllCpctCode();
    this.functionPermission();

    this.columnDefs = [

      {
        headerName: "CPT Code",
        minWidth: 150,
        field: "cptCode",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,

      },
      {
        headerName: "CPT Description",
        field: "cptDesc",
        minWidth: 150,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        filter: false,
        resizable: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        unSortIcon: true,
        field: "cptCodeId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "CPTCodeList" },
      },

    ]


    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };





    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };



    this.noRowsTemplate = `"<span">no rows to show</span>"`;

  }


  showModalDialog(orderId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 99)
    if (foundElement.id == 99 && foundElement.isAllowed == true) {
    this.CPTCodePopupHeading = "Edit CPT Code";
    this.modalRef = this.modalService.show(
      this.modaltemp,
    );
    this.displayModal = true;

    if (orderId) {

      this.CptCodeId = orderId.cptCodeId
      this.DisplayedId = orderId.isDisplayed
      this.CPCTForm.patchValue({
        cptcode: orderId.cptCode,
        cptdesc: orderId.cptDesc,
      });
      if (orderId.isDisplayed == 1) {
        let displayed = true;
        this.CPCTForm.patchValue({
          IsDisplayedGroup: displayed
        });
      }
      else {
        let displayed = false;
        this.CPCTForm.patchValue({
          IsDisplayedGroup: displayed
        });
      }
    }
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }

  }



  //pagetitle to be displayed at the header
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);

  // }


  SaveCptCode() {
    this.submitted = true;
    if (this.CPCTForm && this.CPCTForm.valid) {
      var AppData = {

        cptcode: this.CPCTForm.get("cptcode").value,
        cptdesc: this.CPCTForm.get("cptdesc").value,
        IsDisplayedGroup: this.CPCTForm.get("IsDisplayedGroup").value,
      }
      if (AppData.IsDisplayedGroup == true) {
        AppData.IsDisplayedGroup = 1;
      }
      else {
        AppData.IsDisplayedGroup = 0;
      }

      let CPTdata = 0;
      if (this.CptCodeId > 0) {
        CPTdata = this.CptCodeId;
      }
      this.isLoading = true;
      this.submitted = false;
      this.ConfigurationService.InsertUpdateCPTCode(AppData, this.globals.HospitalId, CPTdata).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200 && this.result.message == 'Success') {
          if (CPTdata == 0) {
            this.isLoading = false;
            this.toastr.success("CPT Code added successfully");
          }
          else {
            this.isLoading = false;
            this.toastr.success("CPT Code Updated successfully");
            this.CptCodeId = 0;
          }
          this.modalRef.hide();
          this.clearForm();
          this.GetAllCpctCode(1);

        }
        else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);

        }
      })



    }

  }


  GetAllCpctCode(load=0) {
     if(load==1)   this.gridApi.showLoadingOverlay();

    // this.ConfigurationService.GetCpctCode(this.globals.HospitalId, ShowAll).subscribe((res) => {
    //   this.cpctcode = res.response;
    //   if(load==1)   this.gridApi.hideOverlay();
    // });

      this.CptCodeInput.BranchId=this.globals.HospitalId;
      this.ConfigurationService.GetCpctCodePagination(this.CptCodeInput).subscribe((res) => {
         this.cpctcode = res.response['cptData'];
         this.cpctcodeRows=res.response['totalCount'];
         if(load==1)   this.gridApi.hideOverlay();
       });
}


  DeleteCPTCode(id) {
    const foundElement = this.functionPermissionData.find(x => x.id === 100)
    if (foundElement.id == 100 && foundElement.isAllowed == true) {
    this.CptCodeId = id.cptCodeId;
    this.gridApi.showLoadingOverlay();
    this.ConfigurationService.DeleteCPTCode(this.CptCodeId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {

        this.toastr.success("CPT Code deleted successfully");
        this.displayModal = false;
        //  this.GetAllCpctCode();

        let find = this.cpctcode.findIndex(x => x.cptCodeId === this.CptCodeId);
        this.cpctcode.splice(find, 1);
        this.gridApi.setRowData(this.cpctcode);
        this.gridApi.hideOverlay();
        this.CptCodeId = 0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
        this.displayModal = false;
      }

    },
    );
  } else {
    this.toastr.warning("You are not allowed to delete data");
  }

  }

  // to clear form
  clearForm() {
    this.CPCTForm.reset({
      cptcode: '',
      cptdesc: '',
      IsDisplayedGroup: true
    });
    this.submitted = false;
  }

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    // this.gridApi.setQuickFilter(
    //   (document.getElementById('filter-text-box') as HTMLInputElement).value
    // );
    this.CptCodeInput.CPTCode=(document.getElementById('filter-text-box') as HTMLInputElement).value;
    this.CptCodeInput.PageNumber=1;
    this.GetAllCpctCode(1);
  }


  openModalCPTCode(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 98)
    if (foundElement.id == 98 && foundElement.isAllowed == true) {
    this.CPTCodePopupHeading = "Add CPT Code";
    this.modalRef = this.modalService.show(template);
    this.CptCodeId = 0;
    this.clearForm();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }

  // for geting all form items

  get f() {
    return this.CPCTForm.controls;
  }

  ResetFunction() {
    this.CptCodeId = 0;
    this.clearForm();
  }

  pageChanged(event){
    this.CptCodeInput.PageNumber=event.page;
    this.GetAllCpctCode(1);
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

}
