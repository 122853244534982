import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { GeneralGridActionComponent } from '../../../shared/general-grid-action/general-grid-action.component';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { UserpermissionService } from '../userpermission.service';

@Component({
  selector: 'app-user-group-menumap',
  templateUrl: './user-group-menumap.component.html',
  styleUrls: ['./user-group-menumap.component.scss']
})
export class UserGroupMenumapComponent implements OnInit {

  
  // usergroup: UserGroups;
  columnDefs = [];
  private gridOptions: GridOptions;
  public gridApi;
  private gridColumnApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public form: FormGroup;
  displayModal: boolean;
  displayModal1: boolean;
  supervisor = "";
  locationname = "";
  Mobilenumber = "";
  globals: globalvars = new globalvars();
  products: any = {};
  rowData: any = [];
  subscription: Subscription;
  selectedCities: any[] = [];
  statusBar: any;
  constructor(
    private userpermissionservice: UserpermissionService,
    private sharedservice: SharedService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
       // this.GetLocations();
      }
    });
    this.getUserGroups();
    this.form = new FormGroup({
      userGroup: new FormControl(""),
      userGroupId: new FormControl(0),
      isBranchSpecific: new FormControl(true),
      active: new FormControl(false),
      blockReason: new FormControl("")
    });
    this.columnDefs = [
      {
        headerName: "User Group",
        field: "userGroup",
        resizable: true,
        unSortIcon: true,
        flex: 3,
        sort: "asc",
      },
      {
        headerName: "Action",
        field: "userGroupId",
        enableRowGroup: true,
        resizable: true,
        
        cellRenderer: "actionsComponentRenderer",
        cellRendererParams: {
            Page:'UserGroups'
         },
        flex: 2,
      },

      // ik
    ];
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;


    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };

    this.frameworkComponents = {
      actionsComponentRenderer: GeneralGridActionComponent,
    };
    this.context = { componentParent: this };
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  Save() {
  }
  getUserGroup(id) {
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

  getUserGroups() {
    var bidv = 0;
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        bidv = this.globals.HospitalId
      }
    });
    var branchid = bidv;
    this.userpermissionservice.getUserGroups(branchid).subscribe((res) => {
      
      this.rowData = res.response;
    });
  }
  
  ngOnInit(): void {
  }
  showModalDialog() {
    this.displayModal = true;
  }

}
