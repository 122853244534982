import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ErrorHandleService } from '../../client/services/error-handle.service';
import { Response } from '../../../../app/_Models/Response';


@Injectable({
  providedIn: 'root'
})
export class BillingDashboardService {

  private baseUrl = "";

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

 

  getBillingList(inputData) {
    var url = "api/Bill/GetBillList";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  getTodaysConsultationFilter(inputData){
    var url = "api/Bill/SearchPatientforBill";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
}
