import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from '../../client/services/configuration.service';
import { ToastrService } from "ngx-toastr";
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { Ledger } from '../../client/interfaces/Ledger';


@Component({
  selector: 'app-ledger-settings',
  templateUrl: './ledger-settings.component.html',
  styleUrls: ['./ledger-settings.component.scss']
})
export class LedgerSettingsComponent implements OnInit {

  //all page variables
  subscription: Subscription;
  public user_id:string;
  globals: globalvars = new globalvars();
  BranchesId: any;
 /////////////////////


 public submitted: boolean = false; // form validation controller
 form: FormGroup; // form variable
 public LedgerForm: Ledger; // form interface
 LedgerList:Ledger[]=[];
 isLoading=false;

 // grid

 public defaultColDef: ColDef;
 columnDefs: ColDef[] = [];
 rowGroupPanelShow = "always";
 public context;
 public gridApi;
 public statusBar;
 public loadingTemplate;
 public noRowsTemplateList;
 public frameworkComponents;
 public gridOptionsList = {
  pagination: true,
  paginationPageSize: 10,
};

functionPermissionData: any;

  constructor(
    private configurationService:ConfigurationService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
  ) {
    this.user_id =  localStorage.getItem('user_id');

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {


    this.form = new FormGroup({
      HeadId: new FormControl(0),
      HeadDesc: new FormControl('',[Validators.required]),
      HeadType: new FormControl('',[Validators.required]),
      State: new FormControl('U'),
      UserId: new FormControl(this.user_id),
      IsDisplayed: new FormControl(true),
      BranchId: new FormControl(this.BranchesId),
    });

    this.GetLedgerList();
    this.functionPermission();

    this.context = { componentParent: this };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    this.columnDefs=[
      {
        headerName: "Description",
        field: "headDesc",
        minWidth:180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth:100,
        pinned:"right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "headId",
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "ledger" },
      },
    ]
  }

  get f() {
    return this.form.controls;
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }


  SaveLedger()
  {
    const foundElement = this.functionPermissionData.find(x => x.id === 92)
    if (foundElement.id == 92 && foundElement.isAllowed == true) {
    this.submitted = true;
    if (this.form && this.form.valid)
      {
        this.LedgerForm = this.form.getRawValue();
        this.LedgerForm.UserId = Number(this.LedgerForm.UserId); // string to number
        this.LedgerForm.HeadType = Number(this.LedgerForm.HeadType); // string to number
         this.isLoading=true;
 
        this.configurationService
        .SaveLedger(this.LedgerForm).subscribe(
          (res) => {
            this.submitted = false;

            if (res.status == 200 && res.message == "Success") {
              this.clearForm();
              this.isLoading=false;
              this.toastr.success("Data saved successfully");
              this.GetLedgerList();
            } else if (res.status == 200) {
              this.isLoading=false;
              this.toastr.error("Failed : " + res.message);
            } else {
              this.isLoading=false;
              this.toastr.error("Failure: " + res.response.message);
            }
          },
          (err) => {
            this.isLoading=false;
            this.toastr.error("Some unexpected error occures try again later");
          }
        );

      }
    } else {
      this.toastr.warning("You are not allowed to save data");
    }

  }

  clearForm()
  {
    this.form.reset({
      HeadId: 0,
      HeadDesc: '',
      HeadType: '',
      State: 'U',
      UserId: this.user_id,
      IsDisplayed: true,
      BranchId:this.BranchesId,

    });
  }
  GetLedgerList()
  {
    let Input={
      "HeadId":0,
      "ShowAll":1,
      "BranchId":this.BranchesId
    }
    this.configurationService.GetLedgerData(Input).subscribe((res) => {
      this.LedgerList = res.response;
    });
  }

  EditLedger(data)
  {
    const foundElement = this.functionPermissionData.find(x => x.id === 93)
    if (foundElement.id == 93 && foundElement.isAllowed == true) {
    let LedgerSInglr=this.upper(data);
    this.form.patchValue(LedgerSInglr);
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }
  }
  DeleteLedger(data)
  {
    const foundElement = this.functionPermissionData.find(x => x.id === 94)
    if (foundElement.id == 94 && foundElement.isAllowed == true) {
    let input = {
      "HeadId":data.headId
    }
    this.configurationService.DeleteLedegr(input).subscribe((res) => {
     if (res.status == 200) {
        this.toastr.success("Data deleted successfully");
         this.GetLedgerList();
      } else {
        this.toastr.error("Failure: " + res.message);
      }
    });
  } else {
    this.toastr.warning("You are not allowed to delete data");
  }

  }

    /// For object key name convertion
    upper(obj) {
      var key,
        keys = Object.keys(obj);
      var n = keys.length;
      var newobj = {};
      while (n--) {
        key = keys[n];
        let first = key.substr(0, 1).toUpperCase();
        first = first + key.substr(1);

        newobj[first] = obj[key];
      }
      return newobj;
    }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

}
