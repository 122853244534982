import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { te } from 'date-fns/locale';
import * as _ from 'lodash';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';
import { Response } from '../../../../_Models/Response';
import { teethChart, teethChartResponse } from '../../models/dental.model';
import { DentalService } from '../../services/dental.service';
import { MarkOnTeethModalComponent } from '../mark-on-teeth-modal/mark-on-teeth-modal.component';

@Component({
  selector: 'app-tooth-chart-modal',
  templateUrl: './tooth-chart-modal.component.html',
  styleUrls: ['./tooth-chart-modal.component.scss']
})
export class ToothChartModalComponent implements OnInit {

  @Input() treatMentDetails: any[] = [];
  @Input() branchId: number = 0;
  @Input() visitId: number = 0;
  @Input() patientId: number = 0;
  @Input() tabData: any;
  @Input() modalPage: any;

  @Output() treatmentDetailsChanged = new EventEmitter();

  public chartImages: teethChart[] = [];
  public chartDataLoading: boolean = false;
  private baseUrl: string = '';

  public teethNumberView: boolean = false;

  constructor(private modalService: BsModalService,
              private dentalService: DentalService,
              private configurationManager: ConfigurationManager,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    
    this.baseUrl = (this.configurationManager as any).baseUrl;

    this.getTeethChartData();
  }

  getTeethChartData(){
    let payload = {
      PatientId: this.patientId,
      visitId: this.visitId,
      branchId: this.branchId
    }

    this.chartDataLoading = true;
    this.dentalService.getChartAndMarkings(payload).subscribe({
      next: (response: Response<teethChartResponse[]>)=>{
        if(response.status == 200){
          this.readResponse(response.response);
        }else{
          this.toastrService.error(response.errorMessage?.message);
        }
        this.chartDataLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong.. Try again!!');
        this.chartDataLoading = false;
      }
    })
  }

  readResponse(response: teethChartResponse[]){
    let chartResponse: teethChart[] = [];
    let group = _.groupBy(response, 'toothId');
    Object.keys(group).forEach((item: any)=>{
      let teeth : teethChart = {
        toothId: item,
        masterImage: [],
        imageLocationData: [],
        adultFDI: group[item][0].adultFDI,
        adultStandard: group[item][0].adultStandard,
        pediatricStandard: group[item][0].pediatricStandard,
        pediatricFDI: group[item][0].pediatricFDI,
        toothName: group[item][0].toothName,
        isMissing: group[item][0].isMissing,
        isMarkedAsAdult: group[item][0].isMarkedAsAdult,
        mixedDentition: group[item][0].mixedDentition
      };
      _.forEach(group[item], (view: any)=>{
        teeth.masterImage.push({
          imageUrl: this.baseUrl + view?.masterImage,
          viewId: view?.viewId,
          toothMasterId: view?.toothMasterId,
          markings: view?.markingDetail
        });

        teeth.imageLocationData.push({
          imageUrl: this.baseUrl + view?.imageLocationData,
          viewId: view?.viewId,
          toothMasterId: view?.toothMasterId,
          markings: view?.markingDetail
        })
      })
      chartResponse.push(teeth);
    });

    let top = chartResponse;
    let bottom = top.splice(16,16);
    _.forEach(top, (teeth: teethChart)=>{
      teeth.imageLocationData = _.orderBy(teeth.imageLocationData, ['viewId'], 'asc');
      teeth.masterImage = _.orderBy(teeth.masterImage, ['viewId'], 'asc');
    });

    _.forEach(bottom, (teeth: teethChart)=>{
      teeth.imageLocationData = _.orderBy(teeth.imageLocationData, ['viewId'], 'desc');
      teeth.masterImage = _.orderBy(teeth.masterImage, ['viewId'], 'desc');
    });
    bottom = _.orderBy(bottom, ['toothId'], 'desc')
    
    this.chartImages = _.concat(top, bottom);
  }

  openMarkingModal(selectedTeeth: teethChart){
    let teeth = {
      toothNumber: selectedTeeth?.toothId,
      toothName: selectedTeeth.toothName,
      mixedDentition: selectedTeeth.mixedDentition,
      pediatricData: {
        isPediatricTeeth: selectedTeeth.pediatricFDI > 0 ? true : false,
        markedAsAdult: selectedTeeth.isMarkedAsAdult
      },
      views: {
        firstView: {
          imageUrl: selectedTeeth.masterImage[0].imageUrl,
          markings: selectedTeeth.masterImage[0].markings ? selectedTeeth.masterImage[0].markings : [],
          ToothMasterId: selectedTeeth.masterImage[0].toothMasterId,
          MarkedImageBase64: ''
        },
        secondView: {
          imageUrl: selectedTeeth.masterImage[1].imageUrl,
          markings: selectedTeeth.masterImage[1].markings ? selectedTeeth.masterImage[1].markings : [],
          ToothMasterId: selectedTeeth.masterImage[1].toothMasterId,
          MarkedImageBase64: ''
        },
        thirdView: {
          imageUrl: selectedTeeth.masterImage[2].imageUrl,
          markings: selectedTeeth.masterImage[2].markings ? selectedTeeth.masterImage[2].markings : [],
          ToothMasterId: selectedTeeth.masterImage[2].toothMasterId,
          MarkedImageBase64: ''
        },
        fourthView: {
          imageUrl: selectedTeeth.masterImage[3].imageUrl,
          markings: selectedTeeth.masterImage[3].markings ? selectedTeeth.masterImage[3].markings : [],
          ToothMasterId: selectedTeeth.masterImage[3].toothMasterId,
          MarkedImageBase64: ''
        },
        fifthView: {
          imageUrl: selectedTeeth.masterImage[4].imageUrl,
          markings: selectedTeeth.masterImage[4].markings ? selectedTeeth.masterImage[4].markings : [],
          ToothMasterId: selectedTeeth.masterImage[4].toothMasterId,
          MarkedImageBase64: ''
        }
      }
    }

    let treatments = _.filter(this.treatMentDetails, {teeths: selectedTeeth.toothId});

    let initialState = {
      selectedTeeth: teeth,
      branchId: this.branchId,
      visitId: this.visitId,
      treatments: treatments,
      modalPage: this.modalPage
    }
    console.log('treat', treatments);
    let TreatmentConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const treatmentModalRef= this.modalService.show(MarkOnTeethModalComponent,Object.assign( {class: "modal-dialog-centered "}, TreatmentConfig, { class: 'modal-xl', initialState }) );
    treatmentModalRef.content.saveTreatmentDetailsEmitter.subscribe((response: any)=>{
      if(response.status){
        this.treatmentDetailsChanged.emit({status: true});
        this.getTeethChartData();
      }
    })
  }

}
