import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { SponsorsService } from "../../client/services/sponsors.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-edit-claim',
  templateUrl: './add-edit-claim.component.html',
  styleUrls: ['./add-edit-claim.component.scss']
})
export class AddEditClaimComponent implements OnInit {

  public statusBar;
  rowGroupPanelShow = "always";
  public gridApi;
  columnDefs: ColDef[] = [];
  claimDetails:any[]=[];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 20,
  }
  public context;
  public frameworkComponents;
  public loadingTemplate;
  public noRowsTemplate;
  isCollapsed = true;

  sponsorId:number=0;
  sponsorName:string="No Sponsor Selected";
  globals: globalvars = new globalvars();
  getAllSponsorsData:any;
  subscription: Subscription;
  modalRef?: BsModalRef;

  constructor(
    private router: Router,
    private SponsorsService: SponsorsService,
    private sharedservice: SharedService,
    private modalService: BsModalService
  ) {


    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        let locationId=this.globals.Location.locationId;
        this.globals.HospitalId;
      }
    });


    if(this.router.getCurrentNavigation().extras.state){
      let searchData=this.router.getCurrentNavigation().extras.state.searchData;
      if(searchData){
        this.sponsorId=searchData.sponsorId;
      }


    }
  }

  ngOnInit(): void {
    this.context = { componentParent: this };

    this.columnDefs = [
      {
        headerName: "Select",
        field: "claimDate",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Approval No.",
        field: "refNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Asoap No.",
        field: "sponsorName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Encounter Type",
        field: "patientName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Patient Name",
        field: "Rule",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Card No.",
        field: "subAgent",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Bill Date",
        field: "periodFrom",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Bill No.",
        field: "periodTo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Clm Amount",
        field: "claimAmt",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Inv Amt",
        field: "totBills",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Rule",
        field: "Remarks",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
    ];


    this.GetAllSponsors();

  }

  //.//////////////////////////////////////////////////////////// GET SPONSERES
  GetAllSponsors(){

    let Input={
      "SponsorId":0,
      "Showall":0,
      "BranchId":this.globals.HospitalId
    }
    this.SponsorsService.GetAllSponsorList(Input).subscribe((res) => {
      this.getAllSponsorsData= res.response;
      //this.loadSubAgens(0);

        // filter
        let filteredArray = this.getAllSponsorsData.filter((value) =>  value.sponsorId == this.sponsorId );
         if (filteredArray.length != 0) {
          this.sponsorName=filteredArray[0].sponsorName;

        }
    });


  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  changeSponsor(sponsorId){
    this.sponsorId=sponsorId;
    let filteredArray = this.getAllSponsorsData.filter((value) =>  value.sponsorId == this.sponsorId );
    if (filteredArray.length != 0) {
     this.sponsorName=filteredArray[0].sponsorName;

   }
  }
}
