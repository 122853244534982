import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-download-claim',
  templateUrl: './download-claim.component.html',
  styleUrls: ['./download-claim.component.scss']
})
export class DownloadClaimComponent implements OnInit {
  columnDefs: ColDef[] = [];
  downloadCliam: any[] = [];
  rowGroupPanelShow = "always";
  public context;
  public gridApi;
  public statusBar;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  public loadingTemplate;
  public noRowsTemplate;
  public frameworkComponents;

  constructor() { }

  ngOnInit(): void {
    this.context = { componentParent: this };

    this.columnDefs = [
      {
        headerName: "Claim REFNo",
        field: "cliamRefno",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Dowload Status",
        field: "dStatus",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },

    ];

  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

}
