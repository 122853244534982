import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ConfigurationService } from '../../client/services/configuration.service';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-communication-config',
  templateUrl: './communication-config.component.html',
  styleUrls: ['./communication-config.component.scss']
})
export class CommunicationConfigComponent implements OnInit {

  form: FormGroup;
  globals: globalvars = new globalvars();
  BranchesId: any;
  subscription: Subscription;
  // button = 'Save';
  isLoading = false;
  // private loading=new BehaviorSubject<boolean>(false);
  functionPermissionData: any;

  constructor(
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      BranchId: new FormControl(this.globals.HospitalId),
      APIUrl: new FormControl(''),
      UserName: new FormControl(''),
      SMSPassword: new FormControl(''),
      MailSender: new FormControl(''),
      MailPassword: new FormControl(''),
      SMTP: new FormControl(''),
      SMTPPort: new FormControl('')
    });

    this.GetCommunicationConfiguration();
    this.functionPermission();
  }

  SaveSettings() {
    const foundElement = this.functionPermissionData.find(x => x.id === 76)
    if (foundElement.id == 76 && foundElement.isAllowed == true) {
    let formata = this.form.getRawValue();
    this.isLoading = true;
    this.configurationService.SaveCommunication(formata).subscribe(
      (res) => {
        if (res.status == 200 && res.message == "Success") {
          this.isLoading = false;
          this.toastr.success("Data saved successfully");
        } else if (res.status == 200) {
          this.isLoading = false;
          this.toastr.error("Failed : " + res.message);
        } else {
          this.isLoading = false;
          this.toastr.error("Failure: " + res.response.message);
        }
      },
      (err) => {
        this.isLoading = false;
        this.toastr.error("Some unexpected error occures try again later");
      }
    );
    } else {
      this.toastr.warning("You are not allowed to save data");
    }
  }

  GetCommunicationConfiguration() {
    let input = {
      "BranchId": this.globals.HospitalId
    }
    this.configurationService.GetCommunicationConfiguration(input).subscribe((res) => {
      let response = res.response;
      response = this.upper(response);
      this.form.patchValue(response);
      this.form.controls['APIUrl'].setValue(response.ApiUrl);
      this.form.controls['SMSPassword'].setValue(response.SmsPassword);
      this.form.controls['SMTP'].setValue(response.Smtp);
      this.form.controls['SMTPPort'].setValue(response.SmtpPort);


    });
  }

  upper(obj) {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      let first = key.substr(0, 1).toUpperCase();
      first = first + key.substr(1);

      newobj[first] = obj[key];
    }
    return newobj;
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.configurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }
}
