import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ColDef } from 'ag-grid-community';
import { BillingGeneralActionComponent } from '../billing-general-action/billing-general-action.component';

@Component({
  selector: 'app-pending-bill',
  templateUrl: './pending-bill.component.html',
  styleUrls: ['./pending-bill.component.scss']
})
export class PendingBillComponent implements OnInit {

  modalRef: BsModalRef;
  @ViewChild(SearchPatientModalComponent)modaltemp: SearchPatientModalComponent;


  // GRID DETAILS

  public statusBar;
  rowGroupPanelShow = "always";
  public gridApi;
  columnDefs: ColDef[] = [];
  billDetails=[{
    date:'15-11-2022',
    billNo:'REF001',
    regNo:'REG 001',
    patientName:'Test Name',
    location:'Front Office',

    amount:'1000',

  }];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  public context;
  public frameworkComponents;
  public loadingTemplate;
  public noRowsTemplate;

  constructor() { }

  ngOnInit(): void {

    this.context = { componentParent: this };

    this.columnDefs = [
      {
        headerName: "Date",
        field: "date",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Bill No.",
        field: "billNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Reg No.",
        field: "regNo",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Patient Name",
        field: "patientName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Amount",
        field: "amount",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Location",
        field: "location",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "pendingBills" },
      },
    ];
    this.frameworkComponents = { buttonRender: BillingGeneralActionComponent};
  }

  showBasicDialog() {
    this.modaltemp.open();

  }


  onGridReady(params) {
    this.gridApi = params.api;
  }


  searchevent(patient: any) {
    //this.form.controls['RegNo'].setValue(patient.data.regNo);
    // this.patientId=patient.data.patientId;
    // this.PatientInformation=patient.data;
    // this.loadPreviousCredits();
    // this.getOpenCreditsByPatient();
  }
}
