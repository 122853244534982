import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GuiCellView, GuiColumn, GuiPaging, GuiPagingDisplay, GuiSearching, GuiSorting, GuiColumnMenu, GuiInfoPanel, GuiDataType } from '@generic-ui/ngx-grid';
import { GeneralActionComponent } from '../../../general-action/general-action.component';
import { ServiceOrderingService } from '../../../services/ServiceOrderingService';
import { SharedService } from '../../../../../shared/shared.service';
import { globalvars } from '../../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HospitalsService } from '../../../services/hospitals.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { TelerikPrintComponent } from '../../../../../shared/modals/telerik-print/telerik-print.component';

@Component({
  selector: 'app-service-list-modal',
  templateUrl: './service-list-modal.component.html',
  styleUrls: ['./service-list-modal.component.scss']
})

export class ServiceListModalComponent implements OnInit {
  @Input() ConsultationId:number
  user_id: string;
  modalRef?: BsModalRef;
  serviceorder: boolean = true;
  orderdetails: any;
  pendingorder: boolean;
  sourceProducts: any[];
  roducts: any[];
  radioSelected: number;
  radiodate1: boolean = false;
  targetProducts: any[];
  activeState: boolean[] = [true, false, false];
  displayBasic: boolean;
  patients: any[];
  columnDefs: ColDef[] = [];
  columnDefsMoreDetails: ColDef[] = [];
  OrderById: {}[];
  rowData: {}[];
  subscription: Subscription;
  public params: any;
  globals: any;
  userType: any;
  public gridApi;
  public rowSelection = true;
  public enableCellTextSelection = true
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public statusBar;
  active: string;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.itemStatus == "Cancelled";

      },
      "c-reds2": function (params) {
        return params.data.isCancelled == 1;

      },
    },

    domLayout: "autoHeight",
  };
  rowGroupPanelShow = "always";



  paging: GuiPaging = {
    enabled: true,
    page: 1,
    pageSize: 10,
    pageSizes: [10, 25, 50],
    pagerTop: false,
    pagerBottom: true,
    display: GuiPagingDisplay.ADVANCED
  };

  sorting: GuiSorting = {
    enabled: true
  };

  searching: GuiSearching = {
    enabled: true,
    placeholder: 'Search Here'
  };
  GuiColumnMenu = {
    enabled: true,
    columnsManager: true
  };

  infoPanel: GuiInfoPanel = {
    enabled: true,
    infoDialog: false,
    columnsManager: true,

  };
  BranchesId: any;
  serviceOrderResponse: any;
  blockorunblock: boolean;
  confirmblock: any;
  OrderId: any;
  searchForm: FormGroup;
  BlockResponse: any;
  PrintOrder: any;
  OrderDetails: any;
  hospitalList: any;
  //OrderById: any;

  selectedOrderId: any;
  ItemIds: any;
  itemId: any;
  Itemdata: any;
  minDate: Date;
  valueDate: string;
  valueDate2: string;
  ConsultantId: any;
  serviceDateFrom: any;
  serviceDateTo: any;
  functionPermissionData: any;

  constructor(
    private formBuilder: FormBuilder,
    private ServiceOrderingService: ServiceOrderingService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private router: Router,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService,
    private activeModal: BsModalRef,
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        // this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId

      }
    });

  }


  onTab(number) {
    this.serviceorder = false;
    this.orderdetails = false;
    this.pendingorder = false;


    if (number == '1') {
      this.serviceorder = true;
    }
    else if (number == '2') {
      this.orderdetails = true;
    }
    else if (number == '3') {
      this.pendingorder = true;
    }

  }
  ngOnInit(): void {

    this.userType = localStorage.getItem("usertype");
    this.ConsultantId = localStorage.getItem('Id');
    this.minDate = new Date();
    let dte = new Date();
    dte.setDate(dte.getDate() - 31);
    this.rowData = [];
    this.OrderById = [];

    let currentappDate = new Date();
    this.valueDate2 = this.datepipe.transform(currentappDate, 'dd-MM-yyyy');
    let convertDate = currentappDate.setDate(currentappDate.getDate() - 7);

    this.valueDate = this.datepipe.transform(convertDate, 'dd-MM-yyyy');
    this.GetAllServicesOrders()

    this.searchForm = this.formBuilder.group({

      blockReason: new FormControl(''),

    });


    this.columnDefs = [
      {
        headerName: "Order No",
        minWidth: 90,
        field: "orderNo",
        enableRowGroup: false,
        resizable: true,
        // rowGroup: true,
        // hide: true,
        sortable: true,
        filter: true,
        flex: 2,

      },


      {
        headerName: "Service Item",
        minWidth: 80,
        field: 'itemName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Status",
        minWidth: 100,
        field: 'itemStatus',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Payment",
        minWidth: 100,
        field: 'payStatus',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: false,
        flex: 2,

      },


      // {
      //   headerName: "Action",
      //   maxWidth: 50,
      //   pinned: "right",
      //   sortable: false,
      //   resizable: false,
      //   unSortIcon: true,
      //   field: "Id",
      //   flex: 2,
      //   cellRenderer: "buttonRender",
      //   cellRendererParams: { PageType: "ServiceList" },
      // },
    ];


    this.columnDefsMoreDetails = [
      {
        headerName: "Order No",
        minWidth: 100,
        field: "orderNo",
        enableRowGroup: false,
        resizable: true,
        // rowGroup: true,
        // hide: true,
        sortable: true,
        filter: true,
        flex: 2,

      },

      {
        headerName: "Order Date",
        minWidth: 110,
        field: 'orderDate',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Ordered By",
        minWidth: 120,
        field: 'consultantName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },
      {
        headerName: "Reg.No.",
        minWidth: 90,
        field: 'regNo',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: true,
        flex: 2,

      },

      {
        headerName: "Patient Name",
        minWidth: 180,
        field: 'firstName',
        sortable: true,
        filter: true,
        enableRowGroup: false,
        resizable: false,
        flex: 2,

      },


      {
        headerName: "Action",
        maxWidth: 50,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "OrderDetailsModal" },
      },
    ];

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };

    this.functionPermission();

  }
  Activetab(pagetitle) {
    const foundElement = this.functionPermissionData.find(x => x.id === 246)
    if (foundElement.id == 246 && foundElement.isAllowed == true) {
      sessionStorage.setItem("pageTitle", pagetitle);
      this.router.navigate(["/serviceOrder"]);
    } else {
      this.toastr.warning("You are not allowed to add new service order");
    }
  }


  showBasicDialog() {
    this.displayBasic = true;
  }

  //Getting Api call for listing service orders
  GetAllServicesOrders() {
  
      this.ServiceOrderingService.GetServicesOrderByConsultantId(this.globals.HospitalId,this.ConsultationId).subscribe((res) => {
        this.rowData = res.response;
      });
    
  }

  //On selection changed
  onSelectionChanged(eventData) {
    console.log(eventData);
    
    this.selectedOrderId = eventData.data.orderId;
    this.GetAllServicesOrdersById(this.selectedOrderId)
  }

  //get Order details by OrderId
  GetAllServicesOrdersById(selectedOrderId) {
    if (selectedOrderId) {
      this.ServiceOrderingService.GetAllServicesOrdersById(this.selectedOrderId).subscribe((res) => {
        this.OrderById = res.response;
      });
    }
    else {
      this.noRowsTemplate = "No Rows To Show"
      this.gridApi.hideOverlay();
    }
  }


  //cancel orders
  showCancelOrders(orderId, grid) {
    let actionId;
    if (grid == 'OS') {
      actionId = 248;
    } else if(grid == 'OD') {
      actionId = 250;
    }
    const foundElement = this.functionPermissionData.find(x => x.id === actionId)
    if (foundElement.id == actionId && foundElement.isAllowed == true) {
    this.OrderDetails = orderId.subType
    let OrderIds = orderId.orderId
    this.ItemIds = orderId.itemId
    this.OrderId = OrderIds
    this.blockorunblock = true;
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  confirmblocking() {
    this.confirmblock = true;
  }

  ConfirmBlock() {

    let blockReason = this.searchForm.get("blockReason").value;
    if (blockReason == "") {
      this.toastr.warning("Enter Block Reason");
    }
    else {
      this.ServiceOrderingService.CancelServiceOrder(this.OrderId, blockReason, this.ItemIds).subscribe((res) => {
        this.BlockResponse = res.response;
      });
    }
    this.searchForm.get("blockReason").reset();
    setTimeout(() => {
      this.GetAllServicesOrders();
      this.GetAllServicesOrdersById(this.selectedOrderId);
    }, 1000);
  }
  

  //  checking Agi grid is loaded completely
  onGridReady(params) {
    this.gridApi = params.api;
    setTimeout(() => {
      this.GetAllServicesOrdersById(this.selectedOrderId);
    }, 1000);
  }


  //print reports
  PrintOrderReportCardAll(orderId, pagetype, itemId) {
    // const foundElement = this.functionPermissionData.find(x => x.id === 247)
    // if (foundElement.id == 247 && foundElement.isAllowed == true) {
      let initialState = {
        modalHeading: 'Print Service Order Details',
        reportName: 'RptServiceOrderDetails.trdp',
        reportParams: {
          ConsultationId: 0,
          OrderId: orderId,
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    // }
    //  else {
    //   this.toastr.warning("You are not allowed");
    // }
   
  }



  print() {
    this.PrintOrder

  }

  

  showMoreItemsDialog(consultation: any) {
    this.displayBasic = true;
  }


  openWorkListModal(data: any){
    // console.log(data)
    // let initialState = {
    //   itemData: data
    // }
    // let config: ModalOptions = {
    //   backdrop: true,
    //   ignoreBackdropClick: true,
    // }
    // const workListModalRef= this.modalService.show(WorkListManageComponent,Object.assign( {}, config, { class: 'modal-xl', initialState }) );
    // this.modalRef.content.settlementEventEmit.subscribe(res=>{
    //   if(res.status){
    //     this.GetBillList();
    //   }
    // });
    const foundElement = this.functionPermissionData.find(x => x.id === 251)
    if (foundElement.id == 251 && foundElement.isAllowed == true) {
      let payload={
        patientId:data.patientId,
        consultationId:data.consultationId,
        orderDetId:data.orderDetId
      }
      localStorage.setItem("reportWorkListData",JSON.stringify(payload))
      this.router.navigate(['/ServiceList/'+data.orderId+'/report/',data.itemId]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  autoInitiate(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 249)
    if (foundElement.id == 249 && foundElement.isAllowed == true) {
      // need to write the functionalities inside this if condition(This is for functional permission)
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  close(){
    this.activeModal.hide();
  }
}
