import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EMRService } from '../../../services/emr.service';
import { EMRPatientInfo } from '../../../interfaces/EMRPatientInfo';
import { ViewMenstrualHistoryModalComponent } from '../view-menstrual-history-modal/view-menstrual-history-modal.component';
import { ViewSignSymptomsIcdModalComponent } from '../view-sign-symptoms-icd-modal/view-sign-symptoms-icd-modal.component';

@Component({
  selector: 'app-prev-signs-symptoms-icd-modal',
  templateUrl: './prev-signs-symptoms-icd-modal.component.html',
  styleUrls: ['./prev-signs-symptoms-icd-modal.component.scss']
})
export class PrevSignsSymptomsIcdModalComponent implements OnInit {

  @Input() EmrPatientInfo: EMRPatientInfo;

  public signsSymptomsICDList: any[] = [];

  constructor(private activeModalRef: BsModalRef,
              private EmrService: EMRService,
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.GetPrevNarrativeDiagonosis();
  }

  GetPrevNarrativeDiagonosis()
  {
   let input={
     "VisitId":this.EmrPatientInfo.VisitId,
     "PatientId":this.EmrPatientInfo.PatientId,
     "ShowAll":0,
     ConsultationId: this.EmrPatientInfo.ConsultationId
   };
 
   this.EmrService.GetNarrativeDiagonosis(input).subscribe(
     (res) => {
       if(res.status==200)
       {
         let response=res.response
         this.signsSymptomsICDList=response;
       }
     });
  }

  close(){
    this.activeModalRef.hide();
  }

  viewDetails(entryId: any){
    let initialState = {
      entryId: entryId,
      patientId: this.EmrPatientInfo.PatientId,
      consultationId: this.EmrPatientInfo.ConsultationId
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const modalRef= this.modalService.show(ViewSignSymptomsIcdModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

}
