import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, GridApi } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../../../_Models/Response';
import { ClaimService } from '../../services/claim.service';

@Component({
  selector: 'app-change-approval-modal',
  templateUrl: './change-approval-modal.component.html',
  styleUrls: ['./change-approval-modal.component.scss']
})
export class ChangeApprovalModalComponent implements OnInit {

  @Input() transId: any = 0;
  @Input() branchId: any = 0;
  @Input() isExternal: boolean = false;
  
  @Output() changeApprovalEmitter = new EventEmitter();

  private gridApi: GridApi;
  public columnDefs: ColDef[] = [];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 20,
  }
  public context;
  public frameworkComponents;
  public loadingTemplate;
  public noRowsTemplate;

  public billItems: any[] = [];
  public saveApprovalLoading: boolean = false;
  
  public approvalForm: FormGroup;

  constructor(private modalRef: BsModalRef,
              private claimService: ClaimService,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.columnDefs = [
      {
        headerName: "Item Name",
        field: "itemName",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Qty",
        field: "qty",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Rate",
        field: "rate",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Approval Number",
        field: "aprovalNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
        editable: true
      },
    ]

    this.initApprovalForm();
  }

  initApprovalForm(){
    this.approvalForm = this.formBuilder.group({
      approvalNumber: new FormControl('', Validators.required)
    })
  }

  onGridReady(params){
    this.gridApi = params.api;
    this.getBillItems();
  }

  getBillItems(){
    let payload = {
      Transid: this.transId,
      Branchid: this.branchId,
      IsExternal: this.isExternal,
      ShowAll: 0  
    }

    this.gridApi.showLoadingOverlay();
    this.claimService.getTransDetails(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.billItems = response.response;
        }else{
          this.toastrService.error(response.message);
        }
        this.gridApi.hideOverlay();
      },error: (error: any)=>{
        this.toastrService.error('Failed to load transaction details, Try agian');
        this.gridApi.hideOverlay();
      }
    })
  }

  onApplyApprovalNumber(){
    const controls = this.approvalForm.controls;
    if(this.approvalForm.invalid){
      Object.keys(controls).forEach(control=>{
        controls[control].markAllAsTouched();
      })
      return
    }

    if(this.billItems.length > 0){
      this.billItems.forEach(item=> {
        item.aprovalNo = this.approvalForm.value.approvalNumber
      });
      this.gridApi.redrawRows();
    }else{
      this.toastrService.warning('Bill has no items to apply..')
    }
  }

  onSaveApprovalNumber(){

    let payload = {
      ClaimDetailList: [],
      IsExternal : this.isExternal
    }

    this.billItems.forEach(billItem=>{
      let item = {
        Transid: this.transId,
        ItemId: billItem.itemId,
        AprovalNo: billItem.aprovalNo
      }
      payload.ClaimDetailList.push(item)
    });
    this.saveApprovalLoading = true;
    this.claimService.updateApprovalNumber(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == 'Approval Number Updated'){
          this.toastrService.success('Approval number updated successfully.');
          this.changeApprovalEmitter.emit({status: true});
          this.close();
        }else{
          this.toastrService.error(response.message);
        }
        this.saveApprovalLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Failed to update approval number..');
        this.saveApprovalLoading = false;
      }
    })
  }

  close(){
    this.modalRef.hide();
  }
}
