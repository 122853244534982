import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-credit-items-modal',
  templateUrl: './credit-items-modal.component.html',
  styleUrls: ['./credit-items-modal.component.scss']
})
export class CreditItemsModalComponent implements OnInit {

  @Input() creditItems: any[] = [];
  @Input() groupName: string = '';

  public loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
  public noRowsTemplate = `"<span">no rows to show</span>"`;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight'
  };
  public gridApi: GridApi;
  public dataColumns: ColDef[] = [];

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {

    this.dataColumns = [
      {
        headerName: "Item Name",
        field: "itemName",
        resizable: true,
        sortable: true,
        flex: 3,
        filter: true,
        editable: false
      },
      {
        headerName: "Rate",
        field: "rate",
        resizable: true,
        sortable: true,
        flex: 3,
        editable: false
      },
      {
        headerName: "Deduction / Copay",
        field: "",
        valueGetter: params=> {
          let value = '';
          value = params.data.dedItem ? 'Applicable / ' : 'Not Applicable / ';
          value = params.data.coPayItem ? value+'Applicable' : value+'Not Applicable';
          return value;
        },
        resizable: true,
        sortable: true,
        flex: 3,
        editable: true
      },
    ]
  }

  onGridReady(params){
    this.gridApi = params.api;
  }

}
