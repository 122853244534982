import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.scss']
})
export class FilePreviewModalComponent implements OnInit {

  @Input() fileUrl: any;

  constructor(private modalRef: BsModalRef,
              private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.fileUrl)
  }

  close(){
    this.modalRef.hide();
  }

}
