
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SponsorFormConfigList } from '../../client/interfaces/SponsorFormConfig';
import { SponsorFormConfig } from '../../client/services/sponsorFormConfigService';
import { ColDef } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { ConfigurationService } from '../../client/services/configuration.service';
import { HospitalsService } from '../../client/services/hospitals.service';
import { SponsorsService } from '../../client/services/sponsors.service';

@Component({
  selector: 'app-sponsor-form',
  templateUrl: './sponsor-form.component.html',
  styleUrls: ['./sponsor-form.component.scss']
})
export class SponsorFormComponent implements OnInit {

  sponsorForm: FormGroup;
  modalRef: BsModalRef;
  SponsorFormConfigList: SponsorFormConfigList;
  public submitted: boolean = false;
  SponsorForms: any;
  result: any;
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  isLoading=false;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  rowData: any;
  public statusBar;
  SponsorFormDetails: any;
  SFormId: number;
  SponsorPopupHeading:String="Add Insurance Form";
  src :any= '';
  private baseUrl = "";

  @ViewChild("template") modaltemp: any;
  SponsorFormId: any;
  functionPermissionData: any;
  departmentList: any;
  subscription: Subscription;
  private globals: globalvars = new globalvars();
  getAllSponsorsData:any;

  constructor(
    private SponsorFormConfig: SponsorFormConfig,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private configurationManager: ConfigurationManager,
    private sanitizer: DomSanitizer,
    private router: Router,
    private ConfigurationService: ConfigurationService,
    private hospitalsService: HospitalsService,
    private SponsorsService: SponsorsService,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      }
    });
  }

  ngOnInit(): void {

    this.sponsorForm = new FormGroup({
      SFormId: new FormControl(0),
      SFormName: new FormControl('', [Validators.required]),
      IsDisplayed: new FormControl(true),
      IsDeleted: new FormControl(0),
      DepartmentId: new FormControl(0, [Validators.required]),
      SponsorId: new FormControl(0, [Validators.required])
    });

    this.GetAllSponsors();
    this.GetDepartments();

    this.columnDefs = [

      {
        headerName: "Form Name",
        field: "sFormName",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,

      },
      {
        headerName: "Insurance",
        field: "sponsorName",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Department",
        field: "departmentName",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        field: "action",
        enableRowGroup: true,
        resizable: false,
        sortable: false,
        flex: 2,
        maxWidth: 80,
        pinned: "right",
        filter: false,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "SponsorFormist" },
      },

    ]


    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };





    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };



    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;



    this.getALLSponsorForms();
    this.functionPermission();
  }

  openModalSponsorForm(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 144)
    if (foundElement.id == 144 && foundElement.isAllowed == true) {
    this.SponsorPopupHeading="Add Insurance Form";
    this.modalRef = this.modalService.show(template);
    // this.ProfId = 0;
    this.ResetFunction();
    } else {
      this.toastr.warning("You are not allowed to add data");
    }
  }


  getALLSponsorForms() {
    let Input = {
      "ShowAll": 1,
      "SFormid": 0

    }
    this.SponsorFormConfig.GetAllSponsorForm(Input).subscribe((res) => {
      this.SponsorForms = res.response;
    });

  }
  // for geting all form items

  get f() {
    return this.sponsorForm.controls;
  }
  SaveSponsorForm() {

    this.SponsorFormDetails = this.sponsorForm.getRawValue();
    let FormId = this.SponsorFormDetails.SFormId;
    this.SponsorFormDetails.SFormId = Number(FormId) ? Number(FormId) : this.SponsorFormId;
    this.SponsorFormDetails.IsDeleted = 0;
    this.SponsorFormDetails.DepartmentId = Number(this.SponsorFormDetails.DepartmentId);
    this.SponsorFormDetails.SponsorId = Number(this.SponsorFormDetails.SponsorId);
    if(Number(this.SponsorFormDetails.SponsorId) == 0){
      this.toastr.warning('Please select insurance');
      return
    }

    this.submitted = true;
    if (this.sponsorForm && this.sponsorForm.valid) {
      this.isLoading=true;

      this.SponsorFormConfig.InsertSponsorForm(this.SponsorFormDetails).subscribe((res) => {
        this.submitted = false;

        this.result = res;
        let SFormId = 0;

        if (this.result.status == 200 && this.result.message == "Saved Successfully") {
          if (SFormId == 0) {
            //this.clearSponsorForm();
            this.isLoading=false;
            this.toastr.success("Insurance Form Added Successfully");
          } else if (this.result.status == 200 && this.result.message == "Saved Successfully") {
            this.isLoading=false;
            this.toastr.success("Insurance Form Updated Successfully");
            this.SFormId = 0;
          }
          this.getALLSponsorForms();
          this.modalRef.hide();

        }

        else {
          this.isLoading=false;
          this.toastr.error("Failed : " + this.result.message);
        }

      });
    }
  }
  ResetFunction() {
    this.submitted = false;
    this.clearForm();
  }
  // to clear form
  clearForm() {
    this.sponsorForm.reset({
      sponsorForm: '',
      IsDisplayed: true,
      SponsorId: 0,
      DepartmentId: 0
    });
  }

  EditSponsorForms(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 145)
    if (foundElement.id == 145 && foundElement.isAllowed == true) {
    this.SponsorPopupHeading="Edit Insurance Form";
    this.modalRef = this.modalService.show(
      this.modaltemp,
      Object.assign({}, {})
    );
    this.SponsorFormId = data.sFormId;
    this.sponsorForm.patchValue({
      SFormName: data.sFormName,
      IsDisplayed: data.isDisplayed,
      DepartmentId: data.departmentId,
      SponsorId: data.sponsorId
    });
    } else {
      this.toastr.warning("You are not allowed to edit data");
    }

  }
  DeleteSponsorForm(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 146)
    if (foundElement.id == 146 && foundElement.isAllowed == true) {
    var AppData = {
      SFormName: data.sFormName,
      sFormId: data.sFormId,
      IsDisplayed: data.isDisplayed,
      IsDeleted: 1
    }
    this.SponsorFormConfig.InsertSponsorForm(AppData).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200 && this.result.message == "Saved Successfully") {

        this.toastr.success("Insurance Form Deleted Successfully");

      } else {
        this.toastr.error("Failed : " + this.result.message);
      }
      this.getALLSponsorForms();
      this.modalRef.hide();
    });
    } else {
      this.toastr.warning("You are not allowed to delete data");
    }

  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  formPreview(data){
    const foundElement = this.functionPermissionData.find(x => x.id === 147)
    if (foundElement.id == 147 && foundElement.isAllowed == true) {
    // let input=this.sponsorForm.getRawValue();
    // input.SFormId =Number(data.sFormId);
    // input.ConsultationId=Number(0);

    let input={
      'SFormId':Number(data.sFormId)
    }

   //this.router.navigate(['/config/printForm'], { state: { input: input } });

    this.SponsorFormConfig.PrintSponsorForm(input).subscribe(
      (res) => {
        if(res.status==200){
          let response=res.response;
          this.src=response[0].formReturnPath;
          this.src=this.baseUrl+this.src;
          window.open(this.src, "_blank");
        //// this.router.navigate([]).then(result => {  window.open("/config/printForm", '_blank'); });

        //this.router.navigate(['/config/printForm'], { state: { input: input } });
        }

      });
    } else {
      this.toastr.warning("You are not allowed");
    }

  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }

  GetDepartments() {
    let showAll = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, showAll).subscribe((res) => {
      this.departmentList = res.response;
    });
  }

  GetAllSponsors(){
    let Input={
      "SponsorId":0,
      "Showall":1,
      "BranchId":this.globals.HospitalId
    }
    this.SponsorsService.GetAllSponsorList(Input).subscribe((res) => {
      this.getAllSponsorsData = res.response;
      // this.getAllSponsorsData.push(this.sponsorObj);
      // console.log('this.getAllSponsorsData', this.getAllSponsorsData);
    });
  }

}
