import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-credit-claims',
  templateUrl: './manage-credit-claims.component.html',
  styleUrls: ['./manage-credit-claims.component.scss']
})
export class ManageCreditClaimsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
