import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';

import { Response } from '../../../_Models/Response';
import { UserProfile } from '../interfaces/UserProfile';
import { ErrorHandleService } from './error-handle.service';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle:ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  Login(UserName,Password){
  var url = "api/Account/Login";
  return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
  {
    "Username":UserName,
     "Password":Password
  }).pipe(
    retry(1),
    catchError(this.errorHandle.handleError)
  );
}
}
