import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PrevVitalSignsModalComponent } from '../modals/prev-vital-signs-modal/prev-vital-signs-modal.component';
import { Observable, Observer, Subscription, noop, of } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../../shared/shared.service';
import { EMRService } from '../../services/emr.service';
import * as _ from 'lodash';
import { ConsultantDeskService } from '../../../client/services/consultant.service';
import { ConfigurationService } from '../../../client/services/configuration.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { Response } from '../../../../_Models/Response';
import { ConfigurationManager } from '../../../../../assets/configuration/configuration-manager';
import { PrevDrugsAndMedicationModalComponent } from '../modals/prev-drugs-and-medication-modal/prev-drugs-and-medication-modal.component';

import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { TelerikPrintComponent } from '../../../../shared/modals/telerik-print/telerik-print.component';
import { SettingsService } from '../../../settings/services/settings.service';
import { Settings } from '../../../settings/model/settings.model';
import { DrugDosageComponent } from '../modals/drug-dosage/drug-dosage.component';
import { AddEditFrequencyComponent } from '../../modals/add-edit-frequency/add-edit-frequency.component';
import { DatePipe } from '@angular/common';
import { PatientService } from '../../../client/services/patient.service';

@Component({
  selector: 'app-drugs-medication',
  templateUrl: './drugs-medication.component.html',
  styleUrls: ['./drugs-medication.component.scss']
})
export class DrugsMedicationComponent implements OnInit, OnDestroy,OnChanges {
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  @Input() functionPermissionData
  disabled:boolean=false
  
  public EmrPatientInfo:any;
  private globals: globalvars = new globalvars();
  private subscription: Subscription;
  private FormValueChangeChecker: boolean = false;

  public drugsMedicationForm: FormGroup;
  public allDosages: any[] = [];
  public allRoutes: any[] = [];
  public allFrequencies: any[] = [];
  
  private drugsFrequentlyUsed: any[] = [];
  public frequentlyDrugsDropDown: any[] = [];

  public drugSearch: string = '';
  public searchType: any = 1;
  public drugObservale$?: Observable<any[]>;
  public totalAmount: any = 0;

  private baseUrl: string = '';

  public showAllItems: boolean = false;
  isCancelERX:boolean=false

  public dropdownOptions: Options;
  public dropdownDataConfig: any = {
    showCovered: true,
    showFrequent: true
  }
  configSetting:any;
  @Output() drugTotal = new EventEmitter<any>()
  eReference: any;
  dhaRule: boolean;
  HealthInformationPlatform: any;
 

  constructor(private modalService: BsModalService,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private sharedService: SharedService,
              private EmrService: EMRService,
              private configurationManager: ConfigurationManager,
              private ConsultantDeskService: ConsultantDeskService,
              private settingsService: SettingsService,
              private ConfigurationService: ConfigurationService,
              private http: HttpClient,
              private datePipe: DatePipe,
              private patientService:PatientService,
            ) { }

  ngOnInit(): void {

    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
      }
    });
    this.getAppSettings()
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.EmrPatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initForm();

    this.GetDosage();
    this.GetFrequency();
    this.GetRoute();
    this.LoadDrugFrequently();
    this.GetDrugsEMR();
    this.onSearchDrugs();
    this.GetEPrescriptionDetails()

    this.dropdownOptions = {
      templateResult: this.templateResult,
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 42 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (isSettingWithValueZeroPresent) {
        // Your logic when a setting with value 0 is present and billEdit is true
      
        this.disabled=true
      }
      
    }
  }

  // function for result template
  public templateResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    let icon = '<i class="fa" aria-hidden="true"></i>';
    if (state.additional.insCovered) {
      icon = '<i class="fa fa-umbrella" aria-hidden="true"></i>';
    }
    return jQuery('<span class="d-flex justify-content-between"><b>'+ state.text + '   '+icon+ '</b></span>');
  }


  initForm(){
    this.drugsMedicationForm = this.formBuilder.group({
      drugDetails: new FormArray([]),
      visitId: new FormControl(this.EmrPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem("user_id"))),
    })
  }

  GetDrugsEMR() {
    let input = {
      VisitId: this.EmrPatientInfo.VisitId,
      PatientId: this.EmrPatientInfo.PatientId,
      ShowAll: 1,
      ConsultationId: this.EmrPatientInfo.ConsultationId
    };

    this.EmrService.GetDrugsEMR(input).subscribe((res) => {
      if (res.status == 200) {
        let response = res.response[0];
        this.dhaRule=response.isDHARuleEnabled == 1 ? true : false 
        if (response) {
          if (response.drugDetails) {
            this.addMapFormGroup(response.drugDetails);
          }
        }
        this.drugsMedicationForm.valueChanges.subscribe(x => {
          this.FormValueChangeChecker = true;
        })
      }
    });
  }

  GetDosage() {
    let ShowAll = 0;
    this.ConfigurationService.GetDosage(
      this.globals.HospitalId,
      ShowAll
    ).subscribe((res) => {
      this.allDosages = res.response;
    });
  }

  GetRoute() {
    this.ConsultantDeskService.GetRoute(this.globals.HospitalId).subscribe(
      (res) => {
        this.allRoutes = res.response;
      }
    );
  }

  GetFrequency() {
    let ShowAll = 0;
    this.ConfigurationService.GetFrequency(
      this.globals.HospitalId,
      ShowAll
    ).subscribe((res) => {
      this.allFrequencies = res.response;
    });
  }

  onSearchDrugs(){
    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.drugObservale$ = new Observable(
      (observer: Observer<string | undefined>) => {
        observer.next(this.drugSearch);
      }
    ).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.http
            .post<Response<any[]>>(
              `${this.baseUrl}api/EMR/GetDrugsAutoComplete`,
              {
                drugName: this.searchType == 1 ? query : '',
                branchId: this.globals.HospitalId,
                consultantId: 0,
                TradeName: this.searchType == 2 ? query : '',
                ScientificName: this.searchType == 3 ? query : '',
                ConsultationId: this.EmrPatientInfo.ConsultationId
              }
            )
            .pipe(
              map((data) => (data && data.response) || []),
              tap(
                () => noop,
                (err) => {
                  let errorMessage =
                    (err && err.message) || "Something goes wrong";
                  this.toastrService.error(errorMessage);
                }
              )
            );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */
  }

  LoadDrug(drugInfo) {
    let input = {
      consultantId: 0,
      drugId: drugInfo.drugId,
      BranchId: this.globals.HospitalId,
      ConsultationId: this.EmrPatientInfo.ConsultationId
    };
    input;
    this.EmrService.GetConsultantDrugs(input).subscribe((res) => {
      if (res.status == 200) {
        console.log(res)
        let DrugRow = res.response;
        this.addMapFormGroup(DrugRow);
      }
    });
  }

  private createListGroup(data: any[] = [], saved = 0): FormGroup {
    if (!data['duration']) data['duration'] = "";
    if (!data['qty']) data['qty'] = "";

    if (!data['instruction']) data['instruction'] = "";

    return new FormGroup({
      drugId: new FormControl(data["drugId"]),
      drugName: new FormControl(data["drugName"]),
      tradeName: new FormControl(data["tradeName"]),
      scientificName: new FormControl(data["scientificName"]),
      routeId: new FormControl(data["routeId"]),
      duration: new FormControl(data["duration"]),
      freqId: new FormControl(data["freqId"]),
      DurationMode: new FormControl(data["durationMode"].toString()),
      qtyinMl: new FormControl(data["qtyinMl"]),
      qty: new FormControl(data["qty"] ? data['qty'] : 1),
      instruction: new FormControl(data["instruction"]),
      DosageId: new FormControl(data["dosageId"]),
      insCovered: new FormControl(data['insCovered']),
      packagePrice: new FormControl(data['packagePrice']? data['packagePrice'] : 0),
      granularUnit: new FormControl(data['granularUnit']? data['granularUnit'] : 0),
      packNo: new FormControl(data['packNo']? data['packNo'] : 0),
      rate: new FormControl((Number(data['granularUnit']) !== 0) ? (Number(data["packagePrice"])/Number(data["granularUnit"])).toFixed(2) : 0),
      intakeInstruction:new FormControl(data['intakeInstruction'] ? data['intakeInstruction'] : 'After food'),
      ConsumptionUnit:new FormControl(data['consumptionUnit'] )
    });
  }

  public addMapFormGroup(data: any[] = [], saved = 0) {
    const Drugs = this.drugsMedicationForm.get("drugDetails") as FormArray;
    if (data.length > 0) {
      data.forEach((element, i) => {
        let filteredArray = Drugs.value.filter(value => value.drugId == element.drugId);
        if (filteredArray.length == 0) {
          Drugs.insert(0, this.createListGroup(element, saved));
        }
        else {
          this.toastrService.error("Failure: " + "Drug Already added");
        }
      });
      setTimeout(() => {
        // document?.getElementById("routeId-"+(Drugs.length - 1)).focus();
        document?.getElementById("routeId-"+0).focus();

      }, 500);

    }
    
    this.drugSearch = "";
    this.calculateTotalAmount();
  }

  RemoveFromDrugList(index) {
    var Value = new FormArray([]);
    Value = this.drugsMedicationForm.get("drugDetails") as FormArray;
    Value.removeAt(index);
    this.calculateTotalAmount();
  }

  openHistory(){
    let initialState = {
      EmrPatientInfo: this.EmrPatientInfo
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const historyModalRef= this.modalService.show(PrevDrugsAndMedicationModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

  checkValue(i, formValue) {
    let form = this.drugsMedicationForm.controls["drugDetails"].value;
    if (formValue == "duration") {
      let duration = form[i].duration;
      if (!duration) {
        this.toastrService.warning(
          "Warning: " + "Duration cannot be empty, It may cause error"
        );
      }
    }

    if (formValue == "qty") {
      let qty = form[i].qty;
      if (!qty) {
        this.toastrService.warning(
          "Warning: " + "Quantity cannot be empty, It may cause error"
        );
      }
    }

  }
  
  // markHook(info){
  //   if(this.ishookError) return
  //     this.ishookError=true
  //     let hookString="";
  //     hookString="Drug: "+info.drugName  + " , Duration : " + info.duration;
  //     if(info.instruction){
  //       hookString=hookString+" , Notes: "+info.instruction;
  //     }
  //   if (this.EMRPatientInfo.PatientId > 0) {

  //   let formdata={
  //     hookid:0,
  //     hookDesc:hookString,
  //     patientId:Number(this.EMRPatientInfo.PatientId),
  //     branchId: this.globals.HospitalId,
  //     IsDisplayed: true,
  //     IsDeleted: 0
  //   }

  //       this.EMRService.SaveHook(formdata).subscribe((res) => {

  //         if (res.status == 200 && res.message == "Success") {
  //           this.toastr.success(info.drugName + " Successfully added to hook list");
  //           this.ishookError=false
  //         }
  //         else if (res.status == 200) {
  //           this.toastr.error("Failure: " + res.message);
  //           setTimeout(() => {
  //             this.ishookError=false
  //           },5000);
  //         }
  //         else {
           
  //           setTimeout(() => {
  //             this.ishookError=false
  //           },5000);
  //           this.toastr.error("Some unexpected error occures! try again later.");
  //         }
  //       });


  //   }
  // }

  generateInstruction(drug: FormGroup){
    let instruction ='Take';
    let dosageIndex = _.findIndex(this.allDosages, {dosageId: Number(drug.value.DosageId)});
    if(dosageIndex >= 0){
      instruction = instruction+' '+this.allDosages[dosageIndex].dosageDesc;
    }

    if(drug.value.ConsumptionUnit){
      instruction = instruction+' '+drug.value.ConsumptionUnit;
    }

    let frequencyIndex = _.findIndex(this.allFrequencies, {freqId: Number(drug.value.freqId)});
    if(frequencyIndex >= 0){
      instruction = instruction+' '+this.allFrequencies[frequencyIndex].freqDesc;
    }

    if(drug.value.duration){
      instruction = instruction+' For '+drug.value.duration;
    }

    if(drug.value.DurationMode){
      if(drug.value.DurationMode == 1){
        instruction = instruction+' '+ (drug.value.duration == 1 ? 'Hour' : 'Hours');
      }
      if(drug.value.DurationMode == 2){
        instruction = instruction+' '+ (drug.value.duration == 1 ? 'Day' : 'Days');
      }
      if(drug.value.DurationMode == 3){
        instruction = instruction+' '+ (drug.value.duration == 1 ? 'Week' : 'Weeks');
      }
      
    }

    instruction=instruction + ' ' + (drug.value.intakeInstruction)

    drug.patchValue({
      instruction: instruction
    })
  }

  calculateTotalAmount(){
    let drugItems: any[] = (this.drugsMedicationForm.get("drugDetails") as FormArray).value;
    this.totalAmount = 0;
    _.forEach(drugItems, (drug: any)=>{
      const unitPrice = (Number(drug.packagePrice)/Number(drug.granularUnit)).toFixed(2);
      const qunatity = Number(drug.qty);
      this.totalAmount = (Number(this.totalAmount) + Number(unitPrice) * Number(qunatity)).toFixed(2);
      this.drugTotal.emit(this.totalAmount)
    });
  }

  calculateQuantity(drug: FormGroup){
    
    let dosageIndex = _.findIndex(this.allDosages, {dosageId: Number(drug.value.DosageId)});
    let dosage: number = dosageIndex>=0 ? this.allDosages[dosageIndex].dosageValue : 0;
    let frequencyIndex = _.findIndex(this.allFrequencies, {freqId: Number(drug.value.freqId)});
    let frequency: number = frequencyIndex>=0 ? this.allFrequencies[frequencyIndex].freqValue : 0;
    let duration: number = drug.value.duration != '' ? Number(drug.value.duration) : 0;;
    let qunatity: number;
    // if(Number(drug.value.granularUnit) == 1 && Number(drug.value.packNo) == 1){
    
    if( Number(drug.value.packNo) == 1 && drug.value.ConsumptionUnit == "Ml"){
      let totalUsage = (dosage*frequency)*duration;
      if(totalUsage > 0){
        // let quotient = Math.floor(totalUsage/Number(drug.value.qtyinMl));
        // let reminder = totalUsage % Number(drug.value.qtyinMl);
        // qunatity = reminder > 0 ? (quotient+1) : quotient;
        qunatity = Math.ceil(totalUsage / Number(drug.value.qtyinMl));
      }else{
        qunatity = 1;
      }
    }else{
      let totalUsage = (dosage*frequency)*duration;
      if(totalUsage > 0){
        if(totalUsage/drug.value.qtyinMl <= 1){
          qunatity= drug.value.qtyinMl 
      }else{
        debugger
        let addOn=totalUsage % drug.value.qtyinMl == 0 ? 0 : 1
        qunatity=(Math.floor(totalUsage/drug.value.qtyinMl) +addOn) * drug.value.qtyinMl  
      }
      }
      // qunatity = (dosage*frequency)*duration;
    }

     drug.patchValue({
      qty: qunatity ? qunatity : 1
     });
     this.calculateTotalAmount();
  }

  showAllToggle(){
    this.showAllItems = !this.showAllItems;
  }

  LoadDrugFrequently() {
    this.drugsFrequentlyUsed = [];
    let input = {
      consultantId: this.EmrPatientInfo.ConsultantId,
      drugId: 0,
      BranchId: this.globals.HospitalId,
      ConsultationId: this.EmrPatientInfo.ConsultationId,
      IsInsured: this.dropdownDataConfig.showCovered ? 1 : 0,
      IsFreq: this.dropdownDataConfig.showFrequent ? 1 : 0
    };
    this.EmrService.GetConsultantDrugs(input).subscribe((res) => {
      if (res.status == 200) {
        this.drugsFrequentlyUsed = res.response;
        this.frequentlyDrugsDropDown = [];
        this.drugsFrequentlyUsed.forEach((a) => {
          this.frequentlyDrugsDropDown.push({
            id: a.drugId.toString(),
            text: a.drugName,
            additional: {
              insCovered: a.insCovered
            }
          });
        });
        this.frequentlyDrugsDropDown=this.frequentlyDrugsDropDown.reduce((acc, current) => {
          if (!acc.some(item => item.id === current.id)) {
            acc.push(current);
          }
          return acc;
        }, []);
        console.log(this.frequentlyDrugsDropDown)
      }
    });
  }

  LoadFrequentlyUsedDrugs(drugInfo) {
    if (drugInfo) {
      let input = {
        consultantId: this.EmrPatientInfo.ConsultantId,
        drugId: Number(drugInfo),
        BranchId: this.globals.HospitalId,
        ConsultationId: this.EmrPatientInfo.ConsultationId,
        IsInsured: this.dropdownDataConfig.showCovered ? 1 : 0,
        IsFreq: this.dropdownDataConfig.showFrequent ? 1 : 0
      };
      this.EmrService.GetConsultantDrugs(input).subscribe((res) => {
        if (res.status == 200) {
          let Drug = res.response;
          this.addMapFormGroup(Drug);
        }
      });
    }
  }

  print() {
    let initialState;
    if(this.searchType === 1 ) {
      initialState = {
        modalHeading: 'Print Drugs Prescription',
        reportName: 'RptPrintDrugsPrescription.trdp',
        reportParams: {
          VisitId: this.EmrPatientInfo.VisitId,
          ShowAll: 1,
          PatientId: this.EmrPatientInfo.PatientId,
          ConsultationId: this.EmrPatientInfo.ConsultationId,
          BranchId: this.globals.HospitalId
        }
      }
    } else if(this.searchType === 2) {
      initialState = {
        modalHeading: 'Print Trade Prescription',
        reportName: 'RptPrintDrugsPrescriptionwithTradeName.trdp',
        reportParams: {
          VisitId: this.EmrPatientInfo.VisitId,
          ShowAll: 1,
          PatientId: this.EmrPatientInfo.PatientId,
          ConsultationId: this.EmrPatientInfo.ConsultationId,
          BranchId: this.globals.HospitalId
        }
      }
    } else if(this.searchType === 3){
      initialState = {
        modalHeading: 'Print Scientific Prescription',
        reportName: 'RptPrintDrugsPrescriptionwithScientificName.trdp',
        reportParams: {
          VisitId: this.EmrPatientInfo.VisitId,
          ShowAll: 1,
          PatientId: this.EmrPatientInfo.PatientId,
          ConsultationId: this.EmrPatientInfo.ConsultationId,
          BranchId: this.globals.HospitalId
        }
      }
    }
    
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
    // let ReportInput = {
    //   VisitId: this.EmrPatientInfo.VisitId,
    //   PatientId: this.EmrPatientInfo.PatientId,
    // };
    // let Request = {
    //   ReportType: 1,
    //   ReportInput: ReportInput
    // };

    // localStorage.setItem("ReportRequest", JSON.stringify(Request));
    // const url = "#/EMR_prints";
    // window.open(url);
  }

  saveDrugs() {
    if (this.EmrPatientInfo.CrudType != 3 &&
      this.EmrPatientInfo.VisitId > 0 && this.FormValueChangeChecker) {
      let DrugsForm = this.drugsMedicationForm.getRawValue();
      DrugsForm.drugDetails.forEach((a) => {
        a.DosageId = Number(a.DosageId);
        a.routeId = Number(a.routeId);
        a.freqId = Number(a.freqId);
        a.qty=Number(a.qty);
        a.DurationMode=Number(a.DurationMode)
      });
      
      DrugsForm.userId = Number(localStorage.getItem("user_id"));
      this.EmrService.SaveDrugs(DrugsForm).subscribe((res) => {
        if (res.status == 200 && res.message == "Success") {
          this.toastrService.success("Success: " + "Drugs And Medication Details Saved Successfully...");
          this.sendDataToNabid(DrugsForm.drugDetails)
          this.FormValueChangeChecker = false;
        } else {
          this.toastrService.error(res.errorMessage.message)
        }
      });
    }
    else {
      if (!this.FormValueChangeChecker) {
        //this.toastr.warning("Info: " + "No changes detected in the entry");
      }
    }
  }

  markHook(drug: any){
    let formdata={
      hookid:0,
      hookDesc: "Drug: "+drug.drugName  + " , Duration : " + drug.duration + (drug?.instruction ? " , Notes: "+drug.instruction : ''),
      patientId: Number(this.EmrPatientInfo.PatientId),
      branchId: this.globals.HospitalId,
      IsDisplayed: true,
      IsDeleted: 0
    }

    this.EmrService.SaveHook(formdata).subscribe((res) => {
      if (res.status == 200 && res.message == "Success") {
        this.toastrService.success(drug.drugName + " Successfully added to hook list");
      }
      else if (res.status == 200) {
        this.toastrService.error("Failure: " + res.message);
      }
      else {
        this.toastrService.error("Some unexpected error occures! try again later.");
      }
    })
  }
  generateErx(){
    let val = this.configSetting.filter((item) => item.settId == 33)[0].settValue
    let input:any = {
      ConsultationId: this.EmrPatientInfo.ConsultationId,
      BranchId:  this.globals.HospitalId,
      SponsorId: this.EmrPatientInfo.SponsorId,
      PatientId:this.EmrPatientInfo.PatientId,
      VisitId:this.EmrPatientInfo.VisitId,
      ConsultantId:this.EmrPatientInfo.ConsultantId,
      DispositionFlag: val == '1' ? 'TEST' : 'PRODUCTION'
    };
    this.EmrService.generateErx(input).subscribe((res:any)=>{
      if (res.status == 200 && res.message == "Success") {
        this.toastrService.success("Success: " + "Generate ERX Successfully.");
      } else {
        if(res.errorMessage.message == null) {
          this.toastrService.error(res.message)
        } else {
          this.toastrService.error(res.errorMessage.message)
        }
      }
    })
  }
  getAppSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }
  
    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          let val = response.response
          this.configSetting = val.filter((item) => item.settId == 33 || item.settId == 34);
          this.HealthInformationPlatform=_.find(response.response,{displayName:"HealthInformationPlatform"})
      }
    }
    })
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  cancelErx(){
    let val = this.configSetting.filter((item) => item.settId == 34)[0].settValue
    let input:any = {
      ConsultationId: this.EmrPatientInfo.ConsultationId,
      BranchId:  this.globals.HospitalId,
      SponsorId: this.EmrPatientInfo.SponsorId,
      PatientId:this.EmrPatientInfo.PatientId,
      VisitId:this.EmrPatientInfo.VisitId,
      ConsultantId:this.EmrPatientInfo.ConsultantId,
      DispositionFlag: val == '1' ? 'TEST' : 'PRODUCTION'
    };
    this.EmrService.cancelErx(input).subscribe((res:any)=>{
      if (res.status == 200 && res.message == "Success") {
        this.toastrService.success("Success: " + "Cancel ERX Successfully.");
      } else {
        if(res.errorMessage.message == null) {
          this.toastrService.error(res.message)
        } else {
          this.toastrService.error(res.errorMessage.message)
        }
      }
    })
  }

  GetEPrescriptionDetails(){
    let input:any = {
      PatientId:this.EmrPatientInfo.PatientId,
      ConsultationId:this.EmrPatientInfo.ConsultationId
    };
    this.EmrService.GetEPrescriptionDetails(input).subscribe((res:any)=>{
      if (res.status == 200) {
        // this.isCancelERX=res.response.length ? true :false
        // if(this.isCancelERX){
        //   const objWithRStatus = res.response.find(obj => obj.status.trim() === "R");
        //   this.eReference = objWithRStatus ? objWithRStatus.eReferenceNo : null;

        //   this.isCancelERX=res.response.some(item => item.status === 'S')
        // }
        if(res.response.length){
          this.isCancelERX=res.response.some(item => item.status === 'S')

          const objWithRStatus = res.response.find(obj => obj.status.trim() === "S");
          this.eReference = objWithRStatus ? objWithRStatus.eReferenceNo : null;
        }else{
          this.isCancelERX=false
        }

      } else {
        if(res.errorMessage.message == null) {
          this.toastrService.error(res.message)
        } else {
          this.toastrService.error(res.errorMessage.message)
        }
      }
    })
  }

  openDosageModal(data,drug){
    this.calculateQuantity(drug)
    if(data != '.0') return
    let initialState = {
      BranchId:this.globals.HospitalId,
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: ''
    }
    const historyModalRef= this.modalService.show(DrugDosageComponent,Object.assign(
      {}, historyConfig, { class: 'modal-md', initialState }) );
      historyModalRef.content.dosageEvent.subscribe(res=>{
        if(res.data){
          this.GetDosage()
          drug.patchValue({
            DosageId:res.data
          })
           this.calculateQuantity(drug)
        }
      });
  }

  openFrequencyModal(data,drug){
    if(data != '.0') return
    let initialState = {
      DrugFrequencyPopupHeading:"Add",
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: ''
    }
    const frequencyModalRef= this.modalService.show(AddEditFrequencyComponent,Object.assign(
      {}, historyConfig, { class: 'modal-md', initialState }) );
      frequencyModalRef.content.frequencyEvent.subscribe(res=>{
        if(res.data){
          this.GetFrequency()
          drug.patchValue({
            freqId:res.data
          })
           this.calculateQuantity(drug)
        }
      });
  }

  trackByDosageId(index: number, item: any): number {
    return item.dosageId;
  }
  
  trackByfreqId(index: number, item: any): number {
    return item.freqId;
  }

  truncatedText(text): string {
    return text.length > 100 ? text.substring(0, 100) + '...' : text;
  }

  sendDataToNabid(drugList) {
    const consultationId = this.EmrPatientInfo.ConsultationId;
    const patientId = this.EmrPatientInfo.PatientId;
    const currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
  
    const drugPermission = this.functionPermissionData.find(x => x.id === 567);
    
  
    // Check if the platform is 'Nabidh' and permissions are allowed
    if (this.HealthInformationPlatform.settValue === "Nabidh") {
      // Send  drug data if permission is allowed and there are drugs
      if (drugPermission?.isAllowed && drugList?.length > 0) {
        const nabPayload = {
          EventId: 12,
          Consultationid: consultationId,
          EntryDate: currentDate,
          PatientId: patientId,
          BranchId : this.globals.HospitalId
        };
        this.sendToNabidh(nabPayload);
      }else if(drugPermission?.isAllowed == false){
        this.toastrService.warning('No permission to send drug data to nabith');
      }
    }
  }
  
  private sendToNabidh(payload: any) {
    this.patientService.SendDetailstoNabith(payload).subscribe({
      next: (res: any) => {
        this.toastrService.info('Response from Nabidh: ' + res);
      }
    });
  }

}
