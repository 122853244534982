import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OperatorService } from '../../client/services/operator.service';
import { ToastrService } from 'ngx-toastr';
import { FormValidationService } from '../../client/services/form-validation.service';
import { AllOperatorsList } from '../../client/interfaces/OperatorList';
import { ActiveFilterMenuTriggerDirective } from '@generic-ui/ngx-grid/structure/source/feature/counter/filter/active-filter-menu-trigger.directive';
@Component({
  selector: 'app-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss']
})
export class OperatorComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;
  supervisor = '';
  locationname = '';
  Mobilenumber = '';
  public submitted: boolean = false;
  operatorform: FormGroup;
  result: any;
  Operators: AllOperatorsList[] = [];
  operatorId: any;

  deleteoperator: boolean;
  oppid: any;

  constructor(private formBuilder: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private OperatorService: OperatorService,
    private toastr: ToastrService,
    private formValidationService: FormValidationService,
  ) {


  }

  ngOnInit(): void {
    this.BuildForm(1, 1);
    this.GetAllOperators();
  }


  BuildForm(FormId, DepartmentId) {
    this.GenerateForm();
  }
  GenerateForm() {
    this.operatorform = this.formBuilder.group({
      operatorName: new FormControl(''),
      operatorCode: new FormControl(''),
      operatorDescription: new FormControl(''),
    });
  }

  showModalDialog() {
    this.displayModal = true;
  }
  showModalDialogedit(id: any) {
    this.operatorId = id;
    let OperatorData = this.Operators.filter((oppdata) => oppdata['id'] == id);
    this.operatorform.patchValue({
      operatorName: OperatorData[0]['operatorName'],
      operatorCode: OperatorData[0]['operatorCode'],
      operatorDescription: OperatorData[0]['operatorDescription']
    });
    this.displayModal = true;
  }

  showModalDialogdelete(id: any) {
    this.oppid = id;
    this.deleteoperator = true;
    this.DeleteOperator
  }

  //pagetitle to be displayed at the header
  // Activetab(pagetitle) {
  //   sessionStorage.setItem("pageTitle", pagetitle);

  // }


  SaveOperator() {

    var AppData = {

      operatorName: this.operatorform.get("operatorName").value,
      operatorCode: this.operatorform.get("operatorCode").value,
      operatorDescription: this.operatorform.get("operatorDescription").value,
    }
    if (this.operatorId > 0) {
      this.OperatorService.UpdateOperator(AppData, this.operatorId).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Operator Updated successfully");
        }
        else {
          this.toastr.error("Failed.Please try later");
        }
      })
    }
    else {
      this.OperatorService.AddOperator(AppData).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Operator added successfully");
        }
        else {
          this.toastr.error("Failed.Please try later");
        }
      })
    }
  }


  GetAllOperators() {

    this.OperatorService.GetAllOperators().subscribe((res) => {
      this.Operators = res.response;
      if (this.Operators.length == 0) {
        this.toastr.warning("No Operators found");
      }
    });
  }

  DeleteOperator(id) {

    this.operatorId = id;
    this.OperatorService.DeleteOperator(this.oppid).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {

        this.toastr.success("Operator deleted successfully");
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
      }

    },
    );

  }

}
