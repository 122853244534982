import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { Response } from '../../../_Models/Response';
import { AddPayload } from '../models/claim';

export interface EMRPatientInfo {
  VisitId:number;
  ConsultantId:number;
  UserId:number;
  ConsultationId:number;
  PatientId:number;
  VisitType:number;
  VisitStartTime:string;
  VisitEndTime:string;
  CrudType:number;
}

@Injectable({
  providedIn: 'root'
})
export class ClaimService {
  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  GetAllSponsorList(Input) {
    var url = "api/Sponsor/GetSponsor";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }

  GetAllSponsorListWithCounts(Input) {
    var url = "api/Claim/GetSponsorwithStatusCount";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }

  GetAgentForSponsor(sponserId){
    var url = "api/Agent/GetAgentForSponsor/"+sponserId;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,'');
  }

  GetAgentForSponsorWithCounts(payload: any){
    var url = "api/Claim/GetAgentDetailsCountForSponsor";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }

  GetSponsorRules(payload: any){
    var url = "api/Sponsor/GetRuleDescription";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  GetConsultantsList(payload: any){
    var url = "api/MasterData/GetConsultant";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  getBillsForClaim(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetSponsorshipDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  insertUpdateClaim(payload: any): Observable<Response<any>>{
    var url = "api/Bill/InsertUpdateClaim";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  cancelClaim(payload: any): Observable<Response<any>>{
    var url = "api/Bill/CancelClaim";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  getClaimDetails(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetClaim";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  GetRemittanceList(payload){
    var url = "api/Remittance/GetManageRemitance";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  AddClaim(payload:AddPayload){
    var url = "api/Bill/InsertUpdateClaimReceipt";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  getServiceItemsEMR(payload: any): Observable<Response<any>>{
    var url = "api/EMR/GetServiceItemsClaim";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  getBillDetails(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetBillListByTransId";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  onVerifyClaims(payload: any): Observable<Response<any>>{
    var url = "api/Bill/VerifyClaim";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  addRemittance(payload:any):Observable <Response<any>>{
    var url = "api/Bill/InsertUpdateClaimReceipt";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }
  getClaimsForDashboard(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetManageClaimForBilling";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  getTransDetails(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetTransactionDetails";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  getTransactionClaimDetails(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetTransactionClaimDetails";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  updateTransactionClaimDetails(payload: any): Observable<Response<any>>{
    var url = "api/Bill/SaveTransactionClaimDetails";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  updateApprovalNumber(payload: any): Observable<Response<any>>{
    var url = "api/Bill/UpdateApprovalNo";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  createTestXML(payload: any): Observable<Response<any>>{
    var url = "api/xml/GenerateTestXML";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  createProductionXML(payload: any): Observable<Response<any>>{
    var url = "api/xml/GenerateProductionXML";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  createInvestigationTestXML(payload: any): Observable<Response<any>>{
    var url = "api/xml/GenerateTestInvestigationXML";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  createInvestigationProductionXML(payload: any): Observable<Response<any>>{
    var url = "api/xml/GenerateProductionInvestigationXML";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  createTestXMLWithNSCode(payload: any): Observable<Response<any>>{
    var url = "api/xml/GenerateTestXmlWithNSCode";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  createProductionXMLWithNSCode(payload: any): Observable<Response<any>>{
    var url = "api/xml/GenerateProductionXmlWithNsCode";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  /////////////////////// Online Submission API's ///////////////////////////////////////

  onValidateXMLOnline(payload: any): Observable<Response<any>>{
    var url = "api/Claim/ClaimOnlineSubmission";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  onGenerateAndUpload(payload: any): Observable<Response<any>>{
    var url = "api/Claim/GenerateAndUpload";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  /////////////////////// Online Submission API's ///////////////////////////////////////

  onDownloadFile(url: string){
    return new Promise((resolve, reject) => {
      this.http.get(url, {
                      responseType: "blob",
                      }).subscribe((res) => {
                              resolve(res);
                      },(err) => {
                              console.log(err)
                              reject(err);
                      });
      });
  }

  getAllStaffs(payload: any): Observable<Response<any>>{
    var url = "api/MasterData/GetStaff";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getGeneratedXMLlist(payload: any): Observable<Response<any>>{
    var url = "api/claim/GetManageClaimXml";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  downloadRemittance(payload:{ClaimId:number,BranchId:number}): Observable<Response<any>>{
    var url = "api/Claim/GetDownloadServices";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getRaList(payload:{BranchId:number}): Observable<Response<any>>{
    var url = "api/Claim/DownloadTransactionFile";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getClaimResubmissions(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetClaimResubmission";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getResubmissionDetails(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetClaimResubmissionDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getAllClaimRemittances(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetClaimReceipts";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getAllBillsToRebmit(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetClaimDetailsForResubmission";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  
  getActivityDetails(payload:{ConsultationId:number,EPriorRequestId:number}){
    var url = "api/Bill/GetPriorRequestActivityDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  printBillInvoice(payload): Observable<Response<any>>{
    var url = "api/Report/PrintBillInvoice";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  uploadClaimXMLid(payload: any): Observable<Response<any>>{
    var url = "api/Bill/UploadFileforClaim";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  changeClaimmXMLid(payload: any): Observable<Response<any>>{
    var url = "api/Claim/UpdateXMLClaimDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  loadXML(file: any){
    return this.http.get(file,  {  
      headers: new HttpHeaders()  
        .set('Content-Type', 'text/xml')  
        .append('Access-Control-Allow-Methods', 'GET')  
        .append('Access-Control-Allow-Origin', '*')  
        .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),  
      responseType: 'text'  
    }); 
  }

  getInvoiceItemsListForResubmission(payload: any): Observable<Response<any>>{
    var url = "api/Claim/GetClaimItemDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  changeInvoiceItemsListForResubmission(payload: any): Observable<Response<any>>{
    var url = "api/Bill/SaveClaimDenialDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  createpriorRequest(payload){
    var url="api/Claim/InsertUpdatePriorRequest"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getPriorRequest(payload){
    var url="api/Bill/GetPriorRequestDetails"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getClaimDetailsForResubmission(payload:any): Observable<Response<any>>{
    var url="api/Claim/GetClaimDetailsForResubmission"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getPriorRequestdata(payload:{ConsultationId:number,
  BranchId:number,EPriorRequestId :number}){
    var url="api/Bill/GetPriorRequests"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetConsultant(department: Number) {
    var url = "api/TodaysPatient/GetConsultant";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "DeptId": Number(department?department:0),
        "ShowExternal": false
      }).pipe(
      );
  }
  GetDepartments(branchId, showall) {
    var url = "api/MasterData/GetDepartment";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "DepartmentId": 0,
        "ShowAll": showall,
        "BranchId": branchId
      }).pipe(
      );
  }

  // to set and get global information for EMR
  SetEMRPatientInfo(EMRPatientInfo: EMRPatientInfo) {
    sessionStorage.setItem('EMRPatientInfo', JSON.stringify(EMRPatientInfo));
    return true;
  }

  //get service items against ruleId
  getserviceItem(payload:{RuleId:number,    
  BranchId:number}){
    var url="api/Sponsor/GetSponsorItemForCredit"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getconsultationlist(payload:{ Consultationid:number,
  Branchid:number,
  FromDate:string,
  Todate:string}){
    var url="api/Claim/GetConsultationById"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  //upload multiple files service items
  postServicefiles(payload){
    var url="api/Claim/UploadFileEPriorRequest"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getFileFromFolderId(payload:{FolderId:number}){
    var url="api/Claim/GetPriorRequestsfiles"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }


  deletePriorServiceFile(payload:{FolderList:any[], FileId:number,
  UserId:any}){
    var url="api/Claim/DeleteFileEPriorRequest"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  } 

  deletePriorItem(payload:{  EPriorRequestId: number,
  Branchid:number }){
    var url="api/Claim/DeletePriorRequest"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  } 

  getPriorSponsor(payload:{FromDate:string,
  ToDate:string,
  SponsorId:number,
  BranchId:number}){
    var url="api/Remittance/GetRemittanceforSponsorwithCount"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getPriorSubAgent(payload:{FromDate:string,
    ToDate:string,
    SponsorId:number,
    BranchId:number}){
    var url="api/Remittance/GetRemittanceforAgentwithCount"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  cancelremittance(payload:{
    ClaimRecId :number,BranchId:number,UserId:number,SessionId:number
  }){
    var url="api/Remittance/CancelRemittance"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  claimReport(payload){
    var url="api/Report/GetReceiptReport"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  claimReportItemWise(payload){
    var url="api/Report/GetItemwiseReport"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetScdsReport(payload){
    var url="api/Report/GetScdsReport"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  printPriorRequest(inputdata){
    var url="api/Report/PrintPriorRequest"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputdata)
  }
  claimReports(payload){
    var url="api/Report/GetClaimReport"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  UploadPriorRequest(payload){
    var url="api/PriorRequest/UploadPriorRequest"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  DownloadPriorRequest(payload){
    var url="api/PriorRequest/DownloadPriorRequest"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  CancelPriorAuthorization(payload){
    var url="api/PriorRequest/CancelPriorAuthorization"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetBillVerificationStatus(payload){
    var url="api/Claim/GetBillVerificationStatus"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  ClaimOnlineSubmission(payload){
    var url="api/ClaimSubmission/ClaimOnlineSubmission"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  ClaimOnlineSubmissionProduction(payload){
    var url="api/ClaimSubmission/ClaimOnlineSubmissionProduction"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  DownloadRemittanceFileList(payload){
    var url="api/ClaimSubmission/DownloadRemittanceFileList"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetManageRemittanceFiles(payload){
    var url="api/ClaimSubmission/GetManageRemittanceFiles"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  InsertUpdateClaimReceipt(payload){
    var url="api/ClaimSubmission/InsertUpdateClaimReceipt"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetManageRemittanceDetails(payload){
    var url="api/ClaimSubmission/GetManageRemittanceDetails"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetclaimRaAndResubmissionDetails(payload){
    var url="api/Bill/GetClaimResubmissionPieChart"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetClaimDetails(payload){
    var url="api/Bill/GetInsuranceClaimChartBillCountAndAmount"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetRemittancePieChart(payload){
    var url="api/Bill/GetRemittancePieChart"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  InsertClaimResubmission(payload){
    var url="api/Bill/InsertClaimResubmission"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  ClaimOnlineReSubmission(payload){
    var url="api/ReSubmission/ClaimOnlineReSubmission"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  UploadFileResubmission(payload){
    var url="api/ReSubmission/UploadFileResubmission"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetReSubmissionfiles(payload:{FolderId:number}){
    var url="api/ReSubmission/GetReSubmissionfiles"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  DeleteFileReSub(payload:{FolderList:any[], FileId:number,
    UserId:any}){
      var url="api/ReSubmission/DeleteFileReSub"
      return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
    } 

  GetReconcilationDetails(payload){
    var url="api/Bill/GetReconcilationDetails"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetClaimReceiptBillDetails(payload){
    var url="api/Bill/GetClaimReceiptBillDetails"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetAmountDeatails(payload){
    var url="api/Bill/GetSponsorshipDetails1"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  MarkPriorRequestasRead(payload){
    var url="api/PriorRequest/MarkPriorRequestasRead"
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

}
