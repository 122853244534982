import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PrevVitalSignsModalComponent } from '../modals/prev-vital-signs-modal/prev-vital-signs-modal.component';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { EMRService } from '../../services/emr.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PrevPlanProcedureModalComponent } from '../modals/prev-plan-procedure-modal/prev-plan-procedure-modal.component';
import { Settings } from '../../../settings/model/settings.model';

@Component({
  selector: 'app-plans-procedure',
  templateUrl: './plans-procedure.component.html',
  styleUrls: ['./plans-procedure.component.scss']
})
export class PlansProcedureComponent implements OnInit,OnChanges {
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  disabled:boolean=false

  public EmrPatientInfo:EMRPatientInfo;
  private globals: globalvars = new globalvars();
  private FormValueChangeChecker: boolean = false;

  public planProcedureForm: FormGroup;
  
  constructor(private modalService: BsModalService,
              private EmrService: EMRService,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.EmrPatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initform();
    this.GetPlanAndProcedure();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 44 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (isSettingWithValueZeroPresent) {
        // Your logic when a setting with value 0 is present and billEdit is true
      
        this.disabled=true
      }
      
    }
  }


  initform(){
    this.planProcedureForm = this.formBuilder.group({
      papId: new FormControl(0),
      planAndProcedure: new FormControl(""),
      patientInstruction: new FormControl(""),
      followUp: new FormControl(""),
      visitId: new FormControl(this.EmrPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
    })
  }

  SavePlanAndProcedure(){
    if(this.EmrPatientInfo.CrudType!=3 &&
      this.EmrPatientInfo.VisitId>0 && this.FormValueChangeChecker)
    {

      let PlanAndProcedure = this.planProcedureForm.getRawValue();
      PlanAndProcedure.userId=Number(localStorage.getItem('user_id'));
      this.EmrService.SavePlanAndProcedure(
        PlanAndProcedure
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.toastrService.success("Success: " + "Plan And Procedure Saved Successfully...");
          }
          else{
            this.toastrService.error(res.errorMessage.message)
          }
        });
    }
    else{
      if(!this.FormValueChangeChecker){
       // this.toastr.warning("Info: " + "No changes detected in the entry");
      }
    }
  }

  GetPlanAndProcedure(){
    let input={
      VisitId:this.EmrPatientInfo.VisitId,
      PatientId:this.EmrPatientInfo.PatientId,
      ShowAll:1
    };

    this.EmrService.GetPlanAndProcedure(input).subscribe(
      (res) => {
        if(res.status==200){
          let response=res.response[0];
          this.planProcedureForm.patchValue(response);
          this.planProcedureForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker=true;
           })
        }
      });
  }

  openHistory(){
    let initialState = {
      EmrPatientInfo: this.EmrPatientInfo
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const historyModalRef= this.modalService.show(PrevPlanProcedureModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

}
