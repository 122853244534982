import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { DatePipe } from '@angular/common';
import { ReportsService } from '../services/reports.service';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-collection-summaray',
  templateUrl: './collection-summaray.component.html',
  styleUrls: ['./collection-summaray.component.scss']
})
export class CollectionSummarayComponent implements OnInit {
  form: FormGroup; // form variable
  isLoading=false;
  isPdfLoaded:boolean=false;
  src :any= '';
  subscription: Subscription;
  globals: globalvars = new globalvars();
  private baseUrl = "";
  branchLoadId:any;
  constructor(
    private NewBillingService:NewBillingService,
    private sharedservice: SharedService,
    private configurationManager: ConfigurationManager,
    public  datepipe: DatePipe,
    private ReportsService:ReportsService,
    private sanitizer: DomSanitizer,

  ) { 
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.branchLoadId=this.globals.HospitalId
      }
    });

    this.baseUrl = (this.configurationManager as any).baseUrl;

  }

  ngOnInit(): void {
    this.form=new FormGroup({
      FromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ToDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      BranchId: new FormControl(this.globals.HospitalId),
    });

  }
  generateReport(){
    this.isLoading=true;
    let input=this.form.getRawValue();
    if (typeof (input.FromDate) == "object") {
      let dobConverted = this.datepipe.transform(input.FromDate, 'dd-MM-yyyy');
      input.FromDate = dobConverted;
    }

    if (typeof (input.ToDate) == "object") {
      let dobConverted = this.datepipe.transform(input.ToDate, 'dd-MM-yyyy');
      input.ToDate = dobConverted;
    }
    input.branchId= this.branchLoadId;

    this.ReportsService.CollectionSummaryReport(input).subscribe((res) => {
      this.isLoading=false;
      if(res.status==200){
        let response=res.response;
        this.src=response[0].reportPath;
        this.src=this.baseUrl+this.src;
        this.src=this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
        this.isPdfLoaded=true;
            }
     });

  }

}
