import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { CommissionList } from '../interfaces/CommissionList';
import { ErrorHandleService } from './error-handle.service';
import { Response } from '../../../_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class CommissionService {
  private baseUrl = "";

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle:ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  Savecommission(InputDetails) {
    var url = "api/MasterData/InsertUpdateCommissionRule";
    return this.http.post<Response<CommissionList>>(`${this.baseUrl}${url}`, InputDetails)
  }
  
  GetCommissionData(InputDetails) {
    var url = "api/MasterData/GetCommissionRuleV2";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }

  SearchRegPatient(InputDetails) {
    var url = "api/Registration/SearchPatientInList";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, InputDetails)
  }


}
