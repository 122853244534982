import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { VisionAcuityComponent } from './vision-acuity/vision-acuity.component';
import { MedicalLensSpecificationComponent } from './medical-lens-specification/medical-lens-specification.component';
import { SlitlampExaminationComponent } from './slitlamp-examination/slitlamp-examination.component';
import { AdnexaComponent } from './adnexa/adnexa.component';
import { Settings } from '../../settings/model/settings.model';

@Component({
  selector: 'app-consultation-ophthalmology',
  templateUrl: './consultation-ophthalmology.component.html',
  styleUrls: ['./consultation-ophthalmology.component.scss']
})
export class ConsultationOphthalmologyComponent implements OnInit, OnDestroy,OnChanges {
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  disabled:boolean=false

  @ViewChild(VisionAcuityComponent) VA: VisionAcuityComponent;
  @ViewChild(MedicalLensSpecificationComponent) ML: MedicalLensSpecificationComponent;
  @ViewChild(SlitlampExaminationComponent) SLE: SlitlampExaminationComponent;
  @ViewChild(AdnexaComponent) AC: AdnexaComponent;

  public globals: globalvars = new globalvars();

  private subscription: Subscription;

  MedicalLensSpecificationDisabled: boolean=false;
  AdnexaDisabled: boolean=false;
  SlitlampExaminationDisabled: boolean=false;
  VisualAcuityDisabled: boolean=false;

  constructor(private sharedService: SharedService) {
    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
      }
    });
   }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const VisualAcuit = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 47 && setting.settValue == '0' && this.billEdit == true;
      });
      if (VisualAcuit) {
        // Your logic when a setting with value 0 is present and billEdit is true
        this.VisualAcuityDisabled=true
      }

      const SlitlampExamination = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 48 && setting.settValue == '0' && this.billEdit == true;
      });
      if (SlitlampExamination) {
        // Your logic when a setting with value 0 is present and billEdit is true
        this.SlitlampExaminationDisabled=true
      }

      const Adnexa = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 49 && setting.settValue == '0' && this.billEdit == true;
      });
      if (Adnexa) {
        // Your logic when a setting with value 0 is present and billEdit is true
        this.AdnexaDisabled=true
      }

      const MedicalLensSpecification = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 50 && setting.settValue == '0' && this.billEdit == true;
      });
      if (MedicalLensSpecification) {
        // Your logic when a setting with value 0 is present and billEdit is true
        this.MedicalLensSpecificationDisabled=true
      }
      
    }
  }

  onSaveClick(tabNo: Number){
    switch(tabNo){
      case 1 :  this.VA.onSaveVisionAcuity();
                break;

      case 2 :  this.SLE.saveSitelampExamination();
                break;

      case 3 :  this.AC.saveAdnexaDetails();
                break;

      case 4 : this.ML.saveLensSpecification();
               break;

      default: this.VA.onSaveVisionAcuity();
               this.ML.saveLensSpecification();
               this.SLE.saveSitelampExamination();
               this.AC.saveAdnexaDetails();
    }
  }

  ngOnDestroy(): void {
    this.onSaveClick(10);
  }

}
