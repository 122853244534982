import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Response } from '../../../../_Models/Response';
import { BillingService } from '../../services/billing.service';

@Component({
  selector: 'app-settlement-modal',
  templateUrl: './settlement-modal.component.html',
  styleUrls: ['./settlement-modal.component.scss']
})
export class SettlementModalComponent implements OnInit {

  @Input() transId: any = 0;
  @Input() branchId: any = 0;
  @Input() locationId: any = 0;
  @Input() isExternal: boolean = false;
  @Input() action: any;

  @Output() settlementEventEmit = new EventEmitter();

  public settlmentForm: FormGroup;
  public billDetailsForm: FormGroup;

  public settlmentSaveLoading: boolean = false;
  public transactionDataFetching: boolean = false;

  public creditList: any[] = [];
  public cardTypes: any[] = [];
  public ledgerList: any[] = [];
  public advanceBalance: number = 0;

  private patientId: Number = 0;

  public today: Date = new Date();

  private billingSettings: any;

  constructor(public modalRef: BsModalRef,
              private billingService: BillingService,
              private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getBillingSettings();
  }

  getBillingSettings(){
    const payload = {
      LocationId: this.locationId,
      BranchId: this.branchId,
      UserId: Number(localStorage.getItem('user_id')),
      ShiftDate: this.datePipe.transform(new Date(), 'dd-MM-yyyy')
    }
    this.transactionDataFetching = true;
    this.billingService.getBillingSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.billingSettings = response.response[0];
          this.getTransactionDetails();
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

  getTransactionDetails(){
    if(this.transId && this.transId != 0){
      let payload = {
        Transid: this.transId,
        Branchid: this.branchId,
      }
      
      if(this.isExternal){
        this.transactionDataFetching = true;
        this.billingService.getExternalBillDataForSettlment(payload).subscribe({
          next: (response: Response<any>)=>{
            if(response.status == 200){
              this.getPatientCreditList(response.response[0]?.patientId);
              this.getAdvanceBalance(response.response[0]?.patientId);
              this.getLedgerList();
              this.getCardTypes();
              this.initBillDetailsForm(response.response[0]);
            }else{

            }
            this.transactionDataFetching = false;
          },error: (error: any)=>{
            this.toastrService.error('Something wents wrong, Try again...!');
            this.transactionDataFetching = false;
          }
        })
      }else{
        this.transactionDataFetching = true;
        this.billingService.getBillDataForSettlment(payload).subscribe({
          next: (response: Response<any>)=>{
            if(response.status == 200){
              this.getPatientCreditList(response.response[0]?.patientId);
              this.getAdvanceBalance(response.response[0]?.patientId);
              this.getLedgerList();
              this.getCardTypes();
              this.initBillDetailsForm(response.response[0]);
            }else{

            }
            this.transactionDataFetching = false;
          },error: (error: any)=>{
            this.toastrService.error('Something wents wrong, Try again...!');
            this.transactionDataFetching = false;
          }
        })
      }
    }else{
      this.toastrService.warning('Please choose a bill for settlment...!!');
      this.modalRef.hide();
    }
  }

  getPatientCreditList(patientId: Number){
    this.patientId = patientId;
    let payload = {
      Patientid: patientId,
      BranchId: this.branchId
    }

    this.billingService.getPatientCreditList(payload).subscribe(res=>{
      if(res.status){
        this.creditList = res.response;
      }
    })
  }

  getCardTypes(){
    let payload = {
      CardId: 0,
      ShowAll: 1,
      BranchId: this.branchId
    }

    this.billingService.getCardTypesForSettlment(payload).subscribe(res=>{
      if(res.status){
        this.cardTypes = res.response;
      }
    })
  }

  getLedgerList(){
    let payload = {
      HeaderId: 0,
      ShowAll: 1,
      BranchId: this.branchId
    }

    this.billingService.getLedgerList(payload).subscribe(res=>{
      if(res.status){
        this.ledgerList = res.response;
      }
    })
  }

  getAdvanceBalance(patientId: Number){
    let payload = {
      Patientid: patientId,
      BranchId: this.branchId
    }

    this.billingService.getAdvanceBalance(payload).subscribe(res=>{
      if(res.status){
        this.advanceBalance = res.response[0].advBal;
      }
    })
  }
  

  initBillDetailsForm(data: any){
    this.billDetailsForm = this.formBuilder.group({
      billNumber: new FormControl(data? data?.transNo : ''),
      billDate: new FormControl(data? data?.transDate : ''),
      creditId: new FormControl(data? data.creditId : 0),
      patientName: new FormControl(data? data?.patientName : ''),
      netAmount: new FormControl(data? Number(data?.netAmount).toFixed(this.billingSettings?.currencyDecimals) : (0).toFixed(this.billingSettings?.currencyDecimals)),
      sponsoredAmount: new FormControl(data? Number(data?.totalSponsored).toFixed(this.billingSettings?.currencyDecimals) : (0).toFixed(this.billingSettings?.currencyDecimals)),
      patientPay: new FormControl(data? (Number(data.netAmount) - Number(data.totalSponsored)).toFixed(this.billingSettings?.currencyDecimals) : (0).toFixed(this.billingSettings?.currencyDecimals)),
    });

    this.initSettlmentForm(Number(this.billDetailsForm.get('patientPay').value));
  }

  initSettlmentForm(amount: number){
    this.settlmentForm = this.formBuilder.group({
      TransId: new FormControl(this.transId, Validators.required),
      cashAmount: new FormControl(amount ? amount : 0, Validators.required),
      cardType: new FormControl(''),
      cardNumber: new FormControl(''),
      cardAmount: new FormControl(0),
      chequeNumber: new FormControl(''),
      chequeDate: new FormControl(''),
      chequeBranch: new FormControl(''),
      chequeAmount: new FormControl(0),
      thirdPartyPostTo: new FormControl(''),
      thirdPartyAmount: new FormControl(0),
      patientAccountRefNo: new FormControl(''),
      patientAccountOpenDate: new FormControl({value: '', disabled: true}),
      patientAccountBalance: new FormControl({value: '', disabled: true}),
      patientAccountAmount: new FormControl(0),
      advanceBalance: new FormControl({value: '', disabled: true}),
      advanceAmount: new FormControl(0),
    })
  }

  paymentModeOpenChange(event, mode){
    let cash = Number(this.settlmentForm.get('cashAmount').value);
    let card = Number(this.settlmentForm.get('cardAmount').value);
    let thirdParty = Number(this.settlmentForm.get('thirdPartyAmount').value);
    let cheque = Number(this.settlmentForm.get('chequeAmount').value);
    let patient = Number(this.settlmentForm.get('patientAccountAmount').value);
    let advance = Number(this.settlmentForm.get('advanceAmount').value);

    let patientPay = Number(this.billDetailsForm.get('patientPay').value);

    switch (mode){
      case 1 : if(event){
                  let balance = patientPay - (card + thirdParty + cheque + patient + advance);
                  balance = balance < 0 ? 0 : balance;
                  this.settlmentForm.get('cashAmount').setValue(balance);

                  this.settlmentForm.get('cashAmount').setValidators(Validators.required);    
                }else{
                  this.settlmentForm.get('cashAmount').setValue(0);

                  this.settlmentForm.get('cashAmount').clearValidators();
                }
                this.settlmentForm.get('cashAmount').updateValueAndValidity();
                break;
      
      case 2 : if(event){
                  let balance = patientPay - (cash + thirdParty + cheque + patient + advance);
                  balance = balance < 0 ? 0 : balance;
                  this.settlmentForm.get('cardAmount').setValue(balance);

                  this.settlmentForm.get('cardAmount').setValidators(Validators.required);
                  this.settlmentForm.get('cardType').setValidators(Validators.required);
                  this.settlmentForm.get('cardNumber').setValidators(Validators.required);
                }else{
                  this.settlmentForm.get('cardAmount').setValue(0);
                  this.settlmentForm.get('cardType').setValue(0);
                  this.settlmentForm.get('cardNumber').setValue(0);

                  this.settlmentForm.get('cardAmount').clearValidators;
                  this.settlmentForm.get('cardType').clearValidators();
                  this.settlmentForm.get('cardNumber').clearValidators;
                  
                }
                this.settlmentForm.get('cardNumber').updateValueAndValidity();
                this.settlmentForm.get('cardType').updateValueAndValidity();
                this.settlmentForm.get('cardAmount').updateValueAndValidity();
                break;

      case 3 : if(event){
                  let balance = patientPay - (cash + thirdParty + card + patient + advance);
                  balance = balance < 0 ? 0 : balance;
                  this.settlmentForm.get('chequeAmount').setValue(balance);

                  this.settlmentForm.get('chequeAmount').setValidators(Validators.required);
                  this.settlmentForm.get('chequeBranch').setValidators(Validators.required);
                  this.settlmentForm.get('chequeDate').setValidators(Validators.required);
                  this.settlmentForm.get('chequeNumber').setValidators(Validators.required);
                }else{
                  this.settlmentForm.get('chequeAmount').setValue(0);
                  this.settlmentForm.get('chequeBranch').setValue('');
                  this.settlmentForm.get('chequeDate').setValue('');
                  this.settlmentForm.get('chequeNumber').setValue('');

                  this.settlmentForm.get('chequeAmount').clearValidators();
                  this.settlmentForm.get('chequeBranch').clearValidators();
                  this.settlmentForm.get('chequeDate').clearValidators();
                  this.settlmentForm.get('chequeNumber').clearValidators();
                  
                }
                this.settlmentForm.get('chequeAmount').updateValueAndValidity();
                this.settlmentForm.get('chequeBranch').updateValueAndValidity();
                this.settlmentForm.get('chequeDate').updateValueAndValidity();
                this.settlmentForm.get('chequeNumber').updateValueAndValidity();
                break;

      case 4 : if(event){
                  let balance = patientPay - (cash + cheque + card + patient + advance);
                  balance = balance < 0 ? 0 : balance;
                  this.settlmentForm.get('thirdPartyAmount').setValue(balance);

                  this.settlmentForm.get('thirdPartyAmount').setValidators(Validators.required);
                  this.settlmentForm.get('thirdPartyPostTo').setValidators(Validators.required);
                }else{
                  this.settlmentForm.get('thirdPartyAmount').setValue(0);
                  this.settlmentForm.get('thirdPartyPostTo').setValue('');

                  this.settlmentForm.get('thirdPartyAmount').clearValidators();
                  this.settlmentForm.get('thirdPartyPostTo').clearValidators();
                  
                }
                this.settlmentForm.get('thirdPartyAmount').updateValueAndValidity();
                this.settlmentForm.get('thirdPartyPostTo').updateValueAndValidity();
                break;

      case 5 : if(event){
                  let balance = patientPay - (cash + cheque + card + thirdParty + advance);
                  balance = balance < 0 ? 0 : balance;
                  this.settlmentForm.get('patientAccountAmount').setValue(balance);

                  this.settlmentForm.get('patientAccountAmount').setValidators(Validators.required);
                  this.settlmentForm.get('patientAccountRefNo').setValidators(Validators.required);
                  this.settlmentForm.get('patientAccountBalance').setValidators(Validators.required);

                  if(this.creditList.length <= 0){
                    this.createPatientCredit(balance);
                  }
                }else{
                  this.settlmentForm.get('patientAccountAmount').setValue(0);
                  this.settlmentForm.get('patientAccountRefNo').setValue('');
                  this.settlmentForm.get('patientAccountOpenDate').setValue('');
                  this.settlmentForm.get('patientAccountBalance').setValue('');

                  this.settlmentForm.get('patientAccountAmount').clearValidators();
                  this.settlmentForm.get('patientAccountRefNo').clearValidators();
                  this.settlmentForm.get('patientAccountOpenDate').clearValidators();
                  this.settlmentForm.get('patientAccountBalance').clearValidators();
                  
                }
                this.settlmentForm.get('patientAccountAmount').updateValueAndValidity();
                this.settlmentForm.get('patientAccountRefNo').updateValueAndValidity();
                this.settlmentForm.get('patientAccountOpenDate').updateValueAndValidity();
                this.settlmentForm.get('patientAccountBalance').updateValueAndValidity();
                break;

      case 6 : if(event){
                  let balance = patientPay - (cash + cheque + card + thirdParty + patient);
                  balance = balance < 0 ? 0 : balance;
                  balance = balance > this.advanceBalance ? this.advanceBalance : balance;
                  this.settlmentForm.get('advanceAmount').setValue(balance);
                  this.settlmentForm.get('advanceBalance').setValue(this.advanceBalance);

                  this.settlmentForm.get('advanceAmount').setValidators([Validators.required, Validators.max(this.advanceBalance)]);
                  this.settlmentForm.get('advanceBalance').setValidators(Validators.required);
                }else{
                  this.settlmentForm.get('advanceAmount').setValue(0);
                  this.settlmentForm.get('advanceBalance').setValue('');

                  this.settlmentForm.get('advanceAmount').clearValidators();
                  this.settlmentForm.get('advanceBalance').clearValidators();
                  
                }
                this.settlmentForm.get('advanceAmount').updateValueAndValidity();
                this.settlmentForm.get('advanceBalance').updateValueAndValidity();
                break;

      default : console.log('Undefined option');
    }
  }


  saveSettlment(){
    this.settlmentSaveLoading = true;
    const controls = this.settlmentForm.controls;
    if(this.settlmentForm.invalid){
      Object.keys(controls).forEach(control=>{
        controls[control].markAllAsTouched();
      });
      this.settlmentSaveLoading = false;
      return
    }

    let payload = {
      TransId: this.transId,
      BillSettlementList: [],
      UserId: Number(localStorage.getItem('user_id')),
      // status: 'S',
      status: this.action == 'resettlement' ? 'R' : 'S',
      locationId: this.locationId,
      SettleDate: this.datePipe.transform(new Date(), 'dd-MM-yyyy'),
      branchId: this.branchId,
      IsReSettled: this.action == 'resettlement' ? true : false
    }
    let settlmentData = this.settlmentForm.getRawValue();
    if(settlmentData.cashAmount && settlmentData.cashAmount > 0){
      payload.BillSettlementList.push({
        Mode: 1,
        HeadId: 0,
        Amount: Number(settlmentData.cashAmount),
        CardId: 0,
        CardNo: "",
        ChqNo: "",
        Chqdate: "",
        ChqBranch: "",
        CreditId: 0,
        Remarks: ""
      });
    }

    if(settlmentData.cardAmount && settlmentData.cardAmount > 0){
      payload.BillSettlementList.push({
        Mode: 2,
        HeadId: 0,
        Amount: Number(settlmentData.cardAmount),
        CardId: Number(settlmentData.cardType),
        CardNo: (settlmentData.cardNumber).toString(),
        ChqNo: "",
        Chqdate: "",
        ChqBranch: "",
        CreditId: 0,
        Remarks: ""
      });
    }

    if(settlmentData.chequeAmount && settlmentData.chequeAmount > 0){
      payload.BillSettlementList.push({
        Mode: 3,
        HeadId: 0,
        Amount: Number(settlmentData.chequeAmount),
        CardId: 0,
        CardNo: "",
        ChqNo: settlmentData.chequeNumber.toString(),
        Chqdate: typeof(settlmentData.chequeDate) == 'object' ? this.datePipe.transform(settlmentData.chequeDate, 'dd-MM-yyyy') : settlmentData.chequeDate,
        ChqBranch: settlmentData.chequeBranch,
        CreditId: 0,
        Remarks: ""
      });
    }

    if(settlmentData.thirdPartyAmount && settlmentData.thirdPartyAmount > 0){
      payload.BillSettlementList.push({
        Mode: 4,
        HeadId: Number(settlmentData.thirdPartyPostTo),
        Amount: Number(settlmentData.thirdPartyAmount),
        CardId: 0,
        CardNo: "",
        ChqNo: "",
        Chqdate: "",
        ChqBranch: "",
        CreditId: 0,
        Remarks: ""
      });
    }

    if(settlmentData.patientAccountAmount && settlmentData.patientAccountAmount > 0){
      payload.BillSettlementList.push({
        Mode: 6,
        HeadId: 0,
        Amount: Number(settlmentData.patientAccountAmount),
        CardId: 0,
        CardNo: "",
        ChqNo: "",
        Chqdate: "",
        ChqBranch: "",
        CreditId: Number(settlmentData.patientAccountRefNo),
        Remarks: ""
      });
    }

    if(settlmentData.advanceAmount && settlmentData.advanceAmount > 0){
      payload.BillSettlementList.push({
        Mode: 7,
        HeadId: 0,
        Amount: Number(settlmentData.advanceAmount),
        CardId: 0,
        CardNo: "",
        ChqNo: "",
        Chqdate: "",
        ChqBranch: "",
        CreditId: 0,
        Remarks: ""
      });
    }

    if(this.billDetailsForm.get('sponsoredAmount').value > 0){
      payload.BillSettlementList.push({
        Mode: 5,
        HeadId: 0,
        Amount: Number(this.billDetailsForm.get('sponsoredAmount').value),
        CardId: 0,
        CardNo: "",
        ChqNo: "",
        Chqdate: "",
        ChqBranch: "",
        CreditId: Number(this.billDetailsForm.get('creditId').value),
        Remarks: ""
      });
    }

    let totalEntered = 0 ;
    payload.BillSettlementList.forEach(item=> {
      if(item.Mode != 5){
        totalEntered = totalEntered +item.Amount;
      }
    });

    if(totalEntered != Number(this.billDetailsForm.get('patientPay').value)){
      this.toastrService.warning('Total amount not match with patient pay.');
      this.settlmentSaveLoading = false;
      return
    }
  
    if(this.isExternal){
      this.settlmentSaveLoading = true;
      this.billingService.saveExternalSettlment(payload).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200 && response.message == 'Success'){
            this.toastrService.success('Settlment saved successfully');
            this.settlementEventEmit.emit({status: true});
            this.modalRef.hide();
          }else{
            this.toastrService.error(response.message);
            this.settlmentSaveLoading = false;
          }
          
        },error: (error: any)=>{
          this.toastrService.error('Something wents wrong, try again..!');
          this.settlmentSaveLoading = false;
        }
      })
    }else{
      this.settlmentSaveLoading = true;
      this.billingService.saveSettlment(payload).subscribe({
        next: (response: Response<any>)=>{
          if(response.status == 200 && response.message == 'Success'){
            this.toastrService.success('Settlment saved successfully');
            this.settlementEventEmit.emit({status: true});
            this.modalRef.hide();
          }else{
            this.toastrService.error(response.message);
            this.settlmentSaveLoading = false;
          }
          
        },error: (error: any)=>{
          this.toastrService.error('Something wents wrong, try again..!');
          this.settlmentSaveLoading = false;
        }
      })
    }
  }

  onSelectCredit(event){
    let credit = this.creditList.filter(cr=> cr.creditId == event.target.value);
    this.settlmentForm.patchValue({
      patientAccountOpenDate: credit[0]?.openDate,
      patientAccountBalance: credit[0]?.outstandingCreditBalace
    });
    // this.settlmentForm.get('patientAccountAmount').setValidators(Validators.max(credit[0]?.outstandingCreditBalace));
    // this.settlmentForm.get('patientAccountAmount').updateValueAndValidity();
  }

  createPatientCredit(creditAmount){
    let today = new Date();
    let expiry = new Date().setFullYear(today.getFullYear()+1);
    let payload = {
      creditId: 0,
      creditRefNo: '',
      creditType: 1,
      openDate: this.datePipe.transform(today, 'dd-MM-yyyy'),
      sponsorId: 0,
      patientId: this.patientId,
      agentId: 0,
      creditLimit: Number(creditAmount),
      creditAvailed: 0,
      validUpto: this.datePipe.transform(expiry, 'dd-MM-yyyy'),
      ExpiryDate: '',
      dedAmount: 0,
      coPayPcnt: 0,
      maxLimit: 0,
      priority: 0,
      policyNo: '',
      payerId: '',
      certificateNo: '',
      dependantNo: 0,
      policyDate: this.datePipe.transform(today, 'dd-MM-yyyy'),    
      ruleId: 0,
      userId: Number(localStorage.getItem('user_id')),
      active: 1,
      branchId: this.branchId,
      currentCreditCategory: "N",
      creditItemGroupList: [],
      WaitingPeriod: this.datePipe.transform(today, 'dd-MM-yyyy'),  
      
      waitingPeriodDays:0,
      InsCardNo:0,
      gpReferal:0,
    }

    const formData = new FormData();
    formData.append('CreditJson', JSON.stringify(payload));
    formData.append('InsCardImg', '');

    this.billingService.InsertCredit(formData).subscribe((res) => {
          if(res.status==200 && res.message=="success"){
            this.getPatientCreditList(this.patientId);
          }
    });
  }

  onCancel(){
    this.settlementEventEmit.emit({status: false});
    this.modalRef.hide();
  }


}
