import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PrevVitalSignsModalComponent } from '../modals/prev-vital-signs-modal/prev-vital-signs-modal.component';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EMRService } from '../../services/emr.service';
import { SharedService } from '../../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { DatePipe } from '@angular/common';
import { TreatmentsService } from '../../../treatment/services/treatments.service';
import { ConfigurationService } from '../../../client/services/configuration.service';
import moment from 'moment';
import { Settings } from '../../../settings/model/settings.model';
import _ from 'lodash';
import { SettingsService } from '../../../settings/services/settings.service';
import { PatientService } from '../../../client/services/patient.service';

@Component({
  selector: 'app-vital-signs',
  templateUrl: './vital-signs.component.html',
  styleUrls: ['./vital-signs.component.scss']
})
export class VitalSignsComponent implements OnInit, OnDestroy,OnChanges {

  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  @Input() functionPermissionData
  disabled:boolean=false

  public EmrPatientInfo:EMRPatientInfo;
  public FormValueChangeChecker: boolean = false;

  public vitalsignForm: FormGroup;
  public vitalSignList: any[] = [];
  // public vitalSignList1: any[] = [];
  public TodaysvitalSignList: any[] = [];
  public isPrevious: boolean = false;
  bp1:boolean;
  bp2:boolean;
  Staffs:any;
  NurseData:any;
  staffName:string = '';

  vitalsignData: any;
@Input() icdVal:any;
  private subscription: Subscription;
  private globals: globalvars = new globalvars();
  @Output() loading = new EventEmitter<any>();
  @ViewChildren('since') since:  QueryList<ElementRef>;
  @ViewChildren('since1') since1:  QueryList<ElementRef>;
  painVal: any=1;
  HealthInformationPlatform: any;

  // lastSavedDate :any=''

  constructor(private modalService: BsModalService,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private sharedService: SharedService,
              private TreatmentsService: TreatmentsService,
              private EmrService: EMRService,
              private datepipe: DatePipe,
              private ConfigurationService: ConfigurationService,
              private settingsService:SettingsService,
              private patientService:PatientService
            ) { }

  ngOnInit(): void {
    this.EmrPatientInfo = this.EmrService.GetEMRPatientInfo();
    this.subscription = this.sharedService.getHospital().subscribe((glob) => {
      if (this.sharedService.checkglobals(glob)) {
        this.globals = glob;
      }
    });
    this.GetAllVitalSign();
    this.initForm();
    this.GetPerformingStaff();
    this.GetEMRVitalSign();
    this.GetTodaysvitalSign();
    this.getAppSettings()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.emrConfig) {
      const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
        // Replace 'dynamicSettId' with the actual dynamic settId value
        return setting.settId == 37 && setting.settValue == '0' && this.billEdit == true;
      });
      
      if (isSettingWithValueZeroPresent) {
        // Your logic when a setting with value 0 is present and billEdit is true
        // this.vitalsignForm.disable()
        this.disabled=true
      }
      
    }
  }

  initForm(){
    this.vitalsignForm = this.formBuilder.group({
      eid: new FormControl(0),
      vitalSignDataList: new FormArray([]),
      createdTime: new FormControl(''),
      visitId: new FormControl(this.EmrPatientInfo.VisitId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),
      ConsultationId: new FormControl(this.EmrPatientInfo.ConsultationId),
      PerformedStaffId: new FormControl(this.EmrPatientInfo.UserId),
    })
  }

  GetEMRVitalSign(vistId=this.EmrPatientInfo.VisitId){
    let input={
      "VisitId":vistId,
      "BranchId": this.globals.HospitalId
    };
    this.EmrService.GetEMRVitalSign(input).subscribe(
      (res) => {
        if(res.status==200){
          this.vitalSignList=res.response;
          // this.vitalSignList1=res.response;
          this.loading.emit(false)
          this.getStaffName()
          if(this.vitalSignList?.length){
            this.vitalSignList.forEach((res:any)=>{
              if(res.vitalSignName == 'BP Systolic'){
                if(res.vitalSignValue != "" ){
                  this.bp1 = true;
                } else {
                  this.bp1 = false;
                }
              }
              if(res.vitalSignName == 'BP Diastolic'){
                if(res.vitalSignValue != "" ){
                  this.bp2 = true;
                } else {
                  this.bp2 = false;
                }
              }

              if(res.vitalSignName == 'PainScale'){
                this.changeStyle(res.vitalSignValue,true)
              }
            })
            if(this.bp1 && !this.bp2){
              this.toastrService.error('Please add BP Diastolic')
              setTimeout(()=>{
                this.since.last.nativeElement.focus()
              },300)
            } else if(!this.bp1 && this.bp2){
              this.toastrService.error('Please add BP Systolic')
              setTimeout(()=>{
                this.since1.last.nativeElement.focus()
              },300)
            }
          }
          if(this.vitalSignList){
            let eid=this.vitalSignList[0].eid;
            if(eid>0){
              this.vitalsignForm.controls['eid'].setValue(this.vitalSignList[0].eid);
              this.isPrevious=true;
            }
          }
        }
      });
  }

  GetTodaysvitalSign(){
    let input={
      VisitId: this.EmrPatientInfo.VisitId,
      PatientId: this.EmrPatientInfo.PatientId,
      IsFromEMR: 1,
    }
    this.EmrService.GetAllEMRVitalSignByVisitId(input).subscribe(
      (res) => {
        if(res.status==200){
          this.TodaysvitalSignList=res.response;
          
        }
      });
  }

  AddNew(){
    // this.isFormChanged=false;
    this.vitalsignForm.controls['eid'].setValue(0);
    this.GetEMRVitalSign(0);
  }

  openHistory(){
    let initialState = {
      EmrPatientInfo: this.EmrPatientInfo
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const historyModalRef= this.modalService.show(PrevVitalSignsModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

  SaveVitalSign(){
    this.vitalSignList=this.vitalSignList.map((sign)=>{
      if(sign.vitalSignName=='PainScale'){
        return{
          ...sign,
          vitalSignValue:String(this.painVal)
        }
      }
      else{
        return sign
      }
    })
    if( this.EmrPatientInfo.CrudType!=3 && this.EmrPatientInfo.VisitId>0 && this.FormValueChangeChecker && this.vitalSignList.some(sign => sign.vitalSignValue !== '') )
    {
      this.loading.emit(true)
      let EMRVitalSignForm = this.vitalsignForm.getRawValue();
      
      EMRVitalSignForm.vitalSignDataList = this.vitalSignList
      
      EMRVitalSignForm.CreatedTime = this.datepipe.transform(new Date(), 'dd-MM-yyyy HH:mm');
      EMRVitalSignForm.userId = Number(localStorage.getItem('user_id'));
      EMRVitalSignForm.IsFromEMR=1;
      let bp1;
      let bp2;
      // let apiTriger =false;
      // let vitalVal1 = [];
      // let vitalVal2 = [];
      let save=true
      if(this.vitalSignList?.length){
        this.vitalsignData.forEach((val:any)=>{
          if(val.mandatory === 1 || val.inValidValidation){
            // vitalVal1.push(val)
            this.vitalSignList.forEach((res:any)=>{
              if(res.vitalSignName === val.signName){
                if(res.vitalSignValue == "" && val.mandatory === 1){
                  this.toastrService.error(`Please add ${res.vitalSignName}`)
                  this.loading.emit(false)
                  save=false
                } 
                if(val.inValidValidation && res.vitalSignValue != ""){
                  const enteredValue = parseFloat(res.vitalSignValue)
                  if (isNaN(enteredValue) || enteredValue < val.minValidationValue || enteredValue > val.maxValidationValue) {
                    // Show a Toastr message
                    this.toastrService.error(res.vitalSignName + ' value is outside the allowed range ' + val.minValidationValue +'-'+val.maxValidationValue);
                    this.loading.emit(false)
                    save=false
                    // Clear the value or handle accordingly
                   
                  }
                }
              }
            })
          }
        })
        // this.vitalSignList.forEach((res:any)=>{
        //   this.vitalSignList1.forEach((val:any)=>{
        //     console.log(apiTriger)
        //     console.log(res,val)
        //     if(res.vitalSignName == val.vitalSignName){
        //       if(res.vitalSignValue != val.vitalSignValue){
        //         console.log(res,val)
        //         apiTriger = true;
        //       }
        //     }
        //   })
        // })
        this.vitalSignList.forEach((res:any)=>{
          if(res.vitalSignName == 'BP Systolic'){
            if(res.vitalSignValue != "" ){
              bp1 = true;
            } else {
              bp1 = false;
            }
          }
          if(res.vitalSignName == 'BP Diastolic'){
            if(res.vitalSignValue != "" ){
              bp2 = true;
            } else {
              bp2 = false;
            }
          }
        })
      }
      if(bp1 && !bp2){
        this.toastrService.error('Please add BP Diastolic')
        setTimeout(()=>{
          this.since.last.nativeElement.focus()
        },300)
        this.loading.emit(false)
      } else if(!bp1 && bp2){
        this.toastrService.error('Please add BP Systolic')
        setTimeout(()=>{
          this.since1.last.nativeElement.focus()
        },300)
        this.loading.emit(false)
      } else {
        if(save) {
          let date = this.datepipe.transform(new Date(), 'MM-dd-yyyy HH:mm');
          date = date.concat(':00')

          if(this.TodaysvitalSignList?.length){
            const rawDate = this.TodaysvitalSignList[0]?.createdDate;
            let date1 = moment(this.TodaysvitalSignList[0]?.createdDate, 'DD/MM/YYYY, hh:mm:ss A').format('DD/MM/YYYY');
            // let date1 = this.datepipe.transform(this.TodaysvitalSignList[0]?.createdDate, 'dd-MM-yyyy HH:mm:ss');
            let val= this.TodaysvitalSignList[0]?.createdDate;
            let dateObj = new Date(date1)
            let timestamp= dateObj.getTime()
            let dateObj1 = new Date(date)
            let timestamp1= dateObj1.getTime()

            if(date !== date1){
              this.EmrService.SaveEMRVitalSigns(EMRVitalSignForm).subscribe(
                (res) => {
                  if (res.status == 200 && res.message == "Success") {
                    // this.sendDataToNabid()
                    this.toastrService.success("Success: " + "Vital Sign details Saved Successfully...");
                    this.GetTodaysvitalSign();
                    this.GetEMRVitalSign();
                    this.FormValueChangeChecker = false
                    
                  }
                  else{
                    this.toastrService.error(res.errorMessage.message)
                    this.loading.emit(false)
                  }
                });
            } else {
              this.loading.emit(false)
            }
          
// console.log(val,timestamp,timestamp1,date1)
          } else {
            this.EmrService.SaveEMRVitalSigns(EMRVitalSignForm).subscribe(
              (res) => {
                if (res.status == 200 && res.message == "Success") {
                  this.toastrService.success("Success: " + "Vital Sign details Saved Successfully...");
                  this.GetTodaysvitalSign();
                  this.GetEMRVitalSign();
                  this.FormValueChangeChecker = false
                }
                else{
                  this.toastrService.error(res.errorMessage.message)
                  this.loading.emit(false)
                }
              });
          }
          //  if(apiTriger){
          //   console.log('s')
          //   apiTriger = false
          //  } else{
          //   console.log('d')
          //  }
          //  console.log(apiTriger)
          // this.EmrService.SaveEMRVitalSigns(EMRVitalSignForm).subscribe(
          //   (res) => {
          //     if (res.status == 200 && res.message == "Success") {
          //       this.toastrService.success("Success: " + "Vital Sign details Saved Successfully...");
          //       this.GetTodaysvitalSign();
          //       this.GetEMRVitalSign();
                
          //     }
          //     else{
          //       this.toastrService.error(res.errorMessage.message)
          //     }
          //   });
        }
      }
    }
    else{
      //this.toastr.warning("Info: " + "No changes detected in the page");
    }
  }
  GetPerformingStaff() {
    let Input = {
      "StaffId": 0,
      "ShowAll": 0,
      "BranchId": this.globals.HospitalId,
    }

    this.TreatmentsService.GetPerformingStaff(Input).subscribe((res) => {
      this.Staffs = res.response;
      this.NurseData = this.Staffs.filter(x => x.categoryId === 4);

    });
  }
  getStaffName(){
    this.vitalSignList.map((res:any)=>{
      if(res.performedStaffId !== 0){
        if(this.EmrPatientInfo.UserId === res.performedStaffId){
          this.staffName = localStorage.getItem('username')
        } else {
          this.Staffs.map((val:any)=>{
            if(val.staffId === res.performedStaffId){
              this.staffName =  val.name;
            } 
          })
        }
      }
    })
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getBMI(){
    let height = 0;
    let weight = 0;
    let meterHeight = 0;
    this.vitalSignList.find(x => {
      if(x.reservedId == 6){
        height = Number(x.vitalSignValue);
      }
      if(x.reservedId == 5){
        weight = Number(x.vitalSignValue);
      }
    })

    if(height == 0 || height == null || height == undefined){
      this.toastrService.warning("Please enter height");
      return
    }

    if(weight == 0 || weight == null || weight == undefined){
      this.toastrService.warning("Please enter weight");
      return
    }

    meterHeight = height / 100;
    let bmi = (weight / (meterHeight * meterHeight));

    this.vitalSignList.find(x => {
      if(x.reservedId == 8){
        x.vitalSignValue = Number(bmi).toFixed(2);
      }
    })
  }

   // get vital sign mandatory
   GetAllVitalSign() {
    this.ConfigurationService.GetVitalSign(this.globals.HospitalId).subscribe((res) => {
      this.vitalsignData = res.response;
    });
  }

  onInputChange(sign): void {
    const enteredValue = parseFloat(sign.vitalSignValue);

    if (isNaN(enteredValue) || enteredValue < sign.minValidationValue || enteredValue > sign.maxValidationValue) {
      // Show a Toastr message
      this.toastrService.error('Entered value is outside the allowed range.');

      // Clear the value or handle accordingly
     
    }
  }

  changeValue(event){
    if(event.reservedId == 5){
      let vitalVlue = Number(event.vitalSignValue);
      if(typeof vitalVlue === 'number' && !isNaN(vitalVlue) && vitalVlue !== 0 && vitalVlue !== null){
        this.vitalSignList.find(x => {
          let listVital = Number(x.vitalSignValue);
          if(x.reservedId == 6 && typeof listVital === 'number' && !isNaN(listVital) && listVital !== 0 && listVital !== null){
            this.calculateBMI();
          }
        })
      }
    }else if(event.reservedId == 6){
      let vitalVlue = Number(event.vitalSignValue);
      if(typeof vitalVlue === 'number' && !isNaN(vitalVlue) && vitalVlue !== 0 && vitalVlue !== null){
        this.vitalSignList.find(x => {
          let listVital = Number(x.vitalSignValue);
          if(x.reservedId == 5 && typeof listVital === 'number' && !isNaN(listVital) && listVital !== 0 && listVital !== null){
            this.calculateBMI();
          }
        })
      }
    }
  }

  calculateBMI(){
    let height = 0;
    let weight = 0;
    let meterHeight = 0;
    this.vitalSignList.find(x => {
      if(x.reservedId == 6){
        height = Number(x.vitalSignValue);
      }
      if(x.reservedId == 5){
        weight = Number(x.vitalSignValue);
      }
    })

    meterHeight = height / 100;
    let bmi = (weight / (meterHeight * meterHeight));

    this.vitalSignList.find(x => {
      if(x.reservedId == 8){
        x.vitalSignValue = Number(bmi).toFixed(2);
      }
    })
  }

  changeStyle(id, isInit = false) {
    this.painVal = id
   
    for (let i = 1; i <= id; i++) {
      var element = document.getElementById(i.toString()+'pain');
      element.classList.add("pain-scale__level--active");
    }
    for (let i = id + 1; i <= 10; i++) {
      var element = document.getElementById(i.toString()+'pain');
      element.classList.remove("pain-scale__level--active");
    }
    if(!isInit){
     
      this.FormValueChangeChecker = true;
    }
  }

  changeStylePrevious(id,entry) {
    
    for (let i = 1; i <= id; i++) {
      var element = document.getElementById(`${i}pain${entry}`);
      element.classList.add("pain-scale__level--active");
    }
    for (let i = id + 1; i <= 10; i++) {
      var element = document.getElementById(`${i}pain${entry}`);
      element.classList.remove("pain-scale__level--active");
    }  
  }

  openAccordian(entry){
    let painScale=entry.vitalSignData.find(sign => sign.vitalSignName === 'PainScale')
    if(painScale){
      this.changeStylePrevious(Number(painScale.vitalSignValue),entry.eid)
    }
  }


  getAppSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }
  
    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: any)=>{
        if(response.status == 200){
          let val = response.response
           this.HealthInformationPlatform=_.find(response.response,{displayName:"HealthInformationPlatform"})
      }
    }
    })
  }


  sendDataToNabid() {
    const currentDate = this.datepipe.transform(new Date(), "dd-MM-yyyy");
    const nbPayload = {
      EventId: 1,
      Consultationid: this.EmrPatientInfo.ConsultationId,
      EntryDate: currentDate,
      PatientId: this.EmrPatientInfo.PatientId,
      BranchId: this.globals.HospitalId,
    };
    const Permission = this.functionPermissionData.find((x) => x.id === 560);
    // Check if the platform is 'Nabidh' and permissions are allowed
    if (this.HealthInformationPlatform.settValue === "Nabidh" ) {
      if (Permission?.isAllowed ) {
         this.sendToNabidh(nbPayload)
      } else {
        this.toastrService.warning("No permission to send data to nabith");
      }
    }
  }

  private sendToNabidh(payload: any) {
    this.patientService.SendDetailstoNabith(payload).subscribe({
      next: (res: any) => {
        this.toastrService.info("Response from Nabidh: " + res);
      },
    });
  }
}
