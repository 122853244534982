import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { Response } from '../../../_Models/Response';

@Injectable({
  providedIn: 'root'
})
export class DentalService {

  private baseUrl: string = '';

  constructor(private configurationManager: ConfigurationManager,
              private httpClient: HttpClient,) {
                this.baseUrl = (this.configurationManager as any).baseUrl;
               }

  getChartAndMarkings(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/EMR/GetDentalMarkings`;
    return this.httpClient.post<Response<any>>(endPoint, payload);
  }

  updateTeethMarking(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/EMR/InsertUpdateDentalMarkings`;
    return this.httpClient.post<Response<any>>(endPoint, payload);
  }

  getDentalHistory(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/EMR/GetDentalTreatmentHistory`;
    return this.httpClient.post<Response<any>>(endPoint, payload);
  }

  updateTeethMarkingandEstimate(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/EMR/InsertUpdateDentalMarkingsandEstimate`;
    return this.httpClient.post<Response<any>>(endPoint, payload);
  }

  GetDentalEstimateItemsEMR(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/EMR/GetDentalEstimateItemsEMR`;
    return this.httpClient.post<Response<any>>(endPoint, payload);
  }

  GetEstimateLoad(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/EMR/GetEstimateLoad`;
    return this.httpClient.post<Response<any>>(endPoint, payload);
  }

  InsertEstimateItemsEMR(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/EMR/InsertEstimateItemsEMR`;
    return this.httpClient.post<Response<any>>(endPoint, payload);
  }

  GetServiceOrderByEstimateId(payload: any): Observable<Response<any>>{
    const endPoint = `${this.baseUrl}api/EMR/GetServiceOrderByEstimateId`;
    return this.httpClient.post<Response<any>>(endPoint, payload);
  }
}
