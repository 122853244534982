import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dental-complete-procedure',
  templateUrl: './dental-complete-procedure.component.html',
  styleUrls: ['./dental-complete-procedure.component.scss']
})
export class DentalCompleteProcedureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
