import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-instructor-authentication',
  templateUrl: './instructor-authentication.component.html',
  styleUrls: ['./instructor-authentication.component.scss']
})
export class InstructorAuthenticationComponent implements OnInit {

  @Input() branchId;
  @Input() userId;

  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  close(){
    this.modalRef.hide();
  }

}
