import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-print-barcode-modal',
  templateUrl: './print-barcode-modal.component.html',
  styleUrls: ['./print-barcode-modal.component.scss']
})
export class PrintBarcodeModalComponent implements OnInit {

  @Input() regNo: string = '';

  @ViewChild('printContent') content: ElementRef;

  public printLoading: boolean = false;

  constructor(private activeModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  onPrintBarcode(){
    this.printLoading = true;
    html2canvas(this.content.nativeElement,{
      scale: 3
    }).then(canvas=>{
      let image = canvas.toDataURL();

      // let a = document.createElement('a');
      // a.href = image;
      // a.download = 'Image';
      // a.click();
      // a.remove();

      let doc = new jsPDF({
        compress: false,
        format: 'a4'
      });
      var width = doc.internal.pageSize.getWidth();
      doc.internal.scaleFactor = 2;
      doc.addImage(image,'image/png', width/3, 0, 70, 30);
      doc.output('dataurlnewwindow');
      this.printLoading = false;
    })
    .catch(error=>{
      console.log(error);
      this.printLoading = false;
    })
  }

  close(){
    this.activeModalRef.hide();
  }

}
