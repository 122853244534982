import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ErrorHandleService } from '../../client/services/error-handle.service';
import { Response } from '../../../_Models/Response';


@Injectable({
  providedIn: 'root'
})
export class PackageandprofileService {

  private baseUrl = "";

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  savePackage(data) {
    var url = "api/MasterData/InsertUpdatePackage";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, data)
  }

  getpackages(inputData) {
    var url = "api/MasterData/GetPackage";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }

  GetServiceItems(branchId) {
    var url = "api/MasterData/GetServiceItem/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "Id": 0,
        "BranchId": branchId,
        "GroupId":0,
        "ConsultantId":0,
        "ShowAll":0
      }
    )
  }

  saveProfile(profile) {
    var url = "api/MasterData/InsertUpdateProfile";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, profile)
  }

  getprofiles(inputData) {
    var url = "api/MasterData/GetProfile";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
}
