import { Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { SponsorsService } from "../../../client/services/sponsors.service";
import { Subscription } from "rxjs";
import { SharedService } from "../../../../shared/shared.service";
import { globalvars } from "../../../../shared/models/GlobalVars";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators,FormArray } from "@angular/forms";
import { ConfigurationService } from "../../../client/services/configuration.service";
import { SponsorRule } from "../../../client/interfaces/SponsorRule";
import { ToastrService } from "ngx-toastr";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { TreeNode } from "primeng/api";
import { ColDef, FirstDataRenderedEvent, GridApi } from "ag-grid-community";
import { tr } from "date-fns/locale";
import { GeneralActionComponent } from "../../../client/general-action/general-action.component";
import { event } from "jquery";
import * as _ from "lodash";
import { ServiceValidityModalComponent } from "../../../billing-settlement/manage-bill/service-validity-modal/service-validity-modal.component";
import { SponsorRuleItemNoteComponent } from "../../modals/sponsor-rule-item-note/sponsor-rule-item-note.component";
@Component({
  selector: "app-sponsor-rules",
  templateUrl: "./sponsor-rules.component.html",
  styleUrls: ["./sponsor-rules.component.scss"],
})
export class SponsorRulesComponent implements OnInit {
  GetSponsorRuleDesc: any;
  GetSponsorsRuleData: any[];
  subscription: Subscription;
  globals: globalvars = new globalvars();
  GetAllSponsorsData: any;
  SponsorId: any;
  SponsorRuleForm: FormGroup;
  copyRuleDesc:FormGroup;
  DedAmount:FormGroup;
  rateGroupDatas: any;
  GetSponsorsRateData: any[];
  GetSponsorRules: any[] = [];
  GetSponsorRulesItems:any;
  public groupItems: any[] = [];
  itemFilterUnSupported: any;
  GetitemFilterUnSupportedData: any;
  GroupId: any;
  itemFilterUnSupportedItems: any;
  GetFilterUnSupportedDataItem: any;
  cpctcode: any;
  SponsorRuleDetails: any;
  submitted: any;
  result: any;
  GetAllData: any;
  ruleId = 0;
  sponsorId = 0;
  addData: any;
  SponsorRule:SponsorRule;
  RuleIdResponse: any[] = [];
  itemFilterSupported: any;
  GetitemFilterSupportedData: any;
  itemFilterSupportedItems: any;
  GetitemFilterSupportedDataItems: any;
  modalRef: BsModalRef;
  @ViewChild("template") modaltemp: any;
  SponsorRulesData:any;
  SponsorRulesDatas: any;

  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  private groupsGrid: GridApi;
  private itemGrid: GridApi;

  public groupColumnDefs: ColDef[] = [
    {
      headerName: "",
      resizable: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      sortable: true,
      flex: 2,
      editable: false,
    },
    {
      headerName: "Group Name",
      field: "groupName",
      minWidth: 250,
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      flex: 2,
      filter: true,
      editable: false,
    },
    {
      headerName: "Ded. Amt",
      field: "dedAmt",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "Ded. %",
      field: "dedPer",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "Copay. Amt",
      field: "coPayAmt",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "Copay. %",
      field: "copayPer",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "Disc. Amt",
      field: "discAmount",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "Disc. %",
      field: "discPcnt",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "Deduction",
      field: "dedGroup",
      resizable: true,
      sortable: true,
      flex: 2,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "sponsorRule", gridType: 'dedGroup' },
      editable: false,
    },
    {
      headerName: "Copay",
      field: "coPayGroup",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: false,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "sponsorRule", gridType: 'coPayGroup' },
    },
  ]

  public itemColumnDefs: ColDef[] = [
    {
      headerName: "",
      resizable: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      sortable: true,
      flex: 2,
      editable: false,
    },
    {
      headerName: "Item Name",
      field: "itemName",
      minWidth: 250,
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      flex: 2,
      filter: true,
      editable: false,
    },
    {
      headerName: "New Name",
      field: "",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "CPT Code",
      field: "cptCode",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "New CPT Code",
      field: "",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "STD. Rate",
      field: "stdRate",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: false,
    },
    {
      headerName: "New Rate",
      field: "rate",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    // {
    //   headerName: "Disc. Amt",
    //   field: "discAmount",
    //   resizable: true,
    //   sortable: true,
    //   flex: 2,
    //   editable: true,
    // },
   
    // {
    //   headerName: "Disc. %",
    //   field: "discPcnt",
    //   resizable: true,
    //   sortable: true,
    //   flex: 2,
    //   editable: true,
    // },
    {
      headerName: "Disc. %",
      field: "discPcnt",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true, // Allow editing
      valueSetter: params => { 
        const discPcnt = params.newValue;
        const rate=params.data.rate
        if(discPcnt !=0 && rate !=0 ){
          params.data.discAmount=(discPcnt / 100) * rate         
        }
        params.data.discPcnt=discPcnt != "" ? discPcnt : 0
        return true
      },
    },
    {
      headerName: "Disc. Amount",
      field: "discAmount",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true, // Allow editing
      valueSetter: params => { 
        const discAmount = params.newValue != "" ? params.newValue : 0;
        const rate=params.data.rate
        if(discAmount !=0 && rate !=0 ){
          params.data.discPcnt=(discAmount/rate)*100        
        }
        params.data.discAmount=discAmount != "" ? discAmount : 0
        return true
      },
    },
    {
      headerName: "Deduction",
      field: "dedItem",
      resizable: true,
      sortable: true,
      flex: 2,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "sponsorRule", gridType: 'dedItem' },
      editable: false,
    },
    {
      headerName: "Copay",
      field: "coPayItem",
      resizable: true,
      sortable: true,
      flex: 2,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "sponsorRule", gridType: 'coPayItem' },
      editable: false,
    },
    {
      headerName: "Service Validity",
      field: "serviceValidityDays",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "Note",
      field: "note",
      resizable: true,
      sortable: true,
      flex: 2,
      editable: true,
    },
    {
      headerName: "Auth Req",
      field: "authReq",
      resizable: true,
      sortable: true,
      flex: 2,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "sponsorRule", gridType: 'authReq' },
      editable: false,
    },
    // {
    //   headerName: "Is Note",
    //   field: "authReq",
    //   resizable: true,
    //   sortable: true,
    //   flex: 2,
    //   cellRenderer: "buttonRender",
    //   cellRendererParams: { PageType: "sponsorRule", gridType: 'authReq' },
    //   editable: false,
    // },
  ]

  public frameworkComponents: any;
  public context = { componentParent: this };
  public loadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait data is loading...</span>';

  public AllGroups: any[] = [];

  private allGroupItems: any[] = [];
  private supportedGroups: any[] = [];
  private supportedItems: any[] = [];

  public dataFetchingLoading: boolean = true;
  public dataSaveLoading: boolean = false;

  public copyRuleLoading: boolean = false;
  validityFlag = true;

  constructor(
    private SponsorsService: SponsorsService,
    private sharedservice: SharedService,
    private activatedRoute: ActivatedRoute,
    private ConfigurationService: ConfigurationService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: BsModalService,

  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId;
      }
    });
  }

  onGridReadyGroup(params){
    this.groupsGrid = params.api;
    this.GetSponsorGroupForRule();
  }

  onGridReadyItem(params){
    this.itemGrid = params.api;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      
      if(params.id){
      this.ruleId = Number(params?.id);}
      
      if(params.sponsorId){
      this.sponsorId =  Number(params?.sponsorId);}
      
    });
    if(this.ruleId == 0){
      this.validityFlag = false;
    }

    this.SponsorRuleForm = new FormGroup({
      SponsorRuleGroupList: new FormArray([]),
      SponsorRuleItemList:new FormArray([]),
      SponsorId:new FormControl(this.sponsorId ? this.sponsorId : '',[Validators.required]),
      SponsorName: new FormControl(""),
      RuleDesc: new FormControl(''),
      RateGroupId: new FormControl(104,[Validators.required]),
      NewRuleDescription: new FormControl("", Validators.required),
      SessionId:new FormControl(0),
      UserId:new FormControl(Number(localStorage.getItem("user_id"))),
      Branchid:new FormControl(Number(this.globals.HospitalId)),
      Ruleid:new FormControl(this.ruleId? this.ruleId : ''),
      IsDisplayed: new FormControl(true),
      CopayBefDisc:new FormControl(false),
      DhaRule: new FormControl(false),
      UpfrontDed: new FormControl(false),
      IsDeleted:new FormControl(0),
      insAmountLimit: new FormControl(0)
    });

    this.copyRuleDesc=new FormGroup({
      ruleId: new FormControl("", Validators.required),
      sponsorId: new FormControl("", Validators.required)
    });

    this.GetAllSponsors();
    this.GetAllRateGroup();
    // this.loadAllGroupsItems()
    // this.GetAllCpctCode();

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    
  }

  //pagetitle to be displayed at the header
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);
  }

  GetAllSponsors() {
    let Input = {
      SponsorId: 0,
      Showall: 0,
      BranchId: this.globals.HospitalId,
    };
    this.SponsorsService.GetAllSponsorList(Input).subscribe((res) => {
      this.GetAllSponsorsData = res.response;
      this.GetAllData = [];
      this.GetAllSponsorsData.forEach((a) => {
        this.GetAllData.push({
          id: a.sponsorId.toString(),
          text: a.sponsorName,
        });
      });

    });

  }

  GetRuleDescription(event){
    if(event){
      let Input = {
        SponsorId: Number(event),
        BranchId:this.globals.HospitalId,
        ShowAll:0
      };
      this.SponsorsService.GetRuleDescription(Input).subscribe((res) => {
        this.GetSponsorRuleDesc = res.response;
        if(this.GetSponsorRuleDesc){
        this.GetSponsorsRuleData = [];
        this.GetSponsorRuleDesc.forEach((a) => {
          this.GetSponsorsRuleData.push({
            id: a.ruleId.toString(),
            text: a.ruleDesc,
          });
        });
      }
      });
    }
  }

  goBack(){
    if(!this.dataSaveLoading){
      this.router.navigate(['/config/SponsorAndSubagent'], {replaceUrl: true})
    }
  }

  onRateGroupChange(){
    let Input = {
      Ruleid: 0,
      Branchid: this.globals.HospitalId,
      Rgroupid: Number(this.SponsorRuleForm.get('RateGroupId').value)
    };
    this.groupsGrid.showLoadingOverlay();
    this.SponsorsService.GetSponsorItemForRules(Input).subscribe((res) => {
      this.allGroupItems = res.response;  
    });
    this.groupsGrid.hideOverlay();
  }

  GetAllRateGroup() {
    let input = {
      RGroupId: 0,
      ShowAll: 0,
      BranchId: this.globals.HospitalId,
    };
    this.ConfigurationService.GetRateGroup(input).subscribe((res) => {
      this.rateGroupDatas = res.response;
      this.GetSponsorsRateData = [];
      this.rateGroupDatas.forEach((a) => {
        this.GetSponsorsRateData.push({
          id: a.rGroupId.toString(),
          text: a.rGroupName,
        });
      });
    });
  }

  GetSponsorGroupForRule(){
    let Input = {
      "Ruleid": 0,
      "Branchid": this.globals.HospitalId
    };
    this.groupsGrid.showLoadingOverlay();
    this.dataFetchingLoading = true;
    this.SponsorsService.GetSponsorGroupForRules(Input).subscribe((res) => {
      this.GetSponsorRules = res.response;
        let Input = {
          Ruleid: 0,
          Branchid: this.globals.HospitalId,
          Rgroupid: Number(this.SponsorRuleForm.get('RateGroupId').value)
        };
        this.SponsorsService.GetSponsorItemForRules(Input).subscribe((res) => {
        this.allGroupItems = res.response;  
          
        if(this.ruleId && this.ruleId != 0 && this.sponsorId && this.sponsorId != 0){
          this.FetchingDetails();
        }
        else{
          this.FilterGroupsAndData();
        }
        });
    });
  }

  FilterGroupsAndData(){
    this.GetSponsorRules.forEach((group, index)=>{
      this.RuleIdResponse[0]?.sponsorRuleGroupList?.forEach((selectedGroup)=>{
        if(group.groupId == selectedGroup.groupId){
          this.GetSponsorRules[index] = selectedGroup
        }
      })
    });
    this.groupsGrid.redrawRows();
    this.AllGroups = this.GetSponsorRules;

    setTimeout(() => {
        this.groupsGrid.forEachNode((node, index) => {
          this.dataFetchingLoading = true;
          node.setSelected(false);
          this.RuleIdResponse[0]?.sponsorRuleGroupList?.forEach((group)=>{
            if(node.data.groupId == group.groupId){
              node.setSelected(true);
            }
          });
          setTimeout(()=>{
            this.dataFetchingLoading = (index == this.AllGroups.length - 1) ? false : true
          }, 2000)
        });
        
    }, 200);

    this.supportedItems = this.RuleIdResponse[0]?.sponsorRuleItemList? this.RuleIdResponse[0].sponsorRuleItemList : [];
    
    this.supportedItems.forEach((spItem)=> {
      let index = this.allGroupItems.findIndex(item=> spItem.itemId == item.itemId);
      if(index >= 0){
        this.allGroupItems[index].cptCode = spItem?.cptCode;
        this.allGroupItems[index].rate = spItem?.rate;
        this.allGroupItems[index].discPcnt = spItem?.discPcnt;
        this.allGroupItems[index].discAmount = spItem?.discAmount;
        this.allGroupItems[index].dedItem = spItem?.dedItem;
        this.allGroupItems[index].coPayItem = spItem?.coPayItem;
        this.allGroupItems[index].authReq = spItem?.authReq;
        this.allGroupItems[index].stdRate = spItem?.stdRate;
      }
      
    });
    this.groupsGrid.hideOverlay();
  }

  
  FetchingDetails(event? : any){
    this.ruleId = event ? Number(event) : this.ruleId;
    this.sponsorId = event ?  Number(this.SponsorRuleForm.get('SponsorId').value) : this.sponsorId
    if(this.ruleId && this.sponsorId){
      let Input={
        "ruleId":this.ruleId,
        "ShowAll":0,
        "BranchId":this.globals.HospitalId,
        "SponsorId": this.sponsorId
      }
      this.groupsGrid.showLoadingOverlay();
      this.itemGrid.setRowData([]);
      this.dataFetchingLoading = true;
        this.SponsorsService.GetSponsorRule(Input).subscribe((res) => {
          if(res.status == 200){
            this.RuleIdResponse = res.response;
            this.SponsorRuleForm.patchValue({ SponsorId: this.RuleIdResponse[0]?.sponsorId });
            this.SponsorRuleForm.patchValue({ RuleDesc: this.RuleIdResponse[0].ruleDesc });
            this.SponsorRuleForm.patchValue({ RuleId: this.RuleIdResponse[0].ruleId });
            this.SponsorRuleForm.patchValue({ RateGroupId: this.RuleIdResponse[0].rateGroupId });
            this.SponsorRuleForm.patchValue({ insAmountLimit: this.RuleIdResponse[0].insAmountLimit });
            this.SponsorRuleForm.patchValue({ CopayBefDisc: this.RuleIdResponse[0].copayBefDisc });
            this.SponsorRuleForm.patchValue({ IsDisplayed: this.RuleIdResponse[0].isDisplayed });
            this.SponsorRuleForm.patchValue({ UpfrontDed: this.RuleIdResponse[0].upfrontDed });
            this.SponsorRuleForm.patchValue({ DhaRule: this.RuleIdResponse[0].dhaRule });
            this.SponsorRuleForm.get('NewRuleDescription').clearValidators();
            this.SponsorRuleForm.get('NewRuleDescription').updateValueAndValidity();
            this.FilterGroupsAndData();
          }else{
            this.toastr.error('Failed to load insurance network..!')
          }
    
        });
    }
  
  }


  GetAllCpctCode() {
    let ShowAll=0;
    this.ConfigurationService.GetCpctCode(this.globals.HospitalId,ShowAll).subscribe((res) => {
      this.cpctcode = res.response;

    });
  }

  InsertUpdateSponsorRule(){
    this.submitted = true;
    this.itemGrid.stopEditing();
    this.groupsGrid.stopEditing();
    if (this.SponsorRuleForm && this.SponsorRuleForm.valid && !this.dataSaveLoading) {

    this.SponsorRuleDetails = this.SponsorRuleForm.getRawValue();
    let RategroupId=this.SponsorRuleDetails.RateGroupId;
    this.SponsorRuleDetails.RateGroupId = Number(RategroupId);

    let SponsorId=this.SponsorRuleDetails.SponsorId;
    this.SponsorRuleDetails.SponsorId = Number(SponsorId);

    let Ruleid=this.SponsorRuleDetails.Ruleid;
    this.SponsorRuleDetails.Ruleid = 0;
    if(this.ruleId){
      this.SponsorRuleDetails.Ruleid=Number(this.ruleId)?Number(this.ruleId):0;
    }
    
    this.SponsorRuleDetails.SponsorRuleGroupList = this.groupsGrid.getSelectedRows().map(list=>{
      let group=this.AllGroups.find(obj=> obj.ruleCategory == list.ruleCategory && obj.groupName == list.groupName)
      return {
        ...list,
        dedGroup:group?.dedGroup,
        coPayGroup:group?.coPayGroup
      }
    });
     
    this.SponsorRuleDetails.SponsorRuleGroupList.forEach((a) => {
      a.dedAmt = Number(a.dedAmt)? a.dedAmt = Number(a.dedAmt):0;
      a.dedPer = Number(a.dedPer);
      a.coPayAmt = Number(a.coPayAmt)?Number(a.coPayAmt):0;
      a.copayPer = Number(a.copayPer);
      a.discPcnt = Number(a.discPcnt);
      a.discAmount = Number(a.discAmount);
      a.dedGroup=a.dedGroup?a.dedGroup:false;
      a.coPayGroup=a.coPayGroup?a.coPayGroup:false;
    });
    
    this.SponsorRuleDetails.SponsorRuleItemList = this.supportedItems;
    this.SponsorRuleDetails.SponsorRuleItemList.forEach((a) => {
      a.discPcnt = Number(a.discPcnt);
      a.discAmount = Number(a.discAmount);
      a.cptCodeId = Number(a.cptCodeId);
      a.stdRate = Number(a.stdRate);
      a.rate = Number(a.rate);
      a.serviceValidityDays = Number(a.serviceValidityDays);
      a.authReq=a.authReq?a.authReq:false;
      a.dedItem=a.dedItem?a.dedItem:false;
      a.coPayItem=a.coPayItem?a.coPayItem:false
    });

    let ruleId = 0;
    if (this.ruleId > 0) {
      ruleId = this.ruleId;
    }

    this.dataSaveLoading = true;
    this.SponsorsService.InsertUpdateSponsorRule(this.SponsorRuleDetails).subscribe(
      (res) => {
        this.result = res;
        this.submitted = false;
        if (this.result.status == 200 && this.result.message == "Success") {
          if (ruleId == 0) {
            this.toastr.success("Insurance Network Added successfully");
            this.clearForm();
          } else {
            this.toastr.success("Insurance Network Updated successfully");
            this.ruleId = 0;
          }
           this.router.navigate(['/config/SponsorAndSubagent'])
          this.submitted = false;
        } else {
          this.toastr.error("Failed : " + this.result.message);
        }
        this.submitted = false;
        this.dataSaveLoading = false;
      }
    );
  }


  }

  get f() {
    return this.SponsorRuleForm.controls;
  }

  clearForm(){
    this.SponsorRuleForm.reset({
      SponsorRuleGroupList:'',
      SponsorId:Number(this.SponsorId),
      RuleDesc:'',
      RateGroupId:'',
      NewRuleDescription:'',
      UserId:Number(localStorage.getItem("user_id")),
      Branchid:Number(this.globals.HospitalId),
      Ruleid:'',
      IsDisplayed:true,
      CopayBefDisc:false,
      DhaRule:false,
      UpfrontDed:false,
      SponsorRuleItemList:'',

      })
  }

  NewRule(){
    this.modalRef = this.modalService.show(this.modaltemp); 
  }

  GetSponsorRule(){
    this.copyRuleDesc.get('ruleId').setValue('');
    let Input = {
      SponsorId: Number(this.copyRuleDesc.get('sponsorId').value),
      BranchId:this.globals.HospitalId,
      ShowAll:0
    };
    this.SponsorsService.GetRuleDescription(Input).subscribe((res) => {
      this.SponsorRulesData = res.response;
      this.SponsorRulesDatas = [];
        this.SponsorRulesData.forEach((a) => {
          this.SponsorRulesDatas.push({
            id: a.ruleId.toString(),
            text: a.ruleDesc,
          });
        })
    });
  }

  CopyNewRule(){
    const controls = this.copyRuleDesc.controls
    if(this.copyRuleDesc.invalid){
      Object.keys(controls).forEach(control=>{
        controls[control].markAllAsTouched();
      });
      this.toastr.error('Select a network to copy...');
      return
    }

    let Input={
      ruleId: Number(this.copyRuleDesc.get('ruleId').value),
      ShowAll:0,
      BranchId:this.globals.HospitalId,
      SponsorId: Number(this.copyRuleDesc.get('sponsorId').value)
    }
    this.copyRuleLoading = true;
    this.SponsorsService.GetSponsorRule(Input).subscribe((res) => {
      this.RuleIdResponse = res.response;
      this.FilterGroupsAndData();
      this.groupsGrid.setRowData(this.AllGroups);
      this.copyRuleLoading = false;
      this.modalRef.hide();
    });
  }

  onGroupCellClicked(event, show: boolean = false){
    
    if(event.colDef?.field == 'groupName' || show){
      let groupItems = [];
      this.groupItems = [];
      groupItems = this.allGroupItems.filter(value => value.insuranceGroupid == event.data.groupId);
      

      groupItems?.forEach((item, index)=>{
        // item.isNote = false
        this.supportedItems?.forEach((supported)=>{
          if(item.itemId == supported.itemId){
            supported.insuranceGroupid = groupItems[index].insuranceGroupid
            groupItems[index] = supported
            // if(item.note !== null){
            //   item.isNote = true
            // } else {
            //   item.isNote = false
            // }
          }
        })
      });
      console.log(groupItems,this.allGroupItems)
      this.groupItems = groupItems;
      
      this.itemGrid.setRowData(groupItems);
      this.itemGrid.redrawRows();
      
      setTimeout(() => {
        this.supportedItems?.forEach((item)=>{
          this.itemGrid.forEachNode((node) => {
            if(node.data.itemId == item.itemId){
              node.setSelected(true);
            }
          });
        })
      }, 100);

    }
  }

  onSelectionChanged(){
    let selected = this.itemGrid.getSelectedRows();
    let newSupported = this.supportedItems;
    if(newSupported.length > 0){
      newSupported.forEach((item, index)=>{
        selected.forEach((selectedItem)=>{
          if(selectedItem.itemId == item.itemId){
            newSupported[index] = selectedItem ;
          }else{
            newSupported.push(selectedItem);
          }
        })
      });
      this.supportedItems = newSupported;
    }else{
      this.supportedItems = selected;
    }
    this.supportedItems = [...new Map(this.supportedItems.map(item => [item['itemId'], item])).values()];

    this.itemGrid.forEachNode((nodeItem)=>{
      let index = this.supportedItems.findIndex(suppItem=> suppItem.itemId == nodeItem.data.itemId && !nodeItem.isSelected());
      if(index >= 0){
        this.supportedItems.splice(index, 1);
      }
    });
  }

  groupFieldChange(data, isChecked, fieldName){
    
    this.AllGroups.forEach((group,index)=>{
      if(group.groupId == data.groupId){
        this.AllGroups[index][fieldName] = isChecked;
        // group[fieldName] = isChecked;
        // group={ ...group, [fieldName]: isChecked };

        this.allGroupItems.forEach(item=>{
          if(item.insuranceGroupid == data.groupId){
            fieldName == 'dedGroup' ? item['dedItem'] = isChecked : (fieldName == 'coPayGroup' ? item['coPayItem'] = isChecked : '' );
          }
        });

        this.groupItems.forEach(item=>{
          if(item.insuranceGroupid == data.groupId){
            fieldName == 'dedGroup' ? item['dedItem'] = isChecked : (fieldName == 'coPayGroup' ? item['coPayItem'] = isChecked : '' );
          }
        })
      }
    });
    // this.AllGroups=[...this.AllGroups]
    // this.groupsGrid.setRowData(this.AllGroups)
    console.log(this.AllGroups)
    this.onSelectionChanged();
  }

  itemFieldChange(data, isChecked, field){
    this.allGroupItems.forEach((item)=>{
      if(item.itemId == data.itemId){
        item[field] = isChecked;
      }
    });

    this.groupItems.forEach((item)=>{
      if(item.itemId == data.itemId){
        item[field] = isChecked;
      }
    });
    this.onSelectionChanged();
  }

  onGroupSelectionChanged(event){
    
    if(!this.dataFetchingLoading){
      let groupItems = [];
      groupItems = this.allGroupItems.filter(value => value.insuranceGroupid == event.data.groupId);
      console.log(groupItems)
      if(event.node.selected){
        groupItems?.forEach((item, index)=>{
          this.supportedItems?.forEach((supported)=>{
            if(item.itemId == supported.itemId){
              groupItems[index] = supported
            }
          })
        });
        this.supportedItems.push(...groupItems);
        this.supportedItems = [...new Map(this.supportedItems.map(item => [item['itemId'], item])).values()];
      }else{
        let supportedCopy = this.supportedItems;
        groupItems?.forEach((item)=>{
            let index = this.supportedItems.findIndex(a=> a.itemId == item.itemId);
            if(index >=0 ){
              this.supportedItems.splice(index, 1);
            }
        });
      }
      console.log(this.supportedItems)
      this.onGroupCellClicked(event, true)
    }
  }

  onFilterTextBoxChangedItem(event){
    this.itemGrid.setQuickFilter(
      event.target.value
    );
  }

  changeServiceValidity(event){
    this.groupItems.find(x => {
      this.itemGrid.getSelectedRows().find(y => {
        if(x.itemId == y.itemId){
          x.serviceValidityDays = Number(event.target.value);
        }
      })
      // x.serviceValidityDays = Number(event.target.value);
    })
    this.itemGrid.refreshCells();
    
  }

  test(event){

    this.allGroupItems.find(x => {
      if(x.ruleCategory == 'Supported Items'){
      x.serviceValidityDays = Number(event.target.value);
      }
    })
    this.supportedItems.find(x => {
      if(x.ruleCategory == 'Supported Items'){
      x.serviceValidityDays = Number(event.target.value);
      }
    })
    
    this.itemGrid.refreshCells();
  }

  async onItemCellClicked(event){
    if(event.colDef?.field == 'note'){
      let initialState = {
        noteFieldData: this.itemGrid.getRowNode(event.rowIndex).data.note,
        action: 'addEdit'
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      const modalRef = this.modalService.show(SponsorRuleItemNoteComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
      await modalRef.content.noteDataEmitter.subscribe((res) => {
        if (res.status == 'AddData') {
          const rowData = this.itemGrid.getRowNode(event.rowIndex).data;
          this.itemGrid.getRowNode(event.rowIndex).data.note = res.data;
          this.itemGrid.refreshCells();
        }
      })

    }
  }

}
