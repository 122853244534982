import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfigurationService } from '../../client/services/configuration.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from "../../../shared/shared.service";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { CFormOrderModalComponent } from './c-form-order-modal/c-form-order-modal.component';
import { Router } from '@angular/router';
// import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-consultantComponent',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss']
})
export class consultantComponent implements OnInit {
  products3: any[];
  items1: any = {};
  displayBasic: boolean;
  displayitembase: boolean;
  selectedProduct1: any;
  selectedProducts2: any[];
  displayblock: boolean;
  displayworking: boolean;
  displayclinic: boolean;

  AddConsultant: boolean = true;
  ConsultantWorkingDays: boolean
  products: any = {};
  //consultantForm: FormGroup;
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  columnDefsConsultantWorking: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public imageComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public statusBar;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    // rowClassRules: {
    //   'c-reds': function (params) { return params.data.active == '0'; },
    //   'c-reds2': function (params) {  return params.data.appStatus == 'Cancelled'; },
    // },

  }
  rowData: any;
  RowData: any;
  consultantId: any;
  result: any;
  modalRefDelConsultant: BsModalRef;
  @ViewChild("template2") modaltemp1: any;
  selectedMenuId: string;
  functionPermissionData: any;

  constructor(
    private ConfigurationService: ConfigurationService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private router: Router
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId;
      }
    });
    this.selectedMenuId = localStorage.getItem('selectedMenu');
  }

  ngOnInit(): void {

    //initializing Consultants data into datatable
    this.GetAllConsultants();
    this.functionPermission();


    this.columnDefs = [
      {
        headerName: "Consultant Name",
        field: "consultantName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 180,
        // maxWidth: 250
      },
      {
        headerName: "Consultant Code",
        field: "consultantCode",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 180,
        // maxWidth: 250
      },
      {
        headerName: "Mobile",
        field: "mobile",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 180,
        // maxWidth: 250
      },
      {
        headerName: "Designation",
        field: "designation",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 180,
        // maxWidth: 250
      },

      {
        headerName: "Sort Order",
        field: "sortOrder",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        minWidth: 180,
        // maxWidth: 250

      },

      {
        headerName: "Action",
        resizable: false,
        filter: false,
        unSortIcon: true,
        pinned:"right",
        sortable: false,
        field: "Id",
        // minWidth: 180,
        maxWidth: 100,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "MaterConsultant" },
      },
    ]

    this.columnDefsConsultantWorking = [
      {
        headerName: "First Name",
        field: "firstName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        minWidth: 180,


      },
      {
        headerName: "consultant Code",
        field: "consultantCode",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        minWidth: 180,
        flex: 2,

      },

      {
        headerName: "Designation",
        field: "designation",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        minWidth: 180,
        flex: 2,

      },




    ]


    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
      onGridReady: (event) => event.api.sizeColumnsToFit()

    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }
  showBasicDialog() {
    this.displayBasic = true;
  }


  showblock() {
    this.displayblock = true;
  }

  showWorkingDays() {
    this.displayworking = true;
  }

  showClinic() {
    this.displayclinic = true;
  }

  //pagetitle to be displayed at the header
  Activetab(pagetitle) {
    const foundElement = this.functionPermissionData.find(x => x.id === 4)
    if (foundElement.id == 4 && foundElement.isAllowed == true) {
      sessionStorage.setItem("pageTitle", pagetitle);
      this.router.navigate(['/config/addconsultant']);
    } else {
      this.toastr.warning("You are not allowed to add a consultant");
    }

  }
  customSort(event) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }


  GetAllConsultants() {

    this.ConfigurationService.GetAllConsultants(this.globals.HospitalId).subscribe((res) => {
      this.RowData = res.response;

    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  DeleteConsultant(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 6)
    if (foundElement.id == 6 && foundElement.isAllowed == true) {
      this.modalRefDelConsultant = this.modalService.show(
        this.modaltemp1,
        // Object.assign({}, { class: "gray modal-lg" })
      );
      this.consultantId = data.consultantId;
    } else {
      this.toastr.warning("You are not allowed to delete consultant data");
    }

  }
  ConfirmDeleteConsultant() {
    this.ConfigurationService.DeleteConsultant(this.consultantId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success("Consultant deleted successfully");
        this.GetAllConsultants();
        this.modalRefDelConsultant.hide();
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
      }
    },
    );
  }
  onTab(number) {
    this.AddConsultant = false;
    this.ConsultantWorkingDays = false;

    if (number == "1") {
      this.AddConsultant = true;
    } else if (number == "2") {
      this.ConsultantWorkingDays = true;
    }
  }
  openModalDeleteConsultant(template2: TemplateRef<any>) {

    this.modalRefDelConsultant = this.modalService.show(template2);

  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  onCFormModal(data: any){
    const foundElement = this.functionPermissionData.find(x => x.id === 9)
    if (foundElement.id == 9 && foundElement.isAllowed == true) {
      let initialState = {
        consultantId: data.consultantId,
        branchId: this.globals.HospitalId,
        userId: Number(localStorage.getItem("user_id")),
        consultantList: this.RowData
      }
      let historyConfig: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-dialog-scrollable'
      }
      const cFormModalRef = this.modalService.show(CFormOrderModalComponent, Object.assign(
        { class: "modal-dialog-centered " },
        historyConfig,
        { class: 'modal-lg', initialState }));
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  functionPermission() {
    let input = {
      MenuId: Number(this.selectedMenuId),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });
  }

  editConsultant(consultantId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 5)
    if (foundElement.id == 5 && foundElement.isAllowed == true) {
      this.router.navigate(["/config/addconsultant", consultantId]);
    } else {
      this.toastr.warning("You are not allowed to edit consultant data");
    }
  }

  consultantBaseCost(consultantId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 7)
    if (foundElement.id == 7 && foundElement.isAllowed == true) {
      this.router.navigate(["/config/itembasecost", consultantId]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

  consultantWorkingDays(consultantId) {
    const foundElement = this.functionPermissionData.find(x => x.id === 8)
    if (foundElement.id == 8 && foundElement.isAllowed == true) {
      this.router.navigate(["/config/consultantworkingdays", consultantId]);
    } else {
      this.toastr.warning("You are not allowed");
    }
  }

}



