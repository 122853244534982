import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-log-report',
  templateUrl: './user-log-report.component.html',
  styleUrls: ['./user-log-report.component.scss']
})
export class UserLogReportComponent implements OnInit {

  users = [
    {key: 1, value: 'Admin'},
    {key:2, value: 'Consultant'}
  ]

  tasks = [
    {key:1, value: 'Registration'},
    {key:1, value: 'New Consultation'}
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
