import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AlertConfig, AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { FullCalendarModule } from '@fullcalendar/angular';
import { AgmCoreModule } from '@agm/core';
import { AgGridModule } from 'ag-grid-angular';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { DialogModule } from 'primeng/dialog';
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin
import interactionPlugin from "@fullcalendar/interaction"; // a plugin
import timeGridPlugin from "@fullcalendar/timegrid";
import { DataTablesModule } from "angular-datatables";
import { ClientRoutingModule } from './client-routing.module';
import { TableModule } from "primeng/table";
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmailComponent } from './email/email.component';
import { ChartsComponent } from './charts/charts.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { ColumnChartComponent } from './charts/column-chart/column-chart.component';
import { FilemanagerComponent } from './filemanager/filemanager.component';
import { FullcalenderComponent } from './fullcalender/fullcalender.component';
import { ComposeEmailComponent } from './email/compose-email/compose-email.component';
import { ComposeEmailDetailsComponent } from './email/compose-email-details/compose-email-details.component';
import { PagesComponent } from './pages/pages.component';
import { PaymentsComponent } from './payments/payments.component';
import { AddpaymentsComponent } from './payments/addpayments/addpayments.component';
import { InvoicesComponent } from './payments/invoices/invoices.component';
import { InvoiceDetailComponent } from './payments/invoices/invoice-detail/invoice-detail.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { EventsComponent } from './doctors/doctors-schedule/events/events.component';
import { CovidComponent } from './dashboard/covid/covid.component';
import { AddRegistrationComponent } from './frontoffice/addregistration/addregistration.component';
import { RegistrationComponent } from './frontoffice/registration/registration.component';
import { ConsultationComponent } from './frontoffice/consultation/consultation.component';
import { AddConsultationComponent } from './frontoffice/addconsultation/addconsultation.component';
import { AppointmentComponent } from './frontoffice/checkappointment/appointment.component';
import { AddAppointmentComponent } from './frontoffice/addappointment/addappointment.component';
import { FrontofficeComponent } from './frontoffice/frontoffice.component';

import { editRegistrationComponent } from './frontoffice/editregistration/editregistration.component';
import { editConsultationComponent } from './frontoffice/editconsultation/editconsultation.component';
import { editAppointmentComponent } from './frontoffice/editappointment/editappointment.component';
import { addscheduledComponent } from './frontoffice/appointmentscheduled/appointmentschedule.component';
import { consultantdeskComponent } from './consultantdesk/consultantdesk.component';
import { todayspatientComponent } from './consultantdesk/todayspatient/todayspatient.component';

import { ConfigComponent } from './configuration/config.component';


import { ServiceOrderingComponent } from './serviceordering/serviceorderdetails.component';
import { ServiceOrdersComponent } from './serviceordering/serviceorder/serviceordering.component';
import { pendingOrdersComponent } from './serviceordering/pendingOrders/pendingordering.component';
import { ServiceListComponent } from './serviceordering/service-list/service-list.component';
import { AddServiceComponent } from './serviceordering/add-service/add-service.component';

//import 'ag-grid-enterprise';
import { GeneralActionComponent } from './general-action/general-action.component';

import { MegaMenuModule } from 'primeng/megamenu';
// import { HeaderComponent } from './header/header.component';
// import { LeftmenuComponent } from './leftmenu/leftmenu.component';
// import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '../../shared/models/shared.module';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelect2Module } from 'ng-select2';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { DragDropModule } from 'primeng/dragdrop';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserModule } from '@angular/platform-browser';
import { TextMaskModule } from 'angular2-text-mask';
import { NgApexchartsModule } from 'ng-apexcharts';
import { WebcamModule } from 'ngx-webcam';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { PickListModule } from 'primeng/picklist';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { MatExpansionModule } from '@angular/material/expansion';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AccordionModule } from 'primeng/accordion';
import { GMapModule } from 'primeng/gmap';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';

import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxTextEditorModule } from 'ngx-text-editor';

import { PostponeAppointmentComponent } from './frontoffice/postpone-appointment/postpone-appointment.component';
import { CancelAppointmentComponent } from './frontoffice/cancel-appointment/cancel-appointment.component';
import { UserDetailsAppointmentComponent } from './frontoffice/userdetails-appointment/userdetails-appointment.component';
import { DateComponent } from './dateformat/date-format.component';
import { SearchPatientComponent } from './frontoffice/searchpatient/searchpatient.component';
//import {DepartmentComponent } from './department/department.component';
import { SearchPatientModalComponent } from './frontoffice/searchpatient-modal/searchpatient-modal.component';
import { CdTimerModule } from 'angular-cd-timer';
import { NgxTimerModule } from 'ngx-timer';
import { personalisationComponent } from './consultantdesk/personalisation/personalisation.component';
import { addDiseaseComponent } from './consultantdesk/personalisation/addDisease/addDisease.component';
import { addServiceItemsComponent } from './consultantdesk/personalisation/addServiceItems/addServiceItems.component';
import { addMarksComponent } from './consultantdesk/personalisation/addMarks/addMarks.component';
import { addDrugsComponent } from './consultantdesk/personalisation/addDrugs/add_drugs.component';
import { AddSketchComponent } from './consultantdesk/personalisation/add-sketch/add-sketch.component';
import { SketchButtonsComponent } from './consultantdesk/personalisation/add-sketch/sketch-buttons/sketch-buttons.component';
//import { SketchMarkingsComponent } from './consultantdesk/personalisation/add-sketch/sketch-markings/sketch-markings.component';
import { SketchCanvasComponent } from './consultantdesk/personalisation/add-sketch/sketch-canvas/sketch-canvas.component';
//import { SVGService } from '../../core/Directive/svg.service';
// import { DraggableDirective } from '../../core/Directive/Draggable';
// import { DroppableDirective } from '../../core/Directive/Dropable';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { PatientPrintComponent } from './patient-print/patient-print.component';
import { PatientStickerComponent } from './patient-sticker/patient-sticker.component';

import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PatientRegistrationComponent } from './frontoffice/patient-registration/patient-registration.component';
import { WorkListManageComponent } from './serviceordering/modals/work-list-manage/work-list-manage.component';
// register FullCalendar plugins
// FullCalendarModule.registerPlugins([
//   dayGridPlugin,
//   timeGridPlugin,
//   interactionPlugin
// ]);
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ReportManageComponent } from './serviceordering/report-manage/report-manage.component';
import { SponsorConsentComponent } from './frontoffice/modals/sponsor-consent/sponsor-consent.component';
import { SafeHtmlModule } from '../../shared/pipes/safe-html/safe-html.module';
import { PrintAsoapComponent } from './frontoffice/modals/print-asoap/print-asoap.component';
import { PatientQuestionnaireModalComponent } from './frontoffice/modals/patient-questionnaire-modal/patient-questionnaire-modal.component';
import { AddDiseaseModalComponent } from './consultantdesk/modals/add-disease-modal/add-disease-modal.component';
import { AttachmentModalComponent } from './frontoffice/modals/attachment-modal/attachment-modal.component';
import { PrintBarcodeModalComponent } from './frontoffice/modals/print-barcode-modal/print-barcode-modal.component';
import { FetchEmiratesCardModalComponent } from './frontoffice/modals/fetch-emirates-card-modal/fetch-emirates-card-modal.component';
import { ConsultantListComponent } from './dashboard/modal/consultant-list/consultant-list.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PasswordResetComponent } from './user-profile/modal/password-reset/password-reset.component';
import { ShowOverbookedAppoinmentsModalComponent } from './frontoffice/modals/show-overbooked-appoinments-modal/show-overbooked-appoinments-modal.component';
import { ChangeFeeComponent } from './consultantdesk/modals/change-fee/change-fee.component';
import { ChangeStatusModalComponent } from './consultantdesk/change-status-modal/change-status-modal.component';
import { StaticPatientConsentComponent } from './frontoffice/modals/static-patient-consent/static-patient-consent.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NabithConsentComponent } from './frontoffice/addregistration/nabith-consent/nabith-consent.component';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { ServiceListModalComponent } from './serviceordering/modals/service-list-modal/service-list-modal.component';
import { StatusInfoModalComponent } from './frontoffice/modals/status-info-modal/status-info-modal.component';
import { SearchPrescriptionComponent } from './frontoffice/search-prescription/search-prescription.component'
import { AccordionModule as AccordionsModule } from 'ngx-bootstrap/accordion';
import { DhaLoginComponent } from './consultantdesk/personalisation/dha-login/dha-login.component';
import { InformedStaticConsentComponent } from './frontoffice/modals/informed-static-consent/informed-static-consent.component';
import { RiayatiConsentComponent } from './frontoffice/addregistration/riayati-consent/riayati-consent.component';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
  imports: [
    SharedModule,
    NgSelect2Module,
    NgxUiLoaderModule,
    DataTablesModule,
    CommonModule,
    ClientRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    AgGridModule.withComponents(),
    DragDropModule,
    MatSliderModule,
    NgApexchartsModule,
    SidebarModule,
    ButtonModule,
    TableModule,
    PickListModule,
    TreeTableModule,
    TreeModule,
    VirtualScrollerModule,
    TieredMenuModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    InputTextModule,
    SplitButtonModule,
    MegaMenuModule,
    MenubarModule,
    CardModule,
    SlideMenuModule,
    DropdownModule,
    BrowserModule,
    InputMaskModule,
    CarouselModule,
    DynamicDialogModule,
    TextMaskModule,
    WebcamModule,
    FileUploadModule,
    HttpClientModule,
    InputNumberModule,
    MultiSelectModule,
    NgMultiSelectDropDownModule,
    GMapModule,
    AccordionModule,
    AccordionsModule,
    AutocompleteLibModule,
    MatExpansionModule,
    ButtonsModule,
    AgGridModule.withComponents(),
    NgxBarcodeModule,
    NgxTextEditorModule,
    NgxUiLoaderModule,
    CdTimerModule,
  NgxTimerModule,
    TypeaheadModule,
    PaginationModule.forRoot(),
    ToastrModule.forRoot({
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'GOOGLE_API_KEY'
    }),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule,
    CKEditorModule,
    SafeHtmlModule,
    TelerikReportingModule,
    PdfJsViewerModule,
  ],
  declarations: [
    ClientRoutingModule.components,

    DashboardComponent,
    EmailComponent,
    ChartsComponent,
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
    ColumnChartComponent,
    FilemanagerComponent,
    FullcalenderComponent,
    ComposeEmailComponent,
    EventsComponent,
    ComposeEmailDetailsComponent,
    PagesComponent,
    InvoicesComponent,
    InvoiceDetailComponent,
    PaymentsComponent,
    AddpaymentsComponent,
    AppointmentComponent,
    DoctorsComponent,
    RegistrationComponent,
    ConsultationComponent,
    CovidComponent,
    AddRegistrationComponent,
    AddConsultationComponent,
    AddAppointmentComponent,
    FrontofficeComponent,

    editRegistrationComponent,
    editConsultationComponent,
    editAppointmentComponent,
    addscheduledComponent,
    consultantdeskComponent,
    todayspatientComponent,

    ConfigComponent,


    ServiceOrderingComponent,
    ServiceOrdersComponent,
    ServiceListComponent,
    AddServiceComponent,
    pendingOrdersComponent,

    GeneralActionComponent,

    PostponeAppointmentComponent,
    CancelAppointmentComponent,
    UserDetailsAppointmentComponent,
    DateComponent,
    // SearchPatientComponent,
    //SearchPatientModalComponent,

    personalisationComponent,
    addDiseaseComponent,
    addServiceItemsComponent,
    addMarksComponent,
    addDrugsComponent,
    AddSketchComponent,
    SketchButtonsComponent,
 //   SketchMarkingsComponent,
    SketchCanvasComponent,
    // DraggableDirective,
    // DroppableDirective,


    PatientPrintComponent,
    PatientStickerComponent,

    PatientRegistrationComponent,
      WorkListManageComponent,
      ReportManageComponent,
      SponsorConsentComponent,
      PrintAsoapComponent,
      PatientQuestionnaireModalComponent,
      AddDiseaseModalComponent,
      AttachmentModalComponent,
      PrintBarcodeModalComponent,
      FetchEmiratesCardModalComponent,
      ConsultantListComponent,
      UserProfileComponent,
      PasswordResetComponent,
      ShowOverbookedAppoinmentsModalComponent,
      ChangeFeeComponent,
      ChangeStatusModalComponent,
      StaticPatientConsentComponent,
      ResetPasswordComponent,
      NabithConsentComponent,
      ServiceListModalComponent,
      StatusInfoModalComponent,
      SearchPrescriptionComponent,
      DhaLoginComponent,
      InformedStaticConsentComponent,
      RiayatiConsentComponent,

  ],
  providers: [BsDatepickerModule, DatePipe, AlertConfig, BsDatepickerConfig, BsDropdownConfig]
})
export class ClientModule { }



