import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';

import { Response } from '../../../_Models/Response';
import { UserProfile } from '../interfaces/UserProfile';
import { ErrorHandleService } from './error-handle.service';

@Injectable({
  providedIn: 'root'
})
export class ProfessionService {
  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }




  AddProfession(appData, id: Number, branchId) {
    var url = "api/MasterData/InsertUpdateCommonMasterItem";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "MasterFieldType": "Profession",
        "CodeData": appData.profCode,
        "NameData": appData.profName,
        "Id": id,
        "IsDisplayed": appData.IsDisplayedGroup,
        "BranchId": branchId,
        "UserId": Number(localStorage.getItem('user_id')),
      }



    );


  }



  GetAllProfession(BranchId) {
    var url = "api/MasterData/GetProfession";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "ProfId": 0,
        "ShowAll": 1,
        "BranchId": BranchId

      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }


  DeleteProfession(id: Number) {
    var url = "api/MasterData/DeleteProfession/";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      { "ProfId": id }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

}

