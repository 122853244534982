import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PasswordResetComponent } from './modal/password-reset/password-reset.component';
import { ActivatedRoute, Router } from "@angular/router";
import { UserpermissionService } from "../../user-permission/userpermission.service";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  userId:any;
  userData:any;
  userRole:any;
  userForm: FormGroup;
  userName:any;
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private userservice: UserpermissionService,

  ) { 

  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      UserId: new FormControl(this.userId),
      name: new FormControl(''),
      bod: new FormControl(''),
      gender: new FormControl(''),
      email: new FormControl(''),
      phoneNo: new FormControl(''),
      address: new FormControl(''),


    });


    this.getUserProfile();
  }
  resetPassword(){
    let initialState={
      userId:Number(localStorage.getItem("user_id"))
    }
    const modalRef = this.modalService.show(PasswordResetComponent, Object.assign({ initialState }))

  }
  getUserProfile(){
    let input={
      userId:Number(localStorage.getItem("user_id"))
    }
    this.userservice.GetUserData(input).subscribe((res) => {
      this.userData = res.response;
      this.userRole=this.userData[0].userRole;
      this.userName=this.userData[0].userName;
    this.userForm.patchValue({ name: this.userData[0].nameOfUser});
    this.userForm.patchValue({ bod: this.userData[0].dob});
    this.userForm.patchValue({ gender: this.userData[0].gender});
    this.userForm.patchValue({ email: this.userData[0].email});
    this.userForm.patchValue({ phoneNo: this.userData[0].mobile});
console.log(    this.userForm.patchValue({ phoneNo: this.userData[0].mobile})
);

    });

  }
}
