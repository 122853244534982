import { Component, OnInit } from '@angular/core';
import { ConsultationsList } from '../../../interfaces/ConsultationsList';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// loading Consultation Service
import { ConsultaionsService } from '../../../services/consultations.service';

import { AllAppointmentsList } from '../../../interfaces/AllAppointmentsList';

// loading Appointment Service
import { AppointmentsService } from '../../../services/appointments.service';
import { ToastrService } from 'ngx-toastr';
import { PatientList } from '../../../interfaces/PatientList';
import { Router } from '@angular/router';
// loading Patients Service
import { PatientService } from '../../../services/patient.service';

import { ColDef, GridOptions } from 'ag-grid-community';
import { GeneralActionComponent } from '../../../general-action/general-action.component';
import { ConsultantDeskService } from '../../../services/consultant.service';
import { SharedService } from '../../../../../shared/shared.service';
import { globalvars } from '../../../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: 'app-addMarks ',
  templateUrl: './addMarks.component.html',
  styleUrls: ['./addMarks.component.scss'],

})
export class addMarksComponent  implements OnInit {
    products: any={ };
    Disease: boolean ;
    myPatients: boolean;
    myAppointment: boolean;
    myConsultation: boolean;
    Personalisations: boolean;
    Notifications: boolean;
    Changepassword: boolean;
    displayModal: boolean;
    displayModal1: boolean;
    displayModaluser: boolean;
    displaybranch:boolean;
    rowData : any=[];
    username='';
    password='';
    consultations: ConsultationsList[]=[];
    items: any = {};
    user_role:any;
    user_id :any;
    Id:any;
    appointments: AllAppointmentsList[] = [];
    users:any=[];
    selectedCities: any[] = [];
    availableProducts:any=[];
    selectedProducts: any=[];
    draggedProduct: any=[];
    patients:PatientList[]=[];
    columnDefs: ColDef[] =[];
    columnDefsapp: ColDef[] =[];
    columnDefconsult: ColDef[] =[];
    AddMarksForm: FormGroup;
    attachdocuments:boolean;
    CancelAppId:number;
    printmodal: boolean;
    myFile: any;
    CancelAppointments: boolean;
    confirmblockingConsultants:any;
    public submitted: boolean = false;
    router_url = this.router.url;
    //ag grid setup
    public gridApi;
    public rowSelection = true;
    public defaultColDef: ColDef;
    public context;
    public frameworkComponents;
    public imageComponents;
    public noRowsTemplate;
    public loadingTemplate;
    public gridOptions = {
      pagination: true,
      paginationPageSize:10,
      rowClassRules: {
        'c-reds': function (params) { return params.data.active == '0'; },
        'c-reds2': function (params) {  return params.data.appStatus == 'Cancelled'; },
      },

    }
  consultantDataDetails: any;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  BranchesId: any;
  appointmentDetails: any;
  consultationsDetails: any;
  active: string;
  blockorunblock: boolean;
  blockId: number;
  confirmblock: boolean;
  displayBasicUser: boolean;
  patientId: any;
  displayBasicdragdrop: boolean;
  regDocLocation: any;
  documentList: any;
  pagetype: any;
  patientmodal: any;
  gender: any;
  regDate: any;
  patientName: any;
  address: any;
  mobile: any;
  regNo: any;
  age: any;
  regDetails: PatientList[];
  regIdentities: any;
  historyconsent: boolean;
  ConsentHistory: any;
  consentHistoryList: any;
  consentHistoryListArabic: any;
  consentHistoryPatientName: any;
  consentHistorySignature: any;
  PostponeObj:any;
  consultationId: any;
  CancelledReason: any;
  symptomDetails: ConsultationsList[];
  ReasonVisit: boolean;
  symptomList: any[];
  dropdownSettings: IDropdownSettings = {};
  result: any;
  blockorunblockconsult: boolean;
  documentFile: File[];
  documentFile1: any;
  displayBasic: boolean;
  showWebcam: boolean;
  public statusBar;
  consentDate: any;
  myObservable$: Subject<unknown>;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  generatedImage: string;
  minDate: any;

  rowGroupPanelShow = "always";
  OtherSymptom: boolean;
  consultantDataDetailsEvent: any[];
  BranchId: any;
  valueDate: Date;
  valueDate2: Date;
  FromDate: string;
  ToDate: string;
  minDate1 = new Date();
  appointmentDate: string;
  CurrentappointmentDate: string;
  AppointmentDataDetails: any;
  ServiceItems: boolean;
  DrugList: boolean;
  Marks: boolean;
  Sketch: boolean;
  GetSymptoms: any;
  GetSymptomsVitalSign: any;
  SymptomsVitalSign: any[];
  GetDiseasesIcd: any;
  SaveMarks: any;
  Indicators: any;
  marksId: any;
  MarksId: any;
  MarksResponse:any;
  constructor(
    private consultationService:ConsultaionsService,
    private appointmentService: AppointmentsService,
    private patientService:PatientService,
    private ConsultantDeskService:ConsultantDeskService,
    private sharedservice: SharedService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private ngxLoader:NgxUiLoaderService
  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
         this.BranchesId=this.globals.HospitalId
         localStorage.setItem('BranchId', this.BranchesId);
      }
      this.BranchId =  localStorage.getItem('BranchId');

    });


  }




  ngOnInit(): void {

    this.AddMarksForm = this.formBuilder.group({

      indicator:new FormControl('0'),
      MarkDescription:new FormControl(''),
      Color:new FormControl(''),

    });

    this.activatedRoute.params.subscribe((params) => {
      this.MarksId = Number(params.id);
    });
    // getting the values of user_role and user_id  from session

    this.user_role = localStorage.getItem('user_role');
    this.user_id =  localStorage.getItem('user_id');
    this.Id =  localStorage.getItem('Id');
   if(this.MarksId){
    this.GetFetchingDetails();
   }


//Patients ag grid List
this.columnDefs=[


  {
    headerName: "Drugs Description",
    field: 'regDate',
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    flex: 2,
    editable: true
  },



]
this.GetAllSymptomsDiseases();
  this.GetDiseaseVitalSigns();

  this.GetAllIndicators();
  }


  onTab(number) {
    this.Disease = false;
    this.ServiceItems = false;
    this.DrugList = false;
    this.Marks = false;
    this.Sketch = false;

    if (number == '1') {
      this.Disease = true;
    }
    else if (number == '2') {
      this.ServiceItems = true;
    }

    else if (number == '3') {
      this.DrugList = true;
    }
    else if (number == '4') {
      this.Marks = true;
    }
    else if (number == '5') {
      this.Sketch = true;
    }


  }

     //Reseting Form
     ClearForm() {

      this.AddMarksForm.reset({
        indicator: "",
        MarkDescription:"",
        Color:""

      });
      this.AddMarksForm.clearValidators();

    }



  TabSelection(){
    this.Disease = false;
    this.ServiceItems = false;
    this.DrugList = false;
    this.Marks = false;
    this.Sketch = false;
  }

     //pagetitle to be displayed at the header
     Activetab(pagetitle) {
      sessionStorage.setItem("pageTitle", pagetitle);

    }

    onGridReady(params) {
      this.gridApi = params.api;
    }

    GetAllSymptomsDiseases(){
      this.ConsultantDeskService.GetDiseaseSymptoms(this.Id).subscribe((res) => {
        this.GetSymptoms = res.response;
    });

}
GetDiseaseVitalSigns(){
  this.ConsultantDeskService.GetDiseaseVitalSigns(this.Id).subscribe((res) => {
    this.GetSymptomsVitalSign = res.response;

});

}



SaveDiseases(){

}

isAllCheckBoxChecked() {
  return this.products.every(p => p.checked);

}


//checkAllCheckBox(ev) { // Angular 9
checkAllCheckBox(ev: any) { // Angular 13
  this.products.forEach(x => x.checked = ev.target.checked)
}

SaveAddMarks(){
  this.activatedRoute.params.subscribe(params => {
    this.marksId = Number(params.id);
  });

  if(this.marksId){
    this.ConsultantDeskService.InsertUpdateConsultantMarking(this.marksId,this.AddMarksForm,this.Id).subscribe((res) => {
      this.SaveMarks = res.response;
      this.result = res;
      {
        if (this.result.message == "Updated Successfully") {
          this.ngxLoader.stop();

          this.toastr.success(" Updated Successfully");

        }

        else {
          this.ngxLoader.stop();
          this.toastr.error("Failure: " + this.result.message);
        }

        this.submitted = false;
      }
    })
  }
  this.ConsultantDeskService.InsertSaveMarks(this.AddMarksForm,this.Id).subscribe((res) => {
      this.SaveMarks = res.response;
      this.result = res;
      {
        if (this.result.message == "Success") {
          this.ngxLoader.stop();

          this.toastr.success(" Marks added successfully");

        }

        else {
          this.ngxLoader.stop();
          this.toastr.error("Failure: " + this.result.message);
        }

        this.submitted = false;
      }
    });
}

GetAllIndicators(){

  this.ConsultantDeskService.GetAllIndicators(this.globals.HospitalId).subscribe((res) => {
    this.Indicators = res.response;
});
}


GetFetchingDetails(){
  this.activatedRoute.params.subscribe((params) => {
    this.MarksId = Number(params.id);
  });

  this.ConsultantDeskService.FetchingMarksDetails(this.MarksId).subscribe((res) => {
    this.MarksResponse = res.response;
    this.AddMarksForm.patchValue({
      indicator: this.MarksResponse[0].indicatorId,
      MarkDescription: this.MarksResponse[0].markDesc,
       Color: this.MarksResponse[0].colour,
    });
});






}


}

