import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ErrorHandleService } from '../../client/services/error-handle.service';
import { Response } from '../../../_Models/Response';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  private baseUrl: string = "";
  value: any;

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService,
    private router: Router,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  /////////////////OPen Credit//////////////////////////////////////
    GetSponsorDetailsByPatient(InputDetails){
      var url = "api/Bill/GetSponsorDetailsByPatient";
      return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
    }

    getConsulatationsList(payload){
      var url = "api/Emr/GetConsultationForExternalBill";
      return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
    }
  /////////////////////////////////////////////////////////////////////

  GetGroupData(inputData){
    var url = "api/Bill/GetItemsGroups";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  GetSponsorRule(inputData){
    var url = "api/Sponsor/GetSponsorRule";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  GetCreditItemGroup(inputData){
    var url = "api/Bill/GetCreditItemGroup";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }

  GetSponsorItemForRules(Input){
    var url = "api/Sponsor/GetSponsorItemForRule";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, Input);
  }

  InsertCredit(inputData){
    var url = "api/Bill/InsertCredit";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  GetAgentForSponsor(sponserId){
    var url = "api/Agent/GetAgentForSponsor/"+sponserId;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,{})
  }
  GetOpenCreditsByPatient(inputData){
    var url = "api/Bill/GetSponsorDetailsByPatient";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  GetCredit(inputData){
    var url = "api/Bill/GetCredit";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
  GetManageCreditForBilling(inputData){
    var url = "api/Bill/GetManageCreditForBilling";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }

  // GetShift(payload) {
  //   var url = "api/Bill/GetShift";
  //   return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {
  //    "LocationId":1,"Status":"O",payload
  //     });
  // }
  GetShift(payload): Observable<Response<any>>{
    var url = "api/Bill/GetShift";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,payload);
  }
  CloseShift(payload): Observable<Response<any>>{
    const endPoint = 'api/Bill/CloseShift';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }
 
  InsertShift(payload): Observable<Response<any>>{
    const endPoint = 'api/bill/InsertShift';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  CloseCredit(inputData){
    var url = "api/Bill/CloseCredit";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }

  getCreditDetailsForBilling(payload): Observable<Response<any>>{
    const endPoint = 'api/Bill/GetCreditDetailsForBilling';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getUnbilledItesm(payload: any): Observable<Response<any>>{
    const endPoint = 'api/Bill/GetUnbilledItemsforBilling';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getBillingInstruction(payload: any): Observable<Response<any>>{
    const endPoint = `api/Bill/GetRecentBillingInstruction`;
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  insertUpdateCredit(payload: any): Observable<Response<any>>{
    const endPoint = `api/Bill/InsertCredit`;
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getPackageItems(payload): Observable<Response<any>>{
    const endPoint = `api/Bill/GetPackageItem`;
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getBillingList(inputData) {
    var url = "api/Bill/GetBillList";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }

  getConsultationBills(payload): Observable<Response<any>>{
    const endPoint = 'api/Bill/GetBillListByConsultation';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  getBillDetailsByTransId(payload): Observable<Response<any>>{
    var url = "api/Bill/GetBillListByTransId";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getExternalBillDetailsByTransId(payload): Observable<Response<any>>{
    var url = "api/Bill/GetExternalBillListByTransId";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetLedger(LedgerId, ShowAll, BranchId) {
    var url = "api/MasterData/GetLedgerHead";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, {
        HeadId: LedgerId,
        ShowAll: ShowAll,
        BranchId: BranchId,
      })
      
  }

  GetAllSponsorForm(Input) {
    var url = "api/Sponsor/GetSponsor";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input).pipe(
    );
  }

  cancelBill(payload): Observable<Response<any>>{
    var url = "api/Bill/CancelBill";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }


  cancelSettlment(payload): Observable<Response<any>>{
    var url = "api/Bill/CancelBillSettlement";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  printBillInvoice(payload): Observable<Response<any>>{
    var url = "api/Report/PrintBillInvoice";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  printBill(payload): Observable<Response<any>>{
    var url = "api/Report/PrintBill";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetReceiptList(Input) {
    var url = "api/Bill/GetReceiptList";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input).pipe(
    );
  }
  GetPaymentList(Input) {
    var url = "api/Bill/GetPaymentList";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input).pipe(
    );
  }
  // SavePaymentList(InputDetails) {
  //   var url = "api/Bill/InsertUpdatePayment";
  //   return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  // }
  SavePaymentList(Input) {
    var url = "api/Bill/InsertUpdatePayment";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input).pipe(
    );
  }
  cancelPayment(payload): Observable<Response<any>>{
    var url = "api/Bill/CancelPayment";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetCardTypes(BranchId) {
    var url = "api/MasterData/GetCardType";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "CardId": 0,
        "ShowAll": 1,
        "BranchId": BranchId
      }

    )
  }
  GetCreditBalance(Input) {
    var url = "api/bill/GetCreditforPatient";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input).pipe(
    );
  }
  getCreditBalance(Input) {
    var url = "api/Bill/GetCreditforPatient";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input).pipe(
    );
  }
  GetBillBalance(Input) {
    var url = "api/bill/GetBillsWithDueAmt";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input).pipe(
    );
  }
  saveReceiptLists(Input) {
    var url = "api/bill/InsertUpdateReceipt";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input).pipe(
    );
  }

  osBalance(Input) {
    var url = "api/bill/GetOutstandingBalance";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input).pipe(
    );
  }

  getBillDataForSettlment(payload: any): Observable<any>{
    var url = "api/Bill/GetTransaction";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }

  getExternalBillDataForSettlment(payload: any): Observable<any>{
    var url = "api/Bill/GetExternalTransaction";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }

  saveSettlment(payload: any): Observable<any>{
    var url = "api/Bill/InsertTransactionPayment";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }

  saveExternalSettlment(payload: any): Observable<any>{
    var url = "api/Bill/InsertExternalTransactionPayment";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }

  getLedgerList(payload: any): Observable<any>{
    var url = "api/MasterData/GetLedgerHead";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }

  getPatientCreditList(payload: any): Observable<any>{
    var url = "api/Bill/GetCreditforPatient";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }

  getAdvanceBalance(payload: any): Observable<any>{
    var url = "api/Bill/GetAdvanceBalance";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }
  cancelItem(Input) {
    var url = "api/Bill/CancelReceipt";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,Input);
  }

  getAllLocations(payload: any): Observable<Response<any>>{
    var url = "api/MasterData/GetLocation";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }

  getUnbilledItemsList(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetUnbilledItems";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }

  GetServicesOrderLoad(payload: any) {
    var url = "api/ServiceOrder/GetUnbilledServicesOrder";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }

  GetAllServicesOrdersById(orderId: Number) {

    var url = "api/ServiceOrder/GetUnbilledServicesOrderDetailById/" + orderId;
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,{});

  }
  SearchPatientByRegNo(regNo: string) {
    // api/TodaysPatient/GetPatientByRegNo
    var url = "api/TodaysPatient/GetPatientByRegNo";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "RegNo": regNo
      }).pipe(
      );
  }

  getCardTypesForSettlment(payload): Observable<Response<any>>{
    var url = "api/MasterData/GetCardType";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,{});
  }


  getExternalLabs(payload): Observable<Response<any>>{
    var url = "api/Bill/GetExternalLab";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,payload);
  }

  GetProfile(payload: any) {
    var url = "api/MasterData/GetProfile";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload)
  }

  CancelUnbilledOrder(payload: any): Observable<Response<any[]>> {
    var url = "api/ServiceOrder/CancelServiceOrder";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, payload);
  }
  GetDepartments(branchId, showall) {
    var url = "api/MasterData/GetDepartment";
    // return this.http.post<Response<Departments[]>>(`${this.baseUrl}${url}`, null)
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "DepartmentId": 0,
        "ShowAll": showall,
        "BranchId": branchId
      }).pipe(
      );
  }
  GetConsultant(department: Number) {
    var url = "api/TodaysPatient/GetConsultant";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {
        "DeptId": Number(department?department:0),
        "ShowExternal": false
      }).pipe(
      );
  }

  GetPaymentRef(payload) {
    var url = "api/Claim/GetPaymentReference";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload).pipe(
    );
  }

  getOutstandingBalanceOfPatient(payload: any): Observable<Response<any>> {
    var url = "api/Bill/GetOutstandingBalance";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload);
  }

  getCreditTransactions(payload: any): Observable<Response<any>>{
    var url = "api/Bill/GetTransactionSummary";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload); 
  }

  printUpfrontInvoice(payload: any): Observable<Response<any>>{
    var url = "api/Report/PrintUpfront";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload); 
  }

  saveConsultationCredits(payload: any): Observable<Response<any>>{
    var url = "api/Bill/SaveConsultationCredits";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload); 
  }

  getBillingSettings(payload: any): Observable<any>{
    var url="api/Bill/GetSettingsforBilling";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,payload);
  }

  ApproveCredit(inputData){
    var url = "api/Bill/ApproveCredit";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, inputData)
  }
}
