import { Component, Input, OnInit } from '@angular/core';
import { OphthalmologyService } from '../../services/ophthalmology.service';
import { Response } from '../../../../_Models/Response';
import { globalvars } from '../../../../shared/models/GlobalVars';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EMRPatientInfo } from '../../interfaces/EMRPatientInfo';
import { EMRService } from '../../services/emr.service';
import { ToastrService } from 'ngx-toastr';
import _ from 'lodash';

@Component({
  selector: 'app-adnexa',
  templateUrl: './adnexa.component.html',
  styleUrls: ['./adnexa.component.scss']
})
export class AdnexaComponent implements OnInit {

  @Input() globals: globalvars;

  public lymphNodesDropdown: any[] = [];
  public adnexaForm: FormGroup;
  public EMRpatientInfo: EMRPatientInfo;

  private FormValueChangeChecker: boolean = false;

  constructor(private ophthalmologyService: OphthalmologyService,
              private EmrService: EMRService,
              private toastrService: ToastrService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.EMRpatientInfo = this.EmrService.GetEMRPatientInfo();
    this.initForm();
    this.getPreauricularLymphNodesDropdown();

    this.getAdnexaDetails();
  }

  getPreauricularLymphNodesDropdown(){
    let payload = {
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getGetGetPreauricularLymphnodesDropdown(payload).subscribe({
      next: (response: Response<any>)=>{
        this.lymphNodesDropdown = response.response;
      }
    })
  }

  initForm(){
    this.adnexaForm = this.formBuilder.group({
      AId: new FormControl(0),
      leftLymphNode: new FormControl(''),
      rightLymphNode: new FormControl(''),
      leftLacimal: new FormControl(''),
      rightLacimal: new FormControl(''),
      leftEyelids: new FormControl(''),
      rightEyelids: new FormControl('')
    })
  }

  getAdnexaDetails(){
    let payload = {
      PatientId: this.EMRpatientInfo.PatientId,
      ConsultationId: this.EMRpatientInfo.ConsultationId,
      BranchId: this.globals.HospitalId
    }

    this.ophthalmologyService.getAdnexaDetails(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          if(response.response[0]){
            let data = response.response[0];
            let rightEye = _.find(data?.adnexaDetailList, {eye: 'R'});
            let leftEye = _.find(data?.adnexaDetailList, {eye: 'L'});
            this.adnexaForm.patchValue({
              AId: data?.aId,
              rightLymphNode: rightEye?.panId != 0 ? rightEye?.panId : '',
              leftLymphNode: leftEye?.panId != 0 ? leftEye?.panId : '',
              rightLacimal: rightEye?.lacimal,
              leftLacimal: leftEye?.lacimal,
              rightEyelids: rightEye?.eyeLids,
              leftEyelids: leftEye?.eyeLids
            });
          }
          this.adnexaForm.valueChanges.subscribe(x => {
            this.FormValueChangeChecker = true;
          })
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

  saveAdnexaDetails(){
    if(this.EMRpatientInfo.CrudType == 3 &&
      this.EMRpatientInfo.VisitId == 0 && !this.FormValueChangeChecker){
      return
    }

    let payload = {
      AId: Number(this.adnexaForm.get('AId').value),
      PatientId: this.EMRpatientInfo.PatientId,
      ConsultationId: this.EMRpatientInfo.ConsultationId,
      VisitId: this.EMRpatientInfo.VisitId,
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals.Location.locationId,
      BranchId: this.globals.HospitalId,
      AdnexaDetailList: [
        {
          Eye: "R",
          PANId: Number(this.adnexaForm.get('rightLymphNode').value),
          Lacimal: this.adnexaForm.get('rightLacimal').value,
          EyeLids: this.adnexaForm.get('rightEyelids').value,
          ADId: 0
        },
        {
          Eye: "L",
          PANId: Number(this.adnexaForm.get('leftLymphNode').value),
          Lacimal: this.adnexaForm.get('leftLacimal').value,
          EyeLids: this.adnexaForm.get('leftEyelids').value,
          ADId: 0
        }
      ]
    }

    this.ophthalmologyService.saveAdnexaDetails(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200 && response.message == 'Success'){
          this.adnexaForm.patchValue({
            AId: response.response[0].aId
          });
          this.FormValueChangeChecker = false;
          this.toastrService.success('Adnexa Details Saved Successfully');
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong..! Try again');
      }
    })
  }

}
