import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserpermissionService } from '../../user-permission/userpermission.service';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../services/dashboard.service';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  userId: any;
  isLoading = false;
  resetPasswordForm: FormGroup;
  public submitted: boolean = false;
  result: any;
  public strongPassword: boolean = false;
  matchFlag = true;
  subscription: Subscription;
  globals: globalvars = new globalvars();
  branchId: any;

  constructor(
    private modalRef: BsModalRef,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private sharedservice: SharedService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.branchId = this.globals.HospitalId
      }
    });
    this.userId = Number(localStorage.getItem('user_id'));
   }

  ngOnInit(): void {
    this.resetPasswordForm = new FormGroup({
      BranchId: new FormControl(this.branchId),
      UserId: new FormControl(this.userId),
      CurrentPassword: new FormControl('', [Validators.required]),
      NewPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          "(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}"
        ),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    });
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  save() {
    if (this.submitted = true) {
      var formdata = this.resetPasswordForm.getRawValue();
       if (formdata.CurrentPassword !== "" && formdata.confirmPassword == formdata.NewPassword && formdata.confirmPassword !== "") {
        this.isLoading = true;
        this.dashboardService.UpdateUserPassword(formdata).subscribe((res) => {
          this.submitted = false;
          this.result = res;
          if (this.result.status == 200 && this.result.message == 'Success') {
            this.isLoading = false;
            this.toastr.success("Password Upadate Successfully");
            this.close();
          }
          else {
            this.isLoading = false;
            this.toastr.error(this.result.message);
          }
        })
      }
    }
  }

  close() {
    this.modalRef.hide();
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }

  matchPassword(event){
    if(this.resetPasswordForm.controls.NewPassword.value !== event.target.value){
      this.matchFlag = false;
    } else{
      this.matchFlag = true;
    }
  }

}
