import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { Location, LocationTypes } from '../../client/interfaces/Location';
import { Response } from '../../../_Models/Response';
import { ErrorHandleService } from '../../client/services/error-handle.service';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class LocationService {
  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle:ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
   }
   
  GetLocationsType() {
    var url="api/Location/GetLocationTypes";
    return this.http.post<Response<LocationTypes[]>>(`${this.baseUrl}${url}`,null);
  }
  GetLocation(id: any) {
    var url="api/Location/GetLocationById";
    return this.http.post<Response<Location>>(`${this.baseUrl}${url}`,id);
  
  }
  GetLocations(branchId,Id) {
    var url="api/MasterData/GetLocation";
    return this.http.post<Response<Location[]>>(`${this.baseUrl}${url}`,
    
      {
        "LocationId":Id,
        "ShowAll":1,
        "HospitalId":branchId
    
   }).pipe(
       retry(1),
       catchError(this.errorHandle.handleError)
     );
  }



  Save(data:Location)
  {
    var url="api/MasterData/InsertUpdateLocation";
    return this.http.post<Response<Location[]>>(`${this.baseUrl}${url}`,data);

  }


  SaveAssociateLocations(LocationId,AssociateId) {
    var url="api/MasterData/InsertAssociateLocation";
    return this.http.post<Response<Location[]>>(`${this.baseUrl}${url}`,
    
    {
      "LocationId": LocationId,
      "AssociateLcations": AssociateId
  })
  }

  GetAssociativeLocationById(data){
    var url="api/MasterData/GetAssociativeLocationById";
    return this.http.post<Response<Location[]>>(`${this.baseUrl}${url}`,data);

  }

}
