export class ReportRequest{
  PID:number;
  ReportType:number;
  ReportInput:any;
}


// Report Type Details
/**
 * 1 : Medication Print
 *
 *
 *
 *
 */
