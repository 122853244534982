export class Location{

    locationName:string;
    locationId: number;
    supervisor:string;
    contactNumber:string;
    lTypeId: number;
    lType: string;
    manageSPoints:boolean;
    manageBilling :boolean;     
    manageCash: boolean;
    manageCredit:boolean;
    manageIPCredit:boolean;
    active: boolean;
    blockReason  :string;
    repHeadImg:string;
    hospitalId:number;     
  id: number;
  text: string;
}
export interface LocationTypes{
    lTypeId:number;
    lTypeName:string;
}