import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Response } from '../../../_Models/Response';
import { UserHospitals } from '../interfaces/UserHospitals';
import { Departments } from '../interfaces/Departments';
import { Consultants } from '../interfaces/Consultants';
import { catchError, retry } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandleService } from './error-handle.service';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { DatePipe } from "@angular/common";
import { ServicesGroups } from '../interfaces/ServicesGroups';
@Injectable({
  providedIn: 'root'
})
export class ServiceOrderingService {

  private baseUrl = "";
  errorMessage: any;

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private toastr: ToastrService,
    private errorHandle: ErrorHandleService,
    private datePipe: DatePipe,
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;

  }


  GetConsultantByHospital(BranchesId, consultantTypeId) {
    var url = "api/MasterData/GetConsultantByHospital/";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "BranchId": BranchesId,
        "IsExternal": Number(consultantTypeId)
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }


  GetAllPckages(BranchesId) {
    var url = "api/MasterData/GetPackage/";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "BranchId": BranchesId,
        "PackId": 0,
        "showAll":0
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }


  GetAllAvailableServices(branchId) {
    var url = "api/ServiceOrder/GetAvailableService";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "PatientId": 0,
        "GroupIdList": [0],
        "BranchId": Number(branchId)

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  SaveServiceOrdering(form, selectedproducts, PatId, consultaionId, orderDate) {
    console.log(form.value);
    var url = "api/ServiceOrder/InsertServiceNew";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        // "OrderId":0,
        "OrderNo": "",
        "OrderDate": orderDate,
        "BranchId": Number(form.value.hospitalId),
        "PatientId": PatId,
        "ConsultantId": Number(form.value.consultant),
        "ConsultationId": Number(consultaionId) ? Number(consultaionId) : 0,
        "PackId": 0,
        "PackNo": "",
        "UserId": Number(localStorage.getItem('user_id')),
        "SessionId": 0,
        "SerialNo": 0,
        "LocationId": form.value.locationId ? Number(form.value.locationId) : 0,
        "Status": "N",
        "PayStatus": 0,
        "ItemObj": selectedproducts


      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetLastConsultantDate(consutantId, PatId) {
    var url = "api/ServiceOrder/GetLastConsultation";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": consutantId,
        "PatientId": PatId

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetPackageItem(PackageId: Number) {
    var url = "api/ServiceOrder/GetPackageItem/" + PackageId;
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {


      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  GetProfile(BranchId) {
    var url = "api/MasterData/GetProfile";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,

      {
        "ProfileId": 0,
        "ShowAll": 0,
        "BranchId": BranchId
      })
  }

  GetProfileSubItem(profileId) {
    var url = "api/ServiceOrder/GetProfileItem";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,

      {
        "ProfileIds": profileId


      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
  getServiceGroupItems(Input) {
    var url = "api/ServiceOrder/GetServicesGroups/";
    return this.http.post<Response<ServicesGroups[]>>(`${this.baseUrl}${url}`,Input
      ).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }
  GetServicesOrderLoad(HospitalId, FromDate, ToDate) {
    var url = "api/ServiceOrder/GetServicesOrderLoad";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "BranchId": HospitalId,
        "OrderFromDate": FromDate,
        "OrderToDate": ToDate
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetAvailableService(branchId, patId, groupId) {
    var url = "api/ServiceOrder/GetAvailableService";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "PatientId": patId?patId:0,
        "GroupIdList": groupId,
        "BranchId": branchId

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }

  GetAvailableServiceForLocation(branchId, patId, groupId,locationId) {
    var url = "api/ServiceOrder/GetAvailableServiceByLocation";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "PatientId": patId?patId:0,
        "GroupId": groupId[0],
        "BranchId": branchId,
        "locationId":locationId

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }

  GetAllServicesOrders(event,patientId, HospitalId, DateServiceFrom, DateServiceTo) {
    var url = "api/ServiceOrder/GetServicesOrderByDate";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {

        "OrderFromDate": DateServiceFrom ? DateServiceFrom : "",
        "OrderToDate": DateServiceTo ? DateServiceTo : "",
        "PatientId": patientId,
        "OrderNo": event.value.orderNo,
        // "PayStatus": "3",
        "BranchId": HospitalId,
        "RegNo": event.value.RegtrnNo,
        "PatientName": event.value.patientname,
        "ConsultantId": Number(event.value.serviceconsultants),
        "isExternalConsultant": Number(event.value.consultanttype),


      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }

  GetPendingOrders(patientId, HospitalId) {

    var url = "api/ServiceOrder/GetServicesOrderByDate";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {

        "OrderFromDate": "",
        "OrderToDate": "",
        "PatientId": patientId,
        "OrderNo": "",
        //"PayStatus": "0",
        "BranchId": HospitalId,
        "RegNo": "",
        "PatientName": ""


      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }

  CancelServiceOrder(orderId, Reason, ItemIds) {

    var url = "api/ServiceOrder/CancelServiceOrder";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {


        "CancelReason": Reason,
        // "SubType":subprofile,
        "OrderId": orderId,
        "ItemId": ItemIds ? ItemIds : 0,
        "UserId": Number(localStorage.getItem('user_id')),
        "SessionId": 0,


      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );



  }

  GetAllServicesOrdersById(orderId: Number) {

    var url = "api/ServiceOrder/GetServicesOrderDetailById/" + orderId;
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {

      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }

  GetServicesOrderDetails(orderId: Number,LocationId:Number) {
    var url = "api/ServiceOrder/GetServicesOrderDetails";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        OrderId:orderId,
        LocationId:LocationId
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );

  }



  GetServicesOrderLoadByConsultantId(HospitalId, FromDate, ToDate, consultantId) {
    var url = "api/Consultant/GetServicesOrderLoadByConsultantId";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultantId": Number(consultantId),
        "BranchId": HospitalId,
        "OrderFromDate": FromDate,
        "OrderToDate": ToDate
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetPendingOrdersDetails(event, patientId, BranchId, valueDateFrom, valueDateTo) {
    if (event) {
      var url = "api/MasterData/GetPendingServiceItemsByPatient";
      return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
        {

          "OrderFromDate": valueDateFrom,
          "OrderToDate": valueDateTo,
          "PatientId": patientId,
          "OrderNo": event.value.orderNo,
          "BranchId": BranchId,
          "RegNo": event.value.RegtrnNo,
          "PatientName": event.value.patientname,
          "consultantId":Number (event.value.serviceconsultants),
          "isExternalConsultant": 0


        }).pipe(
          retry(1),
          catchError(this.errorHandle.handleError)
        );
    }
    else {

      var url = "api/MasterData/GetPendingServiceItemsByPatient";
      return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
        {

          "OrderFromDate": valueDateFrom,
          "OrderToDate": valueDateTo,
          "PatientId": patientId,
          "OrderNo": "",
          "BranchId": BranchId,
          "RegNo": "",
          "PatientName": "",
          "consultantId": 0,
          "isExternalConsultant": 0


        }).pipe(
          retry(1),
          catchError(this.errorHandle.handleError)
        );
    }
  }


  GetReportsByServiceItem(payload: any): Observable<Response<any>> {
    var url = "api/ServiceOrder/GetServiceOrderReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getAllStaffs(payload: any): Observable<Response<any>>{
    var url = "api/MasterData/GetStaff";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  // getTemplatesOServiceItem(payload): Observable<Response<any>>{
  //   const endPoint = 'api/ServiceOrder/GetItemTemplates';
  //   return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  // }
  getTemplatesOServiceItem(payload: any): Observable<Response<any>>{
    const endPoint = 'api/MasterData/GetTemplate';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  saveReportDetails(payload: any): Observable<Response<any>>{
    const endPoint = 'api/ServiceOrder/InsertUpdateServiceOrderReport';
    return this.http.post<Response<any>>(`${this.baseUrl}${endPoint}`, payload);
  }

  GetServiceItem(payload: any) {
    var url = "api/MasterData/GetServiceItem/";
    return this.http
      .post<Response<any>>(`${this.baseUrl}${url}`, payload)
      .pipe(retry(1), catchError(this.errorHandle.handleError));
  }


  GetServicesOrderByConsultantId(HospitalId, ConsultationId) {
    var url = "api/ServiceOrder/GetServiceOrderLoadByConsultationId";
    return this.http.post<Response<Consultants[]>>(`${this.baseUrl}${url}`,
      {
        "ConsultationId": Number(ConsultationId),
        "BranchId": HospitalId,
       
      }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }
}

