import { Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import { ColDef, GridOptions } from "ag-grid-community";
import { globalvars } from "../../../shared/models/GlobalVars";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { DatePipe, Location } from "@angular/common";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { EMRService } from "../services/emr.service";
import { EMRPatientInfo } from "../interfaces/EMRPatientInfo";
import { Response } from "../../../_Models/Response";
import { EmrPreference } from "../models/Preference.EMR";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { CopyEmrModalComponent } from "../modals/copy-emr-modal/copy-emr-modal.component";
import { ConfirmModalComponent } from "../../../shared/modals/confirm-modal/confirm-modal.component";
import _ from "lodash";
import { SettingsService } from "../../settings/services/settings.service";
import { Settings } from "../../settings/model/settings.model";
import { ConfigurationService } from '../../client/services/configuration.service';
import { PatientService } from "../../client/services/patient.service";

@Component({
  selector: "app-emr-consultation",
  templateUrl: "./emr-consultation.component.html",
  styleUrls: ["./emr-consultation.component.scss"],
})
export class EMRConsultationComponent implements OnInit {
  dentalDisabled:boolean=false
  products: any = {};

  General: boolean;
  Ortho: boolean = true;
  Dental: boolean;
  // displayModal: boolean;
  // displayModal1: boolean;
  rowData: any = [];
  subscription: Subscription;
  globals: globalvars = new globalvars();
  result: any; //check status code
  columnDefs: ColDef[] = [];
  users: any = [];
  form: FormGroup; // form initialization for Zone
  formBranch: FormGroup; // form initialization for Branches

  BranchesId: any;

  Selectedtab: string;
  IsViewed: any;

  public viewSwitch: boolean = true;
  public emrPreferenceLoading: boolean = false;
  public EmrPreference: EmrPreference;
  public EMRPatientInfo: EMRPatientInfo;

  public printLoading: boolean = false;
  @ViewChild("printContent") content: ElementRef;
  emrConfig: Settings[]=[];
  billEdit:boolean=false
  HealthInformationPlatform: any;
  functionPermissionData: any;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sharedservice: SharedService,
    private router: Router,
    private emrService: EMRService,
    private modalService: BsModalService,
    private _location: Location,
    private datepipe: DatePipe,
    private EMRService: EMRService ,// private configList:ConfigurationList,
    private settingsService:SettingsService,
    private route:ActivatedRoute,
    private ConfigurationService: ConfigurationService,
    private patientService:PatientService,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    if (this.router.getCurrentNavigation().extras.state) {
      this.IsViewed = this.router.getCurrentNavigation().extras.state.id;
      //this.patientId=this.router.getCurrentNavigation().extras.state.id;
    }
  }

  ngOnInit(): void {
    
    this.route.queryParamMap.subscribe((params) => {
      this.billEdit= params.has('isBilledit') ? params.get('isBilledit') == 'true' : false;
      console.log(this.billEdit)

  });
    if(sessionStorage.getItem('tabName')){
      if(sessionStorage.getItem('tabName') == 'Followup'){
      this.Scrolltotab("Followup");
      sessionStorage.removeItem('tabName');
      }
    }else{
    this.Scrolltotab("General");
    }
    this.EMRPatientInfo = this.emrService.GetEMRPatientInfo();
    this.getEMRPreference();
    this.getAppSettings()
    this.functionPermission()
  }

 

  Scrolltotab(elem) {
    this.Selectedtab = elem;
  }

  goback() {
    // console.log('this.EMRPatientInfo.CrudType', this.EMRPatientInfo.CrudType);
    let content = "";
    if (this.EMRPatientInfo.CrudType == 1) {
      content = "<p>Do you want to save and finish this consultation..?</p>";
    } else if (this.EMRPatientInfo.CrudType == 2) {
      content =
        "<p>Do you want to save and finish updating this consultation..?</p>";
    } else if (this.EMRPatientInfo.CrudType == 3) {
      content = "<p>Do you want to save and end view this consultation..?</p>";
    } else {
      content = "";
    }

    // if(this.EMRPatientInfo.CrudType !== 3){
    if(this.EMRPatientInfo.CrudType == 1 || this.EMRPatientInfo.CrudType == 2){
    let initialState = {
      heading: "Confirm",
      content: content,
      yesButton: 'Save & Finish,Send data to '+this.HealthInformationPlatform.settValue,
      noButton: "Save and Go back",
    };
    let modalConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-scrollable",
    };
    // debugger
    const modalRef = this.modalService.show(
      ConfirmModalComponent,
      Object.assign({ class: "modal-dialog-centered " }, modalConfig, {
        class: "modal-md",
        initialState,
      })
    );
    modalRef.content.confirmEmitter.subscribe((res) => {
      if (res.status) {
        this.EndConsultation();
      } else {
        this._location.back();
      }
    });
  } else{
    this.EndConsultation();
  }
  }

  //end consultation insertion
  EndConsultation() {
    let conultationId = this.EMRPatientInfo.ConsultationId;
    if (conultationId > 0) {
      this.EMRPatientInfo.VisitStartTime = "";
      this.EMRPatientInfo.ConsultationId = Number(conultationId);
      this.EMRPatientInfo.VisitType = 1;
      this.EMRPatientInfo.CrudType = 1;
      let lastConsultationId = this.EMRPatientInfo.lastConsultationId
      let ConsultationId = this.EMRPatientInfo.ConsultationId
      let CDate = new Date();
      // console.log(this.EMRPatientInfo)
      let Endtime = this.datepipe.transform(CDate, "dd-MM-yyyy hh:mm:ss a");
      this.EMRPatientInfo.VisitEndTime = Endtime;

      this.EMRService.InsertVisit(this.EMRPatientInfo).subscribe((res) => {
        if (res.status == 200 && res.message == "Success") {
          const EMRPatientInfo=this.EMRPatientInfo
          setTimeout(() => {
            this.sendDataToNabid(EMRPatientInfo)
          }, 2000);
          // let lastConsultationId =this.EMRPatientInfo.lastConsultationId;
          let result = res.response;
          result = this.EMRService.upper(res.response);
          this.EMRPatientInfo = result;
          // console.log(result)
          if(ConsultationId !== lastConsultationId){
            this.EMRPatientInfo.VisitEndTime = '';
            this.EMRPatientInfo.lastConsultationId = lastConsultationId;
          }
          // console.log(this.EMRPatientInfo)
          // if(this.EMRPatientInfo.ConsultationId !== lastConsultationId) {
          //   this.EMRPatientInfo.VisitEndTime = "";
          //   }
          this.EMRPatientInfo.ConsultationId = 0;
          this.EMRPatientInfo.VisitType = 0;
          this.EMRPatientInfo.VisitId = 0;
          this.EMRPatientInfo.CrudType = 0;
          this.EMRService.SetEMRPatientInfo(this.EMRPatientInfo);
          this._location.back();
          if(this.billEdit){
            setTimeout(() => {
               window.close()
            }, 1000);
          }
        } else {
          this.toastr.error("Something wents wrong..! Try again");
        }
      });
    } else {
      this.toastr.warning("Error: " + "Please select a consulation to start");
    }
  }
  // end of  insert end consulation

  printEMR() {
    this.printLoading = true;
    html2canvas(this.content.nativeElement, {
      scale: 3,
    })
      .then((canvas) => {
        let image = canvas.toDataURL();

        // let a = document.createElement('a');
        // a.href = image;
        // a.download = 'Image';
        // a.click();
        // a.remove();

        let doc = new jsPDF({
          compress: false,
          format: "a4",
        });
        var width = doc.internal.pageSize.getWidth();
        doc.internal.scaleFactor = 2;
        doc.addImage(image, "image/png", 0, 0, width, 0);
        doc.save("EMR.pdf");
        this.printLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.printLoading = false;
      });
  }

  getEMRPreference() {
    
    let payload = {
      ConsultantId: this.EMRPatientInfo.ConsultantId,
      BranchId: this.globals.HospitalId,
    };
    this.emrPreferenceLoading = true;
    this.emrService.getCFormOrder(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200) {
          this.EmrPreference = {
            singlePageView: response.response[0].emrPageView,
            tabOrders: response.response,
          };
        } else {
          this.toastr.error(response.errorMessage.message);
        }
        this.emrPreferenceLoading = false;
      },
      error: (error: any) => {
        this.emrPreferenceLoading = false;
      },
    });
  }

  copyEmrModal() {
    
    let initialState = {
      EmrPatientInfo: this.EMRPatientInfo,
      branchId: this.globals.HospitalId,
    };
    let modalConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-scrollable",
    };
    const copyModalRef = this.modalService.show(
      CopyEmrModalComponent,
      Object.assign({ class: "modal-dialog-centered " }, modalConfig, {
        class: "modal-md",
        initialState,
      })
    );
    copyModalRef.content.copyEmitter.subscribe((res) => {
      if (res.status) {
        this.getEMRPreference();
      }
    });
  }

  getAppSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }
  
    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          let val = response.response
          // debugger
          this.emrConfig = _.filter(response.response, {tabId: 7});
           this.HealthInformationPlatform=_.find(response.response,{displayName:"HealthInformationPlatform"})
          const isSettingWithValueZeroPresent = this.emrConfig.some(setting => {
            // Replace 'dynamicSettId' with the actual dynamic settId value
            return setting.settId == 53 && setting.settValue == '0' && this.billEdit == true;
            
          });
          
          if (isSettingWithValueZeroPresent) {
            // Your logic when a setting with value 0 is present and billEdit is true
          
            this.dentalDisabled=true
          }
      }
    }
    })
  }

  emrEdit(){
    let returnValue:boolean
    if(this.billEdit){

    }else{
      returnValue=true
    }
    return returnValue
  }

  functionPermission() {
    let input = {
      // MenuId: Number(localStorage.getItem('selectedMenu')),
      MenuId: 140,
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      // console.log('functionPermissionData', this.functionPermissionData);
    });
  }

  sendDataToNabid(EMRPatientInfo) {
    const consultationId = EMRPatientInfo.ConsultationId;
    const patientId = EMRPatientInfo.PatientId;
    const currentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
  
    const allergyPermission = this.functionPermissionData.find(x => x.id === 564);
    const vitalPermission = this.functionPermissionData.find(x => x.id === 561);
    const chronicIcdPermission = this.functionPermissionData.find(x => x.id === 569);
  
    // Check if the platform is 'Nabidh' and permissions are allowed
    if (this.HealthInformationPlatform.settValue === "Nabidh") {
  
      // Send allergy data if permission is allowed and there are allergies
      if (allergyPermission?.isAllowed ) {

        let input = { "PatientId": EMRPatientInfo.PatientId };
        this.EMRService.GetAllergy(input).subscribe(
          (res) => {
            if (res.status == 200 && res.response.length > 0) {
              const nabPayload = {
                EventId: 6,
                Consultationid: consultationId,
                EntryDate: currentDate,
                PatientId: patientId,
                BranchId : this.globals.HospitalId
              };
              this.sendToNabidh(nabPayload); 
            }
          });
      }else{
        this.toastr.warning('No permission to send allergy data to nabith');
      }
  
      // Fetch and send vital sign data if permission is allowed
      if (vitalPermission?.isAllowed) {
        const input = { VisitId: EMRPatientInfo.VisitId, PatientId: patientId };
        this.EMRService.GetAllEMRVitalSignByVisitId(input).subscribe(res => {
          if (res.status === 200 && res.response?.length > 0) {
            const nabPayload = {
              EventId: 3,
              Consultationid: consultationId,
              EntryDate: currentDate,
              PatientId: patientId,
              BranchId : this.globals.HospitalId
            };
            this.sendToNabidh(nabPayload);
          }
        });
      }else{
        this.toastr.warning('No permission to send vital data to nabith');
      }

      // send icd data if there is any chronic icd and permission is allowed
      if (chronicIcdPermission?.isAllowed) {
        let inputPayload = {
          VisitId: EMRPatientInfo.VisitId,
          PatientId: EMRPatientInfo.PatientId,
          ShowAll: 1,
          ConsultationId: EMRPatientInfo.ConsultationId,
        };

        this.EMRService.GetNarrativeDiagonosis(inputPayload).subscribe((res) => {
          if (res.status == 200) {
            let response = res.response[0];
            if (res.status === 200 && res.response?.length > 0) {
              if (response?.icdLabelList) {
                let chronicIcd = response.icdLabelList.filter(item => (item.isChronic == true))
                let chronicLen = chronicIcd?.length ? chronicIcd?.length : 0
                if(chronicLen !== 0){
                  const nabPayload = {
                    EventId: 14,
                    Consultationid: consultationId,
                    EntryDate: currentDate,
                    PatientId: patientId,
                    BranchId : this.globals.HospitalId
                  };
                  this.sendToNabidh(nabPayload);
                }
              }
            }
          }
        });
      }else{
        this.toastr.warning('No permission to send icd data to nabith');
      }
    }
  }
  
  private sendToNabidh(payload: any) {
    this.patientService.SendDetailstoNabith(payload).subscribe({
      next: (res: any) => {
        this.toastr.info('Response from Nabidh: ' + res);
      }
    });
  }
}
