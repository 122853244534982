import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/internal/Subscription';
import { GeneralGridActionComponent } from '../../../shared/general-grid-action/general-grid-action.component';
import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { Submenu } from '../../client/interfaces/SubMenu';
import { UserpermissionService } from '../userpermission.service';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent implements OnInit {

  modalRef?: BsModalRef;

  submenu: Submenu;
  columnDefs = [];
  private gridOptions: GridOptions;
  public gridApi;
  private gridColumnApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents;
  public form: FormGroup;
  displayModal: boolean;
  displayModal1: boolean;
  supervisor = "";
  locationname = "";
  Mobilenumber = "";
  globals: globalvars = new globalvars();
  products: any = {};
  rowData: any = [];
  subscription: Subscription;
  selectedCities: any[] = [];
  statusBar: any;
  @ViewChild('template') modaltemp:any
  constructor(
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private UserService:UserpermissionService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
      
      }
    });
    this.form = new FormGroup({
      subMenu: new FormControl(""),
      sId: new FormControl(0),
      subMenuAlias: new FormControl(""),
      subMenuLink: new FormControl(""),
      subMenuIcon: new FormControl('')
    });
    this.columnDefs = [
      {
        headerName: "Sub Menu",
        field: "subMenu",
        resizable: true,
        unSortIcon: true,
        flex: 3,
        sort: "asc",
      },
      {
        headerName: "Alias",
        field: "subMenuAlias",
        enableRowGroup: true,
        resizable: true,

        flex: 2,
      },
      {
        headerName: "Action",
        field: "sId",
        enableRowGroup: true,
        resizable: true,

        cellRenderer: "actionsComponentRenderer",
        cellRendererParams: {
            Page:'SubMenu'
         },
        flex: 2,
      },

      // ik
    ];
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    // this.valueDate =  this.datepipe.transform(new Date(),'yyyy-MM-dd');
    // let dte = new Date();
    // dte.setDate(dte.getDate() - 28);

    // this.valueDate2=this.datepipe.transform(dte.toString(),'yyyy-MM-dd');

    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };

    this.frameworkComponents = {
      actionsComponentRenderer: GeneralGridActionComponent,
    };
    this.context = { componentParent: this };
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  Save() {
    this.submenu = this.form.getRawValue();
    this.UserService.SaveSubMenu(this.submenu).subscribe((res) => {
      this.displayModal = false;
      this.form.reset();
      alert(res.response);
    });
  }
  GetSubmenu(id) {
    this.UserService.GetSubmenu(id).subscribe((res) => {

      this.submenu = res.response;
      this.form.patchValue(this.submenu);
      this.modalRef = this.modalService.show(this.modaltemp, Object.assign({}, { class: 'gray modal-lg' })  );
 
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  GetSubmenus() {
    //alert(JSON.stringify(this.globals));
    this.UserService
      .GetSubmenus()
      .subscribe((res) => {
        this.rowData = res.response;
      });
  }
 
  ngOnInit(): void {
      this.GetSubmenus();
    
  }
  showModalDialog() {
    this.displayModal = true;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'gray modal-lg' })  );
  }

}
