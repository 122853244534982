import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-list-tooth-modal',
  templateUrl: './list-tooth-modal.component.html',
  styleUrls: ['./list-tooth-modal.component.scss']
})
export class ListToothModalComponent implements OnInit {

  @Output() nextEvent = new EventEmitter();

  public selectedTeeths: any[] = [];

  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  close(){
    this.modalRef.hide();
  }

  onSelectTeeth(toothNumber: any){
    let teeth = {
      toothNumber: toothNumber,
      markings: []
    }
    this.selectedTeeths[0] = teeth;
    
  }

  onNextClicked(){
    this.close();
    this.nextEvent.emit({
      status: true,
      selectedTeeths: this.selectedTeeths
    });
  }

  isSelectedTeeth(teethNumber: number): boolean{
    let index = _.findIndex(this.selectedTeeths, {toothNumber: teethNumber});
    return ( index >=0 ? true : false);
  }

}
