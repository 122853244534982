import { Component, OnInit ,ViewChild,TemplateRef} from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { BillingGeneralActionComponent } from '../billing-general-action/billing-general-action.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-claim',
  templateUrl: './manage-claim.component.html',
  styleUrls: ['./manage-claim.component.scss']
})
export class ManageClaimComponent implements OnInit {
  // modalRefonline: BsModalRef;
  @ViewChild("onlineSubmissionTemplate") modaltransaction: any;
  // GRID ITEMS
  modalRef?: BsModalRef;
  @ViewChild("cancelTemplate") modalCancel: any;
  @ViewChild("resubmission") modalResubmmision: any;
  @ViewChild("claimreceptTemplate") modalclaimrecept: any;

  public statusBar;
  rowGroupPanelShow = "always";
  uploadservicestab: boolean = true;
  downloadservicestab: boolean = true;
  public gridApi;
  columnDefs: ColDef[] = [];
  columnClaim: ColDef[] = [];
  columnDefsList: ColDef[] = [];
  resubmissionList: any[] = [];
  columnDefsDetails: ColDef[] = [];
  resubmissionData: any[] = [];

  claimDetails=[{
    claimDate:'15-11-2022',
    refNo:'REF001',
    sponsorName:'Test',
    patientName:'Test Name',
    Rule:'001',
    subAgent:'Agent X',
    periodFrom:'01-11-2022',
    periodTo:'15-11-2022',
    claimAmt:'250000',
    totBills:'300',
    remarks:''
  }];

  columnClaimrecept=[{
    claimDate:'15-11-2022',
    refNo:'REF001',
    sponsorName:'Test',
    patientName:'Test Name',
    Rule:'001',
    subAgent:'Agent X',
    periodFrom:'01-11-2022',
    periodTo:'15-11-2022',
    claimAmt:'250000',
    totBills:'300',
    remarks:''
  }];
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  public context;
  public frameworkComponents;
  public loadingTemplate;
  public noRowsTemplate;
  // END OF GRID ITEMS
  constructor(
    private modalService: BsModalService

  ) { }

  ngOnInit(): void {
    this.context = { componentParent: this };

    // Grid COls
    this.columnDefs = [
      {
        headerName: "Claim Date",
        field: "claimDate",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Ref No.",
        field: "refNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Sponsor Name",
        field: "sponsorName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Rule",
        field: "Rule",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Sub Agent",
        field: "subAgent",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Period From",
        field: "periodFrom",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Period To",
        field: "periodTo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Claim Amt",
        field: "claimAmt",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Tot. Bills",
        field: "totBills",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Remarks",
        field: "Remarks",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Rec Amount",
        field: "Remarks",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Denied Amt",
        field: "DeniedAmt",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Resub Amt",
        field: "ResubAmt",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "manageClaim" },
      },
    ];
    this.columnClaim = [
      {
        headerName: "Claim Date",
        field: "claimDate",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Ref No.",
        field: "refNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Sponsor Name",
        field: "sponsorName",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },

      {
        headerName: "Period To",
        field: "periodTo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Claim Amt",
        field: "claimAmt",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Tot. Bills",
        field: "totBills",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
     
      {
        headerName: "Action",
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        filter: false,
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "manageClaim" },
      },
    ];
    this.columnDefsList = [
      {
        headerName: "REsub Ref No.",
        field: "refNo.",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Remitance",
        field: "remitance",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Resub Date",
        field: "resubdate",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
    ];
    this.columnDefsDetails = [
      {
        headerName: "Bill No.",
        field: "billNo",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Type",
        field: "type",
        resizable: true,
        sortable: true,
        filter: true,
        flex: 2,
      },
      {
        headerName: "Comment",
        field: "comment",
        resizable: true,
        filter: true,
        sortable: true,
        flex: 2,
      },
    ];


    this.frameworkComponents = { buttonRender: BillingGeneralActionComponent};
    // End Of Grid Cols

    //sampleData

  }
  onTab(number) {
    this.uploadservicestab = false;
    this.downloadservicestab = false;
   

    if (number == "1") {
      this.uploadservicestab = true;
    } else if (number == "2") {
      this.downloadservicestab = true;
    } 
   
    
  }


  // Grid Function

  onGridReady(params) {
    this.gridApi = params.api;
  }
  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
  // ENd of Grid Function

  onlineSubmission(data){
    this.modalRef= this.modalService.show(
       this.modaltransaction, {  class: "modal-lg modal-custom-style", backdrop: "static"})
   }
   claimReceipt(data){
    this.modalRef= this.modalService.show(
       this.modalclaimrecept, {  class: "modal-xl modal-custom-style", backdrop: "static"})
   }
  cancelItem(data){
    this.modalRef= this.modalService.show(
       this.modalCancel, { class: "gray" })
   }
   openModalupload(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,{ class: "gray modal-lg " });
  }
  Resubmission(data){
    this.modalRef= this.modalService.show(
       this.modalResubmmision, { class: "gray modal-xl" })
   }

}
