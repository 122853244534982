import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeDetectorService {

  private timer: any;
  private idleTimeThreshold: number;
  // @Inject idleTimeThreshold;

  constructor(private router: Router,) {
    // // this.idleTimeThreshold = idleTimeThreshold;
    // this.startTimer();
    // this.setupEventListeners();
   }

   startFun(idleTimeThreshold) {
    this.idleTimeThreshold = idleTimeThreshold;
    this.startTimer();
    this.setupEventListeners();
   }

   private startTimer() {
    this.timer = setTimeout(this.handleIdle.bind(this), this.idleTimeThreshold);
  }

  private resetTimer() {
    clearTimeout(this.timer);
    this.startTimer();
  }

  private handleIdle() {
    // This function is called when idle time is detected.
    // You can perform actions like logging the user out or displaying a message.
    console.log('User is idle.');
    // this.router.navigate(['/logout']);


    localStorage.clear();
    sessionStorage.clear()
    localStorage.removeItem("usertype");
    this.router.navigate(['/']);
    location.reload()
  }

  private setupEventListeners() {
    // Add event listeners to reset the timer when there is user activity.
    window.addEventListener('mousemove', this.resetTimer.bind(this));
    window.addEventListener('mousewheel', this.resetTimer.bind(this));
    window.addEventListener('keydown', this.resetTimer.bind(this));
    window.addEventListener('keyup', this.resetTimer.bind(this));
    window.addEventListener('keypress', this.resetTimer.bind(this));
    // Add more event listeners as needed (e.g., 'click', 'touchstart', etc.).
  }

}
