import { Component, OnInit, Renderer2 } from "@angular/core";
import { HospitalsService } from "../services/hospitals.service";
import { AppointmentsService } from "../services/appointments.service";
import { DatePipe } from "@angular/common";
import { Departments } from "../interfaces/Departments";
import { Consultants } from "../interfaces/Consultants";
import { AppointmentsList } from "../interfaces/AppointmentsList";
import { FormBuilder, FormGroup, FormControl, FormArray } from "@angular/forms";
import { ConsultaionsService } from "../services/consultations.service";
import { ConsultationsList } from "../interfaces/ConsultationsList";
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralActionComponent } from "../general-action/general-action.component";
import { PatientService } from "../services/patient.service";
import { progressBarList } from "../interfaces/ProgressBarList";
import { ToastrService } from "ngx-toastr";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Router } from "@angular/router";
import { globalvars } from "../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { SharedService } from "../../../shared/shared.service";
import {
  GuiCellView,
  GuiColumn,
  GuiPaging,
  GuiPagingDisplay,
  GuiSearching,
  GuiSorting,
  GuiColumnMenu,
  GuiInfoPanel,
  GuiDataType,
} from "@generic-ui/ngx-grid";

@Component({
  selector: 'app-ServiceOrderingComponent',
  templateUrl: './serviceorderdetails.component.html',
  styleUrls: ['./serviceorderdetails.component.scss'],

})
export class ServiceOrderingComponent implements OnInit {
  form: FormGroup;
  displayBasic: boolean;
  UrgentVisit: boolean;
  position: string;
  sourceProducts: any[];
  items: any = {};
  items1: any = {};
  items2: any = {};
  targetProducts: any[];
  products: any = [];
  users: any = [];
  selectedCities: any[] = [];
  availableProducts: any = [];
  draggedProduct: any = [];
  displayModal1: boolean;
  displayBasicAppoint: boolean;
  supervisor = "";
  locationname = "";
  Mobilenumber = "";
  displayPosition: boolean;
  departments: any;
  consultants: Consultants[] = [];
  appointments: AppointmentsList[] = [];
  userType: any;
  totalConsultations: any;
  totalAppointments: any;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingsPostPoned: IDropdownSettings = {};
  gender: any;
  patientName: any;
  CancelButton: boolean;
  PostponedAppointments: boolean;
  blockId: any;
  links: any[] = ["link1.com", "link2.com", "link3.com"];
  minDate = new Date();
  mailText: string = "";
  searchForm: FormGroup;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  columns: Array<GuiColumn> = [
    {
      header: "Patient Name",
      field: "patientName",
    },
    {
      header: "Reg.No",
      field: "regNo",
      view: GuiCellView.CHIP,
    },
    {
      header: "Time",
      field: "timeNo",
    },

    {
      header: "Status",
      field: "status",
    },

    {
      header: "",
      field: "",
    },
  ];

  //consultation generic grid
  columns1: Array<GuiColumn> = [
    {
      header: "Token",
      field: "tokenNO",
    },
    {
      header: "Patient Name",
      field: "patientName",
      view: GuiCellView.CHIP,
    },
    {
      header: "Reg No",
      field: "regNo",
    },

    {
      header: "Time",
      field: "timeNo",
    },

    {
      header: "Sponsor",
      field: "sponsor",
    },
    {
      header: "Status",
      field: "status",
    },
  ];

  paging: GuiPaging = {
    enabled: true,
    page: 1,
    pageSize: 10,
    pageSizes: [10, 25, 50],
    pagerTop: false,
    pagerBottom: true,
    display: GuiPagingDisplay.ADVANCED,
  };

  sorting: GuiSorting = {
    enabled: true,
  };

  searching: GuiSearching = {
    enabled: true,
    placeholder: "Search Here",
  };
  GuiColumnMenu = {
    enabled: true,
    columnsManager: true,
  };

  infoPanel: GuiInfoPanel = {
    enabled: true,
    infoDialog: false,
    columnsManager: true,
  };

  rowGroupPanelShow = "always";

  rowData: ConsultationsList[] = [];
  //ag grid setup
  public gridApi;
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public noRowsTemplate;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.status == "C";
      },
    },

    domLayout: "autoHeight",
  };
  public statusBar;
  // appoinements ag Grid
  columnDefs: ColDef[] = [
    {
      headerName: "Token No.",
      field: "tokenNO",
      width: 100,
      minWidth: 50,
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      flex: 1.5,
    },
    {
      headerName: "Patient Name",
      field: "patientName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
      onCellClicked: this.showuserDialog.bind(this),
      cellRenderer: (params) => {
        if (params.data.gender == 1) {
          if (params.data.emergency == 1) {
            return (
              " <img  src='assets/images/xs/maleurgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          }
          return (
            " <img  src='assets/images/xs/malegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        } else if (params.data.gender == 2) {
          if (params.data.emergency == 1) {
            return (
              " <img  src='assets/images/xs/femaleurgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          }

          return (
            " <img  src='assets/images/xs/femalegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        } else if (params.data.gender == 0) {
          if (params.data.emergency == 1) {
            return (
              " <img  src='assets/images/xs/defaulturgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          }
          return (
            " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        } else if (params.data.gender == 3) {
          if (params.data.emergency == 1) {
            return (
              " <img  src='assets/images/xs/defaulturgent.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
              "&nbsp" +
              "&nbsp" +
              params.value
            );
          }
          return (
            " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        }
      },
    },
    {
      headerName: "Reg. No.",
      field: "regNo",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Time",
      field: "timeNo",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
    },
    {
      headerName: "Sponsor",
      field: "sponsor",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },

    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },

    {
      headerName: "Action",
      sortable: false,
      resizable: false,
      unSortIcon: true,
      field: "Id",
      flex: 2,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "FrontofficeConsultationList" },
    },
  ];

  ConsultationBlockId: number;
  active: string;
  consultationId: number;
  blockorunblock: boolean;
  confirmBlock: boolean;

  // Appointmnet Ag=grid
  rowDataAppt: AppointmentsList[] = [];

  columnDefs_appt: ColDef[] = [
    {
      headerName: "Patient Name",
      field: "patientName",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
      onCellClicked: this.showuserDialogAppointments.bind(this),
      cellRenderer: (params) => {
        if (params.data.gender == 1) {
          return (
            " <img  src='assets/images/xs/malegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        } else if (params.data.gender == 2) {
          return (
            " <img  src='assets/images/xs/femalegeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        } else {
          return (
            " <img  src='assets/images/xs/defaultgeneral.png' data-toggle='tooltip' data-placement='top' title='' alt='Avatar' class='avatar rounded-circle' data-original-title='Avatar Name'>" +
            "&nbsp" +
            "&nbsp" +
            params.value
          );
        }
      },
    },
    {
      headerName: "Reg. No.",
      field: "regNo",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Time",
      field: "timeNo",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
    },

    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      flex: 2,
    },

    {
      headerName: "Action",
      resizable: true,
      sortable: false,
      unSortIcon: true,
      field: "Id",
      flex: 2,
      cellRenderer: "buttonRender",
      cellRendererParams: { PageType: "FrontAppointmentList" },
    },
  ];
  confirmMarkasUrgent: boolean = false;
  progressBarData: progressBarList[] = [];
  userId: number;
  locationId: number;
  ReasonVisit: boolean;
  appointmentDataReason: any;
  reasonDataReason: any;
  result: any;
  consultId: boolean;
  OtherSymptom: any;
  symptomList: any;
  CancelAppointments: boolean;
  confirmblockApp: boolean;
  confirmedblockApp: boolean;
  Confirmed: any;
  Arrived: any;
  Cancelled: any;
  waiting: any;
  ConsultCancelled: any;
  Consultfinished: any;
  ConsultOngoing: any;
  Consultwaiting: any;
  AppointmentDataDetails: AppointmentsList[];
  appDate: string;
  AppId: any;
  emergency: any;
  BranchId: number;
  consultDataId: any[];
  departmentList: any;

  valueDate: string;
  scheduleTime: any;
  postponeddate: any;
  sliceNo: any;
  EditStatus: boolean;
  EditStatusAppId: any;
  symptomDetails: any;
  BranchesId: any;
  addressapp: any;
  mobapp: any;
  regNoapp: any;
  emailapp: boolean;
  appdate: any;
  regNoconsult: any;
  regNoaddress: any;
  regNomobile: any;
  consultdate: any;
  consultemail: any;
  postponnedconsultId: any;

  constructor(
    private formBuilder: FormBuilder,
    private hospitalsService: HospitalsService,
    private appointmentsService: AppointmentsService,
    private patientService: PatientService,
    private consultationsService: ConsultaionsService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private router: Router,
    private appointmentService: AppointmentsService,
    private sharedservice: SharedService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agTotalRowCountComponent",
          align: "center",
        },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };

    this.minDate.setDate(this.minDate.getDate() - 1);
  }
  //Initailasing functions on Page Load
  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "symptomId",
      textField: "newsymDesc",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
    this.dropdownSettingsPostPoned = {
      singleSelection: false,
      idField: 'sliceNo',
      textField: 'sliceTime',
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };

    this.userType = localStorage.getItem("usertype"); //U As admin otherwise consultant

    // Appointments Action grid
    this.form = this.formBuilder.group({
      departmenId: [0],
      consultantId: [0],
      patientStatus: [""],
      appointmentStatus: new FormControl(""),
      reasonDataReason: new FormControl(""),
      ConsultationId: new FormControl(""),
      Symptoms: new FormControl(""),
      otherreasonforvisit: new FormControl(""),
      blockReason: new FormControl(""),
      PostPonedDepartment: new FormControl(""),
      PostPonedConsultant: new FormControl(""),
      appDate: new FormControl(""),
      slicetime: new FormControl(""),
      DeptId: new FormControl(""),
      consultId: new FormControl(""),
      sliceNo: new FormControl(""),
    });

    // Modal form initialization
    this.searchForm = this.formBuilder.group({
      Symptoms: new FormControl(),
      otherreasonforvisit: new FormControl(""),
      blockReason: new FormControl(""),
    });

    this.GetDepartments(); //on load api function getting departments
    this.GetAppointments(); //on load api function getting appoinments
    this.GetConsultations();
    this.GetProgressBar();
    this.GetTemplateReasonForVisit();
  }

  /* ============  fetching data to be filled in progress bar ============ */
  GetProgressBar() {
    let CurrentDate = new Date();
    let date = this.datePipe.transform(CurrentDate, "dd-MM-yyyy");
    this.patientService.GetProgressBar(date, this.globals.HospitalId).subscribe((res) => {
      this.progressBarData = res.response;
      this.waiting = this.progressBarData[0].appPercW;
      this.Arrived = this.progressBarData[0].appPercA;
      this.Cancelled = this.progressBarData[0].appPercC;
      this.Confirmed = this.progressBarData[0].appPercF;

      this.Consultwaiting = this.progressBarData[0].consPercW;
      this.ConsultOngoing = this.progressBarData[0].consPercO;
      this.ConsultCancelled = this.progressBarData[0].consPercC;
      this.Consultfinished = this.progressBarData[0].consPercF;

      // this.searchForm.patchValue({blockReason:''})
    });
  }
  /* ============  fetching data to be filled in select department dropdowns ============ */
  GetDepartments() {
    let ShowAll = 0;
    this.hospitalsService.GetDepartments(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.departments = res.response;
    });
  }
  /* ============ Onchanging data Appointments by consultations ============ */
  loadConsultant(DepartmentId: number) {
    let departmentId = Number(this.form.get("departmenId").value);

    this.hospitalsService.GetConsultant(departmentId).subscribe((res) => {
      this.consultants = res.response;
      this.form.patchValue({ consultantId: "" });
      this.GetAppointments();
      this.GetConsultations();
    });
  }

  /* ============  fetching data to the appointments grid ============ */
  GetAppointments() {
    let CurrentDate = new Date();
    let date = this.datePipe.transform(CurrentDate, "dd-MM-yyyy");
    let DepartmentId = Number(this.form.get("departmenId").value);
    let consultantId = Number(this.form.get("consultantId").value);
    this.appointmentsService
      .GetAppointments(date, DepartmentId, consultantId, this.globals.HospitalId)
      .subscribe((res) => {
        this.appointments = res.response;
        this.totalAppointments = this.appointments.length;

        this.rowDataAppt = res.response;
        this.totalAppointments = this.rowDataAppt.length;
        this.GetConsultations();
      });
  }
  /* ============  fetching data to the Consultations grid ============ */
  GetConsultations() {
    let status = this.form.get("patientStatus").value;
    let CurrentDate = new Date();
    let date = this.datePipe.transform(CurrentDate, "yyyy-MM-dd");
    let DepartmentId = Number(this.form.get("departmenId").value);
    let consultantId = Number(this.form.get("consultantId").value);
    this.consultationsService
      .GetConsultations(date, status, consultantId, DepartmentId, this.globals.HospitalId)
      .subscribe((res) => {
        this.rowData = res.response;
        this.totalConsultations = this.rowData.length;
      });
    //this.GetAppointments();
  }

  /* ============  userinformation opening modal data  ============ */

  showuserDialog(consultation: any) {
    consultation = consultation.data;
    this.patientName = consultation.patientName;
    this.gender = consultation.gender;
    this.emergency = consultation.emergency;
    this.regNoconsult = consultation.regNo;
    this.regNoaddress = consultation.address;
    this.regNomobile = consultation.mobile;
    this.consultdate = consultation.consultDate;
    //this.consultemail=consultation.data.mobile;
    this.displayBasic = true;
  }

  showuserDialogAppointments(product: any) {

    this.gender = product.data.gender;
    this.patientName = product.data.patientName;
    this.addressapp = product.data.address1;
    this.mobapp = product.data.mobile;
    this.regNoapp = product.data.regNo;
    this.emailapp = product.data.email;
    this.appdate = product.data.appDate
    // this.emergency = consultation.emergency;
    this.displayBasicAppoint = true;
  }

  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  }

  showuserDialog3() {
    this.displayBasic = true;
  }

  save(param) {
    return false;
  }
  drop() {
    return false;
  }
  mailMe() {
    this.mailText =
      "mailto:abc@abc.com?subject=files&body=" + this.links.join(" ,");
    window.location.href = this.mailText;
  }

  // Activetab(pagetitle = "Front Office") {
  //   sessionStorage.setItem("pageTitle", pagetitle);
  // }

  //Mark as urgent Confirmation Popup
  ConfirmMarkasUrgent(rowDatas) {
    this.confirmMarkasUrgent = true;
  }

  confirmrugentConslt(rowDatas) {
    let consultationId = Number(rowDatas.consultationId);
    // this.consultationsService.MarkasUrgentConsultation(consultationId).subscribe((res)=>{

    //     this.GetConsultations();
    // });

    this.confirmMarkasUrgent = false;
  }

  //changing urgent consultation
  ChangeAsUrgent(id) {
    this.consultationId = id;

    this.userId = Number(localStorage.getItem("user_id"));
    this.locationId = 1;

    this.consultationsService
      .ChangeAsUrgent(this.consultationId, this.userId, this.locationId,  this.globals.HospitalId)
      .subscribe((res) => {
        if (res.message == "success") {
          this.toastr.success("Consultation changed as Urgent");
          this.GetConsultations();
        } else {
          this.toastr.error("Failure: " + res["errorMessage"]["message"]);
        }
      });
    this.GetConsultations();
  }
  // Consulation cancel confirmation modal
  showBlockUnblock(rowDatas) {
    this.searchForm.patchValue({ blockReason: "" });
    this.active = rowDatas.status == "W" ? "Cancel" : "Cancelled";
    this.consultationId = Number(rowDatas.consultationId);
    this.blockorunblock = true;
  }

  // Consulation cancel confirmation is true then ask reason
  confirmblocking(blockId: any) {
    if (this.active != "Cancelled") {
      this.confirmBlock = true;
    }
  }

  // Function to cancel consultation with reason
  ConfirmBlock() {
    let blockReason = this.searchForm.get("blockReason").value;
    this.consultationsService
      .CancelConsultation(this.consultationId, blockReason)
      .subscribe((res) => {
        this.GetConsultations();
      });
    this.confirmBlock = false;
  }

  // //Getting teplates for symtoms and reason for visit
  GetTemplateReasonForVisit() {
    let ShowAll = 0;
    this.consultationsService.GetTemplateReasonForvisit(this.globals.HospitalId, ShowAll).subscribe((res) => {
      this.symptomList = res.response;
      let syptomReasonList = [];
      syptomReasonList.push({
        SymptomId: 0,
        SymptomDesc: "None Of the Above",
        Active: 1,
        BlockReason: "",
      });
      this.symptomList = syptomReasonList.concat(this.symptomList);
      this.symptomList.forEach((element) => {
        element["newsymDesc"] = element["SymptomDesc"]
          ? element["SymptomDesc"]
          : element["symptomDesc"];
      });
    });
  }

  //  checking Agi grid is loaded completely

  onGridReady(params) {
    this.gridApi = params.api;
  }
  //Modal showing reason visits
  ReasonForVisitModal(id) {
    //this.rowData;

    this.consultationId = id.consultationId;
    this.GetTemplateReasonForVisit();
    this.consultationsService
      .Getsymptoms(this.consultationId)
      .subscribe((res) => {
        this.symptomDetails = res.response;

        this.searchForm.patchValue({
          Symptoms: this.symptomDetails["symptoms"],
        });
      });

    this.ReasonVisit = true;
  }

  //updating reason for visit
  SaveReasonForVisit() {
    var AppData = {
      ConsultationId: this.consultationId,
      Symptoms: this.searchForm.get("Symptoms").value,

      otherreasonforvisit: this.searchForm.get("otherreasonforvisit").value,
    };

    this.consultationsService.SaveReasonVisit(AppData).subscribe((res) => {
      this.result = res;

      if (this.result.status == 200) {
        this.toastr.success("Reason For Visit added successfully");
        this.GetConsultations();
      } else {
        this.toastr.error("Reason For Visit failed");
      }
    });
  }
  //Modal showing for urgent Consultations
  UrgentVisitmodal(id) {
    this.consultId = id.consultationId;
    this.UrgentVisit = true;
  }
  //confirm for saving Urgent consultation
  ConfirmUrgentConsultation(id) {
    this.ChangeAsUrgent(id);
  }

  //reason for visit symptoms list
  SymptomReason() {
    let val = this.searchForm.get("Symptoms").value;

    if (val.length > 0) {
      if (val[0]["newsymDesc"] == "None Of the Above") {
        this.OtherSymptom = true;
      } else {
        this.OtherSymptom = false;
      }
    } else {
      this.OtherSymptom = false;
    }
  }
  //Modal showing cancel Appointment
  showCancelAppointment(rowDatas) {
    let appointmentsData = this.appointments.filter(
      (oppdata) => oppdata["appId"] == rowDatas
    );

    this.form.patchValue({ blockReason: "" });
    this.active =
      appointmentsData[0]["appStatus"] == "Cancelled"
        ? "Already Cancelled this Appointment"
        : "Are you sure that you want to this Cancel Appointment?";
    this.blockId = rowDatas;
    this.CancelAppointments = true;
    this.CancelButton =
      appointmentsData[0]["appStatus"] == "Cancelled" ? false : true;
  }
  // Consulation cancel confirmation is true then ask reason
  confirmblockingAppointment(blockId) {
    this.confirmblockApp = true;
  }

  // Function to cancel consultation with reason
  ConfirmBlockingApp(blockId) {
    let blockReason = this.form.get("blockReason").value;

    this.appointmentService
      .CancelAppointment(this.blockId, blockReason)
      .subscribe((res) => {
        this.GetAppointments();
        //this.LoadAppTable();
      });
    this.confirmedblockApp = false;
  }

  //print appointment card
  PrintAppointmentCard(appId) {
    this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      let appointmentsData = this.AppointmentDataDetails.filter(
        (oppdata) => oppdata["appId"] == appId
      );

      let printContents = "";
      //contact detatils

      printContents =
        printContents +
        '<div align="center"><div class="card" style="border:1px solid #000;width:50%">';
      printContents =
        printContents +
        ' <table class="print-table1"><tr><td><h3>Appointment Details</h3></td></tr><tr><td><strong>Appointment Date.</strong></td><td>' +
        appointmentsData[0]["appDate"] +
        "</td></tr>";

      printContents =
        printContents +
        " <tr><td><strong>App Time:</strong></td><td>" +
        appointmentsData[0]["sliceData"][0]['sliceTime'] +
        "</td></tr>";

      printContents =
        printContents +
        " <tr><td><strong>Consultant Name:</strong></td><td>" +
        appointmentsData[0]["consultantName"] +
        "</td></tr>";
      printContents =
        printContents +
        " <tr><td><strong>Patient Name:</strong></td><td>" +
        appointmentsData[0]["firstName"] + " " + appointmentsData[0]["middleName"] + " " + appointmentsData[0]["lastName"]
      "</td></tr>";
      // printContents = printContents + ' <tr><td><strong>Mobile:</strong></td><td>' + this.appointments[0].mobile + '</td></tr>';

      var mywindow = window.open("", "PRINT", "height=1000,width=1200");
      mywindow.document.write(
        '<html><head><link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"><title>' +
        document.title +
        "</title>"
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write(printContents);
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    });
    return true;
  }
  //apoointment postponned modal
  AppointmentPostPoned(appId) {
    this.AppId = appId;
    this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
      this.departmentList = [this.AppointmentDataDetails[0]["deptId"]];
      this.consultDataId = [this.AppointmentDataDetails[0]["consultantId"]];
      this.postponnedconsultId = this.AppointmentDataDetails[0]["consultantId"];
      this.form.patchValue({
        PostPonedDepartment: this.AppointmentDataDetails[0]["departmentName"],
      });
      this.form.patchValue({
        PostPonedConsultant: this.AppointmentDataDetails[0]["consultantName"],
      });
      this.form.patchValue({
        appDate: this.AppointmentDataDetails[0]["appDate"],
      });
      this.form.patchValue({
        slicetime: this.AppointmentDataDetails[0]["sliceData"],
      });



      // this.form.patchValue({
      //   slicetime: this.AppointmentDataDetails[0]["sliceTime"],
      // });
      this.form.patchValue({
        sliceNo: this.AppointmentDataDetails[0]["sliceNo"],
      });
      //this.form.patchValue({date:  this.appDate});


      this.PostponedAppointments = true;
    });
    setTimeout(() => {
      this.GetScheduledData();
    }, 1000);
  }

  //Getschedule method called from AppointmentService
  GetScheduledData() {
    this.valueDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.appointmentService
      .GetAppointmentSchedule(
        this.consultDataId,
        this.valueDate,
        this.departmentList,
        this.BranchesId
      )
      .subscribe((res) => {
        this.scheduleTime = res.response[0]["labels"];
        // this.form.patchValue({ slicetime: this.scheduleTime.sliceTime });
      });
  }

  //Save PostPonned Appointments method
  InsertPostponnedAppointments(appId) {
    this.postponeddate = this.form.get("appDate").value;

    if (typeof this.postponeddate == "object") {
      this.postponeddate = this.datePipe.transform(
        this.postponeddate,
        "dd-MM-yyyy"
      );
    } else {
      this.postponeddate = this.form.get("appDate").value;
    }
    let sliceData = this.form.get("slicetime").value;
    let sliceDataDetails = [];
    sliceData.forEach(a => {
      sliceDataDetails.push({ "sliceNo": Number(a.sliceNo), "sliceTime": a.sliceTime });
    });
    var AppData = {
      appId: this.AppId,
      PostPonedConsultant: this.postponnedconsultId,
      PostPonedDepartment: this.form.get("PostPonedDepartment").value,
      AppDate: this.postponeddate,
      SliceTime: sliceDataDetails,
      // SliceNo: 0,
      UserId: Number(localStorage.getItem("user_id")),
    };
    this.appointmentService
      .SavePostponnedAppointments(AppData)
      .subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Postponned Appointment added successfully");
          this.GetAppointments();
          this.PostponedAppointments = false;


        } else {
          this.toastr.error("Postponned Appointment failed");
        }
      });
  }

  //Details to show Appoinment Status modal
  EditStatusModal(appId) {
    this.EditStatusAppId = appId;
    this.appointmentService.GetAppoinmentDetailsbyId(appId).subscribe((res) => {
      this.AppointmentDataDetails = res.response;
    });

    this.form.patchValue({
      appointmentStatus: this.AppointmentDataDetails["appStatus"],
    });

    this.EditStatus = true;
  }
  //Inserting Appointment Status
  SaveAppointmentStatus(appId) {
    appId = this.EditStatusAppId;
    var AppData = {
      appId: this.EditStatusAppId,
      appointmentStatus: this.form.get("appointmentStatus").value,
    };

    // return false;
    this.appointmentService.SaveAppointmentstatus(AppData).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {
        this.toastr.success(" Appointment status added successfully");

        this.GetAppointments();
      } else {
        this.toastr.error("Appointment status failed");
      }
    });
  }
}



