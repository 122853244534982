import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LabService } from '../../../../lab/services/lab.service';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-status-info-modal',
  templateUrl: './status-info-modal.component.html',
  styleUrls: ['./status-info-modal.component.scss']
})
export class StatusInfoModalComponent implements OnInit {
  @Input() data:any

  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowClassRules: {
      "c-reds": function (params) {
        return params.data.status == "Cancelled";
      },
      "c-green2": function (params) {
        return params.data.status == "published";
      },
      "c-green1": function (params) {
        return params.data.status == "result entered";
      },
      
    },

    domLayout: "autoHeight",
  };
  serviceItemDef: ColDef[] = [
    {
      headerName: "Name",
      minWidth: 90,
      field: "itemName",
      enableRowGroup: true,
      resizable: true,
      sortable: true,
      flex: 1.5,
    },
    {
      headerName: "Status",
      minWidth:50,
      field: "status",
      enableRowGroup: true,
      resizable: true,
      flex: 1.5,
    }]
  gridApi: any;

  serviceItems:any[]=[]
  loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
  noRowsTemplate = `"<span">no rows to show</span>"`;

  constructor(
    private activeModal: BsModalRef,
    private labService:LabService,
  ) { }

  ngOnInit(): void {
    this.getinfoStatus()
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  close(){
    this.activeModal.hide()
  }

  getinfoStatus(){
    this.labService.GetServiceItem(this.data).subscribe({
      next:(res)=>{
        this.serviceItems=res.response
      }
    })
  }

}
