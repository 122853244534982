import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import * as XLSX from 'xlsx';
import { ConfigurationService } from '../../../client/services/configuration.service';
import _ from 'lodash';
import { Response } from '../../../../_Models/Response';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-excel-data-fetch-modal',
  templateUrl: './excel-data-fetch-modal.component.html',
  styleUrls: ['./excel-data-fetch-modal.component.scss']
})
export class ExcelDataFetchModalComponent implements OnInit {

  @Input() branchId: number;

  @Output() updateEmitter = new EventEmitter();

  public columnDef: ColDef[] = [];
  public fetchedData: any[] = [];
  public context = { componentParent: this };
  public gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  public noRowsTemplate : string = '<span>Select a file  to show data.../No data to show..</span>';
  public loadingTemplate: string = '';
  public selectedFile: any;

  public updateDrugTable: boolean = false;

  public updateLoading: boolean = false;

  private arrayBuffer: any;

  private gridApi: GridApi;

  constructor(private activeModalRef: BsModalRef,
              private toastrService: ToastrService,
              private datePipe: DatePipe,
              private configurationService: ConfigurationService) { }

  ngOnInit(): void {
    this.columnDef = [
      {
        headerName: "DDC Code",
        field: "DDC_CODE",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Trade Name",
        field: "TRADE_NAME",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Status",
        field: "STATUS",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Scientific Code",
        field: "SCIENTIFIC_CODE",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Scientific Name",
        field: "SCIENTIFIC_NAME",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Ingredient Strength",
        field: "INGREDIENT_STRENGTH",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Dosage Form Package",
        field: "DOSAGE_FORM_PACKAGE",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Route Of Admin",
        field: "ROUTE_OF_ADMIN",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Package Price",
        field: "PACKAGE_PRICE",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Granular Unit",
        field: "GRANULAR_UNIT",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Manufacturer",
        field: "MANUFACTURER",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Registered Owner",
        field: "REGISTERED_OWNER",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
      {
        headerName: "Updated Date",
        field: "UPDATED_DATE",
        minWidth:180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: false,
        filter: true,
      },
    ]
  }

  onGridReady(params){
    this.gridApi = params.api
  }

  onFileSelected(event){
    let file = event.target.files[0];
    if(file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type == 'application/vnd.ms-excel'){
      this.selectedFile = file;
      this.fetchData();
    }else{
      this.toastrService.error('Unsupported file type, Select a supported excel file..!');
      this.selectedFile = null;
      this.fetchedData = [];
    }
  }

  fetchData(){
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.selectedFile);
    fileReader.onload = (e)=>{
      this.arrayBuffer = fileReader.result;
      let data = new Uint8Array(this.arrayBuffer);
      let arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      let bstr = arr.join("");
      let workbook = XLSX.read(bstr, {type:"binary"});    
      let first_sheet_name = workbook.SheetNames[0];    
      let worksheet = workbook.Sheets[first_sheet_name];
      this.fetchedData =  XLSX.utils.sheet_to_json(worksheet,{raw:true});
    }
  }

  removeFile(){
    this.selectedFile = null;
    this.fetchedData = [];
  }

  close(){
    this.activeModalRef.hide();
  }

  onSaveClick(){
    let payload = {
      BranchId: Number(this.branchId),
      UserId: Number(localStorage.getItem('user_id')),
      DrugDetailList: [],
      IsUpdateDrugTable: this.updateDrugTable ? 1 : 0
    }
    _.forEach(this.fetchedData, (drug: any)=>{
      let [day, month, year] = (drug.UPDATED_DATE.slice(0,10)).split('/');
      let item = {
        Status: drug.STATUS,
        DDCCode: drug.DDC_CODE,
        ScientificCode: drug.SCIENTIFIC_CODE,
        ScientificName: drug.SCIENTIFIC_NAME,
        TradeName: drug.TRADE_NAME,
        IngredientStrength: drug.INGREDIENT_STRENGTH,
        DosageFormPackage: drug.DOSAGE_FORM_PACKAGE,
        RouteOfAdmin: drug.ROUTE_OF_ADMIN,
        PackagePrice: +(drug.PACKAGE_PRICE),
        GranularUnit: Number(drug.GRANULAR_UNIT),
        Manufacturer: drug.MANUFACTURER,
        RegisteredOwner: drug.REGISTERED_OWNER,
        UpdatedDate: this.datePipe.transform(new Date(+year, +month-1, day), 'yyyy-MM-dd'),
        Source: drug.SOURCE
      }
      payload.DrugDetailList.push(item);
    });
    this.updateLoading = true;
    this.configurationService.updateDrugDetailsFromExcel(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.toastrService.success('Drug details updated successfully.');
          this.updateEmitter.emit({
            status: true
          })
          this.close();
        }else{
          this.toastrService.error(response.errorMessage.message);
        }
        this.updateLoading = false;
      },error: (error: any)=>{
        this.toastrService.error('Something wents wrong.. Try again..!');
        this.updateLoading = false;
      }
    })
  }

}
