import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { tabOrder } from '../models/Preference.EMR';
import { ChiefComplaintsComponent } from './chief-complaints/chief-complaints.component';
import { SignsSymptomsIcdComponent } from './signs-symptoms-icd/signs-symptoms-icd.component';
import { ServiceOrdersComponent } from './service-orders/service-orders.component';
import { MedicalDecisionComponent } from './medical-decision/medical-decision.component';
import { PlansProcedureComponent } from './plans-procedure/plans-procedure.component';
import { MenstrualComponent } from './menstrual/menstrual.component';
import { ReviewOfSystemsComponent } from './review-of-systems/review-of-systems.component';
import { VitalSignsComponent } from './vital-signs/vital-signs.component';
import { DrugsMedicationComponent } from './drugs-medication/drugs-medication.component';
import { PhysicalExaminationComponent } from './physical-examination/physical-examination.component';
import { EMRService } from '../services/emr.service';
import { EMRPatientInfo,EMRSponsorInfo } from '../interfaces/EMRPatientInfo';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { EmrEntryStatus } from '../consultation-general/consultation-general.component';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../../_Models/Response';
import { Router } from '@angular/router';
import { SettingsService } from '../../settings/services/settings.service';
import { Settings } from '../../settings/model/settings.model';
import _ from 'lodash';
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { SignPopupComponent } from "../modals/sign-popup/sign-popup.component";
import { HealthSummaryModalComponent } from '../../treatment/modals/health-summary-modal/health-summary-modal.component';
import { PrintAsoapComponent } from '../../client/frontoffice/modals/print-asoap/print-asoap.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
import { AddDiseaseModalComponent } from '../../client/consultantdesk/modals/add-disease-modal/add-disease-modal.component';


@Component({
  selector: "app-consultation-general-single-page",
  templateUrl: "./consultation-general-single-page.component.html",
  styleUrls: ["./consultation-general-single-page.component.scss"],
})
export class ConsultationGeneralSinglePageComponent
  implements OnInit, OnDestroy
{
  @Input() tabOrders: tabOrder[];
  @Input() emrConfig:Settings[]
  @Input() billEdit:boolean
  @Input() functionPermissionData
  

  private EmrPatientInfo: any;
  private subscription: Subscription;
  private globals: globalvars = new globalvars();
  public EmrEntryStatus: EmrEntryStatus;
  EMRSponsorInfo:EMRSponsorInfo;
  modalRefAlert?: BsModalRef;
  printForm : FormGroup;
  @ViewChild(ChiefComplaintsComponent) CC: ChiefComplaintsComponent;
  @ViewChild(SignsSymptomsIcdComponent) SSI: SignsSymptomsIcdComponent;
  @ViewChild(ServiceOrdersComponent) SO: ServiceOrdersComponent;
  @ViewChild(MedicalDecisionComponent) MD: MedicalDecisionComponent;
  @ViewChild(PlansProcedureComponent) PP: PlansProcedureComponent;
  @ViewChild(MenstrualComponent) MH: MenstrualComponent;
  @ViewChild(ReviewOfSystemsComponent) RS: ReviewOfSystemsComponent;
  @ViewChild(VitalSignsComponent) VS: VitalSignsComponent;
  @ViewChild(DrugsMedicationComponent) DM: DrugsMedicationComponent;
  @ViewChild(PhysicalExaminationComponent) PE: PhysicalExaminationComponent;
  currentUrl: string = '';
  public configurationSettings: Settings[] = [];
  configSetting: Settings[] = [];
  showInsuranceDet: any;
  serviceTotal = 0;
  drugTotal:any = 0;
  total:any=0;
  icdVal:any;
  loader = false;
  consultfee:any = 0;
  addDiseaseCount: any=0;
  constructor(private EmrService: EMRService,
              private sharedService: SharedService,
              private settingsService: SettingsService,private modalService: BsModalService,
              private toastrService: ToastrService,
              private router: Router, private fb:FormBuilder
              ) {
                this.currentUrl = this.router.url
              }

  ngOnInit(): void {
        this.EmrPatientInfo = this.EmrService.GetEMRPatientInfo();
        this.EMRSponsorInfo = this.EmrService.GetEMRSponsorInfo();
        this.subscription = this.sharedService.getHospital().subscribe((glob) => {
                if (this.sharedService.checkglobals(glob)) {
                  this.globals = glob;
                }
              });
              this.getApplicationSettings();
        this.getEntryStatusEMRgeneral();
        this.createForm()
        // this.consultfee = localStorage.getItem('consultFee')
        this.consultfee = Number(this.EmrPatientInfo.ConsultationFee);
        this.total= (Number(this.serviceTotal)+Number(this.consultfee)+Number(this.drugTotal)).toFixed(2)
  }
  createForm(){
    this.printForm =  this.fb.group({
      printVal: ['']
    })
  }
  drugsTo(i:any){
    this.drugTotal = i
    this.total= (Number(this.serviceTotal)+Number(this.consultfee)+Number(this.drugTotal)).toFixed(2)
  }
  serviceTo(i:any){
    this.serviceTotal = i
    this.total= (Number(this.serviceTotal)+Number(this.consultfee)+Number(this.drugTotal)).toFixed(2)
  }
  onSaveEMR() {
    if(!this.loader) {
      this.CC.saveChiefComplaints();
      this.SSI.SaveNarrativeDiagonosis();
      this.SO.SaveServiceOrder();
      this.PP.SavePlanAndProcedure();
      this.MH.SaveMenstrualHistory();
      this.PE.SavePhysicalCommunication();
      this.RS.SaveReviewOfSymptoms();
      this.DM.saveDrugs();
      this.MD.SaveMedicalDecision();
      this.VS.SaveVitalSign();
      this.getEntryStatusEMRgeneral();
    }
  }

  onOpenChange(event: boolean, tabno: number) {
    if (!event) {
      switch (tabno) {
        case 1:
          this.CC.saveChiefComplaints();
          break;
        case 2:
          this.PE.SavePhysicalCommunication();
          break;
        case 3:
          this.RS.SaveReviewOfSymptoms();
          break;
        case 4:
          this.MD.SaveMedicalDecision();
          break;
        case 5:
          this.SSI.SaveNarrativeDiagonosis();
          break;
        case 6:
          this.PP.SavePlanAndProcedure();
          break;
        case 7:
          this.MH.SaveMenstrualHistory();
          break;
        case 8:
          this.VS.SaveVitalSign();
          break;
        case 9:
          this.DM.saveDrugs();
          break;
        case 10:
          this.SO.SaveServiceOrder();
          break;
        default:
          return;
      }
      this.getEntryStatusEMRgeneral();
    }
  }

  onHistoryClick(tabno: number) {
    switch (tabno) {
      case 1:
        this.CC.openHistory();
        break;
      case 2:
        this.PE.openHistory();
        break;
      case 3:
        this.RS.openHistory();
        break;
      case 4:
        this.MD.openHistory();
        break;
      case 5:
        this.SSI.openHistory();
        break;
      case 6:
        this.PP.openHistory();
        break;
      case 7:
        this.MH.openHistory();
        break;
      case 8:
        this.VS.openHistory();
        break;
      case 9:
        this.DM.openHistory();
        break;
      case 10:
        this.SO.openHistory();
        break;
      default:
        return;
    }
  }

  getEntryStatusEMRgeneral() {
    const payload = {
      Consultationid: this.EmrPatientInfo.ConsultationId,
      PatientId: this.EmrPatientInfo.PatientId,
      Visitid: this.EmrPatientInfo.VisitId,
      BrabchId: this.globals.HospitalId,
    };

    this.EmrService.getGeneralEntryStatus(payload).subscribe({
      next: (response: Response<any>) => {
        if (response.status == 200) {
          this.EmrEntryStatus = response.response[0];
        } else {
          this.toastrService.error(response.errorMessage.message);
        }
      },
      error: (error: any) => {
        this.toastrService.error(
          "Failed to load EMR entry status..! Try again."
        );
      },
    });
  }

  signPopup() {
    let initialState = {
      // EmrPatientInfo: this.EMRPatientInfo,
      branchId: this.globals.HospitalId,
    };
    let modalConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-scrollable",
    };
    const copyModalRef = this.modalService.show(
      SignPopupComponent,
      Object.assign({ class: "modal-dialog-centered " }, modalConfig, {
        class: "modal-md",
        initialState,
      })
    );
    copyModalRef.content.copyEmitter.subscribe((res) => {
      if (res.status) {
        this.SSI.searchValUpdate(res);
      }
    });
  }
  showHealthSummary(data: any){
    // const foundElement = this.functionPermissionData.find(x => x.id === 484)
    // if (foundElement.id == 484 && foundElement.isAllowed == true) {
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    let initialState = {
      patientId: this.EmrPatientInfo.PatientId,
      branchId: this.globals.HospitalId
    }
    this.close()
    const modalRef = this.modalService.show(HealthSummaryModalComponent, Object.assign({}, config, { class: 'modal-lg', initialState }));
    // } else {
      // this.toastr.warning("You are not allowed");
    // }
  }
  printAsoap(data){
    let asoapPatInfo = JSON.parse(localStorage.getItem('asoapPatInfo'));
    // const foundElement = this.functionPermissionData.find(x => x.id === 183)
    // if (foundElement.id == 183 && foundElement.isAllowed == true) {
    let initialState = {
      consultationId: this.EmrPatientInfo.ConsultationId,
      branchId: this.globals.HospitalId,
      sponsorId: this.EmrPatientInfo.SponsorId,
      telerikName:asoapPatInfo.telerikName
    }
    console.log(initialState,this.EmrPatientInfo)
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    this.close()
    const modalRef = this.modalService.show(PrintAsoapComponent, Object.assign({}, config, { class: 'modal-xl', initialState }));
    // } else {
      // this.toastr.warning("You are not allowed");
    // }
  }
  treatmentDetails(data){
      let initialState = {
        modalHeading: 'Treatment Summary',
        reportName: 'TreatmentSummary.trdp',
        reportParams: {
          PatientId : this.EmrPatientInfo.PatientId,
          FromDate : (this.EmrPatientInfo.VisitStartTime.split(" ")[0]),
          ToDate: (this.EmrPatientInfo.VisitStartTime.split(" ")[0]),
          ConsultationId : this.EmrPatientInfo.ConsultationId
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.close()
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  }
  printReferal(data){
      let initialState = {
        modalHeading: 'Referral Letter',
        reportName: 'ReferralLetter.trdp',
        reportParams: {
          branchId: this.globals.HospitalId,
          ConsultationId : this.EmrPatientInfo.ConsultationId
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.close()
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  }
  printMedicalReport(){
      let initialState = {
        modalHeading: 'Medical Report',
        reportName: 'RptMedicalReport.trdp',
        reportParams: {
          PatientId: this.EmrPatientInfo.PatientId,
          ConsultationId : this.EmrPatientInfo.ConsultationId
        }
      }
      let config: ModalOptions = {
        backdrop: true,
        ignoreBackdropClick: true,
      }
      this.close()
      const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-xl', initialState }))
  }
  openModal(template: TemplateRef<any>,i:any) {
    this.modalRefAlert = this.modalService.show(template, { class: 'modal-lg modal-custom-style' });
  }
  close(){
    this.modalRefAlert.hide()
    this.printForm?.reset()
  }
  next(){
    let val = this.printForm.value.printVal
    if(val =='RL'){
      this.printReferal('')
    } else if(val == 'TS'){
      this.treatmentDetails('')
    } else if(val == 'AP'){
      this.printAsoap('')
    } else if(val == 'HS'){
      this.showHealthSummary('')
    } else if(val == 'MR'){
      this.printMedicalReport()
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.onSaveEMR();
}

getApplicationSettings(){
  let  payload = {
    UserId: Number(localStorage.getItem('user_id')),
    LocationId: this.globals?.Location?.locationId,
    BranchId:this.globals.HospitalId
  }

  this.settingsService.getApplicationSettings(payload).subscribe({
    next: (response: Response<any>)=>{
      if(response.status == 200){
        console.log(response)
        this.configurationSettings = _.filter(response.response, {tabId: 1});
        let showInsure = this.configurationSettings.filter((item) => item.settId == 29);
        this.showInsuranceDet = Number(showInsure[0].settValue);
    }
  }
  })
}

addDisease() {
  
  let initialState = {
    diseaseId: -1
  }
  let config: ModalOptions = {
    backdrop: true,
    ignoreBackdropClick: true,
  }
  const modalRef = this.modalService.show(AddDiseaseModalComponent, Object.assign({}, config, { class: 'modal-md', initialState }));
  modalRef.content.diseaseEmitter.subscribe((response: any)=>{
    if(response.status){
      this.addDiseaseCount=this.addDiseaseCount+1
    }
  })
 }
}
