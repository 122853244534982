import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators,DefaultValueAccessor } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ColDef } from 'ag-grid-community';
import { CommissionList } from '../../client/interfaces/CommissionList';
import { CommissionService } from '../../client/services/commission.service';
import { GeneralActionComponent } from '../../client/general-action/general-action.component';
import { ReportsService } from '../../reports/services/reports.service'
import { ConfigurationService } from '../../client/services/configuration.service'

import { globalvars } from '../../../shared/models/GlobalVars';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CommonValidationComponent } from '../../../shared/common-validation/common-validation.component';


// const original = DefaultValueAccessor.prototype.registerOnChange;

// DefaultValueAccessor.prototype.registerOnChange = function (fn) {
//   return original.call(this, value => {
//     const trimmed = String(value) ? value.trim() : value;
//     return fn(trimmed);
//   })
// }

@Component({
  selector: 'app-commission-rules',
  templateUrl: './commission-rules.component.html',
  styleUrls: ['./commission-rules.component.scss']
})
export class CommissionRulesComponent implements OnInit {
  subscription: Subscription;
  globals: globalvars = new globalvars();
  GetGroupItems: any;
  commissionItemGroupList: any;
  displayModal: boolean;
  formData: CommissionList;
  commissionId: any;
  public submitted: boolean = false;
  commissionForm: FormGroup;
  //ag grid setup
  public gridApi;
  columnDefs: ColDef[] = [];
  public rowSelection = true;
  public defaultColDef: ColDef;
  public context;
  public frameworkComponents;
  public noRowsTemplate;
  public loadingTemplate;
  isLoading = false;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  }
  rowData: any;
  public statusBar;
  rowGroupPanelShow = "always";
  Commissions: CommissionList[] = [];
  result: any;
  IsDisplayed: any;
  DisplayedId: any;
  modalRef: BsModalRef;
  CommissionPopupHeading: string = "Add Rule";
  @ViewChild("template") modaltemp: any;

  public validator = new CommonValidationComponent();

  public isExecuted: boolean = false;
  functionPermissionData: any;

  constructor(
    private CommissionService: CommissionService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private ReportsService: ReportsService,
    private ConfigurationService: ConfigurationService,
    private sharedservice: SharedService,

  ) {

    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.globals.HospitalId
      }
    });
  }

  ngOnInit(): void {

    this.commissionForm = new FormGroup({
      commissionItemGroupList: new FormArray([]),
      commissionCode: new FormControl('', [Validators.required,this.validator.noWhitespaceValidator]),
      commissionName: new FormControl('', [Validators.required,this.validator.noWhitespaceValidator]),
      isDeleting: new FormControl(0),
      branchid: new FormControl(this.globals.HospitalId),
      isDisplayed: new FormControl(true),
      commissionId: new FormControl(0),

    });
    this.GetItemGroups();
    this.Getcommissionrule();
    this.functionPermission();


    this.columnDefs = [

      {
        headerName: "Commission Name",
        minWidth: 180,
        field: "commissionName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,

      },
      {
        headerName: "Commission Code",
        minWidth: 180,
        field: "commissionCode",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,

      },


      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        resizable: false,
        unSortIcon: true,
        field: "Id",
        flex: 2,
        pinned: "right",
        sortable: false,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "commissionList" },
      },




    ]

    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };



    this.frameworkComponents = {
      buttonRender: GeneralActionComponent
    };



    //this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }



  // for geting all form items

  get f() {
    return this.commissionForm.controls;
  }

  openModalCommission(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 151)
    if (foundElement.id == 151 && foundElement.isAllowed == true) {
    this.CommissionPopupHeading = "Add Rule";
    this.modalRef = this.modalService.show(template, { class: "modal-lg", backdrop: "static" });
    this.commissionId = 0;
    this.ResetFunction();
    } else {
      this.toastr.warning("You are not allowed to add rule");
    }
  }

  showModalDialog(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 152)
    if (foundElement.id == 152 && foundElement.isAllowed == true) {
    console.log(data);
    this.commissionForm.patchValue(data);



    const GroupItems = this.commissionForm.get("commissionItemGroupList") as FormArray;
    GroupItems.controls = [];

    let ggroupList = data.commissionItemGroupList;
    ggroupList.forEach(element => {
      GroupItems.push(this.createListGroup(element, '', 1));

    });



    this.CommissionPopupHeading = "Edit Commission Rules";
    this.modalRef = this.modalService.show(this.modaltemp, { class: "modal-lg modal-custom-style", backdrop: "static" });
    } else {
      this.toastr.warning("You are not allowed to edit rule");
    }
  }
  showModalDialogedit(id: any) {
    // this.commissionId = id;

    this.displayModal = true;
  }

  // loadserviceitem(){

  // }



  // to clear form

  clearForm() {
    (this.commissionForm.get('commissionItemGroupList') as FormArray).clear();
    var Value = new FormArray([]);
    Value = this.commissionForm.get("commissionItemGroupList") as FormArray;
    Value.controls = [];

    this.commissionForm.reset({
      commissionName: '',
      commissionCode: '',
      isDeleting: 0,
      branchid: this.globals.HospitalId,
      // commissionitemlist: [],
      isDisplayed: true,
      commissionItemGroupList: [],
    });

  }



  //SaveCommission




  SaveCommission() {
    this.isExecuted = false;
    this.submitted = true;
    if (this.commissionForm && this.commissionForm.valid) {
      this.formData = this.commissionForm.getRawValue();
      console.log(this.formData);
      if (this.formData.commissionId == null) {
        this.formData.commissionId = Number(0);
      }
      const tblrowcountdata = this.commissionForm.get("commissionItemGroupList");
      if (tblrowcountdata.value.length > 0) {
        const GroupItems = tblrowcountdata;
        tblrowcountdata.value.forEach(element => {
          if (this.isExecuted == false) {
            if (element.fromAmt == null || element.toAmt == null || element.percentageData == null) {
              this.toastr.warning("Warning: " + "Please enter all values to continue");
              this.isExecuted=true;
              return;
            }
          }
        });

        this.formData.branchid = Number(this.globals.HospitalId);
        this.CommissionService.Savecommission(this.formData).subscribe((res) => {
          this.submitted = false;
          this.isExecuted=false;
          if (res.status == 200 && res.message == 'Saved Successfully') {
            this.toastr.success("Data saved successfully");
            this.modalRef?.hide()
            this.Getcommissionrule();
            this.clearForm();
          }
          else if (res.status == 200 && res.message == 'Updated Successfully') {
            this.toastr.success("Data Updated Successfully");
            this.modalRef?.hide()
            this.Getcommissionrule();
            this.clearForm();
          }
          else {
            this.toastr.error("Failed : " + res.message);
          }


        });

      }
      else {
        this.isLoading = false;

        this.toastr.warning("Warning: " + "Please select at least one item to continue");
      }
    }else{
      console.log("Notvalid");
      console.log(this.commissionForm.errors);
    }

  }

  DeleteCommission(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 153)
    if (foundElement.id == 153 && foundElement.isAllowed == true) {
    let form = this.commissionForm.getRawValue();
    form.commissionId = data.commissionId;
    form.isDeleting = 1;
    this.CommissionService.Savecommission(form).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200 &&this.result.message=="Deleted Successfully") {
        this.toastr.success("Commission deleted successfully");
        this.Getcommissionrule();
        this.clearForm();
        this.commissionId = 0;
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
        this.displayModal = false;
      }

    },
    );
    } else {
      this.toastr.warning("You are not allowed to delete rule");
    }
  }


  //  checking Agi grid is loaded completely

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // onFilterTextBoxChanged() {
  //   this.gridApi.setQuickFilter(
  //     (document.getElementById('filter-text-box') as HTMLInputElement).value
  //   );
  // }

  ResetFunction() {
    // this.commissionId = 0;
    this.submitted = false;
    this.commissionForm.reset

    this.clearForm();
  }


  GetItemGroups() {


    this.ConfigurationService.GetGroupData(this.globals.HospitalId).subscribe((res) => {
      let response = res.response;
      this.GetGroupItems = response


    });

  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }


  Getcommissionrule() {
    const input = {
      "Id": 0,
      "ShowAll": 1,
      "Branchid": Number(this.globals.HospitalId)
    };
    this.CommissionService.GetCommissionData(input).subscribe((res) => {
      let response = res.response;
      this.Commissions = response
    });
  }

  LoadGroupItems(data) {

    console.log(data);

    const ServiceItems = this.commissionForm.get("commissionItemGroupList") as FormArray;

    let textdata = data.target.options[data.target.selectedIndex].text;
    let textvalue = data.target.value;

    let filteredArray = ServiceItems.value.filter(value => value.groupId == textvalue);
    if (filteredArray.length == 0) {
      ServiceItems.push(this.createListGroup(textdata, textvalue));
    }
    else {
      this.toastr.error("Failure: " + "Group Name Already added");
    }



  }

  private createListGroup(data: any[] = [], textvalue, saved = 0): FormGroup {

    let groupName = "";
    if (saved == 1) {
      textvalue = data['groupId'];
      groupName = data['groupName'];
    }
    else {
      groupName = data.toString();
    }


    return new FormGroup({
      groupId: new FormControl(Number(textvalue)),
      groupName: new FormControl(groupName),
      fromAmt: new FormControl(data['fromAmt']),
      toAmt: new FormControl(data['toAmt']),
      percentageData: new FormControl(data['percentageData']),
    });

  }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
    });

  }
}
