import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ErrorHandleService } from '../../client/services/error-handle.service';
import { Treatmentform } from '../interfaces/TreatmentForm';
import { Response } from '../../../_Models/Response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TreatmentsService {

  private baseUrl: string = "";

  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }

  /***********************************/
  SaveTreatmentDetails(InputDetails){
    var url = "api/Treatment/InsertTreatmentDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetTreatments(InputDetails:any){
    var url = "api/Treatment/GetTreatmentDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }

  GetPerformingStaff(InputDetails:any){
    var url = "api/MasterData/GetStaff";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }
  PrintTreatment(InputDetails:any){
    var url = "api/Report/PrintTreatmentDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }
  SearchPatientByRegNo(regNo: string) {
    // api/TodaysPatient/GetPatientByRegNo
    var url = "api/TodaysPatient/GetPatientByRegNo";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      {
        "RegNo": regNo
      }).pipe(
      );
  }

  searchForVaccine(payload:any){
    var url = "api/Treatment/GetVaccinationDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  saveVaccineDetails(payload: any): Observable<any>{
    var url = "api/Treatment/InsertUpdateVaccinationDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  getHealthSummaryChart(payload: any): Observable<any>{
    var url = "api/Emr/GetPatientHealthSummary";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  printHealthSummaryReport(payload: any): Observable<any>{
    var url = "api/Report/PrintPatientHealthSummary";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetServiceItemByConsultation(payload: any): Observable<any>{
    var url = "api/Physiotherapy/GetServiceItemByConsultation";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetSensory(payload: any): Observable<any>{
    var url = "api/MasterData/GetSensoryDeficit";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetStatus(payload: any): Observable<any>{
    var url = "api/Physiotherapy/PhyDetStatusGet";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  GetSummary(payload: any): Observable<any>{
    var url = "api/Physiotherapy/PhyGetSummary";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  saveSummary(payload: any): Observable<any>{
    var url = "api/Physiotherapy/PhySaveUpdateSummary";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  PhyGetReport(payload: any): Observable<any>{
    var url = "api/Physiotherapy/PhyGetReport";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  PhyDetSaveUpdate(payload: any): Observable<any>{
    var url = "api/Physiotherapy/PhyDetSaveUpdate";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  PhySaveItemFromOrder(payload: any): Observable<any>{
    var url = "api/Physiotherapy/PhySaveItemFromOrder";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  PhySaveUpdateHome(payload: any): Observable<any>{
    var url = "api/Physiotherapy/PhySaveUpdateHome";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  PhyGet(payload: any): Observable<any>{
    var url = "api/Physiotherapy/PhyGet";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  PhyGetDayDetail(payload: any): Observable<any>{
    var url = "api/Physiotherapy/PhyGetDayDetail";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  FinishPhysiotherapy(payload: any): Observable<any>{
    var url = "api/Physiotherapy/FinishPhysiotherapy";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  PhyDetGet(payload: any): Observable<any>{
    var url = "api/Physiotherapy/PhyDetGet";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }
  VerifyUser(payload: any): Observable<any>{
    var url = "api/Account/VerifyUser";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetVaccineChart(payload: any): Observable<any>{
    var url = "api/MasterData/GetVaccineChart";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetVaccinePurpose(InputDetails:any){
    var url = "api/MasterData/GetVaccinePurpose";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }

  InsertUpdateVaccineChartDetails(InputDetails:any){
    var url = "api/Treatment/InsertUpdateVaccineChartDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, InputDetails)
  }

  getVaccineDurationquantity(){
    var url = "api/Treatment/GetVaccineDurationQuantity";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {})
  }

  getVaccineDurationUnit(){
    var url = "api/Treatment/GetVaccineDurationUnits";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {})
  }

  getVaccinePattern(){
    var url = "api/Treatment/GetVaccinePatterns";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, {})
  }






}
