import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfigurationService } from '../../client/services/configuration.service';
import { ToastrService } from 'ngx-toastr';
import { HospitalBranchesList } from '../../client/interfaces/HospitalBranchesList';

@Component({
  selector: 'app-Branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class branchesComponent implements OnInit {
  products: any = {};
  displayModal: boolean;
  displayModal1: boolean;
  Hospitalform: FormGroup;
  supervisor = '';
  locationname = '';
  Mobilenumber = '';
  result: any;
  HospitalBranches: HospitalBranchesList[] = [];
  user_id: string;
  hospitalId: any;


  constructor(private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.BuildForm(1, 1);
    this.GetAllBranches();
    this.products = [{ 'code': 'Aj1002', 'name': 'Vasumathi Eye Clinic', 'Address1': '1st floor,Lullu Mall', 'address2': 'Edapally', 'street': 'Kochi', 'city': 'Ernakulam' }, { 'code': 'TMC_Kerala', 'name': 'Government Medical College TVM', 'Address1': 'Medical College PO', 'address2': 'TVM', 'street': 'ULLOOR', 'city': 'Thiruvananthapuram' }, { 'code': 'KIM', 'name': 'KIMS', 'Address1': '', 'address2': '', 'street': '', 'city': '' }, { 'code': 'AIIMS', 'name': 'All India Institute Of Medical Sciences', 'Address1': 'Delhi', 'address2': 'Delhi', 'street': 'Delhi', 'city': 'Delhi' }];
  }

  BuildForm(FormId, DepartmentId) {
    this.GenerateForm();
  }
  GenerateForm() {
    this.user_id = localStorage.getItem('user_id');
    this.Hospitalform = this.formBuilder.group({
      HospitalName: new FormControl(''),
      HospitalCode: new FormControl(''),
      Address1: new FormControl(''),
      Address2: new FormControl(''),
      street: new FormControl(''),
      Place: new FormControl(''),
      state: new FormControl(''),
      pin: new FormControl(''),
      city: new FormControl(''),
      country: new FormControl(''),
      Email: new FormControl(''),
      Mobile: new FormControl(''),
      DhaUserName: new FormControl(''),
      DhaUserPassword: new FormControl(''),
      Clinic: new FormControl(''),
      MalafiSystemCode: new FormControl(''),
      UserId: new FormControl(localStorage.getItem('user_id')),
    });
  }

  showModalDialog() {
    this.displayModal = true;
  }
  showModalDialogedit(id) {
    this.hospitalId = id;
    this.HospitalBranches;
    let HospitalData = this.HospitalBranches.filter((oppdata) => oppdata['hospitalId'] == id);

    this.Hospitalform.patchValue({
      HospitalName: HospitalData[0]['hospitalName'],
      HospitalCode: HospitalData[0]['hospitalCode'],
      Address1: HospitalData[0]['phone'],
      Address2: HospitalData[0]['pin'],
      street: HospitalData[0]['street'],
      Place: HospitalData[0]['placePO'],
      pin: HospitalData[0]['pin'],
      Email: HospitalData[0]['email'],
      city: HospitalData[0]['city'],
      country: HospitalData[0]['country'],
      state: HospitalData[0]['state'],
      Mobile: HospitalData[0]['phone'],
      DhaUserName: HospitalData[0]['dhaUserName'],
      DhaUserPassword: HospitalData[0]['dhaPassword'],
      Clinic: HospitalData[0]['clinicId'],
      MalafiSystemCode: HospitalData[0]['malaffiSystemcode'],

    });
    this.displayModal = true;
    this.displayModal1 = true;

  }



  SaveBranches() {
    const formData = new FormData();

    var AppData = {

      HospitalName: this.Hospitalform.get("HospitalName").value,
      HospitalCode: this.Hospitalform.get("HospitalCode").value,
      Address1: this.Hospitalform.get("Address1").value,
      Address2: this.Hospitalform.get("Address2").value,
      street: this.Hospitalform.get("street").value,
      PlacePO: this.Hospitalform.get("Place").value,
      PIN: this.Hospitalform.get("pin").value,
      City: this.Hospitalform.get("city").value,
      Country: this.Hospitalform.get("country").value,
      State: this.Hospitalform.get("state").value,
      Email: this.Hospitalform.get("Email").value,
      Phone: this.Hospitalform.get("Mobile").value,
      DHAUserName: this.Hospitalform.get("DhaUserName").value,
      DHAPassword: this.Hospitalform.get("DhaUserPassword").value,
      Clinic: this.Hospitalform.get("Clinic").value,
      MalafiSystemCode: this.Hospitalform.get("MalafiSystemCode").value,
      Caption: '0',
      ClinicId: '0',
      Fax: '0',
      URL: '',
      SR_ID: '',
      ReportLogo: '',
      Logo: '',
      DhaFacilityId: '',
      UserId: Number(localStorage.getItem('user_id')),
    }

    formData.append('HospitalJson', JSON.stringify(AppData));
    if (this.hospitalId > 0) {
      this.configurationService.SaveBranches(formData).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Hospital Branches Updated successfully");
        }
        else {
          this.toastr.error("Failed.Please try later");
        }
      })
    }
    else {
      this.configurationService.SaveBranches(formData).subscribe((res) => {
        this.result = res;
        if (this.result.status == 200) {
          this.toastr.success("Hospital Branches added successfully");
        }
        else {
          this.toastr.error("Failed.Please try later");
        }
      })

    }
  }

  GetAllBranches() {

    this.configurationService.GetHospitalBranches().subscribe((res) => {
      this.HospitalBranches = res.response;
      if (this.HospitalBranches.length == 0) {
        this.toastr.warning("No Hospital Branches found");
      }
    });
  }

  DeleteBranches() {

    this.configurationService.DeleteBranches(this.hospitalId).subscribe((res) => {
      this.result = res;
      if (this.result.status == 200) {

        this.toastr.success("Hospital Branches deleted successfully");
      }
      else {
        this.toastr.error("Failure: " + this.result.message);
      }

    },
    );

  }




}
