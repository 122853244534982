import { Component, OnInit,ViewChild } from '@angular/core';
import { SearchPatientModalComponent } from '../../client/frontoffice/searchpatient-modal/searchpatient-modal.component';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { globalvars } from '../../../shared/models/GlobalVars';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ReportsService } from '../services/reports.service';
import { NewBillingService } from '../../billing-settlement/new-bill/new-bill.services';
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-bill-settlement-details',
  templateUrl: './bill-settlement-details.component.html',
  styleUrls: ['./bill-settlement-details.component.scss']
})
export class BillSettlementDetailsComponent implements OnInit {
  @ViewChild(SearchPatientModalComponent) modaltemp: SearchPatientModalComponent;
  patientId:any;
  PatientInformation = {
    "regNo": "",
    "patient": ""
  };
  form: FormGroup; // form variable
  subscription: Subscription;
  globals: globalvars = new globalvars();
  private baseUrl = "";
  location: any[];
  consultants: any[] = [];
  GetConsultantsData: any;
  isLoading = false;
  src: any = '';
  isPdfLoaded: boolean = false;
  patients: any[] = [];
  result: any;
  branchLoadId:any;

regNo:any;
  constructor(
    public datepipe: DatePipe,
    private sharedservice: SharedService,
    private configurationManager: ConfigurationManager,
    private ReportsService: ReportsService,
    private NewBillingService: NewBillingService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,

  ) { 
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.branchLoadId=this.globals.HospitalId
      }
    });

    this.baseUrl = (this.configurationManager as any).baseUrl;

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      FromDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      ToDate: new FormControl(this.datepipe.transform(new Date(), 'dd-MM-yyyy')),
      locationId: new FormControl(0),
      ConsultantId: new FormControl(0),
      patientName: new FormControl(""),
      BranchId: new FormControl(this.branchLoadId),
      setType: new FormControl(0),
      patientId: new FormControl(0),
      regNo: new FormControl(''),

    });
this.Getlocation();
this.loadConsultants();
  }
  showBasicDialog() {
    this.modaltemp.open();
  }
  searchevent(patient: any) {
    this.patientId = patient.data.patientId;
    this.PatientInformation = patient.data;
    this.form.patchValue({ patientName: this.PatientInformation.patient});
    this.form.patchValue({ regNo: this.PatientInformation.regNo});

  }
  Getlocation() {

    let input = {
      "showAll": 0,
      "HospitalId": this.globals.HospitalId,
      "locationId": 0
    }
    this.ReportsService.Getlocation(input).subscribe((res) => {
      this.location = res.response;
    });

  }
  loadConsultants() {
    let Input = {
      "BranchId": Number(this.globals.HospitalId),
      "IsExternal": 3
    }

    this.NewBillingService.getConsultantByHospital(Input).subscribe((res) => {
      this.consultants = res.response;

      this.GetConsultantsData = [];
      this.consultants.forEach((a) => {
        this.GetConsultantsData.push({
          id: a.consultantId.toString(),
          text: a.consultantName,
        });
      });

    });

  }
showReport(){
  this.isLoading = true;
  let input = this.form.getRawValue();
  if(this.patientId>0 ){
    input.patientId=Number(this.patientId);
  }
  else{
    input.patientId==0;
  }
  
  if (typeof (input.FromDate) == "object") {
    let dobConverted = this.datepipe.transform(input.FromDate, 'dd-MM-yyyy');
    input.FromDate = dobConverted;
  }

  if (typeof (input.ToDate) == "object") {
    let dobConverted = this.datepipe.transform(input.ToDate, 'dd-MM-yyyy');
    input.ToDate = dobConverted;
  }

    input.ConsultantId = Number(input.ConsultantId);
  input.setType=Number(input.setType);
  input.branchId=Number(this.branchLoadId);

  this.ReportsService.GetBillSettlementReport(input).subscribe((res) => {
    this.isLoading = false;
    if (res.status == 200) {
      let response = res.response;
      this.src = response[0].reportPath;
      this.src = this.baseUrl + this.src;
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
      this.isPdfLoaded = true;
      this.clearForm();
    }
  });

}

SearchPatientDetail(patient: any) {
   
  this.GetPatientByRegNo();

}

GetPatientByRegNo() {
 this.ReportsService.SearchPatientByRegNo(this.form.get("regNo").value).subscribe((res) => {
   this.patients = res.response;
   this.patientId = this.patients[0].patientId;
   this.PatientInformation=this.patients[0]
   this.form.patchValue({ patientName: this.PatientInformation.patient});
    if (this.patients.length > 0) {
     if (this.patients[0].active == "c") {
       // this.toastr.warning("This Patient is blocked");
       // this.clearForm();
       this.form.patchValue({ regNo: this.patients[0].regNo });
       this.patients = [];
     }
   }
   //  else {
   //   this.toastr.warning("No patients found");
   // }
 });
}

onPaste(event){
 let clipboardData = event.clipboardData 
 let pastedText = clipboardData.getData('text');
 console.log(pastedText)
 this.SearchPatientDetail(pastedText)
}
clearForm() {
  this.form.patchValue({
    locationId: 0,
    ConsultantId: 0,
    BranchId: 0,
    patientName:'',
    setType: 0,
    regNo: '',
    patientId:0
  })
  this.patientId=0
}

}
