import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { globalvars } from "../../../shared/models/GlobalVars";
import { ColDef, GridOptions } from "ag-grid-community";
import { GeneralGridActionComponent } from "../../../shared/general-grid-action/general-grid-action.component";
import { Router } from "@angular/router";
import { ConfigurationService } from "../../client/services/configuration.service";
import { SharedService } from '../../../shared/shared.service';
import { TreatmentsService } from '../services/treatments.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ToastrService } from "ngx-toastr";
import { log } from "console";
import { DatePipe } from '@angular/common';
import { ClaimActionComponent } from "../../claim/claim-action/claim-action.component";
import { EMRPatientInfo } from "../../emr/interfaces/EMRPatientInfo";
import { EMRService } from '../../emr/services/emr.service';

@Component({
  selector: "app-physiothearapy-treatment-details",
  templateUrl: "./physiothearapy-treatment-details.component.html",
  styleUrls: ["./physiothearapy-treatment-details.component.scss"],
})
export class PhysiothearapyTreatmentDetailsComponent implements OnInit {
  public gridApi;
  public gridApimodality;
  public gridApiMovement;
  public gridApiTenderness;
  public gridApiSpasticity;
  public gridApiMovementRange;
  EMRPatientInfo: EMRPatientInfo;

  subscription: Subscription;
  globals: globalvars = new globalvars();
  physioidDemo:any
  modalityData: any = [];
  dailyMovementData: any = [];
  TendernessData: any = [];
  SpasticityData: any = [];
  MovementData: any = [];
  RightData: any = [];
  TendernesList:any[]=[];
  modalitycolumnDefs = [];
  dailyMovementcolumnDefs = [];
  TendernesscolumnDefs = [];
  SpasticitycolumnDefs = [];
  MovementcolumnDefs = [];
  RightcolumnDefs = [];
  bodypartsList: any[] = [];
  sensory:any[]=[];
  SpasticityList:any[]=[];
  MovementRangeList:any[]=[];
  modalityDefs: ColDef[] = [];
  dailyMovementDefs: ColDef[] = [];
  TendernessDefs: ColDef[] = [];
  SpasticityDefs: ColDef[] = [];
  MovementDefs: ColDef[] = [];
  RightDefs: ColDef[] = [];
  MovementList:any[]=[]; 
  status:any[]=[];
  statusBar: any;
  isLoading = false;
  ParamId = 0;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  // grid setup
  BranchesId: any;
  form: FormGroup; // form variable
  public submitted: boolean = false;
  result: any;
  PhysioId:any;
  private gridColumnApi;
  public rowSelection = true;
  public context;
  public noRowsTemplate;
  public loadingTemplate;
  public frameworkComponents={ actionsComponentRenderer: GeneralGridActionComponent};
  public movemtsData:any[]=[];
  public modalitydefaultColDef: ColDef;
  public dailyMovementdefaultColDef: ColDef;
  public TendernessdefaultColDef: ColDef;
  public SpasticitydefaultColDef: ColDef;
  public MovementdefaultColDef: ColDef;
  public RightdefaultColDef: ColDef;
  bodypartsDropdownSettings: IDropdownSettings = {};
  phydetID:any;
  consultationId:any;
  PainScale:number
  is_locked:any;
  constructor(private router: Router,
    private configurationService: ConfigurationService,
    private sharedservice: SharedService,
    private TreatmentsService: TreatmentsService,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private EMRService: EMRService,


    ) {
      this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
        if (this.sharedservice.checkglobals(glob)) {
          this.globals = glob;
          this.BranchesId = this.globals.HospitalId;
        }
      });
      if (this.router.getCurrentNavigation().extras.state) {
        this.phydetID = this.router.getCurrentNavigation().extras.state.phydetID;
        this.consultationId = this.router.getCurrentNavigation().extras.state.consultationId;
        this.PhysioId = this.router.getCurrentNavigation().extras.state.PhysioId;
        this.PainScale = this.router.getCurrentNavigation().extras.state.PainScale;

      }
  
    // this.frameworkComponents = {
    //   actionsComponentRenderer: GeneralGridActionComponent,
    // };
    this.context = { componentParent: this };
  }

  ngOnInit(): void {
    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();

    this.modalitycolumnDefs = [
      {
        headerName: "Select",
        field: "",
        resizable: true,
        sortable: true,
        filter: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        flex: 2,
      },
      {
        headerName: "Modality",
        field: "modality",
        minWidth: 20,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Start Date",
        field: "",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
        cellRenderer: "actionsComponentRenderer",
        cellRendererParams: { PageType: "treatmentDetailsphysio", PageField: "startDate" },

      },
      {
        headerName: "End Date",
        field: "",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
        cellRenderer: "actionsComponentRenderer",
        cellRendererParams: { PageType: "treatmentDetailsphysio", PageField: "endDate" },

      },

    ];
    this.modalitydefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    //
    this.dailyMovementdefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    //
    this.TendernessdefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    //
    this.MovementdefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    this.RightdefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.noRowsTemplate = `"<span">no rows to show</span>"`;

    this.SpasticitycolumnDefs = [
      {
        headerName: "Spasticity",
        field: "",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
      {
        headerName: "Status",
        field: "",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
        editable: false,
      },
    ];
    this.SpasticitydefaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.form = new FormGroup({
      PhysioNo: new FormControl(''),
      PhysioId: new FormControl(this.PhysioId),

      PhysioDetId: new FormControl(this.phydetID),
      DayNo: new FormControl(0),
      TreatDate: new FormControl(''),
      PainImpr: new FormControl(this.PainScale),
      SensoryDeficit: new FormControl(0),
      Fracture: new FormControl(''),
      Mobilization: new FormControl(''),
      PhyDetBodyPart: new FormControl([]),
      PhyDetModality:new FormControl([]),
      MovementDetails: new FormArray([]),
      SpasticityDetails: new FormArray([]),
      TendernessDetails: new FormArray([]),
      MovementRangeDetails: new FormArray([]),
      PhyDetExam:new FormArray([])
    });
    this.PhyDetGet();
    this.GetBodyparts();
    this.GetSensory();
    document.getElementById("myDiv").click();

    // this.GetModality();
    this.GetTMovementsList();
    this.GetTendernesList();
    this.GetSpasticityList();
    this.GetMovementsRange();
    this.GetStatus();
    this.addMapFormGroupMovement();
    this.newdataTenderness();
    this.addMapFormGroupMovementRange();
    this.addMapFormGroupSpasticity();
    this.getsummary();
    this.bodypartsDropdownSettings = {
      singleSelection: false,
      idField: "bodyId",
      textField: "bodyDesc",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,
    };

  }

  onGridReadymodality(params) {
    this.gridApimodality = params.api;
  }


  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);

    this.router.navigate(["/physiothearapy-treatment"], );
  }

  onFilterTextBoxChangedmodality() {
    this.gridApimodality.setQuickFilter(
      (document.getElementById("filter-text-box") as HTMLInputElement).value
    );
  }

  GetBodyparts() {

    let input = {
      bodyId: 0,
      branchId: this.globals.HospitalId,
      ShowAll: 1,
    };
    this.configurationService.GetBodyparts(input).subscribe((res) => {
      this.bodypartsList = res.response;
    });
  }
  GetSensory() {
    let input = {
      id: 0,
    };
    this.TreatmentsService.GetSensory(input).subscribe((res) => {
      this.sensory = res.response;
    });
  }
  GetModality(){
    let input = {
      ShowAll: 1,
      Id: 0,
      BranchId: this.globals.HospitalId,
      
    };
    this.configurationService.GetModality(input).subscribe((res) => {
      this.modalityData = res.response;
    });
  }
  GetTMovementsList() {
    let input = {
      MasterFieldType: "Movement",
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId,
    };
    this.configurationService.GetTMovementsList(input).subscribe((res) => {
      this.MovementList = res.response;
    });
  }
  GetTendernesList() {
    let input = {
      MasterFieldType: "Tenderness",
      Id: 0,
      ShowAll: 1,
      BranchId: this.globals.HospitalId,
    };
    this.configurationService.GetTendernesList(input).subscribe((res) => {
      this.TendernesList = res.response;
    });
  }
  GetSpasticityList() {
    let input = {
      ShowAll: 1,
      spasticityId: 0,
      branchId: this.globals.HospitalId,
    };
    this.configurationService.GetSpasticityList(input).subscribe((res) => {
      this.SpasticityList = res.response;
    });
  }
  GetMovementsRange() {
    let input = {
      ShowAll: 1,
      movementRangeId: 0,
      branchId: this.globals.HospitalId,
    };
    this.configurationService.GetMovementsRange(input).subscribe((res) => {
      this.MovementRangeList = res.response;
    });
  }
  GetStatus() {
    let input = {
      StatusGroupId: 1,
    };
    this.TreatmentsService.GetStatus(input).subscribe((res) => {
      this.status = res.response;
    });
  }
////////movements formarray //////////
  newdataMovement() {

    this.addMapFormGroupMovement();

  }
  public addMapFormGroupMovement(data?: any[]) {
    const rangedatas = this.form.get("MovementDetails") as FormArray;
  
      rangedatas.push(this.createListGroupMovement(data));
  }
  private createListGroupMovement(data?): FormGroup {
    return new FormGroup({
      movement: new FormControl(data ? data.movementId : 0),
      movementStatus: new FormControl(data ? data.statusId : 0),

    });
  }

/////////tenderness formarray//////////////
  newdataTenderness() {

    this.addMapFormGroupTenderness();

  }
  public addMapFormGroupTenderness(data?: any[]) {
    const rangedatas = this.form.get("TendernessDetails") as FormArray;
  
      rangedatas.push(this.createListGroupTenderness(data));
  }
  private createListGroupTenderness(data?): FormGroup {
    return new FormGroup({
      Tenderness: new FormControl(data ? data.tendernId : 0),
      TendernessStatus: new FormControl(data ? data.statusId : 0),

    });
  }
  //////////spasticity formarray///////////
  newdataSpasticity() {

    this.addMapFormGroupSpasticity();

  }
  public addMapFormGroupSpasticity(data?: any[]) {
    const rangedatas = this.form.get("SpasticityDetails") as FormArray;
  
      rangedatas.push(this.createListGroupSpasticity(data));
  }
  private createListGroupSpasticity(data?): FormGroup {
    return new FormGroup({
      Spasticity: new FormControl(data ? data.spasticityId : 0),
      SpasticityStatus: new FormControl(data ? data.statusId : 0),

    });
  }
  ////////////movements range formarray///////////
  newdataMovementRange() {

    this.addMapFormGroupMovementRange();

  }
  public addMapFormGroupMovementRange(data?: any[]) {
    const rangedatas = this.form.get("MovementRangeDetails") as FormArray;
  
      rangedatas.push(this.createListGroupMovementRange(data));
  }
  private createListGroupMovementRange(data?): FormGroup {
    return new FormGroup({
      MovementRange: new FormControl(data ? data.movRangeId : 0),
      MovementRangeStatus: new FormControl(data ? data.statusId : 0),

    });
  }

////////////////body parts formarray/////
// newdatabodyparts() {
//   this.movemtsData.forEach(item => {
//     if (item.movementsListData && item.movementsListData.length > 0) {
//       item.movementsListData.forEach(movement => {
//         if (movement) {
//           this.addMapFormGroupbodyparts(movement);
//         }
//       });
//     }
//   });
// }
// public addMapFormGroupbodyparts(data?: any) {
//   const rangedatas = this.form.get("PhyDetExam") as FormArray;
//   rangedatas.push(this.createListGroupbobyparts(data));
// }
// private createListGroupbobyparts(data?): FormGroup {
//   return new FormGroup({
//     name: new FormControl(data ? data.descriptionData : ''),
//     PemId: new FormControl(data ? data.id : 0),
//     bodyId: new FormControl(data ? data.bodyId : 0),
//     value: new FormControl(data ? data.value : 0),
//     MeasurementR: new FormControl(data ? data.measurementR : 0),
//     MmtR: new FormControl( data ? data.mmtR : 0),
//     MeasurementL: new FormControl(data ? data.measurementL : 0),
//     MmtL: new FormControl(data ? data.mmtL : 0),
//     PcntOfNormalL: new FormControl(data ? data.pcntOfNormalL : 0),
//     PcntOfNormalR: new FormControl(data ? data.pcntOfNormalR : 0),

//   });
// }


  save(){
    if(this.is_locked==0){
    this.submitted = true;

    if (this.form && this.form.valid) {
      console.log( this.movemtsData);
      
      var formdata = this.form.getRawValue();
      let modalityList:any[]=[];
      this.gridApimodality.getSelectedRows().map(items => {
        // return items.modalityId
        if (typeof (items.endDate) == "object") {
         var endData = this.datepipe.transform(items.endDate, 'dd-MM-yyyy');

        }
        else {
          endData = items.endDate.replaceAll('/', '-');
        }
        if (typeof (items.startDate) == "object") {
          var startDAte = this.datepipe.transform(items.startDate, 'dd-MM-yyyy');
 
         }
         else {
          startDAte = items.startDate.replaceAll('/', '-');
         }
 
        let item={
          endDate:endData?endData:"",
          
          startDate:startDAte?startDAte:"",
          isChecked:true,
          modalityId:items.modalityId
        }
        modalityList.push(item);

      });
      formdata.PhysioNo=this.physioidDemo;
      formdata.SensoryDeficit=Number(formdata.SensoryDeficit);
      formdata.PhyDetModality=modalityList;
      formdata.MovementDetails = formdata.MovementDetails.map((item) => {

        return {
          ...item,
          movement:Number(item.movement),
          movementStatus:Number(item.movementStatus),
    
        }
      
      })
      formdata.TendernessDetails = formdata.TendernessDetails.map((item) => {

        return {
          ...item,
          Tenderness:Number(item.Tenderness),
          TendernessStatus:Number(item.TendernessStatus),
    
        }
      
      })
      formdata.SpasticityDetails = formdata.SpasticityDetails.map((item) => {
          return {
            ...item,
            Spasticity:Number(item.Spasticity),
            SpasticityStatus:Number(item.SpasticityStatus),
      
          }
  
        
      })
      formdata.MovementRangeDetails = formdata.MovementRangeDetails.map((item) => {
          return {
            ...item,
            MovementRange:Number(item.MovementRange),
            MovementRangeStatus:Number(item.MovementRangeStatus),
      
          }
  
        
      })
      formdata.PhyDetExam= this.movemtsData.map(item => {
        return {
          ...item,
          movementsListData: item.movementsListData.map(data => {
            return {
              ...data,
              value: data.value,
              measurementR: Number(data.measurementR),
              pcntOfNormalR: data.pcntOfNormalR==null?0:Number(data.pcntOfNormalR),
              mmtR: Number(data.mmtR),
              measurementL: Number(data.measurementL),
              pcntOfNormalL: Number(data.pcntOfNormalL),
              mmtL: Number(data.mmtL),
              id: Number(data.id),
              bodyId: Number(data.bodyId)
            }
          })
        }
      });     
      this.isLoading = true;
      this.TreatmentsService.PhyDetSaveUpdate(formdata).subscribe((res) => {
      this.result = res;
        if (this.result.status == 200 && this.result.message == "Success") {
            this.isLoading = false;
            this.toastr.success(" Data Saved Successfully");
        } else {
          this.isLoading = false;
          this.toastr.error("Failed : " + this.result.message);
        }
      });
    }
  }else{
    this.toastr.warning("This Treatment is Locked");

  }
  
  }
  onFirstDataRendered(params: any) {
    params.api.forEachNode((node) =>
      node.setSelected(!!node.data && node.data.isChecked)
    );
  }
  RemoveFormArray(index, data?) {
    var Value = new FormArray([]);
    Value = this.form.get(data) as FormArray;
    Value.removeAt(index);
  }
  listmoventsData(data){
    this.movemtsData=[];
    let selectesBodyParts=[];
    selectesBodyParts=data;
    // selectesBodyParts=this.form.get("PhyDetBodyPart").value;
    selectesBodyParts.forEach((a) => {
      let data = this.bodypartsList.find(item => item.bodyId ===a.bodyId);
      let item2 = this.movemtsData.filter(x => x.bodyId == data.bodyId)[0];
      if (item2 == undefined) {
      this.movemtsData.push(data);
      }
    });
    console.log(this.movemtsData);

    // this.newdatabodyparts();
  }
  onDeSelectAll(){
    this.movemtsData=[];
    // this.newdatabodyparts();

  }
  removelistmoventsData(data){
    const index = this.movemtsData.findIndex(item => item.bodyId === data.bodyId);
    if (index !== -1) {
      this.movemtsData.splice(index, 1);
      // this.newdatabodyparts();

    }
  }
  listmoventsigleData(data){
    let list = this.bodypartsList.find(item => item.bodyId ===data.bodyId);
    this.movemtsData.push(list);
    // this.newdatabodyparts();

  }
  
  PhyDetGet(){
    

    let input={
      PhysioDetId:this.phydetID,
      BranchId : this.globals.HospitalId,

    }
    // setTimeout(() => {
      this.TreatmentsService.PhyDetGet(input).subscribe((res) => {
        this.result = res.response;
        // this.form.patchValue( this.result );
        this.form.controls['PhysioNo'].setValue( this.result[0].physioNo);
        this.form.controls['DayNo'].setValue( this.result[0].dayNo);
        this.form.controls['TreatDate'].setValue( this.result[0].treatmentDate);
        this.form.controls['PainImpr'].setValue( this.result[0].painImprovement);
        this.form.controls['SensoryDeficit'].setValue( this.result[0].sensoryDeficit);
        this.form.controls['Fracture'].setValue( this.result[0].fracture);
        this.form.controls['Mobilization'].setValue( this.result[0].mobilization);
        this.form.controls['PhyDetBodyPart'].setValue( this.result[0].phyDetBodyPart);
        this.movemtsData=this.result[0].phyDetExam;
        // this.newdatabodyparts();
        // this.listmoventsData()
  
        this.physioidDemo=this.result[0].physioNo;
        this.modalityData=this.result[0].phyDetModality;
        document.getElementById("myDiv").click();
        if( this.result[0].phyDetMovRange!=null){
          (this.form.get('MovementRangeDetails') as FormArray).clear();

          this.result[0].phyDetMovRange.forEach(element => {
  
            this.addMapFormGroupMovementRange(element);
     
           });
   
        }
        if( this.result[0].phyDetDailyMovAn!=null ){
          (this.form.get('MovementDetails') as FormArray).clear();

          this.result[0].phyDetDailyMovAn.forEach(element => {
  
            this.addMapFormGroupMovement(element);
    
          });
  
        }
        if( this.result[0].phyDetTendern!=null){
          (this.form.get('TendernessDetails') as FormArray).clear();

          this.result[0].phyDetTendern.forEach(element => {
  
            this.addMapFormGroupTenderness(element);
    
          });
  
        }
        if(this.result[0].phyDetSpasticity!=null){
          (this.form.get('SpasticityDetails') as FormArray).clear();

          this.result[0].phyDetSpasticity.forEach(element => {
  
            this.addMapFormGroupSpasticity(element);
    
          });
  
        }
        // this.result[0].phyDetExam[0].movementDetails.forEach(element => {
  
        //   this.createListGroupbobyparts(element);
  
        // });
  
      });
  
    // }, 1000); 


  }
  movementdetailsvalidation(event:Event,i){
    var id=  (event.target as HTMLSelectElement).value;

    var data=this.form.get("MovementDetails") as FormArray;
    let movementData=data.value;
    
    const count = movementData.filter(item => item.movement == id).length;    
    if (count>1) {
      (event.target as HTMLSelectElement).value ="";
      this.toastr.warning("Movement Alredy Exist");
      (this.form.get('MovementDetails') as FormArray).at(i).patchValue({movement:""}); 

    }      
  }
  TendernessDetailsvalidation(event:Event,i){
    var id=  (event.target as HTMLSelectElement).value;

    var data=this.form.get("TendernessDetails") as FormArray;
    let  TendernessData=data.value;
    
    const count = TendernessData.filter(item => item.Tenderness == id).length;    
    if (count>1) {
      (event.target as HTMLSelectElement).value = "";
      this.toastr.warning("Tenderness Alredy Exist");
      (this.form.get('TendernessDetails') as FormArray).at(i).patchValue({Tenderness:""}); 

    }      
  }
  SpasticityDetailsvalidation(event:Event,i){
    var id=  (event.target as HTMLSelectElement).value;

    var data=this.form.get("SpasticityDetails") as FormArray;
    let SpasticityData=data.value;
    
    const count = SpasticityData.filter(item => item.Spasticity == id).length;    
    if (count>1) {
      (event.target as HTMLSelectElement).value = "";
      this.toastr.warning("Spasticity Alredy Exist");
      (this.form.get('SpasticityDetails') as FormArray).at(i).patchValue({Spasticity:""}); 

    }      
  }
  MovementRangeDetailsvalidation(event:Event,i){
    var id=  (event.target as HTMLSelectElement).value;

    var data=this.form.get("MovementRangeDetails") as FormArray;
    let MovementRangeData=data.value;
    
    const count = MovementRangeData.filter(item => item.MovementRange == id).length;    
    if (count>1) {
      (event.target as HTMLSelectElement).value = "";
      this.toastr.warning("MovementRange Alredy Exist");
      (this.form.get('MovementRangeDetails') as FormArray).at(i).patchValue({MovementRange:""}); 

    }      
  }
  calculateNormalValueR(index,number,value,id){
 if(number!=0 && value!=0){
  var percentageValue=(number*100)/value;
  percentageValue =Number(percentageValue.toFixed(2)) ; 

  const targetElement = this.movemtsData.find(element => element.bodyId === id);
if (targetElement) {
    // Update the value of measurementL
    targetElement.movementsListData[index].pcntOfNormalR = percentageValue;
}
 }

  }
  calculateNormalValueL(index,number,value,id){

    if(number!=0 && value!=0){
      var percentageValue=(number*100)/value;
      console.log(percentageValue);
      
      percentageValue =Number(percentageValue.toFixed(2)) ; 
      const targetElement = this.movemtsData.find(element => element.bodyId === id);
    if (targetElement) {
        targetElement.movementsListData[index].pcntOfNormalL = percentageValue;
    }
     }
    
   }
   getsummary() {
    let input = {
      ConsultationId: this.EMRPatientInfo.ConsultationId,
    };
    this.TreatmentsService.GetSummary(input).subscribe((res) => {
      let getSummaryData = res.response[0];
     this.is_locked = getSummaryData["isLocked"];
    });
  }

}
