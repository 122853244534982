import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BillingSettlementRoutingModule } from './billing-settlement-routing.module';
import { BillingSettlementComponent } from './billing-settlement.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NewBillComponent } from './new-bill/new-bill.component';
import { PendingBillComponent } from './pending-bill/pending-bill.component';
import { BillDetailsComponent } from './bill-details/bill-details.component';
import { ExternalBillComponent } from './external-bill/external-bill.component';
import { ManageCreditClaimsComponent } from './manage-credit-claims/manage-credit-claims.component';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { ReceiptsListComponent } from './receipts-list/receipts-list.component';
import { UnbilledItemComponent } from './unbilled-item/unbilled-item.component';
import { ManageShiftComponent } from './manage-shift/manage-shift.component';
import { PersonRegisterComponent } from './person-register/person-register.component';
import { TreeviewModule } from 'ngx-treeview';
import { AgGridModule } from 'ag-grid-angular';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SharedModule } from '../../shared/models/shared.module';
import { BillingGeneralActionComponent } from './billing-general-action/billing-general-action.component';
import { BillingQuickMenuComponent } from './billing-quick-menu/billing-quick-menu.component';
import { NewBillingQuickModalComponent } from './new-billing-quick-modal/new-billing-quick-modal.component';
 import { OpenCreditModalComponent } from './open-credit-modal/open-credit-modal.component';
import { TodaysBillingComponent } from './todays-billing/todays-billing.component';
import { ManageCreditComponent } from './manage-credit/manage-credit.component';
import { NewBillsComponent } from './new-bills/new-bills.component';
import { ManageBillComponent } from './manage-bill/manage-bill.component';
import { SponsorDataComponent } from './modals/sponsor-data/sponsor-data.component';
import { ManageLocComponent } from './modals/manage-loc/manage-loc.component';
import { PendingItemsComponent } from './modals/pending-items/pending-items.component';
import { ManageClaimComponent } from './manage-claim/manage-claim.component';
import { ClaimActionBtnComponent } from './claim-action-btn/claim-action-btn.component';
import { AddEditClaimComponent } from './add-edit-claim/add-edit-claim.component';
import { ManageRemittanceComponent } from './manage-remittance/manage-remittance.component';
import { PriorRequestComponent } from './prior-request/prior-request.component';
import { DownloadClaimComponent } from './download-claim/download-claim.component';
import { EClaimSubmitedComponent } from './eclaim-submited/eclaim-submited.component';
import { CancelBillModalComponent } from './modals/cancel-bill-modal/cancel-bill-modal.component';
import { CancelSettlmentModalComponent } from './modals/cancel-settlment-modal/cancel-settlment-modal.component';
import { SettlementModalComponent } from './modals/settlement-modal/settlement-modal.component';
import { ClaimDashboardComponent } from './claim-dashboard/claim-dashboard.component';
import { CreditItemsModalComponent } from './modals/credit-items-modal/credit-items-modal.component';
import { WebCameraModalComponent } from './modals/web-camera-modal/web-camera-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { FilePreviewModalComponent } from './modals/file-preview-modal/file-preview-modal.component';
import { NewRecieptModalComponent } from './modals/new-reciept-modal/new-reciept-modal.component';
import { NewPaymentModalComponent } from './modals/new-payment-modal/new-payment-modal.component';
import { TransactionListModalComponent } from './modals/transaction-list-modal/transaction-list-modal.component';
import { OpenshiftComponent } from './manage-shift/openshift/openshift.component';
import { CloseshiftComponent } from './manage-shift/closeshift/closeshift.component';
import { ChangeConsultationCreditModalComponent } from './modals/change-consultation-credit-modal/change-consultation-credit-modal.component';
import { ScanInsuranceCardModalComponent } from './modals/scan-insurance-card-modal/scan-insurance-card-modal.component';
import { ServiceValidityModalComponent } from './manage-bill/service-validity-modal/service-validity-modal.component';
import { ResettlementConfirmModalComponent } from './manage-bill/resettlement-confirm-modal/resettlement-confirm-modal.component';
import { ResettleAdvanceModalComponent } from './manage-bill/resettle-advance-modal/resettle-advance-modal.component';



@NgModule({
  declarations: [
    BillingSettlementComponent,
    DashboardComponent,
    NewBillComponent,
    PendingBillComponent,
    BillDetailsComponent,
    ExternalBillComponent,
    ManageCreditClaimsComponent,
    PaymentListComponent,
    ReceiptsListComponent,
    UnbilledItemComponent,
    ManageShiftComponent,
    PersonRegisterComponent,
    BillingGeneralActionComponent,
    BillingQuickMenuComponent,
    NewBillingQuickModalComponent,
   TodaysBillingComponent,
   OpenCreditModalComponent,
   ManageCreditComponent,
   NewBillsComponent,
   ManageBillComponent,
   SponsorDataComponent,
   ManageLocComponent,
   PendingItemsComponent,
   ManageClaimComponent,
   ClaimActionBtnComponent,
   AddEditClaimComponent,
   ManageRemittanceComponent,
   PriorRequestComponent,
   DownloadClaimComponent,
   EClaimSubmitedComponent,
   CancelBillModalComponent,
   CancelSettlmentModalComponent,
   SettlementModalComponent,
   ClaimDashboardComponent,
   CreditItemsModalComponent,
   WebCameraModalComponent,
   ConfirmModalComponent,
   FilePreviewModalComponent,
   NewRecieptModalComponent,
   NewPaymentModalComponent,
   TransactionListModalComponent,
   OpenshiftComponent,
   CloseshiftComponent,
   ChangeConsultationCreditModalComponent,
   ScanInsuranceCardModalComponent,
   ServiceValidityModalComponent,
   ResettlementConfirmModalComponent,
   ResettleAdvanceModalComponent
  ],
  imports: [
    CommonModule,
    BillingSettlementRoutingModule,
    SharedModule,
    TreeviewModule.forRoot(),
    AgGridModule.withComponents(),
    TypeaheadModule.forRoot(),
    AccordionModule.forRoot()

  ]
})
export class BillingSettlementModule { }
