import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';

import { Response } from '../../../_Models/Response';
import { UserProfile } from '../interfaces/UserProfile';
import { ErrorHandleService } from './error-handle.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  private baseUrl = "";
  constructor(
    private configurationManager: ConfigurationManager,
    private http: HttpClient,
    private errorHandle: ErrorHandleService
  ) {
    this.baseUrl = (this.configurationManager as any).baseUrl;
  }


  AddConsentForm(formData) {
    var url = "api/MasterData/InsertUpdateConsent";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, formData)
  }

  GetAllConsents() {
    var url = "api/MasterData/GetConsent/0";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,
      {

      }
    ).pipe(
      retry(1),
      catchError(this.errorHandle.handleError)
    );
  }

  DeleteConsents(id: Number) {
    var url = "api/MasterData/DeleteConsent/" + id;
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`,
      { "id": id }).pipe(
        retry(1),
        catchError(this.errorHandle.handleError)
      );
  }

  GetPatientConsent(BranchId,ShowAll) {
    var url = "api/MasterData/GetPatientConsent";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      "ContentId": 0,
      "ShowAll": Number(ShowAll),
      "BranchId": BranchId
    });
  }
  GetSponserConsent(BranchId,SponsorId) {
    var url = "api/MasterData/GetSponserConsent";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      "ContentId": 0,
      "ShowAll": 1,
      "BranchId": BranchId,
      "SponsorId":SponsorId
    });
  }
  GetSponsors(BranchId) {
    var url = "api/MasterData/GetSponsor";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {

      "SponsorId": 0,
      "ShowAll": 1,
      "BranchId": BranchId


    });
  }

  GetInformedConsent(BranchId) {
    var url = "api/MasterData/GetInformedConsent";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
      "ContentId": 0,
      "ShowAll": 1,
      "BranchId": BranchId
    });
  }

  GetConsentGroup() {
    var url = "api/MasterData/GetConsentGroup";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`, {
    });
  }

  GetSignedConsentPdf(payload: any): Observable<Response<any>>{
    var url = "api/Report/GetSignedConsentPdf";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  GetConsentWithPatDetails(input){
    var url = "api/Report/GetConsentWithPatDetails";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input)
  }

  GetNabithOptInOptOutPDF(input){
    var url = "api/Report/GetNabithOptInOptOutPDF";
    return this.http.post<Response<any[]>>(`${this.baseUrl}${url}`,input)
  }

  GetSignedNabidhConsentPdf(payload: any): Observable<Response<any>>{
    var url = "api/Report/GetSignedNabidhConsentPdf";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

  UpdateHIConsentDetails(payload: any): Observable<Response<any>>{
    var url = "api/MasterData/UpdateHIConsentDetails";
    return this.http.post<Response<any>>(`${this.baseUrl}${url}`, payload)
  }

}

