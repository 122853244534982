import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConsoleLogger } from '@generic-ui/ngx-grid/common/cdk/logger/console.logger';

@Component({
  selector: 'ngx-common-validation',
  templateUrl: './common-validation.component.html',
  styleUrls: ['./common-validation.component.scss']
})
export class CommonValidationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  test()
  {
    alert("Hai");
  }

omit_special_char(event)
{
   var k;
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   // 32 = sapce
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8  || (k >= 48 && k <= 57));
}
basic_char_only(event){
  var k;
  k = event.charCode;  //         k = event.keyCode;  (Both can be used)
  // 32 = sapce
  return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8  || (k >= 48 && k <= 57) || (k==32));
}

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  Onlycharecters(event)
  {
    var regex = new RegExp("^[A-Za-z \b]+$");
    var key = String.fromCharCode(!event.charCode ? event.which: event.charCode);
      if (!regex.test(key))
      {
      //	event.preventDefault();
        return false;
      }
  }

 onlyAddress(event)
{
	var regex = new RegExp("^[0-9a-zA-Z-. /\b]+$");
	var key = String.fromCharCode(!event.charCode ? event.which: event.charCode);
		if (!regex.test(key))
		{
			return false;
		}
}


 onlyFilepath(event)
{

	var regex = new RegExp("^[A-Z0-9a-z@#\._]+$");
	var key = String.fromCharCode(!event.charCode ? event.which: event.charCode);
		if (!regex.test(key))
		{

			return false;
		}

}

  onlyDotnumbers(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 46 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  omit_empty_space(event)
{
   var k;
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   // 32 = sapce
   if(k==32){
     return false;
   }
   else{
     return true;
   }

  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}


trim (el) {
  console.log(el);
  el.value = el.value.
     replace (/(^\s*)|(\s*$)/gi, ""). // removes leading and trailing spaces
     replace (/[ ]{2,}/gi," ").       // replaces multiple spaces with one space
     replace (/\n +/,"\n");           // Removes spaces after newlines
  return;
}​

}
