import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EMRPatientInfo } from '../../../interfaces/EMRPatientInfo';
import { EMRService } from '../../../services/emr.service';
import { ViewServiceOrdersModalComponent } from '../view-service-orders-modal/view-service-orders-modal.component';

@Component({
  selector: 'app-prev-sevice-ordes-modal',
  templateUrl: './prev-sevice-ordes-modal.component.html',
  styleUrls: ['./prev-sevice-ordes-modal.component.scss']
})
export class PrevSeviceOrdesModalComponent implements OnInit {

  @Input() EmrPatientInfo: EMRPatientInfo;

  public serviceOrderList: any[] = [];

  constructor(private activeModalRef: BsModalRef,
              private EmrService: EMRService,
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.GetPrevServiceItems();
  }

  close(){
    this.activeModalRef.hide();
  }

  GetPrevServiceItems() {
    let input = {
      "VisitId": this.EmrPatientInfo.VisitId,
      "PatientId": this.EmrPatientInfo.PatientId,
      "ShowAll": 0
    };

    this.EmrService.GetServiceItemsEMR(input).subscribe(
      (res) => {
        if (res.status == 200) {
          this.serviceOrderList = res.response;

        }
      });
  }

  viewDetails(data: any){
    let initialState = {
      patientId: this.EmrPatientInfo.PatientId,
      serviceItems: data
    }
    let historyConfig: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable'
    }
    const modalRef= this.modalService.show(ViewServiceOrdersModalComponent,Object.assign(
      {class: "modal-dialog-centered "}, 
      historyConfig, 
      { class: 'modal-lg', initialState }) );
  }

}
