import { Component,OnInit,ViewChild, ElementRef,TemplateRef} from "@angular/core";
import { WebcamImage } from "ngx-webcam";
import { Subject } from "rxjs";
import { FormBuilder,FormControl,FormGroup,Validators,} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HospitalsService } from "../../../client/services/hospitals.service";
import { SharedService } from "../../../../shared/shared.service";
import { globalvars } from "../../../../shared/models/GlobalVars";
import { Subscription } from "rxjs";
import { TitleList } from "../../../client/interfaces/TitlteList";
import { PatientService } from "../../../client/services/patient.service";
import { Select2OptionData } from "ng-select2";
import { AppointmentsService } from "../../../client/services/appointments.service";
import SignaturePad from "signature_pad";
import { ConfigurationService } from "../../../client/services/configuration.service";
import { CommonValidationComponent } from "../../../../shared/common-validation/common-validation.component";
import { DatePipe } from "@angular/common";
import { GenderList } from "../../../client/interfaces/GenderList";
import { RegDocLocation } from "../../../client/interfaces/RegDocLocation";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { UserpermissionService } from "../../../user-permission/userpermission.service";
import { log } from "console";
//import { RegAddress } from '../../../interfaces/consultantDetails';
@Component({
  selector: "app-addconsultantComponent",
  templateUrl: "./addconsultant.component.html",
  styleUrls: ["./addconsultant.component.scss"],
})
export class addconsultantComponent implements OnInit {
  signaturePad: SignaturePad;
  @ViewChild("canvas") canvasEl: ElementRef;
  value4: number = 0;
  age;
  showAge;
  birthMonth;
  opened = false;
  sticky: boolean = true;
  displayBasic: boolean;
  displayBasicdragdrop: boolean;
  displayBasicFetchCard: boolean;
  uploadedFiles: any[] = [];
  public showWebcam = false;
  ConsultantForm: FormGroup;
  ConsultantUserForm: FormGroup;
  status: any;
  result: any;
  selectedCities1: any[];
  dropdownList = [];
  dropdownSettings: any = {};
  dropdownSettings2: any = {};
  cities: any[];
  departments: any;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;
  titleList: TitleList[] = [];
  NationalityData: Array<Select2OptionData> = [];
  GroupnameData: Array<Select2OptionData> = [];
  countryList: any;
  CountryListData: any[];
  state1List: any;
  StateListData: any[];
  state2List: any;
  StateListData2: any[];
  CommissionRulesData: any;
  GetLedgerHead: any;
  GetServiceItemData: any[];
  maxDate: Date;
  genderList: GenderList[] = [];
  documentList: RegDocLocation[] = [];
  isShowUserDetails: boolean = true;
  isShowUserDetailsShowing: boolean = false;
  // public RegAddress: RegAddress[] = [];
  // public RegAddressObj: RegAddress = <RegAddress>{};
  ConsultantDetails: any;
  Groupname: any;
  isLoading: boolean = false;

  Locations: any;
  regDocLocation: RegDocLocation[] = [];
  public documentFile: any;
  isLoding=false;
  public webcamImage: WebcamImage = null;
  public validator = new CommonValidationComponent();
  private trigger: Subject<void> = new Subject<void>();
  ItemSelectId: any;
  GetConsultantById: any;
  ConsultantId: any;
  CountryId: any;
  userdata: any;
  documentFile2: any;
  SignatureSave: any;
  DatOfJoining: any;
  user_id: string;
  LocationIds: any;
  modalRef: BsModalRef;

  @ViewChild("template") modaltemp: any;


  public submitted: boolean = false;
  public submitted1: boolean = false;
  GetUsersData: any;
  showConsultantLogin = null;
  SignaturePhoto: any;
  selectedItems: any[] = [];
  Commission: boolean;
  disabledField: any;
  isDropdownDisabled = false;
  IsNewUser: any;
  ConsultantUserId: any;
  //DateOfJoin: string;

  public strongPassword: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private hospitalsService: HospitalsService,
    private sharedservice: SharedService,
    private patientService: PatientService,
    public datepipe: DatePipe,
    private AppointmentsService: AppointmentsService,
    private ConfigurationService: ConfigurationService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private Userservice: UserpermissionService
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        //this.BranchId=this.form.get('branchId')?.value;
        this.BranchesId = this.globals.HospitalId;
      }
    });
  }

  ngOnInit(): void {
    this.user_id = localStorage.getItem("user_id");
    this.activatedRoute.params.subscribe((params) => {
      this.ConsultantId = Number(params.id);
    });
    this.ConsultantForm = this.formBuilder.group({
      ConsultantId: new FormControl(this.ConsultantId ? this.ConsultantId : 0),
      ConsultantCode: new FormControl("", [Validators.required]),
      SpecialityCode: new FormControl(""),
      DeptId: new FormControl("", [Validators.required]),
      CRegNo: new FormControl(""),
      DateOfJoin: new FormControl("", [Validators.required]),
      Designation: new FormControl("", [Validators.required]),
      Specialisation: new FormControl("", [Validators.required]),
      RoomNo: new FormControl(""),
      SortOrder: new FormControl(0),
      IsAprovedAuthority: new FormControl(false),
      Title: new FormControl("", [Validators.required]),
      FirstName: new FormControl("", [Validators.required]),
      MiddleName: new FormControl(""),
      LastName: new FormControl("", [Validators.required]),
      NationalityId: new FormControl("", [Validators.required]),
      Gender: new FormControl("", [Validators.required]),
      DateOfBirth: new FormControl("", [Validators.required]),
      Age: new FormControl(""),
      Month: new FormControl(""),
      Qualification: new FormControl(""),
      Address1: new FormControl(""),
      Address2: new FormControl(""),
      PlacePO: new FormControl(""),
      City: new FormControl(""),
      PIN: new FormControl(""),
      Street: new FormControl(""),
      CountryId: new FormControl(""),
      State: new FormControl(""),
      Mobile: new FormControl(""),
      ResPhone: new FormControl(""),
      OffPhone: new FormControl(""),
      Email: new FormControl(""),
      Fax: new FormControl(""),
      TimeSlice: new FormControl("", [Validators.required,Validators.max(240)]),
      MaxPatients: new FormControl(1),
      //ItemName:new FormControl([]),
      AllowCommission: new FormControl(true),
      AllowConsultantLogin: new FormControl(false),
      DeptOverrule: new FormControl(true),
      DeptWiseConsultation: new FormControl(true),
      ExternalConsultant: new FormControl(false),
      ConsultantMedicationReport: new FormControl(true),
      AppType: new FormControl(0),
      Active: new FormControl(0),
      BlockReason: new FormControl(""),
      ItemIdList: new FormControl("", [Validators.required]),
      UserId: new FormControl(this.user_id?this.user_id:0),
      SignatureLoc: new FormControl(""),
      // LocationIds: new FormControl(""),
      BranchId: new FormControl(Number(this.globals.HospitalId)),
      ConsultantLedger: new FormControl("", [Validators.required]),
      CommissionId: new FormControl(0),
      // DrugRefType:new FormControl(),
      DrugRefType: Number("0" ? "0" : new FormControl("")),
      IsDeleted: 0,
      IsDisplayed: 1,
    });
    this.ConsultantUserForm = this.formBuilder.group({
      UserName: new FormControl("", [Validators.required]),
      UserPassword: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          "(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}"
        ),
      ]),
      BranchIds: new FormControl(""),
      GroupIds: new FormControl("", [Validators.required]),
      LocationIds: new FormControl("", [Validators.required]),
      active: true,
      //ddlUser: new FormControl(this.IsNewUser?this.IsNewUser:0),
      blockReason: new FormControl(""),
      ConsultantLogin: new FormControl(""),
      ConsultantUserId: new FormControl(""),
    });

    this.maxDate = new Date();

    this.dropdownSettings = {
      singleSelection: false,
      idField: "itemId",
      textField: "itemName",
      // selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      idField: "locationId",
      textField: "locationName",
      // selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };

    this.GetDepartments();
    this.GetTitle();
    this.LoadCountry();
    this.GetCommissionRules();
    this.GetLedgerHeadData();
    this.GetServiceItem();
    this.GetGender();
    this.GetUsers();
    if (this.ConsultantId) {
      this.fetchDetails(this.ConsultantId);
      this.isShowUserDetails = false;
    }
    this.fetchId();
  }

  //pagetitle to be displayed at the header
  Activetab(pagetitle) {
    sessionStorage.setItem("pageTitle", pagetitle);
  }

  showBasicDialog() {
    this.displayBasic = true;
  }

  //getting departments and consultants
  GetDepartments() {
    let showAll = 0;
    this.hospitalsService
      .GetDepartments(this.globals.HospitalId, showAll)
      .subscribe((res) => {
        this.departments = res.response;
      });
  }

  GetTitle() {
    this.patientService.GetTitle(this.globals.HospitalId).subscribe((res) => {
      this.titleList = res.response;
    });
  }
  //LoadCountry method called from AppointmentService
  LoadCountry() {
    this.AppointmentsService.GetCountry(this.globals.HospitalId).subscribe(
      (res) => {
        this.countryList = res.response;
        this.NationalityData = [];
        this.CountryListData = [];
        this.countryList.forEach((a) => {
          this.NationalityData.push({
            id: a.countryId.toString(),
            text: a.nationalityName,
          });

          this.CountryListData.push({
            id: a.countryId.toString(),
            text: a.countryName,
          });
        });
      }
    );
  }

  //ng-autocomplete countrylist select event
  selectEvent(CountryId: any) {
    this.CountryId = CountryId;

    this.GetState();
  }

  //Get state from current country selected inputs
  GetState() {
    if (this.CountryId) {
      let ShowAll = 0;
      this.ConfigurationService.GetState(
        this.globals.HospitalId,
        this.CountryId,
        ShowAll
      ).subscribe((res) => {
        this.state1List = res.response;
        this.StateListData = [];
        this.state1List.forEach((a) => {
          this.StateListData.push({
            id: a.stateId.toString(),
            text: a.stateName,
          });
        });
      });
    }
  }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  SaveConsultantDetails() {
    this.submitted = true;
    if (this.ConsultantForm && this.ConsultantForm.valid) {
      //this.ConsultantDetails = new ConsultantDetails();
      this.ConsultantDetails = this.ConsultantForm.getRawValue();

      const formData = new FormData();

      // date convertion
      let DOj = this.ConsultantDetails.DateOfJoin;
      if (typeof DOj == "object") {
        let DateOfJoin1 = this.datepipe.transform(DOj, "dd-MM-yyyy");
        this.ConsultantDetails.DateOfJoin = DateOfJoin1;
      }
      let DateOfBirth1 = this.ConsultantDetails.DateOfBirth;
      if (typeof DateOfBirth1 == "object") {
        let DateOfBirth2 = this.datepipe.transform(DateOfBirth1, "dd-MM-yyyy");
        this.ConsultantDetails.DateOfBirth = DateOfBirth2;
      }

      let UserId=this.ConsultantDetails.UserId;// User Id CONVERSION TO INT
      this.ConsultantDetails.UserId = Number(UserId);

      let DeptId = this.ConsultantDetails.DeptId; // Department Id CONVERSION TO INT
      this.ConsultantDetails.DeptId = Number(DeptId);

      let CountryId = this.ConsultantDetails.CountryId; // Country Id CONVERSION TO INT
      this.ConsultantDetails.CountryId = Number(CountryId);
      this.ConsultantDetails.SignatureLoc = this.SignaturePhoto;
      if(this.ConsultantDetails.SortOrder ==null){
        console.log(this.ConsultantDetails.SortOrder);
        
        this.ConsultantDetails.SortOrder=0;
      }

      (this.LocationIds = this.ConsultantUserForm.get("LocationIds").value),
        (this.ConsultantDetails["LocationIds"] = this.LocationIds);
      if (this.userdata) {
        this.ConsultantDetails["UserData"] = this.userdata;
      } else {
        this.ConsultantDetails["UserData"] = {
          IsNewUser: 0,
          ConsultantUserId:this.ConsultantUserId ,
          UserName: "",
          P_UserId: 0,
          UserPassword: "",
          LocationIds: "",
          BranchIds: [],
          GroupIds: [],
          active: true,
          blockReason: "",
        };
      }
      console.log(this.ConsultantDetails)
      formData.append("ConsultantJson", JSON.stringify(this.ConsultantDetails));

      if (this.documentFile) {
        formData.append(
          "SignaturePhoto",
          this.documentFile,
          this.documentFile.name
        );

        // for (var i = 0; i < this.documentFile.length; i++) {
        //   formData.append('SignaturePhoto', this.documentFile[i], this.documentFile[i].name);
        // }
      }
      if (this.documentFile2) {
        formData.append("SignaturePhoto", this.documentFile2);
      }
      //  else{
      //     formData.append('SignaturePhoto', this.SignaturePhoto);
      //   }
      this.isLoading=true;

      if (this.ConsultantId) {

        this.ConfigurationService.InsertUpdateConsultant(formData).subscribe(
          (res) => {
            this.result = res;
            this.submitted = false;
            if (this.result.status == 200 && this.result.message == "Success") {
              this.isLoading=false;

              this.toastr.success("Consultant Updated successfully");
            } else {
              this.toastr.error("Failed : " + this.result.message);
              this.isLoading=false;

            }
            this.submitted = false;
          }
        );
      } else {
        this.isLoading=true;

        this.ConfigurationService.InsertUpdateConsultant(formData).subscribe(
          (res) => {
            this.result = res;
            this.submitted = false;
            if (this.result.status == 200 && this.result.message == "Success") {
              this.isLoading=false;

              this.toastr.success("Consultant added successfully");
            } else {
              this.toastr.error("Failed : " + this.result.message);
              this.isLoading=false;

            }
          }
        );
        // this.clearForm();
        this.submitted = false;
      }
    }
  }



  GetCommissionRules() {
    this.ConfigurationService.GetCommissionRules(
      this.globals.HospitalId
    ).subscribe((res) => {
      this.CommissionRulesData = res.response;
    });
  }
  GetLedgerHeadData() {
    let Input = {
      HeadId: 0,
      ShowAll: 0,
      BranchId: this.globals.HospitalId,
    };
    this.ConfigurationService.GetLedgerData(Input).subscribe((res) => {
      this.GetLedgerHead = res.response;
    });
  }

  GetServiceItem() {
    let showAll = 0;
    this.ConfigurationService.GetConsultantItems(
      this.globals.HospitalId,
      showAll
    ).subscribe((res) => {
      this.GetServiceItemData = res.response;
      this.GetServiceItemData.forEach((a) => {
        (a.id = a.itemId), (a.text = a.itemName);
      });
    });
  }

  GetGender() {
    this.patientService.GetGender().subscribe((res) => {
      this.genderList = res.response;
    });
  }

  //Giving DOB method for calculating Age
  ageCalculator() {
    this.age = this.ConsultantForm.get("DateOfBirth").value;
    let convertAge = new Date(this.age);

    if (convertAge.toString() == "Invalid Date") {
      let datstring = this.age.split("-");
      if (datstring) {
        let nedob = datstring[2] + "/" + datstring[1] + "/" + datstring[0];
        var today = new Date();
        var birthDate = new Date(nedob);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
      }
    } else {
      if (this.age) {
        let convertAge = new Date(this.age);
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        this.showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
        this.ConsultantForm.patchValue({ Age: this.showAge });
        this.birthMonth = Math.abs(
          new Date().getMonth() - convertAge.getMonth()
        );
        this.ConsultantForm.patchValue({ Month: this.birthMonth });
      }
    }
  }

  //Age reversing method for calculating DOB
  dobCalculator() {
    const now = new Date();
    this.showAge = this.ConsultantForm.get("Age").value;

    if (this.showAge) {
      now.setFullYear(now.getFullYear() - this.showAge);
    }

    this.birthMonth = this.ConsultantForm.get("Month").value;
    if (this.birthMonth) {
      now.setMonth(now.getMonth() - this.birthMonth);
    }

    this.age = this.datepipe.transform(now, "dd-MM-yyyy");
    this.ConsultantForm.controls["DateOfBirth"].setValue(this.age);
  }

  getUserGroups() {
    var bidv = 0;
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        bidv = this.globals.HospitalId;
      }
    });
    var branchid = bidv;
    this.ConfigurationService.getUserGroups(branchid).subscribe((res) => {
      this.Groupname = res.response;
      this.GroupnameData = [];
      this.Groupname.forEach((a) => {
        this.GroupnameData.push({
          id: a.userGroupId.toString(),
          text: a.userGroup,
        });
      });
    });
  }

  GetLocations() {
    this.ConfigurationService.GetLocations(this.globals.HospitalId).subscribe(
      (res) => {
        this.Locations = res.response;
      }
    );
  }
  GetUsers() {
    this.Userservice.GetUsers(this.globals.HospitalId).subscribe((res) => {
      this.GetUsersData = res.response;
    });
  }
  ConsultantUserLogin() {
    this.submitted1 = true;
    if (this.ConsultantUserForm && this.ConsultantUserForm.valid) {
      let userName = this.ConsultantUserForm.get("UserName").value;
      if (userName) {
        this.submitted1 = false;
        this.userdata = {
          IsNewUser: 1,
          ConsultantUserId: Number(this.ConsultantUserForm.get("ConsultantUserId").value)?Number(this.ConsultantUserForm.get("ConsultantUserId").value):0,
          UserName: this.ConsultantUserForm.get("UserName").value,
          P_UserId: 0,
          UserPassword: this.ConsultantUserForm.get("UserPassword").value,
          LocationIds: this.ConsultantUserForm.get("LocationIds").value,
          BranchIds: [this.globals.HospitalId]? [this.globals.HospitalId]: [0],
          GroupIds: [this.ConsultantUserForm.get("GroupIds").value]? [this.ConsultantUserForm.get("GroupIds").value]: [0],
          active: true,
          blockReason: "",
        };
        this.toastr.success(
          "Users Data will be saved when submitting all datas"
        );
        this.modalRef.hide();
        //this.ConsultantDetails["LocationIds"] = this.userdata.LocationIds;
        this.displayBasic = false;
      } else {
        this.submitted1 = false;
        this.userdata = {
          IsNewUser: 0,
          ConsultantUserId: Number(this.ConsultantUserForm.get("ConsultantUserId").value)?Number(this.ConsultantUserForm.get("ConsultantUserId").value):0,
          UserName: this.ConsultantUserForm.get("UserName").value,
          P_UserId: 0,
          UserPassword: this.ConsultantUserForm.get("UserPassword").value,
          LocationIds: this.ConsultantUserForm.get("LocationIds").value,
          BranchIds: [this.globals.HospitalId]? [this.globals.HospitalId]: [0],
          GroupIds: [this.ConsultantUserForm.get("GroupIds").value]? [this.ConsultantUserForm.get("GroupIds").value]: [0],
          active: true,
          blockReason: "",
        };
        this.toastr.success(
          "Users Data will be saved when submitting all datas"
        );
        this.modalRef.hide();
        //this.ConsultantDetails["LocationIds"] = this.userdata.LocationIds;
        this.displayBasic = false;
      }
    }
  }

  onUpload(event) {
    const formData = new FormData();
    formData.append("firstname", "test");
    // this.documentFile= <File[]>event.files;
    this.documentFile = event.target.files[0];
    this.toastr.success("Files will be uploaded when  details saved");
  }
  EnableDisableTextField(event) {
    var abc = event;
    this.disabledField = event.target.value;
    if (event.target.value != 0) {
      this.ConsultantUserForm.get("UserName").disable();
      this.ConsultantUserForm.get("UserPassword").disable();
      this.ConsultantUserForm.get("GroupIds").disable();
      this.ConsultantUserForm.get("LocationIds").disable();
      this.isDropdownDisabled = true;
    } else {
      this.isDropdownDisabled = false;
      this.ConsultantUserForm.get("UserName").enable();
      this.ConsultantUserForm.get("UserPassword").enable();
      this.ConsultantUserForm.get("GroupIds").enable();
      this.ConsultantUserForm.get("LocationIds").enable();
    }
  }

  //Getting File Images in Modal
  attachments(template1: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template1,
      Object.assign({}, { class: "gray modal-lg" })
    );

    this.patientService.GetPatientDocuments(Number()).subscribe(
      (res) => {
        this.regDocLocation = res.response;
        this.documentList = this.regDocLocation[0]?.regDocLocation;
      },
      (error) => {
        this.toastr.error("Failure: " + error);
      }
    );
  }

  //open image modal
  OpenImage(filePath: string) {
    //document view
    window.open(
      filePath,
      "Image",
      "width=largeImage.stylewidth,height=largeImage.style.height,resizable=1"
    );
  }

  // delete Uploaded documents
  DeleteUploadedDoc(docId) {
    if (confirm("Are you sure that you want to delete this file ?")) {
      this.patientService.DeleteUploadedDoc(Number(docId)).subscribe(
        (res) => {
          this.result = res;
          if (this.result.message == "success") {
            // this.attachments();
            this.toastr.success("The file deleted successfully");
          } else {
            this.toastr.error("Failure: " + this.result.message);
          }
        },
        (error) => {
          this.toastr.error("Failure: " + error);
        }
      );
    }
  }

  fetchId() {
    this.activatedRoute.params.subscribe((params) => {
      this.ConsultantId = Number(params.id);
    });
  }

  fetchDetails(Id: Number) {
    if (Id) {
      // this.ConsultantForm.patchValue({
      //   AllowConsultantLogin: allowConsultantLogin,
      // });
    }
    this.ConfigurationService.GetConsultantById(this.ConsultantId).subscribe(
      (res) => {
        this.GetConsultantById = res.response;
        console.log(this.GetConsultantById)
        let labelItem = this.GetConsultantById[0].itemIdList;
        if (labelItem) {
          this.selectedItems = [];
          labelItem.forEach((element) => {
            let item = {
              itemId: element.itemId,
              itemName: element.itemName,
            };
            this.selectedItems.push(item);
          });
        }
        this.CountryId = this.GetConsultantById[0].countryId;
        if (this.CountryId) {
          this.GetState();
        }
        this.ConsultantUserId= this.GetConsultantById[0].cuid;
        this.SignaturePhoto = this.GetConsultantById[0].signatureLoc;
        this.ConsultantForm.patchValue({
          CRegNo: this.GetConsultantById[0].cRegNo,
        });
        this.ConsultantForm.patchValue({
          ConsultantCode: this.GetConsultantById[0].consultantCode,
        });
        this.ConsultantForm.patchValue({
          SpecialityCode: this.GetConsultantById[0].specialityCode,
        });
        this.ConsultantForm.patchValue({
          DateOfJoin: this.GetConsultantById[0].doj,
        });
        this.ConsultantForm.patchValue({
          FirstName: this.GetConsultantById[0].firstName,
        });

        this.ConsultantForm.patchValue({
          MiddleName: this.GetConsultantById[0].middleName,
        });
        this.ConsultantForm.patchValue({
          LastName: this.GetConsultantById[0].lastName,
        });
        this.ConsultantForm.patchValue({
          DeptId: this.GetConsultantById[0].deptId,
        });
        this.ConsultantForm.patchValue({
          Designation: this.GetConsultantById[0].designation,
        });
        this.ConsultantForm.patchValue({
          Specialisation: this.GetConsultantById[0].specialisation,
        });

        this.ConsultantForm.patchValue({
          RoomNo: this.GetConsultantById[0].roomNo,
        });
        this.ConsultantForm.patchValue({
          SortOrder: this.GetConsultantById[0].sortOrder,
        });
        this.ConsultantForm.patchValue({
          IsAprovedAuthority: this.GetConsultantById[0].isAprovedAuthority,
        });
        this.ConsultantForm.patchValue({
          Title: this.GetConsultantById[0].title,
        });
        this.ConsultantForm.patchValue({
          NationalityId: this.GetConsultantById[0].nationalityId,
        });
        this.ConsultantForm.patchValue({
          Gender: this.GetConsultantById[0].gender,
        });
        this.ConsultantForm.patchValue({
          DateOfBirth: this.GetConsultantById[0].dob,
        });
        this.ConsultantForm.patchValue({
          Age:this.GetConsultantById[0].age,
        });
        this.ConsultantForm.patchValue({
          Month: this.GetConsultantById[0].month,
        });
        this.ConsultantForm.patchValue({
          Qualification: this.GetConsultantById[0].qualification,
        });

        this.ConsultantForm.patchValue({
          Address1: this.GetConsultantById[0].address1,
        });

        this.ConsultantForm.patchValue({
          Address2: this.GetConsultantById[0].address2,
        });
        this.ConsultantForm.patchValue({
          PlacePO: this.GetConsultantById[0].placePO,
        });
        this.ConsultantForm.patchValue({
          City: this.GetConsultantById[0].city,
        });
        this.ConsultantForm.patchValue({
          PIN: this.GetConsultantById[0].pin,
        });
        this.ConsultantForm.patchValue({
          Street: this.GetConsultantById[0].street,
        });

        this.ConsultantForm.patchValue({
          CountryId: this.GetConsultantById[0].countryId,
        });
        this.ConsultantForm.patchValue({
          Mobile: this.GetConsultantById[0].mobile,
        });

        this.ConsultantForm.patchValue({
          State: this.GetConsultantById[0].state,
        });

        this.ConsultantForm.patchValue({
          ResPhone: this.GetConsultantById[0].resPhone,
        });
        this.ConsultantForm.patchValue({
          OffPhone: this.GetConsultantById[0].offPhone,
        });
        this.ConsultantForm.patchValue({
          Email: this.GetConsultantById[0].email,
        });
        this.ConsultantForm.patchValue({
          Fax: this.GetConsultantById[0].fax,
        });
        this.ConsultantForm.patchValue({
          TimeSlice: this.GetConsultantById[0].timeSlice,
        });

        this.ConsultantForm.patchValue({
          MaxPatients: this.GetConsultantById[0].maxPatients,
        });

        this.ConsultantForm.patchValue({
          ConsultantLedger: this.GetConsultantById[0].consultantLedger,
        });

        this.ConsultantForm.patchValue({
          CommissionId: this.GetConsultantById[0].commissionId,
        });
        this.ConsultantForm.patchValue({
          ConsultantLogin: this.GetConsultantById[0].userId,
        });

        // this.ConsultantForm.patchValue({
        //   ItemIdList: this.GetConsultantById[0].ItemIdList,
        // });
        if (this.selectedItems.length != 0) {
          this.ConsultantForm.controls["ItemIdList"].setValue(
            this.selectedItems
          );
          this.GetServiceItem();
        }
        this.ConsultantForm.patchValue({
          DrugRefType: this.GetConsultantById[0].drugRefType,
        });

        this.ConsultantForm.patchValue({
          ConsultantMedicationReport:
            this.GetConsultantById[0].consultantMedicationReport,
        });
        this.ConsultantForm.patchValue({
          ExternalConsultant: this.GetConsultantById[0].externalConsultant,
        });
        this.ConsultantForm.patchValue({
          AllowCommission: this.GetConsultantById[0].allowCommission,
        });
        // this.ConsultantForm.patchValue({
        //   LocationIds: this.GetConsultantById[0].firstName,
        // });

        this.ConsultantForm.patchValue({
          AllowConsultantLogin: this.GetConsultantById[0].allowConsultantLogin,
        });

        this.isShowUserDetailsShowing = this.GetConsultantById[0].allowConsultantLogin
      }
    );
  }

  clearPad() {
    this.signaturePad.clear();
  }
  //Saving Signature Pad
  savePad() {
    const signName = "sign.jpeg";
    const base64Data = this.signaturePad.toDataURL();

    let signsplit = base64Data.split(";");

    const signBlob = this.dataURItoBlob(signsplit[1].split(",")[1]);

    const signFile = new File([signBlob], signName, { type: "image/jpeg" });

    this.documentFile2 = signFile;

    //const formData = new FormData();
    //this.SignatureSave=formData.append('Sign', this.documentFile2, this.documentFile2.name);

    this.toastr.success("Saved Your Signature  successfully");
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }

  // for geting all form items

  get f() {
    return this.ConsultantForm.controls;
  }

  get f2() {
    return this.ConsultantUserForm.controls;
  }

  openModalConsultant(template: TemplateRef<any>) {
    if (this.showConsultantLogin == true || this.showConsultantLogin == null) {
      this.modalRef = this.modalService.show(template);
      this.getUserGroups();
      this.GetLocations();
    }
    // this.ConsultantUserId= this.GetConsultantById[0].cuid;
    this.ConsultantUserForm.patchValue({
      ConsultantUserId: this.ConsultantUserId,
    });
  }

  clearForm() {
    this.ConsultantForm.reset({
      ConsultantCode: "",
      ConsultantId: "",
      SpecialityCode: "",
      DeptId: "",
      CRegNo: "",
      DateOfJoin: "",
      Designation: "",
      Specialisation: "",
      RoomNo: "",
      SortOrder: 0,
      IsAprovedAuthority: 0,
      Title: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      NationalityId: "",
      Gender: "",
      DateOfBirth: "",
      Age: "",
      Month: "",
      Qualification: "",
      Address1: "",
      Address2: "",
      PlacePO: "",
      City: "",
      PIN: "",
      Street: "",
      CountryId: "",
      State: "",
      Mobile: "",
      ResPhone: "",
      OffPhone: "",
      Email: "",
      Fax: "",
      TimeSlice: "",
      MaxPatients: 1,
      SignatureLoc: "",
      ItemIdList: "",
      AllowCommission: false,
      AllowConsultantLogin: true,
      DeptOverrule: true,
      DeptWiseConsultation: true,
      ExternalConsultant: false,
      ConsultantMedicationReport: true,
    });
  }
  GetCheckedStatus($event) {
    this.showConsultantLogin = $event.currentTarget.checked;
    this.isShowUserDetailsShowing = true;
  }
  GetCheckedStatusCommisiion($event) {
    if ($event.currentTarget.checked == false) {
      this.Commission = true;
    } else {
      this.Commission = false;
    }
  }
  GetCheckedStatusConsultant($event) {
    if ($event.currentTarget.checked == false) {
      this.isShowUserDetailsShowing = true;
      this.isShowUserDetails = true;

      this.ConsultantForm.patchValue({
        AllowConsultantLogin: true,
      });
    } else {
      this.isShowUserDetails = false;
      this.ConsultantForm.patchValue({
        AllowConsultantLogin: false,
      });
    }
  }
  OpenImageSignature(filePath: string) {
    //document view
    window.open(
      filePath,
      "Image",
      "width=largeImage.stylewidth,height=largeImage.style.height,resizable=1"
    );
  }


  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }



}
