import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMRService } from '../../../services/emr.service';

@Component({
  selector: 'app-view-service-orders-modal',
  templateUrl: './view-service-orders-modal.component.html',
  styleUrls: ['./view-service-orders-modal.component.scss']
})
export class ViewServiceOrdersModalComponent implements OnInit {

  @Input() entryId: any;
  @Input() patientId: any;
  @Input() serviceItems: any[] = [];

  public dataLoading: boolean = false;
  public itemData: any;

  constructor(private activeModalRef: BsModalRef,
              private emrService: EMRService) { }

  ngOnInit(): void {
  }

  close(){
    this.activeModalRef.hide();
  }

}
