import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxBarcodeModule } from 'ngx-barcode';
import { LabService } from '../../services/lab.service';
import { BarcodeTypeModalComponent } from '../barcodeType.modal copy 2/barcodeType-modal.component';
import { log } from 'console';
import { BarcodePrintComponent } from '../../../print/barcode-print/barcode-print.component';
import { Params, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-result-form',
  templateUrl: './barcode-modal.component.html',

  styleUrls: ['./barcode-modal.component.scss']
})
export class BarcodeModalComponent implements OnInit {
  @Input() patientName: any
  @Input() regno: any
  @Input() barcodeSelect: any
  @Input() startDateFrom: any
  @Input() barcodeInvNo: any
  barcodeForm: FormGroup;
 countarray=[];
 constructor(private modalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router,
    private toastr: ToastrService,


  ) { }

  ngOnInit(): void {
    this.barcodeForm = new FormGroup({
      barcodeinv: new FormControl(''),
      barcodetime: new FormControl(''),
      count: new FormControl([1]),

    });

  }

  close() {

    this.modalRef.hide();
  }
  print() {
    var formdata = this.barcodeForm.getRawValue();
    if(formdata.count>0 ){
      for(let i=1;i<=formdata.count;i++){
        this.countarray.push(i)
       }
       console.log(this.countarray);
       
      //  this.router.navigate(['/view_report'],  { state: { barcodeSelect: this.barcodeSelect ,regno:this.regno,patientName:this.patientName,startDateFrom:this.startDateFrom,barcodeInvNo:this.barcodeInvNo,count:this.countarray } });
       this.openInNewTab()
      this.close()
       
    }else{
      this.toastr.warning("Please Enter Count");
    }
  // }
// printbarcode(){
//   // const modalRef = this.modalService.show(BarcodePrintComponent, Object.assign({}))
//   this.router.navigate(['/BarcodePrintComponent/']);

 }

 openInNewTab() {
  const baseUrl = window.location.origin;
  // Create the URL tree without query parameters
  const urlTree = this.router.createUrlTree(['/view_report']);

  // Serialize the URL tree to a URL string
  const url = baseUrl + "/#" + this.router.serializeUrl(urlTree);

  // Store the state data in the localStorage
  localStorage.setItem('state', JSON.stringify({
    barcodeSelect: this.barcodeSelect,
    regno: this.regno,
    patientName: this.patientName,
    startDateFrom: this.startDateFrom,
    barcodeInvNo: this.barcodeInvNo,
    count: this.countarray
  }));

  // Open the URL in a new tab
  const newWindow = window.open(url, '_blank');
}

}