import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Package } from '../../client/interfaces/Package';
import { ServiceOrderingService } from '../../client/services/ServiceOrderingService';
import { PackageandprofileService } from './packageandprofile.service';
import { ColDef, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { GeneralActionComponent } from "../../client/general-action/general-action.component";
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { Profile } from '../../client/interfaces/Profile';
import { ConsoleEventLogger } from '@generic-ui/hermes/core/infrastructure/logger/event/console.event.logger';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ConfigurationService } from '../../client/services/configuration.service';

@Component({
  selector: 'app-package-and-profile',
  templateUrl: './package-and-profile.component.html',
  styleUrls: ['./package-and-profile.component.scss']
})
export class PackageAndProfileComponent implements OnInit {

  //Variable Declarations

  packageTab: boolean; // for tab switching
  profileTab: boolean; // for tab switching
  form: FormGroup; // form variable
  profileForm: FormGroup; // form variable
  serviceitemlist: any[];
  public PackageForm: Package; // form interface
  public ProfileForm: Profile;  // form interface
  public user_id: string;
  serviceItemBasket: any[] = [];
  public submittedPackage: boolean = false; // form validation controller
  public submittedProfile: boolean = false; // form validation controller
  packageList: Package[] = [];
  PackagePopupHeading: string = "Add Package";
  ProfilePopupHeading: string = "Add Package";

  globals: globalvars = new globalvars();
  BranchesId: any;
  subscription: Subscription;
  dropdownSettings: IDropdownSettings = {};
  selectItem = [];
  // grid variables
  public defaultColDef: ColDef;
  columnDefs: ColDef[] = [];
  rowGroupPanelShow = "always";
  public context;
  public gridApi;
  public gridApiList;
  public statusBar;
  public loadingTemplate;
  public gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  public gridOptionsList = {
    pagination: true,
    paginationPageSize: 10,
  };
  public noRowsTemplate;
  Rate: any;
  // list grid details

  ListcolumnDefs: ColDef[] = [];
  public noRowsTemplateList;
  public frameworkComponents;
  modalRef: BsModalRef;
  isLoading = false;
  isFormChanged: boolean = false;

  // profile grid

  ProfilecolumnDefs: ColDef[] = [];
  ProfileList: Profile[] = []
  public profilegridOptionsList = {
    pagination: true,
    paginationPageSize: 10,
  };
  selectedItems: any[] = [];
  modalRefprofile: BsModalRef;
  @ViewChild("template") modaltemp: any;
  @ViewChild("templateprofile") modaltemp2: any;
  PackagePopupHding: string = "Add Package";
  Popupheading: string = "Add Profile";
  functionPermissionData: any;

  constructor(
    private ServiceOrderingService: ServiceOrderingService,
    private packageandprofileService: PackageandprofileService,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private sharedservice: SharedService,
    private modalService: BsModalService,
    private ConfigurationService: ConfigurationService 
  ) {
    this.onTab(1); // default tab set
    this.user_id = localStorage.getItem('user_id');
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId
      }
    });
  }

  ngOnInit(): void {

    this.form = new FormGroup({
      PackId: new FormControl(0),
      PackDesc: new FormControl('', [Validators.required]),
      EffectFrom: new FormControl('', [Validators.required]),
      EffectTo: new FormControl('', [Validators.required]),
      PackAmount: new FormControl('', [Validators.required]),
      Remarks: new FormControl(''),
      IsDisplayed: new FormControl(true),
      BranchId: new FormControl(this.globals.HospitalId),
      UserId: new FormControl(this.user_id),
      serviceitemlist: new FormControl(),
      ItemRateList: new FormArray([]),
    });

    this.profileForm = new FormGroup({
      ProfileId: new FormControl(0),
      ProfileDesc: new FormControl('', [Validators.required]),
      Remarks: new FormControl(''),
      IsDisplayed: new FormControl(true),
      profileItemSelect: new FormControl("", [Validators.required]),
      BranchId: new FormControl(this.globals.HospitalId),
      ProfileItems: new FormArray([])

    });

    this.GetAllAvailableServices();
    this.functionPermission();
    this.columnDefs = [
      {
        headerName: "Item Name",
        minWidth: 180,
        field: "ItemName",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
      },
      {
        headerName: "Rate",
        field: "Rate",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        editable: true
      },
    ];
    ////////////////////////////////////////
    this.ListcolumnDefs = [
      {
        headerName: "Package",
        field: "packDesc",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Rate",
        field: "packAmount",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Effect From",
        field: "effectFrom",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Effect To",
        field: "effectTo",
        minWidth: 180,
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Remarks",
        field: "remarks",
        minWidth: 180,
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        sortable: false,
        resizable: true,
        unSortIcon: true,
        field: "PackId",
        flex: 1,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "package" },
      },
    ]

    this.noRowsTemplate = `"<span">no rows to show</span>"`;
    this.frameworkComponents = {
      buttonRender: GeneralActionComponent,
    };

    this.context = { componentParent: this };

    this.getPackageList();
    ////////////////////////////////////////////

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'itemId',
      textField: 'itemName',
      // selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 20,
      allowSearchFilter: true,

    };

    //////////////////////////////
    this.ProfilecolumnDefs = [
      {
        headerName: "Profile Name",
        minWidth: 180,
        field: "profileDesc",
        enableRowGroup: true,
        resizable: true,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Remarks",
        minWidth: 180,
        field: "remarks",
        enableRowGroup: true,
        resizable: false,
        sortable: true,
        flex: 2,
        filter: true,
      },
      {
        headerName: "Action",
        filter: false,
        maxWidth: 100,
        pinned: "right",
        resizable: false,
        unSortIcon: true,
        field: "profileId",
        flex: 2,
        cellRenderer: "buttonRender",
        cellRendererParams: { PageType: "profile" },
      },
    ]

    this.GetProfileList();
  }


  /******* Function Definitions******************************/
  // for geting all form items

  get f() {
    return this.form.controls;
  }

  get f2() {
    return this.profileForm.controls;
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onGridReadyList(params) {
    this.gridApiList = params.api;
  }

  // tab switch
  onTab(number) {
    this.packageTab = false;
    this.profileTab = false;

    if (number == "1") {
      this.packageTab = true;
    }
    else if (number == "2") {
      this.profileTab = true;
    }
  }

  savePackage() {
    this.submittedPackage = true;
    if (this.form && this.form.valid) {
      this.PackageForm = this.form.getRawValue();
      this.PackageForm.PackAmount = Number(this.PackageForm.PackAmount); // string to number
      this.PackageForm.UserId = Number(this.PackageForm.UserId);
      let itemarayList = this.serviceItemBasket.map(x => ({ ...x, Rate: Number(x.Rate) })); // string to number convertion
      this.PackageForm.ItemRateList = itemarayList;

      let StartDateObj = this.PackageForm.EffectFrom;
      let EndDateObj = this.PackageForm.EffectTo;

      // date convertion
      if (typeof (StartDateObj) == "object") {
        let startDateFrom = this.datepipe.transform(StartDateObj, 'dd-MM-yyyy');
        this.PackageForm.EffectFrom = startDateFrom;
      }
      if (typeof (EndDateObj) == "object") {
        let endDate = this.datepipe.transform(EndDateObj, 'dd-MM-yyyy');
        this.PackageForm.EffectTo = endDate;
      }


      this.isLoading = true;

      this.packageandprofileService
        .savePackage(this.PackageForm).subscribe(
          (res) => {
            this.submittedPackage = false;

            if (res.status == 200 && res.message == 'Success') {
              if (this.form.get('PackId').value == 0) {
                this.isLoading = false;
                this.toastr.success("Package added successfully");
              }
              else {
                this.isLoading = false;
                this.toastr.success("Package Updated successfully");

              }
              this.modalRef.hide();
              this.getPackageList()
              this.clearForm();
            }
            else {
              this.isLoading = false;
              this.toastr.error("Failed : " + res.message);
            }



          },
        );
    }
  }

  GetAllAvailableServices() {

    this.packageandprofileService.GetServiceItems(this.globals.HospitalId).subscribe((res) => {
      this.serviceitemlist = res.response;
      this.serviceitemlist.forEach((a) => {
        (a.id = a.itemId), (a.text = a.itemName);
      });
    });

  }

  loadserviceitem(itemId) {
    if (itemId > 0) {
      let item = this.serviceitemlist.filter(x => x.itemId == itemId)[0];
      let item2 = this.serviceItemBasket.filter(x => x.ItemId == itemId)[0];
      if (item2 == undefined) {
        let itemarray = {
          ItemId: item.itemId,
          Rate: 0,
          ItemName: item.itemName
        }
        this.serviceItemBasket.push(itemarray);
        this.gridApi.setRowData(this.serviceItemBasket);

      }
      else {

      }
    }
  }
  packAmountvalue(Rate) {
    let rateAmount = 0;
    for (let i = 0; i < this.serviceItemBasket.length; i++) {
      let arrayRate = this.serviceItemBasket[i].Rate;
      rateAmount = Number(rateAmount) + Number(arrayRate);
    }
    this.form.patchValue({ PackAmount: rateAmount });

  }
  // to clear form

  clearForm() {
    this.serviceItemBasket = [];
    this.gridApi.setRowData(this.serviceItemBasket);
    this.form.reset({
      PackId: 0,
      PackDesc: '',
      EffectFrom: '',
      EffectTo: '',
      PackAmount: '',
      Remarks: '',
      serviceitemlist: [],
      IsDisplayed: true,
      BranchId: this.globals.HospitalId,
      UserId: this.user_id,
    });
    this.submittedPackage = false;

  }

  // to get package list for grid

  getPackageList() {
    let input = {
      "PackId": 0,
      "ShowAll": 1,
      "BranchId": this.globals.HospitalId
    }
    this.packageandprofileService.getpackages(input).subscribe((res) => {
      this.packageList = res.response;
    });
  }

  EditPackage(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 26)
    if (foundElement.id == 26 && foundElement.isAllowed == true) {
    this.PackagePopupHeading = "Edit package";
    this.modalRef = this.modalService.show(
      this.modaltemp, { class: "modal-lg modal-custom-style" }

    );
    this.serviceItemBasket = [];
    let PackagSingle = this.upper(data);
    this.form.patchValue(PackagSingle);

    let basket = this.upper(data.itemRateData);

    data.itemRateData.forEach(element => {
      let itemarray = {
        ItemId: element.itemId,
        Rate: element.rate,
        ItemName: element.itemName
      }
      this.serviceItemBasket.push(itemarray);
    });
    document.getElementById("myDiv").click();

    this.gridApi.setRowData(this.serviceItemBasket);
    } else {
      this.toastr.warning("You are not allowed");
    }

  }


  /// For object key name convertion
  upper(obj) {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      let first = key.substr(0, 1).toUpperCase();
      first = first + key.substr(1);

      newobj[first] = obj[key];
    }
    return newobj;
  }


  saveProfile() {
    this.submittedProfile = true;
    if (this.profileForm && this.profileForm.valid) {
      this.ProfileForm = this.profileForm.value as Profile;

      let list = this.profileForm.controls['profileItemSelect'].value
      let NewList = Array();
      list.forEach(element => {
        NewList.push({ 'ItemId': element.itemId });
      });
      this.ProfileForm.ProfileItems = NewList;
      delete this.ProfileForm['profileItemSelect'];


      // save form
      this.isLoading = true;

      this.packageandprofileService.saveProfile(this.ProfileForm)
        .subscribe((res) => {
          this.submittedProfile = false;

          if (res.status == 200 && res.message == 'Success') {
            if (this.profileForm.get('ProfileId').value == 0) {
              this.isLoading = false;
              this.toastr.success("Profile Added Successfully");
            }
            else {
              this.isLoading = false;
              this.toastr.success("profile Updated Successfully");

            }
            this.modalRefprofile.hide();
            this.GetProfileList();
            this.clearProfileForm();
          }
          else {
            this.isLoading = false;
            this.toastr.error("Failed : " + res.message);
          }


        },

          // (err) => {
          //   this.toastr.error("Some unexpected error occures try again later");
          // }

        );


    }
  }

  clearProfileForm() {
    this.profileForm.reset({
      ProfileId: 0,
      ProfileDesc: '',
      Remarks: '',
      IsDisplayed: true,
      profileItemSelect: [],
      ProfileItems: [],
      BranchId: this.globals.HospitalId
    });
    this.submittedProfile = false;

  }

  EditProfile(data) {
    const foundElement = this.functionPermissionData.find(x => x.id === 28)
    if (foundElement.id == 28 && foundElement.isAllowed == true) {
    this.Popupheading = "Edit profile";
    this.modalRefprofile = this.modalService.show(
      this.modaltemp2,
    );

    let ProfileSIngle = this.upper(data);
    this.profileForm.patchValue(ProfileSIngle);
    let items = ProfileSIngle['ProfileItems'];
    this.selectedItems = [];
    this.profileForm.controls['ProfileId'].setValue(ProfileSIngle['ProfileId']);
    items.forEach(element => {
      let item = {
        'itemId': element.itemId,
        'itemName': element.itemName
      }
      this.selectedItems.push(item);

    });

    this.profileForm.controls['profileItemSelect'].setValue(this.selectedItems);
    } else {
      this.toastr.warning("You are not allowed");
    }

  }
  GetProfileList() {
    let input = {
      "PackId": 0,
      "ShowAll": 1,
      "BranchId": this.globals.HospitalId
    }
    this.packageandprofileService.getprofiles(input).subscribe((res) => {
      this.ProfileList = res.response;
    });
  }

  // to delete a row from grid
  deleteItem(event) {
    let data = event.data;
  }
  // my works
  //   openModalCompany(template: TemplateRef<any>) {
  //     this.modalRef = this.modalService.show(template);
  //     this.clearForm();
  //  }
  openModal(template: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 25)
    if (foundElement.id == 25 && foundElement.isAllowed == true) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg modal-custom-style" });
    this.PackagePopupHeading = "Add Package";
    // this.clearForm()
    } else {
      this.toastr.warning("You are not allowed");
    }
  }
  openModalprofile(templateprofile: TemplateRef<any>) {
    const foundElement = this.functionPermissionData.find(x => x.id === 27)
    if (foundElement.id == 27 && foundElement.isAllowed == true) {
    this.modalRefprofile = this.modalService.show(templateprofile);
    this.Popupheading = "Add Profile"
    this.clearProfileForm()
    } else {
      this.toastr.warning("You are not allowed");
    }

  }
  //   onCellValueChanged(event){
  //     if(!this.isFormChanged){
  //         if(event.value)
  //         {
  //           this.isFormChanged=true;
  //         }
  //     }
  //   }

  functionPermission() {
    let input = {
      MenuId: Number(localStorage.getItem('selectedMenu')),
      UserId: Number(localStorage.getItem("user_id"))
    }
    this.ConfigurationService.functionPermission(input).subscribe((res) => {
      this.functionPermissionData = res.response;
      console.log('functionPermissionData', this.functionPermissionData);
    });

  }

}




