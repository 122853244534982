import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SharedService } from '../../../shared/shared.service';
import { globalvars } from '../../../shared/models/GlobalVars';
import { EMRPatientInfo, EMRSponsorInfo } from '../interfaces/EMRPatientInfo';
import { noop, Observable, Observer, of, Subscription } from 'rxjs';
import { EMRService } from '../../emr/services/emr.service';
import { ConfigurationManager } from '../../../../assets/configuration/configuration-manager';
import { ServiceOrderingService } from '../../client/services/ServiceOrderingService';
import { ConfigurationService } from "../../client/services/configuration.service";
import { HttpClient } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { Response } from '../../../_Models/Response';
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ServiceOrderForm } from '../interfaces/ServiceOrderForm';
import { ConsultantDeskService } from '../../client/services/consultant.service';
import { DomSanitizer } from "@angular/platform-browser";
import * as _ from 'lodash';

import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { TelerikPrintComponent } from '../../../shared/modals/telerik-print/telerik-print.component';
import { SettingsService } from '../../settings/services/settings.service';
import { Settings } from '../../settings/model/settings.model';
import { DatePipe } from '@angular/common';
import { SponsorRuleItemNoteComponent } from '../../configurations/modals/sponsor-rule-item-note/sponsor-rule-item-note.component';

interface servicItems {
  itemId: number;
  itemCode: string;
  itemName: string,
  itemDropDownName: string,
  cptCode: string
}


@Component({
  selector: 'app-general-service-order',
  templateUrl: './general-service-order.component.html',
  styleUrls: ['./general-service-order.component.scss']
})


export class GeneralServiceOrderComponent implements OnInit, OnDestroy {
  @Input() IsViewtab;
  form: FormGroup; // form variable
  EMRPatientInfo: any;
  globals: globalvars = new globalvars();
  subscription: Subscription;
  BranchesId: any;
  private baseUrl: string = "";
  ServicesGroups: any;
  OriginalServiceGroups: any[];
  SelectGroupId: any[] = [];
  sourceOriginal: any;
  sourceProducts: any[];
  EventId: any;
  CPTModifiers: any[] = [];
  search?: string;
  suggestions$?: Observable<servicItems[]>;
  errorMessage?: string;
  ServiceOrderForm: ServiceOrderForm;
  PrevServiceItems: ServiceOrderForm[] = [];
  ModalServiceItems: any[] = [];
  dropdownSettings: any = {};
  modalRef?: BsModalRef;
  @ViewChild("template") modaltemp: any;
  GetServiceList: any;
  GetServiceListData: any;
  src: any = '';
  isLoading = false;

  FormValueChangeChecker: boolean = false;
  IsDisabled: boolean = true;
  orderId: any = 0;

  public totalAmount: any = 0.00;

  public dropdownOptions: Options;
  public dropdownDataConfig: any = {
    showCovered: true,
    showFrequent: true
  }

  public configurationSettings: Settings[] = [];
  showInsuranceDet: any;
  EMRSponsorInfo:EMRSponsorInfo;
  totalCopay = 0;
  totalDeduction = 0;
  
  constructor(
    private sharedservice: SharedService,
    private EMRService: EMRService,
    private configurationManager: ConfigurationManager,
    private ServiceOrderingService: ServiceOrderingService,
    private ConfigurationService: ConfigurationService,
    private http: HttpClient,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private ConsultantDeskService: ConsultantDeskService,
    private sanitizer: DomSanitizer,
    private settingsService: SettingsService,
    private datepipe: DatePipe,
  ) {
    this.subscription = this.sharedservice.getHospital().subscribe((glob) => {
      if (this.sharedservice.checkglobals(glob)) {
        this.globals = glob;
        this.BranchesId = this.globals.HospitalId;
      }
    });

    this.EMRPatientInfo = this.EMRService.GetEMRPatientInfo();
    this.EMRSponsorInfo = this.EMRService.GetEMRSponsorInfo();
    this.baseUrl = (this.configurationManager as any).baseUrl;
    this.getApplicationSettings();
  }

  ngOnInit(): void {

    if (this.IsViewtab == true) {
      this.IsDisabled = false;
    } else if (this.IsViewtab == "undefined") {
      this.IsDisabled = true;
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: "itemId",
      textField: "itemName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };


    /*****************************FOR DROP DOWN AUTO COMPLETE****************** */
    this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.search);
    }).pipe(
      switchMap((query: string) => {
        if (query) {

          return this.http.post<Response<servicItems[]>>(
            `${this.baseUrl}api/EMR/GetEMRServiceItem`, {
            "ServiceName": query,
            "branchId": this.BranchesId,
            "GroupId": 0,
            // "consultantId":this.EMRPatientInfo.ConsultantId
            "consultantId": 0,
            PatientId: this.EMRPatientInfo.PatientId,
            ConsultationId: this.EMRPatientInfo.ConsultationId,
            CurrentDate: this.datepipe.transform(new Date(), "dd-MM-yyyy"),
            CreditId: this.EMRSponsorInfo.CreditId,
            Category: 'N'
          }).pipe(
            map((data) => _.forEach(data.response, e=>{
              e.itemDropDownName = e.itemName+' - '+e.cptCode;
              return e
            }) || []),
            tap(() => noop, err => {
              this.errorMessage = err && err.message || 'Something goes wrong';
            })
          );
        }

        return of([]);
      })
    );
    /*****************************END  DROP DOWN AUTO COMPLETE****************** */




    this.form = new FormGroup({
      itemDetails: new FormArray([]),
      typeOfOrder: new FormControl('G'),
      visitId: new FormControl(this.EMRPatientInfo.VisitId),
      branchId: new FormControl(this.BranchesId),
      userId: new FormControl(Number(localStorage.getItem('user_id'))),

    });

    //   this.getServiceGroupItems();
    // this.GetAllAvailableServices();
    this.GetAllCPTModifier();
    this.GetEMRServiceItems();
    this.GetPrevServiceItems();
    this.getFrequentlyServiceItem();

    this.dropdownOptions = {
      templateResult: this.templateResult,
    }
  }

  // function for result template
  public templateResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    let icon = '<i class="fa" aria-hidden="true"></i>';
    if (state.additional.insCovered) {
      icon = '<i class="fa fa-umbrella" aria-hidden="true"></i>';
    }
    return jQuery('<span class="d-flex justify-content-between">'+ state.text + '   '+icon+ '</span>');
  }

  SaveServiceOrder() {

    if (
      this.EMRPatientInfo.CrudType != 3 &&
      this.EMRPatientInfo.VisitId > 0 && this.FormValueChangeChecker) {
      this.ServiceOrderForm = this.form.getRawValue();

// console.log('this.ServiceOrderForm', this.ServiceOrderForm);

      this.ServiceOrderForm.userId = Number(localStorage.getItem('user_id'));
      this.ServiceOrderForm.branchId = Number(this.BranchesId);
      this.ServiceOrderForm.LocationId = Number(this.globals.Location.locationId);
      this.ServiceOrderForm.typeOfOrder = 'G';
      this.isLoading = true;

      this.ServiceOrderForm.itemDetails.forEach((a) => {
        if(a.serviceValidity == false){
          a.RuleId = 0;
        }
      });

      this.EMRService.SaveServiceOrder(
        this.ServiceOrderForm
      ).subscribe(
        (res) => {
          if (res.status == 200 && res.message == "Success") {
            this.isLoading = false;

            this.orderId = res.response['orderId'];
            this.toastr.success("Success: " + "Service Order Details Saved Successfully...");
          }

          else {
            this.isLoading = false;

            Swal.fire(
              'Error!',
              'Some unexpected error occures! try again later.',
              'error'
            )
          }
        });
    }
    else {
      if (!this.FormValueChangeChecker) {

        this.toastr.warning("Info: " + "No changes detected in the entry");
      }
    }

  }


  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  //getting service group items
  getServiceGroupItems() {
    let input = {

      "BranchId": this.globals.HospitalId,
      "PageCode": "EMR"

    }
    this.ServiceOrderingService.getServiceGroupItems(input).subscribe((res) => {
      this.ServicesGroups = res.response;
      this.OriginalServiceGroups = res.response;

    });
  }


  nodeSelect(event) {
    let showAll = 0;
    let consultantId = this.EMRPatientInfo.ConsultantId;
    this.EventId = event.node.groupId
    this.ConfigurationService.GetServiceItem(showAll, this.globals.HospitalId, this.EventId, consultantId).subscribe((res) => {
      this.sourceProducts = res.response
      this.sourceOriginal = res.response
    });

  }

  nodeUnselect(event) {
    let unselectgroup = [];
    let unselectchild = [];
    if (event.node.children != null) {
      event.node.children.forEach(b => {
        unselectchild.push(b.groupId)
      });
    }


    this.SelectGroupId.forEach(a => {
      if (a != event.node.groupId) {
        if (unselectchild.indexOf(a) <= -1) {
          unselectgroup.push(a)
        }
      }
    });

    this.SelectGroupId = unselectgroup;
    this.ServiceOrderingService.GetAvailableService(this.globals.HospitalId, 0, this.SelectGroupId).subscribe((res) => {
      this.sourceProducts = res.response
      this.sourceOriginal = res.response;
    });

  }

  LoadItems(item) {
    this.addMapFormGroup(item, 0);
  }

  public addMapFormGroup(data: any[] = [], saved = 0) {
    const ServiceItems = this.form.get("itemDetails") as FormArray;
    const values = Object.keys(data).map(key => data[key]);

    if (data.length > 0) {
      data.forEach((element, i) => {
        let filteredArray = ServiceItems.value.filter(value => value.itemId == element.itemId);
        if (filteredArray.length == 0) {
          ServiceItems.push(this.createListGroup(element, saved));

        }
        else {
          this.toastr.error("Failure: " + "Service Items Already added");
        }
      });

    }
    else if (values.length > 0) {
      let filteredArray = ServiceItems.value.filter(value => value.itemId == values[0]);
      if (filteredArray.length == 0) {
        ServiceItems.push(this.createListGroup(data, saved));
      }
      else {
        this.toastr.error("Failure: " + "Service Items Already added");
      }

    }
    // else if(data){

    //  ServiceItems.push(this.createListGroup(data, saved));
    // }
    this.search = "";
    this.calculateTotalAmount();
    this.getTotalRate();
  }


  private createListGroup(data: any[] = [], saved = 0): FormGroup {

    if (saved == 0) {
      if (!data["qty"]) data["qty"] = 1;
    }
    if (saved == 0) {

      this.FormValueChangeChecker = true;
    }

    let temName = data["itemName"] + " - " + data["itemCode"];
    let cptModifierId = 0;

    if (data["cptModifierId"]) cptModifierId = data["cptModifierId"];
    return new FormGroup({
      itemId: new FormControl(data["itemId"]),
      itemName: new FormControl(temName),
      notes: new FormControl(data["notes"]),
      cptModifierId: new FormControl(cptModifierId),
      qty: new FormControl(data["qty"]),
      authReq: new FormControl(data['authReq']),
      cptCode: new FormControl(data['cptCode'] ? data['cptCode'] : ''),
      rate: new FormControl(data['itemRate']? data['itemRate'] : (data['rate']? data['rate'] : 0)),
      insCovered: new FormControl(data["insCovered"]),
      serviceValidity: new FormControl(data["serviceValidity"]),
      remainingDays: new FormControl(data["remainingDays"]),
      RuleId: new FormControl(data["ruleId"] ? data["ruleId"] : 0),
      dedAmt: new FormControl(data["dedAmt"] ? data["dedAmt"] : 0),
      dedPer: new FormControl(data["dedPer"] ? data["dedPer"] : 0),
      coPayAmt: new FormControl(data["coPayAmt"] ? data["coPayAmt"] : 0),
      copayPer: new FormControl(data["copayPer"] ? data["copayPer"] : 0),
      taxPcnt: new FormControl(data["taxPcnt"] ? data["taxPcnt"] : 0),
      copayMaxLimit: new FormControl(data["copayMaxLimit"] ? data["copayMaxLimit"] : 0),
      maxLimit: new FormControl(data["maxLimit"] ? data["maxLimit"] : 0),
      note: new FormControl(data["note"] ? data["note"] : ''),
    });
  }

  GetEMRServiceItems() {
    let date = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    let input = {
      // "VisitId": this.EMRPatientInfo.VisitId,
      // "PatientId": this.EMRPatientInfo.PatientId,
      // "ShowAll": 1,
      VisitId: this.EMRPatientInfo.VisitId,
      PatientId: this.EMRPatientInfo.PatientId,
      ConsultationId: this.EMRPatientInfo.ConsultationId,
      CurrentDate: date
    };

    // this.EMRService.GetServiceItemsEMR(input).subscribe(
      this.EMRService.GetGeneralServiceItemsEMR(input).subscribe(
      (res) => {
        if (res.status == 200) {
          let response = res.response[0];
          this.orderId = response.orderId;
          if (response) {
            if (response.itemDetails) {
              this.addMapFormGroup(response.itemDetails, 1);
            }
          }
          this.form.valueChanges.subscribe(x => {
            this.FormValueChangeChecker = true;
          })
        }

      });
  }
  GetPrevServiceItems() {
    let input = {
      "VisitId": this.EMRPatientInfo.VisitId,
      "PatientId": this.EMRPatientInfo.PatientId,
      "ShowAll": 0,
      ConsultationId: this.EMRPatientInfo.ConsultationId
    };

    this.EMRService.GetServiceItemsEMR(input).subscribe(
      (res) => {
        if (res.status == 200) {
          this.PrevServiceItems = res.response;

        }
      });
  }
  openModal(ItemDetails) {
    this.ModalServiceItems = ItemDetails;
    this.modalRef = this.modalService.show(
      this.modaltemp,
      { class: 'modal-lg modal-custom-style' }
    );
  }


  getFrequentlyServiceItem() {
    this.ConsultantDeskService.GetServiceItems(
      Number(this.EMRPatientInfo.ConsultantId), 
      this.globals.HospitalId, 
      Number(this.EMRPatientInfo.ConsultationId), 
      this.dropdownDataConfig,
      this.datepipe.transform(new Date(), "dd-MM-yyyy"),
      this.EMRSponsorInfo.CreditId,
      'N'
      ).subscribe((res) => {
      this.GetServiceList = res.response;
      this.GetServiceListData = [];
      this.GetServiceList.forEach((a) => {
        this.GetServiceListData.push({
          id: a.itemId.toString(),
          text: a.itemName + ' - '+a.cptCode,
          additional: {
            insCovered: a.insCovered
          }
        });
      });

    });

  }
  getFrequentlyServiceItemData(event) {
    if (event) {
      let filteredArray = []
      this.GetServiceList.forEach((a) => {
        let itemFilter = filteredArray.filter(value => value.itemId == a.itemId);
        if (a.itemId == event && itemFilter.length == 0) {
          filteredArray.push(a);
        }
      });
      this.addMapFormGroup(filteredArray, 0);
    }
  }

  RemoveFromServiceList(index) {
    var Value = new FormArray([]);
    Value = this.form.get("itemDetails") as FormArray;
    Value.removeAt(index);
    this.calculateTotalAmount();
    this.getTotalRate();
  }
  RemoveFromSelect(event) {
    var EventValue = new FormArray([]);
    EventValue = this.form.get("itemDetails") as FormArray;
    EventValue.removeAt(EventValue.value.findIndex(element => element.itemId === event.itemId));
  }


  GetAllCPTModifier() {

    this.ConfigurationService.GetCPTModifier(this.globals.HospitalId, 0).subscribe((res) => {
      this.CPTModifiers = res.response;

    });
  }
  PrintServiceOrder() {
    let initialState = {
      modalHeading: 'Print Service Order Details',
      reportName: 'RptServiceOrderDetails.trdp',
      reportParams: {
        ConsultationId: this.EMRPatientInfo.ConsultationId,
        OrderId: this.orderId,
      }
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(TelerikPrintComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
    // const payload = {
    //   sid: this.orderId,
    //   clientid: Number(this.globals.ClientId)
    // }
    // this.ConfigurationService.PrintServiceOrder(payload).subscribe((res) => {
    //   if (res.status == 200) {
    //     let response = res.response;
    //     this.src = response[0].reportPath;
    //     this.src = this.baseUrl + this.src;
    //     //this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);

    //     window.open(this.src, "_blank");
    //     // this.isPdfLoaded=true;
    //   }

    // })


  }

  calculateTotalAmount(){
    let serviceItems: any[] = (this.form.get("itemDetails") as FormArray).value;
    this.totalAmount = 0;
    _.forEach(serviceItems, (item: any)=>{
      const unitPrice = Number(item.rate);
      const qunatity = Number(item.qty);
      this.totalAmount = (Number(this.totalAmount) + Number(unitPrice) * Number(qunatity)).toFixed(2);
    });
  }

  getApplicationSettings(){
    let  payload = {
      UserId: Number(localStorage.getItem('user_id')),
      LocationId: this.globals?.Location?.locationId,
      BranchId:this.globals.HospitalId
    }

    this.settingsService.getApplicationSettings(payload).subscribe({
      next: (response: Response<any>)=>{
        if(response.status == 200){
          this.configurationSettings = _.filter(response.response, {tabId: 1});
          let showInsure = this.configurationSettings.filter((item) => item.settId == 29);
          this.showInsuranceDet = Number(showInsure[0].settValue);
      }
    }
    })
  }

  getTotalRate() {
    let maxLimit;
    this.totalDeduction = 0;
    this.form.get('itemDetails')['controls'].forEach((element, index) => {
      
      let taxAmount = Number(((Number(element.get('taxPcnt').value) * element.get('rate').value) / 100).toFixed(2));

      if (element.get('insCovered').value && this.EMRSponsorInfo.SponsorId) {
        let deduction: number = 0;
        let isDeductionApplied: boolean = false;

        if (element.get('dedAmt').value && element.get('dedAmt').value != 0) {
          deduction = Number(element.get('dedAmt').value);
          
        } else {
          deduction = Number(((Number(element.get('dedPer').value) * (element.get('rate').value + taxAmount)) / 100).toFixed(2));
          
        }
        deduction = deduction > element.get('rate').value ? element.get('rate').value : deduction;
        isDeductionApplied = deduction > 0 ? true : false;
        
        this.totalDeduction = Number((this.totalDeduction + deduction).toFixed(2));

        let copay: number = 0;

        if (element.get('coPayAmt').value && element.get('coPayAmt').value != 0) {
          copay = Number(element.get('coPayAmt').value);
        } else {
          copay = Number(((Number(element.get('copayPer').value) * ((element.get('rate').value - deduction) + taxAmount)) / 100).toFixed(2));
        }
        copay = copay > element.get('rate').value ? (isDeductionApplied && deduction < element.get('rate').value ? element.get('rate').value : 0.00) : (isDeductionApplied && (copay > element.get('rate').value - deduction) ? element.get('rate').value - deduction : copay);
        copay = ((copay > Number(element.get('copayMaxLimit').value)) && (Number(element.get('copayMaxLimit').value) > 0)) ? Number(element.get('copayMaxLimit').value) : copay;
        this.totalCopay = Number((this.totalCopay + copay).toFixed(2));
      }
      maxLimit = element.get('maxLimit').value
    });
    if ((Number(maxLimit) !== 0) && (this.totalCopay > Number(maxLimit))) {
      this.totalCopay = Number((maxLimit).toFixed(2))
    }
    
  }

  openServiceNote(data){
    let initialState = {
      noteFieldData: data,
      action: 'view'
    }
    let config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: true,
    }
    const modalRef = this.modalService.show(SponsorRuleItemNoteComponent, Object.assign({}, config, { class: 'modal-lg', initialState }))
  
  }

  generateErx(){
    let input:any = {
      ConsultationId: this.EMRPatientInfo.ConsultationId,
      BranchId:  this.globals.HospitalId,
      SponsorId: this.EMRPatientInfo.SponsorId,
      PatientId:this.EMRPatientInfo.PatientId,
      VisitId:this.EMRPatientInfo.VisitId,
      ConsultantId:this.EMRPatientInfo.ConsultantId
    };
    this.EMRService.generateErx(input).subscribe((res:any)=>{
      if (res.status == 200 && res.message == "Success") {
        this.toastr.success("Success: " + "Generate ERX Successfully.");
      } else {
        if(res.errorMessage.message == null) {
          this.toastr.error(res.message)
        } else {
          this.toastr.error(res.errorMessage.message)
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
